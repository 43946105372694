<template>
  <v-row><v-col>Debug Commands</v-col></v-row>
  <v-row>
    <v-col>
      <v-btn @click="debugOn">Debug On</v-btn>
    </v-col>
    <v-col>
      <v-btn @click="debugOff">Debug Off</v-btn>
    </v-col>
  </v-row>

  <v-row><v-col>Debug Cover</v-col></v-row>
  <v-row>
    <v-col>
      <v-btn @click="coverOpen">Cover Open</v-btn>
    </v-col>
    <v-col>
      <v-btn @click="coverClose">Cover Close</v-btn>
    </v-col>
  </v-row>

  <v-row><v-col>Debug Drone</v-col></v-row>
  <v-row>
    <v-col>
      <v-btn @click="droneOn">Drone On</v-btn>
    </v-col>
    <v-col>
      <v-btn @click="droneOff">Drone Off</v-btn>
    </v-col>
  </v-row>

  <v-row><v-col>Debug Task Execution</v-col></v-row>
  <v-row>
    <v-col>
      <v-btn @click="taskPause">Task Pause</v-btn>
    </v-col>
    <v-col>
      <v-btn @click="taskResume">Task Resume</v-btn>
    </v-col>
  </v-row>

</template>

<script>
import useStore from "@/store";
import { storeToRefs } from "pinia";
// eslint-disable-next-line no-unused-vars
import {
  dockDebugOffService,
  dockDebugOnService,
  dockDroneOnService,
  dockDroneOffService,
  dockCoverOpenService,
  dockCoverCloseService,
} from "@/services/dockService.js";

import {
  // eslint-disable-next-line no-unused-vars
  taskPauseService,
  // eslint-disable-next-line no-unused-vars
  taskResumeService,
} from "@/services/taskService.js";

export default {
  data: () => ({}),
  components: {},
  setup() {
    const store = storeToRefs(useStore());
    const { device_sn, child_device_sn, telemetry } = store;
    return {
      store: store,
      device_sn: device_sn,
      child_device_sn: child_device_sn,
      telemetry: telemetry,
    };
  },
  mounted() {},
  methods: {
    async debugOn() {
      console.log("Debug On");
      await dockDebugOnService(this.device_sn);
    },
    async debugOff() {
      console.log("Debug Off");
      await dockDebugOffService(this.device_sn);
    },
    async droneOn() {
      console.log("Drone On");
      await dockDroneOnService(this.device_sn);
    },
    async droneOff() {
      console.log("Drone Off");
      await dockDroneOffService(this.device_sn);
    },
    async coverOpen() {
      console.log("Cover Open");
      await dockCoverOpenService(this.device_sn);
    },
    async coverClose() {
      console.log("Cover Close");
      await dockCoverCloseService(this.device_sn);
    },
    async taskPause() {
      console.log("Task Pause");
      const taskId = this.telemetry[this.device_sn]["events_flighttask_progress"]["data"]["output"]["ext"]["flight_id"];
      console.log(taskId)
      await taskPauseService(taskId);
    },
    async taskResume() {
      console.log("Task Pause");
      const taskId = this.telemetry[this.device_sn]["events_flighttask_progress"]["data"]["output"]["ext"]["flight_id"];
      console.log(taskId)
      await taskResumeService(taskId);
    }
    
  },
};
</script>

<style scoped>
.telemetryPanel {
  width: 100%;
}
</style>
