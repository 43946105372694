/* eslint-disable no-unused-vars */
import useStore from "@/store";
import { alarmDetecion, alarmPause, alarmGeneral } from "@/services/audioService";
import { onMessage } from "@/mqtt/handlerV2";

const OSDType = {
  TELEMETRY: "latitude",
  COMMAND: "drc_state",
  MAINTAINANCE: "job_number",
};

const MessageType = {
  OSD: "osd",
  EVENTS: "events",
};

export const handleConnect = () => {
  const store = useStore();
  const device_sn = store.device_sn;
  const child_device_sn = store.child_device_sn;
  const topics = [
    "thing/product/" + device_sn + "/#",
    "thing/product/" + child_device_sn + "/#",
  ];
  store.telemetry[device_sn] = {};
  store.telemetry[child_device_sn] = {};
  for (const topic of topics) {
    if (store.mqtt.client !== null) {
      store.mqtt.client.subscribe(topic, (err, granted) => {
        console.log("MQTT SUBSCRIBED", topic, err, granted);
      });
    }
  }
  store.mqtt.isConnected = true;
};

export const handleDisconnect = () => {
  const store = useStore();
  store.device_connected = false;
  store.child_device_connected = false;
  store.mqtt.isConnected = false;
  store.telemetry = {};
};

export const handleMessage = (topic, messageRaw) => {
  const store = useStore();
  const deviceSn = topic.split("/")[2];
  const messageType = topic.split("/")[3];
  const message = JSON.parse(messageRaw.toString());
  onMessage(topic, messageRaw);
  if (messageType === MessageType.OSD) {
    
    const key = getOSDType(message.data);
    checkIfExist(store, deviceSn, key);
    store.telemetry[deviceSn][key] = message;
    if (key == "osd_telemetry") {
      setMapData(store, deviceSn, message.data);
    }
  } else if (messageType === MessageType.EVENTS) {
    handleReplyEvent(message);
    const key = messageType + "_" + message["method"];
    checkIfExist(store, deviceSn, key);
    store.telemetry[deviceSn][key] = message;
  } else if (messageType === "custom") {
    try {
      store.agl = message["agl"]
    } catch {
      console.log("error custom message")
    }
  } else if (messageType === "alarm") {
    console.log("ALARME HERE ! ", message);
    try {
      if (message["event"] == "detection-alarm"){
        if (localStorage.getItem("sound") === "true" || localStorage.getItem("sound") === true){
          alarmDetecion.play();
        } else {
          alarmDetecion.stop();
        }
      } else if (message["event"] == "auto-pause"){
        alarmPause.play();
      } else {
        if (localStorage.getItem("sound") === "true" || localStorage.getItem("sound") === true){
          alarmGeneral.play();  
        } else {
          alarmGeneral.stop();
        }
      }  
    } catch {
      if (localStorage.getItem("sound") === "true" || localStorage.getItem("sound") === true){
        alarmGeneral.play();  
      } else {
        alarmGeneral.stop();
      }    }
  } else {
    const key = messageType;
    checkIfExist(store, deviceSn, key);
    store.telemetry[deviceSn][key] = message;
  }
};

const setMapData = (store, sn, data) => {
  if (sn === store.device_sn) {
    if (store.gatewayMarker === null ) {
      return
    } else {
      const coords = {"lat": data.latitude, "lng": data.longitude};
      store.gatewayCoordinates = coords;
      store.gatewayMarker.setPosition(store.gatewayCoordinates);
    }
  } else if (sn === store.child_device_sn) {
    if (store.droneMarker === null ) {
      return
    } else {
      const coords = {"lat": data.latitude, "lng": data.longitude};
      store.droneCoordinates = coords;
      store.droneMarker.setPosition(store.gatewayCoordinates);
    }
  }
}

const handleReplyEvent = (message) => {
  if (message["need_reply"] === 0) {
    return;
  } else if (message["method"] !== "flighttask_progress") {
    return;

  } else if (message["data"]["output"]["status"] === "ok") {
    return;
  } else {
    const reply = {
      tid: message["tid"],
      bid: message["bid"],
      method: message["method"],
      gateway: message["gateway"],
      timestamp: Date.now(),
      data: {
        result: 0,
      },
    };
    console.log(reply);
    const store = useStore();
    store.mqtt.client.publish(
      "thing/product/" + message["gateway"] + "/events_reply",
      JSON.stringify(reply)
    );
  }
};

const checkIfExist = (store, deviceSn, key) => {
  if (!store.telemetry[deviceSn][key]) {
    store.telemetry[deviceSn][key] = {};
  }
};

const getOSDType = (data) => {
  if (Object.prototype.hasOwnProperty.call(data, OSDType.TELEMETRY)) {
    return "osd_telemetry";
  } else if (Object.prototype.hasOwnProperty.call(data, OSDType.COMMAND)) {
    return "osd_command";
  } else if (Object.prototype.hasOwnProperty.call(data, OSDType.MAINTAINANCE)) {
    return "osd_maintainance";
  } else {
    console.log("OSD TYPE NOT FOUND", data);
    return null;
  }
};

