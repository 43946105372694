<template>
  <v-main class="d-flex align-center justify-center" style="min-height: 300px">
    <v-app id="inspire">
      <v-toolbar>
        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

        <v-toolbar-title>Debug</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon v-if="gatewayConnected" color="green">mdi-controller</v-icon>
          <v-icon v-else color="red">mdi-controller</v-icon>
        </v-btn>

        <v-btn icon>
          <v-icon v-if="droneConnected" color="green">mdi-quadcopter</v-icon>
          <v-icon v-else color="red">mdi-quadcopter</v-icon>
        </v-btn>
      </v-toolbar>
      <v-main v-if="gatewayConnected">
        <v-grid>
          <v-row>
            <v-col>
              <v-expansion-panels>
                <div
                  v-for="(value, key) in gatewayTelemetry"
                  :key="key"
                  class="telemetryPanel"
                >
                  <DebugTelemetry :telemetry="value.data" :name="key" />
                </div>
              </v-expansion-panels>
            </v-col>
            <v-col>
              <DebugCommands />
              <DockTelemetryBox />
              <v-expansion-panels>
                <div
                  v-for="(value, key) in droneTelemetry"
                  :key="key"
                  class="telemetryPanel"
                >
                  <DebugTelemetry :telemetry="value.data" :name="key" />
                </div>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-grid>
      </v-main>
    </v-app>
  </v-main>
</template>

<script>
import useStore from "@/store";
import { storeToRefs } from "pinia";
import {
  deviceConnected,
  droneTelemetryFilter,
} from "./utils/telemetryUtils.js";
import DebugTelemetry from "./components/DebugTelemetry.vue";
import DebugCommands from "./components/DebugCommands.vue";
import DockTelemetryBox from "./components/DockTelemetryBox.vue";
export default {
  data: () => ({}),
  components: {
    DebugTelemetry,
    DebugCommands,
    DockTelemetryBox,
  },
  setup() {
    const store = storeToRefs(useStore());
    const { device_sn, child_device_sn, telemetry } = store;
    return {
      store: store,
      device_sn: device_sn,
      child_device_sn: child_device_sn,
      telemetry: telemetry,
    };
  },
  computed: {
    droneTelemetry() {
      const data = this.telemetry[this.child_device_sn];
      const result = {};
      for (const [key, value] of Object.entries(data)) {
        result[key] = value;
        if (key.includes("osd_")) {
          if (key.includes("osd_telemetr")) {
            result[key].data = droneTelemetryFilter(value.data);
          }
        }
      }
      return result;
    },
    droneConnected() {
      const data = this.telemetry[this.child_device_sn];
      return deviceConnected(data);
    },
    gatewayTelemetry() {
      const data = this.telemetry[this.device_sn];
      const result = {};
      for (const [key, value] of Object.entries(data)) {
        result[key] = value;
        if (key.includes("osd_")) {
          console.log();
        }
      }
      return result;
    },
    gatewayConnected() {
      const data = this.telemetry[this.device_sn];
      return deviceConnected(data);
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.telemetryPanel {
  width: 100%;
}
</style>
