import useTelemetryStore from '@/store/telemetry'
import { alarmStart } from '@/services/audioService'
import useUiStore from '@/store/ui'

export const onMessage = (topic, payloadRaw) => {
  const payload = JSON.parse(payloadRaw.toString())
  const topic_data = getTopicData(topic)
  if (topic_data.payload_type === 'osd') {
    handle_osd(topic_data.device_id, payload)
  }
  else if (topic_data.payload_type === 'events') {
    handle_events(topic_data.device_id, payload)
  }
  else if (topic_data.payload_type === 'services') {
    handle_services(payload)
  }
  else if (topic_data.payload_type === 'services_reply') {
    handle_services_reply(payload)
  }
}

const getTopicData = (topic) => {
  const arr = topic.split('/')
  const device_id = arr[2]
  const payload_type = arr[3]
  return {
    device_id,
    payload_type
  }
}

const handle_osd = (device_id, payload) => {
  const telemetryStore = useTelemetryStore()
  const timestamp = payload.timestamp
  const data = payload.data
  for (let k in data) {
    telemetryStore.setDeviceTelemetry(device_id, timestamp, k, data[k])
  }
}

const handle_services = (payload) => {
  const uiStore = useUiStore()

  const result = {
    "timestamp": payload.timestamp,
    "data": payload.data,
    "type": "services",
    "method": payload.method,
  }
  uiStore.addService(result);
}

const handle_services_reply = (payload) => {
  const uiStore = useUiStore()
  const result = {
    "timestamp": payload.timestamp,
    "status": payload.data.result,
    "type": "services_reply",
    "method": payload.method,
  }
  uiStore.addService(result);
  if (result.method == "flighttask_execute" && result.status == 0) {
    alarmStart.play()
  }
}

const handle_events = (device_id, payload) => {
  const uiStore = useUiStore()
  const timestamp = payload.timestamp
  const data = payload.data
  if (payload.method === 'hms') {
    uiStore.setHms({
      "timestamp": timestamp,
      "data": data.list
    })
  }
}