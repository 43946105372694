<template>

    <v-card :title="getTitle">
        <v-card-text>
            Plan de vuelo: {{ getSubtitle }} <br>
            Inicio de misión: {{ getBeginTime }}
        </v-card-text>
        <v-card-actions>
            <v-btn variant="elevated" color="grey-darken-3" @click="dialog = !dialog" text>
                Eliminar
            </v-btn>
        </v-card-actions>

        <v-fade-transition hide-on-leave>
            <v-card v-if="dialog" class="mx-auto" elevation="16">
                <v-card-actions>
                    <v-btn elevated @click="cancelMission"> Confirmar </v-btn>
                    <v-btn elevated @click="dialog = !dialog" > Cancelar </v-btn>
                </v-card-actions>
            </v-card>
        </v-fade-transition>

    </v-card>
</template>

<script>
import { taskDeleteService } from '@/services/taskService.js'
import { ref } from 'vue'

export default {
    name: "CalendarView",
    props: {
        job: Object,
    },
    setup() {
        const dialog = ref(false)
        const cancelEnabled = ref(true)
        return {
            dialog,
            cancelEnabled
        }
    },
    computed: {
        getTitle() {
            return "Mision - " + this.job.raw.job_name
        },
        getSubtitle() {
            return "Plan de vuelo - " + this.job.raw.file_name
        },
        getBeginTime() {
            const date_string_utc = this.job.raw.begin_time;
            console.log(date_string_utc)
            const d1 = date_string_utc.split(" ")[0].split("-")
            const YY = Number(d1[0])
            const MM = Number(d1[1]) - 1
            const DD = Number(d1[2])
            const d2 = date_string_utc.split(" ")[1].split(":")
            const HH = Number(d2[0])
            const mm = Number(d2[1])
            const ss = Number(d2[2])
            const date = new Date(Date.UTC(YY, MM, DD, HH, mm, ss));
            console.log(date)
            return date.toLocaleString()
            //return date.toLocaleDateString() + " " + date.toLocaleTimeString()
        },
    },
    methods: {
        async cancelMission() {
            console.log("Cancelando misión")
            this.dialog = !this.dialog
            await taskDeleteService(this.job.raw.job_id)
        }
    },
};
</script>