<template>
  
  <v-navigation-drawer :permanent="true" :width="400">
    <v-list>
      <v-list-item>
        <v-card>
          <v-card-title>Subir Wayline</v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-text-field v-model="fileName" label="Nombre" :rules="rules" required></v-text-field>
              <v-file-input
                v-model="files"
                :accept='[".kmz",".kml"]'
                color="deep-purple-accent-4"
                counter
                clearable
                label="File input"
                placeholder="Select your files"
                prepend-icon="mdi-paperclip"
                variant="outlined"
                :show-size="1000"
                :loading="this.loading"
              >
              </v-file-input>
              <v-btn block class="mt-2" color="primary" @click="uploadFile">Subir</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-list-item>
   </v-list>
   <v-list>
    <v-list-item v-if="this.selectedWaylineObject !== null">
      <v-card>
        <v-card-title>Wayline Seleccionado</v-card-title>
        <v-card-subtitle>Nombre: {{ this.selectedWaylineObject.name }}</v-card-subtitle>
        
      </v-card>
    </v-list-item>
      <v-list-item v-for="wayline in waylines" :key="wayline">
        <WaylineBox :wayline="wayline"></WaylineBox>
      </v-list-item>
   </v-list>
  </v-navigation-drawer>
  
  <v-main class="d-flex" style="min-height: 300px;">
    <WorkspaceNotify />
    <TSAMap />
  </v-main>
</template>

<script>
import WaylineBox from "./components/wayline/WaylineBox.vue";
import { uploadWaylineService, getWaylinesService } from '@/services/waylineService.js';
import TSAMap from "./components/tsa/TSAMap.vue";
import useStore from "@/store";
import { storeToRefs } from "pinia";
import router from "@/router";
export default {
  name: "TaskLibraryView",
  components: {
    WaylineBox,
    TSAMap,
  },
  setup() {
    const store = useStore();
    store.selectedWaylineObject = null;
    const { selectedWaylineObject } = storeToRefs(store);
    return {
      waylines: [],
      selectedWaylineObject,
    };
  },
  async mounted() {
    await getWaylinesService().then((response) => {
      if (response["code"] == 0) {
        this.waylines = response["data"]["list"];
        console.log(this.waylines)
      } else {
        console.log(response["message"]);
      }
    });
  },
  data: () => ({
    files: [],
    loading: false,
    fileName: "",
    rules: [
        v => (v !== "") || 'Nombre invalido',
        v => (/^[^<>:"/|?*._\\]+$/).test(v) || 'Nombre invalido',
    ],
    //
  }),
  methods: {
    async beforeUploaded() {
        //await this.$refs.form.validate();
        console.log("??")
    },
    async uploadFile() {
      for (var i = 0; i < this.files.length; i++) {
        const f = this.files[i];
        const fileData = new FormData();
        fileData.append("file", f, this.fileName + ".kmz");
        await uploadWaylineService(fileData).then((response) => {
          console.log(response);
          router.go();
        });
      }
    },
  },
  computed: {
    //
  },
  watch: {
    files(val) {
      console.log(val);
    },
    //
  },
  created() {
    //
  },
};
</script>

<style scoped>
.container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
