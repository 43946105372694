export const deviceConnected = (data) => {
  if (data === null || data === undefined) {
    return false;
  }
  const checkExistTelemetry = existTelemetry(data);
  if (checkExistTelemetry) {
    return validTelemetry(data);
  } else {
    return false;
  }
};

const existTelemetry = (data) => {
  return Object.keys(data).length !== 0;
};

const validTelemetry = (data) => {
  const keys = Object.keys(data);
  for (const key of keys) {

    if (Date.now() - data[key]["timestamp"] < 5000) {
      return true;
    }
  }
  return false;
};


import { EModeCode } from "@/types/device.ts";


export const droneTelemetryFilter = (data) => {
  /*const ik = ["elevation", "height", "latitude", "longitude", "mode_code", "rid_state", "horizontal_speed", "vertical_speed"];
  let result = {};
  for (const key of ik) {
    result[key] = data[key];
  }*/
  let result = data;
  const mode_code = result["mode_code"];
  result["mode_code_text"] = EModeCode[mode_code];
  return result;
}