import { defineStore } from 'pinia'

export default defineStore({
  id: 'ui2',
  state: () => ({
    videoSizeFactor: 10,
    notifications: [],
    services: [],
    hms: {},
    hmsCode: hsmCodes,
    showFlyToPoint: 1,
    flyToPointActive: false,
  }),
  actions: {
    resetVideoSize() {
      this.videoSizeFactor = 10
    },
    addVideoSize() {
      this.videoSizeFactor += 2
    },
    subVideoSize() {
      if (this.videoSizeFactor > 6) {
        this.videoSizeFactor -= 2
      }
    },
    addNotification(message) {
      this.notifications.push(message)
    },
    removeNotification(index) {
      this.notifications.splice(index, 1)
    },
    removeAllNotifications() {
      this.notifications = []
    },
    setHms(hms) {
      this.hms = hms
    },
    addService(service) {
      this.services.push(service)
    },
    async removeService(index) {
      this.services.splice(index, 1)
      const copy = JSON.parse(JSON.stringify(this.services))
      this.services = [];
      this.services = copy;
    },
    removeAllServices() {
      this.services = []
    },
    findCodeText(code) {
      for (var key in this.hmsCode) {
        if (key.includes(code)) {
          return this.hmsCode[key]
        }
      }
    }
  }
})



const hsmCodes = {
  "dock_tip_0x12040000": {
    "en": "Dock RTK device disconnected",
    "zh": "机场RTK设备断连"
  },
  "dock_tip_0x12040001": {
    "en": "Dock moved",
    "zh": "机场位置被移动"
  },
  "dock_tip_0x12040002": {
    "en": "Dock RTK error",
    "zh": "机场RTK工作异常"
  },
  "dock_tip_0x12040003": {
    "en": "Location calibrated by RTK device far from current dock location",
    "zh": "机场RTK标定位置距离机场位置过远"
  },
  "dock_tip_0x12040004": {
    "en": "Dock RTK initialization error",
    "zh": "机场RTK初始化收敛失败"
  },
  "dock_tip_0x160900BF": {
    "en": "Wind speed too high (≥9 m/s). Unable to perform task",
    "zh": "风速过大（≥9 m/s），无法执行飞行任务"
  },
  "dock_tip_0x17000001": {
    "en": "No image transmission signal",
    "zh": "无图传信号"
  },
  "dock_tip_0x17000004": {
    "en": "Image transmission signal weak",
    "zh": "图传信号弱"
  },
  "dock_tip_0x17000005": {
    "en": "Strong signal interference in dock location. Fly with caution",
    "zh": "机场所在位置信号干扰较强，请注意飞行安全"
  },
  "dock_tip_0x17110041": {
    "en": "Dock working status error. Aircraft may perform signal lost action",
    "zh": "机场工作状态异常，飞行器可能执行航线失联行为"
  },
  "dock_tip_0x1910F003": {
    "en": "Dock external security camera not installed or disconnected",
    "zh": "机场舱外监控摄像头未安装或断连"
  },
  "dock_tip_0x19110000": {
    "en": "Dock %1$s cover motor stalled",
    "zh": "%1$s舱盖电机堵转"
  },
  "dock_tip_0x19110002": {
    "en": "Dock %1$s cover excessive position error",
    "zh": "%1$s舱盖位置误差过大"
  },
  "dock_tip_0x19110003": {
    "en": "Dock %1$s cover motor overspeed",
    "zh": "%1$s舱盖电机运动超速"
  },
  "dock_tip_0x19110004": {
    "en": "Dock %1$s cover motor out of control",
    "zh": "%1$s舱盖电机无法控制"
  },
  "dock_tip_0x19110005": {
    "en": "Dock %1$s cover motor voltage too low",
    "zh": "%1$s舱盖电机电压过低"
  },
  "dock_tip_0x19110006": {
    "en": "Dock %1$s cover motor voltage too high",
    "zh": "%1$s舱盖电机电压过高"
  },
  "dock_tip_0x1911000F": {
    "en": "Dock %1$s cover motor driver communication error",
    "zh": "%1$s舱盖驱动器通讯异常"
  },
  "dock_tip_0x19110011": {
    "en": "Dock %1$s cover unknown error",
    "zh": "%1$s舱盖运行未知错误"
  },
  "dock_tip_0x19110012": {
    "en": "Dock %1$s cover position calibration data error",
    "zh": "%1$s舱盖位置校准数据错误"
  },
  "dock_tip_0x19110013": {
    "en": "Dock %1$s cover not properly open or closed",
    "zh": "%1$s舱盖开启或关闭时位置不到位"
  },
  "dock_tip_0x19110014": {
    "en": "Opening dock %1$s cover timed out",
    "zh": "%1$s舱盖开启超时"
  },
  "dock_tip_0x19110015": {
    "en": "Closing dock %1$s cover timed out",
    "zh": "%1$s舱盖关闭超时"
  },
  "dock_tip_0x19110016": {
    "en": "Dock %1$s cover stalled. Failed to close",
    "zh": "%1$s舱盖关闭时触发防夹，未成功关闭"
  },
  "dock_tip_0x19110017": {
    "en": "Dock %1$s cover stalled. Failed to open",
    "zh": "%1$s舱盖开启时触发防夹，未成功开启"
  },
  "dock_tip_0x19110020": {
    "en": "%s dock cover Hall sensor error",
    "zh": "%s舱盖霍尔传感器故障"
  },
  "dock_tip_0x19110021": {
    "en": "Opening or closing %s dock cover timed out",
    "zh": "%s舱盖开启或关闭超时"
  },
  "dock_tip_0x19110022": {
    "en": "Opening or closing %s dock cover triggered pinch protection",
    "zh": "%s舱盖打开或关闭时触发防夹"
  },
  "dock_tip_0x19110023": {
    "en": "Driving rod of %s dock cover voltage too low",
    "zh": "%s舱盖推杆电压过低"
  },
  "dock_tip_0x19110024": {
    "en": "Driving rod of %s dock cover disconnected",
    "zh": "%s舱盖推杆断线"
  },
  "dock_tip_0x19110025": {
    "en": "%s driving rod short-circuited",
    "zh": "%s推杆短路"
  },
  "dock_tip_0x19110026": {
    "en": "%s driving rod temperature too high",
    "zh": "%s推杆温度过高"
  },
  "dock_tip_0x19110027": {
    "en": "%s dock cover error",
    "zh": "%s舱盖异常"
  },
  "dock_tip_0x19110028": {
    "en": "%s flight pause button error",
    "zh": "%s急停按钮故障"
  },
  "dock_tip_0x19110029": {
    "en": "%s dock cover command execution failed",
    "zh": "%s舱盖命令执行失败"
  },
  "dock_tip_0x1911002A": {
    "en": "%s dock cover not sealed",
    "zh": "%s舱盖未处于密封状态"
  },
  "dock_tip_0x19110400": {
    "en": "Dock %1$s driving rod position calibration data error",
    "zh": "%1$s推杆位置标定数据错误"
  },
  "dock_tip_0x19110401": {
    "en": "Dock %1$s driving rod steering gear stalled",
    "zh": "%1$s推杆舵机堵转"
  },
  "dock_tip_0x19110402": {
    "en": "Excessive resistance in steering %1$s driving rod",
    "zh": "%1$s推杆舵机阻力过大"
  },
  "dock_tip_0x19110403": {
    "en": "Dock %1$s driving rod steering gear overheated",
    "zh": "%1$s推杆舵机温度过高"
  },
  "dock_tip_0x19110404": {
    "en": "Dock %1$s driving rod steering gear communication error",
    "zh": "%1$s推杆舵机通讯异常"
  },
  "dock_tip_0x19110405": {
    "en": "Dock %1$s driving rod steering gear internal error",
    "zh": "%1$s推杆舵机内部错误"
  },
  "dock_tip_0x19110406": {
    "en": "Dock %1$s driving rod internal limit switch error",
    "zh": "%1$s推杆内侧限位开关工作异常"
  },
  "dock_tip_0x19110407": {
    "en": "Dock %1$s driving rod internal limit switch error",
    "zh": "%1$s推杆内侧限位开关工作异常"
  },
  "dock_tip_0x19110408": {
    "en": "Dock %1$s driving rod external limit switch error",
    "zh": "%1$s推杆外侧限位开关工作异常"
  },
  "dock_tip_0x19110409": {
    "en": "Dock %1$s driving rod external limit switch error",
    "zh": "%1$s推杆外侧限位开关工作异常"
  },
  "dock_tip_0x1911040B": {
    "en": "Pushing %1$s driving rod timed out",
    "zh": "%1$s推杆闭合超时"
  },
  "dock_tip_0x1911040C": {
    "en": "Pulling %1$s driving rod timed out",
    "zh": "%1$s推杆展开超时"
  },
  "dock_tip_0x19110800": {
    "en": "Failed to charge dock backup battery",
    "zh": "机场蓄电池无法充电"
  },
  "dock_tip_0x19110801": {
    "en": "Dock backup battery level low",
    "zh": "机场蓄电池电量低"
  },
  "dock_tip_0x19110802": {
    "en": "Dock backup battery overheated",
    "zh": "机场蓄电池温度过高"
  },
  "dock_tip_0x19110803": {
    "en": "Dock backup battery temperature too low",
    "zh": "机场蓄电池温度过低"
  },
  "dock_tip_0x19110804": {
    "en": "Dock backup battery switch not turned on",
    "zh": "机场蓄电池开关未开启"
  },
  "dock_tip_0x19110805": {
    "en": "Dock backup battery completely depleted. Unable to work properly",
    "zh": "机场蓄电池过度放电，已无法正常工作"
  },
  "dock_tip_0x19110806": {
    "en": "Dock backup battery charging function error",
    "zh": "机场蓄电池充电功能异常"
  },
  "dock_tip_0x19110807": {
    "en": "Dock backup battery charging function error",
    "zh": "机场蓄电池充电功能异常"
  },
  "dock_tip_0x19110C00": {
    "en": "Dock security camera not installed or disconnected",
    "zh": "机场监控摄像头未安装或断连"
  },
  "dock_tip_0x19111400": {
    "en": "Dock electrical cabinet cover open",
    "zh": "机场配电柜柜门被打开"
  },
  "dock_tip_0x19111401": {
    "en": "Door sensor of dock electrical cabinet error",
    "zh": "机场配电柜柜门传感器异常"
  },
  "dock_tip_0x19111402": {
    "en": "Door sensor of dock electrical cabinet error",
    "zh": "机场配电柜柜门传感器异常"
  },
  "dock_tip_0x19111800": {
    "en": "Emergency stop button pressed down",
    "zh": "急停按钮被按下"
  },
  "dock_tip_0x19111D01": {
    "en": "Aircraft inside dock is not linked. Linked aircraft is outside of dock",
    "zh": "舱内飞行器未对频，已对频飞行器在舱外"
  },
  "dock_tip_0x19112C01": {
    "en": "Temperature sensor of dock control module damaged",
    "zh": "机场主控模块温度传感器损坏"
  },
  "dock_tip_0x19112C02": {
    "en": "Temperature sensor of dock control module damaged",
    "zh": "机场主控模块温度传感器损坏"
  },
  "dock_tip_0x19112C03": {
    "en": "Dock environment temperature sensor damaged",
    "zh": "机场环境温度传感器损坏"
  },
  "dock_tip_0x19112C04": {
    "en": "Temperature sensor of dock control module damaged",
    "zh": "机场主控模块温度传感器损坏"
  },
  "dock_tip_0x19112C05": {
    "en": "Temperature sensor of dock control module damaged",
    "zh": "机场主控模块温度传感器损坏"
  },
  "dock_tip_0x19112C06": {
    "en": "Temperature sensor of dock left charging connector damaged",
    "zh": "机场左侧充电连接器温度传感器损坏"
  },
  "dock_tip_0x19112C07": {
    "en": "Temperature sensor of dock right charging connector damaged",
    "zh": "机场右侧充电连接器温度传感器损坏"
  },
  "dock_tip_0x19112C08": {
    "en": "Temperature sensor of landing pad interface board damaged",
    "zh": "停机坪接口板温度传感器损坏"
  },
  "dock_tip_0x19112C09": {
    "en": "Temperature sensor of landing pad interface board damaged",
    "zh": "停机坪接口板温度传感器损坏"
  },
  "dock_tip_0x19112C0A": {
    "en": "Temperature sensor of landing pad interface board damaged",
    "zh": "停机坪接口板温度传感器损坏"
  },
  "dock_tip_0x19112C0B": {
    "en": "Temperature sensor of dock control module damaged",
    "zh": "机场主控模块温度传感器损坏"
  },
  "dock_tip_0x19112C0C": {
    "en": "Temperature sensor of dock control module not connected",
    "zh": "机场主控模块温度传感器未连接"
  },
  "dock_tip_0x19112C0D": {
    "en": "Temperature sensor of dock charging module damaged",
    "zh": "机场充电电源模块温度传感器损坏"
  },
  "dock_tip_0x19112C0E": {
    "en": "Temperature sensor of dock charging module not connected",
    "zh": "机场充电电源模块温度传感器未连接"
  },
  "dock_tip_0x19112C0F": {
    "en": "Temperature sensor of dock battery module damaged",
    "zh": "机场电源模块温度传感器损坏"
  },
  "dock_tip_0x19112C10": {
    "en": "Temperature sensor of dock power module not connected",
    "zh": "机场电源模块温度传感器未连接"
  },
  "dock_tip_0x19112C11": {
    "en": "Temperature sensor of dock battery module connector damaged",
    "zh": "机场电源模块连接器温度传感器损坏"
  },
  "dock_tip_0x19112C12": {
    "en": "Temperature sensor of dock battery module connector not connected",
    "zh": "机场电源模块连接器温度传感器未连接"
  },
  "dock_tip_0x19112C13": {
    "en": "Temperature sensor of dock charging module connector damaged",
    "zh": "机场充电模块连接器温度传感器损坏"
  },
  "dock_tip_0x19112C14": {
    "en": "Temperature sensor of dock charging module connector not connected",
    "zh": "机场充电模块连接器温度传感器未连接"
  },
  "dock_tip_0x19112C15": {
    "en": "Temperature sensor of dock AC battery module damaged",
    "zh": "机场空调电源模块温度传感器损坏"
  },
  "dock_tip_0x19112C16": {
    "en": "Temperature sensor of dock AC battery module not connected",
    "zh": "机场空调电源模块温度传感器未连接"
  },
  "dock_tip_0x19112C17": {
    "en": "Temperature sensor of dock AC battery module connector damaged",
    "zh": "机场空调电源模块连接器温度传感器损坏"
  },
  "dock_tip_0x19112C18": {
    "en": "Temperature sensor of dock AC battery module connector not connected",
    "zh": "机场空调电源模块连接器温度传感器未连接"
  },
  "dock_tip_0x19112C19": {
    "en": "Temperature sensor of dock backup battery damaged",
    "zh": "机场蓄电池温度传感器损坏"
  },
  "dock_tip_0x19112C1A": {
    "en": "Temperature sensor of dock backup battery not connected",
    "zh": "机场蓄电池温度传感器未连接"
  },
  "dock_tip_0x19113000": {
    "en": "Dock vibration sensor error",
    "zh": "机场振动传感器工作异常"
  },
  "dock_tip_0x19113001": {
    "en": "Dock vibration detected. Dock may be moved or damaged",
    "zh": "机场产生振动，可能被移动或遭到破坏"
  },
  "dock_tip_0x19113401": {
    "en": "Aircraft battery charge voltage error",
    "zh": "飞行器电池充电电压异常"
  },
  "dock_tip_0x19113403": {
    "en": "Aircraft battery not properly installed, or battery level too low and battery enters hibernation mode",
    "zh": "飞行器电池不在位，或电量极低已进入休眠模式"
  },
  "dock_tip_0x19113404": {
    "en": "Failed to charge aircraft battery",
    "zh": "飞行器电池充电失败"
  },
  "dock_tip_0x19113406": {
    "en": "Aircraft battery error",
    "zh": "飞行器电池异常"
  },
  "dock_tip_0x19113407": {
    "en": "Dock power supply module error. Unable to charge aircraft",
    "zh": "机场供电电源模块异常，无法给飞行器电池充电"
  },
  "dock_tip_0x19113414": {
    "en": "Battery overheated (≥44° C). Unable to start charging",
    "zh": "电池温度过高（≥44℃）无法开始充电"
  },
  "dock_tip_0x19113800": {
    "en": "Dock internal power module error",
    "zh": "机场内部电源模块工作异常"
  },
  "dock_tip_0x19113802": {
    "en": "Dock electronic module power supply error",
    "zh": "机场弱电模块供电异常"
  },
  "dock_tip_0x19113803": {
    "en": "Dock power supply disconnected",
    "zh": "机场供电电源断开"
  },
  "dock_tip_0x19113806": {
    "en": "Dock battery module error",
    "zh": "机场电源模块故障"
  },
  "dock_tip_0x19113807": {
    "en": "Output voltage of dock battery module too high",
    "zh": "机场电源模块输出电压过高"
  },
  "dock_tip_0x19113808": {
    "en": "Output voltage of dock battery module too low",
    "zh": "机场电源模块输出电压过低"
  },
  "dock_tip_0x19113809": {
    "en": "Dock battery module temperature too high",
    "zh": "机场电源模块温度过高"
  },
  "dock_tip_0x1911380A": {
    "en": "Dock battery module temperature too low",
    "zh": "机场电源模块温度过低"
  },
  "dock_tip_0x1911380B": {
    "en": "Dock battery module connector temperature too high",
    "zh": "机场电源模块连接器温度过高"
  },
  "dock_tip_0x1911380C": {
    "en": "Dock battery module connector temperature too low",
    "zh": "机场电源模块连接器温度过低"
  },
  "dock_tip_0x19113901": {
    "en": "Battery voltage of dock system too high",
    "zh": "机场系统电源电压过高"
  },
  "dock_tip_0x19113902": {
    "en": "Battery voltage of dock system too low",
    "zh": "机场系统电源电压过低"
  },
  "dock_tip_0x19113903": {
    "en": "Dock control module powered off abnormally",
    "zh": "机场主控模块异常掉电"
  },
  "dock_tip_0x19113C00": {
    "ar": "",
    "de": "",
    "en": "Surge protector circuit breaker not turned on, or surge protector device damaged",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "防雷器后备保护器开关未开启，或防雷器损坏",
    "zh-Hant": ""
  },
  "dock_tip_0x19113C01": {
    "en": "Landing pad connection error",
    "zh": "停机坪连接异常"
  },
  "dock_tip_0x19113C02": {
    "en": "Rainfall gauge connection error",
    "zh": "雨量计连接异常"
  },
  "dock_tip_0x19113C03": {
    "en": "AC connection error",
    "zh": "空调连接异常"
  },
  "dock_tip_0x19113C04": {
    "en": "Dock charging module connection error",
    "zh": "机场充电模块连接异常"
  },
  "dock_tip_0x19113D00": {
    "en": "AC cooler damaged",
    "zh": "空调制冷片损坏"
  },
  "dock_tip_0x19113D01": {
    "en": "AC cooler damaged",
    "zh": "空调制冷片损坏"
  },
  "dock_tip_0x19113D02": {
    "en": "AC cooler damaged",
    "zh": "空调制冷片损坏"
  },
  "dock_tip_0x19113D03": {
    "en": "AC heater damaged",
    "zh": "空调加热器损坏"
  },
  "dock_tip_0x19113D04": {
    "en": "AC heater damaged",
    "zh": "空调加热器损坏"
  },
  "dock_tip_0x19113D05": {
    "en": "AC internal circulation fan damaged",
    "zh": "空调内循环风扇损坏"
  },
  "dock_tip_0x19113D06": {
    "en": "AC external circulation fan damaged",
    "zh": "空调外循环风扇损坏"
  },
  "dock_tip_0x19113D07": {
    "en": "Temperature sensor of AC external circulation supply vent damaged",
    "zh": "空调外循环出风口温度传感器损坏"
  },
  "dock_tip_0x19113D08": {
    "en": "Temperature sensor of AC internal circulation return vent damaged",
    "zh": "空调内循环进风口温度传感器损坏"
  },
  "dock_tip_0x19113D09": {
    "en": "Temperature sensor on the right of AC internal circulation supply vent damaged",
    "zh": "空调内循环出风口右侧温度传感器损坏"
  },
  "dock_tip_0x19113D0A": {
    "en": "Temperature sensor on the left of AC internal circulation supply vent damaged",
    "zh": "空调内循环出风口左侧温度传感器损坏"
  },
  "dock_tip_0x19113D0B": {
    "en": "AC cooler temperature sensor damaged",
    "zh": "空调制冷片温度传感器损坏"
  },
  "dock_tip_0x19113D0C": {
    "en": "Temperature sensor of AC internal circulation tube damaged",
    "zh": "空调内循环风道温度传感器损坏"
  },
  "dock_tip_0x19113D0D": {
    "en": "Dock cover damaged, or unable to close cover properly",
    "zh": "舱盖破损或无法完全关闭"
  },
  "dock_tip_0x19113D0E": {
    "en": "AC internal circulation return vent blocked",
    "zh": "空调内循环进风口堵塞"
  },
  "dock_tip_0x19113D0F": {
    "en": "AC internal circulation supply vent blocked",
    "zh": "空调内循环出风口堵塞"
  },
  "dock_tip_0x19113D10": {
    "en": "Large temperature difference (≥20° C) between AC internal circulation supply and return vents",
    "zh": "空调外循环出风口与进风口温差过大（≥20℃）"
  },
  "dock_tip_0x19113D11": {
    "en": "AC cooler power supply error",
    "zh": "空调制冷片供电异常"
  },
  "dock_tip_0x19113D12": {
    "en": "AC heater power supply error",
    "zh": "空调加热器供电异常"
  },
  "dock_tip_0x19113D13": {
    "en": "AC external circulation fan power supply error",
    "zh": "空调外循环风扇供电异常"
  },
  "dock_tip_0x19113D14": {
    "en": "AC internal circulation fan power supply error",
    "zh": "空调内循环风扇供电异常"
  },
  "dock_tip_0x19113D15": {
    "en": "AC power supply error",
    "zh": "空调供电异常"
  },
  "dock_tip_0x19113D16": {
    "en": "",
    "es": "",
    "ja": "",
    "ko": "",
    "zh": "空调外循环进风口堵塞"
  },
  "dock_tip_0x19113D20": {
    "en": "Temperature sensor of return vent of AC external circulation damaged",
    "zh": "空调外循环进风口温度传感器损坏"
  },
  "dock_tip_0x19113D21": {
    "en": "Temperature sensor of supply vent of AC internal circulation damaged",
    "zh": "空调内循环出风口温度传感器损坏"
  },
  "dock_tip_0x19113D22": {
    "en": "Temperature sensor of AC TEC cold side damaged",
    "zh": "空调TEC冷端温度传感器损坏"
  },
  "dock_tip_0x19113D23": {
    "en": "Large temperature difference between AC external circulation supply vent and return vent (≥10° C)",
    "zh": "空调外循环出风口与进风口温差过大（≥10℃）"
  },
  "dock_tip_0x19113D24": {
    "en": "NTC temperature sensor of left driving rod damaged",
    "zh": "左推杆温感NTC温度传感器损坏"
  },
  "dock_tip_0x19113D25": {
    "en": "NTC temperature sensor of right driving rod damaged",
    "zh": "右推杆温感NTC温度传感器损坏"
  },
  "dock_tip_0x19113D26": {
    "en": "High temperature warning (≥45℃)",
    "zh": "高温预警（≥45℃）"
  },
  "dock_tip_0x19113D27": {
    "en": "Low temperature warning (≤-20℃)",
    "zh": "低温预警（≤-20℃）"
  },
  "dock_tip_0x19113D28": {
    "en": "Temperature sensor of AC external circulation supply vent short-circuited",
    "zh": "空调外循环出风口温度传感器短路"
  },
  "dock_tip_0x19113D29": {
    "en": "Temperature sensor of AC internal circulation return vent short-circuited",
    "zh": "空调内循环进风口温度传感器短路"
  },
  "dock_tip_0x19113D2A": {
    "en": "AC cooler temperature sensor short-circuited",
    "zh": "空调制冷片温度传感器短路"
  },
  "dock_tip_0x19113D2B": {
    "en": "Temperature sensor of AC TEC cold side short-circuited",
    "zh": "空调TEC冷端温度传感器短路"
  },
  "dock_tip_0x19113D2C": {
    "en": "Temperature sensor of AC external circulation return vent short-circuited",
    "zh": "空调外循环进风口温度传感器短路"
  },
  "dock_tip_0x19113D2D": {
    "en": "Temperature sensor of AC internal circulation supply vent short-circuited",
    "zh": "空调内循环出风口温度传感器短路"
  },
  "dock_tip_0x19113D2E": {
    "en": "NTC temperature sensor of left driving rod short-circuited",
    "zh": "左推杆温感NTC温度传感器短路"
  },
  "dock_tip_0x19113D2F": {
    "en": "NTC temperature sensor of right driving rod short-circuited",
    "zh": "右推杆温感NTC温度传感器短路"
  },
  "dock_tip_0x19114000": {
    "en": "Wind speed too high (≥ 9 m/s). Unable to perform task",
    "zh": "风速过大（≥9 m/s），无法执行飞行任务"
  },
  "dock_tip_0x19114800": {
    "en": "Heavy rainfall. Unable to perform task",
    "zh": "雨量过大，无法执行飞行任务"
  },
  "dock_tip_0x19114801": {
    "en": "Dock security camera power supply error",
    "zh": "机场监控摄像头供电异常"
  },
  "dock_tip_0x19114802": {
    "en": "DJI Cellular Dongle power supply error",
    "zh": "DJI Cellular 模块供电异常"
  },
  "dock_tip_0x19114803": {
    "en": "Power supply error in status indicator on dock cover",
    "zh": "舱盖告警灯供电异常"
  },
  "dock_tip_0x19114804": {
    "en": "Wind speed gauge heating module power supply error",
    "zh": "风速计加热供电异常"
  },
  "dock_tip_0x19114805": {
    "en": "Temperature and humidity sensor connection error",
    "zh": "舱内温湿度传感器连接异常"
  },
  "dock_tip_0x19114806": {
    "en": "Rainfall gauge connection error",
    "zh": "雨量计连接异常"
  },
  "dock_tip_0x19114807": {
    "en": "Rainfall gauge heating module power supply error",
    "zh": "雨量计加热模块供电异常"
  },
  "dock_tip_0x19114808": {
    "en": "Temperature sensor of rainfall gauge heating module connection error",
    "zh": "雨量计加热模块温度传感器连接异常"
  },
  "dock_tip_0x19114809": {
    "en": "Temperature sensor of rainfall gauge heating module overheated",
    "zh": "雨量计加热模块温度传感器温度过高"
  },
  "dock_tip_0x1911480A": {
    "en": "Temperature sensor of wind speed gauge heating module damaged",
    "zh": "风速计加热模块温度传感器损坏"
  },
  "dock_tip_0x1911480B": {
    "en": "Temperature sensor of wind speed gauge heating module overheated",
    "zh": "风速计加热模块温度传感器温度过高"
  },
  "dock_tip_0x1911480C": {
    "en": "Dock cover temperature sensor damaged. Heating module unable to work properly in low-temperature environments",
    "zh": "舱盖除冰加热温度传感器损坏"
  },
  "dock_tip_0x1911480D": {
    "en": "Dock cover temperature sensor overheated. Heating module failed to work properly",
    "zh": "舱盖除冰加热温度传感器温度过高"
  },
  "dock_tip_0x1911480E": {
    "en": "Rainfall gauge connection error",
    "zh": "雨量计连接异常"
  },
  "dock_tip_0x1911480F": {
    "en": "Rainfall gauge short-circuited",
    "zh": "雨量计短路"
  },
  "dock_tip_0x19114810": {
    "en": "",
    "es": "",
    "ja": "",
    "ko": "",
    "zh": "风速计模块连接异常"
  },
  "dock_tip_0x19114811": {
    "en": "Hall temperature sensor of wind speed gauge damaged",
    "zh": "风速计霍尔温度传感器损坏"
  },
  "dock_tip_0x19114812": {
    "en": "Hall temperature sensor of wind speed gauge damaged",
    "zh": "风速计霍尔温度传感器损坏"
  },
  "dock_tip_0x19114813": {
    "en": "Temperature and humidity sensor self-heating time too long",
    "zh": "温湿度传感器自加热时间过久"
  },
  "dock_tip_0x19114814": {
    "en": "Rainfall gauge damaged",
    "zh": "雨量计检测部件损坏"
  },
  "dock_tip_0x19114815": {
    "en": "Rainfall gauge damaged",
    "zh": "雨量计检测部件损坏"
  },
  "dock_tip_0x19114C00": {
    "en": "Water immersion detected",
    "zh": "机场浸水"
  },
  "dock_tip_0x19115000": {
    "en": "Error occurred and dock restarted",
    "zh": "机场异常重启"
  },
  "dock_tip_0x19116000": {
    "en": "Battery of dock charging module error",
    "zh": "机场充电模块电源故障"
  },
  "dock_tip_0x19116001": {
    "en": "Battery voltage of dock charging module too high",
    "zh": "机场充电模块电源电压过高"
  },
  "dock_tip_0x19116002": {
    "en": "Battery voltage of dock charging module too low",
    "zh": "机场充电模块电源电压过低"
  },
  "dock_tip_0x19116003": {
    "en": "Electric current of dock charging module too large",
    "zh": "机场充电模块电源电流过大"
  },
  "dock_tip_0x19116004": {
    "en": "Electric current of dock charging module too small",
    "zh": "机场充电模块电源电流过小"
  },
  "dock_tip_0x19116005": {
    "en": "Battery MOS of dock charging module temperature too high",
    "zh": "机场充电模块电源MOS温度过高"
  },
  "dock_tip_0x19116006": {
    "en": "Battery MOS of dock charging module temperature too low",
    "zh": "机场充电模块电源MOS温度过低"
  },
  "dock_tip_0x19116007": {
    "en": "Controller battery of dock charging module error",
    "zh": "机场充电模块控制器电源异常"
  },
  "dock_tip_0x19116008": {
    "en": "Dock charging module connector temperature too high",
    "zh": "机场充电模块连接器温度过高"
  },
  "dock_tip_0x19116009": {
    "en": "Dock charging module connector temperature too low",
    "zh": "机场充电模块连接器温度过低"
  },
  "dock_tip_0x1911600A": {
    "en": "Dock AC battery power supply error",
    "zh": "机场空调电源模块供电异常"
  },
  "dock_tip_0x1911600B": {
    "en": "Dock AC battery voltage too high",
    "zh": "机场空调电源模块电压过高"
  },
  "dock_tip_0x1911600C": {
    "en": "Dock AC battery voltage too low",
    "zh": "机场空调电源模块电压过低"
  },
  "dock_tip_0x1911600D": {
    "en": "Electric current of dock AC power module too large",
    "zh": "机场空调电源模块电流过大"
  },
  "dock_tip_0x1911600E": {
    "en": "Electric current of dock AC power module too small",
    "zh": "机场空调电源模块电流过小"
  },
  "dock_tip_0x1911600F": {
    "en": "Dock AC controller module battery error",
    "zh": "机场空调控制器模块电源故障"
  },
  "dock_tip_0x19116010": {
    "en": "Electric current of dock AC controller module too large",
    "zh": "机场空调控制器模块电流过大"
  },
  "dock_tip_0x19116011": {
    "en": "Electric current of dock AC controller module too small",
    "zh": "机场空调控制器模块电流过小"
  },
  "dock_tip_0x19116012": {
    "en": "Battery MOS of dock AC module temperature too high",
    "zh": "机场空调模块电源MOS温度过高"
  },
  "dock_tip_0x19116013": {
    "en": "Battery MOS of dock AC module temperature too low",
    "zh": "机场空调模块电源MOS温度过低"
  },
  "dock_tip_0x19116014": {
    "en": "AC module connector temperature too high",
    "zh": "空调模块连接器温度过高"
  },
  "dock_tip_0x19116015": {
    "en": "AC module connector temperature too low",
    "zh": "空调模块连接器温度过低"
  },
  "dock_tip_0x19116016": {
    "en": "Battery of weather station module error",
    "zh": "气象站模块电源故障"
  },
  "dock_tip_0x19116017": {
    "en": "Electric current of weather station module too large",
    "zh": "气象站模块电源电流过大"
  },
  "dock_tip_0x19116018": {
    "en": "Electric current of weather station module too small",
    "zh": "气象站模块电源电流过小"
  },
  "dock_tip_0x1911601B": {
    "en": "Battery of dock image transmission module error",
    "zh": "机场图传模块电源异常"
  },
  "dock_tip_0x1911601F": {
    "en": "Battery voltage of dock internal security camera too high",
    "zh": "机场舱内监控摄像头电压过高"
  },
  "dock_tip_0x19116020": {
    "en": "Battery voltage of dock internal security camera too low",
    "zh": "机场舱内监控摄像头电压过低"
  },
  "dock_tip_0x19116021": {
    "en": "Battery voltage of right dock cover too high",
    "zh": "右舱盖电源电压过高"
  },
  "dock_tip_0x19116022": {
    "en": "Battery voltage of right dock cover too low",
    "zh": "右舱盖电源电压过低"
  },
  "dock_tip_0x19116023": {
    "en": "Dock control module temperature too high",
    "zh": "机场主控模块温度过高"
  },
  "dock_tip_0x19116024": {
    "en": "Dock control module temperature too low",
    "zh": "机场主控模块温度过低"
  },
  "dock_tip_0x19117000": {
    "en": "Failed to recharge aircraft battery",
    "zh": "飞行器电池充电失败"
  },
  "dock_tip_0x19117021": {
    "en": "Dock cover moving. Charging aircraft battery failed",
    "zh": "舱盖运动中，飞行器电池充电失败"
  },
  "dock_tip_0x19117022": {
    "en": "Updating dock. Charging aircraft battery failed",
    "zh": "机场升级中，飞行器电池充电失败"
  },
  "dock_tip_0x19117023": {
    "en": "No aircraft found",
    "zh": "未检测到飞行器"
  },
  "dock_tip_0x19117024": {
    "en": "Metal foreign objects detected in dock charging module. Charging aircraft battery failed",
    "zh": "机场充电模块检测到金属异物，飞行器电池充电失败"
  },
  "dock_tip_0x19117025": {
    "en": "Dock cover opened. Charging aircraft battery failed",
    "zh": "检测到机场舱盖已打开，飞行器充电失败"
  },
  "dock_tip_0x19117041": {
    "en": "Aircraft not connected",
    "zh": "飞行器未连接"
  },
  "dock_tip_0x19117043": {
    "en": "Aircraft battery level critically low. Unable to power on aircraft",
    "zh": "飞行器严重低电量，无法开机"
  },
  "dock_tip_0x19117045": {
    "en": "Aircraft battery error. Unable to charge battery or power on aircraft",
    "zh": "飞行器电池故障，无法充电或开机"
  },
  "dock_tip_0x19117046": {
    "en": "Aircraft battery communication error",
    "zh": "飞行器电池通信异常"
  },
  "dock_tip_0x19117047": {
    "en": "Aircraft battery level high. No battery charging required",
    "zh": "飞行器电量较高，无需充电"
  },
  "dock_tip_0x19117048": {
    "en": "Aircraft wireless charging module error",
    "zh": "飞行器无线充电模块异常"
  },
  "dock_tip_0x19117061": {
    "en": "Waking aircraft charging module failed",
    "zh": "飞行器充电模块唤醒失败"
  },
  "dock_tip_0x19117202": {
    "en": "Powering on aircraft timed out",
    "zh": "飞行器开机超时"
  },
  "dock_tip_0x19117221": {
    "en": "Failed to connect dock to aircraft Bluetooth",
    "zh": "机场连接飞行器蓝牙失败"
  },
  "dock_tip_0x19117222": {
    "en": "Dock Bluetooth link error",
    "zh": "机场蓝牙模块链路故障"
  },
  "dock_tip_0x19117223": {
    "en": "Aircraft Bluetooth signal weak",
    "zh": "飞行器蓝牙模块信号弱"
  },
  "dock_tip_0x19117321": {
    "en": "Aircraft image transmission link error. Powering off failed",
    "zh": "飞行器图传链路异常，关机失败"
  },
  "dock_tip_0x19117322": {
    "en": "Powering off aircraft timed out",
    "zh": "飞行器关机超时"
  },
  "dock_tip_0x19117460": {
    "en": "Battery voltage of dock charging module too low",
    "zh": "机场充电电源模块电压过低"
  },
  "dock_tip_0x19117461": {
    "en": "Electric current of dock charging module too large",
    "zh": "机场充电电源模块电流过大"
  },
  "dock_tip_0x19117462": {
    "en": "Electric current of aircraft charging module coil too large",
    "zh": "飞行器充电模块线圈电流过大"
  },
  "dock_tip_0x19117463": {
    "en": "Aircraft charging module error",
    "zh": "飞行器充电电源故障"
  },
  "dock_tip_0x19117464": {
    "en": "Battery voltage of aircraft charging module too high",
    "zh": "飞行器充电电源过压"
  },
  "dock_tip_0x19117465": {
    "en": "Battery voltage of dock charging module too high",
    "zh": "机场充电电源过压"
  },
  "dock_tip_0x19117470": {
    "en": "Aircraft charging module error",
    "zh": "飞行器充电电源故障"
  },
  "dock_tip_0x19117480": {
    "en": "Dock charging module temperature too high",
    "zh": "机场充电模块温度过高"
  },
  "dock_tip_0x19117481": {
    "en": "Electric current of dock charging module too large",
    "zh": "机场充电模块充电电流过大"
  },
  "dock_tip_0x191174A0": {
    "en": "Temperature of dock charging module coil too high",
    "zh": "机场充电模块线圈温度过高"
  },
  "dock_tip_0x191174B0": {
    "en": "Temperature of dock wireless charging module error",
    "zh": "机场无线充电模块温度检测异常"
  },
  "dock_tip_0x191174B1": {
    "en": "Temperature sensor of dock charging module coil not connected",
    "zh": "机场充电模块线圈温度传感器未连接"
  },
  "dock_tip_0x191174B2": {
    "en": "Temperature sensor of dock charging module coil error",
    "zh": "机场充电模块线圈温度传感器异常"
  },
  "dock_tip_0x191174C0": {
    "en": "Temperature of aircraft charging module coil too high",
    "zh": "飞行器充电模块线圈温度过高"
  },
  "dock_tip_0x191174D0": {
    "en": "Temperature of aircraft wireless charging module error",
    "zh": "飞行器无线充电模块温度检测异常"
  },
  "dock_tip_0x191174D1": {
    "en": "Temperature sensor of aircraft charging module coil not connected",
    "zh": "飞行器充电模块线圈温度传感器未连接"
  },
  "dock_tip_0x191174D2": {
    "en": "Temperature sensor of aircraft charging module coil error",
    "zh": "飞行器充电模块线圈温度传感器异常"
  },
  "fpv_tip_0x11000002": {
    "en": "Aircraft power distribution board 1 overheated. Return to home or land promptly",
    "zh": "飞行器分电板1温度过高，请尽快返航或降落"
  },
  "fpv_tip_0x11000003": {
    "en": "Aircraft Power Distribution Board 2 overheated. Return to home or land promptly",
    "zh": "飞行器分电板2温度过高，请尽快返航或降落"
  },
  "fpv_tip_0x11000020": {
    "de": "Spannung von Gimbal %index zu hoch (%alarmid)",
    "en": "Gimbal %index payload voltage too high",
    "es": "Voltaje de instrumento en el estabilizador %index demasiado alto",
    "fr": "Tension de la nacelle %index trop élevée",
    "ja": "ジンバル%indexペイロード高電圧",
    "ko": "짐벌 %index 페이로드 전압 너무 높음",
    "ru": "Слишком высокое напряжение оборудования стабилизатора %index",
    "tr": "Gimbal %index yük voltajı çok yüksek",
    "zh": "%index号云台位负载电压过高"
  },
  "fpv_tip_0x11000021": {
    "de": "Spannung von Gimbal %index zu niedrig (%alarmid)",
    "en": "Gimbal %index payload voltage too low",
    "es": "Voltaje de instrumento en el estabilizador %index demasiado bajo",
    "fr": "Tension de la nacelle %index trop basse",
    "ja": "ジンバル%indexペイロード低電圧",
    "ko": "짐벌 %index 페이로드 전압 너무 낮음",
    "ru": "Слишком низкое напряжение оборудования стабилизатора %index",
    "tr": "Gimbal %index yük voltajı çok düşük",
    "zh": "%index号云台位负载电压过低"
  },
  "fpv_tip_0x11000022": {
    "de": "Akkumodul von Gimbal %index überhitzt (%alarmid)",
    "en": "Gimbal %index battery module overheated",
    "es": "Módulo de batería del estabilizador %index sobrecalentado",
    "fr": "Module de la batterie de la nacelle %index en surchauffe",
    "ja": "ジンバル%indexバッテリーモジュール高温",
    "ko": "짐벌 %index 배터리 모듈 과열",
    "ru": "Перегрев модуля батареи стабилизатора %index",
    "tr": "Gimbal %index pil modülü aşırı ısındı",
    "zh": "%index号云台位电源温度过高"
  },
  "fpv_tip_0x11000023": {
    "de": "Akkutemperatur von Gimbal %index zu niedrig (%alarmid)",
    "en": "Gimbal %index battery module temperature too low",
    "es": "Temperatura del módulo de batería del estabilizador %index demasiado baja",
    "fr": "Température du module de la batterie de la nacelle %index trop basse",
    "ja": "ジンバル%indexバッテリーモジュール低温",
    "ko": "짐벌 %index 배터리 모듈 온도 너무 낮음",
    "ru": "Слишком низкая температура модуля батареи стабилизатора %index",
    "tr": "Gimbal %index pil modülü sıcaklığı çok düşük",
    "zh": "%index号云台位电源温度过低"
  },
  "fpv_tip_0x11000024": {
    "de": "Leistung aller Nutzlasten zu hoch",
    "en": "Total payload power too high",
    "es": "Potencia total de instrumento demasiado alta",
    "fr": "Puissance totale de la charge utile trop élevée",
    "ja": "合計ペイロード高電力",
    "ko": "총 페이로드 전력 너무 높음",
    "ru": "Слишком высокая мощность оборудования",
    "tr": "Toplam yük gücü çok yüksek",
    "zh": "负载总功率过载"
  },
  "fpv_tip_0x11000025": {
    "de": "Übertragungsfehler: Gimbal %index (%alarmid)",
    "en": "Gimbal %index payload transmission error",
    "es": "Error de transmisión del instrumento en el estabilizador %index",
    "fr": "Erreur de transmission de la charge utile de la nacelle %index",
    "ja": "ジンバル%indexペイロード伝送エラー",
    "ko": "짐벌 %index 페이로드 전송 오류",
    "ru": "Ошибка передачи оборудования стабилизатора %index",
    "tr": "Gimbal %index yük iletim hatası",
    "zh": "%index号云台负载链路异常"
  },
  "fpv_tip_0x11000029": {
    "de": "PSDK-Spannung zu hoch",
    "en": "PSDK voltage too high",
    "es": "Voltaje de PSDK demasiado alto",
    "fr": "Tension PSDK trop élevée",
    "it": "",
    "ja": "PSDK高電圧",
    "ko": "PSDK 전압 너무 높음",
    "pt": "",
    "ru": "Слишком высокое напряжение PSDK",
    "tr": "PSDK voltajı çok yüksek",
    "zh": "PSDK 设备电压过高"
  },
  "fpv_tip_0x1100002A": {
    "de": "PSDK-Spannung zu niedrig",
    "en": "PSDK voltage too low",
    "es": "Voltaje de PSDK demasiado bajo",
    "fr": "Tension PSDK trop basse",
    "it": "",
    "ja": "PSDK低電圧",
    "ko": "PSDK 전압 너무 낮음",
    "pt": "",
    "ru": "Слишком низкое напряжение PSDK",
    "tr": "PSDK voltajı çok düşük",
    "zh": "PSDK 设备电压过低"
  },
  "fpv_tip_0x1100002B": {
    "de": "PSDK %index Akkumodul überhitzt (%alarmid)",
    "en": "PSDK battery module overheated",
    "es": "Módulo de batería de PSDK sobrecalentado",
    "fr": "Module de batterie du PSDK %index en surchauffe",
    "it": "",
    "ja": "PSDKバッテリーモジュール高温",
    "ko": "PSDK %index 배터리 모듈 과열",
    "pt": "",
    "ru": "Перегрев модуля батареи %index PSDK",
    "tr": "PSDK pil modülü aşırı ısındı",
    "zh": "PSDK电源温度过高"
  },
  "fpv_tip_0x1100002C": {
    "de": "PSDK %index Akkutemperatur zu niedrig (%alarmid)",
    "en": "PSDK battery module temperature too low",
    "es": "Temperatura del módulo de batería PSDK demasiado baja",
    "fr": "Température du module de batterie du PSDK %index trop basse",
    "it": "",
    "ja": "PSDKバッテリーモジュール低温",
    "ko": "PSDK %index 배터리 모듈 온도 너무 낮음",
    "pt": "",
    "ru": "Слишком низкая температура модуля батареи %index PSDK",
    "tr": "PSDK pil modülü sıcaklığı çok düşük",
    "zh": "PSDK电源温度过低"
  },
  "fpv_tip_0x1100002D": {
    "de": "PSDK-Gerätenennleistung wurde überschritten. Wird neu gestartet...",
    "en": "PSDK device rated power exceeded. Restarting...",
    "es": "Se superó la potencia nominal del dispositivo PSDK. Reiniciando...",
    "fr": "Puissance nominale de l'appareil PSDK dépassée. Redémarrage...",
    "it": "",
    "ja": "PSDK機器の定格出力を超えました。再起動しています",
    "ko": "PSDK 기기 정격 출력이 초과됨. 재시동 중...",
    "pt": "",
    "ru": "Превышена номинальная мощность устройства PSDK. Выполняется перезапуск...",
    "tr": "PSDK cihazı nominal gücü aşıldı. Yeniden başlatılıyor...",
    "zh": "PSDK 设备功率过载，重启中"
  },
  "fpv_tip_0x1100002E": {
    "de": "PSDK-Gerätenennleistung wurde überschritten. Fehler beim Neustart",
    "en": "PSDK device rated power exceeded. Failed to restart",
    "es": "Se superó la potencia nominal del dispositivo PSDK. No se pudo reiniciar.",
    "fr": "Puissance nominale de l'appareil PSDK dépassée. Échec du redémarrage",
    "it": "",
    "ja": "PSDK機器の定格出力を超えました。再起動に失敗しました",
    "ko": "PSDK 기기 정격 출력이 초과됨. 재시동하지 못했습니다.",
    "pt": "",
    "ru": "Превышена номинальная мощность устройства PSDK. Не удалось выполнить перезапуск",
    "tr": "PSDK cihazı nominal gücü aşıldı. Yeniden başlatılamadı",
    "zh": "PSDK 设备功率过载，重启失败"
  },
  "fpv_tip_0x11000034": {
    "de": "Fehler in der Stromversorgung des Landegestellmotors. Fluggerät neu starten",
    "en": "Landing gear motor power supply error. Restart aircraft",
    "es": "Error de la fuente de alimentación del motor del tren de aterrizaje. Reinicia la aeronave",
    "fr": "Erreur d’alimentation du moteur du train d’atterrissage. Redémarrer l’appareil",
    "ja": "ランディングギアモーターの電源エラー。機体を再起動してください",
    "ko": "랜딩 기어 모터 전력 공급 오류. 기체를 재시작하세요",
    "ru": "Ошибка питания двигателя шасси. Перезапустите дрон",
    "tr": "İniş takımı motoru güç kaynağı hatası. Hava aracını yeniden başlatın",
    "zh": "起落架舵机供电异常，请尝试重启飞行器"
  },
  "fpv_tip_0x11000035": {
    "de": "Fehler in der Stromversorgung des Radars. Neustart des Radars empfohlen",
    "en": "Radar power supply error. Restarting radar recommended",
    "es": "Error de la fuente de alimentación del radar. Se recomienda reiniciar el radar",
    "fr": "Erreur d’alimentation du radar. Redémarrage du radar recommandé",
    "ja": "レーダーの電源エラー。レーダーの再起動をお勧めします",
    "ko": "레이더 전력 공급 오류. 레이더 재시작 권장",
    "ru": "Ошибка питания радара. Рекомендуется перезапустить радар",
    "tr": "Radar güç kaynağı hatası. Radarın yeniden başlatılması önerilir",
    "zh": "雷达供电异常，请尝试重启雷达"
  },
  "fpv_tip_0x11000036": {
    "de": "Fehler in der Stromversorgung des 4G-Moduls. Modul entfernen und wieder einsetzen",
    "en": "4G module power supply error. Remove and insert module again",
    "es": "Error de la fuente de alimentación del módulo 4G. Retira el módulo e insértalo de nuevo",
    "fr": "Erreur d’alimentation du module 4G. Retirer et réinsérer le module",
    "ja": "4Gモジュールの電源エラー。モジュールを取り付け直してください",
    "ko": "4G 모듈 전력 공급 오류. 모듈을 제거했다가 다시 삽입하세요",
    "ru": "Ошибка питания модуля 4G. Удалите и снова вставьте модуль",
    "tr": "4G modül güç kaynağı hatası. Modülü çıkarın ve tekrar takın",
    "zh": "4G 模块供电异常，请尝试拔插"
  },
  "fpv_tip_0x11000037": {
    "de": "Fehler in der Stromversorgung des Gimbals. Den Gimbal lösen und wieder montieren",
    "en": "Gimbal power supply error. Detach and remount gimbal",
    "es": "Error de la fuente de alimentación del estabilizador. Retira el estabilizador y vuélvelo a montar",
    "fr": "Erreur d’alimentation de la nacelle. Détacher et remonter la nacelle",
    "ja": "ジンバルの電源エラー。ジンバルを取り付け直してください",
    "ko": "짐벌 전력 공급 오류. 짐벌을 분리했다가 다시 장착하세요",
    "ru": "Ошибка питания гиростабилизатора. Отсоедините и переустановите гиростабилизатор",
    "tr": "Gimbal güç kaynağı hatası. Gimbalı çıkarın ve yeniden takın",
    "zh": "云台供电异常，请尝试拔插云台"
  },
  "fpv_tip_0x11000038": {
    "de": "Der linke Akkuanschluss ist überhitzt. Zum Startpunkt zurückkehren oder umgehend landen",
    "en": "Left battery connector overheated. Return to home or land promptly",
    "es": "Conector de batería izquierdo sobrecalentado. Regresa al punto de origen o aterriza cuando antes.",
    "fr": "Connecteur de batterie gauche en surchauffe. Retourner au point de départ ou atterrir rapidement",
    "ja": "左バッテリーコネクターが高温。直ちに帰還するか、着陸してください",
    "ko": "왼쪽 배터리 커넥터가 과열 상태입니다. 즉시 리턴 투 홈을 실행하거나 착륙하세요",
    "ru": "Разъем аккумуляторной батареи слева перегрелся. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "Sol pil konektörü aşırı ısındı. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "左电池连接器温度过高，请尽快返航或降落"
  },
  "fpv_tip_0x11000039": {
    "de": "Der rechte Akkuanschluss ist überhitzt. Zum Startpunkt zurückkehren oder umgehend landen",
    "en": "Right battery connector overheated. Return to home or land promptly",
    "es": "Conector de batería derecho sobrecalentado. Regresa al punto de origen o aterriza cuando antes.",
    "fr": "Connecteur de batterie droite en surchauffe. Retourner au point de départ ou atterrir rapidement",
    "ja": "右バッテリーコネクターが高温。直ちに帰還するか、着陸してください",
    "ko": "오른쪽 배터리 커넥터가 과열 상태입니다. 즉시 리턴 투 홈을 실행하거나 착륙하세요",
    "ru": "Разъем аккумуляторной батареи справа перегрелся. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "Sağ pil konektörü aşırı ısındı. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "右电池连接器温度过高，请尽快返航或降落"
  },
  "fpv_tip_0x1100003A": {
    "de": "Der linke Akku ist nicht ordnungsgemäß eingesetzt. Akku entfernen und wieder einsetzen.",
    "en": "Left battery not properly installed. Remove and reinstall battery",
    "es": "La batería izquierda no está instalada correctamente. Retira la batería y vuelve a instalarla",
    "fr": "La batterie gauche n’est pas correctement installée. Retirer et réinstaller la batterie",
    "ja": "左バッテリーが正しく取り付けられていません。バッテリーを取り付け直してください",
    "ko": "왼쪽 배터리가 올바로 설치되지 않았습니다. 배터리를 제거했다가 다시 설치하세요",
    "ru": "Левая аккумуляторная батарея установлена неправильно. Извлеките и переустановите аккумуляторную батарею",
    "tr": "Sol pil düzgün takılmamış. Pili çıkarın ve yeniden takın",
    "zh": "左电池安装不到位，请重新安装电池"
  },
  "fpv_tip_0x1100003B": {
    "de": "Der rechte Akku ist nicht ordnungsgemäß eingesetzt. Akku entfernen und wieder einsetzen",
    "en": "Right battery not properly installed. Remove and reinstall battery",
    "es": "La batería derecha no está instalada correctamente. Retira la batería y vuelve a instalarla",
    "fr": "La batterie droite n’est pas correctement installée. Retirer et réinstaller la batterie",
    "ja": "右バッテリーが正しく取り付けられていません。バッテリーを取り付け直してください",
    "ko": "오른쪽 배터리가 올바로 설치되지 않았습니다. 배터리를 제거했다가 다시 설치하세요",
    "ru": "Правая аккумуляторная батарея установлена неправильно. Извлеките и переустановите аккумуляторную батарею",
    "tr": "Sağ pil düzgün takılmamış. Pili çıkarın ve yeniden takın",
    "zh": "右电池安装不到位，请重新安装电池"
  },
  "fpv_tip_0x1100003C": {
    "de": "Der Gimbal ist nicht ordnungsgemäß installiert. Den Gimbal lösen und wieder montieren",
    "en": "Gimbal not properly installed. Detach and remount gimbal",
    "es": "El estabilizador no se ha instalado correctamente. Retira el estabilizador y vuélvelo a montar",
    "fr": "La nacelle n’est pas correctement installée. Détacher et remonter la nacelle",
    "ja": "ジンバルが正しく取り付けられていません。ジンバルを取り付け直してください",
    "ko": "짐벌이 올바로 설치되지 않았습니다. 짐벌을 분리했다가 다시 장착하세요",
    "ru": "Неправильно установлен гиростабилизатор. Отсоедините и переустановите гиростабилизатор",
    "tr": "Gimbal düzgün takılmamış. Gimbalı çıkarın ve yeniden takın",
    "zh": "云台安装不到位，请重新安装云台"
  },
  "fpv_tip_0x1100003D": {
    "de": "Die Mittelplatine des Fluggeräts ist überhitzt. Zum Startpunkt zurückkehren oder umgehend landen",
    "en": "Aircraft center board overheated. Return to home or land promptly",
    "es": "Placa central de la aeronave sobrecalentada. Regresa al punto de origen o aterriza cuando antes.",
    "fr": "Carte principale de l’appareil en surchauffe. Retourner au point de départ ou atterrir rapidement",
    "ja": "機体のセンターボードが高温。直ちに帰還するか、着陸してください",
    "ko": "기체 중앙 보드가 과열 상태입니다. 즉시 리턴 투 홈을 실행하거나 착륙하세요",
    "ru": "Борт дрона перегрелся. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "Uçak merkez panosu aşırı ısındı. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "飞行器中心板温度过高，请尽快返航或降落"
  },
  "fpv_tip_0x1100003E": {
    "de": "Radar nicht angeschlossen. Mit Vorsicht fliegen und zum Startpunkt zurückkehren oder umgehend landen",
    "en": "Radar disconnected. Fly with caution and return to home or land promptly",
    "es": "Radar desconectado. Vuela con cuidado y regresa al punto de origen o aterriza cuanto antes",
    "fr": "Radar déconnecté. Pilotez avec précaution et revenez au point de départ ou atterrissez rapidement",
    "ja": "レーダーの接続切断。慎重に飛行し、直ちに帰還するか着陸してください",
    "ko": "레이더 연결이 끊어졌습니다. 비행 시 주의하고 즉시 RTH를 실행하거나 착륙하세요",
    "ru": "Радар отключен. Соблюдайте осторожность при полете; как можно скорее вернитесь на базу или приземлитесь",
    "tr": "Radar bağlantısı kesildi. Dikkatli uçun ve başlangıç noktasına dönün veya derhal iniş yapın.",
    "zh": "雷达连接断开，请谨慎飞行并尽快返航或降落"
  },
  "fpv_tip_0x1100003F": {
    "de": "Verbindungsfehler bei den abwärts gerichteten Infrarotsensoren. Fluggerät neu starten",
    "en": "Downward infrared sensing system connection error. Restart aircraft",
    "es": "Error de conexión del sistema de detección por infrarrojos inferior Reinicia la aeronave",
    "fr": "Erreur de connexion du système de détection infrarouge inférieur. Redémarrer l’appareil",
    "ja": "下方赤外線検知システムの接続エラー。機体を再起動してください",
    "ko": "하향 적외선 감지 시스템 연결 오류. 기체를 재시작하세요",
    "ru": "Ошибка подключения системы нижнего ИК датчика. Перезапустите дрон",
    "tr": "Aşağıya doğru kızılötesi algılama sistemi bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "飞行器TOF模块连接异常，请尝试重启飞行器"
  },
  "fpv_tip_0x11000040": {
    "de": "Kommunikationsfehler des Landegestellmotors. Fluggerät neu starten",
    "en": "Landing gear motor communication error. Restart aircraft",
    "es": "Error de comunicación del motor del tren de aterrizaje. Reinicia la aeronave",
    "fr": "Erreur de communication du moteur du train d’atterrissage. Redémarrer l’appareil",
    "ja": "ランディングギアモーターの通信エラー。機体を再起動してください",
    "ko": "랜딩 기어 모터 통신 오류. 기체를 재시작하세요",
    "ru": "Ошибка связи двигателя шасси. Перезапустите дрон",
    "tr": "İniş takımı motoru bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "起落架舵机通信异常，请尝试重启飞行器"
  },
  "fpv_tip_0x11000041": {
    "de": "Landegestellmotor blockiert. Darauf achten, dass sich keine Hindernisse am Motor befinden, und das Fluggerät neu starten.",
    "en": "Landing gear motor stalled. Make sure motor is clear of obstacles and restart aircraft",
    "es": "Motor del tren de aterrizaje atascado. Asegúrate de que el motor esté libre de obstáculos y reinicia la aeronave.",
    "fr": "Moteur du train d’atterrissage bloqué. Assurez-vous que le moteur est exempt d’obstacles et redémarrez l’appareil",
    "ja": "ランディングギアモーターが停止しました。モーターに障害物がないことを確認し、機体を再起動してください",
    "ko": "랜딩 기어 모터가 멈췄습니다. 모터에 장애물이 없는지 확인 후 기체를 재시작하세요",
    "ru": "Двигатель шасси заглох. Убедитесь, что работе двигателя ничего не мешает, и перезапустите дрон",
    "tr": "İniş takımı motoru durdu. Motorun önünde engel olmadığından emin olun ve uçağı yeniden başlatın",
    "zh": "起落架舵机堵转，请检查是否有异物并重启飞行器"
  },
  "fpv_tip_0x11000042": {
    "de": "Große Stromdifferenz zwischen den Akkus. Für einen reibungslosen Flug sorgen und umgehend landen",
    "en": "Large current difference between batteries. Maintain smooth flight and land promptly",
    "es": "Gran diferencia de corriente entre las baterías. Mantén un vuelo fluido y aterriza cuanto antes",
    "fr": "Forte différence d’intensité entre les batteries. Maintenez un vol fluide et atterrissez rapidement",
    "ja": "バッテリー間の電流差が大きくなっています。スムーズな飛行を維持し、直ちに着陸してください",
    "ko": "배터리 간 큰 전류 차이. 비행을 매끄럽게 유지하고 즉시 착륙합니다",
    "ru": "Большая разница тока между аккумуляторами. Поддерживайте плавный полет и как можно скорее приземлитесь",
    "tr": "Piller arasında büyük akım farkı var. Sorunsuz uçuşu sürdürün ve derhal iniş yapın",
    "zh": "电池电流差异大，请保持平稳飞行并尽快降落"
  },
  "fpv_tip_0x11000043": {
    "de": "Motor überhitzt. Fluggerät nicht häufig überführen",
    "en": "Motor overheated. Do not transform aircraft frequently",
    "es": "Motor sobrecalentado. No transforme la aeronave con frecuencia",
    "fr": "Moteur en surchauffe. Ne transformez pas fréquemment l'appareil",
    "ja": "モーターが過熱。機体を頻繁に変形させないでください",
    "ko": "모터 과열 상태. 기체를 자주 변속하지 마세요",
    "ru": "Мотор перегрелся. Не трансформируйте дрон слишком часто",
    "tr": "Motor aşırı ısındı. Hava araçlarını sık sık değiştirmeyin",
    "zh": "舵机温度高，请避免频繁变形"
  },
  "fpv_tip_0x11000044": {
    "de": "Motor überhitzt. Überführung des Fluggeräts gestoppt. Warten, bis der Motor sich abgekühlt hat",
    "en": "Motor overheated. Transforming aircraft stopped. Wait until motor cools down",
    "es": "Motor sobrecalentado. Se ha detenido la transformación de la aeronave. Espere a que se enfríe el motor",
    "fr": "Moteur en surchauffe. La transformation de l'appareil s'est arrêtée. Attendez que le moteur refroidisse",
    "ja": "モーターが過熱。機体の変形が停止しました。モーターが冷えるまでお待ちください",
    "ko": "모터 과열 상태. 기체 변속이 중지되었습니다. 모터가 식을 때까지 기다리세요",
    "ru": "Мотор перегрелся. Трансформация дрона прервана. Подождите, пока мотор остынет",
    "tr": "Motor aşırı ısındı. Değişen hava aracı durdu. Motor soğuyana kadar bekleyin",
    "zh": "舵机温度过高，已经停止变形，请等待舵机温度降低"
  },
  "fpv_tip_0x11000045": {
    "de": "Temperatur zu niedrig. Landegestell klemmt",
    "en": "Temperature too low. Landing gear stuck",
    "es": "Temperatura demasiado baja Tren de aterrizaje atascado",
    "fr": "Température trop basse. Train d'atterrissage bloqué",
    "ja": "温度が低すぎます。ランディングギアが動かなくなっています",
    "ko": "온도 너무 낮음. 랜딩 기어가 막혔습니다",
    "ru": "Слишком низкая температура. Заедание посадочного механизма",
    "tr": "Sıcaklık çok düşük. İniş takımı sıkıştı",
    "zh": "温度过低起落架运动受阻"
  },
  "fpv_tip_0x11000046": {
    "de": "Die Gewindestange des Fluggeräts hat die maximale Lebensdauer erreicht. Um die Flugsicherheit zu gewährleisten, wende dich an den DJI-Support und sende das Fluggerät zur Wartung zurück an DJI",
    "en": "Aircraft screw rod reaching maximum life span. To ensure flight safety, contact DJI Support and send aircraft back to DJI for maintenance",
    "es": "El vástago del tornillo de la aeronave está llegando al fin de su vida útil. Para garantizar la seguridad de vuelo, ponte en contacto con la Asistencia técnica de DJI y envía la aeronave de vuelta a DJI para su mantenimiento",
    "fr": "La tige de vis de l'appareil a atteint sa durée de vie maximale. Pour garantir la sécurité pendant le vol, contactez le Service client DJI et renvoyez l'appareil à DJI à des fins de maintenance",
    "ja": "機体のスクリューロッドの耐用寿命に達しました。飛行安全性を確保するため、DJIサポートに連絡し、機体をDJIに返送してメンテナンスを受けてください",
    "ko": "기체 나사 로드가 최대 수명에 도달했습니다. 비행 안전을 보장하려면 DJI 고객지원에 연락하여 유지 보수를 위해 기체를 DJI로 다시 보내세요.",
    "ru": "Достигнут максимальный срок службы резьбового стержня дрона. Чтобы обеспечить безопасность полетов, обратитесь в службу поддержки DJI и отправьте дрон обратно в DJI для технического обслуживания",
    "tr": "Hava aracı vida çubuğu, maksimum kullanım ömrüne ulaşıyor. Uçuş güvenliğini sağlamak için DJI Destek birimiyle iletişime geçin ve bakım için hava aracını DJI'a gönderin",
    "zh": "飞行器丝杆即将达到设计使用寿命，为确保飞行安全，请您及时联系 DJI 技术支持并寄回处理"
  },
  "fpv_tip_0x11000047": {
    "de": "Die Gewindestange des Fluggeräts hat die maximale Lebensdauer überschritten. Um die Flugsicherheit zu gewährleisten, wende dich schnellstmöglich an den DJI-Support und sende das Fluggerät zur Wartung zurück an DJI. Sollte bei weiterer Nutzung des Fluggeräts ein Unfall passieren, bist du für die Wartungskosten selbst verantwortlich",
    "en": "Aircraft screw rod exceeded maximum life span. To ensure flight safety, contact DJI Support immediately and send aircraft back to DJI for maintenance. You are responsible for the maintenance cost for the flight accident caused by continued use of the aircraft",
    "es": "El vástago del tornillo de la aeronave ha superado su vida útil. Para garantizar la seguridad de vuelo, ponte en contacto inmediatamente con la Asistencia técnica de DJI y envía la aeronave de vuelta a DJI para su mantenimiento. Los gastos de mantenimiento derivados de un posible accidente de vuelo causado por el uso continuado de la aeronave correrán a cuenta del usuario",
    "fr": "La tige de vis de l'appareil a dépassé sa durée de vie maximale. Pour garantir la sécurité pendant le vol, contactez le Service client DJI immédiatement et renvoyez l'appareil à DJI à des fins de maintenance. Vous devrez assumer les frais de maintenance liés à tout accident de vol qui serait causé par la poursuite de l'utilisation de l'appareil",
    "ja": "機体のスクリューロッドが耐用寿命を超えました。飛行安全性を確保するため、DJIサポートに直ちに連絡し、機体をDJIに返送してメンテナンスを受けてください。お客様は、機体の連続使用に起因する飛行事故のメンテナンス費用を負担するものとします",
    "ko": "기체 나사 로드가 최대 수명을 초과했습니다. 비행 안전을 보장하려면 DJI 고객지원에 즉시 연락하여 유지 보수를 위해 기체를 DJI로 다시 보내세요. 기체를 계속 사용하여 발생한 비행 사고에 대한 유지 보수 비용은 사용자가 부담합니다.",
    "ru": "Превышен максимальный срок службы резьбового стержня дрона. Чтобы обеспечить безопасность полетов, незамедлительно обратитесь в службу поддержки DJI и отправьте дрон обратно в DJI для технического обслуживания. Вы оплачиваете техническое обслуживание в случае авиационного происшествия, вызванного длительной эксплуатацией дрона",
    "tr": "Hava aracı vida çubuğu, maksimum kullanım ömrünü aştı. Uçuş güvenliğini sağlamak için hemen DJI Destek birimiyle iletişime geçin ve bakım için hava aracını DJI'a gönderin. Hava aracının sürekli kullanımından kaynaklanan uçuş kazasının bakım maliyetinden siz sorumlusunuz",
    "zh": "飞行器丝杆已超出设计使用寿命，为确保飞行安全，请您立刻联系 DJI 技术支持并寄回处理，继续使用导致的飞行事故，需要您承担维修费用"
  },
  "fpv_tip_0x11000048": {
    "de": "Fehler: Landegestell. Hindernisvermeidung beeinträchtigt. Mit Vorsicht fliegen. Beim Landen des Fluggeräts auf das Objektiv achten. DJI Support kontaktieren.",
    "en": "Landing gear error. Obstacle avoidance performance degraded. Fly with caution. Pay attention to lens when aircraft is landing. Contact DJI Support",
    "es": "Error del tren de aterrizaje. Rendimiento del sistema anticolisión degradado. Vuele con cuidado. Preste atención al objetivo cuando la aeronave esté aterrizando. Póngase en contacto con la Asistencia técnica de DJI",
    "fr": "Erreur du train d’atterrissage. Performances d'évitement d'obstacle dégradées. Piloter avec précaution. Prêter attention à l'objectif lorsque l'appareil atterrit. Contacter le service client DJI",
    "ja": "ランディングギアのエラー。障害物回避性能が低下しています。慎重に飛行してください。着陸時にはレンズに注意してください。DJIサポートまでお問い合わせください",
    "ko": "랜딩 기어 오류. 장애물 회피 성능이 저하됨. 비행 시 주의 필요. 기체가 착륙할 때 렌즈에 주의하세요. DJI 고객지원으로 문의하세요.",
    "ru": "Ошибка статуса шасси. Характеристики уклонения от препятствий ухудшились. Соблюдайте осторожность при полете. Обратите внимание на объектив при посадке дрона. Свяжитесь со службой поддержки DJI",
    "tr": "İniş takımı hatası. Engellerden kaçınma performansı düştü. Dikkatlice uçurun. Hava aracı iniş yaparken lense dikkat edin. DJI Destek birimiyle iletişime geçin",
    "zh": "起落架异常，避障性能降低，请谨慎飞行，注意降落镜头保护，请联系售后"
  },
  "fpv_tip_0x11020030": {
    "ar": "",
    "de": "",
    "en": "%battery_index battery cover temperature error. Switched to single-battery flight. Return to home or land promptly",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "%battery_index电池上盖温度异常，转为单电池飞行，请尽快返航或降落",
    "zh-Hant": ""
  },
  "fpv_tip_0x11020031": {
    "ar": "",
    "de": "",
    "en": "%battery_index battery connector temperature error. Switched to single-battery flight. Return to home or land promptly",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "%battery_index电池连接器温度异常，转为单电池飞行，请尽快返航或降落",
    "zh-Hant": ""
  },
  "fpv_tip_0x11020032": {
    "ar": "",
    "de": "",
    "en": "%battery_index battery temperature error. Switched to single-battery flight. Return to home or land promptly",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "%battery_index电池温度异常，转为单电池飞行，请尽快返航或降落",
    "zh-Hant": ""
  },
  "fpv_tip_0x11020033": {
    "ar": "",
    "de": "",
    "en": "%battery_index battery error. Switched to single-battery flight. Return to home or land promptly",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "%battery_index电池异常，转为单电池飞行，请尽快返航或降落",
    "zh-Hant": ""
  },
  "fpv_tip_0x110B0001": {
    "de": "%battery_index Akku: Überstrom beim Entladen. Für einen reibungslosen Flug sorgen und überprüfen, ob das Fluggerät überladen ist",
    "en": "%battery_index Battery overcurrent when discharging. Maintain smooth flight and check if aircraft is overloaded",
    "es": "Sobrecorriente de la batería %battery_index durante la descarga. Mantenga un vuelo fluido y compruebe si la aeronave está sobrecargada",
    "fr": "Surintensité de la batterie %battery_index lors de la décharge. Maintenir un vol fluide et vérifier si l'appareil est surchargé",
    "ja": "放電時の %battery_index バッテリーの過電流。 スムーズな飛行を維持し、機体が過負荷になっていないかどうかを確認します",
    "ko": "방전 중 %battery_index 배터리 과전류 발생. 원활한 비행을 유지한 상태로 기체 과부하 여부를 확인하세요.",
    "ru": "%battery_index АКБ: перегрузка по току при разрядке. Поддерживайте плавный полет и проверяйте, не перегружен ли дрон",
    "tr": "Deşarj olurken %battery_index Pil aşırı akımı. Sorunsuz uçuşu sürdürün ve aracın aşırı yüklenip yüklenmediğini kontrol edin",
    "zh": "%battery_index电池放电过流，请保持平稳飞行并检查是否有负重"
  },
  "fpv_tip_0x110B0002": {
    "de": "%battery_index Akku: Überhitzt. Zum Startpunkt zurückkehren und warten, bis der Akku abgekühlt ist",
    "en": "%battery_index Battery overheated. Return to home promptly and wait until battery cools down",
    "es": "Batería %battery_index sobrecalentada. Regrese al punto de origen de inmediato y espere hasta que la batería se enfríe.",
    "fr": "Surchauffe batterie %battery_index. Retourner rapidement au point de départ et attendre que la batterie refroidisse",
    "ja": "%battery_index バッテリー高温。 直ちに帰還し、バッテリーが冷えるまで待ちます",
    "ko": "%battery_index 배터리 과열됨. 즉시 리턴 투 홈을 실행하여 배터리가 식을 때까지 기다려 주세요.",
    "ru": "Перегрев %battery_index АКБ. Немедленно вернитесь в домашнюю точку и подождите, пока батарея остынет",
    "tr": "%battery_index Pil aşırı ısındı. Hemen başlangıç noktasına dönün ve pil soğuyana kadar bekleyin",
    "zh": "%battery_index电池温度过高，请尽快返航，待电池冷却到常温再使用"
  },
  "fpv_tip_0x110B0003": {
    "de": "%battery_index Akku: Temperatur zu niedrig. Akku auf mindestens 5 °C erwärmen",
    "en": "%battery_index Battery temperature too low. Warm up battery to 5°C or higher",
    "es": "Temperatura de la batería %battery_index demasiado baja. La batería necesita calentarse hasta 5 °C o más",
    "fr": "Température de la batterie %battery_index trop basse. Réchauffer la batterie à 5 °C ou plus",
    "ja": "%battery_index バッテリー温度が低すぎます。 バッテリーを5℃以上に温めてください",
    "ko": "%battery_index 배터리 온도가 너무 낮음. 배터리를 5°C 이상으로 예열하세요.",
    "ru": "%battery_index АКБ: слишком низкая температура. Прогрейте АКБ до 5 °C или выше",
    "tr": "%battery_index Pil sıcaklığı çok düşük. Pili 5°C veya daha yüksek sıcaklığa kadar ısıtın",
    "zh": "%battery_index电池温度过低，请预热电池至5摄氏度以上"
  },
  "fpv_tip_0x110B0004": {
    "de": "%battery_index Akku: Kurzschluss beim Entladen. Akku austauschen",
    "en": "%battery_index Battery short-circuited during discharge. Replace battery",
    "es": "Cortocircuito de la batería %battery_index durante la descarga. Reemplace la batería.",
    "fr": "Court-circuit sur la batterie %battery_index pendant la décharge. Remplacer la batterie",
    "ja": "放電中の %battery_index バッテリー短絡。 バッテリーを交換してください",
    "ko": "%battery_index 배터리 방전 중 합선 발생. 배터리를 교체하세요.",
    "ru": "%battery_index АКБ: короткое замыкание при разрядке батареи. Замените АКБ",
    "tr": "%battery_index Pil deşarj sırasında kısa devre yaptı. Pili değiştirin",
    "zh": "%battery_index电池放电出现短路，请更换电池"
  },
  "fpv_tip_0x110B0005": {
    "de": "Spannung der Akkuzelle gering. Bitte Akku ersetzen",
    "en": "Battery %index cell voltage low. Replace battery",
    "es": "Voltaje bajo de la célula de batería %index. Reemplaza la batería",
    "fr": "Tension de la cellule de batterie faible. Remplacez la batterie",
    "ja": "低バッテリー%indexセル電圧。バッテリーを交換してください",
    "ko": "배터리 셀 전압 낮음. 배터리 교체 필요",
    "ru": "Низкое напряжение ячейки. Замените батарею",
    "tr": "Pil %index hücre voltajı düşük. Pili değiştirin",
    "zh": "电芯%index欠压，请更换电池"
  },
  "fpv_tip_0x110B0006": {
    "de": "%battery_index Akku: Zelle beschädigt. Akku nicht mehr verwenden, DJI Support kontaktieren",
    "en": "%battery_index Battery cell damaged. Stop using battery and contact DJI Support",
    "es": "Célula de batería %battery_index dañada. Deje de usar la batería y contacte con DJI Support",
    "fr": "Cellule de batterie %battery_index endommagée. Ne plus utiliser la batterie et contacter le service client DJI",
    "ja": "%battery_index バッテリーセル損傷。 バッテリーの使用を止めて DJI サポートにお問い合わせください",
    "ko": "%battery_index 배터리 셀 손상됨. 배터리 사용을 중지하고 DJI 고객지원으로 문의해주세요.",
    "ru": "%battery_index АКБ: ячейка повреждена. Прекратите использование АКБ и обратитесь в службу поддержки DJI",
    "tr": "%battery_index Pil hücresi hasarlı. Pili kullanmayı bırakın ve DJI Destek ile iletişime geçin",
    "zh": "%battery_index电池电芯损坏，请停止使用该电池，并联系售后服务"
  },
  "fpv_tip_0x110B0007": {
    "de": "%battery_index Akku: Selbsttest fehlgeschlagen. Akku nicht mehr verwenden, DJI Support kontaktieren",
    "en": "%battery_index Battery auto-check failed. Stop using battery and contact DJI Support",
    "es": "Error de comprobación automática de la batería %battery_index. Deje de usar la batería y contacte con DJI Support",
    "fr": "La vérification automatique de la batterie %battery_index a échoué. Ne plus utiliser la batterie et contacter le service client DJI",
    "ja": "%battery_index バッテリーの自動確認に失敗しました。 バッテリーの使用を止めて DJI サポートにお問い合わせください",
    "ko": "%battery_index 배터리 자체 점검 실패. 배터리 사용을 중지하고 DJI 고객지원으로 문의해주세요.",
    "ru": "%battery_index АКБ: не удалось выполнить автоматическую проверку. Прекратите использование АКБ и обратитесь в службу поддержки DJI",
    "tr": "%battery_index Pil otomatik denetimi başarısız oldu. Pili kullanmayı bırakın ve DJI Destek ile iletişime geçin",
    "zh": "%battery_index电池自检失败，请更换电池，并联系售后服务"
  },
  "fpv_tip_0x110B0008": {
    "de": "%battery_index Akku: Selbsttest fehlgeschlagen. Akku nicht mehr verwenden, DJI Support kontaktieren",
    "en": "%battery_index Battery auto-check failed. Stop using battery and contact DJI Support",
    "es": "Error de comprobación automática de la batería %battery_index. Deje de usar la batería y contacte con DJI Support",
    "fr": "La vérification automatique de la batterie %battery_index a échoué. Ne plus utiliser la batterie et contacter le service client DJI",
    "ja": "%battery_index バッテリーの自動確認に失敗しました。 バッテリーの使用を止めて DJI サポートにお問い合わせください",
    "ko": "%battery_index 배터리 자체 점검 실패. 배터리 사용을 중지하고 DJI 고객지원으로 문의해주세요.",
    "ru": "%battery_index АКБ: не удалось выполнить автоматическую проверку. Прекратите использование АКБ и обратитесь в службу поддержки DJI",
    "tr": "%battery_index Pil otomatik denetimi başarısız oldu. Pili kullanmayı bırakın ve DJI Destek ile iletişime geçin",
    "zh": "%battery_index电池自检失败，请更换电池，并联系售后服务"
  },
  "fpv_tip_0x110B0009": {
    "de": "%battery_index Akku: Selbsttest fehlgeschlagen. Akku nicht mehr verwenden, DJI Support kontaktieren",
    "en": "%battery_index Battery auto-check failed. Stop using battery and contact DJI Support",
    "es": "Error de comprobación automática de la batería %battery_index. Deje de usar la batería y contacte con DJI Support",
    "fr": "La vérification automatique de la batterie %battery_index a échoué. Ne plus utiliser la batterie et contacter le service client DJI",
    "ja": "%battery_index バッテリーの自動確認に失敗しました。 バッテリーの使用を止めて DJI サポートにお問い合わせください",
    "ko": "%battery_index 배터리 자체 점검 실패. 배터리 사용을 중지하고 DJI 고객지원으로 문의해주세요.",
    "ru": "%battery_index АКБ: не удалось выполнить автоматическую проверку. Прекратите использование АКБ и обратитесь в службу поддержки DJI",
    "tr": "%battery_index Pil otomatik denetimi başarısız oldu. Pili kullanmayı bırakın ve DJI Destek ile iletişime geçin",
    "zh": "%battery_index电池自检失败，请更换电池，并联系售后服务"
  },
  "fpv_tip_0x110B000A": {
    "de": "%battery_index Akku: Selbsttest fehlgeschlagen. Akku nicht mehr verwenden, DJI Support kontaktieren",
    "en": "%battery_index Battery auto-check failed. Stop using battery and contact DJI Support",
    "es": "Error de comprobación automática de la batería %battery_index. Deje de usar la batería y contacte con DJI Support",
    "fr": "La vérification automatique de la batterie %battery_index a échoué. Ne plus utiliser la batterie et contacter le service client DJI",
    "ja": "%battery_index バッテリーの自動確認に失敗しました。 バッテリーの使用を止めて DJI サポートにお問い合わせください",
    "ko": "%battery_index 배터리 자체 점검 실패. 배터리 사용을 중지하고 DJI 고객지원으로 문의해주세요.",
    "ru": "%battery_index АКБ: не удалось выполнить автоматическую проверку. Прекратите использование АКБ и обратитесь в службу поддержки DJI",
    "tr": "%battery_index Pil otomatik denetimi başarısız oldu. Pili kullanmayı bırakın ve DJI Destek ile iletişime geçin",
    "zh": "%battery_index电池自检失败，请更换电池，并联系售后服务"
  },
  "fpv_tip_0x110B000B": {
    "de": "%battery_index Akku: Beschädigt. Akku nicht mehr verwenden, DJI Support kontaktieren",
    "en": "%battery_index Battery damaged. Stop using battery and contact DJI Support",
    "es": "Batería %battery_index dañada. Deje de usar la batería y contacte con DJI Support",
    "fr": "Batterie %battery_index endommagée. Ne plus utiliser la batterie et contacter le service client DJI",
    "ja": "%battery_index バッテリー損傷。 バッテリーの使用を止めて DJI サポートにお問い合わせください",
    "ko": "%battery_index 배터리 손상됨. 배터리 사용을 중지하고 DJI 고객지원으로 문의해주세요.",
    "ru": "%battery_index АКБ: повреждение. Прекратите использование АКБ и обратитесь в службу поддержки DJI",
    "tr": "%battery_index Pil hasarlı. Pili kullanmayı bırakın ve DJI Destek ile iletişime geçin",
    "zh": "%battery_index电池已损坏，请停止使用该电池，并联系售后服务"
  },
  "fpv_tip_0x110B000C": {
    "de": "%battery_index Akku: Wartung erforderlich",
    "en": "%battery_index Battery maintenance required",
    "es": "La batería %battery_index requiere mantenimiento.",
    "fr": "Maintenance de la batterie %battery_index requise",
    "ja": "%battery_index バッテリーのメンテナンスが必要です",
    "ko": "%battery_index 배터리 점검 필요",
    "ru": "%battery_index АКБ: требуется обслуживание",
    "tr": "%battery_index Pil bakımı gerekiyor",
    "zh": "%battery_index电池需要保养"
  },
  "fpv_tip_0x110B000D": {
    "de": "%battery_index Akku: Beschädigt. Akku nicht mehr verwenden, DJI Support kontaktieren",
    "en": "%battery_index Battery damaged. Stop using battery and contact DJI Support",
    "es": "Batería %battery_index dañada. Deje de usar la batería y contacte con DJI Support",
    "fr": "Batterie %battery_index endommagée. Ne plus utiliser la batterie et contacter le service client DJI",
    "ja": "%battery_index バッテリー損傷。 バッテリーの使用を止めて DJI サポートにお問い合わせください",
    "ko": "%battery_index 배터리 손상됨. 배터리 사용을 중지하고 DJI 고객지원으로 문의해주세요.",
    "ru": "%battery_index АКБ: повреждение. Прекратите использование АКБ и обратитесь в службу поддержки DJI",
    "tr": "%battery_index Pil hasarlı. Pili kullanmayı bırakın ve DJI Destek ile iletişime geçin",
    "zh": "%battery_index电池已损坏，请停止使用该电池，并联系售后服务"
  },
  "fpv_tip_0x110B000E": {
    "de": "Selbstentladung während Einlagerung bei Akku %index",
    "en": "Battery %index self-discharged during storage",
    "es": "La batería %index se ha autodescargado durante el almacenamiento",
    "fr": "Décharge automatique de la batterie %index pendant le stockage",
    "ja": "保管中にバッテリー%index自己放電発生",
    "ko": "배터리 %index 보관 중 자동 방전",
    "ru": "Саморазряд батареи %index при хранении",
    "tr": "Pil %index saklama sırasında kendiliğinden boşaldı",
    "zh": "%index电池发生存储自放电"
  },
  "fpv_tip_0x110B000F": {
    "de": "%battery_index Akku: Kapazität signifikant verringert. Weitere Verwendung des Akkus kann zu Sicherheitsrisiken führen",
    "en": "%battery_index Battery capacity significantly reduced. Continue using battery may cause safety risks",
    "es": "Capacidad de la batería %battery_index significativamente reducida. Si continúa usando la batería se pueden producir riesgos de seguridad.",
    "fr": "Capacité de la batterie %battery_index considérablement réduite. La poursuite de l'utilisation de la batterie peut entraîner des risques pour la sécurité",
    "ja": "%battery_index バッテリー容量が大幅に減少しました。 バッテリーを使い続けると、安全上のリスクが発生する可能性があります",
    "ko": "%battery_index 배터리 용량 현저하게 감소됨. 배터리를 계속 사용하면 안전상의 위험이 발생할 수 있습니다.",
    "ru": "%battery_index АКБ: емкость батареи значительно уменьшена. Дальнейшее использования батареи может привести к угрозе безопасности",
    "tr": "%battery_index Pil kapasitesi önemli ölçüde azaldı. Pili kullanmaya devam etmek güvenlik risklerine neden olabilir",
    "zh": "%battery_index电池性能严重下降，继续使用有安全风险"
  },
  "fpv_tip_0x110B0010": {
    "de": "%battery_index Akku: Sicherheitsanforderungen nicht erfüllt. Akku ordnungsgemäß entsorgen",
    "en": "Safety requirements not met. Dispose of %battery_index Battery properly",
    "es": "No se cumplen los requisitos de seguridad. Elimine la batería %battery_index correctamente",
    "fr": "Exigences de sécurité non respectées. Jeter correctement la batterie %battery_index",
    "ja": "安全性要件が満たされていません。 %battery_index バッテリーを適切に廃棄してください",
    "ko": "안전 요구사항 충족하지 않음. 올바른 방법으로 %battery_index 배터리를 폐기하세요.",
    "ru": "Требования безопасности не соблюдены. Утилизируйте %battery_index АКБ надлежащим образом",
    "tr": "Güvenlik gereksinimleri karşılanmadı. %battery_index Pili doğru şekilde atın",
    "zh": "超出安全条件使用，请报废%battery_index电池"
  },
  "fpv_tip_0x110B0011": {
    "de": "%battery_index Akku: Kommunikationsfehler. Akku neu einsetzen. Akku ersetzen, wenn das Problem weiterhin besteht",
    "en": "%battery_index Battery data communication error. Reinstall battery. Replace battery if issue persists",
    "es": "Error de comunicación de la batería %battery_index. Vuelva a instalar la batería. Si el problema continúa, reemplace la batería.",
    "fr": "Erreur de communication des données de la batterie %battery_index. Réinstaller la batterie. Remplacer la batterie si le problème persiste",
    "ja": "%battery_index バッテリーデータ通信エラー。 バッテリーを再度取り付けてください。 問題が解決しない場合は、バッテリーを交換してください",
    "ko": "%battery_index 배터리 데이터 통신 오류. 배터리를 다시 장착하세요. 문제가 지속되면 배터리를 교체하세요.",
    "ru": "%battery_index АКБ: ошибка передачи данных. Заново установите АКБ. Замените батарею, если проблема не устранена",
    "tr": "%battery_index Pil verileri iletişim hatası. Pili yeniden takın. Sorun devam ederse pili değiştirin",
    "zh": "%battery_index电池数据通信异常，请重新安装电池，如果未解决请更换电池"
  },
  "fpv_tip_0x110B0012": {
    "de": "%battery_index Akku: Wartung erforderlich",
    "en": "%battery_index Battery maintenance required",
    "es": "La batería %battery_index requiere mantenimiento.",
    "fr": "Maintenance de la batterie %battery_index requise",
    "ja": "%battery_index バッテリーのメンテナンスが必要です",
    "ko": "%battery_index 배터리 점검 필요",
    "ru": "%battery_index АКБ: требуется обслуживание",
    "tr": "%battery_index Pil bakımı gerekiyor",
    "zh": "%battery_index电池需要保养"
  },
  "fpv_tip_0x110B0013": {
    "de": "%battery_index Akkuschacht: Kein Akku erkannt. Akku einsetzen oder ersetzen",
    "en": "Battery not detected in %battery_index Battery slot. Insert or replace battery",
    "es": "No se ha detectado la batería en la ranura de la batería %battery_index. Instale o reemplace la batería.",
    "fr": "Batterie non détectée dans le logement de batterie %battery_index. Insérer ou remplacer la batterie",
    "ja": "%battery_index バッテリースロットでバッテリーが検出されませんでした。 バッテリーを挿入または交換します",
    "ko": "%battery_index 배터리 슬롯에서 배터리가 감지되지 않음. 배터리를 삽입하거나 교체하세요.",
    "ru": "Батарея не обнаружена в слоте %battery_index АКБ. Вставьте или замените АКБ",
    "tr": "Pil %battery_index Pil yuvasında algılanmadı. Pili takın veya değiştirin",
    "zh": "%battery_index电池槽没有电池，请插入电池或者更换电池"
  },
  "fpv_tip_0x110B0013_in_the_sky": {
    "de": "%battery_index Akkuschacht: Kein Akku erkannt. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "Battery not detected in %battery_index Battery slot. Return to home or land promptly",
    "es": "No se ha detectado la batería en la ranura de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Batterie non détectée dans le logement de batterie %battery_index. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリースロットでバッテリーが検出されませんでした。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 슬롯에서 배터리가 감지되지 않음. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "Батарея не обнаружена в слоте %battery_index АКБ. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil yuvasında algılanmadı. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池槽检测不到电池，请尽快返航或降落"
  },
  "fpv_tip_0x110B0014": {
    "de": "%battery_index Akku: Selbsterwärmung",
    "en": "%battery_index Battery self-heating",
    "es": "Autocalentamiento de la batería %battery_index.",
    "fr": "Auto-chauffe de la batterie %battery_index",
    "it": "",
    "ja": "%battery_index バッテリーの自己発熱",
    "ko": "%battery_index 배터리 자체 발열",
    "pt": "",
    "ru": "%battery_index АКБ: самонагрев",
    "tr": "%battery_index Pil kendi kendine ısınıyor",
    "zh": "%battery_index电池在自加热"
  },
  "fpv_tip_0x110B0015": {
    "de": "%battery_index Akku: Wartung zur Sicherstellung der Flugsicherheit notwendig",
    "en": "%battery_index Battery maintenance required to ensure flight safety",
    "es": "La batería %battery_index requiere mantenimiento para garantizar la seguridad de vuelo.",
    "fr": "Maintenance batterie %battery_index requise pour la sécurité en vol",
    "ja": "飛行安全性を確保するために %battery_index バッテリーのメンテナンスが必要です",
    "ko": "비행 안전을 위해 %battery_index 배터리 점검 필요",
    "ru": "%battery_index АКБ: для безопасности полета требуется техобслуживание",
    "tr": "Uçuş güvenliğini sağlamak için %battery_index Pil bakımı gerekli",
    "zh": "%battery_index电池需要保养才能正常飞行"
  },
  "fpv_tip_0x110B0016": {
    "de": "%battery_index Akku: Wartung zur Sicherstellung der Flugsicherheit notwendig",
    "en": "%battery_index Battery maintenance required to ensure flight safety",
    "es": "La batería %battery_index requiere mantenimiento para garantizar la seguridad de vuelo.",
    "fr": "Maintenance batterie %battery_index requise pour la sécurité en vol",
    "ja": "飛行安全性を確保するために %battery_index バッテリーのメンテナンスが必要です",
    "ko": "비행 안전을 위해 %battery_index 배터리 점검 필요",
    "ru": "%battery_index АКБ: для безопасности полета требуется техобслуживание",
    "tr": "Uçuş güvenliğini sağlamak için %battery_index Pil bakımı gerekli",
    "zh": "%battery_index电池需要保养才能正常飞行"
  },
  "fpv_tip_0x110B0017": {
    "de": "%battery_index Akku: Wartung zur Sicherstellung der Flugsicherheit notwendig",
    "en": "%battery_index Battery maintenance required to ensure flight safety",
    "es": "La batería %battery_index requiere mantenimiento para garantizar la seguridad de vuelo.",
    "fr": "Maintenance batterie %battery_index requise pour la sécurité en vol",
    "ja": "飛行安全性を確保するために %battery_index バッテリーのメンテナンスが必要です",
    "ko": "비행 안전을 위해 %battery_index 배터리 점검 필요",
    "ru": "%battery_index АКБ: для безопасности полета требуется техобслуживание",
    "tr": "Uçuş güvenliğini sağlamak için %battery_index Pil bakımı gerekli",
    "zh": "%battery_index电池需要保养才能正常飞行"
  },
  "fpv_tip_0x110B0018": {
    "de": "%battery_index Akku: Wartung zur Sicherstellung der Flugsicherheit notwendig",
    "en": "%battery_index Battery maintenance required to ensure flight safety",
    "es": "La batería %battery_index requiere mantenimiento para garantizar la seguridad de vuelo.",
    "fr": "Maintenance batterie %battery_index requise pour la sécurité en vol",
    "ja": "飛行安全性を確保するために %battery_index バッテリーのメンテナンスが必要です",
    "ko": "비행 안전을 위해 %battery_index 배터리 점검 필요",
    "ru": "%battery_index АКБ: для безопасности полета требуется техобслуживание",
    "tr": "Uçuş güvenliğini sağlamak için %battery_index Pil bakımı gerekli",
    "zh": "%battery_index电池需要保养才能正常飞行"
  },
  "fpv_tip_0x110B0019": {
    "de": "%battery_index Akku: Wartung zur Sicherstellung der Flugsicherheit notwendig",
    "en": "%battery_index Battery maintenance required to ensure flight safety",
    "es": "La batería %battery_index requiere mantenimiento para garantizar la seguridad de vuelo.",
    "fr": "Maintenance batterie %battery_index requise pour la sécurité en vol",
    "ja": "飛行安全性を確保するために %battery_index バッテリーのメンテナンスが必要です",
    "ko": "비행 안전을 위해 %battery_index 배터리 점검 필요",
    "ru": "%battery_index АКБ: для безопасности полета требуется техобслуживание",
    "tr": "Uçuş güvenliğini sağlamak için %battery_index Pil bakımı gerekli",
    "zh": "%battery_index电池需要保养才能正常飞行"
  },
  "fpv_tip_0x110B001A": {
    "de": "%battery_index Akku: Wartung zur Sicherstellung der Flugsicherheit notwendig",
    "en": "%battery_index Battery maintenance required to ensure flight safety",
    "es": "La batería %battery_index requiere mantenimiento para garantizar la seguridad de vuelo.",
    "fr": "Maintenance batterie %battery_index requise pour la sécurité en vol",
    "ja": "飛行安全性を確保するために %battery_index バッテリーのメンテナンスが必要です",
    "ko": "비행 안전을 위해 %battery_index 배터리 점검 필요",
    "ru": "%battery_index АКБ: для безопасности полета требуется техобслуживание",
    "tr": "Uçuş güvenliğini sağlamak için %battery_index Pil bakımı gerekli",
    "zh": "%battery_index电池需要保养才能正常飞行"
  },
  "fpv_tip_0x110B001B": {
    "de": "%battery_index Akku: Wartung zur Sicherstellung der Flugsicherheit notwendig",
    "en": "%battery_index Battery maintenance required to ensure flight safety",
    "es": "La batería %battery_index requiere mantenimiento para garantizar la seguridad de vuelo.",
    "fr": "Maintenance batterie %battery_index requise pour la sécurité en vol",
    "ja": "飛行安全性を確保するために %battery_index バッテリーのメンテナンスが必要です",
    "ko": "비행 안전을 위해 %battery_index 배터리 점검 필요",
    "ru": "%battery_index АКБ: для безопасности полета требуется техобслуживание",
    "tr": "Uçuş güvenliğini sağlamak için %battery_index Pil bakımı gerekli",
    "zh": "%battery_index电池需要保养才能正常飞行"
  },
  "fpv_tip_0x110B001C": {
    "de": "Akkus stimmen nicht überein. Passende Akkus einsetzen",
    "en": "Batteries do not match. Replace with matching batteries",
    "es": "Las baterías no coinciden. Reemplázalas por baterías iguales",
    "fr": "Les batteries ne correspondent pas. Remplacez-les par des batteries qui se correspondent",
    "ja": "バッテリーが一致しません。一致するバッテリーと交換してください",
    "ko": "배터리가 일치하지 않습니다. 일치하는 배터리로 교체하세요",
    "ru": "Аккумуляторы не подходят. Замените их на соответствующие",
    "tr": "Piller eşleşmiyor. Eşleşen pillerle değiştirin",
    "zh": "电池不匹配，建议更换匹配电池"
  },
  "fpv_tip_0x110B001D": {
    "de": "%battery_index Akku: Entladefehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery discharge error. Return to home or land promptly",
    "es": "Error de descarga de batería %battery_index. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Erreur de décharge de la batterie %battery_index. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー放電エラー。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 방전 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: ошибка разрядки. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil deşarj hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "电池槽%battery_index电池放电异常，请尽快返航或降落"
  },
  "fpv_tip_0x110B001E": {
    "de": "%battery_index Akku: Verbindungsfehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery connection error. Return to home or land promptly",
    "es": "Error de conexión de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Erreur de connexion de la batterie %battery_index. Retourner au point de départ ou atterrir rapidement",
    "it": "",
    "ja": "%battery_index バッテリー接続エラー。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 연결 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "pt": "",
    "ru": "%battery_index АКБ: ошибка подключения. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil bağlantı hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "电池槽%battery_index电池接触不良，请尽快返航或降落"
  },
  "fpv_tip_0x110B007": {
    "de": "",
    "en": "Battery %d% auto check failed. Replace battery and contact DJI Support",
    "es": "",
    "fr": "Vérification auto de batterie %d% impossible. Remplacez la batterie et contactez l'assistance technique DJI",
    "ja": "",
    "ko": "",
    "ru": "",
    "tr": "",
    "zh": "电池%d%自检失败，请更换电池，并联系售后服务"
  },
  "fpv_tip_0x110B0403": {
    "ar": "",
    "de": "",
    "en": "Incompatible battery firmware versions. Update firmware",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "双电池版本不一致，请更新电池固件版本",
    "zh-Hant": ""
  },
  "fpv_tip_0x110b001e": {
    "de": "%battery_index Akku: Verbindungsfehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery connection error. Return to home or land promptly",
    "es": "Error de conexión de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Erreur de connexion de la batterie %battery_index. Retourner au point de départ ou atterrir rapidement",
    "it": "",
    "ja": "%battery_index バッテリー接続エラー。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 연결 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "pt": "",
    "ru": "%battery_index АКБ: ошибка подключения. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil bağlantı hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "电池槽%battery_index电池接触不良，请尽快返航或降落"
  },
  "fpv_tip_0x12000000": {
    "de": "Battery Station nicht aktiviert",
    "en": "Battery Station not activated",
    "es": "Estación de Baterías no activada",
    "fr": "Station de batteries non activée",
    "ja": "バッテリーステーションが有効化されていません",
    "ko": "배터리 스테이션이 활성화되지 않음",
    "ru": "Зарядная станция не активирована",
    "tr": "Pil İstasyonu etkin değil",
    "zh": "电池箱未激活"
  },
  "fpv_tip_0x12000001": {
    "de": "Fehler: Battery Station-Selbsttest",
    "en": "Battery Station auto check error",
    "es": "Error de comprobación automática de la Estación de Baterías",
    "fr": "Erreur de vérification automatique de la station de batteries",
    "ja": "バッテリーステーション自動確認エラー",
    "ko": "배터리 스테이션 자동 점검 오류",
    "ru": "Ошибка автоматической проверки зарядной станции",
    "tr": "Pil İstasyonu otomatik kontrol hatası",
    "zh": "电池箱自检异常"
  },
  "fpv_tip_0x12000002": {
    "de": "Kommunikationsfehler: Battery Station-Erweiterungskarte",
    "en": "Battery Station expansion card communication error",
    "es": "Error de comunicación de la tarjeta de expansión de la Estación de Baterías",
    "fr": "Erreur de communication avec la carte d'extension de la station de batteries",
    "ja": "バッテリーステーション拡張カード通信エラー",
    "ko": "배터리 스테이션 확장 카드 통신 오류",
    "ru": "Ошибка связи карты расширения зарядной станции",
    "tr": "Pil İstasyonu genişletme kartı iletişim hatası",
    "zh": "电池箱拓展板通信异常"
  },
  "fpv_tip_0x12010000": {
    "de": "Stromversorgungsmodul nicht erkannt",
    "en": "Power Supply Module Not Recognized",
    "es": "Módulo de fuente de alimentación no reconocido",
    "fr": "Module d'alimentation non reconnu",
    "ja": "電源モジュールが認識されていません",
    "ko": "전력 공급 모듈이 인식되지 않음",
    "ru": "Модуль питания не распознан",
    "tr": "Güç Kaynağı Modülü Tanınmıyor",
    "zh": "电源模块无法识别"
  },
  "fpv_tip_0x12010001": {
    "de": "Akkumodul-Eingangsspannung zu hoch",
    "en": "Battery module input voltage too high",
    "es": "Voltaje de entrada del módulo de batería demasiado alto",
    "fr": "Tension d'entrée du module de batterie excessive",
    "ja": "バッテリーモジュール入力高電圧",
    "ko": "배터리 모듈 입력 전압이 너무 높음",
    "ru": "Слишком высокое входное напряжение модуля аккумулятора",
    "tr": "Pil modülü giriş gerilimi çok yüksek",
    "zh": "电源模块输入电压过高"
  },
  "fpv_tip_0x12010002": {
    "de": "Akkumodul-Eingangsspannung zu niedrig",
    "en": "Battery module input voltage too low",
    "es": "Voltaje de entrada del módulo de batería demasiado bajo",
    "fr": "Tension d'entrée du module de batterie insuffisante",
    "ja": "バッテリーモジュール入力低電圧",
    "ko": "배터리 모듈 입력 전압이 너무 낮음",
    "ru": "Слишком низкое входное напряжение модуля аккумулятора",
    "tr": "Pil modülü giriş gerilimi çok düşük",
    "zh": "电源模块输入电压过低"
  },
  "fpv_tip_0x12010003": {
    "de": "Fehler: Akkumodul-Ausgangsspannung",
    "en": "Battery module output voltage error",
    "es": "Error de voltaje de salida del módulo de batería",
    "fr": "Erreur de tension de sortie du module de batterie",
    "ja": "バッテリーモジュール出力電圧エラー",
    "ko": "배터리 모듈 출력 전압 오류",
    "ru": "Ошибка выходного напряжения модуля аккумулятора",
    "tr": "Pil modülü çıkış gerilimi hatası",
    "zh": "电源模块输出电压异常"
  },
  "fpv_tip_0x12010004": {
    "de": "Fehler: Akkumodul-Ausgangsstrom",
    "en": "Battery module output current error",
    "es": "Error de corriente de salida del módulo de batería",
    "fr": "Erreur de courant de sortie du module de batterie",
    "ja": "バッテリーモジュール出力電流エラー",
    "ko": "배터리 모듈 출력 전류 오류",
    "ru": "Ошибка выходного тока модуля аккумулятора",
    "tr": "Pil modülü çıkış akımı hatası",
    "zh": "电源模块输出电流异常"
  },
  "fpv_tip_0x12010005": {
    "de": "Kurzschluss am Akkumodul",
    "en": "Battery module short-circuited",
    "es": "Módulo de batería cortocircuitado",
    "fr": "Module de batterie court-circuité",
    "ja": "バッテリーモジュールがショート",
    "ko": "배터리 모듈 단락",
    "ru": "Короткое замыкание модуля аккумулятора",
    "tr": "Pil modülü kısa devre yaptı",
    "zh": "电源模块发生短路"
  },
  "fpv_tip_0x12010006": {
    "de": "Kommunikationsfehler: Akkumodul",
    "en": "Battery module communication error",
    "es": "Error de comunicación del módulo de batería",
    "fr": "Erreur de communication avec le module de batterie",
    "ja": "バッテリーモジュール通信エラー",
    "ko": "배터리 모듈 통신 오류",
    "ru": "Ошибка связи модуля аккумулятора",
    "tr": "Pil modülü iletişim hatası",
    "zh": "电源模块通信异常"
  },
  "fpv_tip_0x12010007": {
    "de": "Fehlfunktion: Akkumodul-Lüfter",
    "en": "Battery module fan malfunctioned",
    "es": "Error de funcionamiento del ventilador del módulo de batería",
    "fr": "Dysfonctionnement du ventilateur du module de batterie",
    "ja": "バッテリーモジュールファン不良",
    "ko": "배터리 모듈 팬 오작동",
    "ru": "Неисправность вентилятора модуля аккумулятора",
    "tr": "Pil modülü fanı arızalı",
    "zh": "电源模块风扇故障"
  },
  "fpv_tip_0x12010008": {
    "de": "Akkumodul-Temperatur zu hoch",
    "en": "Battery module temperature too high",
    "es": "Temperatura del módulo de batería demasiado alta",
    "fr": "Température du module de batterie excessive",
    "ja": "バッテリーモジュールの温度が高すぎます",
    "ko": "배터리 모듈 온도가 너무 높음",
    "ru": "Слишком высокая температура модуля аккумулятора",
    "tr": "Pil modülü sıcaklığı çok yüksek",
    "zh": "电源模块温度过高"
  },
  "fpv_tip_0x12020000": {
    "de": "Kommunikationsfehler: Akku in Akkuschacht %index",
    "en": "Battery port %index battery communication error",
    "es": "Error de comunicación de la batería en el puerto de batería %index",
    "fr": "Erreur de communication avec la batterie du port de batterie %index",
    "ja": "バッテリーポート %index バッテリー通信エラー",
    "ko": "배터리 포트 %index 배터리 통신 오류",
    "ru": "Ошибка связи с аккумулятором (порт аккумулятора %index)",
    "tr": "Pil portu %index pilinde iletişim hatası",
    "zh": "%index槽位电池通讯异常"
  },
  "fpv_tip_0x12020001": {
    "de": "Temperatur von Akku in Akkuschacht %index zu niedrig",
    "en": "Battery port %index battery temperature too low",
    "es": "Temperatura de la batería en el puerto de batería %index demasiado baja",
    "fr": "Température de batterie insuffisante dans le port de batterie %index",
    "ja": "バッテリーポート %index バッテリー低温",
    "ko": "배터리 포트 %index 배터리 온도가 너무 낮음",
    "ru": "Слишком низкая температура аккумулятора (порт аккумулятора %index)",
    "tr": "Pil portu %index pilinin sıcaklığı çok düşük",
    "zh": "%index槽位电池温度过低"
  },
  "fpv_tip_0x12020002": {
    "de": "Akku in Akkuschacht %index überhitzt",
    "en": "Battery port %index battery overheated",
    "es": "Batería en el puerto de batería %index sobrecalentada",
    "fr": "Surchauffe batterie dans le port de batterie %index",
    "ja": "バッテリーポート %index バッテリー高温",
    "ko": "배터리 포트 %index 배터리 과열",
    "ru": "Аккумулятор перегрелся (порт аккумулятора %index)",
    "tr": "Pil portu %index pili aşırı ısındı",
    "zh": "%index槽位电池温度过高"
  },
  "fpv_tip_0x12020003": {
    "de": "Spannung von Akkuzelle in Akkuschacht %index zu hoch",
    "en": "Battery port %index battery cell voltage too high",
    "es": "Voltaje de la célula de la batería en el puerto de batería %index demasiado alto",
    "fr": "Tension de cellule de batterie excessive dans le port de batterie %index",
    "ja": "バッテリーポート %index バッテリーセル高電圧",
    "ko": "배터리 포트 %index 배터리 셀 전압이 너무 높음",
    "ru": "Слишком высокое напряжение элемента аккумулятора (порт аккумулятора %index)",
    "tr": "Pil portu %index pil hücresi gerilimi çok yüksek",
    "zh": "%index槽位电池电芯过压"
  },
  "fpv_tip_0x12020004": {
    "de": "Systemfehler: Akku in Akkuschacht %index",
    "en": "Battery port %index battery system error",
    "es": "Error del sistema de la batería en el puerto de batería %index",
    "fr": "Erreur système batterie dans le port de batterie %index",
    "ja": "バッテリーポート %index バッテリーシステムエラー",
    "ko": "배터리 포트 %index 배터리 시스템 오류",
    "ru": "Ошибка системы аккумулятора (порт аккумулятора %index)",
    "tr": "Pil portu %index pilinde sistem hatası",
    "zh": "%index槽位电池系统异常"
  },
  "fpv_tip_0x12020005": {
    "de": "Überstrom beim Aufladen des Akkus in Akkuschacht %index",
    "en": "Battery port %index battery overcurrent during charging",
    "es": "Sobreintensidad en la batería en el puerto de batería %index durante la carga",
    "fr": "Surtension de la batterie en charge dans le port de batterie %index",
    "ja": "バッテリーポート %index 充電中のバッテリー過電流",
    "ko": "충전 중 배터리 포트 %index 배터리 과전류",
    "ru": "Перегрузка аккумулятора по току во время зарядки (порт аккумулятора %index)",
    "tr": "Pil portu %index pilinde şarj sırasında aşırı akım",
    "zh": "%index槽位电池充电过流"
  },
  "fpv_tip_0x12020006": {
    "de": "Spannung von Akku in Akkuschacht %index zu hoch",
    "en": "Battery port %index battery voltage too high",
    "es": "Voltaje de la batería en el puerto de batería %index demasiado alto",
    "fr": "Tension de batterie excessive dans le port de batterie %index",
    "ja": "バッテリーポート %index バッテリー高電圧",
    "ko": "배터리 포트 %index 배터리 전압이 너무 높음",
    "ru": "Слишком высокое напряжение аккумулятора (порт аккумулятора %index)",
    "tr": "Pil portu %index pilinin gerilimi çok yüksek",
    "zh": "%index槽位电池电压过高"
  },
  "fpv_tip_0x12020007": {
    "de": "Spannung beim Aufladen von Akku in Akkuschacht %index zu hoch",
    "en": "Battery port %index battery voltage too high during charging",
    "es": "Voltaje de la batería en el puerto de batería %index demasiado alto durante la carga",
    "fr": "Tension excessive de la batterie en charge dans le port de batterie %index",
    "ja": "バッテリーポート %index 充電中のバッテリー高電圧",
    "ko": "충전 중 배터리 포트 %index 배터리 전압이 너무 높음",
    "ru": "Слишком высокое напряжение аккумулятора во время зарядки (порт аккумулятора %index)",
    "tr": "Pil portu %index pilinin gerilimi şarj sırasında çok yüksek",
    "zh": "%index槽位电池充电过压"
  },
  "fpv_tip_0x12020008": {
    "de": "Spannung beim Aufladen von Akku in Akkuschacht %index zu niedrig",
    "en": "Battery port %index battery voltage too low during charging",
    "es": "Voltaje de la batería en el puerto de batería %index demasiado bajo durante la carga",
    "fr": "Tension insuffisante de la batterie en charge dans le port de batterie %index",
    "ja": "バッテリーポート %index 充電中のバッテリー低電圧",
    "ko": "충전 중 배터리 포트 %index 배터리 전압이 너무 낮음",
    "ru": "Слишком низкое напряжение аккумулятора во время зарядки (порт аккумулятора %index)",
    "tr": "Pil portu %index pilinin gerilimi şarj sırasında çok düşük",
    "zh": "%index槽位电池充电欠压"
  },
  "fpv_tip_0x12020009": {
    "de": "Akku in Akkuschacht %index dauerhaft beschädigt",
    "en": "Battery port %index battery permanently damaged",
    "es": "Batería en el puerto de batería %index dañada de forma permanente",
    "fr": "Batterie endommagée de manière permanente dans le port de batterie %index",
    "ja": "バッテリーポート %index バッテリーが恒久的に損傷",
    "ko": "배터리 포트 %index 배터리가 영구 손상됨",
    "ru": "Аккумулятор поврежден без возможности восстановления (порт аккумулятора %index)",
    "tr": "Pil portu %index pili kalıcı hasar gördü",
    "zh": "%index槽位电池永久损坏"
  },
  "fpv_tip_0x1202000A": {
    "de": "Kurzschluss am Akku in Akkuschacht %index",
    "en": "Battery port %index battery short-circuited",
    "es": "Batería en el puerto de batería %index cortocircuitada",
    "fr": "Batterie court-circuitée dans le port de batterie %index",
    "ja": "バッテリーポート %index バッテリーがショート",
    "ko": "배터리 포트 %index 배터리 단락",
    "ru": "Короткое замыкание аккумулятора (порт аккумулятора %index)",
    "tr": "Pil portu %index pili kısa devre yaptı",
    "zh": "%index槽位电池短路"
  },
  "fpv_tip_0x1202000B": {
    "de": "Aufladen des Akkus in Akkuschacht %index dauert zu lange",
    "en": "Battery port %index battery charging taking too long",
    "es": "La carga de la batería en el puerto de batería %index está tardando demasiado",
    "fr": "Charge trop lente dans le port de batterie %index",
    "ja": "バッテリーポート %index バッテリー充電が長すぎます",
    "ko": "배터리 포트 %index 배터리 충전이 너무 오래 걸림",
    "ru": "Зарядка аккумулятора занимает слишком долго (порт аккумулятора %index)",
    "tr": "Pil portu %index pilinin şarj olması çok uzun sürüyor",
    "zh": "%index槽位电池充电时间过长"
  },
  "fpv_tip_0x1202000C": {
    "de": "Beim Aufladen kein Strom für Akku in Akkuschacht %index erkannt",
    "en": "No current detected for battery port %index battery during charging",
    "es": "No se ha detectado corriente en la batería en el puerto de batería %index durante la carga",
    "fr": "Aucun courant détecté pour la batterie en charge dans le port de batterie %index",
    "ja": "バッテリーポート %index バッテリーの充電中に電流が検出されません",
    "ko": "충전 중 배터리 포트 %index 배터리에 대한 전류가 감지되지 않음",
    "ru": "На порте аккумулятора %index не обнаружен ток во время зарядки аккумулятора",
    "tr": "Pil portu %index pili için şarj sırasında tespit edilen akım yok",
    "zh": "%index槽位电池无充电电流"
  },
  "fpv_tip_0x1202000D": {
    "de": "Akkutyp in Akkuschacht %index nicht erkannt",
    "en": "Battery port %index battery type not recognized",
    "es": "Tipo de la batería en el puerto de batería %index no reconocido",
    "fr": "Type de batterie non reconnu dans le port de batterie %index",
    "ja": "バッテリーポート %index バッテリータイプが認識されません",
    "ko": "배터리 포트 %index 배터리 유형이 인식되지 않음",
    "ru": "Тип аккумулятора не распознан (порт аккумулятора %index)",
    "tr": "Pil portu %index pilinin tipi tanınmadı",
    "zh": "%index槽位电池类型无法识别"
  },
  "fpv_tip_0x12030000": {
    "de": "Battery Station-Motherboard überhitzt",
    "en": "Battery Station motherboard overheated",
    "es": "Placa base de la Estación de Baterías sobrecalentada",
    "fr": "Surchauffe de la carte mère de la station de batteries",
    "ja": "バッテリーステーションマザーボード高温",
    "ko": "배터리 스테이션 마더보드 과열",
    "ru": "Перегрев материнской платы зарядной станции",
    "tr": "Pil İstasyonu ana kartı aşırı ısındı",
    "zh": "电池箱主板温度过高"
  },
  "fpv_tip_0x12030001": {
    "de": "Spannung von Battery Station-Motherboard zu hoch",
    "en": "Battery Station motherboard voltage too high",
    "es": "Voltaje de la placa base de la Estación de Baterías demasiado alto",
    "fr": "Tension de la carte mère de la station de batteries excessive",
    "ja": "バッテリーステーションマザーボード高電圧",
    "ko": "배터리 스테이션 마더보드 전압이 너무 높음",
    "ru": "Слишком высокое напряжение на материнской плате зарядной станции",
    "tr": "Pil İstasyonu ana kartı gerilimi çok yüksek",
    "zh": "电池箱主板电压过高"
  },
  "fpv_tip_0x12030002": {
    "de": "Spannung von Battery Station-Motherboard zu niedrig",
    "en": "Battery Station motherboard voltage too low",
    "es": "Voltaje de la placa base de la Estación de Baterías demasiado bajo",
    "fr": "Tension de la carte mère de la station de batteries insuffisante",
    "ja": "バッテリーステーションマザーボード低電圧",
    "ko": "배터리 스테이션 마더보드 전압이 너무 낮음",
    "ru": "Слишком низкое напряжение на материнской плате зарядной станции",
    "tr": "Pil İstasyonu ana kartı gerilimi çok düşük",
    "zh": "电池箱主板电压过低"
  },
  "fpv_tip_0x12030003": {
    "de": "Fehler: Battery Station-Schacht %index",
    "en": "Battery Station port %index error",
    "es": "Error del puerto %index de la Estación de Baterías",
    "fr": "Erreur liée au port %index de la station de batteries",
    "ja": "バッテリーステーションポート %index エラー",
    "ko": "배터리 스테이션 포트 %index 오류",
    "ru": "Ошибка порта %index зарядной станции",
    "tr": "Pil İstasyonu portu %index hatası",
    "zh": "电池箱%index槽位充电通道异常"
  },
  "fpv_tip_0x12030004": {
    "ar": "",
    "de": "",
    "en": "AC Input Voltage Drop Too Large",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "交流输入压降过大",
    "zh-Hant": ""
  },
  "fpv_tip_0x12030005": {
    "ar": "",
    "de": "",
    "en": "Oil Maintenance Tips",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "机油保养提示",
    "zh-Hant": ""
  },
  "fpv_tip_0x12030006": {
    "ar": "",
    "de": "",
    "en": "Charging Device Controller Board Output Voltage Error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "管家板输出电压异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x12030007": {
    "ar": "",
    "de": "",
    "en": "Controller Board of Charging Device Input Voltage Error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "管家板输入电压异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x12120000": {
    "de": "Kommunikationsfehler: Fernsteuerungs-Akku in Akkuschacht %index",
    "en": "Battery port %index remote controller battery communication error",
    "es": "Error de comunicación de la batería del control remoto en el puerto de batería %index",
    "fr": "Erreur de communication avec la batterie de la radiocommande dans le port de batterie %index",
    "ja": "バッテリーポート %index 送信機バッテリー通信エラー",
    "ko": "배터리 포트 %index 조종기 배터리 통신 오류",
    "ru": "Ошибка связи с аккумулятором пульта управления (порт аккумулятора %index)",
    "tr": "Pil portu %index uzaktan kumanda pilinde iletişim hatası",
    "zh": "%index槽位遥控器电池通信异常"
  },
  "fpv_tip_0x12120001": {
    "de": "Temperaturfehler: Fernsteuerungs-Akku in Akkuschacht %index",
    "en": "Battery port %index remote controller battery temperature error",
    "es": "Error de temperatura de la batería del control remoto en el puerto de batería %index",
    "fr": "Erreur de température de la batterie de la radiocommande dans le port de batterie %index",
    "ja": "バッテリーポート %index 送信機バッテリー温度エラー",
    "ko": "배터리 포트 %index 조종기 배터리 온도 오류",
    "ru": "Ошибка температуры аккумулятора пульта управления (порт аккумулятора %index)",
    "tr": "Pil portu %index uzaktan kumanda pilinde sıcaklık hatası",
    "zh": "%index槽位遥控器电池温度异常"
  },
  "fpv_tip_0x12120002": {
    "de": "Ladefehler: Fernsteuerungs-Akku in Akkuschacht %index",
    "en": "Battery port %index remote controller battery charging error",
    "es": "Error de carga de la batería del control remoto en el puerto de batería %index",
    "fr": "Erreur de charge de la batterie de la radiocommande dans le port de batterie %index",
    "ja": "バッテリーポート %index 送信機バッテリー充電エラー",
    "ko": "배터리 포트 %index 조종기 배터리 충전 오류",
    "ru": "Ошибка зарядки аккумулятора пульта управления (порт аккумулятора %index)",
    "tr": "Pil portu %index uzaktan kumanda pilinde şarj hatası",
    "zh": "%index槽位遥控器电池充电异常"
  },
  "fpv_tip_0x14010031": {
    "de": "Nutzlast-Datenkonsolidierungsfehler. Nutzlast neu starten",
    "en": "Payload infusion data error. Restart payload",
    "es": "Error de datos de infusión del instrumento. Reinicia el instrumento",
    "fr": "Erreur de données d\\'infusion charge utile. Redémarrer la charge utile",
    "ja": "ペイロード融合データエラー。ペイロードを再起動してください",
    "ko": "페이로드 인퓨전 데이터 오류. 페이로드 재시작 필요",
    "ru": "Предупреждение о жизненном цикле лидара",
    "tr": "Yük infüzyon verisi hatası. Yükü yeniden başlatın",
    "zh": "负载融合数据异常，请重启负载"
  },
  "fpv_tip_0x14010032": {
    "de": "Nutzlast-Datenkonsolidierung kann nicht abgeschlossen werden",
    "en": "Unable to converge payload infusion data",
    "es": "No se pueden juntar los datos de infusión del instrumento",
    "fr": "Impossible de rassembler les données d\\'infusion de la charge utile",
    "ja": "ペイロード融合データを収束できません",
    "ko": "페이로드 인퓨전 데이터 수렴 불가",
    "ru": "Не удалось объединить данные полезной нагрузки",
    "tr": "Yük infüzyon verisi yakınsanamadı",
    "zh": "负载融合数据未收敛"
  },
  "fpv_tip_0x14010033": {
    "de": "Nutzlast-Systemzeitfehler. Fluggerät neu starten",
    "en": "Payload system time error. Restart aircraft",
    "es": "Error de la hora del sistema del instrumento. Reinicia la aeronave",
    "fr": "Erreur de temps du système de charge utile. Redémarrer l\\'appareil",
    "ja": "ペイロードシステム タイムエラー。機体を再起動してください",
    "ko": "페이로드 시스템 시간 오류. 기체 재시작 필요",
    "ru": "Ошибка времени системы полезной нагрузки. Перезапустите дрон",
    "tr": "Yük sistem saati hatası. Aracı yeniden başlatın",
    "zh": "负载系统时间异常，请重启飞行器"
  },
  "fpv_tip_0x14010034": {
    "de": "Nutzlast-Geschwindigkeitsdaten ungültig. Nutzlast neu starten",
    "en": "Payload tachometer data invalid. Restart payload",
    "es": "Datos del tacómetro del instrumento no válidos. Reinicia el instrumento",
    "fr": "Données du tachymètre de la charge utile invalides. Redémarrer la charge utile",
    "ja": "ペイロードタコメーターデータ無効。ペイロードを再起動してください",
    "ko": "유효하지 않은 페이로드 태코미터 데이터. 페이로드 재시작 필요",
    "ru": "Недействительные данные тахометра. Перезапустите полезную нагрузку",
    "tr": "Yük takometre verisi geçersiz. Yükü yeniden başlatın",
    "zh": "负载角速度计数据非法，请重启负载"
  },
  "fpv_tip_0x14010035": {
    "de": "Nutzlast-Geschwindigkeitsdaten nicht aktualisiert. Nutzlast neu starten",
    "en": "Payload tachometer data not updated. Restart payload",
    "es": "Datos del tacómetro del instrumento no actualizados. Reinicia el instrumento",
    "fr": "Données du tachymètre de la charge utile non mises à jour. Redémarrer la charge utile",
    "ja": "ペイロードタコメーターデータ未更新。ペイロードを再起動してください",
    "ko": "페이로드 태코미터 데이터 업데이트 안 됨. 페이로드 재시작 필요",
    "ru": "Данные тахометра не обновлены. Перезапустите полезную нагрузку",
    "tr": "Yük takometre verisi güncel değil. Yükü yeniden başlatın",
    "zh": "负载角速度计数据卡死，请重启负载"
  },
  "fpv_tip_0x14010036": {
    "de": "Keine Nutzlast-Geschwindigkeitsdaten. Nutzlast neu starten",
    "en": "No payload tachometer data. Restart payload",
    "es": "No hay datos del tacómetro del instrumento. Reinicia el instrumento",
    "fr": "Aucune données du tachymètre de la charge utile. Redémarrer la charge utile",
    "ja": "ペイロードタコメーターデータなし。ペイロードを再起動してください",
    "ko": "페이로드 태코미터 데이터 없음. 페이로드 재시작 필요",
    "ru": "Нет данных тахометра полезной нагрузки. Перезапустите полезную нагрузку",
    "tr": "Yük takometre verisi yok. Yükü yeniden başlatın",
    "zh": "负载无角速度计数据，请重启负载"
  },
  "fpv_tip_0x14010037": {
    "de": "Nutzlast-Geschwindigkeitsdaten überschreiten das Limit. Nutzlast neu starten",
    "en": "Payload tachometer data exceeds limit. Restart payload",
    "es": "Los datos del tacómetro del instrumento superan el límite. Reinicia el instrumento",
    "fr": "Limite de données du tachymètre de la charge utile dépassée. Redémarrer la charge utile",
    "ja": "ペイロードタコメーターデータ制限超過。ペイロードを再起動してください",
    "ko": "페이로드 태코미터 데이터 한도 초과. 페이로드 재시작 필요",
    "ru": "Данные тахометра полезной нагрузки превышают максимальное значение. Перезапустите полезную нагрузку",
    "tr": "Yük takometre verisi sınırı aşıyor. Yükü yeniden başlatın",
    "zh": "负载角速度计数据超限，请重启负载"
  },
  "fpv_tip_0x14010038": {
    "de": "Nutzlast-Geschwindigkeitsdatenfehler. Nutzlast neu starten",
    "en": "Payload tachometer data error. Restart payload",
    "es": "Error de datos del tacómetro del instrumento. Reinicia el instrumento",
    "fr": "Erreur de données du tachymètre de la charge utile. Redémarrer la charge utile",
    "ja": "ペイロードタコメーター データエラー。ペイロードを再起動してください",
    "ko": "페이로드 태코미터 데이터 오류. 페이로드 재시작 필요",
    "ru": "Ошибка данных тахометра. Перезапустите дрон",
    "tr": "Yük takometre verisi hatası. Yükü yeniden başlatın",
    "zh": "负载角速度计数据异常，请重启负载"
  },
  "fpv_tip_0x14010039": {
    "de": "Nutzlast-Beschleunigungsdaten ungültig. Nutzlast neu starten",
    "en": "Payload accelerometer data invalid. Restart payload",
    "es": "Datos del acelerómetro del instrumento no válidos. Reinicia el instrumento",
    "fr": "Données de l\\'accéléromètre de la charge utile invalides. Redémarrer la charge utile",
    "ja": "ペイロード加速度計データ無効。ペイロードを再起動してください",
    "ko": "유효하지 않은 페이로드 가속도계 데이터. 페이로드 재시작 필요",
    "ru": "Недействительные данные акселерометра. Перезапустите полезную нагрузку",
    "tr": "Yük ivmeölçer verisi geçersiz. Yükü yeniden başlatın",
    "zh": "负载加速度计数据非法，请重启负载"
  },
  "fpv_tip_0x1401003A": {
    "de": "Nutzlast-Beschleunigungsdaten nicht aktualisiert. Nutzlast neu starten",
    "en": "Payload accelerometer data not updated. Restart payload",
    "es": "Datos del acelerómetro de la carga no actualizados. Reinicia el instrumento",
    "fr": "Données de l\\'accéléromètre de la charge utile non mises à jour. Redémarrer la charge utile",
    "ja": "ペイロード加速度計データ未更新。ペイロードを再起動してください",
    "ko": "페이로드 가속도계 데이터 업데이트 안 됨. 페이로드 재시작 필요",
    "ru": "Данные акселерометра не обновлены. Перезапустите полезную нагрузку",
    "tr": "Yük ivmeölçer verisi güncel değil. Yükü yeniden başlatın",
    "zh": "负载加速度计数据卡死，请重启负载"
  },
  "fpv_tip_0x1401003B": {
    "de": "Keine Nutzlast-Beschleunigungsdaten. Nutzlast neu starten",
    "en": "No payload accelerometer data. Restart payload",
    "es": "No hay datos del acelerómetro del instrumento. Reinicia el instrumento",
    "fr": "Aucune données de l\\'accéléromètre de a charge utile. Redémarrer la charge utile",
    "ja": "ペイロード加速度計データなし。ペイロードを再起動してください",
    "ko": "페이로드 가속도계 데이터 없음. 페이로드 재시작 필요",
    "ru": "Нет данных акселерометра. Перезапустите полезную нагрузку",
    "tr": "Yük ivmeölçer verisi yok. Yükü yeniden başlatın",
    "zh": "负载无加速度计数据，请重启负载"
  },
  "fpv_tip_0x1401003C": {
    "de": "Nutzlast-Beschleunigungsdaten überschreiten das Limit. Nutzlast neu starten",
    "en": "Payload accelerometer data exceeds limit. Restart payload",
    "es": "Los datos del acelerómetro del instrumento superan el límite. Reinicia el instrumento",
    "fr": "Limite de données de l\\'accéléromètre de la charge utile dépassée. Redémarrer la charge utile",
    "ja": "ペイロード加速度計データ制限超過。ペイロードを再起動してください",
    "ko": "페이로드 가속도계 데이터 한도 초과. 페이로드 재시작 필요",
    "ru": "Данные акселерометра превышают максимальное значение. Перезапустите полезную нагрузку",
    "tr": "Yük ivmeölçer verisi sınırı aşıyor. Yükü yeniden başlatın",
    "zh": "负载加速度计数据超限，请重启负载"
  },
  "fpv_tip_0x1401003D": {
    "de": "Nutzlast-Beschleunigungsdatenfehler. Nutzlast neu starten",
    "en": "Payload accelerometer data error. Restart payload",
    "es": "Datos del acelerómetro del instrumento no válidos. Reinicia el instrumento",
    "fr": "Erreur de données de l\\'accéléromètre de la charge utile. Redémarrer la charge utile",
    "ja": "ペイロード加速度計データエラー。ペイロードを再起動してください",
    "ko": "페이로드 가속도계 데이터 오류. 페이로드 재시작 필요",
    "ru": "Ошибка данных акселерометра. Перезапустите полезную нагрузку",
    "tr": "Yük ivmeölçer verisi hatası. Yükü yeniden başlatın",
    "zh": "负载加速度计数据异常，请重启负载"
  },
  "fpv_tip_0x1401003E": {
    "de": "Nutzlast-RTK-Datenberechnungsfehler. Fluggerät neu starten",
    "en": "Payload RTK data calculation error. Restart aircraft",
    "es": "Error de cálculo de datos RTK del instrumento. Reinicia la aeronave",
    "fr": "Erreur de calcul des données RTK de la charge utile. Redémarrer l\\'appareil",
    "ja": "ペイロードRTK データ計算エラー。機体を再起動してください",
    "ko": "페이로드 RTK 데이터 계산 오류. 기체 재시작 필요",
    "ru": "Ошибка расчета данных RTK. Перезапустите дрон",
    "tr": "Yük ivmeölçer verisi hesaplama başarısız. Aracı yeniden başlatın",
    "zh": "负载RTK数据解算异常，请重启飞行器"
  },
  "fpv_tip_0x1401003F": {
    "de": "Nutzlast-Richtungsdatenfehler. Satellitensignal prüfen",
    "en": "Payload direction data error. Check satellite signal",
    "es": "Error de datos de dirección del instrumento. Compruebe la señal satelital",
    "fr": "Erreur de données de direction de nacelles-caméras. Vérifier le signal satellite",
    "ja": "ペイロード方向データエラーです。衛星信号を確認してください",
    "ko": "페이로드 방향 데이터 오류. 위성 신호를 확인하세요.",
    "ru": "Ошибка данных направления полезной нагрузки. Проверьте сигнал спутника",
    "tr": "Yük yönü verisi hatası. Uydu sinyalini kontrol edin",
    "zh": "负载测向数据异常，请确认飞机搜星质量"
  },
  "fpv_tip_0x14010040": {
    "de": "Nutzlast-RTK-Datenfehler. Fluggerät neu starten",
    "en": "Payload RTK data error. Restart aircraft",
    "es": "Error de datos RTK del instrumento. Reinicia la aeronave",
    "fr": "Erreur de données RTK de charge utile. Redémarrer l\\'appareil",
    "ja": "ペイロードRTK データエラー。機体を再起動してください",
    "ko": "페이로드 RTK 데이터 오류. 기체 재시작 필요",
    "ru": "Ошибка данных RTK. Перезапустите дрон",
    "tr": "Yük RTK verisi hatası. Aracı yeniden başlatın",
    "zh": "负载RTK数据异常，请重启飞行器"
  },
  "fpv_tip_0x14010041": {
    "de": "Nutzlast-RTK-Zeitfehler. Fluggerät neu starten",
    "en": "Payload RTK time error. Restart aircraft",
    "es": "Error de la hora del RTK del instrumento. Reinicia la aeronave",
    "fr": "Erreur de temps du RTK de charge utile. Redémarrer l\\'appareil",
    "ja": "ペイロードRTK タイムエラー。機体を再起動してください",
    "ko": "페이로드 RTK 시간 오류.  기체 재시작 필요",
    "ru": "Ошибка времени RTK. Перезапустите дрон",
    "tr": "Yük RTK saati hatası. Aracı yeniden başlatın",
    "zh": "负载RTK时间异常，请重启飞行器"
  },
  "fpv_tip_0x14010042": {
    "ar": "",
    "de": "Nutzlast-RTK-Daten ungültig. Fluggerät neu starten",
    "en": "Payload RTK data invalid. Restart aircraft",
    "es": "Datos RTK del instrumento no válidos. Reinicia la aeronave",
    "fr": "Erreur de données RTK de la charge utile. Redémarrer l\\'appareil",
    "id": "",
    "it": "",
    "ja": "ペイロードRTK データ無効。機体を再起動してください",
    "ko": "유효하지 않은 페이로드 RTK 데이터. 기체 재시작 필요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Данные RTK недействительны. Перезапустите дрон",
    "th": "",
    "tr": "Yük RTK verisi geçersiz. Aracı yeniden başlatın",
    "ug": "",
    "vi": "",
    "zh": "负载RTK数据无效，请重启飞行器",
    "zh-Hant": ""
  },
  "fpv_tip_0x14010042_in_the_sky": {
    "ar": "",
    "de": "Nutzlast-RTK-Daten ungültig. Sicherstellen, dass die Suche nach dem Satellitensignal ordnungsgemäß funktioniert, und warten, bis RTK fixiert ist",
    "en": "Payload RTK data invalid. Make sure satellite signal searching is normal and wait until RTK is fixed",
    "es": "Datos RTK del instrumento no válidos. Asegúrese de que la búsqueda de señal satelital sea normal y espere hasta que RTK esté fijo",
    "fr": "Données RTK de nacelles-caméras non valides. Assurez-vous que la recherche du signal satellite est normale et attendez que le RTK soit fixe",
    "id": "",
    "it": "",
    "ja": "搭載RTKデータが無効です。衛星信号捕捉が正常であることを確認し、RTKが修正されるまでお待ちください",
    "ko": "페이로드 RTK 데이터가 유효하지 않습니다. 위성 신호 검색이 정상인지 확인하고 RTK가 확정될 때까지 기다려주세요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Данные полезной нагрузки RTK недействительны. Убедитесь, что поиск спутникового сигнала работает без сбоев; дождитесь настройки RTK",
    "th": "",
    "tr": "Yük RTK verisi geçersiz. Uydu sinyali aramasının normal olduğundan emin olun ve RTK sabitleninceye kadar bekleyin",
    "ug": "",
    "vi": "",
    "zh": "负载RTK数据无效，请确认搜星情况良好，等待恢复",
    "zh-Hant": ""
  },
  "fpv_tip_0x14010043": {
    "de": "Nutzlast-IMU wird erwärmt. Bitte warten, bis IMU aufgewärmt ist",
    "en": "Payload IMU warming up. Wait until IMU finished warming up to continue",
    "es": "Calentando la IMU del instrumento. Espera a que la IMU termine de calentarse antes de continuar",
    "fr": "Réchauffage de l'IMU. Veuillez attendre que l'IMU ait chauffé avant de continuer",
    "it": "",
    "ja": "ペイロードIMUウォームアップ中。温まるまで待って継続してください",
    "ko": "페이로드 IMU 예열 중. IMU 예열 완료까지 대기",
    "pt": "",
    "ru": "Прогрев IMU. Подождите, пока IMU закончит прогрев, чтобы продолжить",
    "tr": "Yük IMU'su ısınıyor. Devam etmek için IMU'nun ısınması tamamlanana kadar bekleyin",
    "zh": "请等待负载惯导预热完成"
  },
  "fpv_tip_0x14010044": {
    "de": "Fehler: Temperatursteuerung der Nutzlast-IMU. Nutzlast neu starten",
    "en": "Payload IMU temperature control processor error. Restart payload",
    "es": "Error del procesador de control de temperatura de la IMU del instrumento. Reinicia el instrumento",
    "fr": "Erreur du processeur de contrôle de la température de l'IMU. Redémarrer la charge utile",
    "it": "",
    "ja": "ペイロードIMU温度制御プロセッサー エラー。ペイロードを再起動してください",
    "ko": "페이로드 IMU 온도 제어 프로세서 오류. 페이로드 재시작 필요",
    "pt": "",
    "ru": "Ошибка процессора контроля температуры IMU. Перезапустите полезную нагрузку",
    "tr": "Yük IMU'su sıcaklık denetleyici işlemcisi hatası. Yükü yeniden başlatın",
    "zh": "负载惯导温控异常，请重启负载"
  },
  "fpv_tip_0x14010045": {
    "de": "Nutzlast-IMU überhitzt. Nutzlast neu starten",
    "en": "Payload IMU overheated. Restart payload",
    "es": "IMU del instrumento sobrecalentada. Reinicia el instrumento",
    "fr": "Surchauffe de l'IMU de la charge utile. Redémarrer la charge utile",
    "it": "",
    "ja": "ペイロードIMU高温。ペイロードを再起動してください",
    "ko": "페이로드 IMU 과열. 페이로드 재시작 필요",
    "pt": "",
    "ru": "Перегрев полезной нагрузки IMU. Перезапустите полезную нагрузку",
    "tr": "Yük IMU'su aşırı ısındı. Yükü yeniden başlatın",
    "zh": "负载惯导温度过高，请重启负载"
  },
  "fpv_tip_0x14010046": {
    "de": "Nutzlast-IMU-Temperatur zu niedrig. Nutzlast neu starten",
    "en": "Payload IMU temperature too low. Restart payload",
    "es": "Temperatura de la IMU del instrumento demasiado baja. Reinicia el instrumento",
    "fr": "Température de l'IMU de la charge utile trop basse. Redémarrer la charge utile",
    "it": "",
    "ja": "ペイロードIMU低温。ペイロードを再起動してください",
    "ko": "페이로드 IMU 온도 너무 낮음. 페이로드 재시작 필요",
    "pt": "",
    "ru": "Слишком низкая температура IMU. Перезапустите полезную нагрузку",
    "tr": "Yük IMU'su sıcaklığı çok düşük. Yükü yeniden başlatın",
    "zh": "负载惯导温度过低，请重启负载"
  },
  "fpv_tip_0x14010047": {
    "de": "Nutzlastprozessor überhitzt. Sofort zurückkehren oder landen. Nutzlast entfernen und abwarten, bis diese abgekühlt ist",
    "en": "Payload processor overheated. Return to home or land promptly. Remove payload and wait for it to cool down before use",
    "es": "Procesador del instrumento sobrecalentado. Regresa al punto de origen o aterriza rápidamente. Quita el instrumento y espera a que se enfríe antes del uso",
    "fr": "Surchauffe processeur caméra. Revenez au point de départ ou atterrissez immédiatement. Attendez que la température revienne à la normale avant usage",
    "ja": "ペイロードプロセッサー高温。直ちに帰還するか着陸してください。ペイロードを取り外し、プロセッサーが常温に戻るまで待ち 、使用してください",
    "ko": "페이로드 프로세서 과열. 즉시 RTH 또는 착륙 필요. 페이로드 분리 후 열이 다 식은 후 사용 가능",
    "ru": "Перегрев процессора. Вернитесь в домашнюю точку или приземлитесь как можно скорее. Снимите полезную нагрузку и подождите, пока она остынет, перед повторным использованием",
    "tr": "Yük işlemcisi aşırı ısındı. Eve geri dönün veya düzgün iniş yapın. Yükü çıkarın ve kullanmadan önce soğumasını bekleyin",
    "zh": "负载芯片温度过高，请尽快返航或降落，移除负载等待冷却后重启使用"
  },
  "fpv_tip_0x14010047_in_the_sky": {
    "de": "Nutzlastprozessor überhitzt. Sofort zurückkehren oder landen. Nutzlast entfernen und abwarten, bis diese abgekühlt ist",
    "en": "Payload processor overheated. Return to home or land promptly. Remove payload and wait for it to cool down before use",
    "es": "Procesador del instrumento sobrecalentado. Regresa al punto de origen o aterriza rápidamente. Quita el instrumento y espera a que se enfríe antes del uso",
    "fr": "Surchauffe du processeur de la charge utile. Retournez au point de départ ou atterrissez rapidement. Retirer la charge utile et attendez qu\\'elle revienne à une température normale",
    "it": "",
    "ja": "ペイロードプロセッサー高温。直ちに帰還するか着陸してください。ペイロードを取り外し、プロセッサーが常温に戻るまで待ち\r\r\n、使用してください",
    "ko": "페이로드 프로세서 과열. 즉시 RTH 또는 착륙 필요. 페이로드 분리 후 열이 다 식은 후 사용 가능",
    "pt": "",
    "ru": "Перегрев процессора. Вернитесь в домашнюю точку или приземлитесь как можно скорее. Снимите полезную нагрузку и подождите, пока она остынет, перед повторным использованием",
    "tr": "Yük işlemcisi aşırı ısındı. Eve geri dönün veya düzgün iniş yapın. Yükü çıkarın ve kullanmadan önce soğumasını bekleyin",
    "zh": "负载芯片温度过高，请尽快返航或降落，移除负载等待冷却后重启使用"
  },
  "fpv_tip_0x14010048": {
    "ar": "",
    "de": "Fehler des Nutzlastlüfters. Überprüfen, ob der Lüfter blockiert ist",
    "en": "Payload fan error. Check whether the fan is stalled",
    "es": "Error del ventilador del instrumento. Compruebe si el ventilador está atascado",
    "fr": "Erreur sur le ventilateur de la nacelle-caméra. Vérifiez si le ventilateur est bloqué",
    "id": "",
    "it": "",
    "ja": "搭載ファンエラー。ファンが停止していないかを確認してください",
    "ko": "페이로드 팬 오류. 팬이 멈췄는지 확인하세요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Ошибка полезной нагрузки вентилятора. Проверьте, не заклинило ли вентилятор",
    "th": "",
    "tr": "Yük fanı hatası. Fanın arızalanarak durup durmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "负载风扇异常，请检查负载风扇是否有堵转",
    "zh-Hant": ""
  },
  "fpv_tip_0x14010048_in_the_sky": {
    "ar": "",
    "de": "Fehler des Nutzlastlüfters. Zum Startpunkt zurückkehren oder landen und prüfen, ob der Lüfter blockiert ist",
    "en": "Payload fan error. Return to home or land and check whether the fan is stalled",
    "es": "Error del ventilador del instrumento. Regrese al punto de origen y compruebe si el ventilador está atascado",
    "fr": "Erreur sur le ventilateur de la nacelle-caméra. Revenez au point de départ ou atterrissez et vérifiez si le ventilateur est bloqué",
    "id": "",
    "it": "",
    "ja": "搭載ファンエラー。RTHまたは着陸させ、ファンが停止していないかを確認してください",
    "ko": "페이로드 팬 오류. 리턴 투 홈하거나 착륙해 팬이 작동을 멈추었는지 여부를 확인하세요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Ошибка полезной нагрузки вентилятора. Вернитесь домой или приземлитесь. Проверьте, не заклинило ли вентилятор",
    "th": "",
    "tr": "Yük fanı hatası. Eve dönün veya iniş yapın ve fanın durup durmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "负载风扇异常，请返航或降落，检查负载风扇是否有堵转",
    "zh-Hant": ""
  },
  "fpv_tip_0x14010049": {
    "de": "Nutzlast-PPS-Datenfehler",
    "en": "Payload PPS Data Error",
    "es": "Error de datos PPS de instrumentos",
    "fr": "Erreur de données PPS de nacelles-caméras",
    "ja": "ペイロードPPSデータエラー",
    "ko": "페이로드 PPS 데이터 오류",
    "ru": "Ошибка данных PPS полезной нагрузки",
    "tr": "Yük PPS Verisi Hatası",
    "zh": "负载PPS数据异常"
  },
  "fpv_tip_0x1401004A": {
    "de": "Nutzlast-UTC-Zeitfehler",
    "en": "Payload UTC Time Error",
    "es": "Error de hora UTC de instrumentos",
    "fr": "Erreur de temps UTC de nacelles-caméras",
    "ja": "ペイロードUTC時刻エラー",
    "ko": "페이로드 UTC 시간 오류",
    "ru": "Ошибка времени UTC полезной нагрузки",
    "tr": "Yük UTC Saati Hatası",
    "zh": "负载UTC时间异常"
  },
  "fpv_tip_0x14020000": {
    "en": "ASIC芯片温度异常",
    "zh": "ASIC芯片温度异常"
  },
  "fpv_tip_0x14020001": {
    "en": "主控芯片温度异常",
    "zh": "主控芯片温度异常"
  },
  "fpv_tip_0x14020002": {
    "en": "看门狗超时",
    "zh": "看门狗超时"
  },
  "fpv_tip_0x14020003": {
    "en": "ASIC芯片错误",
    "zh": "ASIC芯片错误"
  },
  "fpv_tip_0x14020004": {
    "en": "栈溢出",
    "zh": "栈溢出"
  },
  "fpv_tip_0x14020005": {
    "en": "MIPI故障",
    "zh": "MIPI故障"
  },
  "fpv_tip_0x14020007": {
    "en": "脏污预警",
    "zh": "脏污预警"
  },
  "fpv_tip_0x14020031": {
    "de": "Nutzlast-Datenkonsolidierungsfehler. Nutzlast neu starten",
    "en": "Payload infusion data error. Restart payload",
    "es": "Error de datos de infusión del instrumento. Reinicia el instrumento",
    "fr": "Erreur de données d'infusion charge utile. Redémarrer la charge utile",
    "it": "",
    "ja": "ペイロード融合データエラー。ペイロードを再起動してください",
    "ko": "페이로드 인퓨전 데이터 오류. 페이로드 재시작 필요",
    "pt": "",
    "ru": "Предупреждение о жизненном цикле лидара",
    "tr": "Yük infüzyon verisi hatası. Yükü yeniden başlatın",
    "zh": "负载融合数据异常，请重启负载"
  },
  "fpv_tip_0x14020032": {
    "de": "Nutzlast-Datenkonsolidierung kann nicht abgeschlossen werden",
    "en": "Unable to converge payload infusion data",
    "es": "No se pueden juntar los datos de infusión del instrumento",
    "fr": "Impossible de rassembler les données d'infusion de la charge utile",
    "it": "",
    "ja": "ペイロード融合データを収束できません",
    "ko": "페이로드 인퓨전 데이터 수렴 불가",
    "pt": "",
    "ru": "Не удалось объединить данные полезной нагрузки",
    "tr": "Yük infüzyon verisi yakınsanamadı",
    "zh": "负载融合数据未收敛"
  },
  "fpv_tip_0x14020033": {
    "de": "Nutzlast-Systemzeitfehler. Fluggerät neu starten",
    "en": "Payload system time error. Restart aircraft",
    "es": "Error de la hora del sistema del instrumento. Reinicia la aeronave",
    "fr": "Erreur de temps du système de charge utile. Redémarrer l'appareil",
    "it": "",
    "ja": "ペイロードシステム タイムエラー。機体を再起動してください",
    "ko": "페이로드 시스템 시간 오류. 기체 재시작 필요",
    "pt": "",
    "ru": "Ошибка времени системы полезной нагрузки. Перезапустите дрон",
    "tr": "Yük sistem saati hatası. Aracı yeniden başlatın",
    "zh": "负载系统时间异常，请重启飞行器"
  },
  "fpv_tip_0x14020034": {
    "de": "Nutzlast-Geschwindigkeitsdaten ungültig. Nutzlast neu starten",
    "en": "Payload tachometer data invalid. Restart payload",
    "es": "Datos del tacómetro del instrumento no válidos. Reinicia el instrumento",
    "fr": "Données du tachymètre de la charge utile invalides. Redémarrer la charge utile",
    "it": "",
    "ja": "ペイロードタコメーターデータ無効。ペイロードを再起動してください",
    "ko": "유효하지 않은 페이로드 태코미터 데이터. 페이로드 재시작 필요",
    "pt": "",
    "ru": "Недействительные данные тахометра. Перезапустите полезную нагрузку",
    "tr": "Yük takometre verisi geçersiz. Yükü yeniden başlatın",
    "zh": "负载角速度计数据非法，请重启负载"
  },
  "fpv_tip_0x14020035": {
    "de": "Nutzlast-Geschwindigkeitsdaten nicht aktualisiert. Nutzlast neu starten",
    "en": "Payload tachometer data not updated. Restart payload",
    "es": "Datos del tacómetro del instrumento no actualizados. Reinicia el instrumento",
    "fr": "Données du tachymètre de la charge utile non mises à jour. Redémarrer la charge utile",
    "it": "",
    "ja": "ペイロードタコメーターデータ未更新。ペイロードを再起動してください",
    "ko": "페이로드 태코미터 데이터 업데이트 안 됨. 페이로드 재시작 필요",
    "pt": "",
    "ru": "Данные тахометра не обновлены. Перезапустите полезную нагрузку",
    "tr": "Yük takometre verisi güncel değil. Yükü yeniden başlatın",
    "zh": "负载角速度计数据卡死，请重启负载"
  },
  "fpv_tip_0x14020036": {
    "de": "Keine Nutzlast-Geschwindigkeitsdaten. Nutzlast neu starten",
    "en": "No payload tachometer data. Restart payload",
    "es": "No hay datos del tacómetro del instrumento. Reinicia el instrumento",
    "fr": "Aucune données du tachymètre de la charge utile. Redémarrer la charge utile",
    "it": "",
    "ja": "ペイロードタコメーターデータなし。ペイロードを再起動してください",
    "ko": "페이로드 태코미터 데이터 없음. 페이로드 재시작 필요",
    "pt": "",
    "ru": "Нет данных тахометра полезной нагрузки. Перезапустите полезную нагрузку",
    "tr": "Yük takometre verisi yok. Yükü yeniden başlatın",
    "zh": "负载无角速度计数据，请重启负载"
  },
  "fpv_tip_0x14020037": {
    "de": "Nutzlast-Geschwindigkeitsdaten überschreiten das Limit. Nutzlast neu starten",
    "en": "Payload tachometer data exceeds limit. Restart payload",
    "es": "Los datos del tacómetro del instrumento superan el límite. Reinicia el instrumento",
    "fr": "Limite de données du tachymètre de la charge utile dépassée. Redémarrer la charge utile",
    "it": "",
    "ja": "ペイロードタコメーターデータ制限超過。ペイロードを再起動してください",
    "ko": "페이로드 태코미터 데이터 한도 초과. 페이로드 재시작 필요",
    "pt": "",
    "ru": "Данные тахометра полезной нагрузки превышают максимальное значение. Перезапустите полезную нагрузку",
    "tr": "Yük takometre verisi sınırı aşıyor. Yükü yeniden başlatın",
    "zh": "负载角速度计数据超限，请重启负载"
  },
  "fpv_tip_0x14020038": {
    "de": "Nutzlast-Geschwindigkeitsdatenfehler. Nutzlast neu starten",
    "en": "Payload tachometer data error. Restart payload",
    "es": "Error de datos del tacómetro del instrumento. Reinicia el instrumento",
    "fr": "Erreur de données du tachymètre de la charge utile. Redémarrer la charge utile",
    "it": "",
    "ja": "ペイロードタコメーター データエラー。ペイロードを再起動してください",
    "ko": "페이로드 태코미터 데이터 오류. 페이로드 재시작 필요",
    "pt": "",
    "ru": "Ошибка данных тахометра. Перезапустите дрон",
    "tr": "Yük takometre verisi hatası. Yükü yeniden başlatın",
    "zh": "负载角速度计数据异常，请重启负载"
  },
  "fpv_tip_0x14020039": {
    "de": "Nutzlast-Beschleunigungsdaten ungültig. Nutzlast neu starten",
    "en": "Payload accelerometer data invalid. Restart payload",
    "es": "Datos del acelerómetro del instrumento no válidos. Reinicia el instrumento",
    "fr": "Données de l'accéléromètre de la charge utile invalides. Redémarrer la charge utile",
    "it": "",
    "ja": "ペイロード加速度計データ無効。ペイロードを再起動してください",
    "ko": "유효하지 않은 페이로드 가속도계 데이터. 페이로드 재시작 필요",
    "pt": "",
    "ru": "Недействительные данные акселерометра. Перезапустите полезную нагрузку",
    "tr": "Yük ivmeölçer verisi geçersiz. Yükü yeniden başlatın",
    "zh": "负载加速度计数据非法，请重启负载"
  },
  "fpv_tip_0x1402003A": {
    "de": "Nutzlast-Beschleunigungsdaten nicht aktualisiert. Nutzlast neu starten",
    "en": "Payload accelerometer data not updated. Restart payload",
    "es": "Datos del acelerómetro de la carga no actualizados. Reinicia el instrumento",
    "fr": "Données de l'accéléromètre de la charge utile non mises à jour. Redémarrer la charge utile",
    "it": "",
    "ja": "ペイロード加速度計データ未更新。ペイロードを再起動してください",
    "ko": "페이로드 가속도계 데이터 업데이트 안 됨. 페이로드 재시작 필요",
    "pt": "",
    "ru": "Данные акселерометра не обновлены. Перезапустите полезную нагрузку",
    "tr": "Yük ivmeölçer verisi güncel değil. Yükü yeniden başlatın",
    "zh": "负载加速度计数据卡死，请重启负载"
  },
  "fpv_tip_0x1402003B": {
    "de": "Keine Nutzlast-Beschleunigungsdaten. Nutzlast neu starten",
    "en": "No payload accelerometer data. Restart payload",
    "es": "No hay datos del acelerómetro del instrumento. Reinicia el instrumento",
    "fr": "Aucune données de l'accéléromètre de a charge utile. Redémarrer la charge utile",
    "it": "",
    "ja": "ペイロード加速度計データなし。ペイロードを再起動してください",
    "ko": "페이로드 가속도계 데이터 없음. 페이로드 재시작 필요",
    "pt": "",
    "ru": "Нет данных акселерометра. Перезапустите полезную нагрузку",
    "tr": "Yük ivmeölçer verisi yok. Yükü yeniden başlatın",
    "zh": "负载无加速度计数据，请重启负载"
  },
  "fpv_tip_0x1402003C": {
    "de": "Nutzlast-Beschleunigungsdaten überschreiten das Limit. Nutzlast neu starten",
    "en": "Payload accelerometer data exceeds limit. Restart payload",
    "es": "Los datos del acelerómetro del instrumento superan el límite. Reinicia el instrumento",
    "fr": "Limite de données de l'accéléromètre de la charge utile dépassée. Redémarrer la charge utile",
    "it": "",
    "ja": "ペイロード加速度計データ制限超過。ペイロードを再起動してください",
    "ko": "페이로드 가속도계 데이터 한도 초과. 페이로드 재시작 필요",
    "pt": "",
    "ru": "Данные акселерометра превышают максимальное значение. Перезапустите полезную нагрузку",
    "tr": "Yük ivmeölçer verisi sınırı aşıyor. Yükü yeniden başlatın",
    "zh": "负载加速度计数据超限，请重启负载"
  },
  "fpv_tip_0x1402003D": {
    "de": "Nutzlast-Beschleunigungsdatenfehler. Nutzlast neu starten",
    "en": "Payload accelerometer data error. Restart payload",
    "es": "Datos del acelerómetro del instrumento no válidos. Reinicia el instrumento",
    "fr": "Erreur de données de l'accéléromètre de la charge utile. Redémarrer la charge utile",
    "it": "",
    "ja": "ペイロード加速度計データエラー。ペイロードを再起動してください",
    "ko": "페이로드 가속도계 데이터 오류. 페이로드 재시작 필요",
    "pt": "",
    "ru": "Ошибка данных акселерометра. Перезапустите полезную нагрузку",
    "tr": "Yük ivmeölçer verisi hatası. Yükü yeniden başlatın",
    "zh": "负载加速度计数据异常，请重启负载"
  },
  "fpv_tip_0x1402003E": {
    "de": "Nutzlast-RTK-Datenberechnungsfehler. Fluggerät neu starten",
    "en": "Payload RTK data calculation error. Restart aircraft",
    "es": "Error de cálculo de datos RTK del instrumento. Reinicia la aeronave",
    "fr": "Erreur de calcul des données RTK de la charge utile. Redémarrer l'appareil",
    "it": "",
    "ja": "ペイロードRTK データ計算エラー。機体を再起動してください",
    "ko": "페이로드 RTK 데이터 계산 오류. 기체 재시작 필요",
    "pt": "",
    "ru": "Ошибка расчета данных RTK. Перезапустите дрон",
    "tr": "Yük ivmeölçer verisi hesaplama başarısız. Aracı yeniden başlatın",
    "zh": "负载RTK数据解算异常，请重启飞行器"
  },
  "fpv_tip_0x14020040": {
    "de": "Nutzlast-RTK-Datenfehler. Fluggerät neu starten",
    "en": "Payload RTK data error. Restart aircraft",
    "es": "Error de datos RTK del instrumento. Reinicia la aeronave",
    "fr": "Erreur de données RTK de charge utile. Redémarrer l'appareil",
    "it": "",
    "ja": "ペイロードRTK データエラー。機体を再起動してください",
    "ko": "페이로드 RTK 데이터 오류. 기체 재시작 필요",
    "pt": "",
    "ru": "Ошибка данных RTK. Перезапустите дрон",
    "tr": "Yük RTK verisi hatası. Aracı yeniden başlatın",
    "zh": "负载RTK数据异常，请重启飞行器"
  },
  "fpv_tip_0x14020041": {
    "de": "Nutzlast-RTK-Zeitfehler. Fluggerät neu starten",
    "en": "Payload RTK time error. Restart aircraft",
    "es": "Error de la hora del RTK del instrumento. Reinicia la aeronave",
    "fr": "Erreur de temps du RTK de charge utile. Redémarrer l'appareil",
    "it": "",
    "ja": "ペイロードRTK タイムエラー。機体を再起動してください",
    "ko": "페이로드 RTK 시간 오류.  기체 재시작 필요",
    "pt": "",
    "ru": "Ошибка времени RTK. Перезапустите дрон",
    "tr": "Yük RTK saati hatası. Aracı yeniden başlatın",
    "zh": "负载RTK时间异常，请重启飞行器"
  },
  "fpv_tip_0x14020042": {
    "ar": "",
    "de": "Nutzlast-RTK-Daten ungültig. Fluggerät neu starten",
    "en": "Payload RTK data invalid. Restart aircraft",
    "es": "Datos del RTK del instrumento no válidos. Reinicia la aeronave",
    "fr": "Erreur de données RTK de la charge utile. Redémarrer l'appareil",
    "id": "",
    "it": "",
    "ja": "ペイロードRTK データ無効。機体を再起動してください",
    "ko": "유효하지 않은 페이로드 RTK 데이터. 기체 재시작 필요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Недействительные данные RTK. Перезапустите дрон",
    "th": "",
    "tr": "Yük RTK verisi geçersiz. Aracı yeniden başlatın",
    "ug": "",
    "vi": "",
    "zh": "负载RTK数据无效，请重启飞行器",
    "zh-Hant": ""
  },
  "fpv_tip_0x14020042_in_the_sky": {
    "de": "Nutzlast-RTK-Daten ungültig. Sicherstellen, dass die Suche nach dem Satellitensignal ordnungsgemäß funktioniert, und warten, bis RTK fixiert ist",
    "en": "Payload RTK data invalid. Make sure satellite signal searching is normal and wait until RTK is fixed",
    "es": "Datos RTK del instrumento no válidos. Asegúrese de que la búsqueda de señal satelital sea normal y espere hasta que RTK esté fijo",
    "fr": "Données RTK de nacelles-caméras non valides. Assurez-vous que la recherche du signal satellite est normale et attendez que le RTK soit fixe",
    "id": "",
    "it": "",
    "ja": "搭載RTKデータが無効です。衛星信号捕捉が正常であることを確認し、RTKが修正されるまでお待ちください",
    "ko": "페이로드 RTK 데이터가 유효하지 않습니다. 위성 신호 검색이 정상인지 확인하고 RTK가 확정될 때까지 기다려주세요",
    "nl": "",
    "pl": "",
    "pt-PT": "",
    "ru": "Данные полезной нагрузки RTK недействительны. Убедитесь, что поиск спутникового сигнала работает без сбоев; дождитесь настройки RTK",
    "th": "",
    "tr": "Yük RTK verisi geçersiz. Uydu sinyali aramasının normal olduğundan emin olun ve RTK sabitleninceye kadar bekleyin",
    "ug": "",
    "vi": "",
    "zh": "负载RTK数据无效，请确认搜星情况良好，等待恢复",
    "zh-Hant": ""
  },
  "fpv_tip_0x14030000": {
    "en": "扫描模块温度异常",
    "zh": "扫描模块温度异常"
  },
  "fpv_tip_0x14030001": {
    "en": "扫描模块电流异常",
    "zh": "扫描模块电流异常"
  },
  "fpv_tip_0x14030002": {
    "de": "Rotationsgeschwindigkeit des LiDAR-Abtastmoduls fehlerhaft. Fluggerät neu starten.",
    "en": "Rotation speed error of LiDAR scanning module. Restart aircraft",
    "es": "Error de velocidad de rotación del módulo de escaneado LiDAR. Reinicie la aeronave",
    "fr": "Erreur de vitesse de rotation du module de numérisation LiDAR. Redémarrez l’appareil",
    "it": "",
    "ja": "LiDARスキャンモジュールの回転速度エラー。機体を再起動してください",
    "ko": "LiDAR 스캐닝 모듈 의 회전 속도 오류입니다. 기체 재시작",
    "ru": "Ошибка скорости вращения модуля сканирования LiDAR. Перезапустите дрон",
    "tr": "LiDAR tarama modülünün dönüş hızı hatası. Hava aracını yeniden başlatın",
    "zh": "激光雷达扫描模块转速异常，请重启飞行器"
  },
  "fpv_tip_0x14030003": {
    "de": "Fehler bei der Eigenprüfung des LiDAR-Abtastmoduls. Fluggerät neu starten.",
    "en": "Auto Check error of LiDAR scanning module. Restart aircraft",
    "es": "Error de comprobación automática del módulo de escaneado LiDAR. Reinicie la aeronave",
    "fr": "Erreur de vérification automatique du module de numérisation LiDAR. Redémarrez l’appareil",
    "it": "",
    "ja": "LiDARスキャンモジュールの自動チェックエラー。機体を再起動してください",
    "ko": "LiDAR 스캐닝 모듈의 자동 확인 오류입니다. 기체 재시작",
    "ru": "Ошибка автоматической проверки модуля сканирования LiDAR. Перезапустите дрон",
    "tr": "LiDAR tarama modülünün Otomatik Kontrol hatası. Hava aracını yeniden başlatın",
    "zh": "激光雷达扫描模块自检异常，请重启飞行器"
  },
  "fpv_tip_0x1403000B": {
    "de": "LiDAR-Abtastmodul wird kalibriert. Fluggerät nicht bewegen",
    "en": "Calibrating LiDAR scanning module. Do not move aircraft",
    "es": "Calibrando módulo de escaneado del LiDAR. No muevas la aeronave",
    "fr": "Étalonnage du module de balayage LiDAR. Ne déplacez pas l’appareil",
    "ja": "LiDARスキャンモジュールのキャリブレーション中です。機体を動かさないでください",
    "ko": "LiDAR 스캐닝 모듈 캘리브레이션 중. 기체를 움직이지 마세요",
    "ru": "Калибровка модуля сканирования лидара. Не перемещайте дрон",
    "tr": "LiDAR tarama modülü kalibre ediliyor. Hava aracını hareket ettirmeyin",
    "zh": "激光雷达扫描模块校准中，请勿移动飞行器"
  },
  "fpv_tip_0x14040000": {
    "en": "接收器高压异常",
    "zh": "接收器高压异常"
  },
  "fpv_tip_0x14040001": {
    "en": "接收器温度异常",
    "zh": "接收器温度异常"
  },
  "fpv_tip_0x14040002": {
    "en": "激光器温度异常",
    "zh": "激光器温度异常"
  },
  "fpv_tip_0x14050000": {
    "en": "PPS同步异常",
    "zh": "PPS同步异常"
  },
  "fpv_tip_0x14050002": {
    "en": "PPS同步异常",
    "zh": "PPS同步异常"
  },
  "fpv_tip_0x14050003": {
    "en": "时间同步精度低",
    "zh": "时间同步精度低"
  },
  "fpv_tip_0x14060000": {
    "de": "Fehler bei der NIR-Beleuchtung, deaktiviert. Funktion erneut aktivieren oder DJI-Support kontaktieren",
    "en": "NIR Illumination power error and disabled. Re-enable this function or contact DJI Support",
    "es": "Error de alimentación de iluminación NIR; se ha desactivado. Vuelve a activar esta función o ponte en contacto con la Asistencia técnica de DJI",
    "fr": "Erreur d'alimentation et désactivation de l'Éclairage NIR. Réactivez cette fonction ou contactez le Service client DJI",
    "ja": "NIR照明の電源エラーのため無効になっています。この機能を再度有効にするか、DJIサポートにお問い合わせください",
    "ko": "NIR 조명 전원 오류 및 비활성화됨. 이 기능을 다시 활성화하거나 DJI 고객지원에 문의하세요",
    "ru": "Ошибка питания и отключение освещения в ближней ИК-области. Повторно включите эту функцию или обратитесь в службу поддержки DJI",
    "tr": "NIR Aydınlatma güç hatası verdi ve devre dışı bırakıldı. Bu işlevi yeniden etkinleştirin veya DJI Destek ile iletişime geçin",
    "zh": "近红外补光功率异常并关闭，请重启或联系售后客服"
  },
  "fpv_tip_0x14810040": {
    "de": "LiDAR-Temperatur zu hoch/niedrig",
    "en": "LiDAR temperature too high/low",
    "es": "Temperatura del LiDAR demasiado alta/baja",
    "fr": "Température LiDAR trop faible/élevée",
    "it": "",
    "ja": "LiDAR温度が高温／低温",
    "ko": "LiDAR 온도 너무 높음/낮음",
    "pt": "",
    "ru": "Слишком высокая/низкая температура лидара",
    "tr": "LiDAR sıcaklığı çok yüksek/düşük",
    "zh": "激光雷达温度过高/过低"
  },
  "fpv_tip_0x14810080": {
    "de": "LiDAR-Spannung zu hoch/niedrig",
    "en": "LiDAR voltage too high/low",
    "es": "Voltaje del LiDAR demasiado alto/bajo",
    "fr": "Tension LiDAR trop basse/élevée",
    "it": "",
    "ja": "LiDAR電圧が高圧／低圧",
    "ko": "LiDAR 전압 너무 높음/낮음",
    "pt": "",
    "ru": "Слишком высокое/низкое напряжение лидара",
    "tr": "LiDAR gerilimi çok yüksek/düşük",
    "zh": "激光雷达电压过高/过低"
  },
  "fpv_tip_0x148100C0": {
    "es": "Error del motor del LiDAR"
  },
  "fpv_tip_0x148100c0": {
    "de": "LiDAR-Motorfehler",
    "en": "LiDAR motor error",
    "es": "Error del motor LiDAR",
    "fr": "Erreur du moteur LiDAR",
    "it": "",
    "ja": "LiDAR モーターエラー",
    "ko": "LiDAR 모터 오류",
    "pt": "",
    "ru": "Ошибка мотора лидара",
    "tr": "LiDAR motor hatası",
    "zh": "激光雷达电机异常"
  },
  "fpv_tip_0x14810100": {
    "de": "Warnung: LiDAR-Nutzungsdauer",
    "en": "LiDAR lifecycle warning",
    "es": "Advertencia del ciclo de vida de LiDAR",
    "fr": "Avertissement  du cycle de vie LiDAR",
    "it": "",
    "ja": "LiDAR製品寿命警告",
    "ko": "LiDAR 수명 경고",
    "pt": "",
    "ru": "Предупреждение о жизненном цикле лидара",
    "tr": "LiDAR yaşam döngüsü uyarısı",
    "zh": "激光雷达寿命预警"
  },
  "fpv_tip_0x14810140": {
    "de": "LiDAR-Systemfehler",
    "en": "LiDAR system error",
    "es": "Error del sistema LiDAR",
    "fr": "Erreur du système LiDAR",
    "it": "",
    "ja": "LiDAR システムエラー",
    "ko": "LiDAR 시스템 오류",
    "pt": "",
    "ru": "Ошибка системы лидара",
    "tr": "LiDAR sistem hatası",
    "zh": "激光雷达系统异常"
  },
  "fpv_tip_0x15000020": {
    "de": "Temperatur des Millimeterwellenradars zu niedrig. Überprüfen, ob die Umgebungstemperatur zu niedrig ist",
    "en": "Millimeter-wave radar temperature too low. Check if ambient temperature is too low",
    "es": "La temperatura del radar de ondas milimétricas es demasiado baja. Comprueba si la temperatura ambiente es demasiado baja",
    "fr": "Température trop basse du radar à ondes millimétriques. Vérifiez si la température ambiante est trop basse",
    "ja": "ミリ波レーダーの温度が低すぎます。周囲温度が低すぎるかどうかを確認してください",
    "ko": "밀리미터파 레이더 온도가 너무 낮음. 주변 온도가 너무 낮은지 점검하세요",
    "ru": "Слишком низкая температура РЛС миллиметрового диапазона. Проверьте, не слишком ли низкая температура окружающей среды",
    "tr": "Milimetre dalga radarının sıcaklığı çok düşük. Ortam sıcaklığının çok düşük olup olmadığını kontrol edin",
    "zh": "毫米波雷达温度过低，请确认工作环境温度是否过低"
  },
  "fpv_tip_0x15000021": {
    "de": "Millimeterwellenradar überhitzt. Überprüfen, ob die Umgebungstemperatur zu hoch ist",
    "en": "Millimeter-wave radar overheated. Check if ambient temperature is too high",
    "es": "Radar de ondas milimétricas sobrecalentado. Comprueba si la temperatura ambiente es demasiado alta",
    "fr": "Surchauffe du radar à ondes millimétriques. Vérifiez si la température ambiante est trop élevée",
    "ja": "ミリ波レーダーが過熱しています。周囲温度が高すぎるかどうかを確認してください",
    "ko": "밀리미터파 레이더가 과열됨. 주변 온도가 너무 높은지 점검하세요",
    "ru": "Перегрев РЛС миллиметрового диапазона. Проверьте, не слишком ли высокая температура окружающей среды",
    "tr": "Milimetre dalga radarı aşırı ısındı. Ortam sıcaklığının çok yüksek olup olmadığını kontrol edin",
    "zh": "毫米波雷达温度过高，请确认工作环境温度是否过高"
  },
  "fpv_tip_0x15010020": {
    "de": "Fehler in der internen Stromversorgung des Millimeterwellenradars. Radar neu starten",
    "en": "Millimeter-wave radar internal power supply error. Restart radar",
    "es": "Error de fuente de alimentación interna del radar de ondas milimétricas. Reiniciar radar",
    "fr": "Erreur d’alimentation interne du radar à ondes millimétriques. Redémarrez le radar",
    "ja": "ミリ波レーダー内部電源エラー。レーダーを再起動してください",
    "ko": "밀리미터파 레이더 내부 전력 공급 오류. 레이더 재시작",
    "ru": "Ошибка внутреннего источника питания РЛС миллиметрового диапазона. Перезапустите радар",
    "tr": "Milimetre dalga radarı dahili güç kaynağı hatası. Radarı yeniden başlatın",
    "zh": "毫米波雷达内部供电异常，请重启雷达负载"
  },
  "fpv_tip_0x15010021": {
    "de": "Fehler in der internen Stromversorgung des Millimeterwellenradars. Radar neu starten",
    "en": "Millimeter-wave radar internal power supply error. Restart radar",
    "es": "Error de fuente de alimentación interna del radar de ondas milimétricas. Reiniciar radar",
    "fr": "Erreur d’alimentation interne du radar à ondes millimétriques. Redémarrez le radar",
    "ja": "ミリ波レーダー内部電源エラー。レーダーを再起動してください",
    "ko": "밀리미터파 레이더 내부 전력 공급 오류. 레이더 재시작",
    "ru": "Ошибка внутреннего источника питания РЛС миллиметрового диапазона. Перезапустите радар",
    "tr": "Milimetre dalga radarı dahili güç kaynağı hatası. Radarı yeniden başlatın",
    "zh": "毫米波雷达内部供电异常，请重启雷达负载"
  },
  "fpv_tip_0x15010022": {
    "de": "Fehler in der internen Stromversorgung des Millimeterwellenradars. Radar neu starten",
    "en": "Millimeter-wave radar internal power supply error. Restart radar",
    "es": "Error de fuente de alimentación interna del radar de ondas milimétricas. Reiniciar radar",
    "fr": "Erreur d’alimentation interne du radar à ondes millimétriques. Redémarrez le radar",
    "ja": "ミリ波レーダー内部電源エラー。レーダーを再起動してください",
    "ko": "밀리미터파 레이더 내부 전력 공급 오류. 레이더 재시작",
    "ru": "Ошибка внутреннего источника питания РЛС миллиметрового диапазона. Перезапустите радар",
    "tr": "Milimetre dalga radarı dahili güç kaynağı hatası. Radarı yeniden başlatın",
    "zh": "毫米波雷达内部供电异常，请重启雷达负载"
  },
  "fpv_tip_0x15010023": {
    "de": "Fehler in der internen Stromversorgung des Millimeterwellenradars. Radar neu starten",
    "en": "Millimeter-wave radar internal power supply error. Restart radar",
    "es": "Error de fuente de alimentación interna del radar de ondas milimétricas. Reiniciar radar",
    "fr": "Erreur d’alimentation interne du radar à ondes millimétriques. Redémarrez le radar",
    "ja": "ミリ波レーダー内部電源エラー。レーダーを再起動してください",
    "ko": "밀리미터파 레이더 내부 전력 공급 오류. 레이더 재시작",
    "ru": "Ошибка внутреннего источника питания РЛС миллиметрового диапазона. Перезапустите радар",
    "tr": "Milimetre dalga radarı dahili güç kaynağı hatası. Radarı yeniden başlatın",
    "zh": "毫米波雷达内部供电异常，请重启雷达负载"
  },
  "fpv_tip_0x15020020": {
    "de": "Motorfehler beim Millimeterwellenradar. Überprüfen, ob das Radar blockiert ist",
    "en": "Millimeter-wave radar motor error. Check whether radar is stalled",
    "es": "Error del motor del radar de ondas milimétricas. Comprueba si el radar está atascado",
    "fr": "Erreur de moteur du radar à ondes millimétriques. Vérifiez si le radar est bloqué",
    "ja": "ミリ波レーダーモーターエラー。レーダーが停止していないかを確認してください",
    "ko": "밀리미터파 레이더 모터 오류. 레이더가 멈췄는지 확인하세요",
    "ru": "Ошибка двигателя РЛС миллиметрового диапазона. Проверьте, не заклинило ли радар",
    "tr": "Milimetre dalga radarı motoru hatası. Radarın durup durmadığını kontrol edin",
    "zh": "毫米波雷达电机异常，请检查雷达是否卡转"
  },
  "fpv_tip_0x15020021": {
    "de": "Motorfehler beim Millimeterwellenradar. Überprüfen, ob das Radar blockiert ist",
    "en": "Millimeter-wave radar motor error. Check whether radar is stalled",
    "es": "Error del motor del radar de ondas milimétricas. Comprueba si el radar está atascado",
    "fr": "Erreur de moteur du radar à ondes millimétriques. Vérifiez si le radar est bloqué",
    "ja": "ミリ波レーダーモーターエラー。レーダーが停止していないかを確認してください",
    "ko": "밀리미터파 레이더 모터 오류. 레이더가 멈췄는지 확인하세요",
    "ru": "Ошибка двигателя РЛС миллиметрового диапазона. Проверьте, не заклинило ли радар",
    "tr": "Milimetre dalga radarı motoru hatası. Radarın durup durmadığını kontrol edin",
    "zh": "毫米波雷达电机异常，请检查雷达是否卡转"
  },
  "fpv_tip_0x15020022": {
    "de": "Motorfehler beim Millimeterwellenradar. Überprüfen, ob das Radar blockiert ist",
    "en": "Millimeter-wave radar motor error. Check whether radar is stalled",
    "es": "Error del motor del radar de ondas milimétricas. Comprueba si el radar está atascado",
    "fr": "Erreur de moteur du radar à ondes millimétriques. Vérifiez si le radar est bloqué",
    "ja": "ミリ波レーダーモーターエラー。レーダーが停止していないかを確認してください",
    "ko": "밀리미터파 레이더 모터 오류. 레이더가 멈췄는지 확인하세요",
    "ru": "Ошибка двигателя РЛС миллиметрового диапазона. Проверьте, не заклинило ли радар",
    "tr": "Milimetre dalga radarı motoru hatası. Radarın durup durmadığını kontrol edin",
    "zh": "毫米波雷达电机异常，请检查雷达是否卡转"
  },
  "fpv_tip_0x15020023": {
    "de": "Erfassungsfehler beim Millimeterwellenradar. Firmware-Version überprüfen",
    "en": "Millimeter-wave radar sensing error. Check firmware version",
    "es": "Error de detección del radar de ondas milimétricas. Comprobar versión del firmware",
    "fr": "Erreur de détection du radar à ondes millimétriques. Vérification de la version du firmware",
    "ja": "ミリ波レーダー検知エラー。ファームウェアのバージョンを確認してください",
    "ko": "밀리미터파 레이더 감지 오류. 펌웨어 버전 확인",
    "ru": "Ошибка датчика РЛС миллиметрового диапазона. Проверьте версию прошивку",
    "tr": "Milimetre dalga radarı algılama hatası. Bellenim sürümünü kontrol edin",
    "zh": "毫米波雷达感知功能异常，请检查固件版本"
  },
  "fpv_tip_0x15030020": {
    "ar": "",
    "de": "Verbindung mit dem Millimeterwellenradar instabil. Umgebung auf starke Interferenzen überprüfen",
    "en": "Millimeter-wave radar link unstable. Check if there is strong interference in the surrounding area",
    "es": "Enlace del radar de ondas milimétricas inestable. Comprueba si hay fuertes interferencias en la zona circundante",
    "fr": "Liaison instable du radar à ondes millimétriques. Vérifiez la présence de fortes interférences dans la zone environnante",
    "id": "",
    "it": "",
    "ja": "ミリ波レーダーのリンクが不安定です。周囲に強い干渉がないかを確認してください",
    "ko": "밀리미터파 레이더 연결 불안정. 주변 영역에 강한 간섭이 있는지 점검",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Нестабильная связь с РЛС миллиметрового диапазона. Проверьте, нет ли сильных помех в области полета",
    "th": "",
    "tr": "Milimetre dalga radarı bağlantısı stabil değil. Çevredeki alanda güçlü bir parazit olup olmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "毫米波雷达链路不稳定，请检查周边是否存在强力干扰",
    "zh-Hant": ""
  },
  "fpv_tip_0x15030021": {
    "ar": "",
    "de": "Verbindung mit dem Millimeterwellenradar instabil. Umgebung auf starke Interferenzen überprüfen",
    "en": "Millimeter-wave radar link unstable. Check if there is strong interference in the surrounding area",
    "es": "Enlace del radar de ondas milimétricas inestable. Comprueba si hay fuertes interferencias en la zona circundante",
    "fr": "Liaison instable du radar à ondes millimétriques. Vérifiez la présence de fortes interférences dans la zone environnante",
    "id": "",
    "it": "",
    "ja": "ミリ波レーダーのリンクが不安定です。周囲に強い干渉がないかを確認してください",
    "ko": "밀리미터파 레이더 연결 불안정. 주변 영역에 강한 간섭이 있는지 점검",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Нестабильная связь с РЛС миллиметрового диапазона. Проверьте, нет ли сильных помех в области полета",
    "th": "",
    "tr": "Milimetre dalga radarı bağlantısı stabil değil. Çevredeki alanda güçlü bir parazit olup olmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "毫米波雷达链路不稳定，请检查周边是否存在强力干扰",
    "zh-Hant": ""
  },
  "fpv_tip_0x15030022": {
    "ar": "",
    "de": "Verbindung mit dem Millimeterwellenradar instabil. Umgebung auf starke Interferenzen überprüfen",
    "en": "Millimeter-wave radar link unstable. Check if there is strong interference in the surrounding area",
    "es": "Enlace del radar de ondas milimétricas inestable. Comprueba si hay fuertes interferencias en la zona circundante",
    "fr": "Liaison instable du radar à ondes millimétriques. Vérifiez la présence de fortes interférences dans la zone environnante",
    "id": "",
    "it": "",
    "ja": "ミリ波レーダーのリンクが不安定です。周囲に強い干渉がないかを確認してください",
    "ko": "밀리미터파 레이더 연결 불안정. 주변 영역에 강한 간섭이 있는지 점검",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Нестабильная связь с РЛС миллиметрового диапазона. Проверьте, нет ли сильных помех в области полета",
    "th": "",
    "tr": "Milimetre dalga radarı bağlantısı stabil değil. Çevredeki alanda güçlü bir parazit olup olmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "毫米波雷达链路不稳定，请检查周边是否存在强力干扰",
    "zh-Hant": ""
  },
  "fpv_tip_0x15030023": {
    "ar": "",
    "de": "Zeitüberschreitung beim Starten des Millimeterwellenradars. Umgebung auf starke Interferenzen überprüfen",
    "en": "Starting millimeter-wave radar timed out. Check if there is strong interference in the surrounding area",
    "es": "Se ha agotado el tiempo de espera de inicio del radar de ondas milimétricas. Comprueba si hay fuertes interferencias en la zona circundante",
    "fr": "Délai de démarrage du radar à ondes millimétriques expiré. Vérifiez la présence de fortes interférences dans la zone environnante",
    "id": "",
    "it": "",
    "ja": "ミリ波レーダーの起動がタイムアウトしました。周囲に強い干渉がないかを確認してください",
    "ko": "밀리미터파 레이더 시작 시간 초과. 주변 영역에 강한 간섭이 있는지 점검",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Истекло время ожидания запуска РЛС миллиметрового диапазона. Проверьте, нет ли сильных помех в области полета",
    "th": "",
    "tr": "Başlangıç milimetre dalga radarı zaman aşımına uğradı. Çevredeki alanda güçlü bir parazit olup olmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "毫米波雷达启动超时，请检查周边是否存在强力干扰",
    "zh-Hant": ""
  },
  "fpv_tip_0x15030024": {
    "ar": "",
    "de": "Kommunikationsverbindung mit dem Millimeterwellenradar instabil. Umgebung auf starke Interferenzen überprüfen",
    "en": "Millimeter-wave radar communication link unstable. Check if there is strong interference in the surrounding area",
    "es": "Enlace de comunicación del radar de ondas milimétricas inestable. Comprueba si hay fuertes interferencias en la zona circundante",
    "fr": "Liaison de communication instable du radar à ondes millimétriques. Vérifiez la présence de fortes interférences dans la zone environnante",
    "id": "",
    "it": "",
    "ja": "ミリ波レーダーの通信リンクが不安定です。周囲に強い干渉がないかを確認してください",
    "ko": "밀리미터파 레이더 통신 연결 불안정. 주변 영역에 강한 간섭이 있는지 점검",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Нестабильная связь с РЛС миллиметрового диапазона. Проверьте, нет ли сильных помех в области полета",
    "th": "",
    "tr": "Milimetre dalga radarının iletişim bağlantısı stabil değil. Çevredeki alanda güçlü bir parazit olup olmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "毫米波雷达通信链路不稳定，请检查周边是否存在强力干扰",
    "zh-Hant": ""
  },
  "fpv_tip_0x15030025": {
    "ar": "",
    "de": "Kommunikationsverbindung mit dem Millimeterwellenradar instabil. Umgebung auf starke Interferenzen überprüfen",
    "en": "Millimeter-wave radar communication link unstable. Check if there is strong interference in the surrounding area",
    "es": "Enlace de comunicación del radar de ondas milimétricas inestable. Comprueba si hay fuertes interferencias en la zona circundante",
    "fr": "Liaison de communication instable du radar à ondes millimétriques. Vérifiez la présence de fortes interférences dans la zone environnante",
    "id": "",
    "it": "",
    "ja": "ミリ波レーダーの通信リンクが不安定です。周囲に強い干渉がないかを確認してください",
    "ko": "밀리미터파 레이더 통신 연결 불안정. 주변 영역에 강한 간섭이 있는지 점검",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Нестабильная связь с РЛС миллиметрового диапазона. Проверьте, нет ли сильных помех в области полета",
    "th": "",
    "tr": "Milimetre dalga radarının iletişim bağlantısı stabil değil. Çevredeki alanda güçlü bir parazit olup olmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "毫米波雷达通信链路不稳定，请检查周边是否存在强力干扰",
    "zh-Hant": ""
  },
  "fpv_tip_0x15030026": {
    "ar": "",
    "de": "Kommunikationsverbindung mit dem Millimeterwellenradar instabil. Umgebung auf starke Interferenzen überprüfen",
    "en": "Millimeter-wave radar communication link unstable. Check if there is strong interference in the surrounding area",
    "es": "Enlace de comunicación del radar de ondas milimétricas inestable. Comprueba si hay fuertes interferencias en la zona circundante",
    "fr": "Liaison de communication instable du radar à ondes millimétriques. Vérifiez la présence de fortes interférences dans la zone environnante",
    "id": "",
    "it": "",
    "ja": "ミリ波レーダーの通信リンクが不安定です。周囲に強い干渉がないかを確認してください",
    "ko": "밀리미터파 레이더 통신 연결 불안정. 주변 영역에 강한 간섭이 있는지 점검",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Нестабильная связь с РЛС миллиметрового диапазона. Проверьте, нет ли сильных помех в области полета",
    "th": "",
    "tr": "Milimetre dalga radarının iletişim bağlantısı stabil değil. Çevredeki alanda güçlü bir parazit olup olmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "毫米波雷达通信链路不稳定，请检查周边是否存在强力干扰",
    "zh-Hant": ""
  },
  "fpv_tip_0x15030028": {
    "en": "%index radar cable error. Obstacle avoidance and altitude stabilization unavailable",
    "zh": "%index雷达线材异常，避障定高功能失效"
  },
  "fpv_tip_0x15040020": {
    "de": "Motorfehler beim Millimeterwellenradar. Überprüfen, ob das Radar blockiert ist",
    "en": "Millimeter-wave radar motor error. Check whether radar is stalled",
    "es": "Error del motor del radar de ondas milimétricas. Comprueba si el radar está atascado",
    "fr": "Erreur de moteur du radar à ondes millimétriques. Vérifiez si le radar est bloqué",
    "ja": "ミリ波レーダーモーターエラー。レーダーが停止していないかを確認してください",
    "ko": "밀리미터파 레이더 모터 오류. 레이더가 멈췄는지 확인하세요",
    "ru": "Ошибка двигателя РЛС миллиметрового диапазона. Проверьте, не заклинило ли радар",
    "tr": "Milimetre dalga radarı motoru hatası. Radarın durup durmadığını kontrol edin",
    "zh": "毫米波雷达电机异常，请检查雷达是否卡转"
  },
  "fpv_tip_0x15040021": {
    "de": "Motorfehler beim Millimeterwellenradar. Überprüfen, ob das Radar blockiert ist",
    "en": "Millimeter-wave radar motor error. Check whether radar is stalled",
    "es": "Error del motor del radar de ondas milimétricas. Comprueba si el radar está atascado",
    "fr": "Erreur de moteur du radar à ondes millimétriques. Vérifiez si le radar est bloqué",
    "ja": "ミリ波レーダーモーターエラー。レーダーが停止していないかを確認してください",
    "ko": "밀리미터파 레이더 모터 오류. 레이더가 멈췄는지 확인하세요",
    "ru": "Ошибка двигателя РЛС миллиметрового диапазона. Проверьте, не заклинило ли радар",
    "tr": "Milimetre dalga radarı motoru hatası. Radarın durup durmadığını kontrol edin",
    "zh": "毫米波雷达电机异常，请检查雷达是否卡转"
  },
  "fpv_tip_0x15060020": {
    "de": "Motorfehler beim Millimeterwellenradar. Überprüfen, ob das Radar blockiert ist",
    "en": "Millimeter-wave radar motor error. Check whether radar is stalled",
    "es": "Error del motor del radar de ondas milimétricas. Comprueba si el radar está atascado",
    "fr": "Erreur de moteur du radar à ondes millimétriques. Vérifiez si le radar est bloqué",
    "ja": "ミリ波レーダーモーターエラー。レーダーが停止していないかを確認してください",
    "ko": "밀리미터파 레이더 모터 오류. 레이더가 멈췄는지 확인하세요",
    "ru": "Ошибка двигателя РЛС миллиметрового диапазона. Проверьте, не заклинило ли радар",
    "tr": "Milimetre dalga radarı motoru hatası. Radarın durup durmadığını kontrol edin",
    "zh": "毫米波雷达电机异常，请检查雷达是否卡转"
  },
  "fpv_tip_0x15070020": {
    "ar": "",
    "de": "Verbindung mit dem Millimeterwellenradar instabil. Umgebung auf starke Interferenzen überprüfen",
    "en": "Millimeter-wave radar link unstable. Check if there is strong interference in the surrounding area",
    "es": "Enlace del radar de ondas milimétricas inestable. Comprueba si hay fuertes interferencias en la zona circundante",
    "fr": "Liaison instable du radar à ondes millimétriques. Vérifiez la présence de fortes interférences dans la zone environnante",
    "id": "",
    "it": "",
    "ja": "ミリ波レーダーのリンクが不安定です。周囲に強い干渉がないかを確認してください",
    "ko": "밀리미터파 레이더 연결 불안정. 주변 영역에 강한 간섭이 있는지 점검",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Нестабильная связь с РЛС миллиметрового диапазона. Проверьте, нет ли сильных помех в области полета",
    "th": "",
    "tr": "Milimetre dalga radarı bağlantısı stabil değil. Çevredeki alanda güçlü bir parazit olup olmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "毫米波雷达通信链路不稳定，请检查周边是否存在强力干扰",
    "zh-Hant": ""
  },
  "fpv_tip_0x15080020": {
    "de": "Millimeterwellenradar überhitzt. Überprüfen, ob die Umgebungstemperatur zu hoch ist",
    "en": "Millimeter-wave radar overheated. Check if ambient temperature is too high",
    "es": "Radar de ondas milimétricas sobrecalentado. Comprueba si la temperatura ambiente es demasiado alta",
    "fr": "Surchauffe du radar à ondes millimétriques. Vérifiez si la température ambiante est trop élevée",
    "ja": "ミリ波レーダーが過熱しています。周囲温度が高すぎるかどうかを確認してください",
    "ko": "밀리미터파 레이더가 과열됨. 주변 온도가 너무 높은지 점검하세요",
    "ru": "Перегрев РЛС миллиметрового диапазона. Проверьте, не слишком ли высокая температура окружающей среды",
    "tr": "Milimetre dalga radarı aşırı ısındı. Ortam sıcaklığının çok yüksek olup olmadığını kontrol edin",
    "zh": "毫米波雷达温度过高，请确认工作环境温度是否过高"
  },
  "fpv_tip_0x15080021": {
    "ar": "",
    "de": "Millimeter-wave radar processor overheated. Obstacle avoidance and altitude stabilization affected",
    "en": "Millimeter-wave radar processor overheated. Obstacle avoidance and altitude stabilization affected",
    "es": "Millimeter-wave radar processor overheated. Obstacle avoidance and altitude stabilization affected",
    "fr": "Millimeter-wave radar processor overheated. Obstacle avoidance and altitude stabilization affected",
    "id": "",
    "it": "",
    "ja": "Millimeter-wave radar processor overheated. Obstacle avoidance and altitude stabilization affected",
    "ko": "Millimeter-wave radar processor overheated. Obstacle avoidance and altitude stabilization affected",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Millimeter-wave radar processor overheated. Obstacle avoidance and altitude stabilization affected",
    "th": "",
    "tr": "Millimeter-wave radar processor overheated. Obstacle avoidance and altitude stabilization affected",
    "ug": "",
    "vi": "",
    "zh": "雷达芯片温度过高，避障定高性能衰退",
    "zh-Hant": ""
  },
  "fpv_tip_0x15080022": {
    "ar": "",
    "de": "Millimeter-wave radar processor overheated. Obstacle avoidance and altitude stabilization affected",
    "en": "Millimeter-wave radar processor overheated. Obstacle avoidance and altitude stabilization affected",
    "es": "Millimeter-wave radar processor overheated. Obstacle avoidance and altitude stabilization affected",
    "fr": "Millimeter-wave radar processor overheated. Obstacle avoidance and altitude stabilization affected",
    "id": "",
    "it": "",
    "ja": "Millimeter-wave radar processor overheated. Obstacle avoidance and altitude stabilization affected",
    "ko": "Millimeter-wave radar processor overheated. Obstacle avoidance and altitude stabilization affected",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Millimeter-wave radar processor overheated. Obstacle avoidance and altitude stabilization affected",
    "th": "",
    "tr": "Millimeter-wave radar processor overheated. Obstacle avoidance and altitude stabilization affected",
    "ug": "",
    "vi": "",
    "zh": "雷达芯片温度过高，避障定高性能衰退",
    "zh-Hant": ""
  },
  "fpv_tip_0x15090020": {
    "de": "Millimeterwellenradar überhitzt. Überprüfen, ob die Umgebungstemperatur zu hoch ist",
    "en": "Millimeter-wave radar overheated. Check if ambient temperature is too high",
    "es": "Radar de ondas milimétricas sobrecalentado. Comprueba si la temperatura ambiente es demasiado alta",
    "fr": "Surchauffe du radar à ondes millimétriques. Vérifiez si la température ambiante est trop élevée",
    "ja": "ミリ波レーダーが過熱しています。周囲温度が高すぎるかどうかを確認してください",
    "ko": "밀리미터파 레이더가 과열됨. 주변 온도가 너무 높은지 점검하세요",
    "ru": "Перегрев РЛС миллиметрового диапазона. Проверьте, не слишком ли высокая температура окружающей среды",
    "tr": "Milimetre dalga radarı aşırı ısındı. Ortam sıcaklığının çok yüksek olup olmadığını kontrol edin",
    "zh": "毫米波雷达温度过高，请确认工作环境温度是否过高"
  },
  "fpv_tip_0x15090021": {
    "de": "Millimeterwellenradarfehler. Hinderniserkennung nicht verfügbar",
    "en": "Millimeter-wave radar error. Obstacle sensing unavailable",
    "es": "Error del radar de ondas milimétricas. Detección de obstáculos no disponible",
    "fr": "Erreur du radar à ondes millimétriques. Détection d’obstacles indisponible",
    "ja": "ミリ波レーダーエラー。障害物回避は利用できません",
    "ko": "밀리미터파 레이더 오류. 장애물 감지 사용 불가",
    "ru": "Ошибка РЛС миллиметрового диапазона. Обнаружение препятствий недоступно",
    "tr": "Milimetre dalga radarı hatası. Engel algılama kullanılamıyor",
    "zh": "毫米波雷达功能异常，避障功能失效"
  },
  "fpv_tip_0x15100020": {
    "ar": "",
    "de": "Kommunikationsverbindung mit dem Millimeterwellenradar instabil. Umgebung auf starke Interferenzen überprüfen",
    "en": "Millimeter-wave radar communication link unstable. Check if there is strong interference in the surrounding area",
    "es": "Enlace de comunicación del radar de ondas milimétricas inestable. Comprueba si hay fuertes interferencias en la zona circundante",
    "fr": "Liaison de communication instable du radar à ondes millimétriques. Vérifiez la présence de fortes interférences dans la zone environnante",
    "id": "",
    "it": "",
    "ja": "ミリ波レーダーの通信リンクが不安定です。周囲に強い干渉がないかを確認してください",
    "ko": "밀리미터파 레이더 통신 연결 불안정. 주변 영역에 강한 간섭이 있는지 점검",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Нестабильная связь с РЛС миллиметрового диапазона. Проверьте, нет ли сильных помех в области полета",
    "th": "",
    "tr": "Milimetre dalga radarının iletişim bağlantısı stabil değil. Çevredeki alanda güçlü bir parazit olup olmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "毫米波雷达通信链路不稳定，请检查周边是否存在强力干扰",
    "zh-Hant": ""
  },
  "fpv_tip_0x15100021": {
    "ar": "",
    "de": "Verbindung mit dem Millimeterwellenradar instabil. Umgebung auf starke Interferenzen überprüfen",
    "en": "Millimeter-wave radar link unstable. Check if there is strong interference in the surrounding area",
    "es": "Enlace del radar de ondas milimétricas inestable. Comprueba si hay fuertes interferencias en la zona circundante",
    "fr": "Liaison instable du radar à ondes millimétriques. Vérifiez la présence de fortes interférences dans la zone environnante",
    "id": "",
    "it": "",
    "ja": "ミリ波レーダーのリンクが不安定です。周囲に強い干渉がないかを確認してください",
    "ko": "밀리미터파 레이더 연결 불안정. 주변 영역에 강한 간섭이 있는지 점검",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Нестабильная связь с РЛС миллиметрового диапазона. Проверьте, нет ли сильных помех в области полета",
    "th": "",
    "tr": "Milimetre dalga radarı bağlantısı stabil değil. Çevredeki alanda güçlü bir parazit olup olmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "毫米波雷达通信链路不稳定，请检查周边是否存在强力干扰",
    "zh-Hant": ""
  },
  "fpv_tip_0x15100022": {
    "ar": "",
    "de": "Verbindung mit dem Millimeterwellenradar instabil. Umgebung auf starke Interferenzen überprüfen",
    "en": "Millimeter-wave radar link unstable. Check if there is strong interference in the surrounding area",
    "es": "Enlace del radar de ondas milimétricas inestable. Comprueba si hay fuertes interferencias en la zona circundante",
    "fr": "Liaison instable du radar à ondes millimétriques. Vérifiez la présence de fortes interférences dans la zone environnante",
    "id": "",
    "it": "",
    "ja": "ミリ波レーダーのリンクが不安定です。周囲に強い干渉がないかを確認してください",
    "ko": "밀리미터파 레이더 연결 불안정. 주변 영역에 강한 간섭이 있는지 점검",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Нестабильная связь с РЛС миллиметрового диапазона. Проверьте, нет ли сильных помех в области полета",
    "th": "",
    "tr": "Milimetre dalga radarı bağlantısı stabil değil. Çevredeki alanda güçlü bir parazit olup olmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "毫米波雷达通信链路不稳定，请检查周边是否存在强力干扰",
    "zh-Hant": ""
  },
  "fpv_tip_0x15110020": {
    "de": "Motorfehler beim Millimeterwellenradar. Überprüfen, ob das Radar blockiert ist",
    "en": "Millimeter-wave radar motor error. Check whether radar is stalled",
    "es": "Error del motor del radar de ondas milimétricas. Comprueba si el radar está atascado",
    "fr": "Erreur de moteur du radar à ondes millimétriques. Vérifiez si le radar est bloqué",
    "ja": "ミリ波レーダーモーターエラー。レーダーが停止していないかを確認してください",
    "ko": "밀리미터파 레이더 모터 오류. 레이더가 멈췄는지 확인하세요",
    "ru": "Ошибка двигателя РЛС миллиметрового диапазона. Проверьте, не заклинило ли радар",
    "tr": "Milimetre dalga radarı motoru hatası. Radarın durup durmadığını kontrol edin",
    "zh": "毫米波雷达电机异常，请检查雷达是否卡转"
  },
  "fpv_tip_0x15130021": {
    "de": "Erfassungsfehler beim Millimeterwellenradar. Firmware-Version überprüfen",
    "en": "Millimeter-wave radar sensing error. Check firmware version",
    "es": "Error de detección del radar de ondas milimétricas. Comprobar versión del firmware",
    "fr": "Erreur de détection du radar à ondes millimétriques. Vérification de la version du firmware",
    "ja": "ミリ波レーダー検知エラー。ファームウェアのバージョンを確認してください",
    "ko": "밀리미터파 레이더 감지 오류. 펌웨어 버전 확인",
    "ru": "Ошибка датчика РЛС миллиметрового диапазона. Проверьте версию прошивку",
    "tr": "Milimetre dalga radarı algılama hatası. Bellenim sürümünü kontrol edin",
    "zh": "毫米波雷达感知功能异常，请检查固件版本"
  },
  "fpv_tip_0x15140020": {
    "de": "Millimeterwellenradar-Taktgeberstörung. Umgehend zu Startpunkt zurückfliegen und Radar überprüfen",
    "en": "Millimeter-wave radar RF clock error. Return to home promptly and check radar",
    "es": "Error del reloj de RF del radar de ondas milimétricas. Regresa al punto de origen inmediatamente y revisa el radar",
    "fr": "Erreur d’horloge RF du radar à ondes millimétriques. Revenez rapidement chez vous et vérifiez le radar",
    "ja": "ミリ波レーダーRFクロックエラー。直ちに帰還して、レーダーを確認してください",
    "ko": "밀리미터파 레이더 RF 클록 오류. 즉시 리턴 투 홈을 실행하고 레이더를 점검하세요",
    "ru": "Ошибка часов РЛС миллиметрового диапазона. Немедленно вернитесь домой и проверьте радар",
    "tr": "Milimetre dalga radarı RF saati hatası. Derhal kalkış noktasına dönün ve radarı kontrol edin",
    "zh": "毫米波雷达射频时钟异常，请立即返航并检查雷达负载"
  },
  "fpv_tip_0x15220020": {
    "ar": "",
    "de": "",
    "en": "Backward and downward radar disconnected. Restart aircraft or check and replace radar",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "后下雷达断连，请重启或检查更换",
    "zh-Hant": ""
  },
  "fpv_tip_0x15300020": {
    "de": "Erfassungsfehler beim Millimeterwellenradar. Firmware-Version überprüfen",
    "en": "Millimeter-wave radar sensing error. Check firmware version",
    "es": "Error de detección del radar de ondas milimétricas. Comprobar versión del firmware",
    "fr": "Erreur de détection du radar à ondes millimétriques. Vérification de la version du firmware",
    "ja": "ミリ波レーダー検知エラー。ファームウェアのバージョンを確認してください",
    "ko": "밀리미터파 레이더 감지 오류. 펌웨어 버전 확인",
    "ru": "Ошибка датчика РЛС миллиметрового диапазона. Проверьте версию прошивку",
    "tr": "Milimetre dalga radarı algılama hatası. Bellenim sürümünü kontrol edin",
    "zh": "毫米波雷达感知功能异常，请检查固件版本"
  },
  "fpv_tip_0x15300021": {
    "de": "Erfassungsfehler beim Millimeterwellenradar. Firmware-Version überprüfen",
    "en": "Millimeter-wave radar sensing error. Check firmware version",
    "es": "Error de detección del radar de ondas milimétricas. Comprobar versión del firmware",
    "fr": "Erreur de détection du radar à ondes millimétriques. Vérification de la version du firmware",
    "ja": "ミリ波レーダー検知エラー。ファームウェアのバージョンを確認してください",
    "ko": "밀리미터파 레이더 감지 오류. 펌웨어 버전 확인",
    "ru": "Ошибка датчика РЛС миллиметрового диапазона. Проверьте версию прошивку",
    "tr": "Milimetre dalga radarı algılama hatası. Bellenim sürümünü kontrol edin",
    "zh": "毫米波雷达感知功能异常，请检查固件版本"
  },
  "fpv_tip_0x15300022": {
    "de": "Erfassungsfehler beim Millimeterwellenradar. Firmware-Version überprüfen",
    "en": "Millimeter-wave radar sensing error. Check firmware version",
    "es": "Error de detección del radar de ondas milimétricas. Comprobar versión del firmware",
    "fr": "Erreur de détection du radar à ondes millimétriques. Vérification de la version du firmware",
    "ja": "ミリ波レーダー検知エラー。ファームウェアのバージョンを確認してください",
    "ko": "밀리미터파 레이더 감지 오류. 펌웨어 버전 확인",
    "ru": "Ошибка датчика РЛС миллиметрового диапазона. Проверьте версию прошивку",
    "tr": "Milimetre dalga radarı algılama hatası. Bellenim sürümünü kontrol edin",
    "zh": "毫米波雷达感知功能异常，请检查固件版本"
  },
  "fpv_tip_0x16000001": {
    "de": "Flugregler überlastet. Start nicht möglich. Fluggerät neu starten",
    "en": "Flight controller overloaded. Unable to take off. Restart aircraft",
    "es": "Controlador de vuelo sobrecargado. No se puede despegar. Reinicia la aeronave",
    "fr": "Contrôleur du vol surchargé. Décollage impossible. Redémarrez l\\'appareil",
    "ja": "フライトコントローラー過負荷状態。離陸できません。機体を再起動してください",
    "ko": "비행 컨트롤러 과부하. 이륙할 수 없습니다. 기체 재시동",
    "ru": "Полетный контроллер перегружен. Невозможно выполнить взлет. Перезапустите дрон",
    "tr": "Uçuş kumandası aşırı yüklü. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：飞控系统负载高，请重启飞行器"
  },
  "fpv_tip_0x16000001_in_the_sky": {
    "de": "Flugregler überlastet. Wenn das Problem weiterhin besteht, umgehend mit dem Fluggerät landen",
    "en": "Flight controller overloaded. If the issue persists, land aircraft promptly",
    "es": "Controlador de vuelo sobrecargado. Si el problema continúa, aterriza la aeronave rápidamente",
    "fr": "Contrôleur de vol surchargé. Si le problème persiste, atterrissez immédiatement",
    "ja": "フライトコントローラー過負荷状態。問題が解決しない場合は、直ちに機体を着陸させてください",
    "ko": "비행 컨트롤러 과부하. 문제가 지속되면 신속히 기체를 착륙하세요",
    "ru": "Полетный контроллер перегружен. Если проблема не устранена, приземлите дрон как можно скорее",
    "tr": "Uçuş kumandası aşırı yüklü. Sorun devam ederse hemen aracı indirin",
    "zh": "飞控系统负载高，如若持续存在请尽快降落"
  },
  "fpv_tip_0x16000002": {
    "de": "Fehler: Flugdatenschreiber. Bitte an Händler vor Ort oder an DJI Support wenden",
    "en": "Flight data recording error. Contact your local dealer or DJI Support",
    "es": "Error al grabar los datos de vuelo . Contacta con el distribuidor local o la asistencia técnica de DJI",
    "fr": "Erreur d\\'enregistrement des données de vol. Contactez votre revendeur local ou le Service client DJI",
    "it": "",
    "ja": "飛行データ記録エラー。最寄りの代理店またはDJIサポートにお問い合わせください",
    "ko": "비행 데이터 기록 오류. 현지 딜러 또는 DJI 고객지원으로 문의해주세요",
    "pt": "",
    "ru": "Ошибка записи полетных данных. Свяжитесь с местным представителем или службой поддержки DJI",
    "tr": "Uçuş veri kaydı hatası. Bölgenizdeki satıcı veya DJI Destek ile iletişime geçin",
    "zh": "飞行数据记录异常，请尽快联系就近代理商或大疆售后服务。"
  },
  "fpv_tip_0x16000002_in_the_sky": {
    "de": "Anormale Flugdatenaufzeichnung. Sollte diese Warnung fortbestehen, nehmen Sie bitte Kontakt mit dem DJI Support oder Ihrem Händler auf.",
    "en": "Flight data record abnorma.If the warning persists, contact your local dealer or DJI Support.",
    "es": "Registro de datos de vuelo anómalo. Si la advertencia se sigue mostrando, ponte en contacto con el distribuidor local o la asistencia técnica de DJI.",
    "fr": "Historique des données de vol anormal. Si le problème persiste, contactez votre revendeur local ou le service client DJI.",
    "ja": "飛行データ記録異常。警告が消えない場合、最寄りの代理店またはDJIサポートにお問い合わせください。",
    "ko": "비행 데이터 기록 비정상. 경고가 지속되면 현지 딜러 또는 DJI 고객지원으로 문의해주세요.",
    "ru": "Ошибка записи данных полета. Если предупреждение не устранено, обратитесь к местному представителю или в службу поддержки DJI.",
    "zh": "飞行数据记录异常，请尽快联系就近代理商或大疆售后服务。"
  },
  "fpv_tip_0x16010001": {
    "de": "Fehler: Sensorsystem . Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs . Redémarrez l\\'appareil",
    "ja": "センサーシステムエラー。機体を再起動してください",
    "ko": "센서 시스템 오류 . 기체 재시작 필요",
    "ru": "Ошибка системы датчиков . Перезагрузите дрон",
    "tr": "Sensör sistemi hatası. Aracı yeniden başlatın",
    "zh": "传感器系统异常，请重启飞行器"
  },
  "fpv_tip_0x16010001_in_the_sky": {
    "de": "Fehler: Sensorsystem . Zum Startpunkt zurückkehren oder landen.",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur système de capteurs . Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか、着陸してください",
    "ko": "센서 시스템 오류 . RTH 진행 또는 착륙 필요",
    "ru": "Ошибка системы датчиков . Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常,请返航或降落"
  },
  "fpv_tip_0x16010005": {
    "de": "Initialisierung des Sensorsystems . Bitte warten bis die Initialisierung abgeschlossen ist",
    "en": "Sensor system initializing. Wait for initialization to complete",
    "es": "Iniciando sistema de sensores. Espera a que se complete el inicio",
    "fr": "Initialisation du système de capteurs . Attendez que l\\'initialisation se termine",
    "ja": "センサーシステム初期化中。初期化の完了を待ってください",
    "ko": "센서 시스템 초기화 중 . 초기화가 완료될 때까지 기다려야 함",
    "ru": "Запускается система датчиков . Дождитесь завершения запуска",
    "tr": "Sensör sistemi başlatılıyor. Başlatmanın tamamlanmasını bekleyin",
    "zh": "传感器系统初始化中，请等待初始化完成"
  },
  "fpv_tip_0x16010005_in_the_sky": {
    "de": "Fehler: Sensorsystem. Zum Startpunkt zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur du système de capteurs. Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか、着陸してください",
    "ko": "센서 시스템 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка системы датчиков. Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常,请返航或降落"
  },
  "fpv_tip_0x16010007": {
    "de": "Fehler im Sensorsystem. Fluggerät neu starten.",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicie la aeronave",
    "fr": "Erreur du système de capteur. Redémarrer l'appareil",
    "ja": "センサーシステムエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작",
    "ru": "Ошибка системы датчика. Перезапустите дрон",
    "tr": "Sensör sistemi hatası. Hava aracını yeniden başlatın",
    "zh": "传感器系统异常，请重启飞行器"
  },
  "fpv_tip_0x16010007_in_the_sky": {
    "de": "Fehler: Sensorsystem. Zum Startpunkt zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur du système de capteurs. Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか、着陸してください",
    "ko": "센서 시스템 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка системы датчиков. Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常,请返航或降落"
  },
  "fpv_tip_0x1601000A": {
    "de": "Initialisierung des Sensorsystems. Bitte auf Abschluss der Initialisierung warten",
    "en": "Sensor system initializing. Wait for initialization to complete",
    "es": "Iniciando sistema de sensores. Espera a que se complete el inicio",
    "fr": "Initialisation du système de capteurs. Attendez que l\\'initialisation se termine",
    "ja": "センサーシステム初期化中。初期化が完了するまでお待ちください",
    "ko": "센서 시스템 초기화 중. 초기화가 완료될 때까지 대기",
    "ru": "Инициализация системы датчика. Дождитесь завершения инициализации",
    "tr": "Sensör sistemi başlatılıyor. Başlatmanın tamamlanmasını bekleyin",
    "zh": "传感器系统初始化中，请等待初始化完成"
  },
  "fpv_tip_0x1601000A_in_the_sky": {
    "de": "Fehler: Sensorsystem. Zum Startpunkt zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur système capteur. Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか着陸して下さい",
    "ko": "센서 시스템 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка системы датчиков. Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常,请返航或降落"
  },
  "fpv_tip_0x1601000D": {
    "de": "Fehler: Sensorsystem. Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Redémarrez l\\'appareil",
    "ja": "センサーシステムエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Перезагрузите дрон",
    "tr": "Sensör sistemi hatası. Aracı yeniden başlatın",
    "zh": "传感器系统异常，请重启飞行器"
  },
  "fpv_tip_0x1601000D_in_the_sky": {
    "de": "Fehler: Sensorsystem. Zum Startpunkt zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur système capteur. Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか着陸して下さい",
    "ko": "센서 시스템 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка системы датчиков. Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常,请返航或降落"
  },
  "fpv_tip_0x16010010": {
    "de": "Fehler: Sensorsystem. Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Redémarrez l\\'appareil",
    "ja": "センサーシステムエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Перезагрузите дрон",
    "tr": "Sensör sistemi hatası. Aracı yeniden başlatın",
    "zh": "传感器系统异常，请重启飞行器"
  },
  "fpv_tip_0x16010010_in_the_sky": {
    "de": "Fehler: Sensorsystem. Zum Startpunkt zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur système capteur. Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか着陸して下さい",
    "ko": "센서 시스템 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка системы датчиков. Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常,请返航或降落"
  },
  "fpv_tip_0x16010013": {
    "de": "Fehler: Sensorsystem. Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Redémarrez l\\'appareil",
    "ja": "センサーシステムエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Перезагрузите дрон",
    "tr": "Sensör sistemi hatası. Aracı yeniden başlatın",
    "zh": "传感器系统异常，请重启飞行器"
  },
  "fpv_tip_0x16010013_in_the_sky": {
    "de": "Fehler: Sensorsystem. Zum Startpunkt zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur système capteur. Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか着陸して下さい",
    "ko": "센서 시스템 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка системы датчиков. Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常,请返航或降落"
  },
  "fpv_tip_0x16010016": {
    "de": "Fehler: Sensorsystem. Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Redémarrez l\\'appareil",
    "ja": "センサーシステムエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Перезагрузите дрон",
    "tr": "Sensör sistemi hatası. Aracı yeniden başlatın",
    "zh": "传感器系统异常，请重启飞行器"
  },
  "fpv_tip_0x16010016_in_the_sky": {
    "de": "Fehler: Sensorsystem. Zum Startpunkt zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur système capteur. Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか着陸して下さい",
    "ko": "센서 시스템 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка системы датчиков. Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常,请返航或降落"
  },
  "fpv_tip_0x16010019": {
    "de": "Fehler: Sensorsystem. Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Redémarrez l\\'appareil",
    "ja": "センサーシステムエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Перезагрузите дрон",
    "tr": "Sensör sistemi hatası. Aracı yeniden başlatın",
    "zh": "传感器系统异常，请重启飞行器"
  },
  "fpv_tip_0x16010019_in_the_sky": {
    "de": "Fehler: Sensorsystem. Zum Startpunkt zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur système capteur. Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか着陸して下さい",
    "ko": "센서 시스템 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка системы датчиков. Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常,请返航或降落"
  },
  "fpv_tip_0x1601001C": {
    "de": "Fehler: Sensorsystem. Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Redémarrez l\\'appareil",
    "ja": "センサーシステムエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Перезагрузите дрон",
    "tr": "Sensör sistemi hatası. Aracı yeniden başlatın",
    "zh": "传感器系统异常，请重启飞行器"
  },
  "fpv_tip_0x1601001C_in_the_sky": {
    "de": "Fehler: Sensorsystem. Zum Startpunkt zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur système capteur. Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか着陸して下さい",
    "ko": "센서 시스템 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка системы датчиков. Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常,请返航或降落"
  },
  "fpv_tip_0x1601001F": {
    "de": "Fehler: Sensorsystem . Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs . Redémarrez l\\'appareil",
    "ja": "センサーシステムエラー。機体を再起動してください",
    "ko": "센서 시스템 오류 . 기체 재시작 필요",
    "ru": "Ошибка системы датчиков . Перезагрузите дрон",
    "tr": "Sensör sistemi hatası. Aracı yeniden başlatın",
    "zh": "传感器系统异常，请重启飞行器"
  },
  "fpv_tip_0x1601001F_in_the_sky": {
    "de": "Not Applicable. This string has been deleted",
    "en": "Not Applicable. This string has been deleted",
    "es": "Not Applicable. This string has been deleted",
    "fr": "Non applicable. Cette chaîne a été supprimée",
    "ja": "該当なし。この文字列は削除されました",
    "ko": "해당 없음. 이 문자열은 삭제되었음",
    "ru": "Не применимо. Эта строка была удалена",
    "tr": "Uygulanamaz. Bu metin silindi",
    "zh": "传感器系统异常,请返航或降落"
  },
  "fpv_tip_0x16010022": {
    "de": "Fehler: Sensorsystem. Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Redémarrez l\\'appareil",
    "ja": "センサーシステムエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Перезагрузите дрон",
    "tr": "Sensör sistemi hatası. Aracı yeniden başlatın",
    "zh": "传感器系统异常，请重启飞行器"
  },
  "fpv_tip_0x16010022_in_the_sky": {
    "de": "Fehler: Sensorsystem. Zum Startpunkt zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur système capteur. Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか着陸して下さい",
    "ko": "센서 시스템 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка системы датчиков. Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常,请返航或降落"
  },
  "fpv_tip_0x16010025": {
    "de": "Hot Swapping wird für das RTK-Modul nicht unterstützt. Start nicht möglich.",
    "en": "Hot swapping not supported for RTK module. Unable to take off",
    "es": "El intercambio en caliente no es compatible con el módulo RTK. No se puede despegar",
    "fr": "Le remplacement à chaud n'est pas pris en charge pour le module RTK. Impossible de décoller",
    "ja": "ホットスワップはRTKモジュールではサポートされていません。離陸できません",
    "ko": "RTK 모듈에서는 핫 스와핑이 지원되지 않습니다. 이륙 불가",
    "ru": "Горячая замена не поддерживается для модуля RTK. Не удается выполнить взлет",
    "tr": "RTK modülü için çalışırken değiştirme desteklenmiyor. Kalkış yapılamıyor",
    "zh": "无法起飞：RTK模块不支持热插拔"
  },
  "fpv_tip_0x16010025_in_the_sky": {
    "de": "RTK-Fehler. Umgehend landen.",
    "en": "RTK error. Land promptly",
    "es": "Error de RTK. Aterrice rápidamente",
    "fr": "Erreur RTK. Atterrissez immédiatement",
    "ja": "RTKエラーです。直ちに着陸させてください",
    "ko": "RTK 오류. 즉시 착륙하세요",
    "ru": "Ошибка RTK. Немедленно выполните посадку",
    "tr": "RTK hatası. Derhal iniş yapın",
    "zh": "RTK异常，请尽快降落"
  },
  "fpv_tip_0x16010026": {
    "ar": "",
    "de": "",
    "en": "Unable to take off. Non-DJI RTK module detected",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "无法起飞：检测到非官方RTK模块",
    "zh-Hant": ""
  },
  "fpv_tip_0x16010026_in_the_sky": {
    "ar": "",
    "de": "",
    "en": "Non-DJI RTK module detected",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "检测到非官方RTK模块",
    "zh-Hant": ""
  },
  "fpv_tip_0x16010028": {
    "de": "Fehler: Sensorsystem. Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Redémarrez l\\'appareil",
    "ja": "センサーシステムエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Перезагрузите дрон",
    "tr": "Sensör sistemi hatası. Aracı yeniden başlatın",
    "zh": "传感器系统异常，请重启飞行器"
  },
  "fpv_tip_0x16010028_in_the_sky": {
    "de": "Fehler: Sensorsystem. Zum Startpunkt zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur système capteur. Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか着陸して下さい",
    "ko": "센서 시스템 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка системы датчиков. Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常,请返航或降落"
  },
  "fpv_tip_0x1601002B": {
    "de": "Fehler: Sensorsystem. Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Redémarrez l\\'appareil",
    "ja": "センサーシステムエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Перезагрузите дрон",
    "tr": "Sensör sistemi hatası. Aracı yeniden başlatın",
    "zh": "传感器系统异常，请重启飞行器"
  },
  "fpv_tip_0x1601002B_in_the_sky": {
    "de": "Fehler: Sensorsystem. Zum Startpunkt zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur système capteur. Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか着陸して下さい",
    "ko": "센서 시스템 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка системы датчиков. Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常,请返航或降落"
  },
  "fpv_tip_0x1601002F": {
    "de": "Fehler: Sensorsystem. Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Redémarrez l\\'appareil",
    "ja": "センサーシステムエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Перезагрузите дрон",
    "tr": "Sensör sistemi hatası. Aracı yeniden başlatın",
    "zh": "传感器系统异常，请重启飞行器"
  },
  "fpv_tip_0x1601002F_in_the_sky": {
    "de": "Fehler: Sensorsystem. Zum Startpunkt zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur système capteur. Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか着陸して下さい",
    "ko": "센서 시스템 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка системы датчиков. Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常,请返航或降落"
  },
  "fpv_tip_0x16010032": {
    "de": "Fehler: Sensorsystem. Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Redémarrez l\\'appareil",
    "ja": "センサーシステムエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Перезагрузите дрон",
    "tr": "Sensör sistemi hatası. Aracı yeniden başlatın",
    "zh": "传感器系统异常，请重启飞行器"
  },
  "fpv_tip_0x16010032_in_the_sky": {
    "de": "Fehler: Sensorsystem. Zum Startpunkt zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur système capteur. Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか着陸して下さい",
    "ko": "센서 시스템 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка системы датчиков. Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常,请返航或降落"
  },
  "fpv_tip_0x16010033": {
    "de": "Erkennung der Sichtpositionierung schlecht. Vorsichtig fliegen",
    "en": "Vision positioning detection poor. Fly with caution",
    "es": "Detección de posicionamiento de visión deficiente. Vuela con cuidado",
    "fr": "Mauvaise détection du positionnement visuel. Pilotez avec précaution",
    "ja": "ビジョンポジショニングの検出不良。慎重に飛行してください",
    "ko": "비전 포지셔닝 감지 불량. 비행 시 주의 필요",
    "ru": "Ненадлежащее обнаружение с помощью визуального позиционирования. Соблюдайте осторожность при полете",
    "tr": "Görüş konumlandırma algılaması zayıf. Dikkatli uçun",
    "zh": "视觉定位观测较差，请注意飞行安全"
  },
  "fpv_tip_0x16010034": {
    "de": "GNSS-Erkennung schlecht. Vorsichtig fliegen",
    "en": "GNSS detection poor. Fly with caution",
    "es": "Detección de GNSS deficiente. Vuela con cuidado",
    "fr": "Mauvaise détection de GNSS. Pilotez avec précaution",
    "ja": "GNSSの検出不良。慎重に飛行してください",
    "ko": "GNSS 감지 불량. 비행 시 주의 필요",
    "ru": "Ненадлежащее обнаружение с помощью GNSS. Соблюдайте осторожность при полете",
    "tr": "GNSS algılaması zayıf. Dikkatli uçun",
    "zh": "卫星定位观测较差，请注意飞行安全"
  },
  "fpv_tip_0x16010035": {
    "de": "Positionierungsqualität schlecht. Vorsichtig fliegen",
    "en": "Positioning quality poor. Fly with caution",
    "es": "Calidad de posicionamiento deficiente. Vuela con cuidado",
    "fr": "Mauvaise qualité du positionnement. Pilotez avec précaution",
    "ja": "ポジショニングの品質不良。慎重に飛行してください",
    "ko": "포지셔닝 품질 불량. 비행 시 주의 필요",
    "ru": "Ненадлежащее позиционирование. Соблюдайте осторожность при полете",
    "tr": "Konumlandırma kalitesi zayıf. Dikkatli uçun",
    "zh": "定位质量较差，请注意飞行安全"
  },
  "fpv_tip_0x16010036": {
    "de": "Erkennung der Sichtpositionierung schlecht. Vorsichtig fliegen",
    "en": "Vision positioning detection poor. Fly with caution",
    "es": "Detección de posicionamiento de visión deficiente. Vuela con cuidado",
    "fr": "Mauvaise détection du positionnement visuel. Pilotez avec précaution",
    "ja": "ビジョンポジショニングの検出不良。慎重に飛行してください",
    "ko": "비전 포지셔닝 감지 불량. 비행 시 주의 필요",
    "ru": "Ненадлежащее обнаружение с помощью визуального позиционирования. Соблюдайте осторожность при полете",
    "tr": "Görüş konumlandırma algılaması zayıf. Dikkatli uçun",
    "zh": "视觉定位观测不佳，请注意飞行安全"
  },
  "fpv_tip_0x16010037": {
    "de": "GNSS-Erkennung schlecht. Vorsichtig fliegen",
    "en": "GNSS detection poor. Fly with caution",
    "es": "Detección de GNSS deficiente. Vuela con cuidado",
    "fr": "Mauvaise détection de GNSS. Pilotez avec précaution",
    "ja": "GNSSの検出不良。慎重に飛行してください",
    "ko": "GNSS 감지 불량. 비행 시 주의 필요",
    "ru": "Ненадлежащее обнаружение с помощью GNSS. Соблюдайте осторожность при полете",
    "tr": "GNSS algılaması zayıf. Dikkatli uçun",
    "zh": "卫星定位观测不佳，请注意飞行安全"
  },
  "fpv_tip_0x16010038": {
    "de": "Positionierungsqualität schlecht. Vorsichtig fliegen",
    "en": "Positioning quality poor. Fly with caution",
    "es": "Calidad de posicionamiento deficiente. Vuela con cuidado",
    "fr": "Mauvaise qualité du positionnement. Pilotez avec précaution",
    "ja": "ポジショニングの品質不良。慎重に飛行してください",
    "ko": "포지셔닝 품질 불량. 비행 시 주의 필요",
    "ru": "Ненадлежащее позиционирование. Соблюдайте осторожность при полете",
    "tr": "Konumlandırma kalitesi zayıf. Dikkatli uçun",
    "zh": "定位质量不佳，请注意飞行安全"
  },
  "fpv_tip_0x16010039": {
    "de": "Kein GNSS verfügbar. Auf Satellitensignal warten. Vorsichtig fliegen",
    "en": "No GNSS available. Wait for searching satellite signal. Fly with caution",
    "es": "No hay GNSS disponible. Espera a que se encuentre la señal del satélite. Vuela con cuidado",
    "fr": "Aucun GNSS disponible. Attendez la recherche du signal satellite. Pilotez avec précaution",
    "ja": "GNSSを使用できません。衛星信号をサーチ中です。お待ちください。慎重に飛行してください",
    "ko": "사용 가능한 GNSS 없음. 위성 신호를 검색할 때까지 기다리세요. 비행 시 주의 필요",
    "ru": "GNSS недоступна. Дождитесь поиска спутникового сигнала. Соблюдайте осторожность при полете",
    "tr": "GNSS yok. Uydu sinyali aranmasını bekleyin. Dikkatli uçun",
    "zh": "无卫星定位，请等待搜星，注意飞行安全"
  },
  "fpv_tip_0x16010041": {
    "de": "Fehler: Sensorsystem. Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Redémarrez l\\'appareil",
    "ja": "センサーシステムエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Перезагрузите дрон",
    "tr": "Sensör sistemi hatası. Aracı yeniden başlatın",
    "zh": "传感器系统异常，请重启飞行器"
  },
  "fpv_tip_0x16010041_in_the_sky": {
    "de": "Fehler: Sensorsystem. Zum Startpunkt zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur système capteur. Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか着陸して下さい",
    "ko": "센서 시스템 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка системы датчиков. Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常,请返航或降落"
  },
  "fpv_tip_0x16010050": {
    "en": "",
    "zh": "当前仅使用雷达定位，请勿在狭窄走廊、隧道等环境飞行"
  },
  "fpv_tip_0x16020001": {
    "de": "Fehler: IMU . Fluggerät neu starten",
    "en": "IMU error. Restart aircraft",
    "es": "Error de IMU. Reinicia la aeronave",
    "fr": "Erreur IMU . Redémarrez l\\'appareil",
    "ja": "IMUエラー。機体を再起動してください",
    "ko": "IMU 오류 . 기체 재시작 필요",
    "ru": "Ошибка IMU . Перезагрузите дрон",
    "tr": "IMU hatası. Aracı yeniden başlatın",
    "zh": "IMU异常，请重启飞行器"
  },
  "fpv_tip_0x16020001_in_the_sky": {
    "de": "Fehler: IMU . Zum Startpunk zurückkehren oder landen.",
    "en": "IMU error. Return to home or land",
    "es": "Error de IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur IMU . Revenez au point de départ ou atterrissez",
    "ja": "IMUエラー。帰還するか、着陸してください",
    "ko": "IMU 오류 . RTH 진행 또는 착륙 필요",
    "ru": "Ошибка IMU . Вернитесь домой или приземлитесь",
    "tr": "IMU hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU异常,请返航或降落"
  },
  "fpv_tip_0x16020004": {
    "de": "Fehler: IMU. Fluggerät neu starten",
    "en": "IMU error. Restart aircraft",
    "es": "Error de IMU. Reinicia la aeronave",
    "fr": "Erreur IMU. Redémarrez l\\'appareil",
    "ja": "IMUエラー。機体を再起動してください",
    "ko": "IMU 오류. 기체 재시작 필요",
    "ru": "Ошибка модуля IMU. Перезагрузите дрон",
    "tr": "IMU hatası. Aracı yeniden başlatın",
    "zh": "IMU异常，请重启飞行器"
  },
  "fpv_tip_0x16020004_in_the_sky": {
    "de": "Fehler: IMU. Zum Startpunkt zurückkehren oder landen",
    "en": "IMU error. Return to home or land",
    "es": "Error de IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur IMU. Revenez au point de départ ou atterrissez",
    "ja": "IMUエラー。帰還するか着陸してください",
    "ko": "IMU 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка модуля IMU. Вернитесь домой или приземлитесь",
    "tr": "IMU hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU异常,请返航或降落"
  },
  "fpv_tip_0x16020007": {
    "de": "Fehler: IMU. Fluggerät neu starten",
    "en": "IMU error. Restart aircraft",
    "es": "Error de IMU. Reinicia la aeronave",
    "fr": "Erreur IMU. Redémarrez l\\'appareil",
    "ja": "IMUエラー。機体を再起動してください",
    "ko": "IMU 오류. 기체 재시작 필요",
    "ru": "Ошибка модуля IMU. Перезагрузите дрон",
    "tr": "IMU hatası. Aracı yeniden başlatın",
    "zh": "IMU异常，请重启飞行器"
  },
  "fpv_tip_0x16020007_in_the_sky": {
    "de": "Fehler: IMU. Zum Startpunkt zurückkehren oder landen",
    "en": "IMU error. Return to home or land",
    "es": "Error de IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur IMU. Revenez au point de départ ou atterrissez",
    "ja": "IMUエラー。帰還するか着陸してください",
    "ko": "IMU 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка модуля IMU. Вернитесь домой или приземлитесь",
    "tr": "IMU hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU异常,请返航或降落"
  },
  "fpv_tip_0x1602000A": {
    "de": "Fehler: IMU. Fluggerät neu starten",
    "en": "IMU error. Restart aircraft",
    "es": "Error de IMU. Reinicia la aeronave",
    "fr": "Erreur IMU. Redémarrez l\\'appareil",
    "ja": "IMUエラー。機体を再起動してください",
    "ko": "IMU 오류. 기체 재시작 필요",
    "ru": "Ошибка модуля IMU. Перезагрузите дрон",
    "tr": "IMU hatası. Aracı yeniden başlatın",
    "zh": "IMU异常，请重启飞行器"
  },
  "fpv_tip_0x1602000A_in_the_sky": {
    "de": "Fehler: IMU. Zum Startpunkt zurückkehren oder landen",
    "en": "IMU error. Return to home or land",
    "es": "Error de IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur IMU. Revenez au point de départ ou atterrissez",
    "ja": "IMUエラー。帰還するか着陸してください",
    "ko": "IMU 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка модуля IMU. Вернитесь домой или приземлитесь",
    "tr": "IMU hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU异常,请返航或降落"
  },
  "fpv_tip_0x1602000D": {
    "de": "Fehler: IMU. Fluggerät neu starten",
    "en": "IMU error. Restart aircraft",
    "es": "Error de IMU. Reinicia la aeronave",
    "fr": "Erreur IMU. Redémarrez l\\'appareil",
    "ja": "IMUエラー。機体を再起動してください",
    "ko": "IMU 오류. 기체 재시작 필요",
    "ru": "Ошибка модуля IMU. Перезагрузите дрон",
    "tr": "IMU hatası. Aracı yeniden başlatın",
    "zh": "IMU异常，请重启飞行器"
  },
  "fpv_tip_0x1602000D_in_the_sky": {
    "de": "Fehler: IMU. Zum Startpunkt zurückkehren oder landen",
    "en": "IMU error. Return to home or land",
    "es": "Error de IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur IMU. Revenez au point de départ ou atterrissez",
    "ja": "IMUエラー。帰還するか着陸してください",
    "ko": "IMU 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка модуля IMU. Вернитесь домой или приземлитесь",
    "tr": "IMU hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU异常,请返航或降落"
  },
  "fpv_tip_0x16020016": {
    "de": "Abweichung des IMU zu groß . IMU kalibrieren",
    "en": "IMU bias too large. Calibrate IMU",
    "es": "Desviación de la IMU demasiado grande. Calibra la IMU",
    "fr": "Écart IMU trop grand . Étalonnez l\\'IMU",
    "ja": "IMUバイアスが大きすぎます。IMUをキャリブレーションしてください",
    "ko": "IMU 바이어스 오차 너무 큼 . IMU 캘리브레이션 필요",
    "ru": "Слишком большое смещение IMU . Откалибруйте модуль",
    "tr": "IMU sapması çok büyük. IMU’yu Kalibre Et",
    "zh": "IMU偏差过大，请校准 IMU"
  },
  "fpv_tip_0x16020016_in_the_sky": {
    "de": "Fehler: IMU. Zum Startpunkt zurückkehren oder landen",
    "en": "IMU error. Return to home or land",
    "es": "Error de IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur IMU. Revenez au point de départ ou atterrissez",
    "ja": "IMUエラー。帰還するか、着陸してください",
    "ko": "IMU 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка модуля IMU. Вернитесь домой или приземлитесь",
    "tr": "IMU hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU异常,请返航或降落"
  },
  "fpv_tip_0x16020027": {
    "de": "Fehler: IMU. Fluggerät neu starten",
    "en": "IMU error. Restart aircraft",
    "es": "Error de IMU. Reinicia la aeronave",
    "fr": "Erreur IMU. Redémarrez l\\'appareil",
    "ja": "IMUエラー。機体を再起動してください",
    "ko": "IMU 오류. 기체 재시작 필요",
    "ru": "Ошибка модуля IMU. Перезагрузите дрон",
    "tr": "IMU hatası. Aracı yeniden başlatın",
    "zh": "IMU异常，请重启飞行器"
  },
  "fpv_tip_0x16020027_in_the_sky": {
    "de": "Fehler: IMU. Zum Startpunkt zurückkehren oder landen",
    "en": "IMU error. Return to home or land",
    "es": "Error de IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur IMU. Revenez au point de départ ou atterrissez",
    "ja": "IMUエラー。帰還するか着陸してください",
    "ko": "IMU 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка модуля IMU. Вернитесь домой или приземлитесь",
    "tr": "IMU hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU异常,请返航或降落"
  },
  "fpv_tip_0x1602002A": {
    "de": "Fehler: IMU. Fluggerät neu starten",
    "en": "IMU error. Restart aircraft",
    "es": "Error de IMU. Reinicia la aeronave",
    "fr": "Erreur IMU. Redémarrez l\\'appareil",
    "ja": "IMUエラー。機体を再起動してください",
    "ko": "IMU 오류. 기체 재시작 필요",
    "ru": "Ошибка модуля IMU. Перезагрузите дрон",
    "tr": "IMU hatası. Aracı yeniden başlatın",
    "zh": "IMU异常，请重启飞行器"
  },
  "fpv_tip_0x1602002A_in_the_sky": {
    "de": "Fehler: IMU. Zum Startpunkt zurückkehren oder landen",
    "en": "IMU error. Return to home or land",
    "es": "Error de IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur IMU. Revenez au point de départ ou atterrissez",
    "ja": "IMUエラー。帰還するか着陸してください",
    "ko": "IMU 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка модуля IMU. Вернитесь домой или приземлитесь",
    "tr": "IMU hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU异常,请返航或降落"
  },
  "fpv_tip_0x1602002B": {
    "en": "",
    "zh": "陀螺温度控制异常，无法保证恒温"
  },
  "fpv_tip_0x16020620": {
    "de": "Rückkehrfunktion (RTH) abgebrochen. Steuerknüppel nach unten gedrückt",
    "en": "RTH canceled. Control stick pushed down",
    "es": "RPO cancelado. Palanca de control presionada hacia abajo.",
    "fr": "RTH annulé. Joystick poussé vers le bas",
    "ja": "RTHがキャンセル。操作スティックが倒されました",
    "ko": "RTH 취소됨. 조종 스틱이 아래로 눌림",
    "ru": "Возврат на базу отменен. Джойстик наклонен вниз",
    "tr": "BND iptal edildi. Kontrol çubuğu aşağı bastırıldı",
    "zh": "返航中断：检测到反向杆量"
  },
  "fpv_tip_0x16020621": {
    "de": "Rückkehrfunktion (RTH) abgebrochen. Hindernis erkannt",
    "en": "RTH canceled. Obstacle detected",
    "es": "RPO cancelado. Obstáculo detectado.",
    "fr": "RTH annulé. Obstacle détecté",
    "ja": "RTHがキャンセル。障害物が検知されました",
    "ko": "RTH 취소됨. 장애물 감지됨",
    "ru": "Возврат на базу отменен. Обнаружено препятствие",
    "tr": "BND iptal edildi. Engel tespit edildi",
    "zh": "返航中断：检测到障碍物"
  },
  "fpv_tip_0x16020622": {
    "de": "Rückkehrfunktion (RTH) abgebrochen. Hohe Windgeschwindigkeit oder Fluggerät nähert sich einer GEO-Zone",
    "en": "RTH canceled. Strong wind or aircraft approaching GEO Zone",
    "es": "RPO cancelado. Viento fuerte o aeronave acercándose a la zona GEO.",
    "fr": "RTH annulé. Vent fort ou appareil approchant une zone GEO",
    "ja": "RTHがキャンセル。機体が強風下にあるかGEO区域に接近中です",
    "ko": "RTH 취소됨. 강한 바람 또는 기체가 GEO 구역에 접근 중",
    "ru": "Возврат на базу отменен. Сильный ветер или дрон приближается к зоне GEO",
    "tr": "BND iptal edildi. Güçlü rüzgar veya hava aracı GEO Bölgesine yaklaşıyor",
    "zh": "返航中断：风速过大或有限飞区"
  },
  "fpv_tip_0x16020623": {
    "de": "Rückkehrfunktion (RTH) abgebrochen. GPS-Fehler",
    "en": "RTH canceled. GPS error",
    "es": "RPO cancelado. Error de GPS.",
    "fr": "RTH annulé. Erreur GPS",
    "ja": "RTHがキャンセル。GPSエラー",
    "ko": "RTH 취소됨. GPS 오류",
    "ru": "Возврат на базу отменен. Ошибка GPS",
    "tr": "BND iptal edildi. GPS hatası",
    "zh": "返航中断：GPS异常"
  },
  "fpv_tip_0x16030001": {
    "de": "Fehler: IMU. Fluggerät neu starten",
    "en": "IMU error. Restart aircraft",
    "es": "Error de IMU. Reinicia la aeronave",
    "fr": "Erreur IMU. Redémarrez l\\'appareil",
    "ja": "IMUエラー。機体を再起動してください",
    "ko": "IMU 오류. 기체 재시작 필요",
    "ru": "Ошибка модуля IMU. Перезагрузите дрон",
    "tr": "IMU hatası. Aracı yeniden başlatın",
    "zh": "IMU异常，请重启飞行器"
  },
  "fpv_tip_0x16030001_in_the_sky": {
    "de": "Fehler: IMU. Zum Startpunkt zurückkehren oder landen",
    "en": "IMU error. Return to home or land",
    "es": "Error de IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur IMU. Revenez au point de départ ou atterrissez",
    "ja": "IMUエラー。帰還するか着陸してください",
    "ko": "IMU 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка модуля IMU. Вернитесь домой или приземлитесь",
    "tr": "IMU hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU异常,请返航或降落"
  },
  "fpv_tip_0x16030004": {
    "de": "Fehler: IMU. Fluggerät neu starten",
    "en": "IMU error. Restart aircraft",
    "es": "Error de IMU. Reinicia la aeronave",
    "fr": "Erreur IMU. Redémarrez l\\'appareil",
    "ja": "IMUエラー。機体を再起動してください",
    "ko": "IMU 오류. 기체 재시작 필요",
    "ru": "Ошибка модуля IMU. Перезагрузите дрон",
    "tr": "IMU hatası. Aracı yeniden başlatın",
    "zh": "IMU异常，请重启飞行器"
  },
  "fpv_tip_0x16030004_in_the_sky": {
    "de": "Fehler: IMU. Zum Startpunkt zurückkehren oder landen",
    "en": "IMU error. Return to home or land",
    "es": "Error de IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur IMU. Revenez au point de départ ou atterrissez",
    "ja": "IMUエラー。帰還するか着陸してください",
    "ko": "IMU 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка модуля IMU. Вернитесь домой или приземлитесь",
    "tr": "IMU hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU异常,请返航或降落"
  },
  "fpv_tip_0x16030007": {
    "de": "Fehler: IMU. Fluggerät neu starten",
    "en": "IMU error. Restart aircraft",
    "es": "Error de IMU. Reinicia la aeronave",
    "fr": "Erreur IMU. Redémarrez l\\'appareil",
    "ja": "IMUエラー。機体を再起動してください",
    "ko": "IMU 오류. 기체 재시작 필요",
    "ru": "Ошибка модуля IMU. Перезагрузите дрон",
    "tr": "IMU hatası. Aracı yeniden başlatın",
    "zh": "IMU异常，请重启飞行器"
  },
  "fpv_tip_0x16030007_in_the_sky": {
    "de": "Fehler: IMU. Zum Startpunkt zurückkehren oder landen",
    "en": "IMU error. Return to home or land",
    "es": "Error de IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur IMU. Revenez au point de départ ou atterrissez",
    "ja": "IMUエラー。帰還するか着陸してください",
    "ko": "IMU 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка модуля IMU. Вернитесь домой или приземлитесь",
    "tr": "IMU hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU异常,请返航或降落"
  },
  "fpv_tip_0x1603000A": {
    "de": "Fehler: IMU. Fluggerät neu starten",
    "en": "IMU error. Restart aircraft",
    "es": "Error de IMU. Reinicia la aeronave",
    "fr": "Erreur IMU. Redémarrez l\\'appareil",
    "ja": "IMUエラー。機体を再起動してください",
    "ko": "IMU 오류. 기체 재시작 필요",
    "ru": "Ошибка модуля IMU. Перезагрузите дрон",
    "tr": "IMU hatası. Aracı yeniden başlatın",
    "zh": "IMU异常，请重启飞行器"
  },
  "fpv_tip_0x1603000A_in_the_sky": {
    "de": "Fehler: IMU. Zum Startpunkt zurückkehren oder landen",
    "en": "IMU error. Return to home or land",
    "es": "Error de IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur IMU. Revenez au point de départ ou atterrissez",
    "ja": "IMUエラー。帰還するか着陸してください",
    "ko": "IMU 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка модуля IMU. Вернитесь домой или приземлитесь",
    "tr": "IMU hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU异常,请返航或降落"
  },
  "fpv_tip_0x1603000D": {
    "de": "Fehler: IMU. Fluggerät neu starten",
    "en": "IMU error. Restart aircraft",
    "es": "Error de IMU. Reinicia la aeronave",
    "fr": "Erreur IMU. Redémarrez l\\'appareil",
    "ja": "IMUエラー。機体を再起動してください",
    "ko": "IMU 오류. 기체 재시작 필요",
    "ru": "Ошибка модуля IMU. Перезагрузите дрон",
    "tr": "IMU hatası. Aracı yeniden başlatın",
    "zh": "IMU异常，请重启飞行器"
  },
  "fpv_tip_0x1603000D_in_the_sky": {
    "de": "Fehler: IMU. Zum Startpunkt zurückkehren oder landen",
    "en": "IMU error. Return to home or land",
    "es": "Error de IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur IMU. Revenez au point de départ ou atterrissez",
    "ja": "IMUエラー。帰還するか着陸してください",
    "ko": "IMU 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка модуля IMU. Вернитесь домой или приземлитесь",
    "tr": "IMU hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU异常,请返航或降落"
  },
  "fpv_tip_0x16030010": {
    "de": "Fehler: IMU. Fluggerät neu starten",
    "en": "IMU error. Restart aircraft",
    "es": "Error de IMU. Reinicia la aeronave",
    "fr": "Erreur IMU. Redémarrez l\\'appareil",
    "ja": "IMUエラー。機体を再起動してください",
    "ko": "IMU 오류. 기체 재시작 필요",
    "ru": "Ошибка модуля IMU. Перезагрузите дрон",
    "tr": "IMU hatası. Aracı yeniden başlatın",
    "zh": "IMU异常，请重启飞行器"
  },
  "fpv_tip_0x16030010_in_the_sky": {
    "de": "Fehler: IMU. Zum Startpunkt zurückkehren oder landen",
    "en": "IMU error. Return to home or land",
    "es": "Error de IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur IMU. Revenez au point de départ ou atterrissez",
    "ja": "IMUエラー。帰還するか着陸してください",
    "ko": "IMU 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка модуля IMU. Вернитесь домой или приземлитесь",
    "tr": "IMU hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU异常,请返航或降落"
  },
  "fpv_tip_0x16030013": {
    "de": "Fehler: IMU. Fluggerät neu starten",
    "en": "IMU error. Restart aircraft",
    "es": "Error de IMU. Reinicia la aeronave",
    "fr": "Erreur IMU. Redémarrez l\\'appareil",
    "ja": "IMUエラー。機体を再起動してください",
    "ko": "IMU 오류. 기체 재시작 필요",
    "ru": "Ошибка модуля IMU. Перезагрузите дрон",
    "tr": "IMU hatası. Aracı yeniden başlatın",
    "zh": "IMU异常，请重启飞行器"
  },
  "fpv_tip_0x16030013_in_the_sky": {
    "de": "Fehler: IMU. Zum Startpunkt zurückkehren oder landen",
    "en": "IMU error. Return to home or land",
    "es": "Error de IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur IMU. Revenez au point de départ ou atterrissez",
    "ja": "IMUエラー。帰還するか着陸してください",
    "ko": "IMU 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка модуля IMU. Вернитесь домой или приземлитесь",
    "tr": "IMU hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU异常,请返航或降落"
  },
  "fpv_tip_0x16030016": {
    "de": "IMU-Abweichung zu groß. IMU kalibrieren",
    "en": "IMU bias too large. Calibrate IMU",
    "es": "Desviación de la IMU demasiado grande. Calibra la IMU",
    "fr": "IMU bias too large. Calibrate IMU",
    "ja": "IMU bias too large . Calibrate IMU",
    "ko": "IMU 바이어스 오차가 너무 큼. IMU 캘리브레이션 필요",
    "ru": "Смещение модуля IMU слишком велико. Откалибруйте модуль IMU",
    "tr": "IMU sapması çok büyük. IMU’yu Kalibre Et",
    "zh": "IMU偏差过大，请校准 IMU"
  },
  "fpv_tip_0x16030016_in_the_sky": {
    "de": "Fehler: IMU. Zum Startpunkt zurückkehren oder landen",
    "en": "IMU error. Return to home or land",
    "es": "Error de IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur IMU. Revenez au point de départ ou atterrissez",
    "ja": "IMUエラー。帰還するか着陸してください",
    "ko": "IMU 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка модуля IMU. Вернитесь домой или приземлитесь",
    "tr": "IMU hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU异常,请返航或降落"
  },
  "fpv_tip_0x1603001C": {
    "de": "Fehler: IMU. Fluggerät neu starten",
    "en": "IMU error. Restart aircraft",
    "es": "Error de IMU. Reinicia la aeronave",
    "fr": "Erreur IMU. Redémarrez l\\'appareil",
    "ja": "IMUエラー。機体を再起動してください",
    "ko": "IMU 오류. 기체 재시작 필요",
    "ru": "Ошибка модуля IMU. Перезагрузите дрон",
    "tr": "IMU hatası. Aracı yeniden başlatın",
    "zh": "IMU异常，请重启飞行器"
  },
  "fpv_tip_0x1603001C_in_the_sky": {
    "de": "Fehler: IMU. Zum Startpunkt zurückkehren oder landen",
    "en": "IMU error. Return to home or land",
    "es": "Error de IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur IMU. Revenez au point de départ ou atterrissez",
    "ja": "IMUエラー。帰還するか着陸してください",
    "ko": "IMU 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка модуля IMU. Вернитесь домой или приземлитесь",
    "tr": "IMU hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU异常,请返航或降落"
  },
  "fpv_tip_0x1603001D": {
    "de": "Abnormale Vibrationen des Fluggeräts. Umgehend zum Startpunkt zurückkehren oder landen.",
    "en": "Abnormal aircraft vibration. Return to home or land promptly",
    "es": "Vibración anormal de la aeronave. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Vibrations anormales de l'appareil. Retourner au point de départ ou atterrir rapidement",
    "ja": "機体の異常な振動。直ちに帰還するか、着陸してください",
    "ko": "비정상적 기체 진동. 즉시 리턴 투 홈을 실행하거나 착륙하세요",
    "ru": "Аномальная вибрация дрона. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "Anormal araç titreşimi. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "飞行器异常振动，请尽快返航或降落"
  },
  "fpv_tip_0x16040001": {
    "de": "Fehler: Barometer . Fluggerät neu starten",
    "en": "Barometer error. Restart aircraft",
    "es": "Error del barómetro. Reinicia la aeronave",
    "fr": "Erreur baromètre . Redémarrez l\\'appareil",
    "ja": "気圧計エラー。機体を再起動してください",
    "ko": "기압계 오류 . 기체 재시작 필요",
    "ru": "Ошибка барометра . Перезагрузите дрон",
    "tr": "Barometre hatası. Aracı yeniden başlatın",
    "zh": "气压计异常，请重启飞行器"
  },
  "fpv_tip_0x16040001_in_the_sky": {
    "de": "Fehler: Barometer . Zum Startpunk zurückkehren oder landen.",
    "en": "Barometer error. Return to home or land",
    "es": "Error del barómetro. Regresa al punto de origen o aterriza",
    "fr": "Erreur baromètre . Revenez au point de départ ou atterrissez",
    "ja": "気圧計エラー。帰還するか、着陸してください",
    "ko": "기압계 오류 . RTH 진행 또는 착륙 필요",
    "ru": "Ошибка барометра . Вернитесь домой или приземлитесь",
    "tr": "Barometre hatası. Eve geri dönün veya iniş yapın",
    "zh": "气压计异常,请返航或降落"
  },
  "fpv_tip_0x16040004": {
    "de": "Fehler: Barometer. Fluggerät neu starten",
    "en": "Barometer error. Restart aircraft",
    "es": "Error del barómetro. Reinicia la aeronave",
    "fr": "Erreur baromètre. Redémarrez l\\'appareil",
    "ja": "気圧計エラー。機体を再起動してください",
    "ko": "기압계 오류. 기체 재시작 필요",
    "ru": "Ошибка барометра. Перезагрузите дрон",
    "tr": "Barometre hatası. Aracı yeniden başlatın",
    "zh": "气压计异常，请重启飞行器"
  },
  "fpv_tip_0x16040004_in_the_sky": {
    "de": "Fehler: Barometer. Zum Startpunkt zurückkehren oder landen",
    "en": "Barometer error. Return to home or land",
    "es": "Error del barómetro. Regresa al punto de origen o aterriza",
    "fr": "Erreur baromètre. Revenez au point de départ ou atterrissez",
    "ja": "気圧計エラー。帰還するか着陸してください",
    "ko": "기압계 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка барометра. Вернитесь домой или приземлитесь",
    "tr": "Barometre hatası. Eve geri dönün veya iniş yapın",
    "zh": "气压计异常,请返航或降落"
  },
  "fpv_tip_0x16040007": {
    "de": "Fehler: Barometer. Fluggerät neu starten",
    "en": "Barometer error. Restart aircraft",
    "es": "Error del barómetro. Reinicia la aeronave",
    "fr": "Erreur baromètre. Redémarrez l\\'appareil",
    "ja": "気圧計エラー。機体を再起動してください",
    "ko": "기압계 오류. 기체 재시작 필요",
    "ru": "Ошибка барометра. Перезагрузите дрон",
    "tr": "Barometre hatası. Aracı yeniden başlatın",
    "zh": "气压计异常，请重启飞行器"
  },
  "fpv_tip_0x16040007_in_the_sky": {
    "de": "Fehler: Barometer. Zum Startpunkt zurückkehren oder landen",
    "en": "Barometer error. Return to home or land",
    "es": "Error del barómetro. Regresa al punto de origen o aterriza",
    "fr": "Erreur baromètre. Revenez au point de départ ou atterrissez",
    "ja": "気圧計エラー。帰還するか着陸してください",
    "ko": "기압계 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка барометра. Вернитесь домой или приземлитесь",
    "tr": "Barometre hatası. Eve geri dönün veya iniş yapın",
    "zh": "气压计异常,请返航或降落"
  },
  "fpv_tip_0x1604000A": {
    "de": "Fehler: Barometer. Fluggerät neu starten",
    "en": "Barometer error. Restart aircraft",
    "es": "Error del barómetro. Reinicia la aeronave",
    "fr": "Erreur baromètre. Redémarrez l\\'appareil",
    "ja": "気圧計エラー。機体を再起動してください",
    "ko": "기압계 오류. 기체 재시작 필요",
    "ru": "Ошибка барометра. Перезагрузите дрон",
    "tr": "Barometre hatası. Aracı yeniden başlatın",
    "zh": "气压计异常，请重启飞行器"
  },
  "fpv_tip_0x1604000A_in_the_sky": {
    "de": "Fehler: Barometer. Zum Startpunkt zurückkehren oder landen",
    "en": "Barometer error. Return to home or land",
    "es": "Error del barómetro. Regresa al punto de origen o aterriza",
    "fr": "Erreur baromètre. Revenez au point de départ ou atterrissez",
    "ja": "気圧計エラー。帰還するか着陸してください",
    "ko": "기압계 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка барометра. Вернитесь домой или приземлитесь",
    "tr": "Barometre hatası. Eve geri dönün veya iniş yapın",
    "zh": "气压计异常,请返航或降落"
  },
  "fpv_tip_0x16040010": {
    "de": "Fehler: Barometer. Fluggerät neu starten",
    "en": "Barometer error. Restart aircraft",
    "es": "Error del barómetro. Reinicia la aeronave",
    "fr": "Erreur baromètre. Redémarrez l\\'appareil",
    "ja": "気圧計エラー。機体を再起動してください",
    "ko": "기압계 오류. 기체 재시작 필요",
    "ru": "Ошибка барометра. Перезагрузите дрон",
    "tr": "Barometre hatası. Aracı yeniden başlatın",
    "zh": "气压计异常，请重启飞行器"
  },
  "fpv_tip_0x16040010_in_the_sky": {
    "de": "Fehler: Barometer. Zum Startpunkt zurückkehren oder landen",
    "en": "Barometer error. Return to home or land",
    "es": "Error del barómetro. Regresa al punto de origen o aterriza",
    "fr": "Erreur baromètre. Revenez au point de départ ou atterrissez",
    "ja": "気圧計エラー。帰還するか着陸してください",
    "ko": "기압계 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка барометра. Вернитесь домой или приземлитесь",
    "tr": "Barometre hatası. Eve geri dönün veya iniş yapın",
    "zh": "气压计异常,请返航或降落"
  },
  "fpv_tip_0x16040013": {
    "de": "Fehler: Barometer. Fluggerät neu starten",
    "en": "Barometer error. Restart aircraft",
    "es": "Error del barómetro. Reinicia la aeronave",
    "fr": "Erreur baromètre. Redémarrez l\\'appareil",
    "ja": "気圧計エラー。機体を再起動してください",
    "ko": "기압계 오류. 기체 재시작 필요",
    "ru": "Ошибка барометра. Перезагрузите дрон",
    "tr": "Barometre hatası. Aracı yeniden başlatın",
    "zh": "气压计异常，请重启飞行器"
  },
  "fpv_tip_0x16040013_in_the_sky": {
    "de": "Fehler: Barometer. Zum Startpunkt zurückkehren oder landen",
    "en": "Barometer error. Return to home or land",
    "es": "Error del barómetro. Regresa al punto de origen o aterriza",
    "fr": "Erreur baromètre. Revenez au point de départ ou atterrissez",
    "ja": "気圧計エラー。帰還するか着陸してください",
    "ko": "기압계 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка барометра. Вернитесь домой или приземлитесь",
    "tr": "Barometre hatası. Eve geri dönün veya iniş yapın",
    "zh": "气压计异常,请返航或降落"
  },
  "fpv_tip_0x16040016": {
    "de": "Fehler: Barometer. Fluggerät neu starten",
    "en": "Barometer error. Restart aircraft",
    "es": "Error del barómetro. Reinicia la aeronave",
    "fr": "Erreur baromètre. Redémarrez l\\'appareil",
    "ja": "気圧計エラー。機体を再起動してください",
    "ko": "기압계 오류. 기체 재시작 필요",
    "ru": "Ошибка барометра. Перезагрузите дрон",
    "tr": "Barometre hatası. Aracı yeniden başlatın",
    "zh": "气压计异常，请重启飞行器"
  },
  "fpv_tip_0x16040016_in_the_sky": {
    "de": "Fehler: Barometer. Zum Startpunkt zurückkehren oder landen",
    "en": "Barometer error. Return to home or land",
    "es": "Error del barómetro. Regresa al punto de origen o aterriza",
    "fr": "Erreur baromètre. Revenez au point de départ ou atterrissez",
    "ja": "気圧計エラー。帰還するか着陸してください",
    "ko": "기압계 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка барометра. Вернитесь домой или приземлитесь",
    "tr": "Barometre hatası. Eve geri dönün veya iniş yapın",
    "zh": "气压计异常,请返航或降落"
  },
  "fpv_tip_0x16040019": {
    "de": "Fehler: Barometer. Fluggerät neu starten",
    "en": "Barometer error. Restart aircraft",
    "es": "Error del barómetro. Reinicia la aeronave",
    "fr": "Erreur baromètre. Redémarrez l\\'appareil",
    "ja": "気圧計エラー。機体を再起動してください",
    "ko": "기압계 오류. 기체 재시작 필요",
    "ru": "Ошибка барометра. Перезагрузите дрон",
    "tr": "Barometre hatası. Aracı yeniden başlatın",
    "zh": "气压计异常，请重启飞行器"
  },
  "fpv_tip_0x16040019_in_the_sky": {
    "de": "Fehler: Barometer. Zum Startpunkt zurückkehren oder landen",
    "en": "Barometer error. Return to home or land",
    "es": "Error del barómetro. Regresa al punto de origen o aterriza",
    "fr": "Erreur baromètre. Revenez au point de départ ou atterrissez",
    "ja": "気圧計エラー。帰還するか着陸してください",
    "ko": "기압계 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка барометра. Вернитесь домой или приземлитесь",
    "tr": "Barometre hatası. Eve geri dönün veya iniş yapın",
    "zh": "气压计异常,请返航或降落"
  },
  "fpv_tip_0x16050001": {
    "de": "GPS-Fehler. Fluggerät neu starten",
    "en": "GPS error. Restart aircraft",
    "es": "Error de GPS. Reinicia la aeronave",
    "fr": "Erreur GPS. Redémarrez l\\'appareil",
    "ja": "GPSエラー。機体を再起動してください",
    "ko": "GPS 오류. 기체 재시작 필요",
    "ru": "Ошибка GPS. Перезагрузите дрон",
    "tr": "GPS hatası. Aracı yeniden başlatın",
    "zh": "GPS异常，请重启飞行器"
  },
  "fpv_tip_0x16050001_in_the_sky": {
    "de": "GPS-Fehler. Zum Startpunkt zurückkehren oder landen",
    "en": "GPS error. Return to home or land",
    "es": "Error de GPS. Regresa al punto de origen o aterriza",
    "fr": "Erreur GPS. Revenez au point de départ ou atterrissez",
    "ja": "GPSエラー。帰還するか着陸してください",
    "ko": "GPS 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка GPS. Вернитесь домой или приземлитесь",
    "tr": "GPS hatası. Eve geri dönün veya iniş yapın",
    "zh": "GPS异常,请返航或降落"
  },
  "fpv_tip_0x16050004": {
    "de": "GPS-Fehler. Fluggerät neu starten",
    "en": "GPS error. Restart aircraft",
    "es": "Error de GPS. Reinicia la aeronave",
    "fr": "Erreur GPS. Redémarrez l\\'appareil",
    "ja": "GPSエラー。機体を再起動してください",
    "ko": "GPS 오류. 기체 재시작 필요",
    "ru": "Ошибка GPS. Перезагрузите дрон",
    "tr": "GPS hatası. Aracı yeniden başlatın",
    "zh": "GPS异常，请重启飞行器"
  },
  "fpv_tip_0x16050004_in_the_sky": {
    "de": "GPS-Fehler. Zum Startpunkt zurückkehren oder landen",
    "en": "GPS error. Return to home or land",
    "es": "Error de GPS. Regresa al punto de origen o aterriza",
    "fr": "Erreur GPS. Revenez au point de départ ou atterrissez",
    "ja": "GPSエラー。帰還するか着陸してください",
    "ko": "GPS 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка GPS. Вернитесь домой или приземлитесь",
    "tr": "GPS hatası. Eve geri dönün veya iniş yapın",
    "zh": "GPS异常,请返航或降落"
  },
  "fpv_tip_0x16050019": {
    "de": "Fehler: GPS . Fluggerät neu starten",
    "en": "GPS error. Restart aircraft",
    "es": "Error de GPS. Reinicia la aeronave",
    "fr": "Erreur GPS . Redémarrez l\\'appareil",
    "ja": "GPSエラー。機体を再起動してください",
    "ko": "GPS 오류 . 기체 재시작 필요",
    "ru": "Ошибка GPS . Перезагрузите дрон",
    "tr": "GPS hatası. Aracı yeniden başlatın",
    "zh": "GPS异常，请重启飞行器"
  },
  "fpv_tip_0x16050019_in_the_sky": {
    "de": "Fehler: GPS . Zum Startpunkt zurückkehren oder landen.",
    "en": "GPS error. Return to home or land",
    "es": "Error de GPS. Regresa al punto de origen o aterriza",
    "fr": "Erreur GPS . Revenez au point de départ ou atterrissez",
    "ja": "GPSエラー。帰還するか、着陸してください",
    "ko": "GPS 오류 . RTH 진행 또는 착륙 필요",
    "ru": "Ошибка GPS . Вернитесь домой или приземлитесь",
    "tr": "GPS hatası. Eve geri dönün veya iniş yapın",
    "zh": "GPS异常,请返航或降落"
  },
  "fpv_tip_0x1605001C": {
    "de": "GPS-Fehler. Fluggerät neu starten",
    "en": "GPS error. Restart aircraft",
    "es": "Error de GPS. Reinicia la aeronave",
    "fr": "Erreur GPS. Redémarrez l\\'appareil",
    "ja": "GPSエラー。機体を再起動してください",
    "ko": "GPS 오류. 기체 재시작 필요",
    "ru": "Ошибка GPS. Перезагрузите дрон",
    "tr": "GPS hatası. Aracı yeniden başlatın",
    "zh": "GPS异常，请重启飞行器"
  },
  "fpv_tip_0x1605001C_in_the_sky": {
    "de": "GPS-Fehler. Zum Startpunkt zurückkehren oder landen",
    "en": "GPS error. Return to home or land",
    "es": "Error de GPS. Regresa al punto de origen o aterriza",
    "fr": "Erreur GPS. Revenez au point de départ ou atterrissez",
    "ja": "GPSエラー。帰還するか着陸してください",
    "ko": "GPS 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка GPS. Вернитесь домой или приземлитесь",
    "tr": "GPS hatası. Eve geri dönün veya iniş yapın",
    "zh": "GPS异常,请返航或降落"
  },
  "fpv_tip_0x1605001F": {
    "de": "GPS-Signalstörungen. Mit Vorsicht fliegen",
    "en": "GPS signal interference. Fly with caution",
    "es": "Interferencia de señal GPS. Vuele con cuidado.",
    "fr": "Interférence de signal GPS. Pilotez avec précaution",
    "ja": "GPS信号干渉があります。慎重に飛行してください",
    "ko": "GPS 신호 간섭. 비행 시 주의 필요",
    "ru": "Помехи в сигнале GPS. Соблюдайте осторожность при полете",
    "tr": "GPS sinyal müdahalesi. Dikkatlice uçun",
    "zh": "飞行器GPS受到干扰，谨慎飞行"
  },
  "fpv_tip_0x16050020": {
    "ar": "",
    "de": "Starke GPS-Störung. Fluggerät kehrt zum Startpunkt zurück",
    "en": "Strong GPS interference. Aircraft returning to home",
    "es": "Fuerte interferencia de GPS. La aeronave se está dirigiendo al punto de partida",
    "fr": "Fortes interférences GPS. L'appareil retourne à son point de départ",
    "id": "",
    "it": "",
    "ja": "強いGPS干渉。帰還中です。",
    "ko": "강한 GPS 간섭. 기체가 리턴 투 홈 중입니다.",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Сильные помехи GPS. Дрон выполняет возврат домой",
    "th": "",
    "tr": "Güçlü GPS paraziti. Hava aracı kalkış noktasına dönüyor",
    "ug": "",
    "vi": "",
    "zh": "GPS干扰强，飞行器自动返航",
    "zh-Hant": ""
  },
  "fpv_tip_0x16050021": {
    "de": "RTK nicht konvergiert. Fluggerät in einem offenen Bereich platzieren und Aufgabe starten, nachdem RTK konvergiert ist.",
    "en": "RTK not converged. Place aircraft in an open area and start task after RTK converged",
    "es": "RTK no convergente. Sitúe la aeronave en una zona abierta y comience la tarea después de la convergencia de RTK",
    "fr": "RTK non convergent. Placez l'appareil dans une zone dégagée et démarrez la tâche après la convergence RTK",
    "ja": "RTKが収束していません。機体を開けた場所に置き、RTKが収束したらタスクを開始してください",
    "ko": "RTK가 수렴되지 않았습니다. 기체를 개방된 공간에 두고 RTK가 수렴된 후 작업을 시작하세요",
    "ru": "RTK без конвергенции. Разместите дрон на открытой местности и приступайте к выполнению задачи после конвергенции RTK",
    "tr": "RTK yakınsanmadı. Hava aracını açık bir alana yerleştirin ve RTK yakınsandıktan sonra göreve başlayın",
    "zh": "RTK 启用未收敛，请将飞机置于空旷环境等待 RTK 收敛后执行任务"
  },
  "fpv_tip_0x16050022": {
    "de": "GNSS-Signalfehler groß. Mit Vorsicht fliegen.",
    "en": "GNSS signal error large. Fly with caution",
    "es": "Error de señal GNSS grande. Vuela con cuidado.",
    "fr": "Erreur de signal GNSS importante. Pilotez avec précaution.",
    "ja": "GNSS信号エラーが大きいです。慎重に飛行してください",
    "ko": "GNSS 신호 오류가 큽니다. 비행 시 주의 필요.",
    "ru": "Ошибка сигнала GNSS. Будьте осторожны в полете",
    "tr": "GNSS sinyal hatası büyük. Dikkatlice uçurun",
    "zh": "当前飞行环境GNSS信号误差大，请谨慎飞行"
  },
  "fpv_tip_0x1605002B": {
    "de": "Es werden nur BDS-Daten verwendet. Die Anzahl gesuchter Satelliten wird möglicherweise beeinträchtigt",
    "en": "Only BDS data used. Number of searched satellites may be affected",
    "es": "Solo se utilizan datos de BDS. El número de satélites buscados puede verse afectado.",
    "fr": "Utilisation des données BDS uniquement. Le nombre de satellites recherchés peut être affecté",
    "ja": "BDSデータのみ使用。検索される衛星の数に影響する可能性があります",
    "ko": "BDS 데이터만 사용됨. 검색된 위성 수에 영향을 줄 수 있음",
    "ru": "Используются только данные BDS. Может быть затронут ряд искомых спутников",
    "tr": "Yalnızca BDS verileri kullanılıyor. Aranan uyduların sayısı değişebilir",
    "zh": "进入单北斗模式，搜星质量可能变差"
  },
  "fpv_tip_0x16060001": {
    "de": "Fehler: Kompass . Fluggerät neu starten",
    "en": "Compass error. Restart aircraft",
    "es": "Error de brújula. Reinicia la aeronave",
    "fr": "Erreur du compas . Redémarrez l\\'appareil",
    "ja": "コンパスエラー。機体を再起動してください",
    "ko": "콤파스 오류 . 기체 재시작 필요",
    "ru": "Ошибка компаса . Перезагрузите дрон",
    "tr": "Pusula hatası. Aracı yeniden başlatın",
    "zh": "指南针异常，请重启飞行器"
  },
  "fpv_tip_0x16060001_in_the_sky": {
    "de": "Fehler: Kompass . Zum Startpunkt zurückkehren oder landen.",
    "en": "Compass error. Return to home or land",
    "es": "Error de brújula. Regresa al punto de origen o aterriza",
    "fr": "Erreur compas . Revenez au point de départ ou atterrissez",
    "ja": "コンパスエラー。帰還するか、着陸してください",
    "ko": "콤파스 오류 . RTH 진행 또는 착륙 필요",
    "ru": "Ошибка компаса . Вернитесь домой или приземлитесь",
    "tr": "Pusula hatası. Eve geri dönün veya iniş yapın",
    "zh": "指南针异常,请返航或降落"
  },
  "fpv_tip_0x16060007": {
    "de": "Kompassfehler. Fluggerät neu starten",
    "en": "Compass error. Restart aircraft",
    "es": "Error de brújula. Reinicia la aeronave",
    "fr": "Erreur du compas. Redémarrez l\\'appareil",
    "ja": "コンパスエラー。機体を再起動してください",
    "ko": "콤파스 오류. 기체 재시작 필요",
    "ru": "Ошибка компаса. Перезагрузите дрон",
    "tr": "Pusula hatası. Aracı yeniden başlatın",
    "zh": "指南针异常，请重启飞行器"
  },
  "fpv_tip_0x16060007_in_the_sky": {
    "de": "Kompassfehler. Zum Startpunkt zurückkehren oder landen",
    "en": "Compass error. Return to home or land",
    "es": "Error de brújula. Regresa al punto de origen o aterriza",
    "fr": "Erreur compas. Revenez au point de départ ou atterrissez",
    "ja": "コンパスエラー。帰還するか着陸してください",
    "ko": "콤파스 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка компаса. Вернитесь домой или приземлитесь",
    "tr": "Pusula hatası. Eve geri dönün veya iniş yapın",
    "zh": "指南针异常,请返航或降落"
  },
  "fpv_tip_0x1606000A": {
    "de": "Kompassfehler. Fluggerät neu starten",
    "en": "Compass error. Restart aircraft",
    "es": "Error de brújula. Reinicia la aeronave",
    "fr": "Erreur du compas. Redémarrez l\\'appareil",
    "ja": "コンパスエラー。機体を再起動してください",
    "ko": "콤파스 오류. 기체 재시작 필요",
    "ru": "Ошибка компаса. Перезагрузите дрон",
    "tr": "Pusula hatası. Aracı yeniden başlatın",
    "zh": "指南针异常，请重启飞行器"
  },
  "fpv_tip_0x1606000A_in_the_sky": {
    "de": "Kompassfehler. Zum Startpunkt zurückkehren oder landen",
    "en": "Compass error. Return to home or land",
    "es": "Error de brújula. Regresa al punto de origen o aterriza",
    "fr": "Erreur compas. Revenez au point de départ ou atterrissez",
    "ja": "コンパスエラー。帰還するか着陸してください",
    "ko": "콤파스 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка компаса. Вернитесь домой или приземлитесь",
    "tr": "Pusula hatası. Eve geri dönün veya iniş yapın",
    "zh": "指南针异常,请返航或降落"
  },
  "fpv_tip_0x1606000D": {
    "de": "Fehler: Kompass . Kompass neu kalibrieren",
    "en": "Compass interference. Calibrate compass",
    "es": "Interferencias en la brújula. Calibra la brújula",
    "fr": "Interférence compas . Étalonnez le compas",
    "ja": "コンパスの干渉。コンパスをキャリブレーション",
    "ko": "콤파스 간섭 . 콤파스 캘리브레이션 필요",
    "ru": "Помехи компаса . Откалибруйте его",
    "tr": "Pusula müdahalesi. Pusulayı kalibre edin",
    "zh": "指南针受到干扰，请校准指南针"
  },
  "fpv_tip_0x1606000D_in_the_sky": {
    "de": "Kompassstörung . Fluggerät von der Störquelle wegbewegen.",
    "en": "Compass interference. Move aircraft away from interference source",
    "es": "Interferencias en la brújula. Aleja la aeronave de la fuente de interferencia",
    "fr": "Interférence compas . Éloignez l\\'appareil des sources d\\'interférence",
    "ja": "コンパスの干渉。干渉源から機体を遠ざけてください",
    "ko": "콤파스 간섭 . 기체를 간섭원에서 멀리 이동해야 함",
    "ru": "Помехи компаса . Отдалитесь от источника помех",
    "tr": "Pusula müdahalesi. Aracı müdahale kaynağından uzaklaştırın",
    "zh": "指南针受到干扰,请远离干扰源"
  },
  "fpv_tip_0x16060010": {
    "de": "Kompassstörung. Kompass kalibrieren",
    "en": "Compass interference. Calibrate compass",
    "es": "Interferencias en la brújula. Calibra la brújula",
    "fr": "Interférence compas. Étalonnez le compas",
    "ja": "コンパス干渉。コンパスのキャリブレーションを行ってください",
    "ko": "콤파스 간섭. 콤파스 캘리브레이션 필요",
    "ru": "Помехи в работе компаса. Откалибруйте компас",
    "tr": "Pusula müdahalesi. Pusulayı kalibre edin",
    "zh": "指南针受到干扰，请校准指南针"
  },
  "fpv_tip_0x16060010_in_the_sky": {
    "de": "Kompassstörung. Fluggerät von der Störquelle wegbewegen",
    "en": "Compass interference. Move aircraft away from interference source",
    "es": "Interferencias en la brújula. Aleja la aeronave de la fuente de interferencia",
    "fr": "Interférence compas. Éloignez l\\'appareil des sources d\\'interférence",
    "ja": "コンパス干渉。干渉源から機体を遠ざけてください",
    "ko": "콤파스 간섭. 기체를 간섭원에서 멀리 이동해야 함",
    "ru": "Помехи в работе компаса. Переместите дрон вдаль от источника помех",
    "tr": "Pusula müdahalesi. Aracı müdahale kaynağından uzaklaştırın",
    "zh": "指南针受到干扰,请远离干扰源"
  },
  "fpv_tip_0x16070001": {
    "de": "Flugregler überhitzt. Rückkehr zum Startpunkt...",
    "en": "Flight controller overheats. Returning to home...",
    "es": "El controlador de vuelo se sobrecalienta. Regresando al punto de origen...",
    "fr": "Surchauffe du contrôleur de vol. Retour au point de départ en cours...",
    "ja": "フライトコントローラーが過熱状態。ホームに帰還中...",
    "ko": "비행 컨트롤러 과열. RTH 진행 중...",
    "ru": "Полетный контроллер перегревается. Возвращение на базу...",
    "tr": "Uçuş kontrol sistemi aşırı ısındı. Başlangıç noktasına dönülüyor...",
    "zh": "飞控温度过高，返航中"
  },
  "fpv_tip_0x16070002": {
    "de": "Flugregler überhitzt. Rückkehr zum Startpunkt...",
    "en": "Flight controller overheats. Returning to home...",
    "es": "El controlador de vuelo se sobrecalienta. Regresando al punto de origen...",
    "fr": "Surchauffe du contrôleur de vol. Retour au point de départ en cours...",
    "ja": "フライトコントローラーが過熱状態。ホームに帰還中...",
    "ko": "비행 컨트롤러 과열. RTH 진행 중...",
    "ru": "Полетный контроллер перегревается. Возвращение на базу...",
    "tr": "Uçuş kontrol sistemi aşırı ısındı. Başlangıç noktasına dönülüyor...",
    "zh": "飞控温度过高，返航中"
  },
  "fpv_tip_0x16070003": {
    "de": "Flugregler überhitzt. Automatische Landung…",
    "en": "Flight controller overheats. Auto landing...",
    "es": "El controlador de vuelo se sobrecalienta. Realizando aterrizaje automático…",
    "fr": "Surchauffe du contrôleur de vol. Atterrissage automatique en cours...",
    "ja": "フライトコントローラーが過熱状態。自動着陸中...",
    "ko": "비행 컨트롤러 과열. 자동 착륙 진행 중...",
    "ru": "Полетный контроллер перегревается. Автоматическая посадка…",
    "tr": "Uçuş kontrol sistemi aşırı ısındı. Otomatik iniş yapılıyor...",
    "zh": "飞控温度过高，强制降落中"
  },
  "fpv_tip_0x16070020": {
    "de": "Keine RTK-Daten vom Fluggerät empfangen. Fluggerät neu starten",
    "en": "RTK data not received by aircraft",
    "es": "La aeronave no recibe datos RTK. Reinicia la aeronave",
    "fr": "Données RTK non reçues par l\\'appareil. Redémarrez l\\'appareil",
    "ja": "機体でRTKデータを受信できません。機体を再起動してください",
    "ko": "항공기가 RTK 데이터를 받지 못했습니다. 항공기를 다시 시작하세요.",
    "ru": "Данные RTK не получены дроном",
    "tr": "RTK verisi araç tarafından alınmadı. Aracı yeniden başlatın",
    "zh": "飞行器未接收到RTK数据"
  },
  "fpv_tip_0x16070021": {
    "de": "RTK-Datenfehler. Fluggerät neu starten",
    "en": "RTK data error. Restart aircraft",
    "es": "Error de datos RTK. Reinicia la aeronave",
    "fr": "Erreur liée aux données RTK. Redémarrez l\\'appareil",
    "ja": "RTKデータエラーです。機体を再起動してください",
    "ko": "RTK 데이터 오류입니다. 항공기를 다시 시작하세요.",
    "ru": "Ошибка данных RTK. Перезапустите дрон",
    "tr": "RTK verisi hatası. Aracı yeniden başlatın",
    "zh": "RTK 数据异常，请重启飞行器"
  },
  "fpv_tip_0x16070022": {
    "en": "",
    "zh": "RTK双天线安装错误"
  },
  "fpv_tip_0x16070023": {
    "de": "Fehler bei der Aktualisierungsfrequenz der RTK-Daten. Vorsichtig fliegen.",
    "en": "RTK data refresh frequency error",
    "es": "Error de frecuencia de actualización de datos RTK. Vuela con cuidado",
    "fr": "Erreur de fréquence d\\'actualisation des données RTK. Volez avec prudence",
    "ja": "RTKデータ更新頻度のエラーです。注意して飛行してください",
    "ko": "RTK 데이터 새로 고침 빈도 오류입니다. 주의하여 비행하세요.",
    "ru": "Слишком частое обновление данных RTK",
    "tr": "RTK veri yenileme sıklığı hatası. Dikkatli uçurun",
    "zh": "RTK 数据更新频率异常"
  },
  "fpv_tip_0x16070024": {
    "de": "RTK-Datenfehler. Vorsichtig fliegen.",
    "en": "RTK data error",
    "es": "Error de datos RTK. Vuela con cuidado",
    "fr": "Erreur liée aux données RTK. Volez avec prudence",
    "ja": "RTKデータエラーです。注意して飛行してください",
    "ko": "RTK 데이터 오류입니다. 주의하여 비행하세요.",
    "ru": "Ошибка данных RTK",
    "tr": "RTK verisi hatası. Dikkatli uçurun",
    "zh": "RTK 数据异常"
  },
  "fpv_tip_0x16070025": {
    "de": "Die RTK-Positionsdaten können nicht aktualisiert werden. Fluggerät neu starten",
    "en": "Unable to refresh RTK position data",
    "es": "No se pueden actualizar los datos de posición de RTK. Reinicia la aeronave",
    "fr": "Impossible d\\'actualiser les données de position RTK. Redémarrez l\\'appareil",
    "ja": "RTK位置データを更新できません。機体を再起動してください",
    "ko": "RTK 위치 데이터를 새로 고칠 수 없습니다. 항공기를 다시 시작하세요.",
    "ru": "Не удалось обновить данные положения RTK. Перезапустите дрон",
    "tr": "RTK pozisyon verisini yenileyemiyor. Aracı yeniden başlatın",
    "zh": "RTK 定位数据停止更新"
  },
  "fpv_tip_0x16070026": {
    "de": "Plötzliche Änderung der RTK-Positionsdaten. Fluggerät neu starten",
    "en": "Sudden RTK position data change",
    "es": "Cambio repentino de los datos de posición de RTK. Reinicia la aeronave",
    "fr": "Changement soudain des données de position RTK. Redémarrez l\\'appareil",
    "ja": "RTK位置データが突然変更されました。機体を再起動してください",
    "ko": "갑자기 RTK 위치 데이터가 변경되었습니다. 항공기를 다시 시작하세요.",
    "ru": "Внезапное изменение данных положения RTK. Перезапустите дрон",
    "tr": "RTK konum verisinde ani değişiklik. Aracı yeniden başlatın",
    "zh": "RTK 定位数据跳变"
  },
  "fpv_tip_0x16070027": {
    "de": "RTK- und Sensorhöhendaten stimmen nicht überein. Fluggerät neu starten",
    "en": "RTK and sensor altitude data inconsistent",
    "es": "Datos de altitud de RTK y del sensor incoherentes. Reinicia la aeronave",
    "fr": "Les données RTK et les données d\\'altitude du capteur sont incohérentes. Redémarrez l\\'appareil",
    "ja": "RTKとセンサーの高度データが一致しません。機体を再起動してください",
    "ko": "RTK 및 센서 고도 데이터가 일치하지 않습니다. 항공기를 다시 시작하세요.",
    "ru": "Показания высоты RTK и датчика не совпадают",
    "tr": "RTK ve sensör irtifa verisi tutarsız. Aracı yeniden başlatın",
    "zh": "RTK 高度与传感器高度数据不一致"
  },
  "fpv_tip_0x16070028": {
    "de": "RTK- und GPS-Positionen stimmen nicht überein. Fluggerät neu starten",
    "en": "RTK and GPS positions do not match",
    "es": "Las posiciones de RTK y GPS no coinciden. Reinicia la aeronave",
    "fr": "Les positions RTK et GPS ne correspondent pas. Redémarrez l\\'appareil",
    "ja": "RTKとGPSの位置情報が一致しません。機体を再起動してください",
    "ko": "RTK와 GPS 위치가 일치하지 않습니다. 항공기를 다시 시작하세요.",
    "ru": "Положения RTK и GPS не совпадают. Перезапустите дрон",
    "tr": "RTK ve GPS pozisyonları eşleşmiyor. Aracı yeniden başlatın",
    "zh": "RTK 定位与 GPS 定位不一致"
  },
  "fpv_tip_0x16070029": {
    "de": "Die RTK-Positionsdaten haben sich bei stationärem Fluggerät leicht geändert. Fluggerät neu starten",
    "en": "RTK position data changed slightly when aircraft is stationary",
    "es": "Cambio leve en los datos de posición de RTK con la aeronave estática. Reinicia la aeronave",
    "fr": "Données de position RTK légèrement modifiées lorsque l\\'appareil était à l\\'arrêt. Redémarrez l\\'appareil",
    "ja": "機体は静止していますが、RTKの位置データがわずかに変更されました。機体を再起動してください",
    "ko": "항공기가 정지 상태일 때 RTK 위치 데이터가 약간 변경되었습니다. 항공기를 다시 시작하세요.",
    "ru": "Данные позиционирования RTK слегка изменились при неподвижном дроне",
    "tr": "RTK konum verisi araç sabitken biraz değişti. Aracı yeniden başlatın",
    "zh": "飞行器静止时 RTK 定位缓变"
  },
  "fpv_tip_0x16070030": {
    "de": "RTK-Firmware-Version stimmt nicht überein. Aktualisierung erforderlich",
    "en": "RTK firmware version does not match",
    "es": "La versión del firmware de RTK no coincide. Es necesario actualizar",
    "fr": "Version du firmware RTK non correspondante. Mise à jour requise",
    "ja": "RTKファームウェアバージョンが一致しません。更新が必要です",
    "ko": "RTK 펌웨어 버전이 일치하지 않습니다. 업데이트해야 합니다.",
    "ru": "Несоответствие версии прошивки RTK. Требуется обновление",
    "tr": "RTK donanım yazılımı sürümü eşleşmiyor. Güncelleme gerekli",
    "zh": "RTK 固件版本不匹配"
  },
  "fpv_tip_0x16070031": {
    "de": "Umschaltfehler RTK-Signalquelle",
    "en": "RTK signal source switching error",
    "es": "Error de cambio en la señal de origen RTK",
    "fr": "Erreur de commutation de source de signal RTK",
    "ja": "RTK信号源切り替えエラー",
    "ko": "RTK 신호원 전환 오류",
    "ru": "Ошибка переключения источника сигнала RTK",
    "tr": "RTK sinyal kaynağı değiştirme hatası",
    "zh": "RTK信号源切换异常"
  },
  "fpv_tip_0x16070032": {
    "de": "D-RTK-Mobilstation abgenommen. Status der Mobilstation prüfen oder Fluggerät neu starten",
    "en": "D-RTK mobile station detached",
    "es": "Estación móvil D-RTK no conectada. Comprueba la estación móvil y reinicia la aeronave",
    "fr": "Station mobile D-RTK déconnectée. Vérifiez la station mobile et redémarrez l\\'appareil",
    "ja": "D-RTKモバイルステーションが取り外されました。モバイルステーションを確認して、機体を再起動してください",
    "ko": "D-RTK 이동국이 분리되었습니다. 이동국을 확인하고 항공기를 다시 시작하세요.",
    "ru": "Мобильная станция D-RTK отсоединена",
    "tr": "D-RTK mobil istasyonu söküldü. Mobil istasyonu kontrol edin ve aracı yeniden başlatın",
    "zh": "D-RTK 移动站倾倒"
  },
  "fpv_tip_0x16070033": {
    "de": "D-RTK-Mobilstation bewegt. Status der Mobilstation prüfen oder Fluggerät neu starten",
    "en": "D-RTK mobile station moved",
    "es": "Estación móvil D-RTK desplazada. Comprueba la estación móvil y reinicia la aeronave",
    "fr": "Station mobile D-RTK déplacée. Vérifiez la station mobile et redémarrez l\\'appareil",
    "ja": "D-RTKモバイルステーションが移動しました。モバイルステーションを確認して、機体を再起動してください",
    "ko": "D-RTK 이동국이 이동되었습니다. 이동국을 확인하고 항공기를 다시 시작하세요.",
    "ru": "Мобильная станция D-RTK перемещена",
    "tr": "D-RTK mobil istasyonu hareket etti. Mobil istasyonu kontrol edin ve aracı yeniden başlatın",
    "zh": "D-RTK 移动站发生移动"
  },
  "fpv_tip_0x16070034": {
    "de": "RTK-Flugkurs mit anderen Quellen inkonsistent. Vorsichtig fliegen.",
    "en": "RTK flight heading inconsistent with other sources",
    "es": "Orientación de vuelo RTK incoherente con otras fuentes. Vuela con cuidado",
    "fr": "Cap de vol RTK incompatible avec d\\'autres sources. Volez avec prudence",
    "ja": "RTK飛行方向が他のソースと一致しません。注意して飛行してください",
    "ko": "RTK 비행 기수 방위가 다른 소스와 일치하지 않습니다. 주의하여 비행하세요.",
    "ru": "Направление полета RTK не совпадает с другими источниками",
    "tr": "RTK uçuş istikameti diğer kaynaklarla uyuşmuyor. Dikkatli uçurun",
    "zh": "RTK 航向与其他源不一致"
  },
  "fpv_tip_0x16070035": {
    "de": "D-RTK-Fluggeräte-Antennenfehler. Vorsichtig fliegen.",
    "en": "Aircraft D-RTK antenna error",
    "es": "Error de la antena D-RTK de la aeronave. Vuela con cuidado",
    "fr": "Erreur d\\'antenne D-RTK de l\\'appareil. Volez avec prudence",
    "ja": "機体のD-RTKアンテナのエラーです。注意して飛行してください",
    "ko": "항공기 D-RTK 안테나 오류입니다. 주의하여 비행하세요.",
    "ru": "Ошибка антенны D-RTK дрона",
    "tr": "Araç D-RTK anten hatası. Dikkatli uçurun",
    "zh": "机载 D-RTK 天线异常"
  },
  "fpv_tip_0x16070036": {
    "de": "Start nicht möglich. RTK-Antennen getrennt. DJI Support kontaktieren",
    "en": "Unable to take off. RTK antennas disconnected. Contact DJI Support",
    "es": "No se puede despegar. Antenas RTK desconectadas. Póngase en contacto con la Asistencia técnica de DJI",
    "fr": "Impossible de décoller. Antennes RTK déconnectées. Contactez le service client DJI",
    "ja": "離陸できません。RTKアンテナの接続が切断されました。DJIサポートまでお問い合わせください",
    "ko": "이륙 불가. RTK 안테나 연결이 끊어졌습니다. DJI 고객지원으로 문의하세요.",
    "ru": "Не удается выполнить взлет. Антенны RTK отключены. Свяжитесь со службой поддержки DJI",
    "tr": "Kalkış yapılamıyor. RTK antenlerinin bağlantısı kesildi. DJI Destek ekibiyle iletişime geçin",
    "zh": "无法起飞：RTK天线断连，请联系DJI售后"
  },
  "fpv_tip_0x16070036_in_the_sky": {
    "de": "RTK-Antennen getrennt. Umgehend zum Startpunkt zurückkehren oder landen.",
    "en": "RTK antennas disconnected. Return to home or land promptly",
    "es": "Antenas RTK desconectadas. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Antennes RTK déconnectées. Retourner au point de départ ou atterrir rapidement",
    "ja": "RTKアンテナの接続が切断されました。直ちに帰還するか、着陸してください",
    "ko": "RTK 안테나 연결이 끊어졌습니다. 즉시 리턴 투 홈을 실행하거나 착륙하세요",
    "ru": "Антенны RTK отключены. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "RTK antenlerinin bağlantısı kesildi. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "RTK天线断连，请尽快返航或降落"
  },
  "fpv_tip_0x16070037": {
    "de": "Zertifizierungsfehler: RTK-Dongle",
    "en": "RTK Dongle Certification Error",
    "es": "Error de certificación de adaptador RTK",
    "fr": "Erreur de certification de dongle RTK",
    "ja": "RTKドングル認証エラー",
    "ko": "RTK 동글 인증 오류",
    "ru": "Ошибка сертификации ключа RTK",
    "tr": "RTK Dongle Sertifikasyon Hatası",
    "zh": "RTK认证错误"
  },
  "fpv_tip_0x16070038": {
    "en": "",
    "zh": "RTK主天线信号异常"
  },
  "fpv_tip_0x16070039": {
    "en": "",
    "zh": "RTK副天线信号异常"
  },
  "fpv_tip_0x16070040": {
    "en": "RTK not converged. Place aircraft in an open environment and start task after RTK converged",
    "zh": "RTK 启用未收敛，请将飞机置于空旷环境等待 RTK 收敛后执行任务"
  },
  "fpv_tip_0x16070043": {
    "de": "GNSS wird durch Fehlersignal gestört. Vorsichtig fliegen oder zum Start zurückkehren",
    "en": "GNSS is interfered with error signal. Fly with caution or return to home",
    "es": "GNSS ha interferido con la señal de error. Vuela con cuidado o regresa al punto de origen lo antes posible",
    "fr": "Le dispositif GNSS interfère avec le signal d’erreur. Pilotez avec précaution ou revenez au point de départ",
    "ja": "GNSSがエラー信号で干渉されています。慎重に飛行するか、帰還してください",
    "ko": "오류 신호가 GNSS를 방해함. 비행 시 주의 필요 및 리턴 투 홈 실행",
    "ru": "Работе GNSS препятствует сигнал об ошибке. Соблюдайте осторожность при полете или выполните возврат домой",
    "tr": "GNSS hata sinyaliyle karışmış. Dikkatlice uçun veya kalkış noktasına dönün",
    "zh": "卫星定位受异常信号干扰，请谨慎飞行或返航"
  },
  "fpv_tip_0x16070043_in_the_sky": {
    "de": "GNSS wird durch Fehlersignal gestört. Mit Vorsicht abheben",
    "en": "GNSS is interfered with error signal. Take off with caution",
    "es": "GNSS ha interferido con la señal de error. Despega con precaución",
    "fr": "Le dispositif GNSS interfère avec le signal d’erreur. Décollez avec précaution",
    "ja": "GNSSがエラー信号で干渉されています。慎重に離陸してください",
    "ko": "오류 신호가 GNSS를 방해함. 주의하여 이륙하세요",
    "ru": "Работе GNSS препятствует сигнал об ошибке. Соблюдайте осторожность при взлете",
    "tr": "GNSS hata sinyaliyle karışmış. Dikkatli kalkış yapın",
    "zh": "卫星定位受异常信号干扰，请谨慎起飞"
  },
  "fpv_tip_0x16070044": {
    "de": "GNSS empfängt falsches Signal. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "GNSS is receiving false signal. Return to home or land promptly",
    "es": "GNSS está recibiendo una señal falsa. Regresa al punto de origen o aterriza lo antes posible",
    "fr": "Le dispositif GNSS reçoit un faux signal. Revenez au point de départ ou atterrissez immédiatement",
    "ja": "GNSSが誤った信号を受信しています。直ちに帰還または着陸してください",
    "ko": "GNSS에서 잘못된 신호를 수신함. 즉시 리턴 투 홈을 실행하거나 착륙시키세요",
    "ru": "GNSS получает ложный сигнал. Незамедлительно совершите возврат домой или посадку",
    "tr": "GNSS, hatalı sinyal alıyor. Kalkış noktasına dönün veya derhal iniş yapın",
    "zh": "卫星定位受到欺骗，请立即返航或降落"
  },
  "fpv_tip_0x16070044_in_the_sky": {
    "de": "GNSS empfängt falsches Signal. Mit Vorsicht abheben",
    "en": "GNSS is receiving false signal. Take off with caution",
    "es": "GNSS está recibiendo una señal falsa. Despega con precaución",
    "fr": "Le dispositif GNSS reçoit un faux signal. Décollez avec précaution",
    "ja": "GNSSが誤った信号を受信しています。慎重に離陸してください",
    "ko": "GNSS에서 잘못된 신호를 수신함. 주의하여 이륙하세요",
    "ru": "GNSS получает ложный сигнал. Соблюдайте осторожность при взлете",
    "tr": "GNSS, hatalı sinyal alıyor. Dikkatli kalkış yapın",
    "zh": "卫星定位受到欺骗，请谨慎起飞"
  },
  "fpv_tip_0x16080020": {
    "de": "Motor %index blockiert. Fluggerät sofort landen",
    "en": "Motor %index stalled . Land aircraft immediately",
    "es": "Motor %index atascado. Aterriza la aeronave de inmediato",
    "fr": "Moteur %index a calé . Atterrissez immédiatement",
    "ja": "モーター%indexが停止しました。機体を直ちに着陸させてください",
    "ko": "모터 %index 정지됨 . 즉시 기체 착륙해야 함",
    "ru": "Мотор %index остановился . Посадите дрон немедленно",
    "tr": "Motor %index durdu. Hemen aracı indirin",
    "zh": "%index号电机堵转，请停止飞行。"
  },
  "fpv_tip_0x16080021": {
    "de": "Kurzschluss am ESC %index . Sofort landen und Fluggerät neu starten",
    "en": "ESC %index short-circuited . Land immediately and restart aircraft",
    "es": "Cortocircuito de ESC %index. Aterriza de inmediato y reinicia la aeronave",
    "fr": "Court-circuit ESC %index . Atterrissez immédiatement et redémarrez l\\'appareil",
    "ja": "ESC%index短絡。直ちに着陸させて、機体を再起動してください",
    "ko": "ESC %index 합선 . 즉시 착륙하고 기체 재시작해야 함",
    "ru": "Короткое замыкание ESC %index . Приземлитесь немедленно и перезагрузите дрон",
    "tr": "ESC %index kısa devre yaptı. Hemen iniş yapın ve aracı yeniden başlatın",
    "zh": "%index号电调短路，请停止飞行，并重启飞行器。"
  },
  "fpv_tip_0x16080022": {
    "de": "ESC %index überlastet %index. Fluggerät sofort landen",
    "en": "ESC %index overloaded . Land aircraft immediately",
    "es": "ESC %index sobrecargado. Aterriza la aeronave de inmediato",
    "fr": "Surcharge ESC %index . Atterrissez immédiatement",
    "ja": "ESC%index過負荷状態。機体を直ちに着陸させてください",
    "ko": "ESC %index 과부하 . 즉시 기체 착륙해야 함",
    "ru": "Перегрузка ESC %index . Приземлитесь немедленно",
    "tr": "ESC %index aşırı yüklü. Hemen aracı indirin",
    "zh": "%index号电机过载，请停止飞行。"
  },
  "fpv_tip_0x16080023": {
    "de": "Kommunikationsfehler: Motor %index %index. Sofort landen und Fluggerät neu starten",
    "en": "Motor %index communication error . Land immediately and restart aircraft",
    "es": "Error de comunicación del motor %index. Aterriza de inmediato y reinicia la aeronave",
    "fr": "Erreur de communication du moteur %index . Atterrissez immédiatement et redémarrez l\\'appareil",
    "ja": "モーター%index通信エラー。直ちに着陸させて、機体を再起動してください",
    "ko": "모터 %index 통신 오류 . 즉시 착륙하고 기체 재시작해야 함",
    "ru": "Ошибка связи мотора %index . Приземлитесь немедленно и перезагрузите дрон",
    "tr": "Motor %index iletişim hatası. Hemen iniş yapın ve aracı yeniden başlatın",
    "zh": "%index号电机通信异常，请停止飞行，并重启飞行器。"
  },
  "fpv_tip_0x16080024": {
    "de": "Motor %index überlastet. Bitte Steuerknüppel loslassen %index",
    "en": "Motor %index over-accelerating. Stop pressing control stick",
    "es": "Sobreaceleración del motor %index. Deja de presionar la palanca de control",
    "fr": "Suraccélération du moteur %index. Ne touchez pas au joystick",
    "ja": "モーター%indexが過度に加速。操作スティックを押すのを止めてください",
    "ko": "과도한 모터 %index 가속. 조종 스틱 누르기 중지해야 함",
    "ru": "Чрезмерное ускорение мотора %index. Отпустите джойстик",
    "tr": "Motor %index aşırı hızlanıyor. Kumanda çubuğuna basmayı bırakın",
    "zh": "%index号电机油门过高，请回中摇杆。"
  },
  "fpv_tip_0x16080025": {
    "de": "Kommunikationsfehler: Motor %index %index",
    "en": "Motor %index communication error",
    "es": "Error de comunicación del motor %index",
    "fr": "Erreur de communication du moteur %index",
    "ja": "モーター%index通信エラー",
    "ko": "모터 %index 통신 오류",
    "ru": "Ошибка связи мотора %index",
    "tr": "Motor %index iletişim hatası",
    "zh": "%index号电机通信异常。"
  },
  "fpv_tip_0x16080026": {
    "de": "Kommunikationsfehler: Motor %index %index",
    "en": "Motor %index communication error",
    "es": "Error de comunicación del motor %index",
    "fr": "Erreur de communication du moteur %index",
    "ja": "モーター%index通信エラー",
    "ko": "모터 %index 통신 오류",
    "ru": "Ошибка связи мотора %index",
    "tr": "Motor %index iletişim hatası",
    "zh": "%index号电机通信异常"
  },
  "fpv_tip_0x16080027": {
    "de": "Kommunikationsfehler: Motor %index",
    "en": "Motor %index communication error",
    "es": "Error de comunicación del motor %index",
    "fr": "Erreur de communication du moteur %index",
    "ja": "モーター%index通信エラー",
    "ko": "모터 %index 통신 오류",
    "ru": "Ошибка связи мотора %index",
    "tr": "Motor %index iletişim hatası",
    "zh": "%index号电机通信异常"
  },
  "fpv_tip_0x16080028": {
    "de": "Kommunikationsfehler: Motor %index",
    "en": "Motor %index communication error",
    "es": "Error de comunicación del motor %index",
    "fr": "Erreur de communication du moteur %index",
    "ja": "モーター%index通信エラー",
    "ko": "모터 %index 통신 오류",
    "ru": "Ошибка связи мотора %index",
    "tr": "Motor %index iletişim hatası",
    "zh": "%index号电机通信异常"
  },
  "fpv_tip_0x16080029": {
    "de": "Motor %index Propeller entfernt, Propeller nicht installiert oder Motor unterbelastet",
    "en": "Motor %index propeller detached, propeller not installed, or motor underloaded",
    "es": "Hélice del motor %index suelta, hélice no instalada o motor descargado",
    "fr": "Hélice %index du moteur détachée, hélice non installée ou moteur sous-chargé",
    "ja": "モーター%indexのプロペラが外れているか、プロペラが取り付けられていないか、モーターの負荷が不足しています",
    "ko": "모터 %index 프로펠러가 분리되었거나, 프로펠러가 설치되지 않았거나, 모터가 저부하 상태임",
    "ru": "Пропеллер мотора %index отсоединен, пропеллер не установлен или неполная нагрузка на мотор",
    "tr": "Motor %index pervanesi çıkmış, pervane takılmamış veya motor yükü yetersiz",
    "zh": "%index号电机射桨、空载或轻载"
  },
  "fpv_tip_0x1608002A": {
    "de": "ESC %index überhitzt %index",
    "en": "ESC %index overheated",
    "es": "ESC %index sobrecalentado",
    "fr": "Surchauffe ESC %index",
    "ja": "ESC%index高温",
    "ko": "ESC %index 과열",
    "ru": "Перегрев ESC %index",
    "tr": "ESC %index aşırı ısındı",
    "zh": "%index号电调温度过高"
  },
  "fpv_tip_0x1608002B": {
    "de": "ESC %index überhitzt",
    "en": "ESC %index overheated",
    "es": "ESC %index sobrecalentado",
    "fr": "Surchauffe ESC %index",
    "ja": "ESC %index高温",
    "ko": "ESC %index 과열",
    "ru": "ESC %index перегрелся",
    "tr": "ESC %index aşırı ısındı",
    "zh": "%index号电调温度过高"
  },
  "fpv_tip_0x1608002C": {
    "de": "ESC %index Spannung zu hoch %index",
    "en": "ESC %index voltage too high",
    "es": "Voltaje de ESC %index demasiado alto",
    "fr": "Tension ESC %index trop élevée",
    "ja": "ESC%index高電圧",
    "ko": "ESC %index 전압 너무 높음",
    "ru": "Слишком высокое напряжение ESC %index",
    "tr": "ESC %index voltajı çok yüksek",
    "zh": "%index号电调电压过高"
  },
  "fpv_tip_0x1608002D": {
    "de": "ESC %index Spannung zu niedrig %index",
    "en": "ESC %index voltage too low",
    "es": "Voltaje de ESC %index demasiado bajo",
    "fr": "Tension ESC %index trop basse",
    "ja": "ESC%index低電圧",
    "ko": "ESC %index 전압 너무 낮음",
    "ru": "Слишком низкое напряжение ESC %index",
    "tr": "ESC %index voltajı çok düşük",
    "zh": "%index号电调电压过低"
  },
  "fpv_tip_0x1608002E": {
    "de": "ESC %index Flash-Speicherfehler %index",
    "en": "ESC %index flash memory error",
    "es": "Error de memoria flash de ESC %index",
    "fr": "Erreur mémoire flash ESC %index",
    "ja": "ESC%indexフラッシュメモリのエラー",
    "ko": "ESC %index 플래시 메모리 오류",
    "ru": "Ошибка флеш-памяти ESC %index",
    "tr": "ESC %index flaş bellek hatası",
    "zh": "%index号电调闪存异常"
  },
  "fpv_tip_0x1608002F": {
    "de": "Selbsttest des ESC %index fehlgeschlagen %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC%index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080030": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080031": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080032": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080033": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080034": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080035": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080036": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080037": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080038": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080039": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x1608003a": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x1608003b": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x1608003c": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x1608003d": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x1608003e": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x1608003f": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080040": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080041": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080042": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080043": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080044": {
    "de": "Spannung von ESC %index zu niedrig",
    "en": "ESC %index voltage too low",
    "es": "Voltaje de ESC %index demasiado bajo",
    "fr": "Tension ESC %index trop basse",
    "ja": "ESC %index低電圧",
    "ko": "ESC %index 전압 너무 낮음",
    "ru": "Слишком низкое напряжение ESC %index",
    "tr": "ESC %index voltajı çok düşük",
    "zh": "%index号电调电压过低"
  },
  "fpv_tip_0x16080045": {
    "de": "Spannung von ESC %index zu hoch",
    "en": "ESC %index voltage too high",
    "es": "Voltaje de ESC %index demasiado alto",
    "fr": "Tension ESC %index trop élevée",
    "ja": "ESC %index高電圧",
    "ko": "ESC %index 전압 너무 높음",
    "ru": "Слишком высокое напряжение ESC %index",
    "tr": "ESC %index voltajı çok yüksek",
    "zh": "%index号电调电压过高"
  },
  "fpv_tip_0x16080046": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080047": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080048": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080049": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x1608004a": {
    "de": "ESC %index überhitzt",
    "en": "ESC %index overheated",
    "es": "ESC %index sobrecalentado",
    "fr": "Surchauffe ESC %index",
    "ja": "ESC %index高温",
    "ko": "ESC %index 과열",
    "ru": "ESC %index перегрелся",
    "tr": "ESC %index aşırı ısındı",
    "zh": "%index号电调温度过高"
  },
  "fpv_tip_0x1608004b": {
    "de": "ESC %index Temperatur zu niedrig %index",
    "en": "ESC %index temperature too low",
    "es": "Temperatura de ESC %index demasiado baja",
    "fr": "Température ESC %index trop basse",
    "ja": "ESC%index低温",
    "ko": "ESC %index 온도 너무 낮음",
    "ru": "Слишком низкая температура ESC %index",
    "tr": "ESC %index sıcaklığı çok düşük",
    "zh": "%index号电调温度过低"
  },
  "fpv_tip_0x1608004c": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x1608004d": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x1608004e": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x1608004f": {
    "de": "Fehler: Selbsttest von ESC %index",
    "en": "ESC %index auto-check error",
    "es": "Error de comprobación automática de ESC %index",
    "fr": "Erreur vérif. auto de l\\'ESC %index",
    "ja": "ESC %index自動確認エラー",
    "ko": "ESC %index 자체 점검 오류",
    "ru": "Ошибка автопроверки ESC %index",
    "tr": "ESC %index otomatik kontrol hatası",
    "zh": "%index号电调自检异常"
  },
  "fpv_tip_0x16080050": {
    "de": "ESC-Fehler. Start nicht möglich. Fluggerät neu starten und erneut versuchen",
    "en": "ESC error. Unable to take off. Restart aircraft and try again",
    "es": "Error de ESC. No se puede despegar. Reinicia la aeronave y vuelve a intentarlo",
    "fr": "Erreur de l'ESC Impossible de décoller. Redémarrez l'appareil et réessayez",
    "ja": "ESCエラー。離陸できません。機体を再起動し、やり直してください",
    "ko": "ESC 오류. 이륙 불가. 기체를 재시작하고 다시 시도하세요.",
    "ru": "Ошибка ESC. Не удается выполнить взлет. Перезапустите дрон и повторите попытку",
    "tr": "ESC hatası. Kalkış yapılamıyor. Aracı yeniden başlatın ve tekrar deneyin",
    "zh": "无法起飞：电调异常，请尝试重启飞行器"
  },
  "fpv_tip_0x16080050_in_the_sky": {
    "de": "ESC-Fehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "ESC error. Return to home or land promptly",
    "es": "Error de ESC. Regresa al punto de origen o aterriza rápidamente",
    "fr": "Erreur de l'ESC Retournez au point de départ ou atterrissez rapidement",
    "ja": "ESCエラー。直ちに帰還するか、着陸してください",
    "ko": "ESC 오류. 즉시 리턴 투 홈 또는 착륙 필요",
    "ru": "Ошибка ESC. Вернитесь в исходное положение или приземлитесь как можно скорее",
    "tr": "ESC hatası. Eve geri dönün veya derhal iniş yapın",
    "zh": "电调异常，请尽快返航或降落"
  },
  "fpv_tip_0x16080051": {
    "de": "ESC-Fehler. Mit Vorsicht fliegen",
    "en": "ESC error. Fly with caution",
    "es": "Error de ESC. Vuela con cuidado",
    "fr": "Erreur de l'ESC Pilotez avec précaution",
    "ja": "ESCエラー。慎重に飛行してください",
    "ko": "ESC 오류. 비행 시 주의 필요",
    "ru": "Ошибка ESC. Соблюдайте осторожность при полете",
    "tr": "ESC hatası. Aracınızı dikkatli uçurun",
    "zh": "电调异常，请谨慎飞行"
  },
  "fpv_tip_0x16080052": {
    "de": "Antriebssystem-Fehler. Propeller gefaltet. Start nicht möglich",
    "en": "Propulsion system error. Propellers folded. Unable to take off",
    "es": "Error del sistema de propulsión. Hélices plegadas. No se puede despegar",
    "fr": "Erreur du système de propulsion. Hélices repliées. Impossible de décoller",
    "ja": "推進システムエラー。プロペラが折りたたまれています。離陸できません",
    "ko": "추진력 시스템 오류. 프로펠러 접힘. 이륙 불가",
    "ru": "Ошибка силовой установки. Пропеллеры сложены. Не удается выполнить взлет",
    "tr": "Tahrik sistemi hatası. Pervaneler katlanmış durumda. Kalkış yapılamıyor",
    "zh": "无法起飞：动力系统异常，桨叶未展开"
  },
  "fpv_tip_0x16080052_in_the_sky": {
    "de": "Antriebssystem-Fehler. Propeller gefaltet",
    "en": "Propulsion system error. Propellers folded",
    "es": "Error del sistema de propulsión. Hélices plegadas",
    "fr": "Erreur du système de propulsion. Hélices repliées",
    "ja": "推進システムエラー。プロペラが折りたたまれています",
    "ko": "추진력 시스템 오류. 프로펠러 접힘",
    "ru": "Ошибка силовой установки. Пропеллеры сложены",
    "tr": "Tahrik sistemi hatası. Pervaneler katlanmış durumda",
    "zh": "动力系统异常，桨叶未展开"
  },
  "fpv_tip_0x16080053": {
    "de": "ESC-Fehler. Mit Vorsicht fliegen",
    "en": "ESC error. Fly with caution",
    "es": "Error de ESC. Vuela con cuidado",
    "fr": "Erreur de l'ESC Pilotez avec précaution",
    "ja": "ESCエラー。慎重に飛行してください",
    "ko": "ESC 오류. 비행 시 주의 필요",
    "ru": "Ошибка ESC. Соблюдайте осторожность при полете",
    "tr": "ESC hatası. Aracınızı dikkatli uçurun",
    "zh": "电调异常，请谨慎飞行"
  },
  "fpv_tip_0x16080054": {
    "de": "ESC-Fehler. Start nicht möglich. Fluggerät neu starten und erneut versuchen",
    "en": "ESC error. Unable to take off. Restart aircraft and try again",
    "es": "Error de ESC. No se puede despegar. Reinicia la aeronave y vuelve a intentarlo",
    "fr": "Erreur de l'ESC Impossible de décoller. Redémarrez l'appareil et réessayez",
    "ja": "ESCエラー。離陸できません。機体を再起動し、やり直してください",
    "ko": "ESC 오류. 이륙 불가. 기체를 재시작하고 다시 시도하세요.",
    "ru": "Ошибка ESC. Не удается выполнить взлет. Перезапустите дрон и повторите попытку",
    "tr": "ESC hatası. Kalkış yapılamıyor. Aracı yeniden başlatın ve tekrar deneyin",
    "zh": "无法起飞：电调异常，请尝试重启飞行器"
  },
  "fpv_tip_0x16080054_in_the_sky": {
    "de": "ESC-Fehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "ESC error. Return to home or land promptly",
    "es": "Error de ESC. Regresa al punto de origen o aterriza rápidamente",
    "fr": "Erreur de l'ESC Retournez au point de départ ou atterrissez rapidement",
    "ja": "ESCエラー。直ちに帰還するか、着陸してください",
    "ko": "ESC 오류. 즉시 리턴 투 홈 또는 착륙 필요",
    "ru": "Ошибка ESC. Вернитесь в исходное положение или приземлитесь как можно скорее",
    "tr": "ESC hatası. Eve geri dönün veya derhal iniş yapın",
    "zh": "电调异常，请尽快返航或降落"
  },
  "fpv_tip_0x16080055": {
    "ar": "",
    "de": "Motor %index überhitzt. Fluggerät sofort landen",
    "en": "Motor %index overheated. Land aircraft immediately",
    "es": "Motor %index sobrecalentado. Aterrice la aeronave de inmediato",
    "fr": "Surchauffe du moteur %index. Faire atterrir l'appareil immédiatement",
    "id": "",
    "it": "",
    "ja": "モーター%indexがオーバーヒートしました。機体を直ちに着陸させてください",
    "ko": "모터 %index 과열 상태입니다. 기기를 즉시 착륙시키세요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Двигатель %index перегрелся. Немедленно посадите дрон",
    "th": "",
    "tr": "Motor %index aşırı ısındı. Hava aracını derhal indirin",
    "ug": "",
    "vi": "",
    "zh": "%index号电机温度较高，请尽快降落",
    "zh-Hant": ""
  },
  "fpv_tip_0x16080056": {
    "ar": "",
    "de": "Motor %index überhitzt. Fluggerät landet möglicherweise automatisch",
    "en": "Motor %index overheated. Aircraft may be auto landing",
    "es": "Motor %index sobrecalentado. La aeronave puede realizar un aterrizaje automático",
    "fr": "Surchauffe du moteur %index. L'appareil peut atterrir automatiquement",
    "id": "",
    "it": "",
    "ja": "モーター%indexがオーバーヒートしました。機体が自動着陸中の可能性があります",
    "ko": "모터 %index 과열 상태입니다. 기체가 자동 착륙할 수 있습니다",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Двигатель %index перегрелся. Дрон может совершать автоматическую посадку",
    "th": "",
    "tr": "Motor %index aşırı ısındı. Hava aracı otomatik iniş yapıyor olabilir",
    "ug": "",
    "vi": "",
    "zh": "%index号电机温度过高，飞机可能降落",
    "zh-Hant": ""
  },
  "fpv_tip_0x16080056_in_the_sky": {
    "ar": "",
    "de": "Motor %index -Temperatur zu hoch. Fluggeschwindigkeit beschränkt.",
    "en": "Motor %index temperature too high. Flight speed limited",
    "es": "Temperatura del motor %index   demasiado alta. Velocidad de vuelo limitada",
    "fr": "Température moteur %index  trop élevée. Vitesse de vol limitée",
    "id": "",
    "it": "",
    "ja": "モーター%indexの温度が高すぎます。飛行速度が制限されている",
    "ko": "모터 %index  온도 너무 높음. 비행 속도 제한됨",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Слишком высокая температура мотора %index. Скорость полета ограничена",
    "th": "",
    "tr": "Motor %index  sıcaklığı çok yüksek. Uçuş hızı sınırlı",
    "ug": "",
    "vi": "",
    "zh": "%index号电机温度过高，飞机可能降落",
    "zh-Hant": ""
  },
  "fpv_tip_0x16080057": {
    "de": "ESC-Fehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "ESC error. Return to home or land promptly",
    "es": "Error de ESC. Regresa al punto de origen o aterriza rápidamente",
    "fr": "Erreur de l'ESC Retournez au point de départ ou atterrissez rapidement",
    "ja": "ESCエラー。直ちに帰還するか、着陸してください",
    "ko": "ESC 오류. 즉시 리턴 투 홈 또는 착륙 필요",
    "ru": "Ошибка ESC. Вернитесь в исходное положение или приземлитесь как можно скорее",
    "tr": "ESC hatası. Eve geri dönün veya derhal iniş yapın",
    "zh": "电调异常，请尽快返航或降落"
  },
  "fpv_tip_0x16080057_in_the_sky": {
    "de": "ESC-Fehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "ESC error. Return to home or land promptly",
    "es": "Error de ESC. Regresa al punto de origen o aterriza rápidamente",
    "fr": "Erreur de l'ESC Retournez au point de départ ou atterrissez rapidement",
    "ja": "ESCエラー。直ちに帰還するか、着陸してください",
    "ko": "ESC 오류. 즉시 리턴 투 홈 또는 착륙 필요",
    "ru": "Ошибка ESC. Вернитесь в исходное положение или приземлитесь как можно скорее",
    "tr": "ESC hatası. Eve geri dönün veya derhal iniş yapın",
    "zh": "电调异常，请尽快返航或降落"
  },
  "fpv_tip_0x16080058": {
    "de": "ESC-Fehler. Start nicht möglich. DJI Support kontaktieren",
    "en": "ESC error. Unable to take off. Contact DJI Support",
    "es": "Error de ESC. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur de l'ESC Impossible de décoller. Contactez le Service client DJI",
    "ja": "ESCエラー。離陸できません。DJIサポートまでお問い合わせください",
    "ko": "ESC 오류. 이륙 불가. DJI 고객지원으로 문의하세요.",
    "ru": "Ошибка ESC. Не удается выполнить взлет. Свяжитесь со службой поддержки DJI",
    "tr": "ESC hatası. Kalkış yapılamıyor. DJI Destek ekibiyle iletişime geçin",
    "zh": "无法起飞：电调异常，请联系售后"
  },
  "fpv_tip_0x16080058_in_the_sky": {
    "de": "ESC-Fehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "ESC error. Return to home or land promptly",
    "es": "Error de ESC. Regresa al punto de origen o aterriza rápidamente",
    "fr": "Erreur de l'ESC Retournez au point de départ ou atterrissez rapidement",
    "ja": "ESCエラー。直ちに帰還するか、着陸してください",
    "ko": "ESC 오류. 즉시 리턴 투 홈 또는 착륙 필요",
    "ru": "Ошибка ESC. Вернитесь в исходное положение или приземлитесь как можно скорее",
    "tr": "ESC hatası. Eve geri dönün veya derhal iniş yapın",
    "zh": "电调异常，请尽快返航或降落"
  },
  "fpv_tip_0x16080059": {
    "de": "ESC-Fehler. Start nicht möglich. DJI Support kontaktieren",
    "en": "ESC error. Unable to take off. Contact DJI Support",
    "es": "Error de ESC. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur de l'ESC Impossible de décoller. Contactez le Service client DJI",
    "ja": "ESCエラー。離陸できません。DJIサポートまでお問い合わせください",
    "ko": "ESC 오류. 이륙 불가. DJI 고객지원으로 문의하세요.",
    "ru": "Ошибка ESC. Не удается выполнить взлет. Свяжитесь со службой поддержки DJI",
    "tr": "ESC hatası. Kalkış yapılamıyor. DJI Destek ekibiyle iletişime geçin",
    "zh": "无法起飞：电调异常，请联系售后"
  },
  "fpv_tip_0x16080059_in_the_sky": {
    "de": "ESC-Fehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "ESC error. Return to home or land promptly",
    "es": "Error de ESC. Regresa al punto de origen o aterriza rápidamente",
    "fr": "Erreur de l'ESC Retournez au point de départ ou atterrissez rapidement",
    "ja": "ESCエラー。直ちに帰還するか、着陸してください",
    "ko": "ESC 오류. 즉시 리턴 투 홈 또는 착륙 필요",
    "ru": "Ошибка ESC. Вернитесь в исходное положение или приземлитесь как можно скорее",
    "tr": "ESC hatası. Eve geri dönün veya derhal iniş yapın",
    "zh": "电调异常，请尽快返航或降落"
  },
  "fpv_tip_0x1608005A": {
    "de": "ESC-Fehler. Start nicht möglich. DJI Support kontaktieren",
    "en": "ESC error. Unable to take off. Contact DJI Support",
    "es": "Error de ESC. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur de l'ESC Impossible de décoller. Contactez le Service client DJI",
    "it": "",
    "ja": "ESCエラー。離陸できません。DJIサポートまでお問い合わせください",
    "ko": "ESC 오류. 이륙 불가. DJI 고객지원으로 문의하세요.",
    "pt": "",
    "ru": "Ошибка ESC. Не удается выполнить взлет. Свяжитесь со службой поддержки DJI",
    "tr": "ESC hatası. Kalkış yapılamıyor. DJI Destek ekibiyle iletişime geçin",
    "zh": "无法起飞：电调异常，请联系售后"
  },
  "fpv_tip_0x1608005A_in_the_sky": {
    "de": "ESC-Fehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "ESC error. Return to home or land promptly",
    "es": "Error de ESC. Regresa al punto de origen o aterriza rápidamente",
    "fr": "Erreur de l'ESC Retournez au point de départ ou atterrissez rapidement",
    "ja": "ESCエラー。直ちに帰還するか、着陸してください",
    "ko": "ESC 오류. 즉시 리턴 투 홈 또는 착륙 필요",
    "ru": "Ошибка ESC. Вернитесь в исходное положение или приземлитесь как можно скорее",
    "tr": "ESC hatası. Eve geri dönün veya derhal iniş yapın",
    "zh": "电调异常，请尽快返航或降落"
  },
  "fpv_tip_0x1608005B": {
    "de": "ESC-Fehler. Start nicht möglich. DJI Support kontaktieren",
    "en": "ESC error. Unable to take off. Contact DJI Support",
    "es": "Error de ESC. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur de l'ESC Impossible de décoller. Contactez le Service client DJI",
    "it": "",
    "ja": "ESCエラー。離陸できません。DJIサポートまでお問い合わせください",
    "ko": "ESC 오류. 이륙 불가. DJI 고객지원으로 문의하세요.",
    "pt": "",
    "ru": "Ошибка ESC. Не удается выполнить взлет. Свяжитесь со службой поддержки DJI",
    "tr": "ESC hatası. Kalkış yapılamıyor. DJI Destek ekibiyle iletişime geçin",
    "zh": "无法起飞：电调异常，请联系售后"
  },
  "fpv_tip_0x1608005B_in_the_sky": {
    "de": "ESC-Fehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "ESC error. Return to home or land promptly",
    "es": "Error de ESC. Regresa al punto de origen o aterriza rápidamente",
    "fr": "Erreur de l'ESC Retournez au point de départ ou atterrissez rapidement",
    "ja": "ESCエラー。直ちに帰還するか、着陸してください",
    "ko": "ESC 오류. 즉시 리턴 투 홈 또는 착륙 필요",
    "ru": "Ошибка ESC. Вернитесь в исходное положение или приземлитесь как можно скорее",
    "tr": "ESC hatası. Eve geri dönün veya derhal iniş yapın",
    "zh": "电调异常，请尽快返航或降落"
  },
  "fpv_tip_0x1608005C": {
    "de": "ESC-Fehler. Start nicht möglich. DJI Support kontaktieren",
    "en": "ESC error. Unable to take off. Contact DJI Support",
    "es": "Error de ESC. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur de l'ESC Impossible de décoller. Contactez le Service client DJI",
    "it": "",
    "ja": "ESCエラー。離陸できません。DJIサポートまでお問い合わせください",
    "ko": "ESC 오류. 이륙 불가. DJI 고객지원으로 문의하세요.",
    "pt": "",
    "ru": "Ошибка ESC. Не удается выполнить взлет. Свяжитесь со службой поддержки DJI",
    "tr": "ESC hatası. Kalkış yapılamıyor. DJI Destek ekibiyle iletişime geçin",
    "zh": "无法起飞：电调异常，请联系售后"
  },
  "fpv_tip_0x1608005C_in_the_sky": {
    "de": "ESC-Fehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "ESC error. Return to home or land promptly",
    "es": "Error de ESC. Regresa al punto de origen o aterriza rápidamente",
    "fr": "Erreur de l'ESC Retournez au point de départ ou atterrissez rapidement",
    "ja": "ESCエラー。直ちに帰還するか、着陸してください",
    "ko": "ESC 오류. 즉시 리턴 투 홈 또는 착륙 필요",
    "ru": "Ошибка ESC. Вернитесь в исходное положение или приземлитесь как можно скорее",
    "tr": "ESC hatası. Eve geri dönün veya derhal iniş yapın",
    "zh": "电调异常，请尽快返航或降落"
  },
  "fpv_tip_0x1608005D": {
    "de": "ESC-Fehler. Start nicht möglich. DJI Support kontaktieren",
    "en": "ESC error. Unable to take off. Contact DJI Support",
    "es": "Error de ESC. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur de l'ESC Impossible de décoller. Contactez le Service client DJI",
    "it": "",
    "ja": "ESCエラー。離陸できません。DJIサポートまでお問い合わせください",
    "ko": "ESC 오류. 이륙 불가. DJI 고객지원으로 문의하세요.",
    "pt": "",
    "ru": "Ошибка ESC. Не удается выполнить взлет. Свяжитесь со службой поддержки DJI",
    "tr": "ESC hatası. Kalkış yapılamıyor. DJI Destek ekibiyle iletişime geçin",
    "zh": "无法起飞：电调异常，请联系售后"
  },
  "fpv_tip_0x1608005D_in_the_sky": {
    "de": "ESC-Fehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "ESC error. Return to home or land promptly",
    "es": "Error de ESC. Regresa al punto de origen o aterriza rápidamente",
    "fr": "Erreur de l'ESC Retournez au point de départ ou atterrissez rapidement",
    "ja": "ESCエラー。直ちに帰還するか、着陸してください",
    "ko": "ESC 오류. 즉시 리턴 투 홈 또는 착륙 필요",
    "ru": "Ошибка ESC. Вернитесь в исходное положение или приземлитесь как можно скорее",
    "tr": "ESC hatası. Eve geri dönün veya derhal iniş yapın",
    "zh": "电调异常，请尽快返航或降落"
  },
  "fpv_tip_0x1608005E": {
    "de": "ESC-Fehler. Start nicht möglich. DJI Support kontaktieren",
    "en": "ESC error. Unable to take off. Contact DJI Support",
    "es": "Error de ESC. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur de l'ESC Impossible de décoller. Contactez le Service client DJI",
    "it": "",
    "ja": "ESCエラー。離陸できません。DJIサポートまでお問い合わせください",
    "ko": "ESC 오류. 이륙 불가. DJI 고객지원으로 문의하세요.",
    "pt": "",
    "ru": "Ошибка ESC. Не удается выполнить взлет. Свяжитесь со службой поддержки DJI",
    "tr": "ESC hatası. Kalkış yapılamıyor. DJI Destek ekibiyle iletişime geçin",
    "zh": "无法起飞：电调异常，请联系售后"
  },
  "fpv_tip_0x1608005F": {
    "ar": "",
    "de": "Motor %index überhitzt. Fluggerät landet automatisch",
    "en": "Motor %index overheated. Aircraft auto landing",
    "es": "Motor %index sobrecalentado. Aterrizaje automático de la aeronave",
    "fr": "Surchauffe du moteur %index. Atterrissage automatique de l'appareil",
    "id": "",
    "it": "",
    "ja": "モーター%indexがオーバーヒートしました。機体が自動着陸中",
    "ko": "모터 %index 과열 상태입니다. 기체 자동 착륙",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Двигатель %index перегрелся. Автоматическая посадка дрона",
    "th": "",
    "tr": "Motor %index aşırı ısındı. Araç otomatik iniş yapıyor",
    "ug": "",
    "vi": "",
    "zh": "%index号电机超温，强制降落",
    "zh-Hant": ""
  },
  "fpv_tip_0x1608005a": {
    "de": "ESC-Fehler. Start nicht möglich. DJI Support kontaktieren",
    "en": "ESC error. Unable to take off. Contact DJI Support",
    "es": "Error de ESC. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur de l'ESC Impossible de décoller. Contactez le Service client DJI",
    "ja": "ESCエラー。離陸できません。DJIサポートまでお問い合わせください",
    "ko": "ESC 오류. 이륙 불가. DJI 고객지원으로 문의하세요.",
    "ru": "Ошибка ESC. Не удается выполнить взлет. Свяжитесь со службой поддержки DJI",
    "tr": "ESC hatası. Kalkış yapılamıyor. DJI Destek ekibiyle iletişime geçin",
    "zh": "无法起飞：电调异常，请联系售后"
  },
  "fpv_tip_0x1608005b": {
    "de": "ESC-Fehler. Start nicht möglich. DJI Support kontaktieren",
    "en": "ESC error. Unable to take off. Contact DJI Support",
    "es": "Error de ESC. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur de l'ESC Impossible de décoller. Contactez le Service client DJI",
    "ja": "ESCエラー。離陸できません。DJIサポートまでお問い合わせください",
    "ko": "ESC 오류. 이륙 불가. DJI 고객지원으로 문의하세요.",
    "ru": "Ошибка ESC. Не удается выполнить взлет. Свяжитесь со службой поддержки DJI",
    "tr": "ESC hatası. Kalkış yapılamıyor. DJI Destek ekibiyle iletişime geçin",
    "zh": "无法起飞：电调异常，请联系售后"
  },
  "fpv_tip_0x1608005c": {
    "de": "ESC-Fehler. Start nicht möglich. DJI Support kontaktieren",
    "en": "ESC error. Unable to take off. Contact DJI Support",
    "es": "Error de ESC. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur de l'ESC Impossible de décoller. Contactez le Service client DJI",
    "ja": "ESCエラー。離陸できません。DJIサポートまでお問い合わせください",
    "ko": "ESC 오류. 이륙 불가. DJI 고객지원으로 문의하세요.",
    "ru": "Ошибка ESC. Не удается выполнить взлет. Свяжитесь со службой поддержки DJI",
    "tr": "ESC hatası. Kalkış yapılamıyor. DJI Destek ekibiyle iletişime geçin",
    "zh": "无法起飞：电调异常，请联系售后"
  },
  "fpv_tip_0x1608005d": {
    "de": "ESC-Fehler. Start nicht möglich. DJI Support kontaktieren",
    "en": "ESC error. Unable to take off. Contact DJI Support",
    "es": "Error de ESC. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur de l'ESC Impossible de décoller. Contactez le Service client DJI",
    "ja": "ESCエラー。離陸できません。DJIサポートまでお問い合わせください",
    "ko": "ESC 오류. 이륙 불가. DJI 고객지원으로 문의하세요.",
    "ru": "Ошибка ESC. Не удается выполнить взлет. Свяжитесь со службой поддержки DJI",
    "tr": "ESC hatası. Kalkış yapılamıyor. DJI Destek ekibiyle iletişime geçin",
    "zh": "无法起飞：电调异常，请联系售后"
  },
  "fpv_tip_0x1608005e": {
    "de": "ESC-Fehler. Start nicht möglich. DJI Support kontaktieren",
    "en": "ESC error. Unable to take off. Contact DJI Support",
    "es": "Error de ESC. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur de l'ESC Impossible de décoller. Contactez le Service client DJI",
    "ja": "ESCエラー。離陸できません。DJIサポートまでお問い合わせください",
    "ko": "ESC 오류. 이륙 불가. DJI 고객지원으로 문의하세요.",
    "ru": "Ошибка ESC. Не удается выполнить взлет. Свяжитесь со службой поддержки DJI",
    "tr": "ESC hatası. Kalkış yapılamıyor. DJI Destek ekibiyle iletişime geçin",
    "zh": "无法起飞：电调异常，请联系售后"
  },
  "fpv_tip_0x1608005e_in_the_sky": {
    "de": "ESC-Fehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "ESC error. Return to home or land promptly",
    "es": "Error de ESC. Regresa al punto de origen o aterriza rápidamente",
    "fr": "Erreur de l'ESC Retournez au point de départ ou atterrissez rapidement",
    "ja": "ESCエラー。直ちに帰還するか、着陸してください",
    "ko": "ESC 오류. 즉시 리턴 투 홈 또는 착륙 필요",
    "ru": "Ошибка ESC. Вернитесь в исходное положение или приземлитесь как можно скорее",
    "tr": "ESC hatası. Eve geri dönün veya derhal iniş yapın",
    "zh": "电调异常，请尽快返航或降落"
  },
  "fpv_tip_0x16080077": {
    "ar": "",
    "de": "",
    "en": "%index ESC overheated (%alarmid)",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "%index号电调严重超温(%alarmid)",
    "zh-Hant": ""
  },
  "fpv_tip_0x16080078": {
    "ar": "",
    "de": "",
    "en": "%index ESC overheated (%alarmid)",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "%index号电调严重超温(%alarmid)",
    "zh-Hant": ""
  },
  "fpv_tip_0x16080079": {
    "ar": "",
    "de": "",
    "en": "%index ESC firmware incompatible with aircraft model (%alarmid)",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "%index号电调固件与飞机机型不匹配(%alarmid)",
    "zh-Hant": ""
  },
  "fpv_tip_0x1608007A": {
    "ar": "",
    "de": "",
    "en": "%index ESC firmware incompatible with aircraft model (%alarmid)",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "%index号电调固件与飞机机型不匹配(%alarmid)",
    "zh-Hant": ""
  },
  "fpv_tip_0x1608007B": {
    "ar": "",
    "de": "",
    "en": "%index ESC auto-check error (%alarmid)",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "%index号电调自检异常(%alarmid)",
    "zh-Hant": ""
  },
  "fpv_tip_0x1608007C": {
    "ar": "",
    "de": "",
    "en": "%index ESC auto-check error (%alarmid)",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "%index号电调自检异常(%alarmid)",
    "zh-Hant": ""
  },
  "fpv_tip_0x1608007D": {
    "ar": "",
    "de": "",
    "en": "%index ESC or motor auto-check error (%alarmid)",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "%index号电调或电机自检异常(%alarmid)",
    "zh-Hant": ""
  },
  "fpv_tip_0x1608007E": {
    "ar": "",
    "de": "",
    "en": "%index ESC or motor auto-check error (%alarmid)",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "%index号电调或电机自检异常(%alarmid)",
    "zh-Hant": ""
  },
  "fpv_tip_0x1608007F": {
    "ar": "",
    "de": "",
    "en": "%index ESC or motor auto-check error (%alarmid)",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "%index号电调或电机自检异常(%alarmid)",
    "zh-Hant": ""
  },
  "fpv_tip_0x16080080": {
    "ar": "",
    "de": "",
    "en": "%index ESC or motor auto-check error (%alarmid)",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "%index号电调或电机自检异常(%alarmid)",
    "zh-Hant": ""
  },
  "fpv_tip_0x16090050": {
    "de": "%battery_index Akku: Stromversorgungsfehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery power supply error. Return to home or land promptly",
    "es": "Error de fuente de alimentación de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Erreur d'alimentation de la batterie %battery_index. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー電源エラー。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 전력 공급 장치 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: ошибка источника питания. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil güç kaynağı hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池供电异常，请尽快返航或降落"
  },
  "fpv_tip_0x16090051": {
    "de": "%battery_index Akku: Stromversorgungsfehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery power supply error. Return to home or land promptly",
    "es": "Error de fuente de alimentación de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Erreur d'alimentation de la batterie %battery_index. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー電源エラー。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 전력 공급 장치 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: ошибка источника питания. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil güç kaynağı hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池供电异常，请尽快返航或降落"
  },
  "fpv_tip_0x16090052": {
    "de": "%battery_index Akku: Temperatursensorfehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery temperature sensor error. Return to home or land promptly",
    "es": "Error del sensor de temperatura de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Erreur capteur de température de la batterie %battery_index. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー温度センサーエラー。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 온도 센서 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: ошибка датчика температуры. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil sıcaklık sensörü hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池温度传感器异常，请尽快返航或降落"
  },
  "fpv_tip_0x16090061": {
    "de": "%battery_index Akku: Überhitzt. Umgehend zum Startpunkt zurückkehren oder landen. Akku nicht mehr verwenden",
    "en": "%battery_index Battery overheated. Return to home or land promptly. Stop using battery",
    "es": "Batería %battery_index sobrecalentada. Regrese al punto de origen o aterrice rápidamente. Deje de usar la batería.",
    "fr": "Surchauffe batterie %battery_index. Retourner au point de départ ou atterrir rapidement. Cesser d'utiliser la batterie",
    "ja": "%battery_index バッテリー高温。 直ちに帰還するか、着陸してください。 バッテリーの使用を中止してください",
    "ko": "%battery_index 배터리 과열됨. 즉시 리턴 투 홈을 실행하거나 착륙하세요. 배터리 사용을 중지하세요.",
    "ru": "Перегрев %battery_index АКБ. Как можно скорее вернитесь на базу или приземлитесь. Прекратите использовать АКБ",
    "tr": "%battery_index Pil aşırı ısındı. Başlangıç noktasına geri dönün veya derhal iniş yapın. Pili kullanmayı bırakın",
    "zh": "%battery_index电池温度过高，请尽快返航或降落，停止使用该电池"
  },
  "fpv_tip_0x16090062": {
    "de": "%battery_index Akku: MOS-Temperatur zu hoch. Umgehend zum Startpunkt zurückkehren oder landen. %battery_index Akku: Warten, bis Akku abgekühlt ist",
    "en": "%battery_index Battery MOS temperature too high. Return to home or land promptly. Wait until %battery_index Battery cools down",
    "es": "Temperatura del MOS de la batería %battery_index demasiado alta. Regrese al punto de origen o aterrice rápidamente. Espere a que se enfríe la batería %battery_index.",
    "fr": "Température MOS de la batterie %battery_index excessive. Retourner au point de départ ou atterrir rapidement. Attendre que la batterie %battery_index refroidisse",
    "ja": "%battery_index バッテリー MOS 温度が高すぎます。 直ちに帰還するか、着陸してください。 %battery_index バッテリーが冷えるまでお待ちください",
    "ko": "%battery_index 배터리 MOS 온도가 너무 높음. 즉시 리턴 투 홈을 실행하거나 착륙하세요. %battery_index 배터리가 식을 때까지 기다려 주세요.",
    "ru": "%battery_index АКБ: слишком высокая температура MOS. Как можно скорее вернитесь на базу или приземлитесь. Подождите, пока %battery_index АКБ остынет",
    "tr": "%battery_index Pil MOS sıcaklığı çok yüksek. Başlangıç noktasına geri dönün veya derhal iniş yapın. %battery_index Pil soğuyana kadar bekleyin",
    "zh": "%battery_index电池MOS温度过高，请尽快返航或降落，等待%battery_index电池温度降低"
  },
  "fpv_tip_0x16090063": {
    "de": "%battery_index Akku: Spannung zu hoch. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery voltage too high. Return to home or land promptly",
    "es": "Voltaje de la batería %battery_index demasiado alto. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Tension de batterie %battery_index excessive. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー電圧が高すぎます。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 전압이 너무 높음. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: слишком высокое напряжение. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil gerilimi çok yüksek. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池总电压过高，请尽快返航或降落"
  },
  "fpv_tip_0x16090064": {
    "de": "%battery_index Akku: Spannung zu niedrig. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery voltage too low. Return to home or land promptly",
    "es": "Voltaje de la batería %battery_index demasiado bajo. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Tension de batterie %battery_index trop basse. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー電圧が低すぎます。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 전압이 너무 낮음. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: слишком низкое напряжение. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil gerilimi çok düşük. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池总电压过低，请尽快返航或降落"
  },
  "fpv_tip_0x16090065": {
    "de": "%battery_index Akku: Spannung einer einzelnen Akkuzelle zu hoch. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery single cell voltage too high. Return to home or land promptly",
    "es": "Voltaje de la célula individual de la batería %battery_index demasiado alto. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Tension de cellule unique de la batterie %battery_index excessive. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー 1セルの電圧が高すぎます。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 단일 셀 전압이 너무 높음. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: слишком высокое напряжение отдельной ячейки. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil tek hücre gerilimi çok yüksek. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池单电芯电压过高，请尽快返航或降落"
  },
  "fpv_tip_0x16090066": {
    "de": "%battery_index Akku: Spannung einer einzelnen Akkuzelle zu niedrig. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery single cell voltage too low. Return to home or land promptly",
    "es": "Voltaje de la célula individual de la batería %battery_index demasiado bajo. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Tension de cellule unique de la batterie %battery_index trop basse. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー 1セルの電圧が低すぎます。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 단일 셀 전압이 너무 낮음. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: слишком низкое напряжение отдельной ячейки. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil tek hücre gerilimi çok düşük. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池单电芯电压过低，请尽快返航或降落"
  },
  "fpv_tip_0x16090067": {
    "de": "%battery_index Akku: Großer Unterschied bei statischen Spannungen zwischen Akkuzellen. Akku nicht mehr verwenden",
    "en": "Large static voltage difference between %battery_index Battery cells. Stop using battery",
    "es": "Gran diferencia entre los voltajes estáticos de las células de la batería %battery_index. Deje de usar la batería.",
    "fr": "Différence importante entre les tensions statiques des cellules de batterie %battery_index. Cesser d'utiliser la batterie",
    "ja": "%battery_index バッテリーセル間の静電電圧差が大きすぎます。 バッテリーの使用を中止してください",
    "ko": "%battery_index 배터리 셀 간 정전압 차가 너무 큼. 배터리 사용을 중지하세요.",
    "ru": "Большая разница между статическими напряжениями ячеек %battery_index АКБ. Прекратите использовать АКБ",
    "tr": "%battery_index Pil hücreleri arasındaki statik gerilim farkı yüksek. Pili kullanmayı bırakın",
    "zh": "%battery_index电池静态压差过大，请停止使用该电池"
  },
  "fpv_tip_0x16090068": {
    "de": "%battery_index Akku: Großer Unterschied bei Ladespannungen zwischen Akkuzellen. Umgehend zum Startpunkt zurückkehren oder landen. Akku nicht mehr verwenden",
    "en": "Large charge voltage difference between %battery_index Battery cells. Return to home or land promptly. Stop using battery",
    "es": "Gran diferencia entre los voltajes de carga de las células de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente. Deje de usar la batería.",
    "fr": "Différence de charge importante entre les cellules de batterie %battery_index. Retourner au point de départ ou atterrir rapidement. Cesser d'utiliser la batterie",
    "ja": "%battery_index バッテリーセル間の充電電圧差が大きすぎます。 直ちに帰還するか、着陸してください。 バッテリーの使用を中止してください",
    "ko": "%battery_index 배터리 셀 간 충전 전압 차가 너무 큼. 즉시 리턴 투 홈을 실행하거나 착륙하세요. 배터리 사용을 중지하세요.",
    "ru": "Большая разница между напряжениями заряда ячеек %battery_index АКБ. Как можно скорее вернитесь на базу или приземлитесь. Прекратите использовать АКБ",
    "tr": "%battery_index Pil hücreleri arasındaki şarj gerilim farkı yüksek. Başlangıç noktasına geri dönün veya derhal iniş yapın. Pili kullanmayı bırakın",
    "zh": "%battery_index电池充电电芯间压差过大，请尽快返航或降落，并停止使用该电池"
  },
  "fpv_tip_0x16090069": {
    "de": "%battery_index Akku: Großer Unterschied bei Entladespannungen zwischen Akkuzellen. Umgehend zum Startpunkt zurückkehren oder landen. Akku nicht mehr verwenden",
    "en": "Large discharge voltage difference between %battery_index Battery cells. Return to home or land promptly. Stop using battery",
    "es": "Gran diferencia entre los voltajes de descarga de las células de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente. Deje de usar la batería.",
    "fr": "Différence de décharge importante entre les cellules de batterie %battery_index. Retourner au point de départ ou atterrir rapidement. Cesser d'utiliser la batterie",
    "ja": "%battery_index バッテリーセル間の放電電圧差が大きすぎます。 直ちに帰還するか、着陸してください。 バッテリーの使用を中止してください",
    "ko": "%battery_index 배터리 셀 간 방전 전압 차가 너무 큼. 즉시 리턴 투 홈을 실행하거나 착륙하세요. 배터리 사용을 중지하세요.",
    "ru": "Большая разница между напряжениями разряда ячеек %battery_index АКБ. Как можно скорее вернитесь на базу или приземлитесь. Прекратите использовать АКБ",
    "tr": "%battery_index Pil hücreleri arasındaki deşarj gerilim farkı yüksek. Başlangıç noktasına geri dönün veya derhal iniş yapın. Pili kullanmayı bırakın",
    "zh": "%battery_index电池放电电芯间压差过大，请尽快返航或降落，并停止使用该电池"
  },
  "fpv_tip_0x1609006A": {
    "de": "%battery_index Akku: MOS-Verbindungsfehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery MOS connection error. Return to home or land promptly",
    "es": "Error de conexión del MOS de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Erreur de connexion MOS de la batterie %battery_index. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー MOS 接続エラー。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 MOS 연결 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: ошибка подключения MOS. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil MOS bağlantı hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池MOS连接异常，请尽快返航或降落"
  },
  "fpv_tip_0x1609006B": {
    "de": "%battery_index Akku: MOS-Impedanzfehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery MOS impedance error. Return to home or land promptly",
    "es": "Error de impedancia del MOS de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Erreur d'impédance MOS de la batterie %battery_index. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー MOS インピーダンスエラー。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 MOS 임피던스 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: ошибка сопротивления MOS. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil MOS empedans hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池MOS阻抗异常，请尽快返航或降落"
  },
  "fpv_tip_0x1609006C": {
    "de": "%battery_index Akku: Alterung. Die Flugsicherheit ist möglicherweise beeinträchtigt. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery aging. Flight safety may be affected. Return to home or land promptly",
    "es": "Envejecimiento de la batería %battery_index. La seguridad de vuelo puede verse afectada. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Vieillissement de la batterie %battery_index. La sécurité en vol peut être affectée. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー劣化。 飛行安全性に影響する可能性があります。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 노화. 비행 안전에 영향을 줄 수 있음. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "Износ %battery_index АКБ. Это может повлиять на безопасность полета. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil eski. Uçuş güvenliği etkilenebilir. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池老化，可能影响飞行安全，请尽快返航或降落"
  },
  "fpv_tip_0x1609006D": {
    "de": "%battery_index Akku: Mikro-Kurzschluss aufgetreten. Die Flugsicherheit ist möglicherweise beeinträchtigt. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery experienced micro short circuit. Flight safety may be affected. Return to home or land promptly",
    "es": "La batería %battery_index sufrió un microcortocircuito. La seguridad de vuelo puede verse afectada. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "La batterie %battery_index a subi un micro court-circuit. La sécurité en vol peut être affectée. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリーでマイクロ短絡が発生しました。 飛行安全性に影響する可能性があります。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리에 마이크로 단락 발생. 비행 안전에 영향을 줄 수 있음. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: произошло микрозамыкание. Это может повлиять на безопасность полета. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pilde mikro kısa devre oldu. Uçuş güvenliği etkilenebilir. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池微短路，可能影响飞行安全，请尽快返航或降落"
  },
  "fpv_tip_0x1609006F": {
    "de": "%battery_index Akku: Anschlusstemperatur zu hoch. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery connector temperature too high. Return to home or land promptly",
    "es": "Temperatura del conector de la batería %battery_index demasiado alta. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Température du connecteur de la batterie %battery_index excessive. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリーコネクターの温度が高すぎます。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 커넥터 온도가 너무 높음. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: слишком высокая температура разъема. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil konektör sıcaklığı çok yüksek. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池连接器温度过高，请尽快返航或降落"
  },
  "fpv_tip_0x16090070": {
    "de": "%battery_index Akku: Spannung zu niedrig. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery voltage too low. Return to home or land promptly",
    "es": "Voltaje de la batería %battery_index demasiado bajo. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Tension de batterie %battery_index trop basse. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー電圧が低すぎます。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 전압이 너무 낮음. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: слишком низкое напряжение. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil gerilimi çok düşük. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池电压过低，请尽快返航或降落"
  },
  "fpv_tip_0x16090071": {
    "ar": "",
    "de": "",
    "en": "Battery overvoltage protection",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "电池电压过压保护",
    "zh-Hant": ""
  },
  "fpv_tip_0x16090072": {
    "de": "%battery_index Akku: Überstrom beim Laden. Ladegerät überprüfen",
    "en": "%battery_index Battery overcurrent when charging. Check charging device",
    "es": "Sobrecorriente de la batería %battery_index durante la carga. Compruebe el dispositivo de carga.",
    "fr": "Surintensité de la batterie %battery_index lors de la charge. Vérifier le chargeur",
    "ja": "充電時の %battery_index バッテリーの過電流。 充電器を確認してください",
    "ko": "충전 중 %battery_index 배터리 과전류 발생. 충전 기기를 확인하세요.",
    "ru": "%battery_index АКБ: перегрузка по току при зарядке. Проверьте зарядное устройство",
    "tr": "Şarj olurken %battery_index Pil aşırı akımı. Şarj cihazını kontrol edin",
    "zh": "%battery_index电池充电过流，请检查充电设备"
  },
  "fpv_tip_0x16090073": {
    "de": "%battery_index Akku: Überstrom beim Entladen. Überprüfen, ob Fluggerät überladen ist",
    "en": "%battery_index Battery overcurrent when discharging. Check if aircraft is overloaded",
    "es": "Sobrecorriente de la batería %battery_index durante la descarga. Compruebe si la aeronave está sobrecargada.",
    "fr": "Surintensité de la batterie %battery_index lors de la décharge. Vérifier si l'appareil est surchargé",
    "ja": "放電時の %battery_index バッテリーの過電流。 機体が過負荷になっていないかを確認してください",
    "ko": "방전 중 %battery_index 배터리 과전류 발생. 기체 과부화 여부를 확인하세요.",
    "ru": "%battery_index АКБ: перегрузка по току при разрядке. Проверьте, не перегружен ли дрон",
    "tr": "Deşarj olurken %battery_index Pil aşırı akımı. Aracın aşırı yüklenip yüklenmediğini kontrol edin",
    "zh": "%battery_index电池放电过流，请检查飞行器是否有负重"
  },
  "fpv_tip_0x16090074": {
    "de": "%battery_index Akku: Tiefentladung. Überprüfen, ob Fluggerät überladen ist",
    "en": "%battery_index Battery over discharged. Check if aircraft is overloaded",
    "es": "Descarga excesiva de la batería %battery_index. Compruebe si la aeronave está sobrecargada.",
    "fr": "Batterie %battery_index trop déchargée. Vérifier si l'appareil est surchargé",
    "ja": "%battery_index バッテリー過放電エラー。 機体が過負荷になっていないかを確認してください",
    "ko": "%battery_index 배터리 과다 방전됨. 기체 과부화 여부를 확인하세요.",
    "ru": "%battery_index АКБ: чрезмерная разрядка. Проверьте, не перегружен ли дрон",
    "tr": "%battery_index Pil fazla deşarj edildi. Aracın aşırı yüklenip yüklenmediğini kontrol edin",
    "zh": "%battery_index电池放电过载，请检查飞行器是否有负重"
  },
  "fpv_tip_0x16090075": {
    "de": "%battery_index Akku: Systemfehler. Akku neu starten und erneut versuchen",
    "en": "%battery_index Battery system error. Restart %battery_index Battery and try again",
    "es": "Error del sistema de la batería %battery_index. Reinicie la batería %battery_index e inténtelo de nuevo.",
    "fr": "Erreur système de la batterie %battery_index. Redémarrer la batterie %battery_index et réessayer",
    "ja": "%battery_index バッテリーシステムエラー。 %battery_index バッテリーを再起動し、やり直してください",
    "ko": "%battery_index 배터리 시스템 오류. %battery_index 배터리를 재시동한 후 다시 시도하세요.",
    "ru": "%battery_index АКБ: ошибка системы. Перезагрузите %battery_index АКБ и повторите попытку",
    "tr": "%battery_index Pil sistem hatası. %battery_index Pili yeniden başlatıp tekrar deneyin",
    "zh": "%battery_index电池系统异常，请重启%battery_index电池后重试"
  },
  "fpv_tip_0x16090076": {
    "de": "%battery_index Akku: Interner Modulfehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery internal module error. Return to home or land promptly",
    "es": "Error del módulo interno de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Erreur de module interne de la batterie %battery_index. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー内部モジュールエラー。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 내부 모듈 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: внутренняя ошибка модуля. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil dahili modül hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池内部模块异常，请尽快返航或降落"
  },
  "fpv_tip_0x16090077": {
    "de": "%battery_index Akku: Ladetemperatur zu hoch. Ladevorgang beenden und warten, bis Akku abgekühlt ist",
    "en": "%battery_index Battery charging temperature too high. Stop charging and wait until %battery_index Battery cools down",
    "es": "Temperatura de carga de la batería %battery_index demasiado alta. Deje de cargar y espere a que se enfríe la batería %battery_index.",
    "fr": "Température de charge de la batterie %battery_index excessive. Arrêter la charge et attendre que la batterie %battery_index refroidisse",
    "ja": "%battery_index バッテリー充電温度が高すぎます。 充電を中止し、%battery_index バッテリーが冷えるまでお待ちください",
    "ko": "%battery_index 배터리 충전 온도가 너무 높음. 충전을 중지하고 %battery_index 배터리가 식을 때까지 기다려 주세요.",
    "ru": "%battery_index АКБ: слишком высокая температура зарядки. Прекратите зарядку и подождите, пока %battery_index АКБ остынет",
    "tr": "%battery_index Pil şarj etme sıcaklığı çok yüksek. Şarjı durdurun ve %battery_index Pil soğuyana kadar bekleyin",
    "zh": "%battery_index电池充电温度过高，禁止充电，等待%battery_index电池温度降低"
  },
  "fpv_tip_0x16090078": {
    "de": "%battery_index Akku: Entladetemperatur zu hoch. Umgehend zum Startpunkt zurückkehren oder landen. %battery_index Akku: Warten, bis Akku abgekühlt ist",
    "en": "%battery_index Battery discharging temperature too high. Return to home or land promptly. Wait until %battery_index Battery cools down",
    "es": "Temperatura de descarga de la batería %battery_index demasiado alta. Regrese al punto de origen o aterrice rápidamente. Espere a que se enfríe la batería %battery_index.",
    "fr": "Température de décharge de la batterie %battery_index excessive. Retourner au point de départ ou atterrir rapidement. Attendre que la batterie %battery_index refroidisse",
    "ja": "%battery_index バッテリーの放電温度が高すぎます。 直ちに帰還するか、着陸してください。 %battery_index バッテリーが冷えるまでお待ちください",
    "ko": "%battery_index 배터리 방전 온도가 너무 높음. 즉시 리턴 투 홈을 실행하거나 착륙하세요. %battery_index 배터리가 식을 때까지 기다려 주세요.",
    "ru": "%battery_index АКБ: слишком высокая температура разрядки. Как можно скорее вернитесь на базу или приземлитесь. Подождите, пока %battery_index АКБ остынет",
    "tr": "%battery_index Pil deşarj etme sıcaklığı çok yüksek. Başlangıç noktasına geri dönün veya derhal iniş yapın. %battery_index Pil soğuyana kadar bekleyin",
    "zh": "%battery_index电池放电温度过高，请尽快返航或降落，等待%battery_index电池温度降低"
  },
  "fpv_tip_0x16090079": {
    "de": "%battery_index Akku: Ladetemperatur zu niedrig. Akku vor dem Laden aufwärmen",
    "en": "%battery_index Battery charging temperature too low. Warm up %battery_index Battery before charging",
    "es": "Temperatura de carga de la batería %battery_index demasiado baja. Caliente la batería %battery_index antes de cargarla",
    "fr": "Température de charge de la batterie %battery_index trop basse. Réchauffer la batterie %battery_index avant la charge",
    "ja": "%battery_index バッテリー充電温度が低すぎます。 充電前に %battery_index バッテリーをウォームアップしてください",
    "ko": "%battery_index 배터리 충전 온도가 너무 낮음. 충전하기 전에 %battery_index 배터리를 예열하세요.",
    "ru": "%battery_index АКБ: слишком низкая температура зарядки. Прогрейте %battery_index АКБ перед зарядкой",
    "tr": "%battery_index Pil şarj etme sıcaklığı çok düşük. Şarj etmeden önce %battery_index Pili ısıtın",
    "zh": "%battery_index电池充电温度过低，禁止充电，请预热%battery_index电池后充电"
  },
  "fpv_tip_0x1609007A": {
    "de": "%battery_index Akku: Entladetemperatur zu niedrig. Umgehend zum Startpunkt zurückkehren oder landen. Akku vor dem Start aufwärmen",
    "en": "%battery_index Battery discharging temperature too low. Return to home or land promptly. Warm up %battery_index Battery before takeoff",
    "es": "Temperatura de descarga de la batería %battery_index demasiado baja. Regrese al punto de origen o aterrice rápidamente. Caliente la batería %battery_index antes del despegue.",
    "fr": "Température de décharge de la batterie %battery_index trop basse. Retourner au point de départ ou atterrir rapidement. Réchauffer la batterie %battery_index avant le décollage",
    "ja": "%battery_index バッテリーの放電温度が低すぎます。 直ちに帰還するか、着陸してください。 離陸前に %battery_index バッテリーをウォームアップしてください",
    "ko": "%battery_index 배터리 방전 온도가 너무 낮음. 즉시 리턴 투 홈을 실행하거나 착륙하세요. 이륙하기 전에 %battery_index 배터리를 예열하세요.",
    "ru": "%battery_index АКБ: слишком низкая температура разрядки. Как можно скорее вернитесь на базу или приземлитесь. Прогрейте %battery_index АКБ перед взлетом",
    "tr": "%battery_index Pil deşarj etme sıcaklığı çok düşük. Başlangıç noktasına geri dönün veya derhal iniş yapın. Kalkıştan önce %battery_index Pili ısıtın",
    "zh": "%battery_index电池放电温度过低，请尽快返航或降落，请预热%battery_index电池后再起飞"
  },
  "fpv_tip_0x1609007B": {
    "de": "%battery_index Akku: Spannung zu hoch. %battery_index Akku: Vor dem Start wieder einsetzen",
    "en": "%battery_index Battery voltage too high. Reinstall %battery_index Battery before takeoff",
    "es": "Voltaje de la batería %battery_index demasiado alto. Reinstale la batería %battery_index antes del despegue.",
    "fr": "Tension de batterie %battery_index excessive. Réinstallez la batterie %battery_index avant le décollage",
    "ja": "%battery_index バッテリー電圧が高すぎます。 離陸前に %battery_index バッテリーを取り付け直してください",
    "ko": "%battery_index 배터리 전압이 너무 높음. 이륙하기 전에 %battery_index 배터리를 다시 장착하세요.",
    "ru": "%battery_index АКБ: слишком высокое напряжение. Повторно установите %battery_index АКБ перед взлетом",
    "tr": "%battery_index Pil gerilimi çok yüksek. Kalkıştan önce %battery_index Pili yeniden takın",
    "zh": "%battery_index电池电压过高，请拔插%battery_index电池后再起飞"
  },
  "fpv_tip_0x1609007C": {
    "de": "%battery_index Akku: Spannung zu niedrig. %battery_index Akku: Vor dem Start wieder einsetzen",
    "en": "%battery_index Battery voltage too low. Reinstall %battery_index Battery before takeoff",
    "es": "Voltaje de la batería %battery_index demasiado bajo. Reinstale la batería %battery_index antes del despegue.",
    "fr": "Tension de batterie %battery_index trop basse. Réinstallez la batterie %battery_index avant le décollage",
    "ja": "%battery_index バッテリー電圧が低すぎます。 離陸前に %battery_index バッテリーを取り付け直してください",
    "ko": "%battery_index 배터리 전압이 너무 낮음. 이륙하기 전에 %battery_index 배터리를 다시 장착하세요.",
    "ru": "%battery_index АКБ: слишком низкое напряжение. Повторно установите %battery_index АКБ перед взлетом",
    "tr": "%battery_index Pil gerilimi çok düşük. Kalkıştan önce %battery_index Pili yeniden takın",
    "zh": "%battery_index电池电压过低，请拔插%battery_index电池后再起飞"
  },
  "fpv_tip_0x1609007D": {
    "de": "Spannung des Ladegeräts zu hoch. Gerät überprüfen",
    "en": "Charging device voltage too high. Check device",
    "es": "Voltaje del dispositivo de carga demasiado alto. Compruebe el dispositivo",
    "fr": "Tension du chargeur trop élevée. Vérifier l'appareil",
    "it": "",
    "ja": "充電器の電圧が高すぎます。機器を確認してください",
    "ko": "충전 기기 전압이 너무 높음. 기기를 확인하세요",
    "pt": "",
    "ru": "Напряжение зарядного устройства слишком высокое. Проверьте устройство",
    "tr": "Şarj cihazı gerilimi çok yüksek. Cihazı kontrol edin",
    "zh": "充电器电压过高，请检查充电设备"
  },
  "fpv_tip_0x1609007E": {
    "de": "Spannung des Ladegeräts zu niedrig. Gerät überprüfen",
    "en": "Charging device voltage too low. Check device",
    "es": "Voltaje del dispositivo de carga demasiado bajo. Compruebe el dispositivo",
    "fr": "Tension du chargeur trop faible. Vérifier l'appareil",
    "it": "",
    "ja": "充電器の電圧が低すぎます。機器を確認してください",
    "ko": "충전 기기 전압이 너무 낮음. 기기를 확인하세요",
    "pt": "",
    "ru": "Напряжение зарядного устройства слишком низкое. Проверьте устройство",
    "tr": "Şarj cihazı gerilimi çok düşük. Cihazı kontrol edin",
    "zh": "充电器电压过低，请检查充电设备"
  },
  "fpv_tip_0x1609007F": {
    "de": "%battery_index Akku: Überhitzt. Start nicht möglich. %battery_index Akku: Warten, bis Akku abgekühlt ist",
    "en": "%battery_index Battery overheated. Unable to take off. Wait until %battery_index Battery cools down",
    "es": "Batería %battery_index sobrecalentada. No se puede despegar. Espere a que se enfríe la batería %battery_index.",
    "fr": "Surchauffe batterie %battery_index. Impossible de décoller. Attendre que la batterie %battery_index refroidisse",
    "ja": "%battery_index バッテリー高温。 離陸できません。 %battery_index バッテリーが冷えるまでお待ちください",
    "ko": "%battery_index 배터리 과열됨. 이륙 불가. %battery_index 배터리가 식을 때까지 기다려 주세요.",
    "ru": "Перегрев %battery_index АКБ. Не удается выполнить взлет. Подождите, пока %battery_index АКБ остынет",
    "tr": "%battery_index Pil aşırı ısındı. Kalkış yapılamıyor. %battery_index Pil soğuyana kadar bekleyin",
    "zh": "%battery_index电池温度过高，无法起飞，等待%battery_index电池温度降低"
  },
  "fpv_tip_0x16090080": {
    "de": "%battery_index Akku: Überhitzt. Umgehend zum Startpunkt zurückkehren oder landen. %battery_index Akku: Warten, bis Akku abgekühlt ist",
    "en": "%battery_index Battery overheated. Return to home or land promptly. Wait until %battery_index Battery cools down",
    "es": "Batería %battery_index sobrecalentada. Regrese al punto de origen o aterrice rápidamente. Espere a que se enfríe la batería %battery_index.",
    "fr": "Surchauffe batterie %battery_index. Retourner au point de départ ou atterrir rapidement. Attendre que la batterie %battery_index refroidisse",
    "ja": "%battery_index バッテリー高温。 直ちに帰還するか、着陸してください。 %battery_index バッテリーが冷えるまでお待ちください",
    "ko": "%battery_index 배터리 과열됨. 즉시 리턴 투 홈을 실행하거나 착륙하세요. %battery_index 배터리가 식을 때까지 기다려 주세요.",
    "ru": "Перегрев %battery_index АКБ. Как можно скорее вернитесь на базу или приземлитесь. Подождите, пока %battery_index АКБ остынет",
    "tr": "%battery_index Pil aşırı ısındı. Başlangıç noktasına geri dönün veya derhal iniş yapın. %battery_index Pil soğuyana kadar bekleyin",
    "zh": "%battery_index电池温度过高，请尽快返航或降落，等待%battery_index电池温度降低"
  },
  "fpv_tip_0x16090081": {
    "de": "%battery_index Akku: Überhitzt. Umgehend zum Startpunkt zurückkehren oder landen. %battery_index Akku: Warten, bis Akku abgekühlt ist",
    "en": "%battery_index Battery overheated. Return to home or land promptly. Wait until %battery_index Battery cools down",
    "es": "Batería %battery_index sobrecalentada. Regrese al punto de origen o aterrice rápidamente. Espere a que se enfríe la batería %battery_index.",
    "fr": "Surchauffe batterie %battery_index. Retourner au point de départ ou atterrir rapidement. Attendre que la batterie %battery_index refroidisse",
    "ja": "%battery_index バッテリー高温。 直ちに帰還するか、着陸してください。 %battery_index バッテリーが冷えるまでお待ちください",
    "ko": "%battery_index 배터리 과열됨. 즉시 리턴 투 홈을 실행하거나 착륙하세요. %battery_index 배터리가 식을 때까지 기다려 주세요.",
    "ru": "Перегрев %battery_index АКБ. Как можно скорее вернитесь на базу или приземлитесь. Подождите, пока %battery_index АКБ остынет",
    "tr": "%battery_index Pil aşırı ısındı. Başlangıç noktasına geri dönün veya derhal iniş yapın. %battery_index Pil soğuyana kadar bekleyin",
    "zh": "%battery_index电池温度过高，请尽快返航或降落，等待%battery_index电池温度降低"
  },
  "fpv_tip_0x16090082": {
    "de": "%battery_index Akku: Interner Kommunikationsfehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery internal communication error. Return to home or land promptly",
    "es": "Error de comunicación interna de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Erreur de communication interne de la batterie %battery_index. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー内部通信エラー。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 내부 통신 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: ошибка внутренней связи. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil dahili iletişim hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池内部通讯异常，请尽快返航或降落"
  },
  "fpv_tip_0x16090083": {
    "de": "%battery_index Akku: Interner Kommunikationsfehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery internal communication error. Return to home or land promptly",
    "es": "Error de comunicación interna de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Erreur de communication interne de la batterie %battery_index. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー内部通信エラー。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 내부 통신 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: ошибка внутренней связи. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil dahili iletişim hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池内部通讯异常，请尽快返航或降落"
  },
  "fpv_tip_0x16090084": {
    "de": "%battery_index Akku: Interner Kommunikationsfehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery internal communication error. Return to home or land promptly",
    "es": "Error de comunicación interna de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Erreur de communication interne de la batterie %battery_index. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー内部通信エラー。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 내부 통신 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: ошибка внутренней связи. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil dahili iletişim hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池内部通讯异常，请尽快返航或降落"
  },
  "fpv_tip_0x16090085": {
    "de": "%battery_index Akku: Externer Kommunikationsfehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery external communication error. Return to home or land promptly",
    "es": "Error de comunicación externa de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Erreur de communication externe de la batterie %battery_index. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー外部通信エラー。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 외부 통신 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: ошибка внешней связи. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil harici iletişim hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池对外通信异常，请尽快返航或降落"
  },
  "fpv_tip_0x16090086": {
    "en": "%battery_index battery connector temperature too high. Flight safety affected",
    "zh": "%battery_index电池连接器温度过高，影响飞行安全"
  },
  "fpv_tip_0x16090086_in_the_sky": {
    "de": "%battery_index battery-Anschlusstemperatur zu hoch. Flugsicherheit beeinträchtigt. Zeitnah zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index battery connector temperature too high. Flight safety affected. Return to home or land promptly",
    "es": "Temperatura del conector %battery_index battery demasiado alta. Seguridad de vuelo afectada. Regrese al punto de origen o aterrice lo antes posible",
    "fr": "Température du connecteur %battery_index battery trop élevée. Sécurité en vol affectée. Retourner au point de départ ou atterrir immédiatement",
    "ja": "%battery_index batteryコネクターの温度が高すぎます。飛行安全性に影響します。すぐにRTHするか着陸させてください",
    "ko": "%battery_index battery 커넥터 온도가 너무 높습니다. 비행 안전에 영향이 있습니다. 즉시 리턴 투 홈(RTH)하거나 착륙시키세요",
    "ru": "%battery_index battery слишком высокая температура коннектора. Затронута безопасность полетов. Незамедлительно совершите возврат домой или посадку",
    "tr": "%battery_index battery konektör sıcaklığı çok yüksek. Uçuş güvenliği etkilendi. Hemen başlangıç noktasına dönün veya iniş yapın",
    "zh": "%battery_index电池连接器温度过高，影响飞行安全，请尽快返航或降落"
  },
  "fpv_tip_0x16090092": {
    "ar": "",
    "de": "",
    "en": "Pack voltage sensor error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "Vpack电压采样异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x16090093": {
    "de": "%battery_index Akku: Spannung instabil. Umgehend zum Startpunkt zurückkehren oder landen. Fluggerät nach Landung neu starten",
    "en": "%battery_index Battery voltage unstable. Return to home or land promptly. Restart aircraft after landing",
    "es": "Voltaje de la batería %battery_index inestable. Regrese al punto de origen o aterrice rápidamente. Reinicie la aeronave después del aterrizaje.",
    "fr": "Tension de la batterie %battery_index instable. Retourner au point de départ ou atterrir rapidement. Redémarrer l'appareil après l'atterrissage",
    "ja": "%battery_index バッテリー電圧が不安定です。 直ちに帰還するか、着陸してください。 着陸後に機体を再起動してください",
    "ko": "%battery_index 배터리 전압 불안정. 즉시 리턴 투 홈을 실행하거나 착륙하세요. 착륙 후 기체를 재시동하세요.",
    "ru": "%battery_index АКБ: нестабильное напряжение. Как можно скорее вернитесь на базу или приземлитесь. Перезапустите дрон после приземления",
    "tr": "%battery_index Pil gerilimi güvenilir değil. Başlangıç noktasına geri dönün veya derhal iniş yapın. İnişten sonra aracı yeniden başlatın",
    "zh": "%battery_index电池电压不稳定，请尽快返航或降落后重启电池"
  },
  "fpv_tip_0x16090094": {
    "de": "%battery_index Akku: Spannungssensorfehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery voltage sensor error. Return to home or land promptly",
    "es": "Error del sensor de voltaje de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Erreur capteur de tension de la batterie %battery_index. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー電圧センサーエラー。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 전압 센서 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: ошибка датчика напряжения. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil gerilim sensörü hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池电压采集异常，请尽快返航或降落"
  },
  "fpv_tip_0x16090095": {
    "de": "%battery_index Akku: Spannung zu hoch. Umgehend zum Startpunkt zurückkehren oder landen. Fluggerät nach Landung neu starten",
    "en": "%battery_index Battery voltage too high. Return to home or land promptly. Restart aircraft after landing",
    "es": "Voltaje de la batería %battery_index demasiado alto. Regrese al punto de origen o aterrice rápidamente. Reinicie la aeronave después del aterrizaje.",
    "fr": "Tension de la batterie %battery_index excessive. Retourner au point de départ ou atterrir rapidement. Redémarrer l'appareil après l'atterrissage",
    "ja": "%battery_index バッテリー電圧が高すぎます。 直ちに帰還するか、着陸してください。 着陸後に機体を再起動してください",
    "ko": "%battery_index 배터리 전압이 너무 높음. 즉시 리턴 투 홈을 실행하거나 착륙하세요. 착륙 후 기체를 재시동하세요.",
    "ru": "%battery_index АКБ: слишком высокое напряжение. Как можно скорее вернитесь на базу или приземлитесь. Перезапустите дрон после приземления",
    "tr": "%battery_index Pil gerilimi çok yüksek. Başlangıç noktasına geri dönün veya derhal iniş yapın. İnişten sonra aracı yeniden başlatın",
    "zh": "%battery_index电池电压过高，请尽快返航或降落后重启电池"
  },
  "fpv_tip_0x16090096": {
    "de": "%battery_index Akku: Wartung erforderlich. Mit Vorsicht fliegen",
    "en": "%battery_index Battery maintenance required. Fly with caution",
    "es": "La batería %battery_index requiere mantenimiento. Vuele con cuidado.",
    "fr": "Maintenance de la batterie %battery_index requise. Pilotez avec précaution",
    "ja": "%battery_index バッテリーのメンテナンスが必要です。 慎重に飛行してください",
    "ko": "%battery_index 배터리 점검 필요. 비행 시 주의 필요",
    "ru": "%battery_index АКБ: требуется обслуживание. Соблюдайте осторожность при полете",
    "tr": "%battery_index Pil bakımı gerekiyor. Aracınızı dikkatli uçurun",
    "zh": "%battery_index电池需要保养，请谨慎飞行"
  },
  "fpv_tip_0x16090097": {
    "de": "Akku %battery_index: Pflege erforderlich. Mit Vorsicht fliegen",
    "en": "%battery_index battery maintenance required. Fly with caution",
    "es": "La batería %battery_index requiere mantenimiento. Vuele con cuidado",
    "fr": "Entretien de la batterie %battery_index requis. Pilotez avec précaution",
    "ja": "%battery_indexバッテリーのメンテナンスが必要です。慎重に飛行してください",
    "ko": "%battery_index 배터리 점검 필요. 비행 시 주의 필요",
    "ru": "%battery_index АКБ: требуется обслуживание. Соблюдайте осторожность при полете",
    "tr": "%battery_index pil bakımı gerekiyor. Dikkatlice uçurun",
    "zh": "%battery_index电池需要保养，请谨慎飞行"
  },
  "fpv_tip_0x16090098": {
    "ar": "",
    "de": "",
    "en": "Large voltage difference during discharge",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "放电压差大告警",
    "zh-Hant": ""
  },
  "fpv_tip_0x16090099": {
    "de": "%battery_index Akku: Überstrom. Mit Vorsicht fliegen",
    "en": "%battery_index Battery overcurrent. Fly with caution",
    "es": "Sobreintensidad en la batería %battery_index. Vuele con cuidado.",
    "fr": "Surtension de batterie %battery_index. Pilotez avec précaution",
    "ja": "%battery_index バッテリーが過電流です。 慎重に飛行してください",
    "ko": "%battery_index 배터리 과전류 발생. 비행 시 주의 필요",
    "ru": "%battery_index АКБ: перегрузка по току. Соблюдайте осторожность при полете",
    "tr": "%battery_index Pil aşırı akımı. Aracınızı dikkatli uçurun",
    "zh": "%battery_index电池电流过大，请谨慎飞行"
  },
  "fpv_tip_0x1609009B": {
    "de": "%battery_index Akku: Temperatur ist hoch. Umgehend zum Startpunkt zurückkehren oder landen. %battery_index Akku: Warten, bis Akku abgekühlt ist",
    "en": "%battery_index Battery temperature high. Return to home or land promptly. Wait until %battery_index Battery cools down",
    "es": "Temperatura alta de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente. Espere a que se enfríe la batería %battery_index.",
    "fr": "Température de la batterie %battery_index élevée. Retourner au point de départ ou atterrir rapidement. Attendre que la batterie %battery_index refroidisse",
    "ja": "%battery_index バッテリー温度が高いです。 直ちに帰還するか、着陸してください。 %battery_index バッテリーが冷えるまでお待ちください",
    "ko": "%battery_index 배터리 온도가 너무 높음. 즉시 리턴 투 홈을 실행하거나 착륙하세요. %battery_index 배터리가 식을 때까지 기다려 주세요.",
    "ru": "%battery_index АКБ: слишком высокая температура. Как можно скорее вернитесь на базу или приземлитесь. Подождите, пока %battery_index АКБ остынет",
    "tr": "%battery_index Pil sıcaklığı yüksek. Başlangıç noktasına geri dönün veya derhal iniş yapın. %battery_index Pil soğuyana kadar bekleyin",
    "zh": "%battery_index电池温度高，请尽快返航或降落，等待%battery_index电池温度降低"
  },
  "fpv_tip_0x1609009C": {
    "de": "%battery_index Akku: Temperatur ist hoch. Umgehend zum Startpunkt zurückkehren oder landen. %battery_index Akku: Warten, bis Akku abgekühlt ist",
    "en": "%battery_index Battery temperature high. Return to home or land promptly. Wait until %battery_index Battery cools down",
    "es": "Temperatura alta de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente. Espere a que se enfríe la batería %battery_index.",
    "fr": "Température de la batterie %battery_index élevée. Retourner au point de départ ou atterrir rapidement. Attendre que la batterie %battery_index refroidisse",
    "ja": "%battery_index バッテリー温度が高いです。 直ちに帰還するか、着陸してください。 %battery_index バッテリーが冷えるまでお待ちください",
    "ko": "%battery_index 배터리 온도가 너무 높음. 즉시 리턴 투 홈을 실행하거나 착륙하세요. %battery_index 배터리가 식을 때까지 기다려 주세요.",
    "ru": "%battery_index АКБ: слишком высокая температура. Как можно скорее вернитесь на базу или приземлитесь. Подождите, пока %battery_index АКБ остынет",
    "tr": "%battery_index Pil sıcaklığı yüksek. Başlangıç noktasına geri dönün veya derhal iniş yapın. %battery_index Pil soğuyana kadar bekleyin",
    "zh": "%battery_index电池温度高，请尽快返航或降落，等待%battery_index电池温度降低"
  },
  "fpv_tip_0x1609009D": {
    "de": "%battery_index battery-Anschlusstemperatur zu hoch",
    "en": "%battery_index battery connector temperature too high",
    "es": "Temperatura del conector %battery_index battery demasiado alta",
    "fr": "Température du connecteur %battery_index battery trop élevée",
    "ja": "%battery_index batteryコネクターの温度が高すぎます",
    "ko": "%battery_index battery 커넥터 온도가 너무 높습니다",
    "ru": "%battery_index battery слишком высокая температура коннектора",
    "tr": "%battery_index battery konektör sıcaklığı çok yüksek",
    "zh": "%battery_index电池连接器温度过高"
  },
  "fpv_tip_0x1609009D_in_the_sky": {
    "de": "%battery_index battery-Anschlusstemperatur zu hoch. Flugsicherheit beeinträchtigt. Zeitnah zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index battery connector temperature too high. Flight safety affected. Return to home or land promptly",
    "es": "Temperatura del conector %battery_index battery demasiado alta. Seguridad de vuelo afectada. Regrese al punto de origen o aterrice lo antes posible",
    "fr": "Température du connecteur %battery_index battery trop élevée. Sécurité en vol affectée. Retourner au point de départ ou atterrir immédiatement",
    "ja": "%battery_index batteryコネクターの温度が高すぎます。飛行安全性に影響します。すぐにRTHするか着陸させてください",
    "ko": "%battery_index battery 커넥터 온도가 너무 높습니다. 비행 안전에 영향이 있습니다. 즉시 리턴 투 홈(RTH)하거나 착륙시키세요",
    "ru": "%battery_index battery слишком высокая температура коннектора. Затронута безопасность полетов. Незамедлительно совершите возврат домой или посадку",
    "tr": "%battery_index battery konektör sıcaklığı çok yüksek. Uçuş güvenliği etkilendi. Hemen başlangıç noktasına dönün veya iniş yapın",
    "zh": "%battery_index电池连接器温度过高，影响飞行安全，请尽快返航或降落"
  },
  "fpv_tip_0x1609009E": {
    "de": "%battery_index Akku: Spannung ist hoch. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery voltage high. Return to home or land promptly",
    "es": "Voltaje de la batería %battery_index alto. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Tension de la batterie %battery_index élevée. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー電圧が高いです。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 전압이 높음. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: высокое напряжение. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil gerilimi yüksek. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池电压高，请尽快返航或降落"
  },
  "fpv_tip_0x1609009F": {
    "de": "%battery_index Akku: Spannung ist niedrig. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery voltage low. Return to home or land promptly",
    "es": "Voltaje de la batería %battery_index bajo. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Tension de batterie %battery_index basse. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー電圧が低いです。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 전압이 낮음. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: низкое напряжение. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil gerilimi düşük. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池电压低，请尽快返航或降落"
  },
  "fpv_tip_0x160900A2": {
    "en": "%index battery connector overheated. Flight safety affected. Fly with caution",
    "zh": "%index电池连接器温升异常，影响飞行安全，请谨慎飞行"
  },
  "fpv_tip_0x160900A3": {
    "en": "%index battery exceeds power limit. Flight safety affected. Return to home or land promptly",
    "zh": "%index电池超过使用功率限制范围，影响飞行安全，请尽快返航或降落"
  },
  "fpv_tip_0x160900A6": {
    "de": "%battery_index Akku: Verbindungsfehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery connection error. Return to home or land promptly",
    "es": "Error de conexión de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Erreur de connexion de la batterie %battery_index. Retourner au point de départ ou atterrir rapidement",
    "it": "",
    "ja": "%battery_index バッテリー接続エラー。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 연결 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "pt": "",
    "ru": "%battery_index АКБ: ошибка подключения. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil bağlantı hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "电池槽%battery_index电池接触不良，请尽快返航或降落"
  },
  "fpv_tip_0x160900B0": {
    "de": "%battery_index Akku: Akkustand ist falsch. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery level incorrect. Return to home or land promptly",
    "es": "Nivel incorrecto de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Niveau de batterie %battery_index incorrect. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー残量が正しくありません。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 잔량 잘못됨. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: неверный уровень. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil düzeyi yanlış. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池电量不准，请尽快返航或降落"
  },
  "fpv_tip_0x160900B1": {
    "de": "%battery_index Akku: Akkustand ist instabil. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery level unstable. Return to home or land promptly",
    "es": "Nivel inestable de la batería %battery_index. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Niveau de batterie %battery_index instable. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー残量が不安定です。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 잔량 불안정. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "%battery_index АКБ: нестабильный уровень. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil düzeyi güvenilir değil. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池电量变化过大，请尽快返航或降落"
  },
  "fpv_tip_0x160900B2": {
    "ar": "",
    "de": "",
    "en": "Abnormal SoC changes",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "SOC异常跳变",
    "zh-Hant": ""
  },
  "fpv_tip_0x160900B3": {
    "de": "%battery_index Akku: Kapazitätsfehler. Mit Vorsicht fliegen",
    "en": "%battery_index Battery capacity error. Fly with caution",
    "es": "Error de capacidad de la batería %battery_index. Vuele con cuidado.",
    "fr": "Erreur de capacité de la batterie %battery_index. Pilotez avec précaution",
    "ja": "%battery_index バッテリー容量エラー。 慎重に飛行してください",
    "ko": "%battery_index 배터리 용량 오류. 비행 시 주의 필요",
    "ru": "%battery_index АКБ: ошибка емкости. Соблюдайте осторожность при полете",
    "tr": "%battery_index Pil kapasite hatası. Aracınızı dikkatli uçurun",
    "zh": "%battery_index电池容量异常，请谨慎飞行"
  },
  "fpv_tip_0x160900B4": {
    "de": "%battery_index Akku: Kapazitätsfehler. Mit Vorsicht fliegen",
    "en": "%battery_index Battery capacity error. Fly with caution",
    "es": "Error de capacidad de la batería %battery_index. Vuele con cuidado.",
    "fr": "Erreur de capacité de la batterie %battery_index. Pilotez avec précaution",
    "ja": "%battery_index バッテリー容量エラー。 慎重に飛行してください",
    "ko": "%battery_index 배터리 용량 오류. 비행 시 주의 필요",
    "ru": "%battery_index АКБ: ошибка емкости. Соблюдайте осторожность при полете",
    "tr": "%battery_index Pil kapasite hatası. Aracınızı dikkatli uçurun",
    "zh": "%battery_index电池容量异常，请谨慎飞行"
  },
  "fpv_tip_0x160900B5": {
    "de": "%battery_index Akku: Kapazitätsfehler. Mit Vorsicht fliegen",
    "en": "%battery_index Battery capacity error. Fly with caution",
    "es": "Error de capacidad de la batería %battery_index. Vuele con cuidado.",
    "fr": "Erreur de capacité de la batterie %battery_index. Pilotez avec précaution",
    "ja": "%battery_index バッテリー容量エラー。 慎重に飛行してください",
    "ko": "%battery_index 배터리 용량 오류. 비행 시 주의 필요",
    "ru": "%battery_index АКБ: ошибка емкости. Соблюдайте осторожность при полете",
    "tr": "%battery_index Pil kapasite hatası. Aracınızı dikkatli uçurun",
    "zh": "%battery_index电池容量异常，请谨慎飞行"
  },
  "fpv_tip_0x160900B6": {
    "de": "%battery_index Akku: Kapazitätsfehler. Mit Vorsicht fliegen",
    "en": "%battery_index Battery capacity error. Fly with caution",
    "es": "Error de capacidad de la batería %battery_index. Vuele con cuidado.",
    "fr": "Erreur de capacité de la batterie %battery_index. Pilotez avec précaution",
    "ja": "%battery_index バッテリー容量エラー。 慎重に飛行してください",
    "ko": "%battery_index 배터리 용량 오류. 비행 시 주의 필요",
    "ru": "%battery_index АКБ: ошибка емкости. Соблюдайте осторожность при полете",
    "tr": "%battery_index Pil kapasite hatası. Aracınızı dikkatli uçurun",
    "zh": "%battery_index电池容量异常，请谨慎飞行"
  },
  "fpv_tip_0x160900B7": {
    "de": "%battery_index Akku: Kapazitätsfehler. Mit Vorsicht fliegen",
    "en": "%battery_index Battery capacity error. Fly with caution",
    "es": "Error de capacidad de la batería %battery_index. Vuele con cuidado.",
    "fr": "Erreur de capacité de la batterie %battery_index. Pilotez avec précaution",
    "ja": "%battery_index バッテリー容量エラー。 慎重に飛行してください",
    "ko": "%battery_index 배터리 용량 오류. 비행 시 주의 필요",
    "ru": "%battery_index АКБ: ошибка емкости. Соблюдайте осторожность при полете",
    "tr": "%battery_index Pil kapasite hatası. Aracınızı dikkatli uçurun",
    "zh": "%battery_index电池容量异常，请谨慎飞行"
  },
  "fpv_tip_0x160900B8": {
    "de": "%battery_index Akku: Kapazitätsfehler. Mit Vorsicht fliegen",
    "en": "%battery_index Battery capacity error. Fly with caution",
    "es": "Error de capacidad de la batería %battery_index. Vuele con cuidado.",
    "fr": "Erreur de capacité de la batterie %battery_index. Pilotez avec précaution",
    "ja": "%battery_index バッテリー容量エラー。 慎重に飛行してください",
    "ko": "%battery_index 배터리 용량 오류. 비행 시 주의 필요",
    "ru": "%battery_index АКБ: ошибка емкости. Соблюдайте осторожность при полете",
    "tr": "%battery_index Pil kapasite hatası. Aracınızı dikkatli uçurun",
    "zh": "%battery_index电池容量异常，请谨慎飞行"
  },
  "fpv_tip_0x160900B9": {
    "ar": "",
    "de": "%battery_index Akku: Kapazität über einen längeren Zeitraum nicht aktualisiert. Wartung erforderlich. Mit Vorsicht fliegen",
    "en": "%battery_index Battery capacity not updated for extended period. Maintenance required. Fly with caution",
    "es": "Capacidad de la batería %battery_index no actualizada durante un período prolongado. Se requiere mantenimiento. Vuele con cuidado.",
    "fr": "La capacité de la batterie %battery_index n'a pas été mise à jour pendant une période prolongée. Maintenance requise. Pilotez avec précaution",
    "it": "",
    "ja": "%battery_index バッテリー容量が長期間更新されていません。 メンテナンスが必要です。 慎重に飛行してください",
    "ko": "%battery_index 배터리 용량이 장기간 업데이트되지 않음. 점검 필요. 비행 시 주의 필요",
    "pt": "",
    "ru": "%battery_index АКБ: емкость не обновлялась в течение длительного периода. Требуется обслуживание. Соблюдайте осторожность при полете",
    "tr": "%battery_index Pil kapasitesi uzun süre güncellenmedi. Bakım gerekiyor. Aracınızı dikkatli uçurun",
    "zh": "%battery_index电池容量长期未更新，请谨慎飞行并进行容量校准"
  },
  "fpv_tip_0x160900BA": {
    "ar": "",
    "de": "%battery_index Akku: Kapazität über einen längeren Zeitraum nicht aktualisiert. Wartung erforderlich. Mit Vorsicht fliegen",
    "en": "%battery_index Battery capacity not updated for extended period. Maintenance required. Fly with caution",
    "es": "Capacidad de la batería %battery_index no actualizada durante un período prolongado. Se requiere mantenimiento. Vuele con cuidado.",
    "fr": "La capacité de la batterie %battery_index n'a pas été mise à jour pendant une période prolongée. Maintenance requise. Pilotez avec précaution",
    "it": "",
    "ja": "%battery_index バッテリー容量が長期間更新されていません。 メンテナンスが必要です。 慎重に飛行してください",
    "ko": "%battery_index 배터리 용량이 장기간 업데이트되지 않음. 점검 필요. 비행 시 주의 필요",
    "pt": "",
    "ru": "%battery_index АКБ: емкость не обновлялась в течение длительного периода. Требуется обслуживание. Соблюдайте осторожность при полете",
    "tr": "%battery_index Pil kapasitesi uzun süre güncellenmedi. Bakım gerekiyor. Aracınızı dikkatli uçurun",
    "zh": "%battery_index电池容量长期未更新，请谨慎飞行并进行容量校准"
  },
  "fpv_tip_0x160900BB": {
    "de": "%battery_index Akku: Beeinträchtigt. Mit Vorsicht fliegen",
    "en": "%battery_index Battery degraded. Fly with caution",
    "es": "Batería %battery_index deteriorada. Vuele con cuidado.",
    "fr": "Batterie %battery_index dégradée. Pilotez avec précaution",
    "ja": "%battery_index バッテリー劣化。 慎重に飛行してください",
    "ko": "%battery_index 배터리 성능 저하됨. 비행 시 주의 필요",
    "ru": "%battery_index АКБ: снижение производительности. Соблюдайте осторожность при полете",
    "tr": "%battery_index Pil bozuldu. Aracınızı dikkatli uçurun",
    "zh": "%battery_index电池性能下降，请谨慎飞行"
  },
  "fpv_tip_0x160900BC": {
    "ar": "",
    "de": "",
    "en": "Battery internal resistance unusually low",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "电池内阻异常偏小",
    "zh-Hant": ""
  },
  "fpv_tip_0x160900BD": {
    "de": "%battery_index Akku: Alterung. Die Flugsicherheit ist möglicherweise beeinträchtigt. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery aging. Flight safety may be affected. Return to home or land promptly",
    "es": "Envejecimiento de la batería %battery_index. La seguridad de vuelo puede verse afectada. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Vieillissement de la batterie %battery_index. La sécurité en vol peut être affectée. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー劣化。 飛行安全性に影響する可能性があります。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 노화. 비행 안전에 영향을 줄 수 있음. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "Износ %battery_index АКБ. Это может повлиять на безопасность полета. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil eski. Uçuş güvenliği etkilenebilir. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池老化，可能影响飞行安全，请尽快返航或降落"
  },
  "fpv_tip_0x160900BE": {
    "de": "%battery_index Akku: Alterung. Die Flugsicherheit ist möglicherweise beeinträchtigt. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "%battery_index Battery aging. Flight safety may be affected. Return to home or land promptly",
    "es": "Envejecimiento de la batería %battery_index. La seguridad de vuelo puede verse afectada. Regrese al punto de origen o aterrice rápidamente.",
    "fr": "Vieillissement de la batterie %battery_index. La sécurité en vol peut être affectée. Retourner au point de départ ou atterrir rapidement",
    "ja": "%battery_index バッテリー劣化。 飛行安全性に影響する可能性があります。 直ちに帰還するか、着陸してください",
    "ko": "%battery_index 배터리 노화. 비행 안전에 영향을 줄 수 있음. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "Износ %battery_index АКБ. Это может повлиять на безопасность полета. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "%battery_index Pil eski. Uçuş güvenliği etkilenebilir. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "%battery_index电池老化，可能影响飞行安全，请尽快返航或降落"
  },
  "fpv_tip_0x160900BF": {
    "ar": "",
    "de": "%battery_index Akku: Nähert sich der Grenze der Anzahl von Zyklen. Es wird empfohlen, den Akku durch einen neuen Akku zu ersetzen und den alten Akku zu warten",
    "en": "%battery_index Battery approaching cycle count limit. It is recommended to replace with new battery and maintain %battery_index Battery",
    "es": "La batería %battery_index se acerca al límite de recuento de ciclos. Se recomienda reemplazarla por una batería nueva y mantener la batería %battery_index.",
    "fr": "La batterie %battery_index approche de la limite du nombre de cycles. Il est recommandé de remplacer par une nouvelle batterie et d'entretenir la batterie %battery_index",
    "it": "",
    "ja": "%battery_index バッテリーはサイクル回数の上限に近づいています。 新しいバッテリーと交換し、%battery_index バッテリーをメンテナンスすることをお勧めします",
    "ko": "%battery_index 배터리가 사이클 수 한도에 근접함. 새 배터리로 교체하고 %battery_index 배터리를 유지하는 것이 좋습니다.",
    "pt": "",
    "ru": "%battery_index АКБ: приближается к пределу количества циклов. Рекомендуется заменить на новую АКБ и сохранить %battery_index АКБ",
    "tr": "%battery_index Pil döngü sayısı sınırına yaklaşıyor. Yeni pille değiştirmeniz ve %battery_index Pil değerini korumanız önerilir",
    "zh": "%battery_index电池循环次数过高，建议更换该%battery_index电池"
  },
  "fpv_tip_0x160900C0": {
    "de": "%battery_index Akku: Nennleistung überschritten. Leistung begrenzt. Mit Vorsicht fliegen",
    "en": "%battery_index Battery rated power exceeded. Performance limited. Fly with caution",
    "es": "Se superó la potencia nominal de la batería %battery_index. Rendimiento limitado. Vuele con cuidado.",
    "fr": "Puissance nominale de la batterie %battery_index dépassée. Performances limitées. Pilotez avec précaution",
    "ja": "%battery_index バッテリーの定格出力を超えました。 性能が制限されています。 慎重に飛行してください",
    "ko": "%battery_index 배터리 정격 출력 초과됨. 성능 제한됨. 비행 시 주의 필요",
    "ru": "%battery_index АКБ: превышена номинальная мощность. Производительность ограничена. Соблюдайте осторожность при полете",
    "tr": "%battery_index Pil nominal gücü aşıldı. Performans sınırlandı. Aracınızı dikkatli uçurun",
    "zh": "%battery_index电池已超功率使用，飞行器性能受限，请谨慎飞行"
  },
  "fpv_tip_0x160900C1": {
    "ar": "",
    "de": "",
    "en": "Imbalanced battery",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "电池均衡故障",
    "zh-Hant": ""
  },
  "fpv_tip_0x160900C2": {
    "ar": "",
    "de": "",
    "en": "Sudden SoC changes",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "显示SOC出现跳变",
    "zh-Hant": ""
  },
  "fpv_tip_0x160900C3": {
    "de": "Akku nähert sich der Grenze der Anzahl von Zyklen. Weiterverwendung birgt Sicherheitsrisiken. Akku austauschen empfohlen",
    "en": "Battery approaching cycle count limit. Continuing use will pose safety risks. Replace battery recommended",
    "es": "La batería se acerca al límite de recuento de ciclos. Un uso continuo planteará riesgos de seguridad. Se recomienda sustituir la batería",
    "fr": "La batterie approche de la limite du nombre de cycles. Le fait de continuer à l'utiliser présente des risques pour la sécurité. Remplacement de la batterie recommandé",
    "ja": "バッテリーがサイクル回数上限に近づいています。使用を続けると安全上のリスクが発生します。バッテリーの交換をお勧めします",
    "ko": "배터리가 사이클 수 한도에 근접함. 계속 사용하면 안전 위험이 발생할 수 있습니다. 배터리 교체를 권고합니다",
    "ru": "АКБ приближается к пределу количества циклов. Продолжение использования создаст угрозу безопасности. Рекомендуется заменить АКБ",
    "tr": "Pil döngü sayısı sınırına yaklaşıyor. Kullanıma devam etmek güvenlik riskleri doğuracaktır. Pilin değiştirilmesi önerilir",
    "zh": "电池循环次数过高，继续使用有安全风险，建议更换该电池"
  },
  "fpv_tip_0x160900C4": {
    "de": "Kalibrierung des Akkustands von %battery_index erforderlich. Akku zum Regenerieren zwei Stunden lang ruhen lassen",
    "en": "%battery_index battery level calibration required. Let battery sit for 2 hours to restore",
    "es": "Es necesario calibrar el nivel de batería de %battery_index. Deje reposar la batería durante 2 horas para restaurarla",
    "fr": "%battery_index étalonnage du niveau de batterie requis. Laissez la batterie reposer pendant 2 heures pour restaurer",
    "ja": "%battery_index バッテリー残量キャリブレーションが必要です。復元するにはバッテリーを2時間放置します",
    "ko": "%battery_index 배터리 잔량 캘리브레이션이 필요합니다. 복원하려면 배터리를 2시간 동안 그대로 둡니다",
    "ru": "%battery_index требуется калибровка уровня заряда АКБ. Подождите 2 часа для восстановления АКБ",
    "tr": "%battery_index pil seviyesi kalibrasyonu gerekli. Geri yüklemek için pili 2 saat kullanmadan bekletin",
    "zh": "%battery_index电池电量需要校准，请静置2h尝试恢复"
  },
  "fpv_tip_0x160900D2": {
    "de": "%battery_index battery-Stand längere Zeit nicht kalibriert. Akku vor dem Kalibrieren eine Stunde lang ruhen lassen",
    "en": "%battery_index battery level not calibrated for a long time. Let battery sit for 1 hour before calibration",
    "es": "Nivel %battery_index battery no calibrado durante mucho tiempo. Deje reposar la batería durante 1 hora antes de la calibración",
    "fr": "Niveau %battery_index battery non étalonné depuis longtemps. Laissez la batterie reposer pendant 1 heure avant l'étalonnage",
    "ja": "%battery_index battery残量のキャリブレーションが長期間行われていません。バッテリーを1時間放置してからキャリブレーションを行ってください",
    "ko": "%battery_index battery 레벨을 장기간 캘리브레이션하지 않았습니다. 배터리를 1시간 동안 그대로 두었다가 캘리브레이션을 진행하세요",
    "ru": "%battery_index battery уровень давно не калибровался. Не используйте аккумулятор в течение 1 часа перед калибровкой",
    "tr": "%battery_index battery seviye uzun süredir kalibre edilmedi. Kalibrasyondan önce bataryayı 1 saat bekletin",
    "zh": "%battery_index电池电量长期未校准，请静置1h以上等待校准"
  },
  "fpv_tip_0x160900D5": {
    "de": "Start nicht möglich: Der Akku hat das Ende seiner Lebensdauer erreicht. Akku austauschen",
    "en": "Unable to take off: Battery reached end of lifespan. Replace battery",
    "es": "No se puede despegar: La batería ha alcanzado el final de su vida útil. Sustituye la batería",
    "fr": "Impossible de décoller : la batterie a atteint la fin de sa durée de vie. Remplacer la batterie",
    "ja": "離陸できません: バッテリーが耐用寿命に達しました。バッテリーを交換してください",
    "ko": "이륙 불가: 배터리 수명이 다 되었습니다. 배터리를 교체하세요.",
    "ru": "Не удается выполнить взлет: Срок службы аккумулятора подошел к концу. Замените аккумулятор",
    "tr": "Kalkış yapılamıyor: Pil kullanım ömrünün sonuna erişti. Pili değiştirin",
    "zh": "无法起飞：电池已达最大寿命，请更换电池"
  },
  "fpv_tip_0x160900D5_in_the_sky": {
    "de": "Start nicht möglich: Der Akku hat das Ende seiner Lebensdauer erreicht. Akku austauschen",
    "en": "Unable to take off: Battery reached end of lifespan. Replace battery",
    "es": "No se puede despegar: La batería ha alcanzado el final de su vida útil. Sustituye la batería",
    "fr": "Impossible de décoller : la batterie a atteint la fin de sa durée de vie. Remplacer la batterie",
    "ja": "離陸できません: バッテリーが耐用寿命に達しました。バッテリーを交換してください",
    "ko": "이륙 불가: 배터리 수명이 다 되었습니다. 배터리를 교체하세요.",
    "ru": "Не удается выполнить взлет: Срок службы аккумулятора подошел к концу. Замените аккумулятор",
    "tr": "Kalkış yapılamıyor: Pil kullanım ömrünün sonuna erişti. Pili değiştirin",
    "zh": "无法起飞：电池已达最大寿命，请更换电池"
  },
  "fpv_tip_0x160900D6": {
    "de": "Akku SOHc niedrig. Der Akku erreicht das Ende seiner Lebensdauer nach 20 Zyklen oder Tagen. Akku sofort austauschen",
    "en": "Battery SOHc low. Battery will reach end of lifespan after 20 cycles or days. Replace battery promptly",
    "es": "Batería SOHc baja. La batería alcanzará el final de su vida útil tras 20 ciclos o días. Sustituye la batería lo antes posible",
    "fr": "SOHc de la batterie faible. La batterie atteindra la fin de sa durée de vie au bout de 20 cycles ou jours. Remplacez rapidement la batterie",
    "ja": "バッテリーのSOHcが低下しています。バッテリーは20サイクルまたは20日後に耐用寿命に達します。すぐにバッテリーを交換してください",
    "ko": "배터리 SOHc 낮음. 배터리는 20 사이클 또는 수일 후에 수명이 다하게 됩니다. 배터리를 즉시 교체하세요.",
    "ru": "Низкий SOHc аккумулятора. Срок службы аккумулятора заканчивается через 20 циклов или дней. Замените аккумулятор как можно скорее",
    "tr": "Pil SOHc seviyesi düşük. Pil, 20 döngü veya gün sonra kullanım ömrünün sonuna ulaşacak. Pili derhal değiştirin",
    "zh": "电池健康度低，寿命预计在20循环或20天后耗尽，请及时更换电池"
  },
  "fpv_tip_0x160A0001": {
    "ar": "",
    "de": "",
    "en": "Parachute communication error. Make sure parachute is installed properly",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞通信异常，请检查是否正确安装",
    "zh-Hant": ""
  },
  "fpv_tip_0x160A0011": {
    "de": "Die Registrierung mit Klarnamen wurde abgebrochen, was zu Flugsicherheitsrisiken führen kann. Vor dem Flug die Hilfedokumentation beachten und eine Registrierung mit Klarnamen anhand der Anweisungen abschließen",
    "en": "Real-name registration is canceled, which may result in flight safety risks. View Help Documentation and complete real-name registration based on instructions before flight",
    "es": "El registro de nombre real se ha cancelado, lo que puede provocar riesgos de seguridad de vuelo. Echa un vistazo a la documentación de ayuda y completa el registro de nombre real según las instrucciones antes del vuelo",
    "fr": "L'enregistrement du nom réel est annulé, ce qui peut entraîner des risques de sécurité en vol. Consultez les documents d'aide et effectuez l'enregistrement du nom réel en fonction des instructions avant le vol",
    "ja": "実名の登録がキャンセルされたため、飛行の安全性に危険が生じる可能性があります。飛行前に手順に従ってヘルプドキュメントを参照し、実名の登録を完了してください",
    "ko": "실명 등록이 취소되어 비행 안전 위험이 발생할 수 있습니다. 비행 전에 지침에 따라 도움말 문서를 보고 실명 등록을 완료하세요.",
    "ru": "Регистрация с использованием настоящего имени отменена. Это может угрожать безопасности полета. Изучите справочную документацию и завершите регистрацию по инструкции перед началом полета",
    "tr": "Asıl adla kayıt işlemi iptal edildi. Bu durum, uçuş güvenliği risklerine neden olabilir. Uçuş öncesinde Yardım Belgesine bakın ve talimatlara uygun olarak asıl adla kayıt işlemini tamamlayın",
    "zh": "飞机实名登记状态已注销，存在飞行风险，请点击查看帮助文档，按照指引完成实名登记后飞行"
  },
  "fpv_tip_0x16100001": {
    "de": "Fehler: Kompass . Start nicht möglich. Fluggerät neu starten",
    "en": "Compass error. Unable to take off. Restart aircraft",
    "es": "Error de brújula. No se puede despegar. Reinicia la aeronave",
    "fr": "Erreur du compas . Impossible de décoller. Redémarrez l\\'appareil",
    "ja": "コンパスエラー。離陸できません。機体を再起動してください",
    "ko": "콤파스 오류 . 이륙 불가. 기체 재시작 필요",
    "ru": "Ошибка компаса . Взлет невозможен. Перезагрузите дрон",
    "tr": "Pusula hatası. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：指南针异常，请重启飞行器"
  },
  "fpv_tip_0x16100001_in_the_sky": {
    "de": "Kompassfehler. Zum Startpunkt zurückkehren oder landen",
    "en": "Compass error. Return to home or land",
    "es": "Error de brújula. Regresa al punto de origen o aterriza",
    "fr": "Erreur du compas. Revenez au point de départ ou atterrissez",
    "ja": "コンパスエラー。帰還するか、着陸してください",
    "ko": "콤파스 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка компаса. Вернитесь домой или приземлитесь",
    "tr": "Pusula hatası. Eve geri dönün veya iniş yapın",
    "zh": "指南针异常,请返航或降落"
  },
  "fpv_tip_0x16100002": {
    "de": "DJI Assistant verbunden. Start nicht möglich. Vor dem Start die Verbindung trennen",
    "en": "DJI Assistant connected. Unable to take off. Disconnect before taking off",
    "es": "DJI Assistant conectado. No se puede despegar. Desconéctalo antes de despegar",
    "fr": "DJI Assistant connecté. Impossible de décoller. Déconnectez-le avant de décoller",
    "ja": "DJI Assistantに接続されています。離陸できません。離陸する前に接続を解除してください",
    "ko": "DJI Assistant 연결됨. 이륙 불가. 이륙하기 전 연결 해제 필요",
    "ru": "ПО DJI Assistant подключено. Взлет невозможен. Отключите его перед взлетом",
    "tr": "DJI Assistant bağlandı. Kalkış yapamıyor. Kalkıştan önce bağlantıyı kes",
    "zh": "无法起飞：已连接DJI Assistant 软件，请先断开再起飞"
  },
  "fpv_tip_0x16100002_in_the_sky": {
    "de": "DJI Assistant verbunden. Vor dem Start die Verbindung trennen",
    "en": "DJI Assistant connected. Disconnect before taking off",
    "es": "DJI Assistant conectado. Desconéctalo antes de despegar",
    "fr": "DJI Assistant connecté. Déconnectez-le avant de décoller",
    "ja": "DJI Assistantに接続されています。離陸する前に接続を解除してください",
    "ko": "DJI Assistant 연결됨. 이륙하기 전 연결 해제 필요",
    "ru": "ПО DJI Assistant подключено. Отключите его перед взлетом",
    "tr": "DJI Assistant bağlandı. Kalkıştan önce bağlantıyı kes",
    "zh": "已连接DJI Assistant 软件，请先断开再飞行"
  },
  "fpv_tip_0x16100003": {
    "de": "Fluggerät nicht aktiviert oder Firmware veraltet. Kann nicht abheben. Fluggerät aktivieren oder auf neueste Firmware-Version aktualisieren",
    "en": "Aircraft not activated or firmware out-of-date. Unable to take off. Activate aircraft or update to latest firmware version",
    "es": "Aeronave no activada o firmware obsoleto. No se puede despegar. Activa la aeronave o actualiza a la versión del firmware más reciente",
    "fr": "L\\'appareil n\\'est pas activé ou le firmware est obsolète. Impossible de décoller. Activez l\\'appareil ou mettez le firmware à jour vers la version la plus récente",
    "ja": "機体がアクティベーションされていないか、古いバッテリーファームウェアです。離陸できません。機体をアクティベーションするか、最新のファームウェアバージョンに更新してください",
    "ko": "활성화 안 된 기체 또는 오래된 펌웨어. 이륙 불가. 기체를 활성화하거나 최신 펌웨어 버전으로 업데이트해야 함",
    "ru": "Дрон не активирован или ПО устарело. Взлет невозможен. Активируйте дрон или установите последнюю версию ПО",
    "tr": "Araç etkinleştirilmemiş ya da yazılım güncel değil. Kalkış yapamıyor. Aracı etkinleştirin ya da en son yazılım sürümüne güncelleyin",
    "zh": "无法起飞：请检查是否激活或尝试升级最新固件"
  },
  "fpv_tip_0x16100003_in_the_sky": {
    "de": "Geräteaktivierung oder Firmware-Aktualisierung erforderlich",
    "en": "Device activation or firmware update required",
    "es": "Es necesario activar el dispositivo o actualizar el firmware",
    "fr": "Activation de l\\'appareil ou mise à jour du firmware requise",
    "ja": "機器のアクティベーションまたはファームウェアの更新が必要です",
    "ko": "기기 활성화 또는 펌웨어 업데이트 필요",
    "ru": "Требуется активировать устройство или обновить ПО",
    "tr": "Cihazın etkinleştirilmesi ya da yazılımın güncellenmesi gerekiyor",
    "zh": "请检查是否激活或尝试升级最新固件"
  },
  "fpv_tip_0x16100005": {
    "ar": "",
    "de": "IMU-Fehler . Start nicht möglich. Fluggerät neu starten",
    "en": "IMU error. Unable to take off. Restart aircraft",
    "es": "Error de IMU. No se puede despegar. Reinicia la aeronave",
    "fr": "Erreur IMU. Impossible de décoller. Redémarrez l\\'appareil",
    "it": "",
    "ja": "IMU エラー。離陸できません。機体を再起動してください",
    "ko": "IMU 오류 . 이륙 불가.기체 재시작 필요",
    "pt": "",
    "ru": "Ошибка IMU . Взлет невозможен. Перезагрузите дрон",
    "tr": "IMU hatası. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：IMU异常，请重启飞行器"
  },
  "fpv_tip_0x16100005_in_the_sky": {
    "ar": "",
    "de": "Fehler: IMU. Fluggerät neu starten",
    "en": "IMU error. Restart aircraft",
    "es": "Error de IMU. Reinicia la aeronave",
    "fr": "Erreur IMU. Redémarrez l\\'appareil",
    "it": "",
    "ja": "IMUエラー。機体を再起動してください",
    "ko": "IMU 오류. 기체 재시작 필요",
    "pt": "",
    "ru": "Ошибка модуля IMU. Перезагрузите дрон",
    "tr": "IMU hatası. Aracı yeniden başlatın",
    "zh": "IMU异常，请重启飞行器"
  },
  "fpv_tip_0x16100006": {
    "de": "Ungültige IMU-Seriennummer . Kann nicht abheben. Wartung erforderlich",
    "en": "Invalid IMU serial number. Unable to take off. Maintenance required",
    "es": "Número de serie de la IMU no válido. No se puede despegar. Se requiere mantenimiento",
    "fr": "N° de série de l\\'IMU invalide . Impossible de décoller. Opération de maintenance requise",
    "ja": "無効なIMUシリアルナンバー。離陸できません。メンテナンスが必要です",
    "ko": "유효하지 않은 IMU 시리얼 넘버 . 이륙 불가. 유지 보수 필요",
    "ru": "Неверный серийный номер IMU . Взлет невозможен. Требуется обслуживание",
    "tr": "Geçersiz IMU seri numarası. Kalkış yapamıyor. Bakım yapılması gerekli",
    "zh": "无法起飞：IMU的SN标记错误，请返厂维修"
  },
  "fpv_tip_0x16100006_in_the_sky": {
    "de": "Ungültige IMU-Seriennummer . Zum Startpunkt zurückkehren oder landen.",
    "en": "Invalid IMU serial number. Return to home or land",
    "es": "Número de serie de la IMU no válido. Regresa al punto de origen o aterriza",
    "fr": "N° de série IMU invalide . Revenez au point de départ ou atterrissez",
    "ja": "無効なIMUシリアルナンバー。帰還するか、着陸してください",
    "ko": "유효하지 않은 IMU 시리얼 넘버 . RTH 진행 또는 착륙 필요",
    "ru": "Неверный серийный номер IMU . Вернитесь домой или приземлитесь",
    "tr": "Geçersiz IMU seri numarası. Eve geri dönün veya iniş yapın",
    "zh": "IMU的SN标记错误，请返航或降落"
  },
  "fpv_tip_0x16100008": {
    "de": "Kompass wird kalibriert. Kann nicht abheben. Bitte warten, bis Kalibrierung abgeschlossen ist (alarmierend)",
    "en": "Compass calibrating. Unable to take off. Wait for calibration to complete before taking off(%alarmid)",
    "es": "Calibrando la brújula. No se puede despegar. Espera a que se complete la calibración antes de despegar (%alarmid)",
    "fr": "Étalonnage du compas en cours. Impossible de décoller. Patientez jusqu\\'à ce que l\\'étalonnage soit terminé avant de décoller",
    "ja": "コンパスキャリブレーション中。離陸できません。キャリブレーションが完了してから離陸してください(%alarmid)",
    "ko": "콤파스 캘리브레이션 중. 이륙 불가. 캘리브레이션 완료 후 이륙해야 함",
    "ru": "Идет калибровка компаса. Взлет невозможен. Дождитесь ее окончания, прежде чем взлетать",
    "tr": "Pusula kalibre ediliyor. Kalkış yapamıyor. Kalkıştan önce kalibrasyonun tamamlanmasını bekleyin (%alarmid)",
    "zh": "无法起飞：指南针校准中，请等待校准完成再起飞"
  },
  "fpv_tip_0x16100008_in_the_sky": {
    "de": "Kompass wird kalibriert. Bitte warten, bis Kalibrierung abgeschlossen ist, bevor Sie abheben (alarmierend)",
    "en": "Compass calibrating. Wait for calibration to complete before taking off",
    "es": "Calibrando la brújula. Espera a que se complete la calibración antes de despegar",
    "fr": "Étalonnage du compas en cours. Attendez la fin de l\\'étalonnage avant de décoller",
    "ja": "コンパスキャリブレーション中。キャリブレーションが完了してから離陸してください",
    "ko": "콤파스 캘리브레이션 중. 캘리브레이션 완료 후 이륙해야 함",
    "ru": "Идет калибровка компаса. Дождитесь ее окончания, прежде чем взлетать",
    "tr": "Pusula kalibre ediliyor. Kalkıştan önce kalibrasyonun tamamlanmasını bekleyin",
    "zh": "指南针校准中，请等待校准完成再起飞"
  },
  "fpv_tip_0x16100009": {
    "de": "Initialisierung des Sensorsystems . Start nicht möglich. Bitte warten bis die Initialisierung abgeschlossen ist, bevor Sie starten",
    "en": "Sensor system initializing. Unable to take off. Wait for initialization to complete before taking off",
    "es": "Iniciando sistema de sensores. No se puede despegar. Espera a que se complete el inicio antes de despegar",
    "fr": "Initialisation du système de capteurs . Impossible de décoller. Attendez la fin de l\\'initialisation avant de décoller",
    "ja": "センサーシステム初期化中。離陸できません。初期化が完了してから離陸してください",
    "ko": "센서 시스템 초기화 중 . 이륙 불가. 초기화 완료 후 이륙해야 함",
    "ru": "Запускается система датчиков . Взлет невозможен. Дождитесь завершения процесса, прежде чем взлетать",
    "tr": "Sensör sistemi başlatılıyor. Kalkış yapamıyor. Kalkıştan önce başlatmanın tamamlanmasını bekleyin",
    "zh": "无法起飞：传感器系统初始化中，请等待初始化完成"
  },
  "fpv_tip_0x16100009_in_the_sky": {
    "de": "Initialisierung des Sensorsystems . Zum Startpunkt zurückkehren oder landen.",
    "en": "Sensor system initializing. Return to home or land",
    "es": "Iniciando sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Initialisation du système de capteurs . Revenez au point de départ ou atterrissez",
    "ja": "センサーシステム初期化中。帰還するか、着陸してください",
    "ko": "센서 시스템 초기화 중 . RTH 진행 또는 착륙 필요",
    "ru": "Запускается система датчиков . Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi başlatılıyor. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统初始化中，请返航或降落"
  },
  "fpv_tip_0x1610000A": {
    "de": "Fluggerät im Anfängermodus. Kann nicht abheben. Bitte in einem offenen Außenbereich abheben (alarmierend)",
    "en": "Aircraft in Beginner mode. Unable to take off. Take off in an open outdoor area when in Beginner mode",
    "es": "Aeronave en modo Principiante. No se puede despegar. Cuando el modo Principiante esté activado, despega desde una zona exterior abierta",
    "fr": "L\\'appareil est en mode Débutant. Impossible de décoller. En mode Débutant, décollez dans une zone à l\\'extérieur et dégagée",
    "ja": "機体はビギナーモードです。離陸できません。ビギナーモード中は開けた屋外エリアで離陸してください",
    "ko": "기체 초보자 모드 사용 중. 이륙 불가. 초보자 모드 사용 시 개방된 야외에서 이륙해야 함",
    "ru": "Дрон в режиме новичка. Взлет невозможен. Взлетайте на открытом пространстве на улице в этом режиме",
    "tr": "Araç Başlangıç modunda. Kalkış yapamıyor. Başlangıç modundayken açık bir dış mekan alanında kalkış yapın",
    "zh": "无法起飞：新手模式下请在室外开阔环境飞行"
  },
  "fpv_tip_0x1610000A_in_the_sky": {
    "de": "Anfängermodus aktiviert. Wenn Sie den Anfängermodus verwenden, starten Sie in einem offenen Außenbereich",
    "en": "Beginner mode enabled. When using Beginner mode, take off in an open, outdoor area",
    "es": "Modo Principiante activado. Al usar el modo Principiante, despega desde una zona exterior abierta",
    "fr": "Mode Débutant activé. En utilisant le mode Débutant, décollez dans une zone extérieure dégagée",
    "ja": "ビギナーモードが有効です。ビギナーモード使用時は、開けた屋外エリアで離陸してください",
    "ko": "초보자 모드 활성화됨. 초보자 모드 사용 시 개방된 야외에서 이륙해야 함",
    "ru": "Активирован режим новичка. Взлетайте на открытом пространстве на улице",
    "tr": "Başlangıç modu etkinleştirildi. Başlangıç modundayken açık bir dış mekan alanında kalkış yapın",
    "zh": "新手模式下请在室外开阔环境飞行"
  },
  "fpv_tip_0x1610000B": {
    "de": "Fehler: Akkuzelle . Kann nicht abheben. Bitte DJI Support kontaktieren",
    "en": "Battery cell error. Unable to take off. Contact DJI Support",
    "es": "Error de la célula de batería. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur de cellule de batterie . Impossible de décoller. Contactez l\\'assistance technique DJI",
    "ja": "バッテリーセルエラー。離陸できません。DJIサポートまでお問い合わせください",
    "ko": "배터리 셀 오류 . 이륙 불가. DJI 고객지원으로 문의해주세요",
    "ru": "Ошибка элемента батареи . Взлет невозможен. Обратитесь в службу поддержки DJI",
    "tr": "Pil hücresi hatası. Kalkış yapamıyor. DJI Destek ile iletişime geçin",
    "zh": "无法起飞：电池电芯错误，请联系售后服务"
  },
  "fpv_tip_0x1610000B_in_the_sky": {
    "de": "Fehler: Akkuzelle . Zum Startpunkt zurückkehren oder landen.",
    "en": "Battery cell error. Return to home or land",
    "es": "Error de la célula de batería. Regresa al punto de origen o aterriza",
    "fr": "Erreur de cellule de batterie . Revenez au point de départ ou atterrissez",
    "ja": "バッテリーセルエラー。帰還するか、着陸してください",
    "ko": "배터리 셀 오류 . RTH 진행 또는 착륙 필요",
    "ru": "Ошибка элемента батареи . Вернитесь домой или приземлитесь",
    "tr": "Pil hücresi hatası. Eve geri dönün veya iniş yapın",
    "zh": "电池电芯错误，请返航或降落"
  },
  "fpv_tip_0x1610000C": {
    "de": "Akkukommunikationsfehler . Kann nicht abheben. Akku erneut einlegen",
    "en": "Battery communication error. Unable to take off. Reinstall battery",
    "es": "Error de comunicación de la batería. No se puede despegar. Vuelve a instalar la batería",
    "fr": "Erreur de communication avec la batterie . Impossible de décoller. Réinstallez la batterie",
    "ja": "バッテリー通信エラー。離陸できません。バッテリーを再度取り付けてください",
    "ko": "배터리 통신 오류 . 이륙 불가. 배터리 재설치 필요",
    "ru": "Ошибка связи батареи . Взлет невозможен. Переустановите батарею",
    "tr": "Pil iletişim hatası. Kalkış yapamıyor. Pili tekrar tak",
    "zh": "无法起飞：电池通信错误，请重新安装电池"
  },
  "fpv_tip_0x1610000C_in_the_sky": {
    "de": "Kommunikationsfehler: Akku . Zum Startpunkt zurückkehren.",
    "en": "Battery communication error. Return to home",
    "es": "Error de comunicación de la batería. Regresa al punto de origen",
    "fr": "Erreur de communication avec la batterie . Revenez au point de départ",
    "ja": "バッテリー通信エラー。帰還してください",
    "ko": "배터리 통신 오류 . RTH 진행 필요",
    "ru": "Ошибка связи батареи . Вернитесь домой",
    "tr": "Pil iletişim hatası. Eve dön",
    "zh": "电池通信错误，请返航"
  },
  "fpv_tip_0x1610000D": {
    "de": "Akkuspannung sehr niedrig . Start nicht möglich. Bitte umgehend aufladen",
    "en": "Critical low battery voltage. Unable to take off. Charge promptly",
    "es": "Voltaje de la batería crítico. No se puede despegar. Cárgala cuanto antes",
    "fr": "Tension de la batterie dangereusement faible . Impossible de décoller. Rechargez immédiatement",
    "ja": "重度ローバッテリー電圧警告。離陸できません。直ちに充電してください",
    "ko": "배터리 전압 매우 낮음 . 이륙 불가. 즉시 충전 필요",
    "ru": "Критически низкое напряжение батареи . Взлет невозможен. Срочно зарядите дрон",
    "tr": "Pil voltajı çok düşük. Kalkış yapamıyor. Hemen şarj edin",
    "zh": "无法起飞：严重低电压警报，请及时充电"
  },
  "fpv_tip_0x1610000D_in_the_sky": {
    "de": "Kritisch niedrige Batteriespannung . Sofort zum Startpunkt zurückkehren oder landen.",
    "en": "Critical low battery voltage. Return to home or land promptly",
    "es": "Voltaje de la batería crítico. Regresa al punto de origen o aterriza rápidamente",
    "fr": "Tension de la batterie dangereusement faible . Revenez au point de départ ou atterrissez",
    "ja": "重度ローバッテリー電圧警告。直ちに帰還するか、着陸してください",
    "ko": "배터리 전압 매우 낮음 . 즉시 RTH 진행 또는 착륙 필요",
    "ru": "Критически низкое напряжение батареи . Вернитесь домой или приземлитесь немедленно",
    "tr": "Pil voltajı çok düşük. Hemen eve dönün veya iniş yapın",
    "zh": "严重低电压，请尽快返航或降落"
  },
  "fpv_tip_0x1610000E": {
    "de": "Akkuspannung extrem niedrig. Start nicht möglich. Umgehend aufladen",
    "en": "Critically low battery voltage. Unable to take off. Charge promptly",
    "es": "Voltaje de la batería críticamente bajo. No se puede despegar. Cárguela cuanto antes",
    "fr": "Tension de la batterie dangereusement faible. Impossible de décoller. Rechargez rapidement",
    "ja": "深刻なバッテリー低電圧です離陸できません。直ちに充電してください",
    "ko": "배터리 전압이 매우 낮습니다. 이륙 불가. 즉시 충전하세요",
    "ru": "Критически низкое напряжение АКБ. Невозможно взлететь. Немедленно зарядите АКБ",
    "tr": "Kritik derecede düşük pil gerilimi. Kalkış yapılamıyor. Hemen şarj edin",
    "zh": "无法起飞：严重低电压警报，请及时充电"
  },
  "fpv_tip_0x1610000E_in_the_sky": {
    "de": "Akkuspannung extrem niedrig. Zeitnah zum Startpunkt zurückkehren oder landen",
    "en": "Critically low battery voltage. Return to home or land promptly",
    "es": "Voltaje de la batería críticamente bajo. Regrese al punto de origen o aterrice lo antes posible",
    "fr": "Tension de la batterie dangereusement faible. Retourner au point de départ ou atterrir immédiatement",
    "ja": "深刻なバッテリー低電圧ですすぐにRTHするか着陸させてください",
    "ko": "배터리 전압이 매우 낮습니다. 즉시 리턴 투 홈(RTH)하거나 착륙시키세요",
    "ru": "Критически низкое напряжение АКБ. Незамедлительно совершите возврат домой или посадку",
    "tr": "Kritik derecede düşük pil gerilimi. Hemen başlangıç noktasına dönün veya iniş yapın",
    "zh": "严重低电压，请尽快返航或降落"
  },
  "fpv_tip_0x1610000F": {
    "de": "Akkuspannung kritisch niedrig. Start nicht möglich. Umgehend aufladen.",
    "en": "Critical low battery voltage. Unable to take off. Charge promptly",
    "es": "Voltaje de la batería crítico. No se puede despegar. Cárgala cuanto antes",
    "fr": "Tension de la batterie dangereusement faible. Impossible de décoller. Rechargez immédiatement",
    "ja": "重度のバッテリー低電圧。離陸できません。直ちに充電してください",
    "ko": "배터리 전압 매우 부족. 이륙 불가. 즉시 충전하세요.",
    "ru": "Критически низкое напряжение аккумулятора. Невозможно выполнить взлет. Немедленно зарядите",
    "tr": "Pil voltajı çok düşük. Kalkış yapamıyor. Hemen şarj edin",
    "zh": "无法起飞：严重低电压警报，请及时充电"
  },
  "fpv_tip_0x1610000F_in_the_sky": {
    "de": "Akkuspannung kritisch niedrig. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "Critical low battery voltage. Return to home or land promptly",
    "es": "Voltaje de la batería crítico. Regresa al punto de origen o aterriza rápidamente",
    "fr": "Tension de la batterie dangereusement faible. Revenez au point de départ ou atterrissez",
    "ja": "重度のバッテリー低電圧。直ちに帰還するか着陸してください",
    "ko": "배터리 전압 매우 부족. 즉시 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Критически низкое напряжение аккумулятора. Вернитесь в исходное положение или приземлитесь как можно скорее",
    "tr": "Pil voltajı çok düşük. Hemen eve dönün veya iniş yapın",
    "zh": "严重低电压，请尽快返航或降落"
  },
  "fpv_tip_0x16100010": {
    "de": "Akkuausgangsleistung nicht ausreichend . Kann nicht abheben. Bitte aufladen",
    "en": "Battery output power insufficient. Unable to take off. Charge promptly",
    "es": "Potencia de salida de la batería insuficiente. No se puede despegar. Cárgala cuanto antes",
    "fr": "Puissance de sortie de la batterie insuffisante . Impossible de décoller. Rechargez immédiatement",
    "ja": "バッテリー出力不足。離陸できません。直ちに充電してください",
    "ko": "배터리 출력 전원 부족 . 이륙 불가. 즉시 충전 필요",
    "ru": "Недостаточная мощность батареи . Взлет невозможен. Срочно зарядите дрон",
    "tr": "Pil çıkış gücü yetersiz. Kalkış yapamıyor. Hemen şarj edin",
    "zh": "无法起飞：电池输出功率不足，请充电。若环境温度低，预热电池"
  },
  "fpv_tip_0x16100010_in_the_sky": {
    "de": "Akkuleistung unzureichend . Zum Startpunkt zurückkehren oder landen.",
    "en": "Battery power output insufficient. Return to home or land",
    "es": "Potencia de salida de la batería insuficiente. Regresa al punto de origen o aterriza",
    "fr": "Puissance de sortie de la batterie insuffisante . Revenez au point de départ ou atterrissez",
    "ja": "バッテリー出力不足。帰還するか、着陸してください",
    "ko": "배터리 전원 출력 부족 . RTH 진행 또는 착륙 필요",
    "ru": "Недостаточная производительность батареи . Вернитесь домой или приземлитесь",
    "tr": "Pil çıkış gücü yetersiz. Eve geri dönün veya iniş yapın",
    "zh": "电池输出功率不足，请尽快返航或降落"
  },
  "fpv_tip_0x16100011": {
    "de": "Akkustand kritisch niedrig. Start nicht möglich. Umgehend aufladen.",
    "en": "Critical low battery. Unable to take off. Charge promptly",
    "es": "Nivel de batería crítico. No se puede despegar. Cárgala cuanto antes",
    "fr": "Batterie dangereusement faible. Impossible de décoller. Rechargez immédiatement",
    "ja": "重度のローバッテリー。離陸できません。直ちに充電してください",
    "ko": "심각한 배터리 부족. 이륙 불가. 즉시 충전하세요.",
    "ru": "Критически низкий заряд аккумулятора. Невозможно выполнить взлет. Немедленно зарядите",
    "tr": "Çok düşük pil. Kalkış yapamıyor. Hemen şarj edin",
    "zh": "无法起飞：严重低电量警报，请及时充电"
  },
  "fpv_tip_0x16100011_in_the_sky": {
    "de": "Akkustand kritisch niedrig. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "Critical low battery. Return to home or land promptly",
    "es": "Nivel de batería crítico. Regresa al punto de origen o aterriza rápidamente",
    "fr": "Batterie dangereusement faible. Revenez au point de départ ou atterrissez",
    "ja": "重度のローバッテリー。直ちに帰還するか着陸してください",
    "ko": "심각한 배터리 부족. 즉시 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Критически низкий заряд аккумулятора. Вернитесь в исходное положение или приземлитесь как можно скорее",
    "tr": "Çok düşük pil. Hemen eve dönün veya iniş yapın",
    "zh": "严重低电量，请尽快返航或降落"
  },
  "fpv_tip_0x16100012": {
    "de": "Initialisierung des Akkus . Start nicht möglich. Bitte warten bis die Initialisierung abgeschlossen ist",
    "en": "Battery initializing. Unable to take off. Wait for initialization to complete before taking off",
    "es": "Iniciando batería. No se puede despegar. Espera a que se complete el inicio antes de despegar",
    "fr": "Initialisation de la batterie . Impossible de décoller. Attendez la fin de l\\'initialisation avant de décoller",
    "ja": "バッテリー初期化中。離陸できません。初期化が完了してから離陸してください",
    "ko": "배터리 초기화 중 . 이륙 불가. 초기화 완료 후 이륙해야 함",
    "ru": "Батарея включается . Взлет невозможен. Дождитесь завершения процесса перед взлетом",
    "tr": "Pil başlatılıyor. Kalkış yapamıyor. Kalkıştan önce başlatmanın tamamlanmasını bekleyin",
    "zh": "无法起飞：电池初始化中，请等待初始化完成"
  },
  "fpv_tip_0x16100012_in_the_sky": {
    "de": "Fehler: Akkuinitialisierung . Zum Startpunkt zurückkehren oder landen.",
    "en": "Battery initialization error. Return to home or land",
    "es": "Error de inicio de la batería. Regresa al punto de origen o aterriza",
    "fr": "Erreur d\\'initialisation de la batterie . Revenez au point de départ ou atterrissez",
    "ja": "バッテリー初期化エラー帰還するか、着陸してください",
    "ko": "배터리 초기화 오류 . RTH 진행 또는 착륙 필요",
    "ru": "Ошибка запуска батареи . Вернитесь домой или приземлитесь",
    "tr": "Pil başlatma hatası. Eve geri dönün veya iniş yapın",
    "zh": "电池异常初始化，请返航或降落"
  },
  "fpv_tip_0x16100013": {
    "de": "Flugsimulator aktiv. Kann nicht abheben. Neustart durchführen, um zu starten",
    "en": "Running Flight Simulator. Unable to take off. Restart aircraft before taking off",
    "es": "Se está ejecutando el simulador de vuelo. No se puede despegar. Reinicia la aeronave antes de despegar",
    "fr": "Le simulateur de vol est en cours d’utilisation. Impossible de décoller. Redémarrez l’appareil avant de décoller",
    "ja": "フライトシミュレーター実行中。離陸できません。離陸前に機体を再起動してください",
    "ko": "비행 시뮬레이터 실행 중. 이륙 불가. 이륙하기 전 기체 재시작 필요",
    "ru": "Работает симулятор полетов. Взлет невозможен. Перезагрузите дрон, прежде чем взлетать",
    "tr": "Uçuş Simülatörü Çalışıyor. Kalkış yapamıyor. Kalkıştan önce aracı yeniden başlat",
    "zh": "无法起飞：正在运行模拟器，起飞需要重启飞行器"
  },
  "fpv_tip_0x16100013_in_the_sky": {
    "de": "Flugsimulator aktiv. Neustart durchführen, um zu starten",
    "en": "Running Flight Simulator. Restart aircraft before taking off",
    "es": "Se está ejecutando el simulador de vuelo. Reinicia la aeronave antes de despegar",
    "fr": "Le simulateur de vol est en cours d’utilisation. Redémarrez l’appareil avant de décoller",
    "ja": "フライトシミュレーター実行中。離陸前に機体を再起動してください",
    "ko": "비행 시뮬레이터 실행 중. 이륙하기 전 기체 재시작 필요",
    "ru": "Работает симулятор полетов. Перезагрузите дрон, прежде чем взлетать",
    "tr": "Uçuş Simülatörü Çalışıyor. Kalkıştan önce aracı yeniden başlat",
    "zh": "正在运行模拟器，起飞需要重启飞行器"
  },
  "fpv_tip_0x16100014": {
    "de": "Start nicht möglich. Landegestell im Transportmodus",
    "en": "Unable to take off. Landing gear in Travel mode",
    "es": "No se puede despegar. Tren de aterrizaje en modo Viaje.",
    "fr": "Impossible de décoller. Train d’atterrissage en mode Transport",
    "ja": "離陸できません。ランディングギアがトラベルモード",
    "ko": "이륙 불가. 랜딩 기어가 트래블 모드에 있습니다",
    "ru": "Не удается выполнить взлет. Шасси в режиме движения",
    "tr": "Kalkış yapılamıyor. İniş takımı seyahat modunda",
    "zh": "无法起飞：起落架处于运输模式"
  },
  "fpv_tip_0x16100015": {
    "de": "Neigungswinkel des Fluggeräts zu groß. Kann nicht abheben. Fluggerät vor dem Start auf ebener Fläche abstellen",
    "en": "Aircraft pitch angle too large. Unable to take off. Ensure aircraft is level before taking off",
    "es": "Ángulo de inclinación de la aeronave demasiado grande. No se puede despegar. Asegúrate de que la aeronave esté nivelada antes de despegar",
    "fr": "L\\'angle d\\'inclinaison de l\\'appareil est trop grand. Impossible de décoller. Assurez-vous que l\\'appareil est à l\\'horizontale avant de décoller",
    "ja": "機体のピッチ角が大きすぎます。離陸できません。離陸前に機体が水平であることを確認してください",
    "ko": "기체 피치각 너무 큼. 이륙 불가. 기체를 평평한 곳에 놓고 이륙해야 함",
    "ru": "Слишком большой угол наклона дрона. Взлет невозможен. Убедитесь, что дрон расположен ровно перед взлетом",
    "tr": "Aracın adım açısı çok büyük. Kalkış yapamıyor. Kalkış öncesi aracın düz olduğunda emin olun",
    "zh": "无法起飞：飞行器倾斜角度过大，请水平放置飞行器后起飞"
  },
  "fpv_tip_0x16100015_in_the_sky": {
    "de": "Neigungswinkel des Fluggeräts zu groß. Fluggerät vor dem Start auf ebener Fläche abstellen",
    "en": "Aircraft pitch angle too large. Ensure aircraft is level before taking off",
    "es": "Ángulo de inclinación de la aeronave demasiado grande. Asegúrate de que la aeronave esté nivelada antes de despegar",
    "fr": "Angle d\\'inclinaison de l\\'appareil trop grand. Assurez-vous que l\\'appareil est à l\\'horizontale avant de décoller",
    "ja": "機体のピッチ角が大きすぎます。離陸前に機体が水平であることを確認してください",
    "ko": "기체 피치각 너무 큼. 기체를 평평한 곳에 놓고 이륙해야 함",
    "ru": "Слишком большой угол наклона дрона. Убедитесь, что дрон расположен ровно перед взлетом",
    "tr": "Aracın adım açısı çok büyük. Kalkış öncesi aracın düz olduğunda emin olun",
    "zh": "飞行器倾斜角度过大，请水平放置飞行器后起飞"
  },
  "fpv_tip_0x16100016": {
    "de": "Fluggerät nicht aktiviert. Kann nicht abheben. DJI Pilot neu starten und aktivieren",
    "en": "Aircraft not activated. Unable to take off. Restart DJI Pilot and activate aircraft",
    "es": "Aeronave no activada. No se puede despegar. Reinicia DJI Pilot y activa la aeronave",
    "fr": "L\\'appareil n\\'est pas activé. Impossible de décoller. Redémarrez DJI Pilot et activez l\\'appareil",
    "ja": "機体が未アクティベーション。離陸できません。DJI Pilotを再起動して機体をアクティベートしてください",
    "ko": "활성화 안 된 기체. 이륙 불가. DJI Pilot 재시작 후 기체 활성화해야 함",
    "ru": "Дрон не активирован. Взлет невозможен. Перезагрузите DJI Pilot и активируйте дрон",
    "tr": "Araç etkinleştirilmemiş. Kalkış yapamıyor. DJI Pilot’u yeniden başlatın ve aracı etkinleştirin",
    "zh": "无法起飞：飞行器未激活，请重启App进行激活"
  },
  "fpv_tip_0x16100016_in_the_sky": {
    "de": "Fluggerät nicht aktiviert. Starten Sie DJI Pilot neu und aktivieren Sie das Fluggerät",
    "en": "Aircraft not activated. Restart DJI Pilot and activate aircraft",
    "es": "Aeronave no activada. Reinicia DJI Pilot y activa la aeronave",
    "fr": "Appareil non activé. Redémarrez DJI Pilot et activez l\\'appareil",
    "ja": "機体が未アクティベーション。DJI Pilotを再起動して機体をアクティベートしてください",
    "ko": "활성화 안 된 기체. DJI Pilot 재시작 후 기체 활성화해야 함",
    "ru": "Дрон не активирован. Перезагрузите DJI Pilot и активируйте дрон",
    "tr": "Araç etkinleştirilmemiş. DJI Pilot’u yeniden başlatın ve aracı etkinleştirin",
    "zh": "飞行器未激活，请重启App进行激活"
  },
  "fpv_tip_0x16100017": {
    "de": "Fluggerät befindet sich ein einer GEO-Zone. Start nicht möglich. Bitte Karte auf empfohlene Zonen überprüfen",
    "en": "Aircraft in GEO Zone. Unable to take off. Check map to find Recommended Zones",
    "es": "Aeronave en zona GEO. No se puede despegar. Comprueba el mapa e identifica las zonas recomendadas",
    "fr": "L\\'appareil est dans une zone GEO. Impossible de décoller. Consultez la carte pour trouver les zones recommandées",
    "ja": "機体がGEO区域内です。離陸できません。マップを確認して推奨区域を探してください",
    "ko": "기체가 GEO 구역 내 있음. 이륙 불가. 지도에서 권장 비행 구역 확인 필요",
    "ru": "Дрон в зоне GEO. Взлет невозможен. Найдите рекомендуемые зоны на карте",
    "tr": "Araç GEO Bölgesinde. Kalkış yapamıyor. Önerilen Bölgeleri bulmak için haritaya bak",
    "zh": "无法起飞：飞行器在限飞区，请查看地图寻找可飞行区域"
  },
  "fpv_tip_0x16100017_in_the_sky": {
    "de": "Fluggerät befindet sich ein einer GEO-Zone. Bitte Karte auf empfohlene Zonen überprüfen",
    "en": "Aircraft in GEO Zone. Check map to find Recommended Zones",
    "es": "Aeronave en zona GEO. Comprueba el mapa e identifica las zonas recomendadas",
    "fr": "L\\'appareil est dans une zone GEO. Consultez la carte pour trouver les zones recommandées",
    "ja": "機体がGEO区域内です。マップを確認して推奨区域を探してください",
    "ko": "기체가 GEO 구역 내 있음. 지도에서 권장 비행 구역 확인 필요",
    "ru": "Дрон в зоне GEO. Найдите рекомендуемые зоны на карте",
    "tr": "Araç GEO Bölgesinde. Önerilen Bölgeleri bulmak için haritaya bak",
    "zh": "飞行器在限飞区，请查看地图寻找可飞行区域"
  },
  "fpv_tip_0x16100018": {
    "de": "IMU-Fehler . Start nicht möglich. IMU kalibrieren",
    "en": "IMU error. Unable to take off. Calibrate IMU",
    "es": "Error de IMU. No se puede despegar. Calibra la IMU",
    "fr": "Erreur d’IMU . Impossible de décoller. Étalonnez l\\'IMU",
    "ja": "IMUエラー。離陸できません。IMUをキャリブレーションしてください",
    "ko": "IMU 오류 . 이륙 불가. IMU 캘리브레이션 필요",
    "ru": "Ошибка IMU . Взлет невозможен. Откалибруйте IMU",
    "tr": "IMU hatası. Kalkış yapamıyor. IMU’yu Kalibre Et",
    "zh": "无法起飞：IMU 异常，请校准 IMU"
  },
  "fpv_tip_0x16100018_in_the_sky": {
    "de": "IMU-Initialisierungsfehler . Zum Startpunk zurückkehren oder landen.",
    "en": "IMU initialization error. Return to home or land",
    "es": "Error de inicio de la IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur d\\'initialisation de l\\'IMU . Revenez au point de départ ou atterrissez",
    "ja": "IMU初期化エラー。帰還するか、着陸してください",
    "ko": "IMU 초기화 오류 . RTH 진행 또는 착륙 필요",
    "ru": "Ошибка запуска IMU . Вернитесь домой или приземлитесь",
    "tr": "IMU başlatma hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU 初始化异常，请返航或降落"
  },
  "fpv_tip_0x16100019": {
    "de": "ESC-Fehler . Kann nicht abheben. Bitte DJI Support kontaktieren",
    "en": "ESC error. Unable to take off. Contact DJI Support",
    "es": "Error de ESC. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur ESC . Impossible de décoller. Contactez l\\'assistance technique DJI",
    "ja": "ESCエラー。離陸できません。DJIサポートまでお問い合わせください",
    "ko": "ESC 오류 . 이륙 불가. DJI 고객지원으로 문의해주세요",
    "ru": "Ошибка ESC . Взлет невозможен. Обратитесь в службу поддержки DJI",
    "tr": "ESC hatası. Kalkış yapamıyor. DJI Destek ile iletişime geçin",
    "zh": "无法起飞：电调异常，请联系售后服务"
  },
  "fpv_tip_0x16100019_in_the_sky": {
    "de": "ESC-Fehler . Bitte sofort landen.",
    "en": "ESC error. Land immediately",
    "es": "Error de ESC. Aterriza de inmediato",
    "fr": "Erreur ESC . Atterrissez immédiatement",
    "ja": "ESCエラー。直ちに着陸してください",
    "ko": "ESC 오류 . 즉시 착륙 필요",
    "ru": "Ошибка ESC . Приземлитесь немедленно",
    "tr": "ESC hatası. Hemen iniş yap",
    "zh": "电调异常，请立即降落"
  },
  "fpv_tip_0x1610001A": {
    "de": "Initialisierung des Sensorsystems. Start nicht möglich. Vor dem Start bitte auf Abschluss der Initialisierung warten",
    "en": "Sensor system initializing. Unable to take off. Wait for initialization to complete before taking off",
    "es": "Iniciando sistema de sensores. No se puede despegar. Espera a que se complete el inicio antes de despegar",
    "fr": "Initialisation du système de capteurs. Impossible de décoller. Attendez la fin de l\\'initialisation avant de décoller",
    "ja": "センサーシステム初期化中。離陸できません。初期化が完了するのを待ってから離陸してください",
    "ko": "센서 시스템 초기화 중. 이륙 불가. 이륙 전에 초기화가 완료될 때까지 대기",
    "ru": "Инициализация системы датчика. Невозможно выполнить взлет. Дождитесь завершения инициализации перед взлетом",
    "tr": "Sensör sistemi başlatılıyor. Kalkış yapamıyor. Kalkıştan önce başlatmanın tamamlanmasını bekleyin",
    "zh": "无法起飞：传感器系统初始化中，请等待初始化完成"
  },
  "fpv_tip_0x1610001A_in_the_sky": {
    "de": "Initialisierung des Sensorsystems. Zum Startpunkt zurückkehren oder landen",
    "en": "Sensor system initializing. Return to home or land",
    "es": "Iniciando sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Initialisation du système de capteur. Revenez au point de départ ou atterrissez",
    "ja": "センサーシステム初期化中。帰還するか着陸してください",
    "ko": "센서 시스템 초기화 중. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Инициализация системы датчика. Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi başlatılıyor. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统初始化中，请返航或降落"
  },
  "fpv_tip_0x1610001B": {
    "de": "System wird aktualisiert . Kann nicht abheben. Warten, bis Aktualisierung abgeschlossen ist",
    "en": "System updating. Unable to take off. Wait for update to complete",
    "es": "Actualizando el sistema. No se puede despegar. Espera a que se complete la actualización",
    "fr": "Mise à jour du système . Impossible de décoller. Patientez jusqu\\'à ce que la mise la jour soit terminée",
    "ja": "システム更新中。離陸できません。更新の完了を待ってください",
    "ko": "시스템 업데이트 중 . 이륙 불가. 업데이트가 완료될 때까지 기다려야 함",
    "ru": "Система обновляется . Взлет невозможен. Дождитесь завершения обновления",
    "tr": "Sistem güncelleniyor. Kalkış yapamıyor. Güncellemenin tamamlanmasını bekleyin",
    "zh": "无法起飞：系统正在升级，请稍等"
  },
  "fpv_tip_0x1610001B_in_the_sky": {
    "de": "Systemaktualisierung. Warten, bis die Aktualisierung abgeschlossen ist, bevor Sie abheben .",
    "en": "System updating. Wait for update to complete before taking off",
    "es": "Actualizando el sistema. Espera a que se complete la actualización antes de despegar",
    "fr": "Mise à jour du système en cours. Attendez la fin de la mise à jour avant de décoller",
    "ja": "システム更新中。更新が完了してから離陸してください",
    "ko": "시스템 업데이트 중. 업데이트가 완료될 때까지 기다린 후 이륙해야 함",
    "ru": "Система обновляется. Дождитесь окончания обновления, прежде чем взлетать",
    "tr": "Sistem güncelleniyor. Kalkıştan önce güncellemenin tamamlanmasını bekleyin",
    "zh": "系统正在升级，请等待升级结束再起飞"
  },
  "fpv_tip_0x1610001C": {
    "de": "Flugsimulator aktiv . Kann nicht abheben. Neustart durchführen, um zu starten",
    "en": "Running Flight Simulator. Unable to take off. Restart aircraft before taking off",
    "es": "Se está ejecutando el simulador de vuelo. No se puede despegar. Reinicia la aeronave antes de despegar",
    "fr": "Le simulateur de vol est en cours d’utilisation . Impossible de décoller. Redémarrez l’appareil pour décoller",
    "ja": "フライトシミュレーター実行中。離陸できません。離陸前に機体を再起動してください",
    "ko": "비행 시뮬레이터 실행 중 . 이륙 불가. 이륙하기 전 기체 재시작 필요",
    "ru": "Работает симулятор полетов . Взлет невозможен. Перезагрузите дрон перед взлетом",
    "tr": "Uçuş Simülatörü Çalışıyor. Kalkış yapamıyor. Kalkıştan önce aracı yeniden başlat",
    "zh": "无法起飞：已运行模拟器，起飞需要重启飞行器"
  },
  "fpv_tip_0x1610001C_in_the_sky": {
    "de": "Flugsimulator aktiv . Neustart durchführen, um zu starten.",
    "en": "Running Flight Simulator. Restart aircraft before taking off",
    "es": "Se está ejecutando el simulador de vuelo. Reinicia la aeronave antes de despegar",
    "fr": "Le simulateur de vol est en cours d’utilisation . Redémarrez l’appareil pour décoller",
    "ja": "フライトシミュレーター実行中。離陸前に機体を再起動してください",
    "ko": "비행 시뮬레이터 실행 중 . 이륙하기 전 기체 재시작 필요",
    "ru": "Работает симулятор полетов . Перезагрузите дрон перед взлетом",
    "tr": "Uçuş Simülatörü Çalışıyor. Kalkıştan önce aracı yeniden başlat",
    "zh": "已运行模拟器，起飞需要重启飞行器"
  },
  "fpv_tip_0x1610001D": {
    "de": "IMU-Kalibrierung . Kann nicht abheben. Warten, bis die Kalibrierung abgeschlossen ist",
    "en": "IMU calibrating. Unable to take off. Wait for calibration to complete before takeoff",
    "es": "Calibrando la IMU. No se puede despegar. Espera a que se complete la calibración antes de despegar",
    "fr": "Étalonnage de l\\'IMU en cours . Impossible de décoller. Attendez la fin de l\\'étalonnage avant de décoller",
    "ja": "IMUキャリブレーション中。離陸できません。キャリブレーションが完了してから離陸してください",
    "ko": "IMU 캘리브레이션 중 . 이륙 불가. 캘리브레이션 완료 후 이륙해야 함",
    "ru": "Идет калибровка IMU . Взлет невозможен. Дождитесь завершения процесса, прежде чем взлетать",
    "tr": "IMU kalibre ediliyor. Kalkış yapamıyor. Kalkıştan önce kalibrasyonun tamamlanmasını bekleyin",
    "zh": "无法起飞：IMU 校准中，请等待校准完毕再起飞"
  },
  "fpv_tip_0x1610001D_in_the_sky": {
    "de": "IMU wird kalibriert . Bitte warten, bis Kalibrierung abgeschlossen ist, bevor Sie abheben.",
    "en": "Calibrating IMU. Wait for calibration to complete before taking off",
    "es": "Calibrando la IMU. Espera a que se complete la calibración antes de despegar",
    "fr": "Étalonnage de l\\'IMU en cours . Attendez la fin de l\\'étalonnage avant de décoller",
    "ja": "IMUキャリブレーション中。キャリブレーションが完了してから離陸してください",
    "ko": "IMU 캘리브레이션 중 . 캘리브레이션 완료 후 이륙해야 함",
    "ru": "Идет калибровка IMU . Дождитесь ее завершения, прежде чем взлетать",
    "tr": "IMU kalibre ediliyor. Kalkıştan önce kalibrasyonun tamamlanmasını bekleyin",
    "zh": "IMU 校准中，请等待校准完毕再起飞"
  },
  "fpv_tip_0x1610001E": {
    "de": "Nickwinkel des Fluggeräts zu groß. Start nicht möglich. Fluggerät vor dem Start auf ebener Fläche abstellen",
    "en": "Aircraft pitch angle too large. Unable to take off. Ensure aircraft is level before taking off",
    "es": "Ángulo de inclinación de la aeronave demasiado grande. No se puede despegar. Asegúrate de que la aeronave esté nivelada antes de despegar",
    "fr": "l\\'angle d\\'inclinaison de l\\'appareil est trop grand. Impossible de décoller. Assurez-vous que l\\'appareil est à l\\'horizontale avant de décoller",
    "ja": "機体のピッチ角が大きすぎます。離陸できません。離陸前に機体が水平なことを確認してください",
    "ko": "기체 피치각 너무 큼. 이륙 불가. 이륙 전에 기체가 수평인지 확인하세요",
    "ru": "Слишком большой угол крена дрона. Невозможно выполнить взлет. Убедитесь, что дрон расположен горизонтально перед взлетом",
    "tr": "Aracın adım açısı çok büyük. Kalkış yapamıyor. Kalkış öncesi aracın düz olduğunda emin olun",
    "zh": "无法起飞：飞行器倾斜角度过大，请水平放置飞行器后起飞"
  },
  "fpv_tip_0x1610001E_in_the_sky": {
    "de": "Nickwinkel des Fluggeräts zu groß. Fluggerät vor dem Start auf ebener Fläche abstellen",
    "en": "Aircraft pitch angle too large. Ensure aircraft is level before taking off",
    "es": "Ángulo de inclinación de la aeronave demasiado grande. Asegúrate de que la aeronave esté nivelada antes de despegar",
    "fr": "Angle d\\'inclinaison de l\\'appareil trop grand. Assurez-vous que l\\'appareil est à l\\'horizontale avant de décoller",
    "ja": "機体のピッチ角が大きすぎます。離陸前に機体が水平なことを確認してください",
    "ko": "기체 피치각 너무 큼. 이륙 전에 기체가 수평인지 확인하세요",
    "ru": "Слишком большой угол крена дрона. Убедитесь, что дрон расположен горизонтально перед взлетом",
    "tr": "Aracın adım açısı çok büyük. Kalkış öncesi aracın düz olduğunda emin olun",
    "zh": "飞行器倾斜角度过大，请水平放置飞行器后起飞"
  },
  "fpv_tip_0x1610001F": {
    "ar": "",
    "de": "Start nicht möglich.Flugregler überhitzt.",
    "en": "Unable to take off. Flight controller overheats.",
    "es": "No se puede despegar.El controlador de vuelo se sobrecalienta.",
    "fr": "Impossible de décoller.Surchauffe du contrôleur de vol.",
    "it": "",
    "ja": "離陸できません。フライトコントローラーが過熱状態。",
    "ko": "이륙 불가. 비행 컨트롤러 과열.",
    "pt": "",
    "ru": "Невозможно выполнить взлет.Полетный контроллер перегревается.",
    "tr": "Kalkış yapamıyor.Uçuş kontrol sistemi aşırı ısındı.",
    "zh": "无法起飞：飞控温度过高"
  },
  "fpv_tip_0x1610001F_in_the_sky": {
    "ar": "",
    "de": "Flugregler überhitzt. Rückkehr zum Startpunkt...",
    "en": "Flight controller overheats. Returning to home...",
    "es": "El controlador de vuelo se sobrecalienta. Regresando al punto de origen...",
    "fr": "Surchauffe du contrôleur de vol. Retour au point de départ en cours...",
    "it": "",
    "ja": "フライトコントローラーが過熱状態。ホームに帰還中...",
    "ko": "비행 컨트롤러 과열. RTH 진행 중...",
    "pt": "",
    "ru": "Полетный контроллер перегревается. Возвращение на базу...",
    "tr": "Uçuş kontrol sistemi aşırı ısındı. Başlangıç noktasına dönülüyor...",
    "zh": "飞控温度过高，返航中"
  },
  "fpv_tip_0x16100020": {
    "ar": "",
    "de": "Start nicht möglich.Flugregler überhitzt.",
    "en": "Unable to take off. Flight controller overheats.",
    "es": "No se puede despegar.El controlador de vuelo se sobrecalienta.",
    "fr": "Impossible de décoller.Surchauffe du contrôleur de vol.",
    "it": "",
    "ja": "離陸できません。フライトコントローラーが過熱状態。",
    "ko": "이륙 불가. 비행 컨트롤러 과열.",
    "pt": "",
    "ru": "Невозможно выполнить взлет.Полетный контроллер перегревается.",
    "tr": "Kalkış yapamıyor.Uçuş kontrol sistemi aşırı ısındı.",
    "zh": "无法起飞：飞控温度过高"
  },
  "fpv_tip_0x16100020_in_the_sky": {
    "ar": "",
    "de": "Flugregler überhitzt. Rückkehr zum Startpunkt...",
    "en": "Flight controller overheats. Returning to home...",
    "es": "El controlador de vuelo se sobrecalienta. Regresando al punto de origen...",
    "fr": "Surchauffe du contrôleur de vol. Retour au point de départ en cours...",
    "it": "",
    "ja": "フライトコントローラーが過熱状態。ホームに帰還中...",
    "ko": "비행 컨트롤러 과열. RTH 진행 중...",
    "pt": "",
    "ru": "Полетный контроллер перегревается. Возвращение на базу...",
    "tr": "Uçuş kontrol sistemi aşırı ısındı. Başlangıç noktasına dönülüyor...",
    "zh": "飞控温度过高，返航中"
  },
  "fpv_tip_0x16100021": {
    "ar": "",
    "de": "Start nicht möglich.Flugregler überhitzt.",
    "en": "Unable to take off. Flight controller overheats.",
    "es": "No se puede despegar.El controlador de vuelo se sobrecalienta.",
    "fr": "Impossible de décoller.Surchauffe du contrôleur de vol.",
    "it": "",
    "ja": "離陸できません。フライトコントローラーが過熱状態。",
    "ko": "이륙 불가. 비행 컨트롤러 과열.",
    "pt": "",
    "ru": "Невозможно выполнить взлет.Полетный контроллер перегревается.",
    "tr": "Kalkış yapamıyor.Uçuş kontrol sistemi aşırı ısındı.",
    "zh": "无法起飞：飞控温度过高"
  },
  "fpv_tip_0x16100021_in_the_sky": {
    "ar": "",
    "de": "Flugregler überhitzt. Automatische Landung…",
    "en": "Flight controller overheats. Auto landing...",
    "es": "El controlador de vuelo se sobrecalienta. Realizando aterrizaje automático…",
    "fr": "Surchauffe du contrôleur de vol. Atterrissage automatique en cours...",
    "it": "",
    "ja": "フライトコントローラーが過熱状態。自動着陸中...",
    "ko": "비행 컨트롤러 과열. 자동 착륙 진행 중...",
    "pt": "",
    "ru": "Полетный контроллер перегревается. Автоматическая посадка…",
    "tr": "Uçuş kontrol sistemi aşırı ısındı. Otomatik iniş yapılıyor...",
    "zh": "飞控温度过高，强制降落中"
  },
  "fpv_tip_0x16100022": {
    "en": "Unable to take off. Aircraft unable to remain stable for several times. To ensure flight safety, contact DJI Support",
    "zh": "无法起飞：飞机已多次失稳，为保证飞行安全，请联系大疆售后服务"
  },
  "fpv_tip_0x16100023": {
    "de": "AirSense-Fehler. Informationen zu bemannten Flugzeugen können nicht abgerufen werden",
    "en": "AirSense error. Unable to obtain crewed aircraft information",
    "es": "Error de AirSense. No se puede obtener la información de aeronaves tripuladas",
    "fr": "Erreur AirSense. Impossible d’obtenir des informations sur l’appareil piloté",
    "ja": "AirSenseエラー。有人機体情報を取得できません",
    "ko": "AirSense 오류. 유인 기체 정보를 확인할 수 없음",
    "ru": "Ошибка AirSense. Невозможно получить информацию о пилотируемом летательном средстве",
    "tr": "AirSense hatası. Mürettebatlı hava racı bilgileri alınamıyor",
    "zh": "AirSense功能异常，无法接收附近载人飞机信息"
  },
  "fpv_tip_0x16100024": {
    "de": "Kompassstörung. Start nicht möglich. Fluggerät von der Störquelle wegbewegen oder Kompass kalibrieren.",
    "en": "Compass interference. Unable to take off. Move away from source of interference or calibrate compass",
    "es": "Interferencias en la brújula. No se puede despegar. Aléjese de la fuente de interferencia o calibre la brújula",
    "fr": "Interférences avec le compas. Impossible de décoller. Éloignez-vous de la source des interférences ou étalonnez le compas",
    "ja": "コンパスの干渉。離陸できません。干渉源から離れるかコンパス キャリブレーションを行ってください",
    "ko": "콤파스 간섭. 이륙 불가. 간섭원에서 멀어지거나 콤파스 캘리브레이션 필요",
    "ru": "Помехи в работе компаса. Не удается выполнить взлет. Отдалитесь от источника помех или откалибруйте компас",
    "tr": "Pusula paraziti. Kalkış yapılamıyor. Parazit kaynağından uzaklaşın veya pusulanın kalibrasyonunu yapın",
    "zh": "无法起飞：指南针受到干扰，请远离干扰或校准指南针"
  },
  "fpv_tip_0x16100025": {
    "ar": "",
    "de": "Hot Swapping wird für das RTK-Modul nicht unterstützt. Start nicht möglich.",
    "en": "Hot swapping not supported for RTK module. Unable to take off",
    "es": "El intercambio en caliente no es compatible con el módulo RTK. No se puede despegar",
    "fr": "Le remplacement à chaud n'est pas pris en charge pour le module RTK. Impossible de décoller",
    "it": "",
    "ja": "ホットスワップはRTKモジュールではサポートされていません。離陸できません",
    "ko": "RTK 모듈에서는 핫 스와핑이 지원되지 않습니다. 이륙 불가",
    "pt": "",
    "ru": "Горячая замена не поддерживается для модуля RTK. Не удается выполнить взлет",
    "tr": "RTK modülü için çalışırken değiştirme desteklenmiyor. Kalkış yapılamıyor",
    "zh": "无法起飞：RTK模块不支持热插拔"
  },
  "fpv_tip_0x16100025_in_the_sky": {
    "ar": "",
    "de": "RTK-Fehler. Umgehend landen.",
    "en": "RTK error. Land promptly",
    "es": "Error de RTK. Aterrice rápidamente",
    "fr": "Erreur RTK. Atterrissez immédiatement",
    "it": "",
    "ja": "RTKエラーです。直ちに着陸させてください",
    "ko": "RTK 오류. 즉시 착륙하세요",
    "pt": "",
    "ru": "Ошибка RTK. Немедленно выполните посадку",
    "tr": "RTK hatası. Derhal iniş yapın",
    "zh": "RTK异常，请尽快降落"
  },
  "fpv_tip_0x16100026": {
    "de": "Start nicht möglich. RTK-Modul eines Fremdherstellers erkannt",
    "en": "Unable to take off. Non-DJI RTK module detected",
    "es": "No se puede despegar. Módulo RTK no DJI detectado",
    "fr": "Impossible de décoller. Module RTK autre que DJI détecté",
    "ja": "離陸できません。DJI以外のRTKモジュールが検出されました",
    "ko": "이륙 불가. 비 DJI RTK 모듈이 감지되었습니다",
    "ru": "Не удается выполнить взлет. Обнаружен модуль RTK, не являющийся продуктом DJI",
    "tr": "Kalkış yapılamıyor. DJI marka olmayan RTK modülü tespit edildi",
    "zh": "无法起飞：检测到非官方RTK模块"
  },
  "fpv_tip_0x16100026_in_the_sky": {
    "de": "RTK-Modul eines Fremdherstellers erkannt",
    "en": "Non-DJI RTK module detected",
    "es": "Módulo RTK no DJI detectado",
    "fr": "Module RTK autre que DJI détecté",
    "ja": "DJI以外のRTKモジュールが検出されました",
    "ko": "비 DJI RTK 모듈이 감지되었습니다",
    "ru": "Обнаружен модуль RTK, не являющийся продуктом DJI",
    "tr": "DJI marka olmayan RTK modülü tespit edildi",
    "zh": "检测到非官方RTK模块"
  },
  "fpv_tip_0x16100027": {
    "ar": "",
    "de": "Fehler: Flugregler. Start nicht möglich",
    "en": "Flight control system error. Unable to take off",
    "es": "Error en el sistema de control de vuelo. No se puede despegar",
    "fr": "Erreur du système de contrôle du vol. Impossible de décoller",
    "it": "",
    "ja": "フライトコントロールシステムのエラー。離陸できません",
    "ko": "비행 제어 시스템 오류. 이륙 불가",
    "pt": "",
    "ru": "Ошибка системы управления полетом. Не удается выполнить взлет",
    "tr": "Uçuş kontrol sistemi hatası. Kalkış yapılamıyor",
    "zh": "无法起飞：飞控系统异常"
  },
  "fpv_tip_0x16100027_in_the_sky": {
    "ar": "",
    "de": "Fehler: Flugregler. Umgehend landen",
    "en": "Flight control system error. Land promptly",
    "es": "Error en el sistema de control de vuelo. Aterriza rápidamente",
    "fr": "Erreur du système de contrôle du vol. Atterrissez immédiatement.",
    "it": "",
    "ja": "フライトコントロールシステムのエラー。直ちに着陸してください",
    "ko": "비행 제어 시스템 오류. 즉시 착륙해주세요.",
    "pt": "",
    "ru": "Ошибка системы управления полетом. Немедленно выполните посадку",
    "tr": "Uçuş kontrol sistemi hatası. Derhal iniş yapın",
    "zh": "飞控系统异常，请尽快降落"
  },
  "fpv_tip_0x16100029": {
    "de": "Seriennummer des Fluggeräts ungültig. Kann nicht abheben. An Händler vor Ort oder an DJI-Support wenden",
    "en": "Invalid aircraft serial number. Unable to take off. Contact your local dealer or DJI Support",
    "es": "Número de serie de la aeronave no válido. No se puede despegar. Ponte en contacto con el distribuidor local o la asistencia técnica de DJI",
    "fr": "N° de série appareil invalide. Impossible de décoller. Contactez votre revendeur local ou l\\'assistance technique DJI",
    "ja": "無効な機体シリアルナンバー。離陸できません。最寄りの代理店またはDJIサポートにお問い合わせください",
    "ko": "유효하지 않은 기체 시리얼 넘버. 이륙 불가. 현지 딜러 또는 DJI 고객지원으로 문의해주세요",
    "ru": "Неверный серийный номер дрона. Взлет невозможен. Обратитесь к местному представителю или в службу поддержки DJI",
    "tr": "Aracın seri numarası geçersiz. Kalkış yapamıyor. Yerel satıcınızla ya da DJI Destek ile iletişime geçin",
    "zh": "无法起飞：飞行器SN异常，请联系就近代理商或者大疆售后服务"
  },
  "fpv_tip_0x16100029_in_the_sky": {
    "de": "Ungültige Seriennummer des Fluggeräts . Zum Startpunkt zurückkehren oder landen.",
    "en": "Invalid aircraft serial number. Return to home or land",
    "es": "Número de serie de la aeronave no válido. Regresa al punto de origen o aterriza",
    "fr": "N° de série appareil invalide . Revenez au point de départ ou atterrissez",
    "ja": "無効な機体シリアルナンバー。帰還するか、着陸してください",
    "ko": "유효하지 않은 기체 시리얼 넘버 . RTH 진행 또는 착륙 필요",
    "ru": "Неверный серийный номер дрона . Вернитесь домой или приземлитесь",
    "tr": "Aracın seri numarası geçersiz. Eve geri dönün veya iniş yapın",
    "zh": "飞行器SN异常，请返航或降落"
  },
  "fpv_tip_0x1610002D": {
    "de": "GPS getrennt . Start nicht möglich. Fluggerät neu starten",
    "en": "GPS disconnected. Unable to take off. Restart aircraft",
    "es": "GPS no conectado. No se puede despegar. Reinicia la aeronave",
    "fr": "GPS déconnecté . Impossible de décoller. Redémarrez l’appareil",
    "ja": "GPSが切断されました。離陸できません。機体を再起動してください",
    "ko": "GPS 연결 끊김 . 이륙 불가. 기체 재시작 필요",
    "ru": "GPS отключена . Взлет невозможен. Перезагрузите дрон",
    "tr": "GPS bağlantısı kesildi. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：GPS断开连接，请重启飞行器"
  },
  "fpv_tip_0x1610002D_in_the_sky": {
    "de": "GPS getrennt . Zum Startpunkt zurückkehren oder landen.",
    "en": "GPS disconnected. Return to home or land",
    "es": "GPS no conectado. Regresa al punto de origen o aterriza",
    "fr": "GPS déconnecté . Revenez au point de départ ou atterrissez",
    "ja": "GPSが切断されました。帰還するか、着陸してください",
    "ko": "GPS 연결 끊김 . RTH 진행 또는 착륙 필요",
    "ru": "GPS отключена . Вернитесь домой или приземлитесь",
    "tr": "GPS bağlantısı kesildi. Eve geri dönün veya iniş yapın",
    "zh": "GPS断开连接,请返航或降落"
  },
  "fpv_tip_0x1610002F": {
    "de": "Fehler: Datenrekorder . Start nicht möglich. Fluggerät neu starten",
    "en": "Data recorder error. Unable to take off. Restart aircraft",
    "es": "Error de la grabadora de datos. No se puede despegar. Reinicia la aeronave",
    "fr": "Erreur enregistreur de données . Impossible de décoller. Redémarrez l\\'appareil",
    "ja": "データ記録機エラー。離陸できません。機体を再起動してください",
    "ko": "데이터 기록기 오류 . 이륙 불가. 기체 재시작 필요",
    "ru": "Ошибка записи данных . Взлет невозможен. Перезагрузите дрон",
    "tr": "Veri kaydedici hatası. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：数据记录仪异常，请重启飞行器"
  },
  "fpv_tip_0x1610002F_in_the_sky": {
    "de": "Datenfehler . Zum Startpunkt zurückkehren oder landen.",
    "en": "Data recorder error. Return to home or land",
    "es": "Error de la grabadora de datos. Regresa al punto de origen o aterriza",
    "fr": "Erreur enregistreur de données . Revenez au point de départ ou atterrissez",
    "ja": "データ記録機エラー。帰還するか、着陸してください",
    "ko": "데이터 기록기 오류 . RTH 진행 또는 착륙 필요",
    "ru": "Ошибка регистратора данных . Вернитесь домой или приземлитесь",
    "tr": "Veri kaydedici hatası. Eve geri dönün veya iniş yapın",
    "zh": "数据记录仪异常，请返航或降落"
  },
  "fpv_tip_0x16100030": {
    "de": "Fluggerät und Firmware-Version stimmen nicht überein . Start nicht möglich. An Händler vor Ort oder an DJI-Support wenden",
    "en": "Aircraft model and firmware version do not match. Unable to take off. Contact your local dealer or DJI Support.",
    "es": "El modelo de la aeronave y la versión del firmware no coinciden. No se puede despegar. Ponte en contacto con el distribuidor local o la asistencia técnica de DJI.",
    "fr": "Le modèle de l\\'appareil et la version du firmware ne correspondent pas . Impossible de décoller. Contactez votre revendeur local ou l\\'assistance technique DJI",
    "ja": "機体モデルとファームウェアバージョンが一致しません。離陸できません。最寄りの代理店またはDJIサポートにお問い合わせください",
    "ko": "기체 모델과 펌웨어 버전 불일치 . 이륙 불가. 현지 딜러 또는 DJI 고객지원으로 문의해주세요",
    "ru": "Модель дрона не совпадает с версией ПО . Взлет невозможен. Обратитесь к местному представителю или в службу поддержки DJI.",
    "tr": "Araç modeli ve yazılım sürümü eşleşmiyor. Kalkış yapamıyor. Yerel satıcınızla ya da DJI Destek ile iletişime geçin.",
    "zh": "无法起飞：飞行器机型与固件不匹配，请联系就近代理商或大疆售后服务"
  },
  "fpv_tip_0x16100030_in_the_sky": {
    "de": "Fluggerät und Firmware-Version stimmen nicht überein . Zum Startpunkt zurückkehren oder landen.",
    "en": "Aircraft model and firmware version do not match. Return to home or land",
    "es": "El modelo de la aeronave y la versión del firmware no coinciden. Regresa al punto de origen o aterriza",
    "fr": "Le modèle de l\\'appareil et la version du firmware ne correspondent pas . Revenez au point de départ ou atterrissez",
    "ja": "機体モデルとファームウェアバージョンが一致しません。帰還するか、着陸してください",
    "ko": "기체 모델과 펌웨어 버전 불일치 . RTH 진행 또는 착륙 필요",
    "ru": "Модель дрона не совпадает с версией ПО . Вернитесь домой или приземлитесь",
    "tr": "Araç modeli ve yazılım sürümü eşleşmiyor. Eve geri dönün veya iniş yapın",
    "zh": "飞行器机型与固件不匹配，请返航或降落"
  },
  "fpv_tip_0x16100031": {
    "en": "Unable to take off. Hook is not fully retracted. Make sure hook is retracted and secure",
    "zh": "无法起飞：空吊系统挂钩没有收到位，请将挂钩收到位"
  },
  "fpv_tip_0x16100032": {
    "en": "Winch cable wound in reverse direction. Unable to take off. Manually unwind cable before takeoff",
    "zh": "无法起飞：空吊系统线绳反绕，请整理线绳后起飞"
  },
  "fpv_tip_0x16100032_in_the_sky": {
    "en": "Winch cable wound in reverse direction. Do not operate winch system and land promptly to unwind cable",
    "zh": "空吊系统线绳反绕，请勿操作空吊并尽快降落理线"
  },
  "fpv_tip_0x16100033": {
    "de": "Registrierung mit Klarnamen wurde nicht durchgeführt. Vor dem Flug die Hilfedokumentation beachten und eine Registrierung mit Klarnamen anhand der Anweisungen abschließen",
    "en": "Real-name registration is not performed. View Help Documentation and complete real-name registration based on instructions before flight",
    "es": "No se ha realizado el registro de nombre real. Echa un vistazo a la documentación de ayuda y completa el registro de nombre real según las instrucciones antes del vuelo",
    "fr": "L'enregistrement du nom réel n'est pas effectué. Consultez les documents d'aide et effectuez l'enregistrement du nom réel en fonction des instructions avant le vol",
    "ja": "実名の登録が行われていません。飛行前に手順に従ってヘルプドキュメントを参照し、実名の登録を完了してください",
    "ko": "실명 등록이 수행되지 않았습니다. 비행 전에 지침에 따라 도움말 문서를 보고 실명 등록을 완료하세요.",
    "ru": "Регистрация с использованием настоящего имени не пройдена. Изучите справочную документацию и завершите регистрацию по инструкции перед началом полета",
    "tr": "Asıl adla kayıt işlemi gerçekleştirilmedi. Uçuş öncesinde Yardım Belgesine bakın ve talimatlara uygun olarak asıl adla kayıt işlemini tamamlayın",
    "zh": "飞机未完成实名登记，请点击查看帮助文档，按照指引完成实名登记后飞行"
  },
  "fpv_tip_0x16100034": {
    "de": "Registrierung mit Klarnamen wurde abgebrochen. Vor dem Flug die Hilfedokumentation beachten und eine Registrierung mit Klarnamen anhand der Anweisungen abschließen",
    "en": "Real-name registration is canceled. View Help Documentation and complete real-name registration based on instructions before flight",
    "es": "Se ha cancelado el registro de nombre real. Echa un vistazo a la documentación de ayuda y completa el registro de nombre real según las instrucciones antes del vuelo",
    "fr": "L'enregistrement du nom réel est annulé. Consultez les documents d'aide et effectuez l'enregistrement du nom réel en fonction des instructions avant le vol",
    "ja": "実名の登録がキャンセルされました。飛行前に手順に従ってヘルプドキュメントを参照し、実名の登録を完了してください",
    "ko": "실명 등록이 취소되었습니다. 비행 전에 지침에 따라 도움말 문서를 보고 실명 등록을 완료하세요.",
    "ru": "Регистрация с использованием настоящего имени отменена. Изучите справочную документацию и завершите регистрацию по инструкции перед началом полета",
    "tr": "Asıl adla kayıt işlemi iptal edildi. Uçuş öncesinde Yardım Belgesine bakın ve talimatlara uygun olarak asıl adla kayıt işlemini tamamlayın",
    "zh": "飞行器实名登记状态已注销，请点击查看帮助文档，按照指引完成实名登记后飞行"
  },
  "fpv_tip_0x1610003D": {
    "de": "Sensorsystem getrennt . Start nicht möglich. Fluggerät neu starten",
    "en": "Sensor system disconnected. Unable to take off. Restart aircraft",
    "es": "Sistema de sensores no conectado. No se puede despegar. Reinicia la aeronave",
    "fr": "Système de capteurs déconnecté . Impossible de décoller. Redémarrez l\\'appareil",
    "ja": "センサーシステム未接続。離陸できません。機体を再起動してください",
    "ko": "센서 시스템 연결 끊김 . 이륙 불가. 기체 재시작 필요",
    "ru": "Система датчиков отключена . Взлет невозможен. Перезагрузите дрон",
    "tr": "Sensör sistemi bağlantısı kesildi. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：传感器系统断开，请重启飞行器"
  },
  "fpv_tip_0x1610003D_in_the_sky": {
    "de": "Sensorsystem getrennt . Zum Startpunkt zurückkehren oder landen.",
    "en": "Sensor system disconnected. Return to home or land",
    "es": "Sistema de sensores no conectado. Regresa al punto de origen o aterriza",
    "fr": "Système de capteurs déconnecté . Revenez au point de départ ou atterrissez",
    "ja": "センサーシステム未接続。帰還するか、着陸してください",
    "ko": "센서 시스템 연결 끊김 . RTH 진행 또는 착륙 필요",
    "ru": "Системы датчиков отключена . Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi bağlantısı kesildi. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统断开，请返航或降落"
  },
  "fpv_tip_0x1610003E": {
    "ar": "",
    "de": "Start nicht möglich. Fernsteuerung wird kalibriert. Auf Abschluss der Kalibrierung warten",
    "en": "Unable to take off. Calibrating remote controller. Wait for calibration to complete",
    "es": "No se puede despegar. Calibrando el control remoto. Espere a que se complete la calibración",
    "fr": "Impossible de décoller. Étalonnage de la radiocommande. Attendez que l'étalonnage soit terminé",
    "it": "",
    "ja": "離陸できません。送信機のキャリブレーション中。キャリブレーションが完了するまでお待ちください",
    "ko": "이륙 불가. 조종기 캘리브레이션 중. 캘리브레이션이 완료될 때까지 기다려 주세요",
    "pt": "",
    "ru": "Не удается выполнить взлет. Калибровка пульта. Дождитесь завершения калибровки",
    "tr": "Kalkış yapılamıyor. Uzaktan kumanda kalibre ediliyor. Kalibre işleminin tamamlanmasını bekleyin",
    "zh": "无法起飞：遥控器校准中，请完成校准流程"
  },
  "fpv_tip_0x16100041": {
    "ar": "",
    "de": "Start nicht möglich. Steuerknüppel nicht zentriert. Fernsteuerung kalibrieren",
    "en": "Unable to take off. Control sticks off-centered. Calibrate remote controller",
    "es": "No se puede despegar. Las palancas de control están descentradas. Calibre el control remoto",
    "fr": "Impossible de décoller. Joysticks décentrés. Étalonner la radiocommande",
    "it": "",
    "ja": "離陸できません。操作スティックの中心がずれています。送信機をキャリブレーションしてください",
    "ko": "이륙 불가. 조종 스틱이 중앙에 있지 않습니다. 조종기 캘리브레이션",
    "pt": "",
    "ru": "Не удается выполнить взлет. Ручки управления смещены относительно центра. Выполните калибровку пульта",
    "tr": "Kalkış yapılamıyor. Kumanda çubukları merkez dışı. Uzaktan kumandayı kalibre edin",
    "zh": "无法起飞：摇杆不在中位，请校准遥控器"
  },
  "fpv_tip_0x16100042": {
    "ar": "",
    "de": "Start nicht möglich. Steuerknüppel-Hardwarefehler. DJI Support kontaktieren.",
    "en": "Unable to take off. Control stick hardware error. Contact DJI Support",
    "es": "No se puede despegar. Error de hardware de la palanca de control. Póngase en contacto con la Asistencia técnica de DJI",
    "fr": "Impossible de décoller. Erreur matérielle du joystick. Contactez le service client DJI",
    "it": "",
    "ja": "離陸できません。操作スティックのハードウェアエラー。DJIサポートまでお問い合わせください",
    "ko": "이륙 불가. 조종 스틱 하드웨어 오류. DJI 고객지원으로 문의하세요",
    "pt": "",
    "ru": "Не удается выполнить взлет. Аппаратная ошибка ручки управления. Свяжитесь со службой поддержки DJI",
    "tr": "Kalkış yapılamıyor. Kontrol çubuğu donanım hatası. DJI Destek ekibiyle iletişime geçin",
    "zh": "无法起飞：摇杆硬件异常，请联系售后"
  },
  "fpv_tip_0x16100042_in_the_sky": {
    "ar": "",
    "de": "Steuerknüppel-Hardwarefehler. Umgehend landen. DJI Support kontaktieren.",
    "en": "Control stick hardware error. Land promptly. Contact DJI Support",
    "es": "Error de hardware de la palanca de control. Aterriza rápidamente. Póngase en contacto con la Asistencia técnica de DJI",
    "fr": "Erreur matérielle du joystick. Atterrissez immédiatement. Contactez le service client DJI",
    "it": "",
    "ja": "操作スティックのハードウェアエラー。直ちに着陸してください。DJIサポートまでお問い合わせください",
    "ko": "조종 스틱 하드웨어 오류. 즉시 착륙하세요. DJI 고객지원으로 문의하세요",
    "pt": "",
    "ru": "Аппаратная ошибка ручки управления. Немедленно выполните посадку. Свяжитесь со службой поддержки DJI",
    "tr": "Kontrol çubuğu donanım hatası. Derhal iniş yapın. DJI Destek ekibiyle iletişime geçin",
    "zh": "摇杆硬件异常，请尽快降落，并联系售后"
  },
  "fpv_tip_0x1610004A": {
    "de": "Fehler: Sensorsystem . Start nicht möglich. Fluggerät neu starten",
    "en": "Sensor system error. Unable to take off. Restart aircraft",
    "es": "Error del sistema de sensores. No se puede despegar. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs . Impossible de décoller. Redémarrez l\\'appareil",
    "ja": "センサーシステムエラー。離陸できません。機体を再起動してください",
    "ko": "센서 시스템 오류 . 이륙 불가. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков . Взлет невозможен. Перезагрузите дрон",
    "tr": "Sensör sistemi hatası. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：传感器系统异常，请重启飞行器"
  },
  "fpv_tip_0x1610004A_in_the_sky": {
    "de": "Fehler: Sensorsystem . Zum Startpunkt zurückkehren oder landen.",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur système de capteurs . Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか、着陸してください",
    "ko": "센서 시스템 오류 . RTH 진행 또는 착륙 필요",
    "ru": "Ошибка системы датчиков . Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常，请返航或降落"
  },
  "fpv_tip_0x1610004B": {
    "de": "Fehler: Sensorsystem. Start nicht möglich. Fluggerät neu starten",
    "en": "Sensor system error. Unable to take off. Restart aircraft",
    "es": "Error del sistema de sensores. No se puede despegar. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Impossible de décoller. Redémarrez l\\'appareil",
    "ja": "センサーシステムエラー。離陸できません。機体を再起動してください",
    "ko": "센서 시스템 오류. 이륙 불가. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Невозможно выполнить взлет. Перезагрузите дрон",
    "tr": "Sensör sistemi hatası. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：传感器系统异常，请重启飞行器"
  },
  "fpv_tip_0x1610004B_in_the_sky": {
    "de": "Fehler: Sensorsystem. Zum Startpunkt zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur système capteur. Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか着陸して下さい",
    "ko": "센서 시스템 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка системы датчиков. Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常，请返航或降落"
  },
  "fpv_tip_0x1610004C": {
    "ar": "",
    "de": "",
    "en": "Unable to take off. Remote Controller Calibration Required",
    "es": "",
    "fr": "",
    "it": "",
    "ja": "",
    "ko": "",
    "pt": "",
    "ru": "",
    "tr": "",
    "zh": "无法起飞：遥控器摇杆需要校准"
  },
  "fpv_tip_0x1610004D": {
    "de": "Datenfehler: Flugregler. Kann nicht abheben. Fluggerät neu starten",
    "en": "Flight controller data error. Unable to take off. Restart aircraft",
    "es": "Error de datos del controlador de vuelo. No se puede despegar. Reinicia la aeronave",
    "fr": "Erreur de données du contrôleur du vol. Impossible de décoller. Redémarrez l\\'appareil",
    "ja": "フライトコントローラー データエラー。離陸できません。機体を再起動してください",
    "ko": "비행 컨트롤러 데이터 오류. 이륙 불가. 기체 재시작 필요",
    "ru": "Ошибка данных полетного контроллера. Взлет невозможен. Перезагрузите дрон",
    "tr": "Uçuş kumandası veri hatası. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：飞控数据异常，请重启飞行器"
  },
  "fpv_tip_0x1610004D_in_the_sky": {
    "de": "Datenfehler . Zum Startpunkt zurückkehren oder landen.",
    "en": "Flight controller data error. Return to home or land",
    "es": "Error de datos del controlador de vuelo. Regresa al punto de origen o aterriza",
    "fr": "Erreur données du contrôleur de vol . Revenez au point de départ ou atterrissez",
    "ja": "フライトコントローラー データエラー。帰還するか、着陸してください",
    "ko": "비행 컨트롤러 데이터 오류 . RTH 진행 또는 착륙 필요",
    "ru": "Ошибка данных полетного контроллера . Вернитесь домой или приземлитесь",
    "tr": "Uçuş kumandası veri hatası. Eve geri dönün veya iniş yapın",
    "zh": "飞控数据异常，请返航或降落"
  },
  "fpv_tip_0x1610004E": {
    "de": "Nicht genug Akkus installiert. Kann nicht abheben. Vor dem Start zwei Akkus einlegen",
    "en": "Not enough batteries installed. Unable to take off. Insert two batteries before taking off",
    "es": "No hay suficientes baterías instaladas. No se puede despegar. Instala dos baterías antes de despegar",
    "fr": "Pas assez de batteries installées. Impossible de décoller. Installez deux batteries avant de décoller",
    "ja": "装着バッテリーが不足。離陸できません。バッテリーを2つ取り付けてから離陸してください",
    "ko": "배터리 개수 부족. 이륙 불가. 이륙하기 전 배터리 2개를 장착해야 함",
    "ru": "Не хватает батарей. Взлет невозможен. Установите две батареи для взлета",
    "tr": "Yeterli pil takılmamış. Kalkış yapamıyor. Kalkıştan önce iki pil takın",
    "zh": "无法起飞：电池数量过少，请插入两块电池再起飞"
  },
  "fpv_tip_0x1610004E_in_the_sky": {
    "de": "Nicht genügend Akkus. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "Insufficient batteries. Return to home or land promptly",
    "es": "No hay suficientes baterías. Regrese al punto de origen o aterrice rápidamente",
    "fr": "Pas assez de batteries. Retournez au point de départ ou atterrissez rapidement",
    "ja": "バッテリー不足。直ちに帰還するか、着陸してください",
    "ko": "배터리 부족. 즉시 리턴 투 홈을 실행하거나 착륙하세요",
    "ru": "Недостаточный заряд АКБ. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "Yetersiz pil. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "电池数量过少，请尽快返航或降落"
  },
  "fpv_tip_0x1610004F": {
    "de": "Batterieauthentifizierung fehlgeschlagen. Kann nicht abheben. Durch DJI-Akku ersetzen",
    "en": "Battery authentication failed. Unable to take off. Replace with DJI battery",
    "es": "Error de autenticación de la batería. No se puede despegar. Reemplázala por una batería de DJI",
    "fr": "L\\'authentification de la batterie a échoué. Impossible de décoller. Remplacez-la avec une batterie DJI",
    "ja": "バッテリーの認証に失敗しました。離陸できません。DJIバッテリーに交換してください",
    "ko": "배터리 인증 실패. 이륙 불가. 표준 DJI 배터리로 교체해야 함",
    "ru": "Не удалось опознать батарею. Взлет невозможен. Замените ее на аккумулятор DJI",
    "tr": "Pil kimlik doğrulaması başarısız. Kalkış yapamıyor. DJI pili ile değiştirin",
    "zh": "无法起飞：电池认证失败，请更换DJI官方电池"
  },
  "fpv_tip_0x1610004F_in_the_sky": {
    "de": "Batterieauthentifizierung fehlgeschlagen. Durch Standard-DJI-Akku ersetzen",
    "en": "Battery authentication failed. Replace with standard DJI battery",
    "es": "Error de autenticación de la batería. Reemplázala por una batería estándar de DJI",
    "fr": "L\\'authentification de la batterie a échoué. Remplacez-la avec une batterie DJI standard",
    "ja": "バッテリーの認証に失敗しました。標準のDJIバッテリーと交換してください",
    "ko": "배터리 인증 실패. 표준 DJI 배터리로 교체해야 함",
    "ru": "Не удалось опознать батарею. Замените ее на стандартный аккумулятор DJI",
    "tr": "Pil kimlik doğrulaması başarısız. Standart bir DJI pili ile değiştirin",
    "zh": "电池认证失败，请更换DJI官方电池"
  },
  "fpv_tip_0x16100051": {
    "de": "Großer Spannungsunterschied zwischen Akkus. Start nicht möglich. Akkus durch neue mit ähnlicher Kapazität ersetzen",
    "en": "Large voltage difference between batteries. Unable to take off. Replace batteries with new ones of a similar capacity",
    "es": "Gran diferencia de voltaje entre las baterías. No se puede despegar. Reemplázalas por baterías nuevas con una capacidad similar",
    "fr": "Différence de tension entre les batteries importante. Décollage impossible. Remplacez-les avec des batteries dont le nombre de cycles est similaire",
    "ja": "バッテリーの電圧差が大きすぎます。離陸できません。同程度の容量の新品のバッテリーと交換してください",
    "ko": "배터리 간 큰 전압 차이. 이륙할 수 없습니다. 비슷한 용량의 신품 배터리로 교체하세요",
    "ru": "Большая разница напряжения между аккумуляторами. Невозможно выполнить взлет. Замените аккумуляторы на новые с аналогичной емкостью",
    "tr": "Piller arasında yüksek voltaj farkı. Kalkış yapamıyor. Pilleri aynı kapasitedeki yeni pillerle değiştirin",
    "zh": "无法起飞：两块电池间压差过大，更换电量相近的电池组继续飞行"
  },
  "fpv_tip_0x16100051_in_the_sky": {
    "de": "Großer Spannungsunterschied zwischen Akkus. Zum Startpunkt zurückkehren oder landen. Akkus durch neue mit ähnlicher Kapazität ersetzen und erneut versuchen",
    "en": "Large voltage difference between batteries. Return to home or land. Replace batteries with new ones of a similar capacity and try again",
    "es": "Gran diferencia de voltaje entre las baterías. Regresa al punto de origen o aterriza. Reemplázalas por baterías nuevas con una capacidad similar y vuelve a intentarlo",
    "fr": "Différence de tension entre les batteries importante. Revenez au point de départ ou atterrissez. Remplacez les batteries avec des nouvelles d\\'une capacité similaire et réessayez",
    "ja": "バッテリーの電圧差が大きすぎます。帰還するか着陸してください。バッテリーを同程度の容量の新品のものと交換して再試行してください",
    "ko": "배터리 간 큰 전압 차이. 홈으로 돌아가거나 착륙하세요. 비슷한 용량의 신품 배터리로 교체하고 다시 시도하세요",
    "ru": "Большая разница напряжения между аккумуляторами. Вернитесь домой или приземлитесь. Замените аккумуляторы на новые с аналогичной емкостью и повторите попытку",
    "tr": "Piller arasında yüksek voltaj farkı. Eve geri dönün veya iniş yapın. Pilleri aynı kapasitedeki yeni pillerle değiştirin ve tekrar deneyin",
    "zh": "两块电池间压差过大，请返航或降落，更换电量相近的电池组继续飞行"
  },
  "fpv_tip_0x16100053": {
    "de": "Firmware-Versionen des Fluggerätemoduls stimmen nicht überein. Kann nicht abheben. Auf neuesten Firmware-Versionen aktualisieren",
    "en": "Aircraft module firmware versions do not match. Unable to take off. Update to latest firmware versions.",
    "es": "Las versiones del firmware del módulo de la aeronave no coinciden. No se puede despegar. Actualiza a las versiones del firmware más recientes.",
    "fr": "Les versions des firmwares des modules de l\\'appareil ne correspondent pas. Impossible de décoller. Mettez-les à jour vers les versions les plus récentes",
    "ja": "機体モジュールのファームウェアバージョンが不一致。離陸できません。最新のファームウェアバージョンに更新してください。",
    "ko": "기체 모듈 펌웨어 버전 불일치. 이륙 불가. 최신 펌웨어 버전으로 업데이트 필요",
    "ru": "Версии ПО модулей дрона не совпадают. Взлет невозможен. Установите последние версии ПО",
    "tr": "Araç modül yazılımı sürümleri eşleşmiyor. Kalkış yapamıyor. En güncel yazılım sürümlerine güncelleyin.",
    "zh": "无法起飞：飞行器模块间固件不匹配，请升级固件版本"
  },
  "fpv_tip_0x16100053_in_the_sky": {
    "de": "Firmware-Versionen des Fluggerätemoduls stimmen nicht überein. Update auf die neuesten Firmware-Versionen",
    "en": "Aircraft module firmware versions do not match. Update to latest firmware versions",
    "es": "Las versiones del firmware del módulo de la aeronave no coinciden. Actualiza a las versiones del firmware más recientes",
    "fr": "Les versions des firmwares des modules de l\\'appareil ne correspondent pas. Mettez à jour vers les versions de firmwares les plus récentes",
    "ja": "機体モジュールのファームウェアバージョンが不一致。最新のファームウェアバージョンに更新してください",
    "ko": "기체 모듈 펌웨어 버전 불일치. 최신 펌웨어 버전으로 업데이트 필요",
    "ru": "Версии ПО модулей дрона не совпадают. Установите последние версии",
    "tr": "Araç modül yazılımı sürümleri eşleşmiyor. En güncel yazılım sürümlerine güncelleyin",
    "zh": "飞行器模块间固件不匹配，请升级固件版本"
  },
  "fpv_tip_0x16100054": {
    "de": "Fehler: Gimbal . Kann nicht abheben. Bitte DJI-Support kontaktieren.",
    "en": "Gimbal error. Unable to take off. Contact DJI Support",
    "es": "Error del estabilizador. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur de nacelle . Impossible de décoller. Contactez l\\'assistance technique DJI",
    "ja": "ジンバルエラー。離陸できません。DJIサポートまでお問い合わせください",
    "ko": "짐벌 오류 . 이륙 불가. DJI 고객지원으로 문의해주세요",
    "ru": "Ошибка стабилизатора . Взлет невозможен. Обратитесь в службу поддержки DJI",
    "tr": "Gimbal hatası. Kalkış yapamıyor. DJI Destek ile iletişime geçin",
    "zh": "无法起飞：云台异常，请联系大疆售后服务"
  },
  "fpv_tip_0x16100054_in_the_sky": {
    "de": "Gimbalfehler . Zum Startpunkt zurückkehren oder landen.",
    "en": "Gimbal error. Return to home or land",
    "es": "Error del estabilizador. Regresa al punto de origen o aterriza",
    "fr": "Erreur nacelle . Revenez au point de départ ou atterrissez",
    "ja": "ジンバルエラー。帰還するか、着陸してください",
    "ko": "짐벌 오류 . RTH 진행 또는 착륙 필요",
    "ru": "Ошибка стабилизатора . Вернитесь домой или приземлитесь",
    "tr": "Gimbal hatası. Eve geri dönün veya iniş yapın",
    "zh": "云台异常，返航或降落"
  },
  "fpv_tip_0x16100055": {
    "de": "Gimbal-Fehler. Start nicht möglich. DJI Support kontaktieren",
    "en": "Gimbal error. Unable to take off. Contact DJI Support",
    "es": "Error del estabilizador. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur de nacelle. Impossible de décoller. Contactez l\\'assistance technique DJI",
    "ja": "ジンバルエラー。離陸できません。DJIサポートにお問い合わせください",
    "ko": "짐벌 오류. 이륙 불가. DJI 고객지원으로 문의",
    "ru": "Ошибка стабилизатора. Невозможно выполнить взлет. Обратитесь в службу поддержки DJI",
    "tr": "Gimbal hatası. Kalkış yapamıyor. DJI Destek ile iletişime geçin",
    "zh": "无法起飞：云台异常，请联系大疆售后服务"
  },
  "fpv_tip_0x16100055_in_the_sky": {
    "de": "Gimbal-Fehler. Zum Startpunkt zurückkehren oder landen",
    "en": "Gimbal error. Return to home or land",
    "es": "Error del estabilizador. Regresa al punto de origen o aterriza",
    "fr": "Erreur nacelle. Revenez au point de départ ou atterrissez",
    "ja": "ジンバルエラー。帰還するか着陸してください",
    "ko": "짐벌 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка стабилизатора. Вернитесь домой или приземлитесь",
    "tr": "Gimbal hatası. Eve geri dönün veya iniş yapın",
    "zh": "云台异常，返航或降落"
  },
  "fpv_tip_0x16100056": {
    "de": "Gimbal-Fehler. Start nicht möglich. DJI Support kontaktieren",
    "en": "Gimbal error. Unable to take off. Contact DJI Support",
    "es": "Error del estabilizador. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur de nacelle. Impossible de décoller. Contactez l\\'assistance technique DJI",
    "ja": "ジンバルエラー。離陸できません。DJIサポートにお問い合わせください",
    "ko": "짐벌 오류. 이륙 불가. DJI 고객지원으로 문의",
    "ru": "Ошибка стабилизатора. Невозможно выполнить взлет. Обратитесь в службу поддержки DJI",
    "tr": "Gimbal hatası. Kalkış yapamıyor. DJI Destek ile iletişime geçin",
    "zh": "无法起飞：云台异常，请联系大疆售后服务"
  },
  "fpv_tip_0x16100056_in_the_sky": {
    "de": "Gimbal-Fehler. Zum Startpunkt zurückkehren oder landen",
    "en": "Gimbal error. Return to home or land",
    "es": "Error del estabilizador. Regresa al punto de origen o aterriza",
    "fr": "Erreur nacelle. Revenez au point de départ ou atterrissez",
    "ja": "ジンバルエラー。帰還するか着陸してください",
    "ko": "짐벌 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка стабилизатора. Вернитесь домой или приземлитесь",
    "tr": "Gimbal hatası. Eve geri dönün veya iniş yapın",
    "zh": "云台异常，返航或降落"
  },
  "fpv_tip_0x16100057": {
    "de": "Gimbal-Fehler. Start nicht möglich. DJI Support kontaktieren",
    "en": "Gimbal error. Unable to take off. Contact DJI Support",
    "es": "Error del estabilizador. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur de nacelle. Impossible de décoller. Contactez l\\'assistance technique DJI",
    "ja": "ジンバルエラー。離陸できません。DJIサポートにお問い合わせください",
    "ko": "짐벌 오류. 이륙 불가. DJI 고객지원으로 문의",
    "ru": "Ошибка стабилизатора. Невозможно выполнить взлет. Обратитесь в службу поддержки DJI",
    "tr": "Gimbal hatası. Kalkış yapamıyor. DJI Destek ile iletişime geçin",
    "zh": "无法起飞：云台异常，请联系大疆售后服务"
  },
  "fpv_tip_0x16100057_in_the_sky": {
    "de": "Gimbal-Fehler. Zum Startpunkt zurückkehren oder landen",
    "en": "Gimbal error. Return to home or land",
    "es": "Error del estabilizador. Regresa al punto de origen o aterriza",
    "fr": "Erreur nacelle. Revenez au point de départ ou atterrissez",
    "ja": "ジンバルエラー。帰還するか着陸してください",
    "ko": "짐벌 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка стабилизатора. Вернитесь домой или приземлитесь",
    "tr": "Gimbal hatası. Eve geri dönün veya iniş yapın",
    "zh": "云台异常，返航或降落"
  },
  "fpv_tip_0x16100058": {
    "de": "Gimbal-Firmware wird aktualisiert. Start nicht möglich ...",
    "en": "Gimbal firmware updating. Unable to take off...",
    "es": "Actualizando el firmware del estabilizador. No se puede despegar...",
    "fr": "Mise à jour du firmware de la nacelle en cours. Impossible de décoller .",
    "ja": "ジンバル ファームウェア更新中。離陸できません...",
    "ko": "짐벌 펌웨어 업데이트 중. 이륙 불가",
    "ru": "ПО стабилизатора обновляется. Взлет невозможен",
    "tr": "Gimbal yazılımı güncelleniyor. Kalkış yapamıyor…",
    "zh": "无法起飞：云台升级中，请稍后"
  },
  "fpv_tip_0x16100058_in_the_sky": {
    "de": "Aktualisierung der Gimbal-Firmware wird durchgeführt",
    "en": "Gimbal firmware update in progress",
    "es": "Actualización del firmware del estabilizador en curso",
    "fr": "Mise à jour du firmware de la nacelle en cours",
    "ja": "ジンバル ファームウェアを更新中",
    "ko": "짐벌 펌웨어 업데이트 진행 중",
    "ru": "Идет обновление ПО стабилизатора",
    "tr": "Gimbal yazılım güncellemesi devam ediyor",
    "zh": "云台升级中，请稍后"
  },
  "fpv_tip_0x1610005D": {
    "de": "Kalibrierung der IMU erfolgreich. Start nicht möglich. Fluggerät muss neu gestartet werden",
    "en": "IMU calibration successful. Unable to take off. Restarting aircraft required",
    "es": "IMU calibrada. No se puede despegar. Es necesario reiniciar la aeronave",
    "fr": "Étalonnage de l\\'IMU réussi. Impossible de décoller. Redémarrage de l\\'appareil requis",
    "ja": "IMUキャリブレーションが成功しました。離陸できません。機体の再起動が必要です",
    "ko": "IMU 캘리브레이션 성공. 이륙 불가. 기체 재시작 필요",
    "ru": "IMU откалиброван. Взлет невозможен. Перезагрузите дрон",
    "tr": "IMU kalibrasyonu başarılı. Kalkış yapamıyor. Aracın yeniden başlatılması gerekli",
    "zh": "无法起飞：IMU 校准成功，需重启飞行器"
  },
  "fpv_tip_0x1610005D_in_the_sky": {
    "de": "Kalibrierungsfehler: IMU. Zum Startpunkt zurückkehren oder landen",
    "en": "IMU calibration error. Return to home or land",
    "es": "Error de calibración de la IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur d\\'étalonnage IMU. Revenez au point de départ ou atterrissez",
    "ja": "IMUキャリブレーションエラー。帰還するか、着陸してください",
    "ko": "IMU 캘리브레이션 오류. RTH 진행 또는 착륙 필요",
    "ru": "Ошибка калибровки IMU. Вернитесь домой или приземлитесь",
    "tr": "IMU kalibrasyon hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU 校准异常，请返航或降落"
  },
  "fpv_tip_0x1610005E": {
    "de": "Fluggerät hat sich beim Start gedreht. Start fehlgeschlagen. Bitte Propeller überprüfen",
    "en": "Aircraft rolled during takeoff. Unable to take off. Check whether propellers were installed correctly",
    "es": "La aeronave se ha volcado durante el despegue. No se puede despegar. Comprueba si las hélices se han instalado correctamente",
    "fr": "L\\'appareil s\\'est retourné pendant le décollage. Le décollage a échoué. Vérifiez si les hélices ont été installées correctement",
    "ja": "離陸中に機体が横転。離陸できません。プロペラが正しく取り付けられているか確認してください",
    "ko": "이륙 중 기체 전복. 이륙 실패. 프로펠러가 올바르게 설치되었는지 확인해야 함",
    "ru": "Дрон наклонился при взлете. Не удалось взлететь. Проверьте, установлены ли пропеллеры корректно",
    "tr": "Araç kalkışta yuvarlandı. Kalkış yapamıyor. Pervanelerin düzgün takılıp takılmadığını kontrol edin",
    "zh": "无法起飞：起飞时侧翻，请检查桨叶安装是否正确"
  },
  "fpv_tip_0x1610005E_in_the_sky": {
    "de": "Fluggerät hat sich beim Start gedreht. Bitte Propeller überprüfen",
    "en": "Aircraft rolled during takeoff. Check whether propellers are installed correctly",
    "es": "La aeronave se ha volcado durante el despegue. Comprueba si las hélices se han instalado correctamente",
    "fr": "L\\'appareil a fait un tonneau pendant le décollage. Vérifiez si les hélices ont été installées correctement",
    "ja": "離陸中に機体が横転。プロペラが正しく取り付けられているか確認してください",
    "ko": "이륙 중 기체 전복. 프로펠러가 올바르게 설치되었는지 확인해야 함",
    "ru": "Дрон наклонился при взлете. Проверьте, установлены ли пропеллеры корректно",
    "tr": "Araç kalkışta yuvarlandı. Pervanelerin düzgün takılıp takılmadığını kontrol edin",
    "zh": "起飞时侧翻，请检查桨叶安装是否正确"
  },
  "fpv_tip_0x1610005F": {
    "de": "Motor blockiert. Start nicht möglich. Fluggerät ausschalten und Motor prüfen",
    "en": "Motor stalled. Unable to take off. Power off aircraft and check whether motor can rotate freely",
    "es": "Motor atascado. No se puede despegar. Apaga la aeronave y comprueba si el motor puede rotar con libertad",
    "fr": "Le moteur a calé. Impossible de décoller. Éteignez l\\'appareil et vérifiez si le moteur peut tourner librement",
    "ja": "モーター停止。離陸できません。機体の電源を切り、モーターが制限なく回転するか確認してください",
    "ko": "모터 정지됨. 이륙 불가. 기체의 전원을 끄고 모터가 자유롭게 회전할 수 있는지 확인해야 함",
    "ru": "Мотор заклинило. Взлет невозможен. Выключите дрон и убедитесь, что мотор поворачивается свободно",
    "tr": "Motor durdu. Kalkış yapamıyor. Aracı kapatın ve motorun rahatça dönüp dönmediğini kontrol edin",
    "zh": "无法起飞：电机堵转，关机后检查电机能否自由旋转"
  },
  "fpv_tip_0x1610005F_in_the_sky": {
    "de": "Motor blockiert. Sofort landen. Schalten Sie das Fluggerät aus und prüfen Sie, ob sich der Motor frei drehen kann .",
    "en": "Motor stalled. Land promptly. Power off airfcraft and check whether motor can rotate freely",
    "es": "Motor atascado. Aterriza rápidamente. Apaga la aeronave y comprueba si el motor puede rotar con libertad",
    "fr": "Le moteur a calé. Atterrissez immédiatement. Éteignez l\\'appareil et vérifiez si le moteur peut tourner librement",
    "ja": "モーター停止。直ちに着陸してください。機体の電源を切り、モーターが制限なく回転するか確認してください",
    "ko": "모터 정지됨. 즉시 착륙 필요. 기체의 전원을 끄고 모터가 자유롭게 회전할 수 있는지 확인해야 함",
    "ru": "Мотор заклинило. Приземлитесь немедленно. Выключите дрон и убедитесь, что мотор поворачивается свободно",
    "tr": "Motor durdu. Hemen iniş yapın. Aracı kapatın ve motorun rahatça dönüp dönmediğini kontrol edin",
    "zh": "电机堵转，请降落，在关机后检查电机能否自由旋转"
  },
  "fpv_tip_0x16100060": {
    "de": "Motordrehzahlfehler. Start nicht möglich. Fluggerät neu starten",
    "en": "Motor rotation speed error. Unable to take off. Restart aircraft",
    "es": "Error de velocidad de rotación del motor. No se puede despegar. Reinicia la aeronave",
    "fr": "Erreur de vitesse de rotation du moteur. Impossible de décoller. Redémarrez l\\'appareil",
    "ja": "モーター回転速度エラー。離陸できません。機体を再起動してください",
    "ko": "모터 회전 속도 오류. 이륙 불가. 기체 재시작 필요",
    "ru": "Ошибка скорости вращения мотора. Взлет невозможен. Перезагрузите дрон",
    "tr": "Motor dönüş hızı hatası. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：电机转速异常，请重启飞行器"
  },
  "fpv_tip_0x16100060_in_the_sky": {
    "de": "Motordrehzahlfehler. Fluggerät landen und neu starten",
    "en": "Motor rotation speed error. Land and restart aircraft",
    "es": "Error de velocidad de rotación del motor. Aterriza y reinicia la aeronave",
    "fr": "Erreur de vitesse de rotation du moteur. Atterrissez et redémarrez l\\'appareil",
    "ja": "モーター回転速度エラー。着陸して機体を再起動してください",
    "ko": "모터 회전 속도 오류. 착륙하고 기체 재시작 필요",
    "ru": "Ошибка скорости вращения мотора. Приземлитесь и перезагрузите дрон",
    "tr": "Motor dönüş hızı hatası. Aracı indirin ve yeniden başlatın",
    "zh": "电机转速异常，请停桨后重启飞行器"
  },
  "fpv_tip_0x16100061": {
    "de": "Motor im Leerlauf. Start nicht möglich. Bitte Propeller überprüfen",
    "en": "Motor idle. Unable to take off. Check whether propellers are detached or installed incorrectly",
    "es": "Motor inactivo. No se puede despegar. Comprueba si las hélices se han desconectado o instalado de forma incorrecta",
    "fr": "Moteur inactif. Impossible de décoller. Vérifiez si les hélices sont détachées ou mal installées",
    "ja": "モーターアイドル状態。離陸できません。プロペラが外れたり、間違って取り付けられていないか確認してください",
    "ko": "모터 공회전. 이륙 불가. 프로펠러가 분리되었거나 제대로 설치되지 않았는지 확인해야 함",
    "ru": "Мотор неподвижен. Взлет невозможен. Проверьте установку пропеллеров",
    "tr": "Motor boşta. Kalkış yapamıyor. Pervanelerin doğru çıkarıldığından veya takıldığından emin olun",
    "zh": "无法起飞：电机空转，无法起飞，请检查桨叶是否脱落或未正确安装桨叶"
  },
  "fpv_tip_0x16100061_in_the_sky": {
    "de": "Motor im Leerlauf. Kann nicht abheben. Bitte Propeller überprüfen. .",
    "en": "Motor idle. Unable to take off. Check whether propellers are detached or installed incorrectly",
    "es": "Motor inactivo. No se puede despegar. Comprueba si las hélices se han desconectado o instalado de forma incorrecta",
    "fr": "Moteur inactif. Impossible de décoller. Vérifiez si les hélices sont détachées ou mal installées",
    "ja": "モーターアイドル状態。離陸できません。プロペラが外れたり、間違って取り付けられていないか確認してください",
    "ko": "모터 공회전. 이륙 불가. 프로펠러가 분리되었거나 제대로 설치되지 않았는지 확인해야 함",
    "ru": "Мотор неподвижен. Взлет невозможен. Проверьте установку пропеллеров",
    "tr": "Motor boşta. Kalkış yapamıyor. Pervanelerin doğru çıkarıldığından veya takıldığından emin olun",
    "zh": "电机空转，无法起飞，请检查桨叶是否脱落或未正确安装桨叶"
  },
  "fpv_tip_0x16100062": {
    "de": "Motoren können nicht eingeschaltet werden. Start nicht möglich. Fluggerätstatus prüfen und neu starten",
    "en": "Unable to turn on motor. Unable to take off. Check aircraft status and restart",
    "es": "No se puede encender el motor. No se puede despegar. Comprueba el estado de la aeronave y reiníciala",
    "fr": "Impossible de démarrer les moteurs. Impossible de décoller. Vérifiez l\\'état de l\\'appareil et redémarrez",
    "ja": "モーターをオンにできません。離陸できません。機体の状態を確認して再起動してください",
    "ko": "모터를 켤 수 없음. 이륙 불가. 기체 상태를 확인하고 다시 시작해야 함",
    "ru": "Невозможно включить мотор и взлететь. Проверьте статус дрона и перезагрузите его",
    "tr": "Motoru çalıştıramıyor. Kalkış yapamıyor. Araç durumunu kontrol edin ve yeniden başlatın",
    "zh": "无法起飞：电机无法启动，请检查飞行器状态并重启飞行器"
  },
  "fpv_tip_0x16100062_in_the_sky": {
    "de": "Motor(en) können nicht eingeschaltet werden. Fluggerätstatus prüfen und neu starten",
    "en": "Unable to turn on aircraft motor(s). Check aircraft status and restart",
    "es": "No se puede encender uno o varios motores de la aeronave. Comprueba el estado de la aeronave y reiníciala",
    "fr": "Impossible de démarrer les moteurs. Vérifiez l\\'état de l\\'appareil et redémarrez",
    "ja": "機体のモーターをオンにできません。機体の状態を確認して再起動してください",
    "ko": "기체 모터를 켤 수 없음. 기체 상태를 확인하고 다시 시작해야 함",
    "ru": "Невозможно включить мотор (-ы) дрона. Проверьте статус и перезагрузите его",
    "tr": "Aracın motor(lar)ını açamıyor. Araç durumunu kontrol edin ve yeniden başlatın",
    "zh": "电机无法启动，请检查飞行器状态并重启飞行器"
  },
  "fpv_tip_0x16100063": {
    "de": "Automatischer Start fehlgeschlagen",
    "en": "Auto Takeoff failed. Unable to take off",
    "es": "Error de despegue automático. No se puede despegar",
    "fr": "Décollage automatique impossible",
    "ja": "自動離陸に失敗しました。離陸できません",
    "ko": "자동 이륙 실패",
    "ru": "Сбой автовзлета",
    "tr": "Otomatik Kalkış başarısız. Kalkış yapamıyor",
    "zh": "无法起飞：自动起飞失败"
  },
  "fpv_tip_0x16100063_in_the_sky": {
    "de": "Automatischer Start fehlgeschlagen",
    "en": "Auto Takeoff failed",
    "es": "Error de despegue automático",
    "fr": "Décollage automatique impossible",
    "ja": "自動離陸に失敗しました",
    "ko": "자동 이륙 실패",
    "ru": "Сбой автовзлета",
    "tr": "Otomatik Kalkış başarısız",
    "zh": "自动起飞失败"
  },
  "fpv_tip_0x16100064": {
    "de": "Fluggerät hat sich überschlagen . Start nicht möglich. Fluggerät auf ebener Fläche neu starten",
    "en": "Aircraft rolled over. Unable to take off. Restart aircraft and ensure it is level before taking off",
    "es": "La aeronave se ha volcado. No se puede despegar. Reinicia la aeronave y asegúrate de que esté nivelada antes de despegar",
    "fr": "L\\'appareil s\\'est retourné . Impossible de décoller. Redémarrez-le et assurez-vous qu\\'il est sur une surface plane avant de décoller",
    "ja": "機体が裏返っています。離陸できません。機体を再起動し、機体が水平なことを確認してから離陸してください",
    "ko": "기체 전복됨 . 이륙 불가. 기체 재시작 후 평평한 곳에 놓고 이륙해야 함",
    "ru": "Дрон наклонился . Взлет невозможен. Перезагрузите дрон и убедитесь, что он расположен ровно перед взлетом",
    "tr": "Araç yuvarlandı. Kalkış yapamıyor. Aracı tekrar çalıştırın ve kalkıştan önce düz olduğundan emin olun",
    "zh": "无法起飞：飞行器姿态翻滚，请重启飞行器水平放置后起飞"
  },
  "fpv_tip_0x16100064_in_the_sky": {
    "de": "Fluggerät hat sich gedreht . Zum Startpunkt zurückkehren oder landen.",
    "en": "Aircraft rolled over. Return to home or land",
    "es": "La aeronave se ha volcado. Regresa al punto de origen o aterriza",
    "fr": "L\\'appareil s\\'est retourné . Revenez au point de départ ou atterrissez",
    "ja": "機体が裏返っています。帰還するか、着陸してください",
    "ko": "기체 전복됨 . RTH 진행 또는 착륙 필요",
    "ru": "Дрон перевернулся . Вернитесь домой или приземлитесь",
    "tr": "Araç yuvarlandı. Eve geri dönün veya iniş yapın",
    "zh": "飞行器姿态翻滚， 请返航或降落"
  },
  "fpv_tip_0x16100065": {
    "ar": "",
    "de": "Fehler: Firmware-Version des Akkus. Start nicht möglich. Akku austauschen oder auf neueste Firmware-Version aktualisieren",
    "en": "Battery firmware version error. Unable to take off. Replace battery or update battery firmware to latest version",
    "es": "Error de versión del firmware de la batería. No se puede despegar. Reemplaza la batería o actualiza el firmware de la batería a la versión más reciente",
    "fr": "Erreur de version du firmware de la batterie. Impossible de décoller. Remplacez la batterie ou mettez à jour le firmware de la batterie vers la version la plus récente",
    "id": "",
    "it": "",
    "ja": "バッテリーファームウェア バージョンエラー。離陸できません。バッテリーを交換するか、バッテリーファームウェアを最新バージョンに更新してください",
    "ko": "배터리 펌웨어 버전 오류. 이륙 불가. 배터리를 교체하거나 최신 펌웨어 버전으로 업데이트 필요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Ошибка версии ПО батареи. Взлет невозможен. Замените батарею или установите последнюю версию ПО",
    "th": "",
    "tr": "Pil yazılımı sürümü hatası. Kalkış yapamıyor. Pili değiştirin ya da pil yazılımını en güncel sürüme güncelleyin",
    "ug": "",
    "vi": "",
    "zh": "无法起飞：电池固件版本不一致，请更换电池或将电池升级到最新版本",
    "zh-Hant": ""
  },
  "fpv_tip_0x16100065_in_the_sky": {
    "ar": "",
    "de": "Fehler bei Firmware-Version des Akkus. Akku austauschen oder auf neueste Firmware-Version aktualisieren",
    "en": "Battery firmware version error. Replace battery or update to the latest firmware version",
    "es": "Error de versión del firmware de la batería. Reemplaza la batería o actualiza a la versión del firmware más reciente",
    "fr": "Erreur de version du firmware de la batterie. Remplacez la batterie ou mettez à jour vers la version la plus récente",
    "id": "",
    "it": "",
    "ja": "バッテリーファームウェア バージョンエラー。バッテリーを交換するか最新のファームウェアバージョンに更新してください",
    "ko": "배터리 펌웨어 버전 오류. 배터리를 교체하거나 최신 펌웨어 버전으로 업데이트 필요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Ошибка версии ПО батареи. Замените ее или установите последнюю версию ПО",
    "th": "",
    "tr": "Pil yazılımı sürümü hatası. Pili değiştirin ya da pil yazılımını en güncel sürüme güncelleyin",
    "ug": "",
    "vi": "",
    "zh": "电池固件版本不一致，请更换电池或将电池升级到最新版本",
    "zh-Hant": ""
  },
  "fpv_tip_0x16100066": {
    "de": "RTK-Signal schwach. Start nicht möglich. Zum Starten in einen offenen Bereich gehen oder RTK ausschalten",
    "en": "RTK signal weak. Unable to take off. Move to an open area for takeoff or turn off RTK",
    "es": "Señal RTK débil. No se puede despegar. Desplázate a un espacio abierto para despegar o desactiva RTK",
    "fr": "Signal RTK faible. Impossible de décoller. Déplacez-vous vers une zone dégagée pour décoller ou désactivez le RTK",
    "ja": "RTK信号 弱。離陸できません。開けたエリアに移動して離陸するか、RTKをオフにしてください",
    "ko": "RTK 신호 약함. 이륙 불가. 개방된 장소로 이동해 이륙하거나 RTK를 꺼야 함",
    "ru": "Слабый сигнал RTK. Взлет невозможен. Переместитесь в открытое место для взлета или выкл. RTK",
    "tr": "RTK sinyali zayıf. Kalkış yapamıyor. Kalkış için açık bir alana gidin ya da RTK’yı kapatın",
    "zh": "无法起飞：RTK 信号差，请至空旷地起飞，或关闭RTK功能"
  },
  "fpv_tip_0x16100066_in_the_sky": {
    "de": "RTK-Signal schwach. Zum Starten in einen offenen Bereich gehen oder RTK ausschalten",
    "en": "RTK signal weak. Move to an open area for takeoff or turn off RTK",
    "es": "Señal RTK débil. Desplázate a un espacio abierto para despegar o desactiva RTK",
    "fr": "Signal RTK faible. Déplacez-vous vers une zone dégagée pour décoller ou désactivez le RTK",
    "ja": "RTK信号 弱。開けたエリアに移動して離陸するか、RTKをオフにしてください",
    "ko": "RTK 신호 약함. 개방된 장소로 이동해 이륙하거나 RTK를 꺼야 함",
    "ru": "Слабый сигнал RTK. Переместитесь в открытое место для взлета или выкл. RTK",
    "tr": "RTK sinyali zayıf. Kalkış için açık bir alana gidin ya da RTK’yı kapatın",
    "zh": "RTK 信号差，请至空旷地起飞，或关闭RTK功能"
  },
  "fpv_tip_0x16100067": {
    "de": "Fehler: Kompass . Start nicht möglich. Kompass neu kalibrieren",
    "en": "Compass interference. Unable to take off. Calibrate compass",
    "es": "Interferencias en la brújula. No se puede despegar. Calibra la brújula",
    "fr": "Interférence du compas . Impossible de décoller. Étalonnez le compas",
    "ja": "コンパスの干渉。離陸できません。コンパスをキャリブレーション",
    "ko": "콤파스 간섭 . 이륙 불가. 콤파스 캘리브레이션 필요",
    "ru": "Помехи компаса . Взлет невозможен. Откалибруйте его",
    "tr": "Pusula müdahalesi. Kalkış yapamıyor. Pusulayı kalibre edin",
    "zh": "无法起飞：指南针受到干扰，请校准指南针"
  },
  "fpv_tip_0x16100067_in_the_sky": {
    "de": "Kompassstörung. Von der Störquelle wegbewegen",
    "en": "Compass interference. Move away from interference source",
    "es": "Interferencias en la brújula. Aléjese de la fuente de interferencia",
    "fr": "Interférences avec le compas. Éloignez-vous de la source d'interférence",
    "ja": "コンパスの干渉。干渉源から遠ざけてください",
    "ko": "콤파스 간섭. 간섭원으로부터 멀리 이동하세요",
    "ru": "Помехи в работе компаса. Переместите дрон подальше от источника помех",
    "tr": "Pusula paraziti. Parazit kaynağından uzaklaşın",
    "zh": "指南针受到干扰，请远离干扰源飞行"
  },
  "fpv_tip_0x16100068": {
    "de": "Kurzschluss am ESC. Start nicht möglich. Fluggerät neu starten",
    "en": "ESC short-circuited. Unable to take off. Restart aircraft",
    "es": "Cortocircuito de ESC. No se puede despegar. Reinicia la aeronave",
    "fr": "ESC court-circuité. Impossible de décoller. Redémarrez l\\'appareil",
    "ja": "ESC短絡。離陸できません。機体を再起動してください",
    "ko": "ESC 합선. 이륙 불가. 기체 재시작 필요",
    "ru": "Короткое замыкание ESC. Взлет невозможен. Перезагрузите дрон",
    "tr": "ESC kısa devresi. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：电调短路，请重启飞行器"
  },
  "fpv_tip_0x16100068_in_the_sky": {
    "de": "Kurzschluss am ESC. Fluggerät neu starten .",
    "en": "ESC short-circuited. Restart aircraft",
    "es": "Cortocircuito de ESC. Reinicia la aeronave",
    "fr": "Court-circuit ESC. Redémarrez l\\'appareil",
    "ja": "ESC短絡。機体を再起動してください",
    "ko": "ESC 합선. 기체를 다시 시작해야 함",
    "ru": "Короткое замыкание ESC. Перезагрузите дрон",
    "tr": "ESC kısa devresi. Aracı yeniden başlatın",
    "zh": "电调短路，请重启飞行器"
  },
  "fpv_tip_0x16100069": {
    "de": "Fehler: Gimbal-Selbsttest. Start nicht möglich. Fluggerät neu starten",
    "en": "ESC auto-check error. Unable to take off. Restart aircraft",
    "es": "Error de comprobación automática de ESC. No se puede despegar. Reinicia la aeronave",
    "fr": "Erreur vérif. auto de l\\'ESC. Impossible de décoller. Redémarrez l\\'appareil",
    "ja": "ESC自動確認エラー。離陸できません。機体を再起動してください",
    "ko": "ESC 자체 점검 오류. 이륙 불가. 기체 재시작 필요",
    "ru": "Ошибка автопроверки ESC. Взлет невозможен. Перезагрузите дрон",
    "tr": "ESC otomatik kontrol hatası. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：电子调速器自检异常，请重启飞行器"
  },
  "fpv_tip_0x16100069_in_the_sky": {
    "de": "Fehler: ESC Selbsttest. Umgehend zum Startpunkt zurückkehren.",
    "en": "ESC auto-check error. Return to home or land",
    "es": "Error de comprobación automática de ESC. Regresa al punto de origen o aterriza",
    "fr": "Erreur vérif. auto de l\\'ESC. Revenez au point de départ ou atterrissez",
    "ja": "ESC自動確認エラー。帰還するか、着陸してください",
    "ko": "ESC 자체 점검 오류. RTH 진행 또는 착륙 필요",
    "ru": "Ошибка автопроверки ESC. Вернитесь домой или приземлитесь",
    "tr": "ESC otomatik kontrol hatası. Eve geri dönün veya iniş yapın",
    "zh": "电子调速器自检异常，请返航或降落"
  },
  "fpv_tip_0x1610006A": {
    "de": "Inkompatibler Akku. Akku austauschen",
    "en": "Incompatible battery. Replace battery",
    "es": "Batería incompatible. Reemplaza la batería.",
    "fr": "Batterie non compatible. Remplacez la batterie",
    "ja": "互換性のないバッテリー。バッテリーを交換してください",
    "ko": "호환되지 않는 배터리. 배터리 교체 필요",
    "ru": "Несовместимая АКБ. Замените АКБ",
    "tr": "Uyumsuz pil. Pili değiştirin",
    "zh": "电池不匹配，请更换电池"
  },
  "fpv_tip_0x1610006B": {
    "de": "Start nicht möglich. Firmware-Aktualisierung des Akkus erforderlich",
    "en": "Unable to take off. Battery firmware update required",
    "es": "No se puede despegar. Es necesario actualizar el firmware de la batería.",
    "fr": "Impossible de décoller. Mise à jour du firmware de la batterie nécessaire",
    "ja": "離陸できません。バッテリーのファームウェア更新が必要です",
    "ko": "이륙 불가. 배터리 펌웨어 업데이트 필요",
    "ru": "Не удается выполнить взлет. Требуется обновление прошивки АКБ",
    "tr": "Kalkış yapılamıyor. Pil ürün yazılımı güncellemesi gerekiyor",
    "zh": "无法起飞：电池固件需要升级"
  },
  "fpv_tip_0x1610006B_in_the_sky": {
    "de": "Fehler: Firmware des Akkus. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "Battery firmware error. Return to home or land promptly",
    "es": "Error de firmware de la batería. Regresa al punto de origen o aterriza rápidamente.",
    "fr": "Erreur du firmware de la batterie. Revenez au point de départ ou atterrissez rapidement",
    "ja": "バッテリーファームウェアエラー。直ちにホームに帰還するか着陸してください",
    "ko": "배터리 펌웨어 오류. 즉시 홈으로 돌아가거나 착륙하세요.",
    "ru": "Ошибка прошивки АКБ. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "Pil ürün yazılımı hatası. Derhal başlangıç noktasına geri dönün veya iniş yapın",
    "zh": "电池固件异常，请尽快返航或降落"
  },
  "fpv_tip_0x1610006C": {
    "de": "Start nicht möglich. Schwerwiegender Akkufehler",
    "en": "Unable to take off. Severe battery error",
    "es": "No se puede despegar. Error grave de la batería",
    "fr": "Impossible de décoller. Erreur de batterie grave",
    "ja": "離陸できません。重大なバッテリーエラー",
    "ko": "이륙 불가. 심각한 배터리 오류",
    "ru": "Не удается выполнить взлет. Серьезная ошибка аккумулятора",
    "tr": "Kalkış yapılamıyor. Ciddi pil hatası",
    "zh": "无法起飞：电池严重异常"
  },
  "fpv_tip_0x1610006C_in_the_sky": {
    "de": "Schwerwiegender Akkufehler. Automatische Landung …",
    "en": "Severe battery error. Auto landing...",
    "es": "Error grave de la batería. Realizando aterrizaje automático…",
    "fr": "Erreur de batterie grave. Atterrissage auto en cours",
    "ja": "重大なバッテリーエラー。自動着陸中...",
    "ko": "심각한 배터리 오류. 자동 착륙 중…",
    "ru": "Серьезная ошибка аккумулятора. Автоматическая посадка…",
    "tr": "Ciddi pil hatası. Otomatik iniş...",
    "zh": "电池严重异常：强制降落中"
  },
  "fpv_tip_0x1610006D": {
    "de": "Start nicht möglich. Leistung eingeschränkt. Akkuwarnmeldung auf Problemursache prüfen",
    "en": "Unable to take off. Power restricted. Check battery warning for issue reason",
    "es": "No se puede despegar. Potencia restringida. Compruebe la advertencia de la batería para conocer el motivo del problema",
    "fr": "Impossible de décoller. Puissance limitée. Vérifiez l'avertissement de la batterie pour la raison du problème",
    "ja": "離陸できません。電力が制限されています。バッテリー警告で問題の原因を確認してください",
    "ko": "이륙 불가. 전원 제한 상태. 배터리 경고에서 문제의 원인을 확인하세요",
    "ru": "Не удается выполнить взлет. Мощность ограничена. Проверьте предупреждение от АКБ, чтобы узнать причину проблемы",
    "tr": "Kalkış yapılamıyor. Güç kısıtlı. Sorunun nedeni için pil uyarısını kontrol edin",
    "zh": "无法起飞：已限制功率，原因请参考电池告警"
  },
  "fpv_tip_0x1610006D_in_the_sky": {
    "de": "Leistung und Fluglage eingeschränkt. Umgehend zum Startpunkt zurückkehren. Akkuwarnmeldung auf Problemursache prüfen",
    "en": "Power and flight attitude restricted. Return to home promptly. Check battery warning for issue reason",
    "es": "Potencia y altitud de vuelo restringidas. Regrese al punto de origen cuanto antes. Compruebe la advertencia de la batería para conocer el motivo del problema",
    "fr": "Puissance et assiette de vol restreintes. Revenez au point de départ immédiatement. Vérifiez l'avertissement de la batterie pour la raison du problème",
    "ja": "電力と飛行姿勢が制限されています。すぐに帰還してください。バッテリー警告で問題の原因を確認してください",
    "ko": "전원 및 비행 고도 제한 상태. 즉시 리턴 투 홈을 실행하세요. 배터리 경고에서 문제의 원인을 확인하세요",
    "ru": "Мощность и высота полета ограничены. Как можно скорее вернитесь на базу. Проверьте предупреждение от АКБ, чтобы узнать причину проблемы",
    "tr": "Güç ve uçuş hareketi kısıtlı. Derhal başlangıç noktasına dönün. Sorunun nedeni için pil uyarısını kontrol edin",
    "zh": "已限制功率和飞行姿态，请尽快返航，原因请参考电池告警"
  },
  "fpv_tip_0x16100070": {
    "en": "Not Applicable. This string has been deleted",
    "zh": "还在用么？160没用这个了"
  },
  "fpv_tip_0x16100070_in_the_sky": {
    "en": "Not Applicable. This string has been deleted",
    "zh": "还在用么？160没用这个了"
  },
  "fpv_tip_0x16100071": {
    "de": "GPS getrennt . Start nicht möglich. Fluggerät neu starten",
    "en": "GPS error. Unable to take off. Restart aircraft",
    "es": "Error de GPS. No se puede despegar. Reinicia la aeronave",
    "fr": "Erreur du GPS . Impossible de décoller. Redémarrez l\\'appareil",
    "ja": "GPSエラー。離陸できません。機体を再起動してください",
    "ko": "GPS 오류 . 이륙 불가. 기체 재시작 필요",
    "ru": "Ошибка GPS . Взлет невозможен. Перезагрузите дрон",
    "tr": "GPS hatası. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：GPS异常，请重启飞行器"
  },
  "fpv_tip_0x16100071_in_the_sky": {
    "de": "Fehler: GPS . Zum Startpunkt zurückkehren oder landen.",
    "en": "GPS error. Return to home or land",
    "es": "Error de GPS. Regresa al punto de origen o aterriza",
    "fr": "Erreur GPS . Revenez au point de départ ou atterrissez",
    "ja": "GPSエラー。帰還するか、着陸してください",
    "ko": "GPS 오류 . RTH 진행 또는 착륙 필요",
    "ru": "Ошибка GPS . Вернитесь домой или приземлитесь",
    "tr": "GPS hatası. Eve geri dönün veya iniş yapın",
    "zh": "GPS异常，请降落或返航"
  },
  "fpv_tip_0x16100072": {
    "de": "Gimbal wird kalibriert . Start nicht möglich",
    "en": "Gimbal calibrating. Unable to take off",
    "es": "Calibrando el estabilizador. No se puede despegar",
    "fr": "Étalonnage de la nacelle . Impossible de décoller",
    "ja": "ジンバルキャリブレーション中。離陸できません",
    "ko": "짐벌 캘리브레이션 중 . 이륙 불가",
    "ru": "Идет калибровка стабилизатора . Взлет невозможен",
    "tr": "Gimbal kalibre ediliyor. Kalkış yapamıyor",
    "zh": "无法起飞：云台校准中，请稍后起飞"
  },
  "fpv_tip_0x16100072_in_the_sky": {
    "de": "Kalibrierungsfehler: Gimbal. Zum Startpunkt zurückkehren oder landen",
    "en": "Gimbal calibration error. Return to home or land",
    "es": "Error de calibración del estabilizador. Regresa al punto de origen o aterriza",
    "fr": "Erreur d\\'étalonnage de la nacelle. Revenez au point de départ ou atterrissez",
    "ja": "ジンバル キャリブレーション エラー。帰還するか、着陸してください",
    "ko": "짐벌 캘리브레이션 오류. RTH 진행 또는 착륙 필요",
    "ru": "Ошибка калибровки стабилизатора. Вернитесь домой или приземлитесь",
    "tr": "Gimbal kalibrasyon hatası. Eve geri dönün veya iniş yapın",
    "zh": "云台校准异常，请返航或降落"
  },
  "fpv_tip_0x16100073": {
    "de": "Startbedingungen nicht erfüllt. Start nicht möglich. Firmware veraltete oder Flugroute wird in Fluggerät hochgeladen",
    "en": "Takeoff conditions not met. Unable to take off. Firmware out-of-date or flight route uploading to aircraft",
    "es": "No se cumplen las condiciones de despegue. No se puede despegar. Firmware obsoleto o cargando ruta de vuelo a la aeronave",
    "fr": "Conditions de décollage non satisfaites. Impossible de décoller. Le firmware de l\\'appareil est obsolète ou la trajectoire de vol est en cours de transfert vers l\\'appareil",
    "ja": "離陸条件が満たされていません。離陸できません。ファームウェアが古いか、飛行ルートを機体にアップロード中です",
    "ko": "이륙 조건 충족 안 됨. 이륙 불가. 오래된 펌웨어거나 비행 경로가 기체에 업로드되는 중",
    "ru": "Условия взлета не соблюдены. Взлет невозможен. Устаревшая версия ПО или маршрут полета загружается в дрон",
    "tr": "Kalkış koşulları uygun değil. Kalkış yapamıyor. Yazılım güncel değil ya da uçuş rotası araca yükleniyor",
    "zh": "无法起飞：当前状态不支持起飞。固件版本过低或正在上传航线至飞行器"
  },
  "fpv_tip_0x16100073_in_the_sky": {
    "de": "Veraltete Firmware oder Flugroute wird gerade zum Fluggerät übertragen. Zum Startpunkt zurückkehren oder landen",
    "en": "Firmware out of date or flight route uploading to aircraft. Return to home or land",
    "es": "Firmware obsoleto o cargando ruta de vuelo a la aeronave. Regresa al punto de origen o aterriza",
    "fr": "Firmware obsolète ou trajectoire en cours de transfert vers l\\'appareil. Revenez au point de départ ou atterrissez",
    "ja": "ファームウェアが古いか、飛行ルートを機体にアップロード中です。帰還するか、着陸してください",
    "ko": "오래된 펌웨어거나 기체에 비행 경로가 업로드되는 중. RTH 진행 또는 착륙 필요",
    "ru": "Старая версия ПО или маршрута полета загружается в дрон. Вернитесь домой или приземлитесь",
    "tr": "Yazılım güncel değil ya da uçuş rotası araca yükleniyor. Eve geri dönün veya iniş yapın",
    "zh": "固件版本过低或正在上传航线至飞行器，请返航或降落"
  },
  "fpv_tip_0x16100074": {
    "de": "Fehler: Start nicht möglich. Fluggerät neu starten",
    "en": "Takeoff altitude error. Unable to take off. Restart aircraft",
    "es": "Error de altitud en el despegue. No se puede despegar. Reinicia la aeronave",
    "fr": "Erreur d\\'altitude au décollage. Impossible de décoller. Redémarrez l\\'appareil",
    "ja": "離陸高度エラー。離陸できません。機体を再起動してください",
    "ko": "이륙 고도 오류. 이륙 불가. 기체 재시작 필요",
    "ru": "Ошибка высоты взлета. Взлет невозможен. Перезагрузите дрон",
    "tr": "Kalkış irtifa hatası. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：飞行器起飞高度异常，请重启飞行器"
  },
  "fpv_tip_0x16100074_in_the_sky": {
    "de": "Startfehler. Flugzeug neu starten",
    "en": "Takeoff altitude error. Restart aircraft",
    "es": "Error de altitud en el despegue. Reinicia la aeronave",
    "fr": "Erreur d\\'altitude de décollage. Redémarrez l\\'appareil",
    "ja": "離陸高度エラー。機体を再起動してください",
    "ko": "이륙 고도 오류. 기체를 다시 시작해야 함",
    "ru": "Ошибка высоты взлета. Перезагрузите дрон",
    "tr": "Kalkış irtifa hatası. Aracı yeniden başlatın",
    "zh": "飞行器起飞高度异常，请重启飞行器"
  },
  "fpv_tip_0x16100075": {
    "de": "ESC-Firmware-Versionen stimmen nicht überein. Start nicht möglich. Auf die neuesten Firmware-Versionen aktualisieren",
    "en": "ESC firmware versions do not match. Unable to take off. Update to latest firmware version",
    "es": "Las versiones del firmware de ESC no coinciden. No se puede despegar. Actualiza a la versión del firmware más reciente",
    "fr": "Les versions des firmwares des ESC ne correspondent pas. Impossible de décoller. Mettez-les à jour vers les versions les plus récentes",
    "ja": "ESCファームウェア バージョン不一致。離陸できません。最新のファームウェアバージョンに更新してください",
    "ko": "ESC 펌웨어 버전 불일치. 이륙 불가. 최신 펌웨어 버전으로 업데이트 필요",
    "ru": "Версии ПО ESC не совпадают. Взлет невозможен. Установите последнюю версию",
    "tr": "ESC yazılım sürümleri eşleşmiyor. Kalkış yapamıyor. En güncel yazılım sürümüne güncelleyin",
    "zh": "无法起飞：电调版本不一致，请更新飞行器固件"
  },
  "fpv_tip_0x16100075_in_the_sky": {
    "de": "ESC-Firmware-Versionen stimmen nicht überein. Auf die neuesten Firmware-Versionen aktualisieren",
    "en": "ESC firmware versions do not match. Update to latest firmware versions",
    "es": "Las versiones del firmware de ESC no coinciden. Actualiza a las versiones del firmware más recientes",
    "fr": "Les versions des firmwares des ESC ne correspondent pas. Mettez à jour vers les versions de firmwares les plus récentes",
    "ja": "ESCファームウェア バージョン不一致。最新のファームウェアバージョンに更新してください",
    "ko": "ESC 펌웨어 버전 불일치. 최신 펌웨어 버전으로 업데이트해야 함",
    "ru": "Версии ПО ESC не совпадают. Установите последние версии",
    "tr": "ESC yazılım sürümleri eşleşmiyor. En güncel yazılım sürümlerine güncelleyin",
    "zh": "电调版本不一致，请更新飞行器固件"
  },
  "fpv_tip_0x16100076": {
    "de": "IMU-Fehler . Start nicht möglich. An DJI-Support wenden",
    "en": "IMU error. Unable to take off. Contact DJI Support",
    "es": "Error de IMU. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur IMU. Impossible de décoller. Contacter le service client DJI",
    "ja": "IMU エラー。離陸できません。DJI サポートまでお問い合わせください",
    "ko": "IMU 오류 . 이륙 불가. DJI 고객지원으로 문의해주세요",
    "ru": "Ошибка IMU . Взлет невозможен. Обратитесь в службу поддержки DJI",
    "tr": "IMU hatası. Kalkış yapamıyor. DJI Destek ile iletişime geçin",
    "zh": "无法起飞：IMU异常，请联系大疆售后服务"
  },
  "fpv_tip_0x16100076_in_the_sky": {
    "de": "Fehler: IMU . Zum Startpunk zurückkehren oder landen.",
    "en": "IMU error. Return to home or land",
    "es": "Error de IMU. Regresa al punto de origen o aterriza",
    "fr": "Erreur IMU . Revenez au point de départ ou atterrissez",
    "ja": "IMUエラー。帰還するか、着陸してください",
    "ko": "IMU 오류 . RTH 진행 또는 착륙 필요",
    "ru": "Ошибка IMU . Вернитесь домой или приземлитесь",
    "tr": "IMU hatası. Eve geri dönün veya iniş yapın",
    "zh": "IMU异常，请返航或降落"
  },
  "fpv_tip_0x16100078": {
    "de": "Kompassfehler. Start nicht möglich. An DJI-Support wenden",
    "en": "Compass error. Unable to take off. Contact DJI Support",
    "es": "Error de brújula. No se puede despegar. Ponte en contacto con la asistencia técnica de DJI",
    "fr": "Erreur de compas. Impossible de décoller. Contactez l\\'assistance technique DJI",
    "ja": "コンパスエラー。離陸できません。DJIサポートまでお問い合わせください",
    "ko": "콤파스 오류. 이륙 불가. DJI 고객지원으로 문의해주세요",
    "ru": "Ошибка компаса. Взлет невозможен. Обратитесь в службу поддержки DJI",
    "tr": "Pusula hatası. Kalkış yapamıyor. DJI Destek ile iletişime geçin",
    "zh": "无法起飞：指南针异常，请联系大疆售后服务"
  },
  "fpv_tip_0x16100078_in_the_sky": {
    "de": "Kompassfehler. Zum Startpunkt zurückkehren oder landen",
    "en": "Compass error. Return to home or land",
    "es": "Error de brújula. Regresa al punto de origen o aterriza",
    "fr": "Erreur compas. Revenez au point de départ ou atterrissez",
    "ja": "コンパスエラー。帰還するか、着陸してください",
    "ko": "콤파스 오류. RTH 진행 또는 착륙 필요",
    "ru": "Ошибка компаса. Вернитесь домой или приземлитесь",
    "tr": "Pusula hatası. Eve geri dönün veya iniş yapın",
    "zh": "指南针异常，请返航或降落"
  },
  "fpv_tip_0x1610007A": {
    "de": "ESC-Piepton. Start nicht möglich. ESC-Piepton vor Start deaktivieren",
    "en": "ESC beeping. Unable to take off. Turn off ESC beeping before takeoff",
    "es": "ESC pitando. No se puede despegar. Apaga el pitido ESC antes del despegue",
    "fr": "Alerte sonore de l'ESC. Impossible de décoller. Désactivez l'alerte sonore de l'ESC avant le décollage",
    "ja": "ESCビープ音が鳴動しています。離陸できません。離陸前にESCビープ音をオフにしてください",
    "ko": "ESC 신호음 작동 중. 이륙 불가. 이륙 전 ESC 신호음을 끄세요.",
    "ru": "ESC издает звуковой сигнал. Не удается выполнить взлет. Отключите звуковой сигнал ESC перед взлетом",
    "tr": "ESC bip sesi çıkarıyor. Kalkış yapılamıyor. Kalkıştan önce ESC bip sesini kapatın",
    "zh": "无法起飞：电调鸣叫中，关闭后可正常飞行"
  },
  "fpv_tip_0x1610007A_in_the_sky": {
    "de": "ESC piept. Zum Startpunkt zurückkehren oder landen",
    "en": "ESC beeping. Return to home or land",
    "es": "ESC pitando. Regresa al punto de origen o aterriza",
    "fr": "Alarme sonore de l\\'ESC. Revenez au point de départ ou atterrissez",
    "ja": "ESCビープ音鳴動中。帰還するか、着陸してください",
    "ko": "ESC 신호음 작동 중. RTH 진행 또는 착륙 필요",
    "ru": "ESC сигналит. Вернитесь домой или приземлитесь",
    "tr": "ESC uyarı sesi. Eve geri dönün veya iniş yapın",
    "zh": "电调异常鸣叫中，请返航或降落"
  },
  "fpv_tip_0x1610007B": {
    "de": "ESC überhitzt. Start nicht möglich. Fluggerät ausschalten und warten, bis sich die Temperatur wieder normalisiert hat",
    "en": "ESC overheated. Unable to take off. Power off aircraft and wait for temperature to return to normal",
    "es": "ESC sobrecalentado. No se puede despegar. Apaga la aeronave y espera a que la temperatura vuelva a la normalidad",
    "fr": "Surchauffe de l\\'ESC. Impossible de décoller. Éteignez l\\'appareil et attendez que la température revienne à la normale",
    "ja": "ESC高温。離陸できません。機体の電源を切り、通常の温度に戻るまで待ってください",
    "ko": "ESC 과열됨. 이륙 불가. 기체 전원을 끄고 정상 온도로 내려갈 때까지 기다려야 함",
    "ru": "Перегрев ESC. Взлет невозможен. Выключите дрон и дождитесь его остывания",
    "tr": "ESC aşırı ısındı. Kalkış yapamıyor. Aracı kapatın ve sıcaklığın normale dönmesini bekleyin",
    "zh": "无法起飞：电调温度过高，请关机至恢复正常温度"
  },
  "fpv_tip_0x1610007B_in_the_sky": {
    "de": "ESC überhitzt. Sofort landen. Fluggerät ausschalten und warten, bis sich die Temperatur wieder normalisiert hat .",
    "en": "ESC overheated. Land promptly. Power off aircraft and wait for temperature to return to normal",
    "es": "ESC sobrecalentado. Aterriza rápidamente. Apaga la aeronave y espera a que la temperatura vuelva a la normalidad",
    "fr": "Surchauffe ESC. Atterrissez immédiatement. Éteignez l\\'appareil et attendez que la température revienne à la normale",
    "ja": "ESC高温。直ちに着陸してください。機体の電源を切り、通常の温度に戻るまで待ってください",
    "ko": "ESC 과열됨. 즉시 착륙 필요. 기체 전원을 끄고 정상 온도로 내려갈 때까지 기다려야 함",
    "ru": "Перегрев ESC. Приземлитесь немедленно. Выключите дрон и дождитесь остывания",
    "tr": "ESC aşırı ısındı. Hemen iniş yapın. Aracı kapatın ve sıcaklığın normale dönmesini bekleyin",
    "zh": "电调温度过高，请降落，并关机冷却至恢复正常温度"
  },
  "fpv_tip_0x1610007C": {
    "ar": "",
    "de": "",
    "en": "Battery installed incorrectly. Detach battery and reinstall it",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "电池安装不到位，请重新拔插电池",
    "zh-Hant": ""
  },
  "fpv_tip_0x1610007D": {
    "de": "Aufprall erkannt. Start fehlgeschlagen und Fluggerät gelandet. Fluggerät neu starten",
    "en": "Impact detected. Takeoff failed and aircraft landed. Unable to take off. Restart aircraft",
    "es": "Impacto detectado. Error de despegue. La aeronave ha aterrizado. No se puede despegar. Reinicia la aeronave",
    "fr": "Impact détecté. Le décollage a échoué et l\\'appareil a atterri. Redémarrez l\\'appareil",
    "ja": "衝撃を検出。離陸に失敗し、機体が着陸しました。離陸できません。機体を再起動してください",
    "ko": "충격 감지됨. 이륙 실패 후 기체 착륙함. 기체 재시작 필요",
    "ru": "Обнаружен удар. Сбой взлета, дрон приземлился. Перезагрузите аппарат",
    "tr": "Etki tespit edildi. Kalkış başarısız ve araç indi. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：检测到被撞击，飞行器已停止飞行，请重启飞行器"
  },
  "fpv_tip_0x1610007D_in_the_sky": {
    "de": "Aufprall erkannt. Fluggerät gelandet. Fluggerät neu starten",
    "en": "Impact detected. Aircraft landed. Restart aircraft",
    "es": "Impacto detectado. La aeronave ha aterrizado. Reinicia la aeronave",
    "fr": "Impact détecté. L\\'appareil a atterrit. Redémarrez l\\'appareil",
    "ja": "衝撃を検出。機体が着陸しました。機体を再起動してください",
    "ko": "충격 감지됨. 기체 착륙함. 기체를 다시 시작해야 함",
    "ru": "Обнаружен удар. Дрон приземлился. Перезагрузите его",
    "tr": "Etki tespit edildi. Araç indi. Aracı yeniden başlatın",
    "zh": "检测到被撞击，飞行器已停止飞行，请重启飞行器"
  },
  "fpv_tip_0x1610007F": {
    "de": "Aufprall erkannt. Start nicht möglich. Fluggerät neu starten",
    "en": "Impact detected. Unable to take off. Restart aircraft",
    "es": "Impacto detectado. No se puede despegar. Reinicia la aeronave",
    "fr": "Impact détecté. Impossible de décoller. Redémarrez l\\'appareil",
    "ja": "衝撃を検出。離陸できません。機体を再起動してください",
    "ko": "충격 감지됨. 이륙 불가. 기체 재시작 필요",
    "ru": "Обнаружен удар. Не удалось взлететь. Перезагрузите дрон",
    "tr": "Etki tespit edildi. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：检测到飞行器发生过撞击，请重启飞行器"
  },
  "fpv_tip_0x1610007F_in_the_sky": {
    "de": "Aufprall erkannt. Zum Startpunkt zurückkehren oder landen",
    "en": "Impact detected. Return to home or land",
    "es": "Impacto detectado. Regresa al punto de origen o aterriza",
    "fr": "Impact détecté. Revenez au point de départ ou atterrissez",
    "ja": "衝撃を検出。帰還するか、着陸してください",
    "ko": "충격 감지됨. RTH 진행 또는 착륙 필요",
    "ru": "Столкновение дрона. Вернитесь домой или приземлитесь",
    "tr": "Etki tespit edildi. Eve geri dönün veya iniş yapın",
    "zh": "检测到飞行器发生过撞击，请返航或降落"
  },
  "fpv_tip_0x16100080": {
    "de": "Kontrollfehler: Höhenlage. Start nicht möglich. Fluggerät neu starten",
    "en": "Aircraft altitude control error. Unable to take off. Restart aircraft",
    "es": "Error de control de la altitud de la aeronave. No se puede despegar. Reinicia la aeronave",
    "fr": "Erreur de contrôle de l\\'altitude de l\\'appareil. Impossible de décoller. Redémarrez l\\'appareil",
    "ja": "機体高度制御エラー。離陸できません。機体を再起動してください",
    "ko": "기체 고도 제어 오류. 이륙 불가. 기체 재시작 필요",
    "ru": "Ошибка управления высотой дрона. Взлет невозможен. Перезагрузите дрон",
    "tr": "Araç irtifa kontrol hatası. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：飞行器高度控制异常，请重启飞行器"
  },
  "fpv_tip_0x16100080_in_the_sky": {
    "de": "Kontrollfehler: Höhenlage. Zum Startpunkt zurückkehren oder landen",
    "en": "Aircraft altitude control error. RTH or land",
    "es": "Error de control de la altitud de la aeronave. RPO o aterriza",
    "fr": "Erreur de contrôle de l\\'altitude de l\\'appareil. Revenez au point de départ ou atterrissez",
    "ja": "機体高度制御エラー。RTHを行うか、着陸してください",
    "ko": "기체 고도 제어 오류. RTH 진행 또는 착륙 필요",
    "ru": "Ошибка изменения высоты дрона. Вернитесь домой или приземлитесь",
    "tr": "Araç irtifa kontrol hatası. Eve dönün ya da iniş yapın",
    "zh": "飞行器高度控制异常，请返航或降落"
  },
  "fpv_tip_0x16100081": {
    "de": "Firmware-Version des Akkus veraltet. Start nicht möglich. Auf die neueste Firmware-Version aktualisieren",
    "en": "Battery firmware out-of-date. Unable to take off. Update to latest firmware version",
    "es": "Firmware de la batería obsoleto. No se puede despegar. Actualiza a la versión del firmware más reciente",
    "fr": "Firmware de la batterie obsolète. Impossible de décoller. Mettez à jour vers la version la plus récente",
    "ja": "古いバッテリーファームウェアです。離陸できません。最新のファームウェアバージョンに更新してください",
    "ko": "오래된 배터리 펌웨어. 이륙 불가. 최신 펌웨어 버전으로 업데이트해야 함",
    "ru": "Устаревшая версия ПО батареи . Взлет невозможен. Установите последнюю версию ПО",
    "tr": "Pil yazılımı güncel değil. Kalkış yapamıyor. En güncel yazılım sürümüne güncelleyin",
    "zh": "无法起飞：电池固件版本过低，请更新飞行器固件"
  },
  "fpv_tip_0x16100081_in_the_sky": {
    "de": "Firmware-Version des Akkus veraltet. Zum Startpunkt zurückkehren oder landen. Auf die neueste Firmware-Version aktualisieren",
    "en": "Battery firmware version out of date. Return to home or land. Update to latest firmware version",
    "es": "Versión del firmware de la batería obsoleta. Regresa al punto de origen o aterriza. Actualiza a la versión del firmware más reciente",
    "fr": "Version du firmware de la batterie obsolète. Revenez au point de départ ou atterrissez. Mettez à jour vers la version la plus récente",
    "ja": "バッテリーのファームウェアバージョンが古くなっています。帰還するか、着陸してください。最新のファームウェアバージョンに更新してください",
    "ko": "오래된 배터리 펌웨어 버전. RTH 진행 또는 착륙 필요. 최신 펌웨어 버전으로 업데이트해야 함",
    "ru": "Старая версия ПО батареи. Вернитесь домой или приземлитесь. Установите последнюю версию ПО",
    "tr": "Pil yazılım sürümü güncel değil. Eve geri dönün veya iniş yapın. En güncel yazılım sürümüne güncelleyin",
    "zh": "电池固件版本过低，请返航或降落，并更新飞行器固件"
  },
  "fpv_tip_0x16100082": {
    "de": "Zu großer Spannungsunterschied zwischen den Akkus. Start nicht möglich. Akkuwartung notwendig",
    "en": "Large voltage different between battery cells. Unable to take off. Battery maintenance required",
    "es": "Gran diferencia de voltaje entre las células de batería. No se puede despegar. La batería requiere mantenimiento",
    "fr": "Différence de tension entre les cellules de la batterie importante. Impossible de décoller. Opération de maintenance de la batterie requise",
    "ja": "バッテリーセルの電圧差が大きすぎます。離陸できません。バッテリーのメンテナンスが必要です",
    "ko": "배터리 셀 간 전압차 너무 큼. 이륙 불가. 배터리 유지 보수 필요",
    "ru": "Обнаружена большая разница между напряжением элементов аккумулятора. Требуется техосмотр батареи",
    "tr": "Pil hücreleri arasındaki voltaj farkı yüksek. Kalkış yapamıyor. Pil bakımı gerekli",
    "zh": "无法起飞：电池电芯压差过大，请对电池进行保养"
  },
  "fpv_tip_0x16100082_in_the_sky": {
    "de": "Zu großer Spannungsunterschied zwischen den Akkus. Start nicht möglich. Akkuwartung notwendig",
    "en": "Large difference in battery cell voltage detected. Return to home or land. Battery maintenance required",
    "es": "Se ha detectado una gran diferencia de voltaje entre las células de batería. Regresa al punto de origen o aterriza. La batería requiere mantenimiento",
    "fr": "Différence de tension entre les cellules de la batterie importante. Revenez au point de départ ou atterrissez. Maintenance de la batterie requise",
    "ja": "バッテリーセルに大きい電圧差が検出されました。帰還するか、着陸してください。バッテリーのメンテナンスが必要です",
    "ko": "배터리 셀 전압의 큰 차이가 감지됨. RTH 진행 또는 착륙 필요. 배터리 유지 보수 필요",
    "ru": "Обнаружена большая разница между напряжением элементов аккумулятора. Требуется техосмотр батареи",
    "tr": "Pil hücreleri arasında yüksek voltaj farkı tespit edildi. Eve geri dönün veya iniş yapın. Pil bakımı gerekli",
    "zh": "电池电芯压差过大，请返航或降落，再对电池进行保养"
  },
  "fpv_tip_0x16100083": {
    "de": "Akkufehler. Start nicht möglich. Sicherstellen, dass Akkus bis zum Anschlag eingeschoben sind und beide Akkus fest sitzen",
    "en": "Battery installed incorrectly. Unable to take off. Turn battery locker to its limit and ensure both batteries are installed correctly",
    "es": "Batería instalada de forma incorrecta. No se puede despegar. Gira el seguro de la batería hasta el tope y asegúrate de que las dos baterías estén instaladas correctamente",
    "fr": "Batterie mal installée. Impossible de décoller. Tournez le verrou de la batterie jusqu\\'à sa limite et assurez-vous que les deux batteries sont installées correctement",
    "ja": "バッテリーが正しく取り付けられていません。離陸できません。バッテリーロッカーを限界まで回し、両方のバッテリーが正しく取り付けられていることを確認してください",
    "ko": "배터리 설치 잘못됨. 이륙 불가. 배터리 잠금장치를 최대한으로 회전시키고 배터리가 모두 잘 설치되었는지 확인 필요",
    "ru": "Батарея установлена некорректно. Взлет невозможен. Закройте защелку батареи до упора и убедитесь, что оба аккумулятора установлены корректно",
    "tr": "Pil hatalı takılmış. Kalkış yapamıyor. Pil kilidini sonuna kadar çevirin ve her iki pilin de düzgün şekilde yerleştirildiğinden emin olun",
    "zh": "无法起飞：电池安装不到位，请将电池锁紧旋钮旋转到位"
  },
  "fpv_tip_0x16100083_in_the_sky": {
    "de": "Akku falsch eingesetzt/installiert. Zum Startpunkt zurückkehren oder landen. Die Akkusperre maximal setzen und sicherstellen, dass beide Akkus fest sitzen bzw. installiert sind .",
    "en": "Battery installed incorrectly. Return to home or land. Turn battery locker to its limit and ensure both batteries are installed correctly",
    "es": "Batería instalada de forma incorrecta. Regresa al punto de origen o aterriza. Gira el seguro de la batería hasta el tope y asegúrate de que las dos baterías estén instaladas correctamente",
    "fr": "Batterie mal installée. Revenez au point de départ ou atterrissez. Tournez le verrou de la batterie jusqu\\'à sa limite et assurez-vous que les deux batteries sont installées correctement",
    "ja": "バッテリーが正しく取り付けられていません。帰還するか、着陸してください。バッテリーロッカーを限界まで回し、両方のバッテリーが正しく取り付けられていることを確認してください",
    "ko": "배터리 설치 잘못됨. RTH 또는 착륙 필요. 배터리 잠금장치를 최대한으로 회전시키고 배터리가 모두 잘 설치되었는지 확인 필요",
    "ru": "Батарея установлена некорректно. Вернитесь домой или приземлитесь. Закройте защелку батареи до упора и убедитесь, что оба аккумулятора установлены корректно",
    "tr": "Pil hatalı takılmış. Eve geri dönün veya iniş yapın. Pil kilidini sonuna kadar çevirin ve her iki pilin de düzgün şekilde yerleştirildiğinden emin olun",
    "zh": "电池安装不到位，请返航或降落，并检查电池锁紧旋是否钮旋转到位"
  },
  "fpv_tip_0x16100084": {
    "ar": "",
    "de": "Fehler: oberer Lüfter. Start nicht möglich. Überprüfen, ob der obere Lüfter blockiert ist",
    "en": "Upper fan error. Unable to take off. Check whether upper fan is stalled",
    "es": "Error del ventilador superior. No se puede despegar. Compruebe si el ventilador superior está atascado",
    "fr": "Erreur du ventilateur supérieur. Impossible de décoller. Vérifiez si le ventilateur supérieur est bloqué",
    "id": "",
    "it": "",
    "ja": "上部ファンエラー。離陸できません。上部ファンが停止していないかを確認してください",
    "ko": "상단 팬 오류입니다. 이륙 불가. 상단 팬이 멈췄는지 확인하세요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Ошибка верхнего вентилятора. Не удается выполнить взлет. Убедитесь, что верхний вентилятор не заклинило",
    "th": "",
    "tr": "Üst fan hatası. Kalkış yapılamıyor. Üst fanın arızalanarak durup durmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "无法起飞：上风扇异常，请检查飞行器上风扇是否有堵转",
    "zh-Hant": ""
  },
  "fpv_tip_0x16100084_in_the_sky": {
    "ar": "",
    "de": "Fehler: oberer Lüfter. Zum Startpunkt zurückkehren oder landen. Überprüfen, ob der obere Lüfter blockiert ist",
    "en": "Upper fan error. Return to home or land. Check whether upper fan is stalled",
    "es": "Error del ventilador superior. Regrese al punto de origen o aterrice. Compruebe si el ventilador superior está atascado",
    "fr": "Erreur du ventilateur supérieur. Revenez au point de départ ou atterrissez. Vérifiez si le ventilateur supérieur est bloqué",
    "id": "",
    "it": "",
    "ja": "上部ファンエラー。RTHまたは着陸させてください。上部ファンが停止していないかを確認してください",
    "ko": "상단 팬 오류입니다. 홈으로 돌아가거나 착륙하세요. 상단 팬이 멈췄는지 확인하세요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Ошибка верхнего вентилятора. Вернитесь домой или приземлитесь. Убедитесь, что верхний вентилятор не заклинило",
    "th": "",
    "tr": "Üst fan hatası. Eve geri dönün veya iniş yapın. Üst fanın arızalanarak durup durmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "上风扇异常，请返航或降落，再检查飞行器上风扇是否有堵转",
    "zh-Hant": ""
  },
  "fpv_tip_0x16100085": {
    "de": "Fluggerät überhitzt. Start nicht möglich. Fluggerät ausschalten und warten, bis sich die Temperatur wieder normalisiert hat",
    "en": "Aircraft overheated. Unable to take off. Power off aircraft and wait for temperature to return to normal",
    "es": "Aeronave sobrecalentada. No se puede despegar. Apaga la aeronave y espera a que la temperatura vuelva a la normalidad",
    "fr": "Surchauffe de l\\'appareil. Impossible de décoller. Éteignez-le et attendez que la température revienne à la normale",
    "ja": "機体が高温。離陸できません。機体の電源を切り、通常の温度に戻るまで待ってください",
    "ko": "기체 과열됨. 이륙 불가. 기체 전원을 끄고 정상 온도로 내려갈 때까지 기다려야 함",
    "ru": "Перегрев дрона. Взлет невозможен. Выключите дрон и дождитесь, пока он остынет",
    "tr": "Araç aşırı ısındı. Kalkış yapamıyor. Aracı kapatın ve sıcaklığın normale dönmesini bekleyin",
    "zh": "无法起飞：飞行器过热，请关闭飞行器至恢复正常温度"
  },
  "fpv_tip_0x16100085_in_the_sky": {
    "de": "Flugzeuge überhitzt. Zum Startpunkt zurückkehren oder landen. Fluggerät ausschalten und warten, bis sich die Temperatur wieder normalisiert hat .",
    "en": "Aircraft overheated. Return to home or land. Power off aircraft and wait for temperature to return to normal",
    "es": "Aeronave sobrecalentada. Regresa al punto de origen o aterriza. Apaga la aeronave y espera a que la temperatura vuelva a la normalidad",
    "fr": "Surchauffe de l\\'appareil. Revenez au point de départ ou atterrissez. Éteignez-le et attendez que la température revienne à la normale",
    "ja": "機体が高温。帰還するか、着陸してください。機体の電源を切り、通常の温度に戻るまで待ってください",
    "ko": "기체 과열됨. RTH 또는 착륙 필요. 기체 전원을 끄고 정상 온도로 내려갈 때까지 기다려야 함",
    "ru": "Перегрев дрона. Вернитесь домой или приземлитесь. Выключите дрон и дождитесь, пока он остынет",
    "tr": "Araç aşırı ısındı. Eve geri dönün veya iniş yapın. Aracı kapatın ve sıcaklığın normale dönmesini bekleyin",
    "zh": "飞行器过热，请返航或降落，再关闭飞行器至恢复正常温度"
  },
  "fpv_tip_0x16100087": {
    "de": "Motoren-Notstopp eingeleitet . Start nicht möglich",
    "en": "Emergency Propeller Stop triggered. Unable to take off",
    "es": "Se ha activado la parada de emergencia de la hélice. No se puede despegar",
    "fr": "Arrêt d\\'urgence des hélices activé . Impossible de décoller.",
    "ja": "プロペラ緊急停止が作動しました。離陸できません",
    "ko": "비상 프로펠러 정지 작동됨 . 이륙 불가",
    "ru": "Активирована экстренная остановка пропеллеров . Взлет невозможен",
    "tr": "Acil Durum Pervane Durdurma tetiklendi. Kalkış yapamıyor",
    "zh": "无法起飞：已触发紧急停桨"
  },
  "fpv_tip_0x16100087_in_the_sky": {
    "de": "Motoren-Notstopp eingeleitet",
    "en": "Emergency Propeller Stop triggered",
    "es": "Se ha activado la parada de emergencia de la hélice",
    "fr": "Arrêt d\\'urgence des hélices activé",
    "ja": "プロペラ緊急停止が作動しました",
    "ko": "비상 프로펠러 정지 작동됨",
    "ru": "Активирована экстренная остановка пропеллеров",
    "tr": "Acil Durum Pervane Durdurma tetiklendi",
    "zh": "已触发紧急停浆"
  },
  "fpv_tip_0x16100088": {
    "de": "Start nicht möglich. Fernsteuerung wird kalibriert. Kalibrierung zuerst beenden",
    "en": "Unable to take off. Calibrating remote controller. Exit calibration first",
    "es": "No se puede despegar. Calibrando el control remoto. Salir primero de la calibración",
    "fr": "Impossible de décoller. Étalonnage de la radiocommande. Quitter d’abord l’étalonnage",
    "ja": "離陸できません。送信機をキャリブレーション中です。まずキャリブレーションを終了してください",
    "ko": "이륙 불가. 조종기 캘리브레이션 중. 먼저 캘리브레이션을 종료하세요",
    "ru": "Невозможно взлететь. Калибровка пульта. Сначала выйдите из калибровки",
    "tr": "Kalkış yapılamıyor. Uzaktan kumanda kalibre ediliyor. Önce kalibrasyondan çıkın",
    "zh": "无法起飞：遥控器校准中，请先退出校准状态"
  },
  "fpv_tip_0x16100088_in_the_sky": {
    "de": "Start nicht möglich. Fernsteuerung wird kalibriert. Kalibrierung zuerst beenden",
    "en": "Unable to take off. Calibrating remote controller. Exit calibration first",
    "es": "No se puede despegar. Calibrando el control remoto. Salir primero de la calibración",
    "fr": "Impossible de décoller. Étalonnage de la radiocommande. Quitter d’abord l’étalonnage",
    "ja": "離陸できません。送信機をキャリブレーション中です。まずキャリブレーションを終了してください",
    "ko": "이륙 불가. 조종기 캘리브레이션 중. 먼저 캘리브레이션을 종료하세요",
    "ru": "Невозможно взлететь. Калибровка пульта. Сначала выйдите из калибровки",
    "tr": "Kalkış yapılamıyor. Uzaktan kumanda kalibre ediliyor. Önce kalibrasyondan çıkın",
    "zh": "无法起飞：遥控器校准中，请先退出校准状态"
  },
  "fpv_tip_0x16100089": {
    "ar": "",
    "de": "Start nicht möglich. Fernsteuerung nicht an das aktuelle Fluggerät gekoppelt oder Kopplungsstatus muss noch bestätigt werden. Verknüpfte Fernsteuerung verwenden oder Fluggerät mit aktueller Fernsteuerung verknüpfen",
    "en": "Unable to take off. Remote controller not bound/still confirming binding status. Use bound remote controller or bind aircraft to current remote controller",
    "es": "No se puede despegar. Control remoto no vinculado/estado de vinculación aún en confirmación. Usa el control remoto vinculado o vincula la aeronave al control remoto actual",
    "fr": "Unable to take off. Radiocommande non liée à l'appareil/en attente de confirmation de liaison. Use bound remote controller or bind aircraft to current remote controller",
    "id": "",
    "it": "",
    "ja": "離陸できません。送信機が紐付けされていない／紐付けステータスを確認中。バインドされている送信機を使用するか、機体を現在の送信機にバインドします",
    "ko": "이륙 불가. 조종기 바인딩 안 됨/바인딩 상태 확인 중. 바인딩된 조종기를 사용하거나 기체를 현재 조종기에 바인딩하세요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Не удается выполнить взлет. Пульт управления не привязан к дрону, или статус привязки пока не подтвержден. Используйте привязанный пульт дистанционного управления или привяжите дрон к текущему пульту дистанционного управления",
    "th": "",
    "tr": "Kalkış yapılamıyor. Uzaktan kumanda bağlanmadı/hala bağlanma durumunu onaylıyor. Bağlı uzaktan kumandayı kullanın veya hava aracını geçerli uzaktan kumandaya bağlayın",
    "ug": "",
    "vi": "",
    "zh": "无法起飞：绑定设备不匹配或正在校验中，请使用绑定遥控器或绑定当下遥控器。",
    "zh-Hant": ""
  },
  "fpv_tip_0x16100089_in_the_sky": {
    "de": "Fehler beim Abheben. Fehler beim Überprüfen der Fernsteuerung. Verknüpfte Fernsteuerung verwenden oder Fluggerät mit aktueller Fernsteuerung verknüpfen",
    "en": "Takeoff error. Failed to verify remote controller. Use bound remote controller or bind aircraft to current remote controller",
    "es": "Error de despegue. No se ha podido verificar el control remoto. Usa el control remoto vinculado o vincula la aeronave al control remoto actual",
    "fr": "Erreur au décollage Failed to verify remote controller. Use bound remote controller or bind aircraft to current remote controller",
    "ja": "離陸エラーです。送信機の検証に失敗しました。バインドされている送信機を使用するか、機体を現在の送信機にバインドします",
    "ko": "이륙 오류. 조종기 인증 실패. 바인딩된 조종기를 사용하거나 기체를 현재 조종기에 바인딩하세요",
    "ru": "Ошибка при взлете. Не удалось проверить пульт ДУ. Используйте привязанный пульт дистанционного управления или привяжите дрон к текущему пульту дистанционного управления",
    "tr": "Kalkış hatası. Uzaktan kumanda onaylanamadı. Bağlı uzaktan kumandayı kullanın veya hava aracını geçerli uzaktan kumandaya bağlayın",
    "zh": "异常起飞：遥控器绑定校验失败，请使用绑定遥控器或绑定当下遥控器。"
  },
  "fpv_tip_0x1610008A": {
    "de": "Fehler: Flugregler . Start nicht möglich. Fluggerät neu starten",
    "en": "Flight controller unit error. Unable to take off. Restart aircraft",
    "es": "Error de la unidad del controlador de vuelo. No se puede despegar. Reinicia la aeronave",
    "fr": "Erreur de l\\'unité de contrôle du vol . Impossible de décoller. Redémarrez l\\'appareil",
    "ja": "フライトコントローラー ユニットエラー。離陸できません。機体を再起動してください",
    "ko": "비행 컨트롤러 장치 오류 . 이륙 불가. 기체 재시작 필요",
    "ru": "Ошибка блока полетного контроллера . Взлет невозможен. Перезагрузите дрон",
    "tr": "Uçuş kontrol birimi hatası. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：飞控系统组件异常，请重启飞行器"
  },
  "fpv_tip_0x1610008A_in_the_sky": {
    "de": "Fehler der Flugsteuerungseinheit . Zum Startpunkt zurückkehren oder landen.",
    "en": "Flight controller unit error. Return to home or land",
    "es": "Error de la unidad del controlador de vuelo. Regresa al punto de origen o aterriza",
    "fr": "Erreur unité du contrôleur de vol . Revenez au point de départ ou atterrissez",
    "ja": "フライトコントローラー ユニットエラー。帰還するか、着陸してください",
    "ko": "비행 컨트롤러 장치 오류 . RTH 진행 또는 착륙 필요",
    "ru": "Ошибка полетного контроллера . Вернитесь домой или приземлитесь",
    "tr": "Uçuş kontrol birimi hatası. Eve geri dönün veya iniş yapın",
    "zh": "飞控系统组件异常，请返航或降落"
  },
  "fpv_tip_0x1610008F": {
    "de": "Fluggerät-Antenne: Fehler bei der Suche nach Satellitensignal. Start nicht möglich. Zum Start in einen offenen Bereich gehen",
    "en": "Aircraft antenna satellite signal searching error. Unable to take off. Move to an open area for takeoff",
    "es": "Error de búsqueda de señal satelital de la antena de la aeronave. No se puede despegar. Desplázate a un espacio abierto para despegar",
    "fr": "Erreur de recherche du signal satellite de l\\'antenne de l\\'appareil. Décollage impossible. Déplacez-vous vers une zone dégagée pour décoller",
    "ja": "機体アンテナ衛星信号検索エラー。離陸できません。開けたエリアに移動して離陸してください",
    "ko": "기체 안테나 위성 신호 검색 오류. 이륙할 수 없습니다. 이륙을 위해 개방 영역으로 이동하세요",
    "ru": "Ошибка поиска сигнала спутника для антенны дрона. Невозможно выполнить взлет. Переместитесь на открытый участок для взлета",
    "tr": "Araç anteni uydu sinyal arama hatası. Kalkış yapamıyor. Kalkış için açık bir alana gidin",
    "zh": "无法起飞：机载天线搜星质量较差，请至空旷地起飞"
  },
  "fpv_tip_0x1610008F_in_the_sky": {
    "de": "Fluggerät-Antenne: Fehler bei der Suche nach Satellitensignal. Mit Vorsicht fliegen",
    "en": "Aircraft antenna satellite signal searching error. Fly with caution",
    "es": "Error de búsqueda de señal satelital de la antena de la aeronave. Vuela con cuidado",
    "fr": "Erreur de recherche du signal satellite de l\\'antenne de l\\'appareil. Volez avec précaution",
    "ja": "機体アンテナ衛星信号検索エラー。慎重に飛行してください",
    "ko": "기체 안테나 위성 신호 검색 오류. 주의해서 비행하세요",
    "ru": "Ошибка поиска сигнала спутника для антенны дрона. Будьте внимательны при полете",
    "tr": "Araç anteni uydu sinyal arama hatası. Dikkatli uçurun",
    "zh": "机载天线搜星质量较差，请谨慎飞行"
  },
  "fpv_tip_0x16100090": {
    "de": "Fehler: Sensorsystem . Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs . Redémarrez l\\'appareil",
    "ja": "センサー システムエラー。機体を再起動してください",
    "ko": "센서 시스템 오류 . 기체 재시동",
    "ru": "Ошибка системы датчиков . Перезапустите дрон",
    "tr": "Sensör sistemi hatası. Aracı yeniden başlatın",
    "zh": "无法起飞：传感器系统异常，请重启飞行器"
  },
  "fpv_tip_0x16100090_in_the_sky": {
    "de": "Fehler: Sensorsystem . Zum Startpunkt zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land",
    "es": "Error del sistema de sensores. Regresa al punto de origen o aterriza",
    "fr": "Erreur du système de capteurs . Revenez au point de départ ou atterrissez",
    "ja": "センサーシステムエラー。帰還するか着陸して下さい",
    "ko": "센서 시스템 오류 . 홈으로 돌아가거나 착륙",
    "ru": "Ошибка системы датчиков . Вернитесь домой или приземлитесь",
    "tr": "Sensör sistemi hatası. Eve geri dönün veya iniş yapın",
    "zh": "传感器系统异常,请返航或降落"
  },
  "fpv_tip_0x16100091": {
    "de": "Flug im aktuellen Bereich verboten . Start nicht möglich",
    "en": "Flight prohibited in current area. Unable to take off",
    "es": "Está prohibido volar en la zona actual. No se puede despegar",
    "fr": "Vol interdit dans cette zone . Impossible de décoller",
    "ja": "現在のエリアの飛行は禁止されています。離陸できません",
    "ko": "현재 구역에서 비행 금지 . 이륙 불가",
    "ru": "Полет запрещен в данной области . Взлет невозможен",
    "tr": "Mevcut alanda uçuş yasaktır. Kalkış yapamıyor",
    "zh": "无法起飞：该飞行器无法在此区域飞行"
  },
  "fpv_tip_0x16100091_in_the_sky": {
    "de": "Fliegen in diesem Bereich verboten (alarmierend). Zum Startpunkt zurückkehren oder landen.",
    "en": "Flying prohibited in current area. Return to home or land",
    "es": "Está prohibido volar en la zona actual. Regresa al punto de origen o aterriza",
    "fr": "Vol interdit dans cette zone . Revenez au point de départ ou atterrissez",
    "ja": "現在のエリアの飛行は禁止されています。帰還するか、着陸してください",
    "ko": "현재 구역에서 비행 금지 . RTH 진행 또는 착륙 필요",
    "ru": "Полет запрещен в данной области . Вернитесь домой или приземлитесь",
    "tr": "Mevcut alanda uçuş yasaktır. Eve geri dönün veya iniş yapın",
    "zh": "该飞行器无法在此区域飞行，请返航或降落"
  },
  "fpv_tip_0x16100091_in_the_sky_special": {
    "de": "Fliegen in diesem Bereich nicht möglich. Max. Flughöhe auf %1$s m beschränkt",
    "en": "Unable to fly in this area. Max flight altitude limited to %1$s m",
    "es": "No se puede volar en esta zona. Altitud de vuelo máxima limitada a %1$s m",
    "fr": "Impossible de voler dans cette zone. Altitude en vol max limitée à %1$s m",
    "ja": "このエリアでは飛行できません。最大飛行高度が%1$s mに制限されています",
    "ko": "이 지역에서 비행 불가. 최고 비행 고도가 %1$sm로 제한됨.",
    "ru": "Полет в этой области невозможен. Максимальная высота полета ограничена до %1$s м",
    "tr": "Bu bölgede uçulamıyor. Maks. uçuş irtifası %1$s m ile sınırlıdır",
    "zh": "该飞行器无法在此区域飞行，限高%1$s m"
  },
  "fpv_tip_0x16100092": {
    "de": "Fehler: Akkukapazität. Start nicht möglich. Akkuwartung notwendig",
    "en": "Battery capacity error. Unable to take off. Battery maintenance required",
    "es": "Error de capacidad de la batería. No se puede despegar. La batería requiere mantenimiento",
    "fr": "Erreur de capacité de la batterie. Impossible de décoller. Opération de maintenance de la batterie requise",
    "ja": "バッテリー容量エラー。離陸できません。バッテリーのメンテナンスが必要です",
    "ko": "배터리 용량 오류. 이륙 불가. 배터리 유지 보수 필요",
    "ru": "Ошибка емкости батареи. Взлет невозможен. Требуется ее техосмотр",
    "tr": "Pil kapasitesi hatası. Kalkış yapamıyor. Pil bakımı gerekli",
    "zh": "无法起飞：电池电量异常，请进行电池保养"
  },
  "fpv_tip_0x16100092_in_the_sky": {
    "de": "Fehler: Akkukapazität. Start nicht möglich. Akkuwartung notwendig",
    "en": "Battery capacity error. Return to home or land. Battery maintenance required",
    "es": "Error de capacidad de la batería. Regresa al punto de origen o aterriza. La batería requiere mantenimiento",
    "fr": "Erreur de capacité de la batterie. Revenez au point de départ ou atterrissez",
    "ja": "バッテリー容量エラー。帰還するか、着陸してください。バッテリーのメンテナンスが必要です",
    "ko": "배터리 용량 오류. RTH 진행 또는 착륙 필요. 배터리 유지 보수 필요",
    "ru": "Ошибка емкости батареи. Вернитесь домой или приземлитесь. Требуется техосмотр батареи",
    "tr": "Pil kapasitesi hatası. Eve geri dönün veya iniş yapın. Pil bakımı gerekli",
    "zh": "电池电量异常，请返航或降落，再进行电池保养"
  },
  "fpv_tip_0x16100095": {
    "de": "Unable to take off. Set Hover mode before flying",
    "en": "Unable to take off. Set Hover mode before flying",
    "es": "Unable to take off. Set Hover mode before flying",
    "fr": "Unable to take off. Set Hover mode before flying",
    "ja": "Unable to take off. Set Hover mode before flying",
    "ko": "Unable to take off. Set Hover mode before flying",
    "ru": "Unable to take off. Set Hover mode before flying",
    "tr": "Unable to take off. Set Hover mode before flying",
    "zh": "无法起飞：请在起飞前设置飞行器悬停模式"
  },
  "fpv_tip_0x16100096": {
    "de": "%battery_index Fehler Akkustromversorgung. Fluggerät landet automatisch",
    "en": "%battery_index Battery power supply error. Aircraft auto landing",
    "es": "%battery_index Error de la fuente de alimentación de la batería. Aterrizaje automático de la aeronave",
    "fr": "%battery_index Erreur d’alimentation de la batterie. Atterrissage automatique de l’appareil",
    "ja": "%battery_indexバッテリー電源エラー。機体が自動着陸中",
    "ko": "%battery_index 배터리 전력 공급 장치 오류. 기체 자동 착륙",
    "ru": "%battery_index Ошибка питания АКБ. Автоматическая посадка дрона",
    "tr": "%battery_index Pil güç kaynağı hatası. Hava aracı otomatik iniş yapıyor",
    "zh": "%battery_index电池供电异常，飞行器强制降落"
  },
  "fpv_tip_0x16100099": {
    "de": "Fehler: Gimbal. Start nicht möglich. Überprüfen, ob sich der Gimbal frei drehen kann und Fluggerät neu starten",
    "en": "Gimbal starting error. Unable to take off. Check whether gimbal can rotate freely and restart aircraft",
    "es": "Error de inicio del estabilizador. No se puede despegar. Comprueba si el estabilizador puede rotar con libertad y reinicia la aeronave",
    "fr": "Erreur de démarrage de la nacelle. Impossible de décoller. Vérifiez si la nacelle peut tourner librement et redémarrez l\\'appareil",
    "ja": "ジンバル開始エラー。離陸できません。ジンバルが制限なく回転するか確認して、機体を再起動してください",
    "ko": "짐벌 시작 오류. 이륙 불가. 짐벌이 자유롭게 회전할 수 있는지 확인하고 기체를 다시 시작해야 함",
    "ru": "Ошибка запуска стабилизатора. Взлет невозможен. Проверьте, поворачивается ли стабилизатор свободно, и перезапустите дрон",
    "tr": "Gimbal başlatma hatası. Kalkış yapamıyor. Gimbalın rahatça hareket edip etmediğini kontrol edin ve aracı yeniden başlatın",
    "zh": "无法起飞：云台启动异常，请检查云台是否能自由转动，再重启飞行器"
  },
  "fpv_tip_0x16100099_in_the_sky": {
    "de": "Gimbal-Startfehler. Zum Startpunkt zurückkehren oder landen. Überprüfen, ob sich der Gimbal frei drehen kann und starten Sie das Fluggerät neu .",
    "en": "Gimbal startup error. Return to home or land. Check whether gimbal can rotate freely and restart aircraft",
    "es": "Error de inicio del estabilizador. Regresa al punto de origen o aterriza. Comprueba si el estabilizador puede rotar con libertad y reinicia la aeronave",
    "fr": "Erreur de démarrage de la nacelle. Revenez au point de départ ou atterrissez. Vérifiez si la nacelle peut tourner librement et redémarrez l\\'appareil",
    "ja": "ジンバル開始エラー。帰還するか、着陸してください。ジンバルが制限なく回転するか確認して、機体を再起動してください",
    "ko": "짐벌 시작 오류. RTH 진행 또는 착륙 필요. 짐벌이 자유롭게 회전할 수 있는지 확인하고 기체를 다시 시작해야 함",
    "ru": "Ошибка запуска стабилизатора. Вернитесь домой или приземлитесь. Проверьте, поворачивается ли стабилизатор свободно, и перезапустите дрон",
    "tr": "Gimbal başlatma hatası. Eve geri dönün veya iniş yapın. Gimbalın rahatça hareket edip etmediğini kontrol edin ve aracı yeniden başlatın",
    "zh": "云台启动异常，请返航或降落，再检查云台是否能自由转动，再重启飞行器"
  },
  "fpv_tip_0x1610009A": {
    "de": "Starke Vibrationen am Gimbal. Start nicht möglich. Bitte Gimbal überprüfen und Fluggerät neu starten",
    "en": "Excessive gimbal vibration. Unable to take off. Check whether gimbal can rotate freely or is damaged. Restart aircraft",
    "es": "Vibración del estabilizador excesiva. No se puede despegar. Comprueba si el estabilizador puede rotar con libertad o si está dañado. Reinicia la aeronave",
    "fr": "Vibrations excessives de la nacelle. Impossible de décoller. Vérifiez si la nacelle peut tourner librement ou si elle est endommagée. Redémarrez l\\'appareil",
    "ja": "ジンバルの過剰な振動。離陸できません。ジンバルが制限なく回転するか、または損傷していないか確認してください。機体を再起動してください",
    "ko": "과도한 짐벌 진동. 이륙 불가. 짐벌이 자유롭게 회전할 수 있는지 또는 손상되었는지 확인 필요. 기체를 다시 시작해야 함",
    "ru": "Избыточная вибрация стабилизатора. Взлет невозможен. Убедитесь, что стабилизатор поворачивается свободно и не поврежден. Перезагрузите дрон",
    "tr": "Gimbal aşırı titriyor. Kalkış yapamıyor. Gimbalın rahatça hareket edip etmediğini ve hasar görüp görmediğini kontrol edin. Aracı yeniden başlatın",
    "zh": "无法起飞：云台异常振动，请检查云台是否能自由转动、是否受损，再重启飞行器"
  },
  "fpv_tip_0x1610009A_in_the_sky": {
    "de": "Exzessive Vibrationen am Gimbal. Zum Startpunkt zurückkehren oder landen. Bitte Gimbal überprüfen und Fluggerät neu starten",
    "en": "Excessive gimbal vibration. Return to home or land. Check whether gimbal can rotate freely or is damaged. Restart aircraft",
    "es": "Vibración del estabilizador excesiva. Regresa al punto de origen o aterriza. Comprueba si el estabilizador puede rotar con libertad o si está dañado. Reinicia la aeronave",
    "fr": "Vibrations excessives de la nacelle. Revenez au point de départ ou atterrissez. Vérifiez si la nacelle peut tourner librement ou si elle est endommagée. Redémarrez l\\'appareil",
    "ja": "ジンバルの過剰な振動。帰還するか、着陸してください。ジンバルが制限なく回転するか、または損傷していないか確認してください。機体を再起動してください",
    "ko": "과도한 짐벌 진동. RTH 진행 또는 착륙 필요. 짐벌이 자유롭게 회전할 수 있는지 또는 손상되었는지 확인 필요. 기체를 다시 시작해야 함",
    "ru": "Избыточная вибрация стабилизатора. Вернитесь домой или приземлитесь. Убедитесь, что стабилизатор поворачивается свободно и не поврежден. Перезагрузите дрон",
    "tr": "Gimbal aşırı titriyor. Eve geri dönün veya iniş yapın. Gimbalın rahatça hareket edip etmediğini ve hasar görüp görmediğini kontrol edin. Aracı yeniden başlatın",
    "zh": "云台异常振动，请返航或降落，再检查云台是否能自由转动、是否受损，再重启飞行器"
  },
  "fpv_tip_0x1610009F": {
    "ar": "",
    "de": "Gerätefehler: Flugregler. Start nicht möglich. Fluggerät neu starten",
    "en": "Flight controller unit error. Unable to take off. Restart aircraft",
    "es": "Error de la unidad del controlador de vuelo. No se puede despegar. Reinicia la aeronave",
    "fr": "Erreur de l\\'unité de contrôle du vol. Impossible de décoller. Redémarrez l\\'appareil",
    "it": "",
    "ja": "フライトコントローラー ユニットエラー。離陸できません。機体を再起動してください",
    "ko": "비행 컨트롤러 장치 오류. 이륙 불가. 기체 재시작 필요",
    "pt": "",
    "ru": "Ошибка блока полетного контроллера. Невозможно выполнить взлет. Перезагрузите дрон",
    "tr": "Uçuş kontrol birimi hatası. Kalkış yapamıyor. Aracı yeniden başlatın",
    "zh": "无法起飞：飞控系统组件异常，请重启飞行器"
  },
  "fpv_tip_0x1610009F_in_the_sky": {
    "de": "Gerätefehler: Flugregler. Zum Startpunkt zurückkehren oder landen",
    "en": "Flight controller unit error. Return to home or land",
    "es": "Error de la unidad del controlador de vuelo. Regresa al punto de origen o aterriza",
    "fr": "Erreur unité du contrôleur de vol. Revenez au point de départ ou atterrissez",
    "ja": "フライトコントローラーユニットエラー。帰還するか着陸してください",
    "ko": "비행 컨트롤러 장치 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка блока полетного контроллера. Вернитесь домой или приземлитесь",
    "tr": "Uçuş kontrol birimi hatası. Eve geri dönün veya iniş yapın",
    "zh": "飞控系统组件异常，请返航或降落"
  },
  "fpv_tip_0x161000A0": {
    "de": "GEO-Modulfehler. Start nicht möglich",
    "en": "GEO module error. Unable to take off",
    "es": "",
    "fr": "Erreur du module GEO. Décollage impossible",
    "ja": "GEOモジュールエラー。離陸できません",
    "ko": "GEO 모듈 오류. 이륙 불가",
    "ru": "Ошибка модуля GEO. Невозможно выполнить взлет",
    "tr": "",
    "zh": "无法起飞：限飞模块组件异常"
  },
  "fpv_tip_0x161000A0_in_the_sky": {
    "de": "GEO-Modulfehler . Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "GEO module error . Return to home or land promptly",
    "es": "",
    "fr": "Erreur du module GEO . Revenez au point de départ ou atterrissez",
    "ja": "GEOモジュールエラー。直ちに帰還するか着陸してください",
    "ko": "GEO 모듈 오류 . 홈으로 돌아가거나 신속히 착륙하세요",
    "ru": "Ошибка модуля GEO . Вернитесь в исходное положение или приземлитесь как можно скорее",
    "tr": "",
    "zh": "限飞模块组件异常，请返航或降落"
  },
  "fpv_tip_0x161000A1": {
    "de": "Umgehend landen und prüfen, ob die Rahmenarm-Hülse ordnungsgemäß befestigt ist.",
    "en": "Unable to take off. Check whether frame arm sleeves are tightened securely",
    "es": "No se puede despegar. Comprueba si las fundas del brazo del bastidor están bien apretadas",
    "fr": "Atterrissez rapidement et vérifiez si les manchons des bras du châssis sont bien serrés",
    "ja": "離陸できません。フレームアームスリーブがしっかりと固定されているかを確認してください",
    "ko": "즉시 착륙하여 프레임 암 슬리브가 단단히 조여졌는지 확인하세요.",
    "ru": "Немедленно приземлитесь и проверьте, надежно ли закреплены рукава балок рамы",
    "tr": "Kalkış yapamıyor. Çerçeve kollarının bileziklerinin emniyetli bir şekilde sıkılıp sıkılmadığını kontrol edin",
    "zh": "无法起飞：请检查机臂套筒是否固定到位"
  },
  "fpv_tip_0x161000A1_in_the_sky": {
    "de": "Umgehend landen und prüfen, ob die Rahmenarm-Hülse ordnungsgemäß befestigt ist.",
    "en": "Land promptly and check whether frame arm sleeves are tightened securely",
    "es": "Aterriza rápidamente y comprueba si las fundas del brazo del bastidor están bien apretadas",
    "fr": "Atterrissez rapidement et vérifiez si les manchons des bras sont bien serrés",
    "ja": "直ちに着陸し、フレームアームスリーブがしっかりと固定されているかを確認してください",
    "ko": "즉시 착륙하여 프레임 암 슬리브가 단단히 조여졌는지 확인하세요.",
    "ru": "Немедленно приземлитесь и проверьте, надежно ли закреплены рукава балок рамы",
    "tr": "Hemen iniş yap ve çerçeve kollarının bileziklerinin emniyetli bir şekilde sıkılıp sıkılmadığını kontrol edin",
    "zh": "请尽快降落并检查机臂套筒是否固定到位"
  },
  "fpv_tip_0x161000A2": {
    "ar": "",
    "de": "Fehler: unterer Lüfter. Start nicht möglich. Überprüfen, ob der untere Lüfter blockiert ist",
    "en": "Lower fan error. Unable to take off. Check whether lower fan is stalled",
    "es": "Error del ventilador inferior. No se puede despegar. Compruebe si el ventilador inferior está atascado",
    "fr": "Erreur du ventilateur inférieur. Impossible de décoller. Vérifiez si le ventilateur inférieur est bloqué",
    "id": "",
    "it": "",
    "ja": "下部ファンエラー。離陸できません。下部ファンが停止していないかを確認してください",
    "ko": "하단 팬 오류입니다. 이륙 불가. 하단 팬이 멈췄는지 확인하세요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Ошибка нижнего вентилятора. Не удается выполнить взлет. Убедитесь, что нижний вентилятор не заклинило",
    "th": "",
    "tr": "Alt fan hatası. Kalkış yapılamıyor. Alt fanın arızalanarak durup durmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "无法起飞：下风扇异常，请检查飞行器下风扇是否有堵转",
    "zh-Hant": ""
  },
  "fpv_tip_0x161000A2_in_the_sky": {
    "ar": "",
    "de": "Fehler: unterer Lüfter. Zum Startpunkt zurückkehren oder landen. Überprüfen, ob der untere Lüfter blockiert ist",
    "en": "Lower fan error. Return to home or land. Check whether lower fan is stalled",
    "es": "Error del ventilador inferior. Regrese al punto de origen o aterrice. Compruebe si el ventilador inferior está atascado",
    "fr": "Erreur du ventilateur inférieur. Revenez au point de départ ou atterrissez. Vérifiez si le ventilateur inférieur est bloqué",
    "id": "",
    "it": "",
    "ja": "下部ファンエラー。RTHまたは着陸させてください。下部ファンが停止していないかを確認してください",
    "ko": "하단 팬 오류입니다. 홈으로 돌아가거나 착륙하세요. 하단 팬이 멈췄는지 확인하세요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Ошибка нижнего вентилятора. Вернитесь домой или приземлитесь. Убедитесь, что нижний вентилятор не заклинило",
    "th": "",
    "tr": "Alt fan hatası. Eve geri dönün veya iniş yapın. Alt fanın arızalanarak durup durmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "下风扇异常，请返航或降落，再检查飞行器下风扇是否有堵转",
    "zh-Hant": ""
  },
  "fpv_tip_0x161000A3": {
    "ar": "",
    "de": "",
    "en": "Unable to take off. Disconnect aircraft from USB and try again",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "无法起飞，请断开飞机 USB 连接后重试",
    "zh-Hant": ""
  },
  "fpv_tip_0x161000A4": {
    "de": "Start nicht möglich. Fluggerät auf sich bewegender Plattform",
    "en": "Unable to take off. Aircraft on moving platform",
    "es": "No se puede despegar. La aeronave está en una plataforma móvil.",
    "fr": "Impossible de décoller. L'appareil se trouve sur une plate-forme en mouvement",
    "ja": "離陸できません。機体が移動プラットフォーム上にあります",
    "ko": "이륙 불가. 기체가 이동 플랫폼에 있음",
    "ru": "Не удается выполнить взлет. Дрон на движущейся платформе",
    "tr": "Kalkış yapılamıyor. Hava aracı hareketli platform üzerinde",
    "zh": "无法起飞：飞行器处于移动平台"
  },
  "fpv_tip_0x161000A4_in_the_sky": {
    "de": "Fehler: Flugregler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "Flight controller error. Return to home or land promptly",
    "es": "Error del controlador de vuelo. Regresa al punto de origen o aterriza rápidamente.",
    "fr": "Erreur du contrôleur de vol. Revenez au point de départ ou atterrissez rapidement",
    "ja": "フライトコントローラーエラー。直ちにホームに帰還するか着陸してください",
    "ko": "비행 컨트롤러 오류. 즉시 홈으로 돌아가거나 착륙하세요.",
    "ru": "Ошибка полетного контроллера. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "Uçuş kontrol sistemi hatası. Derhal başlangıç noktasına geri dönün veya iniş yapın",
    "zh": "飞控系统异常，请尽快返航或降落"
  },
  "fpv_tip_0x161000A5": {
    "de": "Start nicht möglich. Propeller gefaltet",
    "en": "Unable to take off. Propellers folded",
    "es": "No se puede despegar. Hélices plegadas.",
    "fr": "Impossible de décoller. Hélices repliées",
    "ja": "離陸できません。プロペラが折りたたんだ状態です",
    "ko": "이륙 불가. 프로펠러 접힘",
    "ru": "Не удается выполнить взлет. Пропеллеры сложены",
    "tr": "Kalkış yapılamıyor. Pervaneler katlanmış durumda",
    "zh": "无法起飞：桨叶未展开"
  },
  "fpv_tip_0x161000A5_in_the_sky": {
    "de": "Fehler: Flugregler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "Flight controller error. Return to home or land promptly",
    "es": "Error del controlador de vuelo. Regresa al punto de origen o aterriza rápidamente.",
    "fr": "Erreur du contrôleur de vol. Revenez au point de départ ou atterrissez rapidement",
    "ja": "フライトコントローラーエラー。直ちにホームに帰還するか着陸してください",
    "ko": "비행 컨트롤러 오류. 즉시 홈으로 돌아가거나 착륙하세요.",
    "ru": "Ошибка полетного контроллера. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "Uçuş kontrol sistemi hatası. Derhal başlangıç noktasına geri dönün veya iniş yapın",
    "zh": "飞控系统异常，请尽快返航或降落"
  },
  "fpv_tip_0x161000A6": {
    "de": "Akku der Fernsteuerung schwach. Kann nicht abheben. Akku aufladen",
    "en": "Remote controller battery low. Unable to take off. Recharge battery",
    "es": "Batería del control remoto baja. No se puede despegar. Recárgala",
    "fr": "Batterie de la radiocommande faible. Décollage impossible. Rechargez la batterie",
    "ja": "送信機バッテリー残量低下。離陸できません。バッテリーを充電してください",
    "ko": "조종기 배터리 부족. 이륙 불가. 배터리 충전 필요",
    "ru": "Низкий заряд аккумулятора пульта ДУ. Взлет невозможен. Зарядите аккумулятор",
    "tr": "Uzaktan kumanda pili düşük. Kalkış yapılamıyor. Pili yeniden şarj edin",
    "zh": "无法起飞：遥控器电量过低，请给遥控器充电"
  },
  "fpv_tip_0x161000A6_in_the_sky": {
    "de": "Akku der Fernsteuerung schwach. Sofort zurückkehren oder landen",
    "en": "Remote controller battery low. Return to home or land promptly",
    "es": "Batería del control remoto baja. Regresa al punto de origen o aterriza rápidamente",
    "fr": "Batterie de la radiocommande faible. Retournez au point de départ ou atterrissez rapidement",
    "ja": "送信機バッテリー残量低下。直ちに帰還するか着陸してください",
    "ko": "조종기 배터리 부족. 즉시 RTH 또는 착륙 필요",
    "ru": "Низкий заряд аккумулятора пульта ДУ. Вернитесь домой или приземлитесь немедленно",
    "tr": "Uzaktan kumanda pili düşük. Eve geri dönün veya düzgün iniş yapın",
    "zh": "遥控器电量过低，请尽快返航或降落"
  },
  "fpv_tip_0x161000A7": {
    "de": "Start nicht möglich. Fehler beim Überprüfen der Fernsteuerung. Max. Anzahl von temporären Flügen erreicht. Verknüpfte Fernsteuerung verwenden oder mit einer anderen Fernsteuerung verknüpfen",
    "en": "Unable to take off. Failed to verify remote controller. Max number of temporary flights reached. Use bound remote controller or bind to another remote controller",
    "es": "No se puede despegar. No se ha podido verificar el control remoto. Se ha alcanzado el número máximo de vuelos temporales. Utiliza el control remoto vinculado o vincula el dispositivo a otro control remoto",
    "fr": "Impossible de décoller. Échec de la vérification de la radiocommande. Nombre maximal de vols temporaires atteint. Utilisez la radiocommande associée ou associez l’appareil à une autre radiocommande",
    "ja": "離陸できません。送信機の確認に失敗しました。一時的な飛行の最大回数に達しました。紐付けされた送信機を使用するか、別の送信機と紐付けしてください",
    "ko": "이륙 불가. 조종기 인증 실패. 임시 비행 횟수 최대 한도에 도달했습니다. 바인딩된 조종기를 사용하거나 다른 조종기에 바인딩하십시오",
    "ru": "Не удается выполнить взлет. Не удалось проверить пульт ДУ. Достигнуто максимальное количество временных полетов. Используйте привязанный пульт дистанционного управления или привяжите к другому пульту дистанционного управления",
    "tr": "Kalkış yapılamıyor. Uzaktan kumanda onaylanamadı. Maks. geçici uçuş sayısına ulaşıldı. Bağlı uzaktan kumandayı kullanın veya başka bir uzaktan kumandaya bağlanın",
    "zh": "无法起飞：遥控器绑定校验失败，临时起飞次数耗尽，请使用绑定遥控器或者换绑"
  },
  "fpv_tip_0x161000A7_in_the_sky": {
    "de": "Fehler des Flugreglers. Zum Startpunkt zurückkehren oder umgehend landen",
    "en": "Flight controller error. Return to home or land promptly",
    "es": "Error del controlador de vuelo. Regresa al punto de origen o aterriza cuando antes.",
    "fr": "Erreur du contrôleur de vol. Retourner au point de départ ou atterrir rapidement",
    "ja": "フライトコントローラーエラー。直ちに帰還するか、着陸してください",
    "ko": "비행 컨트롤러 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요",
    "ru": "Ошибка полетного контроллера. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "Uçuş kumandası hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "飞控系统异常，请尽快返航或降落"
  },
  "fpv_tip_0x161000A9": {
    "de": "Propeller im Zeitlupenmodus. Start nicht möglich",
    "en": "Propellers in slow motion mode. Unable to take off",
    "es": "Hélices en modo Slow Motion. No se puede despegar",
    "fr": "Hélices en mode ralenti. Impossible de décoller",
    "ja": "プロペラがスローモーションモードです。離陸できません",
    "ko": "프로펠러가 슬로 모션 모드에 있음. 이륙 불가",
    "ru": "Пропеллеры в замедленном режиме. Не удается выполнить взлет",
    "tr": "Pervaneler ağır çekim modunda. Kalkış yapılamıyor",
    "zh": "无法起飞：飞行器处于慢转桨模式"
  },
  "fpv_tip_0x161000A9_in_the_sky": {
    "de": "Propellerbewegungsfehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "Propeller motion error. Return to home or land promptly",
    "es": "Error de movimiento de las hélices. Regrese al punto de origen o aterrice rápidamente",
    "fr": "Erreur de mouvement de l'hélice. Retournez au point de départ ou atterrissez rapidement",
    "ja": "プロペラのモーションエラー。直ちに帰還するか、着陸してください",
    "ko": "프로펠러 모션 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요",
    "ru": "Ошибка движения пропеллера. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "Pervane hareket hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "飞行器异常慢转桨，请尽快返航或降落"
  },
  "fpv_tip_0x161000AA": {
    "de": "GEO-Modulfehler. Start nicht möglich",
    "en": "GEO module error. Unable to take off",
    "es": "Error del módulo GEO. No se puede despegar",
    "fr": "Erreur du module GEO. Impossible de décoller",
    "ja": "GEOモジュールエラー。離陸できません",
    "ko": "GEO 모듈 오류. 이륙 불가",
    "ru": "Ошибка модуля GEO. Не удается выполнить взлет",
    "tr": "GEO modülü hatası. Kalkış yapılamıyor",
    "zh": "无法起飞：限飞模块组件异常"
  },
  "fpv_tip_0x161000AA_in_the_sky": {
    "de": "GEO-Modulfehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "GEO module error. Return to home or land promptly",
    "es": "Error del módulo GEO. Regrese al punto de origen o aterrice rápidamente",
    "fr": "Erreur du module GEO. Retournez au point de départ ou atterrissez rapidement",
    "ja": "GEOモジュールエラー。直ちに帰還するか、着陸してください",
    "ko": "GEO 모듈 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요",
    "ru": "Ошибка модуля GEO. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "GEO modülü hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "限飞模块组件异常，请尽快返航或降落"
  },
  "fpv_tip_0x161000AB": {
    "de": "FlySafe-Datenbankfehler. Start nicht möglich",
    "en": "Fly Safe database error. Unable to take off",
    "es": "Error de la base datos VuelaSeguro. No se puede despegar",
    "fr": "Erreur de la base de données Fly Safe. Impossible de décoller",
    "ja": "安全飛行データベースのエラー。離陸できません",
    "ko": "안전 비행 데이터베이스 오류. 이륙 불가",
    "ru": "Ошибка базы данных Fly Safe. Не удается выполнить взлет",
    "tr": "Güvenli Uçuş veri tabanı hatası. Kalkış yapılamıyor",
    "zh": "无法起飞：飞行安全数据库异常"
  },
  "fpv_tip_0x161000AB_in_the_sky": {
    "de": "FlySafe-Datenbankfehler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "Fly Safe database error. Return to home or land promptly",
    "es": "Error de la base datos VuelaSeguro. Regrese al punto de origen o aterrice rápidamente",
    "fr": "Erreur de la base de données Fly Safe. Retournez au point de départ ou atterrissez rapidement",
    "ja": "安全飛行データベースのエラー。直ちに帰還するか、着陸してください",
    "ko": "안전 비행 데이터베이스 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요",
    "ru": "Ошибка базы данных Fly Safe. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "Güvenli Uçuş veri tabanı hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "飞行安全数据库异常，请尽快返航或降落"
  },
  "fpv_tip_0x161000AC": {
    "de": "Fehler: Flugregler. Start nicht möglich",
    "en": "Flight control system error. Unable to take off",
    "es": "Error en el sistema de control de vuelo. No se puede despegar",
    "fr": "Erreur du système de contrôle du vol. Impossible de décoller",
    "ja": "フライトコントロールシステムのエラー。離陸できません",
    "ko": "비행 제어 시스템 오류. 이륙 불가",
    "ru": "Ошибка системы управления полетом. Не удается выполнить взлет",
    "tr": "Uçuş kontrol sistemi hatası. Kalkış yapılamıyor",
    "zh": "无法起飞：飞控系统异常"
  },
  "fpv_tip_0x161000AC_in_the_sky": {
    "de": "Fehler: Flugregler. Umgehend landen",
    "en": "Flight control system error. Land promptly",
    "es": "Error en el sistema de control de vuelo. Aterriza rápidamente",
    "fr": "Erreur du système de contrôle du vol. Atterrissez immédiatement.",
    "ja": "フライトコントロールシステムのエラー。直ちに着陸してください",
    "ko": "비행 제어 시스템 오류. 즉시 착륙해주세요.",
    "ru": "Ошибка системы управления полетом. Немедленно выполните посадку",
    "tr": "Uçuş kontrol sistemi hatası. Derhal iniş yapın",
    "zh": "飞控系统异常，请尽快降落"
  },
  "fpv_tip_0x161000AF": {
    "de": "Flugsteuerungssystem wird initialisiert. Start nicht möglich",
    "en": "Initializing flight control system. Unable to take off",
    "es": "Iniciando el sistema controlador de vuelo. No se puede despegar",
    "fr": "Initialisation du système de contrôle du vol. Impossible de décoller",
    "ja": "フライトコントロールシステムを初期化中です。離陸できません",
    "ko": "비행 제어 시스템 초기화 중. 이륙 불가",
    "ru": "Инициализация системы управления полетом. Не удается выполнить взлет",
    "tr": "Uçuş kontrol sistemi başlatılıyor. Kalkış yapılamıyor",
    "zh": "无法起飞：飞控系统初始化中"
  },
  "fpv_tip_0x161000AF_in_the_sky": {
    "de": "Initialisierungsfehler bei Flugsteuerungssystem. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "Flight control system initialization error. Return to home or land promptly",
    "es": "Error de inicio del sistema controlador de vuelo. Regrese al punto de origen o aterrice rápidamente",
    "fr": "Erreur d'initialisation du système de contrôle du vol. Retournez au point de départ ou atterrissez rapidement",
    "ja": "フライトコントロールシステムの初期化エラー。直ちに帰還するか、着陸してください",
    "ko": "비행 제어 시스템 초기화 오류. 즉시 리턴 투 홈을 실행하거나 착륙하세요",
    "ru": "Ошибка инициализации системы управления полетом. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "Uçuş kontrol sistemi başlatma hatası. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "飞控系统异常初始化，请尽快返航或降落"
  },
  "fpv_tip_0x161000B1": {
    "de": "Statusfehler Landegestell. Automatische Verriegelung des Landegestells aufheben und Taste „Transform“ drücken, um das Landegestell zurückzusetzen.",
    "en": "Landing gear status error. Try to release Landing Gear Auto Lock and press the transform button to reset the landing gear",
    "es": "Error de estado del tren de aterrizaje. Pruebe a liberar el bloqueo automático del tren de aterrizaje y pulse el botón de transformación para restablecer el tren de aterrizaje",
    "fr": "Erreur de statut du train d’atterrissage. Essayer de relâcher le verrouillage automatique du train d'atterrissage et appuyer sur le bouton de transformation pour réinitialiser le train d'atterrissage",
    "ja": "ランディングギアの状態エラー。ランディングギアの自動ロックを解除し、変形ボタンを押して、ランディングギアをリセットしてみてください",
    "ko": "랜딩 기어 상태 오류. 랜딩 기어 자동 잠금을 해제하고 변속 버튼을 눌러 랜딩 기어를 재설정하세요.",
    "ru": "Ошибка статуса шасси. Попробуйте отключить автоматическую блокировку шасси и нажмите кнопку трансформации, чтобы выполнить сброс шасси",
    "tr": "İniş takımı durumu hatası. İniş Takımları Otomatik Kilitlemesini serbest bırakmayı deneyin ve iniş takımlarını sıfırlamak için değiştirme düğmesine basın",
    "zh": "起落架状态异常，请尝试解除起落架锁定，并点击变形按键尝试复位"
  },
  "fpv_tip_0x161000B1_in_the_sky": {
    "de": "Statusfehler Landegestell. Taste „Transform“ drücken, um das Landegestell zurückzusetzen.",
    "en": "Landing gear status error. Try to press the transform button to reset the landing gear",
    "es": "Error de estado del tren de aterrizaje. Pruebe a pulsar el botón de transformación para restablecer el tren de aterrizaje",
    "fr": "Erreur de statut du train d’atterrissage. Essayer d'appuyer sur le bouton de transformation pour réinitialiser le train d'atterrissage",
    "ja": "ランディングギアの状態エラー。変形ボタンを押してランディングギアをリセットしてみてください",
    "ko": "랜딩 기어 상태 오류. 변속 버튼을 눌러 랜딩 기어를 재설정하세요.",
    "ru": "Ошибка статуса шасси. Попробуйте нажать кнопку трансформации, чтобы выполнить сброс шасси",
    "tr": "İniş takımı durumu hatası. İniş takımlarını sıfırlamak için değiştirme düğmesine basmayı deneyin",
    "zh": "起落架状态异常，请尝试点击变形按键复位"
  },
  "fpv_tip_0x161000B2": {
    "de": "Start nicht möglich. Landegestell wird transformiert...",
    "en": "Unable to take off. Landing gear transforming...",
    "es": "No se puede despegar. Transformando el tren de aterrizaje...",
    "fr": "Impossible de décoller. Transformation du train d’atterrissage en cours...",
    "ja": "離陸できません。ランディングギアの変形中...",
    "ko": "이륙 불가. 랜딩 기어 변속 중...",
    "ru": "Не удается выполнить взлет. Трансформация шасси...",
    "tr": "Kalkış yapılamıyor. İniş takımları değişiyor...",
    "zh": "无法起飞：起落架变形中"
  },
  "fpv_tip_0x161000B3": {
    "de": "Start nicht möglich. Landegestell im Transportmodus. Gimbal-Kamera zum Transformieren entfernen",
    "en": "Unable to take off. Landing gear in Travel mode. Remove gimbal camera to transform",
    "es": "No se puede despegar. Tren de aterrizaje en modo Viaje. Retire la cámara del estabilizador para la transformación",
    "fr": "Impossible de décoller. Train d’atterrissage en mode Transport. Retirer la caméra de la nacelle pour transformation",
    "ja": "離陸できません。ランディングギアがトラベルモード。ジンバルカメラを取り外して変形させてください",
    "ko": "이륙 불가. 랜딩 기어가 트래블 모드에 있습니다. 변속을 위해 짐벌 카메라를 제거하세요",
    "ru": "Не удается выполнить взлет. Шасси в режиме движения. Снимите камеру с гиростабилизатором для трансформации",
    "tr": "Kalkış yapılamıyor. İniş takımı seyahat modunda. Dönüştürmek için gimbal kamerasını çıkarın",
    "zh": "无法起飞：起落架已进入运输模式，等待摘除云台"
  },
  "fpv_tip_0x161000B4": {
    "ar": "",
    "de": "",
    "en": "Remote ID broadcast error. Failed to obtain remote controller location. Unable to take off",
    "es": "",
    "fr": "",
    "it": "",
    "ja": "",
    "ko": "",
    "pt": "",
    "ru": "",
    "tr": "",
    "zh": "无法起飞：Remote ID 播报异常，无法获取遥控器位置"
  },
  "fpv_tip_0x161000B5": {
    "ar": "",
    "de": "",
    "en": "Remote ID module error. Unable to take off. Contact DJI Support",
    "es": "",
    "fr": "",
    "it": "",
    "ja": "",
    "ko": "",
    "pt": "",
    "ru": "",
    "tr": "",
    "zh": "无法起飞，Remote ID 模块异常，请联系售后服务"
  },
  "fpv_tip_0x161000B6": {
    "ar": "",
    "de": "",
    "en": "Average battery power output too low. Unable to take off. Reduce payload or replace with fully charged batteries",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "无法起飞：电池平均功率过低，请降低载重或更换满电电池",
    "zh-Hant": ""
  },
  "fpv_tip_0x161000B7": {
    "en": "Payload exceeded max allowable weight. Reduce payload or fully charge battery",
    "zh": "已超最大允许载重，请降低载重或补充至满电"
  },
  "fpv_tip_0x161000B7_in_the_sky": {
    "en": "Payload exceeded max allowable weight. Return to home or land promptly",
    "zh": "已超最大允许载重，请尽快返航或降落"
  },
  "fpv_tip_0x161000B8": {
    "ar": "",
    "de": "",
    "en": "Obstacle detected near aircraft. Unable to take off",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "无法起飞：飞行器周围有障碍物",
    "zh-Hant": ""
  },
  "fpv_tip_0x161000BD": {
    "ar": "",
    "de": "",
    "en": "Parachute built-in battery voltage too low. Unable to take off",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "电池温度过高，无法起飞",
    "zh-Hant": ""
  },
  "fpv_tip_0x161000BE": {
    "ar": "",
    "de": "",
    "en": "Incompatible battery models. Unable to take off",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "无法起飞：电池类型不匹配",
    "zh-Hant": ""
  },
  "fpv_tip_0x161000BF": {
    "ar": "",
    "de": "",
    "en": "Single battery flight not enabled. Unable to take off",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "无法起飞：单电池模式未打开",
    "zh-Hant": ""
  },
  "fpv_tip_0x161000C0": {
    "de": "Start nicht möglich. Gimbal-Kamera mit derzeitiger Befestigung wird nicht unterstützt. Firmware des Fluggeräts aktualisieren",
    "en": "Unable to take off. Gimbal camera with current mount not supported. Update aircraft firmware",
    "es": "No se puede despegar. Montura actual de la cámara con estabilizador no compatible. Actualizar firmware de la aeronave",
    "fr": "Impossible de décoller. La caméra de la nacelle avec le support actuel n’est pas prise en charge. Mettre à jour le firmware de l’appareil",
    "ja": "離陸できません。現在のマウントでのジンバルカメラには対応していません。機体のファームウェアを更新してください",
    "ko": "이륙 불가. 현재 마운트의 짐벌 카메라는 지원되지 않습니다. 기체 펌웨어를 업데이트하세요",
    "ru": "Не удается выполнить взлет. Камера с гиростабилизатором с текущим креплением не поддерживается. Обновите прошивку дрона",
    "tr": "Kalkış yapılamıyor. Geçerli montajlı gimbal kamerası desteklenmiyor. Hava aracı ürün yazılımını güncelleyin",
    "zh": "无法起飞：不支持当前卡口云台，请升级飞行器固件"
  },
  "fpv_tip_0x161000C4": {
    "de": "Start nicht möglich. Automatische ESC-Prüfung läuft ...",
    "en": "Unable to take off. Auto checking ESC...",
    "es": "No se puede despegar. Comprobando automáticamente ESC...",
    "fr": "Impossible de décoller. Vérification automatique ESC en cours",
    "ja": "離陸できません。ESCを自動確認中...",
    "ko": "이륙 불가. ESC 자동 확인 중...",
    "ru": "Не удается выполнить взлет. Автоматическая проверка ESC...",
    "tr": "Kalkış yapılamıyor. ESC otomatik olarak kontrol ediliyor...",
    "zh": "无法起飞：电调自检中"
  },
  "fpv_tip_0x161000C7": {
    "de": "Akkutemperatur unter 10 °C. Start nicht möglich",
    "en": "Battery temperature lower than 10°C. Unable to take off",
    "es": "Temperatura de la batería inferior a 10 °C. No se puede despegar",
    "fr": "Température de la batterie inférieure à 10 °C. Impossible de décoller",
    "ja": "バッテリー温度が10℃未満です。離陸できません",
    "ko": "배터리 온도가 10°C 미만입니다. 이륙 불가",
    "ru": "Температура аккумулятора ниже 10 °C. Не удается выполнить взлет",
    "tr": "Pil sıcaklığı 10 °C'den düşük. Kalkış yapılamıyor",
    "zh": "无法起飞：电池温度低于10度"
  },
  "fpv_tip_0x161000C7_in_the_sky": {
    "de": "Akkutemperatur unter 10 °C. Umgehend zum Startpunkt zurückkehren oder landen.",
    "en": "Battery temperature lower than 10°C. Return to home or land promptly",
    "es": "Temperatura de la batería inferior a 10 °C. Regresa al punto de origen o aterriza rápidamente",
    "fr": "Température de la batterie inférieure à 10 °C. Retournez au point de départ ou atterrissez rapidement",
    "ja": "バッテリー温度が10℃未満です。直ちに帰還するか、着陸してください",
    "ko": "배터리 온도가 10°C 미만입니다. 즉시 리턴 투 홈을 실행하거나 착륙하세요.",
    "ru": "Температура аккумулятора ниже 10 °C. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "Pil sıcaklığı 10 °C'den düşük. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "电池温度低于10度，尽快返航或降落"
  },
  "fpv_tip_0x161000C8": {
    "de": "Start fehlgeschlagen.Überprüfen, ob das Fluggerät mit dem DJI Assistant verbunden ist oder gerade aktualisiert wird.",
    "en": "Unable to take off. Check whether aircraft is connected to DJI Assistant or system is updating",
    "es": "No se puede despegar. Comprueba si la aeronave está conectada a DJI Assistant o si el sistema se está actualizando",
    "fr": "Impossible de décoller. Vérifiez si l\\'appareil est connecté à DJI Assistant ou si une mise à jour est en cours",
    "ja": "離陸できません。機体がDJI Assistantに接続されているか、またはシステムが更新中か確認してください",
    "ko": "이륙 불가. 기체가 DJI Assistant에 연결 또는 시스템 업데이트 상태 확인 필요",
    "ru": "Не удалось взлететь. Нарушено соединение DJI Assistant с дроном либо идет обновление системы",
    "tr": "Kalkış yapamıyor. Aracın DJI Assistant’a bağlı olup olmadığını veya sistem güncellemesinin devam edip etmediğini kontrol edin",
    "zh": "无法起飞：请确认usb是否已连接或者系统正在升级中"
  },
  "fpv_tip_0x161000C8_in_the_sky": {
    "de": "Überprüfen, ob das Fluggerät mit dem DJI Assistant verbunden ist oder das System aktualisiert wird.",
    "en": "Check whether aircraft is connected to DJI Assistant or system is updating",
    "es": "Comprueba si la aeronave está conectada a DJI Assistant o si el sistema se está actualizando",
    "fr": "Vérifiez si l\\'appareil est connecté à DJI Assistant ou si une mise à jour est en cours",
    "ja": "機体がDJI Assistantに接続されているか、またはシステムが更新中か確認してください",
    "ko": "기체가 DJI Assistant에 연결되었는지 또는 시스템 업데이트 상태 확인 필요",
    "ru": "Проверьте, не подключен ли дрон к DJI Assistant или не обновляется ли система",
    "tr": "Aracın DJI Assistant’a bağlı olup olmadığını veya sistem güncellemesinin devam edip etmediğini kontrol edin",
    "zh": "请确认usb是否已连接或者系统正在升级中"
  },
  "fpv_tip_0x161000C9": {
    "de": "Start nicht möglich. Überprüfen, ob das Fluggerät mit dem DJI Assistant verbunden ist oder das System aktualisiert wird.",
    "en": "Unable to take off. Check whether aircraft is connected to DJI Assistant or system is updating",
    "es": "No se puede despegar. Comprueba si la aeronave está conectada a DJI Assistant o si el sistema se está actualizando",
    "fr": "Impossible de décoller. Vérifiez si l\\'appareil est connecté à DJI Assistant ou si une mise à jour est en cours",
    "ja": "離陸できません。機体がDJI Assistantに接続されているか、またはシステムが更新中か確認してください",
    "ko": "이륙 불가. 기체가 DJI Assistant에 연결되었는지 또는 시스템 업데이트 상태 확인 필요",
    "ru": "Невозможно выполнить взлет. Проверьте, не подключен ли дрон к DJI Assistant или не обновляется ли система",
    "tr": "Kalkış yapamıyor. Aracın DJI Assistant’a bağlı olup olmadığını veya sistem güncellemesinin devam edip etmediğini kontrol edin",
    "zh": "无法起飞：请确认usb是否已连接或者系统正在升级中"
  },
  "fpv_tip_0x161000C9_in_the_sky": {
    "de": "Überprüfen, ob das Fluggerät mit dem DJI Assistant verbunden ist oder das System aktualisiert wird.",
    "en": "Check whether aircraft is connected to DJI Assistant or system is updating",
    "es": "Comprueba si la aeronave está conectada a DJI Assistant o si el sistema se está actualizando",
    "fr": "Vérifiez si l\\'appareil est connecté à DJI Assistant ou si une mise à jour est en cours",
    "ja": "機体がDJI Assistantに接続されているか、またはシステムが更新中か確認してください",
    "ko": "기체가 DJI Assistant에 연결되었는지 또는 시스템 업데이트 상태 확인 필요",
    "ru": "Проверьте, не подключен ли дрон к DJI Assistant или не обновляется ли система",
    "tr": "Aracın DJI Assistant’a bağlı olup olmadığını veya sistem güncellemesinin devam edip etmediğini kontrol edin",
    "zh": "请确认usb是否已连接或者系统正在升级中"
  },
  "fpv_tip_0x161000F1": {
    "de": "Start nicht möglich. Überprüfen, ob der vordere rechte Rahmenausleger vollständig auseinandergefaltet ist",
    "en": "Unable to take off. Check whether front right frame arm is fully unfolded",
    "es": "No se puede despegar. Compruebe si el brazo del bastidor delantero derecho está completamente desplegado",
    "fr": "Impossible de décoller. Vérifiez si le bras avant droit est complètement déplié",
    "it": "",
    "ja": "離陸できません。右前フレームアームが完全に展開されているかを確認してください",
    "ko": "이륙 불가. 전방 우측 프레임 암이 완전히 펼쳐졌는지 점검하세요.",
    "pt": "",
    "ru": "Не удается выполнить взлет. Проверьте, полностью ли разложен передний правый рычаг рамы.",
    "tr": "Kalkış yapılamıyor. Ön sağ çerçeve kolunun tamamen açılıp açılmadığını kontrol edin",
    "zh": "无法起飞：请检查右前机臂是否展开并固定到位"
  },
  "fpv_tip_0x161000F1_in_the_sky": {
    "de": "Fehler: vorderer rechter Rahmenausleger. Umgehend landen",
    "en": "Front right frame arm error. Land promptly",
    "es": "Error del brazo del bastidor delantero derecho. Aterriza rápidamente",
    "fr": "Erreur de bras avant droit. Atterrissez immédiatement.",
    "it": "",
    "ja": "右前フレームアームのエラー。直ちに着陸してください",
    "ko": "전방 우측 프레임 암 오류. 즉시 착륙해주세요.",
    "pt": "",
    "ru": "Ошибка переднего правого рычага рамы. Немедленно выполните посадку",
    "tr": "Ön sağ çerçeve kolu hatası. Derhal iniş yapın",
    "zh": "右前机臂异常，请尽快降落"
  },
  "fpv_tip_0x161000F2": {
    "de": "Start nicht möglich. Überprüfen, ob der vordere linke Rahmenausleger vollständig auseinandergefaltet ist",
    "en": "Unable to take off. Check whether front left frame arm is fully unfolded",
    "es": "No se puede despegar. Compruebe si el brazo del bastidor delantero izquierdo está completamente desplegado",
    "fr": "Impossible de décoller. Vérifiez si le bras avant gauche est complètement déplié",
    "it": "",
    "ja": "離陸できません。左前フレームアームが完全に展開されているかを確認してください",
    "ko": "이륙 불가. 전방 좌측 프레임 암이 완전히 펼쳐졌는지 점검하세요.",
    "pt": "",
    "ru": "Не удается выполнить взлет. Проверьте, полностью ли разложен передний левый рычаг рамы.",
    "tr": "Kalkış yapılamıyor. Ön sol çerçeve kolunun tamamen açılıp açılmadığını kontrol edin",
    "zh": "无法起飞：请检查左前机臂是否展开并固定到位"
  },
  "fpv_tip_0x161000F2_in_the_sky": {
    "de": "Fehler: vorderer linker Rahmenausleger. Umgehend landen",
    "en": "Front left frame arm error. Land promptly",
    "es": "Error del brazo del bastidor delantero izquierdo. Aterriza rápidamente",
    "fr": "Erreur de bras avant gauche. Atterrissez immédiatement.",
    "it": "",
    "ja": "左前フレームアームのエラー。直ちに着陸してください",
    "ko": "전방 좌측 프레임 암 오류. 즉시 착륙해주세요.",
    "pt": "",
    "ru": "Ошибка переднего левого рычага рамы. Немедленно выполните посадку",
    "tr": "Ön sol çerçeve kolu hatası. Derhal iniş yapın",
    "zh": "左前机臂异常，请尽快降落"
  },
  "fpv_tip_0x161000F3": {
    "de": "Start nicht möglich. Überprüfen, ob der hintere linke Rahmenausleger vollständig auseinandergefaltet ist",
    "en": "Unable to take off. Check whether back left frame arm is fully unfolded",
    "es": "No se puede despegar. Compruebe si el brazo del bastidor trasero izquierdo está completamente desplegado",
    "fr": "Impossible de décoller. Vérifiez si le bras arrière gauche est complètement déplié",
    "it": "",
    "ja": "離陸できません。左後フレームアームが完全に展開されているかを確認してください",
    "ko": "이륙 불가. 후방 좌측 프레임 암이 완전히 펼쳐졌는지 점검하세요.",
    "pt": "",
    "ru": "Не удается выполнить взлет. Проверьте, полностью ли разложен задний левый рычаг рамы.",
    "tr": "Kalkış yapılamıyor. Arka sol çerçeve kolunun tamamen açılıp açılmadığını kontrol edin",
    "zh": "无法起飞：请检查左后机臂是否展开并固定到位"
  },
  "fpv_tip_0x161000F3_in_the_sky": {
    "de": "Fehler: hinterer linker Rahmenausleger. Umgehend landen",
    "en": "Back left frame arm error. Land promptly",
    "es": "Error del brazo del bastidor trasero izquierdo. Aterriza rápidamente",
    "fr": "Erreur de bras avant gauche arrière. Atterrissez immédiatement.",
    "it": "",
    "ja": "左後フレームアームのエラー。直ちに着陸してください",
    "ko": "후방 좌측 프레임 암 오류. 즉시 착륙해주세요.",
    "pt": "",
    "ru": "Ошибка заднего левого рычага рамы. Немедленно выполните посадку",
    "tr": "Arka sol çerçeve kolu hatası. Derhal iniş yapın",
    "zh": "左后机臂异常，请尽快降落"
  },
  "fpv_tip_0x161000F4": {
    "de": "Start nicht möglich. Überprüfen, ob der hintere rechte Rahmenausleger vollständig auseinandergefaltet ist",
    "en": "Unable to take off. Check whether back right frame arm is fully unfolded",
    "es": "No se puede despegar. Compruebe si el brazo del bastidor trasero derecho está completamente desplegado",
    "fr": "Impossible de décoller. Vérifiez si le bras arrière droit est complètement déplié",
    "it": "",
    "ja": "離陸できません。右後フレームアームが完全に展開されているかを確認してください",
    "ko": "이륙 불가. 후방 우측 프레임 암이 완전히 펼쳐졌는지 점검하세요.",
    "pt": "",
    "ru": "Не удается выполнить взлет. Проверьте, полностью ли разложен задний правый рычаг рамы.",
    "tr": "Kalkış yapılamıyor. Arka sağ çerçeve kolunun tamamen açılıp açılmadığını kontrol edin",
    "zh": "无法起飞：请检查右后机臂是否展开并固定到位"
  },
  "fpv_tip_0x161000F4_in_the_sky": {
    "de": "Fehler: hinterer rechter Rahmenausleger. Umgehend landen",
    "en": "Back right frame arm error. Land promptly",
    "es": "Error del brazo del bastidor trasero derecho. Aterriza rápidamente",
    "fr": "Erreur de bras avant droit arrière. Atterrissez immédiatement.",
    "it": "",
    "ja": "右後フレームアームのエラー。直ちに着陸してください",
    "ko": "후방 우측 프레임 암 오류. 즉시 착륙해주세요.",
    "pt": "",
    "ru": "Ошибка заднего правого рычага рамы. Немедленно выполните посадку",
    "tr": "Arka sağ çerçeve kolu hatası. Derhal iniş yapın",
    "zh": "右后机臂异常，请尽快降落"
  },
  "fpv_tip_0x161000F5": {
    "de": "Fehler: Flugregler. Start nicht möglich",
    "en": "Flight control system error. Unable to take off",
    "es": "Error en el sistema de control de vuelo. No se puede despegar",
    "fr": "Erreur du système de contrôle du vol. Impossible de décoller",
    "ja": "フライトコントロールシステムのエラー。離陸できません",
    "ko": "비행 제어 시스템 오류. 이륙 불가",
    "ru": "Ошибка системы управления полетом. Не удается выполнить взлет",
    "tr": "Uçuş kontrol sistemi hatası. Kalkış yapılamıyor",
    "zh": "无法起飞：飞控系统异常"
  },
  "fpv_tip_0x161000F5_in_the_sky": {
    "de": "Fehler: Flugregler. Umgehend landen",
    "en": "Flight control system error. Land promptly",
    "es": "Error en el sistema de control de vuelo. Aterriza rápidamente",
    "fr": "Erreur du système de contrôle du vol. Atterrissez immédiatement.",
    "ja": "フライトコントロールシステムのエラー。直ちに着陸してください",
    "ko": "비행 제어 시스템 오류. 즉시 착륙해주세요.",
    "ru": "Ошибка системы управления полетом. Немедленно выполните посадку",
    "tr": "Uçuş kontrol sistemi hatası. Derhal iniş yapın",
    "zh": "飞控系统异常，请尽快降落"
  },
  "fpv_tip_0x161000F6": {
    "de": "Fehler: Flugregler. Start nicht möglich",
    "en": "Flight control system error. Unable to take off",
    "es": "Error en el sistema de control de vuelo. No se puede despegar",
    "fr": "Erreur du système de contrôle du vol. Impossible de décoller",
    "ja": "フライトコントロールシステムのエラー。離陸できません",
    "ko": "비행 제어 시스템 오류. 이륙 불가",
    "ru": "Ошибка системы управления полетом. Не удается выполнить взлет",
    "tr": "Uçuş kontrol sistemi hatası. Kalkış yapılamıyor",
    "zh": "无法起飞：飞控系统异常"
  },
  "fpv_tip_0x161000F6_in_the_sky": {
    "de": "Fehler: Flugregler. Umgehend landen",
    "en": "Flight control system error. Land promptly",
    "es": "Error en el sistema de control de vuelo. Aterriza rápidamente",
    "fr": "Erreur du système de contrôle du vol. Atterrissez immédiatement.",
    "ja": "フライトコントロールシステムのエラー。直ちに着陸してください",
    "ko": "비행 제어 시스템 오류. 즉시 착륙해주세요.",
    "ru": "Ошибка системы управления полетом. Немедленно выполните посадку",
    "tr": "Uçuş kontrol sistemi hatası. Derhal iniş yapın",
    "zh": "飞控系统异常，请尽快降落"
  },
  "fpv_tip_0x161000F7": {
    "ar": "",
    "de": "Initialisierung des Sensorsystems läuft",
    "en": "Sensor system initialization in progress",
    "es": "Inicio de sistema de sensores en curso",
    "fr": "Initialisation du système de capteurs en cours",
    "it": "",
    "ja": "センサーシステムを初期中",
    "ko": "센서 시스템 초기화 진행 중",
    "pt": "",
    "ru": "Выполняется инициализация сенсорной системы",
    "tr": "Sensör sistemi başlatma işlemi devam ediyor",
    "zh": "无法起飞：感知系统初始化中"
  },
  "fpv_tip_0x161000F7_in_the_sky": {
    "ar": "",
    "de": "Fehler: Sensorsystem. Umgehend landen oder mit Vorsicht fliegen",
    "en": "Sensor system error. Land promptly or fly with caution",
    "es": "Error del sistema de sensores. Aterriza rápidamente o vuela con cuidado",
    "fr": "Erreur du système de capteurs. Atterrissez rapidement ou pilotez avec précaution",
    "it": "",
    "ja": "センサーシステムのエラー。直ちに着陸するか、慎重に飛行してください",
    "ko": "센서 시스템 오류. 즉시 착륙 또는 비행 시 주의 필요",
    "pt": "",
    "ru": "Ошибка системы датчиков. Немедленно выполните посадку или соблюдайте осторожность при полете",
    "tr": "Sensör sistem hatası. Derhal iniş yapın veya aracı dikkatli uçurun",
    "zh": "感知系统异常，请尽快降落或谨慎飞行"
  },
  "fpv_tip_0x161000F8": {
    "ar": "",
    "de": "Initialisierung des Sensorsystems läuft",
    "en": "Sensor system initialization in progress",
    "es": "Inicio de sistema de sensores en curso",
    "fr": "Initialisation du système de capteurs en cours",
    "it": "",
    "ja": "センサーシステムを初期中",
    "ko": "센서 시스템 초기화 진행 중",
    "pt": "",
    "ru": "Выполняется инициализация сенсорной системы",
    "tr": "Sensör sistemi başlatma işlemi devam ediyor",
    "zh": "无法起飞：感知系统初始化中"
  },
  "fpv_tip_0x161000F8_in_the_sky": {
    "ar": "",
    "de": "Fehler: Sensorsystem. Umgehend landen",
    "en": "Sensor system error. Land promptly",
    "es": "Error del sistema de sensores. Aterriza rápidamente",
    "fr": "Erreur du système de capteurs. Atterrissez immédiatement.",
    "it": "",
    "ja": "センサーシステムエラー。直ちに着陸してください",
    "ko": "센서 시스템 오류. 즉시 착륙해주세요.",
    "pt": "",
    "ru": "Ошибка системы датчиков. Немедленно выполните посадку",
    "tr": "Sensör sistemi hatası. Derhal iniş yapın",
    "zh": "感知系统异常，请尽快降落"
  },
  "fpv_tip_0x161000F9": {
    "de": "Start nicht möglich. Schwaches Fernsteuerungssignal. In der Nähe der Fernsteuerung starten",
    "en": "Unable to take off. Remote controller signal weak. Take off near remote controller",
    "es": "No se puede despegar. Señal del control remoto débil. Despega cerca del control remoto.",
    "fr": "Impossible de décoller. Le signal de la radiocommande est faible. Décollez près de la radiocommande",
    "ja": "離陸できません。送信機信号 弱。送信機の近くで離陸してください",
    "ko": "이륙 불가. 조종기 신호 약함. 조종기 근처에서 이륙하세요.",
    "ru": "Не удается выполнить взлет. Слабый сигнал пульта управления. Взлетайте рядом с пультом дистанционного управления",
    "tr": "Kalkış yapılamıyor. Uzaktan kumanda sinyali zayıf. Uzaktan kumandanın yanında kalkış yapın",
    "zh": "无法起飞：遥控器信号弱，开启增强图传后请靠近飞行器起飞"
  },
  "fpv_tip_0x161000F9_in_the_sky": {
    "de": "Fehler: Flugregler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "Flight controller error. Return to home or land promptly",
    "es": "Error del controlador de vuelo. Regresa al punto de origen o aterriza rápidamente.",
    "fr": "Erreur du contrôleur de vol. Revenez au point de départ ou atterrissez rapidement",
    "ja": "フライトコントローラーエラー。直ちにホームに帰還するか着陸してください",
    "ko": "비행 컨트롤러 오류. 즉시 홈으로 돌아가거나 착륙하세요.",
    "ru": "Ошибка полетного контроллера. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "Uçuş kontrol sistemi hatası. Derhal başlangıç noktasına geri dönün veya iniş yapın",
    "zh": "飞控系统异常，请尽快返航或降落"
  },
  "fpv_tip_0x161000FA": {
    "de": "Start nicht möglich. Schwaches Fernsteuerungssignal. In der Nähe der Fernsteuerung starten",
    "en": "Unable to take off. Remote controller signal weak. Take off near remote controller",
    "es": "No se puede despegar. Señal del control remoto débil. Despega cerca del control remoto.",
    "fr": "Impossible de décoller. Le signal de la radiocommande est faible. Décollez près de la radiocommande",
    "ja": "離陸できません。送信機信号 弱。送信機の近くで離陸してください",
    "ko": "이륙 불가. 조종기 신호 약함. 조종기 근처에서 이륙하세요.",
    "ru": "Не удается выполнить взлет. Слабый сигнал пульта управления. Взлетайте рядом с пультом дистанционного управления",
    "tr": "Kalkış yapılamıyor. Uzaktan kumanda sinyali zayıf. Uzaktan kumandanın yanında kalkış yapın",
    "zh": "无法起飞：遥控器信号弱，开启增强图传后请靠近飞行器起飞"
  },
  "fpv_tip_0x161000FA_in_the_sky": {
    "de": "Fehler: Flugregler. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "Flight controller error. Return to home or land promptly",
    "es": "Error del controlador de vuelo. Regresa al punto de origen o aterriza rápidamente.",
    "fr": "Erreur du contrôleur de vol. Revenez au point de départ ou atterrissez rapidement",
    "ja": "フライトコントローラーエラー。直ちにホームに帰還するか着陸してください",
    "ko": "비행 컨트롤러 오류. 즉시 홈으로 돌아가거나 착륙하세요.",
    "ru": "Ошибка полетного контроллера. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "Uçuş kontrol sistemi hatası. Derhal başlangıç noktasına geri dönün veya iniş yapın",
    "zh": "飞控系统异常，请尽快返航或降落"
  },
  "fpv_tip_0x161000FB": {
    "ar": "",
    "de": "Start nicht möglich. Propeller beschädigt. DJI Support kontaktieren.",
    "en": "Unable to take off. Propeller damaged. Contact DJI Support",
    "es": "No se puede despegar. Hélice dañada. Póngase en contacto con la Asistencia técnica de DJI",
    "fr": "Impossible de décoller. Hélice endommagée. Contactez le service client DJI",
    "id": "",
    "it": "",
    "ja": "離陸できません。プロペラが破損しています。DJIサポートまでお問い合わせください",
    "ko": "이륙 불가. 프로펠러가 손상되었습니다. DJI 고객지원으로 문의하세요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Не удается выполнить взлет. Пропеллер поврежден. Свяжитесь со службой поддержки DJI",
    "th": "",
    "tr": "Kalkış yapılamıyor. Pervane hasar gördü. DJI Destek birimiyle iletişime geçin",
    "ug": "",
    "vi": "",
    "zh": "无法起飞：桨叶损坏，请联系售后服务",
    "zh-Hant": ""
  },
  "fpv_tip_0x161000FC": {
    "ar": "",
    "de": "Start nicht möglich. Standortauswahl von DJI Dock läuft",
    "en": "Unable to take off. DJI Dock in site selection process",
    "es": "No se puede despegar. DJI Dock en el proceso de selección del sitio",
    "fr": "Impossible de décoller. DJI Dock dans le processus de sélection du site",
    "id": "",
    "it": "",
    "ja": "離陸できません。DJI Dockがサイトを選択中です",
    "ko": "이륙 불가. DJI Dock이 장소 선택 중입니다",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Не удается выполнить взлет. DJI Dock в процессе выбора площадки",
    "th": "",
    "tr": "Kalkış yapılamıyor. Yer seçimi sürecinde DJI Dock",
    "ug": "",
    "vi": "",
    "zh": "无法起飞：机场选址中",
    "zh-Hant": ""
  },
  "fpv_tip_0x161000FD": {
    "de": "Abheben nicht möglich. Fluggerät befindet sich außerhalb des benutzerdefinierten Aufgabenbereichs oder näher als 10 Meter an der Bereichsgrenze",
    "en": "Unable to take off. Aircraft is outside of custom task area or within 10 meters of the area boundary",
    "es": "No se puede despegar. La aeronave se encuentra fuera del área de tareas personalizada o a menos de 10 metros del límite del área",
    "fr": "Décollage impossible. L'appareil se trouve en dehors de la zone d'intervention personnalisée ou à moins de 10 mètres de la limite de la zone",
    "ja": "離陸できません。航空機は、カスタム作業エリア外にあるか、またはエリア境界から10メートル以内にあります",
    "ko": "이륙 불가. 비행체가 맞춤 작업 영역 밖이거나 영역 경계선의 10미터 이내에 있습니다.",
    "ru": "Взлет невозможен. Дрон находится за пределами настроенного пользователем участка выполняемого задания или в пределах 10 метров от границы участка",
    "tr": "Kalkış yapılamıyor. Uçak, özel görev alanının dışında veya alan sınırının 10 metre yakınında",
    "zh": "无法起飞，请在自定义作业区内或远离边界10m以上"
  },
  "fpv_tip_0x161000FD_in_the_sky": {
    "de": "Aufgabe im benutzerdefinierten Einsatzgebiet ausführen",
    "en": "Execute task in custom task area",
    "es": "Ejecutar trabajo en la zona de trabajo personalizada",
    "fr": "Exécuter la tâche dans la zone de tâche personnalisée",
    "ja": "カスタムタスクエリアでタスクを実行してください",
    "ko": "맞춤 설정 작업 영역에서 작업을 실행하세요.",
    "ru": "Выполните задачу в пользовательской области задач",
    "tr": "Görevi özel görev alanında yürütün",
    "zh": "请在自定义作业区内作业"
  },
  "fpv_tip_0x161000a1": {
    "de": "Umgehend landen und prüfen, ob die Rahmenarm-Hülse ordnungsgemäß befestigt ist.",
    "en": "Unable to take off. Check whether frame arm sleeves are tightened securely",
    "es": "No se puede despegar. Comprueba si las fundas del brazo del bastidor están bien apretadas",
    "fr": "Atterrissez rapidement et vérifiez si les manchons des bras du châssis sont bien serrés",
    "ja": "直ちに着陸し、フレームアームスリーブがしっかりと固定されているかを確認してください",
    "ko": "즉시 착륙하여 프레임 암 슬리브가 단단히 조여졌는지 확인하세요.",
    "ru": "",
    "tr": "",
    "zh": "无法起飞：请检查机臂套筒是否固定到位"
  },
  "fpv_tip_0x161000a2": {
    "ar": "",
    "de": "Fehler: unterer Lüfter. Start nicht möglich. Überprüfen, ob der untere Lüfter blockiert ist",
    "en": "Lower fan error. Unable to take off. Check whether lower fan is stalled",
    "es": "Error del ventilador inferior. No se puede despegar. Compruebe si el ventilador inferior está atascado",
    "fr": "Erreur du ventilateur inférieur. Impossible de décoller. Vérifiez si le ventilateur inférieur est bloqué",
    "id": "",
    "it": "",
    "ja": "下部ファンエラー。離陸できません。下部ファンが停止していないかを確認してください",
    "ko": "하단 팬 오류입니다. 이륙 불가. 하단 팬이 멈췄는지 확인하세요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Ошибка нижнего вентилятора. Не удается выполнить взлет. Убедитесь, что нижний вентилятор не заклинило",
    "th": "",
    "tr": "Alt fan hatası. Kalkış yapılamıyor. Alt fanın arızalanarak durup durmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "无法起飞：下风扇异常，请检查飞行器下风扇是否有堵转",
    "zh-Hant": ""
  },
  "fpv_tip_0x161000a2_in_the_sky": {
    "ar": "",
    "de": "Fehler: unterer Lüfter. Zum Startpunkt zurückkehren oder landen. Überprüfen, ob der untere Lüfter blockiert ist",
    "en": "Lower fan error. Return to home or land. Check whether lower fan is stalled",
    "es": "Error del ventilador inferior. Regrese al punto de origen o aterrice. Compruebe si el ventilador inferior está atascado",
    "fr": "Erreur du ventilateur inférieur. Revenez au point de départ ou atterrissez. Vérifiez si le ventilateur inférieur est bloqué",
    "id": "",
    "it": "",
    "ja": "下部ファンエラー。RTHまたは着陸させてください。下部ファンが停止していないかを確認してください",
    "ko": "하단 팬 오류입니다. 홈으로 돌아가거나 착륙하세요. 하단 팬이 멈췄는지 확인하세요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Ошибка нижнего вентилятора. Вернитесь домой или приземлитесь. Убедитесь, что нижний вентилятор не заклинило",
    "th": "",
    "tr": "Alt fan hatası. Eve geri dönün veya iniş yapın. Alt fanın arızalanarak durup durmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "下风扇异常，请返航或降落，再检查飞行器下风扇是否有堵转",
    "zh-Hant": ""
  },
  "fpv_tip_0x161000a6": {
    "de": "Akku der Fernsteuerung schwach. Kann nicht abheben. Akku aufladen",
    "en": "Remote controller battery low. Unable to take off. Recharge battery",
    "es": "Batería del control remoto baja. No se puede despegar. Recárgala",
    "fr": "Batterie de la radiocommande faible. Décollage impossible. Rechargez la batterie",
    "it": "",
    "ja": "送信機バッテリー残量低下。離陸できません。バッテリーを充電してください",
    "ko": "조종기 배터리 부족. 이륙 불가. 배터리 충전 필요",
    "pt": "",
    "ru": "Низкий заряд аккумулятора пульта ДУ. Взлет невозможен. Зарядите аккумулятор",
    "tr": "Uzaktan kumanda pili düşük. Kalkış yapılamıyor. Pili yeniden şarj edin",
    "zh": "无法起飞：遥控器电量过低，请给遥控器充电"
  },
  "fpv_tip_0x161000a6_in_the_sky": {
    "de": "Akku der Fernsteuerung schwach. Sofort zurückkehren oder landen",
    "en": "Remote controller battery low. Return to home or land promptly",
    "es": "Batería del control remoto baja. Regresa al punto de origen o aterriza rápidamente",
    "fr": "Batterie de la radiocommande faible. Retournez au point de départ ou atterrissez rapidement",
    "it": "",
    "ja": "送信機バッテリー残量低下。直ちに帰還するか着陸してください",
    "ko": "조종기 배터리 부족. 즉시 RTH 또는 착륙 필요",
    "pt": "",
    "ru": "Низкий заряд аккумулятора пульта ДУ. Вернитесь домой или приземлитесь немедленно",
    "tr": "Uzaktan kumanda pili düşük. Eve geri dönün veya düzgün iniş yapın",
    "zh": "遥控器电量过低，请尽快返航或降落"
  },
  "fpv_tip_0x161000b4": {
    "en": "Remote ID broadcast error. Failed to obtain remote controller location. Unable to take off",
    "zh": "无法起飞：Remote ID 播报异常，无法获取遥控器位置"
  },
  "fpv_tip_0x161000b5": {
    "en": "Remote ID module error. Unable to take off. Contact DJI Support",
    "zh": "无法起飞，Remote ID 模块异常，请联系售后服务"
  },
  "fpv_tip_0x161000c0": {
    "de": "Start nicht möglich: Ausführungen des linken und des rechten Akkus stimmen nicht überein. Akku ersetzen, damit Ausführungen identisch sind",
    "en": "Unable to take off: left battery and right battery models do not match. Replace battery for model consistency",
    "es": "No se puede despegar: los modelos de batería izquierda y derecha no coinciden. Sustituya la batería para mantener la uniformidad del modelo",
    "fr": "Impossible de décoller : les modèles de batterie de gauche et de droite ne correspondent pas. Remplacer la batterie pour que les modèles soient identiques",
    "ja": "離陸できません: 左バッテリーと右バッテリーのモデルが一致しません。モデルの一貫性を保つためにバッテリーを交換してください",
    "ko": "이륙 불가: 왼쪽 배터리와 오른쪽 배터리 모델이 일치하지 않습니다. 모델 일관성을 위해 배터리를 교체하세요",
    "ru": "Не удается выполнить взлет: модели левой батареи и правой батареи не совпадают. Замените батарею для соответствия модели",
    "tr": "Kalkış yapılamıyor: Sol pil ve sağ pil modelleri uyuşmuyor. Model tutarlılığı için pili değiştirin",
    "zh": "无法起飞：左右电池型号不一致，请更换电池保持一致"
  },
  "fpv_tip_0x161000c0_in_the_sky": {
    "de": "Ausführungen des linken und des rechten Akkus stimmen nicht überein. Fluggerät sofort landen und Akku ersetzen",
    "en": "Left battery and right battery models do not match. Land aircraft immediately and replace battery",
    "es": "Los modelos de batería izquierda y derecha no coinciden. Aterrice la aeronave de inmediato y sustituya la batería",
    "fr": "Les modèles de batterie de gauche et de droite ne correspondent pas. Faites atterrir l'appareil immédiatement et remplacez la batterie",
    "ja": "左バッテリーと右バッテリーのモデルが一致しません。機体を直ちに着陸させ、バッテリーを交換してください",
    "ko": "왼쪽 배터리와 오른쪽 배터리 모델이 일치하지 않습니다. 기체를 즉시 착륙시켜 배터리를 교체하세요",
    "ru": "Модели левой батареи и правой батареи не совпадают. Немедленно посадите дрон и замените батарею",
    "tr": "Sol pil ve sağ pil modelleri uyuşmuyor. Aracı hemen indirin ve pili değiştirin",
    "zh": "左右电池型号不一致，请尽快降落更换电池"
  },
  "fpv_tip_0x1611000A": {
    "de": "Signal verloren. Rückkehrfunktion (RTH) aktiv",
    "en": "Signal lost. RTH in progress",
    "es": "Se ha perdido la señal. RPO en curso",
    "fr": "Perte de signal. RTH en cours",
    "ja": "信号ロストです。RTHが進行中です",
    "ko": "신호 끊김. RTH 진행 중",
    "ru": "Сигнал потерян. Выполняется возврат домой",
    "tr": "Sinyal kayboldu. Eve Dönüş sürüyor",
    "zh": "失联返航中"
  },
  "fpv_tip_0x1611004F": {
    "de": "Akkuauthentifizierung fehlgeschlagen",
    "en": "Battery authentication failed",
    "es": "Error de autenticación de la batería",
    "fr": "Échec de l'authentification de la batterie",
    "ja": "バッテリー認証に失敗しました",
    "ko": "배터리 인증에 실패했습니다",
    "ru": "Не удалось опознать АКБ",
    "tr": "Pil kimlik doğrulaması başarısız oldu",
    "zh": "电池认证失败"
  },
  "fpv_tip_0x1612000B": {
    "de": "Flughöhe zu hoch. Verwendung von Propellern für große Flughöhen empfohlen",
    "en": "Altitude too high. Using high-altitude propellers recommended",
    "es": "Altitud demasiado alta. Se recomienda el uso de hélices de gran altitud",
    "fr": "Altitude trop élevée. Utilisation d'hélices à haute altitude recommandée",
    "ja": "飛行高度が高すぎます。高高度向けプロペラの使用をお勧めします",
    "ko": "고도가 너무 높습니다. 높은 고도 프로펠러 사용을 권장합니다",
    "ru": "Дрон летит слишком высоко. Рекомендуется использовать высотные пропеллеры",
    "tr": "İrtifa çok yüksek. Yüksek irtifa pervanelerinin kullanılması önerilir",
    "zh": "海拔过高，推荐使用高原桨"
  },
  "fpv_tip_0x1612000C": {
    "de": "Flughöhe zu hoch. Ausschließlich Propeller für große Flughöhen verwenden",
    "en": "Altitude too high. Use high-altitude propellers only",
    "es": "Altitud demasiado alta. Utilice únicamente hélices de gran altitud",
    "fr": "Altitude trop élevée. Utilisez uniquement des hélices à haute altitude",
    "ja": "飛行高度が高すぎます。高高度向けプロペラのみを使用してください",
    "ko": "고도가 너무 높습니다. 높은 고도 프로펠러만 사용하세요",
    "ru": "Дрон летит слишком высоко. Используйте только высотные пропеллеры",
    "tr": "İrtifa çok yüksek. Yalnızca yüksek irtifa pervaneleri kullanın",
    "zh": "海拔过高，请务必使用高原桨"
  },
  "fpv_tip_0x1612000D": {
    "de": "Maximale Flughöhe erreicht. Auf Flugsicherheit und Flughöhe achten",
    "en": "Reaching maximum flight altitude. Pay attention to flight safety and altitude",
    "es": "Se está alcanzando la altitud de vuelo máxima. Preste atención a la seguridad y la altitud de vuelo",
    "fr": "Altitude en vol maximale bientôt atteinte. Faites attention à la sécurité en vol et à l'altitude",
    "ja": "最大飛行高度に近づいています。飛行安全性と高度に注意してください",
    "ko": "최고 비행 고도에 도달합니다. 비행 안전과 고도에 유의하세요",
    "ru": "Достижение максимальной высоты полета. Обращайте внимание на безопасность полета и высоту",
    "tr": "Maksimum uçuş irtifasına ulaşılıyor. Uçuş güvenliğine ve irtifaya dikkat edin",
    "zh": "海拔即将超过最大飞行高度，请注意飞行安全并控制高度"
  },
  "fpv_tip_0x16120010": {
    "de": "Nähert sich dem Boden. Mit Vorsicht fliegen",
    "en": "Approaching ground. Fly with caution",
    "es": "Acercándose al suelo. Vuela con cuidado",
    "fr": "Approche du sol. Pilotez avec précaution",
    "ja": "地面に接近中です。慎重に飛行してください",
    "ko": "지면 접근 중. 비행 시 주의 필요",
    "ru": "Приближение к поверхности. Соблюдайте осторожность при полете",
    "tr": "Yere yaklaşılıyor. Dikkatlice uçun",
    "zh": "接近地面，谨慎飞行"
  },
  "fpv_tip_0x16130001": {
    "zh": "船上起降模式，飞行器姿态过大，请谨慎飞行"
  },
  "fpv_tip_0x16130002": {
    "zh": "船上起降模式，船身速度过大，请谨慎飞行"
  },
  "fpv_tip_0x16130003": {
    "zh": "船上起降模式，飞行器姿态过大，请谨慎飞行"
  },
  "fpv_tip_0x16200101": {
    "de": "Fluggerät mit USB-Gerät verbunden. Start nicht möglich",
    "en": "Aircraft connected to USB device. Unable to take off",
    "es": "Aeronave conectada a dispositivo USB. No se puede despegar",
    "fr": "Appareil connecté à un périphérique USB. Impossible de décoller",
    "ja": "機体がUSB機器に接続されています。離陸できません",
    "ko": "기체가 USB 기기에 연결됨. 이륙 불가",
    "ru": "Дрон подключен к устройству USB. Не удается выполнить взлет",
    "tr": "Araç USB cihazına bağlı. Kalkış yapılamıyor",
    "zh": "无法起飞：已连接USB"
  },
  "fpv_tip_0x16200101_in_the_sky": {
    "de": "Fehler: USB-Anschluss. Mit Vorsicht fliegen",
    "en": "USB port error. Fly with caution",
    "es": "Error del puerto USB. Vuela con cuidado",
    "fr": "Erreur de port USB. Pilotez avec précaution",
    "ja": "USBポートのエラー。慎重に飛行してください",
    "ko": "USB 포트 오류. 비행 시 주의 필요",
    "ru": "Ошибка порта USB. Соблюдайте осторожность при полете",
    "tr": "USB bağlantı noktası hatası. Aracınızı dikkatli uçurun",
    "zh": "USB接口异常，请谨慎飞行"
  },
  "fpv_tip_0x16200102": {
    "de": "Protokolle werden gelöscht. Start nicht möglich",
    "en": "Clearing logs. Unable to take off",
    "es": "Borrando los registros. No se puede despegar",
    "fr": "Effacement des journaux. Impossible de décoller",
    "ja": "ログを消去中。離陸できません",
    "ko": "로그 지우는 중. 이륙 불가",
    "ru": "Очистка журналов. Не удается выполнить взлет",
    "tr": "Günlükler siliniyor. Kalkış yapılamıyor",
    "zh": "无法起飞：清除日志中"
  },
  "fpv_tip_0x16200102_in_the_sky": {
    "de": "Protokolle werden gelöscht. Mit Vorsicht fliegen",
    "en": "Clearing logs. Fly with caution",
    "es": "Borrando los registros. Vuela con cuidado",
    "fr": "Effacement des journaux. Pilotez avec précaution",
    "ja": "ログを消去中。慎重に飛行してください",
    "ko": "로그 지우는 중. 비행 시 주의 필요",
    "ru": "Очистка журналов. Соблюдайте осторожность при полете",
    "tr": "Günlükler siliniyor. Aracınızı dikkatli uçurun",
    "zh": "清除日志中，请谨慎飞行"
  },
  "fpv_tip_0x16200103": {
    "de": "Aktualisiere Firmware. Start nicht möglich",
    "en": "Updating firmware. Unable to take off",
    "es": "Actualizando el firmware. No se puede despegar",
    "fr": "Màj du firmware... Impossible de décoller",
    "ja": "ファームウェアを更新中。離陸できません",
    "ko": "펌웨어 업데이트 중. 이륙 불가",
    "ru": "Выполняется обновление прошивки. Не удается выполнить взлет",
    "tr": "Ürün yazılımı güncelleniyor. Kalkış yapılamıyor",
    "zh": "无法起飞：固件升级中"
  },
  "fpv_tip_0x16200103_in_the_sky": {
    "de": "Aktualisiere Firmware. Mit Vorsicht fliegen",
    "en": "Updating firmware. Fly with caution",
    "es": "Actualizando el firmware. Vuela con cuidado",
    "fr": "Màj du firmware... Pilotez avec précaution",
    "ja": "ファームウェアを更新中。慎重に飛行してください",
    "ko": "펌웨어 업데이트 중. 비행 시 주의 필요",
    "ru": "Выполняется обновление прошивки. Соблюдайте осторожность при полете",
    "tr": "Ürün yazılımı güncelleniyor. Aracınızı dikkatli uçurun",
    "zh": "固件升级中，请谨慎飞行"
  },
  "fpv_tip_0x16200104": {
    "ar": "",
    "de": "Start nicht möglich. Firmware-Aktualisierung erforderlich oder Aktualisierung läuft",
    "en": "Unable to take off. Firmware update required or update in progress",
    "es": "No se puede despegar. Es necesario actualizar el firmware o hay una actualización en curso",
    "fr": "Impossible de décoller. Mise à jour du firmware requise ou mise à jour en cours",
    "id": "",
    "it": "",
    "ja": "離陸できません。ファームウェアの強制更新が必要か、更新が進行中です",
    "ko": "이륙 불가. 펌웨어 업데이트가 필요하거나 업데이트가 진행 중입니다",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Не удается выполнить взлет. Требуется обновление прошивки или выполняется обновление",
    "th": "",
    "tr": "Kalkış yapılamıyor. Ürün yazılımı güncellemesi gerekli veya güncelleme devam ediyor",
    "ug": "",
    "vi": "",
    "zh": "无法起飞：固件需要升级或正在升级中",
    "zh-Hant": ""
  },
  "fpv_tip_0x16200104_in_the_sky": {
    "ar": "",
    "de": "Obligatorische Firmware-Aktualisierung wird durchgeführt. Mit Vorsicht fliegen",
    "en": "Firmware update required. Fly with caution",
    "es": "Actualización obligatoria del firmware en curso. Vuela con cuidado",
    "fr": "Mise à jour obligatoire du firmware en cours. Pilotez avec précaution",
    "id": "",
    "it": "",
    "ja": "ファームウェアを強制更新中。慎重に飛行してください",
    "ko": "필수 펌웨어 업데이트 진행 중. 비행 시 주의 필요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Идет обязательное обновление прошивки. Соблюдайте осторожность при полете",
    "th": "",
    "tr": "Zorunlu ürün yazılımı güncellemesi devam ediyor. Aracınızı dikkatli uçurun",
    "ug": "",
    "vi": "",
    "zh": "固件强制升级中，请谨慎飞行",
    "zh-Hant": ""
  },
  "fpv_tip_0x16200201": {
    "de": "FlySafe-Datenbank wird aktualisiert. Start nicht möglich",
    "en": "Updating Fly Safe database. Unable to take off",
    "es": "Actualizado la base de datos Vuela Seguro. No se puede despegar",
    "fr": "Mise à jour de la base de données Fly Safe. Impossible de décoller",
    "ja": "安全飛行データベースを更新中。離陸できません",
    "ko": "비행 안전 데이터베이스 업데이트 중. 이륙 불가",
    "ru": "Выполняется обновление базы данных \"Безопасность полетов\". Не удается выполнить взлет",
    "tr": "Güvenli Uçuş veritabanı güncelleniyor. Kalkış yapılamıyor",
    "zh": "无法起飞：限飞数据库升级中"
  },
  "fpv_tip_0x16200201_in_the_sky": {
    "de": "FlySafe-Datenbank wird aktualisiert. Mit Vorsicht fliegen",
    "en": "Updating Fly Safe database. Fly with caution",
    "es": "Actualizado la base de datos Vuela Seguro. Vuela con cuidado",
    "fr": "Mise à jour de la base de données Fly Safe. Pilotez avec précaution",
    "ja": "安全飛行データベースを更新中。慎重に飛行してください",
    "ko": "비행 안전 데이터베이스 업데이트 중. 비행 시 주의 필요",
    "ru": "Выполняется обновление базы данных \"Безопасность полетов\". Соблюдайте осторожность при полете",
    "tr": "Güvenli Uçuş veritabanı güncelleniyor. Aracınızı dikkatli uçurun",
    "zh": "限飞数据库升级中，请谨慎飞行"
  },
  "fpv_tip_0x16200202": {
    "de": "Kalibrierungsfehler: Sichtsensoren. Start nicht möglich",
    "en": "Vision sensor calibration error. Unable to take off",
    "es": "Error de calibración del sensor visual. No se puede despegar",
    "fr": "Erreur d'étalonnage du capteur optique. Impossible de décoller",
    "ja": "ビジョンセンサーのキャリブレーションエラー。離陸できません",
    "ko": "비전 센서 캘리브레이션 오류. 이륙 불가",
    "ru": "Ошибка калибровки датчика обзора. Не удается выполнить взлет",
    "tr": "Görüş sensörü kalibrasyon hatası. Kalkış yapılamıyor",
    "zh": "无法起飞：视觉传感器标定异常"
  },
  "fpv_tip_0x16200202_in_the_sky": {
    "de": "Kalibrierungsfehler: Sichtsensoren. Mit Vorsicht fliegen",
    "en": "Vision sensor calibration error. Fly with caution",
    "es": "Error de calibración del sensor visual. Vuela con cuidado",
    "fr": "Erreur d'étalonnage du capteur optique. Pilotez avec précaution",
    "ja": "ビジョンセンサーのキャリブレーションエラー。慎重に飛行してください",
    "ko": "비전 센서 캘리브레이션 오류. 비행 시 주의 필요",
    "ru": "Ошибка калибровки датчика обзора. Соблюдайте осторожность при полете",
    "tr": "Görüş sensörü kalibrasyon hatası. Aracınızı dikkatli uçurun",
    "zh": "视觉传感器标定异常，请谨慎飞行"
  },
  "fpv_tip_0x16200203": {
    "de": "Kalibrierungsfehler: ToF-Sensor. Start nicht möglich",
    "en": "ToF sensor calibration error. Unable to take off",
    "es": "Error de calibración del sensor de tiempo de vuelo. No se puede despegar",
    "fr": "Erreur étalonnage capteur ToF. Impossible de décoller",
    "ja": "ToFセンサーのキャリブレーションエラー。離陸できません",
    "ko": "ToF 센서 캘리브레이션 오류. 이륙 불가",
    "ru": "Ошибка калибровки датчика ToF. Не удается выполнить взлет",
    "tr": "ToF sensörü kalibrasyon hatası. Kalkış yapılamıyor",
    "zh": "无法起飞：ToF标定异常"
  },
  "fpv_tip_0x16200203_in_the_sky": {
    "de": "Kalibrierungsfehler: ToF-Sensor. Mit Vorsicht fliegen",
    "en": "ToF sensor calibration error. Fly with caution",
    "es": "Error de calibración del sensor de tiempo de vuelo. Vuela con cuidado",
    "fr": "Erreur étalonnage capteur ToF. Pilotez avec précaution",
    "ja": "ToFセンサーのキャリブレーションエラー。慎重に飛行してください",
    "ko": "ToF 센서 캘리브레이션 오류. 비행 시 주의 필요",
    "ru": "Ошибка калибровки датчика ToF. Соблюдайте осторожность при полете",
    "tr": "ToF sensörü kalibrasyon hatası. Aracınızı dikkatli uçurun",
    "zh": "ToF标定异常，请谨慎飞行"
  },
  "fpv_tip_0x16200204": {
    "de": "Statusfehler: Sichtsensoren. Start nicht möglich",
    "en": "Vision sensor status error. Unable to take off",
    "es": "Error de estado del sensor visual. No se puede despegar",
    "fr": "Erreur de statut du capteur optique. Impossible de décoller",
    "ja": "ビジョンセンサーのステータスエラー。離陸できません",
    "ko": "비전 센서 상태 오류. 이륙 불가",
    "ru": "Ошибка статуса датчика изображения. Не удается выполнить взлет",
    "tr": "Görüş sensörü durum hatası. Kalkış yapılamıyor",
    "zh": "无法起飞：视觉传感器状态异常"
  },
  "fpv_tip_0x16200204_in_the_sky": {
    "de": "Statusfehler: Sichtsensoren. Mit Vorsicht fliegen",
    "en": "Vision sensor status error. Fly with caution",
    "es": "Error de estado del sensor visual. Vuela con cuidado",
    "fr": "Erreur de statut du capteur optique. Pilotez avec précaution",
    "ja": "ビジョンセンサーのステータスエラー。慎重に飛行してください",
    "ko": "비전 센서 상태 오류. 비행 시 주의 필요",
    "ru": "Ошибка статуса датчика изображения. Соблюдайте осторожность при полете",
    "tr": "Görüş sensörü durum hatası. Aracınızı dikkatli uçurun",
    "zh": "视觉传感器状态异常，请谨慎飞行"
  },
  "fpv_tip_0x16200208": {
    "de": "Propellerfehler. Start nicht möglich. Sicherstellen, dass die Propeller nicht beschädigt sind. Fluggerät neu starten und erneut versuchen",
    "en": "Propeller error. Unable to take off. Make sure propellers are not damaged. Restart aircraft and try again",
    "es": "Error de la hélice. No se puede despegar. Asegúrese de que las hélices no estén dañadas. Reinicie la aeronave y vuelva a intentarlo",
    "fr": "Erreur d'hélice. Impossible de décoller. Assurez-vous que les hélices ne sont pas endommagées. Redémarrez l’appareil et réessayez",
    "ja": "プロペラエラーです。離陸できません。プロペラが破損していないことを確かにしてください。機体を再起動し、やり直してください",
    "ko": "프로펠러 오류입니다. 이륙 불가. 프로펠러가 손상되지 않았는지 확인하세요. 기체를 재시작한 후 다시 시도하세요",
    "ru": "Ошибка пропеллера. Невозможно взлететь. Убедитесь, что пропеллеры не повреждены. Перезапустите дрон и повторите попытку",
    "tr": "Pervane hatası. Kalkış yapılamıyor. Pervanelerin hasar görmediğinden emin olun. Hava aracını tekrar başlatın ve yeniden deneyin",
    "zh": "桨叶异常，无法起飞，请确认桨叶完好无损后重启飞机再试"
  },
  "fpv_tip_0x16200301": {
    "de": "Gerätefehler am Erweiterungsanschluss. Start nicht möglich. PSDK-Gerät überprüfen",
    "en": "Extension port device error. Unable to take off. Check PSDK device",
    "es": "Error de dispositivo de puerto de ampliación. No se puede despegar. Comprueba el dispositivo PSDK",
    "fr": "Erreur de périphérique de port d'extension. Impossible de décoller. Vérifier le périphérique PSDK",
    "it": "",
    "ja": "拡張ポート機器エラー。離陸できません。PSDK機器を確認してください",
    "ko": "확장 포트 기기 오류. 이륙 불가. PSDK 기기 확인",
    "pt": "",
    "ru": "Ошибка устройства порта расширения. Не удается выполнить взлет. Проверьте устройство PSDK",
    "tr": "Genişletme portu cihaz hatası. Kalkış yapılamıyor. PSDK cihazını kontrol edin",
    "zh": "无法起飞：扩展口设备异常，请检查PSDK设备"
  },
  "fpv_tip_0x16200301_in_the_sky": {
    "de": "Gerätefehler am Erweiterungsanschluss. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "Extension port device error. Return to home or land promptly",
    "es": "Error de dispositivo de puerto de ampliación. Regresa al punto de origen o aterriza rápidamente",
    "fr": "Erreur de périphérique de port d'extension. Retournez au point de départ ou atterrissez rapidement",
    "ja": "拡張ポート機器エラー。直ちに帰還するか、着陸してください",
    "ko": "확장 포트 기기 오류. 즉시 리턴 투 홈 또는 착륙 필요",
    "ru": "Ошибка устройства порта расширения. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "Genişletme portu cihaz hatası. Ana konuma geri dönün veya derhal iniş yapın",
    "zh": "扩展口设备异常，请尽快返航或降落"
  },
  "fpv_tip_0x16200302": {
    "de": "Fehler: Nutzlasten von Drittanbietern. Start nicht möglich",
    "en": "Third-party payload error. Unable to take off",
    "es": "Error de instrumentos de terceros. No se puede despegar",
    "fr": "Erreur de nacelles-caméras tierces. Impossible de décoller",
    "ja": "他社製ペイロードのエラー。離陸できません",
    "ko": "타사 페이로드 오류. 이륙 불가",
    "ru": "Ошибка сторонней полезной нагрузки. Не удается выполнить взлет",
    "tr": "Üçüncü taraf yük hatası. Kalkış yapılamıyor",
    "zh": "无法起飞：第三方负载设备异常"
  },
  "fpv_tip_0x16200302_in_the_sky": {
    "de": "Propeller werden gestoppt. Landebereich basierend auf aktueller Position des Fluggeräts vorhersagen",
    "en": "Stopping propellers. Predict landing area based on current aircraft location",
    "es": "Deteniendo las hélices. Predecir el área de aterrizaje según la ubicación actual de la aeronave",
    "fr": "Arrêt des hélices. Prédisez l'aire d'atterrissage en fonction de la position actuelle de l'appareil",
    "ja": "プロペラを停止中。機体の現在地に基づいて着陸エリアを予測してください",
    "ko": "프로펠러 정지 중. 현재 기체 위치를 기준으로 착륙 지점 예측 필요",
    "ru": "Остановка пропеллеров. Прогнозировать зону посадки на основе текущего местоположения дрона",
    "tr": "Pervaneler durduruluyor. Aracın mevcut konumuna göre iniş alanını tahmin edin",
    "zh": "执行空中紧急停桨，请根据飞行器当前位置预测降落点位置"
  },
  "fpv_tip_0x16200401": {
    "de": "Obligatorische Aktualisierung der FlySafe-Datenbank wird durchgeführt. Start nicht möglich",
    "en": "Mandatory Fly Safe database update in progress. Unable to take off",
    "es": "Actualización obligatoria de la base de datos Vuela Seguro en curso. No se puede despegar",
    "fr": "Mise à jour obligatoire de la base de données Fly Safe en cours. Impossible de décoller",
    "ja": "安全飛行データベースを強制更新中。離陸できません",
    "ko": "필수 비행 안전 데이터베이스 업데이트 진행 중. 이륙 불가",
    "ru": "Выполняется обязательное обновление базы данных \"Безопасность полетов\". Не удается выполнить взлет",
    "tr": "Zorunlu Güvenli Uçuş veritabanı güncellemesi devam ediyor. Kalkış yapılamıyor",
    "zh": "无法起飞：APP限飞数据库强制升级中"
  },
  "fpv_tip_0x16200401_in_the_sky": {
    "de": "Obligatorische Aktualisierung der FlySafe-Datenbank wird durchgeführt. Mit Vorsicht fliegen",
    "en": "Mandatory Fly Safe database update in progress. Fly with caution",
    "es": "Actualización obligatoria de la base de datos Vuela Seguro en curso. Vuela con cuidado",
    "fr": "Mise à jour obligatoire de la base de données Fly Safe en cours. Pilotez avec précaution",
    "ja": "安全飛行データベースを強制更新中。慎重に飛行してください",
    "ko": "필수 비행 안전 데이터베이스 업데이트 진행 중. 비행 시 주의 필요",
    "ru": "Выполняется обязательное обновление базы данных \"Безопасность полетов\". Соблюдайте осторожность при полете",
    "tr": "Zorunlu Güvenli Uçuş veritabanı güncellemesi devam ediyor. Aracınızı dikkatli uçurun",
    "zh": "APP限飞数据库强制升级中，请谨慎飞行"
  },
  "fpv_tip_0x16200402": {
    "de": "Obligatorische Firmware-Aktualisierung wird durchgeführt. Start nicht möglich",
    "en": "Mandatory firmware update in progress. Unable to take off",
    "es": "Actualización obligatoria del firmware en curso. No se puede despegar",
    "fr": "Mise à jour obligatoire du firmware en cours. Impossible de décoller",
    "ja": "ファームウェアを強制更新中。離陸できません",
    "ko": "필수 펌웨어 업데이트 진행 중. 이륙 불가",
    "ru": "Идет обязательное обновление прошивки. Не удается выполнить взлет",
    "tr": "Zorunlu ürün yazılımı güncellemesi devam ediyor. Kalkış yapılamıyor",
    "zh": "无法起飞：APP固件强制升级中"
  },
  "fpv_tip_0x16200402_in_the_sky": {
    "de": "Obligatorische Firmware-Aktualisierung wird durchgeführt. Mit Vorsicht fliegen",
    "en": "Mandatory firmware update in progress. Fly with caution",
    "es": "Actualización obligatoria del firmware en curso. Vuela con cuidado",
    "fr": "Mise à jour obligatoire du firmware en cours. Pilotez avec précaution",
    "ja": "ファームウェアを強制更新中。慎重に飛行してください",
    "ko": "필수 펌웨어 업데이트 진행 중. 비행 시 주의 필요",
    "ru": "Идет обязательное обновление прошивки. Соблюдайте осторожность при полете",
    "tr": "Zorunlu ürün yazılımı güncellemesi devam ediyor. Aracınızı dikkatli uçurun",
    "zh": "APP固件强制升级中，请谨慎飞行"
  },
  "fpv_tip_0x16200501": {
    "de": "Inkompatible Akkuversionen. Start nicht möglich",
    "en": "Incompatible battery versions. Unable to take off",
    "es": "Versiones de batería incompatibles. No se puede despegar",
    "fr": "Versions de batterie incompatibles. Impossible de décoller",
    "ja": "互換性のないバッテリーバージョンです。離陸できません",
    "ko": "호환되지 않는 배터리 버전. 이륙 불가",
    "ru": "Несовместимые версии АКБ. Не удается выполнить взлет",
    "tr": "Uyumsuz pil modeli. Kalkış yapılamıyor",
    "zh": "无法起飞：电池版本不匹配"
  },
  "fpv_tip_0x16200501_in_the_sky": {
    "de": "Inkompatible Akkuversionen. Umgehend zum Startpunkt zurückkehren oder landen",
    "en": "Incompatible battery versions. Return to home or land promptly",
    "es": "Versiones de batería incompatibles. Regrese al punto de origen o aterrice rápidamente",
    "fr": "Versions de batterie incompatibles. Retournez au point de départ ou atterrissez rapidement",
    "ja": "互換性のないバッテリーバージョンです。直ちに帰還するか、着陸してください",
    "ko": "호환되지 않는 배터리 버전. 즉시 리턴 투 홈을 실행하거나 착륙하세요",
    "ru": "Несовместимые версии АКБ. Как можно скорее вернитесь на базу или приземлитесь",
    "tr": "Uyumsuz pil modeli. Başlangıç noktasına geri dönün veya derhal iniş yapın",
    "zh": "电池版本不匹配，请尽快返航或降落"
  },
  "fpv_tip_0x16200601": {
    "de": "Flugsystem wird initialisiert...",
    "en": "Initializing flight system...",
    "es": "Iniciando sistema de vuelo...",
    "fr": "Initialisation du système de vol...",
    "ja": "フライトシステムを初期化中...",
    "ko": "비행 시스템 초기화 중...",
    "ru": "Инициализация системы управления полетом...",
    "tr": "Uçuş sistemi başlatılıyor...",
    "zh": "飞行系统初始化中，请等待"
  },
  "fpv_tip_0x16200601_in_the_sky": {
    "de": "Flugsystem wird initialisiert...",
    "en": "Initializing flight system...",
    "es": "Iniciando sistema de vuelo...",
    "fr": "Initialisation du système de vol...",
    "ja": "フライトシステムを初期化中...",
    "ko": "비행 시스템 초기화 중...",
    "ru": "Инициализация системы управления полетом...",
    "tr": "Uçuş sistemi başlatılıyor...",
    "zh": "飞行系统初始化中，请等待"
  },
  "fpv_tip_0x16200A01": {
    "ar": "",
    "de": "",
    "en": "Unable to take off. Battery cover off",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "无法起飞：未接电池帽盖",
    "zh-Hant": ""
  },
  "fpv_tip_0x16200A01_in_the_sky": {
    "ar": "",
    "de": "",
    "en": "Battery communication error. Fly with caution",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "电池通信异常，请谨慎飞行",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400002": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "AP主动释放控制权",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400003": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "AP控制指令异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400004": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "AP心跳异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400005": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "mcu侧mission调度器状态数据包异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400006": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "mission调度器异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400007": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "调度器没有mission运行",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400008": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "AP到MCU链路异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400009": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "AP没有发送控制指令",
    "zh-Hant": ""
  },
  "fpv_tip_0x1640000A": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "AP挂死",
    "zh-Hant": ""
  },
  "fpv_tip_0x1640000B": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "系统实时性异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1640000C": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "业务实时性异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400100": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "抽象数据fly_core无内容",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400101": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "抽象数据fly_extend无内容",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400102": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "抽象数据fmu_gps无内容",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400103": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "抽象数据fmu_rtk无内容",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400104": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "抽象数据fusion_out无内容",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400105": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "抽象数据vio_result无内容",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400106": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "抽象数据image_status无内容",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400107": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "抽象数据radar_detect_info无内容",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400108": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "抽象数据gimbal_state无内容",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400109": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "抽象数据camera_state无内容",
    "zh-Hant": ""
  },
  "fpv_tip_0x1640010A": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "抽象数据camera_cap_status无内容",
    "zh-Hant": ""
  },
  "fpv_tip_0x1640010B": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "抽象数据camera_video_param无内容",
    "zh-Hant": ""
  },
  "fpv_tip_0x1640010C": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "抽象数据camera_lens_param无内容",
    "zh-Hant": ""
  },
  "fpv_tip_0x1640010D": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "抽象数据camera_lens_param_ir无内容",
    "zh-Hant": ""
  },
  "fpv_tip_0x1640010E": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "抽象数据camera_data_hd无内容",
    "zh-Hant": ""
  },
  "fpv_tip_0x1640010F": {
    "ar": "",
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "抽象数据camera_data_vga无内容",
    "zh-Hant": ""
  },
  "fpv_tip_0x16400110": {
    "en": "",
    "zh": "抽象数据gimbal_state无内容"
  },
  "fpv_tip_0x16400111": {
    "en": "",
    "zh": "抽象数据camera_state无内容"
  },
  "fpv_tip_0x16400112": {
    "en": "",
    "zh": "抽象数据camera_cap_status无内容"
  },
  "fpv_tip_0x16400113": {
    "en": "",
    "zh": "抽象数据camera_video_param无内容"
  },
  "fpv_tip_0x16400114": {
    "en": "",
    "zh": "抽象数据camera_lens_param无内容"
  },
  "fpv_tip_0x16400115": {
    "en": "",
    "zh": "抽象数据camera_lens_param_ir无内容"
  },
  "fpv_tip_0x16400116": {
    "en": "",
    "zh": "抽象数据camera_data_hd无内容"
  },
  "fpv_tip_0x16400117": {
    "en": "",
    "zh": "抽象数据camera_data_vga无内容"
  },
  "fpv_tip_0x16400118": {
    "en": "",
    "zh": "杆量直通rc异常"
  },
  "fpv_tip_0x16400119": {
    "en": "",
    "zh": "杆量直通motion_rc异常"
  },
  "fpv_tip_0x16416001": {
    "en": "",
    "zh": "DSP向ARM发出中断失败"
  },
  "fpv_tip_0x16416002": {
    "en": "",
    "zh": "DSP侧交互部分超时"
  },
  "fpv_tip_0x16418000": {
    "en": "",
    "zh": "使用者调用dsp接口出错"
  },
  "fpv_tip_0x16418001": {
    "en": "",
    "zh": "DSP输入参数错误"
  },
  "fpv_tip_0x16418500": {
    "en": "",
    "zh": "开发者设计dsp接口出错"
  },
  "fpv_tip_0x16418501": {
    "en": "",
    "zh": "DSP算子调用超时"
  },
  "fpv_tip_0x16418502": {
    "en": "",
    "zh": "DSP算子调用忙"
  },
  "fpv_tip_0x16420000": {
    "en": "",
    "zh": "飞行系统模块初始化失败"
  },
  "fpv_tip_0x16420001": {
    "en": "",
    "zh": "飞行系统AP_Linux模块init失败"
  },
  "fpv_tip_0x16420002": {
    "en": "",
    "zh": "飞行系统AP_Linux模块start失败"
  },
  "fpv_tip_0x16420003": {
    "en": "",
    "zh": "飞行系统AP_RTOS模块init失败"
  },
  "fpv_tip_0x16420004": {
    "en": "",
    "zh": "飞行系统AP_RTOS模块start失败"
  },
  "fpv_tip_0x16420010": {
    "en": "",
    "zh": "飞行系统功耗管理异常"
  },
  "fpv_tip_0x16420011": {
    "en": "",
    "zh": "sysmode未响应event事件"
  },
  "fpv_tip_0x16420012": {
    "en": "",
    "zh": "飞行系统event事件通知失败"
  },
  "fpv_tip_0x16420013": {
    "en": "",
    "zh": "飞行系统功耗场景切换超时"
  },
  "fpv_tip_0x16420014": {
    "en": "",
    "zh": "功耗管理消息队列发送超时"
  },
  "fpv_tip_0x16420015": {
    "en": "",
    "zh": "功耗管理节点状态同步失败"
  },
  "fpv_tip_0x16420016": {
    "en": "",
    "zh": "功耗管理子节点间V1消息不通"
  },
  "fpv_tip_0x16420020": {
    "en": "",
    "zh": "能力项参数同步失败"
  },
  "fpv_tip_0x16420021": {
    "en": "",
    "zh": "csdk断连"
  },
  "fpv_tip_0x16420022": {
    "en": "",
    "zh": "能力集参数表数据读取失败"
  },
  "fpv_tip_0x16420100": {
    "en": "",
    "zh": "dbus话题app_state无数据"
  },
  "fpv_tip_0x16420101": {
    "en": "",
    "zh": "dbus话题battery_state无数据"
  },
  "fpv_tip_0x16420102": {
    "en": "",
    "zh": "dbus话题flight_state无数据"
  },
  "fpv_tip_0x16420103": {
    "en": "",
    "zh": "dbus话题fly_limit无数据"
  },
  "fpv_tip_0x16420104": {
    "en": "",
    "zh": "dbus话题home_state无数据"
  },
  "fpv_tip_0x16420105": {
    "en": "",
    "zh": "dbus话题mission_config无数据"
  },
  "fpv_tip_0x16420106": {
    "en": "",
    "zh": "dbus话题rc_state无数据"
  },
  "fpv_tip_0x16420107": {
    "en": "",
    "zh": "dbus话题sensor_data无数据"
  },
  "fpv_tip_0x16420108": {
    "en": "",
    "zh": "dbus话题flight_record无数据"
  },
  "fpv_tip_0x16420109": {
    "en": "",
    "zh": "dbus话题motion_rc_info无数据"
  },
  "fpv_tip_0x1642010A": {
    "en": "",
    "zh": "dbus话题vio_result无数据"
  },
  "fpv_tip_0x1642010B": {
    "en": "",
    "zh": "dbus话题image_status无数据"
  },
  "fpv_tip_0x1642010C": {
    "en": "",
    "zh": "dbus话题fmu_gps无数据"
  },
  "fpv_tip_0x1642010D": {
    "en": "",
    "zh": "dbus话题fmu_rtk无数据"
  },
  "fpv_tip_0x1642010E": {
    "en": "",
    "zh": "dbus话题fusion_out无数据"
  },
  "fpv_tip_0x1642010F": {
    "en": "",
    "zh": "dbus话题radar_detect_info无数据"
  },
  "fpv_tip_0x16420110": {
    "en": "",
    "zh": "dbus话题gimbal_state无数据"
  },
  "fpv_tip_0x16420111": {
    "en": "",
    "zh": "dbus话题camera_state无数据"
  },
  "fpv_tip_0x16420112": {
    "en": "",
    "zh": "dbus话题camera_cap_status无数据"
  },
  "fpv_tip_0x16420113": {
    "en": "",
    "zh": "dbus话题camera_video_param无数据"
  },
  "fpv_tip_0x16420114": {
    "en": "",
    "zh": "dbus话题camera_lens_param无数据"
  },
  "fpv_tip_0x16420115": {
    "en": "",
    "zh": "dbus话题camera_lens_param_ir无数据"
  },
  "fpv_tip_0x16420116": {
    "en": "",
    "zh": "dbus话题camera_data_hd无数据"
  },
  "fpv_tip_0x16420117": {
    "en": "",
    "zh": "dbus话题camera_data_vga无数据"
  },
  "fpv_tip_0x16430001": {
    "en": "",
    "zh": "异常退出焦点跟随"
  },
  "fpv_tip_0x16430002": {
    "en": "",
    "zh": "异常退出到聚焦"
  },
  "fpv_tip_0x16430003": {
    "en": "",
    "zh": "异常退出到多目标扫描"
  },
  "fpv_tip_0x16430004": {
    "en": "",
    "zh": "焦点跟随异常提示"
  },
  "fpv_tip_0x16430005": {
    "en": "",
    "zh": "融合数据无效"
  },
  "fpv_tip_0x16430006": {
    "en": "",
    "zh": "传感器数据更新失败"
  },
  "fpv_tip_0x16430007": {
    "en": "",
    "zh": "未起飞"
  },
  "fpv_tip_0x16430008": {
    "en": "",
    "zh": "相机模式不支持"
  },
  "fpv_tip_0x16430009": {
    "en": "",
    "zh": "融合时间戳异常"
  },
  "fpv_tip_0x1643000A": {
    "en": "",
    "zh": "抽象数据指针为空"
  },
  "fpv_tip_0x1643000B": {
    "en": "",
    "zh": "DBUS获取数据指针为空"
  },
  "fpv_tip_0x1643000C": {
    "en": "",
    "zh": "TM异常"
  },
  "fpv_tip_0x1643000D": {
    "en": "",
    "zh": "飞机姿态过大"
  },
  "fpv_tip_0x1643000E": {
    "en": "",
    "zh": "环境光过暗"
  },
  "fpv_tip_0x1643000F": {
    "en": "",
    "zh": "遥控器断连"
  },
  "fpv_tip_0x16430010": {
    "en": "",
    "zh": "TKPlanner异常"
  },
  "fpv_tip_0x16430011": {
    "en": "",
    "zh": "TE异常"
  },
  "fpv_tip_0x16430012": {
    "en": "",
    "zh": "POI异常"
  },
  "fpv_tip_0x16430013": {
    "en": "",
    "zh": "ML异常"
  },
  "fpv_tip_0x16430014": {
    "en": "",
    "zh": "数据链路异常"
  },
  "fpv_tip_0x16430015": {
    "en": "",
    "zh": "IMU数据异常"
  },
  "fpv_tip_0x16430016": {
    "en": "",
    "zh": "云台数据异常"
  },
  "fpv_tip_0x16430017": {
    "en": "",
    "zh": "fov数据异常"
  },
  "fpv_tip_0x16430018": {
    "en": "",
    "zh": "ml状态异常"
  },
  "fpv_tip_0x16430019": {
    "en": "",
    "zh": "soe数据异常"
  },
  "fpv_tip_0x1643001A": {
    "en": "",
    "zh": "IMU数据无效"
  },
  "fpv_tip_0x1643001B": {
    "en": "",
    "zh": "云台数据无效"
  },
  "fpv_tip_0x1643001C": {
    "en": "",
    "zh": "fov数据无效"
  },
  "fpv_tip_0x1643001D": {
    "en": "",
    "zh": "ml状态数据无效"
  },
  "fpv_tip_0x1643001E": {
    "en": "",
    "zh": "soe数据无效"
  },
  "fpv_tip_0x1643001F": {
    "en": "",
    "zh": "环绕测距时间过长"
  },
  "fpv_tip_0x16430020": {
    "en": "",
    "zh": "环绕时距地面过近"
  },
  "fpv_tip_0x16430021": {
    "en": "",
    "zh": "限远"
  },
  "fpv_tip_0x16430022": {
    "en": "",
    "zh": "限高"
  },
  "fpv_tip_0x16430023": {
    "en": "",
    "zh": "目标类型不支持"
  },
  "fpv_tip_0x16430024": {
    "en": "",
    "zh": "将要进入Tracking/环绕时目标丢失"
  },
  "fpv_tip_0x16430025": {
    "en": "",
    "zh": "目标跟随中注意变焦范围"
  },
  "fpv_tip_0x16430026": {
    "en": "",
    "zh": "目标在画面中占比过大"
  },
  "fpv_tip_0x16430101": {
    "en": "",
    "zh": "异常退出一键短片"
  },
  "fpv_tip_0x16430102": {
    "en": "",
    "zh": "异常终止一键短片拍摄"
  },
  "fpv_tip_0x16430103": {
    "en": "",
    "zh": "传感器数据验证错误"
  },
  "fpv_tip_0x16430104": {
    "en": "",
    "zh": "空指针"
  },
  "fpv_tip_0x16430105": {
    "en": "",
    "zh": "抽象数据指针为空"
  },
  "fpv_tip_0x16430106": {
    "en": "",
    "zh": "DBUS获取数据指针为空"
  },
  "fpv_tip_0x16430107": {
    "en": "",
    "zh": "在地面不能执行一键短片"
  },
  "fpv_tip_0x16430108": {
    "en": "",
    "zh": "用户打杆"
  },
  "fpv_tip_0x16430109": {
    "en": "",
    "zh": "用户暂停"
  },
  "fpv_tip_0x1643010A": {
    "en": "",
    "zh": "限高"
  },
  "fpv_tip_0x1643010B": {
    "en": "",
    "zh": "限远"
  },
  "fpv_tip_0x1643010C": {
    "en": "",
    "zh": "录像停止错误"
  },
  "fpv_tip_0x1643010D": {
    "en": "",
    "zh": "录像开始错误"
  },
  "fpv_tip_0x1643010E": {
    "en": "",
    "zh": "避障"
  },
  "fpv_tip_0x1643010F": {
    "en": "",
    "zh": "遥控器信号丢失"
  },
  "fpv_tip_0x16430110": {
    "en": "",
    "zh": "初始化失败"
  },
  "fpv_tip_0x16430111": {
    "en": "",
    "zh": "夺权失败"
  },
  "fpv_tip_0x16430112": {
    "en": "",
    "zh": "储存空间不足"
  },
  "fpv_tip_0x16430113": {
    "en": "",
    "zh": "目标丢失"
  },
  "fpv_tip_0x16430114": {
    "en": "",
    "zh": "拍照失败"
  },
  "fpv_tip_0x16430115": {
    "en": "",
    "zh": "合成失败"
  },
  "fpv_tip_0x16430116": {
    "en": "",
    "zh": "加载相机参数失败"
  },
  "fpv_tip_0x16430117": {
    "en": "",
    "zh": "调整相机参数失败"
  },
  "fpv_tip_0x16430118": {
    "en": "",
    "zh": "相机角度错误"
  },
  "fpv_tip_0x16430119": {
    "en": "",
    "zh": "相机未知错误"
  },
  "fpv_tip_0x1643011A": {
    "en": "",
    "zh": "相机参数错误"
  },
  "fpv_tip_0x1643011B": {
    "en": "",
    "zh": "相机状态错误"
  },
  "fpv_tip_0x1643011C": {
    "en": "",
    "zh": "设置TOPMODE超时"
  },
  "fpv_tip_0x1643011D": {
    "en": "",
    "zh": "相机TOPMODE错误"
  },
  "fpv_tip_0x1643011E": {
    "en": "",
    "zh": "异常提示一键短片"
  },
  "fpv_tip_0x16430201": {
    "en": "",
    "zh": "大师镜头异常退出"
  },
  "fpv_tip_0x16430202": {
    "en": "",
    "zh": "大师镜头异常暂停"
  },
  "fpv_tip_0x16430203": {
    "en": "",
    "zh": "大师镜头发现空指针"
  },
  "fpv_tip_0x16430204": {
    "en": "",
    "zh": "大师镜头抽象数据指针为空"
  },
  "fpv_tip_0x16430205": {
    "en": "",
    "zh": "大师镜头DBUS获取数据指针为空"
  },
  "fpv_tip_0x16430206": {
    "en": "",
    "zh": "大师镜头传感器数据验证错误"
  },
  "fpv_tip_0x16430207": {
    "en": "",
    "zh": "大师镜头用户打杆"
  },
  "fpv_tip_0x16430208": {
    "en": "",
    "zh": "大师镜头用户暂停"
  },
  "fpv_tip_0x16430209": {
    "en": "",
    "zh": "大师镜头遇限高"
  },
  "fpv_tip_0x1643020A": {
    "en": "",
    "zh": "大师镜头遇限远"
  },
  "fpv_tip_0x1643020B": {
    "en": "",
    "zh": "大师镜头录像停止失败"
  },
  "fpv_tip_0x1643020C": {
    "en": "",
    "zh": "大师镜头录像开始失败"
  },
  "fpv_tip_0x1643020D": {
    "en": "",
    "zh": "大师镜头遇障碍物"
  },
  "fpv_tip_0x1643020E": {
    "en": "",
    "zh": "大师镜头遥控器信号丢失"
  },
  "fpv_tip_0x1643020F": {
    "en": "",
    "zh": "大师镜头初始化失败"
  },
  "fpv_tip_0x16430210": {
    "en": "",
    "zh": "大师镜头夺权失败"
  },
  "fpv_tip_0x16430211": {
    "en": "",
    "zh": "大师镜头储存空间不足"
  },
  "fpv_tip_0x16430212": {
    "en": "",
    "zh": "大师镜头目标丢失"
  },
  "fpv_tip_0x16430213": {
    "en": "",
    "zh": "大师镜头相机参数错误"
  },
  "fpv_tip_0x16430214": {
    "en": "",
    "zh": "大师镜头设置TOPMODE超时"
  },
  "fpv_tip_0x16430215": {
    "en": "",
    "zh": "大师镜头设置TOPMODE异常"
  },
  "fpv_tip_0x16430216": {
    "en": "",
    "zh": "大师镜头警告"
  },
  "fpv_tip_0x16430217": {
    "en": "",
    "zh": "大师镜头调整相机参数失败"
  },
  "fpv_tip_0x16430218": {
    "en": "",
    "zh": "大师镜头云台控制错误"
  },
  "fpv_tip_0x16430219": {
    "en": "",
    "zh": "大师镜头Yaw控制错误"
  },
  "fpv_tip_0x1643021A": {
    "en": "",
    "zh": "大师镜头VA控制错误"
  },
  "fpv_tip_0x1643021B": {
    "en": "",
    "zh": "大师镜头速度控制错误"
  },
  "fpv_tip_0x1643021C": {
    "en": "",
    "zh": "大师镜头相机控制错误"
  },
  "fpv_tip_0x1643021D": {
    "en": "",
    "zh": "大师镜头任务不匹配"
  },
  "fpv_tip_0x16430301": {
    "en": "",
    "zh": "异常退出延时摄影"
  },
  "fpv_tip_0x16430302": {
    "en": "",
    "zh": "延时摄影异常退出当前任务"
  },
  "fpv_tip_0x16430303": {
    "en": "",
    "zh": "延时摄影异常进入暂停状态"
  },
  "fpv_tip_0x16430304": {
    "en": "",
    "zh": "延时摄影异常提示"
  },
  "fpv_tip_0x16430305": {
    "en": "",
    "zh": "地面电机已启动"
  },
  "fpv_tip_0x16430306": {
    "en": "",
    "zh": "相机模式异常"
  },
  "fpv_tip_0x16430307": {
    "en": "",
    "zh": "当前任务不匹配"
  },
  "fpv_tip_0x16430308": {
    "en": "",
    "zh": "抽象数据为空"
  },
  "fpv_tip_0x16430309": {
    "en": "",
    "zh": "相机模式超时"
  },
  "fpv_tip_0x1643030a": {
    "en": "",
    "zh": "相机模式不匹配"
  },
  "fpv_tip_0x1643030b": {
    "en": "",
    "zh": "定向延时中框丢失"
  },
  "fpv_tip_0x1643030c": {
    "en": "",
    "zh": "返回首航点航线平台异常"
  },
  "fpv_tip_0x1643030d": {
    "en": "",
    "zh": "环绕测距超时"
  },
  "fpv_tip_0x1643030e": {
    "en": "",
    "zh": "限飞"
  },
  "fpv_tip_0x1643030f": {
    "en": "",
    "zh": "地面延时电量低"
  },
  "fpv_tip_0x16430310": {
    "en": "",
    "zh": "GPS信号差"
  },
  "fpv_tip_0x16430311": {
    "en": "",
    "zh": "避障"
  },
  "fpv_tip_0x16430312": {
    "en": "",
    "zh": "环绕测距触发限飞"
  },
  "fpv_tip_0x16430313": {
    "en": "",
    "zh": "轨迹延时触发限高"
  },
  "fpv_tip_0x16430314": {
    "en": "",
    "zh": "返回首航点触发限飞"
  },
  "fpv_tip_0x16430315": {
    "en": "",
    "zh": "避障失效"
  },
  "fpv_tip_0x16430316": {
    "en": "",
    "zh": "返回首航点触发避障"
  },
  "fpv_tip_0x16430317": {
    "en": "",
    "zh": "运行过程中触发避障"
  },
  "fpv_tip_0x16430318": {
    "en": "",
    "zh": "拍摄过程中触发限飞"
  },
  "fpv_tip_0x16430319": {
    "en": "",
    "zh": "航线平台异常终止"
  },
  "fpv_tip_0x1643031a": {
    "en": "",
    "zh": "相机存储慢"
  },
  "fpv_tip_0x1643031b": {
    "en": "",
    "zh": "GPS信号弱轨迹点记录失败"
  },
  "fpv_tip_0x1643031c": {
    "en": "",
    "zh": "已达到最大照片限制"
  },
  "fpv_tip_0x1643031d": {
    "en": "",
    "zh": "存储空间不足"
  },
  "fpv_tip_0x1643031e": {
    "en": "",
    "zh": "定向延时航线未锁定"
  },
  "fpv_tip_0x1643031f": {
    "en": "",
    "zh": "云台姿态变化太小"
  },
  "fpv_tip_0x16430320": {
    "en": "",
    "zh": "云台姿态变化超限"
  },
  "fpv_tip_0x16430321": {
    "en": "",
    "zh": "轨迹载入失败"
  },
  "fpv_tip_0x16430322": {
    "en": "",
    "zh": "增加1s失败"
  },
  "fpv_tip_0x16430323": {
    "en": "",
    "zh": "完成照片太少无法合成视频"
  },
  "fpv_tip_0x16430324": {
    "en": "",
    "zh": "返回首航点轨迹生成失败"
  },
  "fpv_tip_0x16432400": {
    "en": "",
    "zh": "异常退出 ApasMission"
  },
  "fpv_tip_0x16432401": {
    "en": "",
    "zh": "融合数据订阅异常"
  },
  "fpv_tip_0x16432402": {
    "en": "",
    "zh": "Json文件读取失败"
  },
  "fpv_tip_0x16432403": {
    "en": "",
    "zh": "FlyCore数据订阅异常"
  },
  "fpv_tip_0x16432404": {
    "en": "",
    "zh": "融合数据超时"
  },
  "fpv_tip_0x16432405": {
    "en": "",
    "zh": "融合定位数据无效"
  },
  "fpv_tip_0x16432410": {
    "en": "",
    "zh": "ApasMission 异常退出到关闭"
  },
  "fpv_tip_0x16432411": {
    "en": "",
    "zh": "ImageStatus 订阅异常"
  },
  "fpv_tip_0x16432412": {
    "en": "",
    "zh": "夜景模式"
  },
  "fpv_tip_0x16432413": {
    "en": "",
    "zh": "环境光过暗且飞行环境安全"
  },
  "fpv_tip_0x16432414": {
    "en": "",
    "zh": "环境光过暗且悬停无水平杆"
  },
  "fpv_tip_0x16432415": {
    "en": "",
    "zh": "APAS Planner 功能异常且飞行环境安全"
  },
  "fpv_tip_0x16432416": {
    "en": "",
    "zh": "APAS Planner 功能异常且悬停无水平杆"
  },
  "fpv_tip_0x16432417": {
    "en": "",
    "zh": "OA Planner 功能异常且飞行环境安全"
  },
  "fpv_tip_0x16432418": {
    "en": "",
    "zh": "OA Planner 功能异常且悬停无水平杆"
  },
  "fpv_tip_0x16432419": {
    "en": "",
    "zh": "在云雾中"
  },
  "fpv_tip_0x16432420": {
    "en": "",
    "zh": "ApasMission 绕行异常退出到刹停"
  },
  "fpv_tip_0x16432421": {
    "en": "",
    "zh": "限飞区边缘"
  },
  "fpv_tip_0x16432422": {
    "en": "",
    "zh": "距离地面过近小于0.5m"
  },
  "fpv_tip_0x16432423": {
    "en": "",
    "zh": "部分环境光过暗"
  },
  "fpv_tip_0x16432424": {
    "en": "",
    "zh": "APAS Planner功能异常"
  },
  "fpv_tip_0x16432425": {
    "en": "",
    "zh": "高帧率录像"
  },
  "fpv_tip_0x16432430": {
    "en": "",
    "zh": "ApasMission 异常触发刹车"
  },
  "fpv_tip_0x16432431": {
    "en": "",
    "zh": "环境光过暗普通刹车"
  },
  "fpv_tip_0x16432432": {
    "en": "",
    "zh": "环境光过暗紧急刹车"
  },
  "fpv_tip_0x16432433": {
    "en": "",
    "zh": "APAS Planner 功能异常普通刹车"
  },
  "fpv_tip_0x16432434": {
    "en": "",
    "zh": "APAS Planner 功能异常紧急刹车"
  },
  "fpv_tip_0x16432435": {
    "en": "",
    "zh": "OA Planner 功能异常普通刹车"
  },
  "fpv_tip_0x16432436": {
    "en": "",
    "zh": "OA Planner 功能异常紧急刹车"
  },
  "fpv_tip_0x16432500": {
    "en": "",
    "zh": "OA模块无法正常工作"
  },
  "fpv_tip_0x16432501": {
    "en": "",
    "zh": "OA模块因地图异常无法正常工作"
  },
  "fpv_tip_0x16432502": {
    "en": "",
    "zh": "OA模块因地图异常无法正常工作"
  },
  "fpv_tip_0x16432503": {
    "en": "",
    "zh": "相机处于夜景模式OA无法正常工作"
  },
  "fpv_tip_0x16432504": {
    "en": "",
    "zh": "OA模块因处于云雾中无法正常工作"
  },
  "fpv_tip_0x16432505": {
    "en": "",
    "zh": "OA模块因环境光过暗无法正常工作"
  },
  "fpv_tip_0x16432506": {
    "en": "",
    "zh": "OA模块tof避障无法正常工作"
  },
  "fpv_tip_0x16432550": {
    "en": "",
    "zh": "OA模块触发异常保护"
  },
  "fpv_tip_0x16432551": {
    "en": "",
    "zh": "OA模块触发紧急刹车"
  },
  "fpv_tip_0x16450001": {
    "en": "",
    "zh": "飞机未能返回机场"
  },
  "fpv_tip_0x16450002": {
    "en": "",
    "zh": "飞机机场外触发不可取消降落"
  },
  "fpv_tip_0x16450011": {
    "en": "",
    "zh": "飞机悬停超时"
  },
  "fpv_tip_0x16450012": {
    "en": "",
    "zh": "飞机无法接收到机场状态"
  },
  "fpv_tip_0x16450013": {
    "en": "",
    "zh": "机场暂时不允许降落"
  },
  "fpv_tip_0x16450014": {
    "en": "",
    "zh": "Marker水平位置源无效"
  },
  "fpv_tip_0x16450015": {
    "en": "",
    "zh": "Marker水平位置源偏差大"
  },
  "fpv_tip_0x16450016": {
    "en": "",
    "zh": "Marker水平位置源订阅失败"
  },
  "fpv_tip_0x16450017": {
    "en": "",
    "zh": "RTK水平位置源无效"
  },
  "fpv_tip_0x16450018": {
    "en": "",
    "zh": "RTK水平位置源电离层校验不通过"
  },
  "fpv_tip_0x16450019": {
    "en": "",
    "zh": "飞机水平控制能力弱"
  },
  "fpv_tip_0x16450021": {
    "en": "",
    "zh": "机场降落功能运行异常"
  },
  "fpv_tip_0x16450022": {
    "en": "",
    "zh": "飞机因为切姿态变为普通降落"
  },
  "fpv_tip_0x16450023": {
    "en": "",
    "zh": "机场没有设置备降点无法触发备降"
  },
  "fpv_tip_0x16450024": {
    "en": "",
    "zh": "飞机没有触发提前停桨"
  },
  "fpv_tip_0x16450025": {
    "en": "",
    "zh": "抽象数据更新失败"
  },
  "fpv_tip_0x16450031": {
    "en": "",
    "zh": "飞机触发备降"
  },
  "fpv_tip_0x16450032": {
    "en": "",
    "zh": "飞机MCU接管控制"
  },
  "fpv_tip_0x16450033": {
    "en": "",
    "zh": "机场状态异常永久不允许降落"
  },
  "fpv_tip_0x16450034": {
    "en": "",
    "zh": "飞机悬停到低电量触发备降"
  },
  "fpv_tip_0x16450035": {
    "en": "",
    "zh": "飞机未能到达备降点中途降落"
  },
  "fpv_tip_0x16450101": {
    "en": "",
    "zh": "航线异常暂停"
  },
  "fpv_tip_0x16450102": {
    "en": "",
    "zh": "航线异常中止"
  },
  "fpv_tip_0x16450103": {
    "en": "",
    "zh": "航线功能异常"
  },
  "fpv_tip_0x16450104": {
    "en": "",
    "zh": "航线限高"
  },
  "fpv_tip_0x16450105": {
    "en": "",
    "zh": "航线限低"
  },
  "fpv_tip_0x16450106": {
    "en": "",
    "zh": "航线限远"
  },
  "fpv_tip_0x16450107": {
    "en": "",
    "zh": "航线触碰到禁飞区"
  },
  "fpv_tip_0x16450108": {
    "en": "",
    "zh": "航线执行中检测到障碍物"
  },
  "fpv_tip_0x16450109": {
    "en": "",
    "zh": "航线执行中gps信号弱"
  },
  "fpv_tip_0x1645010A": {
    "en": "",
    "zh": "低电量"
  },
  "fpv_tip_0x1645010B": {
    "en": "",
    "zh": "航线生成超时"
  },
  "fpv_tip_0x1645010C": {
    "en": "",
    "zh": "拿不到抽象数据"
  },
  "fpv_tip_0x1645010D": {
    "en": "",
    "zh": "参数表读取失败"
  },
  "fpv_tip_0x1645010E": {
    "en": "",
    "zh": "轨迹库计算失败"
  },
  "fpv_tip_0x1645010F": {
    "en": "",
    "zh": "请求起飞失败"
  },
  "fpv_tip_0x16450110": {
    "en": "",
    "zh": "请求航线任务失败"
  },
  "fpv_tip_0x16450111": {
    "en": "",
    "zh": "请求返航失败"
  },
  "fpv_tip_0x16450112": {
    "en": "",
    "zh": "请求降落失败"
  },
  "fpv_tip_0x16450113": {
    "en": "",
    "zh": "飞行器失联"
  },
  "fpv_tip_0x16450114": {
    "en": "",
    "zh": "实时仿地过程中，相机状态有问题（过亮，过暗，两侧亮度不一致）"
  },
  "fpv_tip_0x16450115": {
    "en": "",
    "zh": "实时仿地过程中全局地图计算出错"
  },
  "fpv_tip_0x16450116": {
    "en": "",
    "zh": "航线动作组解析失败"
  },
  "fpv_tip_0x16450117": {
    "en": "",
    "zh": "飞行任务冲突，无法获取飞机控制权"
  },
  "fpv_tip_0x16450118": {
    "en": "",
    "zh": "要执行的文件解析错误"
  },
  "fpv_tip_0x16450119": {
    "en": "",
    "zh": "等待wpmz文件读取时间过长"
  },
  "fpv_tip_0x1645011A": {
    "en": "",
    "zh": "航线已经开始，不能再次开始"
  },
  "fpv_tip_0x1645011B": {
    "en": "",
    "zh": "相机抽象设备异常"
  },
  "fpv_tip_0x1645011C": {
    "en": "",
    "zh": "云台抽象设备异常"
  },
  "fpv_tip_0x1645011D": {
    "en": "",
    "zh": "app链路异常"
  },
  "fpv_tip_0x1645011E": {
    "en": "",
    "zh": "工厂文件未删除"
  },
  "fpv_tip_0x1645011F": {
    "en": "",
    "zh": "RTK状态跳变，航线已退出"
  },
  "fpv_tip_0x16450120": {
    "en": "",
    "zh": "请求快速起飞失败"
  },
  "fpv_tip_0x16450121": {
    "en": "",
    "zh": "自动起飞运行失败"
  },
  "fpv_tip_0x16450122": {
    "en": "",
    "zh": "航线触碰到自定义飞行区"
  },
  "fpv_tip_0x16450123": {
    "en": "",
    "zh": "航线全景拍照动作出错"
  },
  "fpv_tip_0x16450124": {
    "en": "",
    "zh": "执行全景拍照过程超时"
  },
  "fpv_tip_0x16450125": {
    "en": "",
    "zh": "全景拍照设置相机topmode失败"
  },
  "fpv_tip_0x16450126": {
    "en": "",
    "zh": "全景拍照设置相机submode失败"
  },
  "fpv_tip_0x16450127": {
    "en": "",
    "zh": "全景拍照前飞机转yaw超时"
  },
  "fpv_tip_0x16450128": {
    "en": "",
    "zh": "全景拍照后飞机恢复yaw超时"
  },
  "fpv_tip_0x16450129": {
    "en": "",
    "zh": "全景拍照空指针错误"
  },
  "fpv_tip_0x16461000": {
    "en": "",
    "zh": "融合定位系统异常切换"
  },
  "fpv_tip_0x16461001": {
    "en": "",
    "zh": "ap_fusion 断联"
  },
  "fpv_tip_0x16461002": {
    "en": "",
    "zh": "ap_fusion 存在严重故障"
  },
  "fpv_tip_0x16461003": {
    "en": "",
    "zh": "默认融合定位系统被修改"
  },
  "fpv_tip_0x16462001": {
    "en": "",
    "zh": "霍尔设备断开"
  },
  "fpv_tip_0x16462004": {
    "en": "",
    "zh": "霍尔校准数据异常"
  },
  "fpv_tip_0x16462007": {
    "en": "",
    "zh": "霍尔传感器缺少磁环，工作异常"
  },
  "fpv_tip_0x1646200A": {
    "en": "",
    "zh": "霍尔传感器间互检失败"
  },
  "fpv_tip_0x16463001": {
    "en": "",
    "zh": "RTK No NLFix"
  },
  "fpv_tip_0x16463004": {
    "en": "",
    "zh": "主天线观测质量差"
  },
  "fpv_tip_0x16463005": {
    "en": "",
    "zh": "信号频繁失锁"
  },
  "fpv_tip_0x16463006": {
    "en": "",
    "zh": "电离层活跃"
  },
  "fpv_tip_0x16463007": {
    "en": "",
    "zh": "主天线SNR陡降"
  },
  "fpv_tip_0x16463008": {
    "en": "",
    "zh": "副天线SNR陡降"
  },
  "fpv_tip_0x16463009": {
    "en": "",
    "zh": "主天线开路或短路"
  },
  "fpv_tip_0x1646300A": {
    "en": "",
    "zh": "副天线开路或短路"
  },
  "fpv_tip_0x1646300B": {
    "en": "",
    "zh": "RTK Heading No NLFix"
  },
  "fpv_tip_0x16463011": {
    "en": "",
    "zh": "RTK基站RTCM数据异常"
  },
  "fpv_tip_0x16463012": {
    "en": "",
    "zh": "2607未收到RTCM"
  },
  "fpv_tip_0x16463013": {
    "en": "",
    "zh": "2607 RTCM V1组包异常"
  },
  "fpv_tip_0x16463014": {
    "en": "",
    "zh": "2607RTCM传输误码"
  },
  "fpv_tip_0x16463021": {
    "en": "",
    "zh": "2906未收到RTCM"
  },
  "fpv_tip_0x16463022": {
    "en": "",
    "zh": "2906RTK数据源不一致"
  },
  "fpv_tip_0x16463023": {
    "en": "",
    "zh": "2906RTK数据源无效"
  },
  "fpv_tip_0x16463024": {
    "en": "",
    "zh": "2906RTK数据源初始化失败"
  },
  "fpv_tip_0x16463031": {
    "en": "",
    "zh": "2606未收到RTCM"
  },
  "fpv_tip_0x16463032": {
    "en": "",
    "zh": "2606RTK数据源不一致"
  },
  "fpv_tip_0x16463033": {
    "en": "",
    "zh": "2606RTK数据源无效"
  },
  "fpv_tip_0x16463034": {
    "en": "",
    "zh": "2606未收到RTK信息源的信息"
  },
  "fpv_tip_0x16471000": {
    "en": "",
    "zh": "定位无输出，可能是FC传感器未就绪"
  },
  "fpv_tip_0x16471001": {
    "en": "",
    "zh": "定位异常，系统异常或loading异常"
  },
  "fpv_tip_0x16471002": {
    "en": "",
    "zh": "定位输出速度位置一致性差"
  },
  "fpv_tip_0x16471003": {
    "en": "",
    "zh": "飞控传感器未就绪"
  },
  "fpv_tip_0x16471004": {
    "en": "",
    "zh": "状态实时性delay太大"
  },
  "fpv_tip_0x16471005": {
    "en": "",
    "zh": "loop耗时异常"
  },
  "fpv_tip_0x16471006": {
    "en": "",
    "zh": "状态时间戳异常"
  },
  "fpv_tip_0x16471007": {
    "en": "",
    "zh": "IMU BIAS随机游走异常"
  },
  "fpv_tip_0x16471010": {
    "en": "",
    "zh": "视觉定位多方向同步异常"
  },
  "fpv_tip_0x16471012": {
    "en": "",
    "zh": "硬件加速器调用异常"
  },
  "fpv_tip_0x16471100": {
    "en": "",
    "zh": "IMU 时间戳异常"
  },
  "fpv_tip_0x16471101": {
    "en": "",
    "zh": "视觉时间戳异常"
  },
  "fpv_tip_0x16471102": {
    "en": "",
    "zh": "UBLOX GPS时间戳异常"
  },
  "fpv_tip_0x16471103": {
    "en": "",
    "zh": "DJI GPS时间戳异常"
  },
  "fpv_tip_0x16471201": {
    "en": "",
    "zh": "IMU静止检测更新异常"
  },
  "fpv_tip_0x16471204": {
    "en": "",
    "zh": "气压计更新异常"
  },
  "fpv_tip_0x16471205": {
    "en": "",
    "zh": "TOF更新异常"
  },
  "fpv_tip_0x16471207": {
    "en": "",
    "zh": "视觉更新异常"
  },
  "fpv_tip_0x16471209": {
    "en": "",
    "zh": "GPS更新异常"
  },
  "fpv_tip_0x16476000": {
    "en": "",
    "zh": "VIO 前端异常"
  },
  "fpv_tip_0x16476001": {
    "en": "",
    "zh": "初始化异常"
  },
  "fpv_tip_0x16476002": {
    "en": "",
    "zh": "前端启动错误"
  },
  "fpv_tip_0x16476003": {
    "en": "Vision sensor error. Restart aircraft and try again",
    "zh": "视觉定位前端获取里程计异常,尝试重启后再工作"
  },
  "fpv_tip_0x16476004": {
    "en": "",
    "zh": "TF树异常"
  },
  "fpv_tip_0x16476005": {
    "en": "",
    "zh": "circular_tracking错误"
  },
  "fpv_tip_0x16476006": {
    "en": "",
    "zh": "DSP translation KLT异常"
  },
  "fpv_tip_0x16476007": {
    "en": "",
    "zh": "DSP eis KLT异常"
  },
  "fpv_tip_0x16476008": {
    "en": "",
    "zh": "输入harris点数大于最大设置点数"
  },
  "fpv_tip_0x16476009": {
    "en": "",
    "zh": "相机参数未更新"
  },
  "fpv_tip_0x16476100": {
    "en": "",
    "zh": "VIO 动态切图异常"
  },
  "fpv_tip_0x16476101": {
    "en": "",
    "zh": "动态切图初始化异常"
  },
  "fpv_tip_0x16476102": {
    "en": "",
    "zh": "动态切图参数异常"
  },
  "fpv_tip_0x16476103": {
    "en": "",
    "zh": "acc_rectify_channel_update异常"
  },
  "fpv_tip_0x16476104": {
    "en": "",
    "zh": "insert_rectify_one_channel异常"
  },
  "fpv_tip_0x16476105": {
    "en": "",
    "zh": "ss_dbus_shm_acquire异常"
  },
  "fpv_tip_0x16476106": {
    "en": "",
    "zh": "ss_dbus_shm_publish异常"
  },
  "fpv_tip_0x16476107": {
    "en": "",
    "zh": "获取相机参数超时"
  },
  "fpv_tip_0x16479000": {
    "en": "",
    "zh": "V1-RTK功能异常"
  },
  "fpv_tip_0x16479001": {
    "en": "",
    "zh": "RTK初始化异常"
  },
  "fpv_tip_0x16479002": {
    "en": "",
    "zh": "RTK DSP运行异常"
  },
  "fpv_tip_0x16479003": {
    "en": "",
    "zh": "RTK进程调度异常"
  },
  "fpv_tip_0x16479004": {
    "en": "",
    "zh": "RTK输出频率异常"
  },
  "fpv_tip_0x16479005": {
    "en": "",
    "zh": "RTK MIPI数据异常"
  },
  "fpv_tip_0x16479006": {
    "en": "",
    "zh": "RTK 设备驱动异常"
  },
  "fpv_tip_0x16479011": {
    "en": "",
    "zh": "RTK 设备配置异常"
  },
  "fpv_tip_0x16479012": {
    "en": "",
    "zh": "RTK初始化资源申请异常"
  },
  "fpv_tip_0x16479013": {
    "en": "",
    "zh": "MIPI初始化异常"
  },
  "fpv_tip_0x16479014": {
    "en": "",
    "zh": "RTK初始化参数配置异常"
  },
  "fpv_tip_0x16479015": {
    "en": "",
    "zh": "RTK固件版本不匹配"
  },
  "fpv_tip_0x16479021": {
    "en": "",
    "zh": "RTK算子异常挂死"
  },
  "fpv_tip_0x16479022": {
    "en": "",
    "zh": "RTK算子运行超时"
  },
  "fpv_tip_0x16479031": {
    "en": "",
    "zh": "RTK系统中断异常"
  },
  "fpv_tip_0x16479032": {
    "en": "",
    "zh": "RTK系统实时性异常"
  },
  "fpv_tip_0x16479041": {
    "en": "",
    "zh": "RTK时序错误"
  },
  "fpv_tip_0x16479051": {
    "en": "",
    "zh": "RTK MIPI数据同步错误"
  },
  "fpv_tip_0x16479052": {
    "en": "",
    "zh": "RTK MIPI数据丢帧"
  },
  "fpv_tip_0x1647C010": {
    "en": "",
    "zh": "指南针需要校准"
  },
  "fpv_tip_0x1647C011": {
    "en": "",
    "zh": "指南针模长过大，需要校准"
  },
  "fpv_tip_0x1647C012": {
    "en": "",
    "zh": "指南针Yaw误差大，需要校准"
  },
  "fpv_tip_0x1647C013": {
    "en": "",
    "zh": "指南针yaw不一致，需要校准"
  },
  "fpv_tip_0x1647C014": {
    "en": "",
    "zh": "指南针模长不一致，需要校准"
  },
  "fpv_tip_0x1647C015": {
    "en": "",
    "zh": "指南针航向不一致，需要校准"
  },
  "fpv_tip_0x1647C016": {
    "en": "",
    "zh": "指南针长距离未校准，需要校准"
  },
  "fpv_tip_0x1647C017": {
    "en": "",
    "zh": "指南针长时间未校准，需要校准"
  },
  "fpv_tip_0x1647C018": {
    "en": "",
    "zh": "指南针长时间异常，需要校准"
  },
  "fpv_tip_0x1647C019": {
    "en": "",
    "zh": "指南针航向与融合航向不一致，需要校准"
  },
  "fpv_tip_0x1647C020": {
    "en": "",
    "zh": "需要移动飞机"
  },
  "fpv_tip_0x1647C021": {
    "en": "",
    "zh": "指南针校准完后模长过大，需要移动飞机"
  },
  "fpv_tip_0x1647C022": {
    "en": "",
    "zh": "指南针校准完后Yaw误差大，需要移动飞机"
  },
  "fpv_tip_0x1647C023": {
    "en": "",
    "zh": "指南针校准完后yaw不一致，需要移动飞机"
  },
  "fpv_tip_0x1647C024": {
    "en": "",
    "zh": "指南针校准完后模长不一致，需要移动飞机"
  },
  "fpv_tip_0x1647C025": {
    "en": "",
    "zh": "指南针校准完后航向不一致，需要移动飞机"
  },
  "fpv_tip_0x1647C030": {
    "en": "",
    "zh": "需要移动飞机或等待RTK-FIX"
  },
  "fpv_tip_0x1647C031": {
    "en": "",
    "zh": "指南针校准完后模长过大，需要移动飞机或等待RTK-FIX"
  },
  "fpv_tip_0x1647C032": {
    "en": "",
    "zh": "指南针校准完后Yaw误差大，需要移动飞机或等待RTK-FIX"
  },
  "fpv_tip_0x1647C033": {
    "en": "",
    "zh": "指南针校准完后yaw不一致，需要移动飞机或等待RTK-FIX"
  },
  "fpv_tip_0x1647C034": {
    "en": "",
    "zh": "指南针校准完后模长不一致，需要移动飞机或等待RTK-FIX"
  },
  "fpv_tip_0x1647C035": {
    "en": "",
    "zh": "指南针校准完后航向不一致，需要移动飞机或等待RTK-FIX"
  },
  "fpv_tip_0x1647C040": {
    "en": "",
    "zh": "指南针需要校准或等待RTK-FIX"
  },
  "fpv_tip_0x1647C041": {
    "en": "",
    "zh": "指南针模长过大，需要校准或等待RTK-FIX"
  },
  "fpv_tip_0x1647C042": {
    "en": "",
    "zh": "指南针Yaw误差大，需要校准或等待RTK-FIX"
  },
  "fpv_tip_0x1647C043": {
    "en": "",
    "zh": "指南针yaw不一致，需要校准或等待RTK-FIX"
  },
  "fpv_tip_0x1647C044": {
    "en": "",
    "zh": "指南针模长不一致，需要校准或等待RTK-FIX"
  },
  "fpv_tip_0x1647C045": {
    "en": "",
    "zh": "指南针航向不一致，需要校准或等待RTK-FIX"
  },
  "fpv_tip_0x1647C046": {
    "en": "",
    "zh": "指南针长距离未校准，需要校准或等待RTK-FIX"
  },
  "fpv_tip_0x1647C047": {
    "en": "",
    "zh": "指南针长时间未校准，需要校准或等待RTK-FIX"
  },
  "fpv_tip_0x1647C048": {
    "en": "",
    "zh": "指南针长时间异常，需要校准或等待RTK-FIX"
  },
  "fpv_tip_0x16490001": {
    "en": "",
    "zh": "tracking planner异常退出"
  },
  "fpv_tip_0x16490002": {
    "en": "",
    "zh": "tracking planner参数无效"
  },
  "fpv_tip_0x16490003": {
    "ar": "",
    "de": "",
    "en": "Vertical altitude error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "垂直高度反馈异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x16490004": {
    "en": "",
    "zh": "tracking planner跟随失败"
  },
  "fpv_tip_0x16490005": {
    "en": "",
    "zh": "tracking planner地图超时"
  },
  "fpv_tip_0x16490006": {
    "en": "",
    "zh": "tracking planner地图空指针"
  },
  "fpv_tip_0x16490007": {
    "en": "",
    "zh": "tracking planner避障异常退出"
  },
  "fpv_tip_0x16490008": {
    "en": "",
    "zh": "tracking planner目标安全性异常"
  },
  "fpv_tip_0x16490009": {
    "en": "",
    "zh": "tracking planner轨迹跟随异常"
  },
  "fpv_tip_0x1649000A": {
    "en": "",
    "zh": "tracking planner目标估计错误"
  },
  "fpv_tip_0x1649000B": {
    "en": "",
    "zh": "tracking planner失效"
  },
  "fpv_tip_0x1649000C": {
    "en": "",
    "zh": "tracking planner紧急刹车"
  },
  "fpv_tip_0x1649000D": {
    "en": "",
    "zh": "tracking planner平滑性异常"
  },
  "fpv_tip_0x1649000E": {
    "en": "",
    "zh": "tracking planner轨迹检测异常"
  },
  "fpv_tip_0x1649000F": {
    "en": "",
    "zh": "tracking planner刹车规划异常"
  },
  "fpv_tip_0x16490010": {
    "en": "",
    "zh": "tracking planner路径规划异常"
  },
  "fpv_tip_0x16490011": {
    "en": "",
    "zh": "tracking planner轨迹规划异常"
  },
  "fpv_tip_0x16493000": {
    "en": "",
    "zh": "全局地图：拿不到MCU的高频推送 fly_core"
  },
  "fpv_tip_0x16493100": {
    "en": "",
    "zh": "全局地图：拿不到MCU的低频推送 fly_extend"
  },
  "fpv_tip_0x16493200": {
    "en": "",
    "zh": "全局地图：拿不到有效的定位数据 fusion_output"
  },
  "fpv_tip_0x16493300": {
    "en": "",
    "zh": "全局地图：拿不到有效的标定数据"
  },
  "fpv_tip_0x16493400": {
    "en": "",
    "zh": "全局地图：拿不到有效的天空语义"
  },
  "fpv_tip_0x16493500": {
    "en": "",
    "zh": "全局地图：拿不到有效的局部地图"
  },
  "fpv_tip_0x16493600": {
    "en": "",
    "zh": "全局地图：局部地图处理失败/超时"
  },
  "fpv_tip_0x16494000": {
    "en": "",
    "zh": "APAS Planner 功能异常"
  },
  "fpv_tip_0x16494001": {
    "en": "",
    "zh": "局部地图订阅异常"
  },
  "fpv_tip_0x16494002": {
    "en": "",
    "zh": "环境限速订阅异常"
  },
  "fpv_tip_0x16494003": {
    "en": "",
    "zh": "APAS Planner 阻塞"
  },
  "fpv_tip_0x16494004": {
    "en": "",
    "zh": "APAS Follower 阻塞"
  },
  "fpv_tip_0x16494100": {
    "en": "",
    "zh": "APAS Planner 性能异常"
  },
  "fpv_tip_0x16494101": {
    "en": "",
    "zh": "APAS Planner 局部地图共享内存超时"
  },
  "fpv_tip_0x16494102": {
    "en": "",
    "zh": "APAS Planner 初始轨迹求解失败"
  },
  "fpv_tip_0x16494103": {
    "en": "",
    "zh": "APAS Planner 绕行轨迹求解失败"
  },
  "fpv_tip_0x16494104": {
    "en": "",
    "zh": "APAS Planner 触发紧急刹车"
  },
  "fpv_tip_0x16494105": {
    "en": "",
    "zh": "APAS Follower 跟踪误差过大"
  },
  "fpv_tip_0x16494106": {
    "en": "",
    "zh": "APAS Follower 控制序列碰撞检查失败"
  },
  "fpv_tip_0x16497100": {
    "en": "",
    "zh": "仿地绕行异常"
  },
  "fpv_tip_0x16497110": {
    "en": "",
    "zh": "仿地绕行任务设置异常"
  },
  "fpv_tip_0x16497111": {
    "en": "",
    "zh": "仿地绕行任务高度异常"
  },
  "fpv_tip_0x16497112": {
    "en": "",
    "zh": "仿地绕行任务速度异常"
  },
  "fpv_tip_0x16497113": {
    "en": "",
    "zh": "仿地绕行任务航线设置异常"
  },
  "fpv_tip_0x16497120": {
    "en": "",
    "zh": "仿地绕行感知输入异常"
  },
  "fpv_tip_0x16497121": {
    "en": "",
    "zh": "仿地绕行建图异常"
  },
  "fpv_tip_0x16497122": {
    "en": "",
    "zh": "仿地绕行地图超时"
  },
  "fpv_tip_0x16497130": {
    "en": "",
    "zh": "仿地绕行规划失败"
  },
  "fpv_tip_0x16497131": {
    "en": "",
    "zh": "仿地绕行搜索路径无效"
  },
  "fpv_tip_0x16497132": {
    "en": "",
    "zh": "仿地绕行搜索失败"
  },
  "fpv_tip_0x16497133": {
    "en": "",
    "zh": "仿地绕行轨迹优化失败"
  },
  "fpv_tip_0x164A1005": {
    "en": "In No Position Hold mode, aircraft is unable to hover stably. It is recommended to switch to Normal mode before flying",
    "zh": "当前为无位置保持模式，飞行器无法稳定悬停，建议切换为普通模式后起飞"
  },
  "fpv_tip_0x164A1005_in_the_sky": {
    "en": "In No Position Hold mode, aircraft is unable to hover stably, and flight safety is affected. It is recommended to switch to Normal mode",
    "zh": "无位置保持模式下，无法稳定悬停，可能影响飞行安全，建议切换为普通模式"
  },
  "fpv_tip_0x164C0000": {
    "en": "",
    "zh": "局部地图模块异常"
  },
  "fpv_tip_0x164C0040": {
    "en": "",
    "zh": "局部地图模块接受数据异常"
  },
  "fpv_tip_0x164C0080": {
    "en": "",
    "zh": "局部地图模块数据处理异常"
  },
  "fpv_tip_0x164C00C0": {
    "en": "",
    "zh": "局部地图模块规划指令订阅异常"
  },
  "fpv_tip_0x164C0100": {
    "en": "",
    "zh": "局部地图模块订阅下视dpp异常"
  },
  "fpv_tip_0x164C0101": {
    "en": "",
    "zh": "局部地图模块订阅前视dpp异常"
  },
  "fpv_tip_0x164C0102": {
    "en": "",
    "zh": "局部地图模块订阅后视dpp异常"
  },
  "fpv_tip_0x164C0103": {
    "en": "",
    "zh": "局部地图模块订阅右视dpp异常"
  },
  "fpv_tip_0x164C0104": {
    "en": "",
    "zh": "局部地图模块订阅左视dpp异常"
  },
  "fpv_tip_0x164C0105": {
    "en": "",
    "zh": "局部地图模块订阅上视dpp异常"
  },
  "fpv_tip_0x164C013E": {
    "en": "",
    "zh": "局部地图模块订阅dpp异常"
  },
  "fpv_tip_0x164C0140": {
    "en": "",
    "zh": "局部地图模块订阅标定参数异常"
  },
  "fpv_tip_0x164C0180": {
    "en": "",
    "zh": "局部地图模块订阅相机状态异常"
  },
  "fpv_tip_0x164C01C0": {
    "en": "",
    "zh": "局部地图模块数据处理超时"
  },
  "fpv_tip_0x164C0200": {
    "en": "",
    "zh": "局部地图模块数据处理过程错误"
  },
  "fpv_tip_0x164C0240": {
    "en": "",
    "zh": "dpp模块下视视差图时序异常"
  },
  "fpv_tip_0x164C0241": {
    "en": "",
    "zh": "dpp模块前视视差图时序异常"
  },
  "fpv_tip_0x164C0242": {
    "en": "",
    "zh": "dpp模块后视视差图时序异常"
  },
  "fpv_tip_0x164C0243": {
    "en": "",
    "zh": "dpp模块右视视差图时序异常"
  },
  "fpv_tip_0x164C0244": {
    "en": "",
    "zh": "dpp模块左视视差图时序异常"
  },
  "fpv_tip_0x164C0245": {
    "en": "",
    "zh": "dpp模块上视视差图时序异常"
  },
  "fpv_tip_0x164C027E": {
    "en": "",
    "zh": "dpp模块视差图时序异常"
  },
  "fpv_tip_0x164C0280": {
    "en": "",
    "zh": "dpp模块下视视差图丢失"
  },
  "fpv_tip_0x164C0281": {
    "en": "",
    "zh": "dpp模块前视视差图丢失"
  },
  "fpv_tip_0x164C0282": {
    "en": "",
    "zh": "dpp模块后视视差图丢失"
  },
  "fpv_tip_0x164C0283": {
    "en": "",
    "zh": "dpp模块右视视差图丢失"
  },
  "fpv_tip_0x164C0284": {
    "en": "",
    "zh": "dpp模块左视视差图丢失"
  },
  "fpv_tip_0x164C0285": {
    "en": "",
    "zh": "dpp模块上视视差图丢失"
  },
  "fpv_tip_0x164C02BE": {
    "en": "",
    "zh": "dpp模块某方向固定丢失视差图"
  },
  "fpv_tip_0x164C02C0": {
    "en": "",
    "zh": "局部地图模块地图更新延迟大"
  },
  "fpv_tip_0x164C0300": {
    "en": "",
    "zh": "局部地图模块esdf计算延迟大"
  },
  "fpv_tip_0x164C0440": {
    "en": "",
    "zh": "局部地图模块订阅位姿数据异常"
  },
  "fpv_tip_0x164C04BE": {
    "en": "",
    "zh": "dpp模块订阅语义depth数据异常"
  },
  "fpv_tip_0x164C04FE": {
    "en": "",
    "zh": "dpp模块订阅sgbm数据异常"
  },
  "fpv_tip_0x164C053E": {
    "en": "",
    "zh": "dpp模块订阅语义炫光检测数据异常"
  },
  "fpv_tip_0x164C057E": {
    "en": "",
    "zh": "dpp模块订阅语义天空数据异常"
  },
  "fpv_tip_0x164C05BE": {
    "en": "",
    "zh": "dpp模块订阅语义桨叶检测数据异常"
  },
  "fpv_tip_0x164C05FE": {
    "en": "",
    "zh": "dpp模块数据处理异常"
  },
  "fpv_tip_0x164C0600": {
    "en": "",
    "zh": "语义depth模块初始化异常"
  },
  "fpv_tip_0x164C0640": {
    "en": "",
    "zh": "语义感知模块订阅主方向数据异常"
  },
  "fpv_tip_0x164C0680": {
    "en": "",
    "zh": "语义感知模块订阅图像状态数据异常"
  },
  "fpv_tip_0x164C06C0": {
    "en": "",
    "zh": "语义感知模块订阅输入图数据异常"
  },
  "fpv_tip_0x164C0700": {
    "en": "",
    "zh": "语义感知模块订阅最新帧号数据异常"
  },
  "fpv_tip_0x164C0740": {
    "en": "",
    "zh": "语义感知模块执行异常"
  },
  "fpv_tip_0x164C0780": {
    "en": "",
    "zh": "语义depth模块发布视差结果异常"
  },
  "fpv_tip_0x164C07C0": {
    "en": "",
    "zh": "语义感知模块调用nnf接口异常"
  },
  "fpv_tip_0x164C0800": {
    "en": "",
    "zh": "语义depth模块获取视差结果异常"
  },
  "fpv_tip_0x164C0840": {
    "en": "",
    "zh": "语义炫光模块初始化异常"
  },
  "fpv_tip_0x164C0940": {
    "en": "",
    "zh": "语义炫光模块发布炫光检测结果异常"
  },
  "fpv_tip_0x164C09C0": {
    "en": "",
    "zh": "语义天空模块初始化异常"
  },
  "fpv_tip_0x164C0A40": {
    "en": "",
    "zh": "语义感知模块订阅channel_manager数据异常"
  },
  "fpv_tip_0x164C0B00": {
    "en": "",
    "zh": "语义感知模块后处理异常"
  },
  "fpv_tip_0x164C0BC0": {
    "en": "",
    "zh": "语义天空模块发布分割结果异常"
  },
  "fpv_tip_0x164C0C00": {
    "en": "",
    "zh": "语义天空模块地平线计算异常"
  },
  "fpv_tip_0x164C0C40": {
    "en": "",
    "zh": "语义天空模块发布天空数量异常"
  },
  "fpv_tip_0x164C0C80": {
    "en": "",
    "zh": "语义天空模块订阅内参数据异常"
  },
  "fpv_tip_0x164C0CC0": {
    "en": "",
    "zh": "语义天空模块订阅外参数据异常"
  },
  "fpv_tip_0x164C0D40": {
    "en": "",
    "zh": "语义天空模块订阅坐标转换数据异常"
  },
  "fpv_tip_0x164C0D80": {
    "en": "",
    "zh": "语义桨叶检测模块初始化异常"
  },
  "fpv_tip_0x164C0E80": {
    "en": "",
    "zh": "语义桨叶检测模块发布分割结果异常"
  },
  "fpv_tip_0x164C0EC0": {
    "en": "",
    "zh": "marker识别模块初始化异常"
  },
  "fpv_tip_0x164C0ED0": {
    "en": "",
    "zh": "marker识别模块订阅fusion_data数据异常"
  },
  "fpv_tip_0x164C0F40": {
    "en": "",
    "zh": "marker识别模块发布marker_info数据异常"
  },
  "fpv_tip_0x164C0F80": {
    "en": "",
    "zh": "marker识别模块发布marker_status数据异常"
  },
  "fpv_tip_0x164C0d00": {
    "en": "",
    "zh": "语义天空模块订阅vio位姿数据异常"
  },
  "fpv_tip_0x164D0000": {
    "en": "",
    "zh": "环境检测异常"
  },
  "fpv_tip_0x164D0001": {
    "en": "",
    "zh": "环境检测模块初始化异常"
  },
  "fpv_tip_0x164D0002": {
    "en": "",
    "zh": "环境检测模块DBUS发布异常"
  },
  "fpv_tip_0x164D0003": {
    "en": "",
    "zh": "环境检测模块V1发布异常"
  },
  "fpv_tip_0x164D0004": {
    "en": "",
    "zh": "环境检测模块过暗标志推送异常"
  },
  "fpv_tip_0x164D0005": {
    "en": "",
    "zh": "环境检测模块进入低功耗"
  },
  "fpv_tip_0x164D0006": {
    "en": "",
    "zh": "环境检测模块退出低功耗"
  },
  "fpv_tip_0x164D0040": {
    "en": "",
    "zh": "照度检测模块异常"
  },
  "fpv_tip_0x164D0041": {
    "en": "",
    "zh": "水面检测模块异常"
  },
  "fpv_tip_0x164D0042": {
    "en": "",
    "zh": "云雾检测模块异常"
  },
  "fpv_tip_0x164D0043": {
    "en": "",
    "zh": "桨保检测模块异常"
  },
  "fpv_tip_0x164D0044": {
    "en": "",
    "zh": "脏污检测模块异常"
  },
  "fpv_tip_0x164D0045": {
    "en": "",
    "zh": "遮挡检测模块异常"
  },
  "fpv_tip_0x164D0046": {
    "en": "",
    "zh": "室内检测模块异常"
  },
  "fpv_tip_0x164D0080": {
    "en": "",
    "zh": "环境检测模块AEC数据订阅异常"
  },
  "fpv_tip_0x164D0081": {
    "en": "",
    "zh": "环境检测模块照度矩阵订阅异常"
  },
  "fpv_tip_0x164D0082": {
    "en": "",
    "zh": "环境检测模块maphex订阅异常"
  },
  "fpv_tip_0x164D0083": {
    "en": "",
    "zh": "环境检测模块视觉云雾数据异常"
  },
  "fpv_tip_0x164D0084": {
    "en": "",
    "zh": "环境检测模块VIO前端数据异常"
  },
  "fpv_tip_0x164D0085": {
    "en": "",
    "zh": "环境检测模块TOF云雾数据异常"
  },
  "fpv_tip_0x164D0086": {
    "en": "",
    "zh": "环境检测模块桨保图像订阅异常"
  },
  "fpv_tip_0x164D0087": {
    "en": "",
    "zh": "环境检测模块3DTOF数据异常"
  },
  "fpv_tip_0x164D0088": {
    "en": "",
    "zh": "环境检测模块CNN脏污数据异常"
  },
  "fpv_tip_0x164D0089": {
    "en": "",
    "zh": "环境检测模块CNN室内数据异常"
  },
  "fpv_tip_0x164D008A": {
    "en": "",
    "zh": "环境检测模块CNN水面数据异常"
  },
  "fpv_tip_0x164D008B": {
    "en": "",
    "zh": "环境检测模块CNN炫光数据异常"
  },
  "fpv_tip_0x164D008C": {
    "en": "",
    "zh": "环境检测模块FMU FLY CORE数据异常"
  },
  "fpv_tip_0x164D008D": {
    "en": "",
    "zh": "环境检测模块飞行状态数据异常"
  },
  "fpv_tip_0x164D008E": {
    "en": "",
    "zh": "环境检测模块PARSING态数据异常"
  },
  "fpv_tip_0x164DFFF1": {
    "en": "",
    "zh": "1DTOF异常"
  },
  "fpv_tip_0x164DFFF2": {
    "en": "",
    "zh": "3DtoF异常"
  },
  "fpv_tip_0x164DFFF3": {
    "en": "",
    "zh": "雷达传感器异常"
  },
  "fpv_tip_0x164DFFF4": {
    "en": "",
    "zh": "超声波传感器异常"
  },
  "fpv_tip_0x164a0001": {
    "en": "",
    "zh": "垂直高度控制异常"
  },
  "fpv_tip_0x164a0002": {
    "en": "",
    "zh": "垂直速度控制异常"
  },
  "fpv_tip_0x164a0003": {
    "en": "",
    "zh": "垂直高度反馈异常"
  },
  "fpv_tip_0x164a0004": {
    "en": "",
    "zh": "垂直速度反馈异常"
  },
  "fpv_tip_0x164a0005": {
    "en": "",
    "zh": "垂直动力饱和异常"
  },
  "fpv_tip_0x164a1001": {
    "en": "",
    "zh": "水平控制异常"
  },
  "fpv_tip_0x164a1002": {
    "en": "",
    "zh": "水平速度控制异常"
  },
  "fpv_tip_0x164a1003": {
    "en": "",
    "zh": "水平速度反馈异常"
  },
  "fpv_tip_0x164a1004": {
    "en": "",
    "zh": "姿态控制异常"
  },
  "fpv_tip_0x17000001": {
    "de": "Primäre Bildübertragung getrennt",
    "en": "Primary image transmission disconnected",
    "es": "Transmisión de la imagen principal desconectada",
    "fr": "Transmission d'image principale déconnectée",
    "it": "",
    "ja": "プライマリー映像伝送との接続が切断されました",
    "ko": "기본 이미지 전송 연결 끊김",
    "pt": "",
    "ru": "Передача основного изображения отключена",
    "tr": "Birincil görüntü aktarımı bağlantısı kesildi",
    "zh": "主链路图传已断开"
  },
  "fpv_tip_0x17000001_in_the_sky": {
    "de": "Primäre Bildübertragung getrennt. Antennen ausrichten.",
    "en": "Primary image transmission disconnected. Adjust antennas",
    "es": "Transmisión de la imagen principal desconectada. Ajuste las antenas",
    "fr": "Transmission d'image principale déconnectée. Ajuster les antennes",
    "it": "",
    "ja": "プライマリー映像伝送との接続が切断されました。アンテナを調整してください",
    "ko": "기본 이미지 전송 연결 끊김. 안테나 조정 필요",
    "pt": "",
    "ru": "Передача основного изображения отключена. Отрегулируйте антенны",
    "tr": "Birincil görüntü aktarımı bağlantısı kesildi. Antenleri ayarlayın",
    "zh": "主链路图传已断开，请尝试调整天线角度"
  },
  "fpv_tip_0x17000002": {
    "de": "Schwaches Fernsteuerungssignal. Antennen ausrichten",
    "en": "Remote controller signal weak. Adjust antennas",
    "es": "La señal del control remoto es débil. Ajusta las antenas",
    "fr": "Signal de la radiocommande faible. Ajuster les antennes",
    "ja": "送信機信号が弱い。アンテナを調整してください",
    "ko": "조종기 신호 약함. 안테나 조정 필요",
    "ru": "Слабый сигнал пульта управления. Отрегулируйте антенны",
    "tr": "Uzaktan kumanda sinyali zayıf. Antenleri ayarlayın",
    "zh": "遥控器信号微弱，请调整天线"
  },
  "fpv_tip_0x17000002_in_the_sky": {
    "ar": "",
    "de": "",
    "en": "Remote controller signal weak. Adjust antennas",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "遥控器信号微弱，请调整天线",
    "zh-Hant": ""
  },
  "fpv_tip_0x17000003": {
    "de": "Starke Signalstörungen an der Position des Fluggeräts. Mit Vorsicht fliegen",
    "en": "Strong signal interference in aircraft location. Fly with caution",
    "es": "Fuerte interferencia de señal en la ubicación de la aeronave. Vuela con cuidado",
    "fr": "Fortes interférences de signal à la position de l’appareil. Pilotez avec précaution",
    "ja": "機体の現在地で強い電波干渉があります。慎重に飛行してください",
    "ko": "기체 위치에서 강한 신호 간섭 받음. 비행 시 주의 필요",
    "ru": "Сильные помехи в местоположении дрона. Соблюдайте осторожность при полете",
    "tr": "Araç konumunda güçlü sinyal paraziti. Aracınızı dikkatli uçurun",
    "zh": "飞行器所在位置信号干扰较强，请谨慎飞行"
  },
  "fpv_tip_0x17000003_in_the_sky": {
    "ar": "",
    "de": "",
    "en": "Strong signal interference in aircraft location. Fly with caution",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "飞行器所在位置信号干扰较强，请谨慎飞行",
    "zh-Hant": ""
  },
  "fpv_tip_0x17000004": {
    "de": "Schwaches Bildübertragungssignal. Antennen ausrichten",
    "en": "Image transmission signal weak. Adjust antennas",
    "es": "Señal de transmisión de la imagen débil. Ajusta las antenas",
    "fr": "Signal de transmission d'image faible. Ajuster les antennes",
    "ja": "映像伝送信号が弱い。アンテナを調整してください",
    "ko": "이미지 전송 신호 약함. 안테나 조정 필요",
    "ru": "Слабый сигнал передачи изображения. Отрегулируйте антенны",
    "tr": "Görüntü aktarım sinyali zayıf. Antenleri ayarlayın",
    "zh": "图传信号弱，请调整遥控器朝向飞行器"
  },
  "fpv_tip_0x17000004_in_the_sky": {
    "ar": "",
    "de": "",
    "en": "Image transmission signal weak. Adjust antennas",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "图传信号弱，请调整遥控器朝向飞行器",
    "zh-Hant": ""
  },
  "fpv_tip_0x17000005": {
    "de": "Starke Signalstörungen an der Position der Fernsteuerung. Mit Vorsicht fliegen",
    "en": "Strong signal interference in remote controller location. Fly with caution",
    "es": "Fuerte interferencia de señal en la ubicación del control remoto. Vuela con cuidado",
    "fr": "Fortes interférences à la position de la radiocommande. Pilotez avec précaution",
    "ja": "送信機の現在地で強い電波干渉があります。慎重に飛行してください",
    "ko": "조종기 위치에서 강한 신호 간섭 받음. 비행 시 주의 필요",
    "ru": "Сильные помехи в местоположении пульта управления. Соблюдайте осторожность при полете",
    "tr": "Uzaktan kumanda konumunda güçlü sinyal paraziti. Aracınızı dikkatli uçurun",
    "zh": "遥控器信号干扰较强，请注意飞行安全"
  },
  "fpv_tip_0x17000005_in_the_sky": {
    "ar": "",
    "de": "",
    "en": "Strong signal interference in remote controller location. Fly with caution",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "遥控器信号干扰较强，请注意飞行安全",
    "zh-Hant": ""
  },
  "fpv_tip_0x17000006": {
    "de": "Landeskennung nicht aktualisiert. Bestimmte Funktionen nicht verfügbar. Starkes GPS-Signal sicherstellen und mit DJI Pilot verbinden, um automatisch zu aktualisieren",
    "en": "Country code not updated. Certain features unavailable. Make sure GPS signal is strong and connect to DJI Pilot to update automatically",
    "es": "Código del país no actualizado. Algunas funciones no están disponibles. Asegúrate de que la señal GPS sea fuerte y conéctate a DJI Pilot para actualizar automáticamente",
    "fr": "Code de pays non mis à jour. Certaines fonctionnalités ne sont pas disponibles. Assurez-vous que le signal GPS est fort et connectez-vous à DJI Pilot pour mettre à jour automatiquement",
    "ja": "国コードが更新されていません。一部の機能は使用できません。GPS信号が強いことを確認し、DJI Pilotに接続すると、自動的に更新されます",
    "ko": "국가 코드 업데이트 안 됨. 특정 기능 사용 불가. GPS 신호가 강한지 확인하고 DJI Pilot에 연결하여 자동으로 업데이트하세요.",
    "ru": "Код страны не обновлен. Некоторые функции недоступны. Убедитесь, что сигнал GPS сильный, и подключитесь к DJI Pilot для автоматического обновления.",
    "tr": "Ülke kodu güncellenmedi. Bazı özellikler kullanılamıyor. GPS sinyalinin güçlü olduğundan emin olun ve otomatik olarak güncellemek için DJI Pilot'a bağlanın",
    "zh": "国家码未更新，部分功能受限，请保证飞机GPS信号良好并连接 DJI Pilot 自动更新"
  },
  "fpv_tip_0x17000006_in_the_sky": {
    "de": "Landeskennung nicht aktualisiert. Bestimmte Funktionen nicht verfügbar",
    "en": "Country code not updated. Certain features unavailable",
    "es": "Código del país no actualizado. Algunas funciones no están disponibles",
    "fr": "Code de pays non mis à jour. Certaines fonctionnalités ne sont pas disponibles",
    "ja": "国コードが更新されていません。一部の機能は使用できません",
    "ko": "국가 코드 업데이트 안 됨. 특정 기능 사용 불가",
    "ru": "Код страны не обновлен. Некоторые функции недоступны",
    "tr": "Ülke kodu güncellenmedi. Bazı özellikler kullanılamıyor",
    "zh": "国家码未更新，部分功能受限"
  },
  "fpv_tip_0x17000011": {
    "de": "Speicher von Avioniksystem unzureichend. Zum Wiederherstellen Fluggerät neu starten",
    "en": "Avionics system memory insufficient. Restart aircraft to restore",
    "es": "Memoria del sistema de aviónica insuficiente. Reinicia la aeronave para restaurar",
    "fr": "Mémoire des systèmes avioniques insuffisante. Redémarrez l'appareil pour restaurer",
    "ja": "航空電子システムのメモリ不足。復元するには機体を再起動してください",
    "ko": "항공전자 시스템 메모리가 부족합니다. 복원하려면 기체를 재시작하세요",
    "ru": "Недостаточно памяти в авиационной электронной системе. Перезагрузите дрон для восстановления",
    "tr": "Havacılık Elektroniği sistem belleği yetersiz. Geri yüklemek için aracı yeniden başlatın",
    "zh": "航电系统内存低，请重启飞行器尝试恢复"
  },
  "fpv_tip_0x17000011_in_the_sky": {
    "de": "Speicher von Avioniksystem unzureichend. Mit Vorsicht fliegen",
    "en": "Avionics system memory insufficient. Fly with caution",
    "es": "Memoria del sistema de aviónica insuficiente. Vuela con cuidado",
    "fr": "Mémoire des systèmes avioniques insuffisante. Pilotez avec précaution",
    "ja": "航空電子システムのメモリ不足。慎重に飛行してください",
    "ko": "항공전자 시스템 메모리가 부족합니다. 비행 시 주의 필요",
    "ru": "Недостаточно памяти в авиационной электронной системе. Будьте внимательны при полете",
    "tr": "Havacılık Elektroniği sistem belleği yetersiz. Dikkatli uçurun",
    "zh": "航电系统内存低，请谨慎飞行"
  },
  "fpv_tip_0x17000013": {
    "de": "Signalstörungen an der Fernsteuerung",
    "en": "Remote controller signal interference",
    "es": "El control remoto detecta interferencias",
    "fr": "Interférences avec le signal de la radiocommande",
    "ja": "送信機の信号干渉",
    "ko": "조종기 신호 간섭",
    "ru": "Помехи сигнала пульта управления",
    "tr": "Uzaktan kumanda sinyali paraziti",
    "zh": "遥控器信号受干扰"
  },
  "fpv_tip_0x17000014": {
    "de": "Starke Signalstörungen an der Fernsteuerung. Von anderen Fernsteuerungen oder der Störquelle wegbewegen",
    "en": "Strong remote controller signal interference. Move away from other remote controllers or source of interference",
    "es": "El control remoto detecta interferencias fuertes. Aléjate de otros controles remotos o de la fuente de interferencias.",
    "fr": "Fortes interférences avec le signal de la radiocommande. Éloignez-vous des autres radiocommandes ou sources d'interférences",
    "ja": "送信機の信号干渉 強。他の送信機や干渉源から離れてください",
    "ko": "강한 조종기 신호 간섭. 다른 조종기나 간섭원에서 멀리 떨어지세요.",
    "ru": "Сильные помехи сигнала пульта дистанционного управления. Отойдите от других пультов дистанционного управления или источников помех",
    "tr": "Güçlü uzaktan kumanda sinyali paraziti. Diğer uzaktan kumandalardan veya parazit kaynaklarından uzak durun",
    "zh": "遥控器侧干扰较强，请远离其他遥控器或干扰源"
  },
  "fpv_tip_0x17000015": {
    "de": "Starke Signalstörungen am Fluggerät. Umgehend zum Startpunkt zurückkehren oder Fluggerät von der Störquelle wegbewegen",
    "en": "Strong aircraft signal interference. Return to home promptly or move away from source of interference",
    "es": "La aeronave detecta interferencias fuertes. Regresa al punto de origen rápidamente o aléjate de la fuente de interferencias.",
    "fr": "Fortes interférences avec le signal de l'appareil. Retournez rapidement au point de départ ou éloignez-vous des sources d'interférences",
    "ja": "機体の信号干渉 強。直ちにホームに帰還するか、干渉源から離れてください",
    "ko": "강한 기체 신호 간섭. 즉시 홈으로 돌아가거나 간섭원에서 멀리 떨어지세요.",
    "ru": "Сильные помехи сигнала дрона. Как можно скорее вернитесь на базу или отойдите от источника помех",
    "tr": "Güçlü hava aracı sinyali paraziti. Hemen başlangıç noktasına geri dönün veya parazit kaynağından uzaklaşın",
    "zh": "飞行器侧干扰较强，请尽快返航或飞离干扰源"
  },
  "fpv_tip_0x17000016": {
    "de": "Schwache Signalstärke der Fernsteuerung. Antennenausrichtung überprüfen",
    "en": "Remote controller signal weak. Adjust antenna",
    "es": "Señal del control remoto débil. Ajusta la antena.",
    "fr": "Signal de la radiocommande faible. Réglez l'antenne",
    "ja": "送信機信号 弱。アンテナを調整してください",
    "ko": "조종기 신호 약함. 안테나를 조정하세요.",
    "ru": "Слабый сигнал пульта. Отрегулируйте антенну",
    "tr": "Uzaktan kumanda sinyali zayıf. Anteni ayarlayın",
    "zh": "遥控器信号微弱，请尝试调整天线"
  },
  "fpv_tip_0x17000017": {
    "de": "Schwaches Signal Steuerung A Darauf achten, dass die Antennen von Steuerung A ausgeklappt sind und es keine Hindernisse zwischen Fluggerät und Fernsteuerung gibt",
    "en": "Controller A signal weak. Make sure antennas of Controller A are unfolded and no obstacle is blocking aircraft and remote controller",
    "es": "Señal débil del control A. Asegúrese de que las antenas del control A estén desplegadas y de que ningún obstáculo bloquee la aeronave y el control remoto",
    "fr": "Signal de la commande A faible. Assurez-vous que les antennes de la commande A sont dépliées et qu'aucun obstacle ne bloque l'appareil et la radiocommande",
    "ja": "送信機Aの信号 弱。送信機Aのアンテナが展開され、機体や送信機を遮る障害物がないことを確認してください",
    "ko": "조종기 A 신호 약함. 조종기 A의 안테나가 펼쳐져 있고 기체와 조종기를 가리는 장애물이 없는지 확인하세요",
    "ru": "Слабый сигнал пульта А. Убедитесь, что антенны пульта А развернуты и никакие препятствия не блокируют дрон и пульт дистанционного управления",
    "tr": "Kumanda A'nın sinyali zayıf. Kumanda A'nın antenlerinin açık olduğundan ve hiçbir engelin hava aracını ve uzaktan kumandayı engellemediğinden emin olun",
    "zh": "A控信号弱，请检查A控天线已展开并和飞机之间无遮挡"
  },
  "fpv_tip_0x17000018": {
    "de": "Schwaches Signal Steuerung B Darauf achten, dass die Antennen von Steuerung B ausgeklappt sind und es keine Hindernisse zwischen Fluggerät und Fernsteuerung gibt",
    "en": "Controller B signal weak. Make sure antennas of controller B are unfolded and no obstacle is blocking aircraft and remote controller",
    "es": "Señal débil del control B. Asegúrese de que las antenas del control B estén desplegadas y de que ningún obstáculo bloquee la aeronave y el control remoto",
    "fr": "Signal de la commande B faible. Assurez-vous que les antennes de la commande B sont dépliées et qu'aucun obstacle ne bloque l'appareil et la radiocommande",
    "ja": "送信機Bの信号 弱。送信機Bのアンテナが展開され、機体や送信機を遮る障害物がないことを確認してください",
    "ko": "조종기 B 신호 약함. 조종기 B의 안테나가 펼쳐져 있고 기체와 조종기를 가리는 장애물이 없는지 확인하세요",
    "ru": "Слабый сигнал пульта B. Убедитесь, что антенны пульта B развернуты и никакие препятствия не блокируют дрон и пульт дистанционного управления",
    "tr": "Kumanda B'nin sinyali zayıf. Kumanda B'nin antenlerinin açık olduğundan ve hiçbir engelin hava aracını ve uzaktan kumandayı engellemediğinden emin olun",
    "zh": "B控信号弱，请检查B控天线已展开并和飞机之间无遮挡"
  },
  "fpv_tip_0x17000019": {
    "de": "Kamerafehler. Gimbal abnehmen und wieder anbringen",
    "en": "Camera error. Detach and remount gimbal",
    "es": "Error de la cámara. Retire el estabilizador y vuelva a montarlo",
    "fr": "Erreur caméra. Détacher et remonter la nacelle",
    "ja": "カメラエラー。ジンバルを取り外して、取り付け直してください",
    "ko": "카메라 오류. 짐벌을 분리 후 다시 장착하세요",
    "ru": "Ошибка камеры. Отсоедините и переустановите гиростабилизатор",
    "tr": "Kamera hatası. Gimbalı çıkarın ve yeniden takın",
    "zh": "相机异常，请尝试重新插拔云台"
  },
  "fpv_tip_0x17000041": {
    "en": "Unable to get control stick input",
    "zh": "无法获取遥控器杆量"
  },
  "fpv_tip_0x17000071": {
    "de": "Avioniksystem überlastet. Überprüfen, ob Protokolle übertragen werden. Zum Wiederherstellen Fluggerät neu starten",
    "en": "Avionics system overloaded. Check whether logs are being transmitted. Restart aircraft to restore",
    "es": "Sistema de aviónica sobrecargado. Comprueba si se están transmitiendo los registros. Reinicia la aeronave para restaurar",
    "fr": "Systèmes avioniques surchargés. Vérifiez si les journaux sont transmis. Redémarrez l'appareil pour restaurer",
    "it": "",
    "ja": "航空電子システム過負荷状態。ログが伝送中か確認してください。復元するには機体を再起動してください",
    "ko": "항공전자 시스템 과부하. 로그가 전송되고 있는지 확인하세요. 복원하려면 기체를 재시작하세요",
    "pt": "",
    "ru": "Авиационная электронная система перегружена. Проверьте, передаются ли журналы. Перезагрузите дрон для восстановления",
    "tr": "Havacılık Elektroniği sistemi aşırı yüklü. Kayıtların iletilip iletilmediğini kontrol edin. Geri yüklemek için aracı yeniden başlatın",
    "zh": "航电系统负载高，请重启飞行器尝试恢复"
  },
  "fpv_tip_0x17000071_in_the_sky": {
    "de": "Avioniksystem überlastet. Mit Vorsicht fliegen",
    "en": "Avionics system overloaded. Fly with caution",
    "es": "Sistema de aviónica sobrecargado. Vuela con cuidado",
    "fr": "Systèmes avioniques surchargés. Pilotez avec précaution",
    "it": "",
    "ja": "航空電子システム過負荷状態。慎重に飛行してください",
    "ko": "항공전자 시스템 과부하. 비행 시 주의 필요",
    "pt": "",
    "ru": "Авиационная электронная система перегружена. Будьте внимательны при полете",
    "tr": "Havacılık Elektroniği sistemi aşırı yüklü. Dikkatli uçurun",
    "zh": "航电系统负载高，请谨慎飞行"
  },
  "fpv_tip_0x17000081": {
    "de": "Speicher von Avioniksystem unzureichend. Zum Wiederherstellen Fluggerät neu starten",
    "en": "Avionics system memory insufficient. Restart aircraft to restore",
    "es": "Memoria del sistema de aviónica insuficiente. Reinicia la aeronave para restaurar",
    "fr": "Mémoire des systèmes avioniques insuffisante. Redémarrez l\\'appareil pour restaurer",
    "it": "",
    "ja": "航空電子システムのメモリ不足。機体を再起動して復旧してください",
    "ko": "항공전자 시스템 메모리가 부족합니다. 복원하려면 기체를 재시동하세요",
    "pt": "",
    "ru": "Недостаточно памяти в авиационной электронной системе. Перезапустите дрон для восстановления",
    "tr": "Havacılık Elektroniği sistem belleği yetersiz. Geri yüklemek için aracı yeniden başlatın",
    "zh": "航电系统内存低，请重启飞行器尝试恢复"
  },
  "fpv_tip_0x17000081_in_the_sky": {
    "de": "Speicher von Avioniksystem unzureichend. Mit Vorsicht fliegen",
    "en": "Avionics system memory insufficient. Fly with caution",
    "es": "Memoria del sistema de aviónica insuficiente. Vuela con cuidado",
    "fr": "Mémoire des systèmes avioniques insuffisante. Volez avec précaution",
    "it": "",
    "ja": "航空電子システムのメモリ不足。慎重に飛行してください",
    "ko": "항공전자 시스템 메모리가 부족합니다. 주의해서 비행하세요",
    "pt": "",
    "ru": "Недостаточно памяти в авиационной электронной системе. Будьте внимательны при полете",
    "tr": "Havacılık Elektroniği sistem belleği yetersiz. Dikkatli uçurun",
    "zh": "航电系统内存低，请谨慎飞行"
  },
  "fpv_tip_0x17010021": {
    "de": "Fehler: Flugregler. Umgehend zum Startpunkt zurückkehren",
    "en": "Flight controller error. Return to home promptly",
    "es": "Error del controlador de vuelo. Regresa al punto de origen rápidamente.",
    "fr": "Erreur du contrôleur de vol. Revenez au point de départ rapidement",
    "ja": "フライトコントローラーエラー。直ちにホームに帰還してください",
    "ko": "비행 컨트롤러 오류. 즉시 홈으로 돌아가세요.",
    "ru": "Ошибка полетного контроллера. Как можно скорее вернитесь на базу",
    "tr": "Uçuş kontrol sistemi hatası. Derhal başlangıç noktasına geri dönün",
    "zh": "飞控系统异常，请尽快返航"
  },
  "fpv_tip_0x17110041": {
    "ar": "",
    "de": "",
    "en": "Unable to obtain control stick input",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "无法获取遥控器杆量信号",
    "zh-Hant": ""
  },
  "fpv_tip_0x17110041_in_the_sky": {
    "ar": "",
    "de": "",
    "en": "Control stick input error. Fly with caution and land promptly",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "遥控器杆量信号异常，请注意飞行安全，尽快降落",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200020": {
    "ar": "",
    "de": "",
    "en": "Winch system motor stalled",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电机堵转",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200022": {
    "en": "Winch system motor overloaded",
    "zh": "空吊电机过载"
  },
  "fpv_tip_0x1720002B": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC overheated",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调温度过高",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720002C": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC voltage too high",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调电压过高",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720002D": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC voltage too low",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调电压过低",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720002F": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC or motor auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调或电机自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200030": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC or motor auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调或电机自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200031": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC or motor auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调或电机自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200032": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC or motor auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调或电机自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200033": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC or motor auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调或电机自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200034": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC or motor auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调或电机自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200035": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC or motor auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调或电机自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200036": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200037": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200038": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200039": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720003A": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720003B": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720003C": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC or motor auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调或电机自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720003D": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC or motor auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调或电机自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720003E": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC or motor auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调或电机自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720003F": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC or motor auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调或电机自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200040": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200041": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200042": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200043": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200044": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC voltage too low",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调电压过低",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200045": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC voltage too high",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调电压过高",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200046": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC or motor auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调或电机自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200047": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC or motor auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调或电机自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200048": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC or motor auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调或电机自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200049": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720004A": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC overheated",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调温度过高",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720004B": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC temperature too low",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调温度过低",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720004C": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720004D": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720004E": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200055": {
    "ar": "",
    "de": "",
    "en": "Winch system motor may be overheated. Do not quickly reel up and down when aircraft is loaded",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电机存在超温风险，不要反复带载快速收放",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200056": {
    "ar": "",
    "de": "",
    "en": "Winch system motor severely overheated. Aircraft will land automatically",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电机严重超温，待电机冷却后重启使用",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200058": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC speed and current error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调转速、电流异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720005D": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720005E": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200077": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC severely overheated",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调严重超温",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200078": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC severely overheated",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调严重超温",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200079": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC firmware incompatible with aircraft model",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调固件与飞机机型不匹配",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720007B": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720007C": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720007D": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC or motor auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调或电机自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720007E": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC or motor auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调或电机自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1720007F": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC or motor auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调或电机自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200080": {
    "ar": "",
    "de": "",
    "en": "Winch system ESC or motor auto-check error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电调或电机自检异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200083": {
    "en": "Winch system ESC calibration failed",
    "zh": "空吊电调校准失败"
  },
  "fpv_tip_0x17200200": {
    "ar": "",
    "de": "",
    "en": "Cable stuck or reversely reeled in. Enable repair mode to manually unwind cable",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊系统绕线卡塞或反转，请开启检修模式，整理绕线",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200201": {
    "ar": "",
    "de": "",
    "en": "Winch system pinch protection enabled",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊系统防夹手功能启动",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200202": {
    "ar": "",
    "de": "",
    "en": "Winch reeling down without payload. Attach payload",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊系统空载放线，请带载放线",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200203": {
    "ar": "",
    "de": "",
    "en": "Winch system motor not calibrated. Calibrate motor",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊系统电机未校准，请校准再工作",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200204": {
    "ar": "",
    "de": "",
    "en": "Winch system triaxial force sensor not calibrated. Calibrate sensor",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊系统三向力未校准，请校准再工作",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200205": {
    "ar": "",
    "de": "",
    "en": "Winch system cable length not calibrated. Calibrate cable length",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊系统绳长未校准，请校准再工作",
    "zh-Hant": ""
  },
  "fpv_tip_0x17200206": {
    "en": "Winch brake hardware damaged. Restart aircraft and try again",
    "zh": "空吊系统制动器硬件已损坏，尝试重启后再工作"
  },
  "fpv_tip_0x17200207": {
    "en": "Winch brake auto-check error. Unable to take off",
    "zh": "空吊系统制动器自检异常，无法起飞"
  },
  "fpv_tip_0x17200208": {
    "en": "Winch cable auto-check error. Check if cable is wound in reverse direction or not secure",
    "zh": "空吊系统线绳自检异常，起飞前请确认是否反绕或不在位"
  },
  "fpv_tip_0x17200209": {
    "en": "Winch limit switch auto-check error. Stop propellers and rotate dial manually. Make sure winch system motor works properly",
    "zh": "空吊系统在位开关自检异常，请停桨手动波轮，观察空吊电机是否正常运行"
  },
  "fpv_tip_0x1720020A": {
    "en": "Winch limit switch error. Check if the switch is stuck or damaged",
    "zh": "空吊在位开关功能异常，请检查在位开关是否粘连或损坏"
  },
  "fpv_tip_0x17210000": {
    "ar": "",
    "de": "",
    "en": "Parachute sensor error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞传感器异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17210001": {
    "ar": "",
    "de": "",
    "en": "Parachute igniter error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞点火器异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17210002": {
    "ar": "",
    "de": "",
    "en": "Parachute connection error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞链路异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17210003": {
    "ar": "",
    "de": "",
    "en": "Parachute igniter communication error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞点火器通信异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17210004": {
    "ar": "",
    "de": "",
    "en": "Parachute igniter connection error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞点火器连接异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17210005": {
    "ar": "",
    "de": "",
    "en": "Parachute igniter voltage error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞点火器电压异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17210006": {
    "ar": "",
    "de": "",
    "en": "Parachute igniter resistance error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞点火器电阻异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17210007": {
    "ar": "",
    "de": "",
    "en": "Parachute ignition error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞点火器点火异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17210008": {
    "ar": "",
    "de": "",
    "en": "Parachute IMU communication error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞IMU通信异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x17210009": {
    "ar": "",
    "de": "",
    "en": "Parachute IMU connection error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞IMU连接异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1721000A": {
    "ar": "",
    "de": "",
    "en": "Parachute barometer communication error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞气压计通信异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1721000B": {
    "ar": "",
    "de": "",
    "en": "Parachute internal storage error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞机身存储异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1721000C": {
    "ar": "",
    "de": "",
    "en": "Parachute control error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞控制链路异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1721000D": {
    "ar": "",
    "de": "",
    "en": "Parachute connection error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞融合链路异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1721000E": {
    "ar": "",
    "de": "",
    "en": "Parachute speed deviation too large",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞融合速度误差大",
    "zh-Hant": ""
  },
  "fpv_tip_0x1721000F": {
    "ar": "",
    "de": "",
    "en": "Parachute built-in battery voltage too low",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞内置电池电压过低",
    "zh-Hant": ""
  },
  "fpv_tip_0x17210010": {
    "ar": "",
    "de": "",
    "en": "Parachute Built-in Battery Overheated",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞内置电池温度过高",
    "zh-Hant": ""
  },
  "fpv_tip_0x17210011": {
    "en": "Parachute File System Error",
    "zh": "降落伞文件系统异常"
  },
  "fpv_tip_0x17210013": {
    "en": "Parachute deployed",
    "zh": "降落伞已开伞"
  },
  "fpv_tip_0x17210020": {
    "ar": "",
    "de": "",
    "en": "Parachute opened. Watch out for pedestrians nearby",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "降落伞已经开伞，请注意周围行人安全",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000001": {
    "de": "Avioniksystem überlastet. Überprüfen, ob Protokolle übertragen werden. Zum Wiederherstellen Fluggerät neu starten",
    "en": "Avionics system overloaded. Check whether logs are being transmitted. Restart aircraft to restore",
    "es": "Sistema de aviónica sobrecargado. Comprueba si se están transmitiendo los registros. Reinicia la aeronave para restaurar",
    "fr": "Systèmes avioniques surchargés. Vérifiez que les journaux sont bien transmis. Redémarrez l\\'appareil pour restaurer",
    "ja": "航空電子システム過負荷状態。ログが伝送されているか確認してください。機体を再起動して復旧してください",
    "ko": "항공전자 시스템 과부하. 로그가 전송되고 있는지 확인하세요. 복원하려면 기체를 재시동하세요",
    "ru": "Авиационная электронная система перегружена. Проверьте, передаются ли журналы. Перезапустите дрон для восстановления",
    "tr": "Havacılık Elektroniği sistemi aşırı yüklü. Kayıtların iletilip iletilmediğini kontrol edin. Geri yüklemek için aracı yeniden başlatın",
    "zh": "航电系统负载高，请确认是否正在进行日志回传操作，可以重启飞行器尝试恢复"
  },
  "fpv_tip_0x19000001_in_the_sky": {
    "de": "Avioniksystem überlastet. Mit Vorsicht fliegen",
    "en": "Avionics system overloaded. Fly with caution",
    "es": "Sistema de aviónica sobrecargado. Vuela con cuidado",
    "fr": "Systèmes avioniques surchargés. Volez avec précaution",
    "ja": "航空電子システム過負荷状態。慎重に飛行してください(alarmid)",
    "ko": "항공전자 시스템 과부하. 주의해서 비행하세요",
    "ru": "Авиационная электронная система перегружена. Будьте внимательны при полете",
    "tr": "Havacılık Elektroniği sistemi aşırı yüklü. Dikkatli uçurun",
    "zh": "航电系统负载高，请谨慎飞行"
  },
  "fpv_tip_0x19000002": {
    "de": "Avioniksystem überlastet. Überprüfen, ob Protokolle übertragen werden. Zum Wiederherstellen Fluggerät neu starten",
    "en": "Avionics system overloaded. Check whether logs are being transmitted. Restart aircraft to restore",
    "es": "Sistema de aviónica sobrecargado. Comprueba si se están transmitiendo los registros. Reinicia la aeronave para restaurar",
    "fr": "Systèmes avioniques surchargés. Vérifiez si les journaux sont transmis. Redémarrez l'appareil pour restaurer",
    "ja": "航空電子システム過負荷状態。ログが伝送中か確認してください。復元するには機体を再起動してください",
    "ko": "항공전자 시스템 과부하. 로그가 전송되고 있는지 확인하세요. 복원하려면 기체를 재시작하세요",
    "ru": "Авиационная электронная система перегружена. Проверьте, передаются ли журналы. Перезагрузите дрон для восстановления",
    "tr": "Havacılık Elektroniği sistemi aşırı yüklü. Kayıtların iletilip iletilmediğini kontrol edin. Geri yüklemek için aracı yeniden başlatın",
    "zh": "航电系统负载高，请确认是否正在进行日志回传操作，可以重启飞行器尝试恢复"
  },
  "fpv_tip_0x19000002_in_the_sky": {
    "de": "Avioniksystem überlastet. Mit Vorsicht fliegen",
    "en": "Avionics system overloaded. Fly with caution",
    "es": "Sistema de aviónica sobrecargado. Vuela con cuidado",
    "fr": "Systèmes avioniques surchargés. Pilotez avec précaution",
    "ja": "航空電子システム過負荷状態。慎重に飛行してください",
    "ko": "항공전자 시스템 과부하. 비행 시 주의 필요",
    "ru": "Авиационная электронная система перегружена. Будьте внимательны при полете",
    "tr": "Havacılık Elektroniği sistemi aşırı yüklü. Dikkatli uçurun",
    "zh": "航电系统负载高，请谨慎飞行"
  },
  "fpv_tip_0x19000003": {
    "ar": "",
    "de": "",
    "en": "存在业务cpu loading超限",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "存在业务cpu loading超限",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000004": {
    "ar": "",
    "de": "",
    "en": "存在业务内存资源使用超限",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "存在业务内存资源使用超限",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000005": {
    "ar": "",
    "de": "",
    "en": "中断频率过高",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "中断频率过高",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000006": {
    "ar": "",
    "de": "",
    "en": "内存申请失败",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "内存申请失败",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000007": {
    "ar": "",
    "de": "",
    "en": "rt进程cpu loading过高",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "rt进程cpu loading过高",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000008": {
    "ar": "",
    "de": "",
    "en": "系统内存不足",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "系统内存不足",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000009": {
    "ar": "",
    "de": "",
    "en": "系统内存严重不足",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "系统内存严重不足",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900000A": {
    "ar": "",
    "de": "",
    "en": "rcu stall异常",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "rcu stall异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900000B": {
    "ar": "",
    "de": "",
    "en": "进程长期处于D状态",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "进程长期处于D状态",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900000C": {
    "ar": "",
    "de": "",
    "en": "原子操作异常",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "原子操作异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900000D": {
    "ar": "",
    "de": "",
    "en": "自旋锁操作异常",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "自旋锁操作异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000011": {
    "de": "Speicher von Avioniksystem unzureichend. Zum Wiederherstellen Fluggerät neu starten",
    "en": "Avionics system memory insufficient. Restart aircraft to restore",
    "es": "Memoria del sistema de aviónica insuficiente. Reinicia la aeronave para restaurar",
    "fr": "Mémoire des systèmes avioniques insuffisante. Redémarrez l\\'appareil pour restaurer",
    "ja": "航空電子システムのメモリ不足。機体を再起動して復旧してください",
    "ko": "항공전자 시스템 메모리가 부족합니다. 복원하려면 기체를 재시동하세요",
    "ru": "Недостаточно памяти в авиационной электронной системе. Перезапустите дрон для восстановления",
    "tr": "Havacılık Elektroniği sistem belleği yetersiz. Geri yüklemek için aracı yeniden başlatın",
    "zh": "航电系统内存低，请重启飞行器尝试恢复"
  },
  "fpv_tip_0x19000011_in_the_sky": {
    "de": "Speicher von Avioniksystem unzureichend. Mit Vorsicht fliegen",
    "en": "Avionics system memory insufficient. Fly with caution",
    "es": "Memoria del sistema de aviónica insuficiente. Vuela con cuidado",
    "fr": "Mémoire des systèmes avioniques insuffisante. Volez avec précaution",
    "ja": "航空電子システムのメモリ不足。慎重に飛行してください",
    "ko": "항공전자 시스템 메모리가 부족합니다. 주의해서 비행하세요",
    "ru": "Недостаточно памяти в авиационной электронной системе. Будьте внимательны при полете",
    "tr": "Havacılık Elektroniği sistem belleği yetersiz. Dikkatli uçurun",
    "zh": "航电系统内存低，请谨慎飞行"
  },
  "fpv_tip_0x19000012": {
    "de": "Speicher von Avioniksystem unzureichend. Zum Wiederherstellen Fluggerät neu starten",
    "en": "Avionics system memory insufficient. Restart aircraft to restore",
    "es": "Memoria del sistema de aviónica insuficiente. Reinicia la aeronave para restaurar",
    "fr": "Mémoire des systèmes avioniques insuffisante. Redémarrez l'appareil pour restaurer",
    "ja": "航空電子システムのメモリ不足。復元するには機体を再起動してください",
    "ko": "항공전자 시스템 메모리가 부족합니다. 복원하려면 기체를 재시작하세요",
    "ru": "Недостаточно памяти в авиационной электронной системе. Перезагрузите дрон для восстановления",
    "tr": "Havacılık Elektroniği sistem belleği yetersiz. Geri yüklemek için aracı yeniden başlatın",
    "zh": "航电系统内存低，请重启飞行器尝试恢复"
  },
  "fpv_tip_0x19000012_in_the_sky": {
    "de": "Speicher von Avioniksystem unzureichend. Mit Vorsicht fliegen",
    "en": "Avionics system memory insufficient. Fly with caution",
    "es": "Memoria del sistema de aviónica insuficiente. Vuela con cuidado",
    "fr": "Mémoire des systèmes avioniques insuffisante. Pilotez avec précaution",
    "ja": "航空電子システムのメモリ不足。慎重に飛行してください",
    "ko": "항공전자 시스템 메모리가 부족합니다. 비행 시 주의 필요",
    "ru": "Недостаточно памяти в авиационной электронной системе. Будьте внимательны при полете",
    "tr": "Havacılık Elektroniği sistem belleği yetersiz. Dikkatli uçurun",
    "zh": "航电系统内存低，请谨慎飞行"
  },
  "fpv_tip_0x19000020": {
    "ar": "",
    "de": "",
    "en": "camera进程异常崩溃",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "camera进程异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000021": {
    "de": "Fehler: Avioniksystem. Zum Wiederherstellen Fluggerät neu starten",
    "en": "Avionics system error. Restart aircraft to restore",
    "es": "Error del sistema de aviónica. Reinicia la aeronave para restaurar",
    "fr": "Erreur des systèmes avioniques. Redémarrez l\\'appareil pour restaurer",
    "ja": "航空電子システムエラー。機体を再起動して復旧してください",
    "ko": "항공전자 시스템 오류. 복원하려면 기체를 재시동하세요",
    "ru": "Ошибка авиационной электронной системы. Перезапустите дрон для восстановления",
    "tr": "Havacılık Elektroniği sistem hatası. Geri yüklemek için aracı yeniden başlatın",
    "zh": "相机回放进程异常"
  },
  "fpv_tip_0x19000021_in_the_sky": {
    "de": "Fehler: Avioniksystem. Mit Vorsicht fliegen",
    "en": "Avionics system error. Fly with caution",
    "es": "Error del sistema de aviónica. Vuela con cuidado",
    "fr": "Erreur des systèmes avioniques. Volez avec précaution",
    "ja": "航空電子システムエラー。慎重に飛行してください",
    "ko": "항공전자 시스템 오류. 주의해서 비행하세요",
    "ru": "Ошибка авиационной электронной системы. Будьте внимательны при полете",
    "tr": "Havacılık Elektroniği sistem hatası. Dikkatli uçurun",
    "zh": "相机回放进程异常"
  },
  "fpv_tip_0x19000022": {
    "de": "Fehler: Avioniksystem. Zum Wiederherstellen Fluggerät neu starten",
    "en": "Avionics system error. Restart aircraft to restore",
    "es": "Error del sistema de aviónica. Reinicia la aeronave para restaurar",
    "fr": "Erreur des systèmes avioniques. Redémarrez l'appareil pour restaurer",
    "ja": "航空電子システムエラー。復元するには機体を再起動してください",
    "ko": "항공전자 시스템 오류. 복원하려면 기체를 재시작하세요",
    "ru": "Ошибка авиационной электронной системы. Перезагрузите дрон для восстановления",
    "tr": "Havacılık Elektroniği sistem hatası. Geri yüklemek için aracı yeniden başlatın",
    "zh": "相机音频进程异常"
  },
  "fpv_tip_0x19000022_in_the_sky": {
    "de": "Fehler: Avioniksystem. Mit Vorsicht fliegen",
    "en": "Avionics system error. Fly with caution",
    "es": "Error del sistema de aviónica. Vuela con cuidado",
    "fr": "Erreur des systèmes avioniques. Pilotez avec précaution",
    "ja": "航空電子システムエラー。慎重に飛行してください",
    "ko": "항공전자 시스템 오류. 비행 시 주의 필요",
    "ru": "Ошибка авиационной электронной системы. Будьте внимательны при полете",
    "tr": "Havacılık Elektroniği sistem hatası. Dikkatli uçurun",
    "zh": "相机音频进程异常"
  },
  "fpv_tip_0x19000023": {
    "ar": "",
    "de": "",
    "en": "相机麦克风进程异常奔溃",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "相机麦克风进程异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000024": {
    "ar": "",
    "de": "",
    "en": "相机进程异常崩溃",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "相机进程异常崩溃",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000025": {
    "ar": "",
    "de": "",
    "en": "相机进程发生crash，请拉日志分析",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "相机进程发生crash，请拉日志分析",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000026": {
    "ar": "",
    "de": "",
    "en": "相机进程发生crash，请拉日志分析",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "相机进程发生crash，请拉日志分析",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000027": {
    "ar": "",
    "de": "",
    "en": "相机进程发生crash，请拉日志分析",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "相机进程发生crash，请拉日志分析",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000028": {
    "ar": "",
    "de": "",
    "en": "嵌入式dji_sys进程异常崩溃",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "嵌入式dji_sys进程异常崩溃",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000029": {
    "ar": "",
    "de": "",
    "en": "嵌入式日志进程异常崩溃",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "嵌入式日志进程异常崩溃",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900002A": {
    "ar": "",
    "de": "",
    "en": "嵌入式图传进程异常崩溃",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "嵌入式图传进程异常崩溃",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900002B": {
    "ar": "",
    "de": "",
    "en": "嵌入式进程异常崩溃",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "嵌入式进程异常崩溃",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900002C": {
    "ar": "",
    "de": "",
    "en": "嵌入式进程dji_gateway异常崩溃",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "嵌入式进程dji_gateway异常崩溃",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900002D": {
    "ar": "",
    "de": "",
    "en": "进程发生crash，请拉日志分析",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "进程发生crash，请拉日志分析",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900002E": {
    "ar": "",
    "de": "",
    "en": "进程发生crash，请拉日志分析",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "进程发生crash，请拉日志分析",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900002F": {
    "ar": "",
    "de": "",
    "en": "相机进程发生crash，请拉日志分析",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "相机进程发生crash，请拉日志分析",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000030": {
    "ar": "",
    "de": "",
    "en": "飞行系统感知进程异常崩溃",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "飞行系统感知进程异常崩溃",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000031": {
    "de": "Abheben nicht möglich. Daten löschen und Fluggerät neu starten",
    "en": "Unable to take off. Clear data and restart aircraft",
    "es": "No se puede despegar. Borra los datos y reinicia la aeronave",
    "fr": "Décollage impossible. Supprimez les données et redémarrez l'appareil",
    "ja": "離陸できません。データを消去して、機体を再起動してください",
    "ko": "이륙 불가. 데이터 삭제 및 기체 재시작",
    "pt": "",
    "ru": "Невозможно выполнить взлет. Удалите данные и перезапустите дрон",
    "tr": "Kalkış yapılamıyor. Verileri temizle ve aracı yeniden başlat",
    "zh": "无法起飞：数据清除完成后需重启飞行器"
  },
  "fpv_tip_0x19000031_in_the_sky": {
    "de": "Flugzeug nach dem Löschen der Daten neu starten",
    "en": "Restart aircraft after clearing data",
    "es": "Reinicia la aeronave tras borrar los datos",
    "fr": "Redémarrez l'appareil après la suppression des données",
    "ja": "データ消去後、機体を再起動してください",
    "ko": "데이터 삭제 후 기체 재시작",
    "pt": "",
    "ru": "Перезапустите дрон после удаления данных",
    "tr": "Verileri temizledikten sonra aracı yeniden başlatın",
    "zh": "数据清除完成后需重启飞行器"
  },
  "fpv_tip_0x19000032": {
    "ar": "",
    "de": "",
    "en": "飞行系统进程异常崩溃",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "飞行系统进程异常崩溃",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000033": {
    "ar": "",
    "de": "",
    "en": "飞行系统rtk_fusion进程异常崩溃",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "飞行系统rtk_fusion进程异常崩溃",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000034": {
    "ar": "",
    "de": "",
    "en": "CRobot进程异常崩溃",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "CRobot进程异常崩溃",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000035": {
    "ar": "",
    "de": "",
    "en": "进程发生crash，请拉日志分析",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "进程发生crash，请拉日志分析",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000036": {
    "ar": "",
    "de": "",
    "en": "机器学习进程异常崩溃",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "机器学习进程异常崩溃",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000037": {
    "ar": "",
    "de": "",
    "en": "进程发生crash，请拉日志分析",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "进程发生crash，请拉日志分析",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000038": {
    "ar": "",
    "de": "",
    "en": "进程发生crash，请拉日志分析",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "进程发生crash，请拉日志分析",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000039": {
    "ar": "",
    "de": "",
    "en": "电池进程异常崩溃",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "电池进程异常崩溃",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900003A": {
    "ar": "",
    "de": "",
    "en": "进程发生crash，请拉日志分析",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "进程发生crash，请拉日志分析",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900003B": {
    "ar": "",
    "de": "",
    "en": "进程发生crash，请拉日志分析",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "进程发生crash，请拉日志分析",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900003C": {
    "ar": "",
    "de": "",
    "en": "激光雷达进程进程异常崩溃",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "激光雷达进程进程异常崩溃",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900003D": {
    "ar": "",
    "de": "",
    "en": "进程发生crash，请拉日志分析",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "进程发生crash，请拉日志分析",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900003E": {
    "ar": "",
    "de": "",
    "en": "System panic",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "系统panic",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900003F": {
    "ar": "",
    "de": "",
    "en": "进程异常崩溃",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "进程异常崩溃",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000041": {
    "de": "USB-Verbindung erkannt. Kann nicht abheben. USB-Kabel trennen und Fluggerät neu starten",
    "en": "USB connection detected. Unable to take off. Disconnect USB cable and restart aircraft",
    "es": "Conexión USB detectada. No se puede despegar. Desconecta el cable USB y reinicia la aeronave",
    "fr": "Connexion USB détectée. Décollage impossible. Déconnectez le câble USB et redémarrez l\\'appareil",
    "ja": "USB接続を検出。離陸できません。USBケーブルを取り外し、機体を再起動してください",
    "ko": "USB 연결 감지됨. 이륙 불가. USB 케이블 연결 해제 후 기체 재시작 필요",
    "pt": "",
    "ru": "Обнаружено USB-соединение. Взлет невозможен. Отключите USB-кабель и перезапустите дрон",
    "tr": "USB bağlantısı tespit edildi. Kalkış yapamıyor. USB kablosunu çıkarın ve aracı yeniden başlatın",
    "zh": "无法起飞：连接USB阻飞，请拔下USB后重启飞行器尝试恢复"
  },
  "fpv_tip_0x19000041_in_the_sky": {
    "de": "USB-Verbindung erkannt. Vorsichtig fliegen und sofort landen",
    "en": "USB connection detected. Fly with caution and land promptly",
    "es": "Conexión USB detectada. Vuela con precaución y aterriza lo antes posible",
    "fr": "Connexion USB détectée. Volez avec précaution et atterrissez rapidement",
    "ja": "USB接続を検出。慎重に飛行し、直ちに着陸してください",
    "ko": "USB 연결 감지됨. 비행 시 주의 필요. 즉시 착륙 필요",
    "pt": "",
    "ru": "Обнаружено USB-соединение. Будьте осторожны в полете и приземлитесь как можно скорее",
    "tr": "USB bağlantısı tespit edildi. Dikkatli uçurun ve düzgün iniş yapın",
    "zh": "连接USB，请谨慎飞行并尽快降落"
  },
  "fpv_tip_0x19000042": {
    "de": "Abheben nicht möglich. Daten löschen und Fluggerät neu starten",
    "en": "Unable to take off. Clear data and restart aircraft",
    "es": "No se puede despegar. Borra los datos y reinicia la aeronave",
    "fr": "Décollage impossible. Supprimez les données et redémarrez l'appareil",
    "ja": "離陸できません。データを消去して、機体を再起動してください",
    "ko": "이륙 불가. 데이터 삭제 및 기체 재시작",
    "pt": "",
    "ru": "Невозможно выполнить взлет. Удалите данные и перезапустите дрон",
    "tr": "Kalkış yapılamıyor. Verileri temizle ve aracı yeniden başlat",
    "zh": "无法起飞：清除数据阻飞，请数据清除完成后重启飞行器"
  },
  "fpv_tip_0x19000042_in_the_sky": {
    "de": "Flugzeug nach dem Löschen der Daten neu starten",
    "en": "Restart aircraft after clearing data",
    "es": "Reinicia la aeronave tras borrar los datos",
    "fr": "Redémarrez l'appareil après la suppression des données",
    "ja": "データ消去後、機体を再起動してください",
    "ko": "데이터 삭제 후 기체 재시작",
    "pt": "",
    "ru": "Перезапустите дрон после удаления данных",
    "tr": "Verileri temizledikten sonra aracı yeniden başlatın",
    "zh": "清除数据中，请谨慎飞行并尽快降落"
  },
  "fpv_tip_0x19000051": {
    "de": "Firmware-Aktualisierung. Kann nicht abheben. Warten, bis das Update abgeschlossen ist",
    "en": "Firmware updating. Unable to take off. Wait for update to complete",
    "es": "Actualizando firmware. No se puede despegar. Espera a que finalice la actualización",
    "fr": "Mise à jour du firmware en cours. Décollage impossible. Attendez la fin de la mise à jour",
    "ja": "ファームウェア更新中。離陸できません。更新が完了するのをお待ちください",
    "ko": "펌웨어 업데이트 중. 이륙 불가. 완료될 때까지 기다려야 함",
    "pt": "",
    "ru": "Обновление прошивки. Взлет невозможен. Дождитесь завершения обновления",
    "tr": "Yazılım güncelleniyor. Kalkış yapamıyor. Güncellemenin tamamlanmasını bekleyin",
    "zh": "无法起飞：固件升级中，请等待固件升级完成"
  },
  "fpv_tip_0x19000051_in_the_sky": {
    "de": "Firmware-Aktualisierung. Vorsicht fliegen und sofort landen",
    "en": "Firmware updating. Fly with caution and land promptly",
    "es": "Actualizando firmware. Vuela con precaución y aterriza lo antes posible",
    "fr": "Mise à jour du firmware en cours. Volez avec précaution et atterrissez rapidement",
    "ja": "ファームウェア更新中。慎重に飛行し、直ちに着陸してください",
    "ko": "펌웨어 업데이트 중. 비행 시 주의 및 즉시 착륙 필요",
    "pt": "",
    "ru": "Обновление прошивки. Будьте осторожны в полете и приземлитесь как можно скорее",
    "tr": "Yazılım güncelleniyor. Dikkatli uçurun ve düzgün iniş yapın",
    "zh": "固件升级中，请谨慎飞行并尽快降落"
  },
  "fpv_tip_0x19000060": {
    "ar": "",
    "de": "Fehler beim Prüfen der Selinux-Berechtigung",
    "en": "Selinux permission check error",
    "es": "Error de comprobación de permisos de Selinux",
    "fr": "Erreur de vérification de l’autorisation Selinux",
    "id": "",
    "it": "",
    "ja": "Selinux権限チェックエラー",
    "ko": "Selinux 권한 확인 오류",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Ошибка проверки разрешений Selinux",
    "th": "",
    "tr": "Selinux izin kontrolü hatası",
    "ug": "",
    "vi": "",
    "zh": "Selinux 权限检查错误",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000061": {
    "ar": "",
    "de": "",
    "en": "Selinux permission check errors",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "Selinux 权限检查错误",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000070": {
    "de": "Dateisystemfehler. Datei ist möglicherweise beschädigt oder Speicherkarte wurde möglicherweise entfernt. Speicherkarte entnehmen und wieder einsetzen oder Speicherkarte formatieren",
    "en": "File system error. File may be damaged or storage card may be removed. Remove and insert storage card or format storage card",
    "es": "Error de sistema de archivos. Es posible que el archivo esté dañado o que se haya extraído la tarjeta de almacenamiento. Retira e inserta la tarjeta de almacenamiento o formatea la tarjeta de almacenamiento",
    "fr": "Erreur système du fichier. Le fichier peut être endommagé ou la carte de stockage peut être retirée. Retirez et insérez la carte de stockage ou formatez la carte de stockage",
    "ja": "ファイルシステムエラーです。ファイルが破損しているか、ストレージカードが取り出されている可能性があります。ストレージカードを取り出して挿入するか、ストレージカードをフォーマットします",
    "ko": "파일 시스템 오류. 파일이 손상되었거나 저장 장치 카드를 제거할 수 있습니다. 저장 장치 카드를 제거했다가 삽입하거나 포맷하세요",
    "ru": "Ошибка файловой системы. Возможно, файл поврежден или карта памяти извлечена. Извлеките и вставьте карту памяти или отформатируйте карту памяти",
    "tr": "Dosya sistem hatası. Dosya zarar görebilir veya depolama kartı çıkarılabilir. Depolama kartını çıkarıp takın veya depolama kartını biçimlendirin",
    "zh": "文件系统异常:文件可能损坏或存储卡异常拔出，建议尝试拔插存储卡或格式化"
  },
  "fpv_tip_0x190003ED": {
    "ar": "",
    "de": "",
    "en": "dji_wlm_slave cpu loading超限",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "dji_wlm_slave cpu loading超限",
    "zh-Hant": ""
  },
  "fpv_tip_0x190003EE": {
    "ar": "",
    "de": "",
    "en": "logd loading 超限",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "logd loading 超限",
    "zh-Hant": ""
  },
  "fpv_tip_0x190003EF": {
    "ar": "",
    "de": "",
    "en": "dji_autoflight cpu loading 超限",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "dji_autoflight cpu loading 超限",
    "zh-Hant": ""
  },
  "fpv_tip_0x190003F1": {
    "ar": "",
    "de": "",
    "en": "dji_sdrs_agent cpu loading 超限",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "dji_sdrs_agent cpu loading 超限",
    "zh-Hant": ""
  },
  "fpv_tip_0x190003F2": {
    "ar": "",
    "de": "",
    "en": "dji_lte cpu loading超限",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "dji_lte cpu loading超限",
    "zh-Hant": ""
  },
  "fpv_tip_0x190003F3": {
    "ar": "",
    "de": "",
    "en": "dji_wlm cpu loading超限",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "dji_wlm cpu loading超限",
    "zh-Hant": ""
  },
  "fpv_tip_0x190003F5": {
    "ar": "",
    "de": "",
    "en": "dji_nn_server loading超限",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "dji_nn_server loading超限",
    "zh-Hant": ""
  },
  "fpv_tip_0x190003F6": {
    "ar": "",
    "de": "",
    "en": "dji_ml cpu loading超限",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "dji_ml cpu loading超限",
    "zh-Hant": ""
  },
  "fpv_tip_0x190003F9": {
    "ar": "",
    "de": "",
    "en": "dji_media_server cpu loading 超限",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "dji_media_server cpu loading 超限",
    "zh-Hant": ""
  },
  "fpv_tip_0x190003FB": {
    "ar": "",
    "de": "",
    "en": "dji_rcam cpu loading  超限",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "dji_rcam cpu loading  超限",
    "zh-Hant": ""
  },
  "fpv_tip_0x190003FC": {
    "ar": "",
    "de": "",
    "en": "dji_camera cpu loading超限",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "dji_camera cpu loading超限",
    "zh-Hant": ""
  },
  "fpv_tip_0x190003FD": {
    "ar": "",
    "de": "",
    "en": "dji_perception cpu loading超限",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "dji_perception cpu loading超限",
    "zh-Hant": ""
  },
  "fpv_tip_0x190003FE": {
    "ar": "",
    "de": "",
    "en": "dji_blackbox loading超限",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "dji_blackbox loading超限",
    "zh-Hant": ""
  },
  "fpv_tip_0x190003FF": {
    "ar": "",
    "de": "",
    "en": "dji_sys loading超限",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "dji_sys loading超限",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000400": {
    "ar": "",
    "de": "",
    "en": "SD异常",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "SD异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000700": {
    "de": "Dateisystemfehler. Datei ist möglicherweise beschädigt oder Speicherkarte wurde möglicherweise entfernt. Speicherkarte entnehmen und wieder einsetzen oder Speicherkarte formatieren",
    "en": "File system error. File may be damaged or storage card may be removed. Remove and insert storage card or format storage card",
    "es": "Error de sistema de archivos. Es posible que el archivo esté dañado o que se haya extraído la tarjeta de almacenamiento. Retira e inserta la tarjeta de almacenamiento o formatea la tarjeta de almacenamiento",
    "fr": "File system error. File may be damaged or storage card may be removed. Remove and insert storage card or format storage card",
    "ja": "ファイルシステムエラーです。ファイルが破損しているか、ストレージカードが取り出されている可能性があります。ストレージカードを取り出して挿入するか、ストレージカードをフォーマットします",
    "ko": "파일 시스템 오류. 파일이 손상되었거나 저장 장치 카드를 제거할 수 있습니다. 저장 장치 카드를 제거했다가 삽입하거나 포맷하세요",
    "ru": "Ошибка файловой системы. Возможно, файл поврежден или карта памяти извлечена. Извлеките и вставьте карту памяти или отформатируйте карту памяти",
    "tr": "Dosya sistem hatası. Dosya zarar görebilir veya depolama kartı çıkarılabilir. Depolama kartını çıkarıp takın veya depolama kartını biçimlendirin",
    "zh": "文件系统异常:文件可能损坏或存储卡异常拔出，建议尝试拔插存储卡或格式化"
  },
  "fpv_tip_0x19000701": {
    "ar": "",
    "de": "",
    "en": "磁盘扫描异常",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "磁盘扫描异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000702": {
    "ar": "",
    "de": "",
    "en": "File system error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "文件系统检查异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000703": {
    "ar": "",
    "de": "",
    "en": "SD card not recommended. User experience may be\r\n affected",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "SD卡为非推荐卡，可能会影响使用体验",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000800": {
    "ar": "",
    "de": "Fehler des Fluggerätlüfters %index. Überprüfen, ob der Lüfter blockiert ist",
    "en": "Aircraft fan %index error. Check whether fan is stalled",
    "es": "Error %index del ventilador de la aeronave. Compruebe si el ventilador está atascado",
    "fr": "Erreur %index sur le ventilateur de l’appareil. Vérifiez si le ventilateur est bloqué",
    "id": "",
    "it": "",
    "ja": "機体ファン%indexエラー。ファンが停止していないかを確認してください",
    "ko": "기체 팬 %index 오류. 팬이 멈췄는지 확인하세요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Ошибка %index вентилятора дрона. Проверьте, не заклинило ли вентилятор",
    "th": "",
    "tr": "Hava aracı fanı %index hatası. Fanın arızalanarak durup durmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "机身%index号风扇异常，请检查飞行器风扇是否有堵转",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000801": {
    "ar": "",
    "de": "Fehler des Kameralüfters. Überprüfen, ob der Lüfter blockiert ist",
    "en": "Camera fan error. Check whether fan is stalled",
    "es": "Error del ventilador de la cámara. Compruebe si el ventilador está atascado",
    "fr": "Erreur sur le ventilateur de la caméra. Vérifiez si le ventilateur est bloqué",
    "id": "",
    "it": "",
    "ja": "カメラファンエラー。ファンが停止していないかを確認してください",
    "ko": "카메라 팬 오류. 팬이 멈췄는지 확인하세요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Ошибка вентилятора камеры. Проверьте, не заклинило ли вентилятор",
    "th": "",
    "tr": "Kamera fanı hatası. Fanın arızalanarak durup durmadığını kontrol edin",
    "ug": "",
    "vi": "",
    "zh": "相机风扇异常，请检查相机风扇是否有堵转",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000802": {
    "de": "Der Kameraprozessor ist überhitzt. Den Kameraprozessor vor Gebrauch abkühlen lassen",
    "en": "Camera processor overheated. Wait for camera processor to cool down before use",
    "es": "Procesador de la cámara sobrecalentado. Espera a que el procesador de la cámara se enfríe antes de usarlo",
    "fr": "Surchauffe du processeur de la caméra. Attendez que le processeur de la caméra refroidisse avant utilisation",
    "ja": "カメラプロセッサー高温。使用する前にカメラプロセッサーが冷えるのを待ってください",
    "ko": "카메라 프로세서 과열. 카메라 프로세서가 식을 때까지 기다렸다가 사용하세요",
    "ru": "Перегрев процессора камеры. Дождитесь охлаждения процессора камеры перед использованием",
    "tr": "Kamera işlemcisi aşırı ısındı. Kullanmadan önce kamera işlemcisinin soğumasını bekleyin",
    "zh": "相机芯片温度过高，请待冷却后使用"
  },
  "fpv_tip_0x19000803": {
    "de": "Kamera überhitzt. Die Aufnahme wird bald gestoppt. Die Kamera vor Gebrauch abkühlen lassen",
    "en": "Camera overheated. Recording will stop soon. Wait for camera to cool down before use",
    "es": "Cámara sobrecalentada. La grabación se detendrá pronto. Espera a que la cámara se enfríe antes de usarla",
    "fr": "Surchauffe caméra. L’enregistrement va bientôt s’arrêter. Attendez que la caméra refroidisse avant utilisation",
    "ja": "カメラ高温。撮影はすぐに停止します。使用する前にカメラが冷えるのを待ってください",
    "ko": "카메라 과열. 녹화가 곧 중단됩니다. 카메라가 식을 때까지 기다렸다가 사용하세요",
    "ru": "Камера перегрета. Запись скоро остановится. Дождитесь охлаждения камеры перед использованием",
    "tr": "Kamera aşırı ısındı. Kayıt yakında duracak. Kullanmadan önce kameranın soğumasını bekleyin",
    "zh": "相机温度过高，将无法录制，请待冷却后使用"
  },
  "fpv_tip_0x19000810": {
    "ar": "",
    "de": "",
    "en": "Aircraft in low-power state",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "飞机进入低功耗模式",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000811": {
    "ar": "",
    "de": "",
    "en": "Air unit in low-power state",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "air unit处于低功耗状态",
    "zh-Hant": ""
  },
  "fpv_tip_0x19000C00": {
    "en": "Connection error between avionics board and radio frequency board. Downward radar and video transmission performance may be affected",
    "zh": "航电板与射频板连接异常，可能影响下雷达与图传"
  },
  "fpv_tip_0x1900E001": {
    "ar": "",
    "de": "",
    "en": "Log storage space insufficient. Unable to store logs",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "日志空间不足，避免无法存储日志",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900E002": {
    "ar": "",
    "de": "",
    "en": "Log data missing",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "日志数据丢失",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900E003": {
    "ar": "",
    "de": "",
    "en": "Log data missing",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "日志数据丢失",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900E004": {
    "ar": "",
    "de": "",
    "en": "Storage device mounting error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "存储设备挂载异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1900F001": {
    "ar": "",
    "de": "",
    "en": "Goggles only support motion controller",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "眼镜只支持穿越摇杆飞行",
    "zh-Hant": ""
  },
  "fpv_tip_0x19018001": {
    "ar": "",
    "de": "",
    "en": "System in factory mode",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "系统处于工厂模式",
    "zh-Hant": ""
  },
  "fpv_tip_0x1901C080": {
    "de": "Das aktuelle Fluggerät kann nicht zeitgleich mit den beiden Geräten der Zenmuse L2- und H30-Serie betrieben werden. Bitte nur eines der Geräte verwenden",
    "en": "Current aircraft does not support working with both Zenmuse L2 and H30 series. Use one of the payloads separately",
    "es": "La aeronave no admite las series Zenmuse L2 y H30 al mismo tiempo. Usa una de las cargas útiles por separado",
    "fr": "L’appareil actuel ne prend pas en charge le fonctionnement avec les gammes Zenmuse L2 et H30 en même temps. Utilisez l’une des nacelles-caméras séparément",
    "ja": "現在の機体では、Zenmuse L2およびH30シリーズの併用はサポートされていません。いずれかのペイロードを個別に使用してください",
    "ko": "현재 기체는 Zenmuse L2 및 H30 시리즈에 대한 작업을 지원하지 않습니다. 페이로드 중 하나를 별도로 사용하세요",
    "ru": "Текущий дрон не поддерживает одновременную работу с сериями Zenmuse L2 и H30. Используйте одну из полезных нагрузок отдельно",
    "tr": "Mevcut hava aracı hem Zenmuse L2 hem de H30 serisiyle çalışmayı desteklemiyor. Yüklerden birini ayrı olarak kullanın",
    "zh": "当前飞行器不支持H30系列和L2同时工作，请单独挂载使用"
  },
  "fpv_tip_0x1901C081": {
    "de": "Das aktuelle Fluggerät kann nicht zeitgleich mit mehr als einem Gerät der Zenmuse H30-Serie betrieben werden. Bitte nur eines der Geräte verwenden",
    "en": "Current aircraft does not support working with more than one Zenmuse H30 series payload. Use one of the payloads separately",
    "es": "La aeronave no admite más de una carga útil de la serie Zenmuse H30. Usa una de las cargas útiles por separado",
    "fr": "L’appareil actuel ne prend pas en charge le fonctionnement avec plus d’une nacelle-caméra de la gamme Zenmuse H30. Utilisez l’une des nacelles-caméras séparément",
    "ja": "現在の機体では、複数のZenmuse H30シリーズペイロードの併用はサポートされていません。いずれかのペイロードを個別に使用してください",
    "ko": "현재 기체는 2개 이상의 Zenmuse H30 시리즈 페이로드로 작업할 수 없습니다. 페이로드 중 하나를 별도로 사용하세요",
    "ru": "Текущий дрон не поддерживает работу с более чем одной полезной нагрузкой серии Zenmuse H30. Используйте одну из полезных нагрузок отдельно",
    "tr": "Mevcut hava aracı, birden fazla Zenmuse H30 serisi yük ile çalışmayı desteklemiyor. Yüklerden birini ayrı olarak kullanın",
    "zh": "当前飞行器不支持多个H30系列同时工作，请单独挂载使用"
  },
  "fpv_tip_0x1901C082": {
    "de": "Das aktuelle Fluggerät kann nicht zeitgleich mit mehr als einem Gerät der Zenmuse L2-Serie betrieben werden. Bitte nur eines der Geräte verwenden",
    "en": "Current aircraft does not support working with more than one Zenmuse L2 payload. Use one of the payloads separately",
    "es": "La aeronave no admite más de una carga útil de Zenmuse L2. Usa una de las cargas útiles por separado",
    "fr": "L’appareil actuel ne prend pas en charge le fonctionnement avec plus d’une nacelle-caméra Zenmuse L2. Utilisez l’une des nacelles-caméras séparément",
    "ja": "現在の機体では、複数のZenmuse L2ペイロードの併用はサポートされていません。いずれかのペイロードを個別に使用してください",
    "ko": "현재 기체는 2개 이상의 Zenmuse L2 페이로드로 작업할 수 없습니다. 페이로드 중 하나를 별도로 사용하세요",
    "ru": "Текущий дрон не поддерживает работу с более чем одной полезной нагрузкой Zenmuse L2. Используйте одну из полезных нагрузок отдельно",
    "tr": "Mevcut hava aracı, birden fazla Zenmuse L2 yük ile çalışmayı desteklemiyor. Yüklerden birini ayrı olarak kullanın",
    "zh": "当前飞行器不支持多个L2同时工作，请单独挂载使用"
  },
  "fpv_tip_0x19100041": {
    "de": "Livestream-Netzwerkverbindung nicht verfügbar",
    "en": "Livestream network connection unavailable",
    "es": "Conexión de red de retransmisión en directo no disponible",
    "fr": "Connexion au réseau de diffusion en direct indisponible",
    "ja": "ライブ配信ネットワークに接続できません",
    "ko": "라이브 네트워크 연결 불가",
    "ru": "Подключение к прямой трансляции недоступно",
    "tr": "Canlı yayın ağ bağlantısı yok",
    "zh": "视频直播网络不通"
  },
  "fpv_tip_0x19100041_in_the_sky": {
    "de": "Keine Livestream-Netzwerkverbindung",
    "en": "No livestream network connection",
    "es": "No hay conexión de red de retransmisión en directo",
    "fr": "Pas de connexion réseau pour diffusion en direct",
    "ja": "ライブ配信ネットワーク接続がありません",
    "ko": "라이브 스트림 네트워크 연결 없음",
    "ru": "Отсутствует подключение к сети для трансляции",
    "tr": "Canlı yayın ağ bağlantısı yok",
    "zh": "视频直播网络不通"
  },
  "fpv_tip_0x19100042": {
    "de": "Passwortfehler bei Livestream-Registrierung",
    "en": "Livestream registration password error",
    "es": "Error de contraseña de registro de retransmisión en directo",
    "fr": "Erreur de mot de passe de connexion à la diffusion en direct",
    "ja": "ライブ配信登録パスワードエラー",
    "ko": "라이브 등록 비밀번호 오류",
    "ru": "Ошибка пароля прямой трансляции",
    "tr": "Canlı yayın kayıt şifresi hatası",
    "zh": "视频直播注册密码错误"
  },
  "fpv_tip_0x19100042_in_the_sky": {
    "de": "Falsches Livestream-Registrierungspasswort",
    "en": "Incorrect livestream registration password",
    "es": "Contraseña de registro de retransmisión en directo incorrecta",
    "fr": "Mot de passe d'enregistrement de diffusion en direct incorrect",
    "ja": "ライブ配信の登録パスワードが正しくありません",
    "ko": "라이브 스트림 등록 비밀번호 잘못됨",
    "ru": "Неверный пароль для регистрации на трансляцию",
    "tr": "Canlı yayın kayıt şifresi yanlış",
    "zh": "视频直播注册密码错误"
  },
  "fpv_tip_0x19100043": {
    "de": "Zeitüberschreitung bei Livestream-Registrierung",
    "en": "Livestream registration timed out",
    "es": "Se agotó el tiempo de espera de registro de retransmisión en directo",
    "fr": "Délai d'inscription à la diffusion en direct expiré",
    "ja": "ライブ配信登録 時間切れ",
    "ko": "라이브 등록 시간 초과",
    "ru": "Время регистрации прямой трансляции",
    "tr": "Canlı yayın kaydı zaman aşımına uğradı",
    "zh": "视频直播注册超时"
  },
  "fpv_tip_0x19100043_in_the_sky": {
    "de": "Zeitüberschreitung bei Livestream-Registrierung",
    "en": "Livestream registration timed out",
    "es": "Se agotó el tiempo de espera de registro de retransmisión en directo",
    "fr": "L'enregistrement de diffusion en direct a expiré",
    "ja": "ライブ配信の登録がタイムアウトしました",
    "ko": "라이브 스트림 등록 시간 초과됨",
    "ru": "Время регистрации на трансляцию истекло",
    "tr": "Canlı yayın kaydı zaman aşımına uğradı",
    "zh": "视频直播注册超时"
  },
  "fpv_tip_0x19100044": {
    "de": "Verbindung mit Livestream-Kanal %d fehlgeschlagen",
    "en": "Livestream channel %d connection failed",
    "es": "Error de conexión del canal de retransmisión en directo %d",
    "fr": "Connexion au canal %d de diffusion en direct échouée",
    "ja": "ライブ配信チャンネル%d 接続失敗",
    "ko": "라이브 채널 %d 연결 실패",
    "ru": "Не удалось подключиться к каналу прямой трансляции %d",
    "tr": "Canlı yayın kanalı %d bağlantısı başarısız",
    "zh": "直播通道%d连接失败"
  },
  "fpv_tip_0x19100044_in_the_sky": {
    "de": "Verbindung zum Livestream-Kanal fehlgeschlagen %d",
    "en": "Failed to connect to livestream channel %d",
    "es": "Error de conexión al canal de retransmisión en directo %d",
    "fr": "Échec de la connexion au canal de diffusion en direct%d",
    "ja": "ライブ配信チャンネル%dに接続できませんでした",
    "ko": "라이브 스트림 채널 %d에 연결 실패",
    "ru": "Не удалось подключиться к каналу прямой трансляции %d",
    "tr": "%d canlı yayın kanalına bağlanılamadı",
    "zh": "直播通道%d连接失败"
  },
  "fpv_tip_0x19100045": {
    "de": "Fehlerhafte URL für Livestream-Kanal %d",
    "en": "Livestream channel %d URL parameter format error",
    "es": "Error de formato del parámetro de URL del canal de retransmisión en directo %d",
    "fr": "Erreur de format de paramètres URL %d du canal de diffusion en direct",
    "ja": "ライブ配信チャンネル%d URLパラメーター形式エラー",
    "ko": "라이브 채널 %d URL 매개변수 형식 오류",
    "ru": "Ошибка формата URL-адреса канала прямой трансляции %d",
    "tr": "Canlı yayın kanalı %d URL parametresi format hatası",
    "zh": "直播通道%d URL参数格式错误"
  },
  "fpv_tip_0x19100045_in_the_sky": {
    "de": "Livestream-Kanal %d Fehler: URL-Parameterformat",
    "en": "Livestream channel %d URL parameter format error",
    "es": "Error de formato del parámetro de URL del canal de retransmisión en directo %d",
    "fr": "Canal de diffusion en direct%d Erreur de format de paramètre d'URL",
    "ja": "ライブ配信チャンネル%dのURLパラメーターのフォーマットエラー",
    "ko": "라이브 스트림 채널 %d URL 매개변수 포맷 오류",
    "ru": "Ошибка формата параметра URL канала прямой трансляции %d",
    "tr": "%d canlı yayın kanalı URL parametresi biçim hatası",
    "zh": "直播通道%d URL参数格式错误"
  },
  "fpv_tip_0x19100051": {
    "de": "Zeitüberschreitung bei Auswahl von Livestream-Kanal %d",
    "en": "Selecting livestream channel %d timed out",
    "es": "Se agotó el tiempo de espera de selección del canal de retransmisión en directo %d",
    "fr": "Délai de sélection du canal de diffusion en direct %d expiré",
    "ja": "ライブ配信チャンネル%dの選択 時間切れ",
    "ko": "라이브 채널 %d 선택 시간 초과",
    "ru": "Истекло время ожидания %d при выборе канала прямой трансляции",
    "tr": "Canlı yayın kanalı %d seçimi zaman aşımına uğradı",
    "zh": "直播通道%d点播超时"
  },
  "fpv_tip_0x19100051_in_the_sky": {
    "de": "Livestream-Kanal %d Zeitüberschreitung",
    "en": "Livestream channel %d timed out",
    "es": "Se agotó el tiempo de espera %d del canal de retransmisión en directo",
    "fr": "Canal de diffusion en direct%d arrivé à expiration",
    "ja": "ライブ配信チャンネル%dがタイムアウトしました",
    "ko": "라이브 스트림 채널 %d 시간 초과됨",
    "ru": "Превышение времени ожидания канала прямой трансляции %d",
    "tr": "%d canlı yayın kanalı zaman aşımına uğradı",
    "zh": "直播通道%d点播超时"
  },
  "fpv_tip_0x19100052": {
    "de": "Auswahl des Livestream-Kanals %d. Parameterfehler",
    "en": "Selecting livestream channel %d. Parameters error",
    "es": "Parámetros erróneos al seleccionar el canal de retransmisión en directo %d",
    "fr": "Erreur de sélection des paramètres %d du canal de diffusion en direct",
    "ja": "ライブ配信チャンネル%dの選択 パラメーターエラー",
    "ko": "라이브 채널 %d 선택. 매개변수 오류",
    "ru": "Выбор канала прямой трансляции %d. Ошибка параметров",
    "tr": "Canlı yayın kanalı %d seçiliyor. Parametre hatası",
    "zh": "直播通道%d点播参数错误"
  },
  "fpv_tip_0x19100052_in_the_sky": {
    "de": "Livestream-Kanal %d Parameterfehler",
    "en": "Livestream channel %d parameter error",
    "es": "Error del parámetro del canal de retransmisión en directo %d",
    "fr": "Canal de diffusion en direct %d Erreur de paramètre",
    "ja": "ライブ配信チャンネル%dのパラメーターのエラー",
    "ko": "라이브 스트림 채널 %d 매개변수 오류",
    "ru": "Ошибка параметра канала прямой трансляции %d",
    "tr": "%d canlı yayın kanalı parametre hatası",
    "zh": "直播通道%d点播参数错误"
  },
  "fpv_tip_0x19100053": {
    "de": "Keine Antwort bei Auswahl von Livestream-Kanal %d",
    "en": "Selecting livestream channel %d received no response",
    "es": "No se ha recibido respuesta al seleccionar el canal de retransmisión en directo %d",
    "fr": "Aucune réponse de la sélection du canal %d de diffusion en direct",
    "ja": "ライブ配信チャンネル%dの選択 反応なし",
    "ko": "라이브 채널 %d 선택 응답 없음",
    "ru": "Выбор канала прямой трансляции %d не получил ответа",
    "tr": "Canlı yayın kanalı %d seçimi için yanıt alınamadı",
    "zh": "直播通道%d点播未响应"
  },
  "fpv_tip_0x19100053_in_the_sky": {
    "de": "Livestream-Kanal %d Zeitüberschreitung",
    "en": "Livestream channel %d timed out",
    "es": "Se agotó el tiempo de espera %d del canal de retransmisión en directo",
    "fr": "Canal de diffusion en direct%d arrivé à expiration",
    "ja": "ライブ配信チャンネル%dがタイムアウトしました",
    "ko": "라이브 스트림 채널 %d 시간 초과됨",
    "ru": "Превышение времени ожидания канала прямой трансляции %d",
    "tr": "%d canlı yayın kanalı zaman aşımına uğradı",
    "zh": "直播通道%d点播未响应"
  },
  "fpv_tip_0x19100054": {
    "de": "删除",
    "en": "删除",
    "es": "删除",
    "fr": "删除",
    "ja": "删除",
    "ko": "删除",
    "ru": "删除",
    "tr": "删除",
    "zh": "直播通道%d长时间未有点播"
  },
  "fpv_tip_0x19100054_in_the_sky": {
    "de": "Livestream-Kanal %d Zeitüberschreitung",
    "en": "Livestream channel %d timed out",
    "es": "Se agotó el tiempo de espera %d del canal de retransmisión en directo",
    "fr": "Canal de diffusion en direct%d arrivé à expiration",
    "ja": "ライブ配信チャンネル%dがタイムアウトしました",
    "ko": "라이브 스트림 채널 %d 시간 초과됨",
    "ru": "Превышение времени ожидания канала прямой трансляции %d",
    "tr": "%d canlı yayın kanalı zaman aşımına uğradı",
    "zh": "直播通道%d长时间未有点播"
  },
  "fpv_tip_0x19100055": {
    "de": "Livestream-Kanal %d ausgewählt. Keine Streaming-Daten",
    "en": "Livestream channel %d successfully selected. No streaming data",
    "es": "Canal de retransmisión en directo %d seleccionado correctamente. No hay datos de streaming",
    "fr": "Canal de diffusion en direct %d sélectionné. Aucune donnée de diffusion",
    "ja": "ライブ配信チャンネル%d選択済。配信データがありません",
    "ko": "라이브 채널 %d 선택 성공. 스트림 데이터 없음",
    "ru": "Канал прямой трансляции %d успешно выбран. Нет потоковых данных",
    "tr": "Canlı yayın kanalı %d başarıyla seçildi. Canlı yayın verisi yok",
    "zh": "直播通道%d点播成功，无视频流数据"
  },
  "fpv_tip_0x19100055_in_the_sky": {
    "de": "Livestream-Kanal %d verbunden. Noch keine Streaming-Daten",
    "en": "Livestream channel %d connected. No streaming data yet",
    "es": "Canal de retransmisión en directo %d conectado. Aún no hay datos de streaming",
    "fr": "Canal de diffusion en direct %d connecté. Pas encore de données de streaming",
    "ja": "ライブ配信チャンネル%dに接続しました。配信データはまだありません",
    "ko": "라이브 스트림 채널 %d 연결됨. 아직 스트리밍 데이터가 없습니다.",
    "ru": "Канал прямой трансляции %d подключен. Отсутствуют потоковые данные.",
    "tr": "%d canlı yayın kanalı bağlı. Henüz canlı yayın verisi yok",
    "zh": "直播通道%d点播成功，但未有视频流发送"
  },
  "fpv_tip_0x19100056": {
    "de": "Bitratenfehler bei Livestream-Kanal %d",
    "en": "Livestream channel %d bit rate error",
    "es": "Error de tasa de bits del canal de retransmisión en directo %d",
    "fr": "Erreur de débit binaire du canal %d de diffusion en direct",
    "ja": "ライブ配信チャンネル%d ビットレート エラー",
    "ko": "라이브 채널 %d 비트전송률 오류",
    "ru": "Ошибка скорости передачи %d канала прямой трансляции",
    "tr": "Canlı yayın kanalı %d bit hızı hatası",
    "zh": "直播通道%d直播码率异常"
  },
  "fpv_tip_0x19100056_in_the_sky": {
    "de": "Livestream-Kanal %d Bit-Ratenfehler",
    "en": "Livestream channel %d bit rate error",
    "es": "Error de tasa de bits del canal de retransmisión en directo %d",
    "fr": "Canal de diffusion en direct %d Erreur de débit",
    "ja": "ライブ配信チャンネル%dのビットレートエラー",
    "ko": "라이브 스트림 채널 %d 비트 속도 오류",
    "ru": "Ошибка скорости передачи канала прямой трансляции %d",
    "tr": "%d canlı yayın kanalı bit hızı hatası",
    "zh": "直播通道%d直播码率过大或者过小"
  },
  "fpv_tip_0x19100057": {
    "de": "Bildratenfehler bei Livestream-Kanal %d",
    "en": "Livestream channel %d frame rate error",
    "es": "Error de ratio de fotogramas del canal de retransmisión en directo %d",
    "fr": "Erreur de taux de rafraîchissement du canal %d de diffusion en direct",
    "ja": "ライブ配信チャンネル%d フレームレート エラー",
    "ko": "라이브 채널 %d 프레임 속도 오류",
    "ru": "Ошибка частоты кадров %d канала прямой трансляции",
    "tr": "Canlı yayın kanalı %d kare hızı hatası",
    "zh": "直播通道%d直播帧率异常"
  },
  "fpv_tip_0x19100057_in_the_sky": {
    "de": "Livestream-Kanal %d Bildratenfehler",
    "en": "Livestream channel %d frame rate error",
    "es": "Error de ratio de fotogramas del canal de retransmisión en directo %d",
    "fr": "Canal de diffusion en direct %d Erreur de taux de rafraichissement",
    "ja": "ライブ配信チャンネル%dのフレームレートエラー",
    "ko": "라이브 스트림 채널 %d 프레임 속도 오류",
    "ru": "Ошибка частоты кадров канала прямой трансляции %d",
    "tr": "%d canli yayın kanalı kare hızı hatası",
    "zh": "直播通道%d直播帧率过大或者过小"
  },
  "fpv_tip_0x19100058": {
    "de": "Fehler beim Senden der Daten von Livestream-Kanal %d",
    "en": "Livestream channel %d data sending error",
    "es": "Error de envío de datos del canal de retransmisión en directo %d",
    "fr": "Erreur d'envoi de données du canal %d de diffusion en direct",
    "ja": "ライブ配信チャンネル%d データ送信エラー",
    "ko": "라이브 채널 %d 데이터 전송 오류",
    "ru": "Ошибка отправки данных канала прямой трансляции %d",
    "tr": "Canlı yayın kanalı %d veri gönderme hatası",
    "zh": "直播通道%d视频数据发送失败"
  },
  "fpv_tip_0x19100058_in_the_sky": {
    "de": "Daten des Livestream-Kanals %d konnten nicht gesendet werden",
    "en": "Failed to send livestream channel %d data",
    "es": "Error al enviar los datos del canal de retransmisión en directo %d",
    "fr": "Échec de l'envoi des données du canal de diffusion %d en direct",
    "ja": "ライブ配信チャンネル%dのデータの送信に失敗しました",
    "ko": "라이브 스트림 채널 %d 데이터 전송 실패",
    "ru": "Не удалось отправить данные канала прямой трансляции %d",
    "tr": "%d canlı yayın kanalı verileri gönderilemedi",
    "zh": "直播通道%d视频数据发送失败"
  },
  "fpv_tip_0x19100071": {
    "de": "Datenpaket für Livestream-Kanal %d verloren",
    "en": "Livestream channel %d losing data packet",
    "es": "Pérdida de paquete de datos en el canal de retransmisión en directo %d",
    "fr": "Perte de paquet de données du canal %d de diffusion en direct",
    "ja": "ライブ配信チャンネル%d データパケットロス発生中",
    "ko": "라이브 채널 %d 유실 데이터 패킷",
    "ru": "Потеря пакета даных канала передачи %d",
    "tr": "Canlı yayın kanalı %d veri paketi kaybı",
    "zh": "直播通道%d网络持续丢包"
  },
  "fpv_tip_0x19100071_in_the_sky": {
    "de": "Livestream-Kanal %d Paketverlust",
    "en": "Livestream channel %d packet loss",
    "es": "Pérdida de paquete en el canal de retransmisión en directo %d",
    "fr": "Perte de paquets du canal de diffusion %d en direct",
    "ja": "ライブ配信チャンネル%dのパケット損失",
    "ko": "라이브 스트림 채널 %d 패킷 손실",
    "ru": "Потеря пакетов канала прямой трансляции %d",
    "tr": "%d canlı yayın kanalı paket kaybı",
    "zh": "直播通道%d网络持续丢包"
  },
  "fpv_tip_0x19100072": {
    "de": "Hohe Netzwerklatenz bei Livestream-Kanal %d",
    "en": "Livestream channel %d experiencing high network latency",
    "es": "Alta latencia de red en el canal de retransmisión en directo %d",
    "fr": "Forte latence réseau du canal %d de diffusion en direct",
    "ja": "ライブ配信チャンネル%dでネットワークの高遅延 発生中",
    "ko": "라이브 채널 %d 네트워크 지연율 높음",
    "ru": "Большая задержка сети канала прямой трансляции %d",
    "tr": "Canlı yayın kanalı %d yüksek oranda ağ gecikmesi yaşıyor",
    "zh": "直播通道%d网络延迟大"
  },
  "fpv_tip_0x19100072_in_the_sky": {
    "de": "Netzwerklatenz auf Livestream-Kanal %d",
    "en": "Network latency on livestream channel %d",
    "es": "Latencia de red en el canal de retransmisión en directo %d",
    "fr": "Latence du réseau sur le canal de diffusion en direct %d",
    "ja": "ライブ配信チャネル%dのネットワーク遅延",
    "ko": "라이브 스트림 채널 %d의 네트워크 지연율",
    "ru": "Сетевая задержка канала прямой трансляции %d",
    "tr": "%d canlı yayın kanalında ağ gecikmesi",
    "zh": "直播通道%d网络延迟大"
  },
  "fpv_tip_0x19100073": {
    "de": "Hohe Netzwerkschwankungen bei Livestream-Kanal %d",
    "en": "Livestream channel %d experiencing high network jitter",
    "es": "Alta inestabilidad de red en el canal de retransmisión en directo %d",
    "fr": "Forte gigue réseau du canal %d de diffusion en direct",
    "ja": "ライブ配信チャンネル%dで大きなネットワークジッタ発生中",
    "ko": "라이브 채널 %d 네트워크 지터 높음",
    "ru": "Высокий джиттер сети канала прямой трансляции %d",
    "tr": "Canlı yayın kanalı %d yüksek oranda ağ sapması yaşıyor",
    "zh": "直播通道%d网络抖动明显"
  },
  "fpv_tip_0x19100073_in_the_sky": {
    "de": "Netzwerk-Jitter auf Livestream-Kanal %d",
    "en": "Network jitter on livestream channel %d",
    "es": "Alta inestabilidad de red en el canal de retransmisión en directo %d",
    "fr": "Instabilité du réseau sur le canal de diffusion en direct %d",
    "ja": "ライブ配信チャネル%dのネットワークジッター",
    "ko": "라이브 스트림 채널 %d의 네트워크 지터",
    "ru": "Джиттер сети на канале прямой трансляции %d",
    "tr": "%d canlı yayın kanalında ağ sapması",
    "zh": "直播通道%d网络抖动明显"
  },
  "fpv_tip_0x19100074": {
    "de": "Netzwerküberlastung bei Livestream-Kanal %d",
    "en": "Livestream channel %d experiencing network congestion",
    "es": "Congestión de red en el canal de retransmisión en directo %d",
    "fr": "Congestion du réseau du canal %d de diffusion en direct",
    "ja": "ライブ配信チャンネル%d ネットワーク輻輳",
    "ko": "라이브 채널 %d 네트워크 정체 심함",
    "ru": "Перегрузка сети канала прямой трансляции %d",
    "tr": "Canlı yayın kanalı %d yüksek oranda ağ trafiği yaşıyor",
    "zh": "直播通道%d出现网络拥塞"
  },
  "fpv_tip_0x19100074_in_the_sky": {
    "de": "Netzwerküberlastung auf Livestream-Kanal %d",
    "en": "Network congestion on livestream channel %d",
    "es": "Congestión de red en el canal de retransmisión en directo %d",
    "fr": "Congestion du réseau sur le canal de diffusion en direct %d",
    "ja": "ライブ配信チャネル%dのネットワーク輻輳",
    "ko": "라이브 스트림 채널 %d의 네트워크 정체",
    "ru": "Перегрузка сети на канале прямой трансляции %d",
    "tr": "%d canlı yayın kanalında yüksek oranda ağ trafiği",
    "zh": "直播通道%d出现网络拥塞"
  },
  "fpv_tip_0x19100081": {
    "de": "Fehler bei der Übertragung von Livestream-Metadaten",
    "en": "Livestream metadata transfer frequency error",
    "es": "Error de frecuencia de transferencia de metadatos de retransmisión en directo",
    "fr": "Erreur de fréquence de transfert des métadonnées de la diffusion en direct",
    "ja": "ライブ配信メタデータ伝送周波数エラー",
    "ko": "라이브 메타데이터 전송 주파수 오류",
    "ru": "Ошибка частоты передачи метаданных трансляции",
    "tr": "Canlı yayın meta veri aktarım frekansı hatası",
    "zh": "视频直播扩展数据回传频率异常"
  },
  "fpv_tip_0x19100081_in_the_sky": {
    "de": "Fehler: Übertragungsfrequenz der Livestream-Metadaten",
    "en": "Livestream metadata transfer frequency error",
    "es": "Error de frecuencia de transferencia de metadatos de retransmisión en directo",
    "fr": "Erreur de fréquence lors du transfert de métadonnées pour la diffusion en direct",
    "ja": "ライブ配信メタデータの転送頻度エラー",
    "ko": "라이브 스트림 메타데이터 전송 주파수 오류",
    "ru": "Ошибка частоты передачи метаданных прямой трансляции",
    "tr": "Canlı yayın meta veri aktarım frekansı hatası",
    "zh": "视频直播扩展数据回传频率异常"
  },
  "fpv_tip_0x19100082": {
    "de": "Livestream kann keine Metadaten abrufen",
    "en": "Livestream unable to get metadata",
    "es": "La retransmisión en directo no puede obtener los metadatos",
    "fr": "Impossible d'obtenir les métadonnéees de la diffusion en direct",
    "ja": "ライブ配信がメタデータを取得できません",
    "ko": "라이브 메타데이터 가져올 수 없음",
    "ru": "Ошибка получения метаданных трансляции",
    "tr": "Canlı yayın meta verileri alamıyor",
    "zh": "视频直播未收到扩展数据"
  },
  "fpv_tip_0x19100082_in_the_sky": {
    "de": "Metadaten konnten nicht gesendet werden",
    "en": "Failed to send metadata",
    "es": "Error al enviar los metadatos",
    "fr": "Échec de l'envoi des métadonnées",
    "ja": "メタデータの送信に失敗しました",
    "ko": "메타데이터 전송 실패",
    "ru": "Не удалось отправить метаданные",
    "tr": "Meta veri gönderilemedi",
    "zh": "视频直播未收到扩展数据"
  },
  "fpv_tip_0x19100083": {
    "de": "Livestream kann den Standort des Fluggeräts nicht ermitteln",
    "en": "Livestream unable to get aircraft location",
    "es": "La retransmisión en directo no puede obtener la ubicación de la aeronave",
    "fr": "Impossible d'obtenir la localisation de l'appareil pendant la diffusion en direct",
    "ja": "ライブ配信が機体位置を取得できません",
    "ko": "라이브 기체 위치 가져올 수 없음",
    "ru": "Невозможно определить местоположение дрона во время прямой трансляции",
    "tr": "Canlı yayın araç konumunu alamıyor",
    "zh": "视频直播无法获取无人机位置"
  },
  "fpv_tip_0x19100083_in_the_sky": {
    "de": "Informationen zur Position des Fluggeräts konnten nicht gesendet werden",
    "en": "Failed to send aircraft location info",
    "es": "Error al enviar la información de ubicación de la aeronave",
    "fr": "Échec de l'envoi des informations de localisation de l'appareil",
    "ja": "機体の位置情報の送信に失敗しました",
    "ko": "기체 위치 정보 전송 실패",
    "ru": "Не удалось отправить информацию о местоположении дрона",
    "tr": "Araç konum bilgileri gönderilemedi",
    "zh": "视频直播无法获取无人机位置"
  },
  "fpv_tip_0x19117042": {
    "en": "",
    "zh": "飞机充电板未进入充电"
  },
  "fpv_tip_0x19117100": {
    "en": "",
    "zh": "电池无法加热保温"
  },
  "fpv_tip_0x19117121": {
    "en": "",
    "zh": "电池未进入保温状态"
  },
  "fpv_tip_0x19117200": {
    "en": "",
    "zh": "飞机开机发生故障"
  },
  "fpv_tip_0x19117300": {
    "en": "",
    "zh": "飞机关机失败"
  },
  "fpv_tip_0x19117420": {
    "en": "",
    "zh": "飞机电池温度过低，不能工作"
  },
  "fpv_tip_0x19117440": {
    "en": "",
    "zh": "飞机电池超45度，不能工作"
  },
  "fpv_tip_0x19200001": {
    "de": "CPU-Auslastung des Nutzlastsystems zu hoch. Fluggerät neu starten",
    "en": "Payload system CPU usage too high. Restart aircraft to restore",
    "es": "Uso de la CPU del sistema del instrumento demasiado alto. Reinicia la aeronave para restaurar",
    "fr": "Utilisation CPU du système de charge utile trop élevée. Redémarrez l'appareil pour restaurer",
    "ja": "ペイロードシステムのCPU使用率が高すぎます。機体を再起動してリストアしてください",
    "ko": "페이로드 시스템 CPU 사용량 높음. 기체 재시작해 복원",
    "ru": "Слишком высокая загрузка CPU системы полезной нагрузки. Перезагрузите дрон для восстановления",
    "tr": "Yük sistem CPU kullanımı çok yüksek. Geri yüklemek için aracı yeniden başlatın",
    "zh": "负载系统CPU负载高，请重启飞行器尝试恢复"
  },
  "fpv_tip_0x19200001_in_the_sky": {
    "de": "CPU-Auslastung des Nutzlastsystems zu hoch. Kamerafunktion kann beeinträchtigt sein",
    "en": "Payload system CPU usage too high. Camera tasks may be affected",
    "es": "Uso de la CPU del sistema del instrumento demasiado alto. Las tareas de cámara pueden verse afectadas",
    "fr": "Utilisation CPU du système de charge utile trop élevée. Les tâches de la caméra peuvent en être impactées",
    "ja": "ペイロードシステムのCPU使用率が高すぎます。カメラのタスクが影響を受ける可能性があります",
    "ko": "페이로드 시스템 CPU 사용량 높음. 카메라 작업에 영향 줄 수 있음",
    "ru": "Слишком высокая загрузка CPU системы полезной нагрузки. Это может повлиять на работу камеры",
    "tr": "Yük sistem CPU kullanımı çok yüksek. Kamera görevleri etkilenebilir",
    "zh": "负载系统CPU负载高，可能影响作业"
  },
  "fpv_tip_0x19200011": {
    "de": "Nutzlast-Systemspeicher fast voll ausgelastet. Fluggerät neu starten",
    "en": "Payload system memory low. Restart aircraft to restore",
    "es": "Sistema del instrumento con poca memoria. Reinicia la aeronave para restaurar",
    "fr": "Mémoire du système de charge utile faible. Redémarrez l'appareil pour restaurer",
    "ja": "ペイロードシステムがメモリ不足。機体を再起動してリストアしてください",
    "ko": "페이로드 시스템 메모리 부족. 기체 재시작해 복원",
    "ru": "Недостаточно системной памяти полезной нагрузки. Перезагрузите дрон для восстановления",
    "tr": "Yük sistem belleği düşük. Geri yüklemek için aracı yeniden başlatın",
    "zh": "负载系统内存低，请重启飞行器尝试恢复"
  },
  "fpv_tip_0x19200011_in_the_sky": {
    "de": "Speicher des Nutzlastsystems fast voll. Kamerafunktion kann beeinträchtigt sein",
    "en": "Payload system low on memory. Camera tasks may be affected",
    "es": "Sistema del instrumento con poca memoria. Las tareas de cámara pueden verse afectadas",
    "fr": "Mémoire du système de charge utile faible. Les tâches de la caméra peuvent en être impactées",
    "ja": "ペイロードシステムがメモリ不足。カメラのタスクが影響を受ける可能性があります",
    "ko": "페이로드 시스템 메모리 부족. 카메라 작업에 영향 줄 수 있음",
    "ru": "Системе полезной нагрузки не хватает памяти. Это может повлиять на работу камеры",
    "tr": "Yük sistem belleği düşük. Kamera görevleri etkilenebilir",
    "zh": "负载系统内存低，可能影响作业"
  },
  "fpv_tip_0x19200021": {
    "de": "Nutzlast-Systemfehler. Fluggerät neu starten",
    "en": "Payload system error. Restart aircraft to restore",
    "es": "Error del sistema del instrumento. Reinicia la aeronave para restaurar",
    "fr": "Erreur du système de charge utile. Redémarrez l'appareil pour restaurer",
    "ja": "ペイロード システムエラー。機体を再起動してリストアしてください",
    "ko": "페이로드 시스템 오류. 기체 재시작해 복원",
    "ru": "Ошибка системы полезной нагрузки. Перезапустите дрон для восстановления",
    "tr": "Yük sistem hatası. Geri yüklemek için aracı yeniden başlatın",
    "zh": "负载系统异常，请重启飞行器尝试恢复"
  },
  "fpv_tip_0x19200021_in_the_sky": {
    "de": "Nutzlast-Systemfehler. Kamerafunktion kann beeinträchtigt sein",
    "en": "Payload system error. Camera tasks may be affected",
    "es": "Error del sistema del instrumento. Las tareas de cámara pueden verse afectadas",
    "fr": "Erreur du système de charge utile. Les tâches de la caméra peuvent en être impactées",
    "ja": "ペイロード システムエラー。カメラのタスクが影響を受ける可能性があります",
    "ko": "페이로드 시스템 오류. 카메라 작업에 영향 줄 수 있음",
    "ru": "Ошибка системы полезной нагрузки. Это может повлиять на работу камеры",
    "tr": "Yük sistem hatası. Kamera görevleri etkilenebilir",
    "zh": "负载系统异常，可能影响作业"
  },
  "fpv_tip_0x1A010004": {
    "en": "",
    "zh": "视觉NN降噪异常(视觉降噪)"
  },
  "fpv_tip_0x1A010007": {
    "de": "System im Werksmodus. DJI Support kontaktieren",
    "en": "System in factory mode. Contact DJI Support",
    "es": "Sistema en modo de fábrica. Ponte en contacto con la Asistencia técnica de DJI",
    "fr": "Système en mode usine. Contactez le service client DJI",
    "ja": "システムが出荷時の初期モードですDJIサポートまでお問い合わせください",
    "ko": "시스템이 공장 모드에 있습니다. DJI 고객지원에 문의하세요.",
    "ru": "Система в заводском режиме. Обратитесь в службу поддержки DJI",
    "tr": "Sistem, fabrika modunda. DJI Destek birimiyle iletişime geçin",
    "zh": "当前处于工厂模式，请联系售后维修"
  },
  "fpv_tip_0x1A010040": {
    "de": "Verbindungsfehler des unteren (links) Sichtsensors. Fluggerät neu starten",
    "en": "Bottom (left) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual inferior (izquierdo). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision inférieur (gauche). Redémarrez l'appareil",
    "ja": "下部（左）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "하단(좌측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения нижнего (левого) видеодатчика. Перезапустите дрон",
    "tr": "Alt (sol) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "下视左侧视觉传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A010040_in_the_sky": {
    "de": "Verbindungsfehler des unteren (links) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen (%alarmid)",
    "en": "Bottom (left) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)",
    "es": "Error de conexión del sensor visual inferior (izquierdo). Vuela con cuidado Regresa al punto de origen o aterriza (%alarmid)",
    "fr": "Erreur de connexion de capteur de vision inférieur (gauche). Pilotez avec précaution. Revenez au point de départ ou atterrissez (%alarmid)",
    "ja": "下部（左）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください(%alarmid)",
    "ko": "하단(좌측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요 (%alarmid)",
    "ru": "Ошибка подключения нижнего (левого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь (%alarmid)",
    "tr": "Alt (sol) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın (%alarmid)",
    "zh": "下视左侧视觉传感器连接异常,请谨慎飞行，返航或降落（%alarmid）"
  },
  "fpv_tip_0x1A010041": {
    "de": "Verbindungsfehler des unteren (rechts) Sichtsensors. Fluggerät neu starten",
    "en": "Bottom (right) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual inferior (derecho). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision inférieur (droit). Redémarrez l'appareil",
    "ja": "下部（右）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "하단(우측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения нижнего (правого) видеодатчика. Перезапустите дрон",
    "tr": "Alt (sağ) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "下视右侧视觉传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A010041_in_the_sky": {
    "de": "Verbindungsfehler des unteren (rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen (%alarmid)",
    "en": "Bottom (right) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)",
    "es": "Error de conexión del sensor visual inferior (derecho). Vuela con cuidado Regresa al punto de origen o aterriza (%alarmid)",
    "fr": "Erreur de connexion de capteur de vision inférieur (droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez (%alarmid)",
    "ja": "下部（右）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください(%alarmid)",
    "ko": "하단(우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요 (%alarmid)",
    "ru": "Ошибка подключения нижнего (правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь (%alarmid)",
    "tr": "Alt (sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın (%alarmid)",
    "zh": "下视右侧视觉传感器连接异常,请谨慎飞行，返航或降落（%alarmid）"
  },
  "fpv_tip_0x1A010042": {
    "de": "Verbindungsfehler des vorderen (links) Sichtsensors. Fluggerät neu starten",
    "en": "Front (left) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual frontal (izquierdo). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision avant (gauche). Redémarrez l'appareil",
    "ja": "前方（左）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "전면(좌측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения переднего (левого) видеодатчика. Перезапустите дрон",
    "tr": "Ön (sol) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "前视左侧视觉传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A010042_in_the_sky": {
    "de": "Verbindungsfehler des vorderen (links) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen (%alarmid)",
    "en": "Front (left) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)",
    "es": "Error de conexión del sensor visual frontal (izquierdo). Vuela con cuidado Regresa al punto de origen o aterriza (%alarmid)",
    "fr": "Erreur de connexion de capteur de vision avant (gauche). Pilotez avec précaution. Revenez au point de départ ou atterrissez (%alarmid)",
    "ja": "前方（左）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください(%alarmid)",
    "ko": "전면(좌측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요 (%alarmid)",
    "ru": "Ошибка подключения переднего (левого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь (%alarmid)",
    "tr": "Ön (sol) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın (%alarmid)",
    "zh": "前视左侧视觉传感器连接异常,请谨慎飞行，返航或降落（%alarmid）"
  },
  "fpv_tip_0x1A010043": {
    "de": "Verbindungsfehler des vorderen (rechts) Sichtsensors. Fluggerät neu starten",
    "en": "Front (right) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual frontal (derecho). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision avant (droit). Redémarrez l'appareil",
    "ja": "前方（右）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "전면(우측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения переднего (правого) видеодатчика. Перезапустите дрон",
    "tr": "Ön (sağ) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "前视右侧视觉传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A010043_in_the_sky": {
    "de": "Verbindungsfehler des vorderen (rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen (%alarmid)",
    "en": "Front (right) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)",
    "es": "Error de conexión del sensor visual frontal (derecho). Vuela con cuidado Regresa al punto de origen o aterriza (%alarmid)",
    "fr": "Erreur de connexion de capteur de vision avant (droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez (%alarmid)",
    "ja": "前方（右）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください(%alarmid)",
    "ko": "전면(우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요 (%alarmid)",
    "ru": "Ошибка подключения переднего (правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь (%alarmid)",
    "tr": "Ön (sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın (%alarmid)",
    "zh": "前视右侧视觉传感器连接异常,请谨慎飞行，返航或降落（%alarmid）"
  },
  "fpv_tip_0x1A010044": {
    "de": "Verbindungsfehler des hinteren (links) Sichtsensors. Fluggerät neu starten",
    "en": "Back (left) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual trasero (izquierdo). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision arrière (gauche). Redémarrez l'appareil",
    "ja": "後方（左）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "후방(좌측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения заднего (левого) видеодатчика. Перезапустите дрон",
    "tr": "Arka (sol) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "后视左侧视觉传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A010044_in_the_sky": {
    "de": "Verbindungsfehler des hinteren (links) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen (%alarmid)",
    "en": "Back (left) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)",
    "es": "Error de conexión del sensor visual trasero (izquierdo). Vuela con cuidado Regresa al punto de origen o aterriza (%alarmid)",
    "fr": "Erreur de connexion de capteur de vision arrière (gauche). Pilotez avec précaution. Revenez au point de départ ou atterrissez (%alarmid)",
    "ja": "後方（左）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください(%alarmid)",
    "ko": "후방(좌측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요 (%alarmid)",
    "ru": "Ошибка подключения заднего (левого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь (%alarmid)",
    "tr": "Arka (sol) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın (%alarmid)",
    "zh": "后视左侧视觉传感器连接异常,请谨慎飞行，返航或降落（%alarmid）"
  },
  "fpv_tip_0x1A010045": {
    "de": "Verbindungsfehler des hinteren (rechts) Sichtsensors. Fluggerät neu starten",
    "en": "Back (right) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual trasero (derecho). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision arrière (droit). Redémarrez l'appareil",
    "ja": "後方（右）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "후방(우측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения заднего (правого) видеодатчика. Перезапустите дрон",
    "tr": "Arka (sağ) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "后视右侧视觉传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A010045_in_the_sky": {
    "de": "Verbindungsfehler des hinteren (rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen (%alarmid)",
    "en": "Back (right) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)",
    "es": "Error de conexión del sensor visual trasero (derecho). Vuela con cuidado Regresa al punto de origen o aterriza (%alarmid)",
    "fr": "Erreur de connexion de capteur de vision arrière (droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez (%alarmid)",
    "ja": "後方（右）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください(%alarmid)",
    "ko": "후방(우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요 (%alarmid)",
    "ru": "Ошибка подключения заднего (правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь (%alarmid)",
    "tr": "Arka (sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın (%alarmid)",
    "zh": "后视右侧视觉传感器连接异常,请谨慎飞行，返航或降落（%alarmid）"
  },
  "fpv_tip_0x1A010046": {
    "de": "Verbindungsfehler des oberen (links) Sichtsensors. Fluggerät neu starten",
    "en": "Top (left) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual superior (izquierdo). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision supérieur (gauche). Redémarrez l'appareil",
    "ja": "上部（左）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "상단(좌측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения верхнего (левого) видеодатчика. Перезапустите дрон",
    "tr": "Üst (sol) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "上视左侧视觉传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A010046_in_the_sky": {
    "de": "Verbindungsfehler des oberen (links) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen (%alarmid)",
    "en": "Top (left) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)",
    "es": "Error de conexión del sensor visual superior (izquierdo). Vuela con cuidado Regresa al punto de origen o aterriza (%alarmid)",
    "fr": "Erreur de connexion de capteur de vision supérieur (gauche). Pilotez avec précaution. Revenez au point de départ ou atterrissez (%alarmid)",
    "ja": "上部（左）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください(%alarmid)",
    "ko": "상단(좌측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요 (%alarmid)",
    "ru": "Ошибка подключения верхнего (левого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь (%alarmid)",
    "tr": "Üst (sol) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın (%alarmid)",
    "zh": "上视左侧视觉传感器连接异常,请谨慎飞行，返航或降落（%alarmid）"
  },
  "fpv_tip_0x1A010047": {
    "de": "Verbindungsfehler des oberen (rechts) Sichtsensors. Fluggerät neu starten",
    "en": "Top (right) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual superior (derecho). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision supérieur (droit). Redémarrez l'appareil",
    "ja": "上部（右）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "상단(우측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения верхнего (правого) видеодатчика. Перезапустите дрон",
    "tr": "Üst (sağ) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "上视右侧视觉传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A010047_in_the_sky": {
    "de": "Verbindungsfehler des oberen (rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen (%alarmid)",
    "en": "Top (right) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)",
    "es": "Error de conexión del sensor visual superior (derecho). Vuela con cuidado Regresa al punto de origen o aterriza (%alarmid)",
    "fr": "Erreur de connexion de capteur de vision supérieur (droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez (%alarmid)",
    "ja": "上部（右）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください(%alarmid)",
    "ko": "상단(우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요 (%alarmid)",
    "ru": "Ошибка подключения верхнего (правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь (%alarmid)",
    "tr": "Üst (sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın (%alarmid)",
    "zh": "上视右侧视觉传感器连接异常,请谨慎飞行，返航或降落（%alarmid）"
  },
  "fpv_tip_0x1A010048": {
    "de": "Verbindungsfehler des seitlichen (hinten links) Sichtsensors. Fluggerät neu starten",
    "en": "Side (back left) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual lateral (trasero izquierdo). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision latéral (arrière gauche). Redémarrez l'appareil",
    "ja": "側面（左後方）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "측면(후방 좌측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения бокового (заднего левого) видеодатчика. Перезапустите дрон",
    "tr": "Yan (arka sol) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "左视后部视觉传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A010048_in_the_sky": {
    "de": "Verbindungsfehler des seitlichen (hinten links) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen (%alarmid)",
    "en": "Side (back left) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)",
    "es": "Error de conexión del sensor visual lateral (trasero izquierdo). Vuela con cuidado Regresa al punto de origen o aterriza (%alarmid)",
    "fr": "Erreur de connexion de capteur de vision latéral (arrière gauche). Pilotez avec précaution. Revenez au point de départ ou atterrissez (%alarmid)",
    "ja": "側面（左後方）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください(%alarmid)",
    "ko": "측면(후방 좌측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요 (%alarmid)",
    "ru": "Ошибка подключения бокового (заднего левого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь (%alarmid)",
    "tr": "Yan (arka sol) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın (%alarmid)",
    "zh": "左视后部视觉传感器连接异常,请谨慎飞行，返航或降落（%alarmid）"
  },
  "fpv_tip_0x1A010049": {
    "de": "Verbindungsfehler des seitlichen (vorne links) Sichtsensors. Fluggerät neu starten",
    "en": "Side (front left) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual lateral (delantero izquierdo). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision latéral (avant gauche). Redémarrez l'appareil",
    "ja": "側面（左前方）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "측면(전면 좌측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения бокового (переднего левого) видеодатчика. Перезапустите дрон",
    "tr": "Yan (ön sol) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "左视前部视觉传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A010049_in_the_sky": {
    "de": "Verbindungsfehler des seitlichen (vorne links) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen (%alarmid)",
    "en": "Side (front left) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)",
    "es": "Error de conexión del sensor visual lateral (delantero izquierdo). Vuela con cuidado Regresa al punto de origen o aterriza (%alarmid)",
    "fr": "Erreur de connexion de capteur de vision latéral (avant gauche). Pilotez avec précaution. Revenez au point de départ ou atterrissez (%alarmid)",
    "ja": "側面（左前方）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください(%alarmid)",
    "ko": "측면(전면 좌측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요 (%alarmid)",
    "ru": "Ошибка подключения бокового (переднего левого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь (%alarmid)",
    "tr": "Yan (ön sol) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın (%alarmid)",
    "zh": "左视前部视觉传感器连接异常,请谨慎飞行，返航或降落（%alarmid）"
  },
  "fpv_tip_0x1A01004A": {
    "de": "Kommunikationsfehler des seitlichen (vorne rechts) Sichtsensors. Fluggerät neu starten",
    "en": "Side (front right) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual lateral (delantero derecho). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision latéral (avant droit). Redémarrez l'appareil",
    "ja": "側面（右前方）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "측면(전면 우측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения бокового (переднего правого) видеодатчика. Перезапустите дрон",
    "tr": "Yan (ön sağ) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "右视前部视觉传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A01004A_in_the_sky": {
    "de": "Kommunikationsfehler des seitlichen (vorne rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen (%alarmid)",
    "en": "Side (front right) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)",
    "es": "Error de conexión del sensor visual lateral (delantero derecho). Vuela con cuidado Regresa al punto de origen o aterriza (%alarmid)",
    "fr": "Erreur de connexion de capteur de vision latéral (avant droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez (%alarmid)",
    "ja": "側面（右前方）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください(%alarmid)",
    "ko": "측면(전면 우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요 (%alarmid)",
    "ru": "Ошибка подключения бокового (переднего правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь (%alarmid)",
    "tr": "Yan (ön sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın (%alarmid)",
    "zh": "右视前部视觉传感器连接异常,请谨慎飞行，返航或降落（%alarmid）"
  },
  "fpv_tip_0x1A01004B": {
    "de": "Verbindungsfehler des seitlichen (hinten rechts) Sichtsensors. Fluggerät neu starten",
    "en": "Side (back right) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual lateral (trasero derecho). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision latéral (arrière droit). Redémarrez l'appareil",
    "ja": "側面（右後方）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "측면(후방 우측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения бокового (заднего правого) видеодатчика. Перезапустите дрон",
    "tr": "Yan (arka sağ) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "右视后部视觉传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A01004B_in_the_sky": {
    "de": "Verbindungsfehler des seitlichen (hinten rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen (%alarmid)",
    "en": "Side (back right) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)",
    "es": "Error de conexión del sensor visual lateral (trasero derecho). Vuela con cuidado Regresa al punto de origen o aterriza (%alarmid)",
    "fr": "Erreur de connexion de capteur de vision latéral (arrière droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez (%alarmid)",
    "ja": "側面（右後方）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください(%alarmid)",
    "ko": "측면(후방 우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요 (%alarmid)",
    "ru": "Ошибка подключения бокового (заднего правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь (%alarmid)",
    "tr": "Yan (arka sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın (%alarmid)",
    "zh": "右视后部视觉传感器连接异常,请谨慎飞行，返航或降落（%alarmid）"
  },
  "fpv_tip_0x1A010080": {
    "de": "Verbindungsfehler des unteren (links) Sichtsensors. Fluggerät neu starten",
    "en": "Bottom (left) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual inferior (izquierdo). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision inférieur (gauche). Redémarrez l'appareil",
    "ja": "下部（左）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "하단(좌측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения нижнего (левого) видеодатчика. Перезапустите дрон",
    "tr": "Alt (sol) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "下视左侧视觉传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A010080_in_the_sky": {
    "de": "Verbindungsfehler des unteren (links) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Bottom (left) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual inferior (izquierdo). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision inférieur (gauche). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "下部（左）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "하단(좌측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения нижнего (левого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Alt (sol) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "下视左侧视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A010081": {
    "de": "Verbindungsfehler des unteren (rechts) Sichtsensors. Fluggerät neu starten",
    "en": "Bottom (right) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual inferior (derecho). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision inférieur (droit). Redémarrez l'appareil",
    "ja": "下部（右）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "하단(우측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения нижнего (правого) видеодатчика. Перезапустите дрон",
    "tr": "Alt (sağ) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "下视右侧视觉传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A010081_in_the_sky": {
    "de": "Verbindungsfehler des unteren (rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Bottom (right) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual inferior (derecho). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision inférieur (droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "下部（右）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "하단(우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения нижнего (правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Alt (sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "下视右侧视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A010082": {
    "de": "Verbindungsfehler des vorderen (links) Sichtsensors. Fluggerät neu starten",
    "en": "Front (left) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual frontal (izquierdo). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision avant (gauche). Redémarrez l'appareil",
    "ja": "前方（左）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "전면(좌측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения переднего (левого) видеодатчика. Перезапустите дрон",
    "tr": "Ön (sol) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "前视左侧视觉传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A010082_in_the_sky": {
    "de": "Verbindungsfehler des vorderen (links) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Front (left) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual frontal (izquierdo). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision avant (gauche). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "前方（左）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "전면(좌측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения переднего (левого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Ön (sol) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "前视左侧视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A010083": {
    "de": "Verbindungsfehler des vorderen (rechts) Sichtsensors. Fluggerät neu starten",
    "en": "Front (right) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual frontal (derecho). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision avant (droit). Redémarrez l'appareil",
    "ja": "前方（右）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "전면(우측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения переднего (правого) видеодатчика. Перезапустите дрон",
    "tr": "Ön (sağ) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "前视右侧视觉传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A010083_in_the_sky": {
    "de": "Verbindungsfehler des vorderen (rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Front (right) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual frontal (derecho). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision avant (droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "前方（右）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "전면(우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения переднего (правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Ön (sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "前视右侧视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A010084": {
    "de": "Verbindungsfehler des hinteren (links) Sichtsensors. Fluggerät neu starten",
    "en": "Back (left) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual trasero (izquierdo). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision arrière (gauche). Redémarrez l'appareil",
    "ja": "後方（左）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "후방(좌측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения заднего (левого) видеодатчика. Перезапустите дрон",
    "tr": "Arka (sol) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "后视左侧视觉传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A010084_in_the_sky": {
    "de": "Verbindungsfehler des hinteren (links) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Back (left) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual trasero (izquierdo). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision arrière (gauche). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "後方（左）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "후방(좌측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения заднего (левого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Arka (sol) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "后视左侧视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A010085": {
    "de": "Verbindungsfehler des hinteren (rechts) Sichtsensors. Fluggerät neu starten",
    "en": "Back (right) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual trasero (derecho). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision arrière (droit). Redémarrez l'appareil",
    "ja": "後方（右）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "후방(우측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения заднего (правого) видеодатчика. Перезапустите дрон",
    "tr": "Arka (sağ) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "后视右侧视觉传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A010085_in_the_sky": {
    "de": "Verbindungsfehler des hinteren (rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Back (right) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual trasero (derecho). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision arrière (droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "後方（右）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "후방(우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения заднего (правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Arka (sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "后视右侧视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A010086": {
    "de": "Verbindungsfehler des oberen (links) Sichtsensors. Fluggerät neu starten",
    "en": "Top (left) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual superior (izquierdo). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision supérieur (gauche). Redémarrez l'appareil",
    "ja": "上部（左）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "상단(좌측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения верхнего (левого) видеодатчика. Перезапустите дрон",
    "tr": "Üst (sol) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "上视左侧视觉传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A010086_in_the_sky": {
    "de": "Verbindungsfehler des oberen (links) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Top (left) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual superior (izquierdo). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision supérieur (gauche). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "上部（左）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "상단(좌측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения верхнего (левого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Üst (sol) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "上视左侧视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A010087": {
    "de": "Verbindungsfehler des oberen (rechts) Sichtsensors. Fluggerät neu starten",
    "en": "Top (right) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual superior (derecho). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision supérieur (droit). Redémarrez l'appareil",
    "ja": "上部（右）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "상단(우측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения верхнего (правого) видеодатчика. Перезапустите дрон",
    "tr": "Üst (sağ) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "上视右侧视觉传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A010087_in_the_sky": {
    "de": "Verbindungsfehler des oberen (rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Top (right) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual superior (derecho). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision supérieur (droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "上部（右）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "상단(우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения верхнего (правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Üst (sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "上视右侧视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A010088": {
    "de": "Verbindungsfehler des seitlichen (hinten links) Sichtsensors. Fluggerät neu starten",
    "en": "Side (back left) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual lateral (trasero izquierdo). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision latéral (arrière gauche). Redémarrez l'appareil",
    "ja": "側面（左後方）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "측면(후방 좌측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения бокового (заднего левого) видеодатчика. Перезапустите дрон",
    "tr": "Yan (arka sol) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "左视后部视觉传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A010088_in_the_sky": {
    "de": "Verbindungsfehler des seitlichen (hinten rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Side (back right) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual lateral (trasero derecho). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision latéral (arrière droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "側面（右後方）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "측면(후방 우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения бокового (заднего правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Yan (arka sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "右视后部视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A010089": {
    "de": "Verbindungsfehler des seitlichen (vorne links) Sichtsensors. Fluggerät neu starten",
    "en": "Side (front left) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual lateral (delantero izquierdo). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision latéral (avant gauche). Redémarrez l'appareil",
    "ja": "側面（左前方）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "측면(전면 좌측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения бокового (переднего левого) видеодатчика. Перезапустите дрон",
    "tr": "Yan (ön sol) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "左视前部视觉传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A010089_in_the_sky": {
    "de": "Verbindungsfehler des seitlichen (vorne links) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Side (front left) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual lateral (delantero izquierdo). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision latéral (avant gauche). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "側面（左前方）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "측면(전면 좌측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения бокового (переднего левого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Yan (ön sol) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "左视前部视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A01008A": {
    "de": "Kommunikationsfehler des seitlichen (vorne rechts) Sichtsensors. Fluggerät neu starten",
    "en": "Side (front right) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual lateral (delantero derecho). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision latéral (avant droit). Redémarrez l'appareil",
    "ja": "側面（右前方）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "측면(전면 우측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения бокового (переднего правого) видеодатчика. Перезапустите дрон",
    "tr": "Yan (ön sağ) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "右视前部视觉传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A01008A_in_the_sky": {
    "de": "Kommunikationsfehler des seitlichen (vorne rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Side (front right) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual lateral (delantero derecho). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision latéral (avant droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "側面（右前方）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "측면(전면 우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения бокового (переднего правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Yan (ön sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "右视前部视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A01008B": {
    "de": "Verbindungsfehler des seitlichen (hinten rechts) Sichtsensors. Fluggerät neu starten",
    "en": "Side (back right) vision sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor visual lateral (trasero derecho). Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur de vision latéral (arrière droit). Redémarrez l'appareil",
    "ja": "側面（右後方）ビジョンセンサー接続エラー。機体を再起動してください",
    "ko": "측면(후방 우측) 비전 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения бокового (заднего правого) видеодатчика. Перезапустите дрон",
    "tr": "Yan (arka sağ) görüş sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "右视后部视觉传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A01008B_in_the_sky": {
    "de": "Verbindungsfehler des seitlichen (hinten rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Side (back right) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual lateral (trasero derecho). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision latéral (arrière droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "側面（右後方）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "측면(후방 우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения бокового (заднего правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Yan (arka sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "右视后部视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A0100C0": {
    "de": "Verbindungsfehler des unteren (links) Sichtsensors. Sensor neu starten",
    "en": "Bottom (left) vision sensor connection error. Restart sensor",
    "es": "Error de conexión del sensor visual inferior (izquierdo). Reinicia el sensor",
    "fr": "Erreur de connexion de capteur de vision inférieur (gauche). Redémarrez le capteur",
    "ja": "下部（左）ビジョンセンサー接続エラー。センサーを再起動してください",
    "ko": "하단(좌측) 비전 센서 연결 오류. 센서 재시작",
    "ru": "Ошибка подключения нижнего (левого) видеодатчика. Перезапустите датчик",
    "tr": "Alt (sol) görüş sensörü bağlantı hatası. Sensörü yeniden başlatın",
    "zh": "下视左侧视觉传感器连接异常，请重启传感器"
  },
  "fpv_tip_0x1A0100C0_in_the_sky": {
    "de": "Verbindungsfehler des unteren (links) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Bottom (left) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual inferior (izquierdo). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision inférieur (gauche). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "下部（左）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "하단(좌측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения нижнего (левого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Alt (sol) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "下视左侧视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A0100C1": {
    "de": "Verbindungsfehler des unteren (rechts) Sichtsensors. Sensor neu starten",
    "en": "Bottom (right) vision sensor connection error. Restart sensor",
    "es": "Error de conexión del sensor visual inferior (derecho). Reinicia el sensor",
    "fr": "Erreur de connexion de capteur de vision inférieur (droit). Redémarrez le capteur",
    "ja": "下部（右）ビジョンセンサー接続エラー。センサーを再起動してください",
    "ko": "하단(우측) 비전 센서 연결 오류. 센서 재시작",
    "ru": "Ошибка подключения нижнего (правого) видеодатчика. Перезапустите датчик",
    "tr": "Alt (sağ) görüş sensörü bağlantı hatası. Sensörü yeniden başlatın",
    "zh": "下视右侧视觉传感器连接异常，请重启传感器"
  },
  "fpv_tip_0x1A0100C1_in_the_sky": {
    "de": "Verbindungsfehler des unteren (rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Bottom (right) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual inferior (derecho). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision inférieur (droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "下部（右）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "하단(우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения нижнего (правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Alt (sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "下视右侧视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A0100C2": {
    "de": "Verbindungsfehler des vorderen (links) Sichtsensors. Sensor neu starten",
    "en": "Front (left) vision sensor connection error. Restart sensor",
    "es": "Error de conexión del sensor visual frontal (izquierdo). Reinicia el sensor",
    "fr": "Erreur de connexion de capteur de vision avant (gauche). Redémarrez le capteur",
    "ja": "前方（左）ビジョンセンサー接続エラー。センサーを再起動してください",
    "ko": "전면(좌측) 비전 센서 연결 오류. 센서 재시작",
    "ru": "Ошибка подключения переднего (левого) видеодатчика. Перезапустите датчик",
    "tr": "Ön (sol) görüş sensörü bağlantı hatası. Sensörü yeniden başlatın",
    "zh": "前视左侧视觉传感器连接异常，请重启传感器"
  },
  "fpv_tip_0x1A0100C2_in_the_sky": {
    "de": "Verbindungsfehler des vorderen (links) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Front (left) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual frontal (izquierdo). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision avant (gauche). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "前方（左）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "전면(좌측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения переднего (левого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Ön (sol) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "前视左侧视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A0100C3": {
    "de": "Verbindungsfehler des vorderen (rechts) Sichtsensors. Sensor neu starten",
    "en": "Front (right) vision sensor connection error. Restart sensor",
    "es": "Error de conexión del sensor visual frontal (derecho). Reinicia el sensor",
    "fr": "Erreur de connexion de capteur de vision avant (droit). Redémarrez le capteur",
    "ja": "前方（右）ビジョンセンサー接続エラー。センサーを再起動してください",
    "ko": "전면(우측) 비전 센서 연결 오류. 센서 재시작",
    "ru": "Ошибка подключения переднего (правого) видеодатчика. Перезапустите датчик",
    "tr": "Ön (sağ) görüş sensörü bağlantı hatası. Sensörü yeniden başlatın",
    "zh": "前视右侧视觉传感器连接异常，请重启传感器"
  },
  "fpv_tip_0x1A0100C3_in_the_sky": {
    "de": "Verbindungsfehler des vorderen (rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Front (right) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual frontal (derecho). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision avant (droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "前方（右）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "전면(우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения переднего (правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Ön (sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "前视右侧视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A0100C4": {
    "de": "Verbindungsfehler des hinteren (links) Sichtsensors. Sensor neu starten",
    "en": "Back (left) vision sensor connection error. Restart sensor",
    "es": "Error de conexión del sensor visual trasero (izquierdo). Reinicia el sensor",
    "fr": "Erreur de connexion de capteur de vision arrière (gauche). Redémarrez le capteur",
    "ja": "後方（左）ビジョンセンサー接続エラー。センサーを再起動してください",
    "ko": "후방(좌측) 비전 센서 연결 오류. 센서 재시작",
    "ru": "Ошибка подключения заднего (левого) видеодатчика. Перезапустите датчик",
    "tr": "Arka (sol) görüş sensörü bağlantı hatası. Sensörü yeniden başlatın",
    "zh": "后视左侧视觉传感器连接异常，请重启传感器"
  },
  "fpv_tip_0x1A0100C4_in_the_sky": {
    "de": "Verbindungsfehler des hinteren (links) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Back (left) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual trasero (izquierdo). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision arrière (gauche). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "後方（左）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "후방(좌측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения заднего (левого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Arka (sol) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "后视左侧视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A0100C5": {
    "de": "Verbindungsfehler des hinteren (rechts) Sichtsensors. Sensor neu starten",
    "en": "Back (right) vision sensor connection error. Restart sensor",
    "es": "Error de conexión del sensor visual trasero (derecho). Reinicia el sensor",
    "fr": "Erreur de connexion de capteur de vision arrière (droit). Redémarrez le capteur",
    "ja": "後方（右）ビジョンセンサー接続エラー。センサーを再起動してください",
    "ko": "후방(우측) 비전 센서 연결 오류. 센서 재시작",
    "ru": "Ошибка подключения заднего (правого) видеодатчика. Перезапустите датчик",
    "tr": "Arka (sağ) görüş sensörü bağlantı hatası. Sensörü yeniden başlatın",
    "zh": "后视右侧视觉传感器连接异常，请重启传感器"
  },
  "fpv_tip_0x1A0100C5_in_the_sky": {
    "de": "Verbindungsfehler des hinteren (rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Back (right) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual trasero (derecho). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision arrière (droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "後方（右）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "후방(우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения заднего (правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Arka (sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "后视右侧视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A0100C6": {
    "de": "Verbindungsfehler des oberen (links) Sichtsensors. Sensor neu starten",
    "en": "Top (left) vision sensor connection error. Restart sensor",
    "es": "Error de conexión del sensor visual superior (izquierdo). Reinicia el sensor",
    "fr": "Erreur de connexion de capteur de vision supérieur (gauche). Redémarrez le capteur",
    "ja": "上部（左）ビジョンセンサー接続エラー。センサーを再起動してください",
    "ko": "상단(좌측) 비전 센서 연결 오류. 센서 재시작",
    "ru": "Ошибка подключения верхнего (левого) видеодатчика. Перезапустите датчик",
    "tr": "Üst (sol) görüş sensörü bağlantı hatası. Sensörü yeniden başlatın",
    "zh": "上视左侧视觉传感器连接异常，请重启传感器"
  },
  "fpv_tip_0x1A0100C6_in_the_sky": {
    "de": "Verbindungsfehler des oberen (links) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Top (left) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual superior (izquierdo). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision supérieur (gauche). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "上部（左）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "상단(좌측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения верхнего (левого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Üst (sol) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "上视左侧视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A0100C7": {
    "de": "Verbindungsfehler des oberen (rechts) Sichtsensors. Sensor neu starten",
    "en": "Top (right) vision sensor connection error. Restart sensor",
    "es": "Error de conexión del sensor visual superior (derecho). Reinicia el sensor",
    "fr": "Erreur de connexion de capteur de vision supérieur (droit). Redémarrez le capteur",
    "ja": "上部（右）ビジョンセンサー接続エラー。センサーを再起動してください",
    "ko": "상단(우측) 비전 센서 연결 오류. 센서 재시작",
    "ru": "Ошибка подключения верхнего (правого) видеодатчика. Перезапустите датчик",
    "tr": "Üst (sağ) görüş sensörü bağlantı hatası. Sensörü yeniden başlatın",
    "zh": "上视右侧视觉传感器连接异常，请重启传感器"
  },
  "fpv_tip_0x1A0100C7_in_the_sky": {
    "de": "Verbindungsfehler des hinteren (rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Back (right) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual trasero (derecho). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision arrière (droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "後方（右）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "후방(우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения заднего (правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Arka (sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "后视右侧视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A0100C8": {
    "de": "Verbindungsfehler des seitlichen (hinten links) Sichtsensors. Sensor neu starten",
    "en": "Side (back left) vision sensor connection error. Restart sensor",
    "es": "Error de conexión del sensor visual lateral (trasero izquierdo). Reinicia el sensor",
    "fr": "Erreur de connexion de capteur de vision latéral (arrière gauche). Redémarrez le capteur",
    "ja": "側面（左後方）ビジョンセンサー接続エラー。センサーを再起動してください",
    "ko": "측면(후방 좌측) 비전 센서 연결 오류. 센서 재시작",
    "ru": "Ошибка подключения бокового (заднего левого) видеодатчика. Перезапустите датчик",
    "tr": "Yan (arka sol) görüş sensörü bağlantı hatası. Sensörü yeniden başlatın",
    "zh": "左视后部视觉传感器连接异常，请重启传感器"
  },
  "fpv_tip_0x1A0100C8_in_the_sky": {
    "de": "Verbindungsfehler des seitlichen (hinten links) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Side (back left) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual lateral (trasero izquierdo). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision latéral (arrière gauche). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "側面（左後方）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "측면(후방 좌측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения бокового (заднего левого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Yan (arka sol) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "左视后部视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A0100C9": {
    "de": "Verbindungsfehler des seitlichen (vorne links) Sichtsensors. Sensor neu starten",
    "en": "Side (front left) vision sensor connection error. Restart sensor",
    "es": "Error de conexión del sensor visual lateral (delantero izquierdo). Reinicia el sensor",
    "fr": "Erreur de connexion de capteur de vision latéral (avant gauche). Redémarrez le capteur",
    "ja": "側面（左前方）ビジョンセンサー接続エラー。センサーを再起動してください",
    "ko": "측면(전면 좌측) 비전 센서 연결 오류. 센서 재시작",
    "ru": "Ошибка подключения бокового (переднего левого) видеодатчика. Перезапустите датчик",
    "tr": "Yan (ön sol) görüş sensörü bağlantı hatası. Sensörü yeniden başlatın",
    "zh": "左视前部视觉传感器连接异常，请重启传感器"
  },
  "fpv_tip_0x1A0100C9_in_the_sky": {
    "de": "Verbindungsfehler des seitlichen (vorne links) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Side (front left) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual lateral (delantero izquierdo). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision latéral (avant gauche). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "側面（左前方）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "측면(전면 좌측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения бокового (переднего левого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Yan (ön sol) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "左视前部视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A0100CA": {
    "de": "Kommunikationsfehler des seitlichen (vorne rechts) Sichtsensors. Sensor neu starten",
    "en": "Side (front right) vision sensor connection error. Restart sensor",
    "es": "Error de conexión del sensor visual lateral (delantero derecho). Reinicia el sensor",
    "fr": "Erreur de connexion de capteur de vision latéral (avant droit). Redémarrez le capteur",
    "ja": "側面（右前方）ビジョンセンサー接続エラー。センサーを再起動してください",
    "ko": "측면(전면 우측) 비전 센서 연결 오류. 센서 재시작",
    "ru": "Ошибка подключения бокового (переднего правого) видеодатчика. Перезапустите датчик",
    "tr": "Yan (ön sağ) görüş sensörü bağlantı hatası. Sensörü yeniden başlatın",
    "zh": "右视前部视觉传感器连接异常，请重启传感器"
  },
  "fpv_tip_0x1A0100CA_in_the_sky": {
    "de": "Kommunikationsfehler des seitlichen (vorne rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Side (front right) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual lateral (delantero derecho). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision latéral (avant droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "側面（右前方）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "측면(전면 우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения бокового (переднего правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Yan (ön sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "右视前部视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A0100CB": {
    "de": "Verbindungsfehler des seitlichen (hinten rechts) Sichtsensors. Sensor neu starten",
    "en": "Side (back right) vision sensor connection error. Restart sensor",
    "es": "Error de conexión del sensor visual lateral (trasero derecho). Reinicia el sensor",
    "fr": "Erreur de connexion de capteur de vision latéral (arrière droit). Redémarrez le capteur",
    "ja": "側面（右後方）ビジョンセンサー接続エラー。センサーを再起動してください",
    "ko": "측면(후방 우측) 비전 센서 연결 오류. 센서 재시작",
    "ru": "Ошибка подключения бокового (заднего правого) видеодатчика. Перезапустите датчик",
    "tr": "Yan (arka sağ) görüş sensörü bağlantı hatası. Sensörü yeniden başlatın",
    "zh": "右视后部视觉传感器连接异常，请重启传感器"
  },
  "fpv_tip_0x1A0100CB_in_the_sky": {
    "de": "Verbindungsfehler des seitlichen (hinten rechts) Sichtsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Side (back right) vision sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor visual lateral (trasero derecho). Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur de vision latéral (arrière droit). Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "側面（右後方）ビジョンセンサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "측면(후방 우측) 비전 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения бокового (заднего правого) видеодатчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Yan (arka sağ) görüş sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "右视后部视觉传感器连接异常,请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A0100F0": {
    "en": "",
    "zh": "感知传感器初始化正常(仅用于工厂链路测试)"
  },
  "fpv_tip_0x1A011340": {
    "de": "Kalibrierungsfehler: Sichtsensoren",
    "en": "Vision system calibration error",
    "es": "Error de calibración del sistema de visión",
    "fr": "Erreur d'étalonnage du système optique",
    "ja": "ビジョンシステムのキャリブレーションエラー",
    "ko": "비전 시스템 캘리브레이션 오류",
    "ru": "Ошибка калибровки системы обзора",
    "tr": "Görüş sistemi kalibrasyon hatası",
    "zh": "视觉系统标定异常"
  },
  "fpv_tip_0x1A011341": {
    "de": "Kalibrierungsfehler: Sichtsensoren",
    "en": "Vision system calibration error",
    "es": "Error de calibración del sistema de visión",
    "fr": "Erreur d'étalonnage du système optique",
    "ja": "ビジョンシステムのキャリブレーションエラー",
    "ko": "비전 시스템 캘리브레이션 오류",
    "ru": "Ошибка калибровки системы обзора",
    "tr": "Görüş sistemi kalibrasyon hatası",
    "zh": "视觉系统标定异常"
  },
  "fpv_tip_0x1A011342": {
    "de": "Kalibrierungsfehler: Sichtsensoren",
    "en": "Vision system calibration error",
    "es": "Error de calibración del sistema de visión",
    "fr": "Erreur d'étalonnage du système optique",
    "ja": "ビジョンシステムのキャリブレーションエラー",
    "ko": "비전 시스템 캘리브레이션 오류",
    "ru": "Ошибка калибровки системы обзора",
    "tr": "Görüş sistemi kalibrasyon hatası",
    "zh": "视觉系统标定异常"
  },
  "fpv_tip_0x1A011343": {
    "de": "Kalibrierungsfehler: Sichtsensoren",
    "en": "Vision system calibration error",
    "es": "Error de calibración del sistema de visión",
    "fr": "Erreur d'étalonnage du système optique",
    "ja": "ビジョンシステムのキャリブレーションエラー",
    "ko": "비전 시스템 캘리브레이션 오류",
    "ru": "Ошибка калибровки системы обзора",
    "tr": "Görüş sistemi kalibrasyon hatası",
    "zh": "视觉系统标定异常"
  },
  "fpv_tip_0x1A011344": {
    "de": "Kalibrierungsfehler: Sichtsensoren",
    "en": "Vision system calibration error",
    "es": "Error de calibración del sistema de visión",
    "fr": "Erreur d'étalonnage du système optique",
    "ja": "ビジョンシステムのキャリブレーションエラー",
    "ko": "비전 시스템 캘리브레이션 오류",
    "ru": "Ошибка калибровки системы обзора",
    "tr": "Görüş sistemi kalibrasyon hatası",
    "zh": "视觉系统标定异常"
  },
  "fpv_tip_0x1A011345": {
    "de": "Kalibrierungsfehler: Sichtsensoren",
    "en": "Vision system calibration error",
    "es": "Error de calibración del sistema de visión",
    "fr": "Erreur d'étalonnage du système optique",
    "ja": "ビジョンシステムのキャリブレーションエラー",
    "ko": "비전 시스템 캘리브레이션 오류",
    "ru": "Ошибка калибровки системы обзора",
    "tr": "Görüş sistemi kalibrasyon hatası",
    "zh": "视觉系统标定异常"
  },
  "fpv_tip_0x1A011346": {
    "de": "Kalibrierungsfehler: Sichtsensoren",
    "en": "Vision system calibration error",
    "es": "Error de calibración del sistema de visión",
    "fr": "Erreur d'étalonnage du système optique",
    "ja": "ビジョンシステムのキャリブレーションエラー",
    "ko": "비전 시스템 캘리브레이션 오류",
    "ru": "Ошибка калибровки системы обзора",
    "tr": "Görüş sistemi kalibrasyon hatası",
    "zh": "视觉系统标定异常"
  },
  "fpv_tip_0x1A011347": {
    "de": "Kalibrierungsfehler: Sichtsensoren",
    "en": "Vision system calibration error",
    "es": "Error de calibración del sistema de visión",
    "fr": "Erreur d'étalonnage du système optique",
    "ja": "ビジョンシステムのキャリブレーションエラー",
    "ko": "비전 시스템 캘리브레이션 오류",
    "ru": "Ошибка калибровки системы обзора",
    "tr": "Görüş sistemi kalibrasyon hatası",
    "zh": "视觉系统标定异常"
  },
  "fpv_tip_0x1A011348": {
    "de": "Kalibrierungsfehler: Sichtsensoren",
    "en": "Vision system calibration error",
    "es": "Error de calibración del sistema de visión",
    "fr": "Erreur d'étalonnage du système optique",
    "ja": "ビジョンシステムのキャリブレーションエラー",
    "ko": "비전 시스템 캘리브레이션 오류",
    "ru": "Ошибка калибровки системы обзора",
    "tr": "Görüş sistemi kalibrasyon hatası",
    "zh": "视觉系统标定异常"
  },
  "fpv_tip_0x1A011349": {
    "de": "Kalibrierungsfehler: Sichtsensoren",
    "en": "Vision system calibration error",
    "es": "Error de calibración del sistema de visión",
    "fr": "Erreur d'étalonnage du système optique",
    "ja": "ビジョンシステムのキャリブレーションエラー",
    "ko": "비전 시스템 캘리브레이션 오류",
    "ru": "Ошибка калибровки системы обзора",
    "tr": "Görüş sistemi kalibrasyon hatası",
    "zh": "视觉系统标定异常"
  },
  "fpv_tip_0x1A01134A": {
    "de": "Kalibrierungsfehler: Sichtsensoren",
    "en": "Vision system calibration error",
    "es": "Error de calibración del sistema de visión",
    "fr": "Erreur d'étalonnage du système optique",
    "ja": "ビジョンシステムのキャリブレーションエラー",
    "ko": "비전 시스템 캘리브레이션 오류",
    "ru": "Ошибка калибровки системы обзора",
    "tr": "Görüş sistemi kalibrasyon hatası",
    "zh": "视觉系统标定异常"
  },
  "fpv_tip_0x1A01134B": {
    "de": "Kalibrierungsfehler: Sichtsensoren",
    "en": "Vision system calibration error",
    "es": "Error de calibración del sistema de visión",
    "fr": "Erreur d'étalonnage du système optique",
    "ja": "ビジョンシステムのキャリブレーションエラー",
    "ko": "비전 시스템 캘리브레이션 오류",
    "ru": "Ошибка калибровки системы обзора",
    "tr": "Görüş sistemi kalibrasyon hatası",
    "zh": "视觉系统标定异常"
  },
  "fpv_tip_0x1A020040": {
    "de": "Verbindungsfehler des unteren Infrarotsensors. Fluggerät neu starten",
    "en": "Bottom infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos inferior. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge inférieur. Redémarrez l'appareil",
    "ja": "下部赤外線センサー接続エラー。機体を再起動してください",
    "ko": "하단 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения нижнего инфракрасного датчика. Перезапустите дрон",
    "tr": "Alt kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "下视红外传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A020040_in_the_sky": {
    "de": "Verbindungsfehler des unteren Infrarotsensors. Zum Startpunkt zurückkehren oder landen. Bitte auf die Landegeschwindigkeit achten",
    "en": "Bottom infrared sensor connection error. Return to home or land. Pay attention to the landing speed",
    "es": "Error de conexión del sensor de infrarrojos inferior. Regresa al punto de origen o aterriza. Presta atención a la velocidad de aterrizaje",
    "fr": "Erreur de connexion de capteur infrarouge inférieur. Revenez au point de départ ou atterrissez. Prêtez attention à la vitesse d'atterrissage",
    "ja": "下部赤外線センサー接続エラー。RTHまたは着陸させてください。着陸速度に注意してください",
    "ko": "하단 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요. 착륙 속도에 주의하세요.",
    "ru": "Ошибка подключения нижнего инфракрасного датчика. Вернитесь домой или приземлитесь. Обращайте внимание на скорость приземления",
    "tr": "Alt kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın. İniş hızına dikkat edin",
    "zh": "下视红外传感器连接异常,请注意控制降落着地速度，请返航或降落"
  },
  "fpv_tip_0x1A020041": {
    "de": "Verbindungsfehler des vorderen Infrarotsensors. Fluggerät neu starten",
    "en": "Front infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos delantero. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge avant. Redémarrez l'appareil",
    "ja": "前方赤外線センサー接続エラー。機体を再起動してください",
    "ko": "전면 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения переднего инфракрасного датчика. Перезапустите дрон",
    "tr": "Ön kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "前视红外传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A020041_in_the_sky": {
    "de": "Verbindungsfehler des vorderen Infrarotsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Front infrared sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos delantero. Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge avant. Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "前方赤外線センサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "전면 적외선 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения переднего инфракрасного датчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Ön kızılötesi sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "前视红外传感器连接异常，请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A020042": {
    "de": "Verbindungsfehler des rechten Infrarotsensors. Fluggerät neu starten",
    "en": "Right infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos derecho. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge droit. Redémarrez l'appareil",
    "ja": "右赤外線センサー接続エラー。機体を再起動してください",
    "ko": "우측 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения правого инфракрасного датчика. Перезапустите дрон",
    "tr": "Sağ kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "右视红外传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A020042_in_the_sky": {
    "de": "Verbindungsfehler des rechten Infrarotsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Right infrared sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos derecho. Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge droit. Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "右赤外線センサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "우측 적외선 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения правого инфракрасного датчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Sağ kızılötesi sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "右视红外传感器连接异常，请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A020043": {
    "de": "Verbindungsfehler des hinteren Infrarotsensors. Fluggerät neu starten",
    "en": "Back infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos trasero. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge arrière. Redémarrez l'appareil",
    "ja": "後方赤外線センサー接続エラー。機体を再起動してください",
    "ko": "후방 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения заднего инфракрасного датчика. Перезапустите дрон",
    "tr": "Arka kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "后视红外传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A020043_in_the_sky": {
    "de": "Verbindungsfehler des hinteren Infrarotsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Back infrared sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos trasero. Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge arrière. Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "後方赤外線センサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "후방 적외선 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения заднего инфракрасного датчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Arka kızılötesi sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "后视红外传感器连接异常，请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A020044": {
    "de": "Verbindungsfehler des linken Infrarotsensors. Fluggerät neu starten",
    "en": "Left infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos izquierdo. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge gauche. Redémarrez l'appareil",
    "ja": "左赤外線センサー接続エラー。機体を再起動してください",
    "ko": "좌측 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения левого инфракрасного датчика. Перезапустите дрон",
    "tr": "Sol kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "左视红外传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A020044_in_the_sky": {
    "de": "Verbindungsfehler des linken Infrarotsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Left infrared sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos izquierdo. Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge gauche. Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "左赤外線センサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "좌측 적외선 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения левого инфракрасного датчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Sol kızılötesi sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "左视红外传感器连接异常，请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A020045": {
    "de": "Verbindungsfehler des oberen Infrarotsensors. Fluggerät neu starten",
    "en": "Top infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos superior. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge supérieur. Redémarrez l'appareil",
    "ja": "上部赤外線センサー接続エラー。機体を再起動してください",
    "ko": "상단 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения верхнего инфракрасного датчика. Перезапустите дрон",
    "tr": "Üst kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "上视红外传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A020045_in_the_sky": {
    "de": "Verbindungsfehler des oberen Infrarotsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Top infrared sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos superior. Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge supérieur. Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "上部赤外線センサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "상단 적외선 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения верхнего инфракрасного датчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Üst kızılötesi sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "上视红外传感器连接异常，请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A020080": {
    "de": "Verbindungsfehler des unteren Infrarotsensors. Fluggerät neu starten",
    "en": "Bottom infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos inferior. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge inférieur. Redémarrez l'appareil",
    "ja": "下部赤外線センサー接続エラー。機体を再起動してください",
    "ko": "하단 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения нижнего инфракрасного датчика. Перезапустите дрон",
    "tr": "Alt kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "下视红外传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A020080_in_the_sky": {
    "de": "Verbindungsfehler des unteren Infrarotsensors. Zum Startpunkt zurückkehren oder landen. Bitte auf die Landegeschwindigkeit achten",
    "en": "Bottom infrared sensor connection error. Return to Home or land. Pay attention to the landing speed",
    "es": "Error de conexión del sensor de infrarrojos inferior. Regresa al punto de origen o aterriza. Presta atención a la velocidad de aterrizaje",
    "fr": "Erreur de connexion de capteur infrarouge inférieur. Revenez au point de départ ou atterrissez. Prêtez attention à la vitesse d'atterrissage",
    "ja": "下部赤外線センサー接続エラー。帰還するか、着陸してください。着陸速度に注意してください",
    "ko": "하단 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요. 착륙 속도에 주의하세요.",
    "ru": "Ошибка подключения нижнего инфракрасного датчика. Вернитесь домой или приземлитесь. Обращайте внимание на скорость приземления",
    "tr": "Alt kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın. İniş hızına dikkat edin",
    "zh": "下视红外传感器连接异常,请注意控制降落着地速度，请返航或降落"
  },
  "fpv_tip_0x1A020081": {
    "de": "Verbindungsfehler des vorderen Infrarotsensors. Fluggerät neu starten",
    "en": "Front infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos delantero. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge avant. Redémarrez l'appareil",
    "ja": "前方赤外線センサー接続エラー。機体を再起動してください",
    "ko": "전면 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения переднего инфракрасного датчика. Перезапустите дрон",
    "tr": "Ön kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "前视红外传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A020081_in_the_sky": {
    "de": "Verbindungsfehler des vorderen Infrarotsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Front infrared sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos delantero. Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge avant. Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "前方赤外線センサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "전면 적외선 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения переднего инфракрасного датчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Ön kızılötesi sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "前视红外传感器连接异常，请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A020082": {
    "de": "Verbindungsfehler des rechten Infrarotsensors. Fluggerät neu starten",
    "en": "Right infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos derecho. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge droit. Redémarrez l'appareil",
    "ja": "右赤外線センサー接続エラー。機体を再起動してください",
    "ko": "우측 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения правого инфракрасного датчика. Перезапустите дрон",
    "tr": "Sağ kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "右视红外传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A020082_in_the_sky": {
    "de": "Verbindungsfehler des rechten Infrarotsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Right infrared sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos derecho. Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge droit. Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "右赤外線センサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "우측 적외선 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения правого инфракрасного датчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Sağ kızılötesi sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "右视红外传感器连接异常，请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A020083": {
    "de": "Verbindungsfehler des hinteren Infrarotsensors. Fluggerät neu starten",
    "en": "Back infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos trasero. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge arrière. Redémarrez l'appareil",
    "ja": "後方赤外線センサー接続エラー。機体を再起動してください",
    "ko": "후방 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения заднего инфракрасного датчика. Перезапустите дрон",
    "tr": "Arka kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "后视红外传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A020083_in_the_sky": {
    "de": "Verbindungsfehler des hinteren Infrarotsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Back infrared sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos trasero. Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge arrière. Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "後方赤外線センサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "후방 적외선 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения заднего инфракрасного датчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Arka kızılötesi sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "后视红外传感器连接异常，请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A020084": {
    "de": "Verbindungsfehler des linken Infrarotsensors. Fluggerät neu starten",
    "en": "Left infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos izquierdo. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge gauche. Redémarrez l'appareil",
    "ja": "左赤外線センサー接続エラー。機体を再起動してください",
    "ko": "좌측 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения левого инфракрасного датчика. Перезапустите дрон",
    "tr": "Sol kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "左视红外传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A020084_in_the_sky": {
    "de": "Verbindungsfehler des linken Infrarotsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Left infrared sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos izquierdo. Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge gauche. Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "左赤外線センサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "좌측 적외선 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения левого инфракрасного датчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Sol kızılötesi sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "左视红外传感器连接异常，请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A020085": {
    "de": "Verbindungsfehler des oberen Infrarotsensors. Fluggerät neu starten",
    "en": "Top infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos superior. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge supérieur. Redémarrez l'appareil",
    "ja": "上部赤外線センサー接続エラー。機体を再起動してください",
    "ko": "상단 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения верхнего инфракрасного датчика. Перезапустите дрон",
    "tr": "Üst kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "上视红外传感器连接异常，请重启飞行器"
  },
  "fpv_tip_0x1A020085_in_the_sky": {
    "de": "Verbindungsfehler des oberen Infrarotsensors. Mit Vorsicht fliegen. Zum Startpunkt zurückkehren oder landen",
    "en": "Top infrared sensor connection error. Fly with caution. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos superior. Vuela con cuidado Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge supérieur. Pilotez avec précaution. Revenez au point de départ ou atterrissez",
    "ja": "上部赤外線センサー接続エラー。慎重に飛行してください。帰還するか、着陸してください",
    "ko": "상단 적외선 센서 연결 오류. 비행 시 주의 필요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения верхнего инфракрасного датчика. Соблюдайте осторожность при полете. Вернитесь домой или приземлитесь",
    "tr": "Üst kızılötesi sensörü bağlantı hatası. Dikkatlice uçurun. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "上视红外传感器连接异常，请谨慎飞行，返航或降落"
  },
  "fpv_tip_0x1A0200C0": {
    "de": "Verbindungsfehler: Nach unten gerichteter Infrarotsensor",
    "en": "Downward Infrared Sensor Connection Error",
    "es": "Error de conexión del sensor de infrarrojos inferior",
    "fr": "Erreur connexion du capteur infrarouge inférieur",
    "ja": "下方赤外線センサーの接続エラー",
    "ko": "하향 적외선 센서 연결 오류",
    "ru": "Ошибка подключения нижнего инфракрасного датчика",
    "tr": "Aşağı Kızılötesi Sensör Bağlantı Hatası",
    "zh": "下视红外传感器连接异常"
  },
  "fpv_tip_0x1A0200C0_in_the_sky": {
    "de": "Verbindungsfehler des unteren Infrarotsensors. Zum Startpunkt zurückkehren oder landen. Bitte auf die Landegeschwindigkeit achten",
    "en": "Bottom infrared sensor connection error. Return to home or land. Pay attention to the landing speed",
    "es": "Error de conexión del sensor de infrarrojos inferior. Regresa al punto de origen o aterriza. Presta atención a la velocidad de aterrizaje",
    "fr": "Erreur de connexion de capteur infrarouge inférieur. Revenez au point de départ ou atterrissez. Prêtez attention à la vitesse d'atterrissage",
    "ja": "下部赤外線センサー接続エラー。RTHまたは着陸させてください。着陸速度に注意してください",
    "ko": "하단 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요. 착륙 속도에 주의하세요.",
    "ru": "Ошибка подключения нижнего инфракрасного датчика. Вернитесь домой или приземлитесь. Обращайте внимание на скорость приземления",
    "tr": "Alt kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın. İniş hızına dikkat edin",
    "zh": "下视红外传感器连接异常，请注意控制降落着地速度，请返航或降落"
  },
  "fpv_tip_0x1A0200C1": {
    "de": "Verbindungsfehler: Nach vorne gerichteter Infrarotsensor",
    "en": "Forward infrared sensor connection error",
    "es": "Error de conexión del sensor de infrarrojos frontal",
    "fr": "Erreur connexion du capteur infrarouge avant",
    "ja": "前方赤外線センサーの接続エラー",
    "ko": "전방 적외선 센서 연결 오류",
    "ru": "Ошибка подключения переднего инфракрасного датчика",
    "tr": "İleri kızılötesi sensör bağlantı hatası",
    "zh": "前视红外传感器连接异常"
  },
  "fpv_tip_0x1A0200C1_in_the_sky": {
    "de": "Verbindungsfehler des vorderen Infrarotsensors. Zum Startpunkt zurückkehren oder landen",
    "en": "Front infrared sensor connection error. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos delantero. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge avant. Revenez au point de départ ou atterrissez",
    "ja": "前方赤外線センサー接続エラー。帰還するか、着陸してください",
    "ko": "전면 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения переднего инфракрасного датчика. Вернитесь домой или приземлитесь",
    "tr": "Ön kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "前视红外传感器连接异常，请返航或降落"
  },
  "fpv_tip_0x1A0200C2": {
    "de": "Verbindungsfehler: Rechter Infrarotsensor",
    "en": "Right infrared sensor connection error",
    "es": "Error de conexión del sensor de infrarrojos derecho",
    "fr": "Erreur connexion du capteur infrarouge droit",
    "ja": "右赤外線センサーの接続エラー",
    "ko": "우측 적외선 센서 연결 오류",
    "ru": "Ошибка подключения правого инфракрасного датчика",
    "tr": "Sağ kızılötesi sensör bağlantı hatası",
    "zh": "右视红外传感器连接异常"
  },
  "fpv_tip_0x1A0200C2_in_the_sky": {
    "de": "Verbindungsfehler des rechten Infrarotsensors. Zum Startpunkt zurückkehren oder landen",
    "en": "Right infrared sensor connection error. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos derecho. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge droit. Revenez au point de départ ou atterrissez",
    "ja": "右赤外線センサー接続エラー。帰還するか、着陸してください",
    "ko": "우측 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения правого инфракрасного датчика. Вернитесь домой или приземлитесь",
    "tr": "Sağ kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "右视红外传感器连接异常，请返航或降落"
  },
  "fpv_tip_0x1A0200C3": {
    "de": "Verbindungsfehler: Nach hinten gerichteter Infrarotsensor",
    "en": "Backward infrared sensor connection error",
    "es": "Error de conexión del sensor de infrarrojos trasero",
    "fr": "Erreur connexion du capteur infrarouge arrière",
    "ja": "後方赤外線センサーの接続エラー",
    "ko": "후방 적외선 센서 연결 오류",
    "ru": "Ошибка подключения заднего инфракрасного датчика",
    "tr": "Geri kızılötesi sensör bağlantı hatası",
    "zh": "后视红外传感器连接异常"
  },
  "fpv_tip_0x1A0200C3_in_the_sky": {
    "de": "Verbindungsfehler des hinteren Infrarotsensors. Zum Startpunkt zurückkehren oder landen",
    "en": "Back infrared sensor connection error. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos trasero. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge arrière. Revenez au point de départ ou atterrissez",
    "ja": "後方赤外線センサー接続エラー。帰還するか、着陸してください",
    "ko": "후방 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения заднего инфракрасного датчика. Вернитесь домой или приземлитесь",
    "tr": "Arka kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "后视红外传感器连接异常，请返航或降落"
  },
  "fpv_tip_0x1A0200C4": {
    "de": "Verbindungsfehler: Linker Infrarotsensor",
    "en": "Left infrared sensor connection error",
    "es": "Error de conexión del sensor de infrarrojos izquierdo",
    "fr": "Erreur connexion du capteur infrarouge gauche",
    "ja": "左赤外線センサーの接続エラー",
    "ko": "좌측 적외선 센서 연결 오류",
    "ru": "Ошибка подключения левого инфракрасного датчика",
    "tr": "Sol kızılötesi sensör bağlantı hatası",
    "zh": "左视红外传感器连接异常"
  },
  "fpv_tip_0x1A0200C4_in_the_sky": {
    "de": "Verbindungsfehler des linken Infrarotsensors. Zum Startpunkt zurückkehren oder landen",
    "en": "Left infrared sensor connection error. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos izquierdo. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge gauche. Revenez au point de départ ou atterrissez",
    "ja": "左赤外線センサー接続エラー。帰還するか、着陸してください",
    "ko": "좌측 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения левого инфракрасного датчика. Вернитесь домой или приземлитесь",
    "tr": "Sol kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "左视红外传感器连接异常，请返航或降落"
  },
  "fpv_tip_0x1A0200C5": {
    "de": "Verbindungsfehler: Nach oben gerichteter Infrarotsensor",
    "en": "Upward infrared sensor connection error",
    "es": "Error de conexión del sensor de infrarrojos superior",
    "fr": "Erreur connexion du capteur infrarouge supérieur",
    "ja": "上方赤外線センサーの接続エラー",
    "ko": "상향 적외선 센서 연결 오류",
    "ru": "Ошибка подключения верхнего инфракрасного датчика",
    "tr": "Yukarı kızılötesi sensör bağlantı hatası",
    "zh": "上视红外传感器连接异常"
  },
  "fpv_tip_0x1A0200C5_in_the_sky": {
    "de": "Verbindungsfehler des oberen Infrarotsensors. Zum Startpunkt zurückkehren oder landen",
    "en": "Top infrared sensor connection error. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos superior. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge supérieur. Revenez au point de départ ou atterrissez",
    "ja": "上部赤外線センサー接続エラー。帰還するか、着陸してください",
    "ko": "상단 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения верхнего инфракрасного датчика. Вернитесь домой или приземлитесь",
    "tr": "Üst kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "上视红外传感器连接异常，请返航或降落"
  },
  "fpv_tip_0x1A020100": {
    "de": "Kalibrierungsfehler: Abwärts gerichteter Infrarotsensor",
    "en": "Downward infrared sensor calibration error",
    "es": "Error de calibración del sensor de infrarrojos inferior",
    "fr": "Erreur étalonnage du capteur infrarouge inférieur",
    "ja": "下方赤外線センサーのキャリブレーションエラー",
    "ko": "하향 적외선 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки нижнего ИК датчика",
    "tr": "Aşağı kızılötesi sensör kalibrasyon hatası",
    "zh": "下视红外传感器标定异常"
  },
  "fpv_tip_0x1A020101": {
    "de": "Kalibrierungsfehler: Nach vorne gerichteter Infrarotsensor",
    "en": "Forward infrared sensor calibration error",
    "es": "Error de calibración del sensor de infrarrojos frontal",
    "fr": "Erreur étalonnage du capteur infrarouge avant",
    "ja": "前方赤外線センサーのキャリブレーションエラー",
    "ko": "전방 적외선 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки переднего ИК датчика",
    "tr": "İleri kızılötesi sensör kalibrasyon hatası",
    "zh": "前视红外传感器标定异常"
  },
  "fpv_tip_0x1A020102": {
    "de": "Kalibrierungsfehler: Rechter Infrarotsensor",
    "en": "Right infrared sensor calibration error",
    "es": "Error de calibración del sensor de infrarrojos derecho",
    "fr": "Erreur étalonnage du capteur infrarouge droit",
    "ja": "右赤外線センサーのキャリブレーションエラー",
    "ko": "우측 적외선 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки правого ИК датчика",
    "tr": "Sağ kızılötesi sensör kalibrasyon hatası",
    "zh": "右视红外传感器标定异常"
  },
  "fpv_tip_0x1A020103": {
    "de": "Kalibrierungsfehler: Nach hinten gerichteter Infrarotsensor",
    "en": "Backward infrared sensor calibration error",
    "es": "Error de calibración del sensor de infrarrojos trasero",
    "fr": "Erreur étalonnage du capteur infrarouge arrière",
    "ja": "後方赤外線センサーのキャリブレーションエラー",
    "ko": "후방 적외선 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки заднего ИК датчика",
    "tr": "Geri kızılötesi sensör kalibrasyon hatası",
    "zh": "后视红外传感器标定异常"
  },
  "fpv_tip_0x1A020104": {
    "de": "Kalibrierungsfehler: Linker Infrarotsensor",
    "en": "Left infrared sensor calibration error",
    "es": "Error de calibración del sensor de infrarrojos izquierdo",
    "fr": "Erreur étalonnage du capteur infrarouge gauche",
    "ja": "左赤外線センサーのキャリブレーションエラー",
    "ko": "좌측 적외선 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки левого ИК датчика",
    "tr": "Sol kızılötesi sensör kalibrasyon hatası",
    "zh": "左视红外传感器标定异常"
  },
  "fpv_tip_0x1A020105": {
    "de": "Kalibrierungsfehler: Nach oben gerichteter Infrarotsensor",
    "en": "Upward infrared sensor calibration error",
    "es": "Error de calibración del sensor de infrarrojos superior",
    "fr": "Erreur étalonnage du capteur infrarouge supérieur",
    "ja": "上方赤外線センサーのキャリブレーションエラー",
    "ko": "상향 적외선 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки верхнего ИК датчика",
    "tr": "Yukarı kızılötesi sensör kalibrasyon hatası",
    "zh": "上视红外传感器标定异常"
  },
  "fpv_tip_0x1A020140": {
    "de": "Infrarotsensoren blockiert",
    "en": "Infrared sensors blocked",
    "es": "Sensores de infrarrojos bloqueados",
    "fr": "Capteurs infrarouges bloqués",
    "ja": "赤外線センサーがブロック",
    "ko": "적외선 센서 차단",
    "ru": "Инфракрасные датчики заблокированы",
    "tr": "Kızılötesi sensörler engelleniyor",
    "zh": "视觉红外传感器被遮挡"
  },
  "fpv_tip_0x1A020180": {
    "de": "Infrarotsensoren überhitzt. Umgehend zum Startpunkt zurückkehren oder landen. Bitte aus Umgebung mit hoher Temperatur entfernen",
    "en": "Infrared sensors overheated. Return to home or land promptly. Move away from high-temperature environment",
    "es": "Sensores de infrarrojos sobrecalentados. Regresa al punto de origen o aterriza rápidamente. Aléjate del entorno de alta temperatura",
    "fr": "Surchauffe des capteurs infrarouges. Retournez au point de départ ou atterrissez immédiatement. Éloignez-vous des sources de hautes températures",
    "ja": "赤外線センサー高温。直ちに帰還するか、着陸してください。高温環境から離れてください",
    "ko": "적외선 센서 과열. 홈으로 돌아가거나 신속히 착륙하세요. 고온 환경에서 벗어나세요",
    "ru": "Инфракрасные датчики перегреты. Немедленно вернитесь в исходное положение и приземлитесь. Переместитесь от среды с высокой температурой",
    "tr": "Kızılötesi sensörler aşırı ısındı. Hemen eve dön veya iniş yap. Yüksek sıcaklık alanından uzaklaş",
    "zh": "视觉红外传感器温度过高，请尽快返航或降落，远离高温环境"
  },
  "fpv_tip_0x1A020400": {
    "de": "Schlechte Sicht. Mit Vorsicht fliegen",
    "en": "Low visibility. Fly with caution",
    "es": "Poca visibilidad. Vuela con cuidado",
    "fr": "Faible visibilité. Pilotez avec précaution",
    "ja": "視界不良。慎重に飛行してください",
    "ko": "가시성 낮음. 비행 시 주의 필요",
    "ru": "Низкая видимость. Соблюдайте осторожность при полете",
    "tr": "Düşük görüş. Aracınızı dikkatli uçurun",
    "zh": "环境能见度差，请谨慎飞行"
  },
  "fpv_tip_0x1A030040": {
    "de": "Verbindungsfehler des unteren Infrarotsensors. Fluggerät neu starten",
    "en": "Bottom infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos inferior. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge inférieur. Redémarrez l'appareil",
    "ja": "下部赤外線センサー接続エラー。機体を再起動してください",
    "ko": "하단 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения нижнего инфракрасного датчика. Перезапустите дрон",
    "tr": "Alt kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "下视红外传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A030040_in_the_sky": {
    "de": "Verbindungsfehler des unteren Infrarotsensors. Zum Startpunkt zurückkehren oder landen. Bitte auf die Landegeschwindigkeit achten",
    "en": "Bottom infrared sensor connection error. Return to home or land. Pay attention to the landing speed",
    "es": "Error de conexión del sensor de infrarrojos inferior. Regresa al punto de origen o aterriza. Presta atención a la velocidad de aterrizaje",
    "fr": "Erreur de connexion de capteur infrarouge inférieur. Revenez au point de départ ou atterrissez. Prêtez attention à la vitesse d'atterrissage",
    "ja": "下部赤外線センサー接続エラー。RTHまたは着陸させてください。着陸速度に注意してください",
    "ko": "하단 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요. 착륙 속도에 주의하세요.",
    "ru": "Ошибка подключения нижнего инфракрасного датчика. Вернитесь домой или приземлитесь. Обращайте внимание на скорость приземления",
    "tr": "Alt kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın. İniş hızına dikkat edin",
    "zh": "下视红外传感器连接异常,请注意控制降落着地速度,请返航或降落"
  },
  "fpv_tip_0x1A030041": {
    "de": "Verbindungsfehler des vorderen Infrarotsensors. Fluggerät neu starten",
    "en": "Front infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos delantero. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge avant. Redémarrez l'appareil",
    "ja": "前方赤外線センサー接続エラー。機体を再起動してください",
    "ko": "전면 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения переднего инфракрасного датчика. Перезапустите дрон",
    "tr": "Ön kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "前视红外传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A030041_in_the_sky": {
    "de": "Verbindungsfehler des vorderen Infrarotsensors. Zum Startpunkt zurückkehren oder landen",
    "en": "Front infrared sensor connection error. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos delantero. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge avant. Revenez au point de départ ou atterrissez",
    "ja": "前方赤外線センサー接続エラー。帰還するか、着陸してください",
    "ko": "전면 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения переднего инфракрасного датчика. Вернитесь домой или приземлитесь",
    "tr": "Ön kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "前视红外传感器连接异常,请返航或降落"
  },
  "fpv_tip_0x1A030042": {
    "de": "Verbindungsfehler des hinteren Infrarotsensors. Fluggerät neu starten",
    "en": "Back infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos trasero. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge arrière. Redémarrez l'appareil",
    "ja": "後方赤外線センサー接続エラー。機体を再起動してください",
    "ko": "후방 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения заднего инфракрасного датчика. Перезапустите дрон",
    "tr": "Arka kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "后视红外传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A030042_in_the_sky": {
    "de": "Verbindungsfehler des hinteren Infrarotsensors. Zum Startpunkt zurückkehren oder landen",
    "en": "Back infrared sensor connection error. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos trasero. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge arrière. Revenez au point de départ ou atterrissez",
    "ja": "後方赤外線センサー接続エラー。帰還するか、着陸してください",
    "ko": "후방 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения заднего инфракрасного датчика. Вернитесь домой или приземлитесь",
    "tr": "Arka kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "后视红外传感器连接异常,请返航或降落"
  },
  "fpv_tip_0x1A030043": {
    "de": "Verbindungsfehler des oberen Infrarotsensors. Fluggerät neu starten",
    "en": "Top infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos superior. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge supérieur. Redémarrez l'appareil",
    "ja": "上部赤外線センサー接続エラー。機体を再起動してください",
    "ko": "상단 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения верхнего инфракрасного датчика. Перезапустите дрон",
    "tr": "Üst kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "上视红外传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A030043_in_the_sky": {
    "de": "Verbindungsfehler des oberen Infrarotsensors. Zum Startpunkt zurückkehren oder landen",
    "en": "Top infrared sensor connection error. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos superior. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge supérieur. Revenez au point de départ ou atterrissez",
    "ja": "上部赤外線センサー接続エラー。帰還するか、着陸してください",
    "ko": "상단 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения верхнего инфракрасного датчика. Вернитесь домой или приземлитесь",
    "tr": "Üst kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "上视红外传感器连接异常,请返航或降落"
  },
  "fpv_tip_0x1A030044": {
    "de": "Verbindungsfehler des linken Infrarotsensors. Fluggerät neu starten",
    "en": "Left infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos izquierdo. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge gauche. Redémarrez l'appareil",
    "ja": "左赤外線センサー接続エラー。機体を再起動してください",
    "ko": "좌측 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения левого инфракрасного датчика. Перезапустите дрон",
    "tr": "Sol kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "左视红外传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A030044_in_the_sky": {
    "de": "Verbindungsfehler des linken Infrarotsensors. Zum Startpunkt zurückkehren oder landen",
    "en": "Left infrared sensor connection error. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos izquierdo. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge gauche. Revenez au point de départ ou atterrissez",
    "ja": "左赤外線センサー接続エラー。帰還するか、着陸してください",
    "ko": "좌측 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения левого инфракрасного датчика. Вернитесь домой или приземлитесь",
    "tr": "Sol kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "左视红外传感器连接异常,请返航或降落"
  },
  "fpv_tip_0x1A030045": {
    "de": "Verbindungsfehler des rechten Infrarotsensors. Fluggerät neu starten",
    "en": "Right infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos derecho. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge droit. Redémarrez l'appareil",
    "ja": "右赤外線センサー接続エラー。機体を再起動してください",
    "ko": "우측 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения правого инфракрасного датчика. Перезапустите дрон",
    "tr": "Sağ kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "右视红外传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A030045_in_the_sky": {
    "de": "Verbindungsfehler des rechten Infrarotsensors. Zum Startpunkt zurückkehren oder landen",
    "en": "Right infrared sensor connection error. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos derecho. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge droit. Revenez au point de départ ou atterrissez",
    "ja": "右赤外線センサー接続エラー。帰還するか、着陸してください",
    "ko": "우측 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения правого инфракрасного датчика. Вернитесь домой или приземлитесь",
    "tr": "Sağ kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "右视红外传感器连接异常,请返航或降落"
  },
  "fpv_tip_0x1A030080": {
    "de": "Verbindungsfehler des unteren Infrarotsensors. Fluggerät neu starten",
    "en": "Bottom infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos inferior. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge inférieur. Redémarrez l'appareil",
    "ja": "下部赤外線センサー接続エラー。機体を再起動してください",
    "ko": "하단 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения нижнего инфракрасного датчика. Перезапустите дрон",
    "tr": "Alt kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "下视红外传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A030080_in_the_sky": {
    "de": "Verbindungsfehler des unteren Infrarotsensors. Bitte auf die Landgeschwindigkeit achten. Zum Startpunkt zurückkehren oder landen",
    "en": "Bottom infrared sensor connection error. Pay attention to the landing speed. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos inferior. Presta atención a la velocidad de aterrizaje. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge inférieur. Prêter attention à la vitesse d'atterrissage. Revenez au point de départ ou atterrissez",
    "ja": "下部赤外線センサー接続エラー。着陸速度に注意してください。帰還するか、着陸してください",
    "ko": "하단 적외선 센서 연결 오류. 착륙 속도에 주의하세요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения нижнего инфракрасного датчика. Обращайте внимание на скорость приземления. Вернитесь домой или приземлитесь",
    "tr": "Alt kızılötesi sensörü bağlantı hatası. İniş hızına dikkat edin. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "下视红外传感器连接异常,请注意控制降落着地速度，请返航或降落"
  },
  "fpv_tip_0x1A030081": {
    "de": "Verbindungsfehler des vorderen Infrarotsensors. Fluggerät neu starten",
    "en": "Front infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos delantero. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge avant. Redémarrez l'appareil",
    "ja": "前方赤外線センサー接続エラー。機体を再起動してください",
    "ko": "전면 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения переднего инфракрасного датчика. Перезапустите дрон",
    "tr": "Ön kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "前视红外传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A030081_in_the_sky": {
    "de": "Verbindungsfehler des vorderen Infrarotsensors. Zum Startpunkt zurückkehren oder landen",
    "en": "Front infrared sensor connection error. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos delantero. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge avant. Revenez au point de départ ou atterrissez",
    "ja": "前方赤外線センサー接続エラー。帰還するか、着陸してください",
    "ko": "전면 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения переднего инфракрасного датчика. Вернитесь домой или приземлитесь",
    "tr": "Ön kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "前视红外传感器连接异常,请返航或降落"
  },
  "fpv_tip_0x1A030082": {
    "de": "Verbindungsfehler des hinteren Infrarotsensors. Fluggerät neu starten",
    "en": "Back infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos trasero. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge arrière. Redémarrez l'appareil",
    "ja": "後方赤外線センサー接続エラー。機体を再起動してください",
    "ko": "후방 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения заднего инфракрасного датчика. Перезапустите дрон",
    "tr": "Arka kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "后视红外传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A030082_in_the_sky": {
    "de": "Verbindungsfehler des hinteren Infrarotsensors. Zum Startpunkt zurückkehren oder landen",
    "en": "Back infrared sensor connection error. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos trasero. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge arrière. Revenez au point de départ ou atterrissez",
    "ja": "後方赤外線センサー接続エラー。帰還するか、着陸してください",
    "ko": "후방 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения заднего инфракрасного датчика. Вернитесь домой или приземлитесь",
    "tr": "Arka kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "后视红外传感器连接异常,请返航或降落"
  },
  "fpv_tip_0x1A030083": {
    "de": "Verbindungsfehler des oberen Infrarotsensors. Fluggerät neu starten",
    "en": "Top infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos superior. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge supérieur. Redémarrez l'appareil",
    "ja": "上部赤外線センサー接続エラー。機体を再起動してください",
    "ko": "상단 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения верхнего инфракрасного датчика. Перезапустите дрон",
    "tr": "Üst kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "上视红外传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A030083_in_the_sky": {
    "de": "Verbindungsfehler des oberen Infrarotsensors. Zum Startpunkt zurückkehren oder landen",
    "en": "Top infrared sensor connection error. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos superior. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge supérieur. Revenez au point de départ ou atterrissez",
    "ja": "上部赤外線センサー接続エラー。帰還するか、着陸してください",
    "ko": "상단 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения верхнего инфракрасного датчика. Вернитесь домой или приземлитесь",
    "tr": "Üst kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "上视红外传感器连接异常,请返航或降落"
  },
  "fpv_tip_0x1A030084": {
    "de": "Verbindungsfehler des linken Infrarotsensors. Fluggerät neu starten",
    "en": "Left infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos izquierdo. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge gauche. Redémarrez l'appareil",
    "ja": "左赤外線センサー接続エラー。機体を再起動してください",
    "ko": "좌측 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения левого инфракрасного датчика. Перезапустите дрон",
    "tr": "Sol kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "左视红外传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A030084_in_the_sky": {
    "de": "Verbindungsfehler des linken Infrarotsensors. Zum Startpunkt zurückkehren oder landen",
    "en": "Left infrared sensor connection error. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos izquierdo. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge gauche. Revenez au point de départ ou atterrissez",
    "ja": "左赤外線センサー接続エラー。帰還するか、着陸してください",
    "ko": "좌측 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения левого инфракрасного датчика. Вернитесь домой или приземлитесь",
    "tr": "Sol kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "左视红外传感器连接异常,请返航或降落"
  },
  "fpv_tip_0x1A030085": {
    "de": "Verbindungsfehler des rechten Infrarotsensors. Fluggerät neu starten",
    "en": "Right infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos derecho. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge droit. Redémarrez l'appareil",
    "ja": "右赤外線センサー接続エラー。機体を再起動してください",
    "ko": "우측 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения правого инфракрасного датчика. Перезапустите дрон",
    "tr": "Sağ kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "右视红外传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A030085_in_the_sky": {
    "de": "Verbindungsfehler des rechten Infrarotsensors. Zum Startpunkt zurückkehren oder landen",
    "en": "Right infrared sensor connection error. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos derecho. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge droit. Revenez au point de départ ou atterrissez",
    "ja": "右赤外線センサー接続エラー。帰還するか、着陸してください",
    "ko": "우측 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения правого инфракрасного датчика. Вернитесь домой или приземлитесь",
    "tr": "Sağ kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "右视红外传感器连接异常,请返航或降落"
  },
  "fpv_tip_0x1A030180": {
    "de": "Verbindungsfehler des unteren Infrarotsensors. Fluggerät neu starten",
    "en": "Bottom infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos inferior. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge inférieur. Redémarrez l'appareil",
    "ja": "下部赤外線センサー接続エラー。機体を再起動してください",
    "ko": "하단 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения нижнего инфракрасного датчика. Перезапустите дрон",
    "tr": "Alt kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "下视红外传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A030180_in_the_sky": {
    "de": "Verbindungsfehler des unteren Infrarotsensors. Bitte auf die Landgeschwindigkeit achten. Zum Startpunkt zurückkehren oder landen",
    "en": "Bottom infrared sensor connection error. Pay attention to the landing speed. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos inferior. Presta atención a la velocidad de aterrizaje. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge inférieur. Prêtez attention à la vitesse d'atterrissage. Revenez au point de départ ou atterrissez",
    "ja": "下部赤外線センサー接続エラー。着陸速度に注意してください。帰還するか、着陸してください",
    "ko": "하단 적외선 센서 연결 오류. 착륙 속도에 주의하세요. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения нижнего инфракрасного датчика. Обращайте внимание на скорость приземления. Вернитесь домой или приземлитесь",
    "tr": "Alt kızılötesi sensörü bağlantı hatası. İniş hızına dikkat edin. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "下视红外传感器连接异常,请注意控制降落着地速度，请返航或降落"
  },
  "fpv_tip_0x1A030181": {
    "de": "Verbindungsfehler des vorderen Infrarotsensors. Fluggerät neu starten",
    "en": "Front infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos delantero. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge avant. Redémarrez l'appareil",
    "ja": "前方赤外線センサー接続エラー。機体を再起動してください",
    "ko": "전면 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения переднего инфракрасного датчика. Перезапустите дрон",
    "tr": "Ön kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "前视红外传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A030181_in_the_sky": {
    "de": "Verbindungsfehler des vorderen Infrarotsensors. Zum Startpunkt zurückkehren oder landen",
    "en": "Front infrared sensor connection error. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos delantero. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge avant. Revenez au point de départ ou atterrissez",
    "ja": "前方赤外線センサー接続エラー。帰還するか、着陸してください",
    "ko": "전면 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения переднего инфракрасного датчика. Вернитесь домой или приземлитесь",
    "tr": "Ön kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "前视红外传感器连接异常,请返航或降落"
  },
  "fpv_tip_0x1A030182": {
    "de": "Verbindungsfehler des hinteren Infrarotsensors. Fluggerät neu starten",
    "en": "Back infrared sensor connection error. Restart aircraft",
    "es": "Error de conexión del sensor de infrarrojos trasero. Reinicia la aeronave",
    "fr": "Erreur de connexion de capteur infrarouge arrière. Redémarrez l'appareil",
    "ja": "後方赤外線センサー接続エラー。機体を再起動してください",
    "ko": "후방 적외선 센서 연결 오류. 기체 재시작",
    "ru": "Ошибка подключения заднего инфракрасного датчика. Перезапустите дрон",
    "tr": "Arka kızılötesi sensörü bağlantı hatası. Hava aracını yeniden başlatın",
    "zh": "后视红外传感器连接异常,请重启飞行器"
  },
  "fpv_tip_0x1A030182_in_the_sky": {
    "de": "Verbindungsfehler des hinteren Infrarotsensors. Zum Startpunkt zurückkehren oder landen",
    "en": "Back infrared sensor connection error. Return to home or land",
    "es": "Error de conexión del sensor de infrarrojos trasero. Regresa al punto de origen o aterriza",
    "fr": "Erreur de connexion de capteur infrarouge arrière. Revenez au point de départ ou atterrissez",
    "ja": "後方赤外線センサー接続エラー。帰還するか、着陸してください",
    "ko": "후방 적외선 센서 연결 오류. 리턴 투 홈 진행 또는 착륙 필요",
    "ru": "Ошибка подключения заднего инфракрасного датчика. Вернитесь домой или приземлитесь",
    "tr": "Arka kızılötesi sensörü bağlantı hatası. Kalkış noktasına geri dönün veya iniş yapın",
    "zh": "后视红外传感器连接异常,请返航或降落"
  },
  "fpv_tip_0x1A030183": {
    "en": "",
    "zh": "3DTOF 出图异常(上视)"
  },
  "fpv_tip_0x1A030184": {
    "en": "",
    "zh": "3DTOF 出图异常(左视)"
  },
  "fpv_tip_0x1A030185": {
    "en": "",
    "zh": "3DTOF 出图异常(右视)"
  },
  "fpv_tip_0x1A0301C0": {
    "en": "",
    "zh": "TOF3D_CALIBRATION_FAIL_0(下视)"
  },
  "fpv_tip_0x1A0301C1": {
    "en": "",
    "zh": "TOF3D_CALIBRATION_FAIL_1(前视)"
  },
  "fpv_tip_0x1A0301C2": {
    "en": "",
    "zh": "3DTOF 标定异常(后视)"
  },
  "fpv_tip_0x1A0301C3": {
    "en": "",
    "zh": "3DTOF 标定异常(上视)"
  },
  "fpv_tip_0x1A0301C4": {
    "en": "",
    "zh": "3DTOF 标定异常(左视)"
  },
  "fpv_tip_0x1A0301C5": {
    "en": "",
    "zh": "3DTOF 标定异常(右视)"
  },
  "fpv_tip_0x1A040001": {
    "de": "Fehler: Energiemodus. Fluggerät neu starten.",
    "en": "Power mode error. Restart aircraft",
    "es": "Error del modo de energía. Reinicie la aeronave",
    "fr": "Erreur de mode d'alimentation. Redémarrez l’appareil",
    "ja": "電源モードのエラー。機体を再起動してください",
    "ko": "전원 모드 오류. 기체 재시작",
    "ru": "Ошибка режима питания. Перезапустите дрон",
    "tr": "Güç modu hatası. Hava aracını yeniden başlatın",
    "zh": "功耗切换异常，请重启飞行器"
  },
  "fpv_tip_0x1A050040": {
    "ar": "",
    "de": "",
    "en": "Obstacle sensing error. Fly with caution",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "感知避障异常，请谨慎飞行",
    "zh-Hant": ""
  },
  "fpv_tip_0x1A050041": {
    "ar": "",
    "de": "",
    "en": "Obstacle sensing error. Fly with caution",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "感知避障异常，请谨慎飞行",
    "zh-Hant": ""
  },
  "fpv_tip_0x1A050042": {
    "ar": "",
    "de": "",
    "en": "Obstacle sensing error. Fly with caution",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "感知避障异常，请谨慎飞行",
    "zh-Hant": ""
  },
  "fpv_tip_0x1A050043": {
    "ar": "",
    "de": "",
    "en": "Obstacle sensing error. Fly with caution",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "感知避障异常，请谨慎飞行",
    "zh-Hant": ""
  },
  "fpv_tip_0x1A060041": {
    "en": "",
    "zh": "前雷达传感器连接异常"
  },
  "fpv_tip_0x1A060042": {
    "en": "",
    "zh": "后雷达传感器连接异常"
  },
  "fpv_tip_0x1A060043": {
    "en": "",
    "zh": "中心雷达传感器连接异常"
  },
  "fpv_tip_0x1A060080": {
    "en": "",
    "zh": "上雷达传感器标定异常"
  },
  "fpv_tip_0x1A060081": {
    "en": "",
    "zh": "前雷达传感器标定异常"
  },
  "fpv_tip_0x1A060082": {
    "en": "",
    "zh": "后雷达传感器标定异常"
  },
  "fpv_tip_0x1A060083": {
    "en": "",
    "zh": "中心雷达传感器标定异常"
  },
  "fpv_tip_0x1A0600C0": {
    "en": "",
    "zh": "上雷达传感器硬件故障"
  },
  "fpv_tip_0x1A0600C1": {
    "en": "",
    "zh": "前雷达传感器硬件故障"
  },
  "fpv_tip_0x1A0600C2": {
    "en": "",
    "zh": "后雷达传感器硬件故障"
  },
  "fpv_tip_0x1A0600C3": {
    "en": "",
    "zh": "中心雷达传感器硬件故障"
  },
  "fpv_tip_0x1A080080": {
    "en": "",
    "zh": "下前超声波传感器硬件故障"
  },
  "fpv_tip_0x1A080081": {
    "en": "",
    "zh": "下后超声波传感器硬件故障"
  },
  "fpv_tip_0x1A080082": {
    "en": "",
    "zh": "下左超声波传感器硬件故障"
  },
  "fpv_tip_0x1A080083": {
    "en": "",
    "zh": "下右超声波传感器硬件故障"
  },
  "fpv_tip_0x1A080084": {
    "en": "",
    "zh": "中心左超声波传感器硬件故障"
  },
  "fpv_tip_0x1A080085": {
    "en": "",
    "zh": "中心右超声波传感器硬件异常"
  },
  "fpv_tip_0x1A090043": {
    "de": "Anschlussfehler des aufwärts gerichteten LiDAR. Vorsichtig fliegen",
    "en": "Upward LiDAR connection error. Fly with caution",
    "es": "Error de conexión del LiDAR ascendente. Vuela con cuidado",
    "fr": "Erreur de connexion LiDAR supérieur. Pilotez avec précaution",
    "ja": "上方LiDAR接続エラー。慎重に飛行してください",
    "ko": "상향 LiDAR 연결 오류. 비행 시 주의 필요",
    "ru": "Ошибка подключения верхнего лидара. Соблюдайте осторожность при полете",
    "tr": "Yukarı yönlü LiDAR bağlantı hatası. Dikkatlice uçun",
    "zh": "上视激光雷达连接异常，请谨慎飞行"
  },
  "fpv_tip_0x1A090043_in_the_sky": {
    "de": "Anschlussfehler des aufwärts gerichteten LiDAR. Vorsichtig fliegen und umgehend zum Start zurückkehren",
    "en": "Upward LiDAR connection error. Fly with caution and return to home promptly",
    "es": "Error de conexión del LiDAR ascendente. Vuela con cuidado y regresa al punto de origen cuanto antes",
    "fr": "Erreur de connexion LiDAR supérieur. Pilotez avec précaution et revenez au point de départ rapidement",
    "ja": "上方LiDAR接続エラー。慎重に飛行し、直ちに帰還してください",
    "ko": "상향 LiDAR 연결 오류. 비행 시 주의 필요 및 리턴 투 홈 즉시 실행",
    "ru": "Ошибка подключения верхнего лидара. Соблюдайте осторожность при полете и выполните возврат в домашнюю точку в ближайшее время",
    "tr": "Yukarı yönlü LiDAR bağlantı hatası. Dikkatlice uçun ve derhal başlangıç noktasına dönün",
    "zh": "上视激光雷达连接异常，请谨慎飞行并尽快返航"
  },
  "fpv_tip_0x1A210180": {
    "de": "Fehler: Sensorsystem. Umgehend landen oder mit Vorsicht fliegen",
    "en": "Sensor system error. Land promptly or fly with caution",
    "es": "Error del sistema de sensores. Aterriza rápidamente o vuela con cuidado",
    "fr": "Erreur du système de capteurs. Atterrissez rapidement ou pilotez avec précaution",
    "ja": "センサーシステムのエラー。直ちに着陸するか、慎重に飛行してください",
    "ko": "센서 시스템 오류. 즉시 착륙 또는 비행 시 주의 필요",
    "ru": "Ошибка системы датчиков. Немедленно выполните посадку или соблюдайте осторожность при полете",
    "tr": "Sensör sistem hatası. Derhal iniş yapın veya aracı dikkatli uçurun",
    "zh": "感知系统异常，请尽快降落或谨慎飞行"
  },
  "fpv_tip_0x1A210181": {
    "de": "Fehler: Sensorsystem. Umgehend landen oder mit Vorsicht fliegen",
    "en": "Sensor system error. Land promptly or fly with caution",
    "es": "Error del sistema de sensores. Aterriza rápidamente o vuela con cuidado",
    "fr": "Erreur du système de capteurs. Atterrissez rapidement ou pilotez avec précaution",
    "ja": "センサーシステムのエラー。直ちに着陸するか、慎重に飛行してください",
    "ko": "센서 시스템 오류. 즉시 착륙 또는 비행 시 주의 필요",
    "ru": "Ошибка системы датчиков. Немедленно выполните посадку или соблюдайте осторожность при полете",
    "tr": "Sensör sistem hatası. Derhal iniş yapın veya aracı dikkatli uçurun",
    "zh": "感知系统异常，请尽快降落或谨慎飞行"
  },
  "fpv_tip_0x1A210182": {
    "de": "Fehler: Sensorsystem. Umgehend landen oder mit Vorsicht fliegen",
    "en": "Sensor system error. Land promptly or fly with caution",
    "es": "Error del sistema de sensores. Aterriza rápidamente o vuela con cuidado",
    "fr": "Erreur du système de capteurs. Atterrissez rapidement ou pilotez avec précaution",
    "ja": "センサーシステムのエラー。直ちに着陸するか、慎重に飛行してください",
    "ko": "센서 시스템 오류. 즉시 착륙 또는 비행 시 주의 필요",
    "ru": "Ошибка системы датчиков. Немедленно выполните посадку или соблюдайте осторожность при полете",
    "tr": "Sensör sistem hatası. Derhal iniş yapın veya aracı dikkatli uçurun",
    "zh": "感知系统异常，请尽快降落或谨慎飞行"
  },
  "fpv_tip_0x1A210183": {
    "de": "Fehler: Sensorsystem. Umgehend landen oder mit Vorsicht fliegen",
    "en": "Sensor system error. Land promptly or fly with caution",
    "es": "Error del sistema de sensores. Aterriza rápidamente o vuela con cuidado",
    "fr": "Erreur du système de capteurs. Atterrissez rapidement ou pilotez avec précaution",
    "ja": "センサーシステムのエラー。直ちに着陸するか、慎重に飛行してください",
    "ko": "센서 시스템 오류. 즉시 착륙 또는 비행 시 주의 필요",
    "ru": "Ошибка системы датчиков. Немедленно выполните посадку или соблюдайте осторожность при полете",
    "tr": "Sensör sistem hatası. Derhal iniş yapın veya aracı dikkatli uçurun",
    "zh": "感知系统异常，请尽快降落或谨慎飞行"
  },
  "fpv_tip_0x1A210184": {
    "de": "Fehler: Sensorsystem. Umgehend landen oder mit Vorsicht fliegen",
    "en": "Sensor system error. Land promptly or fly with caution",
    "es": "Error del sistema de sensores. Aterriza rápidamente o vuela con cuidado",
    "fr": "Erreur du système de capteurs. Atterrissez rapidement ou pilotez avec précaution",
    "ja": "センサーシステムのエラー。直ちに着陸するか、慎重に飛行してください",
    "ko": "센서 시스템 오류. 즉시 착륙 또는 비행 시 주의 필요",
    "ru": "Ошибка системы датчиков. Немедленно выполните посадку или соблюдайте осторожность при полете",
    "tr": "Sensör sistem hatası. Derhal iniş yapın veya aracı dikkatli uçurun",
    "zh": "感知系统异常，请尽快降落或谨慎飞行"
  },
  "fpv_tip_0x1A210185": {
    "de": "Fehler: Sensorsystem. Umgehend landen oder mit Vorsicht fliegen",
    "en": "Sensor system error. Land promptly or fly with caution",
    "es": "Error del sistema de sensores. Aterriza rápidamente o vuela con cuidado",
    "fr": "Erreur du système de capteurs. Atterrissez rapidement ou pilotez avec précaution",
    "ja": "センサーシステムのエラー。直ちに着陸するか、慎重に飛行してください",
    "ko": "센서 시스템 오류. 즉시 착륙 또는 비행 시 주의 필요",
    "ru": "Ошибка системы датчиков. Немедленно выполните посадку или соблюдайте осторожность при полете",
    "tr": "Sensör sistem hatası. Derhal iniş yapın veya aracı dikkatli uçurun",
    "zh": "感知系统异常，请尽快降落或谨慎飞行"
  },
  "fpv_tip_0x1A2101C0": {
    "de": "Fehler: Sensorsystem. Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Redémarrez l'appareil",
    "ja": "センサーシステムのエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Перезапустите дрон",
    "tr": "Sensör sistem hatası. Aracı yeniden başlatın",
    "zh": "感知系统异常，请重启飞行器"
  },
  "fpv_tip_0x1A2101C1": {
    "de": "Fehler: Sensorsystem. Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Redémarrez l'appareil",
    "ja": "センサーシステムのエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Перезапустите дрон",
    "tr": "Sensör sistem hatası. Aracı yeniden başlatın",
    "zh": "感知系统异常，请重启飞行器"
  },
  "fpv_tip_0x1A2101C2": {
    "de": "Fehler: Sensorsystem. Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Redémarrez l'appareil",
    "ja": "センサーシステムのエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Перезапустите дрон",
    "tr": "Sensör sistem hatası. Aracı yeniden başlatın",
    "zh": "感知系统异常，请重启飞行器"
  },
  "fpv_tip_0x1A2101C3": {
    "de": "Fehler: Sensorsystem. Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Redémarrez l'appareil",
    "ja": "センサーシステムのエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Перезапустите дрон",
    "tr": "Sensör sistem hatası. Aracı yeniden başlatın",
    "zh": "感知系统异常，请重启飞行器"
  },
  "fpv_tip_0x1A2101C4": {
    "de": "Fehler: Sensorsystem. Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Redémarrez l'appareil",
    "ja": "センサーシステムのエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Перезапустите дрон",
    "tr": "Sensör sistem hatası. Aracı yeniden başlatın",
    "zh": "感知系统异常，请重启飞行器"
  },
  "fpv_tip_0x1A2101C5": {
    "de": "Fehler: Sensorsystem. Fluggerät neu starten",
    "en": "Sensor system error. Restart aircraft",
    "es": "Error del sistema de sensores. Reinicia la aeronave",
    "fr": "Erreur du système de capteurs. Redémarrez l'appareil",
    "ja": "センサーシステムのエラー。機体を再起動してください",
    "ko": "센서 시스템 오류. 기체 재시작 필요",
    "ru": "Ошибка системы датчиков. Перезапустите дрон",
    "tr": "Sensör sistem hatası. Aracı yeniden başlatın",
    "zh": "感知系统异常，请重启飞行器"
  },
  "fpv_tip_0x1A310980": {
    "de": "Systemfehler: Sichtpositionierung",
    "en": "Vision positioning system error",
    "es": "Error del sistema de posicionamiento visual",
    "fr": "Erreur système positionnement visuel",
    "ja": "ビジョンポジショニング システム エラー",
    "ko": "비전 포지셔닝 시스템 오류",
    "ru": "Ошибка системы визуального позиционирования",
    "tr": "Görüntü konumlandırma sistemi hatası",
    "zh": "视觉定位系统异常"
  },
  "fpv_tip_0x1A310981": {
    "de": "Systemfehler: Sichtpositionierung",
    "en": "Vision positioning system error",
    "es": "Error del sistema de posicionamiento visual",
    "fr": "Erreur système positionnement visuel",
    "ja": "ビジョンポジショニングシステム エラー",
    "ko": "비전 포지셔닝 시스템 오류",
    "ru": "Ошибка системы визуального позиционирования",
    "tr": "Görüntü konumlandırma sistemi hatası",
    "zh": "视觉定位系统异常"
  },
  "fpv_tip_0x1A420040": {
    "de": "Systemfehler: Hinderniserkennung nach unten",
    "en": "Downward obstacle sensing system error",
    "es": "Error del sistema de detección de obstáculos inferior",
    "fr": "Erreur système de détection d\\'obstacles inférieur",
    "ja": "下方障害物検知システムエラー",
    "ko": "하향 장애물 감지 시스템 오류",
    "ru": "Ошибка системы распознавания препятствий снизу",
    "tr": "Aşağı engel algılama sistemi hatası",
    "zh": "下视障碍物感知系统异常"
  },
  "fpv_tip_0x1A420041": {
    "de": "Systemfehler: Hinderniserkennung nach vorne",
    "en": "Forward obstacle sensing system error",
    "es": "Error del sistema de detección de obstáculos frontal",
    "fr": "Erreur système de détection d\\'obstacles avant",
    "ja": "前方障害物検知システムエラー",
    "ko": "전방 장애물 감지 시스템 오류",
    "ru": "Ошибка системы распознавания препятствий сверху",
    "tr": "İleri engel algılama sistemi hatası",
    "zh": "前视障碍物感知系统异常"
  },
  "fpv_tip_0x1A420042": {
    "de": "Systemfehler: Hinderniserkennung nach rechts",
    "en": "Right obstacle sensing system error",
    "es": "Error del sistema de detección de obstáculos derecho",
    "fr": "Erreur système de détection d\\'obstacles droit",
    "ja": "右障害物検知システムエラー",
    "ko": "우측 장애물 감지 시스템 오류",
    "ru": "Ошибка системы распознавания препятствий справа",
    "tr": "Sağ engel algılama sistemi hatası",
    "zh": "右视障碍物感知系统异常"
  },
  "fpv_tip_0x1A420043": {
    "de": "Systemfehler: Hinderniserkennung nach hinten",
    "en": "Backward obstacle sensing system error",
    "es": "Error del sistema de detección de obstáculos trasero",
    "fr": "Erreur système de détection d\\'obstacles arrière",
    "ja": "後方障害物検知システムエラー",
    "ko": "후방 장애물 감지 시스템 오류",
    "ru": "Ошибка системы распознавания препятствий сзади",
    "tr": "Geri engel algılama sistemi hatası",
    "zh": "后视障碍物感知系统异常"
  },
  "fpv_tip_0x1A420044": {
    "de": "Systemfehler: Hinderniserkennung nach links",
    "en": "Left obstacle sensing system error",
    "es": "Error del sistema de detección de obstáculos izquierdo",
    "fr": "Erreur système de détection d\\'obstacles gauche",
    "ja": "左障害物検知システムエラー",
    "ko": "좌측 장애물 감지 시스템 오류",
    "ru": "Ошибка системы распознавания препятствий слева",
    "tr": "Sol engel algılama sistemi hatası",
    "zh": "左视障碍物感知系统异常"
  },
  "fpv_tip_0x1A420045": {
    "de": "Systemfehler: Hinderniserkennung nach oben",
    "en": "Upward obstacle sensing system error",
    "es": "Error del sistema de detección de obstáculos superior",
    "fr": "Erreur système de détection d\\'obstacles supérieur",
    "ja": "上方障害物検知システムエラー",
    "ko": "상향 장애물 감지 시스템 오류",
    "ru": "Ошибка системы распознавания препятствий сверху",
    "tr": "Yukarı engel algılama sistemi hatası",
    "zh": "上视障碍物感知系统异常"
  },
  "fpv_tip_0x1A420440": {
    "de": "Systemfehler: Hinderniserkennung",
    "en": "Obstacle sensing system error",
    "es": "Error del sistema de detección de obstáculos",
    "fr": "Erreur système de détection d\\'obstacles",
    "ja": "障害物検知システムエラー",
    "ko": "장애물 감지 시스템 오류",
    "ru": "Ошибка системы распознавания препятствий",
    "tr": "Engel algılama sistemi hatası",
    "zh": "障碍物感知系统异常"
  },
  "fpv_tip_0x1A4205C0": {
    "de": "Fehler: Hinderniserkennung",
    "en": "Obstacle sensing system error",
    "es": "Error del sistema de detección de obstáculos",
    "fr": "Erreur système de détection d\\'obstacles",
    "ja": "障害物検知システムエラー",
    "ko": "장애물 감지 시스템 오류",
    "ru": "Ошибка системы распознавания препятствий",
    "tr": "Engel algılama sistemi hatası",
    "zh": "障碍物感知系统异常"
  },
  "fpv_tip_0x1A420680": {
    "de": "Fehler: Hinderniserkennung",
    "en": "Obstacle sensing system error",
    "es": "Error del sistema de detección de obstáculos",
    "fr": "Erreur système de détection d\\'obstacles",
    "ja": "障害物検知システムエラー",
    "ko": "장애물 감지 시스템 오류",
    "ru": "Ошибка системы распознавания препятствий",
    "tr": "Engel algılama sistemi hatası",
    "zh": "障碍物感知系统异常"
  },
  "fpv_tip_0x1A420BC0": {
    "de": "Abwärtsgerichtetes Umgebungslicht zu schwach. Abwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Downward ambient light too low. Obstacle avoidance unavailable. Fly with caution",
    "es": "Luz ambiental inferior demasiado débil. Sistema anticolisión no disponible. Vuela con cuidado",
    "fr": "Éclairage ambiant vers le bas trop faible. Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "下方の環境光が十分ではありません。障害物回避を利用できません。慎重に飛行してください",
    "ko": "하향 주변 조명이 너무 어둡습니다. 하향 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Слишком низкая яркость окружающего света внизу. Обход препятствий снизу недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Aşağıdaki ortam ışığı çok az. Engel önleyici kullanılamıyor. Dikkatli uçurun",
    "zh": "下视环境光过暗，下方视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420BC1": {
    "de": "Vorwärtsgerichtetes Umgebungslicht zu schwach. Vorwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Forward ambient light too low. Obstacle avoidance unavailable. Fly with caution",
    "es": "Luz ambiental frontal demasiado débil. Sistema anticolisión no disponible. Vuela con cuidado",
    "fr": "Éclairage ambiant vers l\\'avant trop faible. Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "前方の環境光が十分ではありません。障害物回避を利用できません。慎重に飛行してください",
    "ko": "전방 주변 조명이 너무 어둡습니다. 전방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Слишком низкая яркость окружающего света впереди. Обход препятствий спереди недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "İleri ortam ışığı çok az. Engel önleyici kullanılamıyor. Dikkatli uçurun",
    "zh": "前视环境光过暗，前向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420BC2": {
    "de": "Rückwärtsgerichtetes Umgebungslicht zu schwach. Rückwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Backward ambient light too low. Backward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental trasera demasiado débil. Sistema anticolisión trasero no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Lumière ambiante trop faible à l\\'arrière. Évitement des obstacles à l\\'arrière non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "後方の環境光が十分ではありません。後方障害物回避を利用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "후방 주변 조명이 너무 어둡습니다. 후방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Слишком низкая яркость окружающего света сзади. Обход препятствий сзади недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Gerideki ortam ışığı çok az. Geri engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "后视环境光过暗，后向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420BC3": {
    "de": "Rechtsgerichtetes Umgebungslicht zu schwach. Rechtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Rightward ambient light too low. Rightward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental derecha demasiado débil. Sistema anticolisión derecho no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Lumière ambiante trop faible sur la droite. Évitement des obstacles sur la droite non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "右方向の環境光が十分ではありません。右方向の障害物回避を使用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "오른쪽 주변 조명이 너무 어둡습니다. 오른쪽 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Слишком низкая яркость окружающего света справа. Обход препятствий справа недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Sağ taraftaki ortam ışığı çok az. Sağ engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "右视环境光过暗，右向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420BC4": {
    "de": "Linksgerichtetes Umgebungslicht zu schwach. Linksgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Leftward ambient light too low. Leftward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental izquierda demasiado débil. Sistema anticolisión izquierdo no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Éclairage ambiant vers la gauche trop faible. Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "左方向の環境光が十分ではありません。左方向の障害物回避を使用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "왼쪽 주변 조명이 너무 어둡습니다. 왼쪽 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Слишком низкая яркость окружающего света слева. Обход препятствий слева недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Sol taraftaki ortam ışığı çok az. Sol engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "左视环境光过暗，左向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420BC5": {
    "de": "Aufwärtsgerichtetes Umgebungslicht zu schwach. Aufwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Upward ambient light too low. Upward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental superior demasiado débil. Sistema anticolisión superior no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Éclairage ambiant vers le haut trop faible. Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "上方の環境光が十分ではありません。上方障害物回避を利用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "상방 주변 조명이 너무 어둡습니다. 상향 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Слишком низкая яркость окружающего света сверху. Обход препятствий сверху недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Yukarıdaki ortam ışığı çok az. Yukarı engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "上视环境光过暗，上方视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420BC6": {
    "de": "Horizontales Umgebungslicht zu schwach. Horizontale Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Horizontal ambient light too low. Horizontal obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental horizontal demasiado débil. Sistema anticolisión horizontal no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Éclairage ambiant horizontal trop faible. Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "水平方向の環境光が十分ではありません。水平方向の障害物回避を利用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "수평 주변 조명이 너무 어둡습니다. 수평 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Слишком низкая яркость окружающего света в горизонтальном направлении. Обход препятствий в горизонтальном направлении недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Yatay ortam ışığı çok az. Yatay engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "水平方向环境光过暗，水平方向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420C00": {
    "de": "Abwärtsgerichtetes Umgebungslicht zu hell. Abwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Downward ambient light too bright. Downward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental inferior demasiado intensa. Sistema anticolisión inferior no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Éclairage ambiant vers le bas trop intense. Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "下方の環境光が明るすぎます。下方障害物回避を利用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "하향 주변 조명이 너무 밝습니다. 하향 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Слишком высокая яркость окружающего света внизу. Обход препятствий снизу недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Aşağıdaki ortam ışığı çok parlak. Aşağı engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "下视环境光过亮，下方视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420C01": {
    "de": "Vorwärtsgerichtetes Umgebungslicht zu hell. Vorwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Forward ambient light too bright. Forward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental frontal demasiado intensa. Sistema anticolisión frontal no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Lumière ambiante trop forte à l\\'avant. Évitement des obstacles à l\\'avant non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "前方の環境光が明るすぎます。前方障害物回避を利用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "전방 주변 조명이 너무 밝습니다. 전방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Слишком высокая яркость окружающего света впереди. Обход препятствий спереди недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "İlerideki ortam ışığı çok parlak. İleri engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "前视环境光过亮，前向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420C02": {
    "de": "Rückwärtsgerichtetes Umgebungslicht zu hell. Rückwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Backward ambient light too bright. Backward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental trasera demasiado intensa. Sistema anticolisión trasero no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Éclairage ambiant vers l\\'arrière trop intense. Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "後方の環境光が明るすぎます。後方障害物回避を利用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "후방 주변 조명이 너무 밝습니다. 후방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Слишком высокая яркость окружающего света сзади. Обход препятствий сзади недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Gerideki ortam ışığı çok parlak. Geri engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "后视环境光过亮，后向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420C03": {
    "de": "Rechtsgerichtetes Umgebungslicht zu hell. Rechtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Rightward ambient light too bright. Rightward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental derecha demasiado intensa. Sistema anticolisión derecho no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Éclairage ambiant vers la droite trop intense. Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "右方向の環境光が明るすぎます。右方向の障害物回避を使用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "오른쪽 주변 조명이 너무 밝습니다. 오른쪽 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Слишком высокая яркость окружающего света справа. Обход препятствий справа недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Sağ taraftaki ortam ışığı çok parlak. Sağ engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "右视环境光过亮，右向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420C04": {
    "de": "Linksgerichtetes Umgebungslicht zu hell. Linksgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Leftward ambient light too bright. Leftward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental izquierda demasiado intensa. Sistema anticolisión izquierdo no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Éclairage ambiant vers la gauche trop intense. Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "左方向の環境光が明るすぎます。左方向の障害物回避を使用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "왼쪽 주변 조명이 너무 밝습니다. 왼쪽 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Слишком высокая яркость окружающего света слева. Обход препятствий слева недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Sol taraftaki ortam ışığı çok parlak. Sol engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "左视环境光过亮，左向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420C05": {
    "de": "Aufwärtsgerichtetes Umgebungslicht zu hell. Aufwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Upward ambient light too bright. Upward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental superior demasiado intensa. Sistema anticolisión superior no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Éclairage ambiant vers le haut trop intense. Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "上方の環境光が明るすぎます。上方障害物回避を利用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "상향 주변 조명이 너무 밝습니다. 상향 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Слишком высокая яркость окружающего света сверху. Обход препятствий сверху недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Yukarıdaki ortam ışığı çok parlak. Yukarı engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "上视环境光过亮，上方视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420C06": {
    "de": "Horizontales Umgebungslicht zu hell. Horizontale Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Horizontal ambient light too bright. Horizontal obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental horizontal demasiado intensa. Sistema anticolisión horizontal no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Éclairage ambiant horizontal trop intense. Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "水平方向の環境光が明るすぎます。水平方向の障害物回避を利用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "수평 주변 조명이 너무 밝습니다. 수평 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Слишком высокая яркость окружающего света в горизонтальном направлении. Обход препятствий в горизонтальном направлении недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Yatay ortam ışığı çok parlak. Yatay engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "水平方向环境光过亮，水平方向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420C40": {
    "de": "Abwärtsgerichtete(r) Sensor(en) blockiert. Abwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Downward sensor(s) blocked. Downward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Sensor(es) inferior(es) bloqueado(s). Sistema anticolisión inferior no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) inférieurs bloqué(s). Évitement des obstacles en dessous non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "下方センサーがブロックされています。下方障害物回避を使用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "하향 센서가 막혔습니다. 하향 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Нижние датчики заблокированы. Обход препятствий снизу недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Aşağı sensör(ler) engelleniyor. Aşağı engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "下视可能遮挡，下方视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420C41": {
    "de": "Vorwärtsgerichtete(r) Sensor(en) blockiert. Vorwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Forward sensor(s) blocked. Forward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Sensor(es) frontal(es) bloqueado(s). Sistema anticolisión frontal no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) avant bloqué(s). Évitement des obstacles à l\\'avant non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "前方センサーがブロックされています。前方障害物回避を利用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "전방 센서가 막혔습니다. 전방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Передние датчики заблокированы. Обход препятствий спереди недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "İleri sensör(ler) engelleniyor. İleri engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "前视可能遮挡，前向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420C42": {
    "de": "Rückwärtsgerichtete(r) Sensor(en) blockiert. Rückwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Backward sensor(s) blocked. Backward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Sensor(es) trasero(s) bloqueado(s). Sistema anticolisión trasero no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) à l\\'arrière bloqué(s). Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "後方センサーがブロックされています。後方障害物回避を利用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "후방 센서가 막혔습니다. 후방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Задние датчики заблокированы. Обход препятствий сзади недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Geri sensör(ler) engelleniyor. Geri engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "后视可能遮挡，后向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420C43": {
    "de": "Rechtsgerichtete(r) Sensor(en) blockiert. Rechtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar.  Mit Vorsicht fliegen",
    "en": "Rightward sensor(s) blocked. Rightward obstacle avoidance unavailable. Only infrared sensors available.  Fly with caution",
    "es": "Sensor(es) derecho(s) bloqueado(s). Sistema anticolisión derecho no disponible. Solo están disponibles los sensores de infrarrojos.  Vuela con cuidado",
    "fr": "Capteur(s) de droite bloqué(s). Évitement des obstacles sur la droite non disponible. Seuls les capteurs infrarouges sont disponibles.  Volez avec prudence",
    "ja": "右方向センサーがブロックされています。右方向の障害物回避を使用できません。赤外線センサーのみが使用可能です。  慎重に飛行してください",
    "ko": "오른쪽 센서가 막혔습니다. 오른쪽 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다.  주의해서 비행하세요",
    "ru": "Правые датчики заблокированы. Обход препятствий справа недоступен. Доступны только инфракрасные датчики.  Будьте внимательны при полете",
    "tr": "Sağ sensör(ler) engelleniyor. Sağ engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor.  Dikkatli uçurun",
    "zh": "右视可能遮挡，右向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420C44": {
    "de": "Linksgerichtete(r) Sensor(en) blockiert. Linksgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Leftward sensor(s) blocked. Leftward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Sensor(es) izquierdo(s) bloqueado(s). Sistema anticolisión izquierdo no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) à gauche bloqué(s). Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "左方向センサーがブロックされています。左方向の障害物回避を使用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "왼쪽 센서가 막혔습니다. 왼쪽 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Левые датчики заблокированы. Обход препятствий слева недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Sol sensör(ler) engelleniyor. Sol engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "左视可能遮挡，左向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420C45": {
    "de": "Aufwärtsgerichtete(r) Sensor(en) blockiert. Aufwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Upward sensor(s) blocked. Upward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Sensor(es) superior(es) bloqueado(s). Sistema anticolisión superior no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) supérieur(s) bloqué(s). Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "上方センサーがブロックされています。上方障害物回避を利用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "상향 센서가 막혔습니다. 상향 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Верхние датчики заблокированы. Обход препятствий сверху недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Yukarı sensör(ler) engelleniyor. Yukarı engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "上视可能遮挡，上方视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1A420C80": {
    "de": "Abwärtsgerichtete(r) Sensor(en) unscharf. Abwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Downward sensor(s) blurry. Downward obstacle avoidance unavailable. Fly with caution",
    "es": "Sensor(es) inferior(es) borroso(s). Sistema anticolisión inferior no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) inférieurs flou(s). Évitement des obstacles en dessous non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "下方センサーが不鮮明です。下方障害物回避を使用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "하향 센서가 흐릿합니다. 하향 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Размытое изображение с нижних датчиков. Обход препятствий снизу недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Aşağı sensör(ler) bulanık. Aşağı engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "下视可能脏污，下方视觉避障失效，请谨慎飞行"
  },
  "fpv_tip_0x1A420C81": {
    "de": "Vorwärtsgerichtete(r) Sensor(en) unscharf. Vorwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Forward sensor(s) blurry. Forward obstacle avoidance unavailable. Fly with caution",
    "es": "Sensor(es) frontal(es) borroso(s). Sistema anticolisión frontal no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) à l\\'avant flou(s). Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "前方センサーが不鮮明です。前方障害物回避を利用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "전방 센서가 흐릿합니다. 전방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Размытое изображение с передних датчиков. Обход препятствий спереди недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "İleri sensör(ler) bulanık. İleri engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "前视可能脏污，前向视觉避障失效，请谨慎飞行"
  },
  "fpv_tip_0x1A420C82": {
    "de": "Rückwärtsgerichtete(r) Sensor(en) verschwommen. Rückwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Backward sensor(s) blurry. Backward obstacle avoidance unavailable. Fly with caution",
    "es": "Sensor(es) trasero(s) borroso(s). Sistema anticolisión trasero no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) arrière flou(s). Évitement des obstacles à l\\'arrière non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "後方センサーが不鮮明です。後方障害物回避を利用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "후방 센서가 흐릿합니다. 후방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Размытое изображение с задних датчиков. Обход препятствий сзади недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Geri sensör(ler) bulanık. Geri engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "后视可能脏污，后向视觉避障失效，请谨慎飞行"
  },
  "fpv_tip_0x1A420C83": {
    "de": "Rechte(r) Sensor(en) unscharf. Rechtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Right sensor(s) blurry. Rightward obstacle avoidance unavailable. Fly with caution",
    "es": "Sensor(es) derecho(s) borroso(s). Sistema anticolisión derecho no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) de droite flou(s). Évitement des obstacles sur la droite non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "右センサーが不鮮明です。右方向の障害物回避を使用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "오른쪽 센서가 흐릿합니다. 오른쪽 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Размытое изображение с правых датчиков. Обход препятствий справа недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Sağ sensör(ler) bulanık. Sağ engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "右视可能脏污，右向视觉避障失效，请谨慎飞行"
  },
  "fpv_tip_0x1A420C84": {
    "de": "Linke(r) Sensor(en) unscharf. Linksgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Left sensor(s) blurry. Leftward obstacle avoidance unavailable. Fly with caution",
    "es": "Sensor(es) izquierdo(s) borroso(s). Sistema anticolisión izquierdo no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) de gauche flou(s). Évitement des obstacles sur la gauche non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "左センサーが不鮮明です。左方向の障害物回避を使用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "왼쪽 센서가 흐릿합니다. 왼쪽 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Размытое изображение с левых датчиков. Обход препятствий слева недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Sol sensör(ler) bulanık. Sol engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "左视可能脏污，左向视觉避障失效，请谨慎飞行"
  },
  "fpv_tip_0x1A420C85": {
    "de": "Aufwärtsgerichtete(r) Sensor(en) unscharf. Aufwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Upward sensor(s) blurry. Upward obstacle avoidance unavailable. Fly with caution",
    "es": "Sensor(es) superior(es) borroso(s). Sistema anticolisión superior no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) supérieur(s) flou(s). Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "上方センサーが不鮮明です。上方障害物回避を利用できません。赤外線センサーのみが使用可能です。慎重に飛行してください",
    "ko": "상향 센서가 흐릿합니다. 상향 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Размытое изображение с верхних датчиков. Обход препятствий сверху недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "Yukarı sensör(ler) bulanık. Yukarı engel önleyici kullanılamıyor. Sadece kızılötesi sensörler kullanılabiliyor. Dikkatli uçurun",
    "zh": "上视可能脏污，上方视觉避障失效，请谨慎飞行"
  },
  "fpv_tip_0x1A420CC0": {
    "de": "Fluglagenwinkel des Fluggeräts zu groß. Hindernisvermeidung nicht verfügbar. Mit Vorsicht fliegen",
    "en": "Aircraft attitude angle too large. Obstacle avoidance unavailable. Fly with caution",
    "es": "Ángulo de posición de la aeronave demasiado grande. Sistema anticolisión no disponible. Vuela con cuidado",
    "fr": "Angle d\\'attitude de l\\'appareil trop important. Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "機体の姿勢角が大きすぎます。障害物回避を利用できません。慎重に飛行してください",
    "ko": "기체 자세 각도가 너무 큽니다. 장애물 회피를 사용할 수 없습니다. 주의해서 비행하세요",
    "ru": "Слишком большой угол тангажа дрона. Обход препятствий недоступен. Будьте внимательны при полете",
    "tr": "Aracın davranış açısı çok büyük. Engel önleyici kullanılamıyor. Dikkatli uçurun",
    "zh": "飞行器姿态角过大，避障失效，请谨慎飞行"
  },
  "fpv_tip_0x1A420D00": {
    "de": "Fluglagenwinkel des Fluggeräts zu groß. Landeschutz nicht verfügbar. Manuell landen",
    "en": "Aircraft attitude angle too large. Landing protection unavailable. Land manually",
    "es": "Ángulo de posición de la aeronave demasiado grande. Protección durante el aterrizaje no disponible. Aterriza manualmente",
    "fr": "Angle d\\'attitude de l\\'appareil trop important. Protection à l\\'atterrissage indisponible. Atterrissez manuellement",
    "ja": "機体の姿勢角が大きすぎます。着陸保護は利用できません。手動で着陸してください",
    "ko": "기체 자세 각도가 너무 큽니다. 착륙 보호 기능을 사용할 수 없습니다. 수동 착륙",
    "ru": "Слишком большой угол тангажа дрона. Безопасное приземление недоступно. Приземлитесь вручную",
    "tr": "Aracın davranış açısı çok büyük. İniş koruma kullanılamıyor. Manuel olarak indirin",
    "zh": "飞行器姿态角过大，降落保护失效，请手动降落"
  },
  "fpv_tip_0x1A420D40": {
    "de": "Fluggerät nähert sich Hindernis mit totem Winkel. Hinderniserkennung evtl. nicht möglich. Mit Vorsicht fliegen",
    "en": "Aircraft approaching obstacle sensing blind spot and may be unable to detect obstacles. Fly with caution",
    "es": "La aeronave se está acercando al punto ciego de detección de obstáculos y es posible que no pueda detectar obstáculos. Vuela con cuidado",
    "fr": "L\\'appareil approche de l\\'angle mort de la détection d\\'obstacles et pourrait ne pas être en mesure de détecter les obstacles. Volez avec précaution",
    "ja": "機体が障害物検知の死角に接近中。障害物を検知できない可能性があります。慎重に飛行してください",
    "ko": "기체가 장애물 감지 사각 지대에 접근하고 있으므로 장애물을 감지하지 못할 수도 있습니다. 주의해서 비행하세요",
    "ru": "Дрон приближается к слепой зоне определения препятствий, и, возможно, не сможет обнаружить препятствия. Будьте внимательны при полете",
    "tr": "Araç, engel algılama kör noktasına yaklaşıyor ve engelleri tespit edemeyebilir. Dikkatli uçurun",
    "zh": "正飞向障碍物感知盲区，可能无法检测障碍物，请谨慎飞行"
  },
  "fpv_tip_0x1A421B00": {
    "en": "Vision system disabled. Switched to radar obstacle avoidance. The obstacle avoidance available range will change",
    "zh": "视觉避障失效，已切换为雷达避障，注意可避障区间变化"
  },
  "fpv_tip_0x1A421B40": {
    "en": "",
    "zh": "激光雷达已连接"
  },
  "fpv_tip_0x1A421B80": {
    "en": "",
    "zh": "激光雷达连接已断开"
  },
  "fpv_tip_0x1A430680": {
    "de": "Instabile Lichtverhältnisse. Sichtsensoren und Hinderniserkennung sind evtl. nicht verfügbar. Mit Vorsicht fliegen",
    "en": "Unstable lighting conditions. Vision system and obstacle sensing may be unavailable. Fly with caution",
    "es": "Condiciones de iluminación inestables. Puede que el sistema de visión y la detección de obstáculos no estén disponibles. Vuela con cuidado.",
    "fr": "Conditions de luminosité instables. Le système optique et la détection d'obstacles peuvent être indisponibles. Pilotez avec précaution",
    "ja": "照明条件が不安定。ビジョンシステムと障害物検知が利用できない可能性があります。慎重に飛行してください",
    "ko": "조명 조건 불안정. 비전 시스템 및 장애물 감지를 사용하지 못할 수 있음. 비행 시 주의 필요",
    "ru": "Нестабильные условия освещения. Системы обзора и обнаружения препятствий могут быть недоступны. Соблюдайте осторожность при полете",
    "tr": "Değişken ışık koşulları. Görüş sistemi ve engel algılama kullanılamayabilir. Dikkatlice uçun",
    "zh": "环境光线差异较大，视觉系统及避障可能失效，请注意飞行安全"
  },
  "fpv_tip_0x1A510380": {
    "de": "Kalibrierungsfehler: Nach unten gerichteter Sichtsensor",
    "en": "Downward vision sensor calibration error",
    "es": "Error de calibración del sensor visual inferior",
    "fr": "Erreur d\\'étalonnage du capteur optique inférieur",
    "ja": "下方ビジョンセンサーのキャリブレーションエラー",
    "ko": "하향 비전 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки нижнего видеодатчика",
    "tr": "Aşağı görüş sensörü kalibrasyon hatası",
    "zh": "下视传感器标定异常"
  },
  "fpv_tip_0x1A510381": {
    "de": "Kalibrierungsfehler: Nach vorne gerichteter Sichtsensor",
    "en": "Forward vision sensor calibration error",
    "es": "Error de calibración del sensor visual frontal",
    "fr": "Erreur d\\'étalonnage du capteur optique avant",
    "ja": "前方ビジョンセンサーのキャリブレーションエラー",
    "ko": "전방 비전 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки переднего видеодатчика",
    "tr": "İleri görüş sensörü kalibrasyon hatası",
    "zh": "前视传感器标定异常"
  },
  "fpv_tip_0x1A510382": {
    "de": "Kalibrierungsfehler: Nach hinten gerichteter Sichtsensor",
    "en": "Backward vision sensor calibration error",
    "es": "Error de calibración del sensor visual trasero",
    "fr": "Erreur d\\'étalonnage du capteur optique arrière",
    "ja": "後方ビジョンセンサーのキャリブレーションエラー",
    "ko": "후방 비전 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки заднего видеодатчика",
    "tr": "Geri görüş sensörü kalibrasyon hatası",
    "zh": "后视传感器标定异常"
  },
  "fpv_tip_0x1A510383": {
    "de": "Kalibrierungsfehler: Nach oben gerichteter Sichtsensor",
    "en": "Upward vision sensor calibration error",
    "es": "Error de calibración del sensor visual superior",
    "fr": "Erreur d\\'étalonnage du capteur optique supérieur",
    "ja": "上方ビジョンセンサーのキャリブレーションエラー",
    "ko": "상향 비전 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки верхнего видеодатчика",
    "tr": "Yukarı görüş sensörü kalibrasyon hatası",
    "zh": "上视传感器标定异常"
  },
  "fpv_tip_0x1A510384": {
    "de": "Kalibrierungsfehler: Linker Sichtsensor",
    "en": "Left vision sensor calibration error",
    "es": "Error de calibración del sensor visual izquierdo",
    "fr": "Erreur d\\'étalonnage du capteur optique gauche",
    "ja": "左ビジョンセンサーのキャリブレーションエラー",
    "ko": "좌측 비전 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки левого видеодатчика",
    "tr": "Sol görüş sensörü kalibrasyon hatası",
    "zh": "左视传感器标定异常"
  },
  "fpv_tip_0x1A510385": {
    "de": "Kalibrierungsfehler: Rechter Sichtsensor",
    "en": "Right vision sensor calibration error",
    "es": "Error de calibración del sensor visual derecho",
    "fr": "Erreur d\\'étalonnage du capteur optique droit",
    "ja": "右ビジョンセンサーのキャリブレーションエラー",
    "ko": "우측 비전 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки правого видеодатчика",
    "tr": "Sağ görüş sensörü kalibrasyon hatası",
    "zh": "右视传感器标定异常"
  },
  "fpv_tip_0x1A5103C0": {
    "de": "Kalibrierungsfehler: Sichtsensor",
    "en": "Downward vision sensor calibration error",
    "es": "Error de calibración del sensor visual inferior",
    "fr": "Erreur d\\'étalonnage du capteur optique inférieur",
    "ja": "下方ビジョンセンサーのキャリブレーションエラー",
    "ko": "비전 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки видеодатчика",
    "tr": "Aşağı görüş sensörü kalibrasyon hatası",
    "zh": "下视传感器标定异常"
  },
  "fpv_tip_0x1A5103C1": {
    "de": "Kalibrierungsfehler: Vorwärtsgerichtete Sichtsensoren",
    "en": "Forward vision sensor calibration error",
    "es": "Error de calibración del sensor visual frontal",
    "fr": "Erreur d\\'étalonnage du capteur optique avant",
    "ja": "前方ビジョンセンサーのキャリブレーションエラー",
    "ko": "전방 비전 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки переднего датчика обзора",
    "tr": "İleri görüş sensörü kalibrasyon hatası",
    "zh": "前视传感器标定异常"
  },
  "fpv_tip_0x1A5103C2": {
    "de": "Kalibrierungsfehler: Rückwärtsgerichtete Sichtsensoren",
    "en": "Backward vision sensor calibration error",
    "es": "Error de calibración del sensor visual trasero",
    "fr": "Erreur d\\'étalonnage du capteur optique arrière",
    "ja": "後方ビジョンセンサーのキャリブレーションエラー",
    "ko": "후방 비전 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки заднего датчика обзора",
    "tr": "Geri görüş sensörü kalibrasyon hatası",
    "zh": "后视传感器标定异常"
  },
  "fpv_tip_0x1A5103C3": {
    "de": "Kalibrierungsfehler: Aufwärtsgerichtete Sichtsensoren",
    "en": "Upward vision sensor calibration error",
    "es": "Error de calibración del sensor visual superior",
    "fr": "Erreur d\\'étalonnage du capteur optique supérieur",
    "ja": "上方ビジョンセンサーのキャリブレーションエラー",
    "ko": "상향 비전 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки верхнего датчика обзора",
    "tr": "Yukarı görüş sensörü kalibrasyon hatası",
    "zh": "上视传感器标定异常"
  },
  "fpv_tip_0x1A5103C4": {
    "de": "Kalibrierungsfehler: Linksgerichtete Sichtsensoren",
    "en": "Left vision sensor calibration error",
    "es": "Error de calibración del sensor visual izquierdo",
    "fr": "Erreur d\\'étalonnage du capteur optique gauche",
    "ja": "左ビジョンセンサーのキャリブレーションエラー",
    "ko": "좌측 비전 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки левого датчика обзора",
    "tr": "Sol görüş sensörü kalibrasyon hatası",
    "zh": "左视传感器标定异常"
  },
  "fpv_tip_0x1A5103C5": {
    "de": "Kalibrierungsfehler: Rechtsgerichtete Sichtsensoren",
    "en": "Right vision sensor calibration error",
    "es": "Error de calibración del sensor visual derecho",
    "fr": "Erreur d\\'étalonnage du capteur optique droit",
    "ja": "右ビジョンセンサーのキャリブレーションエラー",
    "ko": "우측 비전 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки правого датчика обзора",
    "tr": "Sağ görüş sensörü kalibrasyon hatası",
    "zh": "右视传感器标定异常"
  },
  "fpv_tip_0x1A5104C0": {
    "de": "Fehler: abwärts gerichtete Hinderniserkennung. Mit Vorsicht fliegen",
    "en": "Downward obstacle sensing error. Fly with caution",
    "es": "Error de detección de obstáculos inferior. Vuele con cuidado",
    "fr": "Erreur de détection d'obstacles vers le bas. Pilotez avec précaution",
    "ja": "下方障害物検知エラー。慎重に飛行してください",
    "ko": "하향 장애물 감지 오류. 비행 시 주의 필요",
    "ru": "Ошибка определения препятствий снизу. Соблюдайте осторожность при полете",
    "tr": "Aşağı yönde engel algılama hatası. Dikkatlice uçurun",
    "zh": "下视避障感知异常，请谨慎飞行"
  },
  "fpv_tip_0x1A5104C1": {
    "de": "Fehler: nach vorn gerichtete Hinderniserkennung. Mit Vorsicht fliegen",
    "en": "Forward obstacle sensing error. Fly with caution",
    "es": "Error de detección de obstáculos frontal. Vuele con cuidado",
    "fr": "Erreur de détection d'obstacles vers l'avant. Pilotez avec précaution",
    "ja": "前方障害物検知エラー。慎重に飛行してください",
    "ko": "전방 장애물 감지 오류. 비행 시 주의 필요",
    "ru": "Ошибка определения препятствий спереди. Соблюдайте осторожность при полете",
    "tr": "İleri yönde engel algılama hatası. Dikkatlice uçurun",
    "zh": "前视避障感知异常，请谨慎飞行"
  },
  "fpv_tip_0x1A5104C2": {
    "de": "Fehler: nach hinten gerichtete Hinderniserkennung. Mit Vorsicht fliegen",
    "en": "Backward obstacle sensing error. Fly with caution",
    "es": "Error de detección de obstáculos trasero. Vuele con cuidado",
    "fr": "Erreur de détection d'obstacles vers l'arrière. Pilotez avec précaution",
    "ja": "後方障害物検知エラー。慎重に飛行してください",
    "ko": "후방 장애물 감지 오류. 비행 시 주의 필요",
    "ru": "Ошибка определения препятствий сзади. Соблюдайте осторожность при полете",
    "tr": "Geri yönde engel algılama hatası. Dikkatlice uçurun",
    "zh": "后视避障感知异常，请谨慎飞行"
  },
  "fpv_tip_0x1A5104C3": {
    "de": "Fehler: aufwärts gerichtete Hinderniserkennung. Mit Vorsicht fliegen",
    "en": "Upward obstacle sensing error. Fly with caution",
    "es": "Error de detección de obstáculos superior. Vuele con cuidado",
    "fr": "Erreur de détection d'obstacles vers le haut. Pilotez avec précaution",
    "ja": "上方障害物検知エラー。慎重に飛行してください",
    "ko": "상향 장애물 감지 오류. 비행 시 주의 필요",
    "ru": "Ошибка определения препятствий сверху. Соблюдайте осторожность при полете",
    "tr": "Yukarı yönde engel algılama hatası. Dikkatlice uçurun",
    "zh": "上视避障感知异常，请谨慎飞行"
  },
  "fpv_tip_0x1A5104C4": {
    "de": "Fehler: nach links gerichtete Hinderniserkennung. Mit Vorsicht fliegen",
    "en": "Left obstacle sensing error. Fly with caution",
    "es": "Error de detección de obstáculos izquierdo. Vuele con cuidado",
    "fr": "Erreur de détection d'obstacles vers la gauche. Pilotez avec précaution",
    "ja": "左側障害物検知エラー。慎重に飛行してください",
    "ko": "좌측 장애물 감지 오류. 비행 시 주의 필요",
    "ru": "Ошибка определения препятствий слева. Соблюдайте осторожность при полете",
    "tr": "Sol yönde engel algılama hatası. Dikkatlice uçurun",
    "zh": "左视避障感知异常，请谨慎飞行"
  },
  "fpv_tip_0x1A5104C5": {
    "de": "Fehler: nach rechts gerichtete Hinderniserkennung, Mit Vorsicht fliegen",
    "en": "Right obstacle sensing error. Fly with caution",
    "es": "Error de detección de obstáculos derecho. Vuele con cuidado",
    "fr": "Erreur de détection d'obstacles vers la droite. Pilotez avec précaution",
    "ja": "右側障害物検知エラー。慎重に飛行してください",
    "ko": "우측 장애물 감지 오류. 비행 시 주의 필요",
    "ru": "Ошибка определения препятствий справа. Соблюдайте осторожность при полете",
    "tr": "Sağ yönde engel algılama hatası. Dikkatlice uçurun",
    "zh": "右视避障感知异常，请谨慎飞行"
  },
  "fpv_tip_0x1A5104C6": {
    "de": "Fehler: omnidirektionale Hinderniserkennung. Mit Vorsicht fliegen",
    "en": "Omnidirectional obstacle sensing error. Fly with caution",
    "es": "Error de detección de obstáculos omnidireccional. Vuele con cuidado",
    "fr": "Erreur de détection d'obstacles omnidirectionnelle. Pilotez avec précaution",
    "ja": "全方向障害物検知エラー。慎重に飛行してください",
    "ko": "전방향 장애물 감지 오류. 비행 시 주의 필요",
    "ru": "Ошибка определения препятствий на всех направлениях. Соблюдайте осторожность при полете",
    "tr": "Çok yönlü engel algılama hatası. Dikkatlice uçurun",
    "zh": "环视避障感知异常，请谨慎飞行"
  },
  "fpv_tip_0x1A5104C7": {
    "de": "Fehler: auf- und abwärts gerichtete Hinderniserkennung. Mit Vorsicht fliegen",
    "en": "Upward and downward obstacle sensing error. Fly with caution",
    "es": "Error de detección de obstáculos superior e inferior. Vuele con cuidado",
    "fr": "Erreur de détection d'obstacles vers le haut et le bas. Pilotez avec précaution",
    "ja": "上方および下方障害物検知エラー。慎重に飛行してください",
    "ko": "상향 및 하향 장애물 감지 오류. 비행 시 주의 필요",
    "ru": "Ошибка определения препятствий сверху и снизу. Соблюдайте осторожность при полете",
    "tr": "Yukarı ve aşağı yönde engel algılama hatası. Dikkatlice uçurun",
    "zh": "上下避障感知异常，请谨慎飞行"
  },
  "fpv_tip_0x1A680040": {
    "de": "Propellerschützer montiert. Hindernisvermeidung nicht verfügbar. Flugleistung und Windbeständigkeit herabgesetzt",
    "en": "Propeller guards mounted. Obstacle Avoidance unavailable. Flight performance and wind resistance performance reduced",
    "es": "Protectores para hélices instalados. Sistema anticolisión no disponible. Rendimiento de vuelo y rendimiento de resistencia al viento reducidos",
    "fr": "Protections d’hélice installées. Évitement d’obstacles indisponible. Réduction des performances de vol et de résistance au vent",
    "ja": "プロペラガードがマウントされました。障害物回避は使用できません。飛行性能と耐風性能が低下します",
    "ko": "프로펠러 가드 장착됨. 장애물 회피 사용 불가. 비행 성능과 바람 저항 성능이 저하됩니다.",
    "ru": "Установлена защита пропеллеров. Предотвращение столкновений недоступно. Эффективность полета и сопротивление ветру снижены",
    "tr": "Pervane korumaları monte edildi. Engelden Kaçınma kullanılamıyor. Uçuş performansı ve rüzgar direnci performansı azaltıldı",
    "zh": "桨叶保护罩已安装，避障功能失效，飞行性能和抗风性能降低"
  },
  "fpv_tip_0x1A680080": {
    "de": "Propellerschützer entfernt",
    "en": "Propeller guards removed",
    "es": "Protectores para hélices retirados",
    "fr": "Protections d'hélice retirées",
    "ja": "プロペラガードが取り外されました",
    "ko": "프로펠러 가드 제거됨",
    "ru": "Защита пропеллера снята",
    "tr": "Pervane korumaları çıkarıldı",
    "zh": "桨叶保护罩已移除"
  },
  "fpv_tip_0x1AFC0100": {
    "zh": "感知关键日志即将到达上限，请尽快降落拉取日志"
  },
  "fpv_tip_0x1AFC0140": {
    "zh": "存在炸机日志，拉取并清理炸机日志后在进行飞测"
  },
  "fpv_tip_0x1AFD0040": {
    "de": "Fehler: Sensorsystem. Kann nicht abheben. Fliegen untersagt",
    "en": "Sensor system error. Unable to take off. Flight prohibited",
    "es": "Error del sistema del sensor. No se puede despegar. Vuelo prohibido",
    "fr": "Erreur système de capteurs. Décollage impossible. Vol interdit",
    "ja": "センサーシステムエラー。離陸できません。飛行が禁止されています",
    "ko": "센서 시스템 오류. 이륙 불가. 비행 금지",
    "ru": "Ошибка системы датчиков. Невозможно выполнить взлет. Полет запрещен",
    "tr": "Sensör sistem hatası. Kalkış yapamıyor. Uçuş yasaklandı",
    "zh": "无法起飞：感知传感器系统异常，禁止起飞"
  },
  "fpv_tip_0x1AFD0040_in_the_sky": {
    "de": "Fehler: Sensorsystem. Sofort zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land promptly",
    "es": "Error del sistema del sensor. Regresa al punto de origen o aterriza lo antes posible",
    "fr": "Erreur système de capteurs. Revenez au point de départ et atterrissez immédiatement",
    "ja": "センサーシステムエラー。直ちに帰還もしくは着陸してください",
    "ko": "센서 시스템 오류. 즉시 RTH 또는 착륙 필요",
    "ru": "Ошибка системы датчиков. Вернитесь домой или приземлитесь немедленно",
    "tr": "Sensör sistem hatası. Eve geri dönün veya düzgün iniş yapın",
    "zh": "感知传感器系统异常，请尽快返航或降落"
  },
  "fpv_tip_0x1AFE0040": {
    "de": "Sichtsensoren überlastet. In offenen Bereich fliegen",
    "en": "Vision system overloaded. Fly to open area",
    "es": "Sistema de visión sobrecargado. Vuela a un espacio abierto",
    "fr": "Système optique surchargé. Volez dans une zone dégagée",
    "ja": "ビジョンシステム過負荷状態。開けた場所に飛行してください",
    "ko": "비전 시스템 과부하. 개방 영역으로 비행하세요",
    "ru": "Система обзора перегружена. Подлетите к открытому участку",
    "tr": "Görüş sistemi aşırı yüklü. Aracı açık alanda uçurun",
    "zh": "视觉系统负载过高，请谨慎飞行至开阔环境"
  },
  "fpv_tip_0x1B010001": {
    "de": "Systemfehler: Navigation . Fluggerät neu starten",
    "en": "Navigation system error . Restart aircraft",
    "es": "Error del sistema de navegación. Reinicia la aeronave",
    "fr": "Erreur du système de navigation . Redémarrez l\\'appareil",
    "ja": "ナビゲーションシステムエラー。機体を再起動してください",
    "ko": "내비게이션 시스템 오류 . 기체 재시작 필요",
    "ru": "Ошибка навигационной системы . Перезагрузите дрон",
    "tr": "Navigasyon sistemi hatası. Aracı yeniden başlatın",
    "zh": "导航系统异常，请重启飞行器"
  },
  "fpv_tip_0x1B010002": {
    "de": "Smarte Verfolgung für aktuelle Nutzlast nicht verfügbar",
    "en": "Target Acquisition unavailable with current payload",
    "es": "Smart Track no disponible con el instrumento actual",
    "fr": "Smart Track indisponible avec la charge utile actuelle",
    "ja": "現在のペイロードではスマートトラックを使用できません",
    "ko": "현재 페이로드에서는 스마트 트랙을 사용할 수 없음",
    "ru": "Интеллектуальное следование недоступно для текущей полезной нагрузки",
    "tr": "Mevcut yük için Akıllı Takip kullanılamıyor",
    "zh": "当前负载不支持目标锁定功能"
  },
  "fpv_tip_0x1B010003": {
    "de": "Fehler: Kameramodus. Smart Track deaktiviert",
    "en": "Camera mode error. Smart Track disabled",
    "es": "Error del modo de la cámara. Smart Track desactivado",
    "fr": "Erreur de mode de caméra. Smart Track désactivé",
    "ja": "カメラモードエラー。スマートトラックが無効になりました",
    "ko": "카메라 모드 오류. 스마트 트랙 비활성화됨.",
    "ru": "Ошибка режима камеры. Функция Smart Track отключена",
    "tr": "Kamera modu hatası. Akıllı Takip devre dışı",
    "zh": "相机模式异常，智能跟踪已关闭"
  },
  "fpv_tip_0x1B010004": {
    "de": "Ziel zu nah",
    "en": "Target too close",
    "es": "Objetivo demasiado cerca",
    "fr": "Sujet trop proche",
    "ja": "被写体が近すぎます",
    "ko": "피사체 너무 가까움",
    "ru": "Цель слишком близко",
    "tr": "Hedef çok yakın",
    "zh": "距离目标物体过近"
  },
  "fpv_tip_0x1B010005": {
    "de": "Ziel verloren. Smarte Verfolgung gestoppt",
    "en": "Target lost. Target Acquisition paused",
    "es": "Se ha perdido el objetivo. Smart Track detenido",
    "fr": "Sujet perdu. Smart Track interrompu",
    "ja": "ターゲットを見失いました。スマートトラック 停止",
    "ko": "타겟 사라짐. 스마트 트랙 중지됨",
    "ru": "Цель потеряна. Интеллектуальное следование остановлено",
    "tr": "Hedef kayıp. Akıllı Takip durdu",
    "zh": "目标已丢失，目标锁定功能停止"
  },
  "fpv_tip_0x1B010006": {
    "de": "Gimbal zeigt nicht in Flugrichtung. Vorsichtig fliegen",
    "en": "Gimbal not facing flight direction. Fly with caution",
    "es": "El estabilizador no está orientado en la dirección de vuelo. Vuela con precaución",
    "fr": "La nacelle n'est pas orientée dans la direction du vol. Volez avec précaution",
    "ja": "ジンバルが飛行方向を向いていません。慎重に飛行してください",
    "ko": "짐벌, 비행 방향 향하지 않음. 비행 시 주의 필요",
    "ru": "Стабилизатор направлен в сторону, отличную от направления полета. Будьте осторожны",
    "tr": "Gimbal uçuş yönüne bakmıyor. Aracınızı dikkatli uçurun",
    "zh": "云台已偏离航向，请谨慎飞行"
  },
  "fpv_tip_0x1B010007": {
    "de": "Smarte Verfolgung gestoppt . In P-Modus wechseln",
    "en": "Target Acquisition paused . Switch to P mode",
    "es": "Smart Track detenido. Cambia al modo P",
    "fr": "Smart Track interrompu . Passez au mode P",
    "ja": "スマートトラック停止。Pモードに切り替えてください",
    "ko": "스마트 트랙 중지됨 . P 모드로 전환",
    "ru": "Интеллектуальное следование остановлено . Переключитесь на режим P",
    "tr": "Akıllı Takip durdu. P (Konumlandırma) moduna geçin",
    "zh": "目标锁定功能停止，请切换飞行挡位至P挡"
  },
  "fpv_tip_0x1B010007_n_mode": {
    "de": "Zielerfassung gestoppt. Zu N-Modus wechseln",
    "en": "Target Acquisition stopped. Switch to N mode",
    "es": "Se ha detenido Adquisición de objetivo. Cambia al modo N",
    "fr": "L'acquisition de la cible s'est arrêtée. Basculer en mode N",
    "ja": "ターゲットを見失いました。Nモードに切り替えてください",
    "ko": "대상 수집 중단됨. N 모드로 전환",
    "ru": "Получение цели остановлено. Включите режим N",
    "tr": "Hedef Alımı durduruldu. M moduna geçin",
    "zh": "目标锁定功能停止，请切换飞行挡位至N挡"
  },
  "fpv_tip_0x1B010008": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen",
    "en": "Enabling Target Acquisition failed",
    "es": "Error al activar Smart Track",
    "fr": "Impossible d\\'activer Smart Track",
    "ja": "スマートトラックを有効化できません",
    "ko": "스마트 트랙 활성화 실패",
    "ru": "Не удалось включить интеллектуальное следование",
    "tr": "Akıllı Takip Etkinleştirme başarısız",
    "zh": "目标锁定功能开启失败"
  },
  "fpv_tip_0x1B010009": {
    "de": "Hindernis erkannt. Fluggerät schwebt. Bitte manuell steuern, um Hindernissen auszuweichen",
    "en": "Obstacle detected. Circling stopped. Manually control aircraft to avoid obstacle",
    "es": "Obstáculo detectado. Vuelo en círculos detenido. Controla la aeronave manualmente para evitar los obstáculos",
    "fr": "Obstacle détecté. Cercle interrompu. Contrôlez l\\'appareil manuellement pour éviter l\\'obstacle",
    "ja": "障害物を検出。旋回を停止しました。機体を手動で操作し、障害物を避けてください",
    "ko": "장애물 감지됨. 궤도 선회 정지됨. 기체 수동으로 제어해 장애물 피해야 함",
    "ru": "Определено препятствие. Кружение остановлено. Управляйте дроном вручную, чтобы избегать препятствия",
    "tr": "Engel tespit edildi. Turlama durdu. Engelleri aşmak için aracı manuel olarak kontrol edin",
    "zh": "遇到障碍物，停止环绕，请手动控制飞机远离障碍"
  },
  "fpv_tip_0x1B01000A": {
    "de": "GEO-Zone in der Nähe. Fluggerät schwebt. Bitte manuell steuern, um GEO-Zone auszuweichen",
    "en": "GEO Zone nearby. Circling stopped. Manually control aircraft to avoid GEO Zone",
    "es": "Zona GEO cercana. Vuelo en círculos detenido. Controla la aeronave manualmente para evitar la zona GEO",
    "fr": "Zone GEO proche. Cercle interrompu. Contrôlez l\\'appareil manuellement pour éviter la zone GEO",
    "ja": "GEO区域が近くにあります。旋回を停止しました。機体を手動で操作し、GEO区域を避けてください",
    "ko": "GEO 구역 인근. 궤도 선회 정지됨. 기체 수동으로 제어해 GEO 구역을 피해야 함",
    "ru": "Зона GEO неподалеку. Кружение остановлено. Управляйте дроном вручную, чтобы не попасть в нее",
    "tr": "GEO Bölgesi yakın. Turlama durdu. GEO Bölgesine girişi önlemek için aracı manuel olarak kontrol edin",
    "zh": "遇到限飞区，停止环绕，请手动控制飞机远离限飞区"
  },
  "fpv_tip_0x1B01000B": {
    "de": "Smarte Verfolgung unterbrochen",
    "en": "Smart Track paused",
    "es": "Smart Track pausado",
    "fr": "Smart Track mis en pause",
    "ja": "スマートトラック一時停止",
    "ko": "스마트 트랙 일시 중지됨",
    "ru": "Интеллектуальное следование приостановлено",
    "tr": "Akıllı Takip duraklatıldı",
    "zh": "智能跟踪功能停止"
  },
  "fpv_tip_0x1B01000C": {
    "de": "Ziel bewegt sich zu schnell. Fluggerät schwebt.",
    "en": "Target moving too fast. Circling stopped",
    "es": "El objetivo se mueve demasiado rápido. Vuelo en círculos detenido",
    "fr": "Sujet trop rapide. Cercle interrompu",
    "ja": "被写体の移動が速すぎます。旋回を停止しました",
    "ko": "피사체 움직임 너무 빠름. 궤도 선회 정지됨",
    "ru": "Цель движется слишком быстро. Кружение остановлено",
    "tr": "Hedef hızlı hareket ediyor. Turlama durdu",
    "zh": "目标物体运动过快，停止环绕"
  },
  "fpv_tip_0x1B01000D": {
    "de": "Ungewöhnliche Zielbewegung. Ziel erneut durch Ziehen auswählen",
    "en": "Unusual target movement. Drag-select target again",
    "es": "Movimiento inusual del objetivo. Vuelve a arrastrar para seleccionar el objetivo",
    "fr": "Mouvement du sujet inhabituel. Encadrez-le à nouveau",
    "ja": "ターゲットの異常動作。ドラッグしてターゲットを再度選択してください",
    "ko": "특이한 타겟 이동. 다시 타겟을 끌어서 선택하세요",
    "ru": "Необычное перемещение цели. Снова перетащите и выберите цель",
    "tr": "Hedef olağandışı bir şekilde hareket ediyor. Tekrar hedefi sürükleyip seçin",
    "zh": "目标发生异常移动，请重新确认目标"
  },
  "fpv_tip_0x1B01000E": {
    "de": "GPS-Signal schwach und Sichtpositionierung nicht verfügbar. Fluggerät wechselt in den Modus \"A\". Fluggerät bitte manuell steuern (%alarmid)",
    "en": "GPS signal weak and vision positioning unavailable. Aircraft switched to A mode. Control aircraft manually",
    "es": "Señal GPS débil y posicionamiento visual no disponible. La aeronave ha cambiado al modo A. Controla la aeronave manualmente",
    "fr": "Signal GPS faible et positionnement visuel non disponible. Appareil passé en mode A. Contrôlez l\\'appareil manuellement",
    "ja": "GPS 信号が弱く、ビジョンポジショニングが使用できません。機体が A モードに切り替わりました。機体を手動で操作してください",
    "ko": "GPS 신호가 약하고 비전 포지셔닝 사용 불가. 기체가 A 모드로 전환됨. 기체를 수동으로 조종",
    "ru": "Слабый сигнал GPS и визуальное позиционирование недоступно Дрон переведен в режим A. Управляйте дроном вручную",
    "tr": "GPS sinyali zayıf ve görüş konumlandırma kullanılamıyor. Araç A moduna geçti. Aracı manuel olarak kontrol edin",
    "zh": "GPS信号弱且视觉定位失效，飞行器切换至姿态模式，请手动控制飞行器"
  },
  "fpv_tip_0x1B01000F": {
    "de": "Ziel verloren. Suche...",
    "en": "Target lost. Searching...",
    "es": "Se ha perdido el objetivo. Buscando...",
    "fr": "Sujet perdu. Recherche en cours",
    "ja": "ターゲットを見失いました。探索中...",
    "ko": "타겟 사라짐. 검색 중...",
    "ru": "Цель потеряна. Идет поиск...",
    "tr": "Hedef kayıp. Arıyor…",
    "zh": "目标丢失，正在搜索中"
  },
  "fpv_tip_0x1B010010": {
    "de": "Während der smarten Verfolgung können Gimbals innerhalb gewisser Grenzen gesteuert werden",
    "en": "During Smart Track, you can control gimbals within a certain limit",
    "es": "Durante Smart Track, puedes controlar los estabilizadores dentro un límite",
    "fr": "Pendant Smart Track, vous pouvez contrôler les nacelles jusqu\\'à une certaine limite",
    "ja": "スマートトラックの間、一定の制限内でジンバルを制御できます",
    "ko": "스마트 트랙 중에 특정 제한 내에서 짐벌을 제어할 수 있습니다",
    "ru": "При интеллектуальном следовании подвесами можно управлять с соблюдением определенного ограничения",
    "tr": "Akıllı Takip sırasında gimbalları belirli bir sınır içinde kontrol edebilirsiniz",
    "zh": "智能跟踪时只能在一定范围内控制云台"
  },
  "fpv_tip_0x1B010011": {
    "de": "Während der smarten Verfolgung kann das Zoomobjektiv innerhalb gewisser Grenzen gesteuert werden",
    "en": "During Smart Track, you can control lens zoom within a certain limit",
    "es": "Durante Smart Track, puedes controlar el zoom del objetivo dentro un límite",
    "fr": "Pendant Smart Track, vous pouvez contrôler le zoom de l\\'objectif jusqu\\'à une certaine limite",
    "ja": "スマートトラックの間、一定の制限内でレンズズームを制御できます",
    "ko": "스마트 트랙 중에 특정 제한 내에서 렌즈 줌을 제어할 수 있습니다",
    "ru": "При интеллектуальном следовании зумом объектива можно управлять с соблюдением определенного ограничения",
    "tr": "Akıllı Takip sırasında lens yakınlaştırmalarını belirli bir sınır içinde kontrol edebilirsiniz",
    "zh": "智能跟踪时只能在一定范围内控制变焦"
  },
  "fpv_tip_0x1B010401": {
    "de": "Fehler beim Senden von Daten durch die Kamera. Zielerfassung angehalten. Später erneut versuchen",
    "en": "Camera sending data error. Target acquisition stopped. Try again later",
    "es": "Ha fallado el envío de datos de la cámara. Se ha detenido la adquisición de objetivo. Inténtelo de nuevo más tarde",
    "fr": "Erreur d'envoi des données par la caméra. L'acquisition de la cible s'est arrêtée. Réessayez plus tard",
    "ja": "カメラのデータ送信エラーです。ターゲットを見失いました。後で再試行してください",
    "ko": "카메라 전송 데이터 오류. 대상 수집 중단됨. 나중에 다시 시도하세요",
    "ru": "Ошибка отправки данных с камеры. Получение цели остановлено. Повторите попытку позже",
    "tr": "Kamera veri gönderme hatası. Hedef alımı durduruldu. Daha sonra tekrar deneyin",
    "zh": "相机发送数据异常，目标锁定功能停止，请稍后重试"
  },
  "fpv_tip_0x1B010402": {
    "de": "Fehler beim Senden von Daten durch die Kamera. Smart Track angehalten. Später erneut versuchen",
    "en": "Camera sending data error. Smart Track stopped. Try again later",
    "es": "Ha fallado el envío de datos de la cámara. Se ha detenido Smart Track. Inténtelo de nuevo más tarde",
    "fr": "Erreur d'envoi des données par la caméra. Smart Track s'est arrêté. Réessayez plus tard",
    "ja": "カメラのデータ送信エラーです。スマートトラックが停止しました。後で再試行してください",
    "ko": "카메라 전송 데이터 오류. 스마트 트랙 중단됨. 나중에 다시 시도하세요",
    "ru": "Ошибка отправки данных с камеры. Интеллектуальное следование остановлено. Повторите попытку позже",
    "tr": "Kamera veri gönderme hatası. Akıllı Takip durdu. Daha sonra tekrar deneyin",
    "zh": "相机发送数据异常，智能跟踪功能停止，请稍后重试"
  },
  "fpv_tip_0x1B010403": {
    "ar": "",
    "de": "Kamera: Fehler bei Datenversand. Smarte Verfolgung unterbrochen.",
    "en": "Camera data sending error. Smart Track paused.",
    "es": "Error de envío de datos de la cámara. Smart Track pausado.",
    "fr": "Erreur d'envoi des données de la caméra. Smart Track mis en pause.",
    "it": "",
    "ja": "カメラデータ送信エラー。スマートトラック一時停止。",
    "ko": "카메라 데이터 전송 오류. 스마트 트랙 일시 중지됨.",
    "pt": "",
    "ru": "Ошибка отправки данных камеры. Интеллектуальное следование приостановлено.",
    "tr": "Kamera veri gönderme hatası. Akıllı Takip duraklatıldı.",
    "zh": "相机发送数据异常，智能跟踪功能停止"
  },
  "fpv_tip_0x1B010404": {
    "de": "Fehler beim Senden von Daten durch die Kamera. Smart Track angehalten. Später erneut versuchen",
    "en": "Camera sending data error. Smart Track stopped. Try again later",
    "es": "Ha fallado el envío de datos de la cámara. Se ha detenido Smart Track. Inténtelo de nuevo más tarde",
    "fr": "Erreur d'envoi des données par la caméra. Smart Track s'est arrêté. Réessayez plus tard",
    "ja": "カメラのデータ送信エラーです。スマートトラックが停止しました。後で再試行してください",
    "ko": "카메라 전송 데이터 오류. 스마트 트랙 중단됨. 나중에 다시 시도하세요",
    "ru": "Ошибка отправки данных с камеры. Интеллектуальное следование остановлено. Повторите попытку позже",
    "tr": "Kamera veri gönderme hatası. Akıllı Takip durdu. Daha sonra tekrar deneyin",
    "zh": "相机数据发送异常，智能跟踪功能停止，请稍后重试"
  },
  "fpv_tip_0x1B010405": {
    "de": "Fehler beim Senden von Daten durch die Kamera. Smart Track angehalten. Später erneut versuchen",
    "en": "Camera sending data error. Smart Track stopped. Try again later",
    "es": "Ha fallado el envío de datos de la cámara. Se ha detenido Smart Track. Inténtelo de nuevo más tarde",
    "fr": "Erreur d'envoi des données par la caméra. Smart Track s'est arrêté. Réessayez plus tard",
    "ja": "カメラのデータ送信エラーです。スマートトラックが停止しました。後で再試行してください",
    "ko": "카메라 전송 데이터 오류. 스마트 트랙 중단됨. 나중에 다시 시도하세요",
    "ru": "Ошибка отправки данных с камеры. Интеллектуальное следование остановлено. Повторите попытку позже",
    "tr": "Kamera veri gönderme hatası. Akıllı Takip durdu. Daha sonra tekrar deneyin",
    "zh": "相机发送数据异常，智能跟踪功能停止，请稍后重试"
  },
  "fpv_tip_0x1B010406": {
    "de": "Fehler beim Senden von Daten durch die Kamera. Smart Track angehalten. Später erneut versuchen",
    "en": "Camera sending data error. Smart Track stopped. Try again later",
    "es": "Ha fallado el envío de datos de la cámara. Se ha detenido Smart Track. Inténtelo de nuevo más tarde",
    "fr": "Erreur d'envoi des données par la caméra. Smart Track s'est arrêté. Réessayez plus tard",
    "ja": "カメラのデータ送信エラーです。スマートトラックが停止しました。後で再試行してください",
    "ko": "카메라 전송 데이터 오류. 스마트 트랙 중단됨. 나중에 다시 시도하세요",
    "ru": "Ошибка отправки данных с камеры. Интеллектуальное следование остановлено. Повторите попытку позже",
    "tr": "Kamera veri gönderme hatası. Akıllı Takip durdu. Daha sonra tekrar deneyin",
    "zh": "相机发送数据异常，智能跟踪功能停止，请稍后重试"
  },
  "fpv_tip_0x1B010407": {
    "de": "Fehler beim Senden von Daten durch die Kamera. Smart Track angehalten. Später erneut versuchen",
    "en": "Camera sending data error. Smart Track stopped. Try again later",
    "es": "Ha fallado el envío de datos de la cámara. Se ha detenido Smart Track. Inténtelo de nuevo más tarde",
    "fr": "Erreur d'envoi des données par la caméra. Smart Track s'est arrêté. Réessayez plus tard",
    "ja": "カメラのデータ送信エラーです。スマートトラックが停止しました。後で再試行してください",
    "ko": "카메라 전송 데이터 오류. 스마트 트랙 중단됨. 나중에 다시 시도하세요",
    "ru": "Ошибка отправки данных с камеры. Интеллектуальное следование остановлено. Повторите попытку позже",
    "tr": "Kamera veri gönderme hatası. Akıllı Takip durdu. Daha sonra tekrar deneyin",
    "zh": "相机发送数据异常，智能跟踪功能停止，请稍后重试"
  },
  "fpv_tip_0x1B010408": {
    "de": "Fehler beim Senden von Daten durch die Kamera. Smart Track angehalten. Später erneut versuchen",
    "en": "Camera sending data error. Smart Track stopped. Try again later",
    "es": "Ha fallado el envío de datos de la cámara. Se ha detenido Smart Track. Inténtelo de nuevo más tarde",
    "fr": "Erreur d'envoi des données par la caméra. Smart Track s'est arrêté. Réessayez plus tard",
    "ja": "カメラのデータ送信エラーです。スマートトラックが停止しました。後で再試行してください",
    "ko": "카메라 전송 데이터 오류. 스마트 트랙 중단됨. 나중에 다시 시도하세요",
    "ru": "Ошибка отправки данных с камеры. Интеллектуальное следование остановлено. Повторите попытку позже",
    "tr": "Kamera veri gönderme hatası. Akıllı Takip durdu. Daha sonra tekrar deneyin",
    "zh": "相机发送数据异常，智能跟踪功能停止，请稍后重试"
  },
  "fpv_tip_0x1B010801": {
    "de": "Parameter der Kamera geändert. Smarte Verfolgung gestoppt",
    "en": "DJI Pilot error. Target Acquisition paused. Restart DJI Pilot",
    "es": "Parámetros de la cámara modificados. Smart Track detenido",
    "fr": "Paramètres de caméra modifiés. Smart Track interrompu",
    "ja": "カメラパラメーターを変更。スマートトラック停止",
    "ko": "카메라 매개변수 변경됨. 스마트 트랙 중지됨",
    "ru": "Параметры камеры изменены. Интеллектуальное следование остановлено",
    "tr": "Kamera parametreleri değişti. Akıllı Takip durdu",
    "zh": "APP状态异常，目标锁定功能停止，请重启APP"
  },
  "fpv_tip_0x1B010802": {
    "de": "Fehler: DJI Pilot. Smarte Verfolgung unterbrochen. DJI Pilot neu starten",
    "en": "DJI Pilot error. Smart Track paused. Restart DJI Pilot",
    "es": "Error de DJI Pilot. Smart Track pausado. Reinicia DJI Pilot",
    "fr": "Erreur de DJI Pilot. Smart Track mis en pause. Redémarrez DJI Pilot",
    "ja": "DJI Pilotエラー。スマートトラック一時停止。DJI Pilotを再起動してください",
    "ko": "DJI Pilot 오류. 스마트 트랙 일시 중지됨. DJI Pilot 재시작 필요",
    "ru": "Ошибка DJI Pilot. Интеллектуальное следование приостановлено. Перезапустите DJI Pilot",
    "tr": "DJI Pilot hatası. Akıllı Takip duraklatıldı. DJI Pilot’u yeniden başlat",
    "zh": "智能跟踪功能异常，请重启APP"
  },
  "fpv_tip_0x1B010803": {
    "de": "Fehler: DJI Pilot. Smarte Verfolgung unterbrochen. DJI Pilot neu starten",
    "en": "DJI Pilot error. Smart Track paused. Restart DJI Pilot",
    "es": "Error de DJI Pilot. Smart Track pausado. Reinicia DJI Pilot",
    "fr": "Erreur de DJI Pilot. Smart Track mis en pause. Redémarrez DJI Pilot",
    "ja": "DJI Pilotエラー。スマートトラック一時停止。DJI Pilotを再起動してください",
    "ko": "DJI Pilot 오류. 스마트 트랙 일시 중지됨. DJI Pilot 재시작 필요",
    "ru": "Ошибка DJI Pilot. Интеллектуальное следование приостановлено. Перезапустите DJI Pilot",
    "tr": "DJI Pilot hatası. Akıllı Takip duraklatıldı. DJI Pilot’u yeniden başlat",
    "zh": "智能跟踪功能异常，请重启APP"
  },
  "fpv_tip_0x1B010C01": {
    "de": "Ziel verloren. Smarte Verfolgung gestoppt",
    "en": "Flight Controller data sending error. Target Acquisition paused. Restart aircraft",
    "es": "Se ha perdido el objetivo. Smart Track detenido",
    "fr": "Sujet perdu. Smart Track interrompu",
    "ja": "ターゲットを見失いました。スマートトラック終了",
    "ko": "타겟 사라짐. 스마트 트랙 중지됨",
    "ru": "Цель потеряна. Интеллектуальное следование остановлено",
    "tr": "Hedef kayıp. Akıllı Takip durdu",
    "zh": "飞控数据发送异常，目标锁定功能停止，请重启飞行器"
  },
  "fpv_tip_0x1B010C02": {
    "de": "Flugregler: Fehler bei Datenversand. Smarte Verfolgung unterbrochen. Fluggerät neu starten",
    "en": "Flight Controller data sending error. Smart Track paused. Restart aircraft",
    "es": "Error de envío de datos del controlador de vuelo. Smart Track pausado. Reinicia la aeronave",
    "fr": "Erreur d'envoi des données du contrôleur de vol. Smart Track mis en pause. Redémarrez l'appareil",
    "ja": "フライトコントローラー データ送信エラー。スマートトラック一時停止。機体を再起動してください",
    "ko": "비행 컨트롤러 데이터 전송 오류. 스마트 트랙 일시 중지됨. 기체 재시작 필요",
    "ru": "Ошибка отправки данных полетного контроллера. Интеллектуальное следование приостановлено. Перезагрузите дрон",
    "tr": "Uçuş Kumandası veri gönderme hatası. Akıllı Takip duraklatıldı. Aracı yeniden başlatın",
    "zh": "飞控数据发送异常，智能跟踪功能停止，请重启飞行器"
  },
  "fpv_tip_0x1B010C03": {
    "de": "Flugregler: Fehler bei Datenversand. Smarte Verfolgung unterbrochen. Fluggerät neu starten",
    "en": "Flight Controller data sending error. Smart Track paused. Restart aircraft",
    "es": "Error de envío de datos del controlador de vuelo. Smart Track pausado. Reinicia la aeronave",
    "fr": "Erreur d'envoi des données du contrôleur de vol. Smart Track mis en pause. Redémarrez l'appareil",
    "ja": "フライトコントローラー データ送信エラー。スマートトラック一時停止。機体を再起動してください",
    "ko": "비행 컨트롤러 데이터 전송 오류. 스마트 트랙 일시 중지됨. 기체 재시작 필요",
    "ru": "Ошибка отправки данных полетного контроллера. Интеллектуальное следование приостановлено. Перезагрузите дрон",
    "tr": "Uçuş Kumandası veri gönderme hatası. Akıllı Takip duraklatıldı. Aracı yeniden başlatın",
    "zh": "飞控数据发送异常，智能跟踪功能停止，请重启飞行器"
  },
  "fpv_tip_0x1B011001": {
    "de": "Gimbal: Fehler bei Datenversand. Smarte Verfolgung gestoppt. Nutzlast neu installieren",
    "en": "Gimbal data sending error. Target Acquisition paused. Reinstall payload",
    "es": "Error de envío de datos del estabilizador. Smart Track detenido. Vuelve a instalar el instrumento",
    "fr": "Erreur d\\'envoi de données de la nacelle. Smart Track interrompu. Réinstallez la charge utile",
    "ja": "ジンバルデータ送信エラー。スマートトラック停止。ペイロードを再取り付けしてください",
    "ko": "짐벌 데이터 전송 오류. 스마트 트랙 중지됨. 페이로드 재설치",
    "ru": "Ошибка отправки данных подвеса. Интеллектуальное следование остановлено. Переустановите полезную нагрузку",
    "tr": "Gimbal veri gönderme hatası. Akıllı Takip durdu. Yükü tekrar takın",
    "zh": "云台数据发送异常，目标锁定功能停止，请重新拔插负载"
  },
  "fpv_tip_0x1B011002": {
    "de": "Gimbal: Fehler bei Datenversand. Smarte Verfolgung unterbrochen. Nutzlast neu installieren",
    "en": "Gimbal data sending error. Smart Track paused. Reinstall payload",
    "es": "Error de envío de datos del estabilizador. Smart Track pausado. Vuelve a instalar el instrumento",
    "fr": "Erreur d'envoi des données de la nacelle. Smart Track mis en pause. Réinstallez la nacelle-caméra",
    "ja": "ジンバルデータ送信エラー。スマートトラック一時停止。ペイロードを再度取り付けてください",
    "ko": "짐벌 데이터 전송 오류. 스마트 트랙 일시 중지됨. 페이로드 재설치 필요",
    "ru": "Ошибка отправки данных стабилизатора. Интеллектуальное следование приостановлено. Установите оборудование снова",
    "tr": "Gimbal veri gönderme hatası. Akıllı Takip duraklatıldı. Yükü tekrar takın",
    "zh": "云台数据发送异常，智能跟踪功能停止，请重新拔插负载"
  },
  "fpv_tip_0x1B011003": {
    "de": "Gimbal: Fehler bei Datenversand. Smarte Verfolgung unterbrochen. Nutzlast neu installieren",
    "en": "Gimbal data sending error. Smart Track paused. Reinstall payload",
    "es": "Error de envío de datos del estabilizador. Smart Track pausado. Vuelve a instalar el instrumento",
    "fr": "Erreur d'envoi des données de la nacelle. Smart Track mis en pause. Réinstallez la nacelle-caméra",
    "ja": "ジンバルデータ送信エラー。スマートトラック一時停止。ペイロードを再度取り付けてください",
    "ko": "짐벌 데이터 전송 오류. 스마트 트랙 일시 중지됨. 페이로드 재설치 필요",
    "ru": "Ошибка отправки данных стабилизатора. Интеллектуальное следование приостановлено. Установите оборудование снова",
    "tr": "Gimbal veri gönderme hatası. Akıllı Takip duraklatıldı. Yükü tekrar takın",
    "zh": "云台数据发送异常，智能跟踪功能停止，请重新拔插负载"
  },
  "fpv_tip_0x1B011801": {
    "de": "Fernsteuerung: Fehler bei Datenversand. Smarte Verfolgung gestoppt. Fernsteuerung neu starten",
    "en": "Remote controller data sending error. Target Acquisition stopped. Restart RC",
    "es": "Error de envío de datos del control remoto. Smart Track detenido. Reinicia el CR",
    "fr": "Erreur d\\'envoi de données de la radiocommande (RC). Smart Track interrompu. Redémarrez la RC",
    "ja": "送信機データ送信エラー。スマートトラック停止。送信機を再起動してください",
    "ko": "조종기 데이터 전송 오류. 스마트 트랙 중지됨. 조종기 다시 시작",
    "ru": "Ошибка отправки данных пульта ДУ. Интеллектуальное следование остановлено. Перезапустите пульт ДУ",
    "tr": "Uzaktan kumanda veri gönderme hatası. Akıllı Takip durdu. Uzaktan kumandayı tekrar başlatın",
    "zh": "遥控器数据发送异常，目标锁定功能停止，请重启遥控器"
  },
  "fpv_tip_0x1B011802": {
    "de": "Fernsteuerung: Fehler bei Datenversand. Smarte Verfolgung gestoppt. Verbindung zwischen Fernsteuerung und Fluggerät überprüfen",
    "en": "Remote controller data sending error. Target Acquisition paused. Check connection between RC and aircraft",
    "es": "Error de envío de datos del control remoto. Smart Track detenido. Comprueba la conexión entre el CR y la aeronave",
    "fr": "Erreur d\\'envoi de données de la radiocommande (RC). Smart Track interrompu. Vérifiez la connexion entre la RC et l\\'appareil",
    "ja": "送信機データ送信エラー。スマートトラック停止。送信機と機体の接続を確認してください",
    "ko": "조종기 데이터 전송 오류. 스마트 트랙 중지됨. 조종기와 기체 간 연결을 확인하세요",
    "ru": "Ошибка отправки данных пульта ДУ. Интеллектуальное следование остановлено. Проверьте соединение между пультом ДУ и дроном",
    "tr": "Uzaktan kumanda veri gönderme hatası. Akıllı Takip durdu. Uzaktan kumanda ve araç arasındaki bağlantıyı kontrol edin",
    "zh": "遥控器数据发送异常，目标锁定功能停止，请检查遥控器与飞行器的连接状况"
  },
  "fpv_tip_0x1B030001": {
    "de": "Hindernis erkannt. Rückkehr gestoppt. Fluggerät manuell steuern, um Hindernissen auszuweichen",
    "en": "Obstacle detected. RTH stopped. Manually control aircraft to avoid obstacle",
    "es": "Obstáculo detectado. RPO detenido. Controla la aeronave manualmente para evitar los obstáculos",
    "fr": "Obstacle détecté. RTH interrompu. Contrôlez l\\'appareil manuellement pour éviter l\\'obstacle",
    "ja": "障害物を検出。RTHを停止。機体を手動で操作し、障害物を避けてください",
    "ko": "장애물 감지됨. RTH 중단.  기체 수동으로 제어해 장애물 피해야 함",
    "ru": "Определено препятствие. Возврат домой остановлен. Управляйте дроном вручную, чтобы избегать препятствия",
    "tr": "Engel tespit edildi. Eve dönüş durdu. Engelleri aşmak için aracı manuel olarak kontrol edin",
    "zh": "遇到障碍物，自动返航停止，请手动控制飞机远离障碍"
  },
  "fpv_tip_0x1B030002": {
    "de": "GEO-Zone in der Nähe. Rückkehr gestoppt. Bitte manuell steuern, um GEO-Zone auszuweichen",
    "en": "GEO Zone nearby. RTH stopped. Manually control aircraft to avoid GEO Zone",
    "es": "Zona GEO cercana. RPO detenido. Controla la aeronave manualmente para evitar la zona GEO",
    "fr": "Zone GEO proche. RTH interrompu. Contrôlez l\\'appareil manuellement pour éviter la zone GEO",
    "ja": "GEO区域が近くにあります。RTHを停止。機体を手動で操作し、GEO区域を避けてください",
    "ko": "GEO 구역 인근. RTH 중단. 기체 수동으로 제어해 GEO 구역을 피해야 함",
    "ru": "Зона GEO неподалеку. Возврат домой остановлен. Управляйте дроном вручную, чтобы не попасть в нее",
    "tr": "GEO Bölgesi yakın. Eve dönüş durdu. GEO Bölgesine girişi önlemek için aracı manuel olarak kontrol edin",
    "zh": "遇到限飞区，自动返航停止，请手动控制飞机远离限飞区"
  },
  "fpv_tip_0x1B030003": {
    "de": "Rückkehrfehler. Fluggerät manuell steuern",
    "en": "RTH error. Fly aircraft manually",
    "es": "Error de RPO. Vuela la aeronave manualmente",
    "fr": "Erreur RTH. Contrôlez l\\'appareil manuellement",
    "ja": "RTHエラー。機体を手動で飛行してください",
    "ko": "RTH 오류. 기체 수동 비행 필요",
    "ru": "Ошибка возврата домой. Управляйте дроном вручную",
    "tr": "Eve dönüş hatası. Aracı manuel olarak uçurun",
    "zh": "自动返航异常，请切换至手动飞行"
  },
  "fpv_tip_0x1B030004": {
    "de": "Fluggerätsignal verloren. Rückkehrfunktion (RTH) gestartet",
    "en": "Aircraft signal lost. RTH started",
    "es": "Se ha perdido la señal de la aeronave. RPO iniciado",
    "fr": "Signal de l'appareil perdu. RTH lancé",
    "ja": "機体の信号ロストです。RTHが開始しました",
    "ko": "기체 신호 끊김. RTH 시작됨",
    "ru": "Сигнал дрона потерян. Возврат домой начался",
    "tr": "Hava aracı sinyali kayboldu. Eve Dönüş başladı",
    "zh": "飞行器失联，开始返航"
  },
  "fpv_tip_0x1B030005": {
    "de": "Aktivieren der Hindernisvermeidung bei Rückkehr fehlgeschlagen. Fluggerät manuell steuern, um zurückzukehren",
    "en": "Enabling RTH Obstacle Check failed. Control aircraft to return to home manually",
    "es": "Error al activar la comprobación de obstáculos de RPO. Controla la aeronave manualmente para regresar al punto de origen",
    "fr": "Impossible d\\'activer la détection d\\'obstacles RTH. Contrôlez l\\'appareil manuellement pour retourner au point de départ",
    "ja": "RTH障害物確認の有効化に失敗しました。機体を操作して手動でホームに帰還してください",
    "ko": "\\'RTH 장애물 확인\\' 활성화 실패. 기체 수동으로 제어해 RTH 진행해야 함",
    "ru": "Сбой активации проверки препятствий при возврате домой. Управляйте дроном вручную",
    "tr": "Eve Dönüş Engel Kontrolü etkinleştirme başarısız. Aracı eve döndürmek için manuel olarak kumanda edin",
    "zh": "自动返航避障功能启动失败，请切换至手动返航"
  },
  "fpv_tip_0x1B030006": {
    "de": "Hindernis oder Gegenlicht erkannt. Pause-Taste auf der Fernsteuerung drücken, um autom. Rückkehr abzubrechen und das Fluggerät manuell zurückzufliegen",
    "en": "Obstacle avoidance or backlight detected. Press the Pause button on the remote controller to exit RTH and manually control aircraft to return to home",
    "es": "Detectado sistema anticolisión o luz trasera. Pulsa el botón de pausa del control remoto para abandonar el RPO y controla manualmente la aeronave para regresar al punto de origen",
    "fr": "Évitement d'obstacles ou rétroéclairage détecté. Appuyez sur le bouton Pause de la radiocommande pour sortir du RTH et contrôler manuellement le retour au point de départ",
    "ja": "障害物または逆光を検知。送信機の一時停止ボタンを押してRTHを終了し、手動で機体を制御して帰還させてください",
    "ko": "장애물 회피 또는 백라이트 감지. 조종기의 '일시 정지' 버튼 눌러 RTH 종료하고 기체 수동으로 홈 복귀 필요",
    "ru": "Обнаружено распознавание препятствий или подсветка. Нажмите кнопку паузы на пульте управления, чтобы отменить возврат домой, и вручную управляйте дроном, чтобы вернуться в домашнюю точку",
    "tr": "Engellerden kaçınma veya arka ışık tespit edildi. Hava aracının RTH özelliği iptal edildi",
    "zh": "遇到障碍物或环境逆光，请按遥控器上暂停键退出自动返航，进行手动返航"
  },
  "fpv_tip_0x1B030007": {
    "de": "Steuerknüppel nach unten gedrückt. Fluggerät sinkt. Rückkehr abgebrochen",
    "en": "Control stick pushed down. Aircraft descending. Aircraft RTH canceled",
    "es": "Palanca de control movida hacia abajo. Aeronave en descenso. RPO cancelado",
    "fr": "Joystick abaissé. L'appareil descend. RTH appareil annulé",
    "ja": "操作スティックが下に倒され、機体が下降中。機体のRTHはキャンセルされました",
    "ko": "조종 스틱 아래로 눌림. 기체 하강. 기체 RTH 취소됨",
    "ru": "Джойстик опущен вниз. Дрон снижается. Возврат домой отменен",
    "tr": "Kontrol çubuğu aşağı bastırıldı. Hava aracı alçalıyor. Hava aracının RTH özelliği iptal edildi",
    "zh": "检测到打杆下降，已退出自动返航"
  },
  "fpv_tip_0x1B030008": {
    "de": "Steuerknüppel nach unten gedrückt. Fluggerät fliegt rückwärts. Rückkehr abgebrochen",
    "en": "Control stick pushed down. Aircraft flying backward. Aircraft RTH canceled",
    "es": "Palanca de control movida hacia abajo. Aeronave volando hacia atrás. RPO cancelado",
    "fr": "Joystick abaissé. L'appareil vole vers l'arrière. RTH appareil annulé",
    "ja": "操作スティックが下に倒され、機体は後方に飛行。機体のRTHはキャンセルされました",
    "ko": "조종 스틱 아래로 눌림. 기체 후방 비행. 기체 RTH 취소됨",
    "ru": "Джойстик опущен вниз. Дрон летит назад. Возврат домой отменен",
    "tr": "Kontrol çubuğu aşağı bastırıldı. Hava aracı geri geri uçuyor. Hava aracının RTH özelliği iptal edildi",
    "zh": "检测到打杆后退，已退出自动返航"
  },
  "fpv_tip_0x1B03000D": {
    "de": "Schlechte Positionierung beim Ausführen der Rückkehrfunktion (RTH). Rückkehrfunktion (RTH) kann nicht fortgesetzt werden. Fluggerät von Hand zurückfliegen",
    "en": "Positioning poor during RTH. Unable to continue RTH. Manually control aircraft to return to home",
    "es": "Posicionamiento deficiente durante RPO. El RPO no puede continuar. Controle la aeronave manualmente para regresar al punto de origen",
    "fr": "Mauvais positionnement pendant le RTH. Impossible de continuer le RTH. Contrôlez manuellement l'appareil pour la fonction Return-to-Home",
    "ja": "RTH中の測位が不良。RTHを続行できません。手動で機体を操作して帰還させてください",
    "ko": "RTH 중 포지셔닝 불량. RTH를 계속 진행할 수 없습니다. 기체를 수동으로 조종해 RTH를 수행하세요",
    "ru": "Несоответствующее позиционирование во время возврата домой. Не удается продолжить возврат домой. Вручную управляйте дроном, чтобы вернуться домой",
    "tr": "RTH sırasında konumlama zayıf. RHT'ye devam edilemiyor. Başlangıç noktasına dönmek için hava aracını manuel olarak kontrol edin",
    "zh": "返航过程中定位不佳，无法继续返航。请手动控制飞机返航"
  },
  "fpv_tip_0x1B030010": {
    "de": "Es wird bald Abend. Einstellung der RTH-Flughöhe empfohlen.",
    "en": "Approaching evening. Setting RTH altitude recommended",
    "es": "Se acerca la noche. Configuración de la altitud de RPO recomendada",
    "fr": "Approche du soir. Réglage de l'altitude RTH recommandé",
    "ja": "夕刻に近づいています。RTH高度の設定が推奨されます",
    "ko": "저녁이 가까워지고 있습니다. RTH 고도 설정을 권장합니다.",
    "ru": "Приближается вечер. Рекомендуется установка высоты RTH",
    "tr": "Akşam yaklaşıyor. BND irtifasının ayarlanması önerilir",
    "zh": "临近夜晚，请提前设置好返航高度"
  },
  "fpv_tip_0x1B030011": {
    "de": "Fehler beim Fliegen der optimalen Rückkehrroute. Fluggerät kehrt auf voreingestellter Flughöhe zurück",
    "en": "Failed to fly optimal RTH route. Aircraft returning at preset altitude",
    "es": "No se ha podido volar por la ruta RPO óptima. La aeronave está regresando a la altitud preestablecida.",
    "fr": "Échec de trajectoire RTH optimale. L'appareil retourne au point de départ à une altitude prédéfinie",
    "ja": "最適なRTHルートの飛行に失敗。機体はプリセット高度で帰還中です",
    "ko": "최적의 RTH 경로로 비행 실패. 기체가 사전 설정된 고도에서 귀환 중",
    "ru": "Не удалось выполнить полет по оптимальному маршруту возврата на базу. Дрон возвращается на заданную высоту",
    "tr": "Optimum BND rotasında uçulamıyor. Hava aracı önceden ayarlanan irtifaya dönüyor",
    "zh": "智能返航失效，将执行设定高度返航"
  },
  "fpv_tip_0x1B030012": {
    "de": "Fluggerät beschleunigt. Akku entlädt sich schneller",
    "en": "Aircraft accelerating. Battery draining faster",
    "es": "Aeronave acelerando. La batería se está agotando más rápido.",
    "fr": "Appareil en cours d'accélération. La batterie se décharge plus vite",
    "ja": "機体が加速中。バッテリーの消耗が早くなります",
    "ko": "기체 가속 중. 배터리 소모 속도 증가 중",
    "ru": "Дрон разгоняется. АКБ разряжается быстрее",
    "tr": "Hava aracı hızlanıyor. Pil daha hızlı tükeniyor",
    "zh": "返航加速中，电量消耗加速"
  },
  "fpv_tip_0x1B030013": {
    "de": "Umgekehrte Steuerknüppelbewegung erkannt. Rückkehrfunktion (RTH) beendet",
    "en": "RTH stopped. Control stick moved opposite to flight direction",
    "es": "Se ha detectado el movimiento inverso de la palanca de control. Se ha salido del RPO",
    "fr": "Joystick en mouvement détecté en sens inverse. RTH quitté",
    "ja": "逆方向への操作スティックの動きを検出しました。RTHを終了しました",
    "ko": "조종 스틱 역방향 움직임이 감지되었습니다. RTH 종료됨",
    "ru": "Обнаружено перемещение ручки управления в обратном направлении. Выполнен выход из режима возврата домой",
    "tr": "Kontrol çubuğunun ters hareketi algılandı. RTH'den çıkıldı",
    "zh": "返航过程中用户反向打杆，退出返航。"
  },
  "fpv_tip_0x1B030014": {
    "ar": "",
    "de": "",
    "en": "Battery level low. Return to home promptly",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "电量不足请尽快返航",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B030015": {
    "de": "Startpunkt in der Höhenlagezone. Das Fluggerät kehrt in niedrigerer Höhe zurück",
    "en": "Home Point in Altitude Zone. Aircraft will return at lower altitude",
    "es": "Punto de origen en la zona de altitud. Las aeronaves regresarán a menor altitud",
    "fr": "Point de départ dans une zone à altitude limitée. L’appareil reviendra à une altitude inférieure",
    "ja": "高度制限区域のホームポイント。機体は高度を下げて帰還します",
    "ko": "고도 제한 구역 내에 홈포인트. 기체가 더 낮은 고도에서 돌아갑니다",
    "ru": "Домашняя точка в зоне ограничения высоты полета. Дрон вернется на меньшей высоте",
    "tr": "Başlangıç Noktası İrtifa Bölgesinde. Hava aracı daha düşük irtifada dönecek",
    "zh": "返航点在限高区内，将降低返航高度"
  },
  "fpv_tip_0x1B030016": {
    "de": "Komplexes Umfeld. Rückkehrfunktion (RTH) angehalten. Fluggerät von Hand zurückfliegen",
    "en": "Complex environment. RTH stopped. Manually control aircraft to return to home",
    "es": "Entorno complejo. RPO detenido. Controle la aeronave manualmente para regresar al punto de origen",
    "fr": "Environnement complexe. RTH arrêté. Contrôlez manuellement l'appareil pour la fonction Return-to-Home",
    "ja": "環境が複雑です。RTHが停止しました。手動で機体を操作して帰還させてください",
    "ko": "환경이 복잡합니다. RTH 중단됨. 기체를 수동으로 조종해 RTH를 수행하세요",
    "ru": "Сложная среда. Возврат домой остановлен. Вручную управляйте дроном, чтобы вернуться домой",
    "tr": "Karmaşık ortam. RTH durduruldu. Başlangıç noktasına dönmek için hava aracını manuel olarak kontrol edin",
    "zh": "环境复杂，返航中断，请手动返航。"
  },
  "fpv_tip_0x1B030017": {
    "de": "Akku schwach. Fluggerät muss möglicherweise zwangslanden. Vorher nach einem geeigneten Landeplatz Ausschau halten",
    "en": "Low battery. Aircraft may be forced to land. Find appropriate place to land in advance",
    "es": "Batería baja. La aeronave puede verse obligada a aterrizar. Encuentre el lugar apropiado para aterrizar con anticipación",
    "fr": "Batterie faible. L'appareil pourra être forcé d'atterrir. Trouvez un endroit approprié pour atterrir à l'avance",
    "ja": "ローバッテリー。機体が強制着陸する可能性があります。事前に適切な着陸場所を見つけてください",
    "ko": "배터리 부족. 기체를 강제 착륙시킬 수 있습니다. 먼저 적절한 착륙 장소를 찾으세요",
    "ru": "Низкий заряд аккумулятора. Дрон может быть вынужден приземлиться. Найдите подходящее место для посадки заранее",
    "tr": "Düşük pil. Hava aracı inişte zorlanabilir. Önce inmek için uygun yeri bulun",
    "zh": "返航过程中估计的电量无法成功返航，可能提前低电量降落"
  },
  "fpv_tip_0x1B030018": {
    "de": "Max. Flughöhenlimit zu niedrig. Flugsicherheit beim Ausführen der Rückkehrfunktion (RTH) beeinträchtigt",
    "en": "Max flight altitude limit too low. Flight safety during RTH affected",
    "es": "Límite de altitud máxima de vuelo demasiado bajo. Seguridad de vuelo durante RPO afectada",
    "fr": "Limite d'altitude en vol max trop basse. Sécurité en vol pendant le RTH affectée",
    "ja": "飛行高度の上限値が低すぎます。RTH中の飛行安全性に影響します",
    "ko": "최고 비행 고도 한도가 너무 낮습니다. RTH 중 비행 안전에 영향을 미칩니다",
    "ru": "Ограничение по максимальной высоте слишком низкое. Это может повлиять на безопасность полета во время возврата домой",
    "tr": "Maksimum uçuş irtifası sınırı çok düşük. RHT esnasında uçuş güvenliği etkilendi",
    "zh": "限高过低影响返航飞行安全"
  },
  "fpv_tip_0x1B030019": {
    "de": "Fluggerät fliegt nicht entlang der Route. Sichere Rückkehrfunktion beendet.",
    "en": "Aircraft not flying along route. Safe RTH exited",
    "es": "Aeronave que no vuela por la ruta. Salió de RPO seguro",
    "fr": "L’appareil ne suit pas la trajectoire. RTH sécurisé quitté",
    "ja": "機体はルートに沿って飛行していません。セーフRTHが終了しました",
    "ko": "기체가 경로를 따라 비행하지 않음. 안전한 RTH가 종료되었습니다.",
    "ru": "Дрон не летит по маршруту. Функция безопасного возврата домой отключена",
    "tr": "Hava aracı rota üzerinde uçmuyor. Güvenli BND'den çıkıldı",
    "zh": "飞行器已偏离航线，航线安全返航失效"
  },
  "fpv_tip_0x1B03001A": {
    "de": "Sichtpositionierung nicht verfügbar. Rückkehr zum Start nicht möglich. Vergewissere dich, dass der Bereich unter dem Fluggerät sicher ist, und lande das Flugzeug manuell",
    "en": "Vision positioning unavailable. Unable to return to home. Confirm area below is safe and land aircraft manually",
    "es": "Posicionamiento visual no disponible. No se puede regresar al punto de origen. Confirma que el área inferior es segura y aterriza la aeronave manualmente",
    "fr": "Positionnement visuel indisponible. Impossible de revenir au point de départ. Vérifiez que la zone ci-dessous est sûre et faites atterrir l’appareil manuellement",
    "ja": "ビジョンポジショニングを使用できません。ホームに帰還できません。下の領域の安全を確認し、機体を手動で着陸させてください",
    "ko": "비전 포지셔닝 이용 불가. 리턴 투 홈 불가. 아래 영역이 안전한지 확인하고 기체를 수동으로 착륙시킵니다",
    "ru": "Визуальное позиционирование недоступно. Не удается выполнить возврат домой. Убедитесь, что зона ниже безопасна, и посадите дрон вручную",
    "tr": "Görüş konumlandırma kullanılamıyor. Kalkış noktasına geri dönülemiyor. Aşağıdaki alanın güvenli olduğunu doğrulayın ve hava aracını manuel olarak indirin",
    "zh": "视觉定位失效无法继续返航，请确认下方安全后手动降落"
  },
  "fpv_tip_0x1B030C01": {
    "zh": ""
  },
  "fpv_tip_0x1B030C02": {
    "de": "Schwaches GPS-Signal. RTH-Genauigkeit ist beeinträchtigt. Manuelle Rückkehr wird empfohlen",
    "en": "GPS signal weak. RTH accuracy affected. Manual RTH recommended",
    "es": "Señal GPS débil. Precisión de RPO afectada. Se recomienda RPO manual",
    "fr": "Signal GPS faible. Précision du RTH affectée. RTH manuel recommandé",
    "ja": "GPS信号 弱。RTHの精度に影響があります。手動でのRTHを推奨します",
    "ko": "GPS 신호 약함. RTH 정확도 영향받음. 수동 RTH 권장",
    "ru": "Слабый сигнал GPS снижает точность при возврате домой. Управляйте дроном вручную",
    "tr": "GPS sinyali zayıf. Eve Dönüş kesinliği etkilendi. Manuel olarak eve dönüş önerilmekte",
    "zh": "GPS信号弱，自动返航精度低，建议切换至手动返航"
  },
  "fpv_tip_0x1B040001": {
    "de": "KI-Stellenüberprüfung mit aktueller Nutzlast nicht verfügbar",
    "en": "Focused shooting unavailable with current payload",
    "es": "Focalización por inteligencia artificial no disponible con el instrumento actual",
    "fr": "AI Spot-Check indisponible avec la charge utile actuelle",
    "ja": "現在のペイロードではAIスポット確認ができません",
    "ko": "이 페이로드는 \\'AI 현장 확인\\' 사용 불가",
    "ru": "Фокусировка недоступна при съемке с данным оборудованием",
    "tr": "Yapay Zeka Nokta Kontrolü mevcut yük ile kullanılamıyor",
    "zh": "当前负载不支持精准拍照"
  },
  "fpv_tip_0x1B040002": {
    "de": "KI-Stellenüberprüfung fehlgeschlagen . Zur normalen Aufnahme gewechselt",
    "en": "Focused shooting failed . Switched to normal shooting mode",
    "es": "Error de Focalización por inteligencia artificial. Cambiado al modo de captura Normal",
    "fr": "AI Spot-Check échoué . Passage au mode de prise de vue normal",
    "ja": "AIスポットチェックに失敗しました。ノーマル撮影モードに切り替えます",
    "ko": "\\'AI 현장 확인\\' 실패 . 일반 촬영 모드로 전환됨",
    "ru": "Сбой съемки с фокусировкой . Включен обычный режим",
    "tr": "Yapay Zeka Nokta Kontrolü başarısız. Normal çekim moduna geçti",
    "zh": "精准拍照失败，执行普通拍照"
  },
  "fpv_tip_0x1B040003": {
    "de": "KI-Stellenüberprüfung fehlgeschlagen. Zum normalen Aufnahmemodus gewechselt",
    "en": "AI Spot-Check failed. Switched to normal shooting mode",
    "es": "Error de Focalización por inteligencia artificial. Cambiado al modo de captura Normal",
    "fr": "Échec d'AI spot-check. Mode de prise de vue normal activé",
    "ja": "AIスポットチェックに失敗しました。ノーマル撮影モードに切り替えます",
    "ko": "AI 현장 확인 실패. 일반 촬영 모드로 전환됨",
    "ru": "Не удалось выполнить выборочную проверку с помощью ИИ. Переключено на обычный режим съемки",
    "tr": "Yapay Zeka Nokta Kontrolü başarısız. Normal çekim moduna geçti",
    "zh": "精准复拍失败，执行普通拍照"
  },
  "fpv_tip_0x1B040004": {
    "de": "Kamera nicht befestigt. KI-Stellenüberprüfung fehlgeschlagen",
    "en": "Camera not mounted. Focused shooting failed",
    "es": "La cámara no está montada. Error de Focalización por inteligencia artificial",
    "fr": "Caméra non installée. AI Spot-Check échoué",
    "ja": "カメラが搭載されていません。AIスポットチェックに失敗しました",
    "ko": "카메라 장착 안 됨. \\'AI 현장 확인\\' 실패",
    "ru": "Камера не установлена. Сбой съемки с фокусировкой",
    "tr": "Kamera monte edilmemiş. Yapay Zeka Nokta Kontrolü başarısız",
    "zh": "未挂载相机，精准拍照失败"
  },
  "fpv_tip_0x1B040401": {
    "de": "Zeitüberschreitung beim Kamerazoom. KI-Stellenüberprüfung fehlgeschlagen. Später erneut versuchen",
    "en": "Camera zoom timed out. AI Spot-Check failed. Try again later",
    "es": "Se ha agotado el tiempo de zoom de la cámara. Ha fallado la focalización por IA (AI Spot-Check). Inténtelo de nuevo más tarde",
    "fr": "Expiration du dézoom de la caméra. Échec de l'AI Spot-Check. Réessayez plus tard",
    "ja": "カメラのズームがタイムアウトしました。AIスポット点検に失敗しました。後で再試行してください",
    "ko": "카메라 줌 시간 초과. AI 현장 확인 실패. 나중에 다시 시도하세요",
    "ru": "Время ожидания регулировки зума камеры истекло. Ошибка выборочной проверки с помощью ИИ. Повторите попытку позже",
    "tr": "Kamera yakınlaştırma süresi doldu. Yapay Zeka Nokta Kontrolü başarısız. Daha sonra tekrar deneyin",
    "zh": "相机变焦超时，精准复拍失败，请稍后重试"
  },
  "fpv_tip_0x1B040402": {
    "de": "Wechsel des Aufnahmemodus fehlgeschlagen. KI-Stellenüberprüfung fehlgeschlagen. Später erneut versuchen",
    "en": "Switching shooting modes failed. AI Spot-Check failed. Try again later",
    "es": "Ha fallado el cambio de modo de captura. Ha fallado la focalización por IA (AI Spot-Check). Inténtelo de nuevo más tarde",
    "fr": "Échec du changement de mode de prise de vue. Échec de l'AI Spot-Check. Réessayez plus tard",
    "ja": "撮影モードの切り替えに失敗しました。AIスポット点検に失敗しました。後で再試行してください",
    "ko": "촬영 모드 전환 실패. AI 현장 확인 실패. 나중에 다시 시도하세요",
    "ru": "Сбой переключения режимов съемки. Ошибка выборочной проверки с помощью ИИ. Повторите попытку позже",
    "tr": "Çekim modları değiştirme başarısız. Yapay Zeka Nokta Kontrolü başarısız. Daha sonra tekrar deneyin",
    "zh": "切换相机拍照模式失败，精准复拍失败，请稍后重试"
  },
  "fpv_tip_0x1B040403": {
    "de": "Zeitüberschreitung beim Kamerazoom. KI-Stellenüberprüfung fehlgeschlagen. Später erneut versuchen",
    "en": "Camera zoom timed out. AI Spot-Check failed. Try again later",
    "es": "Se ha agotado el tiempo de zoom de la cámara. Ha fallado la focalización por IA (AI Spot-Check). Inténtelo de nuevo más tarde",
    "fr": "Expiration du dézoom de la caméra. Échec de l'AI Spot-Check. Réessayez plus tard",
    "ja": "カメラのズームがタイムアウトしました。AIスポット点検に失敗しました。後で再試行してください",
    "ko": "카메라 줌 시간 초과. AI 현장 확인 실패. 나중에 다시 시도하세요",
    "ru": "Время ожидания регулировки зума камеры истекло. Ошибка выборочной проверки с помощью ИИ. Повторите попытку позже",
    "tr": "Kamera yakınlaştırma süresi doldu. Yapay Zeka Nokta Kontrolü başarısız. Daha sonra tekrar deneyin",
    "zh": "相机变焦超时，精准复拍失败，请稍后重试"
  },
  "fpv_tip_0x1B040801": {
    "de": "Beispiel für KI-Stellenüberprüfung kann nicht aufgefunden werden. Flugroute erneut hochladen",
    "en": "Unable to locate focused shooting sample. Reupload flight route",
    "es": "No se puede localizar la muestra de Focalización por inteligencia artificial. Vuelve a cargar la ruta de vuelo",
    "fr": "Impossible de localiser l\\'échantillon AI Spot-Check. Transférez-le à nouveau",
    "ja": "AIスポットチェックサンプルが見つかりません。飛行ルートを再アップロードしてください",
    "ko": "\\'AI 현장 확인\\' 샘플 위치 찾을 수 없음. 비행경로 다시 업로드해야 함",
    "ru": "Невозможно найти образец съемки с фокусировкой. Перезагрузите маршрут полета",
    "tr": "Yapay Zeka Nokta Kontrolü örneğini konumlandıramıyor. Uçuş rotasını yeniden yükle",
    "zh": "精准拍照示意图未找到，请重新上传航线"
  },
  "fpv_tip_0x1B040802": {
    "de": "Fehler: Zielmarkierung neu einstellen",
    "en": "Target box parameter error. Readjust target box",
    "es": "Error de parámetros del cuadro de objetivo. Vuelve a ajustarlo",
    "fr": "Erreur de réglage du cadre du sujet. Réajustez-le",
    "ja": "被写体ボックス パラメーター エラー。被写体ボックスを再調整してください",
    "ko": "피사체 박스 매개변수 오류. 피사체 박스 재조정 필요",
    "ru": "Ошибка параметров рамки. Измените ее размеры",
    "tr": "Hedef kutu parametre hatası. Hedef kutusunu tekrar ayarlayın",
    "zh": "目标框参数错误，请调整目标框"
  },
  "fpv_tip_0x1B041001": {
    "de": "Gimbalfehler. KI-Stellenüberprüfung fehlgeschlagen. Nutzlast neu installieren",
    "en": "Gimbal error. Focused shooting failed. Reinstall payload",
    "es": "Error del estabilizador. Error de Focalización por inteligencia artificial. Vuelve a instalar el instrumento",
    "fr": "Erreur nacelle. AI Spot-Check échouée. Réinstallez la charge utile",
    "ja": "ジンバルエラー。AIスポットチェックに失敗しました。ペイロードを再度取り付けてください",
    "ko": "짐벌 오류. \\'AI 현장 확인\\' 실패. 페이로드 재설치 필요",
    "ru": "Ошибка стабилизатора. Сбой съемки с фокусировкой. Переустановите оборудование",
    "tr": "Gimbal hatası. Yapay Zeka Nokta Kontrolü başarısız. Yükü tekrar takın",
    "zh": "云台异常，精准拍照失败，请重新拔插负载"
  },
  "fpv_tip_0x1B060001": {
    "de": "Fluggeschwindigkeit beschränkt. Firmware-Aktualisierung erforderlich",
    "en": "Flight speed limited. Firmware update required",
    "es": "Velocidad de vuelo limitada. Es necesario actualizar el firmware.",
    "fr": "Vitesse de vol limitée. Mise à jour du firmware requise",
    "ja": "飛行速度に制限あり。ファームウェア更新が必要です",
    "ko": "비행 속도 제한됨. 펌웨어 업데이트 필요",
    "ru": "Скорость полета ограничена. Требуется обновить прошивку",
    "tr": "Uçuş hızı sınırlı. Ürün yazılımı güncellemesi gerekiyor",
    "zh": "飞行速度受限，固件需要升级"
  },
  "fpv_tip_0x1B070001": {
    "ar": "",
    "de": "",
    "en": "Payload exceeds capacity. Reduce payload or adjust flight plan",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "货物超重，请减少货物或修改飞行计划",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B070001_in_the_sky": {
    "ar": "",
    "de": "",
    "en": "Payload exceeds capacity. Land promptly and reduce payload",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "货物超重，请尽快降落，减少货物",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B070002": {
    "ar": "",
    "de": "",
    "en": "Payload weight not evenly distributed. Adjust payload placement",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "货物重心偏移，请重新摆放货物",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B070002_in_the_sky": {
    "ar": "",
    "de": "",
    "en": "Payload weight not evenly distributed. Land promptly and adjust payload placement",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "货物重心偏移，请尽快降落，重新摆放货物",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B070003": {
    "ar": "",
    "de": "",
    "en": "Cargo case weight sensor error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "货箱称重传感器异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B070004": {
    "ar": "",
    "de": "",
    "en": "Weight sensor error. Calibrate cargo case weight sensor",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "称重异常，请重新校准货箱称重传感器",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B070005": {
    "ar": "",
    "de": "",
    "en": "Weight sensor error. Calibrate cargo case weight sensor",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "称重异常，请重新校准货箱称重传感器",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B070006": {
    "ar": "",
    "de": "",
    "en": "Payload exceeds capacity. Reduce payload or adjust flight plan",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "货物超重，请减少货物或修改飞行计划",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B070006_in_the_sky": {
    "ar": "",
    "de": "",
    "en": "Payload exceeds capacity. Land promptly and reduce payload",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "货物超重，请尽快降落，减少货物",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B070008": {
    "ar": "",
    "de": "",
    "en": "Winch system weight sensor error",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊称重传感器异常",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B070009": {
    "ar": "",
    "de": "",
    "en": "Weight sensor error. Calibrate winch system weight sensor",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "称重异常，请重新校准空吊称重传感器",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B07000A": {
    "ar": "",
    "de": "",
    "en": "Weight sensor error. Calibrate winch system weight sensor",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "称重异常，请重新校准空吊称重传感器",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B07000B": {
    "ar": "",
    "de": "",
    "en": "Swing angle too large. Fly with caution",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊摆角过大，请谨慎飞行",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B07000C": {
    "ar": "",
    "de": "",
    "en": "Cable broke. Check payload on winch system",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊绳索断裂，请检查吊挂货物",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B07000D": {
    "ar": "",
    "de": "",
    "en": "Winch system motor stalled. Check payload on winch system",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电机堵转，请检查吊挂货物",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B07000E": {
    "ar": "",
    "de": "",
    "en": "Winch system motor overheated. Check if payload exceeds capacity",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊电机温度过高，请检查货物是否超重",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B080001": {
    "ar": "",
    "de": "",
    "en": "Remote ID broadcast error. Failed to obtain remote controller location",
    "es": "",
    "fr": "",
    "it": "",
    "ja": "",
    "ko": "",
    "pt": "",
    "ru": "",
    "tr": "",
    "zh": "Remote ID 播报异常，无法获取遥控器位置"
  },
  "fpv_tip_0x1B080002": {
    "ar": "",
    "de": "",
    "en": "Remote ID module error. Contact DJI Support",
    "es": "",
    "fr": "",
    "it": "",
    "ja": "",
    "ko": "",
    "pt": "",
    "ru": "",
    "tr": "",
    "zh": "Remote ID 模块异常，请联系售后服务"
  },
  "fpv_tip_0x1B090001": {
    "de": "Zielerfassung gestoppt",
    "en": "Target tracking stopped",
    "es": "Smart Track detenido",
    "fr": "Smart Track interrompu",
    "ja": "スマートトラック停止",
    "ko": "스마트 트랙 중지됨",
    "ru": "Интеллектуальное следование остановлено",
    "tr": "Akıllı Takip durdu",
    "zh": "停止目标追踪"
  },
  "fpv_tip_0x1B090002": {
    "de": "Zielverfolgung gestoppt",
    "en": "Target tracking stopped",
    "es": "Seguimiento del objetivo detenido",
    "fr": "Suivi du sujet arrêté",
    "ja": "被写体トラック停止",
    "ko": "타겟 추적 중지됨",
    "ru": "Следование за целью остановлено",
    "tr": "Hedef takip durdu",
    "zh": "停止智能跟随"
  },
  "fpv_tip_0x1B090003": {
    "de": "Zielverfolgung gestoppt",
    "en": "Target tracking stopped",
    "es": "Seguimiento del objetivo detenido",
    "fr": "Suivi du sujet arrêté",
    "ja": "被写体トラック停止",
    "ko": "타겟 추적 중지됨",
    "ru": "Следование за целью остановлено",
    "tr": "Hedef takip durdu",
    "zh": "停止智能跟随"
  },
  "fpv_tip_0x1B092C01": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d\\'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "피사체 인식 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C02": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C03": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C04": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C05": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C06": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C07": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C08": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C09": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C0A": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C0B": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C0C": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C0D": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C0E": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C0F": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C10": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C11": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C12": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C13": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C14": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C15": {
    "de": "Zielidentifikationsfehler",
    "en": "Target identification error",
    "es": "Error de identificación del objetivo",
    "fr": "Erreur d'identification du sujet",
    "ja": "被写体特定エラー",
    "ko": "타겟 식별 오류",
    "ru": "Ошибка определения цели",
    "tr": "Hedef tanımlama hatası",
    "zh": "目标识别异常"
  },
  "fpv_tip_0x1B092C16": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B092C17": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B092C18": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B092C19": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B092C1A": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B092C1B": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B092C1C": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B092C1D": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B092C1E": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B092C1F": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B092C20": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Sicherstellen, dass ausgewähltes Ziel gültig ist",
    "en": "Enabling Smart Track failed. Ensure selected target is valid",
    "es": "Error al activar Smart Track. Asegúrate de que el objetivo seleccionado sea válido",
    "fr": "Impossible d'activer Smart Track. Vérifiez que le sujet sélectionné est valide",
    "ja": "スマートトラックを有効化できません 。選択した被写体が有効であるか確認してください",
    "ko": "스마트 트랙 활성화 실패. 선택된 타겟이 유효한지 확인하세요",
    "ru": "Не удалось включить интеллектуальное следование. Выберите подходящую цель",
    "tr": "Akıllı Takip etkinleştirme başarısız. Geçerli bir hedef seçtiğinizden emin olun",
    "zh": "智能追踪功能启动失败，请确保框选有效目标"
  },
  "fpv_tip_0x1B092C21": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B092C22": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Zielauswahl verkleinern",
    "en": "Enabling Smart Track failed. Reduce selected area",
    "es": "Error al activar Smart Track. Reduce la zona seleccionada",
    "fr": "Impossible d'activer Smart Track. Réduisez la zone de sélection",
    "ja": "スマートトラックを有効化できません。選択エリアを小さくしてください",
    "ko": "스마트 트랙 활성화 실패. 선택된 영역을 줄이세요",
    "ru": "Не удалось включить интеллектуальное следование. Выберите участок меньшего размера",
    "tr": "Akıllı Takip etkinleştirme başarısız. Seçilen alanı azaltın",
    "zh": "智能追踪功能启动失败，请缩小目标区域"
  },
  "fpv_tip_0x1B092C23": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Zielauswahl vergrößern",
    "en": "Enabling Smart Track failed. Increase selected area",
    "es": "Error al activar Smart Track. Aumenta la zona seleccionada",
    "fr": "Impossible d'activer Smart Track. Augmentez la zone de sélection",
    "ja": "スマートトラックを有効化できません。選択エリアを大きくしてください",
    "ko": "스마트 트랙 활성화 실패. 선택된 영역을 늘리세요",
    "ru": "Не удалось включить интеллектуальное следование. Выберите участок большего размера",
    "tr": "Akıllı Takip etkinleştirme başarısız. Seçilen alanı arttırın",
    "zh": "智能追踪功能启动失败，请扩大目标区域"
  },
  "fpv_tip_0x1B092C24": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Sicherstellen, dass ausgewähltes Ziel gültig ist",
    "en": "Enabling Smart Track failed. Ensure selected target is valid",
    "es": "Error al activar Smart Track. Asegúrate de que el objetivo seleccionado sea válido",
    "fr": "Impossible d'activer Smart Track. Assurez-vous que le sujet sélectionné est valide",
    "ja": "スマートトラックを有効化できません。選択した被写体が有効であることを確認してください",
    "ko": "스마트 트랙 활성화 실패. 선택된 타겟이 유효한지 확인하세요",
    "ru": "Не удалось включить интеллектуальное следование. Выберите подходящую цель",
    "tr": "Akıllı Takip etkinleştirme başarısız. Geçerli bir hedef seçtiğinizden emin olun",
    "zh": "智能跟踪功能启动失败，请确保框选有效目标"
  },
  "fpv_tip_0x1B092C25": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B092C26": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B093001": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B093002": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B093003": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B093004": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B093005": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B093006": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B093007": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B093008": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B093009": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Zielauswahl vergrößern",
    "en": "Enabling Smart Track failed. Increase selected area",
    "es": "Error al activar Smart Track. Aumenta la zona seleccionada",
    "fr": "Impossible d'activer Smart Track. Augmentez la zone sélectionnée",
    "ja": "スマートトラックを有効化できません。選択範囲を拡大してください",
    "ko": "스마트 트랙 활성화 실패. 선택된 영역을 늘리세요",
    "ru": "Не удалось включить интеллектуальное следование. Выберите участок большего размера",
    "tr": "Akıllı Takip etkinleştirme başarısız. Seçilen alanı arttırın",
    "zh": "智能跟踪功能启动失败，请扩大目标区域"
  },
  "fpv_tip_0x1B09300A": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Sicherstellen, dass ausgewähltes Ziel gültig ist",
    "en": "Enabling Smart Track failed. Ensure selected target is valid",
    "es": "Error al activar Smart Track. Asegúrate de que el objetivo seleccionado sea válido",
    "fr": "Impossible d'activer Smart Track. Assurez-vous que le sujet sélectionné est valide",
    "ja": "スマートトラックを有効化できません。選択した被写体が有効であることを確認してください",
    "ko": "스마트 트랙 활성화 실패. 선택된 타겟이 유효한지 확인하세요",
    "ru": "Не удалось включить интеллектуальное следование. Выберите подходящую цель",
    "tr": "Akıllı Takip etkinleştirme başarısız. Geçerli bir hedef seçtiğinizden emin olun",
    "zh": "智能跟踪功能启动失败，请确保框选有效目标"
  },
  "fpv_tip_0x1B09300B": {
    "de": "Ziel verloren. Smarte Verfolgung gestoppt",
    "en": "Target lost. Exited Target Acquisition",
    "es": "Se ha perdido el objetivo. Smart Track detenido",
    "fr": "Sujet perdu. Smart Track interrompu",
    "ja": "ターゲットを見失いました。スマートトラック停止",
    "ko": "타겟 사라짐. 스마트 트랙 중지됨",
    "ru": "Цель потеряна. Интеллектуальное следование остановлено",
    "tr": "Hedef kayıp. Akıllı Takip durdu",
    "zh": "目标已丢失，目标锁定功能已退出"
  },
  "fpv_tip_0x1B09300C": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B09300D": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B09300E": {
    "de": "Parameter der Kamera geändert. Smarte Verfolgung gestoppt",
    "en": "Camera parameters changed. Exited Target Acquisition",
    "es": "Parámetros de la cámara modificados. Smart Track detenido",
    "fr": "Paramètres de caméra modifiés. Smart Track interrompu",
    "ja": "カメラパラメーターを変更。スマートトラック停止",
    "ko": "카메라 매개변수 변경됨. 스마트 트랙 중지됨",
    "ru": "Параметры камеры изменены. Интеллектуальное следование остановлено",
    "tr": "Kamera parametreleri değişti. Akıllı Takip durdu",
    "zh": "相机参数被修改，目标锁定功能退出"
  },
  "fpv_tip_0x1B09300F": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B093010": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B093011": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B093012": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B093013": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B093014": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B093015": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B093016": {
    "de": "Ziel verloren. Smarte Verfolgung beendet",
    "en": "Target lost. Exited Smart Track",
    "es": "Se ha perdido el objetivo. Smart Track se ha desactivado",
    "fr": "Sujet perdu. Fin de Smart Track",
    "ja": "被写体を見失いました。スマートトラックを終了します",
    "ko": "타겟 사라짐. 스마트 트랙 종료됨",
    "ru": "Цель потеряна. Режим интеллектуального следования закрыт",
    "tr": "Hedef kayıp. Akıllı Takipten çıkıldı",
    "zh": "目标已丢失，智能跟踪功能已退出"
  },
  "fpv_tip_0x1B093017": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B093018": {
    "de": "Ziel zu weit entfernt. Smarte Verfolgung gestoppt",
    "en": "Target too far away. Exited Target Acquisition",
    "es": "Objetivo demasiado lejos. Smart Track detenido",
    "fr": "Sujet trop éloigné. Smart Track interrompu",
    "ja": "被写体が遠すぎます。スマートトラック停止",
    "ko": "타겟이 너무 멀리 있음. 스마트 트랙 중지됨",
    "ru": "Цель слишком далеко. Интеллектуальное следование остановлено",
    "tr": "Hedef çok uzak. Akıllı Takip durdu",
    "zh": "目标距离过远，目标锁定功能已退出"
  },
  "fpv_tip_0x1B093019": {
    "de": "Parameter der Kamera geändert. Smarte Verfolgung beendet",
    "en": "Camera parameters changed. Exited Smart Track",
    "es": "Parámetros de la cámara modificados. Smart Track se ha desactivado",
    "fr": "Les paramètres de la caméra ont changé. Fin de Smart Track",
    "ja": "カメラパラメーターが変更されました。スマートトラックを終了します",
    "ko": "카메라 매개변수 변경됨. 스마트 트랙 종료됨",
    "ru": "Параметры камеры изменены. Режим интеллектуального следования закрыт",
    "tr": "Kamera parametreleri değişti. Akıllı Takipten çıkıldı",
    "zh": "相机参数被修改，智能跟踪功能退出"
  },
  "fpv_tip_0x1B09301A": {
    "de": "Aktivierung von smarter Verfolgung fehlgeschlagen. Bitte erneut versuchen",
    "en": "Enabling Smart Track failed. Check and try again",
    "es": "Error al activar Smart Track. Comprueba y vuelve a intentarlo",
    "fr": "Impossible d'activer Smart Track. Vérifiez et refaites une tentative",
    "ja": "スマートトラックを有効化できません。確認して再試行してください",
    "ko": "스마트 트랙 활성화 실패. 확인 후 다시 시도하세요",
    "ru": "Не удалось включить интеллектуальное следование. Проверьте и повторите попытку",
    "tr": "Akıllı Takip etkinleştirme başarısız. Kontrol edin ve tekrar deneyin",
    "zh": "智能跟踪功能启动失败，请重试"
  },
  "fpv_tip_0x1B0A0001": {
    "de": "GPS-Signal schwach. Geo-Awareness-Funktion beeinträchtigt. Mit Vorsicht fliegen.",
    "en": "GPS signal weak. Geo-awareness function degraded. Fly with caution",
    "es": "Señal GPS débil. Función de reconocimiento geográfico deteriorada. Vuela con cuidado",
    "fr": "Signal GPS faible. Fonction de géovigilance dégradée. Pilotez avec précaution.",
    "ja": "GPS信号が微弱です。ジオアウェアネス機能が低下しています。慎重に飛行してください",
    "ko": "GPS 신호가 약함. 지리 인식 기능 저하됨. 비행 시 주의 필요.",
    "ru": "Слабый сигнал GPS. Функция гео-осведомленности ухудшилась. Будьте осторожны в полете",
    "tr": "GPS sinyali zayıf. Coğrafi farkındalık işlevi bozuldu. Dikkatlice uçurun",
    "zh": "GPS 信号弱，地理感知功能降级，请谨慎飞行"
  },
  "fpv_tip_0x1B0A0002": {
    "de": "Dynamische Sicherheitsdaten konnten nicht geladen werden. Geo Awareness-Funktion herabgesetzt. Es wird empfohlen, die FlySafe-Datenbank zu aktualisieren",
    "en": "Failed to load dynamic safety data. Geo-awareness function degraded. It is recommended to update FlySafe database",
    "es": "Error al cargar los datos de seguridad dinámicos. Función de reconocimiento geográfico deteriorada. Se recomienda actualizar la información de VuelaSeguro",
    "fr": "Échec du chargement des données de sécurité dynamiques. Fonction de géovigilance dégradée. Il est recommandé de mettre à jour la base de données FlySafe",
    "ja": "動的安全性データをロードできません。ジオアウェアネス機能が低下しています。安全飛行データベースを更新することをお勧めします",
    "ko": "동적 안전 데이터를 로드하지 못함. 지리 인식 기능 저하됨. FlySafe 데이터베이스를 업데이트하는 것이 좋습니다.",
    "ru": "Не удалось загрузить динамические данные безопасности. Функция пространственной ситуационной осведомленности ухудшилась. Рекомендуется обновить базу данных FlySafe",
    "tr": "Dinamik güvenlik verileri yüklenemedi. Coğrafi farkındalık işlevinde azalma. FlySafe veri tabanının güncellenmesi önerilir",
    "zh": "动态安全数据加载失败，地理感知功能降级，建议更新飞行安全数据库"
  },
  "fpv_tip_0x1B0A0003": {
    "de": "Dynamische Sicherheitsdaten konnten nicht abgefragt werden. Geo Awareness-Funktion herabgesetzt. Es wird empfohlen, die FlySafe-Datenbank zu aktualisieren",
    "en": "Failed to query dynamic safety data. Geo-awareness function degraded. It is recommended to update FlySafe database",
    "es": "Error al consultar los datos de seguridad dinámicos. Función de reconocimiento geográfico deteriorada. Se recomienda actualizar la información de VuelaSeguro",
    "fr": "Échec de la requête des données de sécurité dynamiques. Fonction de géovigilance dégradée. Il est recommandé de mettre à jour la base de données FlySafe",
    "ja": "動的安全性データを照会できません。ジオアウェアネス機能が低下しています。安全飛行データベースを更新することをお勧めします",
    "ko": "쿼리 동적 안전 데이터를 로드하지 못함. 지리 인식 기능 저하됨. FlySafe 데이터베이스를 업데이트하는 것이 좋습니다.",
    "ru": "Не удалось запросить динамические данные безопасности. Функция пространственной ситуационной осведомленности ухудшилась. Рекомендуется обновить базу данных FlySafe",
    "tr": "Dinamik güvenlik verileri sorgulanamadı. Coğrafi farkındalık işlevinde azalma. FlySafe veri tabanının güncellenmesi önerilir",
    "zh": "动态安全数据查询失败，地理感知功能降级，建议更新飞行安全数据库"
  },
  "fpv_tip_0x1B0A0004": {
    "de": "FlySafe-Datendichte ist in der aktuellen Region hoch. Fluggerät kann Daten möglicherweise nicht vollständig verarbeiten. Es wird empfohlen, die FlySafe-Datenbank zu aktualisieren.",
    "en": "FlySafe data density high in current region. Aircraft may not be able to fully process data. It is recommended to update FlySafe database",
    "es": "Densidad de datos de VuelaSeguro alta en la región actual. Es posible que la aeronave no pueda procesar completamente los datos. Se recomienda actualizar la información de VuelaSeguro",
    "fr": "Densité élevée des données FlySafe dans la région actuelle. L'appareil peut ne pas être en mesure de traiter complètement les données. Il est recommandé de mettre à jour la base de données FlySafe",
    "ja": "現在の地域の安全飛行データ密度が高くなっています。機体がデータを完全に処理できない可能性があります。安全飛行データベースを更新することをお勧めします",
    "ko": "현재 지역에서 FlySafe 데이터 밀도가 높습니다. 기체가 데이터를 완전히 처리하지 못할 수 있습니다. FlySafe 데이터베이스를 업데이트하는 것이 좋습니다.",
    "ru": "Высокая плотность данных FlySafe в текущем регионе. Дрон может быть не в состоянии полностью обработать данные. Рекомендуется обновить базу данных FlySafe",
    "tr": "Mevcut bölgede FlySafe veri yoğunluğu yüksek. Hava aracı, verileri tam olarak işleyemeyebilir. FlySafe veri tabanının güncellenmesi önerilir",
    "zh": "限飞数据库在当前区域数据密度较高，可能会出现飞行器解析不完整的情况，建议更新飞行安全数据库。"
  },
  "fpv_tip_0x1B100000": {
    "de": "Ziel verloren. Tracking gestoppt",
    "en": "Target lost. Tracking stopped",
    "es": "Se ha perdido el objetivo. Seguimiento detenido",
    "fr": "Sujet perdu. Suivi arrêté",
    "ja": "ターゲットロストです。トラッキングが停止しました",
    "ko": "타겟 사라짐. 트래킹 중지됨",
    "ru": "Цель потеряна. Отслеживание остановлено",
    "tr": "Hedef kayboldu. Takip durdu",
    "zh": "目标丢失，跟踪停止"
  },
  "fpv_tip_0x1B100001": {
    "de": "Das Ziel ist zu klein. Leicht zu verlieren",
    "en": "Target too small. Easy to lose",
    "es": "Objetivo demasiado pequeño. Fácil de perder",
    "fr": "Cible trop petite. Facile à perdre",
    "ja": "被写体が小さすぎます。見失いやすくなっています",
    "ko": "타겟이 너무 작습니다. 쉽게 분실될 수 있습니다",
    "ru": "Слишком маленькая цель. Легко потерять",
    "tr": "Hedef çok küçük. Kolayca kaybolabilir",
    "zh": "目标较小，容易丢失"
  },
  "fpv_tip_0x1B300001": {
    "ar": "",
    "de": "",
    "en": "Unable to obtain cargo case data. Make sure cargo case is installed properly",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "无法获得货箱数据，请检查安装情况",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B300002": {
    "ar": "",
    "de": "",
    "en": "Winch system communication error. Check winch system connection",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "空吊设备通信异常，请检查设备连接状况",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B300003": {
    "ar": "",
    "de": "",
    "en": "Payload exceeds capacity. Reduce payload and adjust flight plan",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "货物过重，请减少货物和修改飞行计划",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B300004": {
    "ar": "",
    "de": "",
    "en": "Payload exceeds capacity. Cancel flight",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "货物超重，请终止飞行计划",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B300005": {
    "ar": "",
    "de": "",
    "en": "Payload weight not evenly distributed. Secure payload in center of cargo case",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "货物偏离中心，请把货物摆放在货箱中心",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B300006": {
    "ar": "",
    "de": "",
    "en": "Cargo case severely imbalanced. Secure payload in center of cargo case",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "货物严重偏离中心，请把货物摆放在货箱中心",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B300007": {
    "ar": "",
    "de": "",
    "en": "Cargo case wobbling. Secure cargo case and payload inside",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "货箱晃动，请绑好货箱或固定货物在货箱中的位置",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B300008": {
    "ar": "",
    "de": "",
    "en": "Cargo case wobbles significantly. Secure cargo case and payload inside",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "货箱晃动严重，请绑好货箱或固定货物在货箱中的位置",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B300009": {
    "ar": "",
    "de": "",
    "en": "Payload on winch system exceeds capacity. Reduce payload and adjust flight plan",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "挂载货物过重，请减少货物和修改飞行计划",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B30000A": {
    "ar": "",
    "de": "",
    "en": "Payload on winch system exceeds capacity. Cancel flight",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "挂载货物超重，请终止飞行计划",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B30000B": {
    "ar": "",
    "de": "",
    "en": "Payload swings slightly. Fly with caution",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "挂载货物小幅摆动，请注意飞行安全",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B30000C": {
    "ar": "",
    "de": "",
    "en": "Payload swings significantly. Fly with caution",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "挂载货物大幅摆动，请注意飞行安全",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B30000D": {
    "ar": "",
    "de": "",
    "en": "Payload on winch system approaching obstacle. Fly with caution",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "挂载货物接近障碍物，请注意飞行安全",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B30000E": {
    "ar": "",
    "de": "",
    "en": "Payload on winch system very close to obstacle. Fly with caution",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "挂载货物非常接近障碍物，请注意飞行安全",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B30000F": {
    "en": "Extending or retracting winch cable is prohibited during flight. Make sure aircraft is hovering before extending or retracting winch cable",
    "zh": "飞行过程中禁止收放空吊线缆，请悬停后再进行线缆收放"
  },
  "fpv_tip_0x1B300010": {
    "en": "Payload swings greatly. Do not reel up winch cable. Wait until payload swing reduces before reeling up",
    "zh": "货物摆动大，禁止空吊收绳，请等待摆动减小后再收绳"
  },
  "fpv_tip_0x1B310001": {
    "ar": "",
    "de": "",
    "en": "Battery level too low to complete flight mission. Adjust flight plan",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "当前电量不够完成航线任务，请更改飞行计划",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B310001_in_the_sky": {
    "ar": "",
    "de": "",
    "en": "Battery level too low to complete flight mission. Adjust flight plan",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "当前电量不够完成航线任务，请更改飞行计划",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B310002": {
    "ar": "",
    "de": "",
    "en": "Battery level too low to fly to alternate landing site. Land promptly",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "当前电量不够前往备降点，请尽快降落",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B310002_in_the_sky": {
    "ar": "",
    "de": "",
    "en": "Battery level too low to fly to alternate landing site. Land promptly",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "当前电量不够前往备降点，请尽快降落",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B310003": {
    "ar": "",
    "de": "",
    "en": "Current payload exceeds propulsion system capability limit. Motors may be overheated. Reduce payload weight before taking off",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "当前载重超过动力系统载重限制，可能导致电机超温，请降低载重后起飞",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B310003_in_the_sky": {
    "ar": "",
    "de": "",
    "en": "Current payload exceeds propulsion system capability limit. Motors may be overheated. Return to home or land promptly",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "当前载重超过动力系统载重限制，可能导致电机超温，请尽快返航或降落",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B310004": {
    "ar": "",
    "de": "",
    "en": "Current payload exceeds battery capability limit. Battery may be damaged. Reduce payload weight before taking off",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "当前载重超过电池限制，可能导致电池损坏，请降低载重后起飞",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B310004_in_the_sky": {
    "ar": "",
    "de": "",
    "en": "Current payload exceeds battery capability limit. Battery may be damaged. Return to home or land promptly",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "当前载重超过电池限制，可能导致电池损坏，请尽快返航或降落",
    "zh-Hant": ""
  },
  "fpv_tip_0x1B310005": {
    "en": "Altitude detection function of radar module unavailable. Safe altitude changed. Fly with caution",
    "zh": "雷达模块高度探测失效，安全飞行高度发生变更，请注意飞行安全"
  },
  "fpv_tip_0x1B310006": {
    "en": "Altitude detection function of radar module available. Safe altitude changed. Fly with caution",
    "zh": "雷达模块高度探测恢复正常，安全飞行高度发生变更，请注意飞行安全"
  },
  "fpv_tip_0x1B310007": {
    "en": "Aircraft in area with terrain data. Fly under safe altitude",
    "zh": "飞行器处于地形数据区域，请在安全飞行高度以下飞行"
  },
  "fpv_tip_0x1B310007_in_the_sky": {
    "en": "Aircraft entered area with terrain data. Safe altitude changed",
    "zh": "飞行器进入地形数据区域，安全飞行高度发生变更"
  },
  "fpv_tip_0x1B310008": {
    "en": "Aircraft not in area with terrain data. Apply terrain data",
    "zh": "飞行器不在地形数据区域，请应用地形数据"
  },
  "fpv_tip_0x1B310008_in_the_sky": {
    "en": "Aircraft out of area with terrain data. Safe altitude changed. Fly with caution",
    "zh": "飞行器飞出地形数据区域，安全飞行高度发生变更，请注意飞行安全"
  },
  "fpv_tip_0x1B310009": {
    "en": "Aircraft flight altitude exceeded safe altitude. Fly aircraft under safe altitude",
    "zh": "飞行器当前飞行高度超过安全飞行高度，请在安全飞行高度以下飞行"
  },
  "fpv_tip_0x1B31000A": {
    "en": "Winch system not fully reeled up. Propellers may be impacted during landing. Reel up cable before landing",
    "zh": "空吊未收到位，降落存在打桨风险，请收绳后再降落"
  },
  "fpv_tip_0x1B31000B": {
    "en": "Aircraft Stops. Fly with caution",
    "zh": "飞行器急停，请注意飞行安全"
  },
  "fpv_tip_0x1B31000B_in_the_sky": {
    "en": "Aircraft Stops. Fly with caution",
    "zh": "飞行器急停，请注意飞行安全"
  },
  "fpv_tip_0x1B310011": {
    "de": "Akkutemperatur ist niedrig. Akku vor dem Start aufwärmen",
    "en": "Battery temperature low. Warm up battery before takeoff",
    "es": "Temperatura baja de la batería. Calentar la batería antes del despegue",
    "fr": "Température basse de la batterie. Réchauffez la batterie avant le décollage",
    "ja": "バッテリー 低温。離陸前にバッテリーをウォームアップしてください",
    "ko": "배터리 온도가 낮음. 이륙 전 배터리를 예열하세요",
    "ru": "Слишком низкая температура АКБ. Прогрейте АКБ перед взлетом",
    "tr": "Pil sıcaklığı düşük. Kalkıştan önce pili ısıtın",
    "zh": "电池温度低，请加热后起飞"
  },
  "fpv_tip_0x1B310011_in_the_sky": {
    "de": "Akkutemperatur ist niedrig. Vorsichtig fliegen",
    "en": "Battery temperature low. Fly with caution",
    "es": "Temperatura baja de la batería. Vuela con cuidado",
    "fr": "Température basse de la batterie. Pilotez avec précaution",
    "ja": "バッテリー 低温。慎重に飛行してください",
    "ko": "배터리 온도가 낮음. 비행 시 주의 필요",
    "ru": "Слишком низкая температура АКБ. Соблюдайте осторожность при полете",
    "tr": "Pil sıcaklığı düşük. Dikkatli uçun",
    "zh": "电池温度低，请谨慎飞行"
  },
  "fpv_tip_0x1BA10000": {
    "ar": "",
    "de": "",
    "en": "Cruise control enabled",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "当前在杆量保持中",
    "zh-Hant": ""
  },
  "fpv_tip_0x1BA10001": {
    "ar": "",
    "de": "Fluggerät nicht im Flugbetrieb. Tempomat deaktiviert",
    "en": "Aircraft not in flight. Cruise control disabled",
    "es": "La aeronave no está en vuelo. Control de crucero desactivado",
    "fr": "L'appareil n'est pas en vol. Régulateur de vitesse désactivé",
    "id": "",
    "it": "",
    "ja": "機体が飛行していません。クルーズコントロールが無効",
    "ko": "기체가 비행 상태가 아님. 크루즈 컨트롤 비활성화됨",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Дрон не находится в полете. Круиз-контроль отключен",
    "th": "",
    "tr": "Hava araç uçuşta değil. Hız sabitleyici devre dışı",
    "ug": "",
    "vi": "",
    "zh": "未起飞，已终止定速巡航",
    "zh-Hant": ""
  },
  "fpv_tip_0x1BA10002": {
    "ar": "",
    "de": "Flugmodus wird gewechselt. Tempomat deaktiviert",
    "en": "Switching flight modes. Cruise control disabled",
    "es": "Cambiando modos de vuelo. Control de crucero desactivado",
    "fr": "Changement de mode de vol. Régulateur de vitesse désactivé",
    "id": "",
    "it": "",
    "ja": "フライトモードの切り替え中です。クルーズコントロールが無効",
    "ko": "비행 모드 전환 중. 크루즈 컨트롤 비활성화됨",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Переключение режимов полета. Круиз-контроль отключен",
    "th": "",
    "tr": "Uçuş modları değiştiriliyor. Hız sabitleyici devre dışı",
    "ug": "",
    "vi": "",
    "zh": "飞行模式切换中，已终止定速巡航",
    "zh-Hant": ""
  },
  "fpv_tip_0x1BA10003": {
    "ar": "",
    "de": "Fluggerät bremst. Tempomat deaktiviert",
    "en": "Aircraft braking. Cruise control disabled",
    "es": "Aeronave frenando. Control de crucero desactivado",
    "fr": "Freinage de l'appareil. Régulateur de vitesse désactivé",
    "id": "",
    "it": "",
    "ja": "機体がブレーキ中です。クルーズコントロールが無効",
    "ko": "기체 제동. 크루즈 컨트롤 비활성화됨",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Дрон тормозит. Круиз-контроль отключен",
    "th": "",
    "tr": "Hava aracı fren yapıyor. Hız sabitleyici devre dışı",
    "ug": "",
    "vi": "",
    "zh": "紧急刹车中，已终止定速巡航",
    "zh-Hant": ""
  },
  "fpv_tip_0x1BA10004": {
    "ar": "",
    "de": "Fluggeschwindigkeit beschränkt. Tempomat deaktiviert",
    "en": "Flight speed limited. Cruise control disabled",
    "es": "Velocidad de vuelo limitada. Control de crucero desactivado",
    "fr": "Vitesse de vol limitée. Régulateur de vitesse désactivé",
    "id": "",
    "it": "",
    "ja": "飛行速度が制限されています。クルーズコントロールが無効",
    "ko": "비행 속도 제한됨. 크루즈 컨트롤 비활성화됨",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Скорость полета ограничена. Круиз-контроль отключен",
    "th": "",
    "tr": "Uçuş hızı sınırlı. Hız sabitleyici devre dışı",
    "ug": "",
    "vi": "",
    "zh": "飞机速度受到限制，已终止定速巡航",
    "zh-Hant": ""
  },
  "fpv_tip_0x1BA10005": {
    "ar": "",
    "de": "Fluggerät nähert sich der festgelegten maximalen Flughöhe oder -distanz. Tempomat deaktiviert",
    "en": "Aircraft approaching the set max flight altitude or distance. Cruise control disabled",
    "es": "Aeronave aproximándose a la altitud o distancia máxima de vuelo establecida. Control de crucero desactivado",
    "fr": "L'appareil s'approche de la distance ou de l'altitude en vol maximale définie. Régulateur de vitesse désactivé",
    "id": "",
    "it": "",
    "ja": "設定した最大飛行高度または距離に機体が近づいています。クルーズコントロールが無効",
    "ko": "기체가 설정된 최대 비행 고도 또는 거리에 접근하는 중. 크루즈 컨트롤 비활성화됨",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Дрон приближается к установленной максимальной высоте полета или расстоянию. Круиз-контроль отключен",
    "th": "",
    "tr": "Hava aracı ayarlanan maksimum uçuş yüksekliğine veya mesafesine yaklaşıyor. Hız sabitleyici devre dışı",
    "ug": "",
    "vi": "",
    "zh": "飞机接近最大限制高度或最远限制距离，已终止定速巡航",
    "zh-Hant": ""
  },
  "fpv_tip_0x1BA10006": {
    "ar": "",
    "de": "Tempomat im aktuellen Flugmodus nicht verfügbar. Tempomat deaktiviert",
    "en": "Cruise control unavailable in current flight mode. Cruise control disabled",
    "es": "El control de crucero no está disponible en el modo de vuelo actual. Control de crucero desactivado",
    "fr": "Régulateur de vitesse non disponible avec le mode de vol actuel. Régulateur de vitesse désactivé",
    "id": "",
    "it": "",
    "ja": "現在のフライトモードではクルーズコントロールを使用できません。クルーズコントロールが無効",
    "ko": "현재 비행 모드에서 크루즈 컨트롤을 사용할 수 없습니다. 크루즈 컨트롤 비활성화됨",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Круиз-контроль недоступен в текущем режиме полета. Круиз-контроль отключен",
    "th": "",
    "tr": "Mevcut uçuş modunda hız sabitleyici kullanılamıyor. Hız sabitleyici devre dışı",
    "ug": "",
    "vi": "",
    "zh": "当前飞行模式不支持定速巡航，已终止定速巡航",
    "zh-Hant": ""
  },
  "fpv_tip_0x1BA10007": {
    "ar": "",
    "de": "Fluggerät bremst. Tempomat deaktiviert",
    "en": "Aircraft braking. Cruise control disabled",
    "es": "Aeronave frenando. Control de crucero desactivado",
    "fr": "Freinage de l'appareil. Régulateur de vitesse désactivé",
    "id": "",
    "it": "",
    "ja": "機体がブレーキ中です。クルーズコントロールが無効",
    "ko": "기체 제동. 크루즈 컨트롤 비활성화됨",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Дрон тормозит. Круиз-контроль отключен",
    "th": "",
    "tr": "Hava aracı fren yapıyor. Hız sabitleyici devre dışı",
    "ug": "",
    "vi": "",
    "zh": "紧急刹车中，已终止杆量保持",
    "zh-Hant": ""
  },
  "fpv_tip_0x1BA10008": {
    "de": "Fernsteuerungssignal verloren. Tempomat deaktiviert",
    "en": "Remote controller signal lost. Cruise control disabled",
    "es": "Señal del control remoto perdida. Control de crucero desactivado",
    "fr": "Signal de la radiocommande perdu. Régulateur de vitesse désactivé",
    "ja": "送信機信号ロスト。クルーズコントロールが無効",
    "ko": "조종기 신호가 끊김. 크루즈 컨트롤 비활성화됨",
    "ru": "Сигнал пульта управления потерян. Круиз-контроль отключен",
    "tr": "Uzaktan kumanda sinyali kayboldu. Hız sabitleyici devre dışı",
    "zh": "遥控器信号丢失，已终止定速巡航"
  },
  "fpv_tip_0x1BA10009": {
    "de": "Das Fluggerät hat den Zielpunkt erreicht. Tempomat deaktiviert",
    "en": "Aircraft reached the destination point. Cruise Control exited",
    "es": "La aeronave ha llegado al punto de destino. Se ha salido del control de crucero",
    "fr": "L'appareil a atteint le point de destination. Sortie du régulateur de vitesse",
    "ja": "機体が目的地に到達しました。クルーズコントロールを終了しました",
    "ko": "기체가 목적지에 도착했습니다. 크루즈 컨트롤 종료됨",
    "ru": "Дрон достиг точки назначения. Выполнен выход из режима круиз-контроля",
    "tr": "Hava aracı, varış noktasına ulaştı. Hız Sabitleyiciden çıkıldı",
    "zh": "到达航线终点，定速巡航已退出"
  },
  "fpv_tip_0x1C000001": {
    "de": "",
    "en": "",
    "es": "",
    "fr": "Surchauffe caméra X . Attendez que la température de la caméra revienne à la normale avant usage",
    "ja": "",
    "ko": "",
    "ru": "",
    "tr": "",
    "zh": "X号云台相机过热，请等待降温后再使用"
  },
  "fpv_tip_0x1C000103": {
    "de": "Hohe Temperatur des Kameraprozessors (%alarmid). Fluggerät ausschalten und vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera processor temperature high (%alarmid). Power off aircraft and wait for temperature to return to normal before use",
    "es": "Temperatura alta del procesador de la cámara (%alarmid). Apaga la aeronave y espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "La température du processeur de la caméra est élevée (%alarmid). Éteignez l'appareil et attendez que sa température revienne à la normale avant utilisation",
    "ja": "カメラプロセッサの温度が高い（%alarmid）。機体の電源を切り、通常の温度に戻ってからご使用ください",
    "ko": "카메라 프로세서 온도 높음(%alarmid). 사용 전에 기체의 전원을 끄고 온도가 정상으로 돌아올 때까지 기다리세요.",
    "ru": "Высокая температура процессора камеры (%alarmid). Перед использованием выключите питание дрона и дождитесь, пока температура опустится до нормальной",
    "tr": "Kamera işlemci sıcaklığı yüksek (%alarmid). Aracı kapatın ve kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度高（%alarmid），请关闭飞机，等待冷却后再使用"
  },
  "fpv_tip_0x1C000103_in_the_sky": {
    "de": "Hohe Temperatur des Kameraprozessors (%alarmid). Umgehend zum Startpunkt zurückkehren oder landen. Vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera processor temperature high (%alarmid). Return to home or land promptly. Wait for temperature to return to normal before use",
    "es": "Temperatura alta del procesador de la cámara (%alarmid). Regresa al punto de origen o aterriza rápidamente. Espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "La température du processeur de la caméra est élevée (%alarmid). Retournez au point de départ ou atterrissez rapidement. Attendez que la température revienne à la normale avant utilisation",
    "ja": "カメラプロセッサの温度が高い（%alarmid）。直ちに帰還するか、着陸してください。常温に戻るまで待ってからご使用ください",
    "ko": "카메라 프로세서 온도 높음(%alarmid). 즉시 리턴 투 홈 또는 착륙 필요. 사용 전에 온도가 정상으로 돌아올 때까지 기다리세요.",
    "ru": "Высокая температура процессора камеры (%alarmid). Как можно скорее вернитесь на базу или приземлитесь. Перед использованием подождите, пока температура не опустится до нормальной",
    "tr": "Kamera işlemci sıcaklığı yüksek (%alarmid). Ana konuma geri dönün veya derhal iniş yapın. Kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度高（%alarmid），请尽快返航或降落，等待冷却后重启使用"
  },
  "fpv_tip_0x1C000104": {
    "de": "Temperatur des Kameraprozessors zu hoch. Fluggerät ausschalten.  Vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera processor temperature too high. Power off aircraft.  Wait for temperature to return to normal before use",
    "es": "Temperatura demasiado alta del procesador de la cámara. Apaga la aeronave.  Espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Température du processeur de la caméra trop élevée. Éteignez l'appareil.  Attendez que la température revienne à la normale avant utilisation",
    "ja": "カメラプロセッサの温度が高すぎる。機体電源オフ。常温に戻るまで待ってからご使用ください",
    "ko": "카메라 프로세서 온도 너무 높음. 기체 전원을 끄세요.  사용 전에 온도가 정상으로 돌아올 때까지 기다리세요.",
    "ru": "Чрезмерно высокая температура процессора камеры. Выключите питание дрона.  Перед использованием подождите, пока температура не опустится до нормальной",
    "tr": "Kamera işlemci sıcaklığı çok yüksek. Aracı kapatın.  Kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度过高，请关闭飞机，等待冷却后再使用"
  },
  "fpv_tip_0x1C000104_in_the_sky": {
    "de": "Temperatur des Kameraprozessors zu hoch. Umgehend zum Startpunkt zurückkehren oder landen. Vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera processor temperature too high. Return to home or land promptly. Wait for temperature to return to normal before use",
    "es": "Temperatura demasiado alta del procesador de la cámara. Regresa al punto de origen o aterriza rápidamente. Espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Température du processeur de la caméra trop élevée. Retournez au point de départ ou atterrissez rapidement. Attendez que la température revienne à la normale avant utilisation",
    "ja": "カメラプロセッサの温度が高すぎる。直ちに帰還するか、着陸してください。常温に戻るまで待ってからご使用ください",
    "ko": "카메라 프로세서 온도 너무 높음. 즉시 리턴 투 홈 또는 착륙 필요. 사용 전에 온도가 정상으로 돌아올 때까지 기다리세요.",
    "ru": "Чрезмерно высокая температура процессора камеры. Как можно скорее вернитесь на базу или приземлитесь. Перед использованием подождите, пока температура не опустится до нормальной",
    "tr": "Kamera işlemci sıcaklığı çok yüksek. Ana konuma geri dönün veya derhal iniş yapın. Kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度过高，请尽快返航或降落，等待冷却后重启使用"
  },
  "fpv_tip_0x1C000201": {
    "de": "Hardwarefehler: Kamera. Neu starten und erneut versuchen",
    "en": "Camera hardware error. Restart and try again",
    "es": "Error de hardware de la cámara. Reinicia y vuelve a intentarlo.",
    "fr": "Erreur matérielle de caméra. Redémarrez et réessayez",
    "ja": "カメラのハードウェアエラー。再起動してやり直してください",
    "ko": "카메라 하드웨어 오류. 재시작한 후 다시 시도하세요.",
    "ru": "Аппаратная ошибка камеры. Перезапустите дрон и повторите попытку",
    "tr": "Kamera donanım hatası. Yeniden başlatın ve tekrar deneyin",
    "zh": "相机硬件异常，请尝试重新启动"
  },
  "fpv_tip_0x1C000202": {
    "de": "Hardwarefehler: Kamera. Neu starten und erneut versuchen",
    "en": "Camera hardware error. Restart and try again",
    "es": "Error de hardware de la cámara. Reinicia y vuelve a intentarlo.",
    "fr": "Erreur matérielle de caméra. Redémarrez et réessayez",
    "ja": "カメラのハードウェアエラー。再起動してやり直してください",
    "ko": "카메라 하드웨어 오류. 재시작한 후 다시 시도하세요.",
    "ru": "Аппаратная ошибка камеры. Перезапустите дрон и повторите попытку",
    "tr": "Kamera donanım hatası. Yeniden başlatın ve tekrar deneyin",
    "zh": "相机硬件异常，请尝试重新启动"
  },
  "fpv_tip_0x1C000203": {
    "de": "Hardwarefehler: Kamera. Neu starten und erneut versuchen",
    "en": "Camera hardware error. Restart and try again",
    "es": "Error de hardware de la cámara. Reinicia y vuelve a intentarlo.",
    "fr": "Erreur matérielle de caméra. Redémarrez et réessayez",
    "ja": "カメラのハードウェアエラー。再起動してやり直してください",
    "ko": "카메라 하드웨어 오류. 재시작한 후 다시 시도하세요.",
    "ru": "Аппаратная ошибка камеры. Перезапустите дрон и повторите попытку",
    "tr": "Kamera donanım hatası. Yeniden başlatın ve tekrar deneyin",
    "zh": "相机硬件异常，请尝试重新启动"
  },
  "fpv_tip_0x1C000204": {
    "de": "Bildsensor der Kamera überhitzt",
    "en": "Camera image sensor overheated",
    "es": "Sensor de imagen de la cámara sobrecalentado",
    "fr": "Surchauffe du capteur d\\'images de la caméra",
    "it": "",
    "ja": "カメライメージセンサー高温",
    "ko": "카메라 이미지 센서 과열",
    "pt": "",
    "ru": "Датчик изображения камеры перегрет",
    "tr": "Kamera görüntü sensörü aşırı ısındı",
    "zh": "相机图像传感器温度过高"
  },
  "fpv_tip_0x1C000207": {
    "en": "Check if camera connection cable is loose or has been eroded",
    "zh": "请检查FPV接线是否松脱或腐蚀"
  },
  "fpv_tip_0x1C00020A": {
    "ar": "",
    "de": "Kalibrierungsdaten Bildsensor fehlerhaft. Fluggerät neu starten",
    "en": "Image sensor calibration data error. Restart aircraft",
    "es": "Error de datos de calibración del sensor de imagen. Reinicie la aeronave",
    "fr": "Erreur de données de l'étalonnage du capteur d'image. Redémarrez l’appareil",
    "id": "",
    "it": "",
    "ja": "イメージセンサーキャリブレーションデータエラーです。機体を再起動してください",
    "ko": "이미지 센서 캘리브레이션 데이터 오류. 기체를 재시동하세요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Ошибка данных калибровки датчика изображения. Перезапустите дрон",
    "th": "",
    "tr": "Görüntü sensörü kalibrasyonu veri hatası. Hava aracını yeniden başlatın",
    "ug": "",
    "vi": "",
    "zh": "图像传感器标定数据异常，请重启",
    "zh-Hant": ""
  },
  "fpv_tip_0x1C000303": {
    "de": "Kameraverbindungsfehler. Kamera neu starten und prüfen, ob Kamera ordnungsgemäß installiert ist",
    "en": "Camera connection error. Restart camera and check whether camera is installed properly",
    "es": "Error de conexión de la cámara. Reiníciala y comprueba si está correctamente instalada",
    "fr": "Erreur de connexion de la caméra. Redémarrez la caméra et vérifiez que la caméra est installée correctement",
    "it": "",
    "ja": "カメラ接続エラー。カメラを再起動して、カメラが正しく取り付けられているか確認してください",
    "ko": "카메라 연결 오류. 카메라 재시작 및 카메라 올바르게 설치되었는지 확인 필요",
    "pt": "",
    "ru": "Ошибка подключения камеры. Проверьте, правильно ли установлена камера, и перезапустите ее",
    "tr": "Kamera bağlantısı hatası. Kamerayı yeniden başlatın ve düzgün takılıp takılmadığını kontrol edin",
    "zh": "镜头连接异常，请检查镜头是否正确安装"
  },
  "fpv_tip_0x1C000305": {
    "de": "Verschluss 100.000 Mal (Designlimit) ausgelöst",
    "en": "Shutter trigger count reached 100K design limit",
    "es": "El recuento del disparador del obturador ha alcanzado el límite de diseño (100 000)",
    "fr": "Limite de conception de 100 000 déclenchements de l\\'obturateur atteinte",
    "it": "",
    "ja": "シャッタートリガー回数が設計限界の 100K に達しました",
    "ko": "셔터 트리거 수가 100,000회 설계 한도에 도달함",
    "pt": "",
    "ru": "Количество срабатываний затвора достигло проектного предела в 100 тысяч",
    "tr": "Deklanşör tetiklenme sayısı 100 bin tasarım sınırına ulaştı",
    "zh": "快门使用次数达到十万次"
  },
  "fpv_tip_0x1C000306": {
    "de": "Objektiv von Drittanbieter",
    "en": "Non-DJI Enterprise lens",
    "es": "Objetivo que no es de DJI Enterprise",
    "fr": "Objectif non DJI Enterprise",
    "it": "",
    "ja": "DJI Enterprise製以外のレンズ",
    "ko": "비순정 DJI 기업 솔루션 렌즈",
    "pt": "",
    "ru": "Объектив не от DJI Enterprise",
    "tr": "DJI Enterprise marka olmayan mercek",
    "zh": "非DJI ENTERPRISE镜头"
  },
  "fpv_tip_0x1C000310": {
    "de": "Kameraobjektiv nicht erkannt",
    "en": "Camera lens not detected",
    "es": "Objetivo de la cámara no detectado",
    "fr": "Objectif de caméra non détecté",
    "ja": "カメラレンズが検出されませんでした",
    "ko": "카메라 렌즈가 감지되지 않습니다",
    "ru": "Объектив камеры не обнаружен",
    "tr": "Kamera lensi algılanmadı",
    "zh": "相机镜头未连接"
  },
  "fpv_tip_0x1C000311": {
    "de": "Fehler: Kameraobjektiv. Objektiv erneut installieren",
    "en": "Camera lens error. Re-install lens",
    "es": "Error del objetivo de la cámara. Vuelva a instalar el objetivo",
    "fr": "Erreur de l'objectif de caméra. Réinstaller l'objectif",
    "ja": "カメラレンズエラー。レンズを取り付け直してください",
    "ko": "카메라 렌즈 오류. 렌즈를 재설치하세요.",
    "ru": "Ошибка объектива камеры. Переустановите объектив",
    "tr": "Kamera merceği hatası. Merceği yeniden takın",
    "zh": "相机镜头故障，请尝试重新安装镜头"
  },
  "fpv_tip_0x1C000312": {
    "de": "Fehler: Kameraobjektiv. Objektiv erneut installieren",
    "en": "Camera lens error. Re-install lens",
    "es": "Error del objetivo de la cámara. Vuelva a instalar el objetivo",
    "fr": "Erreur de l'objectif de caméra. Réinstaller l'objectif",
    "ja": "カメラレンズエラー。レンズを取り付け直してください",
    "ko": "카메라 렌즈 오류. 렌즈를 재설치하세요.",
    "ru": "Ошибка объектива камеры. Переустановите объектив",
    "tr": "Kamera merceği hatası. Merceği yeniden takın",
    "zh": "相机镜头故障，请尝试重新安装镜头"
  },
  "fpv_tip_0x1C000313": {
    "de": "Fehler: Kameraobjektiv. Objektiv erneut installieren",
    "en": "Camera lens error. Re-install lens",
    "es": "Error del objetivo de la cámara. Vuelva a instalar el objetivo",
    "fr": "Erreur de l'objectif de caméra. Réinstaller l'objectif",
    "ja": "カメラレンズエラー。レンズを取り付け直してください",
    "ko": "카메라 렌즈 오류. 렌즈를 재설치하세요.",
    "ru": "Ошибка объектива камеры. Переустановите объектив",
    "tr": "Kamera merceği hatası. Merceği yeniden takın",
    "zh": "相机镜头故障，请尝试重新安装镜头"
  },
  "fpv_tip_0x1C000314": {
    "de": "Fehler: Kameraobjektiv. Objektiv erneut installieren",
    "en": "Camera lens error. Re-install lens",
    "es": "Error del objetivo de la cámara. Vuelva a instalar el objetivo",
    "fr": "Erreur de l'objectif de caméra. Réinstaller l'objectif",
    "ja": "カメラレンズエラー。レンズを取り付け直してください",
    "ko": "카메라 렌즈 오류. 렌즈를 재설치하세요.",
    "ru": "Ошибка объектива камеры. Переустановите объектив",
    "tr": "Kamera merceği hatası. Merceği yeniden takın",
    "zh": "相机镜头故障，请尝试重新安装镜头"
  },
  "fpv_tip_0x1C000317": {
    "de": "Kalibrierungsdaten Objektiv fehlerhaft. Objektiv erneut installieren oder Fluggerät neu starten",
    "en": "Lens calibration data error. Re-install lens or restart aircraft",
    "es": "Error de datos de calibración de objetivo. Vuelva a instalar el objetivo o reinicie la aeronave",
    "fr": "Erreur de données de l'étalonnage de l'objectif. Réinstallez l'objectif ou redémarrez l'appareil",
    "ja": "レンズキャリブレーションデータエラーです。レンズを取り付け直すか、機体を再起動してください",
    "ko": "렌즈 캘리브레이션 데이터 오류. 렌즈를 재설치하거나 기체를 재시동하세요",
    "pt": "",
    "ru": "Ошибка данных калибровки объектива. Переустановите объектив или перезапустите дрон",
    "tr": "Lens kalibrasyonu veri hatası. Lensi yeniden takın veya aracı yeniden başlatın",
    "zh": "镜头标定数据异常，请插拔镜头或重启"
  },
  "fpv_tip_0x1C000401": {
    "de": "Ungültige Speicherkarte. Karte austauschen (%alarmid).",
    "en": "Invalid memory card. Replace card (%alarmid)",
    "es": "Tarjeta de memoria no válida. Cambie la tarjeta (%alarmid)",
    "fr": "Carte mémoire non valide. Remplacez la carte (%alarmid)",
    "ja": "メモリーカードが無効です。 カードを交換してください(%alarmid)",
    "ko": "잘못된 메모리 카드입니다. 카드를 교체하세요(%alarmid)",
    "ru": "Недопустимая карта памяти. Замените карту (%alarmid)",
    "tr": "Geçersiz hafıza kartı. Kartı değiştirin (%alarmid)",
    "zh": "无效存储卡，请更换存储卡（%alarmid）"
  },
  "fpv_tip_0x1C000402": {
    "de": "Niedrige Speicherkartengeschwindigkeit. Durch schnellere Karte austauschen.",
    "en": "Memory card speed low. Replace with faster card",
    "es": "Velocidad baja de la tarjeta de memoria. Cambie la tarjeta por una más rápida",
    "fr": "Vitesse de la carte mémoire lente. Remplacez-la par une carte plus rapide",
    "ja": "メモリーカードが低速です。 より高速のカードと交換してください",
    "ko": "메모리 카드 속도 느림. 더 빠른 카드로 교체하세요",
    "ru": "Низкая скорость карты памяти. Замените карту на более быструю",
    "tr": "Hafıza kartının hızı yavaş. Daha hızlı bir hafıza kartı ile değiştirin",
    "zh": "存储卡为慢速卡，请更换"
  },
  "fpv_tip_0x1C000403": {
    "de": "Speicherkartenfehler. Karte austauschen (%alarmid).",
    "en": "Memory card error. Replace card (%alarmid)",
    "es": "Error de la tarjeta de memoria. Cambie la tarjeta (%alarmid)",
    "fr": "Erreur de carte mémoire. Remplacez la carte (%alarmid)",
    "ja": "メモリーカードにエラーが発生しました。 カードを交換してください(%alarmid)",
    "ko": "메모리 카드 오류. 카드를 교체하세요(%alarmid)",
    "ru": "Ошибка карты памяти. Замените карту (%alarmid)",
    "tr": "Hafıza kartı hatası. Kartı değiştirin (%alarmid)",
    "zh": "存储卡异常，请更换存储卡（%alarmid）"
  },
  "fpv_tip_0x1C000404": {
    "de": "Keine Speicherkarte",
    "en": "No memory card",
    "es": "No hay tarjeta de memoria",
    "fr": "Aucune carte mémoire",
    "ja": "メモリーカードがありません",
    "ko": "메모리 카드 없음",
    "ru": "Нет карты памяти",
    "tr": "Hafıza kartı yok",
    "zh": "无存储卡"
  },
  "fpv_tip_0x1C000405": {
    "de": "Lese- und Schreibberechtigungen für Speicherkarte bestätigen",
    "en": "Confirm memory card read and write permissions",
    "es": "Confirme los permisos de lectura y escritura de la tarjeta de memoria",
    "fr": "Confirmez les autorisations de lecture et d'écriture de la carte mémoire",
    "it": "",
    "ja": "メモリーカードの読み取り／書き込み権限を確認してください",
    "ko": "메모리 카드 읽기 및 쓰기 권한을 확인하세요",
    "pt": "",
    "ru": "Подтвердите наличие разрешений на чтение и запись карты памяти",
    "tr": "Hafıza kartı okuma ve yazma izinlerini onaylayın",
    "zh": "请确认存储卡读写属性"
  },
  "fpv_tip_0x1C000407": {
    "de": "Speicherkarte wird formatiert...",
    "en": "Formatting memory card...",
    "es": "Formateando la tarjeta de memoria...",
    "fr": "Formatage de la carte mémoire…",
    "it": "",
    "ja": "メモリーカードのフォーマット中...",
    "ko": "메모리 카드 포맷 중...",
    "pt": "",
    "ru": "Форматирование карты памяти...",
    "tr": "Hafıza kartı biçimlendiriliyor...",
    "zh": "存储卡正在格式化，请等待"
  },
  "fpv_tip_0x1C000408": {
    "de": "Dateisystem der Speicherkarte wird nicht unterstützt. Karte vor Gebrauch formatieren.",
    "en": "Memory card file system not supported. Format card before use",
    "es": "Sistema de archivos de la tarjeta de memoria no compatible. Formatee la tarjeta antes del uso",
    "fr": "Système de fichiers de la carte mémoire non pris en charge. Formatez la carte avant utilisation",
    "it": "",
    "ja": "メモリーカードファイルシステムはサポートされていません。 カードをフォーマットしてから使用してください",
    "ko": "메모리 카드 파일 시스템이 지원되지 않습니다. 사용 전에 카드를 포맷하세요",
    "pt": "",
    "ru": "Файловая система карты памяти не поддерживается. Отформатируйте карту перед использованием",
    "tr": "Hafıza kartı dosya sistemi desteklenmiyor. Kullanmadan önce kartı biçimlendirin",
    "zh": "不支持该存储卡文件系统，请格式化后使用"
  },
  "fpv_tip_0x1C00040A": {
    "de": "Speicherkarte voll. Speicherplatz freigeben.",
    "en": "Memory card full. Clear space",
    "es": "Tarjeta de memoria llena. Libere espacio",
    "fr": "Carte mémoire pleine. Libérez de l'espace",
    "it": "",
    "ja": "メモリーカードが満杯です。 領域をクリア",
    "ko": "메모리 카드 공간 없음. 공간을 확보하세요",
    "pt": "",
    "ru": "Карта памяти заполнена. Освободите место",
    "tr": "Hafıza kartı dolu. Yer açın",
    "zh": "存储卡已满，请清除内存"
  },
  "fpv_tip_0x1C00040B": {
    "de": "Max. Dateiindex überschritten. SD-Karte formatieren",
    "en": "Max file index exceeded. Format SD card",
    "es": "Se ha superado el índice máximo de archivos. Formatear tarjeta SD",
    "fr": "Index de fichier max. dépassé. Formater la carte SD",
    "it": "",
    "ja": "ファイルインデックスの最大値を超えました。SDカードをフォーマット",
    "ko": "최대 파일 색인을 초과함. SD 카드 포맷",
    "pt": "",
    "ru": "Превышен максимальный индекс файла. Форматировать карту памяти SD",
    "tr": "Maks. dosya dizini aşıldı. SD kartı biçimlendir",
    "zh": "文件索引超出最大限制，请格式化SD卡"
  },
  "fpv_tip_0x1C00040C": {
    "de": "Speicherkarte wird initialisiert...",
    "en": "Initializing memory card...",
    "es": "Inicializando tarjeta de memoria...",
    "fr": "Initialisation de la carte mémoire…",
    "it": "",
    "ja": "メモリーカードの初期化中...",
    "ko": "메모리 카드 초기화 중...",
    "pt": "",
    "ru": "Инициализация карты памяти...",
    "tr": "Hafıza kartı başlatılıyor...",
    "zh": "存储卡正在初始化，请等待"
  },
  "fpv_tip_0x1C00040D": {
    "de": "Speicherkartenfehler. Karte vor Gebrauch formatieren.",
    "en": "Memory card error. Format card before use",
    "es": "Error de la tarjeta de memoria. Formatee la tarjeta antes del uso",
    "fr": "Erreur de carte mémoire. Formatez la carte avant utilisation",
    "it": "",
    "ja": "メモリーカードにエラーが発生しました。 カードをフォーマットしてから使用してください",
    "ko": "메모리 카드 오류. 사용 전에 카드를 포맷하세요",
    "pt": "",
    "ru": "Ошибка карты памяти. Отформатируйте карту перед использованием",
    "tr": "Hafıza kartı hatası. Kullanmadan önce kartı biçimlendirin",
    "zh": "存储卡异常，请格式化存储卡后使用"
  },
  "fpv_tip_0x1C00040E": {
    "de": "Niedrige Speicherkarten-Schreibgeschwindigkeit",
    "en": "Memory card write speed low",
    "es": "Velocidad baja de escritura de la tarjeta de memoria",
    "fr": "Vitesse d'écriture de la carte mémoire lente",
    "it": "",
    "ja": "メモリーカード書き込みが低速です",
    "ko": "메모리 카드 쓰기 속도 느림",
    "pt": "",
    "ru": "Низкая скорость записи карты памяти",
    "tr": "Hafıza kartının yazma hızı düşük",
    "zh": "存储卡写入速度过慢"
  },
  "fpv_tip_0x1C00040F": {
    "de": "Niedrige Speicherkarten-Schreibgeschwindigkeit",
    "en": "Memory card write speed low",
    "es": "Velocidad baja de escritura de la tarjeta de memoria",
    "fr": "Vitesse d'écriture de la carte mémoire lente",
    "ja": "メモリーカード書き込みが低速です",
    "ko": "메모리 카드 쓰기 속도 느림",
    "ru": "Низкая скорость записи карты памяти",
    "tr": "Hafıza kartının yazma hızı düşük",
    "zh": "存储卡写入速度过慢"
  },
  "fpv_tip_0x1C000411": {
    "de": "Schreibfehler auf SD-Karte. SD-Karte austauschen",
    "en": "SD card write error. Replace SD card",
    "es": "Error de escritura de la tarjeta SD. Cambie la tarjeta SD",
    "fr": "Erreur écriture carte SD. Remplacez la carte SD",
    "ja": "SDカード書き込みエラー。SDカードを交換してください",
    "ko": "SD 카드 쓰기 오류. SD 카드를 교체하세요",
    "ru": "Ошибка записи SD-карты. Замените SD-карту",
    "tr": "SD kart yazma hatası. SD kartı değiştirin",
    "zh": "SD卡写入异常，请更换"
  },
  "fpv_tip_0x1C000412": {
    "ar": "",
    "de": "",
    "en": "Verification required before using memory card. Enter password to verify",
    "es": "",
    "fr": "",
    "it": "",
    "ja": "",
    "ko": "",
    "pt": "",
    "ru": "",
    "tr": "",
    "zh": "存储卡加密待验证，请输入密码验证后使用"
  },
  "fpv_tip_0x1C000414": {
    "de": "Speicher der SD-Karte stark fragmentiert. Dateien sichern und SD-Karte formatieren",
    "en": "SD card storage severely fragmented. Back up files and format SD card",
    "es": "El almacenamiento de la tarjeta SD está gravemente fragmentado. Realiza una copia de seguridad y formatea la tarjeta SD",
    "fr": "Stockage sur carte SD très fragmenté. Sauvegardez les fichiers et formatez la carte SD",
    "ja": "SDカードストレージが重度に断片化されています。ファイルをバックアップして、SDカードをフォーマットしてください",
    "ko": "SD 카드 저장 장치의 데이터가 심각하게 조각나 있습니다. 파일을 백업하고 SD 카드를 포맷하세요.",
    "ru": "Память SD-карты сильно фрагментирована. Выполните резервное копирование файлов и отформатируйте SD-карту",
    "tr": "SD kart depolama alanı ciddi şekilde parçalanmış. Dosyaları yedekleyin ve SD kartı biçimlendirin",
    "zh": "SD卡存储碎片化程度严重，请备份素材后格式化"
  },
  "fpv_tip_0x1C000602": {
    "de": "Umgebungslicht zu dunkel",
    "en": "Ambient Light Too Low",
    "es": "Luz ambiente demasiado baja",
    "fr": "Lumière ambiante trop faible",
    "it": "",
    "ja": "周辺光が暗すぎます",
    "ko": "주변 조명 너무 어두움",
    "pt": "",
    "ru": "Слишком слабое освещение",
    "tr": "Ortam Işığı Çok Düşük",
    "zh": "相机拍照环境过暗"
  },
  "fpv_tip_0x1C000603": {
    "de": "Fotos können unterbelichtet sein",
    "en": "Photos may be underexposed",
    "es": "Las fotos pueden aparecer subexpuestas",
    "fr": "Les photos peuvent être sous-exposées",
    "ja": "写真の露出不足の可能性があります",
    "ko": "사진이 노출 부족일 수 있음",
    "ru": "Фотографии могут быть недоэкспонированы",
    "tr": "Fotoğraflar yetersiz pozlanmış olabilir",
    "zh": "照片存在欠曝风险"
  },
  "fpv_tip_0x1C000604": {
    "de": "Fotos können überbelichtet sein",
    "en": "Photos may be overexposed",
    "es": "Las fotos pueden aparecer sobreexpuestas",
    "fr": "Les photos peuvent être sur-exposées",
    "ja": "写真の露出オーバーの可能性があります",
    "ko": "사진이 노출 과다일 수 있음",
    "ru": "Фотографии могут быть переэкспонированы",
    "tr": "Fotoğraflar aşırı pozlanmış olabilir",
    "zh": "照片存在过曝风险"
  },
  "fpv_tip_0x1C000901": {
    "de": "Fehler: Senden von PPS-Signal",
    "en": "PPS signal sending error",
    "es": "Error de envío de la señal PPS",
    "fr": "Erreur d\\'envoi du signal PPS",
    "it": "",
    "ja": "PPS 信号送信エラー",
    "ko": "PPS 신호 전송 오류",
    "pt": "",
    "ru": "Ошибка отправки сигнала PPS",
    "tr": "PPS sinyali gönderim hatası",
    "zh": "PPS对时信号异常"
  },
  "fpv_tip_0x1C000902": {
    "de": "Fehler: Zeitsynchronisierung der Kamera",
    "en": "Camera time synchronization error",
    "es": "Error de sincronización de la hora de la cámara",
    "fr": "Erreur de synchronisation de l\\'heure de la caméra",
    "it": "",
    "ja": "カメラの時間同期エラー",
    "ko": "카메라 시간 동기화 오류",
    "pt": "",
    "ru": "Ошибка синхронизации времени камеры",
    "tr": "Kamera saati senkronizasyon hatası",
    "zh": "相机时间同步异常"
  },
  "fpv_tip_0x1C000903": {
    "de": "Hardwarefehler: Kamera. Neu starten und erneut versuchen",
    "en": "Camera hardware error. Restart and try again",
    "es": "Error de hardware de la cámara. Reinicia y vuelve a intentarlo.",
    "fr": "Erreur matérielle de caméra. Redémarrez et réessayez",
    "ja": "カメラのハードウェアエラー。再起動してやり直してください",
    "ko": "카메라 하드웨어 오류. 재시작한 후 다시 시도하세요.",
    "ru": "Аппаратная ошибка камеры. Перезапустите дрон и повторите попытку",
    "tr": "Kamera donanım hatası. Yeniden başlatın ve tekrar deneyin",
    "zh": "相机硬件异常，请尝试重新启动"
  },
  "fpv_tip_0x1C000904": {
    "de": "Hardwarefehler: Kamera. Neu starten und erneut versuchen",
    "en": "Camera hardware error. Restart and try again",
    "es": "Error de hardware de la cámara. Reinicia y vuelve a intentarlo.",
    "fr": "Erreur matérielle de caméra. Redémarrez et réessayez",
    "ja": "カメラのハードウェアエラー。再起動してやり直してください",
    "ko": "카메라 하드웨어 오류. 재시작한 후 다시 시도하세요.",
    "ru": "Аппаратная ошибка камеры. Перезапустите дрон и повторите попытку",
    "tr": "Kamera donanım hatası. Yeniden başlatın ve tekrar deneyin",
    "zh": "相机硬件异常，请尝试重新启动"
  },
  "fpv_tip_0x1C000905": {
    "de": "Hardwarefehler: Kamera. Neu starten und erneut versuchen",
    "en": "Camera hardware error. Restart and try again",
    "es": "Error de hardware de la cámara. Reinicia y vuelve a intentarlo.",
    "fr": "Erreur matérielle de caméra. Redémarrez et réessayez",
    "ja": "カメラのハードウェアエラー。再起動してやり直してください",
    "ko": "카메라 하드웨어 오류. 재시작한 후 다시 시도하세요.",
    "ru": "Аппаратная ошибка камеры. Перезапустите дрон и повторите попытку",
    "tr": "Kamera donanım hatası. Yeniden başlatın ve tekrar deneyin",
    "zh": "相机硬件异常，请尝试重新启动"
  },
  "fpv_tip_0x1C000906": {
    "de": "Hardwarefehler: Kamera. Neu starten und erneut versuchen",
    "en": "Camera hardware error. Restart and try again",
    "es": "Error de hardware de la cámara. Reinicia y vuelve a intentarlo.",
    "fr": "Erreur matérielle de caméra. Redémarrez et réessayez",
    "ja": "カメラのハードウェアエラー。再起動してやり直してください",
    "ko": "카메라 하드웨어 오류. 재시작한 후 다시 시도하세요.",
    "ru": "Аппаратная ошибка камеры. Перезапустите дрон и повторите попытку",
    "tr": "Kamera donanım hatası. Yeniden başlatın ve tekrar deneyin",
    "zh": "相机硬件异常，请尝试重新启动"
  },
  "fpv_tip_0x1C000907": {
    "de": "Hardwarefehler: Kamera. Neu starten und erneut versuchen",
    "en": "Camera hardware error. Restart and try again",
    "es": "Error de hardware de la cámara. Reinicia y vuelve a intentarlo.",
    "fr": "Erreur matérielle de caméra. Redémarrez et réessayez",
    "ja": "カメラのハードウェアエラー。再起動してやり直してください",
    "ko": "카메라 하드웨어 오류. 재시작한 후 다시 시도하세요.",
    "ru": "Аппаратная ошибка камеры. Перезапустите дрон и повторите попытку",
    "tr": "Kamera donanım hatası. Yeniden başlatın ve tekrar deneyin",
    "zh": "相机硬件异常，请尝试重新启动"
  },
  "fpv_tip_0x1C000908": {
    "de": "Hardwarefehler: Kamera. Neu starten und erneut versuchen",
    "en": "Camera hardware error. Restart and try again",
    "es": "Error de hardware de la cámara. Reinicia y vuelve a intentarlo.",
    "fr": "Erreur matérielle de caméra. Redémarrez et réessayez",
    "ja": "カメラのハードウェアエラー。再起動してやり直してください",
    "ko": "카메라 하드웨어 오류. 재시작한 후 다시 시도하세요.",
    "ru": "Аппаратная ошибка камеры. Перезапустите дрон и повторите попытку",
    "tr": "Kamera donanım hatası. Yeniden başlatın ve tekrar deneyin",
    "zh": "相机硬件异常，请尝试重新启动"
  },
  "fpv_tip_0x1C000909": {
    "de": "Hardwarefehler: Kamera. Neu starten und erneut versuchen",
    "en": "Camera hardware error. Restart and try again",
    "es": "Error de hardware de la cámara. Reinicia y vuelve a intentarlo.",
    "fr": "Erreur matérielle de caméra. Redémarrez et réessayez",
    "ja": "カメラのハードウェアエラー。再起動してやり直してください",
    "ko": "카메라 하드웨어 오류. 재시작한 후 다시 시도하세요.",
    "ru": "Аппаратная ошибка камеры. Перезапустите дрон и повторите попытку",
    "tr": "Kamera donanım hatası. Yeniden başlatın ve tekrar deneyin",
    "zh": "相机硬件异常，请尝试重新启动"
  },
  "fpv_tip_0x1C00090A": {
    "de": "Hardwarefehler: Kamera. Neu starten und erneut versuchen",
    "en": "Camera hardware error. Restart and try again",
    "es": "Error de hardware de la cámara. Reinicia y vuelve a intentarlo.",
    "fr": "Erreur matérielle de caméra. Redémarrez et réessayez",
    "ja": "カメラのハードウェアエラー。再起動してやり直してください",
    "ko": "카메라 하드웨어 오류. 재시작한 후 다시 시도하세요.",
    "ru": "Аппаратная ошибка камеры. Перезапустите дрон и повторите попытку",
    "tr": "Kamera donanım hatası. Yeniden başlatın ve tekrar deneyin",
    "zh": "相机硬件异常，请尝试重新启动"
  },
  "fpv_tip_0x1C000A01": {
    "de": "Bei der Aufnahme wurden Bildformate verworfen. Firmware-Aktualisierung empfohlen",
    "en": "Frames dropped in recording. Firmware update recommended",
    "es": "Drop frames en la grabación. Se recomienda actualizar el firmware",
    "fr": "Images perdues lors de l’enregistrement. Mise à jour du firmware recommandée",
    "ja": "撮影中にコマ落ちがありました。ファームウェア更新をお勧めします",
    "ko": "녹화 중 프레임이 떨어졌습니다. 펌웨어 업데이트를 권장합니다",
    "ru": "Потеря кадров в записи. Рекомендуется обновить прошивку",
    "tr": "Kayıt sırasında kare sayısı düştü. Ürün yazılımını güncellemeniz önerilir",
    "zh": "录像丢帧，建议升级固件"
  },
  "fpv_tip_0x1C000A02": {
    "de": "Bei der Aufnahme wurden Bildformate verworfen. Firmware-Aktualisierung empfohlen",
    "en": "Frames dropped in recording. Firmware update recommended",
    "es": "Drop frames en la grabación. Se recomienda actualizar el firmware",
    "fr": "Images perdues lors de l’enregistrement. Mise à jour du firmware recommandée",
    "ja": "撮影中にコマ落ちがありました。ファームウェア更新をお勧めします",
    "ko": "녹화 중 프레임이 떨어졌습니다. 펌웨어 업데이트를 권장합니다",
    "ru": "Потеря кадров в записи. Рекомендуется обновить прошивку",
    "tr": "Kayıt sırasında kare sayısı düştü. Ürün yazılımını güncellemeniz önerilir",
    "zh": "录像丢帧，建议升级固件"
  },
  "fpv_tip_0x1C000B01": {
    "de": "Hardwarefehler: Kamera. Neu starten und erneut versuchen",
    "en": "Camera hardware error. Restart and try again",
    "es": "Error de hardware de la cámara. Reinicia y vuelve a intentarlo.",
    "fr": "Erreur matérielle de caméra. Redémarrez et réessayez",
    "ja": "カメラのハードウェアエラー。再起動してやり直してください",
    "ko": "카메라 하드웨어 오류. 재시작한 후 다시 시도하세요.",
    "ru": "Аппаратная ошибка камеры. Перезапустите дрон и повторите попытку",
    "tr": "Kamera donanım hatası. Yeniden başlatın ve tekrar deneyin",
    "zh": "相机硬件异常，请尝试重新启动"
  },
  "fpv_tip_0x1C000D01": {
    "de": "Fokussierung fehlgeschlagen",
    "en": "Focusing failed",
    "es": "Error de enfoque",
    "fr": "Échec de la mise au point",
    "it": "",
    "ja": "フォーカス失敗",
    "ko": "포커스 맞추기 실패",
    "pt": "",
    "ru": "Сбой фокусировки",
    "tr": "Odaklama başarısız",
    "zh": "对焦失败"
  },
  "fpv_tip_0x1C000D02": {
    "de": "Unendlichkeitsfokus des Objektives nicht kalibriert",
    "en": "Lens infinity focus not calibrated",
    "es": "Enfoque infinito del objetivo no calibrado",
    "fr": "Objectif mise au point infinie non étalonné",
    "it": "",
    "ja": "レンズの無限遠フォーカスがキャリブレーションされていません",
    "ko": "렌즈 무한대 초점 캘리브레이션 안 됨",
    "pt": "",
    "ru": "Сбой загрузки файла калибровки камеры",
    "tr": "Mercek sonsuz odağı kalibre edilmemiş",
    "zh": "镜头未进行无穷远标定"
  },
  "fpv_tip_0x1C000D03": {
    "de": "Kalibrierungsdaten von Unendlichkeitsfokus abgelaufen. Erneut kalibrieren",
    "en": "Infinity focus calibration data expired. Recalibrate",
    "es": "Los datos de calibración de enfoque infinito han caducado. Vuelve a calibrar",
    "fr": "Données d\\'étalonnage de la mise au point infinie expirées. Étalonnez à nouveau",
    "it": "",
    "ja": "無限遠フォーカスのキャリブレーションデータの期限が切れています。再キャリブレーションしてください",
    "ko": "무한대 포커스 캘리브레이션 데이터 만료. 다시 캘리브레이션 하세요",
    "pt": "",
    "ru": "Истек срок действия данных калибровки фокусировки на бесконечность. Выполните повторную калибровку",
    "tr": "Sonsuz odak kalibrasyonu verilerinin süresi doldu. Tekrar kalibre edin",
    "zh": "无穷远标定数据过期，请重新标定"
  },
  "fpv_tip_0x1C000D04": {
    "de": "Kalibrierungsdaten von Unendlichkeitsfokus abgelaufen. Erneut kalibrieren",
    "en": "Infinity focus calibration data expired. Recalibrate",
    "es": "Los datos de calibración de enfoque infinito han caducado. Vuelve a calibrar",
    "fr": "Données d'étalonnage de la mise au point infinie expirées. Étalonnez à nouveau",
    "it": "",
    "ja": "無限遠フォーカスのキャリブレーションデータの期限が切れています。再キャリブレーションしてください",
    "ko": "무한대 포커스 캘리브레이션 데이터 만료. 다시 캘리브레이션 하세요",
    "pt": "",
    "ru": "Истек срок действия данных калибровки фокусировки на бесконечность. Выполните повторную калибровку",
    "tr": "Sonsuz odak kalibrasyonu verilerinin süresi doldu. Tekrar kalibre edin",
    "zh": "无穷远标定数据过期，请重新标定"
  },
  "fpv_tip_0x1C000E01": {
    "de": "Kalibrierungsparameter von Kamera und Objektiv stimmen nicht überein",
    "en": "Camera and lens intrinsic parameters calibration data do not match",
    "es": "Los datos de calibración de los parámetros internos de la cámara y el objetivo no coinciden",
    "fr": "Données d\\'étalonnage des paramètres intrinsèques de la caméra et de l\\'objectif incohérents",
    "it": "",
    "ja": "カメラとレンズの内部パラメーターのキャリブレーションデータが不一致",
    "ko": "카메라 및 렌즈 내부 매개변수 캘리브레이션 데이터 불일치",
    "pt": "",
    "ru": "Параметры калибровки не соответствуют камере или объективу",
    "tr": "Kamera ve mercek yapısal parametre kalibrasyonu verileri eşleşmiyor",
    "zh": "镜头与相机内参检校不匹配"
  },
  "fpv_tip_0x1C000E02": {
    "de": "Kalibrierungsparameter abgelaufen",
    "en": "Intrinsic parameters calibration data expired",
    "es": "Los datos de calibración de los parámetros internos han caducado",
    "fr": "Données d\\'étalonnage des paramètres intrinsèques de la caméra expirées",
    "it": "",
    "ja": "内部パラメーターのキャリブレーションデータが期限切れ",
    "ko": "내부 매개변수 캘리브레이션 데이터 만료",
    "pt": "",
    "ru": "Срок действия данных калибровки внутренних параметров камеры истек",
    "tr": "Yapısal parametre kalibrasyonu verilerinin süresi doldu",
    "zh": "内参检校参数过旧"
  },
  "fpv_tip_0x1C001001": {
    "de": "DJI PROSSD-Fragmentierungsrisiko erkannt. Formatierung des Speichergeräts empfohlen",
    "en": "DJI PROSSD fragmentation risk detected. Formatting storage device recommended",
    "es": "Riesgo de fragmentación de DJI PROSSD detectado. Se recomienda formatear el dispositivo de almacenamiento",
    "fr": "Risque de fragmentation DJI PROSSD détecté. Formatage du périphérique de stockage recommandé",
    "ja": "DJI PROSSDの断片化のリスクが検出されました。ストレージ機器のフォーマットをお勧めします",
    "ko": "DJI PROSSD 단편화 위험이 감지되었습니다. 저장 장치 포맷을 권장합니다",
    "ru": "Обнаружен риск фрагментации DJI PROSSD. Рекомендуется форматирование устройства хранения",
    "tr": "DJI PROSSD parçalanma riski algılandı. Depolama cihazının biçimlendirilmesi önerilir",
    "zh": "DJI PROSSD存储碎片化即将满，建议格式化"
  },
  "fpv_tip_0x1C001002": {
    "de": "DJI PROSSD fragmentiert. Formatierung des Speichergeräts empfohlen",
    "en": "DJI PROSSD fragmented. Formatting storage device recommended",
    "es": "DJI PROSSD fragmentado. Se recomienda formatear el dispositivo de almacenamiento",
    "fr": "DJI PROSSD fragmenté. Formatage du périphérique de stockage recommandé",
    "ja": "DJI PROSSDが断片化しています。ストレージ機器のフォーマットをお勧めします",
    "ko": "DJI PROSSD가 단편화되었습니다. 저장 장치 포맷을 권장합니다",
    "ru": "DJI PROSSD фрагментирован. Рекомендуется форматирование устройства хранения",
    "tr": "DJI PROSSD parçalandı. Depolama cihazının biçimlendirilmesi önerilir",
    "zh": "DJI PROSSD存储碎片化已满，建议格式化"
  },
  "fpv_tip_0x1C001003": {
    "de": "",
    "en": "",
    "es": "",
    "fr": "Surchauffe du processeur de la caméra . Retournez au point de départ ou atterrissez immédiatement et attendez que la température de la caméra revienne à la normale avant usage\r\r\n\r\r\nSurchauffe du processeur de la caméra . Retournez au point de départ ou atterrissez immédiatement et attendez que la température de la caméra revienne à la normale avant usage",
    "ja": "",
    "ko": "",
    "ru": "",
    "tr": "",
    "zh": "获取 DJI PROSSD 存储信息失败，避免存储风险，请备份素材后格式化"
  },
  "fpv_tip_0x1C001101": {
    "de": "Das Ende der Lebensdauer von DJI PROSSD ist bald erreicht. Austausch des Speichergeräts empfohlen.",
    "en": "DJI PROSSD approaching end of lifespan. Replacing storage device recommended",
    "es": "DJI PROSSD cerca del final de su vida útil. Se recomienda sustituir el dispositivo de almacenamiento",
    "fr": "DJI PROSSD bientôt en fin de vie. Remplacement du périphérique de stockage recommandé",
    "ja": "DJI PROSSDが有効期限に近づいています。ストレージ機器の交換をお勧めします",
    "ko": "DJI PROSSD가 수명을 거의 다했습니다. 저장 장치 교체를 권장합니다",
    "ru": "Срок службы DJI PROSSD подходит к концу. Рекомендуется замена устройства хранения",
    "tr": "DJI PROSSD kullanım ömrünün sonuna yaklaşıyor. Depolama cihazının yenilenmesi önerilir",
    "zh": "DJI PROSSD读写量即将到达上限，建议更换"
  },
  "fpv_tip_0x1C001102": {
    "de": "Das Ende der Lebensdauer von DJI PROSSD ist erreicht. Austausch des Speichergeräts empfohlen",
    "en": "DJI PROSSD reached end of lifespan. Replacing storage device recommended",
    "es": "DJI PROSSD ha alcanzado el final de su vida útil. Se recomienda sustituir el dispositivo de almacenamiento",
    "fr": "DJI PROSSD a atteint sa fin de vie. Remplacement du périphérique de stockage recommandé",
    "ja": "DJI PROSSDが有効期限に達しました。ストレージ機器の交換をお勧めします",
    "ko": "DJI PROSSD가 수명을 다했습니다. 저장 장치 교체를 권장합니다",
    "ru": "Срок службы DJI PROSSD подошел к концу. Рекомендуется замена устройства хранения",
    "tr": "DJI PROSSD kullanım ömrünün sonuna erişti. Depolama cihazının yenilenmesi önerilir",
    "zh": "DJI PROSSD读写量已达上限，建议更换"
  },
  "fpv_tip_0x1C001103": {
    "de": "In DJI PROSSD gespeicherte Daten konnten nicht abgerufen werden. Sichere die Dateien, bevor du DJI PROSSD formatierst, um Datenverlust zu vermeiden",
    "en": "Failed to obtain data stored in DJI PROSSD. Back up files before formatting DJI PROSSD to avoid data loss",
    "es": "Error al obtener los datos almacenados en DJI PROSSD. Para evitar la pérdida de datos, realice una copia de seguridad de los archivos antes de formatear DJI PROSSD",
    "fr": "Impossible d'obtenir les données stockées dans DJI PROSSD. Sauvegardez les fichiers avant de formater DJI PROSSD pour éviter toute perte de données",
    "ja": "DJI ProSSDに保存されているデータの取得に失敗しました。データの消失を回避するため、DJI ProSSDをフォーマットする前にファイルをバックアップしてください",
    "ko": "DJI PROSSD에 저장된 데이터를 가져오지 못했습니다. 데이터 손실을 방지하기 위해 DJI PROSSD를 포맷하기 전에 파일을 백업하세요.",
    "ru": "Не удалось получить данные из DJI PROSSD. Во избежание потери данных выполните резервное копирование файлов перед форматированием DJI PROSSD",
    "tr": "DJI PROSSD'de saklanan veriler alınamadı. Veri kaybını önlemek için DJI PROSSD'yi biçimlendirmeden önce dosyaları yedekleyin",
    "zh": "获取 DJI PROSSD 存储信息失败，避免存储风险，请备份素材后格式化"
  },
  "fpv_tip_0x1C001201": {
    "de": "DJI PROSSD zum Starten der Aufnahme verwenden",
    "en": "Use DJI PROSSD to start recording",
    "es": "Utilizar DJI PROSSD para iniciar grabación",
    "fr": "Utiliser DJI PROSSD pour lancer l’enregistrement",
    "ja": "DJI PROSSDを使用して撮影を開始してください",
    "ko": "DJI PROSSD를 사용해 녹화를 시작하세요",
    "ru": "Используйте DJI PROSSD, чтобы начать запись",
    "tr": "Kayda başlamak için DJI PROSSD'yi kullanın",
    "zh": "请使用DJI PROSSD以正常使用录像功能"
  },
  "fpv_tip_0x1C001301": {
    "de": "Das Video konnte nicht in den Speicher geschrieben werden. Aufnahme wird gestoppt...",
    "en": "Failed to write video to storage. Stopping recording...",
    "es": "No se ha podido registrar el video en el almacenamiento. Deteniendo la grabación...",
    "fr": "Échec de l’écriture de la vidéo dans le stockage. Arrêt de l’enregistrement en cours...",
    "ja": "ストレージへの動画の書き込みに失敗しました。撮影を停止しています...",
    "ko": "동영상을 저장 장치에 쓰기하지 못했습니다. 녹화 중단 중...",
    "ru": "Не удалось записать видео в хранилище. Выполняется остановка записи...",
    "tr": "Video, depolama alanına yazılamadı. Kayıt durduruluyor...",
    "zh": "视频写入失败，正在停止录像"
  },
  "fpv_tip_0x1C001302": {
    "de": "Niedrige DJI PROSSD-Schreibgeschwindigkeit. Aufnahme wird gestoppt...",
    "en": "DJI PROSSD write speed low. Stopping recording...",
    "es": "Baja velocidad de escritura en DJI PROSSD. Deteniendo la grabación...",
    "fr": "Vitesse d’écriture DJI PROSSD faible. Arrêt de l’enregistrement en cours...",
    "ja": "DJI PROSSDの書き込みが低速です。撮影を停止しています...",
    "ko": "DJI PROSSD 쓰기 속도가 낮습니다. 녹화 중단 중...",
    "ru": "Низкая скорость записи DJI PROSSD. Выполняется остановка записи...",
    "tr": "DJI PROSSD yazma hızı düşük. Kayıt durduruluyor...",
    "zh": "DJI PROSSD写入速度慢，正在停止录像"
  },
  "fpv_tip_0x1C001303": {
    "de": "Unzureichender Speicherplatz auf DJI PROSSD. Aufnahme wird gestoppt...",
    "en": "Insufficient storage on DJI PROSSD. Stopping recording...",
    "es": "Almacenamiento insuficiente en DJI PROSSD. Deteniendo la grabación...",
    "fr": "Stockage insuffisant sur DJI PROSSD. Arrêt de l’enregistrement en cours...",
    "ja": "DJI PROSSDのストレージが不足しています。撮影を停止しています...",
    "ko": "DJI PROSSD에 저장 공간이 충분하지 않습니다. 녹화 중단 중...",
    "ru": "Недостаточно места на DJI PROSSD. Выполняется остановка записи...",
    "tr": "DJI PROSSD'de yetersiz depolama alanı. Kayıt durduruluyor...",
    "zh": "DJI PROSSD剩余空间不足，正在停止录像"
  },
  "fpv_tip_0x1C001303_special": {
    "de": "Nicht genügend Speicherplatz auf der Speicherkarte. Aufnahme wird gestoppt ...",
    "en": "Insufficient storage on memory card. Stopping recording...",
    "es": "Almacenamiento insuficiente en la tarjeta de memoria. Deteniendo la grabación...",
    "fr": "Espace de stockage insuffisant sur la carte mémoire. Arrêt de l’enregistrement en cours...",
    "ja": "メモリーカードのストレージが不足しています。撮影を停止しています...",
    "ko": "메모리 카드의 저장 공간 부족. 녹화 중단 중...",
    "ru": "Недостаточно места на карте памяти. Выполняется остановка записи",
    "tr": "Hafıza kartında yetersiz depolama alanı. Kayıt durduruluyor...",
    "zh": "存储卡剩余空间不足，正在停止录像"
  },
  "fpv_tip_0x1C001306": {
    "en": "System busy. Recording not allowed",
    "zh": "系统业务繁忙，禁止录像"
  },
  "fpv_tip_0x1C001307": {
    "en": "System not busy. Recording available",
    "zh": "业务繁忙解除，允许录像"
  },
  "fpv_tip_0x1C001401": {
    "de": "Die Kameraverbindung ist instabil. Den Gimbal lösen und wieder montieren",
    "en": "Camera connection unstable. Detach and remount gimbal",
    "es": "La conexión de la cámara es inestable. Retira el estabilizador y vuélvelo a montar",
    "fr": "Connexion caméra instable. Détacher et remonter la nacelle",
    "ja": "カメラの接続が不安定です。ジンバルを取り付け直してください",
    "ko": "카메라 연결이 불안정합니다. 짐벌을 분리했다가 다시 장착하세요",
    "ru": "Нестабильное соединение с камерой. Отсоедините и переустановите гиростабилизатор",
    "tr": "Kamera bağlantısı güvenilir değil. Gimbalı çıkarın ve yeniden takın",
    "zh": "相机信号链路不稳定，请尝试重新拔插云台"
  },
  "fpv_tip_0x1C001402": {
    "de": "Die Kameraverbindung ist instabil. Den Gimbal lösen und wieder montieren.",
    "en": "Camera connection unstable. Detach and remount gimbal",
    "es": "La conexión de la cámara es inestable. Retira el estabilizador y vuélvelo a montar",
    "fr": "Connexion caméra instable. Détacher et remonter la nacelle",
    "ja": "カメラの接続が不安定です。ジンバルを取り付け直してください",
    "ko": "카메라 연결이 불안정합니다. 짐벌을 분리했다가 다시 장착하세요",
    "ru": "Нестабильное соединение с камерой. Отсоедините и переустановите гиростабилизатор",
    "tr": "Kamera bağlantısı güvenilir değil. Gimbalı çıkarın ve yeniden takın",
    "zh": "相机信号链路不稳定，请尝试重新拔插云台"
  },
  "fpv_tip_0x1C001403": {
    "de": "Die Kameraverbindung ist instabil. Den Gimbal lösen und wieder montieren",
    "en": "Camera connection unstable. Detach and remount gimbal",
    "es": "La conexión de la cámara es inestable. Retira el estabilizador y vuélvelo a montar",
    "fr": "Connexion caméra instable. Détacher et remonter la nacelle",
    "ja": "カメラの接続が不安定です。ジンバルを取り付け直してください",
    "ko": "카메라 연결이 불안정합니다. 짐벌을 분리했다가 다시 장착하세요",
    "ru": "Нестабильное соединение с камерой. Отсоедините и переустановите гиростабилизатор",
    "tr": "Kamera bağlantısı güvenilir değil. Gimbalı çıkarın ve yeniden takın",
    "zh": "相机信号链路不稳定，请尝试重新拔插云台"
  },
  "fpv_tip_0x1C001404": {
    "de": "Die Kameraverbindung ist instabil. Den Gimbal lösen und wieder montieren",
    "en": "Camera connection unstable. Detach and remount gimbal",
    "es": "La conexión de la cámara es inestable. Retira el estabilizador y vuélvelo a montar",
    "fr": "Connexion caméra instable. Détacher et remonter la nacelle",
    "ja": "カメラの接続が不安定です。ジンバルを取り付け直してください",
    "ko": "카메라 연결이 불안정합니다. 짐벌을 분리했다가 다시 장착하세요",
    "ru": "Нестабильное соединение с камерой. Отсоедините и переустановите гиростабилизатор",
    "tr": "Kamera bağlantısı güvenilir değil. Gimbalı çıkarın ve yeniden takın",
    "zh": "相机信号链路不稳定，请尝试重新拔插云台"
  },
  "fpv_tip_0x1C001405": {
    "de": "Die Kameraverbindung ist instabil. Den Gimbal lösen und wieder montieren",
    "en": "Camera connection unstable. Detach and remount gimbal",
    "es": "La conexión de la cámara es inestable. Retira el estabilizador y vuélvelo a montar",
    "fr": "Connexion caméra instable. Détacher et remonter la nacelle",
    "ja": "カメラの接続が不安定です。ジンバルを取り付け直してください",
    "ko": "카메라 연결이 불안정합니다. 짐벌을 분리했다가 다시 장착하세요",
    "ru": "Нестабильное соединение с камерой. Отсоедините и переустановите гиростабилизатор",
    "tr": "Kamera bağlantısı güvenilir değil. Gimbalı çıkarın ve yeniden takın",
    "zh": "相机信号链路不稳定，请尝试重新拔插云台"
  },
  "fpv_tip_0x1C001406": {
    "de": "Die Kameraverbindung ist instabil. Den Gimbal lösen und wieder montieren",
    "en": "Camera connection unstable. Detach and remount gimbal",
    "es": "La conexión de la cámara es inestable. Retira el estabilizador y vuélvelo a montar",
    "fr": "Connexion caméra instable. Détacher et remonter la nacelle",
    "ja": "カメラの接続が不安定です。ジンバルを取り付け直してください",
    "ko": "카메라 연결이 불안정합니다. 짐벌을 분리했다가 다시 장착하세요",
    "ru": "Нестабильное соединение с камерой. Отсоедините и переустановите гиростабилизатор",
    "tr": "Kamera bağlantısı güvenilir değil. Gimbalı çıkarın ve yeniden takın",
    "zh": "相机信号链路不稳定，请尝试重新拔插云台"
  },
  "fpv_tip_0x1C001407": {
    "de": "Die Kameraverbindung ist instabil. Den Gimbal lösen und wieder montieren",
    "en": "Camera connection unstable. Detach and remount gimbal",
    "es": "La conexión de la cámara es inestable. Retira el estabilizador y vuélvelo a montar",
    "fr": "Connexion caméra instable. Détacher et remonter la nacelle",
    "ja": "カメラの接続が不安定です。ジンバルを取り付け直してください",
    "ko": "카메라 연결이 불안정합니다. 짐벌을 분리했다가 다시 장착하세요",
    "ru": "Нестабильное соединение с камерой. Отсоедините и переустановите гиростабилизатор",
    "tr": "Kamera bağlantısı güvenilir değil. Gimbalı çıkarın ve yeniden takın",
    "zh": "相机信号链路不稳定，请尝试重新拔插云台"
  },
  "fpv_tip_0x1C001408": {
    "de": "Die Kameraverbindung ist instabil. Den Gimbal lösen und wieder montieren",
    "en": "Camera connection unstable. Detach and remount gimbal",
    "es": "La conexión de la cámara es inestable. Retira el estabilizador y vuélvelo a montar",
    "fr": "Connexion caméra instable. Détacher et remonter la nacelle",
    "ja": "カメラの接続が不安定です。ジンバルを取り付け直してください",
    "ko": "카메라 연결이 불안정합니다. 짐벌을 분리했다가 다시 장착하세요",
    "ru": "Нестабильное соединение с камерой. Отсоедините и переустановите гиростабилизатор",
    "tr": "Kamera bağlantısı güvenilir değil. Gimbalı çıkarın ve yeniden takın",
    "zh": "相机信号链路不稳定，请尝试重新拔插云台"
  },
  "fpv_tip_0x1C001409": {
    "de": "Die Kameraverbindung ist instabil. Den Gimbal lösen und wieder montieren",
    "en": "Camera connection unstable. Detach and remount gimbal",
    "es": "La conexión de la cámara es inestable. Retira el estabilizador y vuélvelo a montar",
    "fr": "Connexion caméra instable. Détacher et remonter la nacelle",
    "ja": "カメラの接続が不安定です。ジンバルを取り付け直してください",
    "ko": "카메라 연결이 불안정합니다. 짐벌을 분리했다가 다시 장착하세요",
    "ru": "Нестабильное соединение с камерой. Отсоедините и переустановите гиростабилизатор",
    "tr": "Kamera bağlantısı güvenilir değil. Gimbalı çıkarın ve yeniden takın",
    "zh": "相机信号链路不稳定，请尝试重新拔插云台"
  },
  "fpv_tip_0x1C00140A": {
    "de": "Die Kameraverbindung ist instabil. Den Gimbal lösen und wieder montieren",
    "en": "Camera connection unstable. Detach and remount gimbal",
    "es": "La conexión de la cámara es inestable. Retira el estabilizador y vuélvelo a montar",
    "fr": "Connexion caméra instable. Détacher et remonter la nacelle",
    "ja": "カメラの接続が不安定です。ジンバルを取り付け直してください",
    "ko": "카메라 연결이 불안정합니다. 짐벌을 분리했다가 다시 장착하세요",
    "ru": "Нестабильное соединение с камерой. Отсоедините и переустановите гиростабилизатор",
    "tr": "Kamera bağlantısı güvenilir değil. Gimbalı çıkarın ve yeniden takın",
    "zh": "相机信号链路不稳定，请尝试重新拔插云台"
  },
  "fpv_tip_0x1C00140E": {
    "de": "Kamerafehler. Neustart des Fluggeräts empfohlen",
    "en": "Camera error. Restarting aircraft recommended",
    "es": "Error de la cámara. Se recomienda reiniciar la aeronave",
    "fr": "Erreur caméra. Redémarrage de l’appareil recommandé",
    "ja": "カメラエラー。機体の再起動をお勧めします",
    "ko": "카메라 오류. 기체 재시작을 권장합니다",
    "ru": "Ошибка камеры. Рекомендуется перезапуск дрона",
    "tr": "Kamera hatası. Hava aracının yeniden başlatılması önerilir",
    "zh": "相机异常，建议重启飞行器"
  },
  "fpv_tip_0x1C00140F": {
    "de": "Die Kameraverbindung ist instabil. Den Gimbal lösen und wieder montieren",
    "en": "Camera connection unstable. Detach and remount gimbal",
    "es": "La conexión de la cámara es inestable. Retira el estabilizador y vuélvelo a montar",
    "fr": "Connexion caméra instable. Détacher et remonter la nacelle",
    "ja": "カメラの接続が不安定です。ジンバルを取り付け直してください",
    "ko": "카메라 연결이 불안정합니다. 짐벌을 분리했다가 다시 장착하세요",
    "ru": "Нестабильное соединение с камерой. Отсоедините и переустановите гиростабилизатор",
    "tr": "Kamera bağlantısı güvenilir değil. Gimbalı çıkarın ve yeniden takın",
    "zh": "相机信号链路不稳定，请尝试重新拔插云台"
  },
  "fpv_tip_0x1C001501": {
    "de": "Lichtsensorfehler. DJI Support kontaktieren",
    "en": "Light sensor error. Contact DJI Support",
    "es": "Error del sensor de luz. Póngase en contacto con la Asistencia técnica de DJI",
    "fr": "Erreur du capteur de lumière. Contactez le service client DJI",
    "ja": "光センサーエラーです。DJIサポートまでお問い合わせください",
    "ko": "광 센서 오류. DJI 고객지원으로 문의하세요",
    "ru": "Ошибка датчика освещенности. Свяжитесь со службой поддержки DJI",
    "tr": "Işık sensörü hatası. DJI Destek birimiyle iletişime geçin",
    "zh": "光照传感器异常，请联系售后服务"
  },
  "fpv_tip_0x1C001502": {
    "de": "Lichtsensorfehler. DJI Support kontaktieren.",
    "en": "Light sensor error. Contact DJI Support",
    "es": "Error del sensor de luz. Póngase en contacto con la Asistencia técnica de DJI",
    "fr": "Light sensor error. Contact DJI Support",
    "ja": "光センサーエラーです。DJIサポートまでお問い合わせください",
    "ko": "광 센서 오류. DJI 고객지원으로 문의하세요",
    "ru": "Ошибка датчика освещенности. Свяжитесь со службой поддержки DJI",
    "tr": "Işık sensörü hatası. DJI Destek birimiyle iletişime geçin",
    "zh": "光照传感器异常，请联系售后服务"
  },
  "fpv_tip_0x1C001503": {
    "de": "Kalibrierungsdaten Lichtsensor fehlerhaft. DJI Support kontaktieren",
    "en": "Light sensor calibration data error. Contact DJI Support",
    "es": "Error de datos de calibración del sensor de luz. Póngase en contacto con la Asistencia técnica de DJI",
    "fr": "Erreur de données de l'étalonnage du capteur de lumière. Contact DJI Support",
    "ja": "光センサーキャリブレーションデータエラーです。DJIサポートまでお問い合わせください",
    "ko": "광 센서 캘리브레이션 데이터 오류. DJI 고객지원으로 문의하세요",
    "ru": "Ошибка данных калибровки датчика освещенности. Свяжитесь со службой поддержки DJI",
    "tr": "Işık sensörü kalibrasyonu veri hatası. DJI Destek birimiyle iletişime geçin",
    "zh": "光照传感器标定数据异常，请联系售后服务"
  },
  "fpv_tip_0x1C001603": {
    "de": "DJI PROSSD kann nicht verwendet werden. Speichergerät entfernen und wieder einsetzen",
    "en": "Unable to use DJI PROSSD. Remove and insert storage device again",
    "es": "No se puede utilizar DJI PROSSD. Retira el dispositivo de almacenamiento e insértalo de nuevo",
    "fr": "Impossible d’utiliser DJI PROSSD. Retirer et réinsérer le périphérique de stockage",
    "ja": "DJI PROSSDを使用できません。ストレージ機器を取り付け直してください",
    "ko": "DJI PROSSD를 사용할 수 없습니다. 저장 장치를 제거했다가 다시 삽입하세요",
    "ru": "Использование DJI PROSSD невозможно. Извлеките и повторно вставьте устройство хранения",
    "tr": "DJI PROSSD kullanılamıyor. Depolama cihazını çıkarın ve yeniden takın",
    "zh": "DJI PROSSD存储不可挂载，请重新拔插"
  },
  "fpv_tip_0x1C001604": {
    "ar": "",
    "de": "Keine Speicherkarte",
    "en": "No memory card",
    "es": "No hay tarjeta de memoria",
    "fr": "Aucune carte mémoire",
    "it": "",
    "ja": "メモリーカードがありません",
    "ko": "메모리 카드 없음",
    "pt": "",
    "ru": "Нет карты памяти",
    "tr": "Hafıza kartı yok",
    "zh": "无存储卡"
  },
  "fpv_tip_0x1C001605": {
    "ar": "",
    "de": "SSD-Dateisystemfehler. SSD entnehmen und wieder einsetzen. SSD-Dateien sichern und SSD-Karte dann formatieren, wenn das Problem weiterhin besteht",
    "en": "SSD file system error. Remove and insert SSD again. Back up SSD files and then format SSD card if issue persists",
    "es": "Error del sistema de archivos SSD. Retire el SSD e insértalo de nuevo. Haga una copia de seguridad de los archivos SSD y luego formatee la tarjeta SSD si el problema continúa",
    "fr": "Erreur système du fichier SSD. Retirer et réinsérer le SSD. Sauvegardez les fichiers SSD, puis formatez la carte SSD si le problème persiste",
    "id": "",
    "it": "",
    "ja": "SSDのファイルシステムエラー。SSDを取り外し、挿入し直してください。問題が解決しない場合は、SSDのファイルをバックアップしてから、SSDカードをフォーマットしてください",
    "ko": "SSD 파일 시스템 오류. SSD를 제거했다가 다시 삽입하세요. 문제가 지속되면 SSD 파일을 백업한 SSD 카드를 포맷하세요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Ошибка файловой системы SSD. Извлеките и снова вставьте SSD. Сделайте резервную копию файлов SSD, а затем отформатируйте карту SSD, если проблема не устранена",
    "th": "",
    "tr": "SSD dosya sistemi hatası. SSD'yi çıkarın ve tekrar takın. SSD dosyalarını yedekleyin ve ardından sorun devam ederse SSD kartı biçimlendirin",
    "ug": "",
    "vi": "",
    "zh": "SSD文件系统异常，请重新拔插SSD，若无效请备份后格式化",
    "zh-Hant": ""
  },
  "fpv_tip_0x1C001606": {
    "ar": "",
    "de": "DJI PROSSD nicht formatiert",
    "en": "DJI PROSSD not formatted",
    "es": "DJI PROSSD no formateado",
    "fr": "DJI PROSSD non formaté",
    "it": "",
    "ja": "DJI PROSSDがフォーマットされていません",
    "ko": "DJI PROSSD가 포맷되지 않은 상태입니다",
    "pt": "",
    "ru": "DJI PROSSD не отформатирован",
    "tr": "DJI PROSSD biçimlendirilmemiş",
    "zh": "DJI PROSSD存储未格式化"
  },
  "fpv_tip_0x1C001607": {
    "ar": "",
    "de": "DJI PROSSD wird formatiert...",
    "en": "Formatting DJI PROSSD...",
    "es": "Formateando DJI PROSSD...",
    "fr": "Formatage DJI PROSSD en cours...",
    "it": "",
    "ja": "DJI PROSSDのフォーマット中...",
    "ko": "DJI PROSSD 포맷 중...",
    "pt": "",
    "ru": "Выполняется форматирование DJI PROSSD...",
    "tr": "DJI PROSSD biçimlendiriliyor...",
    "zh": "DJI PROSSD正在格式化"
  },
  "fpv_tip_0x1C001608": {
    "ar": "",
    "de": "DJI PROSSD-Dateisystemformat nicht unterstützt",
    "en": "DJI PROSSD file system format not supported",
    "es": "Formato del sistema de archivos de DJI PROSSD no compatible",
    "fr": "Format de système de fichiers DJI PROSSD non pris en charge",
    "it": "",
    "ja": "DJI PROSSDのファイルシステム形式には対応していません",
    "ko": "DJI PROSSD 파일 시스템 포맷이 지원되지 않습니다",
    "pt": "",
    "ru": "Формат файловой системы DJI PROSSD не поддерживается",
    "tr": "DJI PROSSD dosya sistemi biçimi desteklenmiyor.",
    "zh": "DJI PROSSD文件系统不支持"
  },
  "fpv_tip_0x1C001609": {
    "ar": "",
    "de": "DJI PROSSD wird aktualisiert...",
    "en": "Refreshing DJI PROSSD...",
    "es": "Actualizando DJI PROSSD...",
    "fr": "Actualisation de DJI PROSSD...",
    "it": "",
    "ja": "DJI PROSSDの更新中...",
    "ko": "DJI PROSSD 새로고침 중...",
    "pt": "",
    "ru": "Выполняется обновление DJI PROSSD...",
    "tr": "DJI PROSSD yenileniyor...",
    "zh": "DJI PROSSD正在刷新"
  },
  "fpv_tip_0x1C00160A": {
    "de": "DJI PROSSD voll",
    "en": "DJI PROSSD full",
    "es": "DJI PROSSD lleno",
    "fr": "DJI PROSSD plein",
    "ja": "DJI PROSSDが満杯です",
    "ko": "DJI PROSSD에 공간이 없습니다",
    "ru": "DJI PROSSD заполнен",
    "tr": "DJI PROSSD dolu",
    "zh": "DJI PROSSD存储空间已满"
  },
  "fpv_tip_0x1C00160B": {
    "de": "DJI PROSSD-Dateibenennungsindex voll. Zusätzliche Dateien löschen",
    "en": "DJI PROSSD file naming index full. Delete extra files",
    "es": "Índice de nomenclatura de archivo DJI PROSSD completo. Elimina los archivos adicionales",
    "fr": "Index de nommage des fichiers DJI PROSSD plein. Supprimer les fichiers excédentaires",
    "ja": "DJI PROSSDのファイルネーミングインデックスが満杯です。余分なファイルを削除してください",
    "ko": "DJI PROSSD 파일 이름 지정 인덱스에 공간이 없습니다. 불필요한 파일을 삭제하세요",
    "ru": "Индекс имен файлов DJI PROSSD заполнен. Удалите лишние файлы",
    "tr": "DJI PROSSD adlandırma dizini dolu. Fazla dosyaları sil",
    "zh": "DJI PROSSD文件序号溢出，请删除多余素材"
  },
  "fpv_tip_0x1C00160C": {
    "de": "DJI PROSSD wird initialisiert...",
    "en": "Initializing DJI PROSSD…",
    "es": "Inicializando DJI PROSSD...",
    "fr": "Initialisation de DJI PROSSD…",
    "ja": "DJI PROSSDの初期化中...",
    "ko": "DJI PROSSD 초기화 중…",
    "ru": "Инициализация DJI PROSSD…",
    "tr": "DJI PROSSD başlatılıyor…",
    "zh": "DJI PROSSD正在初始化"
  },
  "fpv_tip_0x1C00160D": {
    "de": "Neuformatierung von DJI PROSSD empfohlen",
    "en": "Reformatting DJI PROSSD recommended",
    "es": "Se recomienda volver a formatear DJI PROSSD",
    "fr": "Reformatage de DJI PROSSD recommandé",
    "ja": "DJI PROSSDの再フォーマットをお勧めします",
    "ko": "DJI PROSSD 재포맷 권장",
    "ru": "Рекомендуется переформатировать DJI PROSSD",
    "tr": "DJI PROSSD'nin yeniden biçimlendirilmesi önerilir",
    "zh": "DJI PROSSD建议重新格式化"
  },
  "fpv_tip_0x1C00160E": {
    "de": "Dateien auf DJI PROSSD werden wiederhergestellt...",
    "en": "Restoring files on DJI PROSSD...",
    "es": "Restaurando archivos en DJI PROSSD...",
    "fr": "Restauration des fichiers sur DJI PROSSD...",
    "ja": "DJI PROSSDのファイルの復元中...",
    "ko": "DJI PROSSD 파일 복구 중...",
    "ru": "Восстановление файлов на DJI PROSSD...",
    "tr": "DJI PROSSD'deki dosyalar geri yükleniyor...",
    "zh": "DJI PROSSD存储文件正在修复中"
  },
  "fpv_tip_0x1C00160F": {
    "de": "Niedrige DJI PROSSD-Geschwindigkeit. Austausch des Speichergeräts empfohlen",
    "en": "DJI PROSSD speed low. Replacing storage device recommended",
    "es": "Baja velocidad en DJI PROSSD. Se recomienda sustituir el dispositivo de almacenamiento",
    "fr": "Vitesse de DJI PROSSD faible. Remplacement du périphérique de stockage recommandé",
    "ja": "DJI PROSSDの速度が遅くなっています。ストレージ機器の交換をお勧めします",
    "ko": "DJI PROSSD 속도가 낮습니다. 저장 장치 교체를 권장합니다",
    "ru": "Низкая скорость DJI PROSSD. Рекомендуется замена устройства хранения",
    "tr": "DJI PROSSD hızı düşük. Depolama cihazının yenilenmesi önerilir",
    "zh": "DJI PROSSD存储速度慢，建议更换"
  },
  "fpv_tip_0x1C001701": {
    "de": "Zeitauslöserintervall zu kurz. Aufnahmen können nicht gestartet werden.",
    "en": "Timed shot interval too short. Unable to start shooting",
    "es": "Intervalo de foto con temporizador demasiado corto. No puede empezar a disparar",
    "fr": "Intervalle de prise de vue trop court. Impossible de lancer la prise de vue",
    "ja": "タイマー撮影の間隔が短すぎます。撮影を開始できません",
    "ko": "인터벌 타이머 인터벌이 너무 짧습니다. 촬영을 시작할 수 없습니다",
    "ru": "Интервал слишком короткий. Не удается начать съемку",
    "tr": "Zamanlanmış çekim aralığı çok kısa. Çekim başlatılamıyor",
    "zh": "定时拍间隔过小，无法开始拍摄"
  },
  "fpv_tip_0x1C001702": {
    "de": "Zeitauslöserintervall zu kurz. Kameras können nicht umgeschaltet werden.",
    "en": "Timed shot interval too short. Unable to switch cameras",
    "es": "Intervalo de foto con temporizador demasiado corto. No se puede cambiar de cámara",
    "fr": "Intervalle de prise de vue trop court. Impossible de basculer entre les caméras",
    "ja": "タイマー撮影の間隔が短すぎます。カメラの切り替えができません",
    "ko": "인터벌 타이머 인터벌이 너무 짧습니다. 카메라 전환 불가",
    "ru": "Интервал слишком короткий. Не удается переключиться между камерами",
    "tr": "Zamanlanmış çekim aralığı çok kısa. Kameralar arasında geçiş yapılamıyor",
    "zh": "定时拍间隔过小，无法切换相机"
  },
  "fpv_tip_0x1C001703": {
    "de": "Fotoformat stimmt nicht überein. Kameras können nicht umgeschaltet werden.",
    "en": "Photo format inconsistent. Unable to switch cameras",
    "es": "Formato de foto incoherente. No se puede cambiar de cámara",
    "fr": "Format de photo incohérent. Impossible de basculer entre les caméras",
    "ja": "写真フォーマットが一致しません。カメラの切り替えができません",
    "ko": "사진 형식이 일치하지 않습니다. 카메라 전환 불가",
    "ru": "Несоответствие формата фото. Не удается переключиться между камерами",
    "tr": "Fotoğraf biçimi uyumsuz. Kameralar arasında geçiş yapılamıyor",
    "zh": "照片格式不同，无法切换相机"
  },
  "fpv_tip_0x1C001801": {
    "de": "Zeitcodefehler. Fluggerät neu starten",
    "en": "Timecode error. Restart aircraft",
    "es": "Error de código de tiempo. Reinicia la aeronave",
    "fr": "Erreur de code temporel. Redémarrer l’appareil",
    "ja": "タイムコードエラー。機体を再起動してください",
    "ko": "타임코드 오류. 기체를 재시작하세요",
    "ru": "Ошибка кода времени. Перезапустите дрон",
    "tr": "Zaman kodu hatası. Hava aracını yeniden başlatın",
    "zh": "TIMECODE错误，请重启飞行器"
  },
  "fpv_tip_0x1C001901": {
    "ar": "",
    "de": "ProRes-RAW-Codec nicht autorisiert",
    "en": "ProRes RAW codec not authorized,purchase on the official website",
    "es": "Códec ProRes RAW no autorizado",
    "fr": "Codec ProRes RAW non autorisé",
    "id": "",
    "it": "",
    "ja": "ProRes RAWコーデックが承認されていません",
    "ko": "ProRes RAW 코덱이 승인되지 않았습니다",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Кодек ProRes RAW не авторизован",
    "th": "",
    "tr": "ProRes RAW kodeği yetkilendirilmedi",
    "ug": "",
    "vi": "",
    "zh": "ProRes RAW编码格式未授权，请到官网购买使用授权",
    "zh-Hant": ""
  },
  "fpv_tip_0x1C001902": {
    "ar": "",
    "de": "Kompressionsverfahren CinemaDNG nicht zugelassen. Lizenz auf der offiziellen Website erwerben",
    "en": "CinemaDNG encoding format not authorized. Purchase license from official website",
    "es": "Formato de codificación CinemaDNG no autorizado. Comprar licencia desde el sitio web oficial",
    "fr": "Format d'encodage CinemaDNG non autorisé. Achetez une licence sur le site officiel",
    "id": "",
    "it": "",
    "ja": "CinemaDNGエンコードフォーマットは未承認です。公式ウェブサイトからライセンスを購入してください",
    "ko": "승인되지 않은 CinemaDNG 인코딩 형식. 공식 웹 사이트에서 라이선스를 구매하세요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Формат кодирования CinemaDNG не авторизован. Приобретите лицензию на официальном сайте",
    "th": "",
    "tr": "CinemaDNG kodlama formatı yetkilendirilmedi. Resmi web sitesinden lisans satın alın",
    "ug": "",
    "vi": "",
    "zh": "CinemaDNG编码格式未授权，请到官网购买使用授权",
    "zh-Hant": ""
  },
  "fpv_tip_0x1C001903": {
    "ar": "",
    "de": "ProRes-RAW-Codec nicht autorisiert",
    "en": "ProRes RAW codec not authorized,purchase on the official website",
    "es": "Códec ProRes RAW no autorizado",
    "fr": "Codec ProRes RAW non autorisé",
    "id": "",
    "it": "",
    "ja": "ProRes RAWコーデックが承認されていません",
    "ko": "ProRes RAW 코덱이 승인되지 않았습니다",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Кодек ProRes RAW не авторизован",
    "th": "",
    "tr": "ProRes RAW kodeği yetkilendirilmedi",
    "ug": "",
    "vi": "",
    "zh": "ProRes RAW编码格式未授权，请到官网购买使用授权",
    "zh-Hant": ""
  },
  "fpv_tip_0x1C001904": {
    "ar": "",
    "de": "",
    "en": "CinemaDNG encoding format not authorized，purchase on the official website",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "CinemaDNG编码格式未授权，请到官网购买使用授权",
    "zh-Hant": ""
  },
  "fpv_tip_0x1C001A01": {
    "de": "Niedrige DJI PROSSD-Schreibgeschwindigkeit. Neustart des Fluggeräts empfohlen",
    "en": "DJI PROSSD write speed low. Restarting aircraft recommended",
    "es": "Baja velocidad de escritura en DJI PROSSD. Se recomienda reiniciar la aeronave",
    "fr": "Vitesse d’écriture DJI PROSSD faible. Redémarrage de l’appareil recommandé",
    "ja": "DJI PROSSDの書き込みが低速です。機体の再起動をお勧めします",
    "ko": "DJI PROSSD 쓰기 속도가 낮습니다. 기체 재시작을 권장합니다",
    "ru": "Низкая скорость записи DJI PROSSD. Рекомендуется перезапуск дрона",
    "tr": "DJI PROSSD yazma hızı düşük. Hava aracının yeniden başlatılması önerilir",
    "zh": "DJI PROSSD写入速度慢，建议重新启动飞行器"
  },
  "fpv_tip_0x1C001B01": {
    "de": "Nutzungshäufigkeit Gimbal-Anschluss erreicht",
    "en": "Usage count of gimbal port reached",
    "es": "Se ha alcanzado el recuento de usos del puerto del estabilizador",
    "fr": "Nombre d'utilisations du port de nacelle atteint",
    "ja": "ジンバルポートの使用回数に達しました",
    "ko": "짐벌 포트 사용 횟수 한도에 도달했습니다",
    "ru": "Достигнуто количество использований порта гиростабилизатора",
    "tr": "Gimbal bağlantı noktası kullanım sayısına ulaşıldı",
    "zh": "云台接口已达预设使用次数"
  },
  "fpv_tip_0x1C001E04": {
    "en": "System busy. Photo is not allowed",
    "zh": "系统业务繁忙，禁止拍照"
  },
  "fpv_tip_0x1C001E05": {
    "en": "System not busy.Photo available",
    "zh": "业务繁忙解除，允许拍照"
  },
  "fpv_tip_0x1C001F01": {
    "en": "System busy. Zoom is not available",
    "zh": "系统业务繁忙，禁止变焦"
  },
  "fpv_tip_0x1C001F02": {
    "en": "System not busy. Zoom available",
    "zh": "业务繁忙解除，允许变焦"
  },
  "fpv_tip_0x1C002001": {
    "de": "System ausgelastet. Kameras nicht wechseln",
    "en": "System busy. Do not switch cameras",
    "es": "Sistema ocupado. No cambies de cámara",
    "fr": "Système occupé. Ne pas changer de caméra",
    "ja": "システムがビジー状態です。カメラを切り替えないでください",
    "ko": "시스템이 사용 중입니다. 카메라를 전환하지 마세요.",
    "ru": "Система занята. Не переключайте камеры",
    "tr": "Sistem meşgul. Kameralar arasında geçiş yapmayın",
    "zh": "系统业务繁忙，禁止切换相机"
  },
  "fpv_tip_0x1C002002": {
    "de": "System nicht ausgelastet. Kameras nicht wechseln",
    "en": "System not busy. Do not switch cameras",
    "es": "El sistema no está ocupado. No cambies de cámara",
    "fr": "Système disponible. Ne pas changer de caméra",
    "ja": "システムはビジー状態ではありません。カメラを切り替えないでください",
    "ko": "시스템이 사용 중이 아닙니다. 카메라를 전환하지 마세요.",
    "ru": "Система не занята. Не переключайте камеры",
    "tr": "Sistem meşgul değil. Kameralar arasında geçiş yapmayın",
    "zh": "业务繁忙解除，允许切换相机"
  },
  "fpv_tip_0x1C002004": {
    "de": "",
    "en": "",
    "es": "",
    "fr": "Erreur du processeur de transmission d'images du capteur de la caméra H20",
    "ja": "",
    "ko": "",
    "ru": "",
    "tr": "",
    "zh": "H20 相机图像传感器 芯片异常"
  },
  "fpv_tip_0x1C002101": {
    "ar": "",
    "de": "Zeitüberschreitung der LiDAR-Daten empfangenen Kamera. Fluggerät neu starten und erneut versuchen",
    "en": "Camera receiving LiDAR data timed out. Restart aircraft and try again",
    "es": "Se agotó el tiempo de espera de recepción de datos LiDAR de la cámara. Reinicia la aeronave y vuelve a intentarlo",
    "fr": "La caméra recevant les données LiDAR a expiré. Redémarrez l’appareil et réessayez",
    "id": "",
    "it": "",
    "ja": "カメラのLiDARデータ受信がタイムアウトしました。機体を再起動し、やり直してください",
    "ko": "카메라가 LiDAR 데이터를 수신하는 동안 시간이 초과됨. 기체 재시작 후 다시 시도",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Истекло время получения камерой данных лидара. Перезапустите дрон и повторите попытку",
    "th": "",
    "tr": "LiDAR verilerini alan kamera zaman aşımına uğradı. Hava aracını yeniden başlatın ve tekrar deneyin",
    "ug": "",
    "vi": "",
    "zh": "相机接收激光雷达数据超时，请尝试重启飞行器",
    "zh-Hant": ""
  },
  "fpv_tip_0x1C002102": {
    "ar": "",
    "de": "Punktwolkendaten-Frames gedroppt. Das endgültige Punktwolkenmodell ist möglicherweise nicht vollständig. Fluggerät neu starten",
    "en": "Point cloud data frames dropped. The ultimate point cloud model may not be complete. Restart aircraft",
    "es": "Se eliminaron los fotogramas de datos de la nube de puntos. Es posible que el modelo de nube de puntos definitivo no esté completo. Reinicie la aeronave",
    "fr": "Les trames de données des nuages de points ont été supprimées. Le modèle ultime des nuages de points n’est peut-être pas complet. Redémarrez l’appareil",
    "id": "",
    "it": "",
    "ja": "点群データフレームがドロップしました。最終的な点群モデルが完成していない可能性があります。機体を再起動してください",
    "ko": "포인트 클라우드 데이터 프레임이 삭제되었습니다. 포인트 클라우드 모델 결과물이 완전하지 않을 수 있습니다. 기체를 재시작하세요",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Пропущены кадры данных облака точек. Окончательная модель облака точек может быть неполной. Перезапустите дрон",
    "th": "",
    "tr": "Nokta bulutu veri çerçeveleri düştü. Nihai nokta bulutu modeli tam olmayabilir. Hava aracını yeniden başlatın",
    "ug": "",
    "vi": "",
    "zh": "点云数据丢帧，最终点云模型可能不完整，请重启飞行器",
    "zh-Hant": ""
  },
  "fpv_tip_0x1C002103": {
    "de": "Der LiDAR ist möglicherweise blockiert oder außerhalb des Messbereichs",
    "en": "LiDAR may be blocked or object out of measuring range",
    "es": "Puede que el LiDAR esté bloqueado o se encuentre fuera del rango de medición",
    "fr": "Le LiDAR est peut-être bloqué ou l’objet est hors de la plage de mesure",
    "ja": "LiDARがブロックされているか、オブジェクトが測定範囲外にある可能性があります",
    "ko": "LiDAR가 차단되었거나 물체가 측정 범위를 벗어남",
    "ru": "Возможно, лидар заблокирован или объект находится вне диапазона измерения",
    "tr": "LiDAR engellenmiş veya nesne ölçüm aralığının dışında olabilir",
    "zh": "激光雷达可能被遮挡，或者不在量程内"
  },
  "fpv_tip_0x1C002201": {
    "de": "Auf der SSD der Kamera sind ältere Mediendateien vorhanden. Dateien sichern, um Datenverlust zu vermeiden",
    "en": "Previous media files exist in camera SSD. Back up files to avoid data loss",
    "es": "Hay archivos multimedia anteriores en la SSD de la cámara. Hacer copias de seguridad de los archivos para evitar la pérdida de datos",
    "fr": "Des fichiers multimédias précédents sont enregistrés dans le SSD de la caméra. Sauvegardez les fichiers pour éviter toute perte de données",
    "ja": "以前のメディアファイルがカメラ SSDに存在します。ファイルをバックアップして、データの損失を回避してください",
    "ko": "카메라 SSD에 기존 미디어 파일이 존재함. 데이터 손실을 방지하려면 파일을 백업하세요",
    "ru": "Предыдущие медифайлы хранятся на SSD камеры. Выполните резервное копирование файлов во избежание потери данных",
    "tr": "Kamera SSD'sinde eski medya dosyaları mevcut. Veri kaybını önlemek için dosyaları yedekleyin",
    "zh": "相机SSD存在旧版本媒体文件，请及时备份避免丢失"
  },
  "fpv_tip_0x1C002301": {
    "zh": "EIS的图像帧的vsync为0"
  },
  "fpv_tip_0x1C002302": {
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "ja": "",
    "ko": "",
    "ru": "",
    "tr": "",
    "zh": "EIS图像帧的vsync顺序错误"
  },
  "fpv_tip_0x1C002303": {
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "ja": "",
    "ko": "",
    "ru": "",
    "tr": "",
    "zh": "EIS获取不到IMU数据"
  },
  "fpv_tip_0x1C002304": {
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "ja": "",
    "ko": "",
    "ru": "",
    "tr": "",
    "zh": "EIS获取IMU数据不足"
  },
  "fpv_tip_0x1C002305": {
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "ja": "",
    "ko": "",
    "ru": "",
    "tr": "",
    "zh": "EIS获取IMU数据过多"
  },
  "fpv_tip_0x1C002306": {
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "ja": "",
    "ko": "",
    "ru": "",
    "tr": "",
    "zh": "EIS获取IMU数据失败"
  },
  "fpv_tip_0x1C002307": {
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "ja": "",
    "ko": "",
    "ru": "",
    "tr": "",
    "zh": "EIS获取到的IMU数据vsync顺序错误"
  },
  "fpv_tip_0x1C002308": {
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "ja": "",
    "ko": "",
    "ru": "",
    "tr": "",
    "zh": "EIS的IMU target超出约束范围"
  },
  "fpv_tip_0x1C002309": {
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "ja": "",
    "ko": "",
    "ru": "",
    "tr": "",
    "zh": "EIS迭代求解结果超出输入图像的边界"
  },
  "fpv_tip_0x1C00230A": {
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "ja": "",
    "ko": "",
    "ru": "",
    "tr": "",
    "zh": "EIS的IMU对齐范围超出拿到的IMU数据的位置"
  },
  "fpv_tip_0x1C00230B": {
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "ja": "",
    "ko": "",
    "ru": "",
    "tr": "",
    "zh": "EIS帧间时间间隔有问题，vsync不稳定"
  },
  "fpv_tip_0x1C00230C": {
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "ja": "",
    "ko": "",
    "ru": "",
    "tr": "",
    "zh": "当前帧通过step模式匹配到了vsync信息"
  },
  "fpv_tip_0x1C00230D": {
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "ja": "",
    "ko": "",
    "ru": "",
    "tr": "",
    "zh": "当前帧匹配到无效的vsync信息"
  },
  "fpv_tip_0x1C00230E": {
    "de": "",
    "en": "",
    "es": "",
    "fr": "",
    "ja": "",
    "ko": "",
    "ru": "",
    "tr": "",
    "zh": "当前帧匹配不到vsync信息"
  },
  "fpv_tip_0x1C003001": {
    "de": "",
    "en": "",
    "es": "",
    "fr": "Erreur de l'objectif de la caméra H20. Redémarrez la caméra",
    "ja": "",
    "ko": "",
    "ru": "",
    "tr": "",
    "zh": "H20 镜头异常，请断电重启"
  },
  "fpv_tip_0x1C004001": {
    "de": "Ungültige Speicherkarte. Karte austauschen.",
    "en": "Invalid memory card. Replace card",
    "es": "Tarjeta de memoria no válida. Cambie la tarjeta",
    "fr": "Carte mémoire non valide. Remplacez la carte",
    "ja": "メモリーカードが無効です。 メモリーカードを交換してください",
    "ko": "잘못된 메모리 카드입니다. 카드를 교체하세요",
    "ru": "Недопустимая карта памяти. Замените карту",
    "tr": "Geçersiz hafıza kartı. Kartı değiştirin",
    "zh": "无效存储卡，请更换存储卡"
  },
  "fpv_tip_0x1C004002": {
    "de": "Niedrige Speicherkartengeschwindigkeit. Durch schnellere Karte austauschen.",
    "en": "Memory card speed low. Replace with faster card",
    "es": "Velocidad baja de la tarjeta de memoria. Cambie la tarjeta por una más rápida",
    "fr": "Vitesse de la carte mémoire lente. Remplacez-la par une carte plus rapide",
    "ja": "メモリーカードが低速です。 より高速のカードと交換してください",
    "ko": "메모리 카드 속도 느림. 더 빠른 카드로 교체하세요",
    "ru": "Низкая скорость карты памяти. Замените карту на более быструю",
    "tr": "Hafıza kartının hızı yavaş. Daha hızlı bir hafıza kartı ile değiştirin",
    "zh": "存储卡为慢速卡，请更换快速存储卡"
  },
  "fpv_tip_0x1C004003": {
    "de": "Speicherkartenfehler. Karte austauschen.",
    "en": "Memory card error. Replace card",
    "es": "Error de la tarjeta de memoria. Cambie la tarjeta",
    "fr": "Erreur de carte mémoire. Remplacez la carte",
    "ja": "メモリーカードにエラーが発生しました。 メモリーカードを交換してください",
    "ko": "메모리 카드 오류. 카드를 교체하세요",
    "ru": "Ошибка карты памяти. Замените карту",
    "tr": "Hafıza kartı hatası. Kartı değiştirin",
    "zh": "存储卡异常，请更换存储卡"
  },
  "fpv_tip_0x1C004004": {
    "de": "Keine Speicherkarte",
    "en": "No memory card",
    "es": "No hay tarjeta de memoria",
    "fr": "Aucune carte mémoire",
    "ja": "メモリーカードがありません",
    "ko": "메모리 카드 없음",
    "ru": "Нет карты памяти",
    "tr": "Hafıza kartı yok",
    "zh": "无存储卡"
  },
  "fpv_tip_0x1C100001": {
    "de": "Kamera %component_index überhitzt. Vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera %component_index overheated. Wait for temperature to return to normal before use",
    "es": "Cámara %component_index sobrecalentada. Espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Surchauffe du %component_index de la caméra. Attendez que la température revienne à la normale avant utilisation",
    "ja": "カメラ %component_index 高温。常温に戻るまで待ってからご使用ください",
    "ko": "카메라 %component_index 과열. 사용 전에 온도가 정상으로 돌아올 때까지 기다리세요",
    "ru": "Камера %component_index перегрелась. Перед использованием подождите, пока температура не вернется в норму",
    "tr": "Kamera %component_index aşırı ısındı. Kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "%component_index号云台相机过热，请等待降温后再使用"
  },
  "fpv_tip_0x1C100101": {
    "de": "Fehler: Kamera %component_index. Kamera neu starten",
    "en": "Camera %component_index error. Restart camera",
    "es": "Error de la cámara %component_index. Reinicia la cámara",
    "fr": "Erreur de %component_index de la caméra. Redémarrez la caméra",
    "ja": "カメラ%component_indexエラー。カメラを再起動してください",
    "ko": "카메라 %component_index 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка камеры %component_index. Перезагрузите камеру",
    "tr": "Kamera %component_index hatası. Kamerayı yeniden başlatın",
    "zh": "%component_index号云台相机异常，请重启相机"
  },
  "fpv_tip_0x1C100102": {
    "de": "Fehler: Kamera %component_index. Kamera neu starten",
    "en": "Camera %component_index error. Restart camera",
    "es": "Error de la cámara %component_index. Reinicia la cámara",
    "fr": "Erreur de %component_index de la caméra. Redémarrez la caméra",
    "ja": "カメラ%component_indexエラー。カメラを再起動してください",
    "ko": "카메라 %component_index 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка камеры %component_index. Перезагрузите камеру",
    "tr": "Kamera %component_index hatası. Kamerayı yeniden başlatın",
    "zh": "%component_index号云台相机异常，请重启相机"
  },
  "fpv_tip_0x1C100103": {
    "de": "Hohe Temperatur des Kameraprozessors. Fluggerät ausschalten und vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera processor temperature high. Power off aircraft and wait for temperature to return to normal before use",
    "es": "Temperatura alta del procesador de la cámara. Apaga la aeronave y espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Température du processeur de la caméra élevée. Éteignez l'appareil et attendez que sa température revienne à la normale avant utilisation",
    "ja": "カメラプロセッサの温度が高い。機体の電源を切り、通常の温度に戻ってからご使用ください",
    "ko": "카메라 프로세서 온도 높음. 사용 전에 기체의 전원을 끄고 온도가 정상으로 돌아올 때까지 기다리세요.",
    "ru": "Высокая температура процессора камеры. Перед использованием выключите питание дрона и дождитесь, пока температура опустится до нормальной",
    "tr": "Kamera işlemci sıcaklığı yüksek. Aracı kapatın ve kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度高，请关闭飞机，等待冷却后再使用"
  },
  "fpv_tip_0x1C100103_in_the_sky": {
    "de": "Hohe Temperatur des Kameraprozessors. Umgehend zum Startpunkt zurückkehren oder landen. Vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera processor temperature high. Return to home or land promptly. Wait for temperature to return to normal before use",
    "es": "Temperatura alta del procesador de la cámara. Regresa al punto de origen o aterriza rápidamente. Espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Température du processeur de la caméra élevée. Retournez au point de départ ou atterrissez rapidement. Attendez que la température revienne à la normale avant utilisation",
    "ja": "カメラプロセッサの温度が高い。直ちに帰還するか、着陸してください。常温に戻るまで待ってからご使用ください",
    "ko": "카메라 프로세서 온도 높음. 즉시 리턴 투 홈 또는 착륙 필요. 사용 전에 온도가 정상으로 돌아올 때까지 기다리세요.",
    "ru": "Высокая температура процессора камеры. Как можно скорее вернитесь на базу или приземлитесь. Перед использованием подождите, пока температура не опустится до нормальной",
    "tr": "Kamera işlemci sıcaklığı yüksek. Ana konuma geri dönün veya derhal iniş yapın. Kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度高，请尽快返航或降落，等待冷却后重启使用"
  },
  "fpv_tip_0x1C100104": {
    "de": "Temperatur des Kameraprozessors zu hoch. Fluggerät ausschalten und vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera processor temperature too high. Power off aircraft and wait for temperature to return to normal before use",
    "es": "Temperatura demasiado alta del procesador de la cámara. Apaga la aeronave y espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Température du processeur de la caméra trop élevée. Éteignez l'appareil et attendez que sa température revienne à la normale avant utilisation",
    "ja": "カメラプロセッサの温度が高すぎる。機体の電源を切り、通常の温度に戻ってからご使用ください",
    "ko": "카메라 프로세서 온도 너무 높음. 사용 전에 기체의 전원을 끄고 온도가 정상으로 돌아올 때까지 기다리세요.",
    "ru": "Чрезмерно высокая температура процессора камеры. Перед использованием выключите питание дрона и дождитесь, пока температура опустится до нормальной",
    "tr": "Kamera işlemci sıcaklığı çok yüksek. Aracı kapatın ve kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度过高，请关闭飞机，等待冷却后再使用"
  },
  "fpv_tip_0x1C100104_in_the_sky": {
    "de": "Temperatur des Kameraprozessors zu hoch. Umgehend zum Startpunkt zurückkehren oder landen. Vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera processor temperature too high. Return to home or land promptly. Wait for temperature to return to normal before use",
    "es": "Temperatura demasiado alta del procesador de la cámara. Regresa al punto de origen o aterriza rápidamente. Espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Température du processeur de la caméra trop élevée. Retournez au point de départ ou atterrissez rapidement. Attendez que la température revienne à la normale avant utilisation",
    "ja": "カメラプロセッサの温度が高すぎる。直ちに帰還するか、着陸してください。常温に戻るまで待ってからご使用ください",
    "ko": "카메라 프로세서 온도 너무 높음. 즉시 리턴 투 홈 또는 착륙 필요. 사용 전에 온도가 정상으로 돌아올 때까지 기다리세요.",
    "ru": "Чрезмерно высокая температура процессора камеры. Как можно скорее вернитесь на базу или приземлитесь. Перед использованием подождите, пока температура не опустится до нормальной",
    "tr": "Kamera işlemci sıcaklığı çok yüksek. Ana konuma geri dönün veya derhal iniş yapın. Kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度过高，请尽快返航或降落，等待冷却后重启使用"
  },
  "fpv_tip_0x1C100105": {
    "de": "Fehler: Kamera %component_index. Kamera neu starten",
    "en": "Camera %component_index error. Restart camera",
    "es": "Error de la cámara %component_index. Reinicia la cámara",
    "fr": "Erreur de %component_index de la caméra. Redémarrez la caméra",
    "ja": "カメラ%component_indexエラー。カメラを再起動してください",
    "ko": "카메라 %component_index 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка камеры %component_index. Перезагрузите камеру",
    "tr": "Kamera %component_index hatası. Kamerayı yeniden başlatın",
    "zh": "%component_index号云台相机异常，请重启相机"
  },
  "fpv_tip_0x1C100201": {
    "de": "Fehler: Prozessor für den Bildsensor der H20 Kamera",
    "en": "H20 camera image transmission sensor processor error",
    "es": "Error del procesador del sensor de imagen de la cámara H20",
    "fr": "Erreur du processeur du capteur de transmission d\\'images de la caméra H20",
    "ja": "H20 カメラ映像伝送センサープロセッサーエラー",
    "ko": "H20 카메라 이미지 전송 센서 프로세서 오류",
    "ru": "Ошибка процессора датчика передачи изображения камеры H20",
    "tr": "H20 kamera görüntüsü iletim sensörü işlemcisi hatası",
    "zh": "H20 相机图像传感器 芯片异常"
  },
  "fpv_tip_0x1C100202": {
    "de": "Fehler: Prozessor für den Bildsensor der H20 Kamera",
    "en": "H20 camera image transmission sensor processor error",
    "es": "Error del procesador del sensor de imagen de la cámara H20",
    "fr": "Erreur du processeur du capteur de transmission d\\'images de la caméra H20",
    "ja": "H20 カメラ映像伝送センサープロセッサーエラー",
    "ko": "H20 카메라 이미지 전송 센서 프로세서 오류",
    "ru": "Ошибка процессора датчика передачи изображения камеры H20",
    "tr": "H20 kamera görüntüsü iletim sensörü işlemcisi hatası",
    "zh": "H20 相机图像传感器 芯片异常"
  },
  "fpv_tip_0x1C100203": {
    "de": "Kamerachip überhitzt. Fluggerät ausschalten und vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera chip overheated. Power off aircraft and wait for temperature to return to normal before use",
    "es": "Chip de la cámara sobrecalentado. Apaga la aeronave y espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Surchauffe de la puce de la caméra. Éteignez l'appareil et attendez que sa température revienne à la normale avant utilisation",
    "ja": "カメラチップ高温。機体の電源を切り、通常の温度に戻ってからご使用ください",
    "ko": "카메라 칩 과열. 사용 전에 기체의 전원을 끄고 온도가 정상으로 돌아올 때까지 기다리세요",
    "ru": "Чип камеры перегрелся. Выключите питание дрона и дождитесь возвращения температуры к нормальным значениям перед использованием",
    "tr": "Kamera çipi aşırı ısındı. Aracı kapatın ve kullanmadan sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度过高，请关闭飞机，等待冷却后再使用"
  },
  "fpv_tip_0x1C100203_in_the_sky": {
    "de": "Kameraprozessor überhitzt. Umgehend zum Startpunkt zurückkehren oder landen. Vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera processor overheated. Return to home or land promptly. Wait for temperature to return to normal before use",
    "es": "Procesador de la cámara sobrecalentado. Regresa al punto de origen o aterriza rápidamente. Espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Surchauffe du processeur de la caméra. Retournez au point de départ ou atterrissez rapidement. Attendez que la température revienne à la normale avant utilisation",
    "ja": "カメラプロセッサー高温。直ちにホームに戻るか、着陸してください。常温に戻るまで待ってからご使用ください",
    "ko": "카메라 프로세서 과열. 즉시 리턴 투 홈 진행 또는 착륙 필요. 사용 전에 온도가 정상으로 돌아올 때까지 기다리세요",
    "ru": "Перегрев процессора камеры. Немедленно вернитесь в исходное положение и приземлитесь. Перед использованием подождите, пока температура не вернется в норму",
    "tr": "Kamera işlemcisi aşırı ısındı. Hemen eve dön veya iniş yap. Kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度过高，请尽快返航或降落，等待冷却后重启使用"
  },
  "fpv_tip_0x1C100204": {
    "de": "Fehler: Prozessor für den Bildsensor der H20 Kamera",
    "en": "H20 camera image transmission sensor processor error",
    "es": "Error del procesador del sensor de imagen de la cámara H20",
    "fr": "Erreur du processeur du capteur de transmission d\\'images de la caméra H20",
    "ja": "H20 カメラ映像伝送センサープロセッサーエラー",
    "ko": "H20 카메라 이미지 전송 센서 프로세서 오류",
    "ru": "Ошибка процессора датчика передачи изображения камеры H20",
    "tr": "H20 kamera görüntüsü iletim sensörü işlemcisi hatası",
    "zh": "H20 相机图像传感器 芯片异常"
  },
  "fpv_tip_0x1C100205": {
    "de": "Kalibrierungsfehler des FPV-Objektivs. Objektiv neu kalibrieren und erneut versuchen",
    "en": "FPV lens calibration error. Recalibrate lens and try again",
    "es": "Error de calibración del objetivo FPV. Vuelve a calibrar el objetivo e inténtalo de nuevo",
    "fr": "Erreur d’étalonnage de l’objectif FPV. Réétalonner l’objectif et réessayer",
    "ja": "FPVレンズキャリブレーションエラー。レンズを再度キャリブレーションし、やり直してください",
    "ko": "FPV 렌즈 캘리브레이션 오류. 렌즈를 재캘리브레이션한 후 다시 시도하세요",
    "ru": "Ошибка калибровки объектива FPV. Выполните повторную калибровку объектива и повторите попытку",
    "tr": "FPV lens kalibrasyonu hatası. Lensin kalibrasyonunu yeniden yapın ve tekrar deneyin",
    "zh": "FPV镜头标定异常，请重新标定"
  },
  "fpv_tip_0x1C100301": {
    "de": "Fehler: H20 Kameraobjektiv. Kamera neu starten",
    "en": "H20 camera lens error. Restart camera",
    "es": "Error del objetivo de la cámara H20. Reinicia la cámara",
    "fr": "Erreur de l'objectif de la caméra H20. Redémarrez la caméra",
    "ja": "H20カメラレンズエラー。カメラを再起動してください",
    "ko": "H20 카메라 렌즈 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка объектива камеры H20. Перезагрузите камеру",
    "tr": "H20 kamera lensi hatası. Kamerayı yeniden başlatın",
    "zh": "H20 镜头异常，请断电重启"
  },
  "fpv_tip_0x1C100302": {
    "de": "Fehler: H20 Kameraobjektiv. Kamera neu starten",
    "en": "H20 camera lens error. Restart camera",
    "es": "Error del objetivo de la cámara H20. Reinicia la cámara",
    "fr": "Erreur de l'objectif de la caméra H20. Redémarrez la caméra",
    "ja": "H20カメラレンズエラー。カメラを再起動してください",
    "ko": "H20 카메라 렌즈 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка объектива камеры H20. Перезагрузите камеру",
    "tr": "H20 kamera lensi hatası. Kamerayı yeniden başlatın",
    "zh": "H20 镜头异常，请断电重启"
  },
  "fpv_tip_0x1C100303": {
    "de": "Fehler: H20 Kameraobjektiv. Kamera neu starten",
    "en": "H20 camera lens error. Restart camera",
    "es": "Error del objetivo de la cámara H20. Reinicia la cámara",
    "fr": "Erreur de l'objectif de la caméra H20. Redémarrez la caméra",
    "ja": "H20カメラレンズエラー。カメラを再起動してください",
    "ko": "H20 카메라 렌즈 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка объектива камеры H20. Перезагрузите камеру",
    "tr": "H20 kamera lensi hatası. Kamerayı yeniden başlatın",
    "zh": "H20 镜头异常，请断电重启"
  },
  "fpv_tip_0x1C100304": {
    "de": "Fehler: H20 Kameraobjektiv. Kamera neu starten",
    "en": "H20 camera lens error. Restart camera",
    "es": "Error del objetivo de la cámara H20. Reinicia la cámara",
    "fr": "Erreur de l'objectif de la caméra H20. Redémarrez la caméra",
    "ja": "H20カメラレンズエラー。カメラを再起動してください",
    "ko": "H20 카메라 렌즈 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка объектива камеры H20. Перезагрузите камеру",
    "tr": "H20 kamera lensi hatası. Kamerayı yeniden başlatın",
    "zh": "H20 镜头异常，请断电重启"
  },
  "fpv_tip_0x1C100401": {
    "de": "Ungültige Speicherkarte. Karte austauschen.",
    "en": "Invalid memory card. Replace card",
    "es": "Tarjeta de memoria no válida. Cambie la tarjeta",
    "fr": "Carte mémoire non valide. Remplacez la carte",
    "ja": "メモリーカードが無効です。 カードを交換してください",
    "ko": "잘못된 메모리 카드입니다. 카드를 교체하세요",
    "ru": "Недопустимая карта памяти. Замените карту",
    "tr": "Geçersiz hafıza kartı. Kartı değiştirin",
    "zh": "无效存储卡，请更换存储卡"
  },
  "fpv_tip_0x1C100401_index_1": {
    "de": "Ungültige eMMC. Kamera neu starten. Wenn das Problem weiterhin besteht, wenden Sie sich für Reparaturen an den DJI-Support",
    "en": "Invalid eMMC. Restart camera. If the issue persists, contact DJI Support for repairs",
    "fr": "eMMC non valide. Redémarrez la caméra. Si le problème persiste, contactez l'assistance DJI pour la réparation",
    "ja": "eMMCが無効です。カメラを再起動してください。問題が解決しない場合は、DJIサポートにお問い合わせになり、修理をご依頼ください",
    "ko": "eMMC가 올바르지 않습니다. 카메라를 다시 시작하세요. 문제가 지속되면 DJI 지원 센터에 수리를 요청하세요.",
    "ru": "Сбой eMMC. Перезапустите камеру. Если проблема не устранена, свяжитесь со службой поддержки DJI для ремонта",
    "zh": "无效EMMC，请重启相机，如问题还存在，请返厂维修"
  },
  "fpv_tip_0x1C100402": {
    "de": "Niedrige Speicherkartengeschwindigkeit. Durch schnellere Karte austauschen.",
    "en": "Memory card speed low. Replace with faster card",
    "es": "Velocidad baja de la tarjeta de memoria. Cambie la tarjeta por una más rápida",
    "fr": "Vitesse de la carte mémoire lente. Remplacez-la par une carte plus rapide",
    "ja": "メモリーカードが低速です。 より高速のカードと交換してください",
    "ko": "메모리 카드 속도 느림. 더 빠른 카드로 교체하세요",
    "ru": "Низкая скорость карты памяти. Замените карту на более быструю",
    "tr": "Hafıza kartının hızı yavaş. Daha hızlı bir hafıza kartı ile değiştirin",
    "zh": "存储卡为慢速卡，请更换"
  },
  "fpv_tip_0x1C100402_index_1": {
    "de": "eMMC-Geschwindigkeitsfehler. Kamera neu starten. Wenn das Problem weiterhin besteht, wenden Sie sich für Reparaturen an den DJI-Support",
    "en": "eMMC speed error. Restart camera. If the issue persists, contact DJI Support for repairs",
    "fr": "Erreur de vitesse de l'eMMC. Redémarrez la caméra. Si le problème persiste, contactez l'assistance DJI pour la réparation",
    "ja": "eMMCの速度エラーです。カメラを再起動してください。問題が解決しない場合は、DJIサポートにお問い合わせになり、修理をご依頼ください",
    "ko": "eMMC 속도 오류입니다. 카메라를 다시 시작하세요. 문제가 지속되면 DJI 지원 센터에 수리를 요청하세요.",
    "ru": "Ошибка скорости eMMC. Перезапустите камеру. Если проблема не устранена, свяжитесь со службой поддержки DJI для ремонта",
    "zh": "EMMC速度异常，请重启相机，如问题还存在，请返厂维修"
  },
  "fpv_tip_0x1C100403": {
    "de": "Speicherkartenfehler. Karte austauschen.",
    "en": "Memory card error. Replace card",
    "es": "Error de la tarjeta de memoria. Cambie la tarjeta",
    "fr": "Erreur de carte mémoire. Remplacez la carte",
    "ja": "メモリーカードにエラーが発生しました。 カードを交換してください",
    "ko": "메모리 카드 오류. 카드를 교체하세요",
    "ru": "Ошибка карты памяти. Замените карту",
    "tr": "Hafıza kartı hatası. Kartı değiştirin",
    "zh": "存储卡异常，请更换存储卡"
  },
  "fpv_tip_0x1C100403_index_1": {
    "de": "eMMC-Fehler. Kamera neu starten. Wenn das Problem weiterhin besteht, wenden Sie sich für Reparaturen an den DJI-Support",
    "en": "eMMC error. Restart camera. If the issue persists, contact DJI Support for repairs",
    "fr": "Erreur eMMC. Redémarrez la caméra. Si le problème persiste, contactez l'assistance DJI pour la réparation",
    "ja": "eMMCのエラーです。カメラを再起動してください。問題が解決しない場合は、DJIサポートにお問い合わせになり、修理をご依頼ください",
    "ko": "eMMC 오류입니다. 카메라를 다시 시작하세요. 문제가 지속되면 DJI 지원 센터에 수리를 요청하세요.",
    "ru": "Ошибка eMMC. Перезапустите камеру. Если проблема не устранена, свяжитесь со службой поддержки DJI для ремонта",
    "zh": "EMMC异常，请重启相机，如问题还存在，请返厂维修"
  },
  "fpv_tip_0x1C100404": {
    "de": "Keine Speicherkarte",
    "en": "No memory card",
    "es": "No hay tarjeta de memoria",
    "fr": "Aucune carte mémoire",
    "ja": "メモリーカードがありません",
    "ko": "메모리 카드 없음",
    "ru": "Нет карты памяти",
    "tr": "Hafıza kartı yok",
    "zh": "无存储卡"
  },
  "fpv_tip_0x1C100404_index_1": {
    "de": "eMMC-Identifikationsfehler. Kamera neu starten. Wenn das Problem weiterhin besteht, wenden Sie sich für Reparaturen an den DJI-Support",
    "en": "eMMC identification error. Restart camera. If the issue persists, contact DJI Support for repairs",
    "fr": "Erreur d'identification eMMC. Redémarrez la caméra. Si le problème persiste, contactez l'assistance DJI pour la réparation",
    "ja": "eMMCの認識エラーです。カメラを再起動してください。問題が解決しない場合は、DJIサポートにお問い合わせになり、修理をご依頼ください",
    "ko": "eMMC 식별 오류입니다. 카메라를 다시 시작하세요. 문제가 지속되면 DJI 지원 센터에 수리를 요청하세요.",
    "ru": "Ошибка идентификации eMMC. Перезапустите камеру. Если проблема не устранена, свяжитесь со службой поддержки DJI для ремонта",
    "zh": "EMMC识别异常，请重启相机，如问题还存在，请返厂维修"
  },
  "fpv_tip_0x1C100405": {
    "de": "Lese- und Schreibberechtigungen für Speicherkarte bestätigen",
    "en": "Confirm memory card read and write permissions",
    "es": "Confirme los permisos de lectura y escritura de la tarjeta de memoria",
    "fr": "Confirmez les autorisations de lecture et d'écriture de la carte mémoire",
    "ja": "メモリーカードの読み取り／書き込み権限を確認してください",
    "ko": "메모리 카드 읽기 및 쓰기 권한을 확인하세요",
    "ru": "Подтвердите наличие разрешений на чтение и запись карты памяти",
    "tr": "Hafıza kartı okuma ve yazma izinlerini onaylayın",
    "zh": "请确认存储卡读写属性"
  },
  "fpv_tip_0x1C100406": {
    "de": "Speicherkarte nicht formatiert. Karte vor Gebrauch formatieren.",
    "en": "Memory card not formatted. Format card before use",
    "es": "Tarjeta de memoria no formateada. Formatee la tarjeta antes del uso",
    "fr": "Carte mémoire non formatée. Formatez la carte avant utilisation",
    "ja": "メモリーカードがフォーマットされていません。 カードをフォーマットしてから使用してください",
    "ko": "메모리 카드가 포맷되지 않았습니다. 사용 전에 카드를 포맷하세요",
    "ru": "Карта памяти не отформатирована. Отформатируйте карту перед использованием",
    "tr": "Hafıza kartı biçimlendirilmemiş. Kullanmadan önce kartı biçimlendirin",
    "zh": "存储卡未格式化，请格式化后使用"
  },
  "fpv_tip_0x1C100407": {
    "de": "Speicherkarte wird formatiert...",
    "en": "Formatting memory card...",
    "es": "Formateando la tarjeta de memoria...",
    "fr": "Formatage de la carte mémoire…",
    "ja": "メモリーカードのフォーマット中...",
    "ko": "메모리 카드 포맷 중...",
    "ru": "Форматирование карты памяти...",
    "tr": "Hafıza kartı biçimlendiriliyor...",
    "zh": "存储卡正在格式化，请等待"
  },
  "fpv_tip_0x1C100408": {
    "de": "Dateisystem der Speicherkarte wird nicht unterstützt. Karte vor Gebrauch formatieren.",
    "en": "Memory card file system not supported. Format card before use",
    "es": "Sistema de archivos de la tarjeta de memoria no compatible. Formatee la tarjeta antes del uso",
    "fr": "Système de fichiers de la carte mémoire non pris en charge. Formatez la carte avant utilisation",
    "ja": "メモリーカードファイルシステムはサポートされていません。 カードをフォーマットしてから使用してください",
    "ko": "메모리 카드 파일 시스템이 지원되지 않습니다. 사용 전에 카드를 포맷하세요",
    "ru": "Файловая система карты памяти не поддерживается. Отформатируйте карту перед использованием",
    "tr": "Hafıza kartı dosya sistemi desteklenmiyor. Kullanmadan önce kartı biçimlendirin",
    "zh": "不支持该存储卡文件系统，请格式化后使用"
  },
  "fpv_tip_0x1C100409": {
    "de": "Speicherkarte wird aktualisiert...",
    "en": "Refreshing memory card...",
    "es": "Actualizando la tarjeta de memoria...",
    "fr": "Actualisation de la carte mémoire…",
    "ja": "メモリーカードの更新中...",
    "ko": "메모리 카드 새로고침 중...",
    "ru": "Обновление карты памяти...",
    "tr": "Hafıza kartı yenileniyor...",
    "zh": "存储卡正在刷新，请等待"
  },
  "fpv_tip_0x1C10040A": {
    "de": "Speicherkarte voll. Speicherplatz freigeben.",
    "en": "Memory card full. Clear space",
    "es": "Tarjeta de memoria llena. Libere espacio",
    "fr": "Carte mémoire pleine. Libérez de l'espace",
    "ja": "メモリーカードが満杯です。 領域をクリア",
    "ko": "메모리 카드 공간 없음. 공간을 확보하세요",
    "ru": "Карта памяти заполнена. Освободите место",
    "tr": "Hafıza kartı dolu. Yer açın",
    "zh": "存储卡已满，请清除内存"
  },
  "fpv_tip_0x1C10040B": {
    "de": "Max. Dateiindex überschritten. SD-Karte formatieren",
    "en": "Max file index exceeded. Format SD card",
    "es": "Se ha superado el índice máximo de archivos. Formatear tarjeta SD",
    "fr": "Index de fichier max. dépassé. Formater la carte SD",
    "ja": "ファイルインデックスの最大値を超えました。SDカードをフォーマット",
    "ko": "최대 파일 색인을 초과함. SD 카드 포맷",
    "ru": "Превышен максимальный индекс файла. Форматировать карту памяти SD",
    "tr": "Maks. dosya dizini aşıldı. SD kartı biçimlendir",
    "zh": "文件索引超出最大限制，请格式化SD卡"
  },
  "fpv_tip_0x1C10040C": {
    "de": "Speicherkarte wird initialisiert...",
    "en": "Initializing memory card...",
    "es": "Inicializando tarjeta de memoria...",
    "fr": "Initialisation de la carte mémoire…",
    "ja": "メモリーカードの初期化中...",
    "ko": "메모리 카드 초기화 중...",
    "ru": "Инициализация карты памяти...",
    "tr": "Hafıza kartı başlatılıyor...",
    "zh": "存储卡正在初始化，请等待"
  },
  "fpv_tip_0x1C10040D": {
    "de": "Speicherkartenfehler. Karte vor Gebrauch formatieren.",
    "en": "Memory card error. Format card before use",
    "es": "Error de la tarjeta de memoria. Formatee la tarjeta antes del uso",
    "fr": "Erreur de carte mémoire. Formatez la carte avant utilisation",
    "ja": "メモリーカードにエラーが発生しました。 カードをフォーマットしてから使用してください",
    "ko": "메모리 카드 오류. 사용 전에 카드를 포맷하세요",
    "ru": "Ошибка карты памяти. Отформатируйте карту перед использованием",
    "tr": "Hafıza kartı hatası. Kullanmadan önce kartı biçimlendirin",
    "zh": "存储卡异常，请格式化存储卡后使用"
  },
  "fpv_tip_0x1C10040E": {
    "de": "Speicherkarte wird repariert...",
    "en": "Fixing memory card...",
    "es": "Corrigiendo tarjeta de memoria...",
    "fr": "Réparation de la carte mémoire…",
    "ja": "メモリーカードの修正中...",
    "ko": "메모리 카드 문제 해결 중...",
    "ru": "Исправление карты памяти...",
    "tr": "Hafıza kartı düzeltiliyor...",
    "zh": "存储卡修复中，请等待"
  },
  "fpv_tip_0x1C10040F": {
    "de": "Niedrige Lese- und Schreibgeschwindigkeit für Speicherkarte. Warten, bis der Vorgang abgeschlossen wurde.",
    "en": "Memory card read and write speed low. Wait until process completes",
    "es": "Velocidad baja de lectura y escritura de la tarjeta de memoria. Espere hasta que se complete el proceso",
    "fr": "Vitesse de lecture et d'écriture de la carte mémoire lente. Attendez que le processus soit terminé",
    "ja": "メモリーカード読み取り／書き込みが低速です。 プロセスが完了するまでお待ちください",
    "ko": "메모리 카드 읽기 및 쓰기 속도가 느립니다. 프로세스가 완료될 때까지 기다리세요",
    "ru": "Низкая скорость чтения и записи карты памяти. Подождите, пока процесс завершится",
    "tr": "Hafıza kartının okuma ve yazma hızı düşük. İşlem tamamlanana kadar bekleyin",
    "zh": "存储卡读写缓慢，请等待"
  },
  "fpv_tip_0x1C200001": {
    "de": "Kamera %component_index überhitzt. Vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera %component_index overheated. Wait for temperature to return to normal before use",
    "es": "Cámara %component_index sobrecalentada. Espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Surchauffe du %component_index de la caméra. Attendez que la température revienne à la normale avant utilisation",
    "ja": "カメラ %component_index 高温。常温に戻るまで待ってからご使用ください",
    "ko": "카메라 %component_index 과열. 사용 전에 온도가 정상으로 돌아올 때까지 기다리세요",
    "ru": "Камера %component_index перегрелась. Перед использованием подождите, пока температура не вернется в норму",
    "tr": "Kamera %component_index aşırı ısındı. Kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "%component_index号云台相机过热，请等待降温后再使用"
  },
  "fpv_tip_0x1C200101": {
    "de": "Fehler: Kamera %component_index. Kamera neu starten",
    "en": "Camera %component_index error. Restart camera",
    "es": "Error de la cámara %component_index. Reinicia la cámara",
    "fr": "Erreur de %component_index de la caméra. Redémarrez la caméra",
    "ja": "カメラ%component_indexエラー。カメラを再起動してください",
    "ko": "카메라 %component_index 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка камеры %component_index. Перезагрузите камеру",
    "tr": "Kamera %component_index hatası. Kamerayı yeniden başlatın",
    "zh": "%component_index号云台相机异常，请重启相机"
  },
  "fpv_tip_0x1C200102": {
    "de": "Fehler: Kamera %component_index. Kamera neu starten",
    "en": "Camera %component_index error. Restart camera",
    "es": "Error de la cámara %component_index. Reinicia la cámara",
    "fr": "Erreur de %component_index de la caméra. Redémarrez la caméra",
    "ja": "カメラ%component_indexエラー。カメラを再起動してください",
    "ko": "카메라 %component_index 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка камеры %component_index. Перезагрузите камеру",
    "tr": "Kamera %component_index hatası. Kamerayı yeniden başlatın",
    "zh": "%component_index号云台相机异常，请重启相机"
  },
  "fpv_tip_0x1C200103": {
    "de": "Temperatur des Kameraprozessors zu hoch. Fluggerät ausschalten und vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera processor temperature too high. Power off aircraft and wait for temperature to return to normal before use",
    "es": "Temperatura demasiado alta del procesador de la cámara. Apaga la aeronave y espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Température du processeur de la caméra trop élevée. Éteignez l'appareil et attendez que sa température revienne à la normale avant utilisation",
    "ja": "カメラプロセッサの温度が高すぎる。機体の電源を切り、通常の温度に戻ってからご使用ください",
    "ko": "카메라 프로세서 온도 너무 높음. 사용 전에 기체의 전원을 끄고 온도가 정상으로 돌아올 때까지 기다리세요.",
    "ru": "Чрезмерно высокая температура процессора камеры. Перед использованием выключите питание дрона и дождитесь, пока температура опустится до нормальной",
    "tr": "Kamera işlemci sıcaklığı çok yüksek. Aracı kapatın ve kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度过高，请关闭飞机，等待冷却后再使用"
  },
  "fpv_tip_0x1C200103_in_the_sky": {
    "de": "Hohe Temperatur des Kameraprozessors. Umgehend zum Startpunkt zurückkehren oder landen. Vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera processor temperature high. Return to home or land promptly. Wait for temperature to return to normal before use",
    "es": "Temperatura alta del procesador de la cámara. Regresa al punto de origen o aterriza rápidamente. Espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Température du processeur de la caméra élevée. Retournez au point de départ ou atterrissez rapidement. Attendez que la température revienne à la normale avant utilisation",
    "ja": "カメラプロセッサの温度が高い。直ちに帰還するか、着陸してください。常温に戻るまで待ってからご使用ください",
    "ko": "카메라 프로세서 온도 높음. 즉시 리턴 투 홈 또는 착륙 필요. 사용 전에 온도가 정상으로 돌아올 때까지 기다리세요.",
    "ru": "Высокая температура процессора камеры. Как можно скорее вернитесь на базу или приземлитесь. Перед использованием подождите, пока температура не опустится до нормальной",
    "tr": "Kamera işlemci sıcaklığı yüksek. Ana konuma geri dönün veya derhal iniş yapın. Kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度高，请尽快返航或降落，等待冷却后重启使用"
  },
  "fpv_tip_0x1C200104": {
    "de": "Temperatur des Kameraprozessors zu hoch. Fluggerät ausschalten und vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera processor temperature too high. Power off aircraft and wait for temperature to return to normal before use",
    "es": "Temperatura demasiado alta del procesador de la cámara. Apaga la aeronave y espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Température du processeur de la caméra trop élevée. Éteignez l'appareil et attendez que sa température revienne à la normale avant utilisation",
    "ja": "カメラプロセッサの温度が高すぎる。機体の電源を切り、通常の温度に戻ってからご使用ください",
    "ko": "카메라 프로세서 온도 너무 높음. 사용 전에 기체의 전원을 끄고 온도가 정상으로 돌아올 때까지 기다리세요.",
    "ru": "Чрезмерно высокая температура процессора камеры. Перед использованием выключите питание дрона и дождитесь, пока температура опустится до нормальной",
    "tr": "Kamera işlemci sıcaklığı çok yüksek. Aracı kapatın ve kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度过高，请关闭飞机，等待冷却后再使用"
  },
  "fpv_tip_0x1C200104_in_the_sky": {
    "de": "Temperatur des Kameraprozessors zu hoch. Umgehend zum Startpunkt zurückkehren oder landen. Vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera processor temperature too high. Return to home or land promptly. Wait for temperature to return to normal before use",
    "es": "Temperatura demasiado alta del procesador de la cámara. Regresa al punto de origen o aterriza rápidamente. Espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Température du processeur de la caméra trop élevée. Retournez au point de départ ou atterrissez rapidement. Attendez que la température revienne à la normale avant utilisation",
    "ja": "カメラプロセッサの温度が高すぎる。直ちに帰還するか、着陸してください。常温に戻るまで待ってからご使用ください",
    "ko": "카메라 프로세서 온도 너무 높음. 즉시 리턴 투 홈 또는 착륙 필요. 사용 전에 온도가 정상으로 돌아올 때까지 기다리세요.",
    "ru": "Чрезмерно высокая температура процессора камеры. Как можно скорее вернитесь на базу или приземлитесь. Перед использованием подождите, пока температура не опустится до нормальной",
    "tr": "Kamera işlemci sıcaklığı çok yüksek. Ana konuma geri dönün veya derhal iniş yapın. Kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度过高，请尽快返航或降落，等待冷却后重启使用"
  },
  "fpv_tip_0x1C200105": {
    "de": "Fehler: Kamera %component_index. Kamera neu starten",
    "en": "Camera %component_index error. Restart camera",
    "es": "Error de la cámara %component_index. Reinicia la cámara",
    "fr": "Erreur de %component_index de la caméra. Redémarrez la caméra",
    "ja": "カメラ%component_indexエラー。カメラを再起動してください",
    "ko": "카메라 %component_index 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка камеры %component_index. Перезагрузите камеру",
    "tr": "Kamera %component_index hatası. Kamerayı yeniden başlatın",
    "zh": "%component_index号云台相机异常，请重启相机"
  },
  "fpv_tip_0x1C200201": {
    "de": "Fehler: Kamera %component_index. Kamera neu starten",
    "en": "Camera %component_index error. Restart camera",
    "es": "Error de la cámara %component_index. Reinicia la cámara",
    "fr": "Erreur de %component_index de la caméra. Redémarrez la caméra",
    "ja": "カメラ%component_indexエラー。カメラを再起動してください",
    "ko": "카메라 %component_index 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка камеры %component_index. Перезагрузите камеру",
    "tr": "Kamera %component_index hatası. Kamerayı yeniden başlatın",
    "zh": "%component_index号云台相机异常，请重启相机"
  },
  "fpv_tip_0x1C200202": {
    "de": "Fehler: Kamera %component_index. Kamera neu starten",
    "en": "Camera %component_index error. Restart camera",
    "es": "Error de la cámara %component_index. Reinicia la cámara",
    "fr": "Erreur de %component_index de la caméra. Redémarrez la caméra",
    "ja": "カメラ%component_indexエラー。カメラを再起動してください",
    "ko": "카메라 %component_index 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка камеры %component_index. Перезагрузите камеру",
    "tr": "Kamera %component_index hatası. Kamerayı yeniden başlatın",
    "zh": "%component_index号云台相机异常，请重启相机"
  },
  "fpv_tip_0x1C200203": {
    "de": "Kamerachip überhitzt. Fluggerät ausschalten und vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera chip overheated. Power off aircraft and wait for temperature to return to normal before use",
    "es": "Chip de la cámara sobrecalentado. Apaga la aeronave y espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Surchauffe de la puce de la caméra. Éteignez l'appareil et attendez que sa température revienne à la normale avant utilisation",
    "ja": "カメラチップ高温。機体の電源を切り、通常の温度に戻ってからご使用ください",
    "ko": "카메라 칩 과열. 사용 전에 기체의 전원을 끄고 온도가 정상으로 돌아올 때까지 기다리세요",
    "ru": "Чип камеры перегрелся. Выключите питание дрона и дождитесь возвращения температуры к нормальным значениям перед использованием",
    "tr": "Kamera çipi aşırı ısındı. Aracı kapatın ve kullanmadan sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度过高，请关闭飞机，等待冷却后再使用"
  },
  "fpv_tip_0x1C200203_in_the_sky": {
    "de": "Kameraprozessor überhitzt. Umgehend zum Startpunkt zurückkehren oder landen. Vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera processor overheated. Return to home or land promptly. Wait for temperature to return to normal before use",
    "es": "Procesador de la cámara sobrecalentado. Regresa al punto de origen o aterriza rápidamente. Espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Surchauffe du processeur de la caméra. Retournez au point de départ ou atterrissez rapidement. Attendez que la température revienne à la normale avant utilisation",
    "ja": "カメラプロセッサー高温。直ちにホームに戻るか、着陸してください。常温に戻るまで待ってからご使用ください",
    "ko": "카메라 프로세서 과열. 즉시 리턴 투 홈 진행 또는 착륙 필요. 사용 전에 온도가 정상으로 돌아올 때까지 기다리세요",
    "ru": "Перегрев процессора камеры. Немедленно вернитесь в исходное положение и приземлитесь. Перед использованием подождите, пока температура не вернется в норму",
    "tr": "Kamera işlemcisi aşırı ısındı. Hemen eve dön veya iniş yap. Kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度过高，请尽快返航或降落，等待冷却后重启使用"
  },
  "fpv_tip_0x1C200204": {
    "de": "Fehler: Prozessor für den Bildsensor der H20 Kamera",
    "en": "H20 camera image transmission sensor processor error",
    "es": "Error del procesador del sensor de imagen de la cámara H20",
    "fr": "Erreur du processeur du capteur de transmission d\\'images de la caméra H20",
    "ja": "H20 カメラ映像伝送センサープロセッサーエラー",
    "ko": "H20 카메라 이미지 전송 센서 프로세서 오류",
    "ru": "Ошибка процессора датчика передачи изображения камеры H20",
    "tr": "H20 kamera görüntüsü iletim sensörü işlemcisi hatası",
    "zh": "H20 相机图像传感器 芯片异常"
  },
  "fpv_tip_0x1C200301": {
    "de": "Fehler: H20 Kameraobjektiv. Kamera neu starten",
    "en": "H20 camera lens error. Restart camera",
    "es": "Error del objetivo de la cámara H20. Reinicia la cámara",
    "fr": "Erreur de l'objectif de la caméra H20. Redémarrez la caméra",
    "ja": "H20カメラレンズエラー。カメラを再起動してください",
    "ko": "H20 카메라 렌즈 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка объектива камеры H20. Перезагрузите камеру",
    "tr": "H20 kamera lensi hatası. Kamerayı yeniden başlatın",
    "zh": "H20 镜头异常，请断电重启"
  },
  "fpv_tip_0x1C200302": {
    "de": "Fehler: H20 Kameraobjektiv. Kamera neu starten",
    "en": "H20 camera lens error. Restart camera",
    "es": "Error del objetivo de la cámara H20. Reinicia la cámara",
    "fr": "Erreur de l'objectif de la caméra H20. Redémarrez la caméra",
    "ja": "H20カメラレンズエラー。カメラを再起動してください",
    "ko": "H20 카메라 렌즈 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка объектива камеры H20. Перезагрузите камеру",
    "tr": "H20 kamera lensi hatası. Kamerayı yeniden başlatın",
    "zh": "H20 镜头异常，请断电重启"
  },
  "fpv_tip_0x1C200303": {
    "de": "Fehler: H20 Kameraobjektiv. Kamera neu starten",
    "en": "H20 camera lens error. Restart camera",
    "es": "Error del objetivo de la cámara H20. Reinicia la cámara",
    "fr": "Erreur de l'objectif de la caméra H20. Redémarrez la caméra",
    "ja": "H20カメラレンズエラー。カメラを再起動してください",
    "ko": "H20 카메라 렌즈 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка объектива камеры H20. Перезагрузите камеру",
    "tr": "H20 kamera lensi hatası. Kamerayı yeniden başlatın",
    "zh": "H20 镜头异常，请断电重启"
  },
  "fpv_tip_0x1C200304": {
    "de": "Fehler: H20 Kameraobjektiv. Kamera neu starten",
    "en": "H20 camera lens error. Restart camera",
    "es": "Error del objetivo de la cámara H20. Reinicia la cámara",
    "fr": "Erreur de l'objectif de la caméra H20. Redémarrez la caméra",
    "ja": "H20カメラレンズエラー。カメラを再起動してください",
    "ko": "H20 카메라 렌즈 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка объектива камеры H20. Перезагрузите камеру",
    "tr": "H20 kamera lensi hatası. Kamerayı yeniden başlatın",
    "zh": "H20 镜头异常，请断电重启"
  },
  "fpv_tip_0x1C200401": {
    "de": "Ungültige Speicherkarte. Karte austauschen.",
    "en": "Invalid memory card. Replace card",
    "es": "Tarjeta de memoria no válida. Cambie la tarjeta",
    "fr": "Carte mémoire non valide. Remplacez la carte",
    "ja": "メモリーカードが無効です。 カードを交換してください",
    "ko": "잘못된 메모리 카드입니다. 카드를 교체하세요",
    "ru": "Недопустимая карта памяти. Замените карту",
    "tr": "Geçersiz hafıza kartı. Kartı değiştirin",
    "zh": "无效存储卡，请更换存储卡"
  },
  "fpv_tip_0x1C200401_index_1": {
    "de": "Ungültige eMMC. Kamera neu starten. Wenn das Problem weiterhin besteht, wenden Sie sich für Reparaturen an den DJI-Support",
    "en": "Invalid eMMC. Restart camera. If the issue persists, contact DJI Support for repairs",
    "fr": "eMMC non valide. Redémarrez la caméra. Si le problème persiste, contactez l'assistance DJI pour la réparation",
    "ja": "eMMCが無効です。カメラを再起動してください。問題が解決しない場合は、DJIサポートにお問い合わせになり、修理をご依頼ください",
    "ko": "eMMC가 올바르지 않습니다. 카메라를 다시 시작하세요. 문제가 지속되면 DJI 지원 센터에 수리를 요청하세요.",
    "ru": "Сбой eMMC. Перезапустите камеру. Если проблема не устранена, свяжитесь со службой поддержки DJI для ремонта",
    "zh": "无效EMMC，请重启相机，如问题还存在，请返厂维修"
  },
  "fpv_tip_0x1C200402": {
    "de": "Niedrige Speicherkartengeschwindigkeit. Durch schnellere Karte austauschen.",
    "en": "Memory card speed low. Replace with faster card",
    "es": "Velocidad baja de la tarjeta de memoria. Cambie la tarjeta por una más rápida",
    "fr": "Vitesse de la carte mémoire lente. Remplacez-la par une carte plus rapide",
    "ja": "メモリーカードが低速です。 より高速のカードと交換してください",
    "ko": "메모리 카드 속도 느림. 더 빠른 카드로 교체하세요",
    "ru": "Низкая скорость карты памяти. Замените карту на более быструю",
    "tr": "Hafıza kartının hızı yavaş. Daha hızlı bir hafıza kartı ile değiştirin",
    "zh": "存储卡为慢速卡，请更换"
  },
  "fpv_tip_0x1C200402_index_1": {
    "de": "Kamera neu starten. Wenn das Problem weiterhin besteht, wenden Sie sich für Reparaturen an den DJI-Support",
    "en": "Restart camera. If the issue persists, contact DJI Support for repairs",
    "fr": "Redémarrez la caméra. Si le problème persiste, contactez l'assistance DJI pour la réparation",
    "ja": "カメラを再起動してください。問題が解決しない場合は、DJIサポートにお問い合わせになり、修理をご依頼ください",
    "ko": "카메라를 다시 시작하세요. 문제가 지속되면 DJI 지원 센터에 수리를 요청하세요.",
    "ru": "Перезапустите камеру. Если проблема не устранена, свяжитесь со службой поддержки DJI для ремонта",
    "zh": "EMMC速度异常，请重启相机，如问题还存在，请返厂维修"
  },
  "fpv_tip_0x1C200403": {
    "de": "Speicherkartenfehler. Karte austauschen.",
    "en": "Memory card error. Replace card",
    "es": "Error de la tarjeta de memoria. Cambie la tarjeta",
    "fr": "Erreur de carte mémoire. Remplacez la carte",
    "ja": "メモリーカードにエラーが発生しました。 カードを交換してください",
    "ko": "메모리 카드 오류. 카드를 교체하세요",
    "ru": "Ошибка карты памяти. Замените карту",
    "tr": "Hafıza kartı hatası. Kartı değiştirin",
    "zh": "存储卡异常，请更换存储卡"
  },
  "fpv_tip_0x1C200403_index_1": {
    "de": "eMMC-Fehler. Kamera neu starten. Wenn das Problem weiterhin besteht, wenden Sie sich für Reparaturen an den DJI-Support",
    "en": "eMMC error. Restart camera. If the issue persists, contact DJI Support for repairs",
    "fr": "Erreur eMMC. Redémarrez la caméra. Si le problème persiste, contactez l'assistance DJI pour la réparation",
    "ja": "eMMCのエラーです。カメラを再起動してください。問題が解決しない場合は、DJIサポートにお問い合わせになり、修理をご依頼ください",
    "ko": "eMMC 오류입니다. 카메라를 다시 시작하세요. 문제가 지속되면 DJI 지원 센터에 수리를 요청하세요.",
    "ru": "Ошибка eMMC. Перезапустите камеру. Если проблема не устранена, свяжитесь со службой поддержки DJI для ремонта",
    "zh": "EMMC异常，请重启相机，如问题还存在，请返厂维修"
  },
  "fpv_tip_0x1C200404": {
    "de": "Keine Speicherkarte",
    "en": "No memory card",
    "es": "No hay tarjeta de memoria",
    "fr": "Aucune carte mémoire",
    "ja": "メモリーカードがありません",
    "ko": "메모리 카드 없음",
    "ru": "Нет карты памяти",
    "tr": "Hafıza kartı yok",
    "zh": "无存储卡"
  },
  "fpv_tip_0x1C200404_index_1": {
    "de": "eMMC-Identifikationsfehler. Kamera neu starten. Wenn das Problem weiterhin besteht, wenden Sie sich für Reparaturen an den DJI-Support",
    "en": "eMMC identification error. Restart camera. If the issue persists, contact DJI Support for repairs",
    "fr": "Erreur d'identification eMMC. Redémarrez la caméra. Si le problème persiste, contactez l'assistance DJI pour la réparation",
    "ja": "eMMCの認識エラーです。カメラを再起動してください。問題が解決しない場合は、DJIサポートにお問い合わせになり、修理をご依頼ください",
    "ko": "eMMC 식별 오류입니다. 카메라를 다시 시작하세요. 문제가 지속되면 DJI 지원 센터에 수리를 요청하세요.",
    "ru": "Ошибка идентификации eMMC. Перезапустите камеру. Если проблема не устранена, свяжитесь со службой поддержки DJI для ремонта",
    "zh": "EMMC识别异常，请重启相机，如问题还存在，请返厂维修"
  },
  "fpv_tip_0x1C200405": {
    "de": "Lese- und Schreibberechtigungen für Speicherkarte bestätigen",
    "en": "Confirm memory card read and write permissions",
    "es": "Confirme los permisos de lectura y escritura de la tarjeta de memoria",
    "fr": "Confirmez les autorisations de lecture et d'écriture de la carte mémoire",
    "ja": "メモリーカードの読み取り／書き込み権限を確認してください",
    "ko": "메모리 카드 읽기 및 쓰기 권한을 확인하세요",
    "ru": "Подтвердите наличие разрешений на чтение и запись карты памяти",
    "tr": "Hafıza kartı okuma ve yazma izinlerini onaylayın",
    "zh": "请确认存储卡读写属性"
  },
  "fpv_tip_0x1C200406": {
    "de": "Speicherkarte nicht formatiert. Karte vor Gebrauch formatieren.",
    "en": "Memory card not formatted. Format card before use",
    "es": "Tarjeta de memoria no formateada. Formatee la tarjeta antes del uso",
    "fr": "Carte mémoire non formatée. Formatez la carte avant utilisation",
    "ja": "メモリーカードがフォーマットされていません。 カードをフォーマットしてから使用してください",
    "ko": "메모리 카드가 포맷되지 않았습니다. 사용 전에 카드를 포맷하세요",
    "ru": "Карта памяти не отформатирована. Отформатируйте карту перед использованием",
    "tr": "Hafıza kartı biçimlendirilmemiş. Kullanmadan önce kartı biçimlendirin",
    "zh": "存储卡未格式化，请格式化后使用"
  },
  "fpv_tip_0x1C200407": {
    "de": "Speicherkarte wird formatiert...",
    "en": "Formatting memory card...",
    "es": "Formateando la tarjeta de memoria...",
    "fr": "Formatage de la carte mémoire…",
    "ja": "メモリーカードのフォーマット中...",
    "ko": "메모리 카드 포맷 중...",
    "ru": "Форматирование карты памяти...",
    "tr": "Hafıza kartı biçimlendiriliyor...",
    "zh": "存储卡正在格式化，请等待"
  },
  "fpv_tip_0x1C200408": {
    "de": "Dateisystem der Speicherkarte wird nicht unterstützt. Karte vor Gebrauch formatieren.",
    "en": "Memory card file system not supported. Format card before use",
    "es": "Sistema de archivos de la tarjeta de memoria no compatible. Formatee la tarjeta antes del uso",
    "fr": "Système de fichiers de la carte mémoire non pris en charge. Formatez la carte avant utilisation",
    "ja": "メモリーカードファイルシステムはサポートされていません。 カードをフォーマットしてから使用してください",
    "ko": "메모리 카드 파일 시스템이 지원되지 않습니다. 사용 전에 카드를 포맷하세요",
    "ru": "Файловая система карты памяти не поддерживается. Отформатируйте карту перед использованием",
    "tr": "Hafıza kartı dosya sistemi desteklenmiyor. Kullanmadan önce kartı biçimlendirin",
    "zh": "不支持该存储卡文件系统，请格式化后使用"
  },
  "fpv_tip_0x1C200409": {
    "de": "Speicherkarte wird aktualisiert...",
    "en": "Refreshing memory card...",
    "es": "Actualizando la tarjeta de memoria...",
    "fr": "Actualisation de la carte mémoire…",
    "ja": "メモリーカードの更新中...",
    "ko": "메모리 카드 새로고침 중...",
    "ru": "Обновление карты памяти...",
    "tr": "Hafıza kartı yenileniyor...",
    "zh": "存储卡正在刷新，请等待"
  },
  "fpv_tip_0x1C20040A": {
    "de": "Speicherkarte voll. Speicherplatz freigeben.",
    "en": "Memory card full. Clear space",
    "es": "Tarjeta de memoria llena. Libere espacio",
    "fr": "Carte mémoire pleine. Libérez de l'espace",
    "ja": "メモリーカードが満杯です。 領域をクリア",
    "ko": "메모리 카드 공간 없음. 공간을 확보하세요",
    "ru": "Карта памяти заполнена. Освободите место",
    "tr": "Hafıza kartı dolu. Yer açın",
    "zh": "存储卡已满，请清除内存"
  },
  "fpv_tip_0x1C20040B": {
    "de": "Max. Dateiindex überschritten. SD-Karte formatieren",
    "en": "Max file index exceeded. Format SD card",
    "es": "Se ha superado el índice máximo de archivos. Formatear tarjeta SD",
    "fr": "Index de fichier max. dépassé. Formater la carte SD",
    "ja": "ファイルインデックスの最大値を超えました。SDカードをフォーマット",
    "ko": "최대 파일 색인을 초과함. SD 카드 포맷",
    "ru": "Превышен максимальный индекс файла. Форматировать карту памяти SD",
    "tr": "Maks. dosya dizini aşıldı. SD kartı biçimlendir",
    "zh": "文件索引超出最大限制，请格式化SD卡"
  },
  "fpv_tip_0x1C20040C": {
    "de": "Speicherkarte wird initialisiert...",
    "en": "Initializing memory card...",
    "es": "Inicializando tarjeta de memoria...",
    "fr": "Initialisation de la carte mémoire…",
    "ja": "メモリーカードの初期化中...",
    "ko": "메모리 카드 초기화 중...",
    "ru": "Инициализация карты памяти...",
    "tr": "Hafıza kartı başlatılıyor...",
    "zh": "存储卡正在初始化，请等待"
  },
  "fpv_tip_0x1C20040D": {
    "de": "Speicherkartenfehler. Karte vor Gebrauch formatieren.",
    "en": "Memory card error. Format card before use",
    "es": "Error de la tarjeta de memoria. Formatee la tarjeta antes del uso",
    "fr": "Erreur de carte mémoire. Formatez la carte avant utilisation",
    "ja": "メモリーカードにエラーが発生しました。 カードをフォーマットしてから使用してください",
    "ko": "메모리 카드 오류. 사용 전에 카드를 포맷하세요",
    "ru": "Ошибка карты памяти. Отформатируйте карту перед использованием",
    "tr": "Hafıza kartı hatası. Kullanmadan önce kartı biçimlendirin",
    "zh": "存储卡异常，请格式化存储卡后使用"
  },
  "fpv_tip_0x1C20040E": {
    "de": "Speicherkarte wird repariert...",
    "en": "Fixing memory card...",
    "es": "Corrigiendo tarjeta de memoria...",
    "fr": "Réparation de la carte mémoire…",
    "ja": "メモリーカードの修正中...",
    "ko": "메모리 카드 문제 해결 중...",
    "ru": "Исправление карты памяти...",
    "tr": "Hafıza kartı düzeltiliyor...",
    "zh": "存储卡修复中，请等待"
  },
  "fpv_tip_0x1C20040F": {
    "de": "Niedrige Lese- und Schreibgeschwindigkeit für Speicherkarte. Warten, bis der Vorgang abgeschlossen wurde.",
    "en": "Memory card read and write speed low. Wait until process completes",
    "es": "Velocidad baja de lectura y escritura de la tarjeta de memoria. Espere hasta que se complete el proceso",
    "fr": "Vitesse de lecture et d'écriture de la carte mémoire lente. Attendez que le processus soit terminé",
    "ja": "メモリーカード読み取り／書き込みが低速です。 プロセスが完了するまでお待ちください",
    "ko": "메모리 카드 읽기 및 쓰기 속도가 느립니다. 프로세스가 완료될 때까지 기다리세요",
    "ru": "Низкая скорость чтения и записи карты памяти. Подождите, пока процесс завершится",
    "tr": "Hafıza kartının okuma ve yazma hızı düşük. İşlem tamamlanana kadar bekleyin",
    "zh": "存储卡读写缓慢，请等待"
  },
  "fpv_tip_0x1C200410": {
    "de": "Überprüfung vor Verwendung der Speicherkarte erforderlich. Passwort zur Verifizierung eingeben.",
    "en": "Verification required before using memory card. Enter password to verify",
    "es": "Se requiere verificación antes de usar la tarjeta de memoria. Introduce la contraseña para verificar",
    "fr": "Vérification requise avant d'utiliser la carte mémoire. Entrez le mot de passe pour vérifier",
    "ja": "メモリーカードの使用前に検証が必要です。 確認用パスワードを入力",
    "ko": "메모리 카드를 사용하기 전에 인증이 필요합니다. 인증을 위해 비밀번호를 입력하세요",
    "ru": "Требуется проверка перед использованием карты памяти. Введите пароль для проверки",
    "tr": "Hafıza kartını kullanmadan önce doğrulama gerekli. Doğrulamak için parolayı girin",
    "zh": "存储卡加密待验证，请输入密码进行验证"
  },
  "fpv_tip_0x1C300001": {
    "de": "Kamera %component_index überhitzt. Vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera %component_index overheated. Wait for temperature to return to normal before use",
    "es": "Cámara %component_index sobrecalentada. Espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Surchauffe du %component_index de la caméra. Attendez que la température revienne à la normale avant utilisation",
    "ja": "カメラ %component_index 高温。常温に戻るまで待ってからご使用ください",
    "ko": "카메라 %component_index 과열. 사용 전에 온도가 정상으로 돌아올 때까지 기다리세요",
    "ru": "Камера %component_index перегрелась. Перед использованием подождите, пока температура не вернется в норму",
    "tr": "Kamera %component_index aşırı ısındı. Kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "%component_index号云台相机过热，请等待降温后再使用"
  },
  "fpv_tip_0x1C300101": {
    "de": "Fehler: Kamera %component_index. Kamera neu starten",
    "en": "Camera %component_index error. Restart camera",
    "es": "Error de la cámara %component_index. Reinicia la cámara",
    "fr": "Erreur de %component_index de la caméra. Redémarrez la caméra",
    "ja": "カメラ%component_indexエラー。カメラを再起動してください",
    "ko": "카메라 %component_index 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка камеры %component_index. Перезагрузите камеру",
    "tr": "Kamera %component_index hatası. Kamerayı yeniden başlatın",
    "zh": "%component_index号云台相机异常，请重启相机"
  },
  "fpv_tip_0x1C300102": {
    "de": "Fehler: Kamera %component_index. Kamera neu starten",
    "en": "Camera %component_index error. Restart camera",
    "es": "Error de la cámara %component_index. Reinicia la cámara",
    "fr": "Erreur de %component_index de la caméra. Redémarrez la caméra",
    "ja": "カメラ%component_indexエラー。カメラを再起動してください",
    "ko": "카메라 %component_index 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка камеры %component_index. Перезагрузите камеру",
    "tr": "Kamera %component_index hatası. Kamerayı yeniden başlatın",
    "zh": "%component_index号云台相机异常，请重启相机"
  },
  "fpv_tip_0x1C300103": {
    "de": "Hohe Temperatur des Kameraprozessors. Fluggerät ausschalten und vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera processor temperature high. Power off aircraft and wait for temperature to return to normal before use",
    "es": "Temperatura alta del procesador de la cámara. Apaga la aeronave y espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Température du processeur de la caméra élevée. Éteignez l'appareil et attendez que sa température revienne à la normale avant utilisation",
    "ja": "カメラプロセッサの温度が高い。機体の電源を切り、通常の温度に戻ってからご使用ください",
    "ko": "카메라 프로세서 온도 높음. 사용 전에 기체의 전원을 끄고 온도가 정상으로 돌아올 때까지 기다리세요.",
    "ru": "Высокая температура процессора камеры. Перед использованием выключите питание дрона и дождитесь, пока температура опустится до нормальной",
    "tr": "Kamera işlemci sıcaklığı yüksek. Aracı kapatın ve kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度高，请关闭飞机，等待冷却后再使用"
  },
  "fpv_tip_0x1C300103_in_the_sky": {
    "de": "Hohe Temperatur des Kameraprozessors. Umgehend zum Startpunkt zurückkehren oder landen. Vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera processor temperature high. Return to home or land promptly. Wait for temperature to return to normal before use",
    "es": "Temperatura alta del procesador de la cámara. Regresa al punto de origen o aterriza rápidamente. Espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Température du processeur de la caméra élevée. Retournez au point de départ ou atterrissez rapidement. Attendez que la température revienne à la normale avant utilisation",
    "ja": "カメラプロセッサの温度が高い。直ちに帰還するか、着陸してください。常温に戻るまで待ってからご使用ください",
    "ko": "카메라 프로세서 온도 높음. 즉시 리턴 투 홈 또는 착륙 필요. 사용 전에 온도가 정상으로 돌아올 때까지 기다리세요.",
    "ru": "Высокая температура процессора камеры. Как можно скорее вернитесь на базу или приземлитесь. Перед использованием подождите, пока температура не опустится до нормальной",
    "tr": "Kamera işlemci sıcaklığı yüksek. Ana konuma geri dönün veya derhal iniş yapın. Kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度高，请尽快返航或降落，等待冷却后重启使用"
  },
  "fpv_tip_0x1C300104": {
    "de": "Temperatur des Kameraprozessors zu hoch. Fluggerät ausschalten und vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera processor temperature too high. Power off aircraft and wait for temperature to return to normal before use",
    "es": "Temperatura demasiado alta del procesador de la cámara. Apaga la aeronave y espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Température du processeur de la caméra trop élevée. Éteignez l'appareil et attendez que sa température revienne à la normale avant utilisation",
    "ja": "カメラプロセッサの温度が高すぎる。機体の電源を切り、通常の温度に戻ってからご使用ください",
    "ko": "카메라 프로세서 온도 너무 높음. 사용 전에 기체의 전원을 끄고 온도가 정상으로 돌아올 때까지 기다리세요.",
    "ru": "Чрезмерно высокая температура процессора камеры. Перед использованием выключите питание дрона и дождитесь, пока температура опустится до нормальной",
    "tr": "Kamera işlemci sıcaklığı çok yüksek. Aracı kapatın ve kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度过高，请关闭飞机，等待冷却后再使用"
  },
  "fpv_tip_0x1C300104_in_the_sky": {
    "de": "Temperatur des Kameraprozessors zu hoch. Umgehend zum Startpunkt zurückkehren oder landen. Vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera processor temperature too high. Return to home or land promptly. Wait for temperature to return to normal before use",
    "es": "Temperatura demasiado alta del procesador de la cámara. Regresa al punto de origen o aterriza rápidamente. Espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Température du processeur de la caméra trop élevée. Retournez au point de départ ou atterrissez rapidement. Attendez que la température revienne à la normale avant utilisation",
    "ja": "カメラプロセッサの温度が高すぎる。直ちに帰還するか、着陸してください。常温に戻るまで待ってからご使用ください",
    "ko": "카메라 프로세서 온도 너무 높음. 즉시 리턴 투 홈 또는 착륙 필요. 사용 전에 온도가 정상으로 돌아올 때까지 기다리세요.",
    "ru": "Чрезмерно высокая температура процессора камеры. Как можно скорее вернитесь на базу или приземлитесь. Перед использованием подождите, пока температура не опустится до нормальной",
    "tr": "Kamera işlemci sıcaklığı çok yüksek. Ana konuma geri dönün veya derhal iniş yapın. Kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "相机芯片温度过高，请尽快返航或降落，等待冷却后重启使用"
  },
  "fpv_tip_0x1C300105": {
    "de": "Fehler: Kamera %component_index. Kamera neu starten",
    "en": "Camera %component_index error. Restart camera",
    "es": "Error de la cámara %component_index. Reinicia la cámara",
    "fr": "Erreur de %component_index de la caméra. Redémarrez la caméra",
    "ja": "カメラ%component_indexエラー。カメラを再起動してください",
    "ko": "카메라 %component_index 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка камеры %component_index. Перезагрузите камеру",
    "tr": "Kamera %component_index hatası. Kamerayı yeniden başlatın",
    "zh": "%component_index号云台相机异常，请重启相机"
  },
  "fpv_tip_0x1C300301": {
    "de": "Fehler: H20 Kameraobjektiv. Kamera neu starten",
    "en": "H20 camera lens error. Restart camera",
    "es": "Error del objetivo de la cámara H20. Reinicia la cámara",
    "fr": "Erreur de l'objectif de la caméra H20. Redémarrez la caméra",
    "ja": "H20カメラレンズエラー。カメラを再起動してください",
    "ko": "H20 카메라 렌즈 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка объектива камеры H20. Перезагрузите камеру",
    "tr": "H20 kamera lensi hatası. Kamerayı yeniden başlatın",
    "zh": "H20 镜头异常，请断电重启"
  },
  "fpv_tip_0x1C300302": {
    "de": "Fehler: H20 Kameraobjektiv. Kamera neu starten",
    "en": "H20 camera lens error. Restart camera",
    "es": "Error del objetivo de la cámara H20. Reinicia la cámara",
    "fr": "Erreur de l'objectif de la caméra H20. Redémarrez la caméra",
    "ja": "H20カメラレンズエラー。カメラを再起動してください",
    "ko": "H20 카메라 렌즈 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка объектива камеры H20. Перезагрузите камеру",
    "tr": "H20 kamera lensi hatası. Kamerayı yeniden başlatın",
    "zh": "H20 镜头异常，请断电重启"
  },
  "fpv_tip_0x1C300303": {
    "de": "Fehler: H20 Kameraobjektiv. Kamera neu starten",
    "en": "H20 camera lens error. Restart camera",
    "es": "Error del objetivo de la cámara H20. Reinicia la cámara",
    "fr": "Erreur de l'objectif de la caméra H20. Redémarrez la caméra",
    "ja": "H20カメラレンズエラー。カメラを再起動してください",
    "ko": "H20 카메라 렌즈 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка объектива камеры H20. Перезагрузите камеру",
    "tr": "H20 kamera lensi hatası. Kamerayı yeniden başlatın",
    "zh": "H20 镜头异常，请断电重启"
  },
  "fpv_tip_0x1C300304": {
    "de": "Fehler: H20 Kameraobjektiv. Kamera neu starten",
    "en": "H20 camera lens error. Restart camera",
    "es": "Error del objetivo de la cámara H20. Reinicia la cámara",
    "fr": "Erreur de l'objectif de la caméra H20. Redémarrez la caméra",
    "ja": "H20カメラレンズエラー。カメラを再起動してください",
    "ko": "H20 카메라 렌즈 오류. 카메라를 다시 시작하세요",
    "ru": "Ошибка объектива камеры H20. Перезагрузите камеру",
    "tr": "H20 kamera lensi hatası. Kamerayı yeniden başlatın",
    "zh": "H20 镜头异常，请断电重启"
  },
  "fpv_tip_0x1C300401": {
    "de": "Ungültige Speicherkarte. Karte austauschen.",
    "en": "Invalid memory card. Replace card",
    "es": "Tarjeta de memoria no válida. Cambie la tarjeta",
    "fr": "Carte mémoire non valide. Remplacez la carte",
    "ja": "メモリーカードが無効です。 カードを交換してください",
    "ko": "잘못된 메모리 카드입니다. 카드를 교체하세요",
    "ru": "Недопустимая карта памяти. Замените карту",
    "tr": "Geçersiz hafıza kartı. Kartı değiştirin",
    "zh": "无效存储卡，请更换存储卡"
  },
  "fpv_tip_0x1C300401_index_1": {
    "de": "Ungültige eMMC. Kamera neu starten. Wenn das Problem weiterhin besteht, wenden Sie sich für Reparaturen an den DJI-Support",
    "en": "Invalid eMMC. Restart camera. If the issue persists, contact DJI Support for repairs",
    "fr": "eMMC non valide. Redémarrez la caméra. Si le problème persiste, contactez l'assistance DJI pour la réparation",
    "ja": "eMMCが無効です。カメラを再起動してください。問題が解決しない場合は、DJIサポートにお問い合わせになり、修理をご依頼ください",
    "ko": "eMMC가 올바르지 않습니다. 카메라를 다시 시작하세요. 문제가 지속되면 DJI 지원 센터에 수리를 요청하세요.",
    "ru": "Сбой eMMC. Перезапустите камеру. Если проблема не устранена, свяжитесь со службой поддержки DJI для ремонта",
    "zh": "无效EMMC，请重启相机，如问题还存在，请返厂维修"
  },
  "fpv_tip_0x1C300402": {
    "de": "Niedrige Speicherkartengeschwindigkeit. Durch schnellere Karte austauschen.",
    "en": "Memory card speed low. Replace with faster card",
    "es": "Velocidad baja de la tarjeta de memoria. Cambie la tarjeta por una más rápida",
    "fr": "Vitesse de la carte mémoire lente. Remplacez-la par une carte plus rapide",
    "ja": "メモリーカードが低速です。 より高速のカードと交換してください",
    "ko": "메모리 카드 속도 느림. 더 빠른 카드로 교체하세요",
    "ru": "Низкая скорость карты памяти. Замените карту на более быструю",
    "tr": "Hafıza kartının hızı yavaş. Daha hızlı bir hafıza kartı ile değiştirin",
    "zh": "存储卡为慢速卡，请更换"
  },
  "fpv_tip_0x1C300402_index_1": {
    "de": "eMMC-Geschwindigkeitsfehler. Kamera neu starten. Wenn das Problem weiterhin besteht, wenden Sie sich für Reparaturen an den DJI-Support",
    "en": "eMMC speed error. Restart camera. If the issue persists, contact DJI Support for repairs",
    "fr": "Erreur de vitesse de l'eMMC. Redémarrez la caméra. Si le problème persiste, contactez l'assistance DJI pour la réparation",
    "ja": "eMMCの速度エラーです。カメラを再起動してください。問題が解決しない場合は、DJIサポートにお問い合わせになり、修理をご依頼ください",
    "ko": "eMMC 속도 오류입니다. 카메라를 다시 시작하세요. 문제가 지속되면 DJI 지원 센터에 수리를 요청하세요.",
    "ru": "Ошибка скорости eMMC. Перезапустите камеру. Если проблема не устранена, свяжитесь со службой поддержки DJI для ремонта",
    "zh": "EMMC速度异常，请重启相机，如问题还存在，请返厂维修"
  },
  "fpv_tip_0x1C300403": {
    "de": "Speicherkartenfehler. Karte austauschen.",
    "en": "Memory card error. Replace card",
    "es": "Error de la tarjeta de memoria. Cambie la tarjeta",
    "fr": "Erreur de carte mémoire. Remplacez la carte",
    "ja": "メモリーカードにエラーが発生しました。 カードを交換してください",
    "ko": "메모리 카드 오류. 카드를 교체하세요",
    "ru": "Ошибка карты памяти. Замените карту",
    "tr": "Hafıza kartı hatası. Kartı değiştirin",
    "zh": "存储卡异常，请更换存储卡"
  },
  "fpv_tip_0x1C300403_index_1": {
    "de": "eMMC-Fehler. Kamera neu starten. Wenn das Problem weiterhin besteht, wenden Sie sich für Reparaturen an den DJI-Support",
    "en": "eMMC error. Restart camera. If the issue persists, contact DJI Support for repairs",
    "fr": "Erreur eMMC. Redémarrez la caméra. Si le problème persiste, contactez l'assistance DJI pour la réparation",
    "ja": "eMMCのエラーです。カメラを再起動してください。問題が解決しない場合は、DJIサポートにお問い合わせになり、修理をご依頼ください",
    "ko": "eMMC 오류입니다. 카메라를 다시 시작하세요. 문제가 지속되면 DJI 지원 센터에 수리를 요청하세요.",
    "ru": "Ошибка eMMC. Перезапустите камеру. Если проблема не устранена, свяжитесь со службой поддержки DJI для ремонта",
    "zh": "EMMC异常，请重启相机，如问题还存在，请返厂维修"
  },
  "fpv_tip_0x1C300404": {
    "de": "Keine Speicherkarte",
    "en": "No memory card",
    "es": "No hay tarjeta de memoria",
    "fr": "Aucune carte mémoire",
    "ja": "メモリーカードがありません",
    "ko": "메모리 카드 없음",
    "ru": "Нет карты памяти",
    "tr": "Hafıza kartı yok",
    "zh": "无存储卡"
  },
  "fpv_tip_0x1C300404_index_1": {
    "de": "eMMC-Identifikationsfehler. Kamera neu starten. Wenn das Problem weiterhin besteht, wenden Sie sich für Reparaturen an den DJI-Support",
    "en": "eMMC identification error. Restart camera. If the issue persists, contact DJI Support for repairs",
    "fr": "Erreur d'identification eMMC. Redémarrez la caméra. Si le problème persiste, contactez l'assistance DJI pour la réparation",
    "ja": "eMMCの認識エラーです。カメラを再起動してください。問題が解決しない場合は、DJIサポートにお問い合わせになり、修理をご依頼ください",
    "ko": "eMMC 식별 오류입니다. 카메라를 다시 시작하세요. 문제가 지속되면 DJI 지원 센터에 수리를 요청하세요.",
    "ru": "Ошибка идентификации eMMC. Перезапустите камеру. Если проблема не устранена, свяжитесь со службой поддержки DJI для ремонта",
    "zh": "EMMC识别异常，请重启相机，如问题还存在，请返厂维修"
  },
  "fpv_tip_0x1C300405": {
    "de": "Lese- und Schreibberechtigungen für Speicherkarte bestätigen",
    "en": "Confirm memory card read and write permissions",
    "es": "Confirme los permisos de lectura y escritura de la tarjeta de memoria",
    "fr": "Confirmez les autorisations de lecture et d'écriture de la carte mémoire",
    "ja": "メモリーカードの読み取り／書き込み権限を確認してください",
    "ko": "메모리 카드 읽기 및 쓰기 권한을 확인하세요",
    "ru": "Подтвердите наличие разрешений на чтение и запись карты памяти",
    "tr": "Hafıza kartı okuma ve yazma izinlerini onaylayın",
    "zh": "请确认存储卡读写属性"
  },
  "fpv_tip_0x1C300406": {
    "de": "Speicherkarte nicht formatiert. Karte vor Gebrauch formatieren.",
    "en": "Memory card not formatted. Format card before use",
    "es": "Tarjeta de memoria no formateada. Formatee la tarjeta antes del uso",
    "fr": "Carte mémoire non formatée. Formatez la carte avant utilisation",
    "ja": "メモリーカードがフォーマットされていません。 カードをフォーマットしてから使用してください",
    "ko": "메모리 카드가 포맷되지 않았습니다. 사용 전에 카드를 포맷하세요",
    "ru": "Карта памяти не отформатирована. Отформатируйте карту перед использованием",
    "tr": "Hafıza kartı biçimlendirilmemiş. Kullanmadan önce kartı biçimlendirin",
    "zh": "存储卡未格式化，请格式化后使用"
  },
  "fpv_tip_0x1C300407": {
    "de": "Speicherkarte wird formatiert...",
    "en": "Formatting memory card...",
    "es": "Formateando la tarjeta de memoria...",
    "fr": "Formatage de la carte mémoire…",
    "ja": "メモリーカードのフォーマット中...",
    "ko": "메모리 카드 포맷 중...",
    "ru": "Форматирование карты памяти...",
    "tr": "Hafıza kartı biçimlendiriliyor...",
    "zh": "存储卡正在格式化，请等待"
  },
  "fpv_tip_0x1C300408": {
    "de": "Dateisystem der Speicherkarte wird nicht unterstützt. Karte vor Gebrauch formatieren.",
    "en": "Memory card file system not supported. Format card before use",
    "es": "Sistema de archivos de la tarjeta de memoria no compatible. Formatee la tarjeta antes del uso",
    "fr": "Système de fichiers de la carte mémoire non pris en charge. Formatez la carte avant utilisation",
    "ja": "メモリーカードファイルシステムはサポートされていません。 カードをフォーマットしてから使用してください",
    "ko": "메모리 카드 파일 시스템이 지원되지 않습니다. 사용 전에 카드를 포맷하세요",
    "ru": "Файловая система карты памяти не поддерживается. Отформатируйте карту перед использованием",
    "tr": "Hafıza kartı dosya sistemi desteklenmiyor. Kullanmadan önce kartı biçimlendirin",
    "zh": "不支持该存储卡文件系统，请格式化后使用"
  },
  "fpv_tip_0x1C300409": {
    "de": "Speicherkarte wird aktualisiert...",
    "en": "Refreshing memory card...",
    "es": "Actualizando la tarjeta de memoria...",
    "fr": "Actualisation de la carte mémoire…",
    "ja": "メモリーカードの更新中...",
    "ko": "메모리 카드 새로고침 중...",
    "ru": "Обновление карты памяти...",
    "tr": "Hafıza kartı yenileniyor...",
    "zh": "存储卡正在刷新，请等待"
  },
  "fpv_tip_0x1C30040A": {
    "de": "Speicherkarte voll. Speicherplatz freigeben.",
    "en": "Memory card full. Clear space",
    "es": "Tarjeta de memoria llena. Libere espacio",
    "fr": "Carte mémoire pleine. Libérez de l'espace",
    "ja": "メモリーカードが満杯です。 領域をクリア",
    "ko": "메모리 카드 공간 없음. 공간을 확보하세요",
    "ru": "Карта памяти заполнена. Освободите место",
    "tr": "Hafıza kartı dolu. Yer açın",
    "zh": "存储卡已满，请清除内存"
  },
  "fpv_tip_0x1C30040B": {
    "de": "Max. Dateiindex überschritten. SD-Karte formatieren",
    "en": "Max file index exceeded. Format SD card",
    "es": "Se ha superado el índice máximo de archivos. Formatear tarjeta SD",
    "fr": "Index de fichier max. dépassé. Formater la carte SD",
    "ja": "ファイルインデックスの最大値を超えました。SDカードをフォーマット",
    "ko": "최대 파일 색인을 초과함. SD 카드 포맷",
    "ru": "Превышен максимальный индекс файла. Форматировать карту памяти SD",
    "tr": "Maks. dosya dizini aşıldı. SD kartı biçimlendir",
    "zh": "文件索引超出最大限制，请格式化SD卡"
  },
  "fpv_tip_0x1C30040C": {
    "de": "Speicherkarte wird initialisiert...",
    "en": "Initializing memory card...",
    "es": "Inicializando tarjeta de memoria...",
    "fr": "Initialisation de la carte mémoire…",
    "ja": "メモリーカードの初期化中...",
    "ko": "메모리 카드 초기화 중...",
    "ru": "Инициализация карты памяти...",
    "tr": "Hafıza kartı başlatılıyor...",
    "zh": "存储卡正在初始化，请等待"
  },
  "fpv_tip_0x1C30040D": {
    "de": "Speicherkartenfehler. Karte vor Gebrauch formatieren.",
    "en": "Memory card error. Format card before use",
    "es": "Error de la tarjeta de memoria. Formatee la tarjeta antes del uso",
    "fr": "Erreur de carte mémoire. Formatez la carte avant utilisation",
    "ja": "メモリーカードにエラーが発生しました。 カードをフォーマットしてから使用してください",
    "ko": "메모리 카드 오류. 사용 전에 카드를 포맷하세요",
    "ru": "Ошибка карты памяти. Отформатируйте карту перед использованием",
    "tr": "Hafıza kartı hatası. Kullanmadan önce kartı biçimlendirin",
    "zh": "存储卡异常，请格式化存储卡后使用"
  },
  "fpv_tip_0x1C30040E": {
    "de": "Speicherkarte wird repariert...",
    "en": "Fixing memory card...",
    "es": "Corrigiendo tarjeta de memoria...",
    "fr": "Réparation de la carte mémoire…",
    "ja": "メモリーカードの修正中...",
    "ko": "메모리 카드 문제 해결 중...",
    "ru": "Исправление карты памяти...",
    "tr": "Hafıza kartı düzeltiliyor...",
    "zh": "存储卡修复中，请等待"
  },
  "fpv_tip_0x1C30040F": {
    "de": "Niedrige Lese- und Schreibgeschwindigkeit für Speicherkarte. Warten, bis der Vorgang abgeschlossen wurde.",
    "en": "Memory card read and write speed low. Wait until process completes",
    "es": "Velocidad baja de lectura y escritura de la tarjeta de memoria. Espere hasta que se complete el proceso",
    "fr": "Vitesse de lecture et d'écriture de la carte mémoire lente. Attendez que le processus soit terminé",
    "ja": "メモリーカード読み取り／書き込みが低速です。 プロセスが完了するまでお待ちください",
    "ko": "메모리 카드 읽기 및 쓰기 속도가 느립니다. 프로세스가 완료될 때까지 기다리세요",
    "ru": "Низкая скорость чтения и записи карты памяти. Подождите, пока процесс завершится",
    "tr": "Hafıza kartının okuma ve yazma hızı düşük. İşlem tamamlanana kadar bekleyin",
    "zh": "存储卡读写缓慢，请等待"
  },
  "fpv_tip_0x1C300601": {
    "de": "Temperatur der aktuellen Umgebung zu niedrig. Temperaturmessung aktivieren, wenn die Temperatur im normalen Bereich liegt",
    "en": "Temperature of current environment too low. Enable temperature measurement when temperature is within normal range",
    "es": "La temperatura del entorno actual es demasiado baja. Activa la medición de temperatura cuando la temperatura esté en el rango normal",
    "fr": "Température de l\\'environnement actuel trop faible. Activez la mesure de température quand la température de l\\'environnement se situe dans une plage normale",
    "ja": "現在の環境温度が低すぎます。温度測定は通常の温度範囲内の時に有効にしてください",
    "ko": "현재 환경 온도가 너무 낮습니다. 온도가 정상 범위 내에 있을 때 온도 측정을 활성화하세요",
    "ru": "Слишком низкая температура текущей среды. Включайте измерение температуры в пределах нормального диапазона",
    "tr": "Mevcut çevre sıcaklığı çok düşük. Sıcaklık normal aralığa geldiğinde sıcaklık ölçümünü etkinleştir",
    "zh": "环境温度过低，请在正常的工作温度范围内测温"
  },
  "fpv_tip_0x1C300602": {
    "de": "Temperatur der aktuellen Umgebung ist zu hoch. Temperaturmessung nur aktivieren, wenn die Temperatur im normalen Bereich liegt",
    "en": "Temperature of current evironment too high. Only enable temperature measurement when temperature is within normal range",
    "es": "La temperatura del entorno actual es demasiado alta. Activa la medición de temperatura solo cuando la temperatura esté en el rango normal",
    "fr": "Température de l\\'environnement actuel trop élevée. Activez la mesure de température quand la température de l\\'environnement se situe dans une plage normale",
    "ja": "現在の環境温度が高すぎます。温度測定は通常の温度範囲内の時にのみ有効にしてください",
    "ko": "현재 환경 온도가 너무 높습니다. 온도가 정상 범위 내에 있을 때에만 온도 측정을 활성화하세요",
    "ru": "Слишком высокая температура текущей среды. Включайте измерение температуры только в пределах нормального диапазона",
    "tr": "Mevcut çevre sıcaklığı çok yüksek. Sıcaklık ölçümünü yalnızca sıcaklık normal aralığa geldiğinde etkinleştir",
    "zh": "环境温度过高，请在正常的工作温度范围内测温"
  },
  "fpv_tip_0x1C300603": {
    "de": "Moduswechsel an der Zenmuse H20T IR-Wärmebildkamera ...",
    "en": "Zenmuse H20T infrared thermal camera switching modes...",
    "es": "Cámara térmica infrarroja Zenmuse H20T cambiando modos...",
    "fr": "Changement de mode de la caméra thermique infrarouge Zenmuse H20T ...",
    "ja": "Zenmuse H20T赤外線サーマルカメラのモード切り替え中...",
    "ko": "젠뮤즈 H20T 적외선 열화상 카메라 전환 모드 ...",
    "ru": "Режимы переключения инфракрасной тепловизионной камеры Zenmuse H20T ...",
    "tr": "Zenmuse H20T kızılötesi termal kamera geçiş modları…",
    "zh": "H20T相机红外镜头正在切换工作模式，请等待"
  },
  "fpv_tip_0x1C300604": {
    "de": "Fehlende Kalibrierungsdaten: Zenmuse H20T IR-Wärmebildkamera",
    "en": "Zenmuse H20T infrared thermal camera calibration data missing",
    "es": "Faltan datos de calibración de la cámara térmica infrarroja Zenmuse H20T",
    "fr": "Données d\\'étalonnage de la caméra thermique infrarouge Zenmuse H20T introuvables",
    "ja": "Zenmuse H20T赤外線サーマルカメラのキャリブレーションデータがありません",
    "ko": "젠뮤즈 H20T 적외선 열화상 카메라 캘리브레이션 데이터 없음",
    "ru": "Отсутствуют данные о калибровке инфракрасной тепловизионной камеры Zenmuse H20T",
    "tr": "Zenmuse H20T kızılötesi termal kamera kalibrasyon verileri eksik",
    "zh": "H20T相机红外镜头标定数据缺失"
  },
  "fpv_tip_0x1C300605": {
    "de": "Initialisierungsfehler: Zenmuse H20T IR-Wärmebildkamera. Kamera neu starten",
    "en": "Zenmuse H20T infrared thermal camera initialization error. Restart camera",
    "es": "Error de inicio de la cámara térmica infrarroja Zenmuse H20T. Reinicia la cámara",
    "fr": "Erreur d\\'initialisation de la caméra thermique infrarouge Zenmuse H20T. Redémarrez la caméra",
    "ja": "Zenmuse H20T赤外線サーマルカメラの初期化エラー。カメラを再起動してください",
    "ko": "젠뮤즈 H20T 적외선 열화상 카메라 초기화 오류. 카메라를 다시 시작하세요.",
    "ru": "Ошибка инициализации инфракрасной тепловизионной камеры Zenmuse H20T. Перезапустите камеру",
    "tr": "Zenmuse H20T kızılötesi termal kamera başlatma hatası. Kamerayı yeniden başlatın",
    "zh": "H20T相机红外镜头初始化异常，请断电重启"
  },
  "fpv_tip_0x1C300606": {
    "de": "Temperaturmessung fehlgeschlagen. Parameter der Kamera anpassen und erneut versuchen",
    "en": "Temperature measurement failed. Adjust camera parameters and try again",
    "es": "Error de medición de temperatura. Ajusta los parámetros de la cámara y vuelve a intentarlo",
    "fr": "Mesure de la température échouée. Réglez les paramètres de caméra et réessayez",
    "ja": "温度測定に失敗しました。カメラのパラメーターを調整し、再試行してください",
    "ko": "온도 측정에 실패했습니다. 카메라 매개변수를 조정하고 다시 시도하세요",
    "ru": "Не удалось измерить температуру. Настройте параметры камеры и повторите попытку",
    "tr": "Sıcaklık ölçümü başarısız. Kamera parametrelerini ayarlayın ve tekrar deneyin",
    "zh": "测温失败，请调整相机参数后测温"
  },
  "fpv_tip_0x1C300607": {
    "de": "Messbereichsgrenze überschritten. In den Modus mit niedriger Verstärkung wechseln",
    "en": "Exceeded measuring range limit. Switch to Low Gain mode",
    "es": "Se ha excedido el límite del rango de medición. Cambia al modo de baja ganancia",
    "fr": "Limite de plage de mesure dépassée. Passez en mode Gain faible",
    "it": "",
    "ja": "測定範囲の限界を超えました。低ゲインモードに切り替え",
    "ko": "측정 범위 한도를 초과했습니다. 낮은 게인 모드로 전환",
    "ru": "Превышен порог диапазона измерения. Переключить в режим низкого прироста",
    "tr": "Ölçüm aralığı sınırı aşıldı. Düşük Kazanç moduna geç",
    "zh": "测温超出量程，请切换为低增益模式"
  },
  "fpv_tip_0x1C300608": {
    "de": "Messbereichsgrenze überschritten. Es wird empfohlen, einen Infrarot-Dichtefilter anzubringen.",
    "en": "Exceeded measuring range limit. It is recommended to attach an infrared density filter",
    "es": "Se ha excedido el límite del rango de medición. Se recomienda conectar un filtro de densidad infrarroja",
    "fr": "Limite de plage de mesure dépassée. Il est recommandé de fixer un filtre de densité neutre infrarouge",
    "ja": "測定範囲の限界を超えました。赤外線NDフィルターを取り付けることをお勧めします",
    "ko": "측정 범위 한도를 초과했습니다. 적외선 ND 필터를 부착하는 것이 좋습니다.",
    "ru": "Превышен порог диапазона измерения. Рекомендуется подключить инфракрасный нейтральный фильтр",
    "tr": "Ölçüm aralığı sınırı aşıldı. Kızılötesi yoğunluk filtresi takılması önerilir",
    "zh": "测温超出量程，建议安装红外衰减镜"
  },
  "fpv_tip_0x1C300701": {
    "de": "Sonne erkannt. IR-Verschluss geschlossen",
    "en": "Sun detected. Infrared shutter closed",
    "es": "Sol detectado. Obturador de infrarrojos cerrado",
    "fr": "Soleil détecté. Obturateur infrarouge fermé",
    "ja": "太陽を検知。赤外線シャッター閉",
    "ko": "해가 감지되었습니다. 적외선 셔터가 닫혔습니다",
    "ru": "Обнаружено солнце. Инфракрасный затвор закрыт",
    "tr": "Güneş algılandı. Kızılötesi perde kapandı",
    "zh": "检测到太阳，红外快门关闭"
  },
  "fpv_tip_0x1C300702": {
    "de": "Sonne erkannt. Gimbal drehen, um direkte Sonneneinstrahlung zu vermeiden",
    "en": "Sun detected. Rotate gimbal to avoid direct sunlight",
    "es": "Sol detectado. Gire el estabilizador para evitar la luz solar directa",
    "fr": "Soleil détecté. Tournez la nacelle pour éviter la lumière directe du soleil",
    "ja": "太陽を検知。ジンバルを回転させて直射日光を避けてください",
    "ko": "해가 감지되었습니다. 짐벌을 회전시켜 직사 광선을 막으세요",
    "ru": "Обнаружено солнце. Поверните гиростабилизатор, чтобы избежать попадания прямых солнечных лучей",
    "tr": "Güneş algılandı. Doğrudan güneş ışığından kaçınmak için gimbalı döndürün",
    "zh": "检测到太阳，请转动云台，避免太阳直射"
  },
  "fpv_tip_0x1D001001": {
    "de": "Gimbal reagiert nicht nach dem Einschalten",
    "en": "Gimbal unresponsive after power on",
    "es": "El estabilizador no responde después del encendido",
    "fr": "Pas de réponse de la nacelle après la mise sous tension",
    "ja": "電源オンの後にジンバル反応なし",
    "ko": "전원을 켠 후 짐벌이 응답하지 않음",
    "ru": "Стабилизатор не отвечает после включения питания",
    "tr": "Gimbal açıldıktan sonra yanıt vermiyor",
    "zh": "云台上电后无力"
  },
  "fpv_tip_0x1D001101": {
    "de": "Gimbal kann automatische Überprüfung nicht abschließen",
    "en": "Gimbal unable to complete auto check",
    "es": "El estabilizador no puede completar la comprobación automática",
    "fr": "Nacelle incapable de terminer la vérification automatique",
    "ja": "ジンバルの自動確認を完了できません",
    "ko": "짐벌 자동 점검 완료 불가",
    "ru": "Не удалось выполнить автоматическую проверку стабилизатора",
    "tr": "Gimbal otomatik kontrolü tamamlanamıyor",
    "zh": "云台无法完成自检"
  },
  "fpv_tip_0x1D010001": {
    "de": "Gimbal blockiert",
    "en": "Gimbal stuck",
    "es": "Estabilizador atascado",
    "fr": "Nacelle bloquée",
    "ja": "ジンバルがスタック状態",
    "ko": "짐벌 막힘",
    "ru": "Стабилизатор заблокирован",
    "tr": "Gimbal sıkıştı",
    "zh": "云台被卡住"
  },
  "fpv_tip_0x1D010002": {
    "de": "Fehler: Gimbal-Selbsttest",
    "en": "Gimbal auto-check failed",
    "es": "Error de comprobación automática del estabilizador",
    "fr": "Vérif. auto de la nacelle échouée",
    "ja": "ジンバル自動確認失敗",
    "ko": "짐벌 자체 점검 실패",
    "ru": "Сбой автопроверки стабилизатора",
    "tr": "Gimbal otomatik kontrol başarısız",
    "zh": "云台自检失败"
  },
  "fpv_tip_0x1D010003": {
    "de": "Gimbal-Motor überlastet",
    "en": "Gimbal motor overloaded",
    "es": "Motor del estabilizador sobrecargado",
    "fr": "Surcharge du moteur de la nacelle",
    "ja": "ジンバルモーター過負荷状態",
    "ko": "짐벌 모터 과부하",
    "ru": "Мотор стабилизатора перегружен",
    "tr": "Gimbal motoru aşırı yüklü",
    "zh": "云台电机过载"
  },
  "fpv_tip_0x1D010C01": {
    "de": "FPV-Gimbal blockiert",
    "en": "FPV gimbal stuck",
    "es": "Estabilizador FPV atascado",
    "fr": "Nacelle FPV bloquée",
    "ja": "FPVジンバルが動かなくなっています",
    "ko": "FPV 짐벌 막힘",
    "ru": "Заедание стабилизатора курсовой камеры",
    "tr": "FPV gimbal sıkıştı",
    "zh": "FPV云台被卡住"
  },
  "fpv_tip_0x1D010C02": {
    "de": "Selbsttest FPV-Gimbal fehlgeschlagen",
    "en": "FPV gimbal auto-check failed",
    "es": "Error de comprobación automática del estabilizador FPV",
    "fr": "Échec de la vérification auto de la nacelle FPV",
    "ja": "FPVジンバルの自動確認が失敗しました",
    "ko": "FPV 짐벌 자체 점검 실패",
    "ru": "Сбой автопроверки стабилизатора курсовой камеры",
    "tr": "FPV gimbal otomatik kontrolü başarısız",
    "zh": "FPV云台自检失败"
  },
  "fpv_tip_0x1D010C03": {
    "de": "Motor FPV-Gimbal überlastet",
    "en": "FPV gimbal motor overloaded",
    "es": "Motor del estabilizador FPV sobrecargado",
    "fr": "Surcharge du moteur de nacelle FPV",
    "ja": "FPVジンバルモーターが過負荷状態です",
    "ko": "FPV 짐벌 모터 과부하",
    "ru": "Перегрузка двигателя стабилизатора курсовой камеры",
    "tr": "FPV gimbal motoru aşırı yüklü",
    "zh": "FPV云台电机过载"
  },
  "fpv_tip_0x1D020001": {
    "de": "Kalibrierungsfehler: Gimbal",
    "en": "Gimbal calibration error",
    "es": "Error de calibración del estabilizador",
    "fr": "Erreur d\\'étalonnage de la nacelle",
    "ja": "ジンバル キャリブレーション エラー",
    "ko": "짐벌 캘리브레이션 오류",
    "ru": "Ошибка калибровки стабилизатора",
    "tr": "Gimbal kalibrasyon hatası",
    "zh": "云台标定错误"
  },
  "fpv_tip_0x1D020002": {
    "de": "Gimbal-Fehler: IMU. Fluggerät neu starten und erneut versuchen",
    "en": "Gimbal IMU error. Restart aircraft and try again",
    "es": "Error de IMU del estabilizador. Reinicia la aeronave y vuelve a intentarlo.",
    "fr": "Erreur de l'IMU de nacelle. Redémarrez l'appareil et réessayez",
    "ja": "ジンバルのIMUエラー。機体を再起動してやり直してください",
    "ko": "짐벌 IMU 오류. 기체를 재시작한 후 다시 시도하세요.",
    "ru": "Ошибка IMU гиростабилизатора. Перезапустите дрон и повторите попытку",
    "tr": "Gimbal IMU hatası. Hava aracını yeniden başlatın ve tekrar deneyin",
    "zh": "云台IMU数据异常，请尝试重启飞行器"
  },
  "fpv_tip_0x1D020003": {
    "de": "Parameterfehler: Gimbal-Kalibrierung. Automatische Gimbal-Kalibrierung versuchen",
    "en": "Gimbal calibration parameter error. Try gimbal Auto Calibration",
    "es": "Error de parámetros de calibración del estabilizador. Pruebe la calibración automática del estabilizador",
    "fr": "Erreur de paramètre d'étalonnage de la nacelle. Essayez l'étalonnage automatique de la nacelle",
    "ja": "ジンバルキャリブレーションパラメーターエラーです。ジンバル自動キャリブレーションを試行してください",
    "ko": "짐벌 캘리브레이션 매개변수 오류. 짐벌 자동 캘리브레이션을 시도하세요",
    "pt": "",
    "ru": "Ошибка параметра калибровки гиростабилизатора. Попробуйте выполнить автоматическую калибровку гиростабилизатора",
    "tr": "Gimbal kalibrasyon parametresi hatası. Gimbal Otomatik Kalibrasyonu deneyin",
    "zh": "云台校准参数异常，请尝试云台自动校准"
  },
  "fpv_tip_0x1D020C01": {
    "de": "Kalibrierungsfehler FPV-Gimbal",
    "en": "FPV gimbal calibration error",
    "es": "Error de calibración del estabilizador FPV",
    "fr": "Erreur d'étalonnage de la nacelle FPV",
    "ja": "FPVジンバルキャリブレーションエラー",
    "ko": "FPV 짐벌 캘리브레이션 오류",
    "ru": "Ошибка калибровки стабилизатора курсовой камеры",
    "tr": "FPV gimbal kalibrasyon hatası",
    "zh": "FPV云台标定错误"
  },
  "fpv_tip_0x1D030001": {
    "de": "Gimbal kann Fluggerätedaten nicht abrufen",
    "en": "Gimbal unable to retrieve aircraft data",
    "es": "El estabilizador no puede recuperar los datos de la aeronave",
    "fr": "La nacelle ne peut pas récupérer les donnés de l\\'appareil",
    "ja": "ジンバルが機体データを取得できません",
    "ko": "짐벌 기체 데이터 가져올 수 없음",
    "ru": "Стабилизатор получает данные с дрона",
    "tr": "Gimbal araç verilerini alamıyor",
    "zh": "云台无法获取飞行器信息"
  },
  "fpv_tip_0x1D030002": {
    "zh": "飞行器与负载通讯链路中断，请检查负载连接情况"
  },
  "fpv_tip_0x1D030002_in_the_sky": {
    "zh": "飞行器与负载通讯链路中断，请返航并检查负载连接情况"
  },
  "fpv_tip_0x1D030C01": {
    "de": "FPV-Gimbal kann keine Fluggerätedaten abrufen",
    "en": "FPV gimbal unable to obtain aircraft data",
    "es": "El estabilizador FPV no puede obtener los datos de la aeronave",
    "fr": "Nacelle FPV incapable d'obtenir les données de l'appareil",
    "ja": "FPVジンバルで機体データを取得できません",
    "ko": "FPV 짐벌이 기체 데이터 가져올 수 없습니다",
    "ru": "Стабилизатору курсовой камеры не удается получить информацию о дроне",
    "tr": "FPV gimbal, hava aracı verilerini alamıyor",
    "zh": "FPV云台无法获取飞行器信息"
  },
  "fpv_tip_0x1D040001": {
    "de": "Starke Vibrationen am Gimbal",
    "en": "Excessive gimbal vibration",
    "es": "Vibración del estabilizador excesiva",
    "fr": "Vibrations excessives de la nacelle",
    "ja": "ジンバルの過剰な振動",
    "ko": "과도한 짐벌 진동",
    "ru": "Чрезмерная вибрация стабилизатора",
    "tr": "Gimbal aşırı titriyor",
    "zh": "云台异常振动"
  },
  "fpv_tip_0x1D040002": {
    "de": "Fehler: Gimbalsensor",
    "en": "Gimbal sensor error",
    "es": "Error del sensor del estabilizador",
    "fr": "Erreur capteur de la nacelle",
    "ja": "ジンバル センサー エラー",
    "ko": "짐벌 센서 오류",
    "ru": "Ошибка датчика стабилизатора",
    "tr": "Gimbal sensörü hatası",
    "zh": "云台传感器故障"
  },
  "fpv_tip_0x1D040003": {
    "de": "Fehlfunktion: Gimbal-ESC",
    "en": "Gimbal ESC malfunctioned",
    "es": "ESC del estabilizador no funciona correctamente",
    "fr": "Anomalie sur l\\'ESC nacelle",
    "ja": "ジンバルESC故障",
    "ko": "짐벌 ESC 오작동",
    "ru": "Неисправность ESC стабилизатора",
    "tr": "Gimbal ESC arıza yaptı",
    "zh": "云台电调故障"
  },
  "fpv_tip_0x1D040004": {
    "de": "Fehlfunktion: Gimbal-ESC",
    "en": "Gimbal ESC malfunctioned",
    "es": "ESC del estabilizador no funciona correctamente",
    "fr": "Anomalie sur l\\'ESC nacelle",
    "ja": "ジンバルESC故障",
    "ko": "짐벌 ESC 오작동",
    "ru": "Неполадки ESC стабилизатора",
    "tr": "Gimbal ESC arıza yaptı",
    "zh": "云台电调故障"
  },
  "fpv_tip_0x1D040005": {
    "de": "Fehlfunktion: Gimbal-ESC",
    "en": "Gimbal ESC malfunctioned",
    "es": "ESC del estabilizador no funciona correctamente",
    "fr": "Anomalie sur l\\'ESC nacelle",
    "ja": "ジンバルESC故障",
    "ko": "짐벌 ESC 오작동",
    "ru": "Неисправность ESC стабилизатора",
    "tr": "Gimbal ESC arıza yaptı",
    "zh": "云台电调故障"
  },
  "fpv_tip_0x1D040C01": {
    "de": "Abnormale Vibration FPV-Gimbal",
    "en": "Abnormal FPV gimbal vibration",
    "es": "Vibración anormal del estabilizador FPV",
    "fr": "Vibration anormale de la nacelle FPV",
    "ja": "FPVジンバルの振動に異常があります",
    "ko": "비정상적인 FPV 짐벌 진동",
    "ru": "Аномальная вибрация стабилизатора курсовой камеры",
    "tr": "Anormal FPV gimbal titreşimi",
    "zh": "FPV云台异常振动"
  },
  "fpv_tip_0x1D040C02": {
    "de": "Sensorfehler FPV-Gimbal",
    "en": "FPV gimbal sensor error",
    "es": "Error del sensor del estabilizador FPV",
    "fr": "Erreur capteur de la nacelle FPV",
    "ja": "FPVジンバルセンサーエラー",
    "ko": "FPV 짐벌 센서 오류",
    "ru": "Ошибка датчика стабилизатора курсовой камеры",
    "tr": "FPV gimbal sensörü hatası",
    "zh": "FPV云台传感器故障"
  },
  "fpv_tip_0x1D040C03": {
    "de": "ESC-Fehlfunktion FPV-Gimbal",
    "en": "FPV gimbal ESC malfunction",
    "es": "Error de funcionamiento de ESC del estabilizador FPV",
    "fr": "Dysfonctionnement ESC de la nacelle FPV",
    "ja": "FPVジンバルESCに異常",
    "ko": "FPV 짐벌 ESC 오작동",
    "ru": "Неисправность ESC стабилизатора курсовой камеры",
    "tr": "FPV gimbal ESC arızası",
    "zh": "FPV云台电调故障"
  },
  "fpv_tip_0x1D040C04": {
    "de": "ESC-Fehlfunktion FPV-Gimbal",
    "en": "FPV gimbal ESC malfunction",
    "es": "Error de funcionamiento de ESC del estabilizador FPV",
    "fr": "FPV gimbal ESC malfunction",
    "ja": "FPVジンバルESCに異常",
    "ko": "FPV 짐벌 ESC 오작동",
    "ru": "Неисправность ESC стабилизатора курсовой камеры",
    "tr": "FPV gimbal ESC arızası",
    "zh": "FPV云台电调故障"
  },
  "fpv_tip_0x1D040C05": {
    "de": "ESC-Fehlfunktion FPV-Gimbal",
    "en": "FPV gimbal ESC malfunction",
    "es": "Error de funcionamiento de ESC del estabilizador FPV",
    "fr": "FPV gimbal ESC malfunction",
    "ja": "FPVジンバルESCに異常",
    "ko": "FPV 짐벌 ESC 오작동",
    "ru": "Неисправность ESC стабилизатора курсовой камеры",
    "tr": "FPV gimbal ESC arızası",
    "zh": "FPV云台电调故障"
  },
  "fpv_tip_0x1D050001": {
    "ar": "",
    "de": "Gimbal  %component_index Rollachsen-Endpunkt erreicht",
    "en": "Gimbal %component_index roll axis endpoint reached",
    "es": "Se ha alcanzado el límite del eje de rotación del estabilizador %component_index",
    "fr": "Limite axe de roulis de la nacelle  %component_index atteinte",
    "id": "",
    "it": "",
    "ja": "ジンバル%component_indexのロール軸のエンドポイントに達しました",
    "ko": "짐벌  %component_index 롤 축 엔드포엔트 도달",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Достигнут предел оси крена стабилизатора  %component_index",
    "th": "",
    "tr": "Gimbal %component_index dönme ekseni bitiş noktasına ulaştı",
    "ug": "",
    "vi": "",
    "zh": "云台%component_index横滚已达限位",
    "zh-Hant": ""
  },
  "fpv_tip_0x1D050002": {
    "de": "Gimbal  %component_index: Neigungsachsen-Endpunkt erreicht",
    "en": "Gimbal %component_index pitch axis endpoint reached",
    "es": "Se ha alcanzado el límite del eje de inclinación del estabilizador %component_index",
    "fr": "Limite axe vertical de la nacelle  %component_index atteinte",
    "ja": "ジンバル%component_indexピッチ軸のエンドポイントに達しました",
    "ko": "짐벌  %component_index 피치 축 엔드포인트 도달",
    "ru": "Достигнут предел оси наклона стабилизатора  %component_index",
    "tr": "Gimbal %component_index yükselme ekseni bitiş noktasına ulaştı",
    "zh": "云台%component_index俯仰已达限位"
  },
  "fpv_tip_0x1D050003": {
    "de": "Gimbal  %component_index: Gierachsen-Endpunkt erreicht",
    "en": "Gimbal %component_index pan axis endpoint reached",
    "es": "Se ha alcanzado el límite del eje de paneo del estabilizador %component_index",
    "fr": "Limite axe pano de la nacelle  %component_index atteinte",
    "ja": "ジンバル%component_indexパン軸のエンドポイントに達しました",
    "ko": "짐벌  %component_index 요 축 엔드포인트 도달",
    "ru": "Достигнут предел оси поворота стабилизатора  %component_index",
    "tr": "Gimbal %component_index yana dönüş ekseni bitiş noktasına ulaştı",
    "zh": "云台%component_index偏航已达限位"
  },
  "fpv_tip_0x1D050301": {
    "de": "Max. Versuchsanzahl für Gimbal-Bedienung überschritten",
    "en": "Gimbal operation retry attempts limit exceeded",
    "es": "Se ha superado el límite de reintentos de operación del estabilizador",
    "fr": "Limite de nouvelles tentatives dépassée pour l\\'opération de nacelle",
    "it": "",
    "ja": "ジンバル操作の再試行上限に達しました",
    "ko": "짐벌 작업 재시도 횟수 한도 초과",
    "pt": "",
    "ru": "Количество повторных попыток использования стабилизатора превысило предел",
    "tr": "Gimbal işlemi yeniden deneme sınırı aşıldı",
    "zh": "云台重试次数过多"
  },
  "fpv_tip_0x1D050302": {
    "de": "Fehler: Kamerabelichtung",
    "en": "Camera exposure error",
    "es": "Error de exposición de la cámara",
    "fr": "Erreur d\\'exposition de la caméra",
    "it": "",
    "ja": "カメラ露出エラー",
    "ko": "카메라 노출 오류",
    "pt": "",
    "ru": "Ошибка выдержки камеры",
    "tr": "Kamera pozlama hatası",
    "zh": "相机曝光超时"
  },
  "fpv_tip_0x1D05030F": {
    "de": "Gimbal-Rotationsanzahl nähert sich max. Limit",
    "en": "Gimbal rotation count approaching max limit",
    "es": "Recuento de revoluciones del estabilizador cercano al límite máximo",
    "fr": "Nombre de rotations de la nacelle proche de la limite maximale",
    "it": "",
    "ja": "ジンバルの回転数が最大値に近づいています",
    "ko": "짐벌 회전 수가 최대 한도에 근접함",
    "pt": "",
    "ru": "Количество оборотов стабилизатора приближается к максимальному пределу",
    "tr": "Gimbal dönüş sayısı maksimum sınıra yaklaşıyor",
    "zh": "云台三轴转动次数接近使用寿命"
  },
  "fpv_tip_0x1D050A01": {
    "de": "Gimbal-Spannung zu niedrig",
    "en": "Gimbal voltage too low",
    "es": "Voltaje del estabilizador demasiado bajo",
    "fr": "Tension de la nacelle trop basse",
    "it": "",
    "ja": "ジンバル低電圧",
    "ko": "짐벌 전압 너무 낮음",
    "pt": "",
    "ru": "Слишком низкое напряжение стабилизатора",
    "tr": "Gimbal gerilimi çok düşük",
    "zh": "云台电压过低"
  },
  "fpv_tip_0x1D050A02": {
    "de": "Fehler: Zeitsynchronisierung des Gimbals",
    "en": "Gimbal time synchronization error",
    "es": "Error de sincronización de la hora del estabilizador",
    "fr": "Erreur de synchronisation de l\\'heure de la nacelle",
    "it": "",
    "ja": "ジンバルの時間同期エラー",
    "ko": "짐벌 시간 동기화 오류",
    "pt": "",
    "ru": "Ошибка синхронизации времени стабилизатора",
    "tr": "Gimbal saati senkronizasyon hatası",
    "zh": "云台时间同步功能异常"
  },
  "fpv_tip_0x1D050C01": {
    "de": "FPV-Gimbal-Limit erreicht",
    "en": "FPV gimbal limit reached",
    "es": "Límite de estabilizador FPV alcanzado",
    "fr": "Limite nacelle FPV atteinte",
    "ja": "FPVジンバルリミットに達しました",
    "ko": "FPV 짐벌 한계점 도달",
    "ru": "Достигнут предел стабилизатора курсовой камеры",
    "tr": "FPV gimbal sınırına ulaşıldı",
    "zh": "FPV云台已达限位"
  },
  "fpv_tip_0x1D050C02": {
    "de": "Neigungsgrenze FPV-Gimbal erreicht",
    "en": "FPV gimbal tilt limit reached",
    "es": "Límite de inclinación del estabilizador FPV alcanzado",
    "fr": "Limite d'inclinaison de la nacelle FPV atteinte",
    "ja": "チルトがFPVジンバルリミットに達しました",
    "ko": "FPV 짐벌 틸트 한계점 도달",
    "ru": "Достигнут предел наклона стабилизатора курсовой камеры",
    "tr": "FPV gimbal eğilme sınırına ulaşıldı",
    "zh": "FPV云台俯仰已达限位"
  },
  "fpv_tip_0x1D050C03": {
    "de": "Schwenkgrenze FPV-Gimbal erreicht",
    "en": "FPV gimbal pan limit reached",
    "es": "Límite de paneo del estabilizador FPV alcanzado",
    "fr": "Limite panoramique de la nacelle FPV atteinte",
    "ja": "パンがFPVジンバルリミットに達しました",
    "ko": "FPV 짐벌 팬 한계점 도달",
    "ru": "Достигнут предел панорамирования стабилизатора курсовой камеры",
    "tr": "FPV gimbal pan sınırına ulaşıldı",
    "zh": "FPV云台偏航已达限位"
  },
  "fpv_tip_0x1D0C0002": {
    "de": "Gimbal-Fehler: IMU",
    "en": "Gimbal IMU Error",
    "es": "Error de IMU del estabilizador",
    "fr": "Erreur de l'IMU nacelle",
    "ja": "ジンバルのIMUエラー",
    "ko": "짐벌 IMU 오류",
    "ru": "Ошибка IMU гиростабилизатора",
    "tr": "Gimbal IMU hatası",
    "zh": "云台IMU异常"
  },
  "fpv_tip_0x1D0C0003": {
    "de": "Fehler: Gimbal-Initialisierung",
    "en": "Gimbal Initialization Error",
    "es": "Error de inicio del estabilizador",
    "fr": "Erreur d'initialisation nacelle",
    "ja": "ジンバルの初期化エラー",
    "ko": "짐벌 초기화 오류",
    "ru": "Ошибка инициализации гиростабилизатора",
    "tr": "Gimbal Başlatma Hatası",
    "zh": "云台初始化异常"
  },
  "fpv_tip_0x1D0C0004": {
    "de": "Fehler: Gimbal-Initialisierung",
    "en": "Gimbal Initialization Error",
    "es": "Error de inicio del estabilizador",
    "fr": "Erreur d'initialisation nacelle",
    "ja": "ジンバルの初期化エラー",
    "ko": "짐벌 초기화 오류",
    "ru": "Ошибка инициализации гиростабилизатора",
    "tr": "Gimbal Başlatma Hatası",
    "zh": "云台初始化异常"
  },
  "fpv_tip_0x1D0C0005": {
    "de": "Gimbal-Spannungsfehler. Durch DJI-Akku ersetzen",
    "en": "Gimbal voltage error. Replace with DJI battery",
    "es": "Error de voltaje del estabilizador. Sustituir por una batería DJI",
    "fr": "Erreur de tension de la nacelle. Remplacer la batterie par une batterie DJI",
    "ja": "ジンバルの電圧エラー。DJIバッテリーに交換してください",
    "ko": "짐벌 전압 오류. DJI 배터리로 교체하세요",
    "ru": "Ошибка напряжения гиростабилизатора. Замените аккумуляторной батареей DJI",
    "tr": "Gimbal voltaj hatası. DJI pille değiştirin",
    "zh": "云台电源电压异常，请使用官方电池"
  },
  "fpv_tip_0x1D0C000B": {
    "de": "Gimbal Motor-Temperatur zu hoch",
    "en": "Gimbal Motor Temperature Too High",
    "es": "Temperatura del motor del estabilizador demasiado alta",
    "fr": "Température moteur de nacelle trop élevée",
    "ja": "ジンバルモーターの温度が高すぎます",
    "ko": "짐벌 모터 온도 너무 높음",
    "ru": "Слишком высокая температура двигателя гиростабилизатора",
    "tr": "Gimbal Motor Sıcaklığı Çok Yüksek",
    "zh": "云台电机温度过高"
  },
  "fpv_tip_0x1D0C000E": {
    "de": "Objektiv nicht ordnungsgemäß befestigt. Objektiv erneut anbringen und Hebel in die Verriegelungsstellung bringen",
    "en": "Lens not firmly attached. Reattach lens and toggle lever to the locked position",
    "es": "El objetivo no está bien sujeto. Vuelva a colocar el objetivo y la palanca acodada en la posición de bloqueo",
    "fr": "L’objectif n’est pas correctement fixé. Rattachez l’objectif et basculez le levier en position verrouillée",
    "ja": "レンズがしっかりと取り付けられていません。レンズを取り付け直し、レバーをロック位置に切り替えてください",
    "ko": "렌즈가 단단히 부착되지 않았습니다. 렌즈를 다시 부착하고 레버를 잠금 위치로 전환하세요",
    "ru": "Объектив не установлен должным образом. Снова установите объектив и переключите рычаг в заблокированное положение",
    "tr": "Lens sıkıca takılı değil. Lensi yeniden takın ve kolu kilitli konuma getirin",
    "zh": "镜头未安装紧固，请取下重装，并拧紧扳扣"
  },
  "fpv_tip_0x1D0C000F": {
    "de": "Gimbal nicht ausbalanciert. Darauf achten, dass Objektivschutz/ND-Filter, Gegenlichtblende und Gegengewicht ordnungsgemäß installiert sind",
    "en": "Gimbal not balanced. Make sure lens protector/ND filter, hood, and balancing weight are properly installed",
    "es": "Estabilizador no equilibrado. Asegúrate de que el protector del objetivo/filtro ND, el parasol y el peso equilibrado estén montados correctamente",
    "fr": "Nacelle non équilibrée. Assurez-vous que la protection d’objectif/le filtre ND, le pare-soleil et le poids d’équilibrage sont correctement installés",
    "ja": "ジンバルのバランスが取れていない。レンズプロテクター／NDフィルター、フード、およびバランスウェイトが正しく取り付けられていることを確認してください",
    "ko": "짐벌 균형 안 맞음. 렌즈 보호대/ND 필터, 후드, 무게 균형 조정이 제대로 설치되었는지 확인합니다",
    "ru": "Стабилизатор не сбалансирован. Убедитесь, что защита объектива / фильтр нейтральной плотности, бленда и балансировочный груз установлены правильно",
    "tr": "Gimbal dengeli değil. Lens koruyucusunun/ND filtresinin, güneşliğin ve denge ağırlığının doğru şekilde takıldığından emin olun",
    "zh": "云台未配平，请正确安装保护镜/ND镜、遮光罩以及配重"
  },
  "fpv_tip_0x1D0C0C02": {
    "de": "IMU-Fehler FPV-Gimbal",
    "en": "FPV gimbal IMU error",
    "es": "Error de IMU del estabilizador FPV",
    "fr": "Erreur de l'IMU de la nacelle FPV",
    "ja": "FPVジンバルIMUエラー",
    "ko": "짐벌 짐벌 IMU 오류",
    "ru": "Ошибка IMU стабилизатора курсовой камеры",
    "tr": "FPV gimbal IMU hatası",
    "zh": "FPV云台IMU异常"
  },
  "fpv_tip_0x1D0C0C03": {
    "de": "Initialisierungsfehler FPV-Gimbal",
    "en": "FPV gimbal initialization error",
    "es": "Error de inicialización del estabilizador FPV",
    "fr": "Erreur d'initialisation de la nacelle FPV",
    "ja": "FPVジンバル初期化エラー",
    "ko": "FPV 짐벌 초기화 오류",
    "ru": "Ошибка инициализации стабилизатора курсовой камеры",
    "tr": "FPV gimbal başlatma hatası",
    "zh": "FPV云台初始化异常"
  },
  "fpv_tip_0x1D0C0C04": {
    "de": "Initialisierungsfehler FPV-Gimbal",
    "en": "FPV gimbal initialization error",
    "es": "Error de inicialización del estabilizador FPV",
    "fr": "FPV gimbal initialization error",
    "ja": "FPVジンバル初期化エラー",
    "ko": "FPV 짐벌 초기화 오류",
    "ru": "Ошибка инициализации стабилизатора курсовой камеры",
    "tr": "FPV gimbal başlatma hatası",
    "zh": "FPV云台初始化异常"
  },
  "fpv_tip_0x1D0C0C05": {
    "de": "Spannungsfehler FPV-Gimbal. Durch DJI-Akku ersetzen",
    "en": "FPV gimbal voltage error. Replace with DJI battery",
    "es": "Error de voltaje del estabilizador FPV. Sustituir por una batería DJI",
    "fr": "Erreur de tension de la nacelle FPV. Remplacer la batterie par une batterie DJI",
    "ja": "FPVジンバル電圧エラーです。DJIバッテリーに交換してください",
    "ko": "FPV 짐벌 전압 오류. DJI 배터리로 교체하세요",
    "ru": "Ошибка напряжения стабилизатора курсовой камеры. Замените аккумуляторной батареей DJI",
    "tr": "FPV gimbal voltaj hatası. DJI pille değiştirin",
    "zh": "FPV云台电源电压异常，请使用官方电池"
  },
  "fpv_tip_0x1D0C0C0B": {
    "de": "Motor FPV-Gimbal überhitzt",
    "en": "FPV gimbal motor overheated",
    "es": "Motor del estabilizador FPV sobrecalentado",
    "fr": "Surchauffe du moteur de nacelle FPV",
    "ja": "FPVジンバルモーターがオーバーヒートしました",
    "ko": "FPV 짐벌 모터 과부하",
    "ru": "Перегрев двигателя стабилизатора курсовой камеры",
    "tr": "FPV gimbal motoru aşırı ısındı",
    "zh": "FPV云台电机温度过高"
  },
  "fpv_tip_0x1D0E0001": {
    "de": "Fehler: Gimbal-Initialisierung",
    "en": "Gimbal Initialization Error",
    "es": "Error de inicio del estabilizador",
    "fr": "Erreur d'initialisation nacelle",
    "ja": "ジンバルの初期化エラー",
    "ko": "짐벌 초기화 오류",
    "ru": "Ошибка инициализации гиростабилизатора",
    "tr": "Gimbal Başlatma Hatası",
    "zh": "云台初始化异常"
  },
  "fpv_tip_0x1D0E0C01": {
    "de": "Initialisierungsfehler FPV-Gimbal",
    "en": "FPV gimbal initialization error",
    "es": "Error de inicialización del estabilizador FPV",
    "fr": "FPV gimbal initialization error",
    "ja": "FPVジンバル初期化エラー",
    "ko": "FPV 짐벌 초기화 오류",
    "ru": "Ошибка инициализации стабилизатора курсовой камеры",
    "tr": "FPV gimbal başlatma hatası",
    "zh": "FPV云台初始化异常"
  },
  "fpv_tip_0x1D100002": {
    "de": "Gimbal reagiert nicht nach dem Einschalten",
    "en": "Gimbal unresponsive after power on",
    "es": "El estabilizador no responde después del encendido",
    "fr": "Pas de réponse de la nacelle après la mise sous tension",
    "ja": "電源オンの後にジンバル反応なし",
    "ko": "전원을 켠 후 짐벌이 응답하지 않음",
    "ru": "Стабилизатор не отвечает после включения питания",
    "tr": "Gimbal açıldıktan sonra yanıt vermiyor",
    "zh": "云台上电后无力"
  },
  "fpv_tip_0x1D100006": {
    "de": "Gimbal reagiert nicht nach dem Einschalten",
    "en": "Gimbal unresponsive after power on",
    "es": "El estabilizador no responde después del encendido",
    "fr": "Pas de réponse de la nacelle après la mise sous tension",
    "ja": "電源オンの後にジンバル反応なし",
    "ko": "전원을 켠 후 짐벌이 응답하지 않음",
    "ru": "Стабилизатор не отвечает после включения питания",
    "tr": "Gimbal açıldıktan sonra yanıt vermiyor",
    "zh": "云台上电后无力"
  },
  "fpv_tip_0x1D110002": {
    "de": "Gimbal kann automatische Überprüfung nicht abschließen",
    "en": "Gimbal unable to complete auto check",
    "es": "El estabilizador no puede completar la comprobación automática",
    "fr": "Nacelle incapable de terminer la vérification automatique",
    "ja": "ジンバルの自動確認を完了できません",
    "ko": "짐벌 자동 점검 완료 불가",
    "ru": "Не удалось выполнить автоматическую проверку стабилизатора",
    "tr": "Gimbal otomatik kontrolü tamamlanamıyor",
    "zh": "云台无法完成自检"
  },
  "fpv_tip_0x1D110005": {
    "de": "Gimbal kann automatische Überprüfung nicht abschließen",
    "en": "Gimbal unable to complete auto check",
    "es": "El estabilizador no puede completar la comprobación automática",
    "fr": "Nacelle incapable de terminer la vérification automatique",
    "ja": "ジンバルの自動確認を完了できません",
    "ko": "짐벌 자동 점검 완료 불가",
    "ru": "Не удалось выполнить автоматическую проверку стабилизатора",
    "tr": "Gimbal otomatik kontrolü tamamlanamıyor",
    "zh": "云台无法完成自检"
  },
  "fpv_tip_0x1D120001": {
    "de": "Fehler bei automatischer Gimbal-Überprüfung erkannt",
    "en": "Error detected during gimbal auto check",
    "es": "Error detectado durante la comprobación automática del estabilizador",
    "fr": "Erreur détectée lors de la vérification automatique de la nacelle",
    "ja": "ジンバルの自動確認中にエラー検出",
    "ko": "짐벌 자동 점검 중 오류 감지",
    "ru": "Во время автоматической проверки стабилизатора обнаружена ошибка",
    "tr": "Gimbal otomatik kontrolü sırasında hata tespit edildi",
    "zh": "云台自检后异常"
  },
  "fpv_tip_0x1D120002": {
    "de": "Fehler bei automatischer Gimbal-Überprüfung erkannt",
    "en": "Error detected during gimbal auto check",
    "es": "Error detectado durante la comprobación automática del estabilizador",
    "fr": "Erreur détectée lors de la vérification automatique de la nacelle",
    "ja": "ジンバルの自動確認中にエラー検出",
    "ko": "짐벌 자동 점검 중 오류 감지",
    "ru": "Во время автоматической проверки стабилизатора обнаружена ошибка",
    "tr": "Gimbal otomatik kontrolü sırasında hata tespit edildi",
    "zh": "云台自检后异常"
  },
  "fpv_tip_0x1D120004": {
    "de": "Fehler bei automatischer Gimbal-Überprüfung erkannt",
    "en": "Error detected during gimbal auto check",
    "es": "Error detectado durante la comprobación automática del estabilizador",
    "fr": "Erreur détectée lors de la vérification automatique de la nacelle",
    "ja": "ジンバルの自動確認中にエラー検出",
    "ko": "짐벌 자동 점검 중 오류 감지",
    "ru": "Во время автоматической проверки стабилизатора обнаружена ошибка",
    "tr": "Gimbal otomatik kontrolü sırasında hata tespit edildi",
    "zh": "云台自检后异常"
  },
  "fpv_tip_0x1D120006": {
    "de": "Fehler bei automatischer Gimbal-Überprüfung erkannt",
    "en": "Error detected during gimbal auto check",
    "es": "Error detectado durante la comprobación automática del estabilizador",
    "fr": "Erreur détectée lors de la vérification automatique de la nacelle",
    "ja": "ジンバルの自動確認中にエラー検出",
    "ko": "짐벌 자동 점검 중 오류 감지",
    "ru": "Во время автоматической проверки стабилизатора обнаружена ошибка",
    "tr": "Gimbal otomatik kontrolü sırasında hata tespit edildi",
    "zh": "云台自检后异常"
  },
  "fpv_tip_0x1D130003": {
    "de": "Übermäßige Vibrationen am Gimbal",
    "en": "Excessive gimbal vibration",
    "es": "Vibración del estabilizador excesiva",
    "fr": "Vibration excessive de la nacelle",
    "ja": "ジンバルの過剰な振動",
    "ko": "과도한 짐벌 진동",
    "ru": "Чрезмерная вибрация стабилизатора",
    "tr": "Gimbal aşırı titriyor",
    "zh": "云台异常振动"
  },
  "fpv_tip_0x1D130005": {
    "de": "Übermäßige Vibrationen am Gimbal",
    "en": "Excessive gimbal vibration",
    "es": "Vibración del estabilizador excesiva",
    "fr": "Vibration excessive de la nacelle",
    "ja": "ジンバルの過剰な振動",
    "ko": "과도한 짐벌 진동",
    "ru": "Чрезмерная вибрация стабилизатора",
    "tr": "Gimbal aşırı titriyor",
    "zh": "云台异常振动"
  },
  "fpv_tip_0x1D13000A": {
    "de": "Übermäßige Vibrationen am Gimbal",
    "en": "Excessive gimbal vibration",
    "es": "Vibración del estabilizador excesiva",
    "fr": "Vibration excessive de la nacelle",
    "ja": "ジンバルの過剰な振動",
    "ko": "과도한 짐벌 진동",
    "ru": "Чрезмерная вибрация стабилизатора",
    "tr": "Gimbal aşırı titriyor",
    "zh": "云台异常振动"
  },
  "fpv_tip_0x1D140001": {
    "de": "Gimbal-Motor überlastet",
    "en": "Gimbal motor overloaded",
    "es": "Motor del estabilizador sobrecargado",
    "fr": "Surcharge du moteur de nacelle",
    "ja": "ジンバルモーター過負荷状態",
    "ko": "짐벌 모터 과부하",
    "ru": "Двигатель стабилизатора перегружен",
    "tr": "Gimbal motoru aşırı yüklü",
    "zh": "云台电机过载"
  },
  "fpv_tip_0x1D150002": {
    "de": "Abdriften von Gimbal",
    "en": "Gimbal drifting",
    "es": "Desplazamiento del estabilizador",
    "fr": "Dérive de la nacelle",
    "ja": "ジンバルドリフト発生",
    "ko": "짐벌 드리프트",
    "ru": "Стабилизатор смещается",
    "tr": "Gimbal kayıyor",
    "zh": "云台自动漂移"
  },
  "fpv_tip_0x1D150003": {
    "de": "Abdriften von Gimbal",
    "en": "Gimbal drifting",
    "es": "Desplazamiento del estabilizador",
    "fr": "Dérive de la nacelle",
    "ja": "ジンバルドリフト発生",
    "ko": "짐벌 드리프트",
    "ru": "Стабилизатор смещается",
    "tr": "Gimbal kayıyor",
    "zh": "云台自动漂移"
  },
  "fpv_tip_0x1D150004": {
    "de": "Abdriften von Gimbal",
    "en": "Gimbal drifting",
    "es": "Desplazamiento del estabilizador",
    "fr": "Dérive de la nacelle",
    "ja": "ジンバルドリフト発生",
    "ko": "짐벌 드리프트",
    "ru": "Стабилизатор смещается",
    "tr": "Gimbal kayıyor",
    "zh": "云台自动漂移"
  },
  "fpv_tip_0x1D150005": {
    "de": "Abdriften von Gimbal",
    "en": "Gimbal drifting",
    "es": "Desplazamiento del estabilizador",
    "fr": "Dérive de la nacelle",
    "ja": "ジンバルドリフト発生",
    "ko": "짐벌 드리프트",
    "ru": "Стабилизатор смещается",
    "tr": "Gimbal kayıyor",
    "zh": "云台自动漂移"
  },
  "fpv_tip_0x1D160004": {
    "de": "Kamera geneigt",
    "en": "Camera tilted",
    "es": "Cámara inclinada",
    "fr": "Caméra inclinée",
    "ja": "カメラが傾いています",
    "ko": "카메라 기울어짐",
    "ru": "Камера наклонена",
    "tr": "Kamera eğik",
    "zh": "相机视频歪斜"
  },
  "fpv_tip_0x1D170001": {
    "de": "Gimbal-Fehler",
    "en": "Gimbal error",
    "es": "Error del estabilizador",
    "fr": "Erreur de nacelle",
    "ja": "ジンバルエラー",
    "ko": "짐벌 오류",
    "ru": "Ошибка стабилизатора",
    "tr": "Gimbal hatası",
    "zh": "云台功能异常"
  },
  "fpv_tip_0x1D180001": {
    "de": "Datenfehler bei Gimbal-Kalibrierung",
    "en": "Gimbal calibration data error",
    "es": "Error de datos de calibración del estabilizador",
    "fr": "Erreur de données d\\'étalonnage de la nacelle",
    "ja": "ジンバル キャリブレーション データ エラー",
    "ko": "짐벌 캘리브레이션 데이터 오류",
    "ru": "Ошибка данных калибровки стабилизатора",
    "tr": "Gimbal kalibrasyon verisi hatası",
    "zh": "云台校准数据异常"
  },
  "fpv_tip_0x1D190002": {
    "de": "Gimbal-Aktualisierung fehlgeschlagen",
    "en": "Gimbal update failed",
    "es": "Error de actualización del estabilizador",
    "fr": "Échec de la mise à jour de la nacelle",
    "ja": "ジンバルの更新に失敗しました",
    "ko": "짐벌 업데이트 실패",
    "ru": "Не удалось обновить стабилизатор",
    "tr": "Gimbal güncellemesi başarısız",
    "zh": "云台升级失败"
  },
  "fpv_tip_0x1E00000": {
    "en": "",
    "zh": "喊话器与机身发生共振，供电已断开，请等待自行恢复"
  },
  "fpv_tip_0x1E000001": {
    "de": "Startfehler: Nutzlast %component_index",
    "en": "Payload %component_index startup error",
    "es": "Error de inicio del instrumento %component_index",
    "fr": "Erreur démarrage charge utile %component_index",
    "ja": "ペイロード%component_index開始エラー",
    "ko": "페이로드 %component_index 시작 오류",
    "ru": "Ошибка запуска оборудования %component_index",
    "tr": "Yük %component_index başlatma hatası",
    "zh": "%component_index号负载启动异常"
  },
  "fpv_tip_0x1E000002": {
    "de": "Kommunikationsfehler: Nutzlast %component_index",
    "en": "Payload %component_index communication error",
    "es": "Error de comunicación del instrumento %component_index",
    "fr": "Erreur communication charge utile %component_index",
    "ja": "ペイロード%component_index通信エラー",
    "ko": "페이로드 %component_index 통신 오류",
    "ru": "Ошибка связи оборудования %component_index",
    "tr": "Yük %component_index iletişim hatası",
    "zh": "%component_index号负载通讯异常"
  },
  "fpv_tip_0x1E000003": {
    "de": "Nutzlast %component_index überhitzt",
    "en": "Payload %component_index overheated",
    "es": "Instrumento %component_index sobrecalentado",
    "fr": "Surchauffe charge utile %component_index",
    "ja": "ペイロード%component_index高温",
    "ko": "페이로드 %component_index 과열",
    "ru": "Перегрев оборудования %component_index",
    "tr": "Yük %component_index aşırı ısındı",
    "zh": "%component_index号负载温度过高"
  },
  "fpv_tip_0x1E000004": {
    "de": "Hardwarefehler: Nutzlast %component_index",
    "en": "Payload %component_index hardware error",
    "es": "Error de hardware del instrumento %component_index",
    "fr": "Erreur matérielle charge utile %component_index",
    "ja": "ペイロード%component_indexハードウェアエラー",
    "ko": "페이로드 %component_index 하드웨어 오류",
    "ru": "Ошибка аппаратуры оборудования %component_index",
    "tr": "Yük %component_index donanım hatası",
    "zh": "%component_index号负载硬件异常"
  },
  "fpv_tip_0x1E000006": {
    "de": "Lautsprecher schwingt mit dem Fluggerät mit. Stromversorgung unterbrochen. Auf Wiederherstellung der Stromversorgung warten",
    "en": "Speaker resonates with the aircraft. Power supply disconnected. Wait for power supply to recover",
    "es": "El altavoz resuena con la aeronave. Fuente de alimentación desconectada. Espere a que se recupere la fuente de alimentación",
    "fr": "Le haut-parleur résonne avec l’appareil. Alimentation déconnectée. Attendez que l’alimentation se rétablisse",
    "ja": "スピーカーが機体と共鳴しています。電源接続が切断されました。電源が回復するまで待機してください",
    "ko": "스피커가 기체와 공명합니다. 전력 공급 장치가 분리되었습니다. 전력 공급 장치가 복구될 때까지 기다리세요",
    "ru": "Динамик резонирует с дроном. Питание отключено. Дождитесь восстановления питания",
    "tr": "Hoparlör hava aracıyla rezonansa giriyor. Güç kaynağı bağlantısı kesildi. Güç kaynağının düzeltilmesini bekleyin",
    "zh": "喊话器与机身发生共振，供电已断开，请等待自行恢复"
  },
  "fpv_tip_0x1E010001": {
    "de": "Lautsprecher überhitzt. Übermittlungslautstärke reduzieren oder warten, bis Lautsprecher abgekühlt ist",
    "en": "Speaker overheats. Lower broadcast volume or wait until speaker cools down",
    "es": "El altavoz se sobrecalienta. Reduce el volumen o espera a que el altavoz se enfríe.",
    "fr": "Surchauffe du haut-parleur. Diminuez le volume de diffusion ou attendez que le haut-parleur refroidisse",
    "ja": "スピーカーが過熱状態。放送音声の音量を下げるか、スピーカーが冷えるまでお待ちください",
    "ko": "스피커 과열. 방송 볼륨을 낮추거나 스피커가 식을 때까지 기다리세요.",
    "ru": "Динамик перегревается. Уменьшите громкость вещания или подождите, пока динамик остынет",
    "tr": "Hoparlör aşırı ısındı. Yayının ses seviyesini düşürün veya hoparlör soğuyana kadar bekleyin",
    "zh": "喊话器温度过高，请尝试降低音量或冷却后再使用"
  },
  "fpv_tip_0x1E010002": {
    "ar": "",
    "de": "Hardwarefehler: Lautsprecher. Fluggerät neu starten",
    "en": "Speaker hardware error. Contact DJI Support",
    "es": "Error de hardware del altavoz. Reinicia la aeronave.",
    "fr": "Erreur matérielle du haut-parleur. Redémarrez l'appareil",
    "it": "",
    "ja": "スピーカーのハードウェアエラー。機体を再起動してください",
    "ko": "스피커 하드웨어 오류. 기체를 재시작하세요.",
    "pt": "",
    "ru": "Аппаратная ошибка динамика. Перезапустите дрон",
    "tr": "Hoparlör donanım hatası. Hava aracını yeniden başlatın",
    "zh": "喊话器硬件异常，请联系售后服务"
  },
  "fpv_tip_0x1E010003": {
    "de": "Lautsprecher überlastet. Fluggerät neu starten",
    "en": "Speaker overloaded. Restart aircraft",
    "es": "Altavoz sobrecargado. Reinicia la aeronave.",
    "fr": "Haut-parleur surchargé. Redémarrez l'appareil",
    "ja": "スピーカーが過負荷状態。機体を再起動してください",
    "ko": "스피커 과부하. 기체를 재시작하세요.",
    "ru": "Динамик перегружен. Перезапустите дрон",
    "tr": "Hoparlör aşırı yüklendi. Hava aracını yeniden başlatın",
    "zh": "喊话器过载，请重启飞行器"
  },
  "fpv_tip_0x1F010000": {
    "de": "4G service: unable to obtain license. Contact DJI Support",
    "en": "4G service: unable to obtain license. Contact DJI Support",
    "es": "4G service: unable to obtain license. Contact DJI Support",
    "fr": "4G service: unable to obtain license. Contact DJI Support",
    "ja": "4G service: unable to obtain license. Contact DJI Support",
    "ko": "4G service: unable to obtain license. Contact DJI Support",
    "ru": "4G service: unable to obtain license. Contact DJI Support",
    "tr": "4G service: unable to obtain license. Contact DJI Support",
    "zh": "4G服务：License无法获取，请联系DJI售后服务"
  },
  "fpv_tip_0x1F010001": {
    "de": "4G service: license expired. Import valid license",
    "en": "4G service: license expired. Import valid license",
    "es": "4G service: license expired. Import valid license",
    "fr": "4G service: license expired. Import valid license",
    "ja": "4G service: license expired. Import valid license",
    "ko": "4G service: license expired. Import valid license",
    "ru": "4G service: license expired. Import valid license",
    "tr": "4G service: license expired. Import valid license",
    "zh": "4G服务：License过期，请导入有效证书"
  },
  "fpv_tip_0x1F010002": {
    "de": "4G service: license verification failed. Contact DJI Support",
    "en": "4G service: license verification failed. Contact DJI Support",
    "es": "4G service: license verification failed. Contact DJI Support",
    "fr": "4G service: license verification failed. Contact DJI Support",
    "ja": "4G service: license verification failed. Contact DJI Support",
    "ko": "4G service: license verification failed. Contact DJI Support",
    "ru": "4G service: license verification failed. Contact DJI Support",
    "tr": "4G service: license verification failed. Contact DJI Support",
    "zh": "4G服务：License校验失败，请联系DJI售后服务"
  },
  "fpv_tip_0x1F010003": {
    "de": "4G service: device SN unauthorized. Authorize device SN",
    "en": "4G service: device SN unauthorized. Authorize device SN",
    "es": "4G service: device SN unauthorized. Authorize device SN",
    "fr": "4G service: device SN unauthorized. Authorize device SN",
    "ja": "4G service: device SN unauthorized. Authorize device SN",
    "ko": "4G service: device SN unauthorized. Authorize device SN",
    "ru": "4G service: device SN unauthorized. Authorize device SN",
    "tr": "4G service: device SN unauthorized. Authorize device SN",
    "zh": "4G服务：设备SN未授权，请授权设备SN"
  },
  "fpv_tip_0x1F010004": {
    "de": "4G service: device SN unauthorized. Purchase upgrade package",
    "en": "4G service: device SN unauthorized. Purchase upgrade package",
    "es": "4G service: device SN unauthorized. Purchase upgrade package",
    "fr": "4G service: device SN unauthorized. Purchase upgrade package",
    "ja": "4G service: device SN unauthorized. Purchase upgrade package",
    "ko": "4G service: device SN unauthorized. Purchase upgrade package",
    "ru": "4G service: device SN unauthorized. Purchase upgrade package",
    "tr": "4G service: device SN unauthorized. Purchase upgrade package",
    "zh": "4G服务：设备型号未授权，请购买扩容套餐"
  },
  "fpv_tip_0x1F010005": {
    "de": "4G service: the number of connected devices exceeds authorized number. Purchase upgrade package",
    "en": "4G service: the number of connected devices exceeds authorized number. Purchase upgrade package",
    "es": "4G service: the number of connected devices exceeds authorized number. Purchase upgrade package",
    "fr": "4G service: the number of connected devices exceeds authorized number. Purchase upgrade package",
    "ja": "4G service: the number of connected devices exceeds authorized number. Purchase upgrade package",
    "ko": "4G service: the number of connected devices exceeds authorized number. Purchase upgrade package",
    "ru": "4G service: the number of connected devices exceeds authorized number. Purchase upgrade package",
    "tr": "4G service: the number of connected devices exceeds authorized number. Purchase upgrade package",
    "zh": "4G服务：设备连接数量超过授权，请购买扩容套餐"
  },
  "fpv_tip_0x1F010006": {
    "de": "4G service: device firmware version too early. Upgrade firmware",
    "en": "4G service: device firmware version too early. Upgrade firmware",
    "es": "4G service: device firmware version too early. Upgrade firmware",
    "fr": "4G service: device firmware version too early. Upgrade firmware",
    "ja": "4G service: device firmware version too early. Upgrade firmware",
    "ko": "4G service: device firmware version too early. Upgrade firmware",
    "ru": "4G service: device firmware version too early. Upgrade firmware",
    "tr": "4G service: device firmware version too early. Upgrade firmware",
    "zh": "4G服务：设备固件版本过低，请升级固件"
  },
  "fpv_tip_0x1F010007": {
    "de": "4G service: server version too early. Upgrade server",
    "en": "4G service: server version too early. Upgrade server",
    "es": "4G service: server version too early. Upgrade server",
    "fr": "4G service: server version too early. Upgrade server",
    "ja": "4G service: server version too early. Upgrade server",
    "ko": "4G service: server version too early. Upgrade server",
    "ru": "4G service: server version too early. Upgrade server",
    "tr": "4G service: server version too early. Upgrade server",
    "zh": "4G服务：服务器版本过低，请升级服务器"
  },
  "fpv_tip_0x1F010008": {
    "de": "4G service: requested parameter error. Contact DJI Support",
    "en": "4G service: requested parameter error. Contact DJI Support",
    "es": "4G service: requested parameter error. Contact DJI Support",
    "fr": "4G service: requested parameter error. Contact DJI Support",
    "ja": "4G service: requested parameter error. Contact DJI Support",
    "ko": "4G service: requested parameter error. Contact DJI Support",
    "ru": "4G service: requested parameter error. Contact DJI Support",
    "tr": "4G service: requested parameter error. Contact DJI Support",
    "zh": "4G服务：请求参数错误，请联系DJI售后服务"
  },
  "fpv_tip_0x1F010009": {
    "de": "4G service: server internal error. Contact DJI Support",
    "en": "4G service: server internal error. Contact DJI Support",
    "es": "4G service: server internal error. Contact DJI Support",
    "fr": "4G service: server internal error. Contact DJI Support",
    "ja": "4G service: server internal error. Contact DJI Support",
    "ko": "4G service: server internal error. Contact DJI Support",
    "ru": "4G service: server internal error. Contact DJI Support",
    "tr": "4G service: server internal error. Contact DJI Support",
    "zh": "4G服务：服务器内部错误，请联系DJI售后服务"
  },
  "fpv_tip_0x1F01000A": {
    "de": "4G service: unable to obtain license. Contact DJI Support",
    "en": "4G service: unable to obtain license. Contact DJI Support",
    "es": "4G service: unable to obtain license. Contact DJI Support",
    "fr": "4G service: unable to obtain license. Contact DJI Support",
    "ja": "4G service: unable to obtain license. Contact DJI Support",
    "ko": "4G service: unable to obtain license. Contact DJI Support",
    "ru": "4G service: unable to obtain license. Contact DJI Support",
    "tr": "4G service: unable to obtain license. Contact DJI Support",
    "zh": "4G服务：证书无法读取，请联系DJI售后服务"
  },
  "fpv_tip_0x1F01000B": {
    "de": "4G service: license expired. Import valid license",
    "en": "4G service: license expired. Import valid license",
    "es": "4G service: license expired. Import valid license",
    "fr": "4G service: license expired. Import valid license",
    "ja": "4G service: license expired. Import valid license",
    "ko": "4G service: license expired. Import valid license",
    "ru": "4G service: license expired. Import valid license",
    "tr": "4G service: license expired. Import valid license",
    "zh": "4G服务：证书过期，请导入有效证书"
  },
  "fpv_tip_0x1F01000C": {
    "de": "4G service: license verification failed. Contact DJI Support",
    "en": "4G service: license verification failed. Contact DJI Support",
    "es": "4G service: license verification failed. Contact DJI Support",
    "fr": "4G service: license verification failed. Contact DJI Support",
    "ja": "4G service: license verification failed. Contact DJI Support",
    "ko": "4G service: license verification failed. Contact DJI Support",
    "ru": "4G service: license verification failed. Contact DJI Support",
    "tr": "4G service: license verification failed. Contact DJI Support",
    "zh": "4G服务：证书校验失败，请联系DJI售后服务"
  },
  "fpv_tip_0x1F01000D": {
    "de": "Zeitfehler im System. Stelle sicher, dass Geräte- und Serverzeit korrekt sind und versuche es erneut.",
    "en": "System time error. Make sure device and server time are correct and try again",
    "es": "Error de hora del sistema. Asegúrate de que la hora del dispositivo y del servidor son correctas e inténtalo de nuevo",
    "fr": "Erreur d’heure du système. Assurez-vous que l’heure de l’appareil et celle du serveur sont correctes et réessayez",
    "ja": "システム時刻エラーです。デバイスとサーバーの時刻が正しいかどうかを確認し、やり直してください",
    "ko": "시스템 시간 오류. 기기 및 서버 시간이 올바른지 확인하고 다시 시도하세요.",
    "ru": "Ошибка системного времени. Проверьте правильность времени на устройстве и сервере и повторите попытку",
    "tr": "Sistem zaman hatası. Cihaz ve sunucu saatinin doğru olduğundan emin olun ve tekrar deneyin",
    "zh": "系统时间错误，请确认设备和服务器时间正确后重试"
  },
  "fpv_tip_0x1F010063": {
    "de": "4G service: system unknown error. Contact DJI Support",
    "en": "4G service: system unknown error. Contact DJI Support",
    "es": "4G service: system unknown error. Contact DJI Support",
    "fr": "4G service: system unknown error. Contact DJI Support",
    "ja": "4G service: system unknown error. Contact DJI Support",
    "ko": "4G service: system unknown error. Contact DJI Support",
    "ru": "4G service: system unknown error. Contact DJI Support",
    "tr": "4G service: system unknown error. Contact DJI Support",
    "zh": "4G服务：系统未知错误，请联系DJI售后服务"
  },
  "fpv_tip_0x1F0B0001": {
    "de": "Fluggerät: LTE-Übertragung nicht möglich. Datenverbindung instabil oder mit SIM-Karte nicht möglich",
    "en": "Aircraft unable to use LTE Transmission. Network connection unstable or SIM card unable to connect to network",
    "es": "La aeronave no puede utilizar la transmisión LTE. Conexión de red inestable o la tarjeta SIM no se puede conectar a la red",
    "fr": "L\\'appareil ne peut utiliser la transmission LTE. Connexion au réseau instable ou carte SIM incapable de se connecter au réseau",
    "ja": "機体がLTE伝送を使用できません。ネットワーク接続不安定、またはSIMカードがネットワークに接続できません",
    "ko": "기체가 LTE 전송을 사용할 수 없습니다. 네트워크 연결이 불안정하거나 SIM 카드를 네트워크에 연결할 수 없습니다",
    "ru": "Дрону не удается использовать передачу по LTE. Подключение к сети нестабильно, или невозможно подключить SIM-карту к сети",
    "tr": "Araç LTE İletimini kullanamıyor. Ağ bağlantısı istikrarlı değil ya da SIM kart ağa bağlanamıyor",
    "zh": "飞机无法使用LTE链路，网络波动或SIM卡无法访问网络"
  },
  "fpv_tip_0x1F0B0002": {
    "de": "Kein Netz auf DJI Mobilfunk-Dongle der Fernsteuerung",
    "en": "No network on DJI Cellular Dongle of remote controller",
    "es": "No hay red en el adaptador de red móvil de DJI del control remoto",
    "fr": "Pas de réseau sur le dongle cellulaire DJI de la radiocommande",
    "ja": "送信機のDJIセルラードングルでネットワークを利用できません",
    "ko": "조종기 DJI 셀룰러 동글이 네트워크에 연결되어 있지 않습니다",
    "ru": "Нет сети на DJI Cellular Dongle пульта дистанционного управления",
    "tr": "Uzaktan kumandanın DJI Hücresel Dongle'ında ağ yok",
    "zh": "遥控器DJI Cellular模块无法访问网络"
  },
  "fpv_tip_0x1F0B0003": {
    "de": "LTE-Server und LTE-Übertragung nicht verfügbar",
    "en": "LTE Server and LTE Transmission unavailable",
    "es": "Servidor LTE y transmisión LTE no disponibles",
    "fr": "Serveur LTE et transmission LTE indisponibles",
    "ja": "LTEサーバやLTE伝送が利用できません。",
    "ko": "LTE 서버 및 LTE 전송을 사용할 수 없습니다",
    "ru": "Сервер LTE и передача по LTE недоступны",
    "tr": "LTE Sunucusu ve LTE İletimi kullanılamıyor",
    "zh": "LTE服务器不可用，LTE链路不可用"
  },
  "fpv_tip_0x1F0B0004": {
    "de": "LTE-Server und LTE-Übertragung nicht verfügbar",
    "en": "LTE Server and LTE Transmission unavailable",
    "es": "Servidor LTE y transmisión LTE no disponibles",
    "fr": "Serveur LTE et transmission LTE indisponibles",
    "ja": "LTEサーバーやLTE伝送が利用できません",
    "ko": "LTE 서버 및 LTE 전송 사용 불가",
    "ru": "Сервер LTE и передача по LTE недоступны",
    "tr": "LTE Sunucusu ve LTE İletimi kullanılamıyor",
    "zh": "LTE服务器不可用，LTE链路不可用"
  },
  "fpv_tip_0x1F0B0005": {
    "de": "LTE-Übertragung nicht verfügbar. Überprüfen, ob Fernsteuerung und Fluggerät richtig gekoppelt sind",
    "en": "LTE Transmission unavailable. Check and ensure remote controller and aircraft are linked properly",
    "es": "Transmisión LTE no disponible. Comprueba el control remoto y la aeronave y asegúrate de que estén vinculados correctamente",
    "fr": "Transmission LTE indisponible. Assurez-vous que la radiocommande et l\\'appareil sont bien connectés",
    "ja": "LTE伝送は使用できません。。送信機と機体が適切にリンクされていることを確認してください",
    "ko": "LTE 전송을 사용할 수 없습니다. LTE 전송 사용 불가",
    "ru": "Передача по LTE недоступна. Проверьте и убедитесь, что пульт ДУ и дрон сопряжены должным образом",
    "tr": "LTE İletimi kullanılamıyor. Uzaktan kumandanın ve aracın doğru şekilde bağlanıp bağlanmadığını kontrol edin",
    "zh": "LTE链路不可用，请确认遥控器和飞机已成功对频"
  },
  "fpv_tip_0x1F0B0006": {
    "de": "LTE-Übertragung nicht verfügbar. Fernsteuerung neu starten",
    "en": "LTE Transmission unavailable. Restart remote controller",
    "es": "Transmisión LTE no disponible. Reinicia el control remoto",
    "fr": "Transmission LTE indisponible. Redémarrez la radiocommande",
    "ja": "LTE伝送は使用できません。送信機を再起動してください",
    "ko": "LTE 전송을 사용할 수 없습니다. 조종기를 다시 시작하세요",
    "ru": "Передача по LTE недоступна. Перезапустите пульт ДУ",
    "tr": "LTE İletimi kullanılamıyor. Uzaktan kumandayı yeniden başlatın",
    "zh": "LTE链路不可用，请重启遥控器"
  },
  "fpv_tip_0x1F0B0007": {
    "de": "LTE-Übertragung nicht verfügbar. 4G-Dongle-Netzwerkkonnektivität des Fluggeräts überprüfen",
    "en": "LTE Transmission unavailable. Check aircraft 4G Dongle network connectivity",
    "es": "Transmisión LTE no disponible. Comprueba la conectividad de red de la llave 4G de la aeronave",
    "fr": "Transmission LTE indisponible. Vérifiez la connexion au réseau de la clé 4G de l\\'appareil",
    "ja": "LTE伝送が利用不可。機体の4Gドングルネットワークの接続を確認してください",
    "ko": "LTE 전송을 사용할 수 없습니다. 기체 4G 동글 네트워크 연결을 확인하세요",
    "ru": "Передача по LTE недоступна. Проверьте подключение 4G-адаптера дрона к сети",
    "tr": "LTE İletimi kullanılamıyor. Aracın 4G Modem ağ bağlantısını kontrol edin",
    "zh": "LTE链路不可用，请检查飞机4G dongle的网络能力"
  },
  "fpv_tip_0x1F0B0008": {
    "de": "LTE-Übertragung nicht verfügbar. Fluggerät und Fernsteuerung neu starten",
    "en": "LTE Transmission unavailable. Restart aircraft and remote controller",
    "es": "Transmisión LTE no disponible. Reinicia la aeronave y el control remoto",
    "fr": "Transmission LTE indisponible. Redémarrez l\\'appareil et la radiocommande",
    "ja": "LTE伝送が利用不可。機体と送信機を再起動してください",
    "ko": "LTE 전송을 사용할 수 없습니다. 기체와 조종기를 다시 시작하세요",
    "ru": "Передача по LTE недоступна. Перезапустите дрон и пульт ДУ",
    "tr": "LTE İletimi kullanılamıyor. Aracı ve uzaktan kumandayı yeniden başlatın",
    "zh": "LTE链路不可用，请重启飞机和遥控器"
  },
  "fpv_tip_0x1F0B0009": {
    "de": "LTE-Übertragung nicht verfügbar. Fernsteuerung neu starten",
    "en": "LTE Transmission unavailable. Restart remote controller",
    "es": "Transmisión LTE no disponible. Reinicia el control remoto",
    "fr": "Transmission LTE indisponible. Redémarrez la radiocommande",
    "ja": "LTE通信は利用できません。送信機を再起動してください",
    "ko": "LTE 전송 사용 불가. 조종기 다시 시작",
    "ru": "Передача по LTE недоступна. Перезагрузите пульт ДУ",
    "tr": "LTE İletimi kullanılamıyor. Uzaktan kumandayı yeniden başlatın",
    "zh": "LTE链路不可用，请重启遥控器"
  },
  "fpv_tip_0x1F0B0016": {
    "de": "LTE-Übertragung nicht verfügbar. Netzwerkkonnektivität der Fernsteuerung überprüfen",
    "en": "LTE Transmission unavailable. Check remote controller network connectivity",
    "es": "Transmisión LTE no disponible. Comprueba la conectividad de red del control remoto",
    "fr": "Transmission LTE non disponible. Vérifier la connectivité réseau de la radiocommande",
    "ja": "LTE通信は利用できません。リモートコントローラーのネットワーク接続を確認してください",
    "ko": "LTE 전송을 사용할 수 없습니다. 조종기 네트워크 연결을 확인하세요.",
    "ru": "Передача по LTE недоступна. Проверьте подключение пульта ДУ к сети",
    "tr": "LTE İletimi kullanılamıyor. Uzaktan kumanda ağ bağlanabilirliğini kontrol edin",
    "zh": "LTE链路不可用，请检查遥控器的网络能力"
  },
  "fpv_tip_0x1F0B0017": {
    "de": "LTE-Übertragung nicht verfügbar. Netzwerkkonnektivität der Fernsteuerung überprüfen",
    "en": "LTE Transmission unavailable. Check remote controller network connectivity",
    "es": "Transmisión LTE no disponible. Comprueba la conectividad de red del control remoto",
    "fr": "Transmission LTE non disponible. Vérifiez la connectivité réseau de la radiocommande",
    "ja": "LTE通信は利用できません。リモートコントローラーのネットワーク接続を確認してください",
    "ko": "LTE 전송을 사용할 수 없습니다. 조종기 네트워크 연결을 확인하세요.",
    "ru": "Передача по LTE недоступна. Проверьте подключение пульта ДУ к сети",
    "tr": "LTE İletimi kullanılamıyor. Uzaktan kumanda ağ bağlanabilirliğini kontrol edin",
    "zh": "LTE链路不可用，请检查遥控器的网络能力"
  },
  "fpv_tip_0x1F0B0018": {
    "de": "LTE-Übertragung nicht verfügbar. Fluggerät und Fernsteuerung neu starten",
    "en": "LTE Transmission unavailable. Restart aircraft and remote controller",
    "es": "Transmisión LTE no disponible. Reinicia la aeronave y el control remoto",
    "fr": "Transmission LTE indisponible. Redémarrez l\\'appareil et la radiocommande",
    "ja": "LTE伝送が利用不可。機体と送信機を再起動してください",
    "ko": "LTE 전송을 사용할 수 없습니다. 기체와 조종기를 다시 시작하세요",
    "ru": "Передача по LTE недоступна. Перезапустите дрон и пульт ДУ",
    "tr": "LTE İletimi kullanılamıyor. Aracı ve uzaktan kumandayı yeniden başlatın",
    "zh": "LTE链路不可用，请重启飞机和遥控器"
  },
  "fpv_tip_0x1F0B001A": {
    "de": "LTE-Übertragung nicht verfügbar. 4G-Dongle-Netzwerkkonnektivität des Fluggeräts überprüfen",
    "en": "LTE Transmission unavailable. Check aircraft 4G Dongle network connectivity",
    "es": "Transmisión LTE no disponible. Comprueba la conectividad de red de la llave 4G de la aeronave",
    "fr": "Transmission LTE indisponible. Vérifiez la connexion au réseau de la clé 4G de l'appareil",
    "ja": "LTE通信は利用できません。機体の4Gドングルネットワークの接続を確認してください",
    "ko": "LTE 전송 사용 불가. 기체 4G 동글 네트워크 연결을 확인하세요",
    "ru": "Передача по LTE недоступна. Проверьте подключение 4G-адаптера дрона к сети",
    "tr": "LTE İletimi kullanılamıyor. Aracın 4G Modem ağ bağlantısını kontrol edin",
    "zh": "LTE链路不可用，请检查飞机4G dongle的网络能力"
  },
  "fpv_tip_0x1F0B001B": {
    "de": "LTE-Übertragung nicht verfügbar. Fluggerät und Fernsteuerung neu starten",
    "en": "LTE Transmission unavailable. Restart aircraft and remote controller",
    "es": "Transmisión LTE no disponible. Reinicia la aeronave y el control remoto",
    "fr": "Transmission LTE indisponible. Redémarrez l'appareil et la radiocommande",
    "ja": "LTE通信は利用できません。機体と送信機を再起動してください",
    "ko": "LTE 전송 사용 불가. 기체와 조종기를 다시 시작하세요",
    "ru": "Передача по LTE недоступна. Перезагрузите дрон и пульт ДУ",
    "tr": "LTE İletimi kullanılamıyor. Aracı ve uzaktan kumandayı yeniden başlatın",
    "zh": "LTE链路不可用，请重启飞机和遥控器"
  },
  "fpv_tip_0x1F0B001C": {
    "de": "LTE-Übertragungsfehler. Authentifizierungsdateien für Fluggerät fehlen. Lokalen Händler oder DJI Support kontaktieren",
    "en": "LTE Transmission error. Aircraft authentication files missing. Contact your local dealer or DJI Support",
    "es": "",
    "fr": "Erreur transmission LTE. Fichiers d'authentification de l'appareil manquants. Contactez immédiatement votre revendeur local ou l'assistance technique DJI",
    "it": "",
    "ja": "LTE伝送エラー。機体認証ファイルがありません。最寄りの代理店またはDJIサポートにお問い合わせください",
    "ko": "LTE 전송 오류. 기체 인증 파일이 없습니다. 현지 대리점 또는 DJI 고객지원팀에 연락하세요",
    "pt": "",
    "ru": "Ошибка передачи по LTE. Отсутствуют файлы аутентификации дрона. Обратитесь к местному дилеру или в службу поддержки DJI",
    "tr": "",
    "zh": "LTE链路异常，飞行器缺少认证文件，请联系大疆售后服务"
  },
  "fpv_tip_0x1F0B001c": {
    "de": "LTE-Übertragungsfehler. Authentifizierungsdateien für Fluggerät fehlen. Lokalen Händler oder DJI Support kontaktieren",
    "en": "LTE Transmission error. Aircraft authentication files missing. Contact your local dealer or DJI Support",
    "es": "",
    "fr": "Erreur transmission LTE. Fichiers d'authentification de l'appareil manquants. Contactez immédiatement votre revendeur local ou l'assistance technique DJI",
    "ja": "LTE伝送エラー。機体認証ファイルがありません。最寄りの代理店またはDJIサポートにお問い合わせください",
    "ko": "LTE 전송 오류. 기체 인증 파일이 없습니다. 현지 대리점 또는 DJI 고객지원팀에 연락하세요",
    "ru": "Ошибка передачи по LTE. Отсутствуют файлы аутентификации дрона. Обратитесь к местному дилеру или в службу поддержки DJI",
    "tr": "",
    "zh": "LTE链路异常，飞行器缺少认证文件，请联系大疆售后服务"
  },
  "fpv_tip_0x1F0B001d": {
    "de": "LTE-Übertragungsfehler. Authentifizierungsdateien für Fernsteuerung fehlen. Lokalen Händler oder DJI Support kontaktieren",
    "en": "LTE Transmission error. Remote controller authentication files missing. Contact your local dealer or DJI Support",
    "es": "",
    "fr": "Erreur transmission LTE. Fichiers d'authentification de la radiocommande manquants. Contactez votre revendeur local ou l'assistance technique DJI",
    "ja": "LTE伝送エラー。送信機認証ファイルがありません。最寄りの代理店またはDJIサポートにお問い合わせください",
    "ko": "LTE 전송 오류. 조종기 인증 파일이 없습니다. 현지 대리점 또는 DJI 고객지원팀에 연락하세요",
    "ru": "Ошибка передачи по LTE. Отсутствуют файлы аутентификации пульта ДУ. Обратитесь к местному дилеру или в службу поддержки DJI",
    "tr": "",
    "zh": "LTE链路异常，遥控器缺少认证文件，请联系大疆售后服务"
  },
  "fpv_tip_0x1F0B0020": {
    "de": "Aktualisierung des DJI Mobilfunkmodul-Dongles des Fluggeräts erforderlich",
    "en": "Update DJI Cellular Dongle of aircraft required",
    "es": "Es necesario actualizar el adaptador de red móvil de DJI de la aeronave.",
    "fr": "Mise à jour requise du dongle cellulaire DJI de l'appareil",
    "ja": "機体のDJIセルラードングルの更新が必要です",
    "ko": "기체의 DJI 셀룰러 동글 업데이트 필요",
    "ru": "Обновите DJI Cellular Dongle дрона",
    "tr": "Hava aracının DJI Hücresel Dongle'ının güncellenmesi gerekiyor",
    "zh": "飞行器 DJI Cellular 模块需要升级"
  },
  "fpv_tip_0x1F0B0021": {
    "de": "Aktualisierung des DJI Mobilfunkmodul-Dongles der Fernsteuerung erforderlich",
    "en": "Update DJI Cellular Dongle of remote controller required",
    "es": "Es necesario actualizar el adaptador de red móvil de DJI del control remoto.",
    "fr": "Mise à jour requise du dongle cellulaire DJI de la radiocommande",
    "ja": "送信機のDJIセルラードングルの更新が必要です",
    "ko": "조종기의 DJI 셀룰러 동글 업데이트 필요",
    "ru": "Обновите DJI Cellular Dongle пульта дистанционного управления",
    "tr": "Uzaktan kumandanın DJI Hücresel Dongle'ının güncellenmesi gerekiyor",
    "zh": "遥控器 DJI Cellular 模块需升级"
  },
  "fpv_tip_0x1F0B0022": {
    "ar": "",
    "de": "",
    "en": "Airplane mode enabled or Wi-Fi and mobile data disabled on mobile device. Unable to connect",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "手机进入飞行模式或者关闭wifi及移动网络，无法发起移动网络通信",
    "zh-Hant": ""
  },
  "fpv_tip_0x1F0B0023": {
    "de": "Schwaches LTE-Signal. Mit Vorsicht fliegen",
    "en": "LTE signal weak. Fly with caution",
    "es": "Señal de LTE débil. Vuela con cuidado.",
    "fr": "Signal LTE faible. Pilotez avec précaution",
    "ja": "LTE信号 弱。慎重に飛行してください",
    "ko": "LTE 신호 약함. 비행 시 주의 필요",
    "ru": "Слабый сигнал LTE. Будьте внимательны при полете",
    "tr": "LTE sinyali zayıf. Dikkatlice uçun.",
    "zh": "LTE 信号弱，请谨慎飞行"
  },
  "fpv_tip_0x1F0B0024": {
    "de": "Schwaches LTE-Signal. Mit Vorsicht fliegen",
    "en": "LTE signal weak. Fly with caution",
    "es": "Señal de LTE débil. Vuela con cuidado.",
    "fr": "Signal LTE faible. Pilotez avec précaution",
    "ja": "LTE信号 弱。慎重に飛行してください",
    "ko": "LTE 신호 약함. 비행 시 주의 필요",
    "ru": "Слабый сигнал LTE. Будьте внимательны при полете",
    "tr": "LTE sinyali zayıf. Dikkatlice uçun.",
    "zh": "LTE 信号弱，请谨慎飞行"
  },
  "fpv_tip_0x1F0B0025": {
    "de": "Schwaches LTE-Signal. Mit Vorsicht fliegen",
    "en": "LTE signal weak. Fly with caution",
    "es": "Señal de LTE débil. Vuela con cuidado.",
    "fr": "Signal LTE faible. Pilotez avec précaution",
    "ja": "LTE信号 弱。慎重に飛行してください",
    "ko": "LTE 신호 약함. 비행 시 주의 필요",
    "ru": "Слабый сигнал LTE. Будьте внимательны при полете",
    "tr": "LTE sinyali zayıf. Dikkatlice uçun.",
    "zh": "LTE 信号弱，请谨慎飞行"
  },
  "fpv_tip_0x1F0B0026": {
    "ar": "",
    "de": "",
    "en": "Insufficient data allowance on SIM card. Fly with caution",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "SIM卡流量不足，请谨慎飞行",
    "zh-Hant": ""
  },
  "fpv_tip_0x1F0B0027": {
    "de": "Aktualisierung der LTE-Zertifizierung des Fluggeräts erforderlich. An Händler vor Ort oder an DJI Support wenden",
    "en": "Aircraft LTE certification update required. Contact your local dealer or DJI Support",
    "es": "Es necesario actualizar la certificación LTE de la aeronave. Ponte en contacto con el distribuidor local o el servicio de asistencia técnica de DJI.",
    "fr": "Mise à jour de la certification LTE de l'appareil nécessaire. Contactez votre revendeur local ou le service client DJI",
    "ja": "機体のLTE認定の更新が必要です。最寄りの代理店またはDJIサポートにお問い合わせください",
    "ko": "기체 LTE 인증 업데이트 필요. 지역 딜러 또는 DJI 고객지원에 문의하세요.",
    "ru": "Требуется обновление сертификации LTE для дрона. Обратитесь к местному представителю или в службу поддержки DJI",
    "tr": "Hava aracı LTE sertifika güncellemesi gerekiyor. Bölgenizdeki satıcıyla veya DJI Destek ile iletişime geçin",
    "zh": "飞行器 LTE 证书需更新，请联系售后或代理商"
  },
  "fpv_tip_0x1F0B0028": {
    "de": "Aktualisierung der LTE-Zertifizierung der Fernsteuerung erforderlich. An Händler vor Ort oder an DJI Support wenden",
    "en": "Remote controller LTE certification update required. Contact your local dealer or DJI Support",
    "es": "Es necesario actualizar la certificación LTE del control remoto. Ponte en contacto con el distribuidor local o el servicio de asistencia técnica de DJI.",
    "fr": "Mise à jour de la certification LTE de la radiocommande. Contactez votre revendeur local ou le service client DJI",
    "ja": "送信機のLTE認定の更新が必要です。最寄りの代理店またはDJIサポートにお問い合わせください",
    "ko": "조종기 LTE 인증 업데이트 필요. 지역 딜러 또는 DJI 고객지원에 문의하세요.",
    "ru": "Требуется обновление сертификации LTE для пульта дистанционного управления. Обратитесь к местному представителю или в службу поддержки DJI",
    "tr": "Uzaktan kumanda LTE sertifika güncellemesi gerekiyor. Bölgenizdeki satıcıyla veya DJI Destek ile iletişime geçin",
    "zh": "遥控器 LTE 证书需更新，请联系售后或代理商"
  },
  "fpv_tip_0x1F0B0029": {
    "ar": "",
    "de": "",
    "en": "License expired. Unable to establish communication",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "证书过期，将无法正常建立通信链路",
    "zh-Hant": ""
  },
  "fpv_tip_0x1F0B002A": {
    "de": "Inkompatible Firmware-Versionen. Zum Startbildschirm wechseln, um die Firmware zu aktualisieren",
    "en": "Incompatible firmware versions. Return to home screen to update firmware",
    "es": "Versiones del firmware incompatibles. Vuelve a la pantalla de inicio para actualizar el firmware.",
    "fr": "Versions de firmware incompatibles. Revenez sur l'écran d'accueil pour mettre à jour le firmware",
    "ja": "互換性のないファームウェア。ホーム画面に戻ってファームウェアを更新してください",
    "ko": "호환되지 않는 펌웨어 버전. 리턴 투 홈 화면으로 돌아가 펌웨어를 업데이트하세요.",
    "ru": "Несовместимые версии прошивки. Вернитесь на главный экран, чтобы обновить прошивку",
    "tr": "Uyumsuz ürün yazılımı sürümleri. Ürün yazılımını güncellemek için ana ekrana dönün",
    "zh": "固件版本不匹配，请返回首页进行升级"
  },
  "fpv_tip_0x1F0B002B": {
    "de": "Inkompatible Firmware-Versionen. Zum Startbildschirm wechseln, um die Firmware zu aktualisieren",
    "en": "Incompatible firmware versions. Return to home screen to update firmware",
    "es": "Versiones del firmware incompatibles. Vuelve a la pantalla de inicio para actualizar el firmware.",
    "fr": "Versions de firmware incompatibles. Revenez sur l'écran d'accueil pour mettre à jour le firmware",
    "ja": "互換性のないファームウェア。ホーム画面に戻ってファームウェアを更新してください",
    "ko": "호환되지 않는 펌웨어 버전. 리턴 투 홈 화면으로 돌아가 펌웨어를 업데이트하세요.",
    "ru": "Несовместимые версии прошивки. Вернитесь на главный экран, чтобы обновить прошивку",
    "tr": "Uyumsuz ürün yazılımı sürümleri. Ürün yazılımını güncellemek için ana ekrana dönün",
    "zh": "固件版本不匹配，请返回首页进行升级"
  },
  "fpv_tip_0x1F0B002C": {
    "ar": "",
    "de": "",
    "en": "Incompatible firmware versions. Go to home screen to update firmware",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "固件版本不匹配，请在首页进行升级",
    "zh-Hant": ""
  },
  "fpv_tip_0x1F0B002D": {
    "ar": "",
    "de": "",
    "en": "App update required",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "App版本需要升级",
    "zh-Hant": ""
  },
  "fpv_tip_0x1F0B002E": {
    "de": "Schwache Netzwerkverbindung auf Fernsteuerung. Mit Vorsicht fliegen",
    "en": "Weak network connection on remote controller. Fly with caution",
    "es": "Conexión de red débil en el control remoto. Vuele con cuidado",
    "fr": "Connexion réseau faible sur la radiocommande. Pilotez avec précaution",
    "ja": "送信機のネットワーク接続 弱。慎重に飛行してください",
    "ko": "조종기의 네트워크 연결이 약합니다. 비행 시 주의 필요",
    "ru": "Слабое сетевое подключение на пульте дистанционного управления. Соблюдайте осторожность при полете",
    "tr": "Uzaktan kumandada zayıf ağ bağlantısı. Dikkatlice uçurun",
    "zh": "遥控器网络信号弱，请谨慎飞行"
  },
  "fpv_tip_0x1F0B0030": {
    "de": "Verbindung zum LTE-Server fehlgeschlagen. Später erneut versuchen",
    "en": "Failed to connect to LTE server. Try again later",
    "es": "No se pudo conectar al servidor LTE. Vuelve a intentarlo más tarde.",
    "fr": "Échec de la connexion au serveur LTE. Veuillez réessayer ultérieurement",
    "ja": "LTEサーバーへの接続に失敗。後でやり直してください",
    "ko": "LTE 서버 연결 실패. 나중에 다시 시도하세요.",
    "ru": "Не удалось подключиться к серверу LTE. Повторите попытку позже",
    "tr": "LTE sunucusuna bağlanılamadı. Daha sonra tekrar deneyin",
    "zh": "LTE服务器暂时无法连接，请稍后再试"
  },
  "fpv_tip_0x1F0B0031": {
    "ar": "",
    "de": "",
    "en": "Weak network signal on mobile device. Device may be unable to establish communication or connection may be unstable",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "手机可网络信号弱，链路可能无法建立或者容易断开连接",
    "zh-Hant": ""
  },
  "fpv_tip_0x1F0B0032": {
    "ar": "",
    "de": "",
    "en": "Service expired. Unable to use Enhanced Transmission. Firmware update required",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "用户服务到期，不允许进入融合模式。固件版本需要升级",
    "zh-Hant": ""
  },
  "fpv_tip_0x1F0B0035": {
    "ar": "",
    "de": "",
    "en": "Dongle firmware update required",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "dongle固件版本需要升级",
    "zh-Hant": ""
  },
  "fpv_tip_0x1F0B0036": {
    "de": "Verbesserte Übertragung in aktueller Region nicht verfügbar",
    "en": "Enhanced Transmission unavailable in current region",
    "es": "La transmisión mejorada no está disponible en la región actual.",
    "fr": "Transmission améliorée non disponible dans la région actuelle",
    "ja": "現在の地域では、強化伝送を利用できません",
    "ko": "현재 지역에서는 향상된 전송 사용 불가",
    "ru": "Расширенная передача недоступна в текущем регионе",
    "tr": "Gelişmiş Aktarım geçerli bölgede mevcut değil",
    "zh": "当前地区无法使用增强图传功能"
  },
  "fpv_tip_0x1F0B0037": {
    "de": "Kein Internetzugang auf DJI Mobilfunkmodul-Dongle des Fluggeräts. Standort oder Netzwerk wechseln",
    "en": "No internet access on DJI Cellular Dongle of aircraft. Move or change network",
    "es": "No hay acceso a Internet en el adaptador de red móvil de DJI de la aeronave. Muévete o cambia de red.",
    "fr": "Aucun accès Internet sur le dongle cellulaire DJI de l'appareil. Déplacez-vous ou changez de réseau",
    "ja": "機体のDJIセルラードングルがインターネットにアクセス不可。アクセス可能な場所に移動するか、ネットワークを変更してください",
    "ko": "기체의 DJI 셀룰러 동글에서 인터넷에 액세스할 수 없음. 이동하거나 네트워크를 변경하세요.",
    "ru": "Нет доступа в Интернет на DJI Cellular Dongle дрона. Переместите его или измените сеть",
    "tr": "Hava aracının DJI Hücresel Dongle'ında internet erişimi yok. Hareket edin veya ağı değiştirin",
    "zh": "飞行器 DJI Cellular 模块无法访问网络，请更换至网络更好的地点或稍后重试"
  },
  "fpv_tip_0x1F0B0038": {
    "de": "Kein Netz auf DJI Mobilfunk-Dongle der Fernsteuerung",
    "en": "No network on DJI Cellular Dongle of remote controller",
    "es": "No hay red en el adaptador de red móvil de DJI del control remoto",
    "fr": "Pas de réseau sur le dongle cellulaire DJI de la radiocommande",
    "ja": "送信機のDJIセルラードングルでネットワークを利用できません",
    "ko": "조종기 DJI 셀룰러 동글이 네트워크에 연결되어 있지 않습니다",
    "ru": "Нет сети на DJI Cellular Dongle пульта дистанционного управления",
    "tr": "Uzaktan kumandanın DJI Hücresel Dongle'ında ağ yok",
    "zh": "遥控器 DJI Cellular 模块无法访问网络"
  },
  "fpv_tip_0x1F0B0039": {
    "ar": "",
    "de": "",
    "en": "Unable to establish network connection. Move to a different place or switch to a different SIM card from another service provider",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "目前网络无法正常建立，可以尝试更换地点，或者换其他运营商的卡。",
    "zh-Hant": ""
  },
  "fpv_tip_0x1F0B003A": {
    "de": "Kein Internetzugang auf DJI Mobilfunkmodul-Dongle des Fluggeräts. Netzanbieter wechseln",
    "en": "No internet access on DJI Cellular Dongle of aircraft. Change network provider",
    "es": "No hay acceso a Internet en el adaptador de red móvil de DJI de la aeronave. Cambia de proveedor de red.",
    "fr": "Aucun accès Internet sur le dongle cellulaire DJI de l'appareil. Changez de fournisseur de réseau",
    "ja": "機体のDJIセルラードングルがインターネットにアクセス不可。ネットワークプロバイダーを変更してください",
    "ko": "기체의 DJI 셀룰러 동글에서 인터넷에 액세스할 수 없음. 네트워크 공급자를 변경하세요.",
    "ru": "Нет доступа в Интернет на DJI Cellular Dongle дрона. Выберите другого оператора связи",
    "tr": "Hava aracının DJI Hücresel Dongle'ında internet erişimi yok. Ağ sağlayıcısını değiştirin",
    "zh": "飞行器 DJI Cellular 模块无法访问网络，请尝试使用其他运营商网络"
  },
  "fpv_tip_0x1F0B003B": {
    "de": "Kein Netz auf DJI Mobilfunk-Dongle der Fernsteuerung",
    "en": "No network on DJI Cellular Dongle of remote controller",
    "es": "No hay red en el adaptador de red móvil de DJI del control remoto",
    "fr": "Pas de réseau sur le dongle cellulaire DJI de la radiocommande",
    "ja": "送信機のDJIセルラードングルでネットワークを利用できません",
    "ko": "조종기 DJI 셀룰러 동글이 네트워크에 연결되어 있지 않습니다",
    "ru": "Нет сети на DJI Cellular Dongle пульта дистанционного управления",
    "tr": "Uzaktan kumandanın DJI Hücresel Dongle'ında ağ yok",
    "zh": "遥控器 DJI Cellular 模块无法访问网络"
  },
  "fpv_tip_0x1F0B003C": {
    "ar": "",
    "de": "",
    "en": "Unable to establish network connection. Move to a different place or switch to a different SIM card from another service provider",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "目前网络无法正常建立，可以尝试更换地点，或者换其他运营商的卡。",
    "zh-Hant": ""
  },
  "fpv_tip_0x1F0B003D": {
    "de": "Keine Netzwerkverbindung",
    "en": "No network connection",
    "es": "No hay conexión de red",
    "fr": "Aucune connexion réseau",
    "ja": "ネットワーク接続がありません",
    "ko": "네트워크 연결 없음",
    "ru": "Нет подключения к сети",
    "tr": "Ağ bağlantısı yok",
    "zh": "无法访问网络"
  },
  "fpv_tip_0x1F0B003E": {
    "de": "Kein Netz für SIM-Karte des DJI Mobilfunkmodul-Dongles der Fernsteuerung. SIM-Karte prüfen oder ersetzen",
    "en": "No network on SIM card of DJI Cellular Dongle of remote controller. Check or replace SIM card",
    "es": "No hay red en la tarjeta SIM del adaptador de red móvil de DJI del control remoto. Compruebe o sustituya la tarjeta SIM",
    "fr": "Pas de réseau sur la carte SIM du dongle cellulaire de radiocommande DJI. Vérifiez ou remplacez la carte SIM",
    "ja": "送信機のDJIセルラードングルのSIMカードでネットワークを利用できません。SIMカードを確認または交換してください",
    "ko": "조종기 DJI 셀룰러 동글의 SIM 카드에 네트워크가 없습니다. SIM 카드를 확인하거나 교체하세요",
    "ru": "Нет сети на SIM-карте DJI Cellular Dongle пульта дистанционного управления. Проверьте или замените SIM-карту",
    "tr": "Uzaktan kumandanın DJI Hücresel Dongle öğesi SIM kartında ağ bağlantısı yok. SIM kartı kontrol edin veya değiştirin",
    "zh": "遥控器 DJI Cellular SIM卡暂时无法获取网络服务，建议检查或更换SIM卡"
  },
  "fpv_tip_0x1F0B0040": {
    "de": "Kein Netz auf der Fernsteuerung",
    "en": "No network on remote controller",
    "es": "No hay red en el control remoto",
    "fr": "Pas de réseau sur la radiocommande",
    "ja": "送信機でネットワークを利用できません",
    "ko": "조종기가 네트워크에 연결되어 있지 않습니다",
    "ru": "Отсутствие сети на пульте дистанционного управления",
    "tr": "Uzaktan kumandada ağ yok",
    "zh": "遥控器无法访问网络"
  },
  "fpv_tip_0x1F0B0040_in_the_sky": {
    "de": "Kein Internet auf der Fernsteuerung. Status des DJI Mobilfunk-Dongles oder der Wi-Fi-Verbindung prüfen",
    "en": "No internet on remote controller. Check DJI Cellular Dongle or Wi-Fi connection status",
    "es": "No hay internet en el control remoto. Compruebe el estado de la conexión Wi-Fi o del adaptador de red móvil de DJI",
    "fr": "Pas d’Internet sur la radiocommande. Vérifiez le dongle cellulaire DJI ou l'état de la connexion Wi-Fi",
    "ja": "送信機にインターネット接続なし。DJIセルラードングルまたはWi-Fi接続ステータスを確認してください",
    "ko": "조종기가 인터넷에 연결되어 있지 않습니다. DJI 셀룰러 동글 또는 Wi-Fi 연결 상태를 확인하세요",
    "ru": "Отсутствие сети Интернет на пульте. Проверьте DJI Cellular Dongle или статус подключения Wi-Fi",
    "tr": "Uzaktan kumandada internet yok. DJI Hücresel Dongle veya Wi-Fi bağlantı durumunu kontrol edin",
    "zh": "遥控器无法访问网络，请检查DJI Cellular模块或Wi-Fi连接状态"
  },
  "fpv_tip_0x1F0B0045": {
    "de": "Erhöhte Latenzzeit der Verbindung. Mit Vorsicht fliegen",
    "en": "Enhanced link latency high. Fly with caution",
    "es": "Alta latencia de enlace mejorada. Vuele con cuidado.",
    "fr": "Latence de liaison améliorée élevée. Pilotez avec précaution",
    "ja": "強化リンクが高遅延。慎重に飛行してください",
    "ko": "향상된 링크 지연율이 높습니다. 비행 시 주의 필요",
    "ru": "Большая вероятность канальной задержки управления расширенной связью. Соблюдайте осторожность при полете",
    "tr": "Gelişmiş bağlantı gecikmesi yüksek. Dikkatlice uçurun",
    "zh": "增强链路操控延时较大，请谨慎飞行"
  },
  "fpv_tip_0x1F0B0045_in_the_sky": {
    "de": "Erhöhte Latenzzeit der Verbindung. Mit Vorsicht fliegen.",
    "en": "Enhanced link latency high. Fly with caution",
    "es": "Alta latencia de enlace mejorada. Vuele con cuidado",
    "fr": "Latence de liaison améliorée élevée. Pilotez avec précaution",
    "ja": "強化リンクが高遅延。慎重に飛行してください",
    "ko": "향상된 링크 지연율이 높습니다. 비행 시 주의 필요",
    "ru": "Большая вероятность канальной задержки управления расширенной связью. Соблюдайте осторожность при полете",
    "tr": "Gelişmiş bağlantı gecikmesi yüksek. Dikkatlice uçurun",
    "zh": "增强链路操控延时较大，请谨慎飞行"
  },
  "fpv_tip_0x1F0B0046": {
    "ar": "",
    "de": "SIM-Karte nicht in 4G-Dongle des Fluggeräts eingesetzt und eSIM-Karte nicht aktiviert",
    "en": "SIM card not inserted in 4G Dongle of aircraft and eSIM card not activated",
    "es": "Tarjeta SIM no insertada en el adaptador 4G de la aeronave y tarjeta eSIM no activada",
    "fr": "Carte SIM non insérée dans le dongle 4G de l'appareil et carte eSIM non activée",
    "id": "",
    "it": "",
    "ja": "SIMカードが機体の4Gドングルに挿入されておらず、eSIMカードがアクティベートされていません",
    "ko": "기체의 4G 동글에 SIM 카드가 삽입되지 않았고 eSIM 카드가 활성화되지 않았습니다",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "SIM-карта не вставлена в 4G-адаптер дрона и карта eSIM не активирована",
    "th": "",
    "tr": "Hava aracının 4G Dongle'ına SIM kart takılı değil ve eSIM kartı etkinleştirilmemiş",
    "ug": "",
    "vi": "",
    "zh": "飞机的4G Dongle未插入SIM卡且eSIM未激活",
    "zh-Hant": ""
  },
  "fpv_tip_0x1F0B0047": {
    "ar": "",
    "de": "SIM-Karte in 4G-Dongle des Fluggeräts eingesetzt. eSIM-Karte ausgewählt, aber nicht aktiviert",
    "en": "SIM card inserted in 4G Dongle of aircraft. eSIM card selected but not activated",
    "es": "Tarjeta SIM insertada en el adaptador 4G de la aeronave. Tarjeta eSIM seleccionada pero no activada",
    "fr": "Carte SIM insérée dans le dongle 4G de l'appareil. Carte eSIM sélectionnée, mais non activée",
    "id": "",
    "it": "",
    "ja": "SIMカードが機体の4Gドングルに挿入され、eSIMカードが選択されていますがアクティベートされていません",
    "ko": "기체의 4G 동글에 SIM 카드가 삽입되어 있지만 선택한 eSIM 카드가 활성화되지 않았습니다",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "SIM-карта вставлена в 4G-адаптер дрона. Карта eSIM выбрана, но не активирована",
    "th": "",
    "tr": "Hava aracının 4G Dongle'ına SIM kart takıldı eSIM kartı seçildi ancak etkinleştirilmedi",
    "ug": "",
    "vi": "",
    "zh": "飞机的4G Dongle已插入SIM卡，选择了eSIM且eSIM未激活",
    "zh-Hant": ""
  },
  "fpv_tip_0x1F0B0048": {
    "ar": "",
    "de": "SIM-Karte nicht in 4G-Dongle der Fernsteuerung eingesetzt und eSIM-Karte nicht aktiviert",
    "en": "SIM card not inserted in 4G Dongle of remote controller and eSIM card not activated",
    "es": "Tarjeta SIM no insertada en el adaptador 4G del control remoto y tarjeta eSIM no activada",
    "fr": "Carte SIM non insérée dans le dongle 4G de la radiocommande et carte eSIM non activée",
    "id": "",
    "it": "",
    "ja": "SIMカードが送信機の4Gドングルに挿入されておらず、eSIMカードがアクティベートされていません",
    "ko": "조종기의 4G 동글에 SIM 카드가 삽입되지 않았고 eSIM 카드가 활성화되지 않았습니다",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "SIM-карта не вставлена в 4G-адаптер пульта ДУ и карта eSIM не активирована",
    "th": "",
    "tr": "Uzaktan kumandanın 4G Dongle'ına SIM kart takılı değil ve eSIM kartı etkinleştirilmemiş",
    "ug": "",
    "vi": "",
    "zh": "遥控器的4G Dongle未插入SIM卡且eSIM未激活",
    "zh-Hant": ""
  },
  "fpv_tip_0x1F0B0049": {
    "ar": "",
    "de": "SIM-Karte in 4G-Dongle der Fernsteuerung eingesetzt. eSIM-Karte ausgewählt, aber nicht aktiviert",
    "en": "SIM card inserted in 4G Dongle of remote controller. eSIM card selected but not activated",
    "es": "Tarjeta SIM insertada en el adaptador 4G del control remoto. Tarjeta eSIM seleccionada pero no activada",
    "fr": "Carte SIM insérée dans le dongle 4G de la radiocommande. Carte eSIM sélectionnée, mais non activée",
    "id": "",
    "it": "",
    "ja": "SIMカードが送信機の4Gドングルに挿入され、eSIMカードが選択されていますがアクティベートされていません",
    "ko": "조종기의 4G 동글에 SIM 카드가 삽입되어 있지만 선택한 eSIM 카드가 활성화되지 않았습니다",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "SIM-карта вставлена в 4G-адаптер пульта ДУ. Карта eSIM выбрана, но не активирована",
    "th": "",
    "tr": "Uzaktan kumandanın 4G Dongle'ına SIM kart takıldı. eSIM kartı seçildi ancak etkinleştirilmedi",
    "ug": "",
    "vi": "",
    "zh": "遥控器的4G Dongle已插入SIM卡，选择了eSIM且eSIM未激活",
    "zh-Hant": ""
  },
  "fpv_tip_0x1a020180": {
    "de": "Infrarotsensoren überhitzt. Umgehend zum Startpunkt zurückkehren oder landen. Bitte aus Umgebung mit hoher Temperatur entfernen (%alarmid)",
    "en": "Infrared sensors overheated. Return to home or land promptly. Move away from high-temperature environment",
    "es": "Sensores de infrarrojos sobrecalentados. Regresa al punto de origen o aterriza rápidamente. Aléjate del entorno de alta temperatura",
    "fr": "Surchauffe des capteurs infrarouges. Retournez au point de départ ou atterrissez immédiatement. Éloignez-vous des sources de hautes températures",
    "ja": "赤外線センサーが高温。直ちにホームに帰還するか着陸してください。高温環境から離れてください",
    "ko": "적외선 센서 과열. 홈으로 돌아가거나 신속히 착륙하세요. 고온 환경에서 벗어나세요",
    "ru": "Инфракрасные датчики перегреты. Немедленно вернитесь в исходное положение и приземлитесь. Переместитесь от среды с высокой температурой",
    "tr": "",
    "zh": "视觉红外传感器温度过高，请尽快返航或降落，远离高温环境"
  },
  "fpv_tip_0x1a420bc0": {
    "de": "Abwärtsgerichtetes Umgebungslicht zu schwach. Abwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Downward ambient light too low. Obstacle avoidance unavailable. Fly with caution",
    "es": "Luz ambiental inferior demasiado débil. Sistema anticolisión no disponible. Vuela con cuidado",
    "fr": "Éclairage ambiant vers le bas trop faible. Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "下方環境光が弱すぎます。障害物回避を利用できません。慎重に飛行してください",
    "ko": "하향 주변 조명이 너무 어둡습니다. 하향 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Слишком низкая яркость окружающего света внизу. Обход препятствий снизу недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "下视环境光过暗，下方视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420bc1": {
    "de": "Vorwärtsgerichtetes Umgebungslicht zu schwach. Vorwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Mit Vorsicht fliegen",
    "en": "Forward ambient light too low. Obstacle avoidance unavailable. Fly with caution",
    "es": "Luz ambiental frontal demasiado débil. Sistema anticolisión no disponible. Vuela con cuidado",
    "fr": "Éclairage ambiant vers l\\'avant trop faible. Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "前方環境光が弱すぎます。障害物回避を利用できません。慎重に飛行してください",
    "ko": "전방 주변 조명이 너무 어둡습니다. 전방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Слишком низкая яркость окружающего света впереди. Обход препятствий спереди недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "前视环境光过暗，前向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420bc2": {
    "de": "Rückwärtiges Umgebungslicht zu schwach. Rückwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Backward ambient light too low. Backward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental trasera demasiado débil. Sistema anticolisión trasero no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Lumière ambiante trop faible à l\\'arrière. Évitement des obstacles à l\\'arrière non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "後方の環境光が十分ではありません。後方障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "후방 주변 조명이 너무 어둡습니다. 후방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Слишком низкая яркость окружающего света сзади. Обход препятствий сзади недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "后视环境光过暗，后向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420bc3": {
    "de": "Rechtsgerichtetes Umgebungslicht zu schwach. Rechtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Rightward ambient light too low. Rightward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental derecha demasiado débil. Sistema anticolisión derecho no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Lumière ambiante trop faible sur la droite. Évitement des obstacles sur la droite non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "右方向の環境光が十分ではありません。右方向の障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "오른쪽 주변 조명이 너무 어둡습니다. 오른쪽 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Слишком низкая яркость окружающего света справа. Обход препятствий справа недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "右视环境光过暗，右向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420bc4": {
    "de": "Linksgerichtetes Umgebungslicht zu schwach. Linksgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Leftward ambient light too low. Leftward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental izquierda demasiado débil. Sistema anticolisión izquierdo no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Lumière ambiante trop faible sur la gauche. Évitement des obstacles sur la gauche non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "左方向の環境光が十分ではありません。左方向の障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "왼쪽 주변 조명이 너무 어둡습니다. 왼쪽 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Слишком низкая яркость окружающего света слева. Обход препятствий слева недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "左视环境光过暗，左向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420bc5": {
    "de": "Aufwärtsgerichtetes Umgebungslicht zu schwach. Aufwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Upward ambient light too low. Upward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental superior demasiado débil. Sistema anticolisión superior no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Lumière ambiante trop faible au-dessus. Évitement des obstacles au-dessus non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "上方の環境光が十分ではありません。上方障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "상방 주변 조명이 너무 어둡습니다. 상방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Слишком низкая яркость окружающего света сверху. Обход препятствий сверху недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "上视环境光过暗，上方视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420bc6": {
    "de": "Horizontales Umgebungslicht zu schwach. Horizontale Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Horizontal ambient light too low. Horizontal obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental horizontal demasiado débil. Sistema anticolisión horizontal no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Lumière ambiante horizontale trop faible. Évitement des obstacles horizontaux non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "水平方向の環境光が十分ではありません。水平方向の障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "수평 주변 조명이 너무 어둡습니다. 수평 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Слишком низкая яркость окружающего света в горизонтальном направлении. Обход препятствий в горизонтальном направлении недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "水平方向环境光过暗，水平方向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c00": {
    "de": "Abwärtsgerichtetes Umgebungslicht zu hell. Abwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Downward ambient light too bright. Downward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental inferior demasiado intensa. Sistema anticolisión inferior no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Lumière ambiante trop forte en dessous. Évitement des obstacles en dessous non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "下方の環境光が明るすぎます。下方障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "하방 주변 조명이 너무 밝습니다. 하방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Слишком высокая яркость окружающего света внизу. Обход препятствий снизу недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "下视环境光过亮，下方视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c01": {
    "de": "Vorwärts-Umgebungslicht zu hell. Vorwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Forward ambient light too bright. Forward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental frontal demasiado intensa. Sistema anticolisión frontal no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Lumière ambiante trop forte à l\\'avant. Évitement des obstacles à l\\'avant non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "前方の環境光が明るすぎます。前方障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "전방 주변 조명이 너무 밝습니다. 전방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Слишком высокая яркость окружающего света впереди. Обход препятствий спереди недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "前视环境光过亮，前向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c02": {
    "de": "Rückwärtiges Umgebungslicht zu hell. Rückwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Backward ambient light too bright. Backward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental trasera demasiado intensa. Sistema anticolisión trasero no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Lumière ambiante trop forte à l\\'arrière. Évitement des obstacles à l\\'arrière non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "後方の環境光が明るすぎます。後方障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "후방 주변 조명이 너무 밝습니다. 후방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Слишком высокая яркость окружающего света сзади. Обход препятствий сзади недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "后视环境光过亮，后向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c03": {
    "de": "Rechtsgerichtetes Umgebungslicht zu hell. Rechtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Rightward ambient light too bright. Rightward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental derecha demasiado intensa. Sistema anticolisión derecho no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Lumière ambiante trop forte sur la droite. Évitement des obstacles sur la droite non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "右方向の環境光が明るすぎます右方向の障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "오른쪽 주변 조명이 너무 밝습니다. 오른쪽 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Слишком высокая яркость окружающего света справа. Обход препятствий справа недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "右视环境光过亮，右向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c04": {
    "de": "Linksgerichtetes Umgebungslicht zu hell. Linksgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Leftward ambient light too bright. Leftward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental izquierda demasiado intensa. Sistema anticolisión izquierdo no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Lumière ambiante trop forte sur la gauche. Évitement des obstacles sur la gauche non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "左方向の環境光が明るすぎます。左方向の障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "왼쪽 주변 조명이 너무 밝습니다. 왼쪽 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Слишком высокая яркость окружающего света слева. Обход препятствий слева недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "左视环境光过亮，左向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c05": {
    "de": "Aufwärtsgerichtetes Umgebungslicht zu hell. Aufwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Upward ambient light too bright. Upward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental superior demasiado intensa. Sistema anticolisión superior no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Lumière ambiante trop forte au-dessus. Évitement des obstacles au-dessus non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "上方の環境光が明るすぎます。上方障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "상방 주변 조명이 너무 밝습니다. 상방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Слишком высокая яркость окружающего света сверху. Обход препятствий сверху недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "上视环境光过亮，上方视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c06": {
    "de": "Horizontales Umgebungslicht zu hell. Horizontale Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Horizontal ambient light too bright. Horizontal obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Luz ambiental horizontal demasiado intensa. Sistema anticolisión horizontal no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Lumière ambiante horizontale trop forte. Évitement des obstacles horizontaux non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "水平方向の環境光が明るすぎます。水平方向の障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "수평 주변 조명이 너무 밝습니다. 수평 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Слишком высокая яркость окружающего света в горизонтальном направлении. Обход препятствий в горизонтальном направлении недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "水平方向环境光过亮，水平方向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c40": {
    "de": "Abwärtsgerichtete(r) Sensor(en) blockiert. Abwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Downward sensor(s) blocked. Downward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Sensor(es) inferior(es) bloqueado(s). Sistema anticolisión inferior no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) inférieurs bloqué(s). Évitement des obstacles en dessous non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "下方センサーがブロックされています。下方障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "하방 센서가 막혔습니다. 하방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Нижние датчик(и) заблокированы. Обход препятствий снизу недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "下视可能遮挡，下方视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c41": {
    "de": "Vorwärtsgerichtete(r) Sensor(en) blockiert. Vorwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Forward sensor(s) blocked. Forward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Sensor(es) frontal(es) bloqueado(s). Sistema anticolisión frontal no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) avant bloqué(s). Évitement des obstacles à l\\'avant non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "前方センサーがブロックされています。前方障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "전방 센서가 막혔습니다. 전방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Передние датчик(и) заблокированы. Обход препятствий спереди недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "前视可能遮挡，前向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c42": {
    "de": "Rückwärtsgerichtete(r) Sensor(en) blockiert. Rückwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Backward sensor(s) blocked. Backward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Sensor(es) trasero(s) bloqueado(s). Sistema anticolisión trasero no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) arrière bloqué(s). Évitement des obstacles à l\\'arrière non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "後方センサーがブロックされています。後方障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "후방 센서가 막혔습니다. 후방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Задние датчик(и) заблокированы. Обход препятствий сзади недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "后视可能遮挡，后向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c43": {
    "de": "Rechtsgerichtete(r) Sensor(en) blockiert. Rechtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar.  Vorsichtig fliegen.",
    "en": "Rightward sensor(s) blocked. Rightward obstacle avoidance unavailable. Only infrared sensors available.  Fly with caution",
    "es": "Sensor(es) derecho(s) bloqueado(s). Sistema anticolisión derecho no disponible. Solo están disponibles los sensores de infrarrojos.  Vuela con cuidado",
    "fr": "Capteur(s) de droite bloqué(s). Évitement des obstacles sur la droite non disponible. Seuls les capteurs infrarouges sont disponibles.  Volez avec prudence",
    "ja": "右方向センサーがブロックされています。右方向の障害物回避を利用できません。赤外線センサーのみが利用できます。  注意して飛行してください",
    "ko": "오른쪽 센서가 막혔습니다. 오른쪽 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다.  주의하여 비행하세요.",
    "ru": "Правые датчик(и) заблокированы. Обход препятствий справа недоступен. Доступны только инфракрасные датчики.  Будьте внимательны при полете",
    "tr": "",
    "zh": "右视可能遮挡，右向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c44": {
    "de": "Linksgerichtete(r) Sensor(en) blockiert. Linksgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Leftward sensor(s) blocked. Leftward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Sensor(es) izquierdo(s) bloqueado(s). Sistema anticolisión izquierdo no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) de gauche bloqué(s). Évitement des obstacles sur la gauche non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "左方向センサーがブロックされています。左方向の障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "왼쪽 센서가 막혔습니다. 왼쪽 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Левые датчик(и) заблокированы. Обход препятствий слева недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "左视可能遮挡，左向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c45": {
    "de": "Aufwärtsgerichtete(r) Sensor(en) blockiert. Aufwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Upward sensor(s) blocked. Upward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Sensor(es) superior(es) bloqueado(s). Sistema anticolisión superior no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) supérieurs bloqué(s). Évitement des obstacles au-dessus non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "上方センサーがブロックされています。上方障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "상방 센서가 막혔습니다. 상방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Верхние датчик(и) заблокированы. Обход препятствий сверху недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "上视可能遮挡，上方视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c80": {
    "de": "Abwärtsgerichtete(r) Sensor(en) unscharf. Abwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Downward sensor(s) blurry. Downward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Sensor(es) inferior(es) borroso(s). Sistema anticolisión inferior no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) inférieurs flou(s). Évitement des obstacles en dessous non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "下方センサーが不鮮明です。下方障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "하방 센서가 흐릿합니다. 하방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Размытое изображение с нижних датчиков. Обход препятствий снизу недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "下视可能脏污，下方视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c81": {
    "de": "Vorwärtsgerichtete(r) Sensor(en) unscharf. Vorwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Forward sensor(s) blurry. Forward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Sensor(es) frontal(es) borroso(s). Sistema anticolisión frontal no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) avant flou(s). Évitement des obstacles à l\\'avant non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "前方センサーが不鮮明です。前方障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "전방 센서가 흐릿합니다. 전방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Размытое изображение с передних датчиков. Обход препятствий спереди недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "前视可能脏污，前向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c82": {
    "de": "Rückwärtsgerichtete(r) Sensor(en) verschwommen. Rückwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Backward sensor(s) blurry. Backward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Sensor(es) trasero(s) borroso(s). Sistema anticolisión trasero no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) arrière flou(s). Évitement des obstacles à l\\'arrière non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "後方センサーが不鮮明です。後方障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "후방 센서가 흐릿합니다. 후방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의해서 비행하세요",
    "ru": "Размытое изображение с задних датчиков. Обход препятствий сзади недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "后视可能脏污，后向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c83": {
    "de": "Rechte(r) Sensor(en) unscharf. Rechtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Right sensor(s) blurry. Rightward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Sensor(es) derecho(s) borroso(s). Sistema anticolisión derecho no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) de droite flou(s). Évitement des obstacles sur la droite non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "右センサーが不鮮明です。右方向の障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "오른쪽 센서가 흐릿합니다. 오른쪽 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Размытое изображение с правых датчиков. Обход препятствий справа недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "右视可能脏污，右向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c84": {
    "de": "Linke(r) Sensor(en) unscharf. Linksgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Left sensor(s) blurry. Leftward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Sensor(es) izquierdo(s) borroso(s). Sistema anticolisión izquierdo no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) de gauche flou(s). Évitement des obstacles sur la gauche non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "左センサーが不鮮明です。左方向の障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "왼쪽 센서가 흐릿합니다. 왼쪽 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Размытое изображение с левых датчиков. Обход препятствий слева недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "左视可能脏污，左向视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420c85": {
    "de": "Aufwärtsgerichtete(r) Sensor(en) unscharf. Aufwärtsgerichtete Hindernisvermeidung nicht verfügbar. Nur Infrarotsensoren verfügbar. Vorsichtig fliegen.",
    "en": "Upward sensor(s) blurry. Upward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution",
    "es": "Sensor(es) superior(es) borroso(s). Sistema anticolisión superior no disponible. Solo están disponibles los sensores de infrarrojos. Vuela con cuidado",
    "fr": "Capteur(s) supérieurs flou(s). Évitement des obstacles au-dessus non disponible. Seuls les capteurs infrarouges sont disponibles. Volez avec prudence",
    "ja": "上方センサーが不鮮明です。上方障害物回避を利用できません。赤外線センサーのみが利用できます。注意して飛行してください",
    "ko": "상방 센서가 흐릿합니다. 상방 장애물 회피를 사용할 수 없습니다. 적외선 센서만 사용할 수 있습니다. 주의하여 비행하세요.",
    "ru": "Размытое изображение с верхних датчиков. Обход препятствий сверху недоступен. Доступны только инфракрасные датчики. Будьте внимательны при полете",
    "tr": "",
    "zh": "上视可能脏污，上方视觉避障失效，仅红外传感器工作，请谨慎飞行"
  },
  "fpv_tip_0x1a420cc0": {
    "de": "Fluglagenwinkel des Fluggeräts zu groß. Hindernisvermeidung nicht verfügbar. Mit Vorsicht fliegen",
    "en": "Aircraft attitude angle too large. Obstacle avoidance unavailable. Fly with caution",
    "es": "Ángulo de posición de la aeronave demasiado grande. Sistema anticolisión no disponible. Vuela con cuidado",
    "fr": "Angle d\\'attitude de l\\'appareil trop important. Évitement d\\'obstacles indisponible. Volez avec précaution",
    "ja": "機体姿勢角が大きすぎます。障害物回避を利用できません。慎重に飛行してください",
    "ko": "기체 자세 각도가 너무 큽니다. 장애물 회피를 사용할 수 없습니다. 주의해서 비행하세요",
    "ru": "Слишком большой угол тангажа дрона. Обход препятствий недоступен. Будьте внимательны при полете",
    "tr": "",
    "zh": "飞行器姿态角过大，避障失效，请谨慎飞行"
  },
  "fpv_tip_0x1a420d00": {
    "de": "Fluglagenwinkel des Fluggeräts zu groß. Landeschutz nicht verfügbar. Manuell landen",
    "en": "Aircraft attitude angle too large. Landing protection unavailable. Land manually",
    "es": "Ángulo de posición de la aeronave demasiado grande. Protección durante el aterrizaje no disponible. Aterriza manualmente",
    "fr": "Angle d\\'attitude de l\\'appareil trop important. Protection à l\\'atterrissage indisponible. Atterrissez manuellement",
    "ja": "機体姿勢角が大きすぎます。着陸保護を利用できません。手動で着陸してください",
    "ko": "기체 자세 각도가 너무 큽니다. 착륙 보호 기능을 사용할 수 없습니다. 수동 착륙",
    "ru": "Слишком большой угол тангажа дрона. Безопасное приземление недоступно. Приземлитесь вручную",
    "tr": "",
    "zh": "飞行器姿态角过大，降落保护失效，请手动降落"
  },
  "fpv_tip_0x1a420d40": {
    "de": "Fluggerät nähert sich Hindernis mit totem Winkel. Hinderniserkennung evtl. nicht möglich. Mit Vorsicht fliegen",
    "en": "Aircraft approaching obstacle sensing blind spot and may be unable to detect obstacles. Fly with caution",
    "es": "La aeronave se está acercando al punto ciego de detección de obstáculos y es posible que no pueda detectar obstáculos. Vuela con cuidado",
    "fr": "L\\'appareil approche de l\\'angle mort de la détection d\\'obstacles et pourrait ne pas être en mesure de détecter les obstacles. Volez avec précaution",
    "ja": "機体が障害物検知死角に接近中。障害物を検知できない可能性があります。慎重に飛行してください",
    "ko": "기체가 장애물 감지 사각 지대에 접근하고 있으므로 장애물을 감지하지 못할 수도 있습니다. 주의해서 비행하세요",
    "ru": "Дрон приближается к слепой зоне определения препятствий, и, возможно, не сможет обнаружить препятствия. Будьте внимательны при полете",
    "tr": "",
    "zh": "正飞向障碍物感知盲区，可能无法检测障碍物，请谨慎飞行"
  },
  "fpv_tip_0x1a5103c0": {
    "de": "Kalibrierungsfehler: Sichtsensor",
    "en": "Downward vision sensor calibration error",
    "es": "Error de calibración del sensor visual inferior",
    "fr": "Erreur d\\'étalonnage du capteur optique inférieur",
    "ja": "下方ビジョンセンサーのキャリブレーションエラー",
    "ko": "비전 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки нижнего датчика обзора",
    "tr": "",
    "zh": "下视传感器标定异常"
  },
  "fpv_tip_0x1a5103c1": {
    "de": "Kalibrierungsfehler: Vorwärtsgerichtete Sichtsensoren",
    "en": "Forward vision sensor calibration error",
    "es": "Error de calibración del sensor visual frontal",
    "fr": "Erreur d\\'étalonnage du capteur optique avant",
    "ja": "前方ビジョンセンサーのキャリブレーションエラー",
    "ko": "전방 비전 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки переднего датчика обзора",
    "tr": "",
    "zh": "前视传感器标定异常"
  },
  "fpv_tip_0x1a5103c2": {
    "de": "Kalibrierungsfehler: Rückwärtsgerichtete Sichtsensoren",
    "en": "Backward vision sensor calibration error",
    "es": "Error de calibración del sensor visual trasero",
    "fr": "Erreur d\\'étalonnage du capteur optique arrière",
    "ja": "後方ビジョンセンサーのキャリブレーションエラー",
    "ko": "후방 비전 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки заднего датчика обзора",
    "tr": "",
    "zh": "后视传感器标定异常"
  },
  "fpv_tip_0x1a5103c3": {
    "de": "Kalibrierungsfehler: Aufwärtsgerichtete Sichtsensoren",
    "en": "Upward vision sensor calibration error",
    "es": "Error de calibración del sensor visual superior",
    "fr": "Erreur d\\'étalonnage du capteur optique supérieur",
    "ja": "上方ビジョンセンサーのキャリブレーションエラー",
    "ko": "상향 비전 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки верхнего датчика обзора",
    "tr": "",
    "zh": "上视传感器标定异常"
  },
  "fpv_tip_0x1a5103c4": {
    "de": "Kalibrierungsfehler: Linksgerichtete Sichtsensoren",
    "en": "Left vision sensor calibration error",
    "es": "Error de calibración del sensor visual izquierdo",
    "fr": "Erreur d\\'étalonnage du capteur optique gauche",
    "ja": "左ビジョンセンサーのキャリブレーションエラー",
    "ko": "좌측 비전 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки левого датчика обзора",
    "tr": "",
    "zh": "左视传感器标定异常"
  },
  "fpv_tip_0x1a5103c5": {
    "de": "Kalibrierungsfehler: Rechtsgerichtete Sichtsensoren",
    "en": "Right vision sensor calibration error",
    "es": "Error de calibración del sensor visual derecho",
    "fr": "Erreur d\\'étalonnage du capteur optique droit",
    "ja": "右ビジョンセンサーのキャリブレーションエラー",
    "ko": "우측 비전 센서 캘리브레이션 오류",
    "ru": "Ошибка калибровки правого датчика обзора",
    "tr": "",
    "zh": "右视传感器标定异常"
  },
  "fpv_tip_0x1afd0040": {
    "de": "Fehler: Sensorsystem. Kann nicht abheben. Fliegen untersagt",
    "en": "Sensor system error. Unable to take off. Flight prohibited",
    "es": "Error del sistema del sensor. No se puede despegar. Vuelo prohibido",
    "fr": "Erreur système de capteurs. Décollage impossible. Vol interdit",
    "it": "",
    "ja": "センサーシステムエラー。離陸できません。飛行が禁止されています",
    "ko": "센서 시스템 오류. 이륙 불가. 비행 금지",
    "pt": "",
    "ru": "Ошибка системы датчиков. Невозможно выполнить взлет. Полет запрещен",
    "tr": "Sensör sistem hatası. Kalkış yapamıyor. Uçuş yasaklandı",
    "zh": "无法起飞：感知传感器系统异常，禁止起飞"
  },
  "fpv_tip_0x1afd0040_in_the_sky": {
    "de": "Fehler: Sensorsystem. Sofort zurückkehren oder landen",
    "en": "Sensor system error. Return to home or land promptly",
    "es": "Error del sistema del sensor. Regresa al punto de origen o aterriza lo antes posible",
    "fr": "Erreur système de capteurs. Revenez au point de départ et atterrissez immédiatement",
    "it": "",
    "ja": "センサーシステムエラー。直ちに帰還もしくは着陸してください",
    "ko": "센서 시스템 오류. 즉시 RTH 또는 착륙 필요",
    "pt": "",
    "ru": "Ошибка системы датчиков. Вернитесь домой или приземлитесь немедленно",
    "tr": "Sensör sistem hatası. Eve geri dönün veya düzgün iniş yapın",
    "zh": "感知传感器系统异常，请尽快返航或降落"
  },
  "fpv_tip_0x1afe0040": {
    "de": "Sichtsensoren überlastet. In offenen Bereich fliegen",
    "en": "Vision system overloaded. Fly to open area",
    "es": "Sistema de visión sobrecargado. Vuela a un espacio abierto",
    "fr": "Système optique surchargé. Volez dans une zone dégagée",
    "ja": "ビジョンシステム過負荷状態。開けた場所に飛行してください",
    "ko": "비전 시스템 과부하. 개방 영역으로 비행하세요",
    "ru": "Система обзора перегружена. Подлетите к открытому участку",
    "tr": "",
    "zh": "视觉系统负载过高，请谨慎飞行至开阔环境"
  },
  "fpv_tip_0x1b030019": {
    "de": "Fluggerät fliegt nicht entlang der Route. Sichere Rückkehrfunktion beendet",
    "en": "Aircraft not flying along route. Safe RTH exited",
    "es": "Aeronave que no vuela por la ruta. Salió de RPO seguro",
    "fr": "L'appareil ne suit pas l'itinéraire. RTH sécurisé quitté",
    "ja": "機体はルートに沿って飛行していません。セーフRTHが終了しました",
    "ko": "경로에 따라 비행하지 않는 기체. 안전한 RTH 종료됨",
    "ru": "Дрон не летит по маршруту. Функция безопасного возврата домой отключена",
    "tr": "Hava aracı rota üzerinde uçmuyor. Güvenli BND'den çıkıldı",
    "zh": "飞行器已偏离航线，航线安全返航失效"
  },
  "fpv_tip_0x1b080001": {
    "en": "Remote ID broadcast error. Failed to obtain remote controller location",
    "zh": "Remote ID 播报异常，无法获取遥控器位置"
  },
  "fpv_tip_0x1b080002": {
    "en": "Remote ID module error. Contact DJI Support",
    "zh": "Remote ID 模块异常，请联系售后服务"
  },
  "fpv_tip_0x1c000603": {
    "de": "Evtl. Überbelichtung im Foto",
    "en": "Risk of overexposure in photo",
    "es": "Riesgo de sobreexposición en la foto",
    "fr": "Risque de surexposition photo",
    "ja": "写真の露出オーバーのリスク",
    "ko": "사진 과다 노출 위험",
    "ru": "Риск переэкспонирования на фото",
    "tr": "Fotoğrafta aşırı pozlama riski",
    "zh": "照片存在欠曝风险"
  },
  "fpv_tip_0x1c000604": {
    "de": "Evtl. Überbelichtung im Foto",
    "en": "Risk of overexposure in photo",
    "es": "Riesgo de sobreexposición en la foto",
    "fr": "Risque de surexposition photo",
    "ja": "写真の露出オーバーのリスク",
    "ko": "사진 과다 노출 위험",
    "ru": "Риск переэкспонирования на фото",
    "tr": "Fotoğrafta aşırı pozlama riski",
    "zh": "照片存在过曝风险"
  },
  "fpv_tip_0x1c100405": {
    "de": "Lese- und Schreibberechtigungen für Speicherkarte bestätigen",
    "en": "Confirm memory card read and write permissions",
    "es": "Confirme los permisos de lectura y escritura de la tarjeta de memoria",
    "fr": "Confirmez les autorisations de lecture et d'écriture de la carte mémoire",
    "ja": "メモリーカードの読み取り／書き込み権限を確認してください",
    "ko": "메모리 카드 읽기 및 쓰기 권한을 확인하세요",
    "ru": "Подтвердите наличие разрешений на чтение и запись карты памяти",
    "tr": "Hafıza kartı okuma ve yazma izinlerini onaylayın",
    "zh": "请确认存储卡读写属性"
  },
  "fpv_tip_0x1c100405_index_1": {
    "de": "eMMC-Lese- und Schreibeigenschaften bestätigen",
    "en": "Confirm eMMC read and write properties",
    "es": "",
    "fr": "Confirmez les propriétés de lecture et d'écriture de l'eMMC",
    "ja": "eMMCの読み取り/書き込みプロパティを確認",
    "ko": "eMMC 읽기 및 쓰기 속성을 확인하세요.",
    "ru": "Подтвердите наличие разрешений на чтений и запись eMMC-накопителя",
    "tr": "",
    "zh": "请确认EMMC读写属性"
  },
  "fpv_tip_0x1c100406": {
    "de": "Speicherkarte nicht formatiert. Karte vor Gebrauch formatieren.",
    "en": "Memory card not formatted. Format card before use",
    "es": "Tarjeta de memoria no formateada. Formatee la tarjeta antes del uso",
    "fr": "Carte mémoire non formatée. Formatez la carte avant utilisation",
    "ja": "メモリーカードがフォーマットされていません。 カードをフォーマットしてから使用してください",
    "ko": "메모리 카드가 포맷되지 않았습니다. 사용 전에 카드를 포맷하세요",
    "ru": "Карта памяти не отформатирована. Отформатируйте карту перед использованием",
    "tr": "Hafıza kartı biçimlendirilmemiş. Kullanmadan önce kartı biçimlendirin",
    "zh": "存储卡未格式化，请格式化后使用"
  },
  "fpv_tip_0x1c100406_index_1": {
    "de": "eMMC nicht formatiert. Vor Verwendung formatieren",
    "en": "eMMC not formatted. Format before use",
    "es": "",
    "fr": "eMMC non formaté. Formatez avant utilisation",
    "ja": "eMMCはフォーマットされていません。フォーマットしてから、使用してください",
    "ko": "eMMC가 포맷되지 않았습니다. 사용 전에 포맷하세요.",
    "ru": "eMMC-накопитель не отформатирован. Отформатируйте перед использованием",
    "tr": "",
    "zh": "EMMC未格式化，请格式化后使用"
  },
  "fpv_tip_0x1c100407": {
    "de": "Speicherkarte wird formatiert...",
    "en": "Formatting memory card...",
    "es": "Formateando la tarjeta de memoria...",
    "fr": "Formatage de la carte mémoire…",
    "ja": "メモリーカードのフォーマット中...",
    "ko": "메모리 카드 포맷 중...",
    "ru": "Форматирование карты памяти...",
    "tr": "Hafıza kartı biçimlendiriliyor...",
    "zh": "存储卡正在格式化，请等待"
  },
  "fpv_tip_0x1c100407_index_1": {
    "de": "eMMC-Formatierung. Bitte warten",
    "en": "eMMC formatting. Please wait",
    "es": "",
    "fr": "Formatage de l'eMMC en cours. Veuillez patienter",
    "ja": "eMMCをフォーマットしています。お待ちください",
    "ko": "eMMC를 포맷 중입니다. 기다려 주세요.",
    "ru": "Форматирование eMMC. Подождите",
    "tr": "",
    "zh": "EMMC正在格式化，请等待"
  },
  "fpv_tip_0x1c100408": {
    "de": "Dateisystem der Speicherkarte wird nicht unterstützt. Karte vor Gebrauch formatieren.",
    "en": "Memory card file system not supported. Format card before use",
    "es": "Sistema de archivos de la tarjeta de memoria no compatible. Formatee la tarjeta antes del uso",
    "fr": "Système de fichiers de la carte mémoire non pris en charge. Formatez la carte avant utilisation",
    "ja": "メモリーカードファイルシステムはサポートされていません。 カードをフォーマットしてから使用してください",
    "ko": "메모리 카드 파일 시스템이 지원되지 않습니다. 사용 전에 카드를 포맷하세요",
    "ru": "Файловая система карты памяти не поддерживается. Отформатируйте карту перед использованием",
    "tr": "Hafıza kartı dosya sistemi desteklenmiyor. Kullanmadan önce kartı biçimlendirin",
    "zh": "不支持该存储卡文件系统，请格式化后使用"
  },
  "fpv_tip_0x1c100409": {
    "de": "Speicherkarte wird aktualisiert...",
    "en": "Refreshing memory card...",
    "es": "Actualizando la tarjeta de memoria...",
    "fr": "Actualisation de la carte mémoire…",
    "ja": "メモリーカードの更新中...",
    "ko": "메모리 카드 새로고침 중...",
    "ru": "Обновление карты памяти...",
    "tr": "Hafıza kartı yenileniyor...",
    "zh": "存储卡正在刷新，请等待"
  },
  "fpv_tip_0x1c100409_index_1": {
    "de": "eMMC-Aktualisierung. Bitte warten",
    "en": "eMMC refreshing. Please wait",
    "es": "",
    "fr": "Actualisation de l'eMMC en cours. Veuillez patienter",
    "ja": "eMMCを更新しています。お待ちください",
    "ko": "eMMC를 새로 고치는 중입니다. 기다려 주세요.",
    "ru": "Обновление eMMC. Подождите",
    "tr": "",
    "zh": "EMMC正在刷新，请等待"
  },
  "fpv_tip_0x1c10040B": {
    "de": "Max. Dateiindex überschritten. SD-Karte formatieren",
    "en": "Max file index exceeded. Format SD card",
    "es": "Se ha superado el índice máximo de archivos. Formatear tarjeta SD",
    "fr": "Index de fichier max. dépassé. Formater la carte SD",
    "ja": "ファイルインデックスの最大値を超えました。SDカードをフォーマット",
    "ko": "최대 파일 색인을 초과함. SD 카드 포맷",
    "ru": "Превышен максимальный индекс файла. Форматировать карту памяти SD",
    "tr": "Maks. dosya dizini aşıldı. SD kartı biçimlendir",
    "zh": "文件索引超出最大限制，请格式化SD卡"
  },
  "fpv_tip_0x1c10040a": {
    "de": "Speicherkarte voll. Speicherplatz freigeben.",
    "en": "Memory card full. Clear space",
    "es": "Tarjeta de memoria llena. Libere espacio",
    "fr": "Carte mémoire pleine. Libérez de l'espace",
    "ja": "メモリーカードが満杯です。 領域をクリア",
    "ko": "메모리 카드 공간 없음. 공간을 확보하세요",
    "ru": "Карта памяти заполнена. Освободите место",
    "tr": "Hafıza kartı dolu. Yer açın",
    "zh": "存储卡已满，请清除内存"
  },
  "fpv_tip_0x1c10040a_index_1": {
    "de": "eMMC voll. Speicherplatz freigeben",
    "en": "eMMC full. Clear space",
    "es": "",
    "fr": "Stockage eMMC saturé. Libérez de l'espace",
    "ja": "eMMCに空き容量がありません。空き容量を増やしてください",
    "ko": "eMMC가 가득 찼습니다. 공간을 정리하세요.",
    "ru": "eMMC-накопитель заполнен. Освободите место",
    "tr": "",
    "zh": "EMMC已满，请清除内存"
  },
  "fpv_tip_0x1c10040b": {
    "de": "Max. Dateiindex überschritten. SD-Karte formatieren",
    "en": "Max file index exceeded. Format SD card",
    "es": "Se ha superado el índice máximo de archivos. Formatear tarjeta SD",
    "fr": "Index de fichier max. dépassé. Formater la carte SD",
    "ja": "ファイルインデックスの最大値を超えました。SDカードをフォーマット",
    "ko": "최대 파일 색인을 초과함. SD 카드 포맷",
    "ru": "Превышен максимальный индекс файла. Форматировать карту памяти SD",
    "tr": "Maks. dosya dizini aşıldı. SD kartı biçimlendir",
    "zh": "文件索引超出最大限制，请格式化SD卡"
  },
  "fpv_tip_0x1c10040b_index_1": {
    "de": "eMMC-Speicherüberlauf. eMMC formatieren und Kamera neu starten",
    "en": "eMMC memory overflow. Format eMMC and restart camera",
    "es": "",
    "fr": "Débordement de la mémoire eMMC. Formatez l'eMMC et redémarrez la caméra",
    "ja": "eMMCメモリのオーバーフローが発生しました。eMMCをフォーマットして、カメラを再起動してください",
    "ko": "eMMC 메모리 오버플로입니다. eMMC를 포맷하고 카메라를 다시 시작하세요.",
    "ru": "Переполнение памяти eMMC. Отформатируйте eMMC и перезапустите камеру",
    "tr": "",
    "zh": "EMMC内存溢出，请格式化EMMC并重启相机"
  },
  "fpv_tip_0x1c10040c": {
    "de": "Speicherkarte wird initialisiert...",
    "en": "Initializing memory card...",
    "es": "Inicializando tarjeta de memoria...",
    "fr": "Initialisation de la carte mémoire…",
    "ja": "メモリーカードの初期化中...",
    "ko": "메모리 카드 초기화 중...",
    "ru": "Инициализация карты памяти...",
    "tr": "Hafıza kartı başlatılıyor...",
    "zh": "存储卡正在初始化，请等待"
  },
  "fpv_tip_0x1c10040c_index_1": {
    "de": "eMMC-Initialisierung. Bitte warten",
    "en": "eMMC initializing. Please wait",
    "es": "",
    "fr": "Initialisation de l'eMMC en cours. Veuillez patienter",
    "ja": "eMMCを初期化しています。お待ちください",
    "ko": "eMMC를 초기화 중입니다. 기다려 주세요.",
    "ru": "Инициализация eMMC. Подождите",
    "tr": "",
    "zh": "EMMC正在初始化，请等待"
  },
  "fpv_tip_0x1c10040d": {
    "de": "Speicherkartenfehler. Karte vor Gebrauch formatieren.",
    "en": "Memory card error. Format card before use",
    "es": "Error de la tarjeta de memoria. Formatee la tarjeta antes del uso",
    "fr": "Erreur de carte mémoire. Formatez la carte avant utilisation",
    "ja": "メモリーカードにエラーが発生しました。 カードをフォーマットしてから使用してください",
    "ko": "메모리 카드 오류. 사용 전에 카드를 포맷하세요",
    "ru": "Ошибка карты памяти. Отформатируйте карту перед использованием",
    "tr": "Hafıza kartı hatası. Kullanmadan önce kartı biçimlendirin",
    "zh": "存储卡异常，请格式化存储卡后使用"
  },
  "fpv_tip_0x1c10040d_index_1": {
    "de": "eMMC-Fehler. Vor Verwendung formatieren",
    "en": "eMMC error. Format before use",
    "es": "",
    "fr": "Erreur eMMC. Formatez avant utilisation",
    "ja": "eMMCのエラーです。フォーマットしてから、使用してください",
    "ko": "eMMC 오류입니다. 사용 전에 포맷하세요.",
    "ru": "Ошибка eMMC. Отформатируйте перед использованием",
    "tr": "",
    "zh": "EMMC异常，请格式化EMMC后使用"
  },
  "fpv_tip_0x1c10040e": {
    "de": "Speicherkarte wird repariert...",
    "en": "Fixing memory card...",
    "es": "Corrigiendo tarjeta de memoria...",
    "fr": "Réparation de la carte mémoire…",
    "ja": "メモリーカードの修正中...",
    "ko": "메모리 카드 문제 해결 중...",
    "ru": "Исправление карты памяти...",
    "tr": "Hafıza kartı düzeltiliyor...",
    "zh": "存储卡修复中，请等待"
  },
  "fpv_tip_0x1c10040e_index_1": {
    "de": "eMMC-Reparatur wird ausgeführt. Bitte warten",
    "en": "eMMC repair in progress. Please wait",
    "es": "",
    "fr": "Réparation de l'eMMC en cours. Veuillez patienter",
    "ja": "eMMCを修復しています。お待ちください",
    "ko": "eMMC 수리가 진행 중입니다. 기다려 주세요.",
    "ru": "Идет восстановление eMMC. Подождите",
    "tr": "",
    "zh": "EMMC修复中，请等待"
  },
  "fpv_tip_0x1c10040f": {
    "de": "Niedrige Lese- und Schreibgeschwindigkeit für Speicherkarte. Warten, bis der Vorgang abgeschlossen wurde.",
    "en": "Memory card read and write speed low. Wait until process completes",
    "es": "Velocidad baja de lectura y escritura de la tarjeta de memoria. Espere hasta que se complete el proceso",
    "fr": "Vitesse de lecture et d'écriture de la carte mémoire lente. Attendez que le processus soit terminé",
    "ja": "メモリーカード読み取り／書き込みが低速です。 プロセスが完了するまでお待ちください",
    "ko": "메모리 카드 읽기 및 쓰기 속도가 느립니다. 프로세스가 완료될 때까지 기다리세요",
    "ru": "Низкая скорость чтения и записи карты памяти. Подождите, пока процесс завершится",
    "tr": "Hafıza kartının okuma ve yazma hızı düşük. İşlem tamamlanana kadar bekleyin",
    "zh": "存储卡读写缓慢，请等待"
  },
  "fpv_tip_0x1c10040f_index_1": {
    "de": "eMMC-Lesen und -Schreiben langsam. Bitte warten",
    "en": "eMMC read and write speed low. Please wait",
    "es": "",
    "fr": "Vitesse de lecture et d'écriture de l'eMMC basse. Veuillez patienter",
    "ja": "eMMCの読み取り/書き込み速度が低下しています。お待ちください",
    "ko": "eMMC 읽기 및 쓰기 속도가 느립니다. 기다려 주세요.",
    "ru": "Низкая скорость чтения и записи eMMC. Подождите",
    "tr": "",
    "zh": "EMMC读写缓慢，请等待"
  },
  "fpv_tip_0x1c200405_index_1": {
    "de": "eMMC-Lese- und Schreibeigenschaften bestätigen",
    "en": "Confirm eMMC read and write properties",
    "es": "",
    "fr": "Confirmez les propriétés de lecture et d'écriture de l'eMMC",
    "ja": "eMMCの読み取り/書き込みプロパティを確認",
    "ko": "eMMC 읽기 및 쓰기 속성을 확인하세요.",
    "ru": "Подтвердите наличие разрешений на чтений и запись eMMC-накопителя",
    "tr": "",
    "zh": "请确认EMMC读写属性"
  },
  "fpv_tip_0x1c200406_index_1": {
    "de": "eMMC nicht formatiert. Vor Verwendung formatieren",
    "en": "eMMC not formatted. Format before use",
    "es": "",
    "fr": "eMMC non formaté. Formatez avant utilisation",
    "ja": "eMMCはフォーマットされていません。フォーマットしてから、使用してください",
    "ko": "eMMC가 포맷되지 않았습니다. 사용 전에 포맷하세요.",
    "ru": "eMMC-накопитель не отформатирован. Отформатируйте перед использованием",
    "tr": "",
    "zh": "EMMC未格式化，请格式化后使用"
  },
  "fpv_tip_0x1c200407_index_1": {
    "de": "eMMC-Formatierung. Bitte warten",
    "en": "eMMC formatting. Please wait",
    "es": "",
    "fr": "Formatage de l'eMMC en cours. Veuillez patienter",
    "ja": "eMMCをフォーマットしています。お待ちください",
    "ko": "eMMC를 포맷 중입니다. 기다려 주세요.",
    "ru": "Форматирование eMMC. Подождите",
    "tr": "",
    "zh": "EMMC正在格式化，请等待"
  },
  "fpv_tip_0x1c200409_index_1": {
    "de": "eMMC-Aktualisierung. Bitte warten",
    "en": "eMMC refreshing. Please wait",
    "es": "",
    "fr": "Actualisation de l'eMMC en cours. Veuillez patienter",
    "ja": "eMMCを更新しています。お待ちください",
    "ko": "eMMC를 새로 고치는 중입니다. 기다려 주세요.",
    "ru": "Обновление eMMC. Подождите",
    "tr": "",
    "zh": "EMMC正在刷新，请等待"
  },
  "fpv_tip_0x1c20040a_index_1": {
    "de": "eMMC voll. Speicherplatz freigeben",
    "en": "eMMC full. Clear space",
    "es": "",
    "fr": "Stockage eMMC saturé. Libérez de l'espace",
    "ja": "eMMCに空き容量がありません。空き容量を増やしてください",
    "ko": "eMMC가 가득 찼습니다. 공간을 정리하세요.",
    "ru": "eMMC-накопитель заполнен. Освободите место",
    "tr": "",
    "zh": "EMMC已满，请清除内存"
  },
  "fpv_tip_0x1c20040b": {
    "de": "Max. Dateiindex überschritten. SD-Karte formatieren",
    "en": "Max file index exceeded. Format SD card",
    "es": "Se ha superado el índice máximo de archivos. Formatear tarjeta SD",
    "fr": "Index de fichier max. dépassé. Formater la carte SD",
    "ja": "ファイルインデックスの最大値を超えました。SDカードをフォーマット",
    "ko": "최대 파일 색인을 초과함. SD 카드 포맷",
    "ru": "Превышен максимальный индекс файла. Форматировать карту памяти SD",
    "tr": "Maks. dosya dizini aşıldı. SD kartı biçimlendir",
    "zh": "文件索引超出最大限制，请格式化SD卡"
  },
  "fpv_tip_0x1c20040b_index_1": {
    "de": "eMMC-Speicherüberlauf. eMMC formatieren und Kamera neu starten",
    "en": "eMMC memory overflow. Format eMMC and restart camera",
    "es": "",
    "fr": "Débordement de la mémoire eMMC. Formatez l'eMMC et redémarrez la caméra",
    "ja": "eMMCメモリのオーバーフローが発生しました。eMMCをフォーマットして、カメラを再起動してください",
    "ko": "eMMC 메모리 오버플로입니다. eMMC를 포맷하고 카메라를 다시 시작하세요.",
    "ru": "Переполнение памяти eMMC. Отформатируйте eMMC и перезапустите камеру",
    "tr": "",
    "zh": "EMMC内存溢出，请格式化EMMC并重启相机"
  },
  "fpv_tip_0x1c20040c_index_1": {
    "de": "eMMC-Initialisierung. Bitte warten",
    "en": "eMMC initializing. Please wait",
    "es": "",
    "fr": "Initialisation de l'eMMC en cours. Veuillez patienter",
    "ja": "eMMCを初期化しています。お待ちください",
    "ko": "eMMC를 초기화 중입니다. 기다려 주세요.",
    "ru": "Инициализация eMMC. Подождите",
    "tr": "",
    "zh": "EMMC正在初始化，请等待"
  },
  "fpv_tip_0x1c20040d_index_1": {
    "de": "eMMC-Fehler. Vor Verwendung formatieren",
    "en": "eMMC error. Format before use",
    "es": "",
    "fr": "Erreur eMMC. Formatez avant utilisation",
    "ja": "eMMCのエラーです。フォーマットしてから、使用してください",
    "ko": "eMMC 오류입니다. 사용 전에 포맷하세요.",
    "ru": "Ошибка eMMC. Отформатируйте перед использованием",
    "tr": "",
    "zh": "EMMC异常，请格式化EMMC后使用"
  },
  "fpv_tip_0x1c20040e_index_1": {
    "de": "eMMC-Reparatur wird ausgeführt. Bitte warten",
    "en": "eMMC repair in progress. Please wait",
    "es": "",
    "fr": "Réparation de l'eMMC en cours. Veuillez patienter",
    "ja": "eMMCを修復しています。お待ちください",
    "ko": "eMMC 수리가 진행 중입니다. 기다려 주세요.",
    "ru": "Идет восстановление eMMC. Подождите",
    "tr": "",
    "zh": "EMMC修复中，请等待"
  },
  "fpv_tip_0x1c20040f_index_1": {
    "de": "eMMC-Lesen und -Schreiben langsam. Bitte warten",
    "en": "eMMC read and write speed low. Please wait",
    "es": "",
    "fr": "Vitesse de lecture et d'écriture de l'eMMC basse. Veuillez patienter",
    "ja": "eMMCの読み取り/書き込み速度が低下しています。お待ちください",
    "ko": "eMMC 읽기 및 쓰기 속도가 느립니다. 기다려 주세요.",
    "ru": "Низкая скорость чтения и записи eMMC. Подождите",
    "tr": "",
    "zh": "EMMC读写缓慢，请等待"
  },
  "fpv_tip_0x1c200410": {
    "de": "Überprüfung vor Verwendung der Speicherkarte erforderlich. Passwort zur Verifizierung eingeben.",
    "en": "Verification required before using memory card. Enter password to verify",
    "es": "Se requiere verificación antes de usar la tarjeta de memoria. Introduce la contraseña para verificar",
    "fr": "Vérification requise avant d'utiliser la carte mémoire. Entrez le mot de passe pour vérifier",
    "ja": "メモリーカードの使用前に検証が必要です。 確認用パスワードを入力",
    "ko": "메모리 카드를 사용하기 전에 인증이 필요합니다. 인증을 위해 비밀번호를 입력하세요",
    "ru": "Требуется проверка перед использованием карты памяти. Введите пароль для проверки",
    "tr": "Hafıza kartını kullanmadan önce doğrulama gerekli. Doğrulamak için parolayı girin",
    "zh": "存储卡加密待验证，请输入密码验证后使用"
  },
  "fpv_tip_0x1c300405_index_1": {
    "de": "eMMC-Lese- und Schreibeigenschaften bestätigen",
    "en": "Confirm eMMC read and write properties",
    "es": "",
    "fr": "Confirmez les propriétés de lecture et d'écriture de l'eMMC",
    "ja": "eMMCの読み取り/書き込みプロパティを確認",
    "ko": "eMMC 읽기 및 쓰기 속성을 확인하세요.",
    "ru": "Подтвердите наличие разрешений на чтений и запись eMMC-накопителя",
    "tr": "",
    "zh": "请确认EMMC读写属性"
  },
  "fpv_tip_0x1c300406_index_1": {
    "de": "eMMC nicht formatiert. Vor Verwendung formatieren",
    "en": "eMMC not formatted. Format before use",
    "es": "",
    "fr": "eMMC non formaté. Formatez avant utilisation",
    "ja": "eMMCはフォーマットされていません。フォーマットしてから、使用してください",
    "ko": "eMMC가 포맷되지 않았습니다. 사용 전에 포맷하세요.",
    "ru": "eMMC-накопитель не отформатирован. Отформатируйте перед использованием",
    "tr": "",
    "zh": "EMMC未格式化，请格式化后使用"
  },
  "fpv_tip_0x1c300407_index_1": {
    "de": "eMMC-Formatierung. Bitte warten",
    "en": "eMMC formatting. Please wait",
    "es": "",
    "fr": "Formatage de l'eMMC en cours. Veuillez patienter",
    "ja": "eMMCをフォーマットしています。お待ちください",
    "ko": "eMMC를 포맷 중입니다. 기다려 주세요.",
    "ru": "Форматирование eMMC. Подождите",
    "tr": "",
    "zh": "EMMC正在格式化，请等待"
  },
  "fpv_tip_0x1c300409_index_1": {
    "de": "eMMC-Aktualisierung. Bitte warten",
    "en": "eMMC refreshing. Please wait",
    "es": "",
    "fr": "Actualisation de l'eMMC en cours. Veuillez patienter",
    "ja": "eMMCを更新しています。お待ちください",
    "ko": "eMMC를 새로 고치는 중입니다. 기다려 주세요.",
    "ru": "Обновление eMMC. Подождите",
    "tr": "",
    "zh": "EMMC正在刷新，请等待"
  },
  "fpv_tip_0x1c30040a_index_1": {
    "de": "eMMC voll. Speicherplatz freigeben",
    "en": "eMMC full. Clear space",
    "es": "",
    "fr": "Stockage eMMC saturé. Libérez de l'espace",
    "ja": "eMMCに空き容量がありません。空き容量を増やしてください",
    "ko": "eMMC가 가득 찼습니다. 공간을 정리하세요.",
    "ru": "eMMC-накопитель заполнен. Освободите место",
    "tr": "",
    "zh": "EMMC已满，请清除内存"
  },
  "fpv_tip_0x1c30040b": {
    "de": "Max. Dateiindex überschritten. SD-Karte formatieren",
    "en": "Max file index exceeded. Format SD card",
    "es": "Se ha superado el índice máximo de archivos. Formatear tarjeta SD",
    "fr": "Index de fichier max. dépassé. Formater la carte SD",
    "ja": "ファイルインデックスの最大値を超えました。SDカードをフォーマット",
    "ko": "최대 파일 색인을 초과함. SD 카드 포맷",
    "ru": "Превышен максимальный индекс файла. Форматировать карту памяти SD",
    "tr": "Maks. dosya dizini aşıldı. SD kartı biçimlendir",
    "zh": "文件索引超出最大限制，请格式化SD卡"
  },
  "fpv_tip_0x1c30040b_index_1": {
    "de": "eMMC-Speicherüberlauf. eMMC formatieren und Kamera neu starten",
    "en": "eMMC memory overflow. Format eMMC and restart camera",
    "es": "",
    "fr": "Débordement de la mémoire eMMC. Formatez l'eMMC et redémarrez la caméra",
    "ja": "eMMCメモリのオーバーフローが発生しました。eMMCをフォーマットして、カメラを再起動してください",
    "ko": "eMMC 메모리 오버플로입니다. eMMC를 포맷하고 카메라를 다시 시작하세요.",
    "ru": "Переполнение памяти eMMC. Отформатируйте eMMC и перезапустите камеру",
    "tr": "",
    "zh": "EMMC内存溢出，请格式化EMMC并重启相机"
  },
  "fpv_tip_0x1c30040c_index_1": {
    "de": "eMMC-Initialisierung. Bitte warten",
    "en": "eMMC initializing. Please wait",
    "es": "",
    "fr": "Initialisation de l'eMMC en cours. Veuillez patienter",
    "ja": "eMMCを初期化しています。お待ちください",
    "ko": "eMMC를 초기화 중입니다. 기다려 주세요.",
    "ru": "Инициализация eMMC. Подождите",
    "tr": "",
    "zh": "EMMC正在初始化，请等待"
  },
  "fpv_tip_0x1c30040d_index_1": {
    "de": "eMMC-Fehler. Vor Verwendung formatieren",
    "en": "eMMC error. Format before use",
    "es": "",
    "fr": "Erreur eMMC. Formatez avant utilisation",
    "ja": "eMMCのエラーです。フォーマットしてから、使用してください",
    "ko": "eMMC 오류입니다. 사용 전에 포맷하세요.",
    "ru": "Ошибка eMMC. Отформатируйте перед использованием",
    "tr": "",
    "zh": "EMMC异常，请格式化EMMC后使用"
  },
  "fpv_tip_0x1c30040e_index_1": {
    "de": "eMMC-Reparatur wird ausgeführt. Bitte warten",
    "en": "eMMC repair in progress. Please wait",
    "es": "",
    "fr": "Réparation de l'eMMC en cours. Veuillez patienter",
    "ja": "eMMCを修復しています。お待ちください",
    "ko": "eMMC 수리가 진행 중입니다. 기다려 주세요.",
    "ru": "Идет восстановление eMMC. Подождите",
    "tr": "",
    "zh": "EMMC修复中，请等待"
  },
  "fpv_tip_0x1c30040f_index_1": {
    "de": "eMMC-Lesen und -Schreiben langsam. Bitte warten",
    "en": "eMMC read and write speed low. Please wait",
    "es": "",
    "fr": "Vitesse de lecture et d'écriture de l'eMMC basse. Veuillez patienter",
    "ja": "eMMCの読み取り/書き込み速度が低下しています。お待ちください",
    "ko": "eMMC 읽기 및 쓰기 속도가 느립니다. 기다려 주세요.",
    "ru": "Низкая скорость чтения и записи eMMC. Подождите",
    "tr": "",
    "zh": "EMMC读写缓慢，请等待"
  },
  "fpv_tip_0x1f0b0017": {
    "de": "LTE-Übertragung nicht verfügbar. Netzwerkkonnektivität der Fernsteuerung überprüfen",
    "en": "LTE Transmission unavailable. Check remote controller network connectivity",
    "es": "Transmisión LTE no disponible. Comprueba la conectividad de red del control remoto",
    "fr": "Transmission LTE non disponible. Vérifiez la connectivité réseau de la radiocommande",
    "ja": "LTE通信は利用できません。リモートコントローラーのネットワーク接続を確認してください",
    "ko": "LTE 전송을 사용할 수 없습니다. 조종기 네트워크 연결을 확인하세요.",
    "ru": "",
    "tr": "",
    "zh": "LTE链路不可用，请检查遥控器的网络能力"
  },
  "fpv_tip_0x20010100": {
    "de": "Fernsteuerung %2$s hat die Steuerung von Gimbal %1$s übernommen",
    "en": "Remote controller %2$s obtained gimbal %1$s control",
    "es": "El control remoto %2$s ha obtenido el control del estabilizador %1$s",
    "fr": "La radiocommande %2$s a obtenu le contrôle de la nacelle %1$s",
    "ja": "送信機%2$sがジンバル%1$sの制御を取得しました",
    "ko": "RC %2$s 짐벌 %1$s 제어권 가져옴",
    "ru": "Пульт %2$s получил управление стабилизатором %1$s",
    "tr": "%2$s uzaktan kumandası, %1$s gimbalının kontrolünü aldı",
    "zh": "云台%1$s的控制权已被%2$s控获取"
  },
  "fpv_tip_0x20010200": {
    "de": "Steuerung des aktuellen Gimbals übernommen",
    "en": "Obtained control of current gimbal",
    "es": "Se ha obtenido el control del estabilizador actual",
    "fr": "Contrôle de la nacelle actuelle obtenu",
    "ja": "現在のジンバルの制御を取得しました",
    "ko": "현재 짐벌 제어권 가져옴",
    "ru": "Получено управление данным стабилизатором",
    "tr": "Mevcut gimbalın kontrolünü aldı",
    "zh": "已成功获取该云台的控制权"
  },
  "fpv_tip_0x20010301": {
    "de": "Freier Modus",
    "en": "Free-mode",
    "es": "Modo Libre",
    "fr": "Mode libre",
    "ja": "フリーモード",
    "ko": "프리 모드",
    "ru": "Свободный",
    "tr": "Serbest Mod",
    "zh": "自由模式"
  },
  "fpv_tip_0x20010302": {
    "de": "Folgen",
    "en": "Follow",
    "es": "Seguir",
    "fr": "Suivre",
    "ja": "追跡",
    "ko": "팔로우",
    "ru": "Следование",
    "tr": "İzle",
    "zh": "跟随模式"
  },
  "fpv_tip_0x20010304": {
    "de": "Wechsel des Gimbal-Modus fehlgeschlagen. Bitte später erneut versuchen.",
    "en": "Failed to change gimbal mode, please try again.",
    "es": "No se pudo cambiar el modo de estabilizador. Vuelve a intentarlo.",
    "fr": "Impossible de modifier le mode de nacelle, veuillez réessayer.",
    "ja": "ジンバルモードの変更に失敗しました。もう一度やり直してください。",
    "ko": "짐벌 모드 변경 실패. 다시 시도해주세요",
    "ru": "Не удалось изменить режим стабилизатора. Повторите попытку",
    "tr": "Gimbal modu değişimi başarısız, lütfen tekrar deneyin.",
    "zh": "云台模式设置失败，请重试"
  },
  "fpv_tip_0x20010400": {
    "de": "Hinweis: Nur die erste Kamera im dualen Gimbal-Modus ist in der Lage Tap-Zoom zu verwenden.",
    "en": "Tip: Only the first camera can tap-zoom in dual gimbal control mode.",
    "es": "Consejo: En el modo de control de estabilizador doble, el zoom táctil solo está disponible en la primera cámara.",
    "fr": "Seule la première caméra peut tap-zoom en mode de contrôle double nacelle.",
    "ja": "ヒント：1 つ目のカメラのみで、デュアルジンバルコントロールモードでタップズームが可能です。",
    "ko": "주의: 듀얼 짐벌 제어 모드에서 제1카메라에서만 TapZoom을 사용할 수 있습니다",
    "ru": "Примечание: приближение нажатием на экран доступно только для 1-й камеры в режиме управления 2-мя стабилизаторами.",
    "tr": "İpucu: Sadece ilk kamerada, çift gimbal kontrol modunda dokunarak yakınlaştırma özelliği kullanılabilir.",
    "zh": "提示：开启双云台同时控制模式时，只能使用1号云台指点变焦功能"
  },
  "fpv_tip_0x20010501": {
    "de": "Gimbal zentrieren",
    "en": "Gimbal Recenter",
    "es": "Volver a centrar estabilizador",
    "fr": "Recentrer",
    "ja": "ジンバルの中心を再設定",
    "ko": "짐벌 중앙 복귀",
    "ru": "Центрировать камеру",
    "tr": "Gimbalı Tekrar Ortala",
    "zh": "云台回中"
  },
  "fpv_tip_0x20010502": {
    "de": "Gimbal-Schwenk zentrieren",
    "en": "Recenter Gimbal Pan",
    "es": "Volver a centrar paneo del estabilizador",
    "fr": "Recentrer pano nacelle",
    "ja": "ジンバル パンを再センタリング",
    "ko": "짐벌 요 중앙 복귀",
    "ru": "Центрировать камеру по оси поворота",
    "tr": "Gimbalı Yana Dönüş Kafasını Tekrar Ortalayın",
    "zh": "云台偏航回中"
  },
  "fpv_tip_0x20010503": {
    "de": "Gimbal-Nickwinkel nach oben",
    "en": "Gimbal Pitch Up",
    "es": "Inclinación hacia arriba del estabilizador",
    "fr": "Augmenter inclin. nacelle",
    "ja": "ジンバルピッチアップ",
    "ko": "짐벌 피치 위로",
    "ru": "Наклон стабилизатора вверх",
    "tr": "Gimbal Yükselme Eksenini Yukarıya Kaldır",
    "zh": "云台俯仰朝上"
  },
  "fpv_tip_0x20010504": {
    "de": "Gimbal-Nickwinkel abwärts",
    "en": "Gimbal Pitch Down",
    "es": "Inclinación hacia abajo del estabilizador",
    "fr": "Baisser inclin. nacelle",
    "ja": "ジンバルピッチ ダウン",
    "ko": "짐벌 피치 다운",
    "ru": "Опускание наклона подвеса",
    "tr": "Gimbal Yükselme Eksenini Aşağıya İndir",
    "zh": "云台俯仰朝下"
  },
  "fpv_tip_0x20010505": {
    "de": "Gimbal nach oben",
    "en": "Gimbal Up",
    "es": "Estabilizador hacia arriba",
    "fr": "Nacelle relevée",
    "ja": "ジンバルアップ",
    "ko": "짐벌 위로",
    "ru": "Поднятие стабилизатора",
    "tr": "Gimbalı Yukarı Kaldır",
    "zh": "云台朝上"
  },
  "fpv_tip_0x20010506": {
    "de": "Gimbal abwärts",
    "en": "Gimbal down",
    "es": "Estabilizador hacia abajo",
    "fr": "Nacelle baissée",
    "ja": "ジンバル下降",
    "ko": "짐벌 다운",
    "ru": "Опускание подвеса",
    "tr": "Gimbalı aşağı indir",
    "zh": "云台向下"
  },
  "fpv_tip_0x20010507": {
    "de": "Gimbal-Nickwinkel zentrieren",
    "en": "Gimbal Pitch Recenter",
    "es": "Volver a centrar inclinación del estabilizador",
    "fr": "Recentrer l\\'inclinaison de la nacelle",
    "ja": "ジンバルピッチ 再センタリング",
    "ko": "짐벌 피치 중앙 복귀",
    "ru": "Повторная центровка наклона подвеса",
    "tr": "Gimbal Yükselme Eksenini Tekrar Ortala",
    "zh": "云台俯仰回中"
  },
  "fpv_tip_0x20010508": {
    "de": "Gimbal-Rolle zentriert",
    "en": "Gimbal roll centered",
    "es": "Rotación del estabilizador centrada",
    "fr": "Roulis de nacelle centré",
    "ja": "ジンバルロールがセンタリング済み",
    "ko": "짐벌 롤 중앙에 위치",
    "ru": "Рамка крена гиростабилизатора выровнена по центру",
    "tr": "Gimbal yatışı merkezli",
    "zh": "云台横滚回中"
  },
  "fpv_tip_0x20010600": {
    "de": "Gimbal-Stabilisierung aktiviert",
    "en": "Vision stabilization on.",
    "es": "Estabilización visual activada.",
    "fr": "Stabilisation optique en marche.",
    "ja": "ビジョン安定化がオンです。",
    "ko": "짐벌 안정화 시스템 켜짐",
    "ru": "Стабилизация камеры включена",
    "tr": "Görüş stabilizasyonu açık.",
    "zh": "云台增稳开启"
  },
  "fpv_tip_0x20010601": {
    "de": "Gimbal-Stabilisierung deaktiviert",
    "en": "Vision stabilization off.",
    "es": "Estabilización visual desactivada.",
    "fr": "Stabilisation optique arrêtée",
    "ja": "ビジョン安定化がオフです。",
    "ko": "짐벌 안정화 시스템 꺼짐",
    "ru": "Стабилизация камеры выключена",
    "tr": "Görüş stabilizasyonu kapalı.",
    "zh": "云台增稳关闭"
  },
  "fpv_tip_0x20010700": {
    "de": "Kontrolle verloren: %1$s. Multi-Gimbal-Verbindung deaktiviert.",
    "en": "Lost control of %1$s. Multi-Gimbal Connection disabled",
    "es": "Se ha perdido el control de %1$s. Conexión de varios estabilizadores desactivada",
    "fr": "Perte de contrôle de %1$s. Connexion Multi-nacelle désactivée",
    "ja": "%1$sを制御できません。マルチジンバル接続が無効化",
    "ko": "%1$s 제어권 상실. 멀티 짐벌 연결 비활성화됨",
    "ru": "Потерян контроль %1$s. Все стабилизаторы отключены",
    "tr": "%1$s’in kontrolü kaybedildi. Çoklu Gimbal Bağlantısı devre dışı",
    "zh": "失去%1$s的控制权，联动取消"
  },
  "fpv_tip_0x20010701": {
    "de": "Kontrolle verloren: %1$s. %2$s wurde aus der Multi-Gimbal-Verbindung entfernt",
    "en": "Lost control of %1$s. %2$s dropped from Multi-Gimbal Connection",
    "es": "Se ha perdido el control de %1$s. %2$s se ha desconectado de Conexión de varios estabilizadores",
    "fr": "Perte de contrôle de %1$s. %2$s a quitté la connexion Multi-nacelle",
    "ja": "%1$sを制御できません。%2$sはマルチジンバル接続を中止",
    "ko": "%1$s 제어권 상실. 멀티 짐벌 연결에서 %2$s 끊김",
    "ru": "Потерян контроль %1$s. Произошло отключение %2$s",
    "tr": "%1$s’ın kontrolü kaybedildi. %2$s, Çoklu Gimbal Bağlantısından düştü",
    "zh": "失去%1$s的控制权，%2$s脱离联动"
  },
  "fpv_tip_0x20010800": {
    "de": "Übertragung unterbrochen. Gimbal Kontrolle verloren.",
    "en": "Transmission interrupted. Gimbal control lost",
    "es": "Transmisión interrumpida. Se ha perdido el control del estabilizador",
    "fr": "Transmission interrompue. Contrôle de la nacelle perdu",
    "ja": "伝送が中断されました。ジンバルの制御が失われました",
    "ko": "전송 중단됨. 짐벌 제어권 상실",
    "ru": "Передача прервана. Управление стабилизатором потеряно",
    "tr": "İletim kesintiye uğradı. Gimbal kontrolü kaybedildi",
    "zh": "链路中断，您失去了对云台的控制权。"
  },
  "fpv_tip_0x20010900": {
    "de": "Übertragung der Fernsteuerung %1$s unterbrochen. Steuerung über alle Gimbals übernommen",
    "en": "Remote controller %1$s transmission interrupted. Gained control of all gimbals",
    "es": "Transmisión del control remoto %1$s interrumpida. Se ha obtenido el control de todos los estabilizadores",
    "fr": "Transmission de la radiocommande %1$s interrompue. Vous avez pris le contrôle de toutes les nacelles",
    "ja": "送信機%1$sの伝送が中断されました。すべてのジンバルの制御を取得しました",
    "ko": "조종기 %1$s 전송이 중단됨. 모든 짐벌에 대한 제어권 획득",
    "ru": "Передача данных с пульта %1$s прервана. Получено управление всеми подвесами",
    "tr": "%1$s uzaktan kumandası iletimi kesintiye uğradı. Tüm gimbalların kontrolü alındı",
    "zh": "%1$s控连接中断，您已经获得所有云台的控制权。"
  },
  "fpv_tip_0x20020000": {
    "de": "Steuerung über Fluggerät übernommen. Aktueller Steuerknüppelmodus: %1$s",
    "en": "Obtained aircraft flight control. Current control stick mode: %1$s",
    "es": "Se ha obtenido el control de vuelo de la aeronave. Modo de palanca de control actual: %1$s",
    "fr": "Contrôle du vol de l\\'appareil obtenu. Mode de contrôle actuel des joysticks : %1$s",
    "ja": "機体の飛行制御権を取得しました。ジンバル操作スティックモード：%1$s",
    "ko": "기체 비행 제어권을 획득함. 현재 조종 스틱 모드: %1$s",
    "ru": "Получено управление полетом дрона. Текущий режим ручек управления: %1$s",
    "tr": "Aracın uçuş kontrolü alındı. Mevcut kumanda çubuğu modu: %1$s",
    "zh": "已成功获取飞行器的控制权，您当前的摇杆挡位为%1$s"
  },
  "fpv_tip_0x20020001": {
    "de": "Steuerung des Fluggeräts entsperrt",
    "en": "Aircraft flight control unlocked",
    "es": "Control de vuelo de la aeronave desbloqueado",
    "fr": "Contrôle du vol de l\\'appareil déverrouillé",
    "ja": "機体の飛行制御がロック解除されました",
    "ko": "기체 비행 제어권 잠금 해제",
    "ru": "Управление дроном разблокировано",
    "tr": "Araç uçuş kontrolü kilidi açıldı",
    "zh": "控制权已解锁"
  },
  "fpv_tip_0x20020002": {
    "de": "Steuerung des Fluggeräts gesperrt",
    "en": "Aircraft flight control locked",
    "es": "Control de vuelo de la aeronave bloqueado",
    "fr": "Contrôle du vol de l\\'appareil verrouillé",
    "ja": "機体の飛行制御がロックされました",
    "ko": "기체 비행 제어권 잠김",
    "ru": "Управление дроном заблокировано",
    "tr": "Araç uçuş kontrolü kilitlendi",
    "zh": "控制权已锁定"
  },
  "fpv_tip_0x20020003": {
    "de": "Fluggerätesteuerung durch Fernsteuerung %1$s übernommen",
    "en": "Aircraft flight control obtained by remote controller %1$s",
    "es": "El control remoto %1$s ha obtenido el control de vuelo de la aeronave",
    "fr": "Contrôle du vol de l\\'appareil accordé à la radiocommande %1$s",
    "ja": "送信機%1$sが機体の飛行制御権を取得しました",
    "ko": "조종기 %1$s이(가) 기체 비행 제어권을 획득함",
    "ru": "Управление полетом дрона получено пультом %1$s",
    "tr": "Araç uçuş kontrolü, %1$s uzaktan kumandası tarafından alındı",
    "zh": "飞行器的控制权已经被%1$s控获取"
  },
  "fpv_tip_0x20020004": {
    "de": "Übername der Gimbal-Steuerung nicht möglich",
    "en": "Unable to obtain current gimbal control",
    "es": "No se puede obtener el control del estabilizador actual",
    "fr": "Impossible de prendre le contrôle de la nacelle actuelle",
    "ja": "現在のジンバルの制御を取得できません",
    "ko": "현재 짐벌 제어권 가져올 수 없음",
    "ru": "Невозможно получить управление стабилизатором",
    "tr": "Mevcut gimbalın kontrolünü alamıyor",
    "zh": "当前状态下，不可夺取当前云台的控制权"
  },
  "fpv_tip_0x20020005": {
    "de": "Zweite Fernsteuerung hat Kontrolle über Gimbal %1$s",
    "en": "Aircraft flight control locked by remote controller %1$s. Unable to obtain control",
    "es": "El control remoto %1$s ha bloqueado el control de vuelo de la aeronave. No se puede obtener el control",
    "fr": "La RC assistante a obtenu le contrôle de la nacelle %1$s",
    "ja": "コンパニオンコントローラーがジンバル %1$s の制御権を取得",
    "ko": "상대방 조종기가 짐벌 %1$s 제어권을 획득했습니다",
    "ru": "Вспомогательный пульт получил управление подвесом %1$s",
    "tr": "Araç uçuş kontrolü, %1$s uzaktan kumandası tarafından kitlendi. Kontrolü alamıyor",
    "zh": "飞行器的控制权已被%1$s控锁定，无法获取控制权"
  },
  "fpv_tip_0x20020006": {
    "de": "Übername der Flugsteuerung nicht möglich",
    "en": "Unable to obtain aircraft flight control",
    "es": "No se puede obtener el control de vuelo de la aeronave",
    "fr": "Impossible de prendre le contrôle du vol de L\\'appareil",
    "ja": "機体の飛行制御を取得できません",
    "ko": "기체 비행 제어권 가져올 수 없음",
    "ru": "Невозможно получить управление дроном",
    "tr": "Araç uçuş kontrolünü alamıyor",
    "zh": "当前状态下，不可夺取飞行控制权"
  },
  "fpv_tip_0x20020100": {
    "de": "Hinweis: Fluggerät hat die maximale Flughöhe erreicht. Vorsichtig fliegen.",
    "en": "Tip: Aircraft reached maximum distance, please pay attention to flight safety.\\n",
    "es": "Consejo: La aeronave ha alcanzado la distancia máxima. Presta atención a la seguridad de vuelo.\\n",
    "fr": "Note : L\\'appareil a atteint la distance maximum. Soyez attentifs à la sécurité du vol.",
    "ja": "ヒント：機体は最大距離に到達しました。安全飛行を心掛けてください。\\n",
    "ko": "주의: 기체 최대 거리 도달. 비행 시 주의해주세요",
    "ru": "Примечание: достигнуто макс. расстояние. Будьте осторожны",
    "tr": "İpucu: Araç maksimum mesafeye ulaştı, lütfen uçuş güvenliğine dikkat edin.\\n",
    "zh": "提示：飞机已经达到最远距离， 请注意安全飞行"
  },
  "fpv_tip_0x20020200": {
    "de": "Hohe Windgeschwindigkeit. Vorsichtig fliegen.",
    "en": "High Wind Velocity. Fly with caution.",
    "es": "Velocidad del viento alta. Vuela con cuidado.",
    "fr": "Alerte vent fort. Pilotez avec précaution.",
    "ja": "強い風速です。慎重に飛行してください",
    "ko": "높은 풍속. 비행 시 주의 필요.",
    "ru": "Высокая скорость ветра. Будьте внимательны при полете.",
    "tr": "Yüksek Rüzgar Hızı. Dikkatli uçun.",
    "zh": "风速过大，谨慎飞行"
  },
  "fpv_tip_0x20020201": {
    "de": "Hohe Windgeschwindigkeit. Vorsichtig fliegen.",
    "en": "High Wind Velocity. Fly with caution.",
    "es": "Velocidad del viento alta. Vuela con cuidado.",
    "fr": "Alerte vent fort. Pilotez avec précaution.",
    "it": "",
    "ja": "強い風速です。慎重に飛行してください",
    "ko": "높은 풍속. 비행 시 주의 필요.",
    "pt": "",
    "ru": "Высокая скорость ветра. Будьте внимательны при полете.",
    "tr": "Yüksek Rüzgar Hızı. Dikkatli uçun.",
    "zh": "风速过大，谨慎飞行"
  },
  "fpv_tip_0x20020300": {
    "de": "ATTI-Modus",
    "en": "ATTI mode",
    "es": "Modo ATTI",
    "fr": "Mode ATTI",
    "ja": "ATTIモード",
    "ko": "ATTI 모드",
    "ru": "Режим ATTI",
    "tr": "DAVRANIŞ modu",
    "zh": "姿态模式"
  },
  "fpv_tip_0x20020301": {
    "de": "Kann den Flugmodus nicht umschalten. In den Flugreglereinstellungen den \\\"Multi-Flugmodus\\\" aktivieren, bevor der Flugmodus gewechselt wird.",
    "en": "Cannot switch flight mode. Turn on \\\"Multiple Flight Modes\\\" to enable Atti and Sport Modes",
    "es": "No se puede cambiar de modo de vuelo. Activa \\\"Modo de vuelo múltiple\\\" para habilitar los modos Atti y Sport",
    "fr": "Impossible de changer de Mode de vol. Activer \\\"Modes de vol multiples\\\" pour activer les modes Atti et Sport",
    "ja": "フライトモードを切り替えられません。「複数のフライトモード」をオンにして高度とスポーツモードを有効にしてください",
    "ko": "비행 모드 변경 불가. 조종기 설정으로 이동해 비행 모드 전환 전 \\'다중 비행 모드\\'를 켭니다",
    "ru": "Не удается изменить режим полета. Нажмите \\\"Выбор режима полета\\\", чтобы активировать режим аса и спортивный",
    "tr": "Uçuş moduna geçiş yapılamıyor. Davranış ve Spor Modları etkinleştirmek için \\\"Çoklu Uçuş Modunu\\\" açın",
    "zh": "不允许切换飞行挡位，如需切到其他挡，请在飞控设置打开\\'允许切换飞行挡位\\'开关"
  },
  "fpv_tip_0x20020302": {
    "de": "Auf den Modus \\\"S\\\" (Sport) gewechselt.",
    "en": "Switched to S (Sport)-mode.",
    "es": "Cambiado al modo-S (Sport).",
    "fr": "Mode S (Sport) activé.",
    "ja": "S (スポーツ) モードに切り替えました。",
    "ko": "S(스포츠) 모드로 전환됨",
    "ru": "Активирован режим S (спортивный).",
    "tr": "S (Spor) moduna geçti.",
    "zh": "已切换至S挡（运动）"
  },
  "fpv_tip_0x20020303": {
    "de": "Auf Modus \\\"P\\\" (Positon) gewechselt.",
    "en": "Switched to P (Positioning)-mode.",
    "es": "Cambiado al modo-P (Posicionamiento).",
    "fr": "Mode P (Position) activé.",
    "ja": "P (ポジショニング) モードに切り替えました。",
    "ko": "P(포지셔닝) 모드로 전환됨",
    "ru": "Режим P (позиционирования) активирован.",
    "tr": "P (Konumlandırma) moduna geçti.",
    "zh": "已切换至P挡（定位）"
  },
  "fpv_tip_0x20020304": {
    "de": "Auf den Modus \\\"T\\\" (Tripod) gewechselt.",
    "en": "Switched to T (Tripod)-mode.",
    "es": "Cambiado al modo-T (Trípode).",
    "fr": "Mode T (Trépied) activé.",
    "ja": "T (トライポッド) モードに切り替えました。",
    "ko": "T(삼각대) 모드로 전환됨",
    "ru": "Активирован режим T (штатив).",
    "tr": "T (Tripot) moduna geçti.",
    "zh": "已切换至T挡（三脚架）"
  },
  "fpv_tip_0x20020305": {
    "de": "Auf Modus \\\"A\\\" (Attitude) gewechselt.",
    "en": "Switched to A (Attitude)-mode.",
    "es": "Cambiado al modo-A (Atti).",
    "fr": "Mode A (Attitude) activé.",
    "ja": "A (Atti) モードに切り替えました。",
    "ko": "A(자세) 모드로 전환됨",
    "ru": "Активирован режим А (аса).",
    "tr": "A (Davranış) moduna geçti.",
    "zh": "已切换至A挡（姿态）"
  },
  "fpv_tip_0x20020306": {
    "de": "Zum Abheben in allen Modi außer „P“, den Flugmodusschalter in einen anderen Modus umschalten und ihn dann zurückzuschalten.",
    "en": "To take off in non-P mode, toggle Flight Mode Switch to another mode and then toggle it back.",
    "es": "Para despegar en modo-P, cambia el selector de modo de vuelo a otro modo y luego vuelve a cambiarlo.",
    "fr": "Pour décoller dans des modes autres que le Mode-P, changez de mode de vol puis réactivez celui-ci.",
    "ja": "P 以外のモードで離陸するには、フライトモードスイッチを別のモードに切り替えてから、戻してください。",
    "ko": "P 모드가 아닌 모드로 이륙하면, 비행 모드 스위치를 사용해 다른 모드로 전환한 후 다시 돌아와야 합니다",
    "ru": "Чтобы взлететь в другом режиме, выберите его на пульте, затем переведите переключатель в прежнее положение.",
    "tr": "P modu dışında kalkış yapmak için Uçuş Modu Anahtarını diğer moda kaydırıp sonra tekrar geri eski konumuna kaydırın.",
    "zh": "如果要以非P挡起飞，请切换挡位至其他挡再切回当前挡以确认。"
  },
  "fpv_tip_0x20020307": {
    "en": "When winch mode is enabled, aircraft acceleration is limited and braking time is extended",
    "zh": "空吊模式下，加速度受限，刹停时间变长"
  },
  "fpv_tip_0x20020400": {
    "de": "Verbleibender Akkustand reicht nur noch für Rückkehr. Jetzt automatisch zum Startpunkt zurückkehren",
    "en": "The remaining battery is only enough for RTH. Return home now.",
    "es": "La batería restante solo es suficiente para RPO. Regresa al punto de origen ahora.",
    "fr": "Le niveau de batterie restant ne suffit qu\\'au RTH. Retour au point de départ immédiat.",
    "ja": "残量バッテリーでは RTH のみを実行できます。今すぐホームに帰還します。",
    "ko": "RTH 진행할 배터리 잔량만 남음. 지금 RTH 시작",
    "ru": "Оставшегося заряда хватит только на возврат домой. Возврат активирован",
    "tr": "Kalan pil sadece eve dönüş için yeterli. Şimdi eve dön.",
    "zh": "当前电量仅够返回返航点，请尽快返航。"
  },
  "fpv_tip_0x20020500": {
    "de": "Startpunkt aufgezeichnet. Höhe des Rückflugs: %1$.1f %2$s",
    "en": "Home Point recorded. Return-to-Home Altitude:%1$.1f %2$s",
    "es": "Punto de origen registrado. Altitud de Regreso al punto de origen: %1$.1f %2$s",
    "fr": "Point de départ enregistré. Altitude RTH : %1$.1f %2$s",
    "ja": "ホームポイントを記録。RTH高度：%1$.1f %2$s",
    "ko": "홈포인트 기록됨. RTH 고도: %1$.1f %2$s",
    "ru": "Дом. точка записана. Высота возврата домой: %1$.1f %2$s",
    "tr": "Ev Noktası kaydedildi. Eve Dönüş İrtifası:%1$.1f %2$s",
    "zh": "返航点已记录，返航高度：%1$.1f %2$s"
  },
  "fpv_tip_0x20020600": {
    "de": "Niedriger Akkustand. Fluggerät kehrt zum Startpunkt zurück.",
    "en": "Low power. Aircraft will return home.",
    "es": "Batería baja. La aeronave regresará al punto de origen.",
    "fr": "Batterie faible. L\\'appareil retournera à son point de départ.",
    "ja": "低電力。機体は帰還します。",
    "ko": "배터리 부족. 기체 RTH 시작",
    "ru": "Низкий заряд батареи. Дрон возвращается в дом. точку",
    "tr": "Düşük güç. Araç eve dönecek.",
    "zh": "低电量，飞机开始返航"
  },
  "fpv_tip_0x20020601": {
    "de": "Akkustand sehr niedrig! Fluggerät landet. Mit den Steuerknüppeln kann die Sinkgeschwindigkeit verlangsamt oder Objekten ausgewichen werden.",
    "en": "Critically low power. Aircraft is landing.",
    "es": "Nivel de batería crítico. La aeronave está aterrizando.",
    "fr": "Batterie dangereusement faible. Atterrissage de l\\'appareil.",
    "ja": "重度の低電力。機体は着陸中です。",
    "ko": "배터리 매우 부족. 기체 착륙 중. 조종 스틱을 조정해 하강 속도를 늦추거나 장애물을 피하세요",
    "ru": "Критически низкий заряд батареи. Дрон садится. Управляйте джойстиками, чтобы снизить скорость или избежать препятствие",
    "tr": "Kritik seviyede düşük güç. Araç iniş yapıyor.",
    "zh": "严重低电量，系统强制降落，无法取消，您可以通过推油门减缓下降速度，调整摇杆方向避开危险"
  },
  "fpv_tip_0x20020602": {
    "de": "Der verbleibende Akkustand reicht nur zur Rückkehr zum Startpunkt.",
    "en": "Low power. Returning home.",
    "es": "Batería baja. Regresando al punto de origen.",
    "fr": "Batterie faible. Retour au point de départ.",
    "ja": "低電力。ホームに帰還中。",
    "ko": "RTH 진행할 배터리 잔량만 남음. 지금 RTH 시작",
    "ru": "Заряда батареи хватит только на возврат домой. Дрон начинает возврат",
    "tr": "Düşük güç. Eve dönüyor.",
    "zh": "系统计算您当前电量仅足够返回返航点，飞机开始返航"
  },
  "fpv_tip_0x20020603": {
    "de": "Verbleibender Akkustand reicht nur zur Landung. Fluggerät landet.",
    "en": "Critically low power. Aircraft is landing.",
    "es": "Nivel de batería crítico. La aeronave está aterrizando.",
    "fr": "Batterie dangereusement faible. Atterrissage de l\\'appareil.",
    "ja": "重度の低電力。機体は着陸中です。",
    "ko": "RTH 진행할 배터리 잔량만 남음. 기체 착륙 중",
    "ru": "Заряда батареи хватит только на посадку. Дрон садится",
    "tr": "Kritik seviyede düşük güç. Araç iniş yapıyor.",
    "zh": "当前电量仅够当前高度降落，系统将强制下降，无法取消"
  },
  "fpv_tip_0x20020604": {
    "de": "Akkuladezustand sehr niedrig. Fluggerät landet. RTH-Taste auf der Fernsteuerung drücken um abzubrechen.",
    "en": "Critically low voltage. Aircraft is landing. Press the RTH button on the remote controller to stop.",
    "es": "Voltaje crítico. La aeronave está aterrizando. Pulsa el botón RPO en el control remoto para detener.",
    "fr": "Tension dangereusement faible. L\\'appareil est en phase d\\'atterrissage. Appuyez sur le bouton RTH (Return to Home) de la radiocommande pour interrompre.",
    "ja": "重度の低電圧。機体は着陸中。送信機のRTHボタンを押すと、停止します。",
    "ko": "전압 매우 낮음. 기체 착륙 중. 조종기의 RTH 버튼을 누르면 취소합니다",
    "ru": "Критически низкое напряжение. Дрон садится. Нажмите кнопку \\\"RTH\\\" на пульте, чтобы отменить посадку.",
    "tr": "Kritik seviyede düşük voltaj. Araç iniş yapıyor. Durmak için uzaktan kumanda üzerindeki Eve Dönüş düğmesine basın.",
    "zh": "严重低电压，系统强制降落，短按遥控器上的返航按钮停止降落"
  },
  "fpv_tip_0x20020605": {
    "de": "Niedrige Spannung. Fluggerät kehrt zum Startpunkt zurück. RTH-Taste auf der Fernsteuerung drücken, um abzubrechen.",
    "en": "Low voltage. Returning home now. Press the RTH button on the remote controller to stop.",
    "es": "Voltaje bajo. Regresando al punto de origen ahora. Pulsa el botón RPO en el control remoto para detener.",
    "fr": "Tension faible. Retour au point de départ. Appuyez sur le bouton RTH (Return to Home) de la radiocommande pour annuler.",
    "ja": "低電圧。ホームに直ちに帰還します。送信機のRTHボタンを押すと、停止します。",
    "ko": "저전압. 즉시 RTH 진행 필요. 조종기의 RTH 버튼을 누르면 취소합니다",
    "ru": "Низкое напряжение. Дрон возвращается домой. Нажмите кнопку \\\"RTH\\\" на пульте, чтобы отменить возврат.",
    "tr": "Düşük voltaj. Şimdi eve dönüyor. Durmak için uzaktan kumanda üzerindeki Eve Dönüş düğmesine basın.",
    "zh": "低电压报警，飞机开始返航，短按遥控器上的返航按钮停止返航"
  },
  "fpv_tip_0x20020606": {
    "de": "Warnung! Fluggerät landet aufgrund zu niedriger Spannung. Steuerknüppel nutzen um Hindernissen auszuweichen und die Sinkgeschwindigkeit zu reduzieren.",
    "en": "Aircraft is landing due to low voltage, which cannot be stopped. You can decelerate the descending speed and avoid obstacles using sticks.",
    "es": "La aeronave está aterrizando debido a un voltaje bajo. No se puede detener. Puedes reducir la velocidad de descenso y evitar obstáculos mediante el uso de las palancas.",
    "fr": "Atterrissage de l\\'appareil causé par une faible tension. Impossible de l\\'interrompre. Vous pouvez ralentir la vitesse de descente et éviter les obstacles avec les joysticks.",
    "ja": "低電圧のため、機体は着陸中です。停止できません。下降速度を減速し、操作スティックで障害物を避けることはできます。",
    "ko": "저전압으로 기체가 강제 착륙 중. 조종 스틱을 사용해 하강 속도를 줄이고 장애물을 피할 수 있습니다",
    "ru": "Дрон садится из-за низкого напряжения. Посадку нельзя отменить. Используйте джойстики, чтобы снизить скорость или избегать препятствия.",
    "tr": "Araç, durdurulamayan düşük voltaj nedeniyle iniş yapıyor. Kumanda kollarını kullanarak iniş hızını azaltabilir ve engelleri önleyebilirsiniz.",
    "zh": "电池电压过低，系统强制降落，无法取消，您可以通过推油门减缓下降速度，调整摇杆方向避开危险"
  },
  "fpv_tip_0x20020607": {
    "de": "Fluggerät kehrt zum Startpunkt zurück. Die minimale Flughöhe für die Rückkehr beträgt %1$d m. Diese Flughöhe kann in den Einstellungen der Fernsteuerung festgelegt werden nachdem RTH abgebrochen wurde.",
    "en": "Aircraft is returning to the Home Point. Minimum RTH Altitude is %1$dm. You can reset the RTH Altitude in Remote Controller Settings after cancelling RTH if necessary.",
    "es": "La aeronave está regresando al punto de origen. La altitud de RPO mínima es de %1$d m. Si es necesario, puedes restaurar la altitud de RPO en Configuración del control remoto después de cancelar RPO.",
    "fr": "Retour de l\\'appareil au point de départ. L’altitude minimum de RTH est de %1$d m. Vous pouvez réinitialiser l’altitude RTH dans les paramètres de radiocommande après avoir annulé le Retour au point de départ.",
    "ja": "機体はホームポイントに帰還します。最低 RTH 高度は %1$dm です。必要に応じて、RTH をキャンセルした後に送信機の設定で RTH をリセットできます。",
    "ko": "기체 RTH 중. 최저 RTH 고도 %1$d m.  필요한 경우, RTH 취소 후 조종기에서 RTH 고도를 조정할 수 있습니다",
    "ru": "Дрон возвращается в дом. точку. Мин. высота: %1$d м. Высоту возврата домой можно изменить в настройках пульта после отмены процедуры возврата.",
    "tr": "Araç, Ev Noktasına dönüyor. Minimum Eve Dönüş İrtifası %1$dm. Gerekirse, Eve Dönüşü iptal ettikten sonra Uzaktan Kumanda Ayarları’nda Eve Dönüş İrtifasını sıfırlayabilirsiniz.",
    "zh": "开始自动返航，最低返航高度%1$d米。如需调整返航高度，可在取消返航后到飞控设置中重新设置返航高度"
  },
  "fpv_tip_0x20020608": {
    "de": "Fluggerät kehrt zum Startpunkt zurück. Die minimale Flughöhe für die Rückkehr beträgt %1$d m. Diese Flughöhe kann in den Einstellungen der Fernsteuerung festgelegt werden nachdem RTH abgebrochen wurde.",
    "en": "Aircraft is returning to the Home Point. RTH Altitude is %1$dm. You can reset the RTH Altitude in Remote Controller Settings after cancelling RTH if necessary.",
    "es": "La aeronave está regresando al punto de origen. La altitud de RPO es de %1$d m. Si es necesario, puedes restaurar la altitud de RPO en Configuración del control remoto después de cancelar RPO.",
    "fr": "Retour de l\\'appareil au point de départ. L’altitude de RTH est de %1$d m. Vous pouvez réinitialiser l’altitude RTH dans les paramètres de radiocommande après avoir annulé le Retour au point de départ.",
    "ja": "機体はホームポイントに帰還中。RTH高度は %1$dm。必要な場合、RTH操作をキャンセルした後、送信機設定でRTH高度をリセットできます。",
    "ko": "기체 RTH 중. 최저 RTH 고도 %1$d m.  필요한 경우, RTH 취소 후 조종기에서 RTH 고도를 조정할 수 있습니다",
    "ru": "Дрон возвращается в дом. точку. Мин. высота: %1$d м. Высоту возврата домой можно изменить в настройках пульта после отмены процедуры возврата.",
    "tr": "Araç, Ev Noktasına dönüyor. Eve Dönüş İrtifası %1$dm. Gerekirse, Eve Dönüşü iptal ettikten sonra Uzaktan Kumanda Ayarları’nda Eve Dönüş İrtifasını sıfırlayabilirsiniz.",
    "zh": "开始自动返航，最低返航高度%1$d米。如需调整返航高度，可在取消返航后到飞控设置中重新设置返航高度"
  },
  "fpv_tip_0x20020609": {
    "de": "Fluggerätsignal verloren. Rückkehrfunktion (RTH) gestartet",
    "en": "Aircraft signal lost. RTH started",
    "es": "Se ha perdido la señal de la aeronave. RPO iniciado",
    "fr": "Signal de l'appareil perdu. RTH lancé",
    "ja": "機体の信号ロストです。RTHが開始しました",
    "ko": "기체 신호 끊김. RTH 시작됨",
    "ru": "Сигнал дрона потерян. Возврат домой начался",
    "tr": "Hava aracı sinyali kayboldu. Eve Dönüş başladı",
    "zh": "飞行器失联，开始返航"
  },
  "fpv_tip_0x2002060A": {
    "de": "Sie befinden sich in einem Flugbeschränkungsgebiet. Die automatische Landung wird eingeleitet ...",
    "en": "You are in a No-Fly Zone. Auto landing initiated.",
    "es": "Estás en una zona de exclusión aérea. Se ha iniciado el aterrizaje automático.",
    "fr": "Vous êtes dans une zone restreinte. Atterrissage automatique enclenché.",
    "ja": "飛行禁止区域です。自動着陸が開始しました。",
    "ko": "제한 구역에 있음. 자동 착륙 중...",
    "ru": "Дрон в зоне GEO. Идет автопосадка.",
    "tr": "Uçuşa Kapalı bölgedesiniz. Otomatik iniş başlatıldı.",
    "zh": "飞行器在禁飞区内，开始自动降落"
  },
  "fpv_tip_0x2002060B": {
    "de": "Fluggerät befindet sich in der Nähe des Startpunkts. Autom. Rückkehrfunktion leitet autom. Landung ein.",
    "en": "Aircraft is close to the Home Point. Initiating Return to Home will now trigger Auto Landing.",
    "es": "La aeronave está cerca del punto de origen. Si inicias Regreso al punto de origen ahora, se activará Aterrizaje automático.",
    "fr": "Appareil proche du point de départ. Lancer le retour au point de départ active aussi l\\'atterrissage automatique.",
    "ja": "機体とホームポイントの距離は近すぎます。RTHを開始すると自動着陸がトリガーされます。",
    "ko": "기체가 홈포인트에 너무 가까움. RTH에서 착륙으로 전환됨",
    "ru": "Дрон рядом с дом. точкой. Если вы начнете возврат домой, дрон выполнит автопосадку.",
    "tr": "Araç, Ev Noktasına yakın. Eve Dönüş başlatıldığında Otomatik İnişi tetikler.",
    "zh": "飞行器距离返航点太近，返航切换为自动降落"
  },
  "fpv_tip_0x2002060C": {
    "de": "Fluggerät befindet sich zu weit vom Startpunkt entfernt. Rückkehrfunktion leitet autom. Landung ein.",
    "en": "Aircraft is too far from the Home Point. Initiating Return to Home will now trigger Auto Landing.",
    "es": "La aeronave está demasiado lejos del punto de origen. Si inicias Regreso al punto de origen ahora, se activará Aterrizaje automático.",
    "fr": "Appareil trop éloigné du point de départ. Lancer le Retour au point de départ active l\\'atterrissage automatique.",
    "ja": "機体とホームポイントの距離が遠すぎます。RTHを開始すると自動着陸がトリガーされます。",
    "ko": "기체 홈포인트에서 너무 떨어져 있음. RTH에서 자동 착륙으로 전환됨",
    "ru": "Дрон слишком далеко от дом. точки. Если вы начнете возврат домой, дрон выполнит автопосадку.",
    "tr": "Araç, Ev Noktasından çok uzakta. Eve Dönüş başlatıldığında Otomatik İnişi tetikler.",
    "zh": "飞行器距离返航点太远，返航切换为自动降落"
  },
  "fpv_tip_0x2002060D": {
    "de": "Landet ...",
    "en": "Landing",
    "es": "Aterrizando",
    "fr": "Atterrissage",
    "ja": "着陸中",
    "ko": "착륙 중...",
    "ru": "Посадка",
    "tr": "İniş yapıyor",
    "zh": "开始自动降落"
  },
  "fpv_tip_0x2002060E": {
    "de": "Erzwungene Landung durch App anfordern.",
    "en": "Force Landing request from APP.",
    "es": "La aplicación ha solicitado el aterrizaje forzoso.",
    "fr": "Forcer la demande d\\'atterrissage depuis l\\'appli.",
    "ja": "アプリが強制着陸を要求。",
    "ko": "DJI Pilot 요청으로 강제 착륙",
    "ru": "Принудительная посадка через приложение",
    "tr": "Uygulama, Zorunlu İniş talebinde bulundu.",
    "zh": "App请求强制降落"
  },
  "fpv_tip_0x20020610": {
    "de": "Hindernis  während RTH erkannt. Fluggerät landet ...",
    "en": "Obstacle detected. Aircraft is landing",
    "es": "Obstáculo detectado. La aeronave está aterrizando",
    "fr": "Obstacle détecté. L\\'appareil atterrit",
    "ja": "障害物を検出しました。機体は着陸中です。",
    "ko": "RTH  장애물 감지됨. 기체 착륙 중...",
    "ru": "Обнаружено препятствие. Дрон садится",
    "tr": "Engel tespit edildi. Araç iniş yapıyor",
    "zh": "返航时遇到障碍，正在降落"
  },
  "fpv_tip_0x20020611": {
    "de": "Fehler: IMU. Fluggerät kehrt zum Startpunkt zurück.",
    "en": "IMU error. Aircraft is returning home.",
    "es": "Error de IMU. La aeronave está regresando al punto de origen.",
    "fr": "Erreur IMU. L\\'appareil retourne au point de départ.",
    "ja": "IMUエラー。機体は帰還中です。",
    "ko": "IMU 오류. 기체 RTH 진행",
    "ru": "Ошибка IMU. Дрон возвращается домой.",
    "tr": "IMU hatası. Araç eve dönüyor.",
    "zh": "IMU异常，正在返航"
  },
  "fpv_tip_0x20020612": {
    "de": "Fluggerät kehrt zum Startpunkt zurück.",
    "en": "Aircraft is returning to the Home Point.",
    "es": "La aeronave está regresando al punto de origen.",
    "fr": "Retour de l\\'appareil au point de départ.",
    "ja": "機体はホームポイントに帰還中。",
    "ko": "기체 RTH 중.",
    "ru": "Дрон возвращается в дом.",
    "tr": "Araç, Ev Noktasına dönüyor.",
    "zh": "开始智能高度返航"
  },
  "fpv_tip_0x2002061F": {
    "de": "Inoffizieller Akku erkannt. Fluggerät landet ...",
    "en": "Unofficial battery detected. Aircraft is landing.",
    "es": "Se ha detectado una batería no oficial. La aeronave está aterrizando.",
    "fr": "Batterie non officielle détectée. L\\'appareil atterrit.",
    "ja": "純正でないバッテリーを検出。機体は着陸中です。",
    "ko": "비순정 DJI 배터리 감지됨. 기체 착륙 중...",
    "ru": "Обнаружена нелицензионная батарея. Дрон садится.",
    "tr": "Lisanssız pil tespit edildi. Araç iniş yapıyor.",
    "zh": "检测到非法电池，正在降落"
  },
  "fpv_tip_0x20020700": {
    "de": "Flugmodus-Konfiguration: A/P/S. Aktueller Flugmodus: %1$s.",
    "en": "Flight mode configuration: A/P/S. Current flight mode: %1$s.",
    "es": "Configuración de modo de vuelo: A/P/S. Modo de vuelo actual: %1$s.",
    "fr": "Configuration du mode de vol : S/P/A. Mode de vol actuel : %1$s.",
    "ja": "フライトモードの設定：S/P/A 現在のフライトモード：%1$s",
    "ko": "비행 모드 구성: A/P/S. 현재 비행 모드: %1$s.",
    "ru": "Конфигурация режимов полета: A/P/S. Текущий режим полета: %1$s.",
    "tr": "Uçuş modu yapılandırması: A/P/S. Mevcut uçuş modu: %1$s.",
    "zh": "飞行挡位为A/P/S，且当前飞行挡位为%1$s挡"
  },
  "fpv_tip_0x20020700_rc511": {
    "ar": "",
    "de": "Flugmodus-Konfiguration: A/N/S. Aktueller Flugmodus: %1$s.",
    "en": "Flight mode configuration: A/N/S. Current flight mode: %1$s.",
    "es": "Configuración de modo de vuelo: A/N/S. Modo de vuelo actual: %1$s.",
    "fr": "Configuration du mode de vol : A/N/S. Mode de vol actuel : %1$s.",
    "it": "",
    "ja": "フライトモードの設定：A/N/S 現在のフライトモード：%1$s",
    "ko": "비행 모드 구성: A/N/S. 현재 비행 모드: %1$s.",
    "pt": "",
    "ru": "Конфигурация режимов полета: A/N/S. Текущий режим полета: %1$s.",
    "tr": "Uçuş modu yapılandırması: A/N/S. Mevcut uçuş modu: %1$s.",
    "zh": "飞行挡位为A/N/S，且当前飞行挡位为%1$s挡"
  },
  "fpv_tip_0x20020800": {
    "de": "Fernsteuerung %1$s fordert Fluggerätesteuerung an",
    "en": "Remote controller %1$s requesting aircraft flight control",
    "es": "El control remoto %1$s ha solicitado el control de vuelo de la aeronave",
    "fr": "La radiocommande %1$s demande le contrôle du vol de l\\'appareil",
    "ja": "送信機%1$sが機体の飛行制御権を要求中",
    "ko": "조종기 %1$s이(가) 기체 비행 제어권 요청 중",
    "ru": "Пульт %1$s запрашивает управление полетом дрона",
    "tr": "%1$s uzaktan kumandası, aracın uçuş kontrolünü almak istiyor",
    "zh": "%1$s控请求获取飞行器的控制权"
  },
  "fpv_tip_0x20020900": {
    "de": "Mission unterbrochen. Fluggerät manuell steuern",
    "en": "Mission interrupted. Fly aircraft manually",
    "es": "Misión interrumpida. Vuela la aeronave manualmente",
    "fr": "Mission interrompue. Contrôlez L\\'appareil manuellement",
    "ja": "ミッション中断。機体を手動で飛行させてください",
    "ko": "임무 중단됨. 기체를 수동으로 비행",
    "ru": "Задание прервано. Управляйте дроном вручную",
    "tr": "Görev kesintiye uğradı. Aracı manuel olarak uçurun",
    "zh": "任务已中断，可控制飞行器手动飞行"
  },
  "fpv_tip_0x20020A00": {
    "ar": "",
    "de": "ATTI-Modus",
    "en": "ATTI mode",
    "es": "Modo ATTI",
    "fr": "Mode ATTI",
    "it": "",
    "ja": "ATTIモード",
    "ko": "ATTI 모드",
    "pt": "",
    "ru": "Режим ATTI",
    "tr": "DAVRANIŞ modu",
    "zh": "姿态模式"
  },
  "fpv_tip_0x20020C00": {
    "ar": "",
    "de": "FlyTo-Position mit aktueller Fernsteuerung synchronisiert",
    "en": "FlyTo location synced to current controller",
    "es": "Ubicación de FlyTo sincronizada con el controlador actual",
    "fr": "Emplacement FlyTo synchronisé avec le contrôleur actuel",
    "id": "",
    "it": "",
    "ja": "FlyTo位置が現在の送信機と同期されました",
    "ko": "FlyTo 위치가 현재 조종기에 동기화됨",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Местоположение FlyTo синхронизировано с текущим пультом управления",
    "th": "",
    "tr": "FlyTo konumu, geçerli kumanda ile senkronize edildi",
    "ug": "",
    "vi": "",
    "zh": "目标点已同步",
    "zh-Hant": ""
  },
  "fpv_tip_0x20020C01": {
    "ar": "",
    "de": "Schwerwiegender Flugregler-Fehler. Fluggerät manuell steuern und sofort landen",
    "en": "Severe flight controller error. Control aircraft manually and land promptly",
    "es": "Error grave de control de vuelo. Controla la aeronave manualmente y aterriza rápidamente",
    "fr": "Erreur critique lors du contrôle de vol. Contrôlez l’appareil manuellement et atterrissez rapidement",
    "id": "",
    "it": "",
    "ja": "フライトコントローラーの重大なエラー。機体を手動で制御し、速やかに着陸させてください",
    "ko": "심각한 비행 컨트롤러 오류. 기체를 수동으로 제어하고 즉시 착륙시키십시오.",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "Существенная ошибка полетного контроллера. Управляйте дроном вручную и незамедлительно приземлитесь",
    "th": "",
    "tr": "Ciddi uçuş kumandası hatası. Hava aracını manuel olarak kontrol edin ve hemen iniş yapın",
    "ug": "",
    "vi": "",
    "zh": "飞行系统发生严重异常，请立即手动降落!",
    "zh-Hant": ""
  },
  "fpv_tip_0x20020D00": {
    "de": "Landegestell wird eingefahren. Hindernisvermeidung nicht verfügbar",
    "en": "Rising landing gear. Obstacle avoidance unavailable",
    "es": "Levantando el tren de aterrizaje. Sistema anticolisión no disponible",
    "fr": "Relevée du train d'atterrissage. Évitement d'obstacles non disponible",
    "ja": "ランディングギア上昇中障害物回避は使用できません",
    "ko": "랜딩 기어를 올리고 있습니다. 장애물 회피를 사용할 수 없습니다",
    "ru": "Подъем шасси. Обход препятствий недоступен",
    "tr": "İniş takımı kaldırılıyor. Engellerden kaçınma kullanılamıyor",
    "zh": "起落架正在上升，避障失效"
  },
  "fpv_tip_0x20020D01": {
    "de": "Landegestell wird ausgefahren. Hindernisvermeidung nicht verfügbar",
    "en": "Lowering landing gear. Obstacle avoidance unavailable",
    "es": "Bajando el tren de aterrizaje. Sistema anticolisión no disponible",
    "fr": "Abaissement du train d'atterrissage. Obstacle avoidance unavailable",
    "ja": "ランディングギア下降中です。障害物回避は使用できません",
    "ko": "랜딩 기어를 내리고 있습니다. 장애물 회피를 사용할 수 없습니다",
    "ru": "Выпуск шасси. Обход препятствий недоступен",
    "tr": "İniş takımı indiriliyor. Engellerden kaçınma kullanılamıyor",
    "zh": "起落架正在下降，避障失效"
  },
  "fpv_tip_0x20020b00": {
    "ar": "",
    "de": "",
    "en": "Taking off",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "起飞",
    "zh-Hant": ""
  },
  "fpv_tip_0x20020b01": {
    "ar": "",
    "de": "",
    "en": "Takeoff canceled",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "取消起飞",
    "zh-Hant": ""
  },
  "fpv_tip_0x20021000": {
    "ar": "",
    "de": "Start fehlgeschlagen. Steuerknüppel nicht zentriert. Steuerknüppel während des Starts zentriert lassen",
    "en": "Control sticks not centered. Unable to take off. Ensure control sticks are centered",
    "es": "Palancas de control descentradas. No se puede despegar. Asegúrate de que las palancas de control estén centradas",
    "fr": "Échec du décollage. Les joysticks ne sont pas centrés. Assurez-vous de les centrer",
    "it": "",
    "ja": "操作スティックが中央位置にありません。離陸できません。操作スティックが中央にあるか確認してください",
    "ko": "이륙 실패. 조종 스틱이 중앙에 있지 않음. 조종 스틱이 중앙에 있는지 확인 필요",
    "pt": "",
    "ru": "Сбой взлета. Убедитесь, что джойстики расположены по центру",
    "tr": "Kumanda çubukları merkez dışında. Kalkış yapamıyor. Kumanda çubuklarının merkezde olduğundan emin olun",
    "zh": "无法起飞：请确认摇杆在中位"
  },
  "fpv_tip_0x20021001": {
    "en": "RTH canceled",
    "zh": "取消返航"
  },
  "fpv_tip_0x20021002": {
    "en": "Landing canceled",
    "zh": "取消降落"
  },
  "fpv_tip_0x20022001": {
    "de": "QuickSpin gestartet",
    "en": "QuickSpin started",
    "es": "QuickSpin iniciado",
    "fr": "QuickSpin a démarré",
    "ja": "クイックスピンが開始されました",
    "ko": "QuickSpin 시작됨",
    "ru": "QuickSpin запущен",
    "tr": "QuickSpin başlatıldı",
    "zh": "开始快速自旋"
  },
  "fpv_tip_0x20022002": {
    "de": "Kein GNSS-Signal. Flugbereich unbekannt. Max. Flughöhe auf %1$s begrenzt",
    "en": "No GNSS signal. Flight area unknown. Max flight altitude limited to %1$s",
    "es": "No hay señal GNSS. Área de vuelo desconocida. Altitud de vuelo máxima limitada a %1$s",
    "fr": "Aucun signal GNSS. Zone de vol inconnue. Altitude de vol max. limitée à %1$s",
    "ja": "GNSS信号がありません。飛行エリアが不明です。最大飛行高度が%1$sに制限されています",
    "ko": "GNSS 신호가 없습니다. 비행 영역을 알 수 없습니다. 최고 비행 고도가 %1$s(으)로 제한됨",
    "ru": "Отсутствует сигнал GNSS. Зона полета неизвестна. Максимальная высота полета ограничена значением %1$s",
    "tr": "GNSS sinyali yok. Uçuş alanı bilinmiyor. Maks. uçuş irtifası %1$s ile sınırlıdır",
    "zh": "无GNSS信号，飞行区域未知，限高%1$s"
  },
  "fpv_tip_0x20022003": {
    "de": "Kein GNSS-Signal. Flugbereich unbekannt und Lichtverhältnisse zu schlecht. Max. Flughöhe auf %1$s begrenzt",
    "en": "No GNSS signal. Flight area unknown and ambient light too weak. Max flight altitude limited to %1$s",
    "es": "No hay señal GNSS. Área de vuelo desconocida y luz ambiental demasiado débil. Altitud de vuelo máxima limitada a %1$s",
    "fr": "Aucun signal GNSS. Zone de vol inconnue et lumière ambiante trop faible. Altitude de vol max. limitée à %1$s",
    "ja": "GNSS信号がありません。飛行エリアが不明で、周囲光が暗すぎます。最大飛行高度が%1$sに制限されています",
    "ko": "GNSS 신호가 없습니다. 비행 영역을 알 수 없으며 주변이 너무 어둡습니다. 최고 비행 고도가 %1$s(으)로 제한됨",
    "ru": "Отсутствует сигнал GNSS. Зона полета неизвестна, и недостаточное окружающее освещение. Максимальная высота полета ограничена значением %1$s",
    "tr": "GNSS sinyali yok. Uçuş alanı bilinmiyor ve ortam ışığı çok zayıf. Maks. uçuş irtifası %1$s ile sınırlıdır",
    "zh": "无GNSS信号，飞行区域未知，环境光过暗，限高%1$s"
  },
  "fpv_tip_0x20030000": {
    "de": "Bemanntes Fluggerät in der Nähe entdeckt. Mit Vorsicht fliegen",
    "en": "Manned aircraft detected nearby. Fly with caution",
    "es": "Aeronave tripulada cerca. Vuela con cuidado",
    "fr": "Appareil piloté détecté à proximité. Pilotez avec précaution",
    "ja": "有人航空機が付近で検出された。慎重に飛行してください",
    "ko": "유인 기체가 근처에서 감지됨. 비행 시 주의 필요",
    "ru": "Рядом обнаружен пилотируемый летательный аппарат. Соблюдайте осторожность при полете",
    "tr": "Yakında insanlı araç tespit edildi. Aracınızı dikkatli uçurun",
    "zh": "附近有载人飞行器，请谨慎飞行！"
  },
  "fpv_tip_0x20040000": {
    "de": "Fluggeschwindigkeit zu hoch. Intervallaufnahme schlägt evtl. fehl.",
    "en": "Flight speed too fast. Interval shooting may fail",
    "es": "Velocidad de vuelo demasiado rápida. Es posible que el disparo en intervalos no funcione correctamente",
    "fr": "Vitesse de vol trop rapide. L\\'intervalle de prise de vue pourrait échouer",
    "ja": "飛行速度が速すぎます。インターバル撮影が失敗する可能性があります",
    "ko": "비행 속도 너무 빠름. 인터벌 촬영이 실패할 수 있음",
    "ru": "Слишком высокая скорость полета. Может возникнуть сбой интервала съемки",
    "tr": "Uçuş hızı çok yüksek. Ara çekimler başarısız olabilir",
    "zh": "飞行速度过快，可能造成等距拍照失败"
  },
  "fpv_tip_0x20040100": {
    "de": "Manuelle Belichtung aktiv. Messung deaktiviert.",
    "en": "Manual exposure is enabled. Metering is disabled.",
    "es": "Exposición manual activada. Medición desactivada.",
    "fr": "Exposition manuelle activée. Mesure désactivée.",
    "ja": "手動露光が有効になりました。測光が無効になりました。",
    "ko": "수동 노출 켜짐. 측광 비활성화됨",
    "ru": "Режим ручной экспозиции. Экспозамер отключен.",
    "tr": "Manuel ışıklandırma etkinleştirildi. Ölçme devre dışı.",
    "zh": "当前曝光模式处于M档，无法测光"
  },
  "fpv_tip_0x20040200": {
    "de": "Objekt zu nah am Laser-Entfernungsmesser",
    "en": "Object too close to laser rangefinder",
    "es": "Objeto demasiado cerca del telémetro láser",
    "fr": "Objet trop proche pour télémètre laser",
    "ja": "被写体がレーザーレンジファインダーに近すぎます",
    "ko": "대상이 레이저 거리측정기에 너무 가까움",
    "ru": "Объект слишком близко к лазерному дальномеру",
    "tr": "Nesne lazer menzil belirleyiciye çok yakın",
    "zh": "激光测距过近"
  },
  "fpv_tip_0x20040201": {
    "de": "Objekt zu weit vom Laser-Entfernungsmesser entfernt",
    "en": "Object too far for laser rangefinder",
    "es": "Objeto demasiado lejos del telémetro láser",
    "fr": "Objet trop loin pour télémètre laser",
    "ja": "被写体がレーザーレンジファインダーから遠すぎます",
    "ko": "대상이 레이저 거리측정기에서 너무 멀리 있음",
    "ru": "Объект слишком далеко от лазерного дальномера",
    "tr": "Nesne lazer menzil belirleyiciye çok uzak",
    "zh": "激光测距过远"
  },
  "fpv_tip_0x20040300": {
    "de": "Panorama mit RGB-Kamera und nur einem Tastendruck aufnehmen",
    "en": "Capturing one-tap pano with visible light camera",
    "es": "Capturando panorámica de un solo toque con la cámara visual",
    "fr": "Prise de pano en une touche avec la caméra visible",
    "ja": "可視光カメラを使ってワンタッチパノラマを撮影中",
    "ko": "가시광 카메라로 원터치 파노라마 촬영 중",
    "ru": "Съемка панорамы одним нажатием в видимом свете",
    "tr": "Tek dokunuşla görünür ışıklı kamera ile panorama çekimi yapma",
    "zh": "当前飞行器正在使用可见光相机进行一键全景拍摄"
  },
  "fpv_tip_0x20040400": {
    "de": "Zenmuse XT S ist mit anderen IR-Kameras nicht kompatibel",
    "en": "Zenmuse XT S not compatible with other infrared cameras",
    "es": "Zenmuse XT S no es compatible con otras cámaras infrarrojas",
    "fr": "Zenmuse XT S non compatible avec d\\'autres caméras infrarouges",
    "ja": "Zenmuse XT Sは他の赤外線カメラには対応していない",
    "ko": "젠뮤즈 XT S는 다른 적외선 카메라와 호환 안 됨",
    "ru": "Камера Zenmuse XT S не совместима с другими инфракрасными камерами",
    "tr": "Zenmuse XT S diğer kızıl ötesi kameralara uygun değildir",
    "zh": "Zenmuse XT S不兼容其它红外相机"
  },
  "fpv_tip_0x20040500": {
    "de": "Kamera %component_index überhitzt. Foto-/Videoaufnahmen schlagen evtl. fehl",
    "en": "Camera %component_index overheated. Shooting photos/videos may fail",
    "es": "Cámara %component_index sobrecalentada. Es posible que se produzcan errores al capturar fotos//vídeos",
    "fr": "Surchauffe de la caméra %component_index. La prise de photo/vidéos peut échouer",
    "ja": "カメラ%component_indexが高温。写真／動画撮影ができない可能性あり",
    "ko": "카메라 %component_index 과열. 사진/동영상 촬영에 실패할 수 있음",
    "ru": "Камера %component_index перегрета. Возможны ошибки при съемке фото и видео",
    "tr": "Kamera %component_index aşırı ısındı. Fotoğraf/video çekimi başarısız olabilir",
    "zh": "%component_index号云台相机过热，可能会无法拍照/录像"
  },
  "fpv_tip_0x20040501": {
    "de": "Kamera %component_index überhitzt. Vor Gebrauch bitte auf normale Temperatur abkühlen lassen",
    "en": "Camera %component_index overheated. Wait for temperature to return to normal before use",
    "es": "Cámara %component_index sobrecalentada. Espera a que la temperatura vuelva a la normalidad antes del uso",
    "fr": "Surchauffe caméra %component_index. Attendez que la température de la caméra revienne à la normale avant usage",
    "ja": "カメラ%component_indexが高温。常温に戻るまで待ってからご使用ください",
    "ko": "카메라 %component_index 과열. 사용 전에 온도가 정상으로 돌아올 때까지 기다리세요",
    "ru": "Камера %component_index перегрета. Перед использованием подождите, пока температура не вернется в норму",
    "tr": "Kamera %component_index aşırı ısındı. Kullanmadan önce sıcaklığın normale dönmesini bekleyin",
    "zh": "%component_index号云台相机过热，请等待降温后再使用"
  },
  "fpv_tip_0x20040600": {
    "de": "SD-Karten ist langsamen. Kann nicht in 4K aufnehmen.",
    "en": "SD card speed is slow, cannot record 4K video.",
    "es": "Velocidad lenta de la tarjeta SD. No se puede grabar vídeo 4K.",
    "fr": "La vitesse de la carte SD est lente, impossible d\\'enregistrer en 4K.",
    "it": "",
    "ja": "SD カードの速度が遅いため、4K ビデオを撮影できません。",
    "ko": "SD 카드 속도 느림. 4K 촬영할 수 없습니다",
    "pt": "",
    "ru": "медленная скорость карты памяти. Невозможно записывать в 4K.",
    "tr": "SD kart hızı yavaş, 4K videoyu kaydedemiyor.",
    "zh": "SDCard速度慢，无法录制4K视频"
  },
  "fpv_tip_0x20040700": {
    "de": "Bildrate des Live-Streams unter 9 Hz. Vorsichtig fliegen.",
    "en": "Live stream video frame rate less than 9Hz. Fly with caution.",
    "es": "Ratio de fotogramas de retransmisión en directo de vídeo inferior a 9 Hz. Vuela con cuidado.",
    "fr": "Taux de rafraichissement de la diffusion en direct inférieur à 9 Hz. Volez avec précaution.",
    "ja": "ライブストリームビデオフレームレートは 9Hz 未満です。注意して飛行してください。",
    "ko": "라이브 영상 프레임 속도 9Hz 이하. 비행 시 주의해주세요",
    "ru": "Скорость трансляции 9 Гц. Будьте осторожны",
    "tr": "Canlı yayın video kare hızı 9 Hz’den az. Dikkatli uçun.",
    "zh": "当前画面流畅度过低，请注意飞行安全"
  },
  "fpv_tip_0x20040800": {
    "de": "Fluggerät kehrt zurück oder landet. Panorama nicht möglich",
    "en": "Aircraft RTH or landing. Unable to enter Pano mode",
    "es": "Aeronave en RPO o aterrizaje. No se puede acceder al modo Pano",
    "fr": "RTH appareil ou atterrissage en cours. Impossible d\\'entrer en mode Pano",
    "ja": "機体はRTHまたは着陸中。パノラマモードは利用できません",
    "ko": "기체 RTH 또는 착륙. 파노라마 모드 실행 불가",
    "ru": "Дрон возвращается домой или садится. Невозможно перейти в режим панорамы",
    "tr": "Araç Eve Dönüyor ya da iniş yapıyor. Panorama moduna geçilemiyor",
    "zh": "飞行器处于返航或降落状态下，无法进入全景拍照模式"
  },
  "fpv_tip_0x20040801": {
    "de": "Panorama aufgenommen. In der Wiedergabe ansehen",
    "en": "Panorama taken successfully. View in Playback",
    "es": "Panorámica capturada correctamente. Ver en Reproducción",
    "fr": "Panorama pris avec succès. Visualiser dans le menu de lecture",
    "ja": "パノラマ撮影完了。再生で確認してください",
    "ko": "파노라마 촬영 성공. \\'재생\\'에서 확인 가능",
    "ru": "Съемка панорамы завершена. Посмотрите ее в меню воспроизведения",
    "tr": "Panorama görüntüsü başarıyla alındı. Oynatma bölümünde görüntüleyin",
    "zh": "全景拍摄成功，可在回放中查看"
  },
  "fpv_tip_0x20040802": {
    "de": "Panorama fehlgeschlagen",
    "en": "Taking panorama failed",
    "es": "Error al capturar la panorámica",
    "fr": "La prise du panorama a échoué",
    "ja": "パノラマ撮影できません",
    "ko": "파노라마 촬영 실패",
    "ru": "Не удалось снять панораму",
    "tr": "Panorama görüntüsü alma başarısız",
    "zh": "全景拍摄失败"
  },
  "fpv_tip_0x20040803": {
    "de": "Panorama beendet",
    "en": "Panorama stopped",
    "es": "Panorámica detenida",
    "fr": "Panorama interrompu",
    "ja": "パノラマが停止",
    "ko": "파노라마 촬영 중지됨",
    "ru": "Съемка панорамы остановлена",
    "tr": "Panorama çekimi durduruldu",
    "zh": "全景拍摄终止"
  },
  "fpv_tip_0x20040804": {
    "de": "Fluggerät nicht gestartet. Panorama nicht möglich",
    "en": "Aircraft not in flight. Unable to take panorama",
    "es": "La aeronave no ha despegado. No se puede capturar la panorámica",
    "fr": "L\\'appareil n\\'a pas décollé. Impossible de prendre le panorama",
    "ja": "機体は飛行していません。パノラマ撮影できません",
    "ko": "기체 비행 전. 파노라마 촬영 불가",
    "ru": "Дрон не в воздухе. Невозможно снять панораму",
    "tr": "Araç kalkış yapmadı. Panorama görüntüsü alınamıyor",
    "zh": "飞机未起飞，无法开始全景拍摄"
  },
  "fpv_tip_0x20040805": {
    "de": "Übernahme der Steuerung fehlgeschlagen. Panorama beendet",
    "en": "Getting control failed. Panorama stopped",
    "es": "Error al obtener el control. Panorámica detenida",
    "fr": "Impossible de prendre le contrôle. Panorama interrompu",
    "ja": "制御権を取得できません。パノラマ撮影停止",
    "ko": "제어권 가져오기 실패. 파노라마 촬영 중지됨",
    "ru": "Ошибка управления. Съемка панорамы остановлена",
    "tr": "Kontrolü ele geçirme başarısız. Panorama çekimi durduruldu",
    "zh": "控制权获取失败，全景拍摄终止"
  },
  "fpv_tip_0x20040806": {
    "de": "Unbekannter Kamerafehler. Panorama nicht möglich",
    "en": "Unknown camera error. Unable to start panorama",
    "es": "Error desconocido de la cámara. No se puede iniciar la panorámica",
    "fr": "Erreur caméra inconnue. Impossible de lancer le panorama",
    "ja": "原因不明のカメラエラー。パノラマ撮影を開始できません",
    "ko": "알 수 없는 카메라 오류. 파노라마 시작 불가",
    "ru": "Неизвестная ошибка камеры. Невозможно начать съемку панорамы",
    "tr": "Bilinmeyen kamera hatası. Panorama çekimi başlatılamıyor",
    "zh": "未知相机错误，无法开始全景拍摄"
  },
  "fpv_tip_0x20040807": {
    "de": "Zeitüberschreitung der Kamera. Panorama beendet",
    "en": "Camera response timed out. Panorama stopped",
    "es": "Se agotó el tiempo de espera de la cámara. Panorámica detenida",
    "fr": "Délai de réponse de la caméra expiré. Panorama interrompu",
    "ja": "カメラ応答 時間切れ。パノラマが停止",
    "ko": "카메라 반응 시간 초과. 파노라마 촬영 중지됨",
    "ru": "Время запроса истекло. Съемка панорамы остановлена",
    "tr": "Kamera yanıt süresi doldu. Panorama çekimi durduruldu",
    "zh": "相机超时，全景拍摄终止"
  },
  "fpv_tip_0x20040808": {
    "de": "Nicht genug Speicherplatz. Panorama beendet",
    "en": "Insufficient storage. Panorama stopped",
    "es": "Almacenamiento insuficiente. Panorámica detenida",
    "fr": "Stockage insuffisant. Panorama interrompu",
    "ja": "空き容量不足。パノラマが停止",
    "ko": "저장 공간 부족. 파노라마 촬영 중지됨",
    "ru": "Недостаточно места. Съемка панорамы остановлена",
    "tr": "Yetersiz depolama alanı. Panorama çekimi durduruldu",
    "zh": "存储空间不足，全景拍摄终止"
  },
  "fpv_tip_0x20040809": {
    "de": "Fluggerät bewegt sich. Panorama nicht möglich",
    "en": "Aircraft moving. Unable to take panorama",
    "es": "Aeronave en movimiento. No se puede capturar la panorámica",
    "fr": "Appareil en mouvement. Impossible de lancer le panorama",
    "ja": "機体が動作中。パノラマ撮影できません",
    "ko": "기체 흔들림. 파노라마 촬영 불가",
    "ru": "Дрон в движении. Невозможно начать съемку",
    "tr": "Araç hareket ediyor. Panorama görüntüsü alınamıyor",
    "zh": "飞行器运动中，无法开始全景拍摄"
  },
  "fpv_tip_0x2004080A": {
    "de": "Gimbal bewegt sich. Panorama nicht möglich",
    "en": "Gimbal moving. Unable to take panorama",
    "es": "Estabilizador en movimiento. No se puede capturar la panorámica",
    "fr": "Nacelle en mouvement. Impossible de lancer le panorama",
    "ja": "ジンバルが動作中。パノラマ撮影できません",
    "ko": "짐벌 흔들림. 파노라마 촬영 불가",
    "ru": "Камера в движении. Невозможно начать съемку",
    "tr": "Gimbal hareket ediyor. Panorama görüntüsü alınamıyor",
    "zh": "云台运动中，无法开始全景拍摄"
  },
  "fpv_tip_0x2004080B": {
    "de": "Steuerknüppel bewegt. Panorama beendet",
    "en": "Control stick moved. Panorama stopped",
    "es": "Se ha movido la palanca de control. Panorámica detenida",
    "fr": "Joystick bougé. Panorama interrompu",
    "ja": "操作スティックが動作。パノラマが停止",
    "ko": "조종 스틱 움직임. 파노라마 촬영 중지됨",
    "ru": "Совершено движение джойстиком. Съемка панорамы остановлена",
    "tr": "Kumanda çubuğu hareket etti. Panorama çekimi durduruldu",
    "zh": "用户操作摇杆，全景拍摄终止"
  },
  "fpv_tip_0x2004080C": {
    "de": "Max. Höhenlimit erreicht. Panorama unterbrochen",
    "en": "Max altitude limit reached. Panorama stopped",
    "es": "Se ha alcanzado el límite de altitud máx. Panorámica detenida",
    "fr": "Limite d\\'altitude max. atteinte. Panorama interrompu",
    "ja": "最大高度に達した。パノラマが停止しました",
    "ko": "최고 고도 제한에 도달함. 파노라마 중단됨.",
    "ru": "Достигнута предельная высота полета. Панорамная съемка остановлена",
    "tr": "Maksimum irtifa sınırına ulaşıldı. Panorama çekimi durduruldu",
    "zh": "触碰到限飞高度，全景拍摄终止"
  },
  "fpv_tip_0x2004080D": {
    "de": "Max. Distanz erreicht. Panorama beendet",
    "en": "Max distance reached. Panorama stopped",
    "es": "Se ha alcanzado la distancia máxima. Panorámica detenida",
    "fr": "Distance max. atteinte. Panorama interrompu",
    "ja": "最大距離に到達。パノラマが停止",
    "ko": "최대 거리 도달. 파노라마 촬영 중지됨",
    "ru": "Достигнуто макс. расстояние. Съемка панорамы остановлена",
    "tr": "Maksimum mesafeye ulaşıldı. Panorama çekimi durduruldu",
    "zh": "触发距离限制，全景拍摄终止"
  },
  "fpv_tip_0x2004080E": {
    "de": "Gimbal blockiert. Panorama beendet",
    "en": "Gimbal stuck. Panorama stopped",
    "es": "El estabilizador está atascado. Panorámica detenida",
    "fr": "Nacelle bloquée. Panorama interrompu",
    "ja": "ジンバルが動作しません。パノラマが停止",
    "ko": "짐벌 막힘. 파노라마 촬영 중지됨",
    "ru": "Камера заблокирована. Съемка панорамы остановлена",
    "tr": "Gimbal sıkıştı. Panorama çekimi durduruldu",
    "zh": "云台受阻，全景拍摄终止"
  },
  "fpv_tip_0x2004080F": {
    "de": "Fotoaufnahme fehlgeschlagen. Panorama beendet",
    "en": "Taking photo failed. Panorama stopped",
    "es": "Error al capturar la foto. Panorámica detenida",
    "fr": "Échec de la prise de vue. Panorama interrompu",
    "ja": "写真撮影できません。パノラマが停止",
    "ko": "사진 촬영 실패. 파노라마 촬영 중지됨",
    "ru": "Не удалось сфотографировать. Съемка панорамы остановлена",
    "tr": "Fotoğraf çekimi başarısız. Panorama çekimi durduruldu",
    "zh": "拍照失败，全景拍摄终止"
  },
  "fpv_tip_0x20040810": {
    "de": "Zusammenfügen des Panoramas fehlgeschlagen",
    "en": "Stitching panorama failed",
    "es": "Error al crear la panorámica",
    "fr": "Assemblage du panorama échoué",
    "ja": "パノラマを合成できません",
    "ko": "파노라마 합성 실패",
    "ru": "Не удалось объединить снимки панорамы",
    "tr": "Panorama görüntülerini birleştirme başarısız",
    "zh": "全景图片拼接失败"
  },
  "fpv_tip_0x20040811": {
    "de": "Laden der Kalibrierungsparameter fehlgeschlagen. Panorama beendet",
    "en": "Loading calibration parameters failed. Panorama stopped",
    "es": "Error al cargar los parámetros de calibración. Panorámica detenida",
    "fr": "Chargement des paramètres d\\'étalonnage échoué. Panorama interrompu",
    "ja": "キャリブレーション パラメーター読み込み失敗。パノラマが停止",
    "ko": "캘리브레이션 매개변수 로딩 실패. 파노라마 촬영 중지됨",
    "ru": "Сбой загрузки параметров калибровки. Съемка панорамы остановлена",
    "tr": "Kalibrasyon parametre yüklemesi başarısız. Panorama çekimi durduruldu",
    "zh": "加载标定参数失败，全景拍摄终止"
  },
  "fpv_tip_0x20040812": {
    "de": "Anpassung der Kameraparameter fehlgeschlagen. Panorama beendet",
    "en": "Adjusting camera parameters failed. Panorama stopped",
    "es": "Error al ajustar los parámetros de la cámara. Panorámica detenida",
    "fr": "Réglage des paramètres de la caméra échoué. Panorama interrompu",
    "ja": "カメラパラメーターの調整失敗。パノラマが停止",
    "ko": "카메라 매개변수 조정 실패. 파노라마 촬영 중지됨",
    "ru": "Сбой настройки параметров камеры. Съемка панорамы остановлена",
    "tr": "Kamera parametreleri ayarlama başarısız. Panorama çekimi durduruldu",
    "zh": "调整相机参数失败，全景拍摄终止"
  },
  "fpv_tip_0x20040813": {
    "de": "Erstelle Panorama...",
    "en": "Stitching panorama...",
    "es": "Creando panorámica...",
    "fr": "Création du panorama",
    "ja": "パノラマ作成中",
    "ko": "파노라마 생성 중...",
    "ru": "Идет создание панорамы",
    "tr": "Panorama oluşturuluyor...",
    "zh": "全景图正在拼接合成，请耐心等待"
  },
  "fpv_tip_0x20040900": {
    "de": "Wiedergabe auf primärer Fernsteuerung. Liveansicht wurde von der sekundären Fernsteuerung getrennt.",
    "en": "Playback for Primary remote controller in progress. Disconnected from Liveview for Secondary remote controller",
    "es": "Reproducción para el control remoto principal en curso. Se ha desconectado la vista en directo para el control remoto secundario",
    "fr": "Lecture en cours sur la radiocommande principale. RC secondaire déconnectée de l\\'aperçu en direct",
    "ja": "メインの送信機で再生中です。2台目の送信機用のライブビューから接続が切断されました。",
    "ko": "마스터 조종기 재생 진행 중. 부마스터 조종기의 라이브 뷰에서 연결 끊김",
    "ru": "Идет воспроизведение с главного пульта. Трансляция с второстепенного пульта отключена",
    "tr": "Birincil uzaktan kumanda için tekrar oynatma devam ediyor. İkincil uzaktan kumanda için Canlı Görüntü bağlantısı kesildi",
    "zh": "主控已进入回放，辅控将断开图传"
  },
  "fpv_tip_0x20040A00": {
    "de": "Kamera in Verwendung. Wiedergabe nicht möglich.",
    "en": "Camera busy, cannot enter playback.",
    "es": "Cámara ocupada. No se puede acceder a la reproducción.",
    "fr": "Caméra en cours d\\'utilisation. Impossible d\\'ouvrir le menu de lecture.",
    "ja": "カメラがビジーです。再生できません。",
    "ko": "카메라 사용 중. 재생 실행 불가",
    "ru": "Камера занята. Невозможно открыть меню воспроизведения",
    "tr": "Kamera meşgul, tekrar oynatmaya girilemiyor.",
    "zh": "相机正忙，无法进入回放"
  },
  "fpv_tip_0x20040A01": {
    "de": "Keine Speicherkarte",
    "en": "No memory card",
    "es": "No hay tarjeta de memoria",
    "fr": "Aucune carte mémoire",
    "it": "",
    "ja": "メモリーカードがありません",
    "ko": "메모리 카드 없음",
    "pt": "",
    "ru": "Нет карты памяти",
    "tr": "Hafıza kartı yok",
    "zh": "无存储卡"
  },
  "fpv_tip_0x20040B00": {
    "de": "Installation von LiDAR an Gimbal I für optimale Nutzung",
    "en": "Install LiDAR on Gimbal I for optimal use",
    "es": "Instalar LiDAR en el estabilizador I para un uso óptimo",
    "fr": "Installer LiDAR sur la nacelle I pour une utilisation optimale",
    "ja": "最適な使用のためにはジンバルIにLiDARを取り付けてください",
    "ko": "최적의 사용을 위해 짐벌 I에 LiDAR 설치",
    "ru": "Установите LiDAR на Gimbal I для оптимального использования",
    "tr": "En iyi kullanım için Gimbal'a LiDAR takın",
    "zh": "为保证激光雷达负载使用效果，建议在I号云台安装"
  },
  "fpv_tip_0x20040C00": {
    "de": "Verwendung von Steuerung B. LiDAR kann nicht unterstützt werden. Zu Steuerung A wechseln",
    "en": "Using Controller B. Unable to support LiDAR. Switch to Controller A",
    "es": "Utilizando el controlador B. No se puede admitir LiDAR. Cambio al control A",
    "fr": "Utilisation du contrôleur B. Prise en charge de LiDAR impossible. Passer à la radiocommande A",
    "ja": "送信機Bを使用中です。LiDARに対応していません。送信機Aに切り替えてください",
    "ko": "조종기 B 사용. LiDAR을 지원할 수 없습니다. 조종기 A로 전환하세요.",
    "ru": "Использование контроллера B. Поддержка LiDAR не осуществляется. Переключиться на контроллер А",
    "tr": "Kumada B. Kullanılıyor. LiDAR desteklenmiyor. Kumanda A'ya geçin",
    "zh": "当前控为B控，不支持激光雷达负载，请切换为A控"
  },
  "fpv_tip_0x20040D00": {
    "de": "Kalibrierung fehlgeschlagen. Bitte prüfen und erneut versuchen",
    "en": "Calibration failed. Check and try again",
    "es": "Error de calibración. Vuelve a intentarlo",
    "fr": "Échec de l\\'étalonnage. Vérifiez et réessayez",
    "ja": "キャリブレーション失敗。確認して再試行してください",
    "ko": "캘리브레이션 실패. 확인 후 재시도 필요",
    "ru": "Сбой калибровки. Проверьте и повторите попытку",
    "tr": "Kalibrasyon başarısız. Tekrar deneyin",
    "zh": "标定失败，请尝试重新标定。"
  },
  "fpv_tip_0x20040D01": {
    "de": "Kalibrierung fehlgeschlagen. Wenden dich bitte an DJI Support",
    "en": "Calibration failed. Contact DJI Support",
    "es": "Error de calibración. Contacta con Asistencia DJI",
    "fr": "Étalonnage échoué. Veuillez contacter le service client DJI",
    "ja": "キャリブレーション失敗。DJIサポートにご連絡ください",
    "ko": "캘리브레이션 실패. DJI 고객지원으로 문의해주세요",
    "ru": "Сбой калибровка. Обратитесь в службу поддержки DJI",
    "tr": "Kalibrasyon başarısız. DJI Destek ile iletişime geçin",
    "zh": "标定失败，请联系大疆售后服务。"
  },
  "fpv_tip_0x20040E00": {
    "de": "%1$s Kameraobjektiv ist nicht kalibriert. Die Fokussierung ist beeinträchtigt. Bitte unter den Einstellungen Objektivkalibrierung aufrufen.",
    "en": "%1$s camera lens not calibrated. Focusing will be affected. Go to Settings > Calibrate Lens to calibrate.",
    "es": "El objetivo de la cámara %1$s no está calibrado. El enfoque se verá afectado. Accede a Configuración > Calibrar objetivo para realizar la calibración.",
    "fr": "Objectif de la caméra %1$s non étalonné. La mise au point sera affectée. Rendez-vous dans paramètres > Étalonner l\\'objectif pour étalonner.",
    "ja": "%1$s カメラレンズがキャリブレーションされていません。フォーカスが影響されます。 設定 >レンズ キャリブレーションを開き、実行してください。",
    "ko": "%1$s 카메라 렌즈 캘리브레이션 안 됨. 초점에 영향을 줌. \\'설정\\' > \\'렌즈 캘리브레이션\\' 이동 후 캘리브레이션하세요",
    "ru": "Объектив камеры %s не откалиброван. Это повлияет на фокусировку. Откройте \"Настройки > Калибровка объектива\", чтобы откалибровать.",
    "tr": "%s kamera lensi kalibre edilmedi. Odaklanmayı etkileyecektir. Kalibre etmek için Ayarlar > Lensi Kalibre Et menüsüne gidin.",
    "zh": "%s相机镜头未标定，会影响对焦效果，您可以通过相机设置中的『标定镜头』功能进行标定。"
  },
  "fpv_tip_0x20040F00": {
    "de": "Punktwolkedaten werden gesammelt. Echtzeit-Datenübertragung instabil",
    "en": "Collecting point cloud data. Real-time data transfer unstable",
    "es": "Recopilando información de nube de puntos. Transferencia de datos en tiempo real inestable",
    "fr": "Collecte des données des nuages de points. Transfert de données en temps réel instable",
    "ja": "点群データを収集中。リアルタイムデータ転送が不安定",
    "ko": "포인트 클라우드 데이터 수집. 실시간 데이터 전송 불안정",
    "ru": "Сбор данных облака точек. Передача данных в реальном времени нестабильна",
    "tr": "Nokta bulutu verilerinin toplanması. Gerçek zamanlı veri aktarımı güvenilir değil",
    "zh": "实时点云传输不稳定，点云数据正常采集中"
  },
  "fpv_tip_0x20041000": {
    "de": "Nutzlast-IMU wird erwärmt",
    "en": "Payload IMU warmed up",
    "es": "IMU del instrumento calentada",
    "fr": "Réchauffage de l'IMU de la charge utile",
    "ja": "ペイロードIMUウォームアップ済み",
    "ko": "페이로드 IMU 예열 완료",
    "ru": "Полезная нагрузка IMU прогрелась",
    "tr": "Yük IMU'su ısındı",
    "zh": "负载惯导预热已完成"
  },
  "fpv_tip_0x20041100": {
    "de": "Punktwolken-Modellaufzeichnung läuft. Video kann nicht aufgenommen werden",
    "en": "Point cloud model recording in progress. Unable to record video",
    "es": "Grabación del modelo de nube de puntos en curso. No se puede grabar vídeo",
    "fr": "Enregistrement du modèle de nuage de point en cours. Enregistrement vidéo impossible",
    "ja": "点群モデル録画進行中。動画撮影できません",
    "ko": "포인트 클라우드 모델 기록 중. 동영상 촬영 불가",
    "ru": "Идет запись модели облака точек. Невозможно записать видео",
    "tr": "Nokta bulutu modeli kaydı sürüyor. Video kaydedilemiyor",
    "zh": "点云模型录制中，无法开始录像"
  },
  "fpv_tip_0x20041200": {
    "de": "Kalibrierungsflug abgeschlossen. Fluggerät wieder voll einsatzbereit",
    "en": "Calibration Flight complete. Proceed ahead",
    "es": "Vuelo de calibración completo. Continúa con normalidad",
    "fr": "Vol d\\'étalonnage terminé. Continuez normalement",
    "ja": "キャリブレーション飛行完了。操作を継続してください",
    "ko": "캘리브레이션 비행 완료. 정상으로 진행",
    "ru": "Калибровочный полет завершен. Продолжите использование",
    "tr": "Kalibrasyon Uçuşu tamamlandı. İleriye doğru devam et",
    "zh": "已完成标定飞行，请继续操作"
  },
  "fpv_tip_0x20041300": {
    "de": "Der Kalibrierungsflug wurde manuell gestoppt. Überprüfen und erneut versuchen.",
    "en": "Calibration Flight stopped manually. Check and try again",
    "es": "El vuelo de calibración se ha detenido manualmente. Compruébalo y vuelve a intentarlo",
    "fr": "Le vol d’étalonnage a été arrêté manuellement. Vérifiez et refaites une tentative",
    "ja": "キャリブレーション飛行が手動で停止されました。確認して再試行してください",
    "ko": "캘리브레이션 비행 수동 중지. 확인 후 다시 시도해주세요",
    "ru": "Калибровочный полет остановлен вручную. Проверьте и повторите попытку",
    "tr": "",
    "zh": "标定飞行已被手动打断，请重新操作"
  },
  "fpv_tip_0x20041400": {
    "de": "Kommunikationsfehler zwischen Fluggerät und Nutzlast",
    "en": "Communication error between aircraft and payload",
    "es": "Error de comunicación entre la aeronave y el instrumento",
    "fr": "Erreur de communication entre l'appareil et la nacelle",
    "ja": "機体とペイロード間の通信エラー",
    "ko": "기체-페이로드 통신 오류",
    "ru": "Ошибка подключения дрона к полезной нагрузке",
    "tr": "Araç ve yük arasında iletişim hatası",
    "zh": "负载与飞行器连接通信异常"
  },
  "fpv_tip_0x20041500": {
    "de": "IR-Zoom max",
    "en": "IR Zoom Max",
    "es": "Zoom IR máx.",
    "fr": "Zoom infrarouge maximum",
    "ja": "IRズームが最大です",
    "ko": "IR 줌 최대",
    "ru": "ИК-зум макс.",
    "tr": "IR Yakınlaştırma Maks.",
    "zh": "红外变焦已达最大倍数"
  },
  "fpv_tip_0x20041600": {
    "de": "Wechsel erfolgt. Größerer Temperaturmessbereich verfügbar",
    "en": "Switched successfully. A wider temperature measurement range available",
    "es": "Cambio correcto. Un rango de medición de temperatura más amplio disponible",
    "fr": "Basculement réussi. Plage de mesure de température plus large disponible",
    "ja": "正常に切り替えました。温度測定範囲が広がりました",
    "ko": "전환 성공. 보다 넓은 온도 측정 범위 사용 가능",
    "ru": "Переключение успешно выполнено. Доступен более широкий диапазон измерения температуры",
    "tr": "Başarıyla geçiş yapıldı. Daha geniş sıcaklık ölçüm aralığı mevcut",
    "zh": "切换成功，该模式提供更大的测温区间"
  },
  "fpv_tip_0x20041601": {
    "de": "Wechsel erfolgt. Genauere Temperaturmessung verfügbar",
    "en": "Switched successfully. A more precise temperature measurement available",
    "es": "Cambio correcto. Una medición de temperatura más precisa disponible",
    "fr": "Basculement réussi. Mesure de température plus précise disponible",
    "ja": "正常に切り替えました。温度測定の精度が上がりました",
    "ko": "전환 성공. 보다 정확한 온도 측정 가능",
    "ru": "Переключение успешно выполнено. Доступно более точное измерение температуры",
    "tr": "Başarıyla geçiş yapıldı. Daha hassas sıcaklık ölçümü mevcut",
    "zh": "切换成功，该模式提供更精准的测温能力"
  },
  "fpv_tip_0x20041602": {
    "de": "Wechsel erfolgt. Im hochauflösenden Modus werden Infrarotbilder klarer dargestellt. Temperaturmessung wird nicht unterstützt",
    "en": "Switched successfully. High-Res mode provides clearer infrared image. Temperature measurement not supported",
    "es": "Cambio correcto. El modo Alta resolución proporciona imágenes infrarrojas más nítidas. Medición de temperatura no compatible",
    "fr": "Basculement réussi. Le mode Haute résolution fournit une image infrarouge plus claire. Mesure de température non prise en charge",
    "it": "",
    "ja": "正常に切り替わりました。高解像度モードでは、より鮮明な赤外線画像が得られます。温度測定はサポートされていません",
    "ko": "전환 성공. 고해상도 모드는 보다 선명한 적외선 이미지를 제공합니다. 온도 측정이 지원 안 됨",
    "ru": "Переключение успешно выполнено. Режим высокого разрешения обеспечивает более четкое инфракрасное изображение. Измерение температуры не поддерживается",
    "tr": "Başarıyla geçiş yapıldı. Yüksek Çözünürlük modu daha net kızılötesi görüntü sağlar. Sıcaklık ölçümü desteklenmiyor",
    "zh": "切换成功，红外超清模式提供更清晰的红外画面，不支持测温"
  },
  "fpv_tip_0x20041700": {
    "de": "Lichtsensor blockiert. Erfassung von Multispektraldaten beeinträchtigt",
    "en": "Light sensor blocked. Multispectral data collection affected",
    "es": "Sensor de luz bloqueado. Recopilación de datos multiespectrales afectada",
    "fr": "Capteur de lumière bloqué. Collecte de données multisprectrales affectée",
    "ja": "光センサーがブロックされています。マルチスペクトルデータ収集に影響します",
    "ko": "광 센서가 차단되었습니다. 다중 스펙트럼 데이터 수집이 영향을 받습니다",
    "ru": "Датчик света заблокирован. Нарушен сбор мультиспектральных данных",
    "tr": "Işık sensörü engellendi. Multispektral veri toplama işlemi etkilendi",
    "zh": "光照传感器被遮挡，影响多光谱数据采集"
  },
  "fpv_tip_0x20041801": {
    "en": "Incompatible camera parameters. Restart app and go to home screen to update",
    "zh": "摄像头参数不匹配，请重启APP进入首页更新参数"
  },
  "fpv_tip_0x20050100": {
    "de": "GEO-Zone in der Nähe hat temporärer Flugbeschränkungen, die in 1 Stunde wirksam werden",
    "en": "Nearby GEO Zone has temporary restrictions preparing to take effect in 1 hour",
    "es": "La zona GEO cercana tiene restricciones temporales preparadas para entrar en vigor en 1 hora",
    "fr": "La Zone GEO à proximité a des restrictions temporaires prenant effet dans une heure",
    "ja": "付近のGEO区域には、1時間後に適用される一時的な制限が設けられています",
    "ko": "인근 GEO 구역 임시 제한 적용, 1시간 후 발효에 준비 필요",
    "ru": "В зоне GEO поблизости через 1 час вступят в силу ограничения на полеты",
    "tr": "Yakındaki GEO Bölgesinde 1 saat içinde geçerli olacak kısıtlama hazırlıkları yapılıyor",
    "zh": "附近有1小时后生效的临时限飞区"
  },
  "fpv_tip_0x20050200": {
    "de": "GEO-Zone in der Nähe hat bald temporärer Flugbeschränkungen (%1$d)",
    "en": "Nearby GEO Zone has temporary restrictions preparing to take effect (%1$d)",
    "es": "La zona GEO cercana tiene restricciones temporales preparadas para entrar en vigor (%1$d)",
    "fr": "La Zone GEO à proximité a des restrictions temporaires prenant bientôt effet (%1$d)",
    "ja": "付近のGEO区域に一時的な制限が適用される予定です (%1$d)",
    "ko": "인근 GEO 구역 임시 제한 발효 준비 중 (%1$d)",
    "ru": "В зонах GEO поблизости скоро вступят в силу ограничения на полеты (%1$d)",
    "tr": "Yakındaki GEO Bölgesinde geçici kısıtlama hazırlıkları yapılıyor (%1$d)",
    "zh": "附近有即将生效的临时限飞区（%1$d）"
  },
  "fpv_tip_0x20050300": {
    "de": "Fluggerät sinkt in %1$s automatisch ab.",
    "en": "Aircraft will automatically descend in %1$s",
    "es": "La aeronave descenderá automáticamente en %1$s",
    "fr": "L\\'appareil va descendre automatiquement dans %1$s",
    "ja": "機体は自動的に %1$s 秒後に下降します",
    "ko": "%1$s 초 후에 기체 자동으로 하강",
    "ru": "Дрон автоматически снизится через %1$s сек.",
    "tr": "Araç otomatik olarak %1$s içinde alçalacak",
    "zh": "飞行器将在%1$s秒后自动下降"
  },
  "fpv_tip_0x20050400": {
    "de": "Fluggerät verlässt die freigeschaltete GEO-Zone. Vorsichtig fliegen",
    "en": "Aircraft leaving unlocked GEO zone. Fly with caution",
    "es": "Aeronave abandonando zona GEO desbloqueada. Vuela con precaución",
    "fr": "L\\'appareil sort de la zone GEO déverrouillée. Volez avec précaution",
    "ja": "機体はロック解除されたGEO区域の外に出ます。慎重に飛行してください",
    "ko": "기체 잠금 해제된 GEO 구역 벗어나는 중. 비행 시 주의 필요",
    "ru": "Дрон покидает разблокированную зону GEO. Будьте осторожны в полете",
    "tr": "Araç kilidi açılan GEO bölgesini terk ediyor. Aracınızı dikkatli uçurun",
    "zh": "飞行器已触碰到解禁证书的区域边界，请注意飞行安全。"
  },
  "fpv_tip_0x20050401": {
    "de": "Fluggerät an der Grenze einer GEO-Zone. Rückkehrfunktion (RTH) wird möglicherweise gestoppt. Sofort wegfliegen",
    "en": "Aircraft at the boundary of a GEO Zone. RTH may be stopped. Fly away promptly",
    "es": "La aeronave está en el límite de una zona GEO. RPO puede detenerse. Vuela lejos rápidamente",
    "fr": "Appareil à la limite d'une zone GEO. Le RTH peut être arrêté. Partez rapidement",
    "it": "",
    "ja": "機体はGEO区域の境界にあります。RTHが停止する可能性があります。速やかに離れてください",
    "ko": "기체가 GEO 구역의 경계에 있습니다. RTH가 중지될 수 있습니다. 즉시 벗어나세요",
    "pt": "",
    "ru": "Дрон находится на границе зоны GEO. Возврат домой может быть прерван. Покиньте зону как можно скорее",
    "tr": "Hava aracı GEO Bölgesi'nin sınırında. RTH durdurulabilir. Hemen bölgenin dışına doğru uçun",
    "zh": "飞行器已触碰到限飞区，返航可能中止。请尽快操控飞行器远离。"
  },
  "fpv_tip_0x20050402": {
    "de": "Fluggerät befindet sich an der Grenze zu einer Höhenlagezone. (%1$s). Von Grenze wegbewegen oder Sinkflug einleiten",
    "en": "Aircraft at boundary of an Altitude Zone (%1$s ). Fly away or descend as soon as possible",
    "es": "Aeronave en el límite de una zona de altitud (%1$s). Aléjate o desciende lo antes posible",
    "fr": "L\\'appareil est à la limite d\\'une zone d\\'altitude (%1$s). Éloignez-vous ou descendez rapidement",
    "ja": "機体は高度制限区域(%1$s)の境界線付近を飛行中。ただちに離れるか、下降してください",
    "ko": "기체 고도 제한 구역(%1$s) 경계에 있음. 재빨리 멀리 이동 또는 하강 필요",
    "ru": "Дрон находится на границе зоны ограничения высоты (%1$s). Покиньте зону или приземлитесь как можно скорее",
    "tr": "Araç İrtifa Bölgesi sınırlarında (%1$s). En kısa sürede bölgenin dışına doğru uçun veya irtifayı düşürün",
    "zh": "飞行器已触碰到限高区（限高 %1$s），当前飞行高度高于限飞高度，请操控飞行器远离或下降"
  },
  "fpv_tip_0x20050403": {
    "de": "Fluggerät befindet sich in einer Höhenlagezone (%1$s). Vorsichtig fliegen",
    "en": "Aircraft entered Altitude Zone (%1$s). Fly with caution",
    "es": "La aeronave entró en una zona de altitud (%1$s). Vuela con precaución",
    "fr": "L\\'appareil est dans une zone d’altitude (%1$s). Volez avec précaution",
    "ja": "機体が高度制限区域に到達(%1$s)。慎重に飛行してください",
    "ko": "기체 고도 제한 구역(%1$s) 진입. 비행 시 주의 필요",
    "ru": "Дрон в зоне ограничения высоты (%1$s). Будьте осторожны",
    "tr": "Araç İrtifa Bölgesine girdi (%1$s). Aracınızı dikkatli uçurun",
    "zh": "飞行器进入限高区（限高 %1$s），请注意飞行安全"
  },
  "fpv_tip_0x20050404": {
    "de": "Fluggerät an der Grenze eines Flugbeschränkungsgebiets. Sofort von Grenze wegbewegen",
    "en": "Aircraft at the boundary of a Restricted Zone. Fly away promptly",
    "es": "Aeronave en el límite de una zona restringida. Aléjate lo antes posible",
    "fr": "L’appareil est à la limite d’une zone restreinte. Éloignez-vous rapidement",
    "ja": "機体が制限区域の境界付近を飛行。すぐに離れてください",
    "ko": "기체 제한 구역 경계에 있음. 즉시 멀리 이동 필요",
    "ru": "Дрон находится на границе зоны c ограничениями. Отдалитесь от границы зоны как можно скорее",
    "tr": "Araç Kısıtlı Bölge sınırlarında. Hemen bölgenin dışına doğru uçun",
    "zh": "飞行器已触碰到禁飞区，请尽快操控飞行器远离"
  },
  "fpv_tip_0x20050405": {
    "de": "Fluggerät befindet sich in einer Warnzone (%1$s). Bitte vorsichtig fliegen.",
    "en": "GEO: You are in a Warning Zone (%1$s). Fly with caution.",
    "es": "GEO: Estás en una zona de advertencia (%1$s). Vuela con cuidado.",
    "fr": "GEO : Vous êtes dans une zone de prudence (%1$s). Volez avec précaution.",
    "ja": "GEO：(%1$s) 警告区域にいます。注意して飛行してください。",
    "ko": "현재 경고 구역(%1$s)에 있음. 비행 시 주의해주세요",
    "ru": "GEO: дрон в зоне предупреждения (%1$s). Будьте осторожны.",
    "tr": "GEO: Bir Uyarı Bölgesindesiniz (%1$s). Dikkatli uçun.",
    "zh": "飞行器处于警告区(%1$s)内，请注意飞行安全。"
  },
  "fpv_tip_0x20050406": {
    "ar": "",
    "de": "Das Fluggerät fliegt in einer Höhenlage-Zone (%1$s). Bitte vorsichtig Fliegen.",
    "en": "Your aircraft is flying in an Altitude Zone (%1$s). Please fly with caution.",
    "es": "La aeronave está volando en una zona de altitud (%1$s). Vuela con cuidado.",
    "fr": "Votre appareil vole dans une zone  à altitude limitée (%1$s). Volez avec précaution.",
    "it": "",
    "ja": "機体が高度制限区域 (%1$s)を飛行。慎重に飛行してください。",
    "ko": "고도 제한 구역(%1$s)에서 비행 중. 비행 시 주의해주세요",
    "pt": "",
    "ru": "Дрон в зоне ограничения высоты (%1$s). Будьте осторожны",
    "tr": "Aracınız bir İrtifa Bölgesi içinde (%1$s). Lütfen dikkatli uçun.",
    "zh": "飞行器在限高区（%1$s）下方，请注意飞行安全。"
  },
  "fpv_tip_0x20050407": {
    "de": "Fluggerät in GEO-Zone. Max. Flughöhe: %1$s",
    "en": "Aircraft in GEO Zone. Max flight altitude: %1$s",
    "es": "Aeronave en zona GEO. Altitud máx. de vuelo: %1$s",
    "fr": "Appareil dans une zone GEO. Altitude en vol max. : %1$s",
    "ja": "機体がGEO区域内です。最大飛行高度: %1$s",
    "ko": "GEO 구역의 기체. 최고 비행 고도: %1$s",
    "ru": "Дрон в зоне GEO. Максимальная высота полета: %1$s",
    "tr": "Hava aracı GEO Bölgesinde. Maksimum uçuş irtifası: %1$s",
    "zh": "当前在限飞区，限高%1$s。"
  },
  "fpv_tip_0x20050408": {
    "de": "Fluggerät fliegt in einer Höhenlagezone (%s). Vorsichtig fliegen",
    "en": "Aircraft flying in an Altitude Zone (%s). Fly with caution",
    "es": "Aeronave volando en zona de altitud (%s). Vuela con precaución",
    "fr": "L'appareil vole dans une zone à altitude limitée (%s). Volez avec précaution",
    "ja": "機体が高度制限区域内(%s)を飛行中。慎重に飛行してください",
    "ko": "기체 고도 제한 구역(%s) 내 있음. 비행 시 주의 필요",
    "ru": "Дрон в зоне ограничения высоты (%s). Будьте осторожны",
    "tr": "Araç İrtifa Bölgesinde uçuyor (%s). Aracınızı dikkatli uçurun",
    "zh": "飞行器在限高区（%s）下方，请注意飞行安全"
  },
  "fpv_tip_0x20050500": {
    "de": "Erreiche Max. Flughöhe ...",
    "en": "Reaching maximum altitude...",
    "es": "Pronto se alcanzará la altitud máxima",
    "fr": "Altitude max. atteinte",
    "ja": "もうすぐ最大飛行高度に到達します",
    "ko": "최고 고도 도달 중...",
    "ru": "Достигнута макс. высота",
    "tr": "Maksimum irtifaya erişiyor…",
    "zh": "即将达到限飞高度"
  },
  "fpv_tip_0x20050600": {
    "de": "Mehrere erweiterte Warnzonen in der Nähe des Fluggeräts. Mit Vorsicht fliegen.",
    "en": "Multiple Enhanced Warning Zones near aircraft. Fly with caution",
    "es": "Múltiples zonas de advertencia reforzadas cerca de la aeronave. Vuele con cuidado.",
    "fr": "Plusieurs zones de prudence accrue à proximité de l'appareil. Pilotez avec précaution",
    "ja": "機体付近に多数の強化警告区域あり。慎重に飛行してください",
    "ko": "기체 주변에 경고 강화 구역이 여러 개 있음. 비행 시 주의 필요.",
    "ru": "Несколько особых зон предупреждения рядом с дроном. Соблюдайте осторожность при полете",
    "tr": "Aracın yanında birden fazla Geliştirilmiş Uyarı Bölgesi mevcut. Dikkatlice uçurun",
    "zh": "飞行器附近区域有多个加强警示区，请注意飞行安全"
  },
  "fpv_tip_0x20050601": {
    "de": "Mehrere erweiterte Warnzonen unterhalb des Fluggeräts. Mit Vorsicht fliegen.",
    "en": "Multiple Enhanced Warning Zones below aircraft. Fly with caution",
    "es": "Múltiples zonas de advertencia reforzadas debajo de la aeronave. Vuele con cuidado.",
    "fr": "Plusieurs zones de prudence accrue à proximité sous l'appareil. Pilotez avec précaution",
    "ja": "機体下方に多数の強化警告区域あり。慎重に飛行してください",
    "ko": "기체 아래쪽에 경고 강화 구역이 여러 개 있음. 비행 시 주의 필요.",
    "ru": "Несколько особых зон предупреждения под дроном. Соблюдайте осторожность при полете",
    "tr": "Aracın altında birden fazla Geliştirilmiş Uyarı Bölgesi mevcut. Dikkatlice uçurun",
    "zh": "飞行器下方区域有多个加强警示区，请注意飞行安全"
  },
  "fpv_tip_0x20050602": {
    "de": "Mehrere erweiterte Warnzonen oberhalb des Fluggeräts. Mit Vorsicht fliegen.",
    "en": "Multiple Enhanced Warning Zones above aircraft. Fly with caution",
    "es": "Múltiples zonas de advertencia reforzadas encima de la aeronave. Vuele con cuidado.",
    "fr": "Plusieurs zones de prudence accrue à proximité au-dessus de l'appareil. Pilotez avec précaution",
    "ja": "機体上方に多数の強化警告区域あり。慎重に飛行してください",
    "ko": "기체 위쪽에 경고 강화 구역이 여러 개 있음. 비행 시 주의 필요.",
    "ru": "Несколько особых зон предупреждения над дроном. Соблюдайте осторожность при полете",
    "tr": "Aracın üstünde birden fazla Geliştirilmiş Uyarı Bölgesi mevcut. Dikkatlice uçurun",
    "zh": "飞行器上方区域有多个加强警示区，请注意飞行安全"
  },
  "fpv_tip_0x20050603": {
    "de": "Erweiterte Warnzone in der Nähe (%s. Flughöhenbereich: %s m. Tatsächliche Zeit: %s). Mit Vorsicht fliegen.",
    "en": "Enhanced Warning Zone nearby (%s. Altitude range: %s m. Effective time: %s). Fly with caution",
    "es": "Zona de advertencia reforzada cercana (%s. Rango de altitud: %s m. Tiempo efectivo: %s). Vuele con cuidado.",
    "fr": "Zone de prudence accrue à proximité (%s. Plage d'altitude : %sm. Temps effectif : %s). Pilotez avec précaution",
    "ja": "付近に強化警告区域 (%s。高度範囲: %s m。実効時間: %s)。慎重に飛行してください",
    "ko": "근처에 경고 강화 구역(%s. 고도 범위: %sm. 유효 시간: %s). 비행 시 주의 필요.",
    "ru": "Особая зона предупреждения поблизости (%s. Диапазон высот: %s м. Эффективное время: %s). Соблюдайте осторожность при полете",
    "tr": "Yakınlarda Geliştirilmiş Uyarı Bölgesi mevcut (%s. İrtifa menzili: %s dk. geçerlilik süresi: %s). Dikkatlice uçurun",
    "zh": "飞行器附近有加强警示区（%s，高度区间：%s，生效时段：%s），请注意飞行安全"
  },
  "fpv_tip_0x20050604": {
    "de": "Erweiterte Warnzone unterhalb (%s. Flughöhenbereich: %s m. Tatsächliche Zeit: %s). Mit Vorsicht fliegen.",
    "en": "Enhanced Warning Zone below (%s. Altitude range: %s m. Effective time: %s). Fly with caution",
    "es": "Zona de advertencia reforzada debajo (%s. Rango de altitud: %s m. Tiempo efectivo: %s). Vuele con cuidado.",
    "fr": "Zone de prudence accrue sous (%s. Plage d'altitude : %sm. Temps effectif : %s). Pilotez avec précaution",
    "ja": "下方に強化警告区域 (%s。高度範囲: %s m。実効時間: %s)。慎重に飛行してください",
    "ko": "아래쪽에 경고 강화 구역(%s. 고도 범위: %sm. 유효 시간: %s). 비행 시 주의 필요.",
    "ru": "Особая зона предупреждения ниже (%s. Диапазон высот: %s м. Эффективное время: %s). Соблюдайте осторожность при полете",
    "tr": "Altta Geliştirilmiş Uyarı Bölgesi (%s. İrtifa menzili: %s dk. geçerlilik süresi: %s). Dikkatlice uçurun",
    "zh": "飞行器下方区域有加强警示区（%s，高度区间：%s，生效时段：%s），请注意飞行安全"
  },
  "fpv_tip_0x20050605": {
    "de": "Erweiterte Warnzone oberhalb (%s. Flughöhenbereich: %s m. Tatsächliche Zeit: %s). Mit Vorsicht fliegen.",
    "en": "Enhanced Warning Zone above (%s. Altitude range: %s m. Effective time: %s). Fly with caution",
    "es": "Zona de advertencia reforzada encima (%s. Rango de altitud: %s m. Tiempo efectivo: %s). Vuele con cuidado.",
    "fr": "Zone de prudence accrue au-dessus (%s. Plage d'altitude : %sm. Temps effectif : %s). Pilotez avec précaution",
    "ja": "上方に強化警告区域 (%s。高度範囲: %s m。実効時間: %s)。慎重に飛行してください",
    "ko": "위쪽에 경고 강화 구역(%s. 고도 범위: %sm. 유효 시간: %s). 비행 시 주의 필요.",
    "ru": "Особая зона предупреждения выше (%s. Диапазон высот: %s м. Эффективное время: %s). Соблюдайте осторожность при полете",
    "tr": "Yukarıda Geliştirilmiş Uyarı Bölgesi (%s. İrtifa menzili: %s dk. geçerlilik süresi: %s). Dikkatlice uçurun",
    "zh": "飞行器上方区域有加强警示区（%s，高度区间：%s，生效时段：%s），请注意飞行安全"
  },
  "fpv_tip_0x20050606": {
    "de": "Fluggerät in erweiterter Warnzone (%s. Flughöhenbereich: %s. Tatsächliche Zeit: %s). Mit Vorsicht fliegen.",
    "en": "Aircraft in Enhanced Warning Zone (%s. Altitude range: %s. Effective time: %s). Fly with caution",
    "es": "Aeronave en zona de advertencia reforzada (%s. Rango de altitud: %s. Tiempo efectivo: %s). Vuele con cuidado.",
    "fr": "Appareil dans la zone de prudence accrue (%s. Plage d'altitude : %s. Temps effectif : %s). Pilotez avec précaution",
    "ja": "機体が強化警告区域に侵入 (%s。高度範囲: %s。実効時間: %s)。慎重に飛行してください",
    "ko": "기체가 경고 강화 구역 진입(%s. 고도 범위: %s. 유효 시간: %s). 비행 시 주의 필요.",
    "ru": "Дрон находится в особой зоне предупреждения (%s. Диапазон высот: %s. Эффективное время: %s). Соблюдайте осторожность при полете",
    "tr": "Araç Geliştirilmiş Uyarı Bölgesinde (%s. İrtifa menzili: %s. Geçerlilik süresi: %s). Dikkatlice uçurun",
    "zh": "飞行器在加强警示区（%s，区间：%s，生效时段：%s）内，请注意飞行安全"
  },
  "fpv_tip_0x20050607": {
    "de": "Mehrere erweiterte Warnzonen in der Nähe. Mit Vorsicht fliegen.",
    "en": "Multiple Enhanced Warning Zones nearby. Fly with caution",
    "es": "Múltiples zonas de advertencia reforzadas cercanas. Vuele con cuidado.",
    "fr": "Plusieurs zones de prudence accrue à proximité. Pilotez avec précaution",
    "ja": "付近に多数の強化警告区域あり。慎重に飛行してください",
    "ko": "주변에 경고 강화 구역이 여러 개 있음. 비행 시 주의 필요.",
    "ru": "Несколько особых зон предупреждения поблизости. Соблюдайте осторожность при полете",
    "tr": "Yakınlarda birden fazla Geliştirilmiş Uyarı Bölgesi mevcut. Dikkatlice uçurun",
    "zh": "附近有多个加强警示区，请注意飞行安全"
  },
  "fpv_tip_0x20050608": {
    "de": "GPS-Signal schwach. Geo-Awareness-Funktion beeinträchtigt. Mit Vorsicht fliegen.",
    "en": "GPS signal weak. Geo-awareness function degraded. Fly with caution",
    "es": "Señal GPS débil. Función de reconocimiento geográfico deteriorada. Vuele con cuidado.",
    "fr": "Signal GPS faible. Fonction de géovigilance dégradée. Pilotez avec précaution",
    "ja": "GPS信号 弱。ジオアウェアネス機能が低下しています。慎重に飛行してください",
    "ko": "GPS 신호 약함. 지리 인식 기능 저하됨. 비행 시 주의 필요.",
    "ru": "Слабый сигнал GPS. Функция гео-осведомленности ухудшилась. Соблюдайте осторожность при полете",
    "tr": "GPS sinyali zayıf. Coğrafi farkındalık işlevi bozuldu. Dikkatlice uçurun",
    "zh": "GPS信号差，地理感知功能降级，请谨慎飞行"
  },
  "fpv_tip_0x20060100": {
    "de": "GPS-Koordinaten kopiert %s",
    "en": "GPS location copied. %s",
    "es": "Ubicación de GPS copiada. %s",
    "fr": "Géolocalisation GPS copiée. %s",
    "ja": "GPS 位置情報がコピーされました。 %s",
    "ko": "GPS 좌표 복사됨 %s",
    "ru": "Координаты GPS скопированы %s",
    "tr": "GPS konumu kopyalandı. %s",
    "zh": "GPS坐标已复制 %s"
  },
  "fpv_tip_0x20060200": {
    "de": "RNG-Info kopiert. %s",
    "en": "RNG info copied. %s",
    "es": "Información RNG copiada. %s",
    "fr": "Informations RNG copiées. %s",
    "ja": "RNG 情報がコピーされました %s",
    "ko": "RNG 정보 복사됨. %s",
    "ru": "Информация RNG скопирована. %s",
    "tr": "RNG bilgisi kopyalandı. %s",
    "zh": "RNG信息已复制 %s"
  },
  "fpv_tip_0x20070100": {
    "de": "Fehler: Antriebssystem des Fluggeräts. Notlandung. Fluggerät manuell steuern und in einem offenen Bereich landen",
    "en": "Aircraft propulsion system error. Forced landing. Manually control the aircraft and land in an open area",
    "es": "Error del sistema de propulsión de la aeronave. Aterrizaje forzoso. Controla la aeronave manualmente y aterriza en un espacio abierto",
    "fr": "Erreur du système de propulsion de l\\'appareil. Atterrissage forcé. Contrôlez l\\'appareil manuellement et atterrissez dans une zone dégagée",
    "it": "",
    "ja": "機体推進システムエラー。強制着陸。機体を手動で制御し、開けた場所に着陸してください",
    "ko": "기체 추진력 시스템 오류. 강제 착륙. 기체를 수동으로 제어하여 개방 영역에 착륙하세요",
    "pt": "",
    "ru": "Ошибка винтомоторной системы дрона. Принудительное приземление. Вручную управляя дроном, приземлитесь на открытом участке",
    "tr": "Araç itiş gücü sistemi hatası. Zorunlu iniş. Aracı manuel olarak kontrol edin ve açık bir alana iniş yapın",
    "zh": "飞行器动力异常，现已强制降落！请尽快打杆操作飞行器降落至空旷地区"
  },
  "fpv_tip_0x20070101": {
    "de": "Fehler: Antriebssystem des Fluggeräts. Start nicht möglich",
    "en": "Aircraft propulsion system error. Unable to take off",
    "es": "Error del sistema de propulsión de la aeronave. No se puede despegar",
    "fr": "Erreur du système de propulsion de l\\'appareil. Impossible de décoller",
    "ja": "機体推進システムエラー。離陸できません",
    "ko": "기체 추진력 시스템 오류. 이륙 불가",
    "ru": "Ошибка винтомоторной системы дрона. Невозможно выполнить взлет",
    "tr": "Araç itiş gücü sistemi hatası. Kalkış yapamıyor",
    "zh": "飞行器动力异常，无法起飞!"
  },
  "fpv_tip_0x20080100": {
    "de": "Auf OcuSync-Bildübertragung gewechselt",
    "en": "Switched to OcuSync image transmission",
    "es": "Cambiado a transmisión de imagen OcuSync",
    "fr": "Système de transmission d\\'images OcuSync activé",
    "ja": "OcuSync映像伝送に切り替えました",
    "ko": "이미지 전송을 위해 OcuSync로 전환됨",
    "ru": "Включена передача изображения OcuSync",
    "tr": "OcuSync görüntü iletim moduna geçti",
    "zh": "已切换为Ocusync图传"
  },
  "fpv_tip_0x20080101": {
    "de": "Zum LTE-Übertragungsbackup gewechselt",
    "en": "Switched to LTE Dual Link",
    "es": "Cambiado a LTE Dual Link",
    "fr": "Passage à la transmission LTE auxiliaire",
    "ja": "LTE補助伝送に切り替わりました",
    "ko": "LTE 듀얼 링크로 전환됨",
    "ru": "Переключено на резерв передачи LTE",
    "tr": "LTE İkili Bağlantıya geçildi",
    "zh": "图传链路已切换至LTE增强链路"
  },
  "fpv_tip_0x20080200": {
    "de": "LTE Dual-Link ist im Dual-Steuerungsmodus nicht verfügbar oder falls diese Fernsteuerung als B-Fernsteuerung eingestellt ist",
    "en": "In dual control mode or current RC set as controller B. LTE Dual Link unavailable",
    "es": "Modo control dual o CR actual establecido como control B. LTE Dual Link no disponible",
    "fr": "Mode double contrôle ou RC actuelle définie comme radiocommande B. Transmission LTE auxiliaire indisponible",
    "ja": "デュアル制御モード中か、現在の送信機がコントローラーBとして設定されています。LTEデュアルリンクは利用不可",
    "ko": "듀얼 컨트롤 모드에서 또는 현 RC를 B 컨트롤러로 설정. LTE 듀얼 링크 사용 불가",
    "ru": "Активирован режим двойного управления или пульт ДУ установлен в качестве пульта B. LTE Dual Link недоступен",
    "tr": "İkili kontrol modunda veya B kontrol cihazı olarak ayarlanan mevcut uzaktan kumandada LTE İkili Bağlantı kullanılamaz",
    "zh": "双控模式或当前控为B控，无法使用LTE增强链路"
  },
  "fpv_tip_0x20080300": {
    "de": "LTE-Netzwerk instabil. Mit Vorsicht fliegen",
    "en": "LTE network unstable. Fly with caution",
    "es": "Red LTE inestable. Vuela con cuidado",
    "fr": "Réseau LTE instable. Volez avec précaution",
    "ja": "LTEネットワークが不安定。慎重に飛行してください",
    "ko": "LTE 네트워크 불안정. 주의해서 비행하세요",
    "ru": "Сеть LTE нестабильна. Будьте внимательны при полете",
    "tr": "LTE ağı sabit değil. Dikkatli uçurun",
    "zh": "当前LTE网络质量差， 请谨慎飞行！"
  },
  "fpv_tip_0x20080400": {
    "de": "Schwaches Bildübertragungssignal. Antennen ausrichten und mit Vorsicht fliegen",
    "en": "Image transmission signal weak. Adjust antennas and fly with caution",
    "es": "Señal de transmisión de la imagen débil. Ajusta las antenas y vuela con cuidado",
    "fr": "Signal de transmission d'image faible. Ajustez les antennes et pilotez avec précaution",
    "ja": "映像伝送信号が弱い。アンテナを調整し、慎重に飛行してください",
    "ko": "이미지 전송 신호 약함. 안테나를 조정하고 주의하여 비행하세요.",
    "ru": "Плохое качество сигнала передачи изображения. Отрегулируйте антенны и соблюдайте осторожность при полете",
    "tr": "Görüntü aktarım sinyali zayıf. Antenleri ayarlayın ve aracınızı dikkatli uçurun",
    "zh": "图传信号微弱，请调整天线并谨慎打杆"
  },
  "fpv_tip_0x20080500": {
    "de": "Schwaches Fernsteuerungssignal",
    "en": "Remote Controller Signal Weak",
    "es": "Señal débil del control remoto",
    "fr": "Signal de la radiocommande faible",
    "ja": "送信機信号微弱",
    "ko": "조종기 신호 약함",
    "ru": "Слабый сигнал пульта",
    "tr": "Uzaktan Kumanda Sinyali Zayıf",
    "zh": "遥控器信号微弱，请调整天线"
  },
  "fpv_tip_0x20080600": {
    "de": "Starke Signalstörung an der Fernsteuerung. Entfernen Sie sich von anderen Fernsteuerungen oder Störquellen",
    "en": "Strong remote controller signal interference. Move away from other remote controllers or the source of interference",
    "es": "Fuerte interferencia de la señal del control remoto. Aléjate de otros controles remotos o de la fuente de interferencia",
    "fr": "Fortes interférences de signal de la radiocommande. Éloignez-vous des autres radiocommandes ou de la source d'interférence",
    "ja": "強度の送信機信号干渉。他の送信機や干渉源から離れてください",
    "ko": "조종기 신호 간섭 강함. 다른 조종기 또는 간섭원에서 벗어나야 함",
    "ru": "Сильные помехи сигнала пульта управления. Отдалитесь от других пультов управления как можно скорее",
    "tr": "Güçlü uzaktan kumanda sinyali müdahalesi. Diğer uzaktan kumandalardan veya müdahale kaynaklarından uzak durun",
    "zh": "遥控器侧干扰较强，请远离其他遥控器或干扰源"
  },
  "fpv_tip_0x20080700": {
    "de": "Starke Signalstörung am Fluggerät. Kehren Sie sofort zurück oder entfernen Sie sich von der Störquelle",
    "en": "Strong aircraft signal interference. Return to home promptly or move away from the source of interference",
    "es": "Fuerte interferencia de la señal de la aeronave. Regresa al punto de origen rápidamente o aléjate de la fuente de interferencia",
    "fr": "Fortes interférences de signal de l'appareil. Retournez au point de départ rapidement ou éloignez-vous de la source d'interférence",
    "ja": "強度の機体信号干渉。直ちに帰還するか干渉源から離れてください",
    "ko": "조종기 신호 간섭 강함. 즉시 리턴 투 홈 또는 간섭원에서 벗어나야 함",
    "ru": "Сильные помехи сигнала летательного аппарата. Вернитесь домой или отдалитесь от источника помех как можно скорее",
    "tr": "Güçlü araç sinyali müdahalesi. Hemen eve geri dönün veya müdahalenin kaynağından uzaklaşın",
    "zh": "飞行器侧干扰较强，请尽快返航或飞离干扰源"
  },
  "fpv_tip_0x20090000": {
    "de": "Bildmittenstabilisierung ausgesetzt",
    "en": "Vision stabilization paused.",
    "es": "Estabilización visual en pausa.",
    "fr": "Stabilisation optique mise en pause.",
    "ja": "ビジョン安定化を一時停止しました。",
    "ko": "비전 안정화 시스템 일시 정지",
    "ru": "Стабилизация изображения приостановлена",
    "tr": "Görüş stabilizasyonu duraklatıldı.",
    "zh": "视觉增稳功能已暂停"
  },
  "fpv_tip_0x20090001": {
    "de": "Bildmittenstabilisierung fortgesetzt.",
    "en": "Vision stabilization resumed.",
    "es": "Estabilización visual reanudada.",
    "fr": "Reprise de la stabilisation optique.",
    "ja": "ビジョン安定化を再開しました。",
    "ko": "비전 안정화 시스템 재개",
    "ru": "Стабилизация изображения возобновлена",
    "tr": "Görüş stabilizasyonu devam ettirildi.",
    "zh": "视觉增稳功能已恢复"
  },
  "fpv_tip_0x20090002": {
    "de": "Bildmittenstabilisierung ausgesetztReason: Features too few in current video.",
    "en": "Vision stabilization paused.Reason: Features too few in current video.",
    "es": "Estabilización visual en pausa.Reason: Features too few in current video.",
    "fr": "Stabilisation optique mise en pause.Reason: Features too few in current video.",
    "ja": "ビジョン安定化を一時停止しました。Reason: Features too few in current video.",
    "ko": "비전 안정화 시스템 일시 정지Reason: Features too few in current video.",
    "ru": "Стабилизация изображения приостановленаReason: Features too few in current video.",
    "tr": "Görüş stabilizasyonu duraklatıldı.Reason: Features too few in current video.",
    "zh": "视觉增稳功能已暂停，原因：画面特征点过少"
  },
  "fpv_tip_0x20090003": {
    "de": "Bildmittenstabilisierung fortgesetzt.Reason: Features too few in current video.",
    "en": "Vision stabilization resumed.Reason: Features too few in current video.",
    "es": "Estabilización visual reanudada.Reason: Features too few in current video.",
    "fr": "Reprise de la stabilisation optique.Reason: Features too few in current video.",
    "ja": "ビジョン安定化を再開しました。Reason: Features too few in current video.",
    "ko": "비전 안정화 시스템 재개Reason: Features too few in current video.",
    "ru": "Стабилизация изображения возобновленаReason: Features too few in current video.",
    "tr": "Görüş stabilizasyonu devam ettirildi.Reason: Features too few in current video.",
    "zh": "视觉增稳功能已暂停，原因：相机参数已变更"
  },
  "fpv_tip_0x20090004": {
    "de": "Bildmittenstabilisierung ausgesetztReason: Features too few in current video.Reason: Features too few in current video.",
    "en": "Vision stabilization paused.Reason: Features too few in current video.Reason: Features too few in current video.",
    "es": "Estabilización visual en pausa.Reason: Features too few in current video.Reason: Features too few in current video.",
    "fr": "Stabilisation optique mise en pause.Reason: Features too few in current video.Reason: Features too few in current video.",
    "ja": "ビジョン安定化を一時停止しました。Reason: Features too few in current video.Reason: Features too few in current video.",
    "ko": "비전 안정화 시스템 일시 정지Reason: Features too few in current video.Reason: Features too few in current video.",
    "ru": "Стабилизация изображения приостановленаReason: Features too few in current video.Reason: Features too few in current video.",
    "tr": "Görüş stabilizasyonu duraklatıldı.Reason: Features too few in current video.Reason: Features too few in current video.",
    "zh": "视觉增稳功能已暂停，原因：当前处于跟踪模式"
  },
  "fpv_tip_0x20090100": {
    "de": "Stellenmarkierung hinzugefügt",
    "en": "Pin Point Success",
    "es": "Marca añadida correctamente",
    "fr": "Pin Point réussi",
    "ja": "ピンの設置に成功しました",
    "ko": "핀 포인트 성공",
    "ru": "Точка успешно прикреплена",
    "tr": "İsabet Başarısı",
    "zh": "打点成功"
  },
  "fpv_tip_0x20090101": {
    "de": "Datenabruf von Laserentfernungsmesser nicht möglich. Standortaufzeichnung fehlgeschlagen",
    "en": "Unable to get laser rangefinder data. Recording location failed",
    "es": "No se pueden obtener datos del telémetro láser. Error de registro de ubicación",
    "fr": "Impossible d\\'obtenir les données du télémètre laser. Enregistrement de l\\'emplacement échoué",
    "ja": "レーザー距離計データを取得できません。場所の記録に失敗しました",
    "ko": "레이저 거리계 데이터를 얻을 수 없습니다. 위치 레코딩 실패",
    "ru": "Невозможно получить данные лазерного дальномера. Не удалось записать расположение",
    "tr": "Lazer menzil bulucu verisini alamıyor. Konum kaydı başarısız",
    "zh": "未获取到激光测距数据，打点失败"
  },
  "fpv_tip_0x20090102": {
    "de": "Unzureichende Satelliten verfügbar. Standortaufzeichnung fehlgeschlagen",
    "en": "Insufficient satellites available. Recording location failed",
    "es": "No hay suficientes satélites disponibles. Error de registro de ubicación",
    "fr": "Nombre de satellites disponibles insuffisant. Enregistrement de l\\'emplacement échoué",
    "ja": "衛星が不十分。場所の記録に失敗しました",
    "ko": "이용 가능한 위성이 충분하지 않습니다. 위치 레코딩 실패",
    "ru": "Недостаточно доступных спутников. Не удалось записать расположение",
    "tr": "Yeterli sayıda kullanılabilir uydu yok. Konum kaydı başarısız",
    "zh": "位置信息无效，打点失败"
  },
  "fpv_tip_0x20090103": {
    "de": "Position des Fluggeräts erfolgreich gepinnt",
    "en": "Aircraft location pinned successfully",
    "es": "Ubicación de la aeronave fijada con éxito",
    "fr": "Emplacement de l'appareil épinglé avec succès",
    "ja": "機体の位置を正常に固定しました",
    "ko": "기체 위치가 성공적으로 고정되었습니다",
    "ru": "Местоположение дрона успешно зафиксировано",
    "tr": "Hava aracının konumu başarıyla işaretlendi",
    "zh": "原地打点成功"
  },
  "fpv_tip_0x20090200": {
    "de": "Smarte Verfolgung starten",
    "en": "Starting Smart Track",
    "es": "Iniciando Smart Track",
    "fr": "Début de L\\'acquisition de cible",
    "it": "",
    "ja": "ターゲット捕捉を開始しています",
    "ko": "스마트 트랙 시작",
    "pt": "",
    "ru": "Начинается интеллектуальное следование",
    "tr": "Akıllı Takip Başlatılıyor",
    "zh": "开始智能跟踪"
  },
  "fpv_tip_0x20090201": {
    "de": "TA Deaktiviert",
    "en": "Smart Track Turn Off",
    "es": "Desactivar ST",
    "fr": "ST Éteindre",
    "it": "",
    "ja": "ST オフにする",
    "ko": "ST 닫기",
    "pt": "",
    "ru": "Определение цели выключено",
    "tr": "Kapat ST",
    "zh": "智能跟踪关闭"
  },
  "fpv_tip_0x20090202": {
    "de": "Zielidentifikation nicht möglich. Ziel durch Ziehen auf Bildschirm auswählen",
    "en": "Unable to identify target. Drag-select target on screen",
    "es": "No se puede identificar el objetivo. Arrastra para seleccionar el objetivo en pantalla",
    "fr": "Impossible d\\'identifier le sujet. Encadrez le sujet à l\\'écran",
    "ja": "ターゲットを特定できません。ドラッグして画面上でターゲットを選択してください",
    "ko": "타겟을 식별할 수 없습니다. 화면에서 타겟을 끌어서 선택하세요",
    "ru": "Невозможно идентифицировать цель. Перетащите и выберите цель на экране",
    "tr": "Hedefi tanımlayamıyor. Ekran üzerinde hedefi sürükleyin-seçin",
    "zh": "未识别到目标，请在屏幕上手动框选"
  },
  "fpv_tip_0x20100000": {
    "de": "D-RTK-Akkustand niedrig",
    "en": "D-RTK Low Battery",
    "es": "Batería baja de D-RTK",
    "fr": "Batterie D-RTK faible",
    "ja": "D-RTKローバッテリー",
    "ko": "D-RTK 배터리 부족",
    "ru": "Низкий заряд батареи D-RTK",
    "tr": "Düşük Pilde Eve Dönüş",
    "zh": "D-RTK低电量警报"
  },
  "fpv_tip_0x20100100": {
    "de": "RTK-Statusfehler. Der Modus \\\"Positioniergenauigkeit beibehalten\\\" wird gestartet. Das Fluggerät verlässt RTK, falls eine Verbindung nicht in angemessener Zeit wiederhergestellt werden kann",
    "en": "RTK status error. Entering Maintain Positioning Accuracy mode. Aircraft will exit RTK if reconnection timed out",
    "es": "Error de RTK. La aeronave está accediendo al modo Mantener precisión de posicionamiento. La aeronave saldrá de RTK si se agota el tiempo de reconexión",
    "fr": "Erreur de statut RTK. Passage au mode Maintien de la précision de positionnement. L\\'appareil sort du RTK si le délai de reconnexion est expiré",
    "ja": "RTKステータスエラー。測位精度維持モードに入ります。時間内に再接続できない場合、機体はRTKを終了します",
    "ko": "RTK 상태 오류. \\'포지셔닝 정확도 유지\\' 모드 실행. 연결 시간 초과 시 기체 RTK 종료",
    "ru": "Ошибка статуса RTK. Вход в режим поддержания точности позиционирования. Дрон выйдет из режима RTK, если время повторного подключения истекло",
    "tr": "RTK durumu hatası. Konum Doğruluğunu Koruma moduna giriliyor. Bağlantı zaman aşımına uğrarsa araç RTK\\'dan çıkacaktır",
    "zh": "RTK状态异常，将进入精度维持模式，超时将自动退出RTK"
  },
  "fpv_tip_0x20100200": {
    "de": "RTK-Basisstation nicht gefunden. Basisstation überprüfen und erneut suchen",
    "en": "RTK base station not found. Check base station and search again",
    "es": "No se ha encontrado la estación base RTK. Comprueba la estación base y vuelve a buscar",
    "fr": "Station de base RTK introuvable. Vérifiez la station de base et effectuez une nouvelle recherche",
    "ja": "RTK基地局が見つかりません。基地局を確認し、もう一度検索してください",
    "ko": "RTK 기지국을 찾을 수 없습니다. 기지국을 확인하고 다시 검색하세요",
    "ru": "Базовая станция RTK не найдена. Проверьте базовую станцию и повторите поиск",
    "tr": "RTK baz istasyonu bulunamadı. Baz istasyonu kontrol edin ve tekrar arayın",
    "zh": "未搜索到RTK基站，请检查RTK基站后重新搜索"
  },
  "fpv_tip_0x2010D000": {
    "de": "D-RTK -Akkustand niedrig",
    "en": "D-RTK  Low Battery",
    "es": "Batería baja de D-RTK",
    "fr": "Batterie D-RTK  faible",
    "it": "",
    "ja": "D-RTK ローバッテリー",
    "ko": "D-RTK  배터리 부족",
    "pt": "",
    "ru": "Низкий заряд батареи D-RTK",
    "tr": "Düşük Pilde Eve Dönüş",
    "zh": "D-RTK 低电量警报"
  },
  "fpv_tip_0x20110000": {
    "de": "Autom. vorwärmen der Akkus ...",
    "en": "Battery heating",
    "es": "Calentamiento de la batería",
    "fr": "Préchauffage batterie",
    "ja": "バッテリー過熱",
    "ko": "배터리 자동 예열 중...",
    "ru": "Прогрев батареи",
    "tr": "Pil ısınıyor",
    "zh": "电池加热中"
  },
  "fpv_tip_0x20120000": {
    "de": "Zugriff auf die GPS-Informationen des Mobilgeräts fehlgeschlagen.",
    "en": "Fail to access mobile device GPS information.",
    "es": "No se pudo acceder al GPS del dispositivo móvil.",
    "fr": "Impossible d\\'accéder aux informations GPS de votre appareil mobile.",
    "ja": "モバイルデバイスの GPS 情報へのアクセスに失敗しました。",
    "ko": "모바일 기기 GPS 정보 액세스 실패",
    "ru": "Не удалось получить доступ к информации о GPS мобильного устройства.",
    "tr": "Mobil cihaz GPS bilgilerine erişilemedi.",
    "zh": "获取移动设备GPS失败"
  },
  "fpv_tip_0x20120001": {
    "de": "Zugriff auf die GPS-Informationen des DJI Geräts fehlgeschlagen.",
    "en": "Fail to access DJI device GPS information.",
    "es": "No se pudo acceder al GPS del dispositivo DJI.",
    "fr": "Impossible d\\'accéder aux informations GPS de votre appareil DJI.",
    "ja": "DJI デバイスの GPS 情報へのアクセスに失敗しました。",
    "ko": "DJI 기기 GPS 정보 액세스 실패",
    "ru": "Не удалось получить доступ к информации о GPS устройства DJI.",
    "tr": "DJI cihaz GPS bilgilerine erişilemedi.",
    "zh": "获取DJI设备GPS失败"
  },
  "fpv_tip_0x20120200": {
    "de": "Fluggerät vor den nächsten Schritt landen.",
    "en": "Land aircraft before continuing to next step",
    "es": "Aterriza la aeronave antes de proceder con el siguiente paso",
    "fr": "Faire atterrir aéronef avant poursuite étape suivante",
    "ja": "次のステップに進む前に、機体を着陸させてください",
    "ko": "다음 단계 진행 전에 기체를 착륙시키세요",
    "ru": "Приземлитесь перед тем как продолжить",
    "tr": "Bir sonraki adıma devam etmeden önce aracı indirin",
    "zh": "请先将飞机降落至地面，再进行下一步操作"
  },
  "fpv_tip_0x20120300": {
    "de": "Fluggerät fliegt zum Startpunkt",
    "en": "Aircraft flying to editing start point",
    "es": "La aeronave está volando al punto de partida editado",
    "fr": "L\\'appareil vole vers le point de départ modifié",
    "ja": "機体は編集開始点に向かって飛行しています",
    "ko": "기체가 \\'편집 시작 지점\\'으로 비행",
    "ru": "Дрон летит к точке начала редактирования",
    "tr": "Araç düzeltme başlangıç noktasına dönüyor",
    "zh": "飞行器自动飞至起始点，请耐心等待"
  },
  "fpv_tip_0x20120301": {
    "de": "Fluggerät am Startpunkt angekommen. Bitte Bearbeitung starten.",
    "en": "Arrived at editing start point. Begin editing",
    "es": "La aeronave está en el punto de partida editado. Inicia la edición ahora",
    "fr": "L\\'appareil est au point de départ modifié. Vous pouvez éditer la balise",
    "ja": "機体は編集開始点に到着。ここで編集を開始してください",
    "ko": "기체가 \\'편집 시작 지점\\'에 있음. 편집 시작 가능",
    "ru": "Дрон в точке начала редактирования. Вы можете начинать процесс",
    "tr": "Düzenleme başlangıç noktasına varıldı. Düzenlemeye başla",
    "zh": "飞行器已到达起始点，请开始操作"
  },
  "fpv_tip_0x20120302": {
    "de": "Bearbeitung der Flugroute beenden",
    "en": "Exit Flight Route Editing",
    "es": "Salir de edición de ruta de vuelo",
    "fr": "Quitter la modification de l\\'itinéraire de vol",
    "ja": "飛行ルート編集を終了",
    "ko": "비행 경로 편집 종료",
    "ru": "Выход из редактирования маршрута полета",
    "tr": "Uçuş Rotası Düzenleme Menüsünden Çık",
    "zh": "退出飞行编辑"
  },
  "fpv_tip_0x20120400": {
    "de": "Keine Telefonnummer verknüpft. Max. Höhe und Distanz wurden beschränkt. Bitte Telefonnummer vor dem Start verknüpfen.",
    "en": "Phone not bound. Altitude and distance limited. Bind phone before taking off.",
    "es": "El teléfono no está vinculado. Se han limitado la altitud y la distancia. Agrupa el teléfono antes de despegar.",
    "fr": "Téléphone non lié. L\\'altitude et la distance permises pour votre vol sont limitées. Liez votre téléphone avant de décoller.",
    "ja": "電話が紐付けされていません。 高度と距離が制限。 離陸前に電話と紐付けしてください。",
    "ko": "전화번호 바인딩 안 됨. 고도 및 거리에 제한 있음. 이륙 전에 전화번호를 바인딩하세요",
    "ru": "Тел. номер не привязан. Высота и расстояние полета ограничены. Привяжите тел. номер перед взлетом",
    "tr": "Telefon bağlı değil. Uçuş irtifası ve mesafe sınırlandı. Kalkıştan önce telefonu bağlayın.",
    "zh": "未绑定手机，已限高限远。请完成绑定手机再起飞。"
  },
  "fpv_tip_0x20120500": {
    "de": "Mediendateien werden über die aktuelle Fernsteuerung hochgeladen.",
    "en": "Media files will be uploaded through current remote controller",
    "es": "Los archivos multimedia se cargarán a través del control remoto actual",
    "fr": "Les fichiers multimédias seront téléchargés via la radiocommande actuelle",
    "ja": "メディアファイルは、現在の送信機からアップロードされます",
    "ko": "미디어 파일은 현재 조종기를 통해 자동으로 업로드됩니다.",
    "ru": "Медиафайлы будут загружены с помощью текущего пульта дистанционного управления",
    "tr": "Medya dosyaları mevcut uzaktan kumanda aracılığıyla yüklenecektir",
    "zh": "媒体资源将从当前控上传云端"
  },
  "fpv_tip_0x20120501": {
    "de": "Mediendateien werden über eine andere Fernsteuerung hochgeladen.",
    "en": "Media files will be uploaded through another remote controller",
    "es": "Los archivos multimedia se cargarán a través de otro control remoto",
    "fr": "Les fichiers multimédias seront téléchargés via une autre radiocommande",
    "ja": "メディアファイルは別の送信機からアップロードされます",
    "ko": "미디어 파일은 다른 조종기를 통해 자동으로 업로드됩니다.",
    "ru": "Медиафайлы будут загружены с помощью другого пульта дистанционного управления",
    "tr": "Medya dosyaları başka bir uzaktan kumanda aracılığıyla yüklenecektir",
    "zh": "媒体资源将从另一个控上传云端"
  },
  "fpv_tip_0x20120502": {
    "ar": "",
    "de": "",
    "en": "Aircraft cloud control enabled",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "飞行器已接受云端命令控制",
    "zh-Hant": ""
  },
  "fpv_tip_0x20120503": {
    "ar": "",
    "de": "",
    "en": "Aircraft bound to other team. Cloud service unavailable",
    "es": "",
    "fr": "",
    "id": "",
    "it": "",
    "ja": "",
    "ko": "",
    "nl": "",
    "pl": "",
    "pt": "",
    "pt-PT": "",
    "ru": "",
    "th": "",
    "tr": "",
    "ug": "",
    "vi": "",
    "zh": "飞行器跨团队，云服务不可用",
    "zh-Hant": ""
  },
  "fpv_tip_0x20120600": {
    "de": "Aus Projekt entfernt. Projektdetails können nicht angezeigt werden",
    "en": "Removed from project. Unable to view project details",
    "es": "Eliminado del proyecto. No se pueden ver los detalles del proyecto",
    "fr": "Supprimé du projet. Impossible d'afficher les détails du projet",
    "ja": "プロジェクトから削除されました。プロジェクトの詳細を表示できません",
    "ko": "프로젝트에서 제거됨. 프로젝트 세부 정보 확인 불가",
    "ru": "Удалено из проекта. Невозможно просмотреть сведения о проекте",
    "tr": "Projeden kaldırıldı. Proje bilgileri görüntülenemiyor",
    "zh": "您已被当前项目移除，将无法查看该项目内容"
  },
  "fpv_tip_0x20120700": {
    "de": "Projekt archiviert. Projektdetails können nicht angezeigt werden",
    "en": "Project archived. Unable to view project details",
    "es": "Proyecto archivado. No se pueden ver los detalles del proyecto",
    "fr": "Projet archivé. Impossible d'afficher les détails du projet",
    "ja": "プロジェクトがアーカイブされました。プロジェクトの詳細を表示できません",
    "ko": "프로젝트가 보관됨. 프로젝트 세부 정보 확인 불가",
    "ru": "Проект в архиве. Невозможно просмотреть сведения о проекте",
    "tr": "Proje arşivlendi. Proje bilgileri görüntülenemiyor",
    "zh": "您所在项目已归档，将无法查看该项目内容"
  },
  "fpv_tip_0x20120800": {
    "de": "Projektteam aufgelöst. Projektdetails können nicht angezeigt werden",
    "en": "Project team disbanded. Unable to view project details",
    "es": "El equipo del proyecto se disolvió. No se pueden ver los detalles del proyecto",
    "fr": "Équipe du projet dissoute. Impossible d'afficher les détails du projet",
    "ja": "プロジェクトチームが削除されました。プロジェクトの詳細を表示できません",
    "ko": "프로젝트 팀이 해산됨. 프로젝트 세부 정보 확인 불가",
    "ru": "Команда проекта расформирована. Невозможно просмотреть сведения о проекте",
    "tr": "Proje ekibi dağıldı. Proje bilgileri görüntülenemiyor",
    "zh": "您所在项目已解散，将无法查看该项目内容"
  },
  "fpv_tip_0x20120900": {
    "de": "Echtzeit-Kartierung gestoppt",
    "en": "Real-time mapping stopped",
    "es": "Cartografía en tiempo real detenida",
    "fr": "Cartographie en temps réel arrêtée",
    "ja": "リアルタイムマッピングが停止しました",
    "ko": "실시간 매핑 중단됨",
    "ru": "Создание карт в реальном времени остановлено",
    "tr": "Gerçek zamanlı haritalama durduruldu",
    "zh": "实时建图停止"
  },
  "fpv_tip_0x20120A00": {
    "de": "Livestream gestartet",
    "en": "Livestream started",
    "es": "Retransmisión en directo iniciada",
    "fr": "La diffusion en direct a commencé",
    "ja": "ライブ配信が開始されました",
    "ko": "라이브 스트림 시작됨",
    "ru": "Прямая трансляция началась",
    "tr": "Canlı yayın başladı",
    "zh": "已开启直播"
  },
  "fpv_tip_0x20120B00": {
    "de": "Der Flugrouteneinsatz wurde manuell unterbrochen",
    "en": "Flight route mission paused manually",
    "es": "Misión de ruta de vuelo pausada manualmente",
    "fr": "Mission d\\'itinéraire de vol interrompue manuellement",
    "ja": "飛行ルート ミッションを手動で一時停止",
    "ko": "수동으로 비행경로 임무 일시 정지됨",
    "ru": "Полетная миссия приостановлена вручную",
    "tr": "Uçuş rotası görevi manuel olarak duraklatıldı",
    "zh": "您已手动暂停航线任务"
  },
  "fpv_tip_0x20120B01": {
    "de": "RTK-Signal schwach. Flugrouteneinsatz wurde unterbrochen",
    "en": "RTK signal weak. Flight route mission paused",
    "es": "Señal RTK débil. Misión de ruta de vuelo en pausa",
    "fr": "Signal RTK faible. Mission d\\'itinéraire de vol interrompue",
    "ja": "RTK信号 弱。飛行ルート ミッションを一時停止",
    "ko": "RTK 신호 약함. 비행경로 임무 일시 정지됨",
    "ru": "Слабый сигнал RTK. Полетная миссия приостановлена",
    "tr": "RTK sinyali zayıf. Uçuş rotası görevi duraklatıldı",
    "zh": "RTK信号差，航线任务已暂停"
  },
  "fpv_tip_0x20120B02": {
    "de": "Max. Flughöhe erreicht. Flugrouteneinsatz wurde unterbrochen",
    "en": "Max flight altitude reached. Flight route mission paused",
    "es": "Se ha alcanzado la altitud máxima de vuelo. Misión de ruta de vuelo en pausa",
    "fr": "Altitude de vol max. atteinte. Mission d\\'itinéraire de vol interrompue",
    "ja": "最大飛行高度に到達。飛行ルート ミッションを一時停止",
    "ko": "최고 비행 고도 도달. 비행경로 임무 일시 정지됨",
    "ru": "Достигнут предел высоты полета дрона. Полетная миссия приостановлена",
    "tr": "Maksimum uçuş irtifasına ulaşıldı. Uçuş rotası görevi duraklatıldı",
    "zh": "飞行器已达到飞行最大高度，航线任务已暂停"
  },
  "fpv_tip_0x20120B03": {
    "de": "Max. Flugdistanz erreicht. Flugrouteneinsatz wurde unterbrochen",
    "en": "Max flight distance reached. Flight route mission paused",
    "es": "Se ha alcanzado la distancia máxima de vuelo. Misión de ruta de vuelo en pausa",
    "fr": "Distance de vol max. atteinte. Mission d\\'itinéraire de vol interrompue",
    "ja": "最大飛行距離に到達。飛行ルート ミッションを一時停止",
    "ko": "최대 비행 거리 도달. 비행경로 임무 일시 정지됨",
    "ru": "Достигнуто макс. расстояние полета. Полетная миссия приостановлена",
    "tr": "Maksimum uçuş mesafesine ulaşıldı. Uçuş rotası görevi duraklatıldı",
    "zh": "飞行器已达到飞行最远距离，航线任务已暂停"
  },
  "fpv_tip_0x20120B04": {
    "de": "Flugaufgabe pausiert. Hindernis erkannt oder Fluggerät nähert sich einer GEO-Zone",
    "en": "Flight task paused. Obstacle detected or aircraft approaching GEO Zone",
    "es": "Tarea de vuelo en pausa. Obstáculo detectado o aeronave acercándose a la zona GEO.",
    "fr": "Tâche de vol mise en pause. Obstacle détecté ou appareil en approche d'une zone GEO",
    "ja": "飛行タスクが一時停止。障害物が検知されたか、機体がGEO区域に接近中です",
    "ko": "비행 작업 일시 정지됨. 장애물이 감지되었거나 기체가 GEO 구역에 접근 중입니다.",
    "ru": "Полетная задача приостановлена. Обнаружено препятствие или дрон приближается к зоне GEO",
    "tr": "Uçuş görevi duraklatıldı. Engel algılandı veya hava aracı GEO Bölgesine yaklaşıyor",
    "zh": "航线任务暂停，飞行器检测到障碍物或靠近限飞区"
  },
  "fpv_tip_0x20120B05": {
    "de": "Fluggerät nähert sich einer GEO-Zone. Aufgabe unterbrochen.",
    "en": "Approaching GEO Zone. Task paused",
    "es": "Se está acercando a una zona GEO. Tarea en pausa",
    "fr": "Approche de la zone GEO. Tâche interrompue",
    "it": "",
    "ja": "GEO区域に近づいています。タスクが一時停止しました",
    "ko": "GEO 구역에 접근 중. 작업 일시 중지됨",
    "pt": "",
    "ru": "Приближение к зоне GEO. Задание приостановлено",
    "tr": "GEO Bölgesine yaklaşılıyor. Görev duraklatıldı",
    "zh": "飞行器接近限飞区，航线任务已暂停"
  },
  "fpv_tip_0x20120B06": {
    "de": "Mindestflughöhe erreicht. Aufgabe unterbrochen.",
    "en": "Min flight altitude reached. Task paused",
    "es": "Se ha alcanzado la altitud mín. de vuelo. Tarea en pausa",
    "fr": "Altitude en vol minimale atteinte. Tâche interrompue",
    "ja": "最低飛行高度に達しました。タスクが一時停止されました",
    "ko": "최저 비행 고도에 도달했습니다. 작업 일시 중지됨",
    "ru": "Достигнута минимальная высота полета. Задание приостановлено",
    "tr": "Min. uçuş irtifasına ulaşıldı. Görev duraklatıldı",
    "zh": "飞行器已达到飞行最低高度，航线任务已暂停"
  },
  "fpv_tip_0x20120B07": {
    "de": "Unbekannter Fehler. Aufgabe unterbrochen.",
    "en": "Unknown error. Task paused",
    "es": "Error desconocido. Tarea en pausa",
    "fr": "Erreur inconnue. Tâche interrompue",
    "ja": "原因不明のエラー。タスクが一時停止されました",
    "ko": "알 수 없는 오류. 작업 일시 중지됨",
    "ru": "Неизвестная ошибка. Задание приостановлено",
    "tr": "Bilinmeyen hata. Görev duraklatıldı",
    "zh": "未知原因，航线任务已暂停"
  },
  "fpv_tip_0x20120B08": {
    "de": "Maximale Flughöhe der Flughafen-Höhenlagezone erreicht",
    "en": "Max flight altitude of airport Altitude Zone reached",
    "es": "Altitud máxima de vuelo de zona de aeropuerto alcanzada",
    "fr": "Altitude maximale de vol de la zone d'altitude de l'aéroport atteinte",
    "ja": "空港の高度制限区域の最大飛行高度に達しました",
    "ko": "공항 고도 제한 구역 최고 비행 고도에 도달했습니다",
    "ru": "Достигнута максимальная высота полета в зоне ограничения высоты полета аэропорта",
    "tr": "Havaalanı İrtifa Bölgesinin maks. uçuş irtifasına ulaşıldı",
    "zh": "超过机场限高区限高"
  },
  "fpv_tip_0x20120B09": {
    "de": "Notbremse ausgelöst. Flugaufgabe pausiert",
    "en": "Emergency brake triggered. Flight task paused",
    "es": "Freno de emergencia accionado. Tarea de vuelo en pausa",
    "fr": "Frein d'urgence déclenché. Tâche de vol mise en pause",
    "ja": "緊急ブレーキが作動しました。飛行タスクが一時停止しました",
    "ko": "비상 브레이크가 작동되었습니다. 비행이 일시 정지되었습니다",
    "ru": "Сработал аварийный тормоз. Полетная задача приостановлена",
    "tr": "Acil durum freni tetiklendi. Uçuş görevi duraklatıldı",
    "zh": "触发紧急刹停，航线任务已暂停"
  },
  "fpv_tip_0x20120B0A": {
    "de": "Umgebungslicht zu hell oder zu dunkel",
    "en": "Ambient light is too bright or too dark",
    "es": "La luz ambiental es demasiado brillante o demasiado oscura",
    "fr": "La lumière ambiante est trop intense ou trop sombre",
    "ja": "環境光が明るすぎるか、暗すぎます",
    "ko": "주변 조명이 너무 밝거나 너무 어두움",
    "ru": "Окружающий свет слишком яркий или слишком темный",
    "tr": "Ortam ışığı çok parlak veya çok az",
    "zh": "环境光过亮或过暗"
  },
  "fpv_tip_0x20120B0B": {
    "de": "Schwaches GPS-Signal. Flugaufgabe pausiert",
    "en": "Weak GPS signal. Flight task paused",
    "es": "Señal GPS débil. Tarea de vuelo en pausa",
    "fr": "Signal GPS faible. Tâche de vol mise en pause",
    "ja": "GPS信号が弱い。飛行タスクが一時停止しました",
    "ko": "GPS 신호 약함. 비행 작업이 일시 정지됩니다.",
    "ru": "Слабый сигнал GPS. Полетная задача приостановлена",
    "tr": "Zayıf GPS sinyali. Uçuş görevi duraklatıldı",
    "zh": "GPS信号差，航线任务已暂停"
  },
  "fpv_tip_0x20120B0C": {
    "de": "Startpunkt nicht aktualisiert. Flugaufgabe pausiert",
    "en": "Home Point not updated. Flight task paused",
    "es": "Punto de origen no actualizado. Tarea de vuelo en pausa",
    "fr": "Point de départ non mis à jour. Tâche de vol mise en pause",
    "ja": "ホームポイントが更新されていません。飛行タスクが一時停止しました",
    "ko": "홈포인트 업데이트 안 됨. 비행 작업이 일시 정지됩니다.",
    "ru": "Домашняя точка не обновлена. Полетная задача приостановлена",
    "tr": "Başlangıç Noktası güncellenmedi. Uçuş görevi duraklatıldı",
    "zh": "返航点未刷新，航线任务已暂停"
  },
  "fpv_tip_0x20120B0D": {
    "de": "Fernsteuerung getrennt. Intelligente Rückkehr zum Startpunkt läuft",
    "en": "Remote controller disconnected. Smart RTH in progress",
    "es": "Control remoto no conectado. RPO inteligente en curso",
    "fr": "Radiocommande déconnectée. RTH intelligent en cours",
    "ja": "送信機の接続が切断されました。スマートRTHが進行中です",
    "ko": "조종기 연결 끊김. 스마트 RTH가 진행 중입니다.",
    "ru": "Пульт ДУ отключен. Выполняется умный возврат домой",
    "tr": "Uzaktan kumanda bağlantısı kesildi. Akıllı BND sürdürülüyor",
    "zh": "遥控器断连，正在执行智能返航"
  },
  "fpv_tip_0x20120B0E": {
    "de": "Automatischer Start fehlgeschlagen. Flugroute versuchsweise erneut hochladen",
    "en": "Auto takeoff failed. Try uploading flight route again",
    "es": "Error de despegue automático. Pruebe a cargar la ruta de vuelo de nuevo",
    "fr": "Échec du décollage automatique. Essayer de recharger à nouveau la trajectoire de vol",
    "ja": "自動離陸に失敗しました。飛行ルートをアップロードし直してください",
    "ko": "자동 이륙 실패. 비행 경로를 다시 업로드해 보세요.",
    "ru": "Не удалось выполнить автовзлет. Попробуйте загрузить маршрут полета еще раз",
    "tr": "Otomatik kalkış başarısız. Uçuş rotasını tekrar yüklemeyi deneyin",
    "zh": "自动起飞失败，请尝试重新上传航线"
  },
  "fpv_tip_0x20120B0F": {
    "de": "Annäherung an Grenze von benutzerdefiniertem Fluggebiet. Flugaufgabe pausiert",
    "en": "Approaching boundary of custom flight area. Flight task paused",
    "es": "Acercándose al límite de la zona de vuelo personalizada. Tarea de vuelo en pausa",
    "fr": "Approche en cours des limites de la zone de vol personnalisée. Tâche de vol mise en pause",
    "ja": "カスタム飛行エリアの境界に近づいています。飛行タスクが一時停止しました",
    "ko": "맞춤 설정 비행 영역의 경계에 접근 중. 비행 작업이 일시 정지됩니다.",
    "ru": "Приближение к границе пользовательской зоны полета. Полетная задача приостановлена",
    "tr": "Özel uçuş alanının sınırına yaklaşılıyor. Uçuş görevi duraklatıldı",
    "zh": "接近自定义飞行区，航线任务已暂停"
  },
  "fpv_tip_0x20120B10": {
    "de": "Fehler Terrain-Follow-Flughöhe (mehr als 200 m oder weniger als 30 m). Parameter ändern",
    "en": "Terrain Follow altitude error (greater than 200 m or less than 30 m). Modify parameter",
    "es": "Error de altitud y altura constante (más de 200 m o menos de 30 m). Modificar parámetro",
    "fr": "Erreur d’altitude du suivi terrain (supérieure à 200 m ou inférieure à 30 m). Modifier le paramètre",
    "ja": "地形フォロー高度エラー (200 m超または30 m未満)。パラメーターを修正してください",
    "ko": "지형 팔로우 고도 오류(200m 초과 또는 30m 미만). 매개변수를 수정하세요.",
    "ru": "Ошибка высоты в режиме огибания рельефа (более 200 м или менее 30 м). Изменить параметр",
    "tr": "Arazi Takip irtifası hatası (200 m'den fazla veya 30 m'den az). Parametreyi değiştirin",
    "zh": "仿地高度异常（大于200m或小于30m），请修改参数"
  },
  "fpv_tip_0x20120B11": {
    "de": "Windgeschwindigkeit zu hoch. Flugaufgabe pausiert",
    "en": "Wind speed too high. Flight task paused",
    "es": "Velocidad del viento demasiado alta. Tarea de vuelo en pausa",
    "fr": "Vitesse du vent trop élevée. Tâche de vol mise en pause",
    "ja": "風速が強すぎます。飛行タスクが一時停止しました",
    "ko": "풍속이 너무 높음. 비행 작업이 일시 정지됩니다.",
    "ru": "Слишком высокая скорость ветра. Полетная задача приостановлена",
    "tr": "Rüzgar hızı çok yüksek. Uçuş görevi duraklatıldı",
    "zh": "风速过大，航线任务已暂停"
  },
  "fpv_tip_0x20120B12": {
    "de": "Fluggerät an der Grenze einer Höhenlagezone oder Flughöhe des Fluggeräts zu niedrig. Flugaufgabe pausiert",
    "en": "Aircraft at the boundary of an Altitude Zone or aircraft altitude too low. Flight task paused",
    "es": "Aeronave en el límite de una zona de altitud o altitud de la aeronave demasiado baja. Tarea de vuelo en pausa",
    "fr": "Appareil à proximité immédiate d’une zone à altitude limitée ou altitude de l’appareil trop basse. Tâche de vol mise en pause",
    "ja": "機体が高度制限区域の境界にあるか、機体の高度が低すぎます。飛行タスクが一時停止しました",
    "ko": "기체가 고도 제한 구역의 경계에 있거나 기체 고도가 너무 낮음. 비행 작업이 일시 정지됩니다.",
    "ru": "Дрон на границе зоны ограничения высоты полета или высота полета слишком мала. Полетная задача приостановлена",
    "tr": "Hava aracı bir İrtifa Bölgesinin sınırında veya araç irtifası çok düşük. Uçuş görevi duraklatıldı",
    "zh": "触碰到限高区或飞行器高度过低，航线任务已中断"
  },
  "fpv_tip_0x20120BA0": {
    "en": "Aircraft verification in progress...",
    "zh": "飞行器校验中"
  },
  "fpv_tip_0x20120C00": {
    "de": "Sichtpositionierung deaktivieren",
    "en": "Disable Vision Positioning",
    "es": "Desactivar sistema de visión inferior",
    "fr": "Positionnement visuel OFF",
    "ja": "下方ビジョンシステムをオフ",
    "ko": "하향 비전 시스템 꺼짐",
    "ru": "выкл систему нижнего обзора",
    "tr": "Aşağı Görüş Sistemini Kapat",
    "zh": "禁用视觉定位"
  },
  "fpv_tip_0x20120C01": {
    "de": "ichtpositionierung aktivieren",
    "en": "Enable Vision Positioning",
    "es": "Activar sistema de visión inferior",
    "fr": "Positionnement visuel ON",
    "ja": "下方ビジョンシステムをオン",
    "ko": "하향 비전 시스템 켜짐",
    "ru": "Вкл систему нижнего обзора",
    "tr": "Aşağı Görüş Sistemini Aç",
    "zh": "启用视觉定位"
  },
  "fpv_tip_0x20120C02": {
    "en": "Cloud control from DJI DeliveryHub enabled",
    "zh": "已允许大疆司运指令"
  },
  "fpv_tip_0x20120C03": {
    "en": "Pausing flight through cloud control",
    "zh": "执行云端急停指令"
  },
  "fpv_tip_0x20120C04": {
    "en": "Aircraft landing through cloud control",
    "zh": "执行云端降落指令"
  },
  "fpv_tip_0x20120C05": {
    "en": "Aircraft returning to home through cloud control",
    "zh": "执行云端返航指令"
  },
  "fpv_tip_0x20120C06": {
    "en": "Operating winch system through cloud control",
    "zh": "执行云端空吊指令"
  },
  "fpv_tip_0x20120C07": {
    "en": "Operating FPV gimbal camera through cloud control",
    "zh": "执行云端FPV指令"
  },
  "fpv_tip_0x20120C08": {
    "en": "Performing flight task through cloud control",
    "zh": "执行云端航线指令"
  },
  "fpv_tip_0x20120C09": {
    "en": "Cloud control from DJI DeliveryHub disabled",
    "zh": "已撤销云端指令许可"
  },
  "fpv_tip_0x20120C0A": {
    "en": "RTH canceled by cloud control",
    "zh": "云端已取消返航"
  },
  "fpv_tip_0x20120D01": {
    "en": "Obstacle sensing of all directions disabled. Fly with caution",
    "zh": "全部避障已关闭，请安全飞行"
  },
  "fpv_tip_0x20120D02": {
    "en": "Horizontal obstacle sensing disabled. Fly with caution",
    "zh": "水平避障已关闭，请安全飞行"
  },
  "fpv_tip_0x20120D03": {
    "en": "Upward obstacle sensing disabled. Fly with caution",
    "zh": "上避障已关闭，请安全飞行"
  },
  "fpv_tip_0x20120D04": {
    "en": "Downward obstacle sensing disabled. Fly with caution",
    "zh": "下避障已关闭，请安全飞行"
  },
  "fpv_tip_0x20120D05": {
    "en": "Obstacle sensing of all directions enabled",
    "zh": "全部避障已开启"
  },
  "fpv_tip_0x20120D06": {
    "en": "Horizontal obstacle sensing enabled",
    "zh": "水平避障已开启"
  },
  "fpv_tip_0x20120D07": {
    "en": "Upward obstacle sensing enabled",
    "zh": "上避障已开启"
  },
  "fpv_tip_0x20120D08": {
    "en": "Downward obstacle sensing enabled",
    "zh": "下避障已开启"
  },
  "fpv_tip_0x20120E00": {
    "de": "Hohe ionosphärische Aktivität im aktuellen Gebiet. Genauigkeit der Positionsbestimmung möglicherweise beeinträchtigt",
    "en": "High ionospheric activity in current area. Positioning accuracy may be affected",
    "es": "Alta actividad ionosférica en la zona actual. La precisión de posicionamiento podría verse afectada",
    "fr": "Activité ionosphérique élevée dans la zone actuelle. La précision du positionnement risque d'être affectée",
    "ja": "現在の場所で電離層活動が活発。測位精度に影響する可能性があります",
    "ko": "현재 지역에서 높은 전리층 활동. 위치 정확도가 영향을 받을 수 있습니다",
    "ru": "Высокая ионосферная активность в текущем районе. Точность позиционирования может быть нарушена",
    "tr": "Geçerli alanda yüksek iyonosferik aktivite. Konumlandırma doğruluğu etkilenebilir",
    "zh": "当前地区电离层开始活跃，可能影响定位精度"
  },
  "fpv_tip_0x20120F00": {
    "ar": "",
    "de": "Benutzerkonto nicht gefunden. Flugdistanz beschränkt. Bitte anmelden.",
    "en": "Account not logged in. Flight altitude and distance restricted to 30 m and 50 m respectively. Login required",
    "es": "No se inició sesión. Distancia y altitud de vuelo limitadas a 50 y 30 m. Inicia sesión",
    "fr": "Vous n'êtes pas connecté. La distance et l'altitude de vol sont limitées à 30 m et 50 m. Vérifiez et connectez-vous",
    "id": "",
    "it": "",
    "ja": "アカウントにログインしていません。飛行高度は30 mに、飛行距離は50 mに制限。 確認してログインしてください。",
    "ko": "계정 로그인 안 됨. 비행 고도(30m) 및 거리(50m) 제한 있음. 확인 후 로그인하세요",
    "nl": "",
    "pl": "",
    "pt": "Conta não logada. Altitude e distância de voo restritas a 30 m e 50 m. Verifique e faça login",
    "pt-PT": "",
    "ru": "Вход не выполнен. Высота и расстояние полета ограничены до 30 и 50 м. Проверьте и выполните вход",
    "th": "",
    "tr": "Hesap otur. açılamadı. Uçuk irtifası ve mesafesi 30 m ve 50 m ile sınırlıdır. Kontrol et ve aç",
    "ug": "",
    "vi": "",
    "zh": "未检测到账号，已限高30m，限远50m，请登录",
    "zh-Hant": ""
  }
}