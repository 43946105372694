<template>
    <div class="container2">
        <div class="startMission">
            
            <v-chip-group>
                <v-chip @click="ui2Store.subVideoSize()">
                    <v-icon color="blue" >mdi-magnify-minus</v-icon>
                </v-chip>
                <v-chip @click="ui2Store.addVideoSize()">
                    <v-icon color="blue" >mdi-magnify-plus</v-icon>
                </v-chip>
                <v-chip>
                    {{ droneModeString }}
                </v-chip>
            </v-chip-group>

                <!--
                <v-chip>
                    <v-icon>mdi-map</v-icon>
                </v-chip>
                <v-chip>
                    <v-icon>mdi-airplane</v-icon>
                    {{ getJobStatus }}
                </v-chip>
                -->
                <!-- 
                <v-chip v-if="getLastJob.progress !== null && getLastJob.progress !== undefined">
                    {{ getLastJob.progress }}%
                </v-chip>
                -->
            <v-chip @click="wStopFlyToPoint" v-if="this.droneMode==17"> <v-icon>mdi-pause</v-icon>
            </v-chip>
 
            <v-chip-group v-if="getLastJob !== null">
 
                <v-chip @click="pauseJob" v-if="getLastJob.status == 2 && pausableDrone"> <v-icon>mdi-pause</v-icon>
                </v-chip>
                <v-chip @click="resumeJob" v-if="getLastJob.status == 6 && resumableDrone"> <v-icon>mdi-play</v-icon>
                </v-chip>
                <!-- <v-chip @click="show"><v-icon>mdi-dots-vertical</v-icon></v-chip> -->
            </v-chip-group>
        </div>

       

        <div class="endMission">
            <v-chip-group>


                <v-chip>
                    <v-icon @click="this.rth">mdi-home-import-outline</v-icon>
                </v-chip>

                <v-chip>
                    <v-icon v-if="showFlyToPoint == 1" color="black">mdi-map-marker</v-icon>
                    <v-icon v-if="showFlyToPoint == 2" color="green" @click="switchFlyToPoint">mdi-map-marker</v-icon>
                    <v-icon v-if="showFlyToPoint == 3" color="red" @click="switchFlyToPoint">mdi-map-marker</v-icon>
                </v-chip>                
                
                <v-chip v-if="uiStore.showDroneControl" @click="uiStore.showDroneControl=!uiStore.showDroneControl" >
                    <v-icon color="green">mdi-controller</v-icon>
                </v-chip>
                <v-chip v-else @click="uiStore.showDroneControl=!uiStore.showDroneControl" >
                    <v-icon color="red">mdi-controller</v-icon>
                </v-chip>
                
            </v-chip-group>
        </div>

        <!--        <FloatComponent v-if="showMenu" title="Acciones" idx="telemetry-box" x="102%" y="50%">
            <v-item-group>
                <v-btn size="x-small" color="white" class="btn1" @click="uiStore.showDroneControl=!uiStore.showDroneControl"> Control Remoto </v-btn>
                <v-btn size="x-small" color="white" class="btn1"> RTH </v-btn>
            </v-item-group>
            </FloatComponent>
-->
    </div>


</template>

<script>
import { deleteFlyToPoint } from '../../../../api/drone-control/drone.ts';

import {
    taskPauseService,
    taskResumeService,
} from "@/services/taskService.js";
import { rthService } from "@/services/dockService.js";
import { getJobsService } from "@/services/taskService.js";
//import FloatComponent from "@/components/FloatComponent.vue";
import { defineComponent } from "vue";
import useStore from "@/store";
import useUiStore from "@/ui"
import useUi2Store from "@/store/ui";
import { storeToRefs } from "pinia";
export default defineComponent({
    props: {

    },
    components: {
        //FloatComponent,
    },
    setup() {
        const store = useStore();
        const dockSn = store.device_sn;
        const droneSn = store.child_device_sn;
        const { telemetry } = storeToRefs(store);
        const uiStore = useUiStore();
        const ui2Store = useUi2Store();
        const { showFlyToPoint, flyToPointActive } = storeToRefs(ui2Store)
        return {
            uiStore,
            store,
            telemetry,
            dockSn,
            droneSn,
            ui2Store,
            showFlyToPoint,
            flyToPointActive
        };
    },
    computed: {
        droneTelemetry() {
            if (this.exist(this.telemetry[this.droneSn])) {
                if (this.exist(this.telemetry[this.droneSn]["osd_telemetry"])) {
                    try {
                        return this.telemetry[this.droneSn]["osd_telemetry"]["data"];
                    } catch {
                        return null;
                    }
                }
            }
            return null;
        },
        droneMode() {
            const droneTelemetry = this.droneTelemetry;
            if (droneTelemetry == null) {
                return false;
            }
            return droneTelemetry["mode_code"];
        },
        droneModeString() {
            const mode = '' + this.droneMode;
            const statusRef = { "0": "Standby", "1": "Takeoff preparation", "2": "Takeoff preparation completed", "3": "Manual flight", "4": "Automatic takeoff", "5": "Wayline flight", "6": "Panorama", "7": "Intelligent tracking", "8": "ADS-B avoidance", "9": "Auto returning to home", "10": "Automatic landing", "11": "Forced landing", "12": "Three-blade landing", "13": "Upgrading", "14": "Not connected", "15": "APAS", "16": "Virtual stick state", "17": "Live Flight Controls", "18": "Airborne RTK fixing mode", "19": "Dock address selecting", "20": "POI" }

            return statusRef[mode];
        },
        pausableDrone() {
            const droneTelemetry = this.droneTelemetry;
            if (droneTelemetry == null) {
                return false;
            }
            const mode = droneTelemetry["mode_code"];
            return mode == 5;
        },
        resumableDrone() {
            const droneTelemetry = this.droneTelemetry;
            if (droneTelemetry == null) {
                return false;
            }
            return droneTelemetry["mode_code"] == 3;
        },
        getJobStatus() {
            /*
            const options = [
                ["canceled", "Cancelled or terminated"], // 0
                ["failed", "Failed"], // 1
                ["in_progress", "Executing"], // 2
                ["ok","Execution successful"], // 3
                ["partially_done","Partially completed"], // 4
                ["paused","Pause"], // 5
                ["rejected", "Rejected"], // 6
                ["sent", "Issued"], // 7
                ["timeout", "Timeout"] // 8
            ];
            */
            const status = this.getLastJob.status;
            const options2 = [
                "Cancelada",
                "Finalizada",
                "En progreso",
                "Completada",
                "Parcialmente completada",
                "Rechazada",
                "Pausada",
                "Enviada",
                "Timeout"
            ]
            return options2[status];
        },
        dockTelemetry() {
            if (this.exist(this.telemetry[this.dockSn])) {
                if (this.exist(this.telemetry[this.dockSn]["events_flighttask_progress"])) {
                    try {
                        return this.telemetry[this.dockSn]["events_flighttask_progress"];
                    } catch {
                        return [];
                    }
                }
            }
            return [];
        },
        getLastJob() {

            return this.lastJob
        }
    },
    methods: {
        wStopFlyToPoint() {
            //const dockSn = this.dockSn;
            deleteFlyToPoint(this.dockSn);
        },
        switchFlyToPoint() {
            if (this.flyToPointActive == true) {
                this.flyToPointActive = false
                return
            } else if (this.flyToPointActive == false) {
                this.flyToPointActive = true
            }
        },
        exist(data) {
            return data !== undefined && data !== null;
        },
        setMission(mission_name, progress, status) {
            const mission = {
                mission_name: mission_name,
                progress: progress,
                status: status,
            }
            this.mission = mission;
        },
        async rth() {
            console.log("RTH REQ")
            const result = await rthService(this.dockSn);
            console.log(result)
        },
        async updateMissionStatus() {
            const jobs = await getJobsService();
            console.log(jobs);
            console.log(this.dockSn)
            for (let i = 0; i < jobs.length; i++) {
                if (jobs[i]["dock_sn"] == this.dockSn && (jobs[i]["status"] == 2 || jobs[i]["status"] == 6)) {
                    this.lastJob = jobs[i];
                    console.log(this.lastJob)
                    return;
                } else {
                    console.log("no")
                }
            }
            this.lastJob = null;
        },
        async pauseJob() {
            const taskId = this.lastJob["job_id"];
            await taskPauseService(taskId);
        },
        async resumeJob() {
            const taskId = this.lastJob["job_id"];
            await taskResumeService(taskId);
        },
        show() {
            this.showMenu = !this.showMenu;
        },


    },
    data() {
        return {
            jobs: [],
            lastJob: null,
            mission: null,
            showMenu: false,
            x: 0,
            y: 0,
        };
    },
    watch: {
        async dockTelemetry() {
            await this.updateMissionStatus();
        }
    },
    async mounted() {
        await this.updateMissionStatus();
    },
});
</script>

<style scoped>
.container2 {
    display: flex;
    width: 100%;
}
.startMission {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-size: small;
}

.endMission {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    font-size: small;
}

.centerMission {
    width: 100%;
}

.gimbalContainer {
    height: 200px !important;
    width: 200px !important;
    align-items: center;
    justify-content: center;
}

.telemetryContainer {
    height: 35px !important;
    width: auto !important;
    font-size: small;
}

.menu1 {
    position: absolute;
    top: "10px";
    left: "0px";
}

.btn1 {
    display: block;
    margin: 2px;
    width: 100%;
}


</style>