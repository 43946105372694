import { postPayloadAuth, postPayloadCommands } from "@/api/drone-control/payload.ts";
import { changeLivestreamLens } from "@/api/manage.ts";

export const setLentService = async (video_id, video_type) => {
  const body = {
    video_id: video_id,
    video_type: video_type
  }
  return await changeLivestreamLens(body)
}

export const payloadAuthService = async (sn, payload_index) => {
    /*
    export interface body {
        payload_index: string
    }
    */
   const body = {
        payload_index: payload_index
   }
   console.log(body)
   const r = await postPayloadAuth(sn, body)
   console.log(r)
   return r

}

const payloadCommandsService = async (sn, body) => {
    /*
    export interface body {
        cmd: string,
        data: any
    }
    */
    return await postPayloadCommands(sn, body)
}

export const cameraIamService = async (sn, payloadIndex, cameraType, locked, x, y) => {
    const body = {
        cmd: 'camera_aim',
        data: {
            payload_index: payloadIndex,
            camera_type: cameraType,
            locked: locked,
            x: x,
            y: y,
        }
    }
    console.log(body)
    return await payloadCommandsService(sn, body)
}

export const cameraFocalLengthSetService = async (sn, payloadIndex, cameraType, zoomFactor) => {
    const body = {
        cmd: 'camera_focal_length_set',
        data: {
            payload_index: payloadIndex,
            camera_type: cameraType,
            zoom_factor: zoomFactor
        }
    }
    return await payloadCommandsService(sn, body)
}

export const gimbalResetService = async (sn, payloadIndex, resetMode) => {
    /*
        Recenter = 0,
        Down = 1,
        RecenterGimbalPan = 2,
        PitchDown = 3,
    */
    const body = {
        cmd: 'gimbal_reset',
        data: {
            payload_index: payloadIndex,
            reset_mode: resetMode
        }
    }
    return await payloadCommandsService(sn, body)
}

/*
export enum PayloadCommandsEnum {
    CameraModeSwitch = 'camera_mode_switch',
    CameraPhotoTake = 'camera_photo_take',
    CameraRecordingStart = 'camera_recording_start',
    CameraRecordingStop = 'camera_recording_stop',
    CameraFocalLengthSet = 'camera_focal_length_set',
    GimbalReset = 'gimbal_reset',
    CameraAim = 'camera_aim'
}
*/

/*
export interface PostCameraFocalLengthBody {
  payload_index: string,
  camera_type: CameraType,
  zoom_factor: number
}
*/

/*
export interface PostCameraAimBody{
  payload_index: string,
  camera_type: CameraType,
  locked: boolean,
  x: number,
  y: number,
}
*/

/*

export type PostPayloadCommandsBody = {
  cmd: PayloadCommandsEnum.CameraModeSwitch,
  data: PostCameraModeBody
} | {
  cmd: PayloadCommandsEnum.CameraPhotoTake,
  data: PostCameraPhotoBody
} | {
  cmd: PayloadCommandsEnum.CameraRecordingStart,
  data: PostCameraRecordingBody
} | {
  cmd: PayloadCommandsEnum.CameraRecordingStop,
  data: DeleteCameraRecordingParams
} | {
  cmd: PayloadCommandsEnum.CameraFocalLengthSet,
  data: PostCameraFocalLengthBody
} | {
  cmd: PayloadCommandsEnum.GimbalReset,
  data: PostGimbalResetBody
} | {
  cmd: PayloadCommandsEnum.CameraAim,
  data: PostCameraAimBody
}
*/