<template>
    <div class="visibility" @mousedown="dragMouseDown">
        <div ref="draggableContainer" id="draggable-container">
            <div id="draggable-header" >
                <text class="noselect"> {{ title }} </text>
            </div>
            <slot>
            </slot>
        </div>
    </div>
</template>

<script>
import useUiStore from "@/ui";
import { storeToRefs } from "pinia";
export default {
    name: "FloatComponent",
    setup() {
        const uiStore = useUiStore();
        const { floatVisibility } = storeToRefs(uiStore);
        return {
            uiStore,
            floatVisibility,
        };
    },
    props: {
        title: {
            type: String,
            default: "Title",
        },
        idx: {
            type: String,
            required: true,
        },
        x: {
            type: String,
            default: "100px",
        },
        y: {
            type: String,
            default: "100px",
        },
        show: {
            type: Boolean,
            default: true,
        },
    },
    mounted() {
        this.floatVisibility[this.idx] = this.show;
    },
    computed: {
        visibility() {
            try {
                return this.floatVisibility[this.idx] ? "block" : "none";
            } catch {
                return "none";
            }
        },
    },
    data: function () {
        return {
            positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0,
            },
        };
    },
    methods: {
        dragMouseDown: function (event) {
            event.preventDefault();
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;
            document.onmousemove = this.elementDrag;
            document.onmouseup = this.closeDragElement;
        },
        elementDrag: function (event) {
            event.preventDefault();
            this.positions.movementX = this.positions.clientX - event.clientX;
            this.positions.movementY = this.positions.clientY - event.clientY;
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;
            // set the element's new position:
            this.$refs.draggableContainer.style.top =
                this.$refs.draggableContainer.offsetTop -
                this.positions.movementY +
                "px";
            this.$refs.draggableContainer.style.left =
                this.$refs.draggableContainer.offsetLeft -
                this.positions.movementX +
                "px";
        },
        closeDragElement() {
            document.onmouseup = null;
            document.onmousemove = null;
        },


    }
};


</script>

<style scoped>
#draggable-container {
    position: absolute;
    top: v-bind('y');/* 400px; */
    left: v-bind('x'); /* 60px; */
    background-color: #fff;
    /*border: 1px solid #999;*/
    text-align: center;
    align-items: center;
    font-family: "Roboto", "sans-serif";
    background-color: rgba(0, 0, 0, 0.75);
    width: fit-content;
    height: fit-content;
}

#draggable-header {
    z-index: -9;
    background-color: #1f1f1f;
    height: 20px;
    font-size: 14px;
}

.visibility {
    display: v-bind("visibility");
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
</style>
