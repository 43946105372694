import { defineStore } from "pinia";


export default defineStore({
  id: "app",
  state: () => ({
    device_sn: null,
    child_device_sn: null,
    device: null,
    lentSelection: null,
    cameraSelected: {
      "index": "53-0-0"
    },
    agl: -999,
    mqtt: {
      client: null,
      isConnected: false,
    },
    gatewayConnected: false,
    droneConnected: false,
    telemetry: {},
    google: null,
    mapContainer: null,
    map: null,
    droneMarker: null,
    droneCoordinates: {"lat": 0, "lng": 0},
    gatewayMarker: null,
    gatewayCoordinates: {"lat": 0, "lng": 0},
    selectedWayline: null,
    selectedWaylineObject: null,
    devices: [],
    videoUrl: null,
  }),

  getters: {
    getDeviceSn() {
      return this.device_sn;
    },
    getChildDeviceSn() {
      return this.child_device_sn;
    },
    getDevice() {
      return this.device;
    },
  },
  
  actions: {
    setDeviceSn(sn) {
      this.device_sn = sn;
    },
    setChildDeviceSn(sn) {
      this.child_device_sn = sn;
    },
    setDevice(device) {
      this.device = device;
    },
    setMQTTClient(client) {
      this.mqtt.client = client;
    },
    centerGatewayMap() {
      this.map.setCenter(this.gatewayCoordinates);
      this.map.setZoom(20);
    },
    centerDroneMap() {
      this.map.setCenter(this.droneCoordinates);
      this.map.setZoom(20);
    },
  },
});
