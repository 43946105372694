<template>
  <v-card>
    <v-card-title>{{ this.device.nickname }}</v-card-title>
    <v-card-subtitle>{{ this.device.device_name }}</v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col> SN: {{ this.device.device_sn }} </v-col>
        <v-col> Drone SN: {{ this.device.child_device_sn }} </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        @click="openDevice()"
        color="primary"
        variant="elevated"
        rounded="true"
        text
        >Abrir</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import router from "@/router";
import store from "@/store";
export default {
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data: () => ({}),
  setup() {
    const useStore = store();
    return {
      store: useStore,
    };
  },
  mounted() {},
  methods: {
    openDevice() {
      console.log("Open device");
      this.store.setDeviceSn(this.device.device_sn); 
      this.store.setChildDeviceSn(this.device.child_device_sn);
      this.store.setDevice(this.device);
      localStorage.setItem("device", this.device);
      localStorage.setItem("device_sn", this.device.device_sn);
      localStorage.setItem("child_device_sn", this.device.child_device_sn);
      router.push({
        path: "workspace",
      });
    },
  },
};
</script>
