<!-- eslint-disable no-unused-vars -->
<template>
    <pro-calendar class="container" :events="events" :config="calendarConfig"></pro-calendar>
</template>

<script>

import "vue-pro-calendar/style";
import { E_CustomEvents } from "vue-pro-calendar"

//import useStore from "@/store";
//import { storeToRefs } from "pinia";
//import router from "@/router";
export default {
    name: "CalendarView",
    components: {

    },
    setup() {

    },
    async mounted() {
        this.addEvent(1, "E1", Date.now(), "k1", "c1", Date.now(), Date.now())
        document.body.addEventListener(E_CustomEvents.VIEW, (event) => {
            console.log({ event });
        });
        document.body.addEventListener(E_CustomEvents.REPORT, (event) => {
            console.log({ event });
        });
        
    },
    data: () => ({
        events: [],
        calendarConfig: {
            viewEvent: undefined,
            reportEvent: {
                icon: "mdi-home",
                text: "example",
            },
            searchPlaceholder: "",
            eventName: "",
            closeText: "",
            nativeDatepicker: true,
            todayButton: true,
            firstDayOfWeek: 1,
        }
    }),
    methods: {
        viewEventAction(e) {
            console.log(e)
        },
        addEvent(id, name, date, keywords, comment, createdAt, updatedAt) {
            this.events.push({
                id: id,
                name: name,
                date: date,
                keywords: keywords,
                comment: comment,
                createdAt: createdAt,
                updatedAt: updatedAt
            });
        }

    },
    computed: {

    },
    watch: {

    },
    created() {

    },
};
</script>

<style scoped>
.container {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 50px;
}
</style>