


class Alarm {
    ended = true;

    constructor(soundFile) {
        this.audio = new Audio(soundFile);
        this.audio.addEventListener('ended', this.onEnd.bind(this, this));
    }

    play() {
        if (this.ended) {
            try {
                this.audio.play();
                this.ended = false;
            } catch {
                console.log("Error playing sound");
                
            }
        }
    }
    stop() {
        this.audio.pause();
        this.audio.currentTime = 0;
        this.ended = true;
    }
    onEnd() {
        this.ended = true;
    }
}

export const alarmDetecion = new Alarm("https://gitlab.mindlabs.cl/public-data/fronticons/-/raw/main/alarm_detection.mp3?inline=false");
export const alarmPause = new Alarm("https://gitlab.mindlabs.cl/public-data/fronticons/-/raw/main/alarm_pause.mp3?inline=false");
export const alarmGeneral = new Alarm("https://gitlab.mindlabs.cl/public-data/fronticons/-/raw/main/alarm_detection.mp3?inline=false");
export const alarmStart = new Alarm("https://gitlab.mindlabs.cl/public-data/fronticons/-/raw/main/alarm_detection.mp3?inline=false");
