/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */

import { EDockModeCode } from '@/types/device.ts';
import useStore from "@/store";
import { storeToRefs } from "pinia";


export const getDockTelemetry = (data) => {
    return [
        //networkQualityHandler(data, false, true, false),
        networkSpeedHandler(data, true, true, false),
        modeCodeHandler(data, true, true, false),
        dockInDockHandler(data, true, true, false),
        droneChargeStateHandler(data, true, true, false),
        temperatureHandler(data, true, true, false),
        environmentTemperatureHandler(data, true, true, false),
        windSpeedHandler(data, true, true, false),
        rainfallHandler(data, false, true, false),
        /* humidityHandler(data, true, true, false),
        positionStateCalibrationHandler(data, true, true, true),
        positionStateFixedHandler(data, true, true, true),
        positionStateQualityHandler(data, true, true, true),
        positionStateGpsHandler(data, true, true, true),
        positionStateRTKHandler(data, true, true, true), */

    ]
}


const networkQualityHandler = (data, show_value, show_icon, show_label) => {
    let value = data["network_state"]["quality"];
    let icon = null;
    let label = "Calidad de red";
    if (value == 0) {
        value = "BAD"
        icon = "mdi-network-strength-1"
    } else if (value == 1) {
        value = "MEDIUM"
        icon = "mdi-network-strength-3"
    } else {
        value = "GOOD"
        icon = "mdi-network-strength-5"
    }   
    return { value, icon, label, show_value, show_icon, show_label };
}

const networkSpeedHandler = (data, show_value, show_icon, show_label) => {
    let value = data["network_state"]["rate"];
    value = Math.floor(value) + "Kbps";
    let icon = "mdi-speedometer";
    let label = "Velocidad";
    return { value, icon, label, show_value, show_icon, show_label };
}

const droneChargeStateHandler = (data, show_value, show_icon, show_label) => {
    
    const { droneConnected } = storeToRefs(useStore());
    if (data["drone_in_dock"] !== 1) {
        return
    } else if (droneConnected.value ) {
        return
    }
    let charging = data["drone_charge_state"]["state"];
    let value = data["drone_charge_state"]["capacity_percent"];
    let iconvalue = Math.floor(value/20)*20;
    let label = "Batería Drone";
    value = value + "%";
    if (iconvalue == 0 && charging == 0) {
        let icon = "mdi-battery-alert-variant-outline";
        return { value, icon, label, show_value, show_icon, show_label };
    } else if (iconvalue == 0 && charging == 1) {
        let icon = "mdi-battery-charging-outline";
        return { value, icon, label, show_value, show_icon, show_label };
    } else if (iconvalue == 100) {
        let icon = "mdi-battery-arrow-up";
        return { value, icon, label, show_value, show_icon, show_label };
    } else {
        if (charging == 1) {
            let icon = "mdi-battery-charging-" + iconvalue;
            return { value, icon, label, show_value, show_icon, show_label };
        } else {
            let icon = "mdi-battery-" + iconvalue;
            return { value, icon, label, show_value, show_icon, show_label };
        }
    }
}

const dockInDockHandler = (data, show_value, show_icon, show_label) => {
    let value = data["drone_in_dock"];
    let label = "Drone en base";
    if (value == 1) {
        let icon = "mdi-home";
        value = "Dron en base";
        return { value, icon, label, show_value, show_icon, show_label };
    } else {
        let icon = "mdi-home-export-outline";
        value = "Dron fuera de base";
        return { value, icon, label, show_value, show_icon, show_label };
    }

}



/*
  NONE = 0,
  LIGHT_RAIN = 1,
  MODERATE_RAIN = 2,
  HEAVY_RAIN = 3,
*/
const rainfallHandler = (data, show_value, show_icon, show_label) => {
    let value = data["rainfall"];
    let label = "Lluvia";
    if (value == 0) {
        value = "Sin lluvia";
        let icon = "mdi-white-balance-sunny"
        return { value, icon, label, show_value, show_icon, show_label };
    } else if (value == 1) {
        value = "Lluvia ligera";
        let icon = "mdi-weather-rainy"
        return { value, icon, label, show_value, show_icon, show_label };
    } else if (value == 2) {
        value = "Lluvia moderada";
        let icon = "mdi-weather-pouring"
        return { value, icon, label, show_value, show_icon, show_label };
    } else {
        value = "Lluvia fuerte";
        let icon = "mdi-weather-lightning"
        return { value, icon, label, show_value, show_icon, show_label };
    }
}

const windSpeedHandler = (data, show_value, show_icon, show_label) => {
    let value = data["wind_speed"];
    value = value + "m/s";
    let icon = "mdi-weather-windy";
    let label = "Velocidad del viento";
    return { value, icon, label, show_value, show_icon, show_label };
}

const environmentTemperatureHandler = (data, show_value, show_icon, show_label) => {
    let value = data["environment_temperature"];
    value = value + "°C";
    let icon = "mdi-thermometer";
    let label = "Temperatura Ambiente";
    return { value, icon, label, show_value, show_icon, show_label };
}

const positionStateCalibrationHandler = (data, show_value, show_icon, show_label) => {
    let value = data["position_state"]["is_calibration"];
    value = value == 1 ? "Calibrada" : "No calibrada";
    let icon = "mdi-compass";
    let label = "Posición calibrada";
    return { value, icon, label, show_value, show_icon, show_label };
}

const positionStateFixedHandler = (data, show_value, show_icon, show_label) => {
    let value = data["position_state"]["is_fixed"];
    let label = "RTK";
    let icon = "mdi-crosshairs-gps";
    if (value == 0) {
        value = "No iniciada";
        icon = "mdi-crosshairs-question";
    } else if (value == 1) {
        value = "Fixing";
        icon = "mdi-crosshairs";
    } else if (value == 2) {
        value = "Fixed";
        icon = "mdi-crosshairs-gps";
    } else {
        value = "Error";
        icon = "mdi-crosshairs-off";
    }
    return { value, icon, label, show_value, show_icon, show_label };
}

const positionStateQualityHandler = (data, show_value, show_icon, show_label) => {
    let value = data["position_state"]["quality"];
    value = "Gear " + value;
    let icon = "mdi-magnify";
    let label = "SAM"
    return { value, icon, label, show_value, show_icon, show_label };
}

const positionStateGpsHandler = (data, show_value, show_icon, show_label) => {
    let value = data["position_state"]["gps_number"];
    let icon = "mdi-satellite-uplink";
    let label = "N°GPS"
    return { value, icon, label, show_value, show_icon, show_label };
}

const positionStateRTKHandler = (data, show_value, show_icon, show_label) => {
    let value = data["position_state"]["rtk_number"];
    let icon = "mdi-satellite-uplink";
    let label = "N°RTK"
    return { value, icon, label, show_value, show_icon, show_label };
}



const modeCodeHandler = (data, show_value, show_icon, show_label) => {
    let value = data["mode_code"];
    value = EDockModeCode[value];
    let icon = "mdi-home";
    let label = "Dock Mode"
    return { value, icon, label, show_value, show_icon, show_label };
}

const coverStateHandler = (data, show_value, show_icon, show_label) => {
    let value = data["coverState"];
    let icon = "cover";
    let label = "Estado de la cubierta"
    return { value, icon, label, show_value, show_icon, show_label };
}

const humidityHandler = (data, show_value, show_icon, show_label) => {
    let value = data["humidity"];
    value = value  +"%"
    let icon = "mdi-water-percent";
    let label = "Humedad"
    return { value, icon, label, show_value, show_icon, show_label };
}

const temperatureHandler = (data, show_value, show_icon, show_label) => {
    let value = data["temperature"];
    value = value + "°C";
    let icon = "mdi-home-thermometer-outline";
    let label = "Temperatura"
    return { value, icon, label, show_value, show_icon, show_label };
}


