<template>
    <v-card class="mx-auto" max-width="300">
        <!-- this.uiStore.toggleWorkspaceSidebar(); -->
        
        <v-list>
            <v-card>
                <v-sheet>
                    <v-card-item>
                        <v-card-title>Opciones</v-card-title>
                        <template v-slot:append>
                            <v-btn icon="mdi-menu-close" size="small" variant="text" @click="closeMenu"></v-btn>
                        </template>
                    </v-card-item>
                    <v-card-item v-if="activeSound !== null && activeSound !== undefined">
                            <v-switch color="primary" label="Pausa Automatica" v-model="activeAutoPause"></v-switch>
                            <v-switch color="primary" label="Sonido" v-model="activeSound"></v-switch>
                    </v-card-item>
                </v-sheet>
            </v-card>
        </v-list>
       
            <v-card v-if="this.showHMS == false">
                <v-sheet>
                    <v-card-item >
                        <v-card-title class="text-lime-400">Sin Alertas Dock</v-card-title>
                    </v-card-item>
                </v-sheet>
            </v-card>
            <v-card v-else color="success">
                <v-sheet>
                    <v-card-item >
                        <v-card-title >Alertas Dock</v-card-title>
                    </v-card-item>
                    <v-card-item v-for="(item, index) in hms.data" :key="item.id">
                            <TSAFormatHms :item="item" :index="index"></TSAFormatHms>
                    </v-card-item>
                </v-sheet>
            </v-card>
        
        <!--
        <v-list>
            <v-card>
                <v-sheet>
                    <v-card-item>
                        <v-card-title>Acciones</v-card-title>
                        <template v-slot:append>
                            <v-btn icon="$close" size="small" variant="text" @click="removeActions"></v-btn>
                        </template>
                    </v-card-item>

                </v-sheet>
            </v-card>



            <v-list-item-group>
                <v-list-item v-for="(service, index) in services" :key="service.id">
                    <TSAFormatItem :item="service" :index="index"></TSAFormatItem>
                </v-list-item>
            </v-list-item-group>
        </v-list>

        -->


    </v-card>
</template>

<script>
//import TSAFormatItem from './TSAFormatItem.vue';
import TSAFormatHms from './TSAFormatHms.vue';
import useUiStore from '@/ui'
import uiStore2 from '@/store/ui'
import { getDeviceSettings, setDeviceSettingKey } from '@/services/backendService.js'
import { storeToRefs } from 'pinia';
export default {
    name: "TSAMenuBar",
    components: {
        //TSAFormatItem,
        TSAFormatHms
    },
    setup() {
        const uiStore = useUiStore();
        const { hms, services, notifications } = storeToRefs(uiStore2());
        return {
            uiStore,
            hms,
            services,
            notifications
        };
    },
    data() {
        return {
            activeAutoPause: null,
            activeSound: null,
            text: "",
            tid: null,
        }
    },
    computed: {
        showHMS() {
            if (Object.prototype.hasOwnProperty.call(this.hms, "data")) {
                return this.hms.data.length > 0;
            } else {
                return false
            }
        }
    },
    methods: {
        async getAutoPause() {
            try {
                const data = await getDeviceSettings();
                this.activeAutoPause = data["auto_stop"] === true;
            }
            catch {
                console.log("error getting auto pause status")
            }
        },
        async setAutoPause(value) {
            try {
                await setDeviceSettingKey("auto_stop", value);
                await this.getAutoPause();
            }
            catch {
                console.log("error")
            }
        },
        getSound() {
            if (localStorage.getItem('sound') === undefined || localStorage.getItem('sound') === null) {
                localStorage.setItem('sound', true)
            }
            const value = localStorage.getItem('sound')
            this.activeSound = value === 'true' || value === true
        },
        setInt() {
            self.tid = setInterval(async () => {
                await this.getAutoPause();
            }, 5000);
        },
        removeActions() {
            this.services = [];
        },
        closeMenu() {
            this.uiStore.toggleWorkspaceSidebar();
        }
        //
    },
    watch: {
        activeSound(value) {
            localStorage.setItem('sound', value)
        },
        activeAutoPause(value, oldValue) {
            if (value !== oldValue && oldValue !== null && oldValue !== undefined) {
                this.setAutoPause(value);
            }
        }

    },

    async mounted() {
        await this.getAutoPause();
        this.getSound();
        this.setInt();
    },
    beforeUnmount() {
        clearInterval(this.tid);
    }
};
</script>

<style scoped></style>