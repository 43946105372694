const getEnvConf = () => {
  /*
  return {
    "baseURL": "https://backend.cvis.videri.ai/",
    "websocketURL": "wss://backend.cvis.videri.ai/api/v1/ws",
    "rtmpURL": "rtmp://media.cvis.videria.ai/",
    "videriVideoRtmp": "rtmp://media.cvis.videri.ai/",
    "videriVideoViewer": "https://media.cvis.videri.ai/",
    "videriDRCMqtt": "mqtts://mqtt.media.cvis.videri.ai:443",
    "videriMqtt": "mqtts://mqtt.cvis.videri.ai:443",
  }
  */
  return {
      "baseURL": "https://backend.alfa.cvis.videri.ai/",
      "websocketURL": "wss://backend.alfa.cvis.videri.ai/api/v1/ws",
      "rtmpURL": "rtmp://mediamtx1.cvis.videri.ai/",
      "videriVideoRtmp": "rtmp://mediamtx1.cvis.videri.ai/",
      "videriVideoViewer": "https://mediamtx1.cvis.videri.ai:8889/",
      "videriDRCMqtt": "mqtts://mediamtx1.cvis.videri.ai:443",
      "videriMqtt": "mqtts://mqtt.alfa.cvis.videri.ai:443",
  }
}


const envConf = getEnvConf()

export const CURRENT_CONFIG = {

  appId: '140106', // You need to go to the development website to apply.
  appKey: 'ccda8887a5588bbc7a1e4fced593958', // You need to go to the development website to apply.
  appLicense: 'w66hlKhu3XEzrONhg0gSabC6GFVKxI6QVcVYGVfdZftXQCZw/LWXgTyprFAABG8L3wPQXGIfqNFm/2OY3jpwNQTuvOYvn/ZxtKs7B2wSSTjRTRd2YyRntKi2GdJJsuWmEHpCvSR4+08PQLYR6cCk1LBdnehjuZ8BlRZ7bv6om+g=', // You need to go to the development website to apply.
  //baseURL: 'https://backend.alfa.cvis.videri.ai',
  //baseURL: 'https://backend.testcloud.mindlabs.cl',
  baseURL: envConf.baseURL,
  //websocketURL: 'wss://backend.testcloud.mindlabs.cl/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'
  //websocketURL: 'wss://backend.alfa.cvis.videri.ai/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'
  websocketURL: envConf.websocketURL,
  //rtmpURL: 'rtmp://mediamtx1.cvis.videri.ai',
  //rtmpURL: 'rtmp://mediamtx.testcloud3.mindlabs.cl/',
  rtmpURL: envConf.rtmpURL,
  // GB28181 Note:If you don't know what these parameters mean, you can go to Pilot2 and select the GB28181 page in the cloud platform. Where the parameters same as these parameters.
  gbServerIp: 'Please enter the server ip.',
  gbServerPort: 'Please enter the server port.',
  gbServerId: 'Please enter the server id.',
  gbAgentId: 'Please enter the agent id',
  gbPassword: 'Please enter the agent password',
  gbAgentPort: 'Please enter the local port.',
  gbAgentChannel: 'Please enter the channel.',
  // RTSP
  rtspUserName: 'Please enter the username.',
  rtspPassword: 'Please enter the password.',
  rtspPort: '8554',
  // Agora
  agoraAPPID: 'Please enter the agora app id.',
  agoraToken: 'Please enter the agora temporary token.',
  agoraChannel: 'Please enter the agora channel.',

  // map
  // You can apply on the AMap website.
  amapKey: 'Please enter the amap key.',
  //srsViewer: 'https://srs.testcloud.mindlabs.cl/viewer/',
  //videriVideoRtmp: 'rtmp://mediamtx.testcloud3.mindlabs.cl/',
  //videriVideoRtmp: 'rtmp://mediamtx1.cvis.videri.ai/',
  videriVideoRtmp: envConf.videriVideoRtmp,
  //videriVideoViewer: 'https://mediamtx.testcloud3.mindlabs.cl:8889/',
  //videriVideoViewer: 'https://mediamtx1.cvis.videri.ai:8889/',
  videriVideoViewer: envConf.videriVideoViewer,
  //videriDRCMqtt: 'mqtts://mediamtx.testcloud3.mindlabs.cl:443',
  //videriDRCMqtt: 'mqtts://mediamtx1.cvis.videri.ai:443',
  videriDRCMqtt: envConf.videriDRCMqtt,
  videriMqtt: envConf.videriMqtt,
}
