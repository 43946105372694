import { defineStore } from 'pinia'

export default defineStore({
  id: 'telemetry',
  state: () => ({
    devices: null
  }),
  actions: {
    setDeviceTelemetry(
      device,
      timestamp,
      variable,
      value
    ) {
      if (!this.devices) {
        this.devices = {}
      }
      if (!this.devices[device] || this.devices[device] === undefined || null) {
        this.devices[device] = {}
      }
      this.devices[device][variable] = {
        timestamp: timestamp,
        value: value
      }
    },
    getDeviceTelemetry(device, variable) {
      if (this.devices && this.devices[device] && this.devices[device][variable]) {
        return this.devices[device][variable]
      }
      return null
    },
    getDeviceAllTelemetry(device) {
      if (this.devices && this.devices[device]) {
        return this.devices[device]
      }
      return null
    },
    getDeviceTelemetryVariables(device) {
      if (this.devices && this.devices[device]) {
        return Object.keys(this.devices[device])
      }
      return null
    },
    getDevices() {
      if (this.devices) {
        return Object.keys(this.devices)
      }
      return []
    },
    validateDevice(device) {
      if (this.devices && this.devices[device]) {
        return true
      }
      return false
    }
  }
})
