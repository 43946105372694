<template>
  <v-app class="flex justify-center items-center w-full mx-0 px-0">
      <v-navigation-drawer :permanent="true" :width="55">
        <SideView />
      </v-navigation-drawer>
        <router-view  />
  </v-app>
</template>

<script>
import SideView from "@/views/Workspace/components/SideView.vue";
import daemon from "@/views/Workspace/services/daemon.js";
import router from "@/router";
import store from "@/store";
import mqtt from "@/mqtt";
export default {
  name: "App",
  components: {
    SideView,
  },
  data: () => ({}),
  setup() {
    const device_sn = localStorage.getItem("device_sn");
    if (device_sn === null || device_sn === undefined) {
      router.push({ path: "devices" });
    } else {
      const useStore = store();
      useStore.setDeviceSn(device_sn);
      const child_device_sn = localStorage.getItem("child_device_sn");
      useStore.setChildDeviceSn(child_device_sn); 
      const device = localStorage.getItem("device");
      useStore.setDevice(device);
    }
    return {
      //
    };
  },
  mounted() {
    mqtt.connect();
    daemon.start();
  },
  beforeUnmount() {
    mqtt.disconnect();
    daemon.stop();
  },
};
</script>

<style scoped>
.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
html {
  overflow: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

html::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
