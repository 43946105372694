<template>
    <v-sheet class="mx-auto pt-4" width="300">
        <v-form @submit.prevent>
            <!-- <v-text-field v-model="goToPointStrategy" label="Status Go To Point" disabled></v-text-field> -->
            <v-text-field v-model="coordinates" label="Coordenadas" disabled></v-text-field>
            <v-text-field type="number" v-model.number="altitude" :rules="rules" label="Altura"></v-text-field>
            <v-text-field v-if="goToPointStrategy == 1" type="number" v-model.number="altitudeRTH" :rules="rulesRTH" label="Altura de RTH"></v-text-field>
            <v-text-field v-if="goToPointStrategy == 1" type="number" v-model.number="altitudeSecureTakeoff" :rules="rulesSecureTakeOff" label="Altura de despegue"></v-text-field>
            
            <!-- <v-text-field type="number" v-model.number="altitudeSecureTakeoff" :rules="rules" label="Altura segura de despegue"></v-text-field> -->
            <!-- <v-text-field v-model="requestAltitude" label="Request Altitude" disabled></v-text-field> -->
            <v-btn class="mt-2" @click="goToPoint" type="submit" block>Ir al punto</v-btn>
        </v-form>
    </v-sheet>

</template>

<script setup>
/* eslint-disable */
import { defineProps, computed, ref } from 'vue'
const props = defineProps({
    event: Object,
})
import { storeToRefs } from "pinia";

import useStore from "@/store";
const store = useStore();
const droneSn = store.child_device_sn;
const dockSn = store.device_sn;
import { getDroneTelemetry } from "../../utils/droneTelemetryUtils.js";
import { getDockTelemetry } from "../../utils/dockTelemetryUtils.js";

const { telemetry, droneConnected } = storeToRefs(store);

const telemetryData = computed(() => {
    return telemetry.value
})


const altitude = ref(150)
const altitudeRTH = ref(150)
const altitudeSecureTakeoff = ref(150)

const rulesRTH = [
    value => {
        if (value) return true
        return "Ingrese un valor valido"
    },
    value => {
        const number = parseFloat(value);
        return (number >= 100 && number <= 300) || 'El valor debe estar entre 100 y 300';
    }
]

const rulesSecureTakeOff = [
    value => {
        if (value) return true
        return "Ingrese un valor valido"
    },
    value => {
        const number = parseFloat(value);
        return (number >= 100 && number <= 300) || 'El valor debe estar entre 100 y 300';
    }
]

//const altitudeSecureTakeoff = ref(100)
const rules = [
    value => {
        if (value) return true
        return "Ingrese un valor valido"
    },
    value => {
        const number = parseFloat(value);
        return (number >= 50 && number <= 300) || 'El valor debe estar entre 50 y 300';
    }
]

const coordinates = computed(() => {
    return Number(props.event.latLng.lat()).toFixed(6) + ", " + Number(props.event.latLng.lng()).toFixed(6);
})

const exist = (data) => {
    return data !== undefined && data !== null;
};


const dockStatus = computed(() => {
    try {
        return telemetry.value[dockSn]["osd_telemetry"]["data"]["mode_code"]
    } catch {
        return -1
    }
});

const dockASL = computed(() => {
    try {
        return Math.floor(telemetry.value[dockSn]["osd_telemetry"]["data"]["height"])
    } catch {
        return -1
    }
})

const droneStatus = computed(() => {
    try {
        return telemetry.value[droneSn]["osd_telemetry"]["data"]["mode_code"]
    } catch {
        return -1
    }
});

const requestAltitude = computed(() => {
    return dockASL.value + altitude.value
})

const getTakeoffToPointBody = () => {
    return {
        target_height: requestAltitude.value,
        target_latitude: Number(props.event.latLng.lat()).toFixed(12),
        target_longitude: Number(props.event.latLng.lng()).toFixed(12),
        security_takeoff_height: altitudeSecureTakeoff.value,
        max_speed: 15,
        rc_lost_action: 2,
        rth_altitude: altitudeRTH.value,
        exit_wayline_when_rc_lost: 1,
        commander_mode_lost_action: 1,
        commander_flight_mode: 1,
        commander_flight_height: 150, // Parametro de seguridad de 150m
        rth_mode: 1,
    }
}

const getFlyToPoint = () => {
    return {
        max_speed: 15,
        points: [{
            latitude: Number(props.event.latLng.lat()).toFixed(12),
            longitude: Number(props.event.latLng.lng()).toFixed(12),
            height: requestAltitude.value,
        }],
    }
}

// eslint-disable-next-line
const goToPointStrategy = computed(() => {
    if (dockStatus.value == 0) {
        return 1
    } else if (dockStatus.value == 4 && droneStatus.value == 3) {
        return 2
    } else {
        return -1
    }
})


import { postTakeoffToPoint, postFlyToPoint } from '../../../../api/drone-control/drone.ts';

const goToPoint = async () => {
    if (dockStatus.value == 0) {
        const result = await postTakeoffToPoint(dockSn, getTakeoffToPointBody())
        console.log(result)
    } else if (dockStatus.value == 4 && droneStatus.value == 3) {
        const result = await postFlyToPoint(dockSn, getFlyToPoint())
        console.log(result)
    } else {
        console.log("Can't go to point")
    }
}

</script>

<style></style>
