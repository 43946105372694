import { dockDebugOnOff, sendDockControlCmd } from '@/api/interface/use-dock-control.ts';
import { DeviceCmd, cmdList } from '@/types/device-cmd.ts';

export const dockDebugOnService = async (sn) => {
    const result = await dockDebugOnOff(sn, true);
    console.log(result)
    return result;
}

export const dockDebugOffService = async(sn) => {
    const result = await dockDebugOnOff(sn, false);
    console.log(result)
    return result;
}

export const dockDroneOnService = async(sn) => {
    const command = DeviceCmd.DroneOpen;
    const cmdItem = findCmd(command);
    console.log(cmdItem)
    const result = await sendControlCommand(sn, command, cmdItem.action);
    console.log("Drone On")
    console.log(result)
    return result;
}

export const dockDroneOffService = async(sn) => {
    const command = DeviceCmd.DroneClose;
    const cmdItem = findCmd(command);
    console.log(cmdItem)
    const result = await sendControlCommand(sn, command, cmdItem.action);
    console.log("Drone Off")
    console.log(result)
    return result;
}

export const dockCoverOpenService = async(sn) => {
    const command = DeviceCmd.CoverOpen;
    const cmdItem = findCmd(command);
    console.log(cmdItem)
    const result = await sendControlCommand(sn, command, cmdItem.action);
    console.log("Cover Open")
    console.log(result)
    return result;
}
 
export const dockCoverCloseService = async(sn) => {
    const command = DeviceCmd.CoverClose;
    const cmdItem = findCmd(command);
    console.log(cmdItem)
    const result = await sendControlCommand(sn, command, cmdItem.action);
    console.log("Cover Close")
    console.log(result)
    return result;
}

const findCmd = (command) => {
    for (let i = 0; i < cmdList.length; i++) {
        if (cmdList[i].cmdKey === command || cmdList[i].oppositeCmdKey === command) {
            return cmdList[i];
        }
    }
    return undefined;
}

const sendControlCommand = async (sn, command, action) => {
    const result = await sendDockControlCmd({
        sn: sn,
        cmd: command,
        action: action
    });
    return result;
}

export const rthService = async(sn) => {
    console.log(sn)
    const command = DeviceCmd.ReturnHome;
    console.log(command)
    const result = await sendControlCommand(sn, command, 0);
    console.log("RTH Request")
    console.log(result)
    return result;
}