import { defineStore } from "pinia";
export default defineStore({
  id: "ui",
  state: () => ({
    workspaceSidebar: false,
    mapViewWayline: false,
    scheduleBox: {},
    floatVisibility: {},
    showVideoPanel: false,
    showGimbal: false,
    showDroneControl: false,

  }),

  getters: {},

  actions: {
    toggleWorkspaceSidebar() {
      this.workspaceSidebar = !this.workspaceSidebar;
    },
    toggleMapViewWayline() {
      this.mapViewWayline = !this.mapViewWayline;
    },    
    toggleScheduleBox(idx) {
      try {
        this.scheduleBox[idx] = !this.scheduleBox[idx];
      } catch {
        this.scheduleBox[idx] = false;
      }
    },
    toggleFloatVisibility(idx) {
      try {
        this.floatVisibility[idx] = !this.floatVisibility[idx];
      } catch {
        this.floatVisibility[idx] = false;
      }
    },
    toggleShowGimbal() {
      this.showGimbal = !this.showGimbal;
    },
  },
});
