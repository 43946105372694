<template>

    <v-btn-toggle v-model="text" color="#FFFFFFFF" mandatory rounded="0">
        <v-btn value="wide" @click="setLent">
            Wide
        </v-btn>

        <v-btn value="zoom" @click="setLent">
            Zoom
        </v-btn>

        <v-btn value="ir" @click="setLent">
            IR
        </v-btn>
    </v-btn-toggle>


</template>

<script>
import { setLentService } from "@/services/payloadService.js";
import useStore from "@/store";
import { storeToRefs } from "pinia";
import { notify } from "notiwind";

export default {
    name: 'ControlButtons',
    props: {
        videoId: String,
    },
    setup() {
        const { lentSelection } = storeToRefs(useStore());

        return {
            lentSelection,
        }
    },
    data() {
        return {
            size: 50,
            snackbar: false,
            text: "",
            btnSize: "large",
            zoomFactor: 2,
        }
    },
    computed: {

    },
    methods: {
        async setLent() {
            console.log(this.text);
            const result = await setLentService(this.videoId, this.text);
            this.lentSelection = this.text;
            if (result["code"] != 0) {
                notify(
                    {
                        group: 'workspace',
                        level: 'warn',
                        title: 'Selección de lente. ',
                        text: "Error al seleccionar lente. Valide que el video este en vivo y vuelva a intentarlo.",
                    },
                    5000 // 2.5s
                )
            } else {
                notify(
                    {
                        group: 'workspace',
                        level: 'success',
                        title: 'Selección de lente. ',
                        text: "Lente " + this.text + " seleccionado correctamente.",
                    },
                    5000 // 2.5s
                )
            }
        }

    }
}

</script>

<style scoped>
.tcontainer {}

.button-style {
    display: flexbox;
    background-color: #6161611a;
    border-radius: 0%;
    border-color: antiquewhite;
    border-width: 2px;
    color: white;
}

.container-center {
    display: flexbox;
    height: fit-content;
    background-color: #ffffff0a;
}

.widget-tittle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff2d;
}

.button-container {
    display: flexbox;
    background-color: #6161611a;
    border-radius: 0%;
    border-color: antiquewhite;
    border-width: 2px;
    color: white;
}
</style>