<template>
  <div class="justify-center items-center w-full mx-0 px-0" ref="googleMap">
    <tsa-map></tsa-map>
  </div>
</template>

<script>
/* eslint-disable */

import { loadWayline } from "@/views/Workspace/utils/waylineUtils.js";
import TSAMapDialog from "@/views/Workspace/components/tsa/TSAMapDialog.vue";
import { Loader } from "@googlemaps/js-api-loader";
import { createApp, defineComponent } from "vue";
import { storeToRefs } from "pinia";
import useStore from "@/store";
//import dockImage from "@/assets/map/dock.png";
//import droneImage from "@/assets/map/drone.png";
//import { loadWayline } from "@/views/Workspace/utils/waylineUtils.js";
const content = document.createElement("div");
import useUi2Store from "@/store/ui";



import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
  theme: {
    defaultTheme: "dark",
  },
  icons: {
    defaultSet: "mdi",
  },
  components,
  directives,
});







export default defineComponent({
  components: {
  },
  props: {
    // mapConfig: Object,
    // apiKey: String,
  },
  setup() {
    const store = useStore();
    const { telemetry } = storeToRefs(store);
    const droneSn = store.child_device_sn;
    const dockSn = store.device_sn;
    const ui2Store = useUi2Store();
    const { showFlyToPoint, flyToPointActive } = storeToRefs(ui2Store)
    const storeRefs = storeToRefs(useStore());
    const {
      droneConnected,
      gatewayConnected,
      droneCoordinates,
      gatewayCoordinates,
      selectedWayline,
    } = storeRefs;
    return {
      store,
      droneConnected,
      gatewayConnected,
      droneCoordinates,
      gatewayCoordinates,
      selectedWayline,
      telemetry,
      droneSn,
      dockSn,
      showFlyToPoint,
      flyToPointActive
    };
  },
  computed: {
    droneHeading() {
      try {
        const heading =
          this.store.telemetry[this.store.child_device_sn]["osd_telemetry"][
            "data"
          ]["attitude_head"];
        return heading;
      } catch (e) {
        return 0;
      }
    },

    dockStatus() {
      try {
        return this.telemetry.value[dockSn]["osd_telemetry"]["data"]["mode_code"]
      } catch {
        return -1
      }
    },

    droneStatus() {
      try {
        return this.telemetry.value[droneSn]["osd_telemetry"]["data"]["mode_code"]
      } catch {
        return -1
      }
    },
    flyValidation() {
      if (this.dockStatus.value == 0) {
        return true
      } else if (this.dockStatus.value == 4 && this.droneStatus.value == 3) {
        return true
      } else {
        return false
      }
    },



  },
  watch: {
    waylinePoints(newValue) {
      if (this.waylineLine === null) {
        this.waylineLine = new this.google.maps.Polyline({
          path: newValue,
          geodesic: true,
          strokeColor: "#FFFFFF",
          strokeOpacity: 1.0,
          strokeWeight: 2,
          map: this.map,
        });
      } else {
        this.waylineLine.setPath(newValue);
      }
    },
    droneHeading(newValue) {
      this.droneIcon.rotation = newValue;
      this.store.droneMarker.setIcon(this.droneIcon);
    },
    gatewayCoordinates(newValue, oldValue) {
      if (this.map !== null) {
        if (oldValue.lat == 0 && oldValue.lng == 0) {
          this.map.setCenter(newValue);
          this.map.setZoom(16);
        }
      }
    },
    droneCoordinates(newValue, oldValue) {
      if (this.map !== null) {
        if (oldValue.lat == 0 && oldValue.lng == 0) {
          this.map.setCenter(newValue);
          this.map.setZoom(16);
        }
        this.store.droneMarker.setPosition(newValue);
      }
    },
    gatewayConnected(newValue) {
      if (this.map !== null) {
        this.store.gatewayMarker.setVisible(newValue == 1 ? true : false);
        this.store.gatewayMarker.setMap(newValue == 1 ? this.map : null);
      }
    },
    droneConnected(newValue) {
      if (this.map !== null) {
        this.store.droneMarker.setVisible(newValue == 1 ? true : false);
        this.store.droneMarker.setMap(newValue == 1 ? this.map : null);
      }
    },
    async selectedWayline(newValue) {
      this.removeWayline();
      this.waylinePoints = [];
      if (newValue !== null) {
        const points = await loadWayline(newValue);
        this.waylinePoints = points;
        this.initWayline(points);
        this.showWayline();
        const center = this.getWaylineCenter(points);
        this.map.setCenter(center);
        this.map.setZoom(16);
      }
    },
  },
  data() {
    return {
      google: null,
      map: null,
      waylineMarkers: [],
      waylineLine: null,
      waylinePoints: [],
      clickMarker: null,
    };
  },
  async mounted() {

    const tid = setInterval(() => {
      if (this.flyValidation.value == false) {
        this.showFlyToPoint = 1
      } else if (this.flyToPointActive == true) {
        this.showFlyToPoint = 2
      } else {
        this.showFlyToPoint = 3
      }
    }, 1000
    )


    this.droneConnected = false;
    this.gatewayConnected = false;
    this.droneCoordinates = { lat: 0, lng: 0 };
    this.gatewayCoordinates = { lat: 0, lng: 0 };
    const googleMapApi = new Loader({
      apiKey: "AIzaSyAXs4mLoYuGGdKpUHLlYWesNThDgtTwb8A",
      version: "weekly",
      libraries: ["places"],
    });
    const mapOptions = {
      center: {
        lat: -33.4489,
        lng: -70.6693,
      },
      zoom: 10,
      streetViewControl: false,
      tilt: 0,
      rotateControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      zoomControl: false,
    };
    this.mapOptions = mapOptions;
    googleMapApi
      .load()
      .then((google) => {
        this.google = google;
        this.store.google = google;
        this.createMapIcons();
        this.initializeMap();
        this.map.addListener("rightclick", (e) => {
          console.log("Right click", e);
        });
      })
      .catch((e) => {
        console.error("Error loading Google Maps API", e);
      });
  },

  methods: {

    removeEventMarker() {
      this.clickMarker
    },
    initializeMap() {
      const mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(mapContainer, this.mapOptions);
      this.store.map = this.map;
      this.map.setMapTypeId("satellite");
      const gatewayMarker = new this.google.maps.Marker({
        position: this.gatewayCoordinates,
        map: this.gatewayConnected ? this.map : null,
        title: "Gateway",
        icon: this.gatewayIcon,
      });
      const droneMarker = new this.google.maps.Marker({
        position: this.droneCoordinates,
        map: this.droneConnected ? this.map : null,
        title: "Drone",
        icon: this.droneIcon,
      });
      this.store.gatewayMarker = gatewayMarker;
      this.store.droneMarker = droneMarker;
      
      this.map.addListener('click', (e) => {
        if (this.flyToPointActive == 1) {
          
        
        if (this.clickMarker === null) {
          var app = createApp(TSAMapDialog, { event: e })
          app.use(vuetify);
          app.mount(content)

          const infowindow = new this.google.maps.InfoWindow({
            content: content,
          })
          infowindow.addListener("closeclick", () => {
            this.clickMarker.setMap(null)
            this.clickMarker.setVisible(false);
            this.clickMarker = null
            app.unmount()
          })


          this.clickMarker = new this.google.maps.Marker({
            position: e.latLng,
            title: 'Event',
            map: this.map,
          })
          this.clickMarker.addListener("click", () => {
            infowindow.open({
              anchor: this.clickMarker,
              map: this.map
            })
          })

        }
      }
        })
    },
    createMapIcons() {
      /*
      this.gatewayIcon = {
        path: "M12 20C7.6 20 4 16.4 4 12S7.6 4 12 4 20 7.6 20 12 16.4 20 12 20M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M11 14H13V17H16V12H18L12 7L6 12H8V17H11V14",
        anchor: new this.google.maps.Point(12, 12),
        fillColor: "#2196F3",
        fillOpacity: 0.75,
        strokeWeight: 2,
        strokeColor: "#00000000",
        rotation: 0,
        scale: 4,
      };
      */
      this.gatewayIcon = {
        url: "https://gitlab.mindlabs.cl/public-data/fronticons/-/raw/main/dock.svg", 
        anchor: new this.google.maps.Point(36, 36),
        fillColor: "#2196F3",
        fillOpacity: 0.75,
        strokeWeight: 2,
        strokeColor: "#00000000",
        rotation: 0,
        scale: 1,
        scaledSize : new this.google.maps.Size(72, 72)
      };
      this.droneIcon = {
        path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW, //droneImage,
        anchor: new this.google.maps.Point(0, 2.5),
        fillColor: "#76FF03",
        fillOpacity: 0.75,
        strokeWeight: 2,
        strokeColor: "#00000000",
        rotation: 0,
        scale: 10,
      };
      this.waylineIcon = {
        path: this.google.maps.SymbolPath.CIRCLE, //droneImage,
        anchor: new this.google.maps.Point(0, 0),
        fillColor: "#FF0000",
        fillOpacity: 0.75,
        strokeWeight: 0,
        strokeColor: "#00000000",
        rotation: 0,
        scale: 10,
      };
    },
    initWayline(points) {
      this.points = points;
      let markers = [];
      let counter = 0;
      let waylineIcon = null;
      points.forEach((point) => {
        if (counter == 0) {
          waylineIcon = JSON.parse(JSON.stringify(this.waylineIcon));
          waylineIcon.fillColor = "#00FF00";
        } else if (counter == points.length - 1) {
          waylineIcon = JSON.parse(JSON.stringify(this.waylineIcon));
          waylineIcon.fillColor = "#FF0000";
        } else {
          waylineIcon = JSON.parse(JSON.stringify(this.waylineIcon));
          waylineIcon.fillColor = "#0000FF";
        }
        const marker = new this.google.maps.Marker({
          position: point,
          map: this.map,
          title: "W_" + counter++,
          icon: waylineIcon,
        });
        markers.push(marker);
      });
      this.waylineMarkers = markers;
    },
    showWayline() {
      this.waylineMarkers.forEach((marker) => {
        marker.setMap(this.map);
        marker.setVisible(true);
      });
    },
    hideWayline() {

      this.waylineMarkers.forEach((marker) => {
        marker.setMap(null);
        marker.setVisible(false);
      });
    },
    removeWayline() {
      this.waylineMarkers.forEach((marker) => {
        marker.setMap(null);
        marker.setVisible(false);
      });
      this.waylineMarkers = [];
    },
    getWaylineCenter(points) {
      let lat = 0;
      let lng = 0;
      points.forEach((point) => {
        lat += point.lat;
        lng += point.lng;
      });
      return { lat: lat / points.length, lng: lng / points.length };
    }
  },
});
</script>

<style scoped>
.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  z-index: 0;
}
</style>
