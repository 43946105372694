import { importKmzFile, getWaylineFiles, downloadWaylineFile, deleteWaylineFile } from "@/api/wayline.ts";
import { ELocalStorageKey } from "@/types/enums.ts";

export const uploadWaylineService = async (task) => {
  const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId);
  await importKmzFile(workspaceId, task)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getWaylinesService = async () => {
    const options = {
        "page": 1,
        "page_size": 1000,
        "order_by": "update_time desc",
    }
    const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId);
    return await getWaylineFiles(workspaceId, options)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const downloadWaylineService = async (id) => {
    const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId);
    return await downloadWaylineFile(workspaceId, id)
        .then((response) => {
            return response; 
        })
        .catch(() => {
            return null;
        });
}

export const deleteWaylineService = async (id) => {
    const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId);
    return await deleteWaylineFile(workspaceId, id)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}
