//import { message } from 'ant-design-vue'
import { ref } from 'vue'
import { postSendCmd } from '../device-cmd'
import { DeviceCmd, DeviceCmdItemAction } from '../../types/device-cmd'


export async function dockDebugOnOff(sn: string, on: boolean) {
  const result = await sendDockControlCmd({
    sn: sn,
    cmd: on ? DeviceCmd.DebugModeOpen : DeviceCmd.DebugModeClose
  }, false)
  return result
}


// 发送指令
export const sendDockControlCmd = async (params: {
  sn: string,
  cmd: DeviceCmd
  action?: DeviceCmdItemAction
}, tip = true) => {
  try {
    let body = undefined as any
    if (params.action !== undefined) {
      body = {
        action: params.action
      }
    }
    const { code } = await postSendCmd({ dock_sn: params.sn, device_cmd: params.cmd }, body)
    if (code === 0) {
      tip// && message.success('指令发送成功')
      return true
    }
    throw (new Error('Error'))
  } catch (e) {
    tip// && message.error('指令发送失败')
    return false
  }
}

