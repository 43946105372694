<!-- eslint-disable no-unused-vars -->
<template>
    <div class="flex w-full h-screen mx-14 justify-items-center justify-center items-center">
        <v-data-iterator :items="jobs" :page="page" :items-per-page="4">
            <template v-slot:default="{ items }">
                <template v-for="(item, i) in items" :key="i">
                    <TSACalendarNextItem :job="item" />
                    <br>
                </template>
            </template>
            <template v-slot:footer="{ page, pageCount, prevPage, nextPage }">
                <div class="flex justify-center pa-4">
                    <v-btn :disabled="page === 1" density="comfortable" icon="mdi-arrow-left" variant="tonal" rounded
                        @click="prevPage"></v-btn>

                    <div class="mx-2 text-caption">
                        Página {{ page }} de {{ pageCount }}
                    </div>

                    <v-btn :disabled="page >= pageCount" density="comfortable" icon="mdi-arrow-right" variant="tonal"
                        rounded @click="nextPage"></v-btn>
                </div>
            </template>
        </v-data-iterator>
    </div>
</template>

<script>

import { getJobsService } from "@/services/taskService.js";
import TSACalendarNextItem from "./components/tsa/TSACalendarNextItem.vue";
import { ref } from "vue";
//import useStore from "@/store";
//import { storeToRefs } from "pinia";
//import router from "@/router";
export default {
    name: "CalendarView",
    components: {
        TSACalendarNextItem
    },
    setup() {

    },
    async mounted() {
        setInterval(() => {
            this.getJobs();
        }, 3000);
        await this.getJobs();
    },
    data: () => ({
        jobs: [],
        page: ref(1),
    }),
    methods: {
        async getJobs() {
            let result = await getJobsService();
            result = result.sort((a, b) => new Date(a.begin_time) - new Date(b.begin_time));
            this.jobs = this.filterJobs(result);
        },
        filterJobs(data) {
            let result = [];
            data.forEach((job) => {
                if (job.status === 1) {
                    result.push(job);
                }
            });
            return result;
        }

    },
    computed: {

    },
    watch: {

    },
    created() {

    },
};
</script>

<style scoped>
.container {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 50px;
}
</style>