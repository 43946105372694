<template>
  <v-app-bar color="#000000A0" :elevation="0" extended extension-height="auto" density="compact" size="small">
    <template v-slot:prepend>
      <div v-if="!droneConnected">
        <v-chip size="x-small" class="chipIcon" @click="store.centerGatewayMap"><v-icon color="red-accent-4"
            icon="mdi-quadcopter"></v-icon></v-chip>
      </div>
      <TSATelemetryDock />

    </template>
    <template v-slot:append>
      <div>
        <v-chip size="small" class="chipIcon"><v-icon color="secondary" 
            icon="mdi-arrow-up-bold"></v-icon> {{ getSdrUp }}</v-chip>
            <v-chip size="small" class="chipIcon"><v-icon color="secondary" 
            icon="mdi-arrow-down-bold"></v-icon> {{ getSdrDown }}</v-chip>
      </div>
      <div v-if="showVideo">
        <v-chip size="small" class="chipIcon" @click="toggleVideoPlayer"><v-icon color="primary"
            icon="mdi-video"></v-icon></v-chip>
      </div>
      <div v-else>
        <v-chip size="small" class="chipIcon" @click="toggleVideoPlayer"><v-icon color="primary"
            icon="mdi-video-off"></v-icon></v-chip>
      </div>
      <v-chip class="ma-2" color="white" label @click="toggleSideBar">
        <v-icon color="white" icon="mdi-account-circle-outline" start> </v-icon>
        {{ getUsername }}
      </v-chip>
    </template>
    <template v-if="droneConnected" v-slot:extension>
      <div class="rightAling">
        <TSATelemetryDrone />
      </div>
    </template>

  </v-app-bar>
</template>

<script>
import TSATelemetryDock from "./TSATelemetryDock.vue";
import TSATelemetryDrone from "./TSATelemetryDrone.vue";
import useTelemetryStore from "@/store/telemetry";
import useStore from "@/store";
import { storeToRefs } from "pinia";
import useUiStore from '@/ui'
export default {
  data: () => ({}),
  components: {
    TSATelemetryDock,
    TSATelemetryDrone
  },
  setup() {
    const store = useStore();
    const uiStore = useUiStore();
    const telemetryStore = useTelemetryStore();
    const { workspaceSidebar, floatVisibility } = storeToRefs(uiStore);
    const { droneConnected, gatewayConnected } = storeToRefs(store);
    return {
      store,
      droneConnected,
      gatewayConnected,
      uiStore,
      workspaceSidebar,
      floatVisibility,
      telemetryStore
    };
  },
  computed: {
    showVideo() {
      return this.floatVisibility['video-player'];
    },
    showTelemetry() {
      return this.floatVisibility['telemetry-box'];
    },
    getUsername() {
      return localStorage.getItem("username");
    },
    getSdrUp() {
      try {
        const dockSN = localStorage.getItem("device_sn");
        const data = this.telemetryStore.getDeviceAllTelemetry(dockSN)
        console.log(data)
        return data.sdr.value.up_quality + "%";
        } catch {
        return '';
      }
    },
    getSdrDown() {
      try {
        const dockSN = localStorage.getItem("device_sn");
        const data = this.telemetryStore.getDeviceAllTelemetry(dockSN)
        console.log(data)
        return data.sdr.value.down_quality + "%";
        } catch {
        return '';
      }
    }
  },
  mounted() { },
  methods: {
    toggleVideoPlayer() {
      this.uiStore.toggleFloatVisibility('video-player');
    },
    toggleTelemetry() {
      this.uiStore.toggleFloatVisibility('telemetry-box');
    },
    toggleSideBar() {
      this.uiStore.toggleWorkspaceSidebar();
    }

  },
};
</script>

<style scoped>
.telemetryPanel {
  width: 100%;
}

.chipIcon {
  margin-left: 1px;
  margin-right: 1px;
}
</style>