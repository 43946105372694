<template>
  <v-app id="inspire">
    <v-app-bar>
      <v-app-bar-title>Dispositivos</v-app-bar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <v-container v-if="!loaded">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-container>
      <v-container v-else>
        <devicesMainView :devices="devices"></devicesMainView>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { deviceTopoService } from "../services/manageService";
import devicesMainView from "../views/Devices/DevicesMain.vue";
import store from "@/store";
export default {
  data: () => ({
    loaded: false,
    devices: [],
  }),
  components: {
    devicesMainView,
  },
  setup() {
    localStorage.setItem("device_sn", null);
    localStorage.setItem("child_device_sn", null);
    const useStore = store();
    return {
      store: useStore,
    };
  },
  mounted() {
    this.store.setDeviceSn(null);
    this.store.setChildDeviceSn(null);
    this.loadDevices();
  },
  methods: {
    async loadDevices() {
      this.loaded = false;
      const response = await deviceTopoService();
      console.log(response);
      if (response.code == 0) {
        this.devices = response.data;
        this.loaded = true;
        localStorage.setItem("devices", JSON.stringify(this.devices));
      }
    },
  },
};
</script>
