<template>
    <div>
        <div class="widget-tittle">
            Drone Control
            <v-slider class="slider" v-model="speed" :max="15" :min="1" :step="2" thumb-label="always"
                show-ticks="always" :thumb-size="12"></v-slider>
        </div>
        <div class="button-container">
            <div v-if="started">
                <!-- <FloatComponent title="Drone Control" idx="drone-control" x="100px" y="900px" :show="true">
            {{ this.messageString }}
-->
                <div class="button-container">

                    <div class="line-h"></div>
                    <div class="line-w"></div>
                    <div class="circle-outter"></div>
                    <div class="circle-inner"></div>
                    <div class="circle-center"></div>
                    <div class="arrow arrow-top" @mousedown="this.downEvent('x', 1 * this.speed)"
                        @mouseup="this.upEvent('x')"><v-icon size="x-large">mdi-chevron-up</v-icon></div>
                    <div class="arrow arrow-bottom" @mousedown="this.downEvent('x', -1 * this.speed)"
                        @mouseup="this.upEvent('x')">
                        <v-icon size="x-large">mdi-chevron-down</v-icon>
                    </div>
                    <div class="arrow arrow-left" @mousedown="this.downEvent('y', -1 * this.speed)"
                        @mouseup="this.upEvent('y')"><v-icon size="x-large">mdi-chevron-left</v-icon></div>
                    <div class="arrow arrow-right" @mousedown="this.downEvent('y', 1 * this.speed)"
                        @mouseup="this.upEvent('y')"><v-icon size="x-large">mdi-chevron-right</v-icon>
                    </div>
                    <div class="corner corner-top-left" @mousedown="this.downEvent('w', -5)"
                        @mouseup="this.upEvent('w')">
                        <v-icon size="x-large">mdi-rotate-left</v-icon>
                    </div>
                    <div class="corner corner-top-right" @mousedown="this.downEvent('h', 1)"
                        @mouseup="this.upEvent('h')">
                        <v-icon size="x-large">mdi-arrow-up-bold</v-icon>
                    </div>
                    <div class="corner corner-bottom-left" @mousedown="this.downEvent('w', 5)"
                        @mouseup="this.upEvent('w')">
                        <v-icon size="x-large">mdi-rotate-right</v-icon>
                    </div>
                    <div class="corner corner-bottom-right" @mousedown="this.downEvent('h', -1)"
                        @mouseup="this.upEvent('h')">
                        <v-icon size="x-large">mdi-arrow-down-bold</v-icon>
                    </div>
                </div>
                <!--
        </FloatComponent>
        -->
            </div>
            <div v-else>
                <div v-if="this.status == 'loading'">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
                <div v-else>
                    {{ status }}
                </div>
            </div>

        </div>

    </div>
</template>

<script>
//import FloatComponent from "@/components/FloatComponent.vue";
import mqtt from "mqtt";
import { postDrcEnterService, postDrcExitService } from '@/services/controlService.js'
import useUiStore from "@/ui";
import useStore from '@/store'
import { storeToRefs } from "pinia";
import { notify } from "notiwind";
import { CURRENT_CONFIG } from '@/api/http/config';
const DRC_MQTT = CURRENT_CONFIG.videriDRCMqtt
// mqtts://mediamtx.testcloud3.mindlabs.cl:443

export default {
    name: 'DroneControl',
    components: {
        //FloatComponent
    },
    data: () => {
        return {
            speed: 1,
            payload: {
                x: 0,
                y: 0,
                h: 0,
                w: 0,
            },
            size: 165,
            seq: 0,
            client: null,
            tid: null,
            initTime: null,
            status: "loading"
        }
    },
    setup() {
        const uiStore = useUiStore();
        const store = useStore();
        const { device_sn } = storeToRefs(store)
        return {
            uiStore,
            device_sn
        };
    },
    computed: {
        started() {
            if (this.initTime !== null && this.initTime !== undefined) {
                return true
            } else {
                return false
            }
        },
        messageString() {
            let message = JSON.parse(JSON.stringify(this.payload));
            message.seq = this.seq
            return JSON.stringify(message)
        },
        getLine() {
            return this.size / 40 + 'px'
        },
        getSize() {
            return this.size + 'px'
        },
        getCircleOutter() {
            return this.size * 0.8 + 'px'
        },
        getCircleInner() {
            return this.size * 0.75 + 'px'
        },
        getCircleCenter() {
            return this.size * 0.35 + 'px'
        },
        getArrowSize() {
            return this.size * 0.2 + 'px'
        },
        getArrowTop() {
            return this.size * -0.55 + 'px'
        },
        getArrowBottom() {
            return this.size * 0.55 + 'px'
        },
        getArrowLeft() {
            return this.size * -0.55 + 'px'
        },
        getArrowRight() {
            return this.size * 0.55 + 'px'
        },
        getCornerSize() {
            return this.size * 0.2 + 'px'
        },
        getCornerTop() {
            return this.size * -0.75 + 'px'
        },
        getCornerBottom() {
            return this.size * 0.75 + 'px'
        },
        getCornerLeft() {
            return this.size * -0.75 + 'px'
        },
        getCornerRight() {
            return this.size * 0.75 + 'px'
        }
    },
    async mounted() {
        await this.connectDRC()
    },
    async unmounted() {
        await this.disconnectDRC()
    },
    methods: {
        eClick(e) {
            console.log(e)
        },
        downEvent(c, v) {
            this.payload[c] = v
        },
        upEvent(c) {
            this.payload[c] = 0
        },
        async connectDRC() {
            console.log("Trying to connect to DRC")
            this.connectClient()
            const result = await postDrcEnterService(this.device_sn)
            if (result.code === 0) {
                console.log("Connected to DRC")
                this.initTime = new Date()
                notify(
                    {
                        group: 'workspace',
                        level: 'success',
                        title: 'Control Manual del Drone. ',
                        text: 'Conectado.',
                    },
                    2500 // 2.5s
                )
            }
            else {
                this.status = "Error al conectar con DRC"
                console.log("Error connecting to DRC")
                notify(
                    {
                        group: 'workspace',
                        level: 'error',
                        title: 'Control Manual del Drone. ',
                        text: 'Error al habilitar el control manual. Revise la calidad de conexión y reintente.',
                    },
                    2500 // 2.5s
                )
            }
            console.log(result)
        },
        async disconnectDRC() {
            console.log("Trying to disconnect to DRC")
            const result = await postDrcExitService(this.device_sn)
            console.log(result)
            clearInterval(this.tid)
            this.disconnectClient()
            notify(
                {
                    group: 'workspace',
                    level: 'warning',
                    title: 'Control Manual del Drone. ',
                    text: 'Desconectado.',
                },
                2500 // 2.5s
            )

        },
        async connectClient() {
            console.log(DRC_MQTT)
            const url = DRC_MQTT; //"mqtts://mediamtx.testcloud3.mindlabs.cl:443"; // DRC_MQTT; //"mqtts://mediamtx1.cvis.videri.ai:443"
            const options = this.getClientOptions()
            this.client = mqtt.connect(url, options)
            this.client.on("connect", this.onConnect)
            this.client.on("message", this.onMessage)
        },
        async onConnect() {
            console.log("Connected to MQTT DRC")
            this.client.subscribe("thing/product/" + this.device_sn + "/drc/up")
            this.startMessages()
        },
        async onMessage(topic, message) {
            console.log("Received message from DRC")
            console.log(topic, message.toString())
        },
        async disconnectClient() {
            console.log("Disconnect MQTT")
            this.client.end()
            console.log(this.client)
            notify(
                {
                    group: 'workspace',
                    level: 'warning',
                    title: 'Control Manual del Drone. ',
                    text: 'Desconectado.',
                },
                2500 // 2.5s
            )

        },
        async sendMessage(payload) {
            const topic = "thing/product/" + this.device_sn + "/drc/down"
            const body = {
                "method": "drone_control",
                "data": payload
            }
            this.client.publish(topic, JSON.stringify(body))
        },
        getClientOptions() {
            return {
                protocolVersion: 5,
                path: "/mqtt",
                username: localStorage.getItem("mqtt_username"),
                password: localStorage.getItem("x-auth-token"),
                clean: true,
            }
        },
        startMessages() {
            const tid = setInterval(() => {
                let payload = JSON.parse(JSON.stringify(this.payload));
                payload.seq = this.seq
                this.sendMessage(payload)
                this.seq += 1
            }, 100)
            self.tid = tid;
        },

    },
    watch: {
        payload: {
            handler() {
                this.seq = 0;
            }, deep: true
        }
    }
}
</script>

<style scoped>
/* Add your CSS here */


.container-center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50%;
    height: 100%;
}

.button-container {
    position: relative;
    width: v-bind('getSize');
    height: v-bind('getSize');
    background-color: #616161;
    border-radius: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.line-h {
    position: absolute;
    width: v-bind('getLine');
    height: v-bind('getSize');
    background-color: #353535;
    border-radius: 0%;
    display: flex;
    margin: 0px 0px;

}

.line-w {
    position: absolute;
    width: v-bind('getSize');
    height: v-bind('getLine');
    background-color: #353535;
    border-radius: 0%;
    display: flex;
    margin: 0px 0px;

}

.circle-outter {
    position: absolute;
    width: v-bind('getCircleOutter');
    height: v-bind('getCircleOutter');
    background-color: #242424;
    border-radius: 50%;
    display: flex;
    margin: 0px 0px;

}

.circle-inner {
    position: absolute;
    width: v-bind('getCircleInner');
    height: v-bind('getCircleInner');
    background-color: #3a3a3a;
    border-radius: 50%;
    display: flex;
    margin: 0px 0px;

}

.circle-center {
    position: absolute;
    width: v-bind('getCircleCenter');
    height: v-bind('getCircleCenter');
    background-color: #252525;
    border-radius: 50%;
    display: flex;
    margin: 0px 0px;

}

.arrow {
    position: absolute;
    width: v-bind('getArrowSize');
    height: v-bind('getArrowSize');
    margin-top: 0px;
    margin-left: 0px;
    background-color: #3a3a3a;
    border-radius: 100%;
    display: flex;

    justify-content: center;
    align-items: center;
}

.arrow:hover {
    background-color: #4d4d4d7e;

    cursor: pointer;
}

.arrow:active {
    background-color: rgba(156, 156, 156, 0.534);
    transition: filter 0s;
}


.arrow-top {
    margin-top: v-bind('getArrowTop');
}

.arrow-bottom {
    margin-top: v-bind('getArrowBottom');
}

.arrow-left {
    margin-left: v-bind('getArrowLeft');
}

.arrow-right {
    margin-left: v-bind('getArrowRight');
}

.corner {
    position: absolute;
    width: v-bind('getArrowSize');
    height: v-bind('getArrowSize');
    margin-top: 0px;
    margin-left: 0px;
    background-color: #616161;
    border-radius: 100%;
    display: flex;

    justify-content: center;
    align-items: center;
}

.corner:hover {
    background-color: #4d4d4dd0;

    cursor: pointer;
}

.corner:active {
    background-color: rgba(156, 156, 156, 0.534);
    transition: filter 0s;
}

.corner-top-left {
    margin-top: v-bind('getCornerTop');
    margin-left: v-bind('getCornerLeft');
}

.corner-top-right {
    margin-top: v-bind('getCornerTop');
    margin-left: v-bind('getCornerRight');
}

.corner-bottom-left {
    margin-top: v-bind('getCornerBottom');
    margin-left: v-bind('getCornerLeft');
}

.corner-bottom-right {
    margin-top: v-bind('getCornerBottom');
    margin-left: v-bind('getCornerRight');
}

.widget-tittle {
    width: v-bind('getSize');

    position: flex;

}

.slider {
    padding-top: 20px;
}
</style>