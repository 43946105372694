<template>
    <div class="telemetryContainer">
        <v-chip-group v-if="droneConnected">
            <v-chip size="x-small" class="chipIcon" @click="store.centerGatewayMap"><v-icon color="light-green-accent-3" icon="mdi-quadcopter"></v-icon></v-chip>
            <v-chip v-for="(item, index) in this.droneTelemetry" :key="index" compact size="x-small">
                    <div class="telemetryIcon" v-if="item.show_icon">
                        <v-icon color="white" :icon="item.icon"></v-icon>
                    </div>
                    <div v-if="item.show_label">
                        <span class="text-h7"> {{ item.label + ": " }} </span>
                    </div>
                    <div v-if="item.show_value">
                        <span class="text-h7"> {{ item.value }} </span>
                    </div>
            </v-chip>
            <v-chip size="x-small" class="chipIcon" >
                <span class="text-h7"> AGL: {{ agl }} </span>
            </v-chip>
        </v-chip-group>
    </div>
</template>
      
<script>

import { getDroneTelemetry } from "../../utils/droneTelemetryUtils.js";

import { defineComponent } from "vue";
import useStore from "@/store";
import { storeToRefs } from "pinia";
export default defineComponent({
    props: {

    },
    components: {
    },
    setup() {
        const store = useStore();
        const dockSn = store.device_sn;
        const droneSn = store.child_device_sn;
        const { telemetry, gatewayConnected, droneConnected, agl } = storeToRefs(store);
        return {
            store,
            telemetry,
            dockSn,
            droneSn,
            gatewayConnected,
            droneConnected,
            agl,
        };
    },
    computed: {
        droneTelemetry() {
            if (this.exist(this.telemetry[this.droneSn])) {
                if (this.exist(this.telemetry[this.droneSn]["osd_telemetry"])) {
                    try {
                        let result = getDroneTelemetry(this.telemetry[this.droneSn]["osd_telemetry"]["data"]);
                        result = result.filter((item) => item !== null && item !== undefined);
                        return result;
                    } catch {
                        return [];
                    }
                }
            }
            return [];
        },
    },
    methods: {
        exist(data) {
            return data !== undefined && data !== null;
        },

    },
    data() {
        return {};
    },
    watch: {

    },
    mounted() {
    },
});
</script>
  
<style scoped>
.telemetryContainer {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    padding: 0px 20px 0px 20px;
    background-color: rgba(0, 0, 0, 0.1);
}
</style>
  