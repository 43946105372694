/* eslint-disable no-unused-vars */
// Purpose: Handle login requests.
import { getLiveCapacity, startLivestream, stopLivestream, setLivestreamQuality, getLivestreamQuality } from '@/api/manage.ts';
import { ELocalStorageKey } from '@/types/enums.ts';

export const getLiveCapacityService = async () => {
    const response = await getLiveCapacity();
    return response;
}  

export const startLiveService = async (data) => {
    const response = await startLivestream(data);
    return response;
}

export const stopLiveService = async (data) => {
    const response = await stopLivestream(data);
    return response;
}


export const setLiveQualityService = async (data) => {
    const response = await setLivestreamQuality(data);
    console.log(response);
    return response;
}


export const getLiveQualityService = async (data) => {
    const response = await getLivestreamQuality(data);
    console.log(response);
    return response;
}