<template>
        <div class="container-center">
        <div class="widget-tittle">
            Zoom
        </div>
        <div class="button-container">
            <v-chip color="grey-darken-3" variant="flat" @click="eClick(-10)">-10</v-chip>
            <v-chip color="grey-darken-3" variant="flat" @click="eClick(-5)">-5</v-chip>
            <v-chip color="grey-darken-3" variant="flat" @click="eClick(-1)">-1</v-chip>
            <v-chip color="grey-darken-3" variant="flat" @click="setZoom()">x{{ this.zoomFactor }}</v-chip>
            <v-chip color="grey-darken-3" variant="flat" @click="eClick(1)">+1</v-chip>
            <v-chip color="grey-darken-3" variant="flat" @click="eClick(5)">+5</v-chip>
            <v-chip color="grey-darken-3" variant="flat" @click="eClick(10)">+10</v-chip>
        </div>
    </div>
</template>

<script>
import { cameraFocalLengthSetService } from '@/services/payloadService.js'

export default {
    name: 'ControlButtons',
    props: {
        maxZoom: {
            type: Number,
            default: 20
        },
        minZoom: {
            type: Number,
            default: 2
        },
        device_sn: String,
        payloadIndex: String,
        lentType: String
    },
    data() {
        return {
            size: 50,
            snackbar: false,
            text: "",
            btnSize: "large",
            zoomFactor: 2,
        }
    },
    computed: {
        getWidth() {
            return this.size * 6 + 'px'
        },
        getHeight() {
            return this.size + 'px'
        },
        getLine() {
            return this.size / 40 + 'px'
        },
        getSize() {
            return this.size + 'px'
        },
        getCircleOutter() {
            return this.size * 0.8 + 'px'
        },
        getCircleInner() {
            return this.size * 0.75 + 'px'
        },
        getCircleCenter() {
            return this.size * 0.35 + 'px'
        },
        getArrowSize() {
            return this.size * 0.2 + 'px'
        },
        getArrowTop() {
            return this.size * -0.55 + 'px'
        },
        getArrowM2() {
            return Math.floor(this.size * -0.55 * 0.7) + 'px'
        },
        getArrowBottom() {
            return this.size * 0.55 + 'px'
        },
        getArrowP2() {
            return Math.floor(this.size * 0.55 * 0.7) + 'px'
        },
        getArrowLeft() {
            return this.size * -0.55 + 'px'
        },
        getArrowLeft2() {
            return this.size * -0.55 + 'px'
        },
        getArrowRight() {
            return this.size * 0.55 + 'px'
        },
        getCornerSize() {
            return this.size * 0.2 + 'px'
        },
        getCornerTop() {
            return this.size * -0.75 + 'px'
        },
        getCornerBottom() {
            return this.size * 0.75 + 'px'
        },
        getCornerLeft() {
            return this.size * -0.75 + 'px'
        },
        getCornerRight() {
            return this.size * 0.75 + 'px'
        }

    },
    methods: {
        eClick(e) {
            const nz = this.zoomFactor + e;
            if (nz > this.maxZoom) {
                this.zoomFactor = this.maxZoom;
            } else if (nz < this.minZoom) {
                this.zoomFactor = this.minZoom;
            } else {
                this.zoomFactor = nz;
            }   
        },
        async setZoom() {
            console.log("Zoom: " + this.zoomFactor);
            const result = await cameraFocalLengthSetService(this.device_sn, this.payloadIndex, this.lentType, this.zoomFactor);
            console.log(result);
        }
    }
}
</script>

<style scoped>
.container-center {
    display: flexbox;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    background-color: #61616100;
} 
.widget-tittle {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #ffffff00;
}

.button-container {
    position: flexbox;
    width: v-bind('getWidth');
    height: v-bind('getHeight');
    background-color: #616161;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: antiquewhite;
    border-width: 2px;
    color: white;
    padding: 10px;
}

.line-h {
    position: absolute;
    width: v-bind('getLine');
    height: v-bind('getSize');
    background-color: #353535;
    border-radius: 0%;
    display: flex;
    margin: 0px 0px;
    border-color: antiquewhite;
    border-width: 2px;
}

.line-w {
    position: absolute;
    width: v-bind('getSize');
    height: v-bind('getLine');
    background-color: #353535;
    border-radius: 0%;
    display: flex;
    margin: 0px 0px;
    border-color: antiquewhite;
    border-width: 2px;
}

.circle-outter {
    position: absolute;
    width: v-bind('getCircleOutter');
    height: v-bind('getCircleOutter');
    background-color: #242424;
    border-radius: 50%;
    display: flex;
    margin: 0px 0px;
    border-color: antiquewhite;
    border-width: 2px;
}

.circle-inner {
    position: absolute;
    width: v-bind('getCircleInner');
    height: v-bind('getCircleInner');
    background-color: #3a3a3a;
    border-radius: 50%;
    display: flex;
    margin: 0px 0px;
    border-color: antiquewhite;
    border-width: 2px;
}

.circle-center {
    position: absolute;
    width: v-bind('getCircleCenter');
    height: v-bind('getCircleCenter');
    background-color: #252525;
    border-radius: 50%;
    display: flex;
    margin: 0px 0px;
    border-color: antiquewhite;
    border-width: 2px;
}

.arrow {
    position: absolute;
    width: v-bind('getArrowSize');
    height: v-bind('getArrowSize');
    margin-top: 0px;
    margin-left: 0px;
    background-color: #3a3a3a;
    border-radius: 100%;
    display: flex;
    border-color: antiquewhite;
    border-width: 2px;
    justify-content: center;
    align-items: center;
}

.arrow2 {
    position: absolute;
    width: v-bind('getArrowSize');
    height: v-bind('getArrowSize');
    margin-top: 0px;
    margin-left: 0px;
    background-color: #3a3a3a;
    border-radius: 100%;
    display: flex;
    border-color: antiquewhite;
    border-width: 2px;
    justify-content: center;
    align-items: center;
    color: #979797;
}

.arrow:hover {
    background-color: #4d4d4d7e;
    border-color: antiquewhite;
    border-width: 2px;
    cursor: pointer;
}

.arrow2:hover {
    background-color: #4d4d4d7e;
    border-color: antiquewhite;
    border-width: 2px;
    cursor: pointer;
}

.arrow:active {
    background-color: rgba(156, 156, 156, 0.534);
    transition: filter 0s;
}

.arrow2:active {
    background-color: rgba(156, 156, 156, 0.534);
    transition: filter 0s;
}

.arrow-top {
    margin-top: v-bind('getArrowTop');
}

.arrow-top-left {
    margin-top: v-bind('getArrowM2');
    margin-left: v-bind('getArrowM2');
    transform: rotate(-45deg);
}

.arrow-top-right {
    margin-top: v-bind('getArrowM2');
    margin-left: v-bind('getArrowP2');
    transform: rotate(45deg);
}

.arrow-bottom-left {
    margin-top: v-bind('getArrowP2');
    margin-left: v-bind('getArrowM2');
    transform: rotate(-135deg);
}

.arrow-bottom-right {
    margin-top: v-bind('getArrowP2');
    margin-left: v-bind('getArrowP2');
    transform: rotate(-225deg);
}

.arrow-bottom {
    margin-top: v-bind('getArrowBottom');
}

.arrow-left {
    margin-left: v-bind('getArrowLeft');
}

.arrow-right {
    margin-left: v-bind('getArrowRight');
}

.corner {
    position: absolute;
    width: v-bind('getArrowSize');
    height: v-bind('getArrowSize');
    margin-top: 0px;
    margin-left: 0px;
    background-color: #616161;
    border-radius: 100%;
    display: flex;
    border-color: antiquewhite;
    border-width: 2px;
    justify-content: center;
    align-items: center;
}

.corner:hover {
    background-color: #4d4d4dd0;
    border-color: antiquewhite;
    border-width: 2px;
    cursor: pointer;
}

.corner:active {
    background-color: rgba(156, 156, 156, 0.534);
    transition: filter 0s;
}

.corner-top-left {
    margin-top: v-bind('getCornerTop');
    margin-left: v-bind('getCornerLeft');
}

.corner-top-right {
    margin-top: v-bind('getCornerTop');
    margin-left: v-bind('getCornerRight');
}

.corner-bottom-left {
    margin-top: v-bind('getCornerBottom');
    margin-left: v-bind('getCornerLeft');
}

.corner-bottom-right {
    margin-top: v-bind('getCornerBottom');
    margin-left: v-bind('getCornerRight');
}</style>
