<template>
  <v-expansion-panel :title="name">
    <v-expansion-panel-text>
      <v-list>
        <v-list-item v-for="(value, key) in telemetry" :key="key">
          <v-list-item-title class="panelKey">{{ key }}</v-list-item-title>
          <p class="panelValue">{{ value }}</p>
        </v-list-item>
      </v-list>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "DebugTelemetry",
  props: {
    telemetry: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
.panelKey{
    color: #cecece;
    font-weight: bold;
}
.panelValue{
    color: #9b9b9b;
}
</style>
