import { postDrcEnter, postDrcExit }  from '@/api/drc.ts';

export const postDrcEnterService = async (dock_sn) => {
    const client_id = localStorage.getItem("user_id");
    const payload = {
        "client_id": client_id,
        "dock_sn": dock_sn,
    }
    const result = await postDrcEnter(payload);
    return result;
}


export const postDrcExitService = async (dock_sn) => {
    const client_id = localStorage.getItem("user_id");
    const payload = {
        "client_id": client_id,
        "dock_sn": dock_sn,
    }
    const result = await postDrcExit(payload);
    return result;
}
