/* eslint-disable no-unused-vars */
// Purpose: Handle login requests.
import { login, getDeviceTopo, getPlatformInfo, getDeviceBySn, refreshToken }  from '@/api/manage.ts';
import { ELocalStorageKey } from '@/types/enums.ts';



export const refreshTokenService = () => {
    const tid = setInterval(async () => {
        try {
            const result = await refreshToken();
            if (result.code == 0){
                //setLoginLocalStorage(result.data);
                setRefreshLoginLocalStorage(result.data);
            }
        } catch (error) {
            console.log(error);
        }    
    }, 1000*60*5);
}

const setRefreshLoginLocalStorage = (data) => {
    localStorage.setItem(ELocalStorageKey.Token, data.access_token);
}

const setLoginLocalStorage = (data) => {
    if (data.password !== undefined && data.password !== null) {
        localStorage.setItem("password", data.password)
    }
    localStorage.setItem(ELocalStorageKey.Username, data.username);
    localStorage.setItem(ELocalStorageKey.WorkspaceId, data.workspace_id);
    localStorage.setItem(ELocalStorageKey.Token, data.access_token);
    localStorage.setItem(ELocalStorageKey.UserId, data.user_id);
    localStorage.setItem(ELocalStorageKey.Flag, data.user_type);
    localStorage.setItem("mqtt_username", data.mqtt_username);
    localStorage.setItem("mqtt_password", data.mqtt_password);
    console.log(localStorage)
}

export const loginService = async (username, password, flag) => {
    const data = {
        "username": username,
        "password": password,
        "flag": flag
    }
    const result = await login(data);
    if (result.code == 0) {
        result.data["password"] = password;
        setLoginLocalStorage(result.data);
        return result;
    }
    return result;
};

export const deviceTopoService = async () => {
    console.log(localStorage)
    const workspace_id = localStorage.getItem(ELocalStorageKey.WorkspaceId);
    const result = await getDeviceTopo(workspace_id);
    return result;
}

export const platformInfoService = async () => {
    const result = await getPlatformInfo();
    return result;
}

export const getDevice = async (sn) => {
    const workspace_id = localStorage.getItem(ELocalStorageKey.WorkspaceId);
    const result = await getDeviceBySn(workspace_id, sn);
    console.log(sn);
    console.log(result);
    return result
}
