import './index.css'
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import Notifications from 'notiwind'


import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { fa } from "vuetify/iconsets/fa";
import { aliases, mdi } from "vuetify/lib/iconsets/mdi";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure your project is capable of handling css files

import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate"

import 'v-calendar/style.css';
import VCalendar from 'v-calendar';

import { ProCalendar } from "vue-pro-calendar";


// Use plugin with optional defaults

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);
const vuetify = createVuetify({
  theme: {
    defaultTheme: "dark",
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
      fa,
    },
  },
  components,
  directives,
});


const app = createApp(App);
app.use(Notifications)


app.use(pinia);
app.use(VCalendar, {componentPrefix: 'vc'})
app.use(ProCalendar);
app.use(vuetify);
app.use(router);
app.mount("#app");
