<template>
  <v-card>
    <v-card-title> Dock Telemetry </v-card-title>
    <v-card-text>
      <v-chip-group column >
        <div v-for="(item, index) in keyValues" :key="index">
          <v-chip>
            <div class="telemetryIcon" v-if="item.show_icon">
              <v-icon color="primary" :icon="item.icon"></v-icon>
            </div>
            <div v-if="item.show_label">
              <span class="text-h6"> {{ item.label + ": " }} </span>
            </div>
            <div v-if="item.show_value">
              <span class="text-h6">  {{ item.value }} </span>
            </div>
          </v-chip>
        </div>
      </v-chip-group>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="load" text>More Info</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import useStore from "@/store";
import { storeToRefs } from "pinia";
import { getDockTelemetry } from "../utils/dockTelemetryUtils.js";

export default {
  name: "DockTelemetryBox",
  data: () => ({
    keyValues: [],
  }),
  setup() {
    const store = storeToRefs(useStore());
    const { device_sn, child_device_sn, telemetry } = store;
    return {
      store: store,
      device_sn: device_sn,
      child_device_sn: child_device_sn,
      telemetry: telemetry,
    };
  },

  methods: {
    load() {
      console.log("Loading more info");
      const req = this.telemetry[this.device_sn]["osd_telemetry"]["data"];
      console.log(getDockTelemetry(req))
      this.keyValues = getDockTelemetry(req);
    },
  },
};
</script>

<style scoped>
.telemetryIcon {
  display: inline-block;
  margin-right: 5px;
}
</style>
