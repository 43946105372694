<template>

  <FloatComponent title="Acciones" idx="control-actions" x="101%" y="0%" :show="false">
    <v-container>
      <v-row>

        <v-col class="colCenter">
          <div v-if="this.lentSelection == 'wide'">
            <GimbalControl :device_sn="this.device_sn" :payloadIndex="this.cameraSelected.index" lentType="wide" />
          </div>
          <div v-else>
            <GimbalControl :device_sn="this.device_sn" :payloadIndex="this.cameraSelected.index" lentType="zoom" />
          </div>
        </v-col>

        <v-col class="colCenter" v-if="this.uiStore.showDroneControl">
          <DroneControl />
        </v-col>
      </v-row>

      <v-row v-if="showZoom">
        <v-col class="colCenter" v-if="this.lentSelection== 'zoom'">
          <ZoomControl v-if="this.lentSelection== 'zoom'" :maxZoom="200" :minZoom="2" :device_sn="this.device_sn"
                :payloadIndex="this.cameraSelected.index" lentType="zoom" />
        </v-col>
        <v-col class="colCenter" v-if="this.lentSelection == 'ir'">
          <ZoomControl v-if="this.lentSelection == 'ir'" :maxZoom="20" :minZoom="2" :device_sn="this.device_sn"
                :payloadIndex="this.cameraSelected.index" lentType="ir" />
        </v-col>
      </v-row>
    </v-container>
  </FloatComponent>
</template>

<script>
import FloatComponent from "@/components/FloatComponent.vue";
import GimbalControl from './widgets/gimbalControl.vue';
import ZoomControl from './widgets/zoomControl.vue';
import DroneControl from './widgets/droneControl.vue';

import useUiStore from '@/ui'
import useStore from "@/store";
import { storeToRefs } from "pinia";

export default {
  name: 'TSAControl',
  props: {
    //
  },
  components: {
    //
    FloatComponent,
    GimbalControl,
    ZoomControl,
    DroneControl
  },
  setup() {
    const store = useStore();
    const uiStore = useUiStore();
    const { lentSelection, device_sn, cameraSelected } = storeToRefs(store);
    return {
      lentSelection,
      device_sn,
      cameraSelected,
      uiStore
    };
  },
  data: () => ({
    //
  }),
  methods: {
    //
  },
  computed: {
    showZoom(){
      return this.lentSelection == 'zoom' || this.lentSelection == 'ir';
    },
  },
  watch: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  }
}
</script>

<style scoped>
/* Add your CSS here */
.colCenter {
  display: flex;
  justify-content: center;
}

    /* Tooltip container */
    .tooltip {
        position: relative;
        display: inline-block;
        border-bottom: 1px dotted black; /* Optional: Dots under the hoverable text */
    }

    /* Tooltip text */
    .tooltip .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
    }

    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltip:hover .tooltiptext {
        visibility: visible;
    }
</style>