import * as zip from "@zip.js/zip.js";

export const loadWayline = async (blob) => {
  try {
    const reader = new zip.ZipReader(new zip.BlobReader(blob));
    const entries = await reader.getEntries();
    const kmlFile = entries.find((entry) => entry.filename.includes(".kml"));
    const kmlData = await kmlFile.getData(new zip.TextWriter());
    const parser = new DOMParser();
    const doc = parser.parseFromString(kmlData, "application/xml");
    console.log(doc);
    const placemarks = doc.getElementsByTagName("Placemark");
    const points = [];
    for (let i = 0; i < placemarks.length; i++) {
      const coordinates =
        placemarks[i].getElementsByTagName("coordinates")[0].textContent;
      const coords = coordinates.split(",");
      const lat = parseFloat(coords[1]);
      const lng = parseFloat(coords[0]);
      points.push({ lat, lng });
    }
    return points;
  
  } catch {
    return []
  }
};
