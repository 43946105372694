import { createWebHistory, createRouter } from 'vue-router';
import HomePage from '@/pages/HomePage.vue';
import WorkspacePage from '@/pages/WorkspacePage.vue';
import DevicesPage from '@/pages/DevicesPage.vue';

import DebugView from '@/views/Workspace/DebugView.vue'
import TSAView from '@/views/Workspace/TSAView.vue'
import WaylineLibraryView from '@/views/Workspace/WaylineLibraryView.vue'
import TaskView from "@/views/Workspace/TaskView.vue"
import CalendarView from '../views/Workspace/CalendarView.vue';
import CalendarNext from '../views/Workspace/CalendarNext.vue';
const routes = [
	{
		path: '/',
		name: 'HomePage',
		component: HomePage,
	},
    {
        path: '/workspace',
        name: 'Workspace',
        component: WorkspacePage,
        redirect: '/tsa',
        children: [
            {
                path: '/debug',
                name: 'Debug',
                component: DebugView,
                                
            },
            {
                path: '/tsa',
                name: 'TSA',
                component: TSAView,
            },
            {
                path: '/waylines',
                name: 'WaylineLibrary',
                component: WaylineLibraryView
            },
            {
                path: '/tasks',
                name: 'Tasks',
                component: TaskView
            },
            {
                path: '/calendar',
                name: 'Calendar',
                component: CalendarView
            },
            {
                path: '/calendar2',
                name: 'Calendar2',
                component: CalendarNext
            }
        ]
    },
    {
        path: '/devices',
        name: 'Devices',
        component: DevicesPage,
    }
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});


export default router;