
<template>
    <FloatComponent title="Video" idx="video-player" x="100px" y="100px" :show="true">
        <TSAControl /> 
        <v-sheet :height="auto" :width="auto" border rounded>
            <v-container fluid>
                <v-row>
                    <TSAMission class="centerMission"></TSAMission>
                </v-row>
                <v-row>
                    <iframe :width="this.videoWidth" :height="this.videoHeight" :src="this.videoUrl" allowfullscreen></iframe>
                </v-row>
                    <TSAVideoPanel></TSAVideoPanel>
            </v-container>
        </v-sheet>
    </FloatComponent>
</template>

<script>
import TSAMission from "@/views/Workspace/components/tsa/TSAMission.vue";

import TSAControl from './TSAControl.vue';

import FloatComponent from "@/components/FloatComponent.vue";
import TSAVideoPanel from "./TSAVideoPanel.vue";
import { getLiveCapacityService } from "@/services/videoService.js";
import useStore from "@/store";
import useUiStore from "@/store/ui";
import { storeToRefs } from "pinia";
export default {
    name: "TSAVideo",
    components: {
        FloatComponent,
        TSAVideoPanel,
        TSAMission,
        TSAControl
    },
    setup() {
        const { videoUrl } = storeToRefs(useStore());
        const ui2Store = useUiStore();
        const { videoSizeFactor } = storeToRefs(useUiStore());
        return {
            videoUrl,
            videoSizeFactor,
            ui2Store
        };
    },
    data: () => ({
        //
    }),
    methods: {
        async getVideo() {
            const video = await getLiveCapacityService();
            console.log(video);
        },
    },
    computed: {
        videoWidth() {
            console.log(this.videoSizeFactor);
            return this.videoSizeFactor * 72;
        },
        videoHeight() {
            return this.videoSizeFactor * 48;
        }
        //
    },
    watch: {
        //
    },
    created() {
        //
    },
    mounted() {
        
        //
    },
};
</script>

<style scoped>
.centerMission {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>