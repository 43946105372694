<template>
    <v-container fill-height>
      <v-virtual-scroll :items="devices" class="vscroll">
        <template v-slot="{ item }">
          <v-row>
            <v-col>
              <deviceCard :device="item"></deviceCard>
            </v-col>
          </v-row>
        </template> 
      </v-virtual-scroll>

<!--        <v-row v-for="device in devices" :key="device.id">
            <v-col>
                <deviceCard :device="device"></deviceCard>
            </v-col>
        </v-row> -->

    </v-container>
</template>

<script>
import DeviceCard from "./components/DeviceCard.vue";
export default {
  props: {
    devices: {
      type: Array,
      required: true,
    },
  },
  components: {
    DeviceCard,
  },
  data: () => ({}),
  setup() {
    return {
      //
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.vscroll {
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: top;
  justify-content: center;
  overflow-y: auto;
}
</style>
