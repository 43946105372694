/* eslint-disable no-unused-vars */
import useStore from "@/store";

class Daemon {
  tId = null;
  store = null;

  start() {
    this.store = useStore();
    this.tId = setInterval(() => {
      const dockTelemetry = this.store.telemetry[this.store.device_sn];
      const droneTelemetry = this.store.telemetry[this.store.child_device_sn];
      this.store.gatewayConnected = this._validateTelemetry(dockTelemetry);
      if (this.store.gatewayConnected) {
        this.store.droneConnected  = this._validateTelemetry(droneTelemetry);
      } else {
        this.store.droneConnected = false;
      }
    }, 1000);
  }

  stop() {
    clearInterval(this.tId);
    this.tId = null;
  }

  setMarkerMap(map, marker) {
    /*
    if (marker.getMap() === null && map !== null) {
      map.setCenter(marker.getPosition());
      map.setZoom(20);
    }
    marker.setMap(map);
    if (marker.getMap() === null) {
      marker.setPosition({lat: 0, lng: 0});
    }
    */
    return;
  }

  _validateTelemetry(data) {
    try {
      if (this._checkExist(data) && this._checkValidTimestamp(data)) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  _checkExist(data) {
    if (Object.prototype.hasOwnProperty.call(data, "osd_telemetry")) {
      return true;
    } else {
      return false;
    }
  }

  _checkValidTimestamp(data) {
    const delta = Date.now() - data.osd_telemetry.timestamp;
    if (delta < 5000) {
      return true;
    } else {
      return false;
    }
  }
}

export default new Daemon();
