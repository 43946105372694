<template>
  <div v-if="this.workspaceSidebar">
    <v-navigation-drawer location="end" :permanent="true" :width="300">
      <TSAMenuBar />
    </v-navigation-drawer>
  </div>
  
  <TSATopBar />
 
  <v-main class="d-flex" style="min-height: 300px;">
    <TSAMap />
    <TSAVideo />

    <!--<TSAMission /> -->
    <!-- <TSAGimbalZoom/> -->
    <!-- <TSADroneControl v-if="this.uiStore.showDroneControl"/> -->
    <!-- <TSAControl /> -->
    <WorkspaceNotify />
  </v-main>
</template>

<script>
import WorkspaceNotify from "@/notifications/WorkspaceNotify.vue";
import TSATopBar from "./components/tsa/TSATopBar.vue";
import TSAMap from "./components/tsa/TSAMap.vue";
import TSAVideo from "./components/tsa/TSAVideo.vue";
//import TSAMission from "./components/tsa/TSAMission.vue";
//import TSADroneControl from './components/tsa/widgets/droneControl.vue';
//import TSAGimbalZoom from "./components/tsa/TSAGimbalZoom.vue";
// import TSAControl from "./components/tsa/TSAControl.vue";
import TSAMenuBar from "./components/tsa/TSAMenuBar.vue";
//import TSAFloat from "./components/tsa/TSAFloat.vue";
import useUiStore from '@/ui'
import { storeToRefs } from "pinia";

export default {
  name: "TSAView",
  components: {
    TSAMap,
    TSATopBar,
    TSAVideo,
    //TSAMission,
    //TSAGimbalZoom,
    //TSADroneControl,
    //TSAControl,
    TSAMenuBar,
    WorkspaceNotify,
    //TSAFloat,
  },
  setup() {
    const uiStore = useUiStore();
    const { workspaceSidebar } = storeToRefs(uiStore);
    return {
      uiStore,
      workspaceSidebar
    };
  },
  data: () => ({
    //
  }),
  methods: {
    //
  },
  computed: {
    //
  },
  watch: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
};
</script>

<style scoped>
.container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
