<template>
        <div class="widget-tittle">
            Gimbal
        </div>
<div class="container-center">

        <div class="button-container">
            <div class="circle-outter"></div>
            <div class="circle-inner"></div>
            <div class="circle-center"></div>
            <div class="arrow arrow-top" @click="moveCamera(0.5, 0.25)"><v-icon :size="this.btnSize">mdi-chevron-up</v-icon></div>
            <div class="arrow arrow-bottom" @click="moveCamera(0.5, 0.75)"><v-icon :size="this.btnSize">mdi-chevron-down</v-icon>
            </div>
            <div class="arrow arrow-left" @click="moveCamera(0.25, 0.5)"><v-icon :size="this.btnSize">mdi-chevron-left</v-icon>
            </div>
            <div class="arrow arrow-right" @click="moveCamera(0.75, 0.5)"><v-icon :size="this.btnSize">mdi-chevron-right</v-icon>
            </div>

            <div class="arrow2 arrow-top-left" @click="moveCamera(0.3, 0.3)"><v-icon
                    :size="this.btnSize">mdi-chevron-up</v-icon></div>
            <div class="arrow2 arrow-top-right" @click="moveCamera(0.7, 0.3)"><v-icon
                    :size="this.btnSize">mdi-chevron-up</v-icon></div>
            <div class="arrow2 arrow-bottom-left" @click="moveCamera(0.3, 0.7)"><v-icon
                    :size="this.btnSize">mdi-chevron-up</v-icon></div>
            <div class="arrow2 arrow-bottom-right" @click="moveCamera(0.7, 0.7)"><v-icon
                    :size="this.btnSize">mdi-chevron-up</v-icon></div>

        </div>
    </div>
</template>

<script>
import { cameraIamService } from "@/services/payloadService.js";
export default {
    name: 'ControlButtons',
    props: {
        device_sn: String,
        payloadIndex: String,
        lentType: String
    },
    data() {
        return {
            size: 180,
            snackbar: false,
            text: "",
            btnSize: "large",
        }
    },
    computed: {
        getLine() {
            return this.size / 40 + 'px'
        },
        getSize() {
            return this.size + 'px'
        },
        getCircleOutter() {
            return this.size * 0.8 + 'px'
        },
        getCircleInner() {
            return this.size * 0.75 + 'px'
        },
        getCircleCenter() {
            return this.size * 0.35 + 'px'
        },
        getArrowSize() {
            return this.size * 0.2 + 'px'
        },
        getArrowTop() {
            return this.size * -0.55 + 'px'
        },
        getArrowM2() {
            return Math.floor(this.size * -0.55 * 0.7) + 'px'
        },
        getArrowBottom() {
            return this.size * 0.55 + 'px'
        },
        getArrowP2() {
            return Math.floor(this.size * 0.55 * 0.7) + 'px'
        },
        getArrowLeft() {
            return this.size * -0.55 + 'px'
        },
        getArrowLeft2() {
            return this.size * -0.55 + 'px'
        },
        getArrowRight() {
            return this.size * 0.55 + 'px'
        },
        getCornerSize() {
            return this.size * 0.2 + 'px'
        },
        getCornerTop() {
            return this.size * -0.75 + 'px'
        },
        getCornerBottom() {
            return this.size * 0.75 + 'px'
        },
        getCornerLeft() {
            return this.size * -0.75 + 'px'
        },
        getCornerRight() {
            return this.size * 0.75 + 'px'
        }

    },
    methods: {
        eClick(e) {
            this.text = e;
        },
        async moveCamera(x, y) {
            const sn = this.device_sn;
            const payloadIndex = this.payloadIndex;
            const lentType = this.lentType;
            const result = await cameraIamService(sn, payloadIndex, lentType, false, x, y);
            console.log(result)
        },
    }
}
</script>

<style scoped>
.container-center {
    display: flexbox;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    border-radius: 10%;
    padding: 10px;
    background-color: #616161;
}
.widget-tittle {
    display: flexbox;
    justify-content: center;
    align-items: center;
    background-color: #ffffff00;
}

.button-container {
    position: flex;
    width: v-bind('getCircleOutter');
    height: v-bind('getCircleOutter');
    background-color: #6161611a;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.line-h {
    position: absolute;
    width: v-bind('getLine');
    height: v-bind('getSize');
    background-color: #353535;
    border-radius: 0%;
    display: flex;
    margin: 0px 0px;
    
}

.line-w {
    position: absolute;
    width: v-bind('getSize');
    height: v-bind('getLine');
    background-color: #353535;
    border-radius: 0%;
    display: flex;
    margin: 0px 0px;

}

.circle-outter {
    position: absolute;
    width: v-bind('getCircleOutter');
    height: v-bind('getCircleOutter');
    background-color: #242424;
    border-radius: 50%;
    display: flex;
    margin: 0px 0px;
    border-color: rgba(250, 235, 215, 0);
}

.circle-inner {
    position: absolute;
    width: v-bind('getCircleInner');
    height: v-bind('getCircleInner');
    background-color: #3a3a3a;
    border-radius: 50%;
    display: flex;
    margin: 0px 0px;
    
}

.circle-center {
    position: absolute;
    width: v-bind('getCircleCenter');
    height: v-bind('getCircleCenter');
    background-color: #252525;
    border-radius: 50%;
    display: flex;
    margin: 0px 0px;
    
}

.arrow {
    position: absolute;
    width: v-bind('getArrowSize');
    height: v-bind('getArrowSize');
    margin-top: 0px;
    margin-left: 0px;
    background-color: #3a3a3a;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow2 {
    position: absolute;
    width: v-bind('getArrowSize');
    height: v-bind('getArrowSize');
    margin-top: 0px;
    margin-left: 0px;
    background-color: #3a3a3a;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #979797;
}

.arrow:hover {
    background-color: #4d4d4d7e;
    cursor: pointer;
}

.arrow2:hover {
    background-color: #4d4d4d7e;
    cursor: pointer;
}

.arrow:active {
    background-color: rgba(156, 156, 156, 0.534);
    transition: filter 0s;
}

.arrow2:active {
    background-color: rgba(156, 156, 156, 0.534);
    transition: filter 0s;
}

.arrow-top {
    margin-top: v-bind('getArrowTop');
}

.arrow-top-left {
    margin-top: v-bind('getArrowM2');
    margin-left: v-bind('getArrowM2');
    transform: rotate(-45deg);
}

.arrow-top-right {
    margin-top: v-bind('getArrowM2');
    margin-left: v-bind('getArrowP2');
    transform: rotate(45deg);
}

.arrow-bottom-left {
    margin-top: v-bind('getArrowP2');
    margin-left: v-bind('getArrowM2');
    transform: rotate(-135deg);
}

.arrow-bottom-right {
    margin-top: v-bind('getArrowP2');
    margin-left: v-bind('getArrowP2');
    transform: rotate(-225deg);
}

.arrow-bottom {
    margin-top: v-bind('getArrowBottom');
}

.arrow-left {
    margin-left: v-bind('getArrowLeft');
}

.arrow-right {
    margin-left: v-bind('getArrowRight');
}

.corner {
    position: absolute;
    width: v-bind('getArrowSize');
    height: v-bind('getArrowSize');
    margin-top: 0px;
    margin-left: 0px;
    background-color: #616161;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.corner:hover {
    background-color: #4d4d4dd0;
    cursor: pointer;
}

.corner:active {
    background-color: rgba(156, 156, 156, 0.534);
    transition: filter 0s;
}

.corner-top-left {
    margin-top: v-bind('getCornerTop');
    margin-left: v-bind('getCornerLeft');
}

.corner-top-right {
    margin-top: v-bind('getCornerTop');
    margin-left: v-bind('getCornerRight');
}

.corner-bottom-left {
    margin-top: v-bind('getCornerBottom');
    margin-left: v-bind('getCornerLeft');
}

.corner-bottom-right {
    margin-top: v-bind('getCornerBottom');
    margin-left: v-bind('getCornerRight');
}</style>
