<template>
  <v-app>
    <!--
    <v-snackbar v-model="toast" multi-line :close-on-content-click="true" color="red" location="top center">
      {{ toastText }}
    </v-snackbar>
    -->
    <v-layout class="flex justify-center items-center">
      <v-sheet>
        <v-form validate-on="submit lazy" @submit.prevent="submit" class="form">
          <v-img class="im" :src="logo"></v-img>

          <v-text-field v-model="username" label="Nombre de usuario"></v-text-field>

          <v-text-field v-model="password" label="Contraseña" type="password"></v-text-field>

          <v-btn :loading="loading" type="submit" block class="mt-2" text="Login"></v-btn>
        </v-form>
      </v-sheet>
    </v-layout>
  </v-app>
</template>

<script>
import logo from "@/assets/logo_videri.png";
import { loginService } from "@/services/manageService.js";
import router from "@/router/index.js";
import { notify } from "notiwind";
import {
  VApp,
  VLayout,
  VSheet,
  VForm,
  VBtn,
  VTextField,
  VImg,
  //VSnackbar,
} from "vuetify/components";
export default {
  components: {
    VImg,
    VApp,
    VLayout,
    VSheet,
    VForm,
    VBtn,
    VTextField,
    //VSnackbar,
  },
  data: () => ({
    loading: false,
    timeout: null,
    username: "",
    password: "",
    logo: logo,
    toast: false,
    toastText: "",
  }),
  setup() {
    localStorage.setItem("device_sn", null);
    localStorage.setItem("child_device_sn", null);
    return {};
  },

  methods: {
    async submit() {
      this.loading = true;
      const response = await loginService(this.username, this.password, 1);
      if (response.code == 0) {
        console.log("Logged");
        router.push("devices");
      } else {
        if (response.code == 401) {
          notify(
            {
              group: 'default',
              level: 'warn',
              title: 'Login ',
              text: 'Error al inicio de sesión, usuario y/o contraseña incorrecta',
            },
            2500 // 2.5s
          )
        } else {
          notify(
            {
              group: 'default',
              level: 'error',
              title: 'Login ',
              text: 'Error de conexión con el servidor, intente nuevamente más tarde',
            },
            2500 // 2.5s
          )
        }
        this.toast = true;
      }
      this.loading = false;
    },
  },
};
</script>
<style>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  height: 100%;
  width: 640px;
  padding: 24px;
}

.im {
  padding: 20px;
}
</style>