<!-- eslint-disable no-unused-vars -->
<template>
  <v-main class="align-center justify-center" style="min-height: 300px">
    <div v-if="loading">Cargando ...</div>
    <div v-else>
      <TaskList :jobs="jobs" />
    </div>
  </v-main>
</template>

<script>
import { getJobsService } from "@/services/taskService.js";
import TaskList from "./components/task/TaskList.vue";
export default {
  name: "TaskView",
  components: {
    TaskList,
  },
  setup() {
    return {};
  },
  async mounted() {
    const result = await getJobsService();
    
    this.jobs = this.filterJobs(result);
    this.loading = false;
  },
  data: () => ({
    jobs: [],
    loading: true,
  }),
  methods: {
    filterJobs(data) {
      console.log(data)
      let result = [];
      data.forEach((job) => {
        if (job.status == 2 || job.status === 3 || job.status === 5) {
          result.push(job);
        }
      
      });
      return result;
    }
  },
  computed: {
    //
  },
  watch: {},
  created() {
    
  },
};
</script>

<style scoped>
.container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
