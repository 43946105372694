/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */


export const getDroneTelemetry = (data) => {
    return [
        droneBatteryHandler(data, true, true, false),
        /* remainFlightTimeHandler(data, true, false, true), */
        positionFixedHandler(data, true, false, false),
        gpsSatHandler(data, true, false, true),
        rtkSatHandler(data, true, false, true),
        windSpeedHandler(data, true, false, true),
        relativeAltitudHandler(data, true, false, true),
        /* distanceToHomePointHandler(data, true, true, false), */
        operationModeHandler(data, true, true, false),
    ]
}

const droneBatteryHandler = (data, show_value, show_icon, show_label) => {
    let value = data["battery"]["capacity_percent"]
    value = value + "%"
    let icon = "mdi-battery"
    let label = "Batería" 
    return { value, icon, label, show_value, show_icon, show_label };
}

const remainFlightTimeHandler = (data, show_value, show_icon, show_label) => {
    let value = data["battery"]["remain_flight_time"]
    let minutes = Math.floor(value/60)
    let seconds = value%60
    value = minutes + "m " + seconds + "s"
    let icon = "mdi-clock"
    let label = "Tiempo de vuelo restante"
    return { value, icon, label, show_value, show_icon, show_label }
}

const positionFixedHandler = (data, show_value, show_icon, show_label) => {
    let value = data["position_state"]["is_fixed"]
    const statusRef = {"0":"No GPS","1":"Single GPS","2":"DGPS","3":"RTK Float","4":"RTK Fixed"}
    value = statusRef[value.toString()]
    let icon = "mdi-home"
    let label = "P"
    return { value, icon, label, show_value, show_icon, show_label };
}

const gpsSatHandler = (data, show_value, show_icon, show_label) => {
    let value = data["position_state"]["gps_number"]
    let icon = "mdi-home"
    let label = "GPS"
    return { value, icon, label, show_value, show_icon, show_label };
}


const rtkSatHandler = (data, show_value, show_icon, show_label) => {
    let value = data["position_state"]["rtk_number"]
    let icon = "mdi-home"
    let label = "RTK"
    return { value, icon, label, show_value, show_icon, show_label };
}

const relativeAltitudHandler = (data, show_value, show_icon, show_label) => {
    let value = data["elevation"]
    value = Math.floor(value) + "m"
    let icon = "mdi-home"
    let label = "Altitud" 
    return { value, icon, label, show_value, show_icon, show_label };
}

const distanceToHomePointHandler = (data, show_value, show_icon, show_label) => {
    let value = data["home_distance"]
    value = Math.floor(value) + "m"
    let icon = "mdi-home"
    let label = "Distancia al Home"
    return { value, icon, label, show_value, show_icon, show_label };
}

const operationModeHandler = (data, show_value, show_icon, show_label) => {
    let value = data["mode_code"]
    const statusRef = {"0":"Standby","1":"Takeoff preparation","2":"Takeoff preparation completed","3":"Manual flight","4":"Automatic takeoff","5":"Wayline flight","6":"Panorama","7":"Intelligent tracking","8":"ADS-B avoidance","9":"Auto returning to home","10":"Automatic landing","11":"Forced landing","12":"Three-blade landing","13":"Upgrading","14":"Not connected","15":"APAS","16":"Virtual stick state","17":"Live Flight Controls","18":"Airborne RTK fixing mode","19":"Dock address selecting","20":"POI"}
    value = statusRef[value.toString()]
    let icon = "mdi-quadcopter"
    let label = "Estado" 
    return { value, icon, label, show_value, show_icon, show_label };
}


const windSpeedHandler = (data, show_value, show_icon, show_label) => {
    let value = data["wind_speed"]
    value = Math.floor(value)/10 + "m/s"
    let icon = "mdi-home"
    let label = "Viento"
    return { value, icon, label, show_value, show_icon, show_label };
}