<template>
    <div class="telemetryContainer">
        <v-chip-group  v-if="gatewayConnected">
            <v-chip size="x-small" class="chipIcon" @click="store.centerGatewayMap"><v-icon color="light-green-accent-3"
                    icon="mdi-home"></v-icon></v-chip>
            <v-chip v-for="(item, index) in this.dockTelemetry" :key="index" compact size="x-small">
                <div class="telemetryIcon" v-if="item.show_icon">
                    <v-icon color="white" :icon="item.icon"></v-icon>
                </div>
                <div v-if="item.show_label">
                    <span class="text-h7"> {{ item.label + ": " }} </span>
                </div>
                <div v-if="item.show_value">
                    <span class="text-h7"> {{ item.value }} </span>
                </div>
            </v-chip>

        </v-chip-group>
        <v-chip-group v-else>
            <v-chip size="x-small" class="chipIcon" @click="store.centerGatewayMap"><v-icon color="red-accent-4"
                    icon="mdi-home"></v-icon></v-chip>
        </v-chip-group>

    </div>
</template>

<script>

import { getDockTelemetry } from "../../utils/dockTelemetryUtils.js";

import { defineComponent } from "vue";
import useStore from "@/store";
import { storeToRefs } from "pinia";
export default defineComponent({
    props: {

    },
    components: {
    },
    setup() {
        const store = useStore();
        const dockSn = store.device_sn;
        const droneSn = store.child_device_sn;
        const { telemetry, gatewayConnected, droneConnected } = storeToRefs(store);
        return {
            store,
            telemetry,
            dockSn,
            droneSn,
            gatewayConnected,
            droneConnected
        };
    },
    computed: {
        dockTelemetry() {
            if (this.exist(this.telemetry[this.dockSn])) {
                if (this.exist(this.telemetry[this.dockSn]["osd_telemetry"])) {
                    try {
                        let result = getDockTelemetry(this.telemetry[this.dockSn]["osd_telemetry"]["data"]);
                        result = result.filter((item) => item !== null && item !== undefined);
                        
                        return result;
                    } catch {
                        return [];
                    }
                }
            }
            return [];
        },
    },
    methods: {
        exist(data) {
            return data !== undefined && data !== null;
        },

    },
    data() {
        return {};
    },
    watch: {

    },
    mounted() {
    },
});
</script>

<style scoped>
.telemetryContainer {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    padding: 0px 10px 0px 10px;
    margin: 0px 0px 0px 0px;
    /*background-color: rgba(73, 73, 73, 0.63);*/
    border-radius: 5px;
}
</style>