<template>
  <DefaultNotify />
    <div id="app">
      <router-view />
      </div>
      


</template>

<script setup>
/*import Notifications from 'notiwind'*/
import DefaultNotify from '@/notifications/DefaultNotify.vue';
import { refreshTokenService } from '@/services/manageService';
refreshTokenService();
</script>

<style scoped></style>
