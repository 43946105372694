<template>
    <v-alert  v-model="alert" density="compact" :title="alertTitle" :text="alertText" :type="alertType" variant="tonal"></v-alert>
</template>

<script>
import useUiStore from '@/store/ui'

export default {
name: "TSAFormatHms",
props: {
    item: Object,
},
components: {
},
setup() {
    const uiStore = useUiStore();
    return {
        uiStore
    };
},
data: () => ({
  alert: true,
}),
computed: {
    alertTitle() {
        const action = this.item.code;
        console.log(action)
        return `${action}`;
    },
    alertText() {
        const code = this.item.code;
        const code_str = this.uiStore.findCodeText(code)
        return code_str["en"]
    },
    alertDate() {
        const timestamp = this.item.timestamp;
        const date = new Date(timestamp);
        return date.toLocaleString();
    },
    alertType() {
        const level = this.item.level;
        if (level == 1) {
            return "warning"
        } else if (level == 2) {
            return "error"
        } else {
            return "info"
        }
    }
    
},
methods: {
},
watch: {
   
},
}

</script>